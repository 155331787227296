<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';
import { StorysetsStyles } from '@/Types/types';

const { trans } = useI18n();

defineProps<{
	style: StorysetsStyles | '';
}>();

const emit = defineEmits(['setStyle']);

const onClickStyle = (filter: StorysetsStyles | '') => {
	Bugsnag.leaveBreadcrumb(`Filter to ${filter || 'all'}`);
	emit('setStyle', filter);
};
</script>

<template>
	<div data-testid="storyset-container-btn-filters" class="flex h-10 pt-1">
		<button
			class="mr-2 h-6 rounded-full border border-gray-600 px-3 text-sm font-semibold"
			:class="{
				'bg-gray-600 text-white': style === '',
				'text-gray-300 hover:bg-gray-600 hover:text-white': style !== '',
			}"
			@click.stop="() => onClickStyle('')"
		>
			{{ trans('All') }}
		</button>

		<button
			class="mr-2 h-6 w-6 overflow-hidden rounded-full border"
			:class="{
				'border-gray-600 bg-gray-600': style === StorysetsStyles.Rafiki,
				'border-gray-700 bg-gray-800 hover:border-gray-600 hover:bg-gray-600': style !== StorysetsStyles.Rafiki,
			}"
			@click.stop="() => onClickStyle(StorysetsStyles.Rafiki)"
		>
			<SvgIcon name="stories-avatar-rafiki" class="h-full w-full" />
		</button>

		<button
			class="mr-2 h-6 w-6 overflow-hidden rounded-full border"
			:class="{
				'border-gray-600 bg-gray-600': style === StorysetsStyles.Bro,
				'border-gray-700 bg-gray-800 hover:border-gray-600 hover:bg-gray-600': style !== StorysetsStyles.Bro,
			}"
			@click.stop="() => onClickStyle(StorysetsStyles.Bro)"
		>
			<SvgIcon name="stories-avatar-bro" class="h-full w-full" />
		</button>

		<button
			class="mr-2 h-6 w-6 overflow-hidden rounded-full border"
			:class="{
				'border-gray-600 bg-gray-600': style === StorysetsStyles.Amico,
				'border-gray-700 bg-gray-800 hover:border-gray-600 hover:bg-gray-600': style !== StorysetsStyles.Amico,
			}"
			@click.stop="() => onClickStyle(StorysetsStyles.Amico)"
		>
			<SvgIcon name="stories-avatar-amico" class="h-full w-full" />
		</button>

		<button
			class="mr-2 h-6 w-6 overflow-hidden rounded-full border"
			:class="{
				'border-gray-600 bg-gray-600': style === StorysetsStyles.Pana,
				'border-gray-700 bg-gray-800 hover:border-gray-600 hover:bg-gray-600': style !== StorysetsStyles.Pana,
			}"
			@click.stop="() => onClickStyle(StorysetsStyles.Pana)"
		>
			<SvgIcon name="stories-avatar-pana" class="h-full w-full" />
		</button>

		<button
			class="mr-2 h-6 w-6 overflow-hidden rounded-full border"
			:class="{
				'border-gray-600 bg-gray-600': style === StorysetsStyles.Cuate,
				'border-gray-700 bg-gray-800 hover:border-gray-600 hover:bg-gray-600': style !== StorysetsStyles.Cuate,
			}"
			@click.stop="() => onClickStyle(StorysetsStyles.Cuate)"
		>
			<SvgIcon name="stories-avatar-cuate" class="h-full w-full" />
		</button>
	</div>
</template>
