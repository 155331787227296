<script setup lang="ts">
import { Sketch } from '@ckpack/vue-color';
import { colord } from 'colord';
import { computed, ComputedRef, ref, toRef, watch } from 'vue';

import { SolidColor } from '@/color/classes/SolidColor';
import ColorPickerOpacity from '@/color/components/ColorPickerOpacity.vue';
import ColorsPalette from '@/color/components/ColorsPalette.vue';
import EyeDrop from '@/color/components/EyeDrop.vue';
import { useMountedState } from '@/common/composables/useMountedState';
import { Color } from '@/Types/colorsTypes';
import { ColorInput } from '@/Types/types';

const props = defineProps<{ color: SolidColor; hideAlpha?: boolean; hideGradient?: boolean; hidePalettes?: boolean }>();

const emit = defineEmits<{ (e: 'change', color: Color): void }>();

const color = toRef(props, 'color');

const colorData = colord(props.color.toObject());
const oldHue = ref(colorData.hue());

// Necesitamos mantener una copia del DTO que emite la libreria para que se entere de actualizaciones en el hue cuando
// estamos sobre un color blanco o negro que está presente en cualquier hue
const sketchModel: ComputedRef<ColorInput> = computed(() => {
	const colorData = colord(props.color.toObject());
	return {
		a: props.color.a,
		rgba: colorData.toRgb(),
		hex: props.color.toHex(),
		hex8: colorData.toHex(),
		oldHue: oldHue.value,
		hsl: { ...colorData.toHsl(), h: oldHue.value },
		hsv: { ...colorData.toHsv(), h: oldHue.value },
		source: 'hsva',
	};
});

// Ya que el EyeDrop tiene que ir cuando este montado el sketch, lo retrasamos
const ready = useMountedState();

const emitColor = (colorData: ColorInput) => {
	oldHue.value = colorData.oldHue;

	const color = SolidColor.fromObject(colorData.rgba);
	emit('change', color);
};

const refreshColor = (colorData: Color) => {
	emit('change', colorData);
};
</script>

<template>
	<div ref="root" class="custom-picker">
		<Sketch
			:model-value="(sketchModel as unknown as SolidColor)"
			:preset-colors="[]"
			@update:model-value="(data) => emitColor(data as ColorInput)"
		/>
		<Teleport v-if="ready" to=".vc-sketch-controls">
			<EyeDrop @change="refreshColor" />
		</Teleport>
		<ColorPickerOpacity v-if="ready && !props.hideAlpha" :color="(color as SolidColor)" @change="refreshColor" />
		<ColorsPalette
			v-if="ready && !hidePalettes"
			:selected="color"
			:hide-gradient="hideGradient"
			@change="refreshColor"
		/>
	</div>
</template>

<style lang="sass">
.custom-picker .vc-sketch
  @apply bg-transparent w-auto shadow-none p-0 flex flex-col relative

  .vc-sketch-presets
    @apply -mx-0 mt-3 pt-1 px-1 border-t border-gray-600 #{!important}

    .preset-active::after
      @apply border-0 #{!important}

  @media (max-width: 1023px)
    @apply bg-transparent shadow-none

  & > div[class*="vc-sketch"]
    @apply order-2

  .vc-sketch-saturation-wrap
    @apply mb-2 overflow-visible
    padding-bottom: 65%

    .vc-saturation, .vc-saturation--black, .vc-saturation--white
      @apply rounded-xl lg:rounded

  .vc-saturation-pointer
    @apply w-3 h-3 -translate-x-1/2 -translate-y-1/2

    .vc-saturation-circle
      @apply h-full w-full border-[3px] border-white bg-black/10 translate-y-0 translate-x-0
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 60%)

  @media (max-width: 1023px)
    .vc-sketch-saturation-wrap
      padding-bottom: 25%
    .vc-sketch-controls + .vc-sketch-field
      display: none

  .vc-sketch-controls
    @apply items-center gap-2

  .vc-sketch-sliders
    @apply p-0

    .vc-sketch-hue-wrap
      @apply h-2

      @media (max-width: 1023px)
        @apply h-3

      .vc-hue
        @apply rounded-full h-2

        @media (max-width: 1023px)
          @apply h-3

        .vc-hue-picker
          @apply w-2 w-2 rounded-full mt-0

          @media (max-width: 1023px)
            @apply w-3 h-3

    .vc-sketch-alpha-wrap
      @apply hidden

  .vc-sketch-color-wrap
    @apply m-0 rounded-full hidden

    @media (max-width: 1023px)
      @apply w-10 h-10

    .vc-sketch-active-color
      @apply rounded-full shadow-none border border-gray-800

    .vc-checkerboard
      @apply rounded-full

  .vc-sketch-field
    @apply grid grid-cols-6 gap-2

    &--double
      @apply col-span-2 flex items-center

    &--single
      @apply hidden

    .vc-editable-input
      @apply flex flex-col-reverse

    .vc-input__input
      @apply bg-gray-900 rounded shadow-none px-1 h-5 py-0 text-2xs text-gray-100 w-full text-center

    .vc-input__label
      @apply hidden

  .vc-sketch-presets

    @media (max-width: 1023px)
      @apply flex flex-col border-gray-600

    .vc-sketch-presets-color
      @apply m-0 w-full aspect-square rounded-full h-auto

      &::before
        content: ''
        @apply absolute inset-0 -z-10 rounded-full m-px
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADBJREFUOE9jfPbs2X8GPEBSUhKfNAPjqAHDIgz+//+PNx08f/4cfzoYNYCBceiHAQC5flV5JzgrxQAAAABJRU5ErkJggg==')
        background-repeat: repeat
        background-size: 10px

      &:hover
        cursor: url('@/assets/svgs/dropper-fill.svg') 12 12, auto

      @media (max-width: 1023px)
        @apply w-8 h-8 shrink-0

.mode-mockup .custom-picker .vc-sketch .vc-sketch-field .vc-input__input
  @apply bg-fp-gray-150 text-fp-gray-700

.project-colors-panel .custom-picker .vc-sketch
  .vc-sketch-field
    @apply gap-0 pt-0

    .vc-input__label
      @apply block text-gray-300 text-2xs

    &--single
      @apply block
</style>
