import Element from '@/elements/element/classes/Element';
import { NodeOriginalData } from '@/Types/DomNodesTypes';
export class DomNodesElement {
	element: Element;
	baseNode: HTMLElement | null;
	constructor(element: Element) {
		this.element = element;
		this.baseNode = this.element.domNode();
	}

	get nodeData(): NodeOriginalData | undefined {
		return this.originalData;
	}

	setSizeToOriginalNode(width: number, height: number) {
		if (!this.baseNode) return;
		this.baseNode.style.width = `${width}px`;
		this.baseNode.style.height = `${height}px`;
	}

	setPositionToOriginalNode(x: number, y: number) {
		if (!this.baseNode) return;
		this.baseNode.style.transform = `translate(${x}px, ${y}px) rotate(${this.element.rotation}deg)`;
	}

	setRotationToOriginalNode(rotation: number) {
		if (!this.baseNode) return;
		this.baseNode.style.transform = `translate(${this.element.position.x}px, ${this.element.position.y}px) rotate(${rotation}deg)`;
	}

	private get originalData(): NodeOriginalData | undefined {
		if (!this.baseNode) return;
		const transformNode = this.baseNode.style.transform;
		if (transformNode === '') {
			this.baseNode.style.transform = `translate(0px, 0px)`;
		}
		const positionNode = this.baseNode.style.transform.match(/-?\d+\.?\d*/g)?.map((val) => Number(val));
		if (!positionNode) return;
		const width = parseFloat(this.baseNode.style.width);
		const height = parseFloat(this.baseNode.style.height);

		return { originalPosition: { x: positionNode[0], y: positionNode[1] }, originalSize: { width, height } };
	}
}
