import Bugsnag from '@bugsnag/js';
import { createSharedComposable, until, useUrlSearchParams } from '@vueuse/core';
import { computed, nextTick, Ref, ref } from 'vue';

import { getSvg } from '@/api/DataApiClient';
import { useProjectColors } from '@/color/composables/useProjectColors';
import { useVariantsColors } from '@/color/composables/useVariantsColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import { useBackgroundableElement } from '@/elements/element/composables/useBackgroundableElement';
import ElementTools from '@/elements/element/utils/ElementTools';
import Line from '@/elements/line/classes/Line';
import { BackgroundableElement } from '@/elements/medias/crop/types/croppeable.type';
import Image from '@/elements/medias/images/image/classes/Image';
import ErrorPhotoModeUrl from '@/elements/medias/images/image/exception/ErrorPhotoModeUrl';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useHistoryStore } from '@/history/stores/history';
import { SlidesgoTemplateLoader } from '@/loader/slidesgo/SlidesgoTemplateLoader';
import { PageData, TemplateLoaderData } from '@/loader/types/templateLoaderData';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { Panels } from '@/Types/types';

export const useTemplateLoader = createSharedComposable(() => {
	let isNewArtboard = false;
	const store = useMainStore();
	const history = useHistoryStore();
	const project = useProjectStore();
	const { setArtboardFromUrl, addPages } = useProject();
	const {
		isAdminMode,
		isPhotoMode,
		isIllustratorContext,
		isPredefinedTextMode,
		isSlidesgoMode,
		isCypressContext,
		cypressContextData,
	} = useEditorMode();
	const { isIOS } = useDeviceInfo();
	const { fitZoomScale } = useZoom();
	const { setArtboardSize, convertMmToPx, MM_TO_PX } = useArtboard();
	const { fonts, loadFontsBySlug } = useFonts();
	const {
		updatePageBgColorById,
		updateQRCodeColorById,
		updateLinesColorById,
		updateShapesColorById,
		updateBoxesColorById,
		updateStorysetsColorById,
		updateTextsColorById,
	} = useProjectColors();

	const { toggleApplyingVariantColors } = useVariantsColors();

	const temporalRefElement = ref(Image.create());
	const { setElementAsBackground } = useBackgroundableElement(temporalRefElement as Ref<BackgroundableElement>);

	const tempPageRef = ref(Page.createDefault());
	const { adjustContent } = usePage(tempPageRef as Ref<Page>);

	const templateData = ref<TemplateLoaderData>();
	const variantSelected = ref();

	const currentTasks = ref<PageData[]>([]);
	const pendingTasks = ref<PageData[]>([]);
	const isFirstLoad = ref(true);

	const tasks = computed(() => {
		return [...currentTasks.value, ...pendingTasks.value];
	});

	const init = async () => {
		const pathNameSplit = window.location.pathname.split('/').filter((a) => !!a);
		let slug = pathNameSplit.length === 0 ? 'new-artboard' : pathNameSplit.pop() || 'new-artboard';

		slug = ['editor', 'slidesgo'].includes(slug) ? 'new-artboard' : slug;

		isNewArtboard = slug === 'new-artboard';
		const params = useUrlSearchParams<{
			variant: string | undefined;
			template: string;
		}>();

		if (params.template) {
			slug = params.template;
		}

		await until(fonts).toMatch((fonts) => {
			return Object.keys(fonts).length > 0;
		});

		try {
			templateData.value = await TemplateLoader.getTemplateData(slug, true);
		} catch (error) {
			store.isFromFreepik = window.preloadVector?.has_freepik_vector || false;
			store.freepikPreview = window.preloadVector?.freepik_preview || null;
			project.sourceVectorId = window.preloadVector?.id;
			throw error;
		}

		// Actualizamos el editor para usar la info del template
		setProjectMetadataFromTemplate(templateData.value);

		/**
		 * SLIDESGO
		 */
		if (templateData.value.origin?.name === 'slidesgo' && !templateData.value.is_parsed) {
			try {
				const { data } = await getSvg(templateData.value.pages[templateData.value.pages.length - 1].svg_url);
				let content = data.value as string;
				// SVGJS falla con namespaces formados por dos puntos
				content = content.replaceAll('ooo:', 'ooo-');
				const slidesgoTemplateLoader = new SlidesgoTemplateLoader();
				await slidesgoTemplateLoader.parseMonolithicSvg(content, templateData.value);
				project.sourceVectorId = templateData.value.id;
			} catch (error) {
				console.error(error);
			}

			isFirstLoad.value = false;
			return;
		}

		/**
		 * WEPIK
		 */
		if (templateData.value.generator) {
			store.generator = templateData.value.generator;
		}

		const { width, height, unit } = templateData.value.artboard;
		setArtboardSize(width, height, unit, true);

		variantSelected.value = templateData.value.colorPalettes?.find(
			(colorPalette) => colorPalette.id.toString() == params.variant
		);
		pendingTasks.value = templateData.value?.pages.slice(1) || [];
		currentTasks.value = templateData.value?.pages.slice(0, 1) || [];

		const defaultPages = templateData.value.pages.map((pageResponse) => {
			const page = Page.create();
			page.name = 'temporal';
			page.preview = pageResponse.preview;

			// vamos precargando
			const img = document.createElement('img');
			img.src = page.preview || '';

			return page;
		});

		addPages(defaultPages);

		await runTasks();
	};

	const runTasks = async () => {
		const useCypressSvg =
			isCypressContext.value &&
			cypressContextData.value.svg &&
			cypressContextData.value.json &&
			cypressContextData.value.data;

		// Hemos terminado de cargar todas las páginas del proyecto
		if (pendingTasks.value.length === 0 && currentTasks.value.length === 0) {
			// Aplicamos los cambios que cada modo requiere
			await setupMode();

			// Como ya no queremos almacenar escalas en el backend si esté lo trae, le marcamos su escala a 1 y forzamos un sync
			// para tener los datos al tamaño original que es lo que espera ahora el backend
			// Una vez pasado por aquí entendemos que la plantilla ya está parseada al sistema nuevo del fix IOS
			let forceSync = templateData.value?.forceSync;

			if (project.scale !== 1) {
				project.scale = 1;
				forceSync = true;
			}

			history.setInitialState(forceSync);
			store.finishedLoading = true;

			if (useCypressSvg) {
				const json = await fetch(cypressContextData.value.json as string).then((res) => res.json());
				const pageParsed = TemplateLoader.loadFromData(json);

				window.Cypress.editor = {
					page: project.pages[0],
					page_parsed: pageParsed,
				};
			}

			return;
		}

		// Siguiente tanda de páginas
		if (pendingTasks.value.length > 0 && currentTasks.value.length === 0) {
			currentTasks.value = pendingTasks.value.splice(0, 5);
		}

		// Parseo de páginas
		const queue = currentTasks.value.map(async (page: PageData) => {
			let pageLoaded = Page.createDefault();
			let shouldInvalidateServerVersion = false;

			try {
				const url = useCypressSvg ? (cypressContextData.value.svg as string) : page.svg_url;
				Bugsnag.leaveBreadcrumb(`Loading ${url}`);

				const { data, response } = await getSvg(url);
				const contentType = response.value?.headers.get('content-type');
				const content = contentType === 'application/json' ? JSON.parse(data.value as string) : data.value;
				const isSvg = typeof content === 'string';

				if (isSvg && isFirstLoad.value && isIllustratorContext.value && typeof templateData.value !== 'undefined') {
					pageLoaded = TemplateLoader.parseIllustrator(content, templateData.value);
				} else if (isSvg && typeof templateData.value !== 'undefined') {
					pageLoaded = await TemplateLoader.parseSvg(content, templateData.value);
				} else {
					pageLoaded = TemplateLoader.loadFromData(content, !!templateData.value?.userVectorId);
				}
				// Migramos el nombre de las fuentes a un slug
				await migrateFontNameToFontSlug(pageLoaded);

				shouldInvalidateServerVersion = await applyDocumentFixes(pageLoaded, isSvg);

				// Aplicamos la variante de la url si existe
				applyVariant(pageLoaded);
			} catch (error: any) {
				console.error('Error loading page', error);
				// Si hay un error en multi-página lo ignoramos
				if (templateData.value?.pages.length === 1 || isAdminMode.value) {
					throw error;
				}
			}

			shouldInvalidateServerVersion =
				(await TemplateLoader.legacyCodeMigrator(pageLoaded)) || shouldInvalidateServerVersion;
			if (shouldInvalidateServerVersion) {
				project.invalidateServerVersion?.(pageLoaded);
			}

			return {
				order: page.order,
				page: pageLoaded,
			};
		});

		// Procesamos las páginas que hay en la cola
		await Promise.all(queue).then(async (pages) => {
			if (isFirstLoad.value && typeof templateData.value !== 'undefined') {
				// Si estamos en modo illustrator y no tenemos el elemento de este modo redireccionamos al modo admin
				if (isIllustratorContext.value && !pages[0].page.elementsAsArray().find((el) => el.type === 'illustrator')) {
					const url = window.location.href.split('?')[0] + '?admin=1';
					window.location.href = url;
				}

				isFirstLoad.value = false;
				project.id = templateData.value?.userVectorId || project.id;

				const artboardInUrl = await setArtboardFromUrl();

				if (!artboardInUrl && !isPhotoMode.value) {
					const { width, height, unit } = templateData.value.artboard;
					setArtboardSize(width, height, unit, true);
				}

				// Si el proyecto trae escala del backend vamos a reescalar el artboard a su tamaño original
				if (project.scale !== 1) {
					setArtboardSize(project.size.width / project.scale, project.size.height / project.scale, project.unit, true);
				}

				store.setActivePage(pages[0].page);
				// Ajustamos la escala de zoom por defecto
				fitZoomScale(isAdminMode.value ? 1 : undefined);
			}

			// Añadimos todas las páginas del template al proyecto
			project.$patch(() => {
				pages.forEach((page) => {
					if (page.page) {
						// Si el proyecto trae escala del backend vamos a reescalar todos los elementos al tamaño original
						// ya que en el backend ahora siempre vamos a tener los elementos a su tamaño original, esto deshace
						// la anterior versión del fix de ios que guardaba los elementons escalados en el backend
						if (project.scale !== 1) {
							page.page.scaleBy(1 / project.scale);
						}
						// Si es iOS aplicamos la escala calculada para esta sesión a todos los elementos
						if (isIOS.value) {
							page.page.scaleBy(store.scaleMaxAllowedSize);
						}
						// En la página se esta guardado la preview que puede estar desfasada
						// Usamos la que hay en la respuesta de la api del vector si esta disponible
						page.page.preview = project.pages[page.order].preview || page.page.preview;
						project.pages[page.order].replace(page.page);
					}
				});
			});

			currentTasks.value = [];

			await runTasks();
		});
	};

	const setProjectMetadataFromTemplate = (templateData: TemplateLoaderData) => {
		const params = useUrlSearchParams('history');

		if (!!params.category_id && !!params.category_name) {
			const categoryId = params.category_id.toString();
			const isCategoryIdNumber = /^\d+$/.test(categoryId);
			store.activeTemplateCategory = {
				id: isCategoryIdNumber ? parseInt(categoryId) : 0,
				name: params.category_name.toString(),
			};
		}

		if (!isNewArtboard && templateData.category_tree.length) {
			store.downloadTemplateCategory = templateData.category_tree[1];
			store.activeTemplateCategory = templateData.category_tree.reverse()[0];
		}

		if (templateData.pack) {
			store.pack = templateData.pack;
		}

		if (templateData.inSchedules) {
			store.inSchedules = templateData.inSchedules;
		}

		store.projectCategory = store.activeTemplateCategory;

		if (params.category_name) {
			project.category = params.category_name.toString();
		} else {
			project.category = templateData.category_tree.length && !isNewArtboard ? templateData.category_tree[0].name : '';
		}

		project.name = isPhotoMode.value ? 'Photo mode' : !isNewArtboard ? templateData.name : '';
		project.flaticonSearch = templateData.flaticonSearch;
		project.sourceVectorId = templateData.id;
		project.scale = templateData.scale;

		if (templateData.colorTags) {
			project.colorTags = templateData.colorTags;
		}

		if (templateData.userVectorId) {
			store.userVector = {
				uuid: templateData.userVectorId,
				project: templateData.project || 'wepik',
				preview: templateData.preview || '',
			};
		} else {
			store.colorPalettes = templateData.colorPalettes || [];
		}

		if (templateData.artboard.dpi) {
			store.dpi = templateData.artboard.dpi;
		}

		store.isFromFreepik = templateData.fromFreepik;
		store.freepikPreview = templateData.freepikPreview;
	};

	const fitPhotoModeImage = async () => {
		if (!isPhotoMode.value || typeof templateData.value === 'undefined') return;

		temporalRefElement.value = project.pages[0].elementsAsArray()[0] as Image;

		await nextTick();
		setArtboardSize(templateData.value.artboard.width, templateData.value.artboard.height, 'px', true);
		await setElementAsBackground();
		fitZoomScale();
	};

	const setupMode = async () => {
		try {
			if (isPhotoMode.value && typeof templateData.value !== 'undefined') {
				await TemplateLoader.initPhotoMode(project.pages[0] as Page, templateData.value);
				await fitPhotoModeImage();
			} else if (isPredefinedTextMode.value) {
				TemplateLoader.initPredefindedTextMode(project.pages as Page[]);
			} else if (isIllustratorContext.value) {
				TemplateLoader.initIllustratorMode();
			}
		} catch (error: any) {
			if (error.name === 'ErrorPhotoModeUrl') {
				if (!localStorage.getItem('wepik.com.isPhotoMode.error')) {
					// Si falla al cargar el modo photo lo intentamos una vez más antes de lanzar el error
					localStorage.setItem('wepik.com.isPhotoMode.error', 'true');
					window.location.reload();
				} else {
					// Si falla después de reintentarlo entonces lanzamos el error definitivamente
					localStorage.removeItem('wepik.com.isPhotoMode.error');
					throw new ErrorPhotoModeUrl(error);
				}
				return;
			} else {
				throw error;
			}
		}
	};

	const applyVariant = (page: Page) => {
		variantSelected.value?.color_palette.forEach((colorCollection: any) => {
			toggleApplyingVariantColors();

			colorCollection.ids.forEach((id: string) => {
				updatePageBgColorById(id, colorCollection.color, page);
				updateQRCodeColorById(
					id,
					colorCollection.color,
					page.elementsAsArray().filter((el) => el instanceof QRCode) as QRCode[]
				);
				updateLinesColorById(
					id,
					colorCollection.color,
					page.elementsAsArray().filter((el) => el instanceof Line) as Line[]
				);
				updateShapesColorById(
					id,
					colorCollection.color,
					page.elementsAsArray().filter((el) => el instanceof Shape) as Shape[]
				);
				updateBoxesColorById(
					id,
					colorCollection.color,
					page.elementsAsArray().filter((el) => el instanceof Box) as Box[]
				);
				updateStorysetsColorById(
					id,
					colorCollection.color,
					page.elementsAsArray().filter((el) => el instanceof Storyset) as Storyset[]
				);
				updateTextsColorById(
					id,
					colorCollection.color,
					page.elementsAsArray().filter((el) => el instanceof Text) as Text[]
				);
			});
		});

		toggleApplyingVariantColors();
	};

	const applyDocumentFixes = async (page: Page, isSvg: boolean) => {
		const changesTextFixes = await TextTools.applyTextFixes(page);

		let changesDpi = false;
		const newElements = ElementTools.fixRepeatedElementIds(project.allElements, page.elementsAsArray());
		if (newElements?.length) page.elements = new Map(newElements.map((el) => [el.id, el]));

		// Para ajustar la plantilla al usar un dpi distinto
		const { dpi } = (templateData.value as TemplateLoaderData).artboard;
		const { width, height } = project.size;

		if (!isSvg && project.unit === 'mm' && dpi !== MM_TO_PX) {
			tempPageRef.value = page;
			adjustContent(convertMmToPx(project.size.width, project.size.height), {
				width: Math.round(width * dpi),
				height: Math.round(height * dpi),
			});

			changesDpi = true;
		}

		return changesTextFixes || changesDpi;
	};

	const migrateFontNameToFontSlug = async (page: Page) => {
		const fontsInPage = page
			.elementsAsArray()
			.filter((el) => el instanceof Text)
			.flatMap((textEl: any) => {
				const list = new Set<string>();
				list.add(textEl.fontFamily);

				//@ts-ignore
				textEl
					.htmlInstance()
					.querySelectorAll<HTMLElement>('[style*="font-family"]')
					.forEach((el: HTMLElement) => {
						const fontFamily = el.style.fontFamily;
						list.add(fontFamily);
					});

				return Array.from(list);
			});
		await loadFontsBySlug([...new Set(fontsInPage)]);
		page
			.elementsAsArray()
			.filter((el) => el instanceof Text)
			.forEach((textEl: any) => {
				const fontSlug =
					Object.values(fonts.value).find((f) => f.name === textEl.fontFamily)?.slug ||
					Object.values(fonts.value).find((f) => f.slug === textEl.fontFamily)?.slug ||
					Object.values(fonts.value).find((f) => f.name === 'Montserrat')?.slug;

				textEl.fontFamily = fontSlug;

				const content = textEl.htmlInstance();

				//@ts-ignore
				content.querySelectorAll<HTMLElement>('[style*="font-family"]').forEach((el) => {
					const fontFamily = el.style.fontFamily.replaceAll('"', '');
					const fontSlug =
						Object.values(fonts.value).find((f) => f.name === fontFamily)?.slug ||
						Object.values(fonts.value).find((f) => f.slug === fontFamily)?.slug ||
						Object.values(fonts.value).find((f) => f.name === 'Montserrat')?.slug;

					el.style.fontFamily = fontSlug;
				});

				textEl.updateContent(content.body.firstElementChild.innerHTML.toString());
			});
	};

	return {
		init,
		tasks,
		isFirstLoad,
		templateData,
	};
});
