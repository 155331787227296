<script setup lang="ts">
import { computed } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';

// en el gradient transform -90 porque los gradientes en svg por defecto tienen una orientación distinta al css, lo corregimos
const props = defineProps<{ gradients: GradientColor[]; previewName?: string }>();
const preview = computed(() => (props.previewName ? `-preview-${props.previewName}` : ''));
</script>

<template>
	<svg class="pointer-events-none absolute" width="0" height="0">
		<defs>
			<component
				:is="gradient.type === 'linear' ? 'linearGradient' : 'radialGradient'"
				v-for="gradient in gradients"
				:id="gradient.id + preview"
				:key="gradient.toCssString() + previewName"
				data-testid="gradients-renderer"
				:gradientTransform="`rotate(${gradient.rotation - 90}, 0.5, 0.5)`"
			>
				<stop
					v-for="stop in gradient.stops"
					:key="gradient.stopToString(stop)"
					data-testid="gradient-stop"
					:offset="`${stop.offset}%`"
					:stop-color="`rgba(${stop.r}, ${stop.g}, ${stop.b}, ${stop.a})`"
				></stop>
			</component>
		</defs>
	</svg>
</template>
