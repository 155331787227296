<script setup lang="ts">
import { ref } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { TemplateSuggestion } from '@/Types/types';

const store = useMainStore();
const project = useProjectStore();
const { selection, selectionId } = useSelection();

const elRef = ref();
const { page } = usePageElement(elRef);

const showSuggestion = async (suggestion: TemplateSuggestion, fix = false) => {
	const element = project.getElementById(suggestion.element);

	if (!element) {
		return;
	}

	elRef.value = element;

	// Si no encontramos el contenido renderizado, hacemos scroll hacia esa página y esperamos a que este listo
	if (!page.value.domNode()?.querySelector(`#element-${suggestion.element}`)) {
		page.value.domNode()?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
		});
		await new Promise((resolve) => setTimeout(resolve, 1500));
	}

	const domElement = element.domNode() as HTMLElement;

	if (domElement.classList.contains('report-error')) {
		domElement.classList.remove('report-error');
		return;
	}

	selectionId.value = [suggestion.element];

	if (suggestion.fix && fix) {
		suggestion.fix();
	} else {
		domElement.classList.add('report-error');
	}
};
</script>

<template>
	<div>
		<button
			v-for="suggestion in store.templateSuggestions"
			class="h group pointer-events-auto relative mr-2 flex h-10 w-10 cursor-pointer items-center justify-center rounded bg-gray-800 p-2 transition-all duration-300 hover:bg-orange-400"
			@click="showSuggestion(suggestion)"
		>
			<SvgIcon
				class="h-8 w-8 animate-pulse fill-current text-orange-600 group-hover:animate-none group-hover:text-white"
				name="alert"
			/>
			<div
				class="items-left transiton-all pointer-events-none absolute bottom-full left-0 h-auto w-64 translate-y-2 flex-col rounded-br rounded-tl rounded-tr bg-orange-400 p-2 text-sm opacity-0 duration-300 group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100"
			>
				<p class="text-center text-base font-bold text-white">{{ suggestion.message }}</p>
				<span
					v-if="suggestion.fix"
					class="mt-1 block rounded bg-green-600 p-1 font-bold text-white hover:bg-green-800"
					@click="showSuggestion(suggestion, true)"
					>FIX</span
				>
			</div>
		</button>
	</div>
</template>
