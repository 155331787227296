<script lang="ts" setup>
import Bugsnag from '@bugsnag/browser';
import { computed, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { SolidColor } from '@/color/classes/SolidColor';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import { useImagePresets } from '@/elements/medias/images/foreground/composables/useImagePresets';
import Image from '@/elements/medias/images/image/classes/Image';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useActivePage } from '@/page/composables/useActivePage';
import { MediaApi } from '@/Types/apiClient';
import { Font, Panels, TextWithValue } from '@/Types/types';
import { BrandkitFont } from '@/Types/types';

defineProps<{ brandKitSelected: any }>();

const store = useMainStore();
const { trans } = useI18n();

const { setBackground } = useActivePage();
const { runOnMobile } = useDeviceInfo();
const logo = ref<Image | Shape | Box>(Image.create());
const previewText = ref(Text.create({ fontSize: 24, fontWeight: 400, lineHeight: 1.2 }));
const { addInsertableText: addText, addInsertableElement } = useAddInsertableElement(previewText as Ref<Text>);
const isLogoWithSeparateLayers = computed(
	() => logo.value instanceof Image && logo.value.backgroundMode === 'background' && !!logo.value.urlBackgroundRemoved
);
const selectHandler = async (mediaApi: MediaApi) => {
	const insertable = await addInsertableElement(mediaApi);
	if (!insertable || (!(insertable instanceof Image) && !(insertable instanceof Shape) && !(insertable instanceof Box)))
		return;
	logo.value = insertable;

	// si es un logo con capas separadas, lo añadimos con el fondo transparente
	if (isLogoWithSeparateLayers.value && logo.value instanceof Image) {
		const { removeBg } = useImagePresets(logo as Ref<Image>);
		removeBg();
	}
	Bugsnag.leaveBreadcrumb(`Add image to canvas from brand-kit; image-${logo.value.id}}`);
	GAnalytics.track('click', 'Brandkit', 'add-logo', null);

	runOnMobile(() => (store.activePanel = null));
};

const setBackgroundColor = (color: string) => {
	const newColor = SolidColor.fromString(color);
	setBackground(newColor);

	GAnalytics.track('click', 'Brandkit', 'apply-color', null);

	runOnMobile(() => (store.activePanel = null));
};
const userFontText = {
	heading: trans('Heading text style'),
	subheading: trans('Subheading text style'),
	bodytext: trans('Body text style'),
};
const addFont = (font: BrandkitFont) => {
	const style: TextWithValue = {
		fontSize: font.origin === 'heading' ? 24 : font.origin === 'subheading' ? 18 : 14,
		lineHeight: 1.2,
		fontWeight: font.weights[0] as any,
		fontFamily: font.slug,
		text:
			(font.origin === 'heading' ? 'Heading' : font.origin === 'subheading' ? 'Subheading' : 'Body') + ' text style',
	};

	addText(style, true);

	GAnalytics.track('click', 'Brandkit', 'apply-font', null);

	runOnMobile(() => (store.activePanel = null));
};
</script>

<template>
	<div>
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Logos') }}</h4>
		<div class="mb-6 flex flex-wrap gap-2">
			<InsertableElement
				v-for="selectedLogo in brandKitSelected.logos"
				:key="selectedLogo.id"
				:data="selectedLogo"
				:type="Panels.brandKits"
				:draggable="true"
				class="h-12 w-auto rounded bg-gray-700 p-2 hover:bg-gray-600"
				@click="selectHandler(selectedLogo)"
			>
				<ListMedia class="h-full" :use-background-removed-if-available="true" :image="selectedLogo" />
			</InsertableElement>

			<p
				v-if="brandKitSelected.logos.length === 0"
				class="mx-auto rounded-full bg-gray-600 px-8 py-1 text-center text-xs text-gray-100"
			>
				{{ trans('You have not uploaded any logo') }}
			</p>
		</div>

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Colors') }}</h4>
		<div data-testid="brandkit-container-colors" class="mb-6 flex flex-row flex-wrap gap-2">
			<button
				v-for="color in brandKitSelected.colors"
				:key="color"
				class="bg-transparent-pattern relative h-10 w-10 shrink-0 overflow-hidden rounded-full"
				@click="setBackgroundColor(color)"
			>
				<span
					class="absolute left-0 top-0 h-full w-full"
					:style="{
						background: color,
					}"
				></span>
				<span class="absolute left-0 top-0 h-full w-full rounded-full border border-white/10"></span>
			</button>

			<p
				v-if="brandKitSelected.colors.length === 0"
				class="mx-auto rounded-full bg-gray-600 px-8 py-1 text-center text-xs text-gray-100"
			>
				{{ trans('You have not added any color') }}
			</p>
		</div>

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Fonts') }}</h4>
		<div data-testid="brandkit-container-fonts" class="flex flex-col gap-4">
			<button
				v-for="font in (brandKitSelected.fonts as BrandkitFont[])"
				:key="font.slug"
				class="w-full cursor-pointer rounded bg-gray-700 p-4 text-left text-xl text-gray-100 focus:outline-none hover:bg-gray-600 hover:text-white"
				@click="addFont(font)"
			>
				<p class="mb-2 text-left text-sm">{{ font.name }}, {{ font.weights[0] }}</p>
				<p
					:style="{
						fontSize: `23px`,
						fontFamily: font.slug,
						fontWeight: font.weights[0],
					}"
				>
					{{ userFontText[font.origin as keyof typeof userFontText] }}
				</p>
			</button>

			<p
				v-if="brandKitSelected.fonts.length === 0"
				class="mx-auto rounded-full bg-gray-600 px-8 py-1 text-center text-xs text-gray-100"
			>
				{{ trans('You have not uploaded any font') }}
			</p>
		</div>
	</div>
</template>
