<script lang="ts" setup>
import { isEqual } from 'lodash';
import { computed, ref } from 'vue';

import CollectionListbox from '@/common/components/CollectionListbox.vue';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { AiPresentationTone, ItemList } from '@/Types/types';

const props = defineProps<{ tone: string }>();

const emits = defineEmits<{ (e: 'select', tone: AiPresentationTone): void }>();

const tones = ref<{ name: AiPresentationTone; id: string }[]>([
	{ name: AiPresentationTone.unspecified, id: '1' },
	{ name: AiPresentationTone.casual, id: '2' },
	{ name: AiPresentationTone.creative, id: '3' },
	{ name: AiPresentationTone.formal, id: '4' },
	{ name: AiPresentationTone.fun, id: '5' },
	{ name: AiPresentationTone.professional, id: '6' },
]);

const selectedTone = ref<ItemList>(tones.value.find((t) => t.name === props.tone) || tones.value[0]);

const placeholder = computed(() => {
	return TextTools.capitalizeFirstLetter(selectedTone.value.name);
});

const onSelectListOption = (newVal: ItemList[]) => {
	if (isEqual(selectedTone.value, newVal)) return;
	if (newVal.length) {
		const tone = newVal[0].name as AiPresentationTone;

		emits('select', AiPresentationTone[tone]);
	}

	selectedTone.value = newVal[0];
};
</script>
<template>
	<div class="relative">
		<CollectionListbox
			class="appearance-none rounded border border-transparent bg-gray-900 text-sm text-gray-100 placeholder-gray-300/50 shadow-none placeholder:text-gray-500 focus:border-blue-500 focus:outline-none lg:rounded"
			:collection="tones"
			:placeholder="placeholder"
			:show-check="false"
			:custom-button-class="'pl-4'"
			@selected="onSelectListOption"
		/>
	</div>
</template>
