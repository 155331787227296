<script lang="ts" setup>
import { ref } from 'vue';

import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import PhotosPanel from '@/elements/medias/images/image/components/panels/add/PhotosPanel.vue';
import { useI18n } from '@/i18n/useI18n';
import { ImageApi, VideoApi } from '@/Types/apiClient';

const replacing = ref(false);

const onCloseModal = () => (replacing.value = false);
const { trans } = useI18n();

const onReplaceImage = async (img: ImageApi | VideoApi) => {
	const url = img.metadata?.raw || img.url || img.preview;
	const encoded = encodeURIComponent(url);
	// TODO: Use URLSearchParams
	window.location.replace(window.location.href.split('?photo=')[0] + `?photo=${encoded}`);
};
</script>

<template>
	<Modal open>
		<div
			class="relative mx-auto flex flex-col items-center justify-center overflow-hidden rounded-md bg-white px-8 py-8"
		>
			<SvgIcon name="alert" class="mb-6 h-10 w-10 text-red-500" />
			<h1 class="text-2xl font-bold text-gray-800">{{ trans(`Something went wrong`) }}</h1>
			<p class="text-lg text-gray-600">{{ trans('The image has expired') }}</p>
			<div class="mt-6 flex flex-col gap-4 sm:flex-row">
				<button
					class="rounded border-2 border-blue-500 bg-blue-500 px-4 py-2 font-semibold text-white hover:border-blue-600 hover:bg-blue-600 slidesgo:border-purple-400 slidesgo:bg-purple-400"
					@click="replacing = true"
				>
					{{ trans('Try another image') }}
				</button>
			</div>
		</div>
	</Modal>

	<Modal
		v-if="replacing"
		content-classes="rounded w-11/12  lg:w-auto lg:max-w-lg lg:min-w-lg relative bg-white max-h-full py-4 lg:py-5 px-4 lg:px-6 h-[90vh]"
		open
		@close="replacing = false"
	>
		<PhotosPanel data-testid="replace-img-modal" modal @closeModal="onCloseModal" @selection="onReplaceImage" />
	</Modal>
</template>
