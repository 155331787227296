import Bugsnag from '@bugsnag/js';
import { getIndicesBetween } from '@tldraw/indices';
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { computed, Ref } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useSelection } from '@/interactions/composables/useSelection';

export const useGroup = (element: Ref<Element>) => {
	const { selection, selectionId, selectionOrdered } = useSelection();
	const { page } = usePageElement(element);

	const lockedGroup = computed(() => {
		return page.value?.elementsAsArray().filter((el) => el.group && el.group === element.value.group) || [];
	});
	const isLockGroup = computed(
		() => lockedGroup.value.length && selection.value.every((el) => el.group === lockedGroup.value[0].group)
	);
	const isGrouped = computed(() => group.value.length > 1);
	const group = computed(() =>
		selectionId.value.includes(element.value.id) ? selectionOrdered.value : lockedGroup.value
	);

	const setOpacity = (opacity: number) => group.value.forEach((el) => el.setOpacity(opacity));

	const lockGroup = (elements: Element[]) => {
		const idGroup = uuidv4();
		elements.forEach((el) => {
			el.group = idGroup;
		});

		setElementsCorrelatively();
		Bugsnag.leaveBreadcrumb(`Lock group: ${elements.map((el) => ` ${el.type}-${el.id}`)}`);
	};

	// Coloca a todos los elementos del grupo de manera consecutiva en su z-index, respetando el orden visual de los elementos
	const setElementsCorrelatively = () => {
		const sortedElements = [...group.value];
		const pageElements = page.value.elementsAsArray();
		const index = pageElements.findIndex((el) => el.id === sortedElements[sortedElements.length - 1].id);

		const aboveElement = pageElements.find(
			(el) => el.index > pageElements[index]?.index && el.group !== element.value.group
		);
		const belowElement = [...pageElements]
			.reverse()
			.find((el) => el.index < pageElements[index]?.index && el.group !== element.value.group);

		const indexes = getIndicesBetween(belowElement?.index, aboveElement?.index, sortedElements.length);

		sortedElements.forEach((el, i) => {
			el.index = indexes[i];
		});

		return sortedElements;
	};

	const unlockGroup = (elements: Element[]) => {
		const elementSelected = cloneDeep(selection.value[0]);
		Bugsnag.leaveBreadcrumb(`Unlock group: ${elements.map((el) => ` ${el.type}-${el.id}`)}`);
		elements.forEach((el) => {
			el.group = null;
			if (el instanceof Box) {
				el.keepProportions = false;
			}
		});

		if (elementSelected) {
			selectionId.value = [elementSelected.id];
		}
	};

	const removeFromGroup = () => {
		// If group has only 2 elements or less delete all group
		if (lockedGroup.value.filter((element) => element.group).length <= 2) {
			lockedGroup.value.forEach((element) => (element.group = null));
		} else {
			element.value.group = null;
		}
	};

	return {
		group,
		isGrouped,
		lockedGroup,
		isLockGroup,
		lockGroup,
		removeFromGroup,
		setOpacity,
		unlockGroup,
	};
};
