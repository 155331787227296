<script lang="ts" setup>
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { useAiImage } from '@/elements/medias/images/image/composables/useAiImage';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { ImageApi } from '@/Types/apiClient';
import { AiViewerMode } from '@/Types/types';

defineProps<{
	hideTryPrompt?: boolean;
	viewerMode?: AiViewerMode | null;
}>();
const emit = defineEmits<{
	(e: 'close'): void;
	(e: 'select', value: ImageApi): void;
	(e: 'tryprompt'): void;
}>();

const { trans } = useI18n();

const store = useMainStore();
const {
	AIstyle,
	loading,
	ratio,
	results,
	selectedImage,
	selectedRatio,
	getPromptStyleToReplace,
	replaceAiImage,
	select,
} = useAiImage();
const { addInsertableImage } = useAddInsertableElement();
const { isWebview, runOnMobile } = useDeviceInfo();

const initialImage = ref(selectedImage.value);

const disableReplaceButton = computed(
	() => initialImage.value && selectedImage.value && initialImage.value.id === selectedImage.value.id
);

const finalPrompt = computed(() => {
	let result = `${selectedImage.value?.metadata.prompt}`;

	const promptStyle = getPromptStyleToReplace(result);

	if (promptStyle.newText.length) {
		result = result.replaceAll(promptStyle.style, promptStyle.newText);
	} else {
		result += `${AIstyle.value}`;
	}

	result += `, ${selectedRatio.value}.`;

	return result;
});

const onClickSelectImage = (selImage: ImageApi) => {
	selectedImage.value = selImage;
};

const onCloseModal = () => {
	emit('close');
};

const onClickTryThisPrompt = () => {
	if (selectedImage.value?.id) {
		GAnalytics.track('click', 'AI', `try-this-prompt_${selectedImage.value.id}`, null);
	}

	emit('tryprompt');
};

const onClickInsertImage = async () => {
	if (!selectedImage.value) {
		return;
	}

	await select(selectedImage.value);

	await addInsertableImage(selectedImage.value);
	onSelect(selectedImage.value);

	GAnalytics.track('click', 'ai', `include-image-visor_${selectedImage.value.id}`);

	runOnMobile(() => (store.activePanel = null));
};

const onClickReplaceImage = async () => {
	replaceAiImage();

	if (selectedImage.value?.id) {
		GAnalytics.track('click', 'AI', `replace-image-${selectedImage.value.id}`);
	}

	emit('close');
};

const onSelect = (image: ImageApi) => {
	emit('select', image);
	emit('close');
};

const onClickDownload = (image: ImageApi | undefined) => {
	if (image?.id) {
		GAnalytics.track('Click', 'AI', `download-visor_${image.id}`);
	}
};
</script>

<template>
	<Modal open content-classes="p-0 w-full max-h-screen" @close="emit('close')">
		<div class="-mx-6 flex h-screen w-screen flex-col items-center justify-center bg-gray-900/80 backdrop-blur">
			<div class="flex h-full w-full max-w-screen-xl flex-col px-6 pb-6 pt-12 lg:h-auto lg:p-6">
				<button class="absolute right-2 top-2 rounded p-2 text-white hover:bg-gray-100/25" @click="onCloseModal">
					<SvgIcon name="cross" class="h-5 w-5" />
				</button>
				<div class="flex h-full flex-col gap-8 lg:flex-row">
					<!-- Left side -->
					<div class="flex-1">
						<!-- Images -->
						<div class="flex flex-col gap-3">
							<div class="aspect-square overflow-hidden rounded-lg bg-gray-800">
								<img
									:src="selectedImage?.url"
									:alt="`${selectedImage?.metadata.prompt}-selected`"
									class="h-full w-full object-contain"
								/>
							</div>
							<div v-if="loading && results.length > 1" class="flex h-[128px] w-full items-center justify-center">
								<SvgIcon name="spinner" class="loading h-10 w-10 animate-spin fill-white" />
							</div>
							<div v-else-if="results.length > 1" class="grid w-full grid-cols-4 gap-3">
								<button
									v-for="(img, index) in results"
									:key="`imgbutton-${index})`"
									:data-testid="`select-image-preview-${index + 1}`"
									class="aspect-square overflow-hidden rounded-lg border-2 bg-gray-800"
									:class="{
										'border-transparent opacity-40': selectedImage?.preview !== img.preview,
										'border-blue-500 slidesgo:border-purple-400': selectedImage?.preview === img.preview,
									}"
									@click="onClickSelectImage(img)"
								>
									<img
										:key="img.id"
										class="h-full w-full object-contain"
										:src="img.preview"
										:alt="`${img?.metadata.prompt}-${index}`"
									/>
								</button>
							</div>
							<!-- End Images-->
						</div>
					</div>
					<!-- End Left side -->
					<!-- Right Side -->
					<div class="flex h-full flex-1 flex-col">
						<!-- Description block -->
						<div>
							<div class="flex flex-col gap-4 text-white">
								<h2 class="hidden text-2xl text-gray-100 lg:block">{{ trans('Image details') }}</h2>
								<div>
									<h5 class="hidden text-xl font-bold lg:block">{{ trans('Prompt') }}</h5>
									<p class="text-lg lg:text-xl">{{ finalPrompt }}</p>
								</div>
								<button
									v-if="!hideTryPrompt"
									class="mr-auto flex h-10 items-center justify-center gap-3 rounded border border-white px-4 font-semibold hover:bg-white hover:text-gray-900"
									@click="onClickTryThisPrompt"
								>
									<SvgIcon name="note" class="h-4 w-4" />
									{{ trans('Try this prompt') }}
								</button>
								<span class="text-xl text-gray-300">{{ trans(`Dimensions: ${ratio.width}x${ratio.height}`) }}</span>
							</div>
						</div>
						<!-- Buttons -->
						<div class="mb-3 mt-auto flex gap-4 text-white lg:mb-0 lg:flex-col">
							<a
								v-if="selectedImage && !isWebview"
								:href="selectedImage.url"
								class="flex w-full items-center justify-center gap-2 rounded bg-gray-50 p-4 text-gray-900"
								@click="onClickDownload(selectedImage)"
							>
								<SvgIcon name="download" class="h-4 w-4" /> {{ trans('Download') }}
							</a>

							<button
								v-if="viewerMode !== AiViewerMode.replace"
								class="flex w-full items-center justify-center gap-2 rounded bg-green-500 p-4"
								data-testid="ai-visor-include-in-design"
								@click="onClickInsertImage"
							>
								<SvgIcon name="photo" class="h-4 w-4" />
								<span class="hidden lg:block">{{ trans('Include in my design') }}</span>
								<span class="lg:hidden">{{ trans('Include') }}</span>
							</button>
							<button
								v-if="viewerMode === AiViewerMode.replace && !loading && results.length > 1"
								class="flex w-full items-center justify-center gap-2 rounded bg-green-500 p-4"
								:class="{
									'pointer-events-none bg-green-800/70 text-gray-100/70': disableReplaceButton,
								}"
								@click="onClickReplaceImage"
							>
								<SvgIcon name="replace" class="h-4 w-4" />
								<span class="hidden lg:block">{{ trans('Replace image') }}</span>
								<span class="lg:hidden">{{ trans('Replace') }}</span>
							</button>
						</div>
						<!-- End Buttons -->
					</div>
					<!-- End Right Side -->
				</div>
			</div>
		</div>
	</Modal>
</template>
<style lang="sass" scoped></style>
