<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getStorysets } from '@/api/DataApiClient';
import AutocompleteInput from '@/common/components/AutocompleteInput.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import StorysetStyles from '@/elements/storyset/components/menus/StorysetStyles.vue';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useProjectStore } from '@/project/stores/project';
import { InsertableApiType, StorysetsStyles } from '@/Types/types';

const { addInsertableStoryset } = useAddInsertableElement();

const { trans } = useI18n();
const emit = defineEmits(['elementAdded']);
const project = useProjectStore();

// Constants
const URL_CONFIG = {
	ORDER: 'recent',
	LIMIT: 30,
	PAGE: 1,
};

// Data
const search = ref('');

const style = ref<StorysetsStyles | ''>('');

const url = ref('');

// Computed
const autocompleteSource = computed(() => `autocomplete/stories?search=${search.value}`);

const source = computed(
	() =>
		`https://stories.freepiklabs.com/api/vectors?order=${URL_CONFIG.ORDER}&limit=${URL_CONFIG.LIMIT}&page=${
			URL_CONFIG.PAGE
		}&query=${search.value || project.flaticonSearch || project.category || ''}&style=${style.value}`
);

const query = computed(() => project.flaticonSearch || project.category || '');

const { data: storysets, isFetching } = getStorysets(url, { immediate: false, refetch: true });

watch(
	source,
	(newVal) => {
		if (!newVal) return;
		url.value = newVal;
	},
	{ immediate: true }
);

watch(storysets, (newVal) => {
	if (!newVal) return;
	if (newVal.data.length === 0) {
		GAnalytics.trackGA4('search_editor', {
			category: 'storyset',
			search_term: `${search.value}`,
			zero_results: `true`,
		});

		return;
	}

	GAnalytics.trackGA4('search_editor', {
		category: 'storyset',
		search_term: `${search.value}`,
		zero_results: `false`,
	});
});

watch(isFetching, (newVal, oldVal) => {
	if (newVal || !oldVal) return;
	if (!storysets.value?.data.length && !search.value) {
		url.value = `https://stories.freepiklabs.com/api/vectors?order=${URL_CONFIG.ORDER}&limit=${URL_CONFIG.LIMIT}&page=${URL_CONFIG.PAGE}&query=&style=${style.value}`;
	}
});

const addStoryset = async (url: string) => {
	await addInsertableStoryset(url);
	emit('elementAdded');
};

const loadMore = () => {
	if (isFetching.value || !storysets.value?.links?.next) return;
	url.value = storysets.value.links.next;
};

const onSetStyle = (val: StorysetsStyles | '') => (style.value = val);

const updateSearchValue = (searchValue: string) => {
	search.value = searchValue;

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in Storyset panel: ${searchValue}`);
};
</script>

<template>
	<div class="flex h-full flex-col">
		<AutocompleteInput
			:placeholder="trans(query ? query : 'Search Illustration...')"
			:query="search"
			:autocomplete-source="autocompleteSource"
			@change="updateSearchValue"
		/>

		<StorysetStyles :style="style" @setStyle="onSetStyle" />

		<InfiniteLoading
			:data="storysets?.data || []"
			:is-fetching="isFetching"
			:container-classes="'grid grid-cols-2 gap-2'"
			@load="loadMore"
		>
			<template #item="{ item }">
				<InsertableElement
					v-if="'src' in item && 'preview' in item && 'illustration' in item"
					:data="item"
					:type="InsertableApiType.Storyset"
					:draggable="true"
					class="flex aspect-square w-full overflow-hidden rounded bg-gray-700 hover:bg-gray-600"
					data-testid="storyset"
					@click="addStoryset(item.src)"
				>
					<img :src="item.preview" :alt="item.illustration.name" class="h-full w-full object-contain p-2" />
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
