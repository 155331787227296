import { Dom, Element as SVGJSElement } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';

import { SolidColor } from '@/color/classes/SolidColor';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { SlideTextGradient } from '@/loader/slidesgo/slidesgoTemplateLoaderTypes';
import { Color } from '@/Types/colorsTypes';
import { FontWeight, TextAlign } from '@/Types/elements';

export class SlidesgoTextParser {
	static init(wrapper: SVGJSElement, textGradients: SlideTextGradient[]) {
		const textShape = this.getTextShape(wrapper) as SVGJSElement;
		const box = textShape && (this.getTextBox(textShape) as SVGJSElement);
		const content = box && this.getTextContent(textShape);

		if (!textShape || !box || !content) return;

		const rotation = this.getTextRotation(textShape);
		const textAlign = this.getTextAlign(box, textShape);

		const { colors, fontFamily, nearestWeight, fontStyle, fontSize, letterSpacing, textShadow, outline } =
			TextTools.extractFontDataFromText(wrapper);

		// Get textShape box
		const { x, y, width, height } = textShape.rbox();

		// Get line height
		const positionsY = textShape.find('tspan[y]').map((tspan) => parseFloat(tspan.attr('y') as string));
		const { lineHeight } = TextTools.getLineHeightBasedOnLinesPosY(positionsY, fontSize);

		// Set position
		const position = {
			x,
			y,
		};

		// Set size
		const size = {
			width: Math.ceil(width), // Render issue on Chrome
			height,
		};

		const newText = Text.create({
			content,
			colors: [...colors, ...textGradients.map((gradient) => gradient.gradient)],
			fontFamily,
			fontSize,
			fontStyle,
			fontWeight: nearestWeight.weight,
			letterSpacing,
			lineHeight,
			outline,
			position,
			rotation,
			size,
			textAlign,
			textShadow: Array.isArray(textShadow) ? textShadow : [textShadow],
			metadata: {
				autofix: {
					box: true,
				},
			},
		});

		// Apply gradients from pptxData
		const provisionalTextNode = document.createElement('div');
		provisionalTextNode.innerHTML = content;
		textGradients.forEach((textGradient) => {
			const gradient = textGradient.gradient;
			const text = textGradient.text;
			const span = Array.from(provisionalTextNode.querySelectorAll('span')).find((el) =>
				el.textContent?.includes(text)
			);
			if (!span) return;
			span.style.setProperty(`--${gradient.id}`, gradient.toCssString());
			TextTools.applyColorVarToTextNode(span, gradient);
			newText.content = provisionalTextNode.innerHTML;
		});

		newText.content = TextTools.parseTextColorsToCssVars(newText);

		return newText;
	}

	static isText(wrapper: SVGJSElement) {
		const textContent = wrapper.node.textContent;
		const hasContent = !!textContent?.trim().length;
		const hasTextNode = !!wrapper.findOne('.SVGTextShape, .TextShape');
		return hasContent && hasTextNode;
	}

	// Given a box and an angle, get the inner box rotated the given angle which fits inside the given box
	static getInnerBox(box: SVGJSElement, angle: number) {
		const alpha = (angle * Math.PI) / 180;
		const cos = Math.cos(alpha);
		const sin = Math.sin(alpha);

		const W = box.width() as number;
		const H = box.height() as number;

		const width = Math.abs((W * cos - H * sin) / (cos ** 2 - sin ** 2));
		const height = Math.abs((H * cos - W * sin) / (cos ** 2 - sin ** 2));

		// calculate the coordinates of the center of the original box
		const centerX = W / 2;
		const centerY = H / 2;

		// calculate the coordinates of the center of the rotated box
		const rotatedCenterX = width / 2;
		const rotatedCenterY = height / 2;

		// calculate the distance between the center of the original box and the center of the rotated box
		const distanceX = rotatedCenterX - centerX;
		const distanceY = rotatedCenterY - centerY;

		// apply the rotation to the distance to get the offset
		const x = distanceX * cos + distanceY * sin;
		const y = distanceY * cos - distanceX * sin;

		return {
			x,
			y,
			width,
			height,
		};
	}

	static getTextShape(wrapper: SVGJSElement) {
		const textShape = wrapper.findOne('.TextShape') || wrapper.findOne('.SVGTextShape');
		if (!textShape) {
			console.warn('getTextShape could not find TextShape to parse');
		}
		return textShape;
	}

	static getTextBox(textShape: Dom) {
		const box = textShape.parent()?.findOne('rect');
		if (!box) {
			console.warn('getTextBox could not find rect (BoundingBox) to parse:', textShape.node.textContent);
		}
		return box;
	}

	static getTextContent(textShape: SVGJSElement) {
		const diffsY = textShape
			.find('.TextPosition[y]')
			.map((tspanY, i, arr) => {
				if (!i) return;
				return Math.abs(parseFloat(tspanY.attr('y')) - parseFloat(arr[i - 1].attr('y')));
			})
			.filter(Boolean);

		// Get the number in diffsY that appears the most
		const mostRepeatedDiff = diffsY.reduce((acc, curr) => {
			if (acc[curr]) {
				acc[curr]++;
			} else {
				acc[curr] = 1;
			}
			return acc;
		}, {} as { [key: string]: number });

		const mostRepeatedDiffValue = Math.max(...Object.values(mostRepeatedDiff));

		let mostRepeatedDiffKey: number | string | undefined = Object.keys(mostRepeatedDiff).find(
			(key) => mostRepeatedDiff[key] === mostRepeatedDiffValue
		);
		mostRepeatedDiffKey = mostRepeatedDiffKey ? parseFloat(mostRepeatedDiffKey) : 0;

		// Set fixed content
		let contents: string[] = [];

		textShape.children().forEach((child: SVGJSElement) => {
			if (child.hasClass('TextParagraph')) {
				// If diff between first line of this paragraph and last line of previous paragraph is bigger than
				// the most repeated diff, add a line break before this paragraph
				const firstTextPosition = child.findOne('.TextPosition');
				const firstTextPositionY = firstTextPosition ? parseFloat(firstTextPosition.attr('y') as string) : 0;

				const prevTextPosition = textShape
					.find('.TextPosition')
					.filter((tspan) => parseFloat(tspan.attr('y') as string) < firstTextPositionY);
				const sortedPrevTextPositions = prevTextPosition.length
					? prevTextPosition.sort((a, b) => parseFloat(b.attr('y') as string) - parseFloat(a.attr('y') as string))
					: [];

				let shouldAddLineBreakBefore = false;
				if (sortedPrevTextPositions.length) {
					const lastPrevTextPositionY = parseFloat(sortedPrevTextPositions[0].attr('y') as string);
					shouldAddLineBreakBefore =
						Math.abs(lastPrevTextPositionY - firstTextPositionY) > (mostRepeatedDiffKey as number);
				}

				// If next item is a list item, add a line break after this paragraph
				const shouldAddLineBreakAfter = !!child.next()?.hasClass('ListItem');

				const line =
					(shouldAddLineBreakBefore ? '<div><br/></div>' : '') +
					this.parseTextParagraph(child, mostRepeatedDiffKey as number) +
					(shouldAddLineBreakAfter ? '<div><br/></div>' : '');
				contents.push(line);
			}

			if (child.hasClass('ListItem')) {
				contents = this.parseListItem(child, contents, mostRepeatedDiffKey as number);
			}
		});

		return contents.join('');
	}

	static parseListItem(listItem: SVGJSElement, contents: string[], mostRepeatedDiff: number) {
		const listItemTag = listItem.attr('ooo-numbering-type') === 'number-style' ? 'ol' : 'ul';

		// Open list if needed
		const shouldOpenList =
			!contents.length ||
			(!contents[contents.length - 1].startsWith(`<div><${listItemTag}`) &&
				!contents[contents.length - 1].startsWith('<li style="'));
		if (shouldOpenList) {
			const fontFamily = listItem.attr('font-family');
			const fontSize = parseFloat(listItem.attr('font-size'));
			const listStyleType = listItemTag === 'ol' ? 'decimal' : 'disc';
			const marginLeft = fontSize ? fontSize * 1.2 : 0;
			const styles = `style="font-family: ${fontFamily}; font-size: ${fontSize}px; list-style-type: ${listStyleType}; margin-left: ${marginLeft}px;"`;
			contents.push(`<div><${listItemTag} ${styles}>`);
		}

		// Add list item
		const listItemTextPositions = listItem.find('.TextPosition');
		listItemTextPositions.shift();
		let listItemContent = this.parseTextPositions(listItemTextPositions, mostRepeatedDiff);
		const style = listItemContent.split('style="')[1]?.split('"')[0] || '';
		listItemContent = listItemContent.replace(`style="${style}"`, '');
		contents.push(`<li style="${style}">${listItemContent}</li>`);

		// Close list if needed
		const next = listItem.next();
		const nextTextPostions = next && next.find('.TextPosition');
		const nextListItemX = nextTextPostions?.length && nextTextPostions[0]?.attr('x');
		const currentListIitemX = listItem.find('.TextPosition')[0]?.attr('x');
		const shouldCloseList =
			!nextTextPostions || (!!nextListItemX && !!currentListIitemX && nextListItemX !== currentListIitemX);
		if (shouldCloseList) {
			contents.push(`</${listItemTag}></div>`);
		}

		return contents;
	}

	static parseTextParagraph(textParagraph: SVGJSElement, mostRepeatedDiff: number) {
		const textPositions = textParagraph.find('.TextPosition');
		const textLine = this.parseTextPositions(textPositions, mostRepeatedDiff);
		const textParagraphStyle = this.getStylesFromSVGJSElement(textParagraph);
		return textParagraphStyle ? `<div style="${textParagraphStyle}">${textLine}</div>` : `<div>${textLine}</div>`;
	}

	static parseTextPositions(textPositions: SVGJSElement[], mostRepeatedDiff: number) {
		return textPositions
			.map((tp, i, arr) => {
				const shouldAddLineBreak =
					i && Math.abs(parseFloat(tp.attr('y')) - parseFloat(arr[i - 1].attr('y'))) > mostRepeatedDiff;
				const textPostionStyle = this.getStylesFromSVGJSElement(tp);
				let line = textPostionStyle
					? `<span style="${textPostionStyle}">${this.getStylizedTextLine(tp)}</span>`
					: this.getStylizedTextLine(tp);
				if (shouldAddLineBreak) {
					line = `<div><br/></div>${line}`;
				}
				return line;
			})
			.join('');
	}

	static getStylesFromSVGJSElement(element: SVGJSElement) {
		const attributesToGet = ['fill', 'font-family', 'font-size', 'font-style', 'font-weight', 'line-height'];
		const attributes = element.attr();

		const styles: any = {};
		Object.entries(attributes).forEach(([key, val]) => {
			if (!attributesToGet.includes(key)) return;
			if (key === 'fill') {
				styles.color = val;
			} else {
				styles[key] = val;
			}
		});

		let joinedStyles = '';
		Object.entries(styles).forEach(([key, val]) => {
			joinedStyles += `${key}: ${val};`;
		});

		return joinedStyles;
	}

	static getStylizedTextLine(textPosition: Dom | SVGJSElement) {
		if (!textPosition.node.textContent) return '';
		const { textContent } = textPosition.node;
		const tspans = textPosition.find('tspan');
		if (tspans.length === 1) {
			const styles = this.getStylesFromSVGJSElement(tspans[0]);
			return styles ? `<span style="${styles}">${textContent}</span>` : textContent;
		}
		let stylizedLine = tspans
			.map((ts: SVGJSElement, i: number) => {
				const styles = this.getStylesFromSVGJSElement(ts);
				const addSpace = i && !tspans[i - 1].node.textContent?.trim().length ? ' ' : '';
				return styles
					? `<span style="${styles}">${addSpace + ts.node.textContent}</span>`
					: addSpace + ts.node.textContent;
			})
			.join('');

		textPosition.find('a').forEach((link: SVGJSElement) => {
			if (!link.node.textContent) return;
			const styles = this.getStylesFromSVGJSElement(link) + 'text-decoration: underline;';
			const anchor = styles
				? `<a href="${link.attr('xlink:href')}" target="_blank" style="${styles}">${link.node.textContent}</a>`
				: `<a href="${link.attr('xlink:href')}" target="_blank">${link.node.textContent}</a>`;
			stylizedLine = stylizedLine?.replace(link.node.textContent, anchor);
		});

		return stylizedLine;
	}

	static getTextColors(textShape: Dom) {
		let color: Color = SolidColor.black();
		let colors: Color[] = [color];
		const fills = textShape.find('tspan[fill]')?.map((tspan) => tspan.attr('fill'));
		if (fills.length) {
			const filtered = new Set(fills);
			colors = Array.from(filtered).map((rgba) => {
				const [r, g, b, a] = Normalize(rgba as string);
				return new SolidColor(r * 255, g * 255, b * 255, a);
			});
			color = colors[0];
		}
		return { color, colors };
	}

	static getTextFontFamily(textNode: Dom) {
		if (!textNode) return Text.defaults().fontFamily;

		const fontFamilies =
			textNode.attr('font-family') ||
			textNode.findOne('[font-family]')?.attr('font-family') ||
			Text.defaults().fontFamily;

		return fontFamilies.includes(',') ? fontFamilies.split(',')[0] : fontFamilies;
	}

	static getTextFontSize(textNode: Dom) {
		const fontSizes = textNode?.find('tspan[font-size]').map((tspan) => tspan.attr('font-size'));
		const mostCommonFontSize = fontSizes?.reduce(
			(a, b, i, arr) => (arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length ? a : b),
			null
		);
		return mostCommonFontSize ? parseFloat(mostCommonFontSize) : Text.defaults().fontSize;
	}

	static getTextFontStyle(textNode: Dom) {
		return textNode ? parseFloat(textNode.attr('font-style')) : Text.defaults().fontStyle;
	}

	static getTextFontWeight(textNode: Dom) {
		const fontWeights = textNode?.find('tspan[font-weight]').map((tspan) => tspan.attr('font-weight'));
		const mostCommonFontWeight = fontWeights?.reduce(
			(a, b, i, arr) => (arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length ? a : b),
			null
		);
		return mostCommonFontWeight ? (parseInt(mostCommonFontWeight) as FontWeight) : Text.defaults().fontWeight;
	}

	static getTextAlign(box: SVGJSElement, textShape: Dom): TextAlign {
		const transform = textShape.attr('transform');
		if (transform && transform.includes('rotate')) textShape.attr('transform', '');
		const textPositionsX: number[] = [];
		const textPositionsX2: number[] = [];
		const textPositionsY: number[] = [];
		const textPositionsY2: number[] = [];
		const textPositions = textShape.find('.TextPosition').filter((tp: SVGJSElement) => !tp.parent('.ListItem'));
		if (!textPositions.length) return 'center';

		textPositions.forEach((tp) => {
			const { x, x2, y, y2 } = tp.bbox();
			textPositionsX.push(x);
			textPositionsX2.push(x2);
			textPositionsY.push(y);
			textPositionsY2.push(y2);
		});

		return textPositions.length === 1
			? this.getTextAlignSingleLine(box, textPositions[0])
			: this.getTextAlignMultiLine(textPositionsX, textPositionsX2);
	}

	static getTextAlignMultiLine(textPositionsX: number[], textPositionsX2: number[]): TextAlign {
		const isAlignToLeft = textPositionsX
			.map((x, i) => {
				if (i) return Math.round(x - textPositionsX[i - 1]);
			})
			.filter(Boolean)
			.every((result) => Math.abs(result) < 5);

		const isAlignToRight = textPositionsX2
			.map((x, i) => {
				if (i) return Math.round(x - textPositionsX2[i - 1]);
			})
			.filter(Boolean)
			.every((result) => Math.abs(result) < 5);

		if (!isAlignToLeft && !isAlignToRight) return 'center';
		if (isAlignToLeft && isAlignToRight) return 'justify';
		if (isAlignToLeft) return 'left';
		if (isAlignToRight) return 'right';
		return 'center';
	}

	static getTextAlignSingleLine(box: SVGJSElement, textPosition: SVGJSElement): TextAlign {
		const bBox = box.bbox();
		const tBox = textPosition.bbox();
		const diffLeft = Math.abs(bBox.x - tBox.x);
		const diffRight = Math.abs(bBox.x2 - tBox.x2);
		const factorLeft = bBox.width / diffLeft;
		const factorRight = bBox.width / diffRight;
		const factor = Math.round(factorLeft / factorRight);
		if (factor > 1) return 'left';
		if (factor === 1) return 'center';
		if (factor === 0) return 'right';
		return 'center';
	}

	static getTextRotation(textShape: SVGJSElement) {
		let rotation = 0;
		const transform = textShape.attr('transform');
		if (transform?.includes('rotate')) {
			const value = transform.split('rotate(')[1].split(')')[0];
			rotation = Math.round(value.includes(' ') ? value.split(' ')[0] : value);
			textShape.rotate(-rotation);
		}
		return rotation;
	}
}
