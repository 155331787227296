<script lang="ts" setup>
import { ref } from 'vue';

import { useAdminActions } from '@/admin/composables/useAdminActions';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import Switch from '@/common/components/Switch.vue';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
const emits = defineEmits(['close', 'save']);

const project = useProjectStore();
const { duplicateTemplate } = useAdminActions();
const { trans } = useI18n();

const maxAllowedPages = 60;

const closeModal = () => {
	emits('close');
};

const name = ref(project.name || '');
const flaticonSearch = ref(project.flaticonSearch || '');

const keepTags = ref(true);
const samePack = ref(true);
const copyVariants = ref(true);

const saving = ref(false);

const errors = ref(new Set<string>());

const createCopy = async () => {
	errors.value.clear();

	if (!name.value) {
		errors.value.add('name');
		return;
	}

	saving.value = true;
	await duplicateTemplate(name.value, flaticonSearch.value, keepTags.value, samePack.value, copyVariants.value);
	saving.value = false;
	emits('save');
	closeModal();
};
</script>

<template>
	<Modal open content-classes="w-full flex items-center justify-center" v @close="closeModal()">
		<div
			:class="saving ? 'pointer-events-none' : 'pointer-events-auto'"
			class="relative flex max-h-full w-full select-none flex-col rounded-lg bg-white py-4 px-4 lg:w-full lg:min-w-lg lg:max-w-2xl lg:py-6 lg:px-8"
		>
			<h2 class="text-xl font-bold text-gray-700">Save as copy</h2>
			<form class="mt-4 flex flex-col gap-4" @submit.prevent="createCopy">
				<div>
					<label class="flex flex-col">
						<span class="mb-1 text-gray-500">Template name</span>
						<input
							v-model="name"
							type="text"
							autocomplete="off"
							class="rounded border border-gray-100/50 bg-white p-2 text-gray-700"
						/>
						<span v-if="errors.has('name')" class="text-xs text-red-600">The name cannot be empty</span>
					</label>
				</div>
				<div>
					<label class="flex flex-col">
						<span class="mb-1 text-gray-500">Flaticon search</span>
						<input
							v-model="flaticonSearch"
							type="text"
							class="rounded border border-gray-100/50 bg-white p-2 text-gray-700"
						/>
					</label>
				</div>
				<div class="flex gap-6">
					<label class="flex flex-col">
						<Switch v-model="keepTags">Copy tags</Switch>
					</label>
					<label class="flex flex-col">
						<Switch v-model="samePack">Same pack</Switch>
					</label>
					<label class="flex flex-col">
						<Switch v-model="copyVariants">Copy variants</Switch>
					</label>
				</div>

				<div class="mt-4 flex justify-between">
					<button
						v-if="!saving"
						class="rounded bg-gray-100/50 px-4 py-2 font-bold text-gray-700 hover:text-red-600"
						@click="closeModal"
					>
						Cancel
					</button>
					<button
						class="ml-auto rounded bg-yellow-500 px-4 py-2 font-bold text-white hover:bg-yellow-600"
						type="submit"
					>
						<span v-if="saving" class="flex">
							<SvgIcon name="spinner" class="mr-2 h-6 w-6 animate-spin" /> Saving...
						</span>
						<span v-else>Create copy</span>
					</button>
				</div>
			</form>
		</div>
	</Modal>
</template>
