import { ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { RectBox } from '@/Types/types';

type Align = 'left' | 'center' | 'right' | 'top' | 'middle' | 'bottom';

export const useGroupAlign = () => {
	const { selection } = useSelection();

	const hAlignment = ref('');
	const vAlignment = ref('');
	const temporalRef = ref<Element>(Shape.create());

	const { getGroupIdsInPage } = useActivePage();
	const usingTransformOrchestrator = useElementTransformOrchestrator(temporalRef);

	const alignHandler = (align: Align) => {
		const selectionBox = getGroupBox();

		const ungroupedElements = selection.value.filter((el) => !el.group);
		ungroupedElements.forEach((el) => {
			alignUngroupedElement(align, el, selectionBox);
		});

		const groups = getGroupsInSelection();

		groups.forEach((g) => {
			const groupBox = getGroupBox(g);

			if (groups.length === 1 && !ungroupedElements.length) {
				g.forEach((el) => {
					alignUngroupedElement(align, el, groupBox);
				});
				return;
			}

			g.forEach((el) => {
				alignGroup(align, el, groupBox, selectionBox);
			});
		});

		const { h, v } = getAlignment();
		hAlignment.value = h;
		vAlignment.value = v;
	};

	const alignGroup = (align: Align, el: Element, groupBox: RectBox, selectionBox: RectBox) => {
		if (align === 'left')
			el.position.x +=
				groupBox.left >= selectionBox.left ? selectionBox.left - groupBox.left : groupBox.left - selectionBox.left;
		if (align === 'center')
			el.position.x +=
				groupBox.center >= selectionBox.center
					? -Math.abs(groupBox.center - selectionBox.center)
					: Math.abs(groupBox.center - selectionBox.center);
		if (align === 'right') el.position.x -= groupBox.right - selectionBox.right;
		if (align === 'top')
			el.position.y +=
				groupBox.top >= selectionBox.top ? selectionBox.top - groupBox.top : groupBox.top - selectionBox.top;
		if (align === 'middle')
			el.position.y +=
				groupBox.middle >= selectionBox.middle
					? -Math.abs(groupBox.middle - selectionBox.middle)
					: Math.abs(groupBox.middle - selectionBox.middle);
		if (align === 'bottom') el.position.y -= groupBox.bottom - selectionBox.bottom;
	};

	const alignUngroupedElement = (align: Align, el: Element, selectionBox: RectBox) => {
		temporalRef.value = el;
		const { left, top, widthWithRotation, heightWithRotation } = usingTransformOrchestrator.value;
		if (align === 'left') el.position.x = left.value + selectionBox.left;
		if (align === 'center') el.position.x = left.value + selectionBox.center - widthWithRotation.value / 2;
		if (align === 'right') el.position.x = left.value + selectionBox.right - widthWithRotation.value;
		if (align === 'top') el.position.y = top.value + selectionBox.top;
		if (align === 'middle') el.position.y = top.value + selectionBox.middle - heightWithRotation.value / 2;
		if (align === 'bottom') el.position.y = top.value + selectionBox.bottom - heightWithRotation.value;
	};

	const getBoxes = () => {
		const ungroupedBoxes = selection.value
			.filter((el) => !el.group)
			.map((el) => {
				temporalRef.value = el;
				const { elementBoxWithRotation } = usingTransformOrchestrator.value;
				return elementBoxWithRotation.value;
			});

		const groupBoxes = getGroupsInSelection().map((g) => getGroupBox(g));

		if (groupBoxes.length === 1 && !ungroupedBoxes.length) {
			return selection.value.map((el) => {
				temporalRef.value = el;
				const { elementBoxWithRotation } = usingTransformOrchestrator.value;
				return elementBoxWithRotation.value;
			});
		}

		return [...ungroupedBoxes, ...groupBoxes];
	};

	const getGroupsInSelection = () =>
		getGroupIdsInPage()
			.filter((g) => selection.value.some((el) => el.group === g))
			.map((g) => selection.value.filter((el) => el.group === g));

	const getAlignment = () => ({
		h: getHAlignment(),
		v: getVAlignment(),
	});

	const getHAlignment = () => {
		const boxes = getBoxes();
		const selectionBox = getGroupBox();

		if (boxes.every((box) => Math.abs(box.left - selectionBox.left) < 1)) return 'left';
		if (boxes.every((box) => Math.abs(box.center - selectionBox.center) < 1)) return 'center';
		if (boxes.every((box) => Math.abs(box.right - selectionBox.right) < 1)) return 'right';
		return '';
	};

	const getVAlignment = () => {
		const boxes = getBoxes();
		const selectionBox = getGroupBox();

		if (boxes.every((box) => Math.abs(box.top - selectionBox.top) < 1)) return 'top';
		if (boxes.every((box) => Math.abs(box.middle - selectionBox.middle) < 1)) return 'middle';
		if (boxes.every((box) => Math.abs(box.bottom - selectionBox.bottom) < 1)) return 'bottom';
		return '';
	};

	const getGroupBox = (group: Element[] = []): RectBox => {
		const ar = group.length ? group : selection.value;

		const boxes = ar.map((el) => {
			temporalRef.value = el;
			const { elementBoxWithRotation } = usingTransformOrchestrator.value;
			return elementBoxWithRotation.value;
		});

		const tops = boxes.map((side) => side.top);
		const minTop = Math.min(...tops);

		const rights = boxes.map((side) => side.right);
		const maxRight = Math.max(...rights);

		const bottoms = boxes.map((side) => side.bottom);
		const maxBottom = Math.max(...bottoms);

		const lefts = boxes.map((side) => side.left);
		const minLeft = Math.min(...lefts);

		const center = (minLeft + maxRight) / 2;
		const middle = (minTop + maxBottom) / 2;

		return {
			top: minTop,
			right: maxRight,
			bottom: maxBottom,
			left: minLeft,
			center,
			middle,
		};
	};

	return {
		hAlignment,
		vAlignment,
		alignHandler,
		getAlignment,
	};
};
