<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { computed, ref, watch } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useProjectColors } from '@/color/composables/useProjectColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { StopGradient } from '@/Types/colorsTypes';
import { Option } from '@/Types/types';

const props = defineProps<{ palette: any; id: number }>();
const store = useMainStore();
const { isAdminMode } = useEditorMode();
const { palette } = useProjectColors();

const name = ref<string>(props.palette.name || '');
const mainColor = ref(props.palette.main_color);

watch([name, mainColor], () => {
	store.colorPalettes = store.colorPalettes.map((variant: any, key: number) => {
		if (key === props.id) {
			return {
				...variant,
				name: name.value,
				main_color: mainColor.value,
			};
		}

		return variant;
	});
});

const paletteColors = computed(() => {
	return props.palette.color_palette
		.flatMap((colorCollection: any) => {
			// Si es un degradado pillamos sus stops
			if (colorCollection.color.type) {
				return colorCollection.color.stops.map((colorStop: StopGradient) => {
					const { r, g, b, a } = colorStop;
					const color = SolidColor.fromObject({ r, g, b, a });

					return {
						label: color.toHex(),
						value: color.toHex(),
					};
				});
			}

			return [
				{
					label: colorCollection.color.toHex(),
					value: colorCollection.color.toHex(),
				},
			];
		})
		.reduce((acc: Option[], value: Option) => {
			// Eliminamos repetidos
			if (acc.find((option) => option.label === value.label)) {
				return acc;
			}

			return [...acc, value];
		}, []);
});

const updateColorPalette = () => {
	const color_palette = palette.value.map((colorCollection: any) => {
		return {
			...colorCollection,
			color: colorCollection.color.type
				? GradientColor.unserialize(colorCollection.color)
				: SolidColor.unserialize(colorCollection.color),
		};
	});

	// Si el main color ha dejado de existir usamos el más usado
	const mainColorExist = color_palette
		.flatMap((colorCollection: any) => {
			// Si es un degradado pillamos los stops
			if (colorCollection.color.type) {
				return colorCollection.color.stops.map((colorStop: StopGradient) => {
					const { r, g, b, a } = colorStop;
					const color = SolidColor.fromObject({ r, g, b, a });

					return color;
				});
			}

			return [colorCollection.color];
		})
		.find((color: SolidColor) => color.toHex() === store.colorPalettes[0].main_color);
	const mostUsedColor = color_palette
		.filter((colorCollection: any) => colorCollection.color instanceof SolidColor)
		.reduce((a, b) => (a.ids.length > b.ids.length ? a : b))
		.color.toHex();
	const main_color = mainColorExist ? mainColor.value : mostUsedColor;

	store.colorPalettes = store.colorPalettes.map((variant: any, key: number) => {
		if (key === props.id) {
			return {
				...variant,
				main_color,
				color_palette,
			};
		}

		return variant;
	});

	mainColor.value = main_color;
};

const deleteColorPalette = () => {
	store.colorPalettes = store.colorPalettes.filter((v, key) => key !== props.id);
};
</script>

<template>
	<div class="pickerContainer mb-2 flex flex-col text-sm text-gray-300">
		<div class="flex flex-col">
			<input
				v-model="name"
				class="h-full w-full appearance-none rounded bg-gray-900 py-2 pl-2 text-gray-300 focus:outline-none"
				placeholder="Variant name"
				minlength="1"
			/>

			<div class="mt-2">
				<Multiselect
					v-model="mainColor"
					mode="single"
					placeholder="Main color"
					:classes="{
						container:
							'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none py-2',
						containerActive: 'ring ring-blue-500 slidesgo:ring-purple-400 ring-opacity-30',
						tag: 'text-transparent bg-blue-500 slidesgo:bg-purple-400 font-semibold',
						singleLabel:
							'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
						optionSelected: 'text-gray-700 font-semibold opacity-100 bg-gray-100/50',
						optionSelectedPointed: 'text-black bg-blue-500 slidesgo:bg-purple-400 opacity-90',
						optionSelectedDisabled: 'text-gray-400 bg-blue-500 slidesgo:bg-purple-400 bg-opacity-50 cursor-not-allowed',
					}"
					:hide-selected="false"
					:can-clear="false"
					:can-deselect="false"
					:options="paletteColors"
				>
					<template #placeholder>
						<div class="mr-2 flex w-full flex-row items-center justify-center">
							<div class="mr-1 h-4 w-4 rounded-full bg-black"></div>
							<div class="text-xs">Main color</div>
						</div>
					</template>
					<template #singlelabel="{ value }">
						<div class="mr-2 flex w-full flex-row items-center justify-center">
							<div
								class="mr-1 h-4 w-4 rounded-full border"
								:style="{
									background: value.label,
								}"
							></div>
							<div class="text-xs uppercase">{{ value.label }}</div>
						</div>
					</template>
					<template #option="{ option }">
						<div class="mr-2 flex w-full flex-row items-center justify-center">
							<div
								class="mr-1 h-4 w-4 rounded-full border"
								:style="{
									background: option.label,
								}"
							></div>
							<div class="text-xs uppercase">{{ option.label }}</div>
						</div>
					</template>
				</Multiselect>
			</div>
		</div>

		<div class="mt-2 flex justify-center">
			<a
				v-if="isAdminMode && palette.id"
				tooltip="Open in Nova"
				tooltip-position="top"
				class="hover:text-gray-250 mr-2 rounded border-2 border-gray-600 p-2 hover:border-gray-500"
				:href="`/nova/resources/vector-variants/${palette.id}`"
				target="_blank"
			>
				<SvgIcon name="nova" class="h-4 w-4" />
			</a>

			<button
				v-if="isAdminMode"
				data-testid="update-variant-btn"
				tooltip="Update colors"
				tooltip-position="top"
				class="hover:text-gray-250 rounded border-2 border-gray-600 p-2 hover:border-gray-500"
				@click="updateColorPalette"
			>
				<SvgIcon name="reload" class="h-4 w-4" />
			</button>

			<button
				v-if="isAdminMode && id > 0"
				data-testid="remove-palette-btn"
				tooltip="Remove"
				tooltip-position="top"
				class="hover:text-gray-250 ml-2 rounded border-2 border-gray-600 p-2 hover:border-gray-500"
				@click="deleteColorPalette"
			>
				<SvgIcon name="trash" class="h-4 w-4" />
			</button>
		</div>
	</div>
</template>
