<script setup lang="ts">
import { toRef } from 'vue';

import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { useQRCodeAsSVG } from '@/elements/qr-code/composables/useQRCodeAsSVG';

const props = defineProps<{ element: QRCode }>();

const element = toRef(props, 'element');

useQRCodeAsSVG(element);
</script>

<template>
	<svg
		:style="{ opacity: element.opacity }"
		:viewBox="element.viewbox"
		width="100%"
		height="100%"
		preserveAspectRatio="none"
		v-html="element.content"
	></svg>
</template>
