import { Element as SVGJSElement } from '@svgdotjs/svg.js';
import { v4 as uuidv4 } from 'uuid';

import Line from '@/elements/line/classes/Line';
import MathTools from '@/utils/classes/MathTools';

export class SlidesgoLineParser {
	static init(wrapper: SVGJSElement) {
		return this.parseLine(wrapper);
	}

	static isLine(wrapper: SVGJSElement) {
		const isCustomShape = wrapper.classes().some((cls) => cls.endsWith('CustomShape'));
		const hasStrokeLinejoin = !!wrapper.findOne('[stroke-linejoin]');
		const splittedD = wrapper.findOne('[stroke-linejoin]')?.attr('d').split(' ') || [];
		const isLine = splittedD.length === 4 && splittedD[0] === 'M' && splittedD[2] === 'L';

		return isCustomShape && hasStrokeLinejoin && isLine;
	}

	static parseLine(wrapper: SVGJSElement) {
		const gParent = wrapper.findOne('g[id^="id"]') as SVGJSElement;
		if (!gParent) return;
		const box = gParent.findOne('.BoundingBox') as SVGJSElement;
		const paths = gParent.find('[stroke-linejoin]');
		if (!box || !paths.length) return;
		const isDashedLine = paths.length > 1;

		let x1 = 0;
		let y1 = 0;
		let x2 = 0;
		let y2 = 0;
		let strokeDashArray = '';

		if (isDashedLine) {
			const lineInit = paths[0];
			const initCoor = lineInit
				.attr('d')
				.split(' ')
				.filter((c: string) => c.includes(','))[0]
				.split(',')
				.map((c: string) => parseFloat(c));
			x1 = initCoor[0];
			y1 = initCoor[1];

			const lineEnd = paths[paths.length - 1];
			const endCoor = lineEnd
				.attr('d')
				.split(' ')
				.filter((c: string) => c.includes(','))[1]
				.split(',')
				.map((c: string) => parseFloat(c));
			x2 = endCoor[0];
			y2 = endCoor[1];

			const length = MathTools.getDistanceBetween2Points(x1, y1, x2, y2);
			if (Math.round(length / paths.length / 100) === 1) {
				strokeDashArray = '1,4';
			} else if (Math.round(length / paths.length / 100) === 2) {
				strokeDashArray = '6,3';
			} else {
				strokeDashArray = '6,6';
			}
		} else {
			const coor = paths[0]
				.attr('d')
				.split(' ')
				.filter((c: string) => c.includes(','));
			const initCoor = coor[0].split(',').map((c: string) => parseFloat(c));
			x1 = initCoor[0];
			y1 = initCoor[1];
			const endCoor = coor[1].split(',').map((c: string) => parseFloat(c));
			x2 = endCoor[0];
			y2 = endCoor[1];
		}

		const pathAsMarkers = gParent.find('path:not([stroke-linejoin])');

		const viewbox = `${box.x().toString()} ${box.y().toString()} ${box.width()} ${box.height()}`;

		let styles = '';
		const opacity = paths[0].attr('opacity');
		if (opacity) styles += `opacity: ${opacity};`;
		const stroke = paths[0].attr('stroke');
		if (stroke) styles += `stroke: ${stroke};`;
		if (strokeDashArray) styles += `stroke-dasharray: ${strokeDashArray};`;
		const strokeLinecap = paths[0].attr('stroke-linejoin');
		if (strokeLinecap) styles += `stroke-linecap: ${strokeLinecap};`;
		const strokeWidth = paths[0].attr('stroke-width');
		if (strokeWidth) styles += `stroke-width: ${strokeWidth};`;

		const line = `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" style="${styles}"></line>`;
		const markers = pathAsMarkers
			.map((marker) => {
				marker.id(`sticky-${uuidv4()}`);
				return marker.node.outerHTML;
			})
			.join('');

		const content = line + markers;
		const rawSvg = `<svg viewBox="${viewbox}">${content}</svg>`;

		const newLine = Line.fromSvg(rawSvg);
		return newLine;
	}
}
