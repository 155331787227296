import { computed, Ref } from 'vue';

import Storyset from '@/elements/storyset/classes/Storyset';
import { StorysetBackground } from '@/Types/elements';

export const useStoryset = (storyset: Ref<Storyset>) => {
	const storysetBackgrounds = computed(() => {
		const backgrounds = [];

		const backgroundSimple = storyset.value.instance.findOne(`[id*=background-simple]`);

		if (backgroundSimple) {
			backgrounds.push({
				name: 'Simple',
				isVisible: backgroundSimple.css('visibility') !== 'hidden',
			});
		}

		const backgroundComplete = storyset.value.instance.findOne(`[id*=background-complete]`);

		if (backgroundComplete) {
			backgrounds.push({
				name: 'Complete',
				isVisible: backgroundComplete.css('visibility') !== 'hidden',
			});
		}

		backgrounds.push({
			name: 'Hidden',
			isVisible: backgrounds.every((bg) => !bg.isVisible),
		});

		return backgrounds;
	});

	const currentStorysetBackground = computed(
		() => (storysetBackgrounds.value.find((bg) => bg.isVisible) as any).name as string
	);

	const changeStorysetBackground = (background: StorysetBackground) => {
		const storysetInstance = storyset.value.instance;

		storysetInstance.find('[id*=background-simple], [id*=background-complete]').forEach((el) => {
			el.css('visibility', el.id().toString().includes(background) ? 'visible' : 'hidden');
		});

		storyset.value.content = storysetInstance.node.innerHTML.toString();
	};

	return {
		storysetBackgrounds,
		currentStorysetBackground,
		changeStorysetBackground,
	};
};
