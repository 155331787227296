<script lang="ts" setup>
import { ref } from 'vue';

import { getImages } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		modal?: boolean;
	}>(),
	{
		modal: false,
	}
);

const emit = defineEmits<{
	(e: 'insert', value: ImageApi): void;
	(e: 'viewer', value: ImageApi): void;
}>();

//Data
const source = ref(`images/ai?query`);
const { data: images, isFetching } = getImages(source, { refetch: true });

// Methods
const loadMore = async () => {
	if (isFetching.value || !images.value?.links?.next) return;
	source.value = images.value.links.next;
};
</script>

<template>
	<InfiniteLoading
		:data="(images && images.data) || []"
		:is-fetching="isFetching"
		class="flex flex-col"
		with-masonry
		:masonry-cols="2"
		@load="loadMore()"
	>
		<template #item="{ item }">
			<InsertableElement
				v-if="'origin' in item"
				:data="(item as ImageApi)"
				:type="Panels.photos"
				:draggable="!props.modal"
				class="mb-2"
				@click="emit('insert', item as ImageApi)"
			>
				<ListMedia :image="(item as ImageApi)" class="group relative w-full">
					<div
						class="absolute inset-0 z-10 flex items-end rounded bg-gray-800/70 p-2 opacity-0 group-hover:opacity-100"
					>
						<button
							class="absolute right-2 top-2 rounded bg-gray-100/50 p-2 backdrop-blur-xl hover:bg-gray-100/75"
							@click="emit('viewer', item as ImageApi)"
						>
							<SvgIcon name="fullscreen" class="h-4 w-4 text-white" />
						</button>
						<p class="line-clamp-4 w-full overflow-hidden text-left text-sm leading-tight text-white"></p>
						<p class="line-clamp-4 w-full overflow-hidden text-left text-sm leading-tight text-white">
							{{ item.metadata.prompt }}
						</p>
					</div>
				</ListMedia>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
