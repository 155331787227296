import Bugsnag from '@bugsnag/browser';
import {
	createEventHook,
	MaybeRef,
	promiseTimeout,
	useEventListener,
	useIntervalFn,
	useScroll,
	useTimeoutFn,
} from '@vueuse/core';
import Moveable, {
	MoveableManagerInterface,
	OnDrag,
	OnEvent,
	OnResize,
	OnResizeEnd,
	OnResizeStart,
	OnRotate,
	Renderer,
} from 'moveable';
import { computed, nextTick, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { useElementRegardingPage } from '@/elements/element/composables/useElementRegardingPage';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { DomNodes } from '@/elements/element/dom/DomNodes';
import { DomNodesElement } from '@/elements/element/dom/DomNodesElement';
import { DomNodesMedia } from '@/elements/element/dom/DomNodesMedia';
import { DomNodesText } from '@/elements/element/dom/DomNodesText';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import Line from '@/elements/line/classes/Line';
import { useCrop } from '@/elements/medias/crop/composables/useCrop';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useSyncForeground } from '@/elements/medias/images/foreground/composables/useSyncForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { Video } from '@/elements/medias/video/classes/Video';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import EventTools from '@/interactions/classes/EventTools';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useInteractiveText } from '@/interactions/composables/useInteractiveText';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { ElementClass, InteractionAction, Position, RotationHandlerPosition, Size } from '@/Types/types';

type MaybeHtmlElement = HTMLElement | null;

const moveable = ref<Moveable | null>();
const currentRotationHandlerPosition = ref(RotationHandlerPosition.Bottom);
const action = ref<InteractionAction>(InteractionAction.Idle);
const isMiddleHandler = ref(false);
const isCreatingElement = ref(false);

const waitForMoveableAreaReady = () =>
	new Promise((resolve) => {
		const parent = document.querySelector('#portalTarget') as HTMLElement;

		const interval = useIntervalFn(() => {
			if (parent.style.transform === 'translate3d(0px, 0px, 0px)') return;
			interval.pause();
			resolve(true);
		}, 10);
	});

const lockRotationValues = [
	0, 22.5, 45, 67.5, 90, 112.5, 135, 157.5, 180, 202.5, 225, 247.5, 270, 292.5, 315, 337.5, 360,
];

const DimensionViewable = {
	name: 'dimensionViewable',
	props: [],
	events: [],
	render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
		const { pos4, rotation } = moveable.state;

		// Convertimos la rotacion del estado del moveable que está en radianes a grados
		const finalRotation = parseInt(`${rotation * (180 / Math.PI)}`);

		return React.createElement(
			'div',
			{
				key: 'custom-rotation',
				className: 'moveable-custom-able',
				style: {
					position: 'absolute',
					left: `${pos4[0]}px`,
					top: `${pos4[1] + 30}px`,
					background: 'rgb(55, 73, 87)',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: '0.375rem',
					cursor: 'move',
					color: 'white',
					fontSize: '12px',
					padding: '0.25rem 0.75rem',
				},
			},
			[`${finalRotation}º`]
		);
	},
} as const;
const dragHandlerHook = createEventHook();
const onDragHandler = dragHandlerHook.on;
export const useInteractiveElements = (elementsSelected: MaybeRef<Element[]>) => {
	const { isPinching } = useZoom();
	const { selection } = useSelection();

	const elements = ref(elementsSelected);
	const canvas = ref<MaybeHtmlElement>();
	const scrollContainer = ref<MaybeHtmlElement>();
	const isMoveableEvent = ref(false);
	const isToolbarEvent = ref(false);
	const { exitTextEditingIfPreviouslyActive } = useTextEditing();
	const maxPositionAndSize = ref({
		position: {
			x: 0,
			y: 0,
		},
		size: {
			width: 0,
			height: 0,
		},
	});
	const temporalRefPage = ref(Page.createDefault());

	const element = computed(() => elements.value[0]);
	const isReady = computed(() => element.value && !element.value.locked);
	const MARGIN_LINE_SIZE = 0.5;

	const { isMobile } = useDeviceInfo();
	const activePanel = computed(() => store.activePanel);
	const shouldNotRelocateHandler = computed(
		() =>
			isMobile.value ||
			isForeground ||
			element.value.locked ||
			isCropping.value ||
			!selection.value.length ||
			!moveable.value ||
			isSelection
	);
	const rotationHandler = ref() as Ref<MaybeHtmlElement>;
	const { removeElement } = usePage(temporalRefPage as Ref<Page>);

	const shouldKeepAspectRatio = (element: Element) => {
		const shouldKeepAspectRatioByClasses =
			![Video, Image, Text, Line, Storyset, Box].some((cls) => element instanceof cls) &&
			!(element instanceof Shape && !element.keepProportions);

		// si la imagen o video está cropeada y hacemos resize desde los handlers de las esquinas, debemos mantener la relación de aspecto
		// si no, podremos deformar la imagen al hacer resize
		const shouldKeepAspectRatioByCroppedMedia =
			(element instanceof Image || element instanceof Video) &&
			useCropInstance &&
			!isMiddleHandler.value &&
			!isCropping.value;
		return shouldKeepAspectRatioByClasses || shouldKeepAspectRatioByCroppedMedia;
	};
	const store = useMainStore();
	const project = useProjectStore();
	const { isPhotoMode, isTagMode } = useEditorMode();
	// otro orchestator para temporales
	const { page } = usePageElement(element);
	const { breadScrumbWithDebounce } = useBugsnag(element);

	const { isCropping } = useInteractions();
	const { getPageFromElement } = useProject();

	const elInitialSize: { [key: string]: Size } = {};
	const isUngroupedImage = elements.value.length === 1 && element.value instanceof Image;
	const isUngroupedVideo = elements.value.length === 1 && element.value instanceof Video;
	const isSelection = (elements.value as Element[]).length > 1 && (elements.value as Element[]).some((el) => !el.group);
	const isUngroupedText = elements.value.length === 1 && element.value instanceof Text;
	const isUngroupedCurvedText = computed(
		() => elements.value.length === 1 && element.value instanceof Text && element.value.curvedProperties.minArc
	);
	const isForeground = elements.value.length === 1 && element.value instanceof ForegroundImage;

	const isGroup = elements.value.length > 1;

	let groupIsOutside = ref();

	if (isGroup) {
		const { group } = useGroup(element);
		const { isOutsidePage } = useGroupTransform(group);
		groupIsOutside = isOutsidePage;
	}

	const { isOutsidePage } = useElementRegardingPage(element);

	const containsImage = elements.value.some((el) => el instanceof Image || el instanceof Video);

	const useCropInstance = containsImage ? useCrop(element as any as Ref<Image | Video>) : null;
	const temporalRef: Ref<unknown> = ref(Text.create());
	const {
		keepInitialValues,
		resetInitValues,
		resizeHandler: resizeHandlerText,
	} = useInteractiveText(temporalRef as Ref<Text>);

	const isValidGroupSize = (events: OnResize[]) => {
		return events.every((ev) => {
			const el = EventTools.getElementByEvent(elements.value, ev);
			if (!el) return;
			return (el instanceof Line && isCreatingElement.value) || el.isValidSize({ width: ev.width, height: ev.width });
		});
	};

	const getGuideLinesVerticalPosition = (): number[] => {
		if (!scrollContainer.value || !canvas.value) {
			return [];
		}
		const startPosition = -MARGIN_LINE_SIZE + scrollContainer.value?.scrollLeft;
		const centerPosition = parseFloat(canvas.value.style.width) / 2 + scrollContainer.value?.scrollLeft;
		const endPosition = parseFloat(canvas.value.style.width) + scrollContainer.value?.scrollLeft + MARGIN_LINE_SIZE;

		return [startPosition, centerPosition, endPosition];
	};

	const getGuideLinesHorizontalPosition = (): number[] => {
		if (!scrollContainer.value || !canvas.value) {
			return [];
		}

		const startPosition = -MARGIN_LINE_SIZE + scrollContainer.value?.scrollTop;
		const centerPosition = parseFloat(canvas.value.style.height) / 2 + scrollContainer.value?.scrollTop;
		const endPosition = parseFloat(canvas.value.style.height) + scrollContainer.value?.scrollTop + MARGIN_LINE_SIZE;

		return [startPosition, centerPosition, endPosition];
	};

	const getMoveableRenderDirections = () => {
		if (element.value.locked) return false;
		if (isForeground) return false;
		if (isUngroupedCurvedText.value) return ['nw', 'ne', 'sw', 'se'];
		if (isUngroupedText) return ['nw', 'ne', 'w', 'e', 'sw', 'se'];
		if (isGroup) return ['nw', 'ne', 'sw', 'se'];
		if (element.value instanceof Line) return false;
		// imagen y no tiene mascara con ratio
		if ((element.value instanceof Image || element.value instanceof Video) && element.value.mask?.keepRatio)
			return ['nw', 'ne', 'sw', 'se'];
		// no es imagen pero tiene ratio
		if (!(element.value instanceof Image || element.value instanceof Video) && element.value.keepProportions)
			return ['nw', 'ne', 'sw', 'se'];
		// if (page.value?.backgroundImageId === element.value.id) return false;

		return ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
	};

	const createMoveable = () => {
		const target = Array.from(document.querySelectorAll('.target')) as HTMLElement[];
		const isLine = element.value instanceof Line && selection.value.length === 1;
		const renderDirections = getMoveableRenderDirections();
		const hideDefaultLines = elements.value.length > 1 || isLine;
		const resizable = !isLine;
		// const rotatable = page.value?.backgroundImageId !== element.value.id;
		const rotatable = !element.value.locked;

		// Si es un grupo, forzamos a que se recoloque el handler en bottom
		if (isGroup) currentRotationHandlerPosition.value = RotationHandlerPosition.Bottom;
		return new Moveable(document.body, {
			target,
			elementGuidelines: Array.from(document.querySelectorAll('.ruler')),
			renderDirections,
			snappable: true,
			snapContainer: canvas.value,
			verticalGuidelines: getGuideLinesVerticalPosition(),
			horizontalGuidelines: getGuideLinesHorizontalPosition(),
			snapThreshold: 3,
			isDisplaySnapDigit: false,
			snapGap: false,
			snapDirections: { center: true, middle: true, top: true, right: true, bottom: true, left: true },
			elementSnapDirections: { top: true, right: true, bottom: true, left: true },
			hideDefaultLines,
			snapDigit: 0,
			originDraggable: false,
			draggable: true,
			resizable,
			rotatable,
			roundable: false,
			pinchable: false, // ["resizable", "scalable", "rotatable"]
			origin: true,
			keepRatio: true,
			clipTargetBounds: true,
			dragWithClip: false,
			clipArea: false,
			passDragArea: true,
			// Resize, Scale Events at edges.
			edge: false,
			rootContainer: scrollContainer.value,
			container: scrollContainer.value,
			portalContainer: document.getElementById('portalTarget'),
			rotationPosition: isForeground ? 'none' : currentRotationHandlerPosition.value,
			defaultGroupRotate: 0,
			defaultGroupOrigin: '50% 50%',
			className: isLine ? 'moveable-element-line' : 'moveable-element',
			ables: [],
			checkInput: true,
		});
	};

	const setIsMoveableEvent = (ev: OnEvent) => {
		if (ev.inputEvent?.target?.closest('.toolbar') || ev.inputEvent?.target?.closest('.toolbar-group')) {
			isToolbarEvent.value = true;
			return;
		}
		isMoveableEvent.value = true;
	};

	const unsetIsMoveableEvent = async (ev?: OnEvent) => {
		isMoveableEvent.value = false;
		isToolbarEvent.value = false;

		// En móvil el evento de touchend no se esta transmitiendo al elemento que hay detras
		// a diferencia de versión PC. Esto es un problema a la hora de escuchar entrar en el modo edición
		// La solucion es detectar si el evento que esta finalizando es el group end y le mandamos
		// a mano al elemento real el evento de touch end
		// Hay que comprobar si tiene inputEvent ya que cuando movemos un grupo desde el position del panel se lanzará
		// también el dragGroupEnd y este vendrá sin inputEvent
		if (
			ev &&
			ev.inputEvent &&
			moveable.value &&
			'eventType' in ev &&
			ev.eventType === 'dragGroupEnd' &&
			window.TouchEvent &&
			ev.inputEvent instanceof TouchEvent
		) {
			const area = document.querySelector('.moveable-area') as HTMLElement | null;

			if (area) {
				area.style.pointerEvents = 'none';
				const targetBehind = document.elementFromPoint(
					ev.inputEvent.changedTouches[0].clientX,
					ev.inputEvent.changedTouches[0].clientY
				);
				area.style.pointerEvents = 'auto';

				if (targetBehind) targetBehind.dispatchEvent(new TouchEvent('touchend'));
			}
		}
		// Hay un bug por el cual los botones de abajo en móvil no son clicables hasta el
		// segundo click. El problema parece venir del moveable, lo solventamos forzando
		// cambio en el dragarea para que se recargue y funcionen bien de primeras.
		if (moveable.value) {
			moveable.value.dragArea = true;
			setTimeout(() => {
				if (moveable.value) {
					moveable.value.dragArea = selection.value.length > 1 && !isCropping.value;
				}
			}, 0);
		}

		if (
			moveable.value &&
			[InteractionAction.Drag, InteractionAction.Resize].includes(action.value) &&
			!selection.value[0].locked
		) {
			await removeOrMoveElement();
		}

		action.value = InteractionAction.Idle;
	};

	const temporalRefForeground = ref(ForegroundImage.create());
	const temporalRefBackground = ref(Image.create());
	const { getSizeWithBackground, getPositionWithBackground } = useSyncForeground(temporalRefForeground);
	const { foreground } = useLayersImage(temporalRefBackground);
	const dragHandler = async (element: Element, ev: OnDrag) => {
		action.value = InteractionAction.Drag;

		if (isToolbarEvent.value) return;
		if (!isReady.value) return;

		ev.datas.position = {
			x: ev.beforeTranslate[0],
			y: ev.beforeTranslate[1],
		};

		// Si es una imagen tenemos que comprobar si tiene foreground para sincronizarlo
		const targetNode = DomNodes.setElement(element) as DomNodesElement;
		targetNode.setPositionToOriginalNode(ev.beforeTranslate[0], ev.beforeTranslate[1]);
		if (element instanceof Image) {
			temporalRefBackground.value = element;

			if (foreground.value) {
				temporalRefForeground.value = foreground.value;
				const positionData = getPositionWithBackground();
				const fgNode = DomNodes.setElement(foreground.value) as DomNodesMedia;
				if (!positionData) return;
				fgNode.setPositionToOriginalNode(positionData.x, positionData.y);
			}
		}

		if (element instanceof Image || element instanceof Video)
			dragHandlerHook.trigger({ position: ev.datas.position as Position, element });
	};

	const rotateHandler = (element: Element, { rotate, datas }: OnRotate) => {
		if (!isReady.value) return;
		action.value = InteractionAction.Rotate;

		datas.rotate = rotate;
		const targetNode = DomNodes.setElement(element) as DomNodesElement;
		// !IMPORTANTE: Si es una imagen con FOREGROUND necesitamos actualizar los datos del background antes
		// !ya que al composable useSyncForeground debe de llegar el position y el rotation del DOM actualizados
		targetNode.setRotationToOriginalNode(rotate);
		if (element instanceof Image) {
			temporalRefBackground.value = element;
			if (foreground.value) {
				temporalRefForeground.value = foreground.value;
				const positionForeground = getPositionWithBackground();
				const fgNode = DomNodes.setElement(foreground.value) as DomNodesMedia;
				if (!positionForeground) return;
				fgNode.setRotationToOriginalNode(rotate, positionForeground.x, positionForeground.y);
			}
		}
	};

	const rotateGroupHandler = (events: OnRotate[]) => {
		action.value = InteractionAction.Rotate;
		events.forEach(({ target, drag, rotate, inputEvent, datas }) => {
			let finalRotate = rotate < 0 ? 360 - (Math.abs(rotate) % 360) : rotate % 360;

			const elem = elements.value.find((el) => el.id === target.id.replace('element-', ''));
			if (!elem || elem.locked) return;

			if (inputEvent.shiftKey) {
				// Si rotate es superior o inferior a cualquiera de los valores de lockRotationValues, lo dejamos en ese valor, en caso de pasar de 360 lo dejamos en 0
				const closestValue = lockRotationValues.reduce((prev, curr) => {
					return Math.abs(curr - finalRotate) < Math.abs(prev - finalRotate) ? curr : prev;
				});

				finalRotate = closestValue;
			}

			datas.rotate = finalRotate;
			datas.position = {
				x: drag.beforeTranslate[0],
				y: drag.beforeTranslate[1],
			};
			// !IMPORTANTE: Si es una imagen con FOREGROUND necesitamos actualizar los datos del background antes
			// !ya que al composable useSyncForeground debe de llegar el position y el rotation del DOM actualizados
			target.style.transform =
				`translate(${drag.beforeTranslate[0]}px, ${drag.beforeTranslate[1]}px)` + ` rotate(${finalRotate}deg)`;
			elements.value.forEach((el) => {
				if (el instanceof Image) {
					temporalRefBackground.value = el;
					if (foreground.value) {
						temporalRefForeground.value = foreground.value;
						const fgNodes = DomNodes.setElement(foreground.value) as DomNodesMedia;
						const positionForeground = getPositionWithBackground();
						if (!positionForeground) return;
						fgNodes.setRotationToOriginalNode(positionForeground.rotation, positionForeground.x, positionForeground.y);
					}
				}
			});
		});
	};

	const resizeStartHandler = (ev: OnResizeStart) => {
		if (!moveable.value) throw new Error('resizeStartHandler error');

		// Calculamos el tamaño máximo que puede tener el recorte para usarlo como límite
		if (isUngroupedImage || isUngroupedVideo) {
			maxPositionAndSize.value.position.x =
				element.value.crop.position.x < 0
					? element.value.position.x + element.value.crop.position.x
					: element.value.position.x;
			maxPositionAndSize.value.position.y =
				element.value.crop.position.y < 0
					? element.value.position.y + element.value.crop.position.y
					: element.value.position.y;
			maxPositionAndSize.value.size.width =
				element.value.crop.position.x < 0
					? element.value.size.width - element.value.crop.position.x
					: element.value.size.width;
			maxPositionAndSize.value.size.height =
				element.value.crop.position.y < 0
					? element.value.size.height - element.value.crop.position.y
					: element.value.size.height;
		}

		isMiddleHandler.value = !(ev.direction[0] && ev.direction[1]);
		if (!isCropping.value) {
			moveable.value.keepRatio = elements.value.length > 1 || element.value.keepProportions;

			if (isUngroupedText || isUngroupedImage || isUngroupedVideo) {
				moveable.value.keepRatio = !!(ev.direction[0] && ev.direction[1]) && !isCropping.value;
			}
		}

		resetInitValues();

		// Si hay textos en la selección, al comienzo del resize actualizamos la escala en la que estaba cada uno
		elements.value
			.filter((el) => el instanceof Text)
			.forEach((text) => {
				temporalRef.value = text;
				keepInitialValues();
			});

		if (useCropInstance && isMiddleHandler.value) {
			const { temporalSize } = useCropInstance;
			temporalSize.value = {
				width: (element.value as Image).crop.size.width || element.value.size.width,
				height: (element.value as Image).crop.size.height || element.value.size.height,
			};
		}
		elements.value
			.filter((el): el is Exclude<ElementClass, Text | Line> => !(el instanceof Text) && !(el instanceof Line))
			.forEach(({ id, size }) => {
				elInitialSize[id] = size;
			});

		setIsMoveableEvent(ev);
	};

	const resizeHandler = (element: Element, ev: OnResize) => {
		if (!isReady.value) return;

		action.value = InteractionAction.Resize;

		let { width, height } = ev;
		const { drag, delta, direction } = ev;
		const { translate } = drag;
		if (
			(element instanceof Text || (element instanceof Line && isCreatingElement.value)) &&
			!element.isValidSize({ width, height })
		) {
			width += 1;
			height += 1;
		}
		if (
			(!(element instanceof Text) || ((element instanceof Image || element instanceof Video) && !isCropping.value)) &&
			!element.isValidSize({ width, height })
		)
			return;
		if (shouldKeepAspectRatio(element)) {
			height = width / (elInitialSize[element.id].width / elInitialSize[element.id].height);
		}

		if ((element instanceof Image || element instanceof Video) && useCropInstance) {
			resizeCropHandler(element, delta, direction, width, height);
		}
		ev.datas.size = { width, height };
		ev.datas.position = { x: translate[0], y: translate[1] };

		const domInstance = DomNodes.setElement(element);
		domInstance?.setSizeToOriginalNode(width, height);
		domInstance?.setPositionToOriginalNode(translate[0], translate[1]);

		if (element instanceof Text) {
			temporalRef.value = element;
			resizeHandlerText(ev);
		}
		// Si el video está en play salimos, ya que se produce un flickeo (hay un watcher en el componente que se lanza al hacer resizing,
		// por lo que en la proxima iteración ya si se realizarán los cambios)
		if (element.domNode()?.querySelector('.playing-video')) return;

		if (element instanceof Image || element instanceof Video) {
			resizeMediaHandler(element, width, height);
		}
	};

	const resizeMediaHandler = (element: Image | Video, width: number, height: number) => {
		const domInstance = DomNodes.setElement(element) as DomNodesMedia;
		if (!domInstance) return;

		if (!isMiddleHandler.value && !isCropping.value && !element.hasCrop()) {
			domInstance.setSizeToCroppedNode(width, height);
		}

		const { cropPosition } = (domInstance as DomNodesMedia).nodeData;
		domInstance.setSizeToFilterNodes(width, height);
		domInstance.setPositionToFilterNodes(-cropPosition.x, -cropPosition.y);

		temporalRefBackground.value = element as Image;
		if (foreground.value) {
			temporalRefForeground.value = foreground.value;

			const fgSizeData = getSizeWithBackground();

			const fgDomInstance = DomNodes.setElement(foreground.value) as DomNodesMedia;
			if (!fgSizeData) return;

			fgDomInstance.setSizeToOriginalNode(fgSizeData.width, fgSizeData.height);
			fgDomInstance.setSizeToCroppedNode(fgSizeData.crop.size.width, fgSizeData.crop.size.height);
			fgDomInstance.setPositionToCroppedNode(fgSizeData.crop.position.x, fgSizeData.crop.position.y);

			const positionData = getPositionWithBackground();
			if (positionData) {
				fgDomInstance.setPositionToOriginalNode(positionData.x, positionData.y);
			}
		}
	};
	const resizeCropHandler = (
		element: Image | Video,
		delta: number[],
		direction: number[],
		width: number,
		height: number
	) => {
		if (!useCropInstance) return;

		// WIP | Restrict image position and size on limits if needed
		if (isMiddleHandler.value) {
			if (isCropping.value) {
				// WIP | Restric crop position and size on limits if needed
				const { resizeCropByMiddleHandler } = useCropInstance;
				resizeCropByMiddleHandler(element, delta, direction);
			}

			if (!isCropping.value) {
				const { preCropHandler } = useCropInstance;
				preCropHandler(delta, direction, { width, height });
			}
		}

		if (!isMiddleHandler.value) {
			if (isCropping.value) {
				// WIP | Restric crop position and size on limits if needed
				const { resizeCropByCornerHandler } = useCropInstance;
				resizeCropByCornerHandler(element as Image, delta, direction);
			}

			if (!isCropping.value) {
				const { fitCroppedMediaOnResize } = useCropInstance;
				fitCroppedMediaOnResize(element as Image, { width, height });
			}
		}
	};

	const resizeGroupHandler = (events: OnResize[]) => {
		if (!isValidGroupSize(events)) {
			return;
		}
		events.forEach((ev) => {
			const element = EventTools.getElementByEvent(elements.value, ev);
			if (element) resizeHandler(element, ev);
		});
	};

	const resizeEndHandler = async (ev: OnResizeEnd) => {
		if (useCropInstance && isMiddleHandler.value) {
			const { temporalSize } = useCropInstance;
			temporalSize.value = null;
		}

		if (isUngroupedText) {
			const textNode =
				(document.querySelector(`#editable-${element.value.id}`) as HTMLElement | null) ||
				(document.querySelector(`#element-${element.value.id} .text-element-final`) as HTMLElement | null);
			if (!textNode) return;

			temporalRef.value = element.value;

			const textNodes = DomNodes.setElement(element.value) as DomNodesText;
			const { nodeData } = textNodes;
			if (nodeData?.scale) {
				element.value.setScale(nodeData?.scale);
			}
		}

		if (ev.datas.position) {
			element.value.position = ev.datas.position;
			element.value.size = ev.datas.size;
		}

		if ((element.value instanceof Image || element.value instanceof Video) && element.value.hasCrop()) {
			const domInstance = DomNodes.setElement(element.value) as DomNodesMedia;
			const { cropPosition, cropSize } = domInstance.nodeData;
			element.value.crop.position = cropPosition;
			element.value.crop.size = cropSize;
		}

		await unsetIsMoveableEvent();
	};

	const toggleSizeHandlers = () => {
		if (!moveable.value || isCropping.value) return;

		const allHandles = getMoveableRenderDirections();
		const smallSizeHandles =
			element.value instanceof Text && !isUngroupedCurvedText.value && selection.value.length === 1
				? ['nw', 'e']
				: ['nw', 'se'];

		if (selection.value.length > 1) {
			const moveableArea = document.querySelector('.moveable-area');
			if (!moveableArea) return;
			const heightGroup = moveableArea.getBoundingClientRect().height;
			moveable.value!.renderDirections = heightGroup && heightGroup < 30 ? smallSizeHandles : allHandles;
			return;
		}

		if (!element.value) return;

		moveable.value!.renderDirections =
			(element.value.domNode()?.getBoundingClientRect().height || 30) < 30 ? smallSizeHandles : allHandles;
	};

	const relocateRotationHandler = async () => {
		await waitForMoveableAreaReady();
		if (shouldNotRelocateHandler.value) return;
		await nextTick();

		rotationHandler.value = document.querySelector('.moveable-control.moveable-rotation-control');

		if (!rotationHandler.value) {
			return;
		}

		// establecemos las posiciones del handler de rotación, para posteriormente reposicionarlo si es necesario
		const movOptions = isGroup
			? [
					RotationHandlerPosition.Bottom,
					RotationHandlerPosition.Left,
					RotationHandlerPosition.Top,
					RotationHandlerPosition.Right,
			  ]
			: [RotationHandlerPosition.Top, RotationHandlerPosition.Bottom];
		if (!isGroup) {
			const { collideWithToolbar } = shouldRelocatedRotationHandler();
			//  Ocultamos temporalmente el handler de rotación para evitar flickering en caso de necesitar cambiar su posición
			// Reposición del handler en elementos que no están agrupados
			rotationHandler.value.style.opacity = collideWithToolbar ? '0' : '1';
			if (collideWithToolbar) {
				const newPosition = movOptions.find((pos) => pos !== (moveable as Ref<Moveable>).value.rotationPosition);
				(moveable as Ref<Moveable>).value.rotationPosition = newPosition;
				currentRotationHandlerPosition.value = newPosition as RotationHandlerPosition;
			}
			// recuperamos la visibilidad del handler de rotación y establecemos su data
			recoverRotationHandlerVisibility();
			return;
		}

		// Reposición del handler en grupos
		let stopRelocate = false;
		movOptions
			.filter((mov) => mov !== moveable.value?.rotationPosition)
			.forEach((pos, i) => {
				useTimeoutFn(() => {
					if (stopRelocate) return;
					const { collideWithToolbar, collideWithGroupToolbar } = shouldRelocatedRotationHandler();
					(rotationHandler.value as HTMLElement).style.opacity =
						collideWithGroupToolbar || collideWithToolbar ? '0' : '1';
					if (collideWithToolbar || collideWithGroupToolbar) {
						(moveable as Ref<Moveable>).value.rotationPosition = pos;
						return;
					}
					stopRelocate = true;
				}, 50 * i);
			});
		recoverRotationHandlerVisibility();
	};

	const shouldRelocatedRotationHandler = () => {
		let shouldRelocated = false;
		let shouldRelocatedGroupCases = false;

		const toolbar = document.querySelector('.toolbar') as HTMLElement | undefined;

		if (!toolbar || !rotationHandler.value) return { collideWithToolbar: false, collideWithGroupToolbar: false };

		const toolbarBounds = toolbar.getBoundingClientRect();
		const rotationHandlerBounds = rotationHandler.value.getBoundingClientRect();
		const handlerInLeftSide = rotationHandlerBounds?.x < toolbarBounds?.x;
		/*   Es posible que el handler quede a la izquierda o muy por encima del toolbar, recalculamos la posicion
			  del handler con respecto al toolbar */
		shouldRelocated = handlerInLeftSide
			? rotationHandlerBounds?.width - Math.abs(rotationHandlerBounds?.x - toolbarBounds?.x) > 0 &&
			  toolbarBounds?.height - Math.abs(rotationHandlerBounds?.y - toolbarBounds?.y) > 0
			: toolbarBounds?.width - Math.abs(rotationHandlerBounds?.x - toolbarBounds?.x) > 0 &&
			  toolbarBounds?.height - Math.abs(rotationHandlerBounds?.y - toolbarBounds?.y) > 0;

		if (isGroup) {
			// Calculamos la posición del handler con respecto al toolbar de grupos
			const groupToolbar = document.querySelector('.toolbar-group') as HTMLElement;
			const groupToolbarBounds = groupToolbar?.getBoundingClientRect();
			const handlerInLeftSideToGroupToolbar = rotationHandlerBounds?.x < groupToolbarBounds?.x;
			const handlerInBottomSideToGroupToolbar = rotationHandlerBounds?.y > groupToolbarBounds?.y;

			const relocateHandlerInAxisX = handlerInLeftSideToGroupToolbar
				? rotationHandlerBounds.x + rotationHandlerBounds.width - groupToolbarBounds.x > 0
				: groupToolbarBounds?.width - Math.abs(rotationHandlerBounds?.x - groupToolbarBounds?.x) > 0;
			const relocateHandlerInAxisY = handlerInBottomSideToGroupToolbar
				? groupToolbarBounds.y + groupToolbarBounds.height - rotationHandlerBounds.y > 0
				: groupToolbarBounds?.height - Math.abs(rotationHandlerBounds?.y - groupToolbarBounds?.y) > 0;

			shouldRelocatedGroupCases = relocateHandlerInAxisX && relocateHandlerInAxisY;
		}
		return { collideWithToolbar: shouldRelocated, collideWithGroupToolbar: shouldRelocatedGroupCases };
	};

	const recoverRotationHandlerVisibility = () => {
		useTimeoutFn(() => {
			(rotationHandler.value as HTMLElement).setAttribute(
				`data-test-mov-dir`,
				(moveable as Ref<Moveable>).value?.rotationPosition as string
			);
			(rotationHandler.value as HTMLElement).style.opacity = '1';
		}, 100);
	};

	const toggleThrottleRotate = (shiftKey = false) => {
		if (moveable.value) {
			moveable.value.throttleRotate = 0;

			if (shiftKey) {
				moveable.value.throttleRotate = 22.5;
			}
		}
	};

	const enableAbles = () => {
		// Definimos el able de rotación y se lo asignamos al moveable
		if (moveable.value) {
			moveable.value.ables = [DimensionViewable];
			moveable.value.props = { dimensionViewable: true };
		}
	};

	const disableAbles = () => {
		// Al salir de rotación, eliminamos los ables
		if (moveable.value) {
			if (moveable.value.ables && moveable.value.ables.length > 0) {
				moveable.value.ables = [];
			}
			if (moveable.value.props) {
				moveable.value.props = undefined;
			}
		}
	};

	const registerEvents = () => {
		if (!moveable.value) return;
		if (!elements.value.length) return;
		moveable.value
			.on('dragStart', (ev) => {
				if (isTagMode.value) {
					ev.stop();
				}

				// Si es la imagen de fondo, no permitimos moverla
				if (elements.value[0].id === store.activePage?.backgroundImageId) {
					ev.stop();
				}

				setIsMoveableEvent(ev);
				exitTextEditingIfPreviouslyActive();
			})
			.on('drag', (ev) => {
				if (isPinching.value) return;

				dragHandler(element.value, ev);
				breadScrumbWithDebounce('position', 'Drag element');
			})
			.on('dragEnd', (ev) => {
				if (ev.datas.position) {
					element.value.position = ev.datas.position;
				}
				GAnalytics.track('drag and drop', 'Template', `move-${element.value.type}`, null);
				unsetIsMoveableEvent();
			})
			.on('resizeStart', (ev) => {
				exitTextEditingIfPreviouslyActive();
				resizeStartHandler(ev);
			})
			.on('resize', (ev) => {
				if (isPinching.value) return;
				resizeHandler(element.value, ev);
				breadScrumbWithDebounce('size', 'Resize from handler');
			})
			.on('resizeEnd', (ev) => {
				GAnalytics.track('drag and drop', 'Template', `resize-${element.value.type}`, null);
				toggleSizeHandlers();
				resizeEndHandler(ev);
			})
			.on('rotateStart', (ev) => {
				enableAbles();
				setIsMoveableEvent(ev);
				exitTextEditingIfPreviouslyActive();
			})
			.on('rotate', (ev) => {
				if (isPinching.value) return;
				toggleThrottleRotate(ev.inputEvent.shiftKey);

				rotateHandler(element.value, ev);
				breadScrumbWithDebounce('rotation', 'Rotate from handler');
			})
			.on('rotateEnd', (ev) => {
				if (ev.datas.rotate) {
					element.value.setRotation(ev.datas.rotate);
				}
				disableAbles();

				GAnalytics.track('drag and drop', 'Template', `rotate-${element.value.type}`, null);
				toggleThrottleRotate();

				relocateRotationHandler();
				unsetIsMoveableEvent();
			})
			.on('dragGroupStart', (ev) => {
				if (isTagMode.value) {
					ev.stop();
				}
				setIsMoveableEvent(ev);
				exitTextEditingIfPreviouslyActive();
			})
			.on('dragGroup', ({ events }) => {
				store.$patch(() => {
					if (isPinching.value) return;
					events.forEach((ev) => {
						const element = elements.value.find((el) => el.id === ev.target.id.replace('element-', ''));
						if (!element) return;
						dragHandler(element, ev);
					});
				});

				breadScrumbWithDebounce('position', `Drag group: ${elements.value.map((el) => ` ${el.type}-${el.id}`)} `);
			})
			.on('dragGroupEnd', (data) => {
				data.events.forEach((ev) => {
					if (!ev.datas.position) return;
					const element = elements.value.find((el) => el.id === ev.target.id.replace('element-', ''));
					if (!element) return;
					element.position = ev.datas.position;
				});
				GAnalytics.track('drag and drop', 'Template', `move-group`, null);
				unsetIsMoveableEvent(data);
			})
			.on('rotateGroupStart', (ev) => {
				enableAbles();

				setIsMoveableEvent(ev);
				exitTextEditingIfPreviouslyActive();
			})
			.on('rotateGroup', ({ events }) => {
				toggleThrottleRotate(events[0].inputEvent.shiftKey);

				store.$patch(() => {
					if (isPinching.value) return;

					rotateGroupHandler(events);
					breadScrumbWithDebounce(
						'rotation',
						`Rotate group from handler: ${elements.value.map((el) => ` ${el.type}-${el.id}`)} `
					);
				});
			})
			.on('rotateGroupEnd', (data) => {
				data.events.forEach((ev) => {
					if (!ev.datas.rotate) return;
					const element = elements.value.find((el) => el.id === ev.target.id.replace('element-', ''));
					if (!element) return;

					element.setRotation(ev.datas.rotate);
					element.position = ev.datas.position;
				});
				disableAbles();

				toggleThrottleRotate();

				GAnalytics.track('drag and drop', 'Template', `rotate-group`, null);
				unsetIsMoveableEvent();
				relocateRotationHandler();
			})
			.on('resizeGroupStart', (ev) => {
				exitTextEditingIfPreviouslyActive();
				resizeStartHandler(ev);
			})
			.on('resizeGroup', ({ events }) => {
				if (isPinching.value) return;

				store.$patch(() => {
					resizeGroupHandler(events);
				});
				breadScrumbWithDebounce(
					'size',
					`Resize group from handler: ${elements.value.map((el) => ` ${el.type}-${el.id}`)} `
				);
			})
			.on('resizeGroupEnd', (data) => {
				GAnalytics.track('drag and drop', 'Template', `resize-group`, null);
				data.events.forEach((ev) => {
					if (!ev.datas.size || !ev.datas.position) return;
					const element = elements.value.find((el) => el.id === ev.target.id.replace('element-', ''));
					if (!element) return;
					element.size = ev.datas.size;
					element.position = ev.datas.position;
					const domInstance = DomNodes.setElement(element) as DomNodesMedia | DomNodesText;
					let nodeData;
					if ((element instanceof Image || element instanceof Video) && element.hasCrop()) {
						nodeData = (domInstance as DomNodesMedia).nodeData;
						if (!nodeData) return;
						element.crop.position = nodeData.cropPosition;
						element.crop.size = nodeData.cropSize;
					}

					if (element instanceof Text) {
						nodeData = (domInstance as DomNodesText).nodeData;
						if (!nodeData || !nodeData.scale) return;
						element.setScale(nodeData.scale);
					}
				});
				unsetIsMoveableEvent();
				toggleSizeHandlers();
			});
	};

	/**
	 * Actualiza las líneas magnéticas
	 * @returns
	 */
	const updateGuideLines = () => {
		if (!moveable.value) {
			return;
		}
		moveable.value.verticalGuidelines = getGuideLinesVerticalPosition();
		moveable.value.horizontalGuidelines = getGuideLinesHorizontalPosition();
	};

	const removeOrMoveElement = async () => {
		if (store.isRemoveElementOutsidePaused) return;

		const actualSelection = [...selection.value];

		if (actualSelection.length) temporalRefPage.value = getPageFromElement(actualSelection[0]);

		// Eliminar si está fuera de las páginas
		let removeEl = isGroup ? groupIsOutside.value : isOutsidePage.value;
		if (isGroup && isCreatingElement.value && removeEl) {
			removeEl = false;
		}
		if (isCreatingElement.value) isCreatingElement.value = false;
		if (removeEl) {
			project.pauseAutoSave?.();

			await promiseTimeout(700);

			actualSelection.forEach((elem) => {
				removeElement(elem);
				Bugsnag.leaveBreadcrumb(`${elem.type}-${elem.id} removed after dragging off canvas`);
			});

			project.resumeAutoSave?.();
			document.querySelector('.outside')?.classList.add('delete');
		}
	};

	// Si cambia el tamaño de la ventana, avisamos al moveable
	// para que se ubique bien
	useEventListener('resize', () => {
		setTimeout(() => {
			if (moveable.value) moveable.value.updateRect();
		}, 500);
	});

	onMounted(async () => {
		if (moveable.value) {
			moveable.value?.destroy();
			moveable.value = null;
		}

		if (page.value) {
			canvas.value = page.value.domNode();
		}

		await nextTick();

		if (isPhotoMode.value && page.value?.backgroundImageId === element.value.id) return;

		scrollContainer.value = document.getElementById('scroll-area');

		moveable.value = createMoveable();

		relocateRotationHandler();
		registerEvents();
		toggleSizeHandlers();

		const { isScrolling } = useScroll(scrollContainer);

		watch(isScrolling, (val) => {
			if (!val && moveable.value) {
				updateGuideLines();
			}
		});

		useEventListener('resize', () => {
			moveable.value?.updateTarget();
			updateGuideLines();
		});
	});

	onBeforeUnmount(() => {
		moveable.value?.destroy();
		document.querySelector('.moveable-control-box')?.removeAttribute('style');
		moveable.value = null;
	});

	watch(
		[elements, activePanel],
		async () => {
			if (isMoveableEvent.value || !moveable.value) {
				return;
			}
			await nextTick();
			moveable.value?.updateTarget();
		},
		{ deep: true }
	);

	// Si se actualiza la selección actualizamos los targets para evitar que queden seleccionados elementos anteriores
	watch(selection, async () => {
		await nextTick();
		if (!moveable.value) {
			return;
		}
		moveable.value!.target = Array.from(document.querySelectorAll('.target')) as HTMLElement[];
		// si cambiamos el selection, comprobamos si el handler de rotación necesita reposicionarse
		relocateRotationHandler();
	});

	// Los handlers del moveable se deben mostrar u ocultar dependiendo de su propiedad locked
	watch(isReady, () => {
		if (!moveable.value) return;
		moveable.value.renderDirections = getMoveableRenderDirections();
		moveable.value.rotatable = !element.value.locked;
	});

	const interactiveElementReady = computed(() => !!moveable.value);

	return { interactiveElementReady };
};

export const useMoveable = () => {
	const { selection } = useSelection();
	const toggleMoveableOpacity = (hide: boolean) => {
		// Si es una línea que no está en una selección múltiple evitamos que se haga toggle del moveable,
		//  ya que en estos casos solo debemos renderizar los handlers de las líneas (se gestiona a parte en el componente LineHandler)
		// si no hacemos esto, al recuperar el opacity, haremos que se visualicen los elementos hijos del portalTarget
		// (handlers y handler de rotación)
		if (selection.value.length <= 1 && selection.value[0] instanceof Line) return;

		const portalTarget: HTMLElement | null = document.querySelector('#portalTarget');
		const toolbarTarget: HTMLElement | null = document.querySelector('#toolbarTarget');
		const groupToolbarTarget: HTMLElement | null = document.querySelector('#groupToolbarTarget');

		// establecemos el opacity en función de si queremos ocultar el elemento o no
		if (portalTarget && groupToolbarTarget) {
			portalTarget.style.opacity = hide ? '0' : '1';
		}
		if (toolbarTarget) {
			toolbarTarget.style.opacity = hide ? '0' : '1';
		}
		if (groupToolbarTarget) {
			groupToolbarTarget.style.opacity = hide ? '0' : '1';
		}
	};

	return {
		moveable,
		action,
		onDragHandler,
		isMiddleHandler,
		toggleMoveableOpacity,
		isCreatingElement,
		waitForMoveableAreaReady,
	};
};
