<script lang="ts" setup>
import { computed } from 'vue';

import Element from '@/elements/element/classes/Element';
import { useProject } from '@/project/composables/useProject';

const props = defineProps<{ element: Element }>();

const { getPageFromElement } = useProject();
const page = getPageFromElement(props.element);

const target = computed(() => page?.domNode());
</script>

<template>
	<teleport :to="`#${target?.id} [data-elements-container]`">
		<slot />
	</teleport>
</template>
