<script setup lang="ts">
import { until } from '@vueuse/core';
import { nextTick } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Text } from '@/elements/texts/text/classes/Text';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Text }>();
const { trans } = useI18n();
const { textAI, closeAIWriter, openAIWriter, enabled } = useAiWriter();
const { requireAuth, isLogged } = useAuth();
const { handleBlur } = useTextEditing();
const { isMobile } = useDeviceInfo();
const { closePanel } = usePanelManagement();

const toggleAiWriter = async (e: MouseEvent) => {
	closePanel();
	if (!isLogged.value) {
		await requireAuth();
		await until(isLogged).toBeTruthy();
	}
	handleBlur(e, true);
	await nextTick();
	textAI.value ? closeAIWriter() : openAIWriter(props.element);

	GAnalytics.trackDebounceEditBarGA4({
		category: 'text',
		link_text: 'ai_writer',
	});
};
</script>

<template>
	<button
		v-if="enabled"
		data-button-importance="important"
		:tooltip="trans('AI writer')"
		tooltip-position="top"
		class="ai-writer-excluded order-first h-11 w-[15vw] shrink-0 flex-col items-center justify-between overflow-hidden rounded lg:order-none lg:flex lg:h-6 lg:w-full lg:shrink lg:flex-row lg:justify-center lg:overflow-visible lg:px-2"
		:class="
			textAI && !isMobile
				? 'bg-gradient-to-r from-blue-500 to-purple-400 text-white'
				: 'text-purple-100 hover:text-white'
		"
		@click="toggleAiWriter"
	>
		<SvgIcon name="wand" class="hidden h-4 w-4 lg:block" />
		<svg
			viewBox="0 0 23 22"
			fill="url(#grad1)"
			class="mx-auto h-6 w-6 shrink-0 lg:hidden lg:h-5 lg:w-5"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop offset="0%" style="stop-color: #7ab6ff; stop-opacity: 1" />
					<stop offset="100%" style="stop-color: #b4b4ff; stop-opacity: 1" />
				</linearGradient>
			</defs>
			<path
				d="M19.82 4.53674L19.1052 5.83975C18.8695 6.26925 18.2601 6.25868 18.0761 5.82103L17.5072 4.49479L16.084 4.25607C15.6128 4.17902 15.4583 3.58945 15.8198 3.2587L16.9165 2.25577L16.7542 0.805169C16.6999 0.326892 17.2152 -0.0254924 17.6238 0.205908L18.8683 0.910886L20.191 0.25237C20.6264 0.0366336 21.0978 0.406983 20.9913 0.881014L20.6646 2.32201L21.6442 3.36432C21.9657 3.70649 21.7453 4.29058 21.2678 4.35103L19.82 4.53674ZM11.3838 3.17622L11.9481 4.17987C12.134 4.51081 11.8775 4.90728 11.5143 4.84908L10.4092 4.68016L9.66938 5.51845C9.42578 5.7968 8.97474 5.65703 8.90522 5.28385L8.69469 4.15185L7.67417 3.66463C7.33737 3.50451 7.31662 3.0213 7.6361 2.84735L8.60938 2.31746L8.71788 1.17788C8.75425 0.803228 9.19086 0.64482 9.459 0.908996L10.273 1.71389L11.3596 1.49642C11.7163 1.42505 12.0102 1.80915 11.8544 2.14798L11.3838 3.17622ZM14.3551 7.10097L14.9653 7.71113C15.4895 8.23534 15.7817 8.94003 15.7817 9.68339C15.7817 10.431 15.4938 11.1314 14.9653 11.66L5.87308 20.7521C5.87308 20.7521 3.99601 22.7846 1.63898 20.4274C-0.71805 18.0703 1.3141 16.1932 1.3141 16.1932L10.4063 7.10097C11.4934 6.01386 13.268 6.01386 14.3551 7.10097ZM3.14886 18.9174C3.87321 19.6417 4.66996 18.9174 4.66996 18.9174L10.8618 12.7256L9.34066 11.2045L3.14886 17.3963C3.14886 17.3963 2.42451 18.193 3.14886 18.9174ZM12.3829 11.2002L13.4442 10.1389C13.5645 10.0185 13.6289 9.85097 13.6289 9.67909C13.6289 9.50722 13.5602 9.34394 13.4399 9.22363L12.834 8.61777C12.5848 8.36855 12.1723 8.36855 11.9231 8.61777L10.8618 9.67909L12.3829 11.2002ZM17.0475 13.1394L18.1254 13.5444L18.4269 14.6343C18.5257 14.9939 18.9978 15.0987 19.2249 14.8145L19.9167 13.9487L21.0577 14.0409C21.4329 14.0706 21.6651 13.6683 21.4519 13.3582L20.8015 12.4161L21.2054 11.3842C21.338 11.0454 21.0111 10.689 20.6503 10.7832L19.5557 11.067L18.666 10.3361C18.3726 10.0952 17.9374 10.2785 17.9301 10.6483L17.9034 11.766L16.9488 12.348C16.6334 12.5372 16.6922 13.0057 17.0475 13.1394Z"
			/>
		</svg>
		<span
			v-if="isMobile"
			class="whitespace-nowrap bg-gradient-to-r from-[#7AB6FF] to-[#B4B4FF] bg-clip-text text-2xs text-transparent lg:ml-1 lg:mt-0 lg:text-sm lg:text-inherit"
			data-button-label
			>{{ trans('AI writer') }}</span
		>
	</button>
</template>

<style>
.ai-writer-open .moveable-control-box {
	display: none !important;
}

.ai-writer-excluded[tooltip-position='top']:before {
	@apply bottom-[134%];
}
</style>
