<script setup lang="ts">
import { computed, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Element; inlineLabel?: boolean }>();

// Data
const element = toRef(props, 'element');

// Computeds
const isCropping = computed(() => !!store.croppingId?.length);

// Using composables
const { flipAxis } = useElementTransformOrchestrator(element).value;
const { trans } = useI18n();
const store = useMainStore();
</script>

<template>
	<div class="flex gap-1 lg:w-full lg:gap-px">
		<div class="lg:flex-1">
			<button
				:id="`flip-x-${element.type}-toolbar`"
				class="flex h-9 w-9 items-center justify-center bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-8 lg:w-full lg:rounded-bl lg:rounded-tl lg:px-3"
				data-testid="flip-x"
				:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
				:disabled="isCropping"
				@click="flipAxis('x')"
			>
				<SvgIcon name="flip-hor" class="h-4 w-4 scale-90" />
			</button>
		</div>

		<div class="lg:flex-1">
			<button
				:id="`flip-y-${element.type}-toolbar`"
				class="flex h-9 w-9 items-center justify-center bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-8 lg:w-full lg:rounded-br lg:rounded-tr lg:px-3"
				data-testid="flip-y"
				:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
				:disabled="isCropping"
				@click="flipAxis('y')"
			>
				<SvgIcon name="flip-ver" class="h-4 w-4 scale-90" />
			</button>
		</div>
	</div>

	<label v-if="inlineLabel" class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{
		trans('Flip')
	}}</label>
</template>
