<script lang="ts" setup>
import NumberInput from '@/common/components/NumberInput.vue';
import { Overlay } from '@/elements/medias/filter/classes/Overlay';
import { useI18n } from '@/i18n/useI18n';
const { trans } = useI18n();

const props = defineProps<{ overlay: Overlay }>();

const INPUT_CONFIG = {
	MAX: 1,
	MIN: 0,
	STEP: 0.01,
	TYPE: 'range',
};

const onUpdatePatternSize = (width: number) => {
	if (!props.overlay.pattern) return;

	props.overlay.pattern.size = width;
};

const onUpdatePatternOpacity = (e: Event) => {
	if (!props.overlay.pattern) return;

	const target: HTMLInputElement | null = e.target as HTMLInputElement | null;

	if (target) props.overlay.pattern.opacity = Number(target.value);
};
</script>

<template>
	<div v-if="overlay.pattern">
		<h4 class="mb-2 hidden text-sm font-bold uppercase text-gray-100 opacity-75 lg:block">{{ trans('Pattern') }}</h4>

		<div class="flex gap-4 lg:flex-col lg:gap-0">
			<div class="mb-2 flex items-center">
				<div class="bg-transparent-pattern relative mr-4 h-10 w-10 shrink-0 overflow-hidden rounded-full">
					<div
						class="absolute inset-0"
						:style="`background-image: url(${overlay.pattern.url}); background-size: ${
							overlay.pattern.size
						}px; opacity: ${overlay.pattern.opacity * 100}%`"
						style="background-repeat: repeat"
					></div>
				</div>
				<div>
					<p class="mb-1 text-xs text-gray-200">{{ trans('Size') }}</p>
					<div class="flex flex-col">
						<NumberInput
							class="h-7 w-16 text-sm"
							:min="1"
							:value="overlay.pattern.size"
							@update="onUpdatePatternSize"
						/>
					</div>
				</div>
			</div>
			<div class="flex flex-col">
				<p class="text-sm text-gray-200">{{ trans('Opacity') }}</p>
				<div class="flex items-center" data-testid="opacity-input">
					<div class="relative flex w-full items-center">
						<input
							class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
							:max="INPUT_CONFIG.MAX"
							:min="INPUT_CONFIG.MIN"
							:step="INPUT_CONFIG.STEP"
							:type="INPUT_CONFIG.TYPE"
							:value="overlay.pattern.opacity"
							@input="onUpdatePatternOpacity"
						/>

						<span
							class="pointer-events-none absolute left-0 top-0 h-[2px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
							:style="{
								width: `${overlay.pattern.opacity * 100}%`,
							}"
						/>
					</div>

					<span class="ml-2 w-8 text-xs text-gray-100">{{ Math.round(overlay.pattern.opacity * 100) }}%</span>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
