<script setup lang="ts">
import { computed, Ref, ref } from 'vue';

import ColorSelector from '@/color/components/ColorSelector.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';
import { useMovePageOnColorpickerPanelOrverFocused } from '@/page/composables/useMovePageOnColorpickerPanelOrverFocused';
import { Color } from '@/Types/colorsTypes';
const { trans } = useI18n();

const props = defineProps<{
	color: Color | Color[];
	hideAlpha?: boolean;
	hideGradient?: boolean;
}>();

const emit = defineEmits<{
	(e: 'change', color: Color): void;
	(e: 'close'): void;
}>();

const picker = ref() as Ref<HTMLElement>;
const toChangeColorTypeTrigger = computed((): string => {
	const colorName = Array.isArray(props.color) ? props.color[0].constructor.name : props.color.constructor.name;

	return colorName;
});
useMovePageOnColorpickerPanelOrverFocused(picker, toChangeColorTypeTrigger);
</script>
<template>
	<div
		ref="picker"
		data-testid="colorpicker-mobile"
		class="color-picker absolute bottom-0 z-50 w-full rounded-tl-xl rounded-tr-xl bg-gray-700 p-3"
	>
		<div class="mb-3 flex justify-between">
			<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Colors') }}</h2>
			<button
				data-testid="close-panel"
				class="close-btn-colorpicker flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="emit('close')"
			>
				<SvgIcon name="cross" class="pointer-events-none h-3 w-3" />
			</button>
		</div>

		<ColorSelector
			class="flex flex-col"
			:color="(color as Color)"
			:hide-alpha="hideAlpha"
			:hide-gradient="hideGradient"
			@change="emit('change', $event)"
		/>
	</div>
</template>
