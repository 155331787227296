import { createSharedComposable, useVibrate } from '@vueuse/core';

export const useFeedback = createSharedComposable(() => {
	const { vibrate: selectFeedback } = useVibrate({ pattern: [40] });
	const { vibrate: deleteFeedback } = useVibrate({ pattern: [50] });

	return {
		deleteFeedback,
		selectFeedback,
	};
});
