<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, toRef } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Box } from '@/elements/box/classes/Box';
import BorderStrokeToolbar from '@/elements/box/components/buttons/BorderStrokeToolbar.vue';
import ColorButtonsToolbar from '@/elements/box/components/buttons/ColorButtonsToolbar.vue';
import CornerRadiusToolbar from '@/elements/box/components/buttons/CornerRadiusToolbar.vue';
import DownloadShapeToolbar from '@/elements/box/components/buttons/DownloadShapeToolbar.vue';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import { useCopyStyles } from '@/elements/texts/text/composables/useCopyStyles';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: Box }>();

const element = toRef(props, 'element');

const isPasteStylesMode = computed(() => Object.keys(copiedStyles.value).length > 0);

const { copiedStyles, clearCopiedStyles, copyStyles } = useCopyStyles();
const { isMobile } = useDeviceInfo();
const { isAdmin } = useAuth();
const { trans } = useI18n();
const { togglePanel } = usePanelManagement();

const onClickMoreTools = () => {
	togglePanel(EditPanels.Box);
	Bugsnag.leaveBreadcrumb('Open edit box panel');
};

const onCopyTextStyles = () => {
	copyStyles(element.value);
};
</script>

<template>
	<div class="overflow-auto lg:overflow-visible">
		<template v-if="!isPasteStylesMode">
			<template v-if="!element.locked">
				<ColorButtonsToolbar :element="element" />
				<BorderStrokeToolbar :element="element" />
				<CornerRadiusToolbar :element="element" />
				<DownloadShapeToolbar v-if="isAdmin && !isMobile" :element="element" />

				<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 lg:block" />

				<!-- Order -->
				<OrderToolbar v-if="isMobile" :element="element" />

				<!-- Copy -->
				<CopyToolbar v-if="isMobile" :element="element" />

				<!-- Remove -->
				<RemoveToolbar :element="element" />

				<!-- Copy styles -->
				<div
					class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-10 lg:w-8 lg:justify-center"
					:tooltip="trans('Copy styles')"
					tooltip-position="top"
				>
					<button
						class="flex flex h-full w-full items-center justify-center"
						data-title="copy-styles"
						@click="onCopyTextStyles"
					>
						<SvgIcon name="paint-roll" class="h-5 w-5 scale-90" />
						<p class="text-2xs lg:hidden">
							{{ trans('Copy styles') }}
						</p>
					</button>
				</div>

				<MoreToolsToolbar :element="element" :panel="EditPanels.Box" @more-tools="onClickMoreTools" />
			</template>
			<LockButtonToolbar v-if="!isMobile && element.locked" :element="element"
		/></template>

		<button
			v-if="isPasteStylesMode"
			class="flex h-8 items-center justify-center px-4 text-sm font-semibold text-white opacity-75 hover:opacity-100 mockup:text-fp-gray-700 lg:h-6 lg:px-3"
			@click="clearCopiedStyles"
		>
			<SvgIcon name="cross" class="mr-1 h-4 w-4 fill-current" />
			{{ trans('Cancel') }}
		</button>
	</div>
</template>
