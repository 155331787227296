<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
import { useLineColors } from '@/color/composables/useLineColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import Line from '@/elements/line/classes/Line';
import LineStyleToolbar from '@/elements/line/components/buttons/LineStyleToolbar.vue';
import { Color } from '@/Types/colorsTypes';
import { EditPanels } from '@/Types/types';

// Props
const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');

const { hasSameColors, updateColor, updateMarkerStartColor, updateMarkerEndColor, updateAllColors } =
	useLineColors(element);
const { isMobile } = useDeviceInfo();
const { togglePanel } = usePanelManagement();
const onClickMoreTools = () => {
	Bugsnag.leaveBreadcrumb('Open edit line panel');
	togglePanel(EditPanels.Line);
};
</script>

<template>
	<div class="overflow-auto pl-2 lg:w-full lg:overflow-visible">
		<LockButtonToolbar v-if="!isMobile && element.locked" :element="element" />
		<div v-if="hasSameColors" class="flex items-center lg:h-8" tooltip="Color" tooltip-position="top">
			<ColorPicker
				parent=".toolbar"
				class="relative mr-1 h-8 w-8 shrink-0 before:pointer-events-none before:absolute before:left-1 before:top-1 before:z-10 before:h-6 before:w-6 before:rounded-full lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
				:color="element.mainColor"
				@change="updateAllColors"
			/>
		</div>
		<div v-else id="color-picker-line-toolbar" class="flex items-center lg:h-8" tooltip="Color" tooltip-position="top">
			<ColorPicker
				v-if="element.markerStart"
				parent=".toolbar"
				class="relative mr-1 h-8 w-8 shrink-0 before:pointer-events-none before:absolute before:left-1 before:top-1 before:z-10 before:h-6 before:w-6 before:rounded-full lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
				:color="(element.markerStart.color as Color)"
				@change="updateMarkerStartColor"
			/>

			<ColorPicker
				v-if="element.markerEnd"
				parent=".toolbar"
				class="relative h-8 w-8 shrink-0 before:pointer-events-none before:absolute before:left-1 before:top-1 before:z-10 before:h-6 before:w-6 before:rounded-full lg:mr-1 lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
				:color="(element.markerEnd.color as Color)"
				@change="updateMarkerEndColor"
			/>

			<div class="mr-3 border-r border-gray-600 pl-2 pr-3 lg:mr-0 lg:border-0 lg:pl-0 lg:pr-0">
				<ColorPicker
					parent=".toolbar"
					placement="right-start"
					class="relative h-8 w-8 shrink-0 before:pointer-events-none before:absolute before:left-1 before:top-1 before:z-10 before:h-6 before:w-6 before:rounded-full before:bg-gray-700 lg:mr-1 lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
					:color="(element.mainColor as Color)"
					@change="updateColor"
				/>
			</div>
		</div>

		<LineStyleToolbar :element="element" />

		<OrderToolbar v-if="isMobile" :element="element" />

		<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 lg:block" />

		<CopyToolbar v-if="isMobile" :element="element" />

		<RemoveToolbar :element="element" />

		<MoreToolsToolbar :element="element" :panel="EditPanels.Line" @more-tools="onClickMoreTools" />
	</div>
</template>
