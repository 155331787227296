<script setup lang="ts">
import { ref } from 'vue';

import { getMasks } from '@/api/DataApiClient';
import ActivableItem from '@/common/components/ActivableItem.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { MaskApi } from '@/Types/apiClient';
import { InsertableApiType } from '@/Types/types';

const { addInsertableImageMask } = useAddInsertableElement();

const emit = defineEmits(['elementAdded']);

// Constants
const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

// Data
const url = ref(`elements/category/1741?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}`);

const { data: masks, isFetching } = getMasks(url, { refetch: true });

// Methods
const addMask = async (maskApi: MaskApi) => {
	await addInsertableImageMask(maskApi);
	emit('elementAdded');
};

const loadMore = () => {
	if (isFetching.value || !masks.value?.links?.next) return;
	url.value = masks.value.links.next;
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<InfiniteLoading
			data-testid="container-items"
			:container-classes="'grid grid-cols-5 gap-6'"
			:data="masks?.data || []"
			:is-fetching="isFetching"
			:source="url"
			@load="loadMore"
		>
			<template #item="{ item }">
				<InsertableElement
					v-if="'mask' in item && 'active' in item"
					:data="item"
					:type="InsertableApiType.ImageMask"
					:draggable="true"
					class="flex"
					@click="addMask(item as MaskApi)"
				>
					<ActivableItem :active="item.active">
						<img v-if="item && 'element' in item.mask" :src="item.mask.element" class="h-10 w-10 object-contain" />
					</ActivableItem>
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
