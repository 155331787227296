<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { onClickOutside } from '@vueuse/core';
import { uniqBy } from 'lodash';
import { onBeforeMount, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getArtboards } from '@/api/DataApiClient';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useTransform } from '@/elements/element/composables/useTransform';
import Image from '@/elements/medias/images/image/classes/Image';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import ArtboardButton from '@/project/components/menus/ArtboardButton.vue';
import ArtboardCustomSize from '@/project/components/menus/ArtboardCustomSize.vue';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { Anchor, Artboard, Size, Unit } from '@/Types/types';
const project = useProjectStore();
const store = useMainStore();
const { trans } = useI18n();

defineProps({
	dropdownMode: {
		type: Boolean,
		default: true,
	},
});

// Template refs
const selector = ref();

// Data
const artboardsPresets = ref<Artboard[]>([]);
const presetsVisibility = ref<boolean>(false);
const tempPageRef = ref(Page.createDefault());
const tempRef = ref(Image.create());

const { artboardSizeInPx, rotateArtboard, setArtboardSize, isRotable, isLandscape } = useArtboard();
const { isPhotoMode, isAdminMode } = useEditorMode();
const { photoModeImage } = usePhotoMode();
const { isMobile } = useDeviceInfo();
const { fitZoomScale } = useZoom();
const { adjustContent } = usePage(tempPageRef);
const { align } = useTransform(tempRef);

onBeforeMount(async () => {
	await initPresets();
});
watch(
	artboardSizeInPx,
	(newArtboard, oldArtboard) => {
		// Si no es undefined es que hemos usado el historial, así
		// que ignoramos el cambio de artboard
		if (window.moving || !store.finishedLoading) {
			return;
		}

		project.pages.forEach((page) => {
			tempPageRef.value = page as Page;
			adjustContent(newArtboard, oldArtboard);
		});
		fitZoomScale();
	},
	{ deep: true }
);

const closeArtboardOptions = () => {
	presetsVisibility.value = false;
};

const initPresets = async () => {
	const artboards = await getArtboards();

	if (!artboards?.length) return;

	// ordenamos alfabéticamente los artboards
	const sortedArtboards = artboards.sort((a, b) => a.name.localeCompare(b.name));
	artboardsPresets.value = [...sortedArtboards];
};

const onToggleArtboardSize = (newMode: 'landscape' | 'portrait') => {
	const currentMode = isLandscape.value ? 'landscape' : 'portrait';

	if (currentMode === newMode) return;

	rotateArtboard();
	Bugsnag.leaveBreadcrumb(`rotate artboard to: ${newMode}`);
};

const onChangeArtboardSizeAndUnit = (newSize: Size, newUnit: Unit) => {
	onUpdateArtboard({
		name: 'Custom',
		width: newSize.width,
		height: newSize.height,
		unit: newUnit,
	});

	if (isPhotoMode.value) {
		// Make larger or smaller proportianlly regarding custom size
		const image = project.pages[0].elements.get(project.pages[0].backgroundImageId || '') as Image;
		const swapSize = image.rotation % 90 === 0 && image.rotation % 180 !== 0;
		const factor = swapSize ? image.size.width / newSize.height : image.size.width / newSize.width;

		image.size = {
			width: swapSize ? newSize.height : newSize.width,
			height: swapSize ? newSize.width : newSize.height,
		};

		image.crop = {
			position: {
				x: image.crop.position.x / factor,
				y: image.crop.position.y / factor,
			},
			size: {
				width: image.crop.size.width / factor,
				height: image.crop.size.height / factor,
			},
		};

		if (photoModeImage.value) {
			tempRef.value = photoModeImage.value;
			align(Anchor.topLeft);
		}
	}
};

onClickOutside(selector, closeArtboardOptions);

const onUpdateArtboard = async (preset: Artboard) => {
	closeArtboardOptions();
	setArtboardSize(preset.width, preset.height, preset.unit);
	GAnalytics.track('click', 'select', 'change-artboard-size', null);
	Bugsnag.leaveBreadcrumb(`Update artboard to ${preset.name}: ${preset.width}, ${preset.height}, ${preset.unit}`);
};

const togglePresetsVisibility = () => {
	presetsVisibility.value = !presetsVisibility.value;
};

const toggleCustomSize = () => {
	presetsVisibility.value = !presetsVisibility.value;
};
</script>

<template>
	<div ref="selector" class="relative -order-1 flex gap-1 lg:order-none">
		<template v-if="isPhotoMode || !dropdownMode">
			<div v-if="isMobile && dropdownMode">
				<button
					id="changeArtboardButton"
					data-testid="change-artboard"
					class="flex h-8 w-8 items-center justify-center gap-2 px-2 text-white opacity-50 focus:opacity-100 hover:opacity-100 lg:h-10"
					@click="toggleCustomSize"
				>
					<SvgIcon name="resize" class="h-4 w-4" />
					<span class="hidden lg:block">{{ trans('Resize') }}</span>
				</button>
				<ArtboardCustomSize v-if="presetsVisibility" @changeArtboardSizeAndUnit="onChangeArtboardSizeAndUnit" />
			</div>
			<div v-else :class="{ 'w-64': isPhotoMode, 'w-72': !dropdownMode }">
				<ArtboardCustomSize :dropdown-mode="dropdownMode" @changeArtboardSizeAndUnit="onChangeArtboardSizeAndUnit" />
			</div>
		</template>

		<template v-else>
			<button
				id="changeArtboardButton"
				data-testid="change-artboard"
				class="flex h-8 items-center justify-center gap-2 px-2 text-white opacity-50 focus:opacity-100 hover:opacity-100 lg:h-10 lg:px-4"
				@click="togglePresetsVisibility"
			>
				<SvgIcon name="resize" class="h-4 w-4" />
				<span class="hidden lg:block">{{ trans('Resize') }}</span>
			</button>
			<ArtboardCustomSize
				v-if="isAdminMode"
				:dropdown-mode="false"
				@changeArtboardSizeAndUnit="onChangeArtboardSizeAndUnit"
			/>

			<div v-show="presetsVisibility" data-testid="artboard-list-container" class="absolute left-0 top-12 z-20">
				<Transition
					appear
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="transform -translate-y-4 opacity-0"
				>
					<div class="relative">
						<div
							class="flex max-h-96 w-80 flex-col overflow-auto rounded bg-white py-1 pr-1 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
						>
							<div class="mb-0.5 flex items-center pl-4 pt-2">
								<p class="flex-1 text-sm font-bold uppercase text-gray-500">{{ trans('Change artboard') }}</p>
								<div v-if="isRotable" class="flex gap-1" data-testid="artboard-orientation">
									<button
										class="relative flex h-7 w-7 shrink-0 items-center justify-center rounded"
										:class="{
											'bg-gray-100/25 text-gray-700': !isLandscape,
											'text-gray-400 hover:text-gray-700': isLandscape,
										}"
										data-testid="artboard-orientation-portrait"
										@click="onToggleArtboardSize('portrait')"
									>
										<SvgIcon name="portrait" class="h-4 w-4" />
									</button>

									<button
										class="relative flex h-7 w-7 shrink-0 items-center justify-center rounded"
										:class="{
											'bg-gray-100/25 text-gray-700': isLandscape,
											'text-gray-400 hover:text-gray-700': !isLandscape,
										}"
										data-testid="artboard-orientation-landscape"
										@click="onToggleArtboardSize('landscape')"
									>
										<SvgIcon name="landscape" class="h-4 w-4" />
									</button>
								</div>
							</div>

							<p class="flex-1 pl-4 text-sm font-semibold text-gray-400">{{ trans('Size') }}</p>

							<div class="sticky -top-1 z-10 mb-3 border-b border-gray-50 bg-white/80 py-3 backdrop-blur">
								<ArtboardCustomSize @changeArtboardSizeAndUnit="onChangeArtboardSizeAndUnit" />
							</div>
							<p class="flex-1 pl-4 text-sm font-semibold text-gray-400">{{ trans('Presets') }}</p>

							<div v-for="preset in artboardsPresets" :key="preset.name">
								<ArtboardButton :preset="preset" @close="closeArtboardOptions" />
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</template>
	</div>
</template>
