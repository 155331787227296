import { MaybeRef } from '@vueuse/core';
import { Ref, ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import MathTools from '@/utils/classes/MathTools';

export const useText = (text: Ref<Text>) => {
	const store = useMainStore();
	const { isCircleText } = useCircleTypeInfo(text);

	const fitHeight = (referenceNode?: MaybeRef<HTMLElement>): void => {
		if (isCircleText.value) {
			return;
		}

		const preReference = ref(referenceNode);

		if (preReference.value && preReference.value.getBoundingClientRect().height === 0) {
			preReference.value = undefined;
		}

		const reference = ref(
			preReference.value || (text.value.domNode()?.querySelector(`.text-element-final`) as HTMLElement)
		);
		// Obtenemos el nodo principal para conseguir la altura
		let mainElement;

		if (reference.value instanceof HTMLElement) {
			mainElement = reference.value?.closest('[id^=element-]')
				? (reference.value?.closest('[id^=element-]') as HTMLElement)
				: (reference.value.parentElement as HTMLElement);
		}

		if (!mainElement) {
			return;
		}

		// Obtenemos la altura del texto
		const height = parseFloat(parseFloat(getComputedStyle(reference.value).height).toFixed(2)) * text.value.scale;

		if (height === 0) {
			console.error(`Texto detectado sin altura en el elemento [${mainElement.id}]`);
		}

		const isSameHeight = MathTools.compareWithTolerance(text.value.size.height, height / store.scale, 0.01);

		// Evitamos actualizar la altura si es la misma para evitar sync del historial
		if (!isSameHeight) {
			const prevHeight = text.value.size.height;

			// Sobreescribimos la altura
			text.value.size.height = height;

			// Si es la primera vez, movemos el texto para que quede centrado
			if (!text.value.metadata.fixPosYOnAdded && prevHeight === 0) {
				text.value.position.y -= text.value.size.height / 2;
				text.value.metadata.fixPosYOnAdded = true;
				text.value.metadata.fixPaddingScale = true;
			}
		}
	};

	return {
		fitHeight,
	};
};
