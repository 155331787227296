<script setup lang="ts">
import { ref } from 'vue';

import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import FontsInfo from '@/editor/components/FontsInfo.vue';
import HistoryInfo from '@/editor/components/HistoryInfo.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import PerformanceInfo from '@/editor/components/PerformanceInfo.vue';
import { useMainStore } from '@/editor/stores/store';
import Line from '@/elements/line/classes/Line';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { VideoApi } from '@/Types/apiClient';
import { InsertableMediaOriginApiType } from '@/Types/apiClient.d';
import { InsertableApiType } from '@/Types/types';

const store = useMainStore();
const { setSelection } = useSelection();

const { addInsertableVideo, setupElementInPage } = useAddInsertableElement();
const { addElement } = useActivePage();

const temporalRef = ref<Line>(Line.create());

const addLine = () => {
	const data = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 32.43"><line x1="2.96" y1="16.23" x2="196.64" y2="16.23" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:6px"/><path id="custom-sticky" d="M174.08,32.47a3,3,0,0,1-1.5-5.6L191,16.23,172.58,5.6a3,3,0,0,1,3-5.2L198.5,13.63a3,3,0,0,1,0,5.2L175.58,32.06A3,3,0,0,1,174.08,32.47Z"/></svg>`;
	const line = Line.fromSvg(data);

	temporalRef.value = line;

	addElement(line);
	setupElementInPage(line);

	setSelection(line, false);
};

const video: VideoApi = {
	id: 123,
	type: InsertableApiType.Video,
	url: 'http://localhost:9000/api/video/freepik?id=163164&signature=c595beac9ca3349a7919eff517b618ec3f427726e1cc365fab215788d5303ff0',
	preview:
		'http://localhost:9000/api/video/freepik?thumb=https%3A%2F%2Fv4.cdnpk.net%2Fvidevo_files%2Fvideo%2Ffree%2Fvideo0453%2Fthumbnails%2F_1014__import_small.jpg&signature=db75db391121a32eae2f60a5e618def99c1e3f40823e62852632567e7cc9d317',
	duration: '00:00:07',
	backgroundRemoved: '',
	metadata: {
		raw: 'http://localhost:9000/api/video/freepik?id=163164&signature=c595beac9ca3349a7919eff517b618ec3f427726e1cc365fab215788d5303ff0',
		size: {
			width: 460,
			height: 264,
		},
	},
	links: [''],
	data: [''],
	origin: InsertableMediaOriginApiType.Freepik,
};
</script>

<template>
	<ReserveSideSize ref="el" class="flex h-full flex-col text-gray-800 scrollbar-thumb-gray-600">
		<PanelHeader title="Dev" />

		<button
			class="mt-2 h-12 rounded bg-blue-500 font-bold hover:bg-blue-600 slidesgo:bg-purple-400"
			@click="addInsertableVideo(video)"
		>
			Add Video
		</button>

		<button class="mt-2 h-12 rounded bg-blue-500 font-bold hover:bg-blue-600 slidesgo:bg-purple-400" @click="addLine">
			Add Line
		</button>

		<PerformanceInfo />
		<HistoryInfo />
		<FontsInfo />
	</ReserveSideSize>
</template>
