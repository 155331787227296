<script setup lang="ts">
import { toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
// Components
import { useBoxColors } from '@/color/composables/useBoxColors';
import { Box } from '@/elements/box/classes/Box';
const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');

const { colors, updateColor } = useBoxColors(element);
</script>

<template>
	<div
		:id="`color-buttons-${element.type}-toolbar`"
		class="mr-1 flex h-10 items-center border-r border-gray-600 pr-2 lg:mr-0 lg:border-0 lg:pr-0"
	>
		<div class="flex items-center lg:h-10" tooltip="Fill color" tooltip-position="top">
			<!-- Background color -->
			<ColorPicker
				parent=".toolbar"
				placement="right-start"
				class="relative ml-2 mr-2 h-8 w-8 shrink-0 lg:ml-0 lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
				:color="colors[0]"
				@change="($event) => updateColor(colors[0], $event, 'changeBackground')"
			/>
		</div>
		<!-- Border color -->
		<div class="flex items-center lg:h-10" tooltip="Border color" tooltip-position="top">
			<ColorPicker
				v-if="!!colors[1]"
				parent=".toolbar"
				placement="right-start"
				class="relative ml-1 mr-2 h-8 w-8 shrink-0 before:pointer-events-none before:absolute before:left-1 before:top-1 before:z-10 before:h-6 before:w-6 before:rounded-full before:bg-gray-700 lg:ml-0 lg:h-5 lg:w-5 lg:before:h-3 lg:before:w-3"
				:color="colors[1]"
				@change="($event) => updateColor(colors[1], $event, 'changeStroke')"
			/>
		</div>
	</div>
</template>
