import { Element as SVGJSElement, List } from '@svgdotjs/svg.js';

import { GradientColor } from '@/color/classes/GradientColor';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { StopGradient } from '@/Types/colorsTypes';

const FIND_TEXT_NODES_REGEX = /<text[^>]*>[\s\S]*?<\/text>/gi;

export class SlidesgoShapeParser {
	static init(input: SVGJSElement | SVGJSElement[] | List<SVGJSElement>, pptxDataGradients: GradientColor[]) {
		if (Array.isArray(input)) {
			return input.some((item) => item.classes().some((cls) => cls.includes('CustomShape')))
				? this.parseMultipleCustomShapes(input, pptxDataGradients)
				: this.parseMultipleShapes(input, pptxDataGradients);
		}

		return this.parseShape(input, pptxDataGradients);
	}

	static isShape(wrapper: SVGJSElement) {
		return (
			wrapper.classes().some((cls) => cls.includes('CustomShape')) &&
			!wrapper.classes().includes('ignore-this-element') &&
			!wrapper.findOne('.SVGTextShape') &&
			!wrapper.findOne('.TextShape')
		);
	}

	static parseMultipleCustomShapes(list: SVGJSElement[] | List<SVGJSElement>, pptxDataGradients: GradientColor[]) {
		const boxes: SVGJSElement[] = [];
		const shapes: SVGJSElement[] = [];
		list.forEach((item) => {
			const gParent = item.findOne('g[id^="id"]') as SVGJSElement;
			if (!gParent) return;
			const box = gParent.findOne('.BoundingBox') as SVGJSElement;
			boxes.push(box);
			const others = gParent
				.children()
				.filter((child) => !child.hasClass('BoundingBox') && child.node.tagName !== 'defs');
			shapes.push(...others);
		});
		const minX = Math.min(...boxes.map((box) => parseFloat(box.x().toString())));
		const minY = Math.min(...boxes.map((box) => parseFloat(box.y().toString())));
		const maxX2 = Math.max(...boxes.map((box) => parseFloat(box.x().toString()) + parseFloat(box.width().toString())));
		const maxY2 = Math.max(...boxes.map((box) => parseFloat(box.y().toString()) + parseFloat(box.height().toString())));

		const viewbox = `${minX} ${minY} ${maxX2 - minX} ${maxY2 - minY}`;

		// Extract defs
		const defs =
			list
				.map((item) => this.extractAndClearDefs(item, pptxDataGradients))
				.filter((def) => !!def)
				.join('') || '';

		const content = shapes.map((shape) => shape && shape.node.outerHTML).join('');
		const svg = this.getSvg(content, viewbox, defs);
		const newShape = Shape.fromSvg(svg);
		return newShape;
	}

	static parseMultipleShapes(list: SVGJSElement[] | List<SVGJSElement>, pptxDataGradients: GradientColor[]) {
		const minX = Math.min(...list.map((shape) => shape.bbox().x));
		const minY = Math.min(...list.map((shape) => shape.bbox().y));
		const maxX2 = Math.max(...list.map((shape) => shape.bbox().x + shape.bbox().width));
		const maxY2 = Math.max(...list.map((shape) => shape.bbox().y + shape.bbox().height));

		const viewbox = `${minX} ${minY} ${maxX2 - minX} ${maxY2 - minY}`;

		const defs =
			list
				.map((item) => this.extractAndClearDefs(item, pptxDataGradients))
				.filter((def) => !!def)
				.join('') || '';

		const content = list.map((shape) => shape && shape.node.outerHTML).join('');
		const svg = this.getSvg(content, viewbox, defs);
		const newShape = Shape.fromSvg(svg);
		return newShape;
	}

	static parseShape(wrapper: SVGJSElement, pptxDataGradients: GradientColor[]) {
		const gParent = wrapper.findOne('g[id^="id"]') as SVGJSElement;
		if (!gParent) return;
		const box = gParent.findOne('.BoundingBox') as SVGJSElement;
		const shapes = gParent
			.children()
			.filter((child) => !child.hasClass('BoundingBox') && child.node.tagName !== 'defs');
		if (!box || !shapes.length) return;

		const viewbox = `${box.x().toString()} ${box.y().toString()} ${box.width()} ${box.height()}`;
		const defs = this.extractAndClearDefs(wrapper, pptxDataGradients);
		const content = shapes.map((shape) => shape && shape.node.outerHTML).join('');
		const svg = this.getSvg(content, viewbox, defs);
		const newShape = Shape.fromSvg(svg);
		return newShape;
	}

	static extractAndClearDefs(wrapper: SVGJSElement, pptxDataGradients: GradientColor[]) {
		// Replace LibreOffice stops by PPTX XML stops
		const linearGradients = wrapper.find('linearGradient');
		linearGradients.forEach((linearGradient) => {
			const colorsFromLibreOffice = linearGradient.children().map((stop) => stop.css('stop-color').toString());
			const colorsFromPPTX = pptxDataGradients.map((gradient) =>
				gradient.stops.map((stop: StopGradient) => `rgb(${stop.r}, ${stop.g}, ${stop.b})`)
			);
			const fixedStopsIndex = this.getFixedGradientIndex(colorsFromLibreOffice, colorsFromPPTX);
			if (fixedStopsIndex === -1) return;
			linearGradient.children().forEach((stop) => stop.remove());
			pptxDataGradients[fixedStopsIndex].stops.forEach((stop) => {
				linearGradient.stop({
					color: `rgb(${stop.r}, ${stop.g}, ${stop.b})`,
					offset: stop.offset / 100,
					opacity: stop.a,
				});
			});
		});

		const defs =
			wrapper
				.find('defs')
				.map((def) => def.node.innerHTML)
				.filter((def) => !!def)
				.join('')
				.replaceAll('<defs>', '')
				.replaceAll('</defs>', '') || '';
		wrapper
			.find('defs')
			.filter((def) => !!def && !def.parent('.Master_Slide'))
			.forEach((def) => def.remove());

		return defs;
	}

	static getSvg(content: string, viewbox: string, defs: string) {
		content = content.replace(FIND_TEXT_NODES_REGEX, '');
		return `<svg viewBox="${viewbox}"><defs>${defs}</defs>${content}</svg>`;
	}

	static getFixedGradientIndex(colorsFromLibreOffice: string[], colorsFromPPTX: string[][]) {
		let found = false;
		let completeGradient = -1;

		for (let i = 0; i < colorsFromPPTX.length; i++) {
			const currentArr = colorsFromPPTX[i];
			let currentIndex = 0;

			for (let j = 0; j < currentArr.length; j++) {
				if (currentArr[j] === colorsFromLibreOffice[currentIndex]) {
					currentIndex++;

					if (currentIndex === colorsFromLibreOffice.length) {
						completeGradient = i;
						found = true;
						break;
					}
				}
			}

			if (found) break;
		}

		return completeGradient;
	}
}
