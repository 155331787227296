<script lang="ts" setup>
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		modal?: boolean;
		aiImages: ImageApi[];
		isFetchingUser: boolean;
	}>(),
	{
		modal: false,
	}
);

const emit = defineEmits<{
	(e: 'insert', value: ImageApi): void;
	(e: 'viewer', value: ImageApi): void;
	(e: 'try'): void;
	(e: 'toggle'): void;
	(e: 'load'): void;
}>();

const { trans } = useI18n();
</script>

<template>
	<button
		class="mb-2 h-9 w-full rounded bg-white px-2 font-semibold text-gray-800 hover:bg-gray-50"
		@click="emit('toggle')"
	>
		{{ trans('Find inspiration') }}
	</button>
	<button
		class="mb-4 h-9 w-full rounded bg-gradient-to-r from-blue-500 to-purple-400 px-2 font-semibold text-white hover:opacity-80"
		@click="emit('try')"
	>
		{{ trans('Try it') }}
	</button>
	<InfiniteLoading
		:data="aiImages || []"
		:is-fetching="isFetchingUser"
		class="flex flex-col"
		with-masonry
		:masonry-cols="2"
		@load="emit('load')"
	>
		<template #item="{ item }">
			<InsertableElement
				v-if="'metadata' in item"
				:data="(item as ImageApi)"
				:type="Panels.photos"
				:draggable="!props.modal"
				class="mb-2"
				@click="emit('insert', item as ImageApi)"
			>
				<ListMedia :image="(item as ImageApi)" class="group relative w-full">
					<div
						class="absolute inset-0 z-10 flex items-end rounded bg-gray-800/70 p-2 opacity-0 group-hover:opacity-100"
					>
						<button
							class="absolute right-2 top-2 rounded bg-gray-100/50 p-2 backdrop-blur-xl hover:bg-gray-100/75"
							@click="emit('viewer', item as ImageApi)"
						>
							<SvgIcon name="fullscreen" class="h-4 w-4 text-white" />
						</button>
						<p class="line-clamp-4 w-full overflow-hidden text-left text-sm leading-tight text-white">
							{{ (item as ImageApi).metadata.prompt }}
						</p>
					</div>
				</ListMedia>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
