<script lang="ts" setup>
import { Dom } from '@svgdotjs/svg.js';
import { OnClickOutside } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import ColorPicker from '@/color/components/ColorPicker.vue';
import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { TimeTools } from '@/common/utils/TimeTools';
import TemplateButtons from '@/editor/components/TemplateButtons.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Illustrator } from '@/elements/illustrator/classes/Illustrator';
import { useIllustrator } from '@/elements/illustrator/composables/useIllustrator';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ page: Page }>();
const page = toRef(props, 'page');
const { trans } = useI18n();
const { setBackground, position } = usePage(page);
const { isIllustratorContext, isEmbeddedContext, isAdminMode } = useEditorMode();

const { isMobile } = useDeviceInfo();
const showTools = ref(false);
const showFreepikPreview = ref(false);

const store = useMainStore();
const { clearSelection } = useSelection();

const project = useProjectStore();
const illustratorElement = ref(
	isIllustratorContext.value ? (project.pages[0].elementsAsArray()[0] as Illustrator) : Illustrator.create()
);
const { moveTexts, moveShapes, removeElements } = useIllustrator(illustratorElement);

const showIllustratorButtons = computed(
	() => isIllustratorContext.value && position.value === 0 && project.pages.length > 1
);
const showToggleFreepikPreview = computed(() => isAdminMode.value && store.freepikPreview);

const clearIllustratorSelection = () => {
	store.illustratorSelection.clear();
	document.querySelectorAll('.tree-element-selection').forEach((el) => {
		el.classList.remove('tree-element-selection');
	});
};

const moveIllustratorElements = () => {
	const svg = illustratorElement.value.contentSvg;

	const textsId: string[] = [];
	const shapesId: string[] = [];

	// Categorizamos los elementos, ya que los textos y los shapes se crean de forma diferente,
	// los shapes se unificarán, mientras que los textos son individuales
	Array.from(store.illustratorSelection).forEach((id) => {
		const type = (svg.findOne(`[data-illustrator-link="${id}"]`) as Dom).type;

		switch (type) {
			case 'text': {
				textsId.push(id);
				break;
			}
			default: {
				shapesId.push(id);
				break;
			}
		}
	});

	moveTexts(textsId);
	moveShapes(shapesId);

	clearIllustratorSelection();
};

const removeIllustratorElements = () => {
	const ids = Array.from(store.illustratorSelection);
	illustratorElement.value = project.pages[0].elementsAsArray()[0] as Illustrator;

	removeElements(ids);

	clearIllustratorSelection();
};

const updateBackground = (bg: Color) => {
	setBackground(bg);
};

const trackableEvent = useDebounceFn(() => {
	GAnalytics.track('click', 'Button', 'update-background', null);
}, 100);

const toggleFreepikPreview = () => {
	showFreepikPreview.value = !showFreepikPreview.value;
};
</script>

<template>
	<div class="relative z-[5] flex items-end justify-between gap-3 pb-2" :class="{ 'mt-12': isIllustratorContext }">
		<div class="flex content-center items-center gap-4 text-xs text-gray-400">
			<p v-if="project.pages.length > 1" class="whitespace-nowrap">{{ trans('Page') }} {{ position + 1 }}</p>
			<div v-if="project.allVideos.length" class="flex items-center justify-center gap-1.5">
				<SvgIcon name="time" class="h-3 w-3" />
				{{ TimeTools.formatMillisecondsToMMSS(page.duration()) }}
			</div>
		</div>

		<div :class="{ 'opacity-0': !store.finishedLoading }" class="ml-auto flex items-center gap-1">
			<ColorPicker
				:tooltip="trans('Background color')"
				tooltip-position="top"
				:color="page.background as Color"
				class="z-10 h-5 w-5 shrink-0"
				@click="clearSelection"
				@track="trackableEvent"
				@change="updateBackground"
			/>

			<div v-if="showToggleFreepikPreview" class="z-0 -mr-2 ml-3 flex gap-3 border-l border-gray-700 pl-2">
				<button
					data-testid="toggle-freepik-preview"
					tooltip="Toggle freepik preview"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="toggleFreepikPreview"
				>
					<SvgIcon name="photo" class="h-4 w-4" />
				</button>

				<Teleport v-if="store.freepikPreview && showFreepikPreview" to="body">
					<img :src="store.freepikPreview" class="absolute left-0 top-0 z-40 w-1/3 object-contain" />
				</Teleport>
			</div>

			<div
				v-if="showIllustratorButtons"
				class="illustrator-page-btns z-0 ml-3 flex gap-3 border-l border-gray-700 pl-2"
			>
				<button
					data-testid="clear-illustrator-selection"
					tooltip="Clear selection"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="clearIllustratorSelection"
				>
					<SvgIcon name="clean" class="h-4 w-4" />
				</button>

				<button
					data-testid="move-illustator-elements"
					tooltip="Move elements"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="moveIllustratorElements"
				>
					<SvgIcon name="blank" class="h-4 w-4" />
				</button>

				<button
					data-testid="delete-selection"
					tooltip="Remove elements"
					tooltip-position="top"
					class="flex h-6 w-6 items-center justify-center text-gray-300 hover:text-white"
					@click="removeIllustratorElements"
				>
					<SvgIcon name="trash" class="h-4 w-4" />
				</button>
			</div>

			<div
				v-if="!isEmbeddedContext"
				class="z-0 flex gap-3 border-l border-gray-700"
				:class="{
					'ml-3 pl-3': !isIllustratorContext || (project.pages.length > 1 && position === 0),
				}"
			>
				<TemplateButtons v-if="!isMobile" :page="(page as Page)" />
				<OnClickOutside v-if="isMobile" @trigger="showTools = false">
					<button
						data-testid="page-options"
						data-onboarding="pages"
						class="flex h-6 w-6 items-center justify-center rounded"
						@click="showTools = !showTools"
					>
						<SvgIcon
							name="more"
							class="h-4 w-4 rotate-90"
							:class="!showTools ? 'text-gray-300 hover:text-gray-100' : 'text-gray-100'"
						/>
					</button>
					<Popper v-if="showTools" placement="bottom-start" data-testid="page-options-menu">
						<div class="flex min-w-max flex-col items-start rounded bg-white px-1 py-1 shadow-lg">
							<TemplateButtons :page="(page as Page)" @close="showTools = false" />
						</div>
					</Popper>
				</OnClickOutside>
			</div>
		</div>
	</div>
</template>
