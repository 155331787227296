<script lang="ts" setup>
import { isEqual } from 'lodash';
import { computed, ref } from 'vue';

import CollectionListbox from '@/common/components/CollectionListbox.vue';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { AiPresentationLanguages, ItemList } from '@/Types/types';

const props = defineProps<{ lang?: AiPresentationLanguages }>();

const emits = defineEmits<{ (e: 'select', lang: AiPresentationLanguages): void }>();

const languages: ItemList[] = [{ name: 'auto', id: '0' }];

Object.values(AiPresentationLanguages).forEach((lang, index) => {
	languages.push({ name: lang, id: `${index + 1}` });
});

const selectedLanguage = ref<ItemList>(languages.find((t) => t.name === props.lang) || languages[0]);

const placeholder = computed(() => {
	return TextTools.capitalizeFirstLetter(selectedLanguage.value.name);
});

const onSelectListOption = (newVal: ItemList[]) => {
	if (isEqual(selectedLanguage.value, newVal)) return;
	if (newVal.length) {
		const lang = newVal[0].name as AiPresentationLanguages;

		emits('select', lang);
	}

	selectedLanguage.value = newVal[0];
};
</script>
<template>
	<div class="relative">
		<CollectionListbox
			class="appearance-none rounded border border-transparent bg-gray-900 text-sm text-white placeholder-gray-300/50 shadow-none placeholder:text-gray-500 focus:border-blue-500 focus:outline-none lg:rounded"
			:collection="languages"
			:placeholder="placeholder"
			:show-check="false"
			:custom-button-class="'h-11 w-full appearance-none bg-gray-900 pl-4 pr-12 text-sm text-gray-100 shadow-none focus:border-blue-500 focus:outline-none lg:rounded'"
			@selected="onSelectListOption"
		/>
	</div>
</template>
