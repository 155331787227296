import { createEventHook, createSharedComposable } from '@vueuse/core';

import Page from '@/page/classes/Page';

export const useViewer = createSharedComposable(() => {
	const viewerUpdateHook = createEventHook<Page>();

	return {
		trigger: viewerUpdateHook.trigger,
		onViewerChange: viewerUpdateHook.on,
	};
});
