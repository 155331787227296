import Element from '@/elements/element/classes/Element';
import { DomNodesElement } from '@/elements/element/dom/DomNodesElement';

export class DomNodesStoryset extends DomNodesElement {
	element: Element;
	constructor(element: Element) {
		super(element);

		this.element = element;
	}

	setSizeToOriginalNode(width: number, height: number) {
		super.setSizeToOriginalNode(width, height);

		if (!this.baseNode) return;
		const originalNodes = this.baseNode.querySelectorAll('.original') as NodeListOf<HTMLElement>;
		originalNodes.forEach((node) => {
			node.style.width = `${width}px`;
			node.style.height = `${height}px`;
		});
	}
}
