<script setup lang="ts">
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextUnderline } from '@/elements/texts/text/composables/useTextUnderline';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Text }>();
const element = toRef(props, 'element');

// Using composables

const { hasUnderline, updateUnderline } = useTextUnderline(element);
const { isCircleText } = useCircleTypeInfo(element);
const { trans } = useI18n();

const clickUnderline = () => {
	updateUnderline();
};
</script>

<template>
	<div class="flex flex-col">
		<button
			data-testid="text-underline"
			:disabled="isCircleText"
			class="flex h-8 w-full items-center justify-center rounded px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700"
			:class="{
				'cursor-not-allowed opacity-25': isCircleText,
				'hover:bg-gray-600': !isCircleText && !hasUnderline,
				'bg-gray-600 hover:bg-gray-500': hasUnderline,
				'bg-gray-700': !hasUnderline,
			}"
			data-title="alignText"
			@click="clickUnderline"
		>
			<SvgIcon name="underline" class="h-4 w-4" />
		</button>
		<label
			class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700"
			:class="isCircleText ? 'opacity-50' : ''"
		>
			{{ trans('Underline') }}
		</label>
	</div>
</template>
