<script lang="ts" setup>
import { cloneDeep } from 'lodash-es';
import { computed, InputHTMLAttributes } from 'vue';

import { SolidColor } from '@/color/classes/SolidColor';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ color: SolidColor }>();
const emit = defineEmits<{ (e: 'change', color: Color): void }>();
const { isMobile } = useDeviceInfo();
const opacity = computed(() => Math.round(props.color.a * 100));
const { trans } = useI18n();

const updateOpacity = (e: Event) => {
	const color = cloneDeep(props.color);
	color.a = (e.target as InputHTMLAttributes).value / 100;
	emit('change', color);
};
</script>

<template>
	<Teleport :to="isMobile ? '.vc-sketch-sliders' : '.vc-sketch-field'">
		<div class="col-span-4 mt-4 flex items-center lg:mt-0 lg:flex-col lg:items-start">
			<div class="flex flex-1 items-center">
				<div class="input-range relative flex w-full">
					<input
						type="range"
						min="0"
						max="100"
						:value="opacity"
						class="input-range h-2 w-full cursor-pointer appearance-none rounded-full bg-gray-900 focus:outline-none lg:h-1"
						@input="updateOpacity"
					/>
					<span
						:style="`width: ${opacity}%`"
						class="absolute left-0 top-[2px] h-[4px] rounded-full bg-blue-500 slidesgo:bg-purple-400 lg:top-[1px] lg:h-[2px]"
					></span>
				</div>
				<div class="ml-2 w-8 text-2xs text-gray-100">{{ opacity }}%</div>
			</div>
		</div>
	</Teleport>
</template>
<style lang="sass" scoped>
.input-range
	box-shadow: 0 0 0 1px #2a3742 inset

	@media (max-width: 1023px)
		box-shadow: 0 0 0 2px #374957 inset
</style>
