// @ts-ignore
import { MaybeElement, until, useDebounceFn, useIdle, useIntersectionObserver } from '@vueuse/core';
import domtoimage from 'dom-to-image-more';
import { computed, nextTick, Ref, ref, watch } from 'vue';

import { RenderQueue } from '@/common/composables/useTaskQueue';
import { FilesTools } from '@/common/utils/FilesTools';
import { useMainStore } from '@/editor/stores/store';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { useHistoryStore } from '@/history/stores/history';
import EventTools from '@/interactions/classes/EventTools';
import Page from '@/page/classes/Page';
import { useDebouncedPage } from '@/page/composables/useDebouncedPage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';

export function useCanvasPreview(
	container: Ref<HTMLElement>,
	page: Ref<Page>,
	scale: Ref<number>,
	scrollArea: Ref<HTMLDivElement | string | null | undefined>
) {
	const shouldMount = ref(false);
	const { debouncedPage } = useDebouncedPage(page, 300, scale);
	const isTemporal = computed(() => page.value.name === 'temporal');

	const realScrollArea = computed<MaybeElement>(() => {
		if (!scrollArea.value) {
			return null;
		}
		return typeof scrollArea.value === 'string'
			? document.querySelector<HTMLElement>(scrollArea.value)
			: scrollArea.value;
	});

	const startRendering = async () => {
		// Las páginas 'iniciales' no se renderizan
		if (isTemporal.value) {
			await until(isTemporal).not.toBeTruthy();
		}

		RenderQueue.enqueue(async () => {
			shouldMount.value = true;
		});
	};

	// Si el elemento esta en pantalla, iniciamos el proceso de render mandandolo a la cola
	const observer = useIntersectionObserver(
		container,
		([{ isIntersecting }]) => {
			if (isIntersecting) {
				startRendering();
				observer.stop();
			}
		},
		{
			threshold: 0,
			rootMargin: '0px',
			root: realScrollArea.value,
		}
	);

	return {
		shouldMount,
		debouncedPage,
	};
}

export function useCanvasPreviewGenerator(
	scrollContainer: Ref<HTMLElement | null>,
	page: Ref<Page>,
	scale: Ref<number>
) {
	const store = useMainStore();
	const project = useProjectStore();
	const history = useHistoryStore();
	const { idle } = useIdle(5000, { initialState: false });
	const rendering = ref(false);

	const render = async () => {
		if (history.states.length < 2) return;
		await until(rendering).not.toBeTruthy();

		rendering.value = true;

		await until(idle).toBeTruthy();

		await until(() => store.proccesingCachedElements).toBe(0);
		await ImageTools.allPageImagesLoaded();
		await nextTick();
		await EventTools.idleCallback();

		RenderQueue.enqueue(async () => {
			if (scrollContainer && !scrollContainer.value) return;
			const realCanvas = scrollContainer.value?.querySelector('.canvas') as HTMLElement;
			const { width, height } = realCanvas.getBoundingClientRect();

			await until(idle).toBeTruthy();

			const blob = await domtoimage.toBlob(realCanvas, {
				width: width,
				height: height,
				style: {},
				/*onclone: (doc) => {
				},*/
				filter: (doc: HTMLElement) => {
					// Solucionamos problemas de cors en la copia
					if (doc instanceof HTMLImageElement) {
						doc.src = ImageTools.getImageUrlViaProxy(doc.src);
					}

					return true;
				},
			});

			// Si es la primera página del doc, la guardamos en local storage para mostrarla
			// por la web

			FilesTools.blobToBase64(blob).then((base64) => {
				window.localStorage.removeItem(window.localStorage.getItem('last-preview') || 'none');
				window.localStorage.setItem(`preview-${project.id}`, base64 as string);
				window.localStorage.setItem('last-preview', `preview-${project.id}`);
			});

			rendering.value = false;
			await nextTick();
		});
	};
	const { debouncedPage } = useDebouncedPage(page, 5000, scale);

	watch([debouncedPage], render);

	return {
		debouncedPage,
	};
}
