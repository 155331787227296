import { useDebounceFn } from '@vueuse/core';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import Image from '@/elements/medias/images/image/classes/Image';
import { useMaskeableElement } from '@/elements/medias/mask/composables/useMaskeableElement';
import { useReplaceElement } from '@/elements/medias/replace/useReplaceElement';
import { Video } from '@/elements/medias/video/classes/Video';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useMoveable } from '@/interactions/composables/useInteractiveElements';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { PrimaryElementTypes } from '@/Types/types';

export const useCollisionHandlerOnDrag = (lookFor?: Ref<PrimaryElementTypes[]>) => {
	const { selection, clearSelection } = useSelection();
	const store = useMainStore();

	const hoveredElement = ref<Element | undefined>();
	const temporalRef = ref<Element>(Image.create());
	const ignoreTempRefSet = ref(false);
	const draggingElement = computed(() =>
		ignoreTempRefSet.value ? draggingElement.value : selection.value.length === 1 ? selection.value[0] : undefined
	) as ComputedRef<Element>;

	const draggingElementPosition = computed(() => draggingElement.value?.position || undefined);

	const { page } = usePageElement(temporalRef);
	const { isDragging } = useInteractions();
	const { isBackground } = useIsBackground(temporalRef as Ref<Image>);
	const { replaceElement } = useReplaceElement(temporalRef as Ref<Image | Video>);
	const { applyMask } = useMaskeableElement(temporalRef as Ref<Image>);
	const { removeElement } = useActivePage();
	const { onDragHandler } = useMoveable();

	onDragHandler(
		useDebounceFn((data) => {
			const { position, element } = data;
			temporalRef.value = element;
			if (!isDragging.value) return;
			hoveredElement.value = page.value
				.elementsAsArray()
				.find(
					(el) =>
						(lookFor?.value ? lookFor.value.includes(el.type) : true) &&
						draggingElement.value &&
						draggingElement.value.id !== el.id &&
						el.id !== page.value.backgroundImageId &&
						el.isCollided(draggingElement.value, position)
				);
		}, 50)
	);

	watch(draggingElement, (newEl) => {
		temporalRef.value = newEl;
		if (!isDragging.value) {
			hoveredElement.value = undefined;
		}
	});

	watch([hoveredElement, isDragging], async (newVals, oldVals) => {
		if (window.moving || !store.finishedLoading) {
			return;
		}
		if (!draggingElement.value || !hoveredElement.value) return;

		const [newHoveredEl, newIsDragging] = newVals;
		const [, oldIsDragging] = oldVals;

		if (newIsDragging || !oldIsDragging) return;

		// Images collisioning on drop
		if (
			(draggingElement.value instanceof Image && newHoveredEl instanceof Image) ||
			(draggingElement.value instanceof Video && newHoveredEl instanceof Image) ||
			(draggingElement.value instanceof Image && newHoveredEl instanceof Video)
		) {
			if (!draggingElement.value.mask?.isPlaceholder && newHoveredEl.mask?.isPlaceholder) {
				await replaceMaskByMediaOnDrop(newHoveredEl);
				return;
			}

			if (draggingElement.value.mask?.isPlaceholder && !newHoveredEl.mask?.isPlaceholder) {
				await applyMaskToMediaOnDrop(newHoveredEl);
			}
		}
	});

	const applyMaskToMediaOnDrop = async (hoveredEl: Image | Video) => {
		if (!draggingElement.value || !(draggingElement.value instanceof Image)) return;
		ignoreTempRefSet.value = true;
		temporalRef.value = hoveredEl;
		await applyMask(draggingElement.value.metadata.maskApi);
		removeElement(draggingElement.value);
		clearSelection();
		hoveredElement.value = undefined;
		ignoreTempRefSet.value = false;
	};

	const replaceMaskByMediaOnDrop = async (hoveredEl: Image | Video) => {
		if (isBackground.value) return;
		if (
			!draggingElement.value ||
			(!(draggingElement.value instanceof Image) && !(draggingElement.value instanceof Video))
		)
			return;
		ignoreTempRefSet.value = true;
		temporalRef.value = hoveredEl;

		let type = 'image';
		if (draggingElement.value instanceof Video) type = 'video';

		// Some existing medias in page has no metadata.imageApi so it has to be set in that case
		const dataApi = draggingElement.value.metadata.imageApi || {
			id: '',
			url: draggingElement.value.url,
			preview: draggingElement.value.preview,
			backgroundRemoved: null,
			type,
			data: [],
			links: [],
		};

		// If the dragged element has a filter, apply it to the hovered element
		if (
			(draggingElement.value instanceof Image || draggingElement.value instanceof Video) &&
			draggingElement.value.filter &&
			temporalRef.value instanceof Image
		) {
			temporalRef.value.filter = draggingElement.value.filter;
		}

		await replaceElement(dataApi);
		removeElement(draggingElement.value);

		if ((temporalRef.value instanceof Video || temporalRef.value instanceof Image) && temporalRef.value.mask) {
			temporalRef.value.mask.isPlaceholder = false;
		}
		clearSelection();
		hoveredElement.value = undefined;
		ignoreTempRefSet.value = false;
	};

	return {
		draggingElement,
		hoveredElement,
		isDragging,
	};
};
