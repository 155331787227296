<script lang="ts" setup>
import { ref, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import PhotosPanel from '@/elements/medias/images/image/components/panels/add/PhotosPanel.vue';
import NotPossibleInPhotoModeException from '@/elements/medias/replace/Exception/NotPossibleInPhotoModeException';
import { useReplaceElement } from '@/elements/medias/replace/useReplaceElement';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import { ImageApi, UploadApi } from '@/Types/apiClient';
import { EditPanels } from '@/Types/types';

// Props
const props = defineProps<{ element: Image | Video }>();
const element = toRef(props, 'element');
const store = useMainStore();
const toast = useToast();

const { trans } = useI18n();
const { replaceElement } = useReplaceElement(element);

// Data
const replacing = ref(false);

// Methods
const onCloseModal = () => (replacing.value = false);

const replaceImage = async (image: UploadApi | ImageApi) => {
	if (image.type === 'svg') {
		toast.warning('Your svg has been uploaded, but it is not possible to replace a photo by an svg');
		replacing.value = false;
		return;
	}

	try {
		await replaceElement(image);
	} catch (error) {
		if (error instanceof NotPossibleInPhotoModeException) {
			toast.warning(error.message);
			return;
		}
		toast.warning('Something went wrong');
	} finally {
		replacing.value = false;
	}

	if (store.editPanel === EditPanels.ImagePresets) {
		store.editPanel = null;
	}
};

const openModal = () => {
	replacing.value = true;

	GAnalytics.trackDebounceEditBarGA4({ category: 'photo', link_text: 'replace' });
};
</script>

<template>
	<div class="mockup:hidden">
		<div>
			<button
				:tooltip="trans('Replace')"
				tooltip-position="top"
				data-button-importance="normal"
				data-testid="replace-img"
				class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 outline-none hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-full lg:flex-row lg:justify-center lg:px-2"
				@click="openModal"
			>
				<SvgIcon name="replace" class="h-4 w-4" />
				<span class="button-label text-2xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{ trans('Replace') }}</span>
			</button>
		</div>

		<Modal
			v-if="replacing"
			content-classes="h-[70vh] lg:h-[90vh] y-8 rounded w-full lg:w-auto lg:max-w-lg lg:min-w-lg relative bg-white max-h-full py-4 lg:py-5 px-4 lg:px-6"
			open
			@close="replacing = false"
		>
			<PhotosPanel data-testid="replace-img-modal" :modal="true" @closeModal="onCloseModal" @selection="replaceImage" />
		</Modal>
	</div>
</template>
