import Element from '@/elements/element/classes/Element';
import { DomNodesElement } from '@/elements/element/dom/DomNodesElement';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { NodeCropData, NodeMediaData, NodeOriginalData, NodeRotationData } from '@/Types/DomNodesTypes';
export class DomNodesMedia extends DomNodesElement {
	element: Element;
	constructor(element: Element) {
		super(element);

		this.element = element;
	}

	refreshNode(element: Element) {
		return new DomNodesMedia(element);
	}

	get cropData(): NodeCropData | undefined {
		if (!this.baseNode) return;

		const node = this.baseNode.querySelector('.cropped') as HTMLElement;

		if (!node) return;
		const transformNode = node.style.transform;
		if (transformNode === '') {
			node.style.transform = `translate(0px, 0px)`;
		}

		const positionNode = (node.style.transform.match(/-?\d+\.?\d*/g) || []).map((val) => Number(val));
		const width = parseFloat(node.style.width);
		const height = parseFloat(node.style.height);

		return { cropPosition: { x: positionNode[0], y: positionNode[1] }, cropSize: { width, height } };
	}

	get rotationData(): NodeRotationData | undefined {
		if (!this.baseNode) return;
		const rotateValue = this.baseNode.style.transform.match(/rotate\((-?\d+(\.\d+)?)(deg)?\)/);
		if (!rotateValue) return;
		return { rotation: parseFloat(rotateValue[1]) };
	}

	get nodeData(): NodeMediaData {
		return {
			...(this.cropData as NodeCropData),
			...(super.nodeData as NodeOriginalData),
			...(this.rotationData as NodeRotationData),
		};
	}

	setSizeToOriginalNode(width: number, height: number) {
		super.setSizeToOriginalNode(width, height);
		if (!this.baseNode) return;
		const originalNodes = this.baseNode.querySelectorAll('.original') as NodeListOf<HTMLElement>;

		originalNodes.forEach((node: HTMLElement) => {
			node.style.width = `${width}px`;
			node.style.height = `${height}px`;
		});
	}

	setSizeToCroppedNode(width: number, height: number) {
		if (!this.baseNode) return;
		const croppedNode = this.baseNode.querySelector('.cropped') as HTMLElement;
		croppedNode.style.width = `${width}px`;
		croppedNode.style.height = `${height}px`;
	}

	setSizeInCropMode(target: HTMLElement | SVGElement, width: number, height: number) {
		target.style.width = `${width}px`;
		target.style.height = `${height}px`;
	}

	setPositionInCropMode(target: HTMLElement | SVGElement, x: number, y: number) {
		target.style.transform = `translate(${x}px, ${y}px)`;
	}

	setSizeToFilterNodes(width: number, height: number) {
		if (!this.baseNode) return;
		const filterNodes = this.baseNode.querySelectorAll('.filter-overlay') as NodeListOf<HTMLElement>;

		if (!filterNodes) return;
		filterNodes.forEach((node: HTMLElement) => {
			node.style.width = `${width}px`;
			node.style.height = `${height}px`;
		});
	}
	setPositionToOriginalNode(x: number, y: number) {
		super.setPositionToOriginalNode(x, y);
	}

	setRotationToOriginalNode(rotation: number, x?: number, y?: number) {
		if (!(this.element instanceof ForegroundImage)) {
			super.setRotationToOriginalNode(rotation);
			return;
		}

		if (!this.baseNode) return;
		this.baseNode.style.transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`;
	}

	setPositionToCroppedNode(x: number, y: number) {
		if (!this.baseNode) return;
		const croppedNode = this.baseNode.querySelector('.cropped') as HTMLElement;
		croppedNode.style.transform = `translate(${x}px, ${y}px) rotate(0deg)`;
	}
	setPositionToFilterNodes(x: number, y: number) {
		if (!this.baseNode) return;
		const filterNodes = this.baseNode.querySelectorAll('.filter-overlay') as NodeListOf<HTMLElement>;

		if (!filterNodes) return;
		filterNodes.forEach((node) => {
			node.style.transform = `translate(${x}px, ${y}px)`;
		});
	}
}
