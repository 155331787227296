import { defineStore } from 'pinia';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import Line from '@/elements/line/classes/Line';
import { Croppeable } from '@/elements/medias/crop/types/croppeable.type';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import { useSelection } from '@/interactions/composables/useSelection';
import { TemplateVariant } from '@/loader/types/templateLoaderData';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import {
	DraggableItem,
	EditPanels,
	ElementsPanelTypes,
	Panels,
	TemplateCategory,
	TemplateSuggestion,
	User,
	UserVector,
} from '@/Types/types';

export interface MainState {
	sharing: boolean;
	watchTextHeight: boolean;
	scale: number;
	scaleMaxAllowedSize: number; // Escala que aplicamos para el fix de memoría IOS
	user: User | null;
	activePanel: Panels | null;
	croppingId: string | null;
	activePageId: string | null;
	draggingItem: DraggableItem | null;
	disableDraggable: boolean;
	activeElementType: ElementsPanelTypes | null;
	activeTemplateCategory: TemplateCategory | null;
	projectCategory: TemplateCategory | null;
	pack: string | null;
	downloadTemplateCategory: TemplateCategory | null;
	userVector: UserVector | null;
	proccesingCachedElements: number;
	downloading: boolean;
	finishedLoading: boolean;
	customImagesId: string[];
	illustratorSelection: Set<string>;
	illustratorElementsMoved: Set<string>;
	templateSuggestions: TemplateSuggestion[];
	removingBackground: string[];
	lastBackgroundRemoved: string | null;
	editPanel: EditPanels | null;
	colorPalettes: TemplateVariant[];
	croppingTimeId: string | null;
	userExtensiblePanels: Panels[];
	inSchedules: boolean;
	disableBeforeUnload: boolean;
	uploads: Map<string | number, { url: string; preview: string }>;
	offScreenCanvas: { width: number; height: number; id: string }[];
	// TODO: Eliminar isRemoveElementOutsidePaused cuando se refactoricen los elementos al pegar en el artboard
	isRemoveElementOutsidePaused: boolean;
	generator: string;
	mobileNavigationPanelAutoHeight: boolean;
	dpi: number;
	inOnboarding: boolean;
	isFromFreepik: boolean;
	freepikPreview: string | null;
	draggingPage: Page | null;
}

export const useMainStore = defineStore('main', {
	state: (): MainState => ({
		sharing: false,
		watchTextHeight: true,
		scale: 0.5,
		scaleMaxAllowedSize: 1,
		user: null,
		userVector: null,
		activePanel: null,
		croppingId: null,
		activePageId: null,
		draggingItem: null,
		disableDraggable: false,
		activeElementType: null,
		activeTemplateCategory: null,
		projectCategory: null,
		pack: null,
		downloading: false,
		downloadTemplateCategory: null,
		proccesingCachedElements: 0,
		finishedLoading: false,
		customImagesId: [],
		illustratorSelection: new Set(),
		illustratorElementsMoved: new Set(),
		templateSuggestions: [],
		removingBackground: [],
		editPanel: null,
		lastBackgroundRemoved: null,
		colorPalettes: [],
		croppingTimeId: null,
		userExtensiblePanels: [],
		inSchedules: false,
		disableBeforeUnload: false,
		uploads: new Map<string | number, { url: string; preview: string }>(),
		offScreenCanvas: [],
		// TODO: Eliminar isRemoveElementOutsidePaused cuando se refactoricen los elementos al pegar en el artboard
		isRemoveElementOutsidePaused: false,
		generator: '',
		mobileNavigationPanelAutoHeight: false,
		dpi: 11.811,
		inOnboarding: false,
		isFromFreepik: false,
		freepikPreview: null,
		draggingPage: null,
	}),
	getters: {
		cropping(state): Croppeable | null {
			const project = useProjectStore();
			return project.getElementById(state.croppingId) as Image | Video;
		},
		croppingTime(state): Video | null {
			const project = useProjectStore();
			return project.getElementById(state.croppingTimeId) as Video;
		},

		activePage(state): Page | null {
			const project = useProjectStore();
			return (project.pages as Page[]).find((page) => page.id === state.activePageId) || null;
		},
	},

	actions: {
		setScale(scale: number) {
			this.scale = scale;
		},

		setEditPanelMobile(element: Element) {
			const { isMobile } = useDeviceInfo();
			if (!isMobile.value) return;
			if (element instanceof ForegroundImage || (element instanceof Image && element.backgroundMode === 'background'))
				this.editPanel = EditPanels.ImageLayerSelector;
			if (element instanceof Image && element.backgroundMode === 'original') this.editPanel = EditPanels.Image;
			if (element instanceof Shape) this.editPanel = EditPanels.Shape;
			if (element instanceof Box) this.editPanel = EditPanels.Box;
			if (element instanceof Storyset) this.editPanel = EditPanels.Storyset;
			if (element instanceof Text) this.editPanel = EditPanels.Text;
			if (element instanceof Line) this.editPanel = EditPanels.Line;
			if (element instanceof QRCode) this.editPanel = EditPanels.QRCode;
			if (element instanceof Video) this.editPanel = EditPanels.Video;
		},

		setActivePage(page: Page, force = false) {
			const { clearSelection, selection } = useSelection();
			if (selection.value.length && !force) return;
			if (this.activePageId === page.id) return;
			if (selection.value.length) {
				clearSelection();
			}
			this.activePageId = page.id;
		},
	},
});
