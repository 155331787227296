<script lang="ts" setup>
import { useFullscreen } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useUsersnap } from '@/analytics/usersnap/composables/useUsersnap';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import Viewer from '@/editor/components/Viewer.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProject } from '@/project/composables/useProject';
import { useProjectControls } from '@/project/composables/useProjectControls';
import { useProjectStore } from '@/project/stores/project';

const projectStore = useProjectStore();
const { trans } = useI18n();
const present = ref(false);
const videoPlayerMode = ref(false);
const activePage = ref(0);
const { loadUserSnap, showUsersnap } = useUsersnap();
const { inSlidesgoContext } = useEditorMode();
const store = useMainStore();
const { selection, clearSelection } = useSelection();

const project = computed(() => projectStore);
const { durationInMMSSFormat } = useProjectControls();
const { PROD } = useEnvSettings();
const { isAiPresentation, projectIsPresentation } = useProject();

watch(present, (val) => {
	if (!inSlidesgoContext.value) {
		return;
	}

	if (val && PROD) {
		loadUserSnap();
		return;
	}

	if (window.localStorage.getItem('usersnap')) {
		return;
	}
	if (PROD) {
		showUsersnap();
		window.localStorage.setItem('usersnap', 'true');
	}
});

// Methods
const prevSlide = () => {
	if (activePage.value === 0) return;
	activePage.value -= 1;
};

const nextSlide = () => {
	if (activePage.value === totalPages.value - 1) return;

	activePage.value += 1;
};
const totalPages = computed(() => project.value.pages.length);

const { isIOS } = useDeviceInfo();
const { isSupported } = useFullscreen();

const openPresent = (video = false) => {
	activePage.value = project.value.pages.findIndex((p) => p.id === store.activePage?.id);
	present.value = true;
	videoPlayerMode.value = video;
	clearSelection();
	if (store.activePanel) store.activePanel = null;
	GAnalytics.trackGA4('present');
};

const closePresent = () => {
	present.value = false;
	activePage.value = 0;
};
</script>

<template>
	<button
		v-if="project.allVideos.length"
		data-testid="video-present-btn"
		class="relative flex h-8 items-center justify-center rounded bg-gray-600 px-2 font-bold text-white hover:bg-gray-500 lg:h-10 lg:px-3"
		:tooltip="trans('Play video')"
		tooltip-position="bottom"
		@click="openPresent(true)"
	>
		<SvgIcon name="play" class="mr-2 h-4 w-4" />
		{{ durationInMMSSFormat }}
	</button>
	<button
		v-if="!inSlidesgoContext && !projectIsPresentation && !isAiPresentation"
		class="relative flex h-8 items-center justify-center rounded px-2 text-white/50 focus:text-white hover:bg-gray-500 hover:text-white lg:h-10 lg:w-10 lg:bg-gray-600 lg:px-0 lg:text-gray-100"
		:tooltip="trans('Present')"
		tooltip-position="bottom"
		@click="openPresent()"
	>
		<SvgIcon name="presentation" class="h-4 w-4" />
	</button>
	<button
		v-else
		data-onboarding="btn-present"
		data-testid="btn-present"
		class="relative flex h-8 w-8 items-center justify-center rounded bg-green-500 font-bold text-white hover:bg-green-600 lg:h-10 lg:w-auto lg:px-6 lg:py-3"
		:tooltip="trans('Present')"
		tooltip-position="bottom"
		@click="openPresent()"
	>
		<SvgIcon name="presentation" class="h-4 w-4 sm:mr-2" /> <span class="hidden sm:block">{{ trans('Present') }}</span>
	</button>
	<teleport to="body">
		<div v-if="present" class="fixed inset-0 z-50 bg-gray-900">
			<Viewer
				:project="(project as any)"
				:fullscreen="!isIOS"
				loaded
				:video-player-mode="videoPlayerMode"
				:page="activePage"
				@fullscreenExit="closePresent"
				@activePage="activePage = $event"
			>
				<template v-if="!isSupported || (isIOS && !project.allVideos.length)">
					<button class="absolute right-0 top-0 z-10 m-4 text-gray-100" @click="closePresent">
						<SvgIcon name="fullscreen-invert" class="h-6 w-6 fill-current" />
					</button>
					<div
						class="absolute bottom-0 left-0 flex h-14 w-full items-center justify-between bg-gray-800 pl-2 pr-4 shadow-lg lg:relative lg:z-30 lg:px-4"
					>
						<div class="mx-auto flex items-center justify-center lg:ml-0 lg:w-1/3">
							<div
								v-if="!videoPlayerMode"
								data-testid="current-page-viewer"
								class="flex h-9 items-center rounded bg-realblack/25 font-semibold text-gray-100"
							>
								<button class="flex h-10 w-10 items-center justify-center" @click="prevSlide">
									<SvgIcon name="arrow" class="h-full w-full rotate-90 transform fill-current p-2" />
								</button>
								{{ activePage + 1 }} / {{ totalPages }}
								<button class="flex h-10 w-10 items-center justify-center" @click="nextSlide">
									<SvgIcon name="arrow" class="h-full w-full -rotate-90 transform fill-current p-2" />
								</button>
							</div>
						</div>
					</div>
				</template>
			</Viewer>
		</div>
	</teleport>
</template>
