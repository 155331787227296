import { isEqual } from 'lodash';
import { defineStore } from 'pinia';

import { SolidColor } from '@/color/classes/SolidColor';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import Line from '@/elements/line/classes/Line';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import Page from '@/page/classes/Page';
import Project from '@/project/classes/Project';
import { Color } from '@/Types/colorsTypes';

export const useProjectStore = defineStore('project', {
	// @ts-ignore
	state: (): Project => window.forceProject || new Project(800, 800, 'px', 3170, 1),
	getters: {
		getElementById: (state) => {
			return (id: string | null): Element | null => {
				if (!id) return null;
				const result = state.pages.flatMap((t) => Object.values(t.elementsAsArray())).find((e) => e.id === id);
				return result || null;
			};
		},
		getPageById: (state) => {
			return (id: string | null): Page | null => {
				if (!id) return null;
				return (state.pages.filter((t) => t.id === id)[0] as Page) || null;
			};
		},
		allElements: (state): Element[] => {
			return state.pages.flatMap((t) => Object.values(t.elementsAsArray())) as Element[];
		},
		allShapes: (state): Shape[] => {
			return state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'shape') as Shape[];
		},
		allBoxes: (state): Box[] => {
			return state.pages.flatMap((t) => Object.values(t.elementsAsArray())).filter((e) => e.type === 'box') as Box[];
		},
		allTexts: (state): Text[] => {
			return state.pages.flatMap((t) => Object.values(t.elementsAsArray())).filter((e) => e.type === 'text') as Text[];
		},
		allImages: (state): Image[] => {
			return state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'image') as Image[];
		},
		allForegrounds: (state): ForegroundImage[] => {
			return state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'foregroundImage') as ForegroundImage[];
		},
		allStorysets: (state): Storyset[] => {
			return state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'storyset') as Storyset[];
		},
		allLines: (state): Line[] => {
			return state.pages.flatMap((t) => Object.values(t.elementsAsArray())).filter((e) => e.type === 'line') as Line[];
		},
		allQRCodes: (state): QRCode[] => {
			return state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'qrcode') as QRCode[];
		},
		allVideos: (state): Video[] => {
			return state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'video') as Video[];
		},
		allMedias: (state): Array<Image | Video> => {
			const images = state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'image') as Image[];
			const videos = state.pages
				.flatMap((t) => Object.values(t.elementsAsArray()))
				.filter((e) => e.type === 'video') as Video[];

			return [...images, ...videos];
		},
	},
});
