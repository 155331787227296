<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { useScroll } from '@vueuse/core';
import { computed, onBeforeMount, ref, watch } from 'vue';

import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';

const emits = defineEmits<{ (e: 'select', value: any): void }>();

const select = ref();
const nextBookmark = ref();
const boards = ref<BoardItemSchema[]>([]);
const selectedPinterestBoard = ref();
const loading = ref(false);
const hasMore = ref(true);

interface BoardItemSchema {
	id: string;
	name: string;
}

interface BoardSchema {
	bookmark: string | null;
	items: BoardItemSchema[];
}

const getBoards = async () => {
	if (loading.value || !hasMore.value) return;

	let url = `pinterest/boards`;

	if (nextBookmark.value) {
		url += `?bookmark=${nextBookmark.value}`;
	}

	loading.value = true;

	const { data } = await useEditorApiFetch<BoardSchema>(url).get().json();

	nextBookmark.value = data.value.bookmark;
	boards.value = boards.value.concat(data.value.items);

	hasMore.value = data.value.items.length === 10;
	loading.value = false;
};

const pinterestBoardsOptions = computed(() => {
	return boards.value.map((board) => {
		return {
			label: board.name,
			value: board.id,
		};
	});
});

// Hack para detectar cuando se ha deseleccionado
watch(selectedPinterestBoard, (newValue) => {
	if (!newValue) {
		emits('select', null);
	}
});

watch(select, () => {
	if (!select.value) return;

	const scroll = useScroll(select.value.$el.querySelector('.multiselect-dropdown'), {
		offset: {
			bottom: 30,
		},
		onStop() {
			if (scroll.arrivedState.bottom) {
				getBoards();
			}
		},
	});
});

onBeforeMount(() => getBoards());
</script>
<template>
	<Multiselect
		ref="select"
		v-model="selectedPinterestBoard"
		mode="single"
		placeholder="Board"
		:classes="{
			container: 'multiselect text-gray-600 !text-sm',
			containerActive: 'border-gray-800',
			tag: 'bg-blue-500 slidesgo:bg-purple-400 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
			groupLabelSelected: 'bg-blue-500 slidesgo:bg-purple-400 text-white',
			groupLabelSelectedPointed: 'bg-blue-500 slidesgo:bg-purple-400 text-white opacity-90',
			groupLabelSelectedDisabled: 'text-blue-100 bg-blue-500 slidesgo:bg-purple-400 bg-opacity-50 cursor-not-allowed',
			optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
			optionSelectedPointed: 'text-white bg-blue-500 slidesgo:bg-purple-400 opacity-90',
			optionSelectedDisabled: 'text-blue-100 bg-blue-500 slidesgo:bg-purple-400 bg-opacity-50 cursor-not-allowed',
		}"
		:hide-selected="false"
		:can-clear="false"
		:options="pinterestBoardsOptions"
		@select="emits('select', selectedPinterestBoard)"
	/>
</template>
<style lang="sass">
.multiselect-single-label
	@apply pr-4
</style>
