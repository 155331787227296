import HeaderWelcomeFreepikPhotos from '@/assets/header-welcome-freepik-photos.jpg';
import HeaderWelcomePhotos from '@/assets/header-welcome-photos.jpg';
import HeaderWelcomeSlidesgo from '@/assets/header-welcome-slidesgo.jpg';
import HeaderWelcomeSlidesgoEditor from '@/assets/header-welcome-slidesgo-editor.jpg';
import HeaderWelcome from '@/assets/header-welcome-wepik.jpg';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

export type OnboardingTypes =
	| 'freepikPhoto'
	| 'photo'
	| 'presentation'
	| 'slidesgo'
	| 'slidesgoEditor'
	| 'slidesgoEditorMobile'
	| 'wepik'
	| 'wepikMobile';

export const onBoardingSteps = (onBoarding: OnboardingTypes) => {
	if (onBoarding === 'wepik')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcome,
					title: trans('Hey, there! Welcome to Wepik, Freepik’s online editor!'),
					description: trans(
						'This new tool allows you to customize templates to make your next project stand out or create your own designs without using any additional software. Download all your work - ready to print or use directly online!'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-templates"]',
				},
				content: {
					title: trans('Find the perfect template'),
					description: trans('Select a customizable template and start editing'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="project-colors"]',
				},
				content: {
					title: trans('Colors'),
					description: trans('Modify element colors in just one click.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="pages"]',
				},
				content: {
					title: trans('Add pages'),
					description: trans('Create as many pages as you want or copy them.'),
				},
				options: {
					popper: {
						placement: 'top-start',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-share"]',
				},
				content: {
					title: trans('Share your presentation with anyone'),
					description: trans(
						'Click on “Send link” to obtain a read-only link of the presentation, or share it right away by email'
					),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download'),
					description: trans('Once edited, download your creation - ready to print'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-present"]',
				},
				content: {
					title: trans('Present online'),
					description: trans('Once edited, click and present your slides online right away!'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="help"]',
				},
				content: {
					title: trans('Help'),
					description: trans('Need help with something? Click here'),
				},
				options: {
					popper: {
						placement: 'top',
					},
				},
			},
		];

	if (onBoarding === 'wepikMobile')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcome,
					title: trans('Hey, there! Welcome to Wepik, Freepik’s online editor!'),
					description: trans(
						'This new tool allows you to customize templates to make your next project stand out or create your own designs without using any additional software. Download all your work - ready to print or use directly online!'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-templates"]',
				},
				content: {
					title: trans('Find the perfect template'),
					description: trans('Select a customizable template and start editing'),
				},
				options: {
					popper: {
						placement: 'top',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'top',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="project-colors"]',
				},
				content: {
					title: trans('Colors'),
					description: trans('Modify element colors in just one click.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="pages"]',
				},
				content: {
					title: trans('Add pages'),
					description: trans('Create as many pages as you want or copy them.'),
				},
				options: {
					popper: {
						placement: 'top-start',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download it'),
					description: trans('Once edited, download your creation - ready to print or share it online.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
		];

	if (onBoarding === 'presentation')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcome,
					title: trans('Hey, there! Welcome to Wepik, Freepik’s online editor!'),
					description: trans(
						'This new tool allows you to customize presentation templates to make your projects stand out without using any additional software. Download all your work and engage your audience! Would you like to help us to improve the beta version? You can provide your feedback directly at support@wepik.com'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-templates"]',
				},
				content: {
					title: trans('Start editing online'),
					description: trans('Find a wide variety of graphic resources to boost your presentation.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="project-colors"]',
				},
				content: {
					title: trans('Colors'),
					description: trans('Modify element colors in just one click.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="pages"]',
				},
				content: {
					title: trans('Add pages'),
					description: trans('Create as many pages as you want or copy them.'),
				},
				options: {
					popper: {
						placement: 'top-start',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-share"]',
				},
				content: {
					title: trans('Share your presentation with anyone'),
					description: trans(
						'Click on “Send link” to obtain a read-only link of the presentation, or share it right away by email'
					),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download it'),
					description: trans(
						`Save a copy of your presentation by downloading it in your preferred format: 
					PDF, PNG, or JPEG`
					),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-present"]',
				},
				content: {
					title: trans('Present online'),
					description: trans('Once edited, click and present your slides online right away!'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
		];

	if (onBoarding === 'slidesgo')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcomeSlidesgo,
					title: trans("Hey there! Welcome to Wepik, Slidesgo's online editor!"),
					description: trans(
						'This new tool allows you to customize presentation templates to make your projects stand out without using any additional software. Download all your work and engage your audience! Would you like to help us to improve the beta version? You can provide your feedback directly at support@wepik.com'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-templates"]',
				},
				content: {
					title: trans('Start editing online'),
					description: trans('Find a wide variety of graphic resources to boost your presentation.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="project-colors"]',
				},
				content: {
					title: trans('Colors'),
					description: trans('Modify element colors in just one click.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="pages"]',
				},
				content: {
					title: trans('Add pages'),
					description: trans('Create as many pages as you want or copy them.'),
				},
				options: {
					popper: {
						placement: 'top-start',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-share"]',
				},
				content: {
					title: trans('Share your presentation with anyone'),
					description: trans(
						'Click on “Send link” to obtain a read-only link of the presentation, or share it right away by email'
					),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download it'),
					description: trans(
						`Save a copy of your presentation by downloading it in your preferred format: 
					PDF, PNG, or JPEG`
					),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-present"]',
				},
				content: {
					title: trans('Present online'),
					description: trans('Once edited, click and present your slides online right away!'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
		];

	if (onBoarding === 'photo')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcomePhotos,
					title: trans('Hey there! Welcome to Wepik Photo Editor!'),
					description: trans(
						'This new feature allows you to customize photos easily without using any additional software. Make your own adjustments, such as cropping images, or applying fun filters quickly. Once edited, download your pic in jpg or png in just one click.'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="crop"]',
				},
				content: {
					title: trans('Crop images'),
					description: trans('Customize your photos and crop them to your liking.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="filter"]',
				},
				content: {
					title: trans('Apply filters'),
					description: trans('Enhance your images in one click with our awesome filters.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="filter-settings"]',
				},
				content: {
					title: trans('Settings'),
					description: trans('Make adjustments to give your photos a professional look.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download'),
					description: trans('Once edited, download your creation'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-templates"]',
				},
				content: {
					title: trans('Find a template'),
					description: trans('Boost your designs with our customizable templates. Explore our collection now!'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
		];

	if (onBoarding === 'freepikPhoto')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcomeFreepikPhotos,
					title: trans('Hey there! Welcome to Freepik Photo Editor!'),
					description: trans(
						'This new feature allows you to customize photos easily without using any additional software. Make your own adjustments, such as cropping images, or applying fun filters quickly. Once edited, download your pic in jpg or png in just one click.'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="crop"]',
				},
				content: {
					title: trans('Crop images'),
					description: trans('Customize your photos and crop them to your liking.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="filter"]',
				},
				content: {
					title: trans('Apply filters'),
					description: trans('Enhance your images in one click with our awesome filters.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="filter-settings"]',
				},
				content: {
					title: trans('Settings'),
					description: trans('Make adjustments to give your photos a professional look.'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download and share'),
					description: trans('Once edited, download your creation - ready to print'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-templates"]',
				},
				content: {
					title: trans('Find a template'),
					description: trans('Boost your designs with our customizable templates. Explore our collection now!'),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
		];

	if (onBoarding === 'slidesgoEditor')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcomeSlidesgoEditor,
					title: trans('Ready to brush up your skills with our new online editor?'),
					description: trans(
						'This tool lets you create dazzling presentations without the fuss of professional software. With user-friendly features, you can easily design with no unnecessary downloads or installs. Just pure creativity at your fingertips!'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-template-pages"]',
				},
				content: {
					title: trans('Build the perfect presentation'),
					description: trans(
						'Select the slides that fit best with your goals, duplicate and reorganize them to build your own story.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'right',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="project-colors"]',
				},
				content: {
					title: trans('Colors'),
					description: trans('Modify element colors in just one click.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="pages"]',
				},
				content: {
					title: trans('Add pages'),
					description: trans('Create as many pages as you want or copy them.'),
				},
				options: {
					popper: {
						placement: 'top-start',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download'),
					description: trans('Once edited, download your creation - ready to print'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
		];

	if (onBoarding === 'slidesgoEditorMobile')
		return [
			{
				attachTo: {
					element: '#onboarding',
				},
				content: {
					image: HeaderWelcomeSlidesgoEditor,
					title: trans('Ready to brush up your skills with our new online editor?'),
					description: trans(
						'This tool lets you create dazzling presentations without the fuss of professional software. With user-friendly features, you can easily design with no unnecessary downloads or installs. Just pure creativity at your fingertips!'
					),
				},
				options: {
					popper: {
						placement: 'auto',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-template-pages"]',
				},
				content: {
					title: trans('Build the perfect presentation'),
					description: trans(
						'Select the slides that fit best with your goals, duplicate and reorganize them to build your own story.'
					),
				},
				options: {
					popper: {
						placement: 'top',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-panel-elements"]',
				},
				content: {
					title: trans('Add text, elements, and images'),
					description: trans(
						'Explore through our collection of elements, add personalized text, replace photos, and more.'
					),
				},
				options: {
					popper: {
						placement: 'top',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="project-colors"]',
				},
				content: {
					title: trans('Colors'),
					description: trans('Modify element colors in just one click.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="pages"]',
				},
				content: {
					title: trans('Add pages'),
					description: trans('Create as many pages as you want or copy them.'),
				},
				options: {
					popper: {
						placement: 'top-start',
					},
				},
			},
			{
				attachTo: {
					element: '[data-onboarding="btn-download"]',
				},
				content: {
					title: trans('Download it'),
					description: trans('Once edited, download your creation - ready to print or share it online.'),
				},
				options: {
					popper: {
						placement: 'bottom',
					},
				},
			},
		];
};
