<script setup lang="ts">
import { ref } from 'vue';

import SaveAsCopyModal from '@/admin/components/SaveAsCopyModal.vue';
import UpdateVectorModal from '@/admin/components/UpdateVectorModal.vue';
import { useAdminActions } from '@/admin/composables/useAdminActions';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useProjectStore } from '@/project/stores/project';

const saving = ref<boolean>(false);

const { updateTemplate } = useAdminActions();
const toast = useToast();
const { isIllustratorContext } = useEditorMode();
const project = useProjectStore();
const store = useMainStore();

const checkVariantsStatus = () => {
	let checkFields = false;

	store.colorPalettes.forEach((variant: any) => {
		if (variant.name.trim().length < 1 || !variant.main_color) {
			checkFields = true;
		}
	});

	return checkFields;
};

const setVariants = (res: any) => {
	const variants = res.map((variant: any) => {
		return {
			...variant,
			color_palette: variant.color_palette.map((colorCollection: any) => {
				return {
					...colorCollection,
					color: colorCollection.color.type
						? GradientColor.unserialize(colorCollection.color)
						: SolidColor.unserialize(colorCollection.color),
				};
			}),
		};
	});

	store.colorPalettes = variants;
};

const checkCanBeSaved = () => {
	if (saving.value) return false;

	const hasIllustratorElement = project.pages[0].elementsAsArray().some((el) => el.type === 'illustrator');
	if (isIllustratorContext.value && hasIllustratorElement) {
		toast.error('Hay que eliminar la ilustración');
		return false;
	}

	if (checkVariantsStatus()) {
		toast.error('Para actualizar las variantes tienes que rellenar todos los campos');
		return false;
	}

	return true;
};

const promptUpdateReason = async (reason: string, finished: boolean) => {
	if (!checkCanBeSaved()) {
		return;
	}

	saving.value = true;

	try {
		const res = await updateTemplate(reason, finished);
		setVariants(res);

		toast.success('Template updated!');
	} catch (e) {
		toast.error('Error to save template');
	}
	saving.value = false;
};

const showCreateCopyModal = ref(false);

const saveCopy = async () => {
	if (!checkCanBeSaved()) {
		return;
	}

	saving.value = true;

	try {
		const res = await updateTemplate('Copy');
		setVariants(res);

		toast.success('Template duplicated!');
	} catch (e) {
		toast.error('Error while duplicating the save template');
	}
	showCreateCopyModal.value = false;
	saving.value = false;
};

const showSaveModal = ref(false);
</script>

<template>
	<div class="flex flex-row">
		<button
			data-testid="copy-template"
			class="relative mr-2 flex h-10 w-8 items-center justify-center rounded bg-blue-500 font-bold text-white hover:bg-blue-600 slidesgo:bg-purple-400 lg:w-auto lg:px-6 lg:py-3"
			@click="showCreateCopyModal = true"
		>
			<span v-if="saving">
				<SvgIcon name="spinner" class="h-4 w-4 animate-spin" />
			</span>
			<span v-else class="flex items-center justify-center">
				<SvgIcon name="copy" class="h-4 w-4 lg:mr-2" />
				<span class="hidden lg:block">Copy template</span>
			</span>
		</button>
		<button
			data-testid="update-template"
			class="relative mr-2 flex h-10 w-8 items-center justify-center rounded bg-yellow-500 font-bold text-white hover:bg-yellow-600 lg:w-auto lg:px-6 lg:py-3"
			@click="showSaveModal = true"
		>
			<span v-if="saving">
				<SvgIcon name="spinner" class="h-4 w-4 animate-spin" />
			</span>
			<span v-else class="flex items-center justify-center">
				<SvgIcon name="save" class="h-4 w-4 lg:mr-2" />
				<span class="hidden lg:block">Update template</span>
			</span>
		</button>
	</div>

	<SaveAsCopyModal v-if="showCreateCopyModal" @save="saveCopy" @close="showCreateCopyModal = false" />
	<UpdateVectorModal
		v-if="showSaveModal"
		:saving="saving"
		@close="showSaveModal = false"
		@update="promptUpdateReason($event.reason, $event.finished)"
	/>
</template>
