<script lang="ts" setup>
import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { Color } from '@/Types/colorsTypes';

defineProps<{ colors: Color[]; selected: Color }>();
const emit = defineEmits<{ (e: 'change', color: Color): void }>();

const selectColor = (color: Color) => {
	const clonedColor = cloneDeep(color);
	if (clonedColor instanceof GradientColor) {
		clonedColor.id = 'color-' + uuidv4();
	}
	emit('change', clonedColor);
};
</script>

<template>
	<div
		v-for="color in colors"
		:key="color.toCssString()"
		class="vc-sketch-presets-color shadow-none"
		:class="color.toCssString() === selected.toCssString() ? 'preset-active relative' : ''"
		:style="{ background: color.toCssString() }"
		@click="selectColor(color)"
	></div>
</template>
<style lang="sass">
.preset-active::after
	content: ''
	@apply absolute inset-0 rounded-full border-2 border-gray-800 m-[2px]
</style>
