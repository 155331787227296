import { computed, ComputedRef, Ref } from 'vue';

import { usePageElement } from '@/elements/element/composables/usePageElement';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import Page from '@/page/classes/Page';

export const useForeground = (element: Ref<ForegroundImage>, previewPage?: Ref<Page>) => {
	const { page } = usePageElement(element);

	const imageBackground = computed(() => {
		const currentPage = previewPage?.value ? previewPage.value : page.value;

		return (
			currentPage &&
			(currentPage
				.elementsAsArray()
				.filter((el) => el instanceof Image)
				.find((image) => image.id === element.value.image) as Image | undefined)
		);
	});
	return {
		imageBackground,
	};
};
