<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import FlipMenu from '@/elements/element/components/menus/FlipMenu.vue';
import OpacitySlider from '@/elements/element/components/menus/OpacitySlider.vue';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformMenu from '@/elements/element/components/menus/TransformMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import CropButton from '@/elements/medias/crop/components/buttons/CropButton.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import ReplaceImageButton from '@/elements/medias/images/image/components/buttons/ReplaceImageButton.vue';
import ToBackgroundButton from '@/elements/medias/images/image/components/buttons/ToBackgroundButton.vue';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Image }>();

const element = toRef(props, 'element');

const { isPhotoMode } = useEditorMode();
const { isGrouped } = useGroup(element);
const { closePanel } = usePanelManagement();
const { trans } = useI18n();
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100 mockup:text-fp-gray-700">{{ trans('Edit image') }}</h2>
		<button
			data-testid="close-panel"
			class="text-gray-100 focus:outline-none hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800"
			@click="closePanel()"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<!-- Edition -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-800">
			{{ trans('Edition') }}
		</h4>

		<div class="mb-6 flex">
			<div class="w-1/3">
				<FlipMenu :inline-label="true" :element="element" />
			</div>

			<div class="w-1/4 pl-2">
				<CropButton :label-position="'bottom'" :element="element" />
			</div>

			<div class="w-1/4 pl-2">
				<ReplaceImageButton :label-position="'bottom'" :element="element" />
			</div>

			<div v-if="!isPhotoMode" class="w-1/4 pl-2">
				<ToBackgroundButton :element="element" />
			</div>
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="false" />

		<OpacitySlider :element="element" :opacity="element.opacity" />
	</div>
</template>
