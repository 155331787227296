<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAiEditorStore } from '@/apps/aieditor/store';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { CreditEventInfo, Pack } from '@/Types/types';

const store = useAiEditorStore();
const props = defineProps<{
	packs: Pack[];
}>();

const { APP_BASE } = useEnvSettings();
const packSelectedId = ref(props.packs[1].pack);

const packSelected = computed(() => {
	return props.packs.find((p) => p.pack === packSelectedId.value);
});

const onChangePack = (pack: Pack) => {
	GAnalytics.trackGA4('ai-editor-select-pack', { action: 'select_pack', pack: pack.pack });
};

const onClickBuyCredits = () => {
	GAnalytics.trackGA4('ai-editor-buy-credits', { link_text: 'buy_credits' });

	if (packSelected.value) {
		const items: CreditEventInfo = {
			item_name: `wepik_na_${packSelected.value.pack}`,
			item_id: packSelected.value.code,
			price: packSelected.value.price,
			item_brand: 'wepik',
			item_category: packSelected.value.pack,
			item_category2: 'na',
			item_category3: 'full_price',
			item_list_name: 'editor',
			coupon: '',
			index: props.packs.findIndex((p) => p.pack === packSelectedId.value) + 1,
			quantity: 1,
		};

		window.dataLayer.push({ ecommerce: null });

		GAnalytics.trackGA4('add_to_cart', {
			ecommerce: {
				process_name: 'credits_ai_checkout',
				currency: `${props.packs[0].currency}`,
				items,
			},
		});
	}
};

onMounted(() => {
	let items: CreditEventInfo[] = [];

	props.packs.forEach((pack, index) => {
		const packName = `${pack.pack.slice(0, -1)}-${pack.pack.slice(-1)}`;
		items.push({
			item_name: `wepik_na_${packName}`,
			item_id: pack.code,
			price: pack.price,
			item_brand: 'wepik',
			item_category: packName,
			item_category2: 'na',
			item_category3: 'full_price',
			item_list_name: 'editor',
			coupon: '',
			index: index + 1,
			quantity: 1,
		});
	});

	window.dataLayer.push({ ecommerce: null });

	GAnalytics.trackGA4('view_item_list', {
		ecommerce: {
			process_name: 'credits_ai_checkout',
			currency: `${props.packs[0].currency}`,
			items,
		},
	});
});
</script>

<template>
	<div v-if="packSelected" class="flex flex-col gap-4 pt-4 sm:pt-0">
		<div v-if="store.credits && store.credits > 0">
			<h3 class="mb-4 text-center text-xl font-semibold text-gray-700 sm:text-2xl">
				More credits = endless creativity!
			</h3>
			<p class="text-center text-gray-500 sm:px-16">
				Keep letting your imagination run wild with our flexible credit plans - use them only when you need to!
			</p>
		</div>
		<div v-else>
			<h3 class="mb-4 text-center text-xl font-semibold text-gray-700 sm:text-2xl">Keep the creativity going!</h3>
			<p class="text-center text-gray-500 sm:px-16">
				You have reached the limit of your free credits, but you can still unleash your imagination and create more AI
				images with any of our packs. They never expire!
			</p>
		</div>
		<div class="grid grid-cols-2 gap-4 sm:mt-4 sm:grid-cols-3 sm:gap-x-4 sm:gap-y-6">
			<div
				v-for="pack in packs"
				:key="pack.pack"
				class="relative last:col-span-2 last:mx-auto sm:last:col-span-1 sm:last:mx-0"
			>
				<input
					:id="pack.pack"
					v-model="packSelectedId"
					:value="pack.pack"
					name="packs"
					type="radio"
					class="peer hidden"
					@change="onChangePack(pack)"
				/>
				<label
					:for="pack.pack"
					class="flex cursor-pointer flex-col rounded-lg p-4 outline outline-1 outline-gray-100 peer-checked:outline-2 peer-checked:outline-purple-400"
				>
					<p class="mb-2 flex items-center gap-1.5 text-lg font-semibold text-gray-700">
						<SvgIcon name="coin" class="h-4 w-4 text-purple-400" />
						Pack {{ pack.credits / 100 }}
					</p>
					<p class="text-xl font-bold text-gray-900">{{ pack.price }} {{ pack.currency }}</p>
				</label>
				<span
					class="absolute right-2 top-2 hidden h-5 w-5 items-center justify-center rounded-full bg-gradient-to-r from-blue-500 to-purple-400 peer-checked:flex"
				>
					<SvgIcon name="check" class="h-2.5 w-2.5 text-white" />
				</span>
			</div>
		</div>
		<div
			class="flex items-center justify-center gap-2 rounded-lg bg-purple-100/50 px-2 py-2 text-center text-sm font-semibold text-purple-500"
		>
			<SvgIcon name="calculator" class="h-4 w-4" />
			You will have {{ packSelected.images }} images in {{ packSelected.images / 4 }} generations
		</div>
		<div
			class="flex flex-col-reverse items-baseline justify-between border-t border-gray-100/50 pt-4 sm:mt-6 sm:flex-row sm:pt-6"
		>
			<p class="mt-4 text-xs text-gray-600 sm:mt-0 sm:text-sm">
				Do you need help? Please write to
				<a href="mailto:support@wepik.com" class="text-purple-500 hover:underline">support@wepik.com</a>
			</p>
			<div class="ml-auto flex items-center gap-4 sm:ml-0">
				<p class="text-2xl font-bold text-gray-700">{{ packSelected.price }} {{ packSelected.currency }}</p>
				<a
					target="_blank"
					:href="`${APP_BASE}credits/purchase?pack=${packSelected.code}`"
					class="rounded-lg bg-gradient-to-br from-blue-500 via-purple-400 to-purple-400 px-6 py-2.5 font-bold text-white hover:opacity-90"
					@click="onClickBuyCredits"
				>
					Buy credits
				</a>
			</div>
		</div>
	</div>
</template>
