<script setup lang="ts">
import { Sketch } from '@ckpack/vue-color';
import { cloneDeep } from 'lodash-es';
import { nextTick, ref, shallowRef, toRef, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPickerOpacity from '@/color/components/ColorPickerOpacity.vue';
import ColorsPalette from '@/color/components/ColorsPalette.vue';
import EyeDrop from '@/color/components/EyeDrop.vue';
import RotationGradient from '@/color/components/RotationGradient.vue';
import StopsGradientPanel from '@/color/components/StopsGradientPanel.vue';
import TypeGradient from '@/color/components/TypeGradient.vue';
import { useMountedState } from '@/common/composables/useMountedState';
import { Color, StopGradient } from '@/Types/colorsTypes';

const props = defineProps<{
	gradient: GradientColor;
	hideAlpha?: boolean;
	hidePalettes?: boolean;
}>();
const emit = defineEmits<{ (e: 'change', gradient: Color): void }>();

const gradient = toRef(props, 'gradient');
const stopSelected = shallowRef<StopGradient>(props.gradient.stops[0]);
const sketchModel = ref(props.gradient.convertToSolidColor());

const ready = useMountedState();

const selectStop = (stop: StopGradient) => {
	stopSelected.value = stop;
	sketchModel.value = SolidColor.fromObject(stop);
};

const changeStop = async (data: Color | StopGradient) => {
	if (data instanceof GradientColor) {
		emit('change', data);
		await nextTick();
		selectStop(props.gradient.stops[0]);
		return;
	}
	const color = data instanceof SolidColor ? data : SolidColor.fromObject(data);
	sketchModel.value = color;
	const oldStop = { ...stopSelected.value };
	stopSelected.value = { ...stopSelected.value, ...color.toObject() };

	if (!(data instanceof SolidColor) && data.offset) {
		stopSelected.value.offset = data.offset;
	}

	const grad = cloneDeep(props.gradient);
	grad.updateStop(oldStop, stopSelected.value);

	GAnalytics.track('click', 'Button', `edit-gradient`, null);
	emit('change', grad);
};

const changeStopFromSketch = async (data: any) => {
	await changeStop(data.rgba);
	sketchModel.value = data;
};

watch(gradient, (newVal, oldVal) => {
	const newStops = newVal.stops.map((c) => newVal.stopToString(c));
	const oldStops = oldVal.stops.map((c) => oldVal.stopToString(c));
	const result = Array.from(new Set([...newStops, ...oldStops]));

	// Si hay más de un stop de diferencia significa que el gradiente es distinto y
	// no que se haya añadido o quitado un stop así que reiniciamos el selected stop
	if (Math.abs(result.length - oldStops.length) > 1) {
		stopSelected.value = newVal.stops[0];
	}

	sketchModel.value = SolidColor.fromObject(stopSelected.value);
});
</script>

<template>
	<div class="custom-picker">
		<Sketch :model-value="sketchModel" :preset-colors="[]" @update:model-value="changeStopFromSketch" />
		<Teleport v-if="ready" to=".vc-sketch">
			<div class="relative flex w-full flex-col items-center pb-4 pt-1 lg:border-gray-700 lg:pb-3">
				<StopsGradientPanel
					:gradient="gradient"
					:selected="stopSelected"
					@select="selectStop"
					@change="changeStop"
					@new="emit('change', $event)"
				/>
				<div class="hidden h-6 w-full justify-between lg:flex">
					<TypeGradient :gradient="gradient" @change="emit('change', $event)" />
					<RotationGradient :gradient="gradient" @change="emit('change', $event)" />
				</div>
			</div>
		</Teleport>
		<Teleport v-if="ready" to=".vc-sketch-controls">
			<EyeDrop @change="($event) => changeStop($event)" />
		</Teleport>
		<ColorPickerOpacity
			v-if="!hideAlpha && ready"
			:color="(SolidColor.fromObject(stopSelected) as SolidColor)"
			@change="($event) => changeStop($event)"
		/>
		<ColorsPalette v-if="ready && !hidePalettes" :selected="gradient" @change="($event) => changeStop($event)" />
	</div>
</template>
