<script lang="ts" setup>
import { ref, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Image | ForegroundImage }>();
const element = toRef(props, 'element');
const isSeparating = ref(false);
const { separateLayers, removingBackground, unifyLayers, hasForeground } = useLayersImage(element);
const { checkIsPhotoModeImage } = usePhotoMode();
const { trans } = useI18n();
const toast = useToast();

const performLayerSeparation = async (selectForeground = true) => {
	isSeparating.value = true;
	try {
		await separateLayers(selectForeground);
	} catch (error) {
		if (error instanceof Error) {
			toast.error(error.message);
		}
		toast.error(
			trans('There was an error while separating the background from the foreground, try with another image')
		);
	} finally {
		isSeparating.value = false;
	}
};

const performUnifyLayer = () => {
	try {
		unifyLayers();
	} catch (error) {
		toast.error(trans('There was an error while unifying the background with the foreground'));
	}
};

const handlerLayers = async () => {
	if (isSeparating.value) return;
	if (hasForeground.value || element.value instanceof ForegroundImage) {
		performUnifyLayer();
		return;
	}
	await performLayerSeparation(!checkIsPhotoModeImage(element.value));
};
</script>

<template>
	<button
		:disabled="isSeparating"
		data-button-importance="important"
		class="flex h-9 w-9 flex-col items-center justify-center rounded text-gray-100 focus:outline-none hover:text-white sm:flex-row lg:w-full lg:bg-gray-700 lg:hover:bg-gray-600"
		:class="{ 'pointer-events-none': isSeparating }"
		@click="handlerLayers"
	>
		<div class="flex w-full items-center justify-center">
			<SvgIcon v-if="!removingBackground" name="layers" class="h-5 w-5 shrink-0 scale-90 lg:h-4 lg:w-4" />
			<SvgIcon v-else name="spinner" class="animate-reverse h-5 w-5 shrink-0 animate-spin lg:h-4 lg:w-4" />
			<span class="ml-2 mt-0 hidden text-sm lg:block" data-button-label>{{
				hasForeground ? trans('Unify') : trans('Separate')
			}}</span>
		</div>
	</button>
</template>
