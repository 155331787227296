<script lang="ts" setup>
import Bugsnag from '@bugsnag/browser';
import { computed, onMounted, ref, watch } from 'vue';

import { getUserUploads } from '@/api/UserApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { TimeTools } from '@/common/utils/TimeTools';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useEditorClipboard } from '@/editor/composables/useEditorClipboard';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useUserUploads } from '@/elements/element/composables/useUserUploads';
import DeleteImageModal from '@/elements/medias/images/image/components/DeleteImageModal.vue';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { ImageApi, MediaApi, UploadApi, VideoApi } from '@/Types/apiClient';
import { DraggableItemData, Panels } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		modal?: boolean;
	}>(),
	{ modal: false }
);

const emits = defineEmits<{
	(e: 'close'): void;
	(e: 'select', image: ImageApi | UploadApi | VideoApi): void;
}>();

const { isLogged } = useAuth();
const { trans } = useI18n();
const { isPhotoMode } = useEditorMode();
const { insertUploadElement, deleteUploadElement } = useUserUploads();
const { runOnMobile } = useDeviceInfo();
const { copy, hasImageInClipboard } = useEditorClipboard();
const toast = useToast();
const store = useMainStore();
const { selectFromLocal, uploadImages, userUploads, isUploading, uploadImageInput } = useUserImageProvider();

const imgToDelete = ref<ImageApi | VideoApi | null>(null);

const canDeleteClipboard = computed(() => {
	if (imgToDelete.value) {
		return hasImageInClipboard(`${imgToDelete.value.id}`);
	}
	return false;
});

const { data, isFetching, url } = getUserUploads(userUploads, { refetch: true });
const { data: dataWatcher, execute } = getUserUploads(userUploads, { immediate: false });

onMounted(() => (userUploads.value = []));

// const tabs = ['photos', 'videos']; // Uncomment when videos are ready
const selectedTab = ref('photos');
const links = computed(() => data.value?.links || dataWatcher.value?.links);
const uploads = computed(() => {
	let allowedItems = userUploads.value;
	if (isPhotoMode.value) {
		allowedItems = allowedItems.filter((upload: MediaApi) => upload.type === 'image' || upload.type === 'svg');
	}

	if (props.modal) {
		allowedItems = allowedItems.filter((upload: MediaApi) => upload.type !== 'svg');
	}

	return allowedItems;
});
// const onSetTab = (tab: string) => (selectedTab.value = tab); // Uncomment when videos are ready

const loadMore = () => {
	if (isFetching.value || !links.value?.next) return;
	url.value = links.value.next;
};

const onClickImage = async (dataApi: ImageApi | UploadApi | VideoApi) => {
	await insertUploadElement(dataApi, props.modal);

	if (props.modal) {
		if (!isPhotoMode.value) {
			emits('close');
		} else {
			emits('select', dataApi);
		}
	}

	runOnMobile(() => (store.activePanel = null));
};
const deleteUpload = async () => {
	if (!imgToDelete.value) return;

	if (canDeleteClipboard.value) await copy('');

	try {
		await deleteUploadElement(imgToDelete.value);
	} catch (error) {
		if (error instanceof Error) {
			toast.error(error.message);
		}
	}

	Bugsnag.leaveBreadcrumb(`Delete ${imgToDelete.value.type} uploaded`);

	imgToDelete.value = null;
};

watch(isLogged, async (val) => {
	if (!val) return;
	await execute();
});

// Hay un bug en ciertas versiones de chrome en el que no reconoce los archivos com mime type video/quicktime por eso se añade también la extensión .mov
const acceptedMimes = isPhotoMode.value
	? 'image/png, image/jpeg, image/jpg, image/svg+xml'
	: 'image/png, image/jpeg, image/jpg, image/svg+xml, image/webp, video/mp4, video/webm, video/quicktime, .mov, video/3gpp, .3gp';
</script>

<template>
	<div class="flex h-full flex-col">
		<template v-if="!modal">
			<PanelHeader title="Uploads" />
			<input
				id="upload-img"
				ref="uploadImageInput"
				class="hidden"
				type="file"
				:accept="acceptedMimes"
				multiple
				@change="uploadImages"
			/>
			<button
				class="mb-3 flex h-12 shrink-0 items-center justify-center rounded border-blue-500 bg-blue-500 px-5 font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
				:class="{ 'cursor-not-allowed opacity-50': isUploading }"
				:disabled="isUploading"
				@click="selectFromLocal"
			>
				<span v-if="isUploading" class="relative flex w-full items-start gap-4">
					<SvgIcon data-testid="spinner" name="spinner" class="absolute left-0 top-2.5 h-5 w-5 animate-spin" />
					<div class="flex-1 text-center leading-tight">
						{{ trans('Updating...') }}
						<p
							v-if="!isPhotoMode"
							class="flex scale-95 items-center justify-center gap-1 text-sm font-normal opacity-80"
						>
							{{ trans('Image or video') }}
						</p>
					</div>
				</span>
				<span v-else class="relative flex h-full w-full items-center gap-4">
					<span class="absolute left-0 top-0 flex h-full items-center">
						<SvgIcon data-testid="upload-image" name="upload-image" class="h-4 w-4" />
					</span>
					<div id="upload-media-button" class="flex-1 text-center leading-tight">
						{{ trans('Upload media') }}
						<p
							v-if="!isPhotoMode"
							class="flex scale-95 items-center justify-center gap-1 text-sm font-normal opacity-80"
						>
							{{ trans('Image or video') }}
						</p>
					</div>
				</span>
			</button>
		</template>

		<DeleteImageModal
			:open="!!imgToDelete"
			:delete-clipboard-message="canDeleteClipboard"
			@close="imgToDelete = null"
			@confirm="deleteUpload"
		/>
		<!-- Uncomment component when videos are ready-->
		<!-- <ProviderButtons :tabs="tabs" :selected-tab="selectedTab" @set-tab="onSetTab" />  -->

		<KeepAlive>
			<InfiniteLoading
				v-if="selectedTab === 'photos'"
				:data="uploads"
				:is-fetching="isFetching"
				class="flex flex-col"
				with-masonry
				:modal="modal"
				:masonry-cols="2"
				@load="loadMore"
			>
				<template #item="{ item }">
					<InsertableElement
						v-if="'origin' in item"
						:data="(item as DraggableItemData)"
						:type="Panels.uploads"
						draggable
						class="mb-2"
						@click="onClickImage(item as ImageApi | UploadApi | VideoApi)"
					>
						<ListMedia
							:use-background-removed-if-available="true"
							:image="(item as ImageApi)"
							:deletable="true"
							@delete="imgToDelete = item as ImageApi"
						>
							<div
								v-if="item.metadata.duration"
								class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/75 px-2 pb-1 pt-4 text-right text-xs font-semibold text-white"
							>
								{{ TimeTools.formatMillisecondsToMMSS(Number(item.metadata.duration)) }}
							</div>
						</ListMedia>
					</InsertableElement>
				</template>
			</InfiniteLoading>
		</KeepAlive>
	</div>
</template>
