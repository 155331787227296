import { createSharedComposable } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { useToast } from '@/common/composables/useToast';
import { useEditorClipboard } from '@/editor/composables/useEditorClipboard';
import Element from '@/elements/element/classes/Element';
import { useBackgroundableElement } from '@/elements/element/composables/useBackgroundableElement';
import { useElementLock } from '@/elements/element/composables/useElementLock';
import { useElementOrderOrchestrator } from '@/elements/element/composables/useElementOrderOrchestrator';
import { useGroup } from '@/elements/group/composables/useGroup';
import { BackgroundableElement } from '@/elements/medias/crop/types/croppeable.type';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { Position } from '@/Types/types';

export const useContextMenu = createSharedComposable(() => {
	const { selectionOrdered: selection } = useSelection();

	const tempRef = ref<Element>(selection.value[0] || Image.create());

	const { removeElement } = useActivePage();
	const usingBackgroundable = useBackgroundableElement(tempRef as Ref<BackgroundableElement>);
	const {
		canBeCopied,
		canBePasted,
		currentClipboardData,
		copy: copyClipboard,
		copySelection,
		pasteSelection,
		pasteNewText,
		pasteSVG,
	} = useEditorClipboard();
	const { toggleLockElement } = useElementLock(tempRef);
	const { textEditing } = useTextEditing();
	const usingOrder = useElementOrderOrchestrator(tempRef);
	const { isLockGroup, lockGroup, unlockGroup } = useGroup(tempRef);
	const { trans } = useI18n();
	const toast = useToast();

	// Clipboard
	const copy = () => {
		if (!canBeCopied.value) return;

		copySelection();
		toast.info(trans(`${selection.value.length > 1 ? 'Items' : 'Item'} added to clipboard`));
	};

	const paste = async (positionInPage: Position) => {
		if (!canBePasted.value && !currentClipboardData.value.length) return;

		const hasContent = currentClipboardData.value.length > 0;
		const isWepikData = currentClipboardData.value.startsWith('wepik|') || false;
		const isSVG = currentClipboardData.value.startsWith('<svg') || false;

		// Para pegar un SVG del clipboard si es string, por ejemplo para traernos elementos de figma
		if (hasContent && isSVG) {
			pasteSVG(currentClipboardData.value);
			return;
		}

		// Si solo tenemos el contenido de un texto copiado en el clipboard, creamos un nuevo texto con el contenido
		if (hasContent && !isWepikData) {
			pasteNewText(currentClipboardData.value, selection.value[0] instanceof Text && !textEditing.value);
			return;
		}

		if (!canBeCopied.value && hasContent) {
			await copyClipboard(currentClipboardData.value);
		}

		pasteSelection(positionInPage);
	};

	const remove = () => {
		if (!canBeCopied.value) return;

		selection.value.forEach((element) => removeElement(element));
	};

	const lock = () => {
		toggleLockElement();
	};

	// Z-Order
	const canGoUpAndDown = computed(() => {
		if (!selection.value.length) return { forward: false, backward: false };

		const forward = selection.value.some((el) => {
			tempRef.value = el;
			return usingOrder.value.canGoUp.value;
		});

		const backward = selection.value.some((el) => {
			tempRef.value = el;
			return usingOrder.value.canGoDown.value;
		});

		return {
			forward,
			backward,
		};
	});

	const forward = () => {
		if (!canGoUpAndDown.value.forward) return;
		// Comprobamos si hay algún elemento que esté dentro de un grupo y ejecutamos
		// una única vez por grupo el moveUp porque el composable de grupos ya se encarga
		// de mover a todos los elementos que lo compongan
		const groups: string[] = [];

		selection.value.reverse().forEach((el) => {
			if (el.group) {
				if (groups.includes(el.group)) return;
				groups.push(el.group);
			}
			tempRef.value = el;
			usingOrder.value.moveUp();
		});
	};

	const backward = () => {
		if (!canGoUpAndDown.value.backward) return;
		// Comprobamos si hay algún elemento que esté dentro de un grupo y ejecutamos
		// una única vez por grupo el moveDown porque el composable de grupos ya se encarga
		// de mover a todos los elementos que lo compongan
		const groups: string[] = [];

		selection.value.forEach((el) => {
			if (el.group) {
				if (groups.includes(el.group)) return;
				groups.push(el.group);
			}
			tempRef.value = el;
			usingOrder.value.moveDown();
		});
	};

	const toFront = () => {
		if (!canGoUpAndDown.value.forward) return;

		const groups: string[] = [];

		selection.value.forEach((el) => {
			if (el.group) {
				if (groups.includes(el.group)) return;
				groups.push(el.group);
			}
			tempRef.value = el;
			usingOrder.value.moveToFront();
		});
	};

	const toBack = () => {
		if (!canGoUpAndDown.value.backward) return;
		const groups: string[] = [];

		selection.value.reverse().forEach((el) => {
			if (el.group) {
				if (groups.includes(el.group)) return;
				groups.push(el.group);
			}
			tempRef.value = el;
			usingOrder.value.moveToBack();
		});
	};

	// Backgroundable
	const enabledToBg = computed(() => selection.value.length === 1 && usingBackgroundable.canBeSetAsBackground.value);

	const isBackgroundableSelected = computed(
		() => selection.value.length === 1 && (selection.value[0] instanceof Image || selection.value[0] instanceof Video)
	);

	const setAsBg = () => {
		try {
			tempRef.value = selection.value[0];
			usingBackgroundable.setElementAsBackground();
		} catch (e) {
			const message = EventTools.getErrorMessage(e);
			toast.error(trans(message));
		}
	};

	return {
		canBeCopied,
		canBePasted,
		currentClipboardData,
		canGoUpAndDown,
		enabledToBg,
		isBackgroundableSelected,
		isLockGroup,
		backward,
		copy,
		forward,
		lockGroup,
		paste,
		remove,
		lock,
		setAsBg,
		toBack,
		toFront,
		unlockGroup,
	};
});
