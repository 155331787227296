import { ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementRegardingPage } from '@/elements/element/composables/useElementRegardingPage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextTransform } from '@/elements/texts/text/composables/useTextTransform';
import { useProjectStore } from '@/project/stores/project';

export type Report = {
	type: string;
	model: 'template' | 'user-vector';
	data: object;
	id: string | number;
};

class Reports {
	static findOverlapingTexts(): Array<Report> {
		const project = useProjectStore();
		return project.pages
			.map((p): Report | undefined => {
				const shapes = p.elementsAsArray().filter((e) => e.type === 'shape');
				const texts = p.elementsAsArray().filter((e) => e.type === 'text');

				const shapesWithTexts = shapes.filter((s) => {
					const shapeIndex = s.index;

					const textsBehindOverlapped = texts
						.filter((t) => t.index < shapeIndex)
						.filter((t) => {
							return s.isCollided(t);
						});

					return textsBehindOverlapped.length > 0;
				});

				if (shapesWithTexts.length === 0) {
					return;
				}

				return {
					type: 'shape-over-text',
					model: 'template',
					data: {
						shapes: shapesWithTexts.map((shape) => {
							return {
								id: shape.id,
							};
						}),
					},
					id: project.sourceVectorId,
				};
			})
			.filter((el) => !!el) as Report[];
	}

	static async findBrokenImages(): Promise<Report | null> {
		const project = useProjectStore();
		const store = useMainStore();

		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			const p = project.pages[0];
			if (!p) {
				resolve(null);
				return;
			}
			const images = p.elementsAsArray().filter((e): e is Image => e.type === 'image');

			let brokenImages = images.filter((image) => {
				return !image.urlBackgroundRemoved;
			});

			const imageRequests = images
				.filter((image) => {
					return image.urlBackgroundRemoved;
				})
				.map((image) => {
					return new Promise((resolve) => {
						const img = document.createElement('img');

						img.onerror = () => resolve(image);
						img.onload = () => resolve(false);
						img.src = image.urlBackgroundRemoved as string;
					});
				});

			const failedResponses = (await Promise.all(imageRequests)).filter((response) => !!response) as Image[];

			brokenImages = [...brokenImages, ...failedResponses];

			if (!brokenImages.length) {
				resolve(null);
				return;
			}

			resolve({
				type: 'broken-image',
				model: 'user-vector',
				data: {
					images: brokenImages.map((image) => {
						return {
							id: image.id,
						};
					}),
				},
				id: store.userVector?.uuid as string,
			});
		});
	}

	static findContentOutside(): Report[] {
		const project = useProjectStore();

		return project.allElements
			.filter((e: Element) => {
				if (e.type === 'text') {
					const text = e as Text;
					const { isPartiallyOutside } = useTextTransform(ref(text));
					return isPartiallyOutside.value;
				}
				const element = e as Element;

				const { isOutsidePage } = useElementRegardingPage(ref(element));

				return isOutsidePage.value;
			})
			.map((e) => {
				return {
					type: 'element-outside',
					model: 'template',
					data: {
						element: e.id,
					},
					id: project.sourceVectorId,
				};
			});
	}

	static findBlendModes(): Report[] {
		const project = useProjectStore();

		return project.allShapes
			.filter((e) => {
				return e.content.includes('mix-blend');
			})
			.map((e) => {
				return {
					type: 'element-blend-mode',
					model: 'template',
					data: {
						element: e.id,
					},
					id: project.sourceVectorId,
				};
			});
	}

	static findUnsupportedPatternElement(): Report[] {
		const project = useProjectStore();

		return project.allShapes
			.filter((e) => e.metadata && e.metadata.hasInvalidPattern)
			.map((e) => {
				return {
					type: 'unsupported-data-pattern',
					model: 'template',
					data: {
						element: e.id,
					},
					element: e,
					id: project.sourceVectorId,
				};
			});
	}

	static findWrongTextHeight() {
		const project = useProjectStore();

		return project.allTexts
			.filter((e) => {
				if (e.curvedProperties.arc) return;
				const height = e.domNode()?.getBoundingClientRect().height || 0;
				const textHeight = e.domNode()?.querySelector('.text-element-final')?.getBoundingClientRect().height || 0;

				return textHeight > height * 1.05 || textHeight < height * 0.95;
			})
			.map((e) => {
				return {
					type: 'element-blend-mode',
					model: 'template',
					data: {
						element: e.id,
					},
					element: e,
					id: project.sourceVectorId,
				};
			});
	}

	static findTextsWithInvalidFont() {
		const project = useProjectStore();

		return project.allTexts
			.filter((e) => e.metadata && e.metadata.hasInvalidFont)
			.map((e) => {
				return {
					type: 'element-text-invalid-font',
					model: 'template',
					data: {
						element: e.id,
					},
					element: e,
					id: project.sourceVectorId,
				};
			});
	}
}

export default Reports;
