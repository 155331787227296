import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { PluginOptions, POSITION } from 'vue-toastification';

import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useSessionInfo } from '@/common/composables/useSessionInfo';

const { APP_BUGSNAG_KEY, APP_VERSION, PROD } = useEnvSettings();
export const toastConfig = (): PluginOptions => {
	return {
		position: POSITION.BOTTOM_RIGHT,
		maxToasts: 3,
		containerClassName: ['custom-toast'],
		closeButtonClassName: [],
		bodyClassName: [''],
		toastClassName: ['bg-darkblue-900 rounded'],
	};
};

const errorsToDiscard = [
	"Cannot read properties of null (reading 'addedIndex')",
	"Cannot read properties of null (reading 'move')",
	"Cannot use 'in' operator to search for 'originalClientX' in undefined",
	'Failed waiting for',
	'The user aborted a request',
	'Permissions check failed',
	'Clipboard write is not allowed',
	'ResizeObserver loop',
	'Document is not focused',
	'Network Error when',
	'The play() request was',
	'Read permission denied',
	'Failed to fetch',
	'Write permission denied',
	'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
];

export const setupBugsnag = () => {
	Bugsnag.start({
		maxBreadcrumbs: 100,
		apiKey: APP_BUGSNAG_KEY,
		appVersion: APP_VERSION,
		plugins: [new BugsnagPluginVue()],
		metadata: {
			editor: {
				editorSessionId: useSessionInfo().uuid,
			},
		},
		onError: function (event) {
			for (const error of event.errors) {
				if (errorsToDiscard.some((testString) => error.errorMessage?.includes(testString))) {
					return false;
				}
			}

			if (errorsToDiscard.some((testString) => event.originalError?.message?.includes(testString))) {
				return false;
			}

			const { user } = useAuth();
			if (user.value) {
				event.setUser(user.value.id, user.value.email);
			}
		},
		releaseStage: PROD ? 'production' : 'testing',
		enabledReleaseStages: PROD ? ['production'] : null,
		autoTrackSessions: false,
	});
	Bugsnag.startSession();

	const bugsnagVue: any = Bugsnag.getPlugin('vue');

	return bugsnagVue;
};
