<script lang="ts" setup>
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');
const { togglePanel } = usePanelManagement();

const scale = computed(() => store.scale);

const store = useMainStore();
const { isMobile } = useDeviceInfo();
const { trans } = useI18n();

const { isCircleText } = useCircleTypeInfo(element, scale);
const { hasEffect, hasPreset } = useTextEffects(element);

const onClickEffects = () => {
	togglePanel(EditPanels.TextEffects, props.element);
	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'effects' });
};
</script>

<template>
	<button
		data-testid="text-effects"
		data-button-importance="normal"
		class="relative flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 sm:order-none lg:h-6 lg:w-auto lg:flex-row lg:justify-center lg:px-1"
		:class="{
			'text-white': isMobile ? hasPreset : hasEffect || isCircleText,
			'text-gray-100': isMobile ? !hasPreset : !(hasEffect || isCircleText),
			'-order-1': isMobile,
		}"
		@click="onClickEffects"
	>
		<span
			v-if="isMobile ? hasPreset : hasEffect || isCircleText"
			class="absolute top-0 right-2 z-10 h-2 w-2 rounded-full border border-blue-300 bg-blue-500 slidesgo:bg-purple-400 lg:top-0.5 lg:-left-0.5"
		></span>
		<SvgIcon name="effects" class="h-6 w-6 p-px lg:hidden" />
		<span class="text-2xs lg:mt-0 lg:text-sm" data-button-label>{{ trans('Effects') }}</span>
	</button>
</template>
