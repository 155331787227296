import { setupBugsnag, toastConfig } from '../sharedSetup';

window.EDITOR_MODE = 'EDITOR';

import '@/polyfills.ts';
import '@/window.ts';
import 'vue-toastification/dist/index.css';
import 'virtual:svg-icons-register';
import '@/assets/styles/tailwind.css';
import '@/assets/styles/editor.sass';

import { GesturePlugin } from '@vueuse/gesture';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import VueGtag, { pageview, set as setGTM } from 'vue-gtag';
import masonry from 'vue-next-masonry';
import Toast from 'vue-toastification';

import { useTracking } from '@/api/composables/useTracking';
import Editor from '@/apps/Editor.vue';
import { historyPlugin } from '@/history/stores/history-plugin';
import { i18n } from '@/i18n/useI18n';

const pinia = createPinia();
// @ts-ignore
pinia.use(historyPlugin);

const optionsToast = toastConfig();
const bugsnagVue = setupBugsnag();

createApp(Editor)
	.use(VueGtag, {
		config: { id: 'G-NVE4KXTJVW' },
		includes: [{ id: 'UA-193008345-1' }],
		enable: true,
	})
	.use(i18n)
	.use(pinia)
	.use(GesturePlugin)
	.use(Toast, optionsToast)
	.use(masonry)
	.use(bugsnagVue)
	.mount('#app');

const { hashValues } = useTracking();
setGTM({ page_type: 'editor', rs: hashValues.rs || '' });
pageview({
	page_title: document.title,
	page_path: window.location.pathname,
	page_location: window.location.href,
});

// Page view event for GA4
const trackPageView = () => {
	const fromView = hashValues.rs || hashValues.from_view || '';

	const userId = `${window.user?.id}` || '';

	let userType = 'anonymous';

	if (window.user) {
		userType = window.user.admin ? 'internal-staff' : 'registered';
	}

	const pageViewParams = {
		event: 'page_view_global',
		page_location: window.location.href,
		page_title: document.title,
		page_en: window.location.href,
		location_href: document.location.href,
		content_group: 'editor',
		content_group2: 'na',
		content_type: 'editor',
		country: window.countryCode || '',
		page_code: 200,
		page_lang: document.documentElement.lang,
		from_view: fromView,
		user_id: userId,
		user_type: userType,
		resource_name: window.preloadVector?.name || '',
		resource_id: window.preloadVector?.id || '',
	};

	window.dataLayer.push(pageViewParams);
};

trackPageView();
