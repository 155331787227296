<script lang="ts" setup>
import { until, useStorage, watchOnce } from '@vueuse/core';
import { useVOnboarding, VOnboardingStep, VOnboardingWrapper } from 'v-onboarding';
import { computed, ref } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMountedState } from '@/common/composables/useMountedState';
import { onBoardingSteps, OnboardingTypes } from '@/data/onBoardingSteps';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useProject } from '@/project/composables/useProject';

const steps = ref();
const wrapper = ref();
const { inSlidesgoContext, isSlidesgoMode, isFreepikContext, isPhotoMode } = useEditorMode();
const store = useMainStore();
const ready = useMountedState();
const { trans } = useI18n();
const { projectIsPresentation, isAiPresentation } = useProject();
const { isMobile } = useDeviceInfo();
const { start } = useVOnboarding(wrapper);
const state = useStorage('on-boarding', {
	wepik: false,
	photo: false,
	slidesgo: false,
	presentation: false,
	freepik: false,
	freepikPhoto: false,
	slidesgoEditor: false,
});

const onboarding = computed<Exclude<OnboardingTypes, 'slidesgoEditorMobile' | 'wepikMobile'>>(() => {
	if (isPhotoMode.value && !isFreepikContext.value) return 'photo';
	if (isSlidesgoMode.value) return 'slidesgoEditor';
	if (inSlidesgoContext.value) return 'slidesgo';
	if (projectIsPresentation.value || isAiPresentation.value) return 'presentation';
	if (isFreepikContext.value) return 'freepikPhoto';
	return 'wepik';
});

watchOnce(ready, async () => {
	await until(() => store.finishedLoading).toBeTruthy();

	if (state.value[onboarding.value] || isMobile.value) return;

	store.inOnboarding = true;
	steps.value = onBoardingSteps(onboarding.value);
	start();
});
</script>

<template>
	<div id="onboarding"></div>

	<VOnboardingWrapper ref="wrapper" class="z-50" :steps="steps">
		<template #default="{ next, exit, step, isFirst, isLast }">
			<VOnboardingStep>
				<template v-if="isFirst">
					<div class="fixed inset-0 z-50 -mt-16 flex h-screen w-full items-center justify-center">
						<div class="rounded-xl bg-white">
							<div class="flex w-96 max-w-sm flex-col items-center p-6 text-center leading-normal text-darkblue-500">
								<img :src="step.content.image" class="mb-6 h-48 w-full rounded-xl object-cover" />
								<div class="text-gray-600">
									<h3 class="mb-2 text-xl font-semibold text-gray-800">👋 {{ trans(step.content.title) }}</h3>
									<p class="text-gray-500">
										{{ trans(step.content.description) }}
									</p>
								</div>
							</div>
							<div class="flex justify-center pb-6">
								<button
									class="rounded bg-blue-500 px-6 py-3 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
									@click="
										next();
										state[onboarding] = true;
									"
								>
									{{ trans(`Let's go`) }}
								</button>
							</div>
						</div>
					</div>
				</template>

				<template v-else>
					<div
						data-testid="onboarding"
						class="relative m-2 w-[300px] rounded bg-blue-500 p-4 text-white slidesgo:bg-purple-400"
					>
						<button
							class="absolute right-4 top-4 text-white"
							@click="
								exit();
								store.inOnboarding = false;
							"
						>
							<SvgIcon name="cross" class="h-4 w-4" />
						</button>
						<h3 class="mb-2 px-0.5 font-bold">{{ step.content.title }}</h3>
						<p v-if="step.content.description" class="mb-4 text-sm leading-normal">{{ step.content.description }}</p>
						<div class="mt-4 flex w-full items-center justify-between">
							<button
								v-if="!isFirst"
								class="flex items-center text-sm font-bold hover:opacity-75"
								@click="
									exit();
									store.inOnboarding = false;
								"
							>
								{{ trans('Skip') }}
							</button>
							<button v-if="!isLast" class="flex items-center text-sm font-bold hover:opacity-75" @click="next">
								{{ trans('Next') }}
								<SvgIcon name="right-small" class="ml-2 h-4 w-4" />
							</button>
							<button
								v-else
								class="flex items-center text-sm font-bold hover:opacity-75"
								@click="
									next();
									store.inOnboarding = false;
								"
							>
								<SvgIcon name="check" class="mr-2 h-3 w-3" />
								{{ trans('Finish') }}
							</button>
						</div>
					</div>
				</template>
			</VOnboardingStep>
		</template>
	</VOnboardingWrapper>
</template>

<style lang="sass">
.v-onboarding-overlay
	@apply fixed inset-0 fill-gray-900 opacity-75 w-full h-full pointer-events-none

[data-popper-arrow]
	@apply hidden
</style>
