<script lang="ts" setup>
import PagesListbox from '@/common/components/PagesListbox.vue';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';

const { errors, message, tweetData } = useSharePublish();

const { trans } = useI18n();

const onInputMessage = (e: Event) => {
	if (e.target instanceof HTMLTextAreaElement) {
		message.value = e.target.value;
	}
};
</script>
<template>
	<div class="flex flex-col gap-4">
		<div class="flex flex-col gap-4 lg:flex-row">
			<div class="relative w-full">
				<PagesListbox :multiple="true" :max-pages-selected="4" />
			</div>
		</div>
		<div class="relative flex flex-col">
			<textarea
				:value="message"
				maxlength="280"
				placeholder="What do you want to tell the world?"
				class="pii-cs h-52 w-full resize-none rounded border border-gray-200 px-4 pt-3 pb-6 text-sm text-gray-700 placeholder:text-gray-100 focus:border-gray-400 focus:outline-none"
				@input="onInputMessage"
			></textarea>
			<span
				class="absolute bottom-1 right-1 bg-white px-2 py-1 text-xs text-gray-100"
				:class="{
					'text-red-500': tweetData.weightedLength > 280,
				}"
			>
				{{ tweetData.weightedLength }}/280
			</span>
		</div>

		<div v-for="(error, i) in errors" :key="`${i}${error}`" class="mb-4">
			<p class="text-sm font-semibold text-red-500">- {{ trans(error.description) }}</p>
		</div>
	</div>
</template>
<style></style>
