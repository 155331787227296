// Packages
import { SVG, Svg } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
// Types
import Element from '@/elements/element/classes/Element';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
// Classes
import { Color } from '@/Types/colorsTypes';
import { StorysetDTO } from '@/Types/elements';
import { SerializedClass, ViewBox } from '@/Types/types';

class Storyset extends Element {
	type: 'storyset' = 'storyset';
	viewbox: string;
	content: string;
	mainColor: Color;

	protected constructor(storysetDTO: StorysetDTO) {
		super(storysetDTO);

		this.viewbox = storysetDTO.viewbox;
		this.content = storysetDTO.content;
		this.mainColor = storysetDTO.mainColor;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	/**
	 * Instancia para trabajar con el contenido de storyset usando SVG.js
	 * @returns Instancia de SVG.js
	 */
	get instance(): Svg {
		return SVG(`<svg viewBox="${this.viewbox}">${this.content}</svg>`) as Svg;
	}

	static get defaultColors(): string[] {
		return [
			'rgba(64, 123, 255, 1)',
			'rgba(146, 227, 169, 1)',
			'rgba(186, 104, 200, 1)',
			'rgba(255, 114, 94, 1)',
			'rgba(255, 199, 39, 1)',
		];
	}

	static defaults(): StorysetDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'storyset',
			// Storyset
			viewbox: '0 0 64 64',
			content: '',
			mainColor: SolidColor.fromString('rgba(64, 123, 255, 1)'),
		};
	}

	static create(config: Partial<StorysetDTO> = {}): Storyset {
		const storysetDTO = {
			...Storyset.defaults(),
			...config,
		};

		return new Storyset(storysetDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Storyset>): Storyset {
		const storysetDTO = {
			...Storyset.defaults(),
			...data,
		} as StorysetDTO;

		if (data.mainColor) {
			storysetDTO.mainColor =
				'stops' in data.mainColor
					? GradientColor.unserialize(data.mainColor as GradientColor)
					: SolidColor.unserialize(data.mainColor as SolidColor);
		}

		const elem = new Storyset(storysetDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static fromSvg(rawSvg: string): Storyset {
		const storysetSvg = SVG(rawSvg);
		const originalContent = storysetSvg.node.innerHTML;

		// Añadimos un <g> temporal para obtener info del contenido
		storysetSvg.node.innerHTML = `<g>${originalContent}</g>`;

		const mainG = storysetSvg.first();
		const { x, y, height, width } = mainG.bbox();

		storysetSvg.node.innerHTML = originalContent;

		// Normalizamos los colores
		storysetSvg.find('[fill], [fill-color], [stroke], [stroke-color]').forEach((el) => {
			// Normalizamos el fill-color
			const fillColor = el.node.getAttribute('fill-color') || el.node.getAttribute('fill');

			if (fillColor && fillColor !== 'none') {
				const [r, g, b] = Normalize(fillColor);

				el.attr('fill-color', null);
				el.attr('fill', null);

				el.css('fill', `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`);
			}

			// Normalizamos el stroke-color
			const strokeColor = el.node.getAttribute('stroke-color') || el.node.getAttribute('stroke');

			if (strokeColor && strokeColor !== 'none') {
				const [r, g, b] = Normalize(strokeColor);

				el.attr('stroke-color', null);
				el.attr('stroke', null);

				el.css('stroke', `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`);
			}
		});

		// Pasamos los colores a rgba
		storysetSvg.find('[style*="stroke"], [style*="fill"]').forEach((el) => {
			const stroke = el.css('stroke');

			if (stroke && !stroke.includes('var') && stroke !== 'none') {
				const [r, g, b] = Normalize(stroke);
				const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`;

				el.css('stroke', '');
				el.data('stroke', ` stroke: ${rgba};`);
			}

			const fill = el.css('fill');

			if (fill && !fill.includes('var') && fill !== 'none') {
				const [r, g, b] = Normalize(fill);
				const rgba = `rgba(${r * 255}, ${g * 255}, ${b * 255}, 1)`;

				el.css('fill', '');
				el.data('fill', ` fill: ${rgba};`);
			}

			if (el.data('stroke') || el.data('fill')) {
				el.attr('new-style', `${el.attr('style')}${el.data('stroke') || ''}${el.data('fill') || ''}`);
				el.attr('style', null);
				el.data('stroke', null);
				el.data('fill', null);
			}
		});

		// Ponemos las id en minúsculas
		storysetSvg.find('[id]').forEach((el) => {
			el.attr('id', el.attr('id').toLowerCase());
		});

		const backgroundComplete = storysetSvg.findOne('[id*="background-complete"]');
		const backgroundSimple = storysetSvg.findOne('[id*="background-simple"]');

		// Obtenemos el estado del fondo, si no tiene usamos la lógica definida,
		// usaremos el fondo completo, si no hay, usamos el simple
		const backgroundCompleteVisibility = backgroundComplete?.css('visibility') || 'visible';
		const backgroundSimpleVisibility = backgroundSimple?.css('visibility') || (backgroundComplete ? 'hidden' : 'visible');

		if (backgroundComplete) {
			backgroundComplete.css('visibility', backgroundCompleteVisibility);
		}

		if (backgroundSimple) {
			backgroundSimple.css('visibility', backgroundSimpleVisibility);
		}

		const viewbox = `${x} ${y} ${width} ${height}`;
		const mainColorString = this.defaultColors.find((color) => storysetSvg.node.innerHTML.toString().includes(color));
		const mainColor = SolidColor.fromString(mainColorString || this.defaultColors[0]);
		const size = {
			width: parseFloat(mainG.width().toString()) * parseFloat(mainG.transform('scaleX').toString()),
			height: parseFloat(mainG.height().toString()) * parseFloat(mainG.transform('scaleY').toString()),
		};

		if (mainColorString) {
			// Sustituimos el color principal por un placeholder para evitar conflictos en el comportamiento
			storysetSvg.find(`[new-style*="${mainColor.toCssString()}"]`).forEach((el) => {
				const fill = el.attr('new-style').includes('fill:');

				if (fill) {
					el.css('fill', 'var(--main-storyset-color)');
				}

				const stroke = el.attr('new-style').includes('stroke:');

				if (stroke) {
					el.css('stroke', 'var(--main-storyset-color)');
				}
				el.attr('new-style', null);
			});
		}

		// Hay veces en las que chrome ignora la conversión rgb a rgba al usar opacity 1, así que
		// usamos un attr temporal para forzarlo
		const content = storysetSvg.node.innerHTML.toString().replaceAll('new-style', 'style');

		return Storyset.create({
			viewbox,
			content,
			mainColor,
			size,
		});
	}

	updateColor(newColor: Color) {
		newColor.id = this.mainColor.id;
		this.mainColor = newColor;
	}

	getColors(): Color[] {
		return [this.mainColor];
	}

	clone(): this {
		const element = super.clone();

		// Modificamos el id al color
		element.mainColor.id = 'color-' + uuidv4();

		return element;
	}

	get colors() {
		return [this.mainColor];
	}
}

export default Storyset;
