<script setup lang="ts">
// Vue
import { computed, toRef } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
// Components
import GradientsRenderer from '@/color/components/GradientsRenderer.vue';
// Composables
import { useShapeColors } from '@/color/composables/useShapeColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
// Classes
import { Shape } from '@/elements/shapes/shape/classes/Shape';

// Props
const props = defineProps<{
	element: Shape;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
}>();

// Data
const element = toRef(props, 'element');

// Using composables
const { gradientColors } = useShapeColors(element);
const suffixToColorID = computed(() => (props.previewName ? `-preview-${props.previewName}` : ''));

const colorsVarCss = computed(() => {
	const obj: { [key: string]: string } = {};
	element.value.colors.forEach(
		(color) => (obj[`--${color.id}${suffixToColorID.value}`] = `${color.toElementReference(props.previewName)}`)
	);

	return obj;
});

const { isMobile } = useDeviceInfo();
const smartStrokeWidth = computed(() => {
	return isMobile.value ? '40px' : '10px';
});
const shapeContent = computed(() => {
	let content = element.value.content;
	element.value.colors.forEach((c) => {
		content = content.replaceAll(c.id, c.id + suffixToColorID.value);
	});

	return content;
});
</script>

<template>
	<svg
		data-testid="shape-svg"
		:viewBox="element.viewbox"
		width="100%"
		height="100%"
		:style="{ ...colorsVarCss, opacity: element.opacity }"
		preserveAspectRatio="none"
		:class="smartSelection ? 'smart-selection' : ''"
		v-html="shapeContent"
	></svg>
	<GradientsRenderer :preview-name="previewName" :gradients="(gradientColors as GradientColor[])" />
</template>

<style lang="css">
svg.smart-selection > * {
	pointer-events: auto;
}
svg.smart-selection *:not(path)[stroke]:not([style*='stroke']),
svg.smart-selection path:not([stroke]):not([style*='stroke']),
svg.smart-selection *[style*='stroke: none'] {
	stroke-width: v-bind(smartStrokeWidth);
	stroke: transparent !important;
}
.debug-mode svg.smart-selection *:not([style*='stroke']):not([stroke]),
.debug-mode svg.smart-selection *[style*='stroke: none'] {
	stroke: red !important;
}
</style>
