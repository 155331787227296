<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Box } from '@/elements/box/classes/Box';
import { EditPanels } from '@/Types/types';

import EditCornerRadius from '../panels/edit/EditCornerRadius.vue';
// Props
const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');
const { togglePanel } = usePanelManagement();

// Using composable
const { t: trans } = useI18n();
const { isMobile } = useDeviceInfo();

// Data
const showRadius = ref(false);
const borderRadiusButton = ref();

onClickOutside(borderRadiusButton, (event) => {
	const target = event.target as HTMLElement | null;
	if (target?.closest('#popper-border')) return;

	return (showRadius.value = false);
});
const onClickButton = () => {
	if (!isMobile.value) {
		showRadius.value = !showRadius.value;
		return;
	}
	togglePanel(EditPanels.CornerBox, props.element);
};
</script>

<template>
	<button
		ref="borderRadiusButton"
		data-testid="corner-radius-toolbar"
		:tooltip="trans('Corner rounding')"
		tooltip-position="top"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-10 lg:w-10 lg:justify-center"
		@click="onClickButton()"
	>
		<SvgIcon
			name="radius"
			class="h-5 w-5"
			:class="!showRadius ? 'text-gray-300 hover:text-gray-100' : 'text-gray-100'"
		/>
		<span class="text-2xs lg:hidden">{{ trans('Rounding') }}</span>
	</button>
	<Popper v-if="showRadius && !isMobile" id="popper-border" class="z-30" placement="top-start">
		<EditCornerRadius :element="element" />
	</Popper>
</template>
<style lang="sass" scoped>
.input-range::-webkit-slider-thumb:hover
	@apply bg-blue-600
</style>
