<script setup lang="ts">
import { ref } from 'vue';

import { getMasks } from '@/api/DataApiClient';
import ActivableItem from '@/common/components/ActivableItem.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { LinesAndArrowsApi } from '@/Types/apiClient';
import { DraggableItemData, InsertableApiType } from '@/Types/types';

const emit = defineEmits(['elementAdded']);

const url = ref('elements/category/2328?limit=20&page=1');

const { addInsertableLine } = useAddInsertableElement();

const { data: lines, isFetching } = getMasks(url, { refetch: true });

// Methods
const addLine = async (url: string) => {
	await addInsertableLine(url);
	emit('elementAdded');
};

const loadMore = () => {
	if (isFetching.value || !lines.value?.links?.next) return;
	url.value = lines.value.links.next;
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<!-- <button
			class="mb-2 w-full cursor-pointer rounded bg-gray-700 p-2 text-center text-lg text-gray-100 hover:bg-gray-600 slidesgo:hover:bg-purple-500 hover:text-white focus:outline-none"
			@click="addLine('https://content.wepik.com/statics/4755265/Linea_Mesa-de-trabajo-1.svg')"
		>
			Test without sticky
		</button>

		<button
			class="w-full cursor-pointer rounded bg-gray-700 p-2 text-center text-lg text-gray-100 hover:bg-gray-600 slidesgo:hover:bg-purple-500 hover:text-white focus:outline-none"
			@click="addLine('https://content.wepik.com/statics/4754074/Flecha10-subirestademomento-01.svg')"
		>
			Test with sticky
		</button> -->

		<InfiniteLoading
			data-testid="container-items"
			:container-classes="'grid grid-cols-5 gap-6'"
			:data="lines?.data || []"
			:is-fetching="isFetching"
			@load="loadMore"
		>
			<template #item="{ item }">
				<InsertableElement
					v-if="'svg' in item && 'active' in item"
					:data="(item as DraggableItemData)"
					:type="InsertableApiType.LineAndArrow"
					:draggable="true"
					class="flex"
					@click="addLine(item.svg)"
				>
					<ActivableItem :active="(item as LinesAndArrowsApi).active">
						<img v-if="item" :src="item.svg" :alt="item.name" class="h-10 w-full object-contain opacity-50 invert" />
					</ActivableItem>
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
