import { UseFetchOptions } from '@vueuse/core';
import { DragState } from '@vueuse/gesture';

import { SolidColor } from '@/color/classes/SolidColor';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { Illustrator } from '@/elements/illustrator/classes/Illustrator';
import Line from '@/elements/line/classes/Line';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import {
	BasicShapeApi,
	FlaticonElementApi,
	ImageApi,
	LinesAndArrowsApi,
	MaskApi,
	MediaApi,
	PredefinedTextApi,
	StickersElementApi,
	StorysetApi,
	UploadApi,
	VideoApi,
} from '@/Types/apiClient';
import { Color, SolidColorDTO } from '@/Types/colorsTypes';
import { SerializableClass } from '@/utils/classes/SerializableClass';

export type SerializedClass<T> = {
	[P in keyof T]?: T[P] extends SerializableClass ? T[P] | object : T[P];
};

export interface Dictionary<T> {
	[index: string]: T;
}

export type ComponentProps<T> = {
	element: T;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
};
export interface Template {
	name: string;
	id: string;
	background: Color;
	elements: { [id: string]: Element };
}

export interface UserVector {
	uuid: string;
	project: string;
	preview: string;
}

export interface TemplateCategory {
	id: number;
	name: string;
}

export interface ElementCategory {
	id: string | number;
	name: string;
}

export interface ElementType {
	id: InsertableApiType;
	name: string;
	elements:
		| FlaticonElementApi[]
		| BasicShapeApi[]
		| MaskApi[]
		| StorysetApi[]
		| StickersElementApi[]
		| LinesAndArrowsApi[]
		| PredefinedTextApi[];
	order: number;
}

export enum PanelElementType {
	button = 'button',
	link = 'link',
	line = 'line',
}
interface PanelElementData {
	name?: string;
	hide?: boolean;
	panel?: Panels;
	icon?: string;
	removable?: boolean;
	url?: string;
	subtitle?: string;
}

export interface PanelElement {
	type: PanelElementType;
	data: PanelElementData;
}

export interface Font {
	name: string;
	origin: string;
	weights: string[];
	preview: string;
	recommended: number;
	slug: string;
}

export interface LoadFont {
	slug: string;
	weight: string;
}

export interface FontMultipleWeights {
	name: string;
	source: Font;
	weight: string[];
}

export interface Artboard {
	name: string;
	height: number;
	width: number;
	unit: Unit;
}
export interface FullSize extends Size {
	unit: Unit;
}
export interface Size {
	width: number;
	height: number;
}
export interface Position {
	x: number;
	y: number;
}
export interface Flip {
	x: boolean;
	y: boolean;
}

export interface ViewBox {
	x: number;
	y: number;
	width: number;
	height: number;
}

export enum HandlerPosition {
	topLeft = 'top-left',
	topRight = 'top-right',
	bottomLeft = 'bottom-left',
	bottomRight = 'bottom-right',
}

export enum DownloadFormat {
	jpg = 'jpeg',
	png = 'png',
	tpng = 'tpng',
	pdf = 'pdf',
	app = 'app',
	mp4 = 'mp4',
	pptx = 'pptx',
}
export type tagType = { id: number; name: { en: string } };

export enum ShareModalType {
	share = 'share',
	instagram = 'instagram',
	instagrambusiness = 'instagram business',
	facebook = 'facebook',
	pinterest = 'pinterest',
	twitter = 'twitter',
}

export enum ShareModalOption {
	send = 'send',
	schedule = 'schedule',
	publishnow = 'publishnow',
	embed = 'embed',
}

export enum Anchor {
	right = 'right',
	left = 'left',
	top = 'top',
	bottom = 'bottom',
	centerX = 'center-x',
	centerY = 'center-y',
	topLeft = 'top-left',
	topRight = 'top-right',
	bottomLeft = 'bottom-left',
	bottomRight = 'bottom-right',
	center = 'center',
	topCenter = 'top-center',
	bottomCenter = 'bottom-center',
	leftCenter = 'left-center',
	rightCenter = 'right-center',
}

export enum Alignment {
	Right = 'right',
	left = 'left',
	Center = 'center',
	Justify = 'justify',
}

export interface DragStateHandlerPosition extends DragState {
	positionHandler: HandlerPosition;
}

export type Unit = 'px' | 'mm';

export interface Brandkit {
	name: string;
	slug: string;
	userCollections: any[];
	logos: BrandkitLogo[];
	colors: string[];
	fonts: BrandkitFont[];
}

export interface BrandkitFont {
	name: string;
	weights: string[];
	origin: string;
	slug: string;
	preview: string;
	brandkitPreview: string;
}

export interface BrandkitLogo {
	id: string;
	type: string;
	url: string;
	preview: string;
	backgroundRemoved: null;
	metadata: BrandkitLogoMetadata;
	origin: string;
}

export interface ColorInput {
	a: number;
	rgba: SolidColorDTO;
	hex: string;
	hex8: string;
	oldHue: number;
	hsl: {
		h: number;
		s: number;
		l: number;
		a: number;
	};
	hsv: {
		h: number;
		s: number;
		v: number;
		a: number;
	};
	source: string;
}

export interface BrandkitLogoMetadata {
	uploadID: string;
	duration: null;
}

export interface User {
	id: string;
	fpId: string;
	avatar: string;
	email: string;
	isAdmin: boolean;
	freepikPremium: boolean;
	name: string;
	interests: string;
	tokens: UserTokens;
	fonts: Font[];
	brandkits: Brandkit[];
}

export interface UserTokens {
	google: Token;
	dropbox: Token;
	facebook: Token;
	pinterest: Token;
	twitter: Token;
}

export interface TemplateSuggestion {
	element: string;
	message: string;
	fix?: () => void;
}

export interface Token {
	status: boolean;
	token: string | null;
}

export type DraggableItemData =
	| BasicShapeApi
	| FlaticonElementApi
	| MaskApi
	| ImageApi
	| PredefinedTextApi
	| StickersElementApi
	| LinesAndArrowsApi
	| StorysetApi
	| UploadApi
	| VideoApi;

export interface DraggableItem {
	type: InsertableApiType | Panels;
	data: DraggableItemData;
}

export type PrimaryElementTypes =
	| 'illustrator'
	| 'image'
	| 'line'
	| 'qrcode'
	| 'shape'
	| 'box'
	| 'storyset'
	| 'text'
	| 'foregroundImage'
	| 'video';

export type ElementClass =
	| Image
	| Line
	| Shape
	| Storyset
	| Video
	| Text
	| ForegroundImage
	| QRCode
	| Illustrator
	| Box;

export enum EditPanels {
	GlobalColors = 'GlobalColors',
	Filter = 'Filter',
	Group = 'Group',
	Image = 'Image',
	Foreground = 'Foreground',
	Line = 'Line',
	Mask = 'Mask',
	QRCode = 'QRCode',
	Shape = 'Shape',
	Storyset = 'Storyset',
	Text = 'Text',
	Video = 'Video',
	ImagePresets = 'ImagePresets',
	TextEffects = 'TextEffects',
	TextLink = 'TextLink',
	TextShadow = 'TextShadow',
	TextCurve = 'TextCurve',
	TextBorder = 'TextBorder',
	ImageLayerSelector = 'ImageLayerSelector',
	ColorPicker = 'ColorPicker',
	Box = 'Box',
	BorderBox = 'BorderBox',
	CornerBox = 'CornerBox',
	WriterAI = 'WriterAI',
}

export enum Panels {
	templates = 'templates',
	texts = 'texts',
	elements = 'elements',
	photos = 'photos',
	videos = 'videos',
	photoEdit = 'photo-edit',
	myCreations = 'my-creations',
	treeEditor = 'tree-editor',
	brandKits = 'brand-kits',
	uploads = 'uploads',
	moreTools = 'more-tools',
	dropbox = 'dropbox',
	googleDrive = 'google-drive',
	googlePhotos = 'google-photos',
	qrCode = 'qr-code',
	textToImage = 'text-to-image',
	aiWriter = 'ai-writer',
	aiPresentation = 'ai-presentation',
	dev = 'dev',
	templatePages = 'template-pages',
}

export enum ElementsPanelTypes {
	Flaticon = 'icons',
	Storysets = 'illustrations',
	Stickers = 'stickers',
	BasicShapes = 'basic-shapes',
	ImageMasks = 'image-masks',
	LinesAndArrows = 'lines-and-arrows',
	QRCodes = 'qr-codes',
}

export enum TextsPanelTypes {
	CategorizedTexts = 'categorized-texts',
}

export enum InsertableApiType {
	BasicShape = 'basic-shape',
	Flaticon = 'flaticon',
	Image = 'image',
	ImageMask = 'image-mask',
	Sticker = 'sticker',
	Storyset = 'storyset',
	LineAndArrow = 'line-and-arrow',
	PredefinedText = 'predefined-text',
	QRCode = 'qr-code',
	SVG = 'svg',
	Video = 'video',
}

export enum StorysetsStyles {
	Amico = 'amico',
	Bro = 'bro',
	Cuate = 'cuate',
	Pana = 'pana',
	Rafiki = 'rafiki',
}

export type ValidZoomTypes =
	| 10
	| 20
	| 30
	| 40
	| 50
	| 60
	| 70
	| 80
	| 90
	| 100
	| 125
	| 150
	| 175
	| 200
	| 250
	| 300
	| 350
	| 400
	| 450
	| 500
	| 750;

export enum InteractionAction {
	Drag = 'drag',
	Resize = 'resize',
	Rotate = 'rotate',
	Select = 'select',
	Idle = 'idle',
}

export enum DragAction {
	Move = 'move',
	Remove = 'remove',
}

export type Linecap = 'butt' | 'round';

export interface Marker {
	element: string;
	color: Color;
}

export interface MediaHover {
	target: Image | Video;
	data: ImageApi | MaskApi; // VideoApi es de momento como ImageApi
}

export interface CurvedProperties {
	arc: number | null;
	minArc: number;
	transformCurve: number;
}

export interface TextWithValue extends Partial<Text> {
	text: string;
}

export interface TextShadowProperties {
	angle: number;
	blur: number;
	color: SolidColor;
	distance: number;
	opacity: number;
	unit?: string;
}

export interface TextOutlineProperties {
	color: SolidColor;
	width: number;
	unit?: string;
}

export enum TextEffects {
	None = 'None',
	Outline = 'Outline',
	Curve = 'Curve',
	Shadow = 'Shadow',
	Echo = 'Echo',
	Neon = 'Neon',
	Glitch = 'Glitch',
	Sticker = 'Sticker',
}

export interface RectBox {
	top: number;
	right: number;
	bottom: number;
	left: number;
	center: number;
	middle: number;
}
export interface MoveConfig {
	axis: 'x' | 'y';
	dir: 1 | -1;
}
export interface UseApiFetchOptions extends UseFetchOptions {
	concat?: boolean;
	get?: 'elements' | 'categories';
}

export enum SizeTextTags {
	Heading = 'heading',
	SubHeading = 'subheading',
	BodyTitle = 'body-title',
}

export enum TypeTextTags {
	Email = 'email',
	Phone = 'phone',
	Address = 'address',
	Website = 'website',
	Date = 'date',
	Name = 'name',
	Occupation = 'occupation',
}

export type TextTags = SizeTextTags | TypeTextTags;

export enum ImageTag {
	Logo = 'logo',
	Background = 'background',
}

export interface TagsColorTemplate {
	primary: string[] | string | undefined;
	secondary: string[] | string | undefined;
}

export interface TrackingRequest {
	path: string;
	ga_client_id: string;
	lang: string;
	user_id?: string;
	wepik_user_id?: string;
	referrer: string;
	page: number;
	search: string;
	referral_source: string;
	mode: string;
	context: string;
	template_category?: string | null;
}

export enum DaysList {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
}

export enum MonthsList {
	January,
	February,
	March,
	April,
	May,
	June,
	July,
	August,
	September,
	October,
	November,
	December,
}

export enum BackgroundModes {
	Foreground = 'foreground',
	Background = 'background',
	Both = 'both',
	Original = 'original',
}

export enum AiModalType {
	help = 'help',
	generator = 'generator',
}

export enum AIStyles {
	default = 'default',
	photo = 'Photography',
	digitalArt = 'Digital Art',
	threeD = '3D',
	painting = 'Painting',
	pattern = 'Pattern',
	surprise = 'Surprise',
}

export enum Ratio {
	square = 'square',
	portrait = 'portrait',
	landscape = 'landscape',
}

export enum AiViewerMode {
	viewer = 'viewer',
	replace = 'replace',
}

export enum RotationHandlerPosition {
	Top = 'top',
	Right = 'right',
	Bottom = 'bottom',
	Left = 'left',
}

export enum Lang {
	Es = 'es',
	En = 'en',
}

export type TrackedJobId = string;
export interface TrackedJobResponse {
	uuid: string;
}

export type WeightProps = {
	family: string;
	weight: string[];
}[];

export interface BorderBoxStyle {
	strokeLinecap: 'solid' | 'butt' | 'round';
	strokeDashArray: string;
}

export enum TypeBorder {
	Solid = 'solid',
	Dotted = 'dotted',
	Dashed = 'dashed',
	None = 'none',
}

export interface Border {
	type: TypeBorder;
	size: number;
	color: Color;
	radius: BorderRadiusType;
}

export type BorderRadiusType = [number, number, number, number];
export interface DownloadInfo {
	url: string;
	name: string;
	format: DownloadFormat;
	status: 'done' | 'progress' | 'error';
	duration: number;
}

export enum ModifierType {
	Style = 'style',
	Color = 'color',
	Lighting = 'lighting',
	Framing = 'framing',
}

export interface Modifier {
	name: string;
	type: ModifierType;
}

export interface Prompt {
	query: string;
	modifiers: Modifier[];
	aspectRatio: PromptAspectRatio;
}

export enum PromptAspectRatio {
	square = 'square',
	portrait = 'portrait',
	landscape = 'landscape',
}

export interface AiPresentationStyle {
	id: number;
	image: string;
	name: string;
}

export interface AIWriterSelectedAnswer {
	id: string;
	prompt: string;
	generation: string;
}

export enum AiPresentationTone {
	unspecified = 'unspecified',
	fun = 'fun',
	creative = 'creative',
	casual = 'casual',
	professional = 'professional',
	formal = 'formal',
}

export enum AiPresentationLanguages {
	'zh' = '中文',
	'en' = 'English',
	'es' = 'Español',
	'pt' = 'Português',
	'de' = 'Deutsch',
	'it' = 'Italiano',
	'fr' = 'Français',
	'hi' = 'हिन्दी',
	'bn' = 'বাংলা',
	'ru' = 'Русский',
	'ja' = '日本語',
	'pa' = 'ਪੰਜਾਬੀ',
	'mr' = 'मराठी',
	'te' = 'తెలుగు',
	'tr' = 'Türkçe',
	'ko' = '한국어',
	'vi' = 'Tiếng Việt',
	'ta' = 'தமிழ்',
	'yo' = 'Yorùbá',
	'ur' = 'اردو',
	'jv' = 'Basa Jawa',
	'gu' = 'ગુજરાતી',
	'pl' = 'Polski',
	'uk' = 'Українська',
	'ms' = 'Bahasa Melayu',
}

export type ItemList = {
	name: string;
	id: string;
	icon?: string;
	disabled?: boolean;
	selected?: boolean;
};

export type AiImageBody = {
	uuid: string;
	prompt: string;
	modifiers: Modifier[];
	aspect_ratio: string;
	images: number;
	seed?: number;
	steps?: number;
	cfg?: number;
	generator?: string;
	imageUrl?: string;
};

export interface Pack {
	pack: string;
	price: number;
	currency: string;
	code: string;
	credits: number;
	images: number;
}
export interface AiPresentationParam {
	topic: string;
	tone: string;
	language: string;
	vector: number;
	slides: number;
}

export interface CreditEventInfo {
	item_name: string;
	item_id: string;
	price: number;
	item_brand: string;
	item_category: string;
	item_category2: string;
	item_category3: string;
	item_list_name: string;
	coupon: string;
	index: number;
	quantity: number;
}

export interface SelectedFont {
	font: Font;
	source?: Font;
	weight: string;
	style: boolean;
}

export interface PredefinedTextSubcategoryName {
	name: string;
}
export type Option = {
	label: string;
	value: string;
};

export enum PaletteOptionsValues {
	DOCUMENT = 'document',
	BRANDS = 'brands',
}

export interface RangeContainersIndex {
	anchorNodeIndex: number;
	focusNodeIndex: number;
}

export interface SelectionResult {
	text?: string | null;
	selection: Selection | null;
}

export interface RestoreSelectionParams {
	anchorNode: Node;
	anchorOffset: number;
	focusNode: Node;
	focusOffset: number;
	isCollapsed: boolean;
	domNode: HTMLElement | null;
	containersIndex?: RangeContainersIndex;
	selection?: SelectionResult | null;
}

export type SelectionDirection = 'before' | 'after' | 'nodes not found';

export interface PreviousSelection {
	anchorNode: Node;
	anchorOffset: number;
	focusNode: Node;
	focusOffset: number;
	isCollapsed: boolean;
	wholeTextSelected?: boolean;
}

interface IframeDataBase {
	eventName: string;
}

export interface IframePublishEvent extends IframeDataBase {
	url: string;
	templateId: string;
}

export interface IframeAutoSaveEvent extends IframeDataBase {
	templateId: string;
}
