<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import QRCodeMenu from '@/elements/qr-code/components/menus/QRCodeMenu.vue';
import { useQRCodeAsSVG } from '@/elements/qr-code/composables/useQRCodeAsSVG';
import { useI18n } from '@/i18n/useI18n';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useActivePage } from '@/page/composables/useActivePage';

// Data
const element = ref(QRCode.create());
const elementPrev = ref(QRCode.create());
elementPrev.value.url = 'https://wepik.com';

const qrPreview = useQRCodeAsSVG(elementPrev);
const qr = useQRCodeAsSVG(element);
const { listen } = useOrderedKeyboardListener();
const { trans } = useI18n();

// Computeds
const isReady = computed(() =>
	/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.exec(
		element.value.url
	)
);

// Using composables

const store = useMainStore();
const { runOnMobile } = useDeviceInfo();
const { addElement } = useActivePage();
const usingTransformOrchestrator = useElementTransformOrchestrator(element);

// Methods
const onInsert = () => {
	if (!isReady.value) throw new Error('QRCode can not be inserted!');

	addElement(element.value);
	usingTransformOrchestrator.value.setupInPage();
	resetGenerator();

	Bugsnag.leaveBreadcrumb(`Insert ${element.value.type}-${element.value.id}`);
	runOnMobile(() => (store.activePanel = null));
};

const resetGenerator = () => {
	element.value = QRCode.create();
};

// Register events
listen('Enter', () => {
	if (!isReady.value) return;
	onInsert();
});
</script>

<template>
	<div data-testid="add-qr-code-panel" class="flex h-full flex-col">
		<PanelHeader :title="trans('QR Codes')" />
		<div class="mb-8 mt-4 flex w-full items-center justify-center">
			<svg
				v-if="!qr || element.url === QRCode.defaults().url"
				:viewBox="QRCode.getViewBoxFromRawSvg(qrPreview)"
				class="h-32 w-32 rounded-lg"
				preserveAspectRatio="none"
				v-html="QRCode.getInnerSvg(qrPreview)"
			></svg>
			<svg
				v-else
				:viewBox="QRCode.getViewBoxFromRawSvg(qr)"
				class="h-32 w-32 rounded-lg"
				preserveAspectRatio="none"
				v-html="QRCode.getInnerSvg(qr)"
			></svg>
		</div>
		<p class="mb-8 text-center text-sm text-gray-100">
			{{ trans('Enter a URL and a QR code will be automatically generated to include it in your design.') }}
			<strong>{{ trans('Anyone will be able to scan it and access that URL directly.') }}</strong>
		</p>

		<QRCodeMenu :element="element" :editing="false" />

		<button
			data-testid="insert-qr-btn"
			class="mb-2 flex h-9 items-center justify-center rounded px-3 font-semibold lg:pl-3 lg:pr-4"
			:class="{
				'cursor-not-allowed bg-gray-600 text-gray-800': !isReady,
				'bg-blue-500 text-white slidesgo:bg-purple-400': isReady,
			}"
			:disabled="!isReady"
			@click="onInsert"
		>
			<span>{{ trans('Insert QR') }}</span>
		</button>
	</div>
</template>
