<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, toRef } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import ColorPicker from '@/color/components/ColorPicker.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import TextCurved from '@/elements/texts/curved/components/buttons/TextCurved.vue';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import AiWriterButton from '@/elements/texts/text/components/buttons/AiWriterButton.vue';
import RemoveTextToolbar from '@/elements/texts/text/components/buttons/RemoveTextToolbar.vue';
import TextBorderButtonToolbar from '@/elements/texts/text/components/buttons/TextBorderButtonToolbar.vue';
import TextEffectsButtonToolbar from '@/elements/texts/text/components/buttons/TextEffectsButtonToolbar.vue';
import TextLinkButton from '@/elements/texts/text/components/buttons/TextLinkButton.vue';
import TextShadowButtonToolbar from '@/elements/texts/text/components/buttons/TextShadowButtonToolbar.vue';
import FontPicker from '@/elements/texts/text/components/menus/FontPicker.vue';
import ToolbarFontSize from '@/elements/texts/text/components/menus/ToolbarFontSize.vue';
import { useCopyStyles } from '@/elements/texts/text/composables/useCopyStyles';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { Color } from '@/Types/colorsTypes';
import { EditPanels, Font, SelectedFont } from '@/Types/types';

// Props
const props = defineProps<{ element: Text }>();

// Data
const element = toRef(props, 'element');

const isPasteStylesMode = computed(() => Object.keys(copiedStyles.value).length > 0);

// Using composables
const { copiedStyles, clearCopiedStyles, copyStyles } = useCopyStyles();
const { isMobile } = useDeviceInfo();
const { trans } = useI18n();
const { selection, selectionId } = useSelection();

const { textEditing } = useTextEditing();
const { togglePanel } = usePanelManagement();
const {
	selectedColor,
	fontSizeScaled,
	finalFontFamily,
	fontWeight,
	italic,
	boldAvailable,
	italicAvailable,
	updateFontSize,
	updateColor,
	updateFontFamily,
	updateFontWeight,
	updateFontStyle,
	updateTextAlign,
	rescaleText,
	listStyle,
	updateList,
} = useTextStyles(element);
const { breadScrumbWithDebounce } = useBugsnag(element);
const { isCircleText } = useCircleTypeInfo(element);
const store = useMainStore();

// Methods
const updateTextFontSize = (fontSize: number | 'plus' | 'minus') => {
	let fontSizeReal = fontSize;
	if (element.value.scale !== 1) {
		rescaleText();
	}

	// Hay que tener en cuenta el scaladao que metemos en iOS
	if (typeof fontSizeReal === 'number') {
		fontSizeReal = fontSizeReal * store.scaleMaxAllowedSize;
	}

	updateFontSize(fontSizeReal);
	breadScrumbWithDebounce('fontSize');
};

const updateWeight = (val: string | MouseEvent) => {
	if (!boldAvailable.value) return;

	if (typeof val === 'string') {
		Bugsnag.leaveBreadcrumb(`Update font weight text-${element.value.id}: ${val}`);
		updateFontWeight(val);
	}
	updateFontWeight();

	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'bold' });
};

const updateStyle = () => {
	if (!italicAvailable.value || !(typeof italicAvailable.value === 'string' && italicAvailable.value.includes('i')))
		return;
	updateFontStyle();

	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'italic' });
};

const alignClickManager = () => {
	switch (props.element.textAlign) {
		case 'left': {
			updateTextAlign('center');
			break;
		}
		case 'center': {
			updateTextAlign('right');
			break;
		}
		case 'right': {
			updateTextAlign('justify');
			break;
		}
		case 'justify': {
			updateTextAlign('left');
			break;
		}
	}

	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'justify' });
};

const listClickManager = () => {
	if (element.value.scale !== 1) {
		rescaleText();
	}

	switch (listStyle.value) {
		case '': {
			updateList('unordered');
			break;
		}
		case 'unordered': {
			updateList('ordered');
			break;
		}
		case 'ordered': {
			updateList('');
			break;
		}
	}
	GAnalytics.track('click', 'Button', `text-list-${listStyle.value}`, null);
};

const trackFontFamily = async (font: Font) => {
	await updateFontFamily(font);
	GAnalytics.track('click', 'Button', `change-font-family`, null);
};

const onClickMoreTools = () => {
	Bugsnag.leaveBreadcrumb('Open edit text panel');
	togglePanel(EditPanels.Text);

	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'more_options' });
};

const onClickEdit = () => {
	if (textEditing.value) {
		textEditing.value = null;
		return;
	}

	if (selection.value.length && selectionId.value[0] === props.element.id) {
		textEditing.value = props.element;
		Bugsnag.leaveBreadcrumb(`Start editing text-${props.element.id}`);
	}
};

const onUpdateColor = async (color: Color) => {
	updateColor(color);
};

const onClickColorPicker = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'color' });
};

const onClickRemove = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'delete' });
};

const updateFont = async (selectedFont: SelectedFont) => {
	await trackFontFamily(selectedFont.font);
	await updateFontWeight(selectedFont.weight || '400');
	await updateFontStyle(selectedFont.style || false);
};

const onCopyTextStyles = () => {
	copyStyles(element.value);
};
</script>

<template>
	<div data-keep-text-selection class="flex-col items-start lg:flex-row">
		<template v-if="!isPasteStylesMode">
			<template v-if="!element.locked">
				<div class="mb-4 flex w-full items-center gap-2 px-3 lg:mb-0 lg:gap-0 lg:px-0">
					<!-- Edit Mobile Button -->
					<button
						data-testid="edit-text"
						class="flex h-8 w-8 items-center justify-center rounded bg-gray-600 px-7 text-sm text-white lg:hidden"
						@click="onClickEdit"
					>
						{{ trans('Edit') }}
					</button>

					<!-- Remove -->
					<RemoveTextToolbar v-if="isMobile" :element="element" />

					<!-- Font Family picker -->
					<FontPicker :key="'toolbar-' + finalFontFamily" :element="element" @updateFont="updateFont" />

					<!-- FontSize -->
					<ToolbarFontSize :selected="fontSizeScaled" @update="updateTextFontSize" />
				</div>
				<div class="flex w-screen items-center overflow-auto pl-2 lg:m-0 lg:w-full lg:overflow-visible lg:p-0">
					<div
						class="flex h-10 w-[15vw] shrink-0 items-center justify-center lg:w-10"
						:tooltip="trans('Bold')"
						tooltip-position="top"
					>
						<!-- Bold -->
						<button
							class="flex h-11 w-full flex-col items-center justify-between rounded lg:h-8 lg:w-8 lg:justify-center"
							:class="{
								'text-white mockup:bg-fp-gray-150 mockup:text-blue-500 lg:bg-white/10':
									fontWeight.length >= 1 && fontWeight.some((val) => val >= 600),
								'text-gray-100 hover:text-white mockup:text-fp-gray-500 mockup:hover:text-blue-500': fontWeight.some(
									(val) => val < 600
								),
								'cursor-not-allowed text-gray-100 opacity-50 mockup:text-fp-gray-600': !boldAvailable,
							}"
							data-title="bold"
							@click="updateWeight"
						>
							<SvgIcon name="bold" class="h-6 w-6" />
							<span
								:class="{ 'text-white': fontWeight.length >= 1 && fontWeight.some((val) => val >= 600) }"
								class="text-2xs lg:hidden"
							>
								{{ trans('Bold') }}
							</span>
						</button>
					</div>
					<!-- Italic -->
					<div
						class="flex h-10 w-[15vw] shrink-0 items-center justify-center lg:w-10"
						:tooltip="trans('Italic')"
						tooltip-position="top"
					>
						<button
							class="flex h-11 flex-col items-center justify-between rounded text-gray-100 lg:h-8 lg:w-8 lg:justify-center"
							:class="{
								'text-white mockup:bg-fp-gray-150 mockup:text-blue-500 lg:bg-white/10':
									italic.length === 1 && italic[0] === 'italic',
								'hover:text-white mockup:text-fp-gray-500 mockup:hover:text-blue-500': italic[0] !== 'italic',
								'cursor-not-allowed  opacity-50 mockup:text-fp-gray-600': !italicAvailable,
							}"
							data-title="italic"
							@click="updateStyle"
						>
							<SvgIcon name="italic" class="h-6 w-6" />
							<p :class="{ 'text-white': italic.length === 1 && italic[0] === 'italic' }" class="text-2xs lg:hidden">
								{{ trans('Italic') }}
							</p>
						</button>
					</div>

					<!-- Text align -->
					<button
						:disabled="isCircleText"
						:class="isCircleText ? 'cursor-not-allowed opacity-50' : ''"
						class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-10 lg:justify-center"
						data-title="alignText"
						:tooltip="trans('Text align')"
						tooltip-position="top"
						@click="alignClickManager()"
					>
						<SvgIcon v-if="element.textAlign === 'left'" name="alignleft" class="h-5 w-5" />
						<SvgIcon v-if="element.textAlign === 'center'" name="aligncenter" class="h-5 w-5" />
						<SvgIcon v-if="element.textAlign === 'right'" name="alignright" class="h-5 w-5" />
						<SvgIcon v-if="element.textAlign === 'justify'" name="alignjustify" class="h-5 w-5" />
						<p v-if="element.textAlign === 'left'" class="text-2xs lg:hidden">{{ trans('Left') }}</p>
						<p v-if="element.textAlign === 'center'" class="text-2xs lg:hidden">{{ trans('Center') }}</p>
						<p v-if="element.textAlign === 'right'" class="text-2xs lg:hidden">{{ trans('Right') }}</p>
						<p v-if="element.textAlign === 'justify'" class="text-2xs lg:hidden">{{ trans('Justify') }}</p>
					</button>

					<!-- Text Bullets -->
					<div
						class="flex h-10 w-[15vw] shrink-0 items-center justify-center lg:w-10"
						:tooltip="trans('List')"
						tooltip-position="top"
					>
						<button
							data-testid="text-list"
							:disabled="isCircleText"
							class="flex h-11 flex-col items-center justify-between rounded text-gray-100 lg:h-8 lg:w-8 lg:justify-center"
							:class="{
								'cursor-not-allowed opacity-50': isCircleText,
								'text-gray-100 hover:text-white': listStyle === '',
								'text-white mockup:bg-fp-gray-150 mockup:text-blue-500 lg:bg-white/10': listStyle !== '',
							}"
							data-title="alignText"
							:tooltip="trans('List')"
							tooltip-position="top"
							@click="listClickManager()"
						>
							<SvgIcon v-if="listStyle === ''" name="list" class="h-5 w-5" />
							<SvgIcon v-if="listStyle === 'unordered'" name="list" class="h-5 w-5" />
							<SvgIcon v-if="listStyle === 'ordered'" name="list-ordened" class="h-5 w-5" />
							<p v-if="listStyle === ''" class="text-2xs lg:hidden">{{ trans('List') }}</p>
							<p v-if="listStyle === 'unordered'" class="text-2xs lg:hidden">{{ trans('Unordered') }}</p>
							<p v-if="listStyle === 'ordered'" class="text-2xs lg:hidden">{{ trans('Ordered') }}</p>
						</button>
					</div>

					<!-- Text shadow -->
					<TextShadowButtonToolbar v-if="isMobile" :element="element" />
					<TextCurved v-if="isMobile" :element="element" />
					<TextBorderButtonToolbar v-if="isMobile" :element="element" />

					<!-- Color picker -->
					<div
						id="color-picker-text-toolbar"
						class="order-first mr-2 border-r border-gray-600 pl-1 pr-4 lg:order-none lg:ml-1 lg:h-5 lg:w-5 lg:border-0 lg:px-0"
					>
						<ColorPicker
							parent=".toolbar"
							placement="right-start"
							:tooltip="trans('Text color')"
							tooltip-position="top"
							class="h-8 w-8 lg:-mt-2.5 lg:h-10 lg:w-5 lg:py-2.5"
							:color="(selectedColor as Color[] | Color)"
							:disable-radial="isCircleText"
							:disable-angle="isCircleText"
							:hide-gradient="isCircleText"
							@click="onClickColorPicker"
							@change="onUpdateColor"
						/>
					</div>

					<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 mockup:bg-fp-gray-250 lg:block" />

					<!-- Order -->
					<OrderToolbar v-if="isMobile" :element="element" />

					<!-- Copy -->
					<CopyToolbar v-if="isMobile" :element="element" />

					<!-- Link -->
					<TextLinkButton :element="element" />

					<AiWriterButton :element="element" />

					<TextEffectsButtonToolbar v-if="isMobile" :element="element" />

					<!-- Remove desktop toolbar button -->
					<RemoveToolbar v-if="!isMobile" :element="element" @remove="onClickRemove" />

					<!-- Copy styles -->
					<div
						class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-10 lg:w-8 lg:justify-center"
						:tooltip="trans('Copy styles')"
						tooltip-position="top"
					>
						<button
							class="flex h-full w-full flex-col items-center justify-between sm:flex-row sm:justify-center"
							data-title="copy-styles"
							@click="onCopyTextStyles"
						>
							<SvgIcon name="paint-roll" class="h-5 w-5 scale-90" />
							<p class="text-2xs lg:hidden">
								{{ trans('Copy Styles') }}
							</p>
						</button>
					</div>

					<MoreToolsToolbar :element="element" :panel="EditPanels.Text" @more-tools="onClickMoreTools" />
				</div>
			</template>

			<!-- Lock -->
			<LockButtonToolbar v-if="!isMobile && element.locked" :element="element" />
		</template>

		<button
			v-if="isPasteStylesMode"
			class="flex h-8 items-center justify-center px-4 text-sm font-semibold text-white opacity-75 hover:opacity-100 mockup:text-fp-gray-700 lg:h-6 lg:px-3"
			@click="clearCopiedStyles"
		>
			<SvgIcon name="cross" class="mr-1 h-4 w-4 fill-current" />
			{{ trans('Cancel') }}
		</button>
	</div>
</template>
