import { ref } from 'vue';

import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useMainStore } from '@/editor/stores/store';
import { useProjectStore } from '@/project/stores/project';

const showUnsavedChangesModal = ref(false);
const confirmedNavigation = ref(false);
const intendedNavigation = ref('');

export default function () {
	const { DEV } = useEnvSettings();
	const navigateWithoutLosingChanges = (url: string) => {
		const store = useProjectStore();

		if (store.allChangesSaved) {
			window.location.href = url;
			return;
		}

		showUnsavedChangesModal.value = true;
		intendedNavigation.value = url;
	};

	const confirmIntendedNavigation = () => {
		showUnsavedChangesModal.value = false;
		confirmedNavigation.value = true;
		window.location.href = intendedNavigation.value;
	};

	const guardAgainstUnsavedChanges = () => {
		if (DEV) return;

		window.addEventListener('beforeunload', (e) => {
			const projectStore = useProjectStore();
			const mainStore = useMainStore();

			if (confirmedNavigation.value || projectStore.allChangesSaved || mainStore.disableBeforeUnload) {
				return;
			}

			e.returnValue = "Are you sure? Your changes aren't saved jet";

			return e.returnValue;
		});
	};

	return {
		navigateWithoutLosingChanges,
		showUnsavedChangesModal,
		confirmIntendedNavigation,
		guardAgainstUnsavedChanges,
	};
}
