<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, onBeforeMount, toRef } from 'vue';
import RoundSlider from 'vue3-slider';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ element: Text }>();
const { isMobile } = useDeviceInfo();
const percentageBetween = (min: number, max: number, value: number) => ((value - min) / (max - min)) * 100;

const element = toRef(props, 'element');

const { trans } = useI18n();

const { hasShadow } = useTextEffects(element);

const {
	updateShadowAngle,
	updateShadowColor,
	updateShadowOpacity,
	updateShadowDistance,
	updateShadowBlur,
	resetTextShadow,
} = useTextStyles(element);

const { closePanel } = usePanelManagement();
// For now, only the first stop is supported but multi-text-shadow will be supported in the future
const computedTextShadow = computed(() => element.value.textShadow.slice(0, 1));

const MAX_DISTANCE = 3;
const MAX_BLUR = 1;
const DEFAULT_TEXT_SHADOW = {
	angle: 45,
	blur: 0.1,
	color: SolidColor.black(),
	distance: 0.3,
	opacity: 0.5,
	unit: 'em',
};
onBeforeMount(() => {
	if (hasShadow.value && element.value.textShadow.length === 1) {
		if (element.value.textShadow[0].blur > MAX_BLUR) {
			element.value.textShadow[0].blur = MAX_BLUR;
		}

		if (element.value.textShadow[0].distance > MAX_DISTANCE) {
			element.value.textShadow[0].distance = MAX_DISTANCE;
		}

		if (element.value.textShadow[0].opacity > 100) {
			element.value.textShadow[0].opacity = 100;
		}
	}

	if (!hasShadow.value || element.value.textShadow.length > 1) {
		element.value.textShadow = [DEFAULT_TEXT_SHADOW];
		Bugsnag.leaveBreadcrumb(`Apply default text shadow values to text-${element.value.id}`);
	}

	GAnalytics.track('click', 'Button', 'text-shadow', null);
});

const onUpdateBlur = (index: number, e: Event) => {
	const value = parseFloat((e.target as HTMLInputElement)?.value) || 0;
	updateShadowBlur(index, value);
};

const onUpdateColor = (index: number, value: SolidColor) => {
	updateShadowColor(index, value);
};

const onUpdateDistance = (index: number, e: Event) => {
	const value = parseFloat((e.target as HTMLInputElement)?.value) || 0;
	updateShadowDistance(index, value);
};

const onUpdateOpacity = (index: number, e: Event) => {
	const value = parseFloat((e.target as HTMLInputElement)?.value) || 1;
	updateShadowOpacity(index, value * 0.01);
};

const onUpdateAngle = (index: number, angle: number) => {
	updateShadowAngle(index, angle);
};
const onReset = () => {
	resetTextShadow();
	closePanel(props.element);
};
</script>

<template>
	<div
		ref="textShadowContainer"
		data-testid="text-shadow-panel"
		:class="{
			'fixed bottom-16 left-0 z-10 w-full rounded-tl-2xl rounded-tr-2xl bg-gray-700 px-4 py-3 text-left lg:absolute lg:-right-50 lg:bottom-auto lg:left-auto lg:top-0 lg:ml-2 lg:w-48 lg:rounded-lg lg:bg-gray-800 lg:px-3 lg:shadow-custom':
				isMobile,
		}"
	>
		<div v-if="isMobile" class="mb-4 flex items-center justify-between">
			<div class="flex items-center">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Text shadow') }}</h2>
				<button class="flex items-center text-xs text-gray-100 hover:text-white" @click="onReset">
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					{{ trans('Reset') }}
				</button>
			</div>
			<button
				v-if="isMobile"
				data-testid="close-panel"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="closePanel(element)"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>
		<!-- Warning: check if :key(s) should be uuids if we support multi-shadow in the future -->
		<div v-for="(ts, i) in computedTextShadow" :key="i" class="text-shadow-panel flex gap-4 sm:pt-4">
			<div class="flex w-2/6 items-start gap-4">
				<div class="flex flex-col items-center gap-1">
					<ColorPicker
						class="h-10 w-10"
						data-testid="testShadowColor"
						:color="(ts.color as Color)"
						hide-alpha
						hide-gradient
						@change="(color) => onUpdateColor(i, color as SolidColor)"
					/>
					<label class="text-xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Color') }}</label>
				</div>

				<div class="flex flex-col items-center gap-1">
					<div class="input-range relative flex h-10 w-9 items-center" data-testid="testOpacity">
						<RoundSlider
							:model-value="ts.angle"
							:height="4"
							orientation="circular"
							:max="359"
							:min="0"
							:step="1"
							:circle-offset="90"
							:repeat="true"
							track-color="rgba(74,99,117,0.4)"
							color="#485D6D"
							class="shadow-round-slider w-full"
							@change="(angle) => onUpdateAngle(i, angle)"
						/>
					</div>
					<label class="text-xs text-gray-100 mockup:text-fp-gray-700">{{ Math.round(ts.angle) }}º</label>
				</div>
			</div>

			<div class="w-4/6">
				<div class="mb-1">
					<p class="text-xs text-gray-200 mockup:text-fp-gray-600">{{ trans('Opacity') }}</p>
					<div class="flex items-center">
						<div class="input-range relative mr-4 flex w-full">
							<input
								:value="ts.opacity * 100"
								type="range"
								class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200"
								min="0"
								max="100"
								step="5"
								@input="(e) => onUpdateOpacity(i, e)"
								@change="(e) => onUpdateOpacity(i, e)"
							/>
							<span
								class="absolute top-0 h-[2px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
								:style="{
									width: `${ts.opacity * 100}%`,
								}"
							/>
						</div>
						<span
							class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-600"
						>
							{{ Math.round(ts.opacity * 100) }} <small>%</small>
						</span>
					</div>
				</div>

				<div class="mb-1">
					<p class="text-xs text-gray-200 mockup:text-fp-gray-600">{{ trans('Distance') }}</p>
					<div class="flex items-center">
						<div class="input-range relative mr-4 flex w-full">
							<input
								:value="ts.distance"
								type="range"
								class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200"
								:min="-MAX_DISTANCE"
								:max="MAX_DISTANCE"
								step="0.1"
								@input="(e) => onUpdateDistance(i, e)"
								@change="(e) => onUpdateDistance(i, e)"
							/>
							<span
								class="rounted-full absolute left-0 top-0 h-[2px] bg-blue-500 slidesgo:bg-purple-400"
								:style="`width: ${percentageBetween(-MAX_DISTANCE, MAX_DISTANCE, ts.distance)}%`"
							>
							</span>
						</div>
						<span
							class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-600"
						>
							{{ ts.distance.toFixed(1) }} <small>%</small>
						</span>
					</div>

					<div>
						<p class="text-xs text-gray-200 mockup:text-fp-gray-600">{{ trans('Blur') }}</p>
						<div class="flex items-center">
							<div class="input-range relative mr-4 flex w-full">
								<input
									:value="ts.blur"
									type="range"
									class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200"
									min="0"
									:max="MAX_BLUR"
									step="0.1"
									@input="(e) => onUpdateBlur(i, e)"
									@change="(e) => onUpdateBlur(i, e)"
								/>
								<span
									class="rounted-full absolute left-0 top-0 h-[2px] bg-blue-500 slidesgo:bg-purple-400"
									:style="`width: ${percentageBetween(0, MAX_BLUR, ts.blur)}%`"
								>
								</span>
							</div>
							<span
								class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-600"
							>
								{{ ts.blur.toFixed(2) }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.text-shadow-panel .rs-control {
	.rs-tooltip,
	.rs-tooltip.rs-edit {
		@apply flex h-5 w-7 items-center justify-center rounded bg-black p-0 text-2xs text-gray-200;
		line-height: 0;
		margin-left: 40px !important;
		margin-top: 0 !important;
		transform: translate(-50%, -50%) !important;

		&.rs-hover,
		&:hover {
			@apply border-gray-700;
		}

		.rs-tooltip-text {
			@apply text-2xs;
		}
	}
}
.shadow-round-slider.vue3-slider.circular .handle-container {
	/* Handler de round slider */
	.handle {
		@apply border-2 border-gray-700 bg-gray-200 shadow-none;
		border-color: #2a3742 !important;
		margin: 0 !important;
		transform: scale(1.2);
	}
	/* Punto final de recorrido */
	.handle.round-end {
		@apply hidden;
	}
}
.mode-mockup .shadow-round-slider.vue3-slider.circular .handle-container .handle {
	@apply bg-blue-500 slidesgo:bg-purple-400;
	border-color: white !important;
}
</style>
