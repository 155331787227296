import { computed, Ref, ref } from 'vue';

import { deleteUserFont, uploadUserFont } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useToast } from '@/common/composables/useToast';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { Font } from '@/Types/types';

export const useFontPicker = (text: Ref<Text>) => {
	const { recommendedFonts, sortedFonts, inUseFonts, loadFontsByName, loadedFonts, getFont } = useFonts();
	const { fontFamily, finalFontFamily, updateFontFamily } = useTextStyles(text);
	const { user } = useAuth();
	const toast = useToast();

	const fileInput = ref<HTMLInputElement>();
	const fontName = ref('');
	const showSpinner = ref(false);
	const showFontPicker = ref(false);
	const tab = ref('recent');
	const picker = ref();
	const selectedFontToRemove = ref();
	const openDeleteModal = ref(false);

	const finalFont = computed(() => {
		if (!fontFamily.value.length || fontFamily.value.length > 1) return undefined;

		return getFont(finalFontFamily.value);
	});

	const usedFonts = computed(() => inUseFonts.value);

	const fontList = computed(() => {
		const fName = fontName.value.trim().toLowerCase();
		let wepikFonts = sortedFonts.value
			.filter((f) => !fName.length || f.name.toLowerCase().includes(fName))
			.filter((f) => f.origin !== 'squirrel');

		if (tab.value === 'suggested') {
			wepikFonts = recommendedFonts.value.filter((f) => f.recommended && f.name.toLowerCase().includes(fName));
		} else if (tab.value === 'userfonts') {
			const result: Font[] = [];

			user.value?.fonts.forEach((font) => {
				if (!result.some((f) => f.name === font.name)) {
					result.push(font);
				}
			});

			wepikFonts = result.filter((f) => f.name.toLowerCase().includes(fName));
		}

		return wepikFonts.sort((a, b) => {
			if (a.name > b.name) {
				return 1;
			}

			if (a.name < b.name) {
				return -1;
			}

			return 0;
		});
	});

	const parseUploadedFontToFont = (font: {
		name: string;
		preview: string;
		slug: string;
		type: string;
		weight: string;
	}): Font => {
		const { name, preview, slug, type, weight } = font;

		return {
			name,
			origin: type,
			preview,
			slug,
			weights: [weight],
			recommended: 0,
		};
	};

	const uploadFont = async () => {
		if (showSpinner.value) return;

		const selectedFiles = fileInput.value?.files;

		if (!selectedFiles?.length) return;

		showSpinner.value = true;
		tab.value = 'userfonts';

		const formData = new FormData();

		for (const fontFile of Array.from(selectedFiles)) {
			formData.append('font', fontFile);

			try {
				const { data } = await uploadUserFont(formData);

				if (data.value?.slug && user.value?.fonts) {
					user.value.fonts.push(parseUploadedFontToFont(data.value));
				}

				showSpinner.value = false;
				fileInput.value!.value = '';
			} catch (e) {
				toast.error('The font has not been uploaded');
				showSpinner.value = false;
			}
		}
	};

	const removeUserFont = async (font: Font) => {
		showSpinner.value = true;

		try {
			await deleteUserFont(font.slug);

			user.value!.fonts = user.value!.fonts.filter((f) => f.slug !== font.slug);

			if (loadedFonts.value.length && inUseFonts.value.length) {
				updateFontFamily(inUseFonts.value[0]);
			} else {
				await loadFontsByName(['Montserrat']);
				const montserrat = sortedFonts.value.find((f) => f.name === 'Montserrat');

				if (montserrat) updateFontFamily(montserrat);
			}

			toast.success('Font removed successfully');
		} catch (e) {
			toast.error('The font has not been removed');
		}

		showSpinner.value = false;
	};

	const onConfirmDelete = () => {
		openDeleteModal.value = false;

		if (selectedFontToRemove.value) removeUserFont(selectedFontToRemove.value);
	};

	const onCloseModal = () => {
		openDeleteModal.value = false;
		selectedFontToRemove.value = undefined;
	};

	const onSelectToDelete = (font: Font) => {
		selectedFontToRemove.value = font;

		openDeleteModal.value = true;
	};

	return {
		fileInput,
		finalFont,
		fontList,
		fontName,
		picker,
		showSpinner,
		showFontPicker,
		tab,
		usedFonts,
		openDeleteModal,
		selectedFontToRemove,
		onSelectToDelete,
		uploadFont,
		removeUserFont,
		onConfirmDelete,
		onCloseModal,
	};
};
