<script lang="ts" setup>
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { nextTick, onActivated, onMounted, ref, toRef, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
const { isAndroid } = useDeviceInfo();

const props = withDefaults(
	defineProps<{
		horizontal?: boolean;
	}>(),
	{
		horizontal: false,
	}
);
const emit = defineEmits<{
	(e: 'element', el: HTMLElement): void;
}>();

const horizontal = toRef(props, 'horizontal');

if (!isAndroid) {
	useEventListener(
		window,
		'resize',
		useDebounceFn(() => getElementSize(), 500),
		{ passive: true }
	);
} else {
	useEventListener(
		window,
		'orientationchange',
		useDebounceFn(() => getElementSize(), 500),
		{ passive: true }
	);
}

const el = ref();
const ready = ref(false);

const getElementSize = async () => {
	if (!el.value) return;
	ready.value = false;

	await nextTick();
	if (!el.value) return;

	el.value.style.width = '';
	el.value.style.height = '';

	requestAnimationFrame(() => {
		if (!el.value) return;
		const { width, height } = el.value.getBoundingClientRect();

		if (horizontal.value) {
			el.value.style.width = `${width}px`;
		} else {
			el.value.style.height = `${height}px`;
		}

		ready.value = true;
	});
};

onActivated(() => {
	getElementSize();
});

onMounted(() => {
	getElementSize();
	emit('element', el.value);
});

watch(horizontal, () => getElementSize());
</script>

<template>
	<div
		ref="el"
		data-test="reserve-side-size"
		class="h-full scrollbar-thin mockup:!h-auto"
		:class="{
			'overflow-x-auto overflow-y-hidden': ready && horizontal,
			'overflow-y-auto overflow-x-hidden': ready && !horizontal,
		}"
	>
		<slot v-if="ready"></slot>
	</div>
</template>
<style lang="sass" scoped>
body .scrollbar-thin
	@apply overflow-auto
	scrollbar-gutter: stable

	&::-webkit-scrollbar
		width: 12px
		height: 12px

	&::-webkit-scrollbar-thumb
		border-radius: 50px
		width: 20px
		border: 3px solid
</style>
