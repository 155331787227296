<script lang="ts" setup>
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

defineProps<{
	open: boolean;
}>();

const emit = defineEmits<{
	(e: 'close'): void;
	(e: 'confirm'): void;
}>();
</script>

<template>
	<Modal :open="open" @close="emit('close')">
		<div class="relative flex w-full max-w-lg overflow-hidden rounded">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded-sm bg-white p-1 hover:bg-gray-100/25"
				@click="emit('close')"
			>
				<SvgIcon name="cross" class="h-5 w-5 fill-current text-gray-700 lg:h-6 lg:w-6" />
			</button>
			<div class="flex flex-col bg-white p-8 text-center">
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">
					{{ trans('Are you sure?') }}
				</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					{{ trans('You are going to disconnect your Google account') }}
				</p>
				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-10 items-center justify-center rounded border-2 border-gray-200 px-6 font-semibold text-gray-700 hover:border-gray-300 lg:h-12 lg:px-8 lg:text-lg"
						@click="emit('close')"
					>
						{{ trans('Cancel') }}
					</button>
					<button
						class="mx-2 flex h-10 items-center justify-center rounded border-2 border-red-500 bg-red-500 px-6 font-semibold text-white hover:border-red-400 hover:bg-red-400 lg:h-12 lg:px-8 lg:text-lg"
						@click="emit('confirm')"
					>
						{{ trans('Logout') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>

<style scoped></style>
