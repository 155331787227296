<script setup lang="ts">
import { Ref, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');

// Using composables
const { textTransform, updateTextTransform } = useTextStyles(element as Ref<Text>);
const { trans } = useI18n();

const transformClickManager = () => {
	switch (textTransform.value) {
		case '': {
			updateTextTransform('uppercase');
			break;
		}
		case 'uppercase': {
			updateTextTransform('');
			break;
		}
	}
	GAnalytics.track('click', 'Button', `text-transform-${textTransform.value}`, null);
};
</script>

<template>
	<div data-testid="text-transform" class="flex flex-col">
		<button
			class="flex h-9 w-full items-center justify-center rounded"
			:class="{
				'bg-gray-600 text-white mockup:bg-fp-gray-150 mockup:text-blue-500': textTransform === 'uppercase',
				'bg-gray-700 px-3 text-gray-100 hover:bg-gray-600 hover:text-white mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500':
					textTransform === '',
			}"
			data-title="alignText"
			@click="transformClickManager()"
		>
			<SvgIcon name="uppercase" class="h-5 w-5" />
		</button>
		<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700"> {{ trans('Uppercase') }} </label>
	</div>
</template>
