import { createSharedComposable } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';

export const usePhotoMode = createSharedComposable(() => {
	const project = useProjectStore();
	const { isPhotoMode } = useEditorMode();
	const temporalRef = ref(ForegroundImage.create());
	const { imageBackground } = useForeground(temporalRef as Ref<ForegroundImage>);

	const photoModeImage = computed(() => {
		const page = project.pages[0] as Page;
		if (!isPhotoMode.value || !project.pages.length || !page.backgroundImageId) return undefined;
		return page.elements.get(page.backgroundImageId) as Image;
	});

	const checkIsPhotoModeImage = (image: BaseImage): boolean => {
		if (!photoModeImage.value) return false;

		if (image instanceof ForegroundImage) {
			temporalRef.value = image;
			return imageBackground.value?.id === photoModeImage.value?.id;
		}

		return image.id === photoModeImage.value.id;
	};

	return {
		photoModeImage,
		checkIsPhotoModeImage,
	};
});
