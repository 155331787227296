<script lang="ts" setup>
import { toRef } from 'vue';

import { useTextColors } from '@/color/composables/useTextColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';
// Props
const props = defineProps<{ element: Text }>();
const element = toRef(props, 'element');
const { trans } = useI18n();
const store = useMainStore();
const scale = toRef(store, 'scale');
const { hasGradient } = useTextColors(element);
// Data

const { isCircleText } = useCircleTypeInfo(element, scale);
const { togglePanel } = usePanelManagement();

const onClickCurvedTextBtn = () => togglePanel(EditPanels.TextCurve, element.value);
</script>

<template>
	<button
		:disabled="hasGradient"
		data-testid="curved-text"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-8 lg:w-8 lg:justify-center"
		:class="{
			'text-white': isCircleText,
			'text-gray-100': !isCircleText,
			'text-gray-100 opacity-50': hasGradient,
		}"
		tooltip="Text shadow"
		tooltip-position="top"
		@click="onClickCurvedTextBtn"
	>
		<span
			v-if="isCircleText"
			class="absolute top-0 right-2 h-2 w-2 rounded-full border border-blue-400 bg-blue-500 slidesgo:bg-purple-400"
		></span>
		<SvgIcon name="curved" class="h-5 w-5 lg:h-4 lg:w-4" />
		<p class="text-2xs lg:hidden">{{ trans('Curved') }}</p>
	</button>
</template>
