import { cloneDeep } from 'lodash-es';
import { computed, Ref, ref } from 'vue';

import { useBoxColors } from '@/color/composables/useBoxColors';
import { useShapeColors } from '@/color/composables/useShapeColors';
import { useTextColors } from '@/color/composables/useTextColors';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import Line from '@/elements/line/classes/Line';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import Page from '@/page/classes/Page';
import { Color } from '@/Types/colorsTypes';

export const useGroupColors = (group: Ref<Element[]>) => {
	// Data
	const temporalShape = ref<Shape>(Shape.create());
	const temporalBox = ref<Box>(Box.create());
	const temporalText = ref(Text.create());
	const elementsWithColor = ref<(Element | Page)[]>([]);
	const colorSelected = ref<Color>();

	// Using composables
	const { colors: shapeColors, updateColor: updateShapeColor } = useShapeColors(temporalShape);
	const { colors: boxColors, updateColor: updateBoxColor } = useBoxColors(temporalBox);
	const { colors: textColors, updateColor: updateTextColor } = useTextColors(temporalText);

	// Computeds
	const shapes = computed<Shape[]>(() => group.value.filter((el) => el instanceof Shape) as Shape[]);
	const boxes = computed<Box[]>(() => group.value.filter((el) => el instanceof Box) as Box[]);
	const texts = computed<Text[]>(() => group.value.filter((el) => el instanceof Text) as Text[]);
	const lines = computed<Line[]>(() => group.value.filter((el) => el instanceof Line) as Line[]);
	const stories = computed<Storyset[]>(() => group.value.filter((el) => el instanceof Storyset) as Storyset[]);

	const shapesColors = computed(() => {
		return shapes.value
			.map((shape) => {
				temporalShape.value = shape;
				return [shapeColors.value];
			})
			.flat(2);
	});

	const boxesColors = computed(() => {
		return boxes.value
			.map((box) => {
				temporalBox.value = box;
				return [boxColors.value];
			})
			.flat(2);
	});

	const allTextsColors = computed(() => {
		return texts.value.map((text) => {
			temporalText.value = text;

			return {
				isCurved: !!text.curvedProperties.arc,
				colors: [textColors.value],
			};
		});
	});

	const textsColors = computed(() => {
		return allTextsColors.value
			.map((text) => {
				return [text.colors];
			})
			.flat(2);
	});

	const curvedTextsColors = computed(() => {
		return allTextsColors.value
			.filter((e) => e.isCurved)
			.map((curvedText) => {
				return [curvedText.colors];
			})
			.flat(3);
	});

	const linesStrokeColors = computed(() => {
		return lines.value.map((line) => line.mainColor);
	});

	const groupColors = computed(() => {
		const storysetsColors = stories.value.map((storyset) => storyset.mainColor);
		const lineColors = lines.value.map((line) => {
			const colors = [line.mainColor];

			if (line.markerStart) {
				colors.push(line.markerStart.color);
			}

			if (line.markerEnd) {
				colors.push(line.markerEnd.color);
			}

			return colors;
		});

		const colors = [
			...shapesColors.value,
			...boxesColors.value,
			...textsColors.value,
			...storysetsColors,
			...lineColors,
		].flat(1);

		return colors
			.filter(
				(color, index, colorList) => index === colorList.findIndex((c) => c.toCssString() === color.toCssString())
			)
			.flat(1);
	});

	// Methods
	const getElementsWithColorSelected = () => {
		if (!colorSelected.value) {
			elementsWithColor.value = [];
			return;
		}

		const shapeElements = shapes.value.filter((shape) => {
			temporalShape.value = shape;
			return shapeColors.value.map((c) => c.toCssString()).includes((colorSelected.value as Color).toCssString());
		});
		const boxElements = boxes.value.filter((box) => {
			temporalBox.value = box;
			return boxColors.value.map((c) => c.toCssString()).includes((colorSelected.value as Color).toCssString());
		});
		const storysets = stories.value.filter(
			(storyset) => storyset.mainColor.toCssString() === (colorSelected.value as Color).toCssString()
		);
		const textElements = texts.value.filter((text) => {
			temporalText.value = text;
			return textColors.value.map((c) => c.toCssString()).includes((colorSelected.value as Color).toCssString());
		});
		const linesElements = lines.value.filter(
			(line) =>
				line.mainColor.toCssString() === (colorSelected.value as Color).toCssString() ||
				(line.markerStart && line.markerStart.color.toCssString() === (colorSelected.value as Color).toCssString()) ||
				(line.markerEnd && line.markerEnd.color.toCssString() === (colorSelected.value as Color).toCssString())
		);

		elementsWithColor.value = [
			...shapeElements,
			...boxElements,
			...storysets,
			...textElements,
			...linesElements,
		] as Element[];
	};

	const updateGroupColor = (oldColor: Color, newColor: Color) => {
		const clone = cloneDeep(oldColor);
		const shapes = elementsWithColor.value.filter((e) => e instanceof Shape);
		const boxes = elementsWithColor.value.filter((e) => e instanceof Box);
		const storysets = elementsWithColor.value.filter((e) => e instanceof Storyset);
		const lines = elementsWithColor.value.filter((e) => e instanceof Line);
		const texts = elementsWithColor.value.filter((e) => e instanceof Text);

		shapes.forEach((shape: any) => {
			temporalShape.value = shape;
			const colorsToChange = shapeColors.value.filter((c) => c.toCssString() === clone.toCssString());
			colorsToChange.forEach((c) => updateShapeColor(c, newColor));
		});

		boxes.forEach((box: any) => {
			temporalBox.value = box;
			const colorsToChange = boxColors.value.filter((c) => c.toCssString() === clone.toCssString());
			colorsToChange.forEach((c) => updateBoxColor(c, newColor));
		});

		texts.forEach((text: any) => {
			temporalText.value = text;
			const colorsToChange = textColors.value.filter((c) => c.toCssString() === clone.toCssString());
			colorsToChange.forEach((c) => updateTextColor(c, newColor));
		});

		storysets.forEach((storyset: any) => storyset.updateColor(newColor));

		lines.forEach((line: any) => {
			if (line.mainColor.toCssString() === clone.toCssString()) {
				line.updateStrokeColor(newColor);
			}

			if (line.markerStart && line.markerStart.color.toCssString() === clone.toCssString()) {
				line.updateMarkerStartColor(newColor);
			}

			if (line.markerEnd && line.markerEnd.color.toCssString() === clone.toCssString()) {
				line.updateMarkerEndColor(newColor);
			}
		});
	};

	return {
		colors: groupColors,
		updateGroupColor,
		getElementsWithColorSelected,
		textsColors,
		curvedTextsColors,
		linesStrokeColors,
		colorSelected,
	};
};
