import { computed, ComputedRef, Ref } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import { BorderBoxStyle, TypeBorder } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useBoxStroke = (element: Ref<Box>) => {
	// Computeds
	const maxValue = computed(() => Math.min(element.value.size.width, element.value.size.height) / 2);
	const percentCalculated = computed(() => {
		const MAX_PERCENT = 100;
		const newPercent = Math.ceil((element.value.border.size * 100) / maxValue.value);
		return Math.min(newPercent, MAX_PERCENT);
	});

	// Methods
	const setSizeByPercent = (newPercent: number) => {
		element.value.border.size = MathTools.ruleOfThree(100, maxValue.value, newPercent);
	};
	const typeBorderStyle = computed(() => {
		if (element.value.border.type === TypeBorder.Dashed) {
			return {
				strokeLinecap: 'butt',
				strokeDashArray: `${element.value.border.size * 2} ${element.value.border.size / 1.2}`,
			};
		}
		if (element.value.border.type === TypeBorder.Dotted) {
			return { strokeLinecap: 'round', strokeDashArray: `0 ${element.value.border.size * 1.8}` };
		}
		return { strokeLinecap: 'butt', strokeDashArray: '0' };
	}) as ComputedRef<BorderBoxStyle>;

	const updateTypeBorder = (newVal: TypeBorder) => {
		const border = element.value.border;
		border.type = newVal;
		border.size = border.size ? border.size : 10;
		if (newVal == TypeBorder.None) {
			border.size = 0;
		}
	};

	return {
		updateTypeBorder,
		setSizeByPercent,
		percentCalculated,
		maxValue,
		typeBorderStyle,
	};
};
