// import { sortBy } from 'lodash-es';
import { computed, onMounted, onUnmounted, Ref, ref, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import Element from '@/elements/element/classes/Element';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Page from '@/page/classes/Page';
function useStreamRendering(page: Ref<Page>, shouldRenderContents: Ref<boolean>, minBatchSize: number) {
	const readyForRenderItems = ref<Element[]>([]);

	const { isRenderingContext } = useEditorMode();

	if (isRenderingContext) {
		return {
			elements: page.value.elementsAsArray(),
			renderCompleted: ref(false),
		};
	}

	let requestId: number | null = null;
	const performance = window.performance || { now: () => new Date().getTime() };
	let lastBatchDate = performance.now();

	const processBatch = () => {
		if (!shouldRenderContents.value) {
			return;
		}
		const renderedItemsIds = readyForRenderItems.value.map((item) => item.id);
		const pendingRendering = page.value.elementsAsArray().filter((item) => !renderedItemsIds.includes(item.id));

		// Ordenar los elementos por su posición en el eje Y para renderizarlos de arriba a abajo
		// descactivado temporalmente para mejorar el rendimiento
		// pendingRendering = sortBy(pendingRendering, (item) => item.position.y);

		const ellapsed = performance.now() - lastBatchDate;

		let batchSize = 6;

		if (ellapsed > 16) {
			batchSize = 4;
		}

		const nextBatch = pendingRendering.slice(0, Math.max(minBatchSize, batchSize));

		// Hay que mantener las posiciones de los elementos en el array original ya que sino pueden verse con un z-index distinto
		readyForRenderItems.value = [...readyForRenderItems.value, ...nextBatch];

		if (nextBatch.some((el) => el instanceof ForegroundImage)) {
			readyForRenderItems.value.sort(
				(a, b) =>
					page.value.elementsAsArray().findIndex((item) => item.id === a.id) -
					page.value.elementsAsArray().findIndex((item) => item.id === b.id)
			);
		}

		if (readyForRenderItems.value.length !== page.value.elements.size) {
			// Esto es un hack para detectar de que se ha completado el renderizado de la página
			const messageChannel = new MessageChannel();
			messageChannel.port1.onmessage = () => {
				lastBatchDate = performance.now();
				requestId = requestAnimationFrame(processBatch);
			};
			messageChannel.port2.postMessage(undefined);
		}
	};

	const scheduleRender = () => {
		if (requestId) {
			cancelAnimationFrame(requestId);
		}
		requestId = requestAnimationFrame(processBatch);
	};

	onMounted(() => {
		// Iniciar el proceso de renderizado incremental
		requestId = requestAnimationFrame(processBatch);
	});

	onUnmounted(() => {
		if (requestId) {
			// Cancelar el callback si el componente se desmonta antes de finalizar el proceso
			cancelAnimationFrame(requestId);
		}
	});

	// Reaccionar a los cambios en la lista de elementos
	watch(
		[page, shouldRenderContents, () => page.value.elements.keys()],
		(a, b, c) => {
			const existingItemsIds = readyForRenderItems.value.map((item) => item.id);

			// Solo tenemos en la lista de elementos a renderizar aquellos que no han sido eliminados
			readyForRenderItems.value = page.value.elementsAsArray().filter((item) => existingItemsIds.includes(item.id));
			processBatch();
		},
		{ immediate: true }
	);

	const renderCompleted = computed(() => {
		return readyForRenderItems.value.length === page.value.elements.size;
	});

	return {
		elements: readyForRenderItems,
		renderCompleted,
	};
}

export default useStreamRendering;
