<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import { useElementRenderStyles } from '@/elements/element/composables/useElementRenderStyles';
import { useGroup } from '@/elements/group/composables/useGroup';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useI18n } from '@/i18n/useI18n';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useToolbarTarget } from '@/layout/composables/useToolbarTarget';
import CanvasTeleport from '@/page/components/CanvasTeleport.vue';

const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');
const { isMobile } = useDeviceInfo();
const { isCropping } = useInteractions();
const { trans } = useI18n();
const store = useMainStore();
const { target } = useToolbarTarget(element);

const size = computed(() => props.element.size);
const position = computed(() => props.element.position);

const popper = ref();
const toolbar = ref();
const { isGrouped } = useGroup(element);
// Watchers
watch([position, size, () => store.activePanel, () => store.editPanel], async () => {
	await nextTick();

	if (!popper.value) {
		initPopper();
		return;
	}

	requestAnimationFrame(() => popper.value.update());
});
const initPopper = () => {
	if (hasForeground.value || isMobile.value || isCropping.value) {
		return;
	}
	if (!target.value) return;
	const finalPlacement = isGrouped.value ? 'left-start' : 'right-start';
	popper.value = createPopper(target.value, toolbar.value, {
		placement: finalPlacement,
		modifiers: [
			{ name: 'offset', options: { offset: [10, 10] } },
			{
				name: 'preventOverflow',
				options: {
					tether: false,
				},
			},

			{
				name: 'flip',
				options: {
					// cambia la posición a end en caso de que los botones se solapen con el topbar
					fallbackPlacements: [finalPlacement.replace('start', 'end')],
					boundary: [document.querySelector('#scroll-area')],
				},
			},
		],
	});
};
onMounted(() => {
	requestAnimationFrame(() => initPopper());
});

const { styles } = useElementRenderStyles(props.element);

const { separateLayers, hasForeground, removingBackground } = useLayersImage(element);

const toast = useToast();

const performLayerSeparation = async () => {
	try {
		await separateLayers();
	} catch (error) {
		if (error instanceof Error) {
			toast.error(error.message);
		}
	}
};
</script>

<template>
	<button
		v-if="isMobile"
		:tooltip="trans('Depth effect')"
		tooltip-position="top"
		data-button-importance="important"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
		@click="performLayerSeparation"
	>
		<SvgIcon v-if="!removingBackground" name="layers" class="h-5 w-5 shrink-0 scale-90 lg:h-4 lg:w-4" />
		<SvgIcon v-else name="spinner" class="animate-reverse h-5 w-5 shrink-0 animate-spin lg:h-4 lg:w-4" />
		<span class="text-2xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{ trans('Layers') }}</span>
	</button>
	<teleport v-else to="#separateLayersToolbarTarget">
		<div ref="toolbar" class="toolbar z-20 -ml-8 -mt-8">
			<div class="toolbar">
				<button
					data-testid="separate-unify-layers"
					:tooltip="trans('Depth effect')"
					tooltip-position="right"
					class="flex h-7 w-7 items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-600 slidesgo:bg-purple-400"
					:disabled="removingBackground"
					:class="{ 'pointer-events-none cursor-not-allowed opacity-25': removingBackground }"
					@click="performLayerSeparation"
				>
					<SvgIcon name="layers" class="h-4 w-4" />
				</button>
			</div>
		</div>
		<CanvasTeleport v-if="removingBackground" :element="element">
			<div :style="styles" class="pointer-events-none flex items-center justify-center bg-gray-900/60">
				<SvgIcon name="spinner" class="h-1/12 min-h-[64px] w-1/12 min-w-[64px] animate-spin text-white" />
			</div>
		</CanvasTeleport>
	</teleport>
</template>

<style scoped></style>
