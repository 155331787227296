<script setup lang="ts">
import { computed, toRef, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import BorderBox from '@/elements/box/components/BorderBox.vue';
import { useRounding } from '@/elements/box/composables/useRounding';
import { BorderRadiusType, TypeBorder } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';
// Props
const props = defineProps<{
	element: Box;
	forSmallPreview?: boolean;
	previewName?: string;
	smartSelection?: boolean;
	scale: number;
	interactive: boolean;
}>();

const element = toRef(props, 'element');
const store = useMainStore();
// Use composables
const { maxValue } = useRounding(element);
// Computeds

const borderRadius = computed(() => {
	//  TODO: revisar en  implementación de tablas
	const [topLeftBorderRadius, topRightBorderRadius, bottomRightBorderRadius, bottomLeftBorderRadius] =
		element.value.border.radius;
	return `${topLeftBorderRadius}px ${topRightBorderRadius}px ${bottomRightBorderRadius}px ${bottomLeftBorderRadius}px`;
});

const opacity = computed(() => {
	return element.value.opacity;
});

// Methods
//Controlamos que si tenía un size 0, al cambiar el valor le añadimos un borde Solido,
//si por el contrario, ya tenía un size distinto de 0, dejamos el borde que tenía.
const handleBorderSizeChange = () => {
	const border = element.value.border;
	if (border.size === 0) {
		border.type = TypeBorder.None;
	} else if (border.type === TypeBorder.None) {
		border.type = TypeBorder.Solid;
	}
};

// Watches
watch(() => element.value.border.size, handleBorderSizeChange);

watch(
	() => element.value.size,
	(_, oldSize) => {
		if (props.previewName) return;
		//  si usamos el historial, evitamos recalcular el border radius
		if (window.moving || !store.finishedLoading) return;
		const oldMaxValue = Math.min(oldSize.width, oldSize.height) / 2;
		// TODO: revisar en  implementación de tablas
		let oldPercents: number[] = [];

		element.value.border.radius.forEach((radius) => {
			oldPercents.push((radius * 100) / oldMaxValue);
		});
		element.value.border.radius = element.value.border.radius.map((_, index) =>
			MathTools.ruleOfThree(100, maxValue.value, oldPercents[index])
		) as BorderRadiusType;
	}
);
</script>

<template>
	<div
		data-testid="box-element"
		class="box-element-final sync-box h-full w-full"
		:style="{
			background: element.background.toCssString(),
			borderRadius,
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
			opacity,
		}"
	></div>
	<BorderBox v-if="element.border.size" :element="element" :preview-name="previewName" />
</template>

<style scoped></style>
