import { Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useGroup } from '@/elements/group/composables/useGroup';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import { useImagePresets } from '@/elements/medias/images/foreground/composables/useImagePresets';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { Video } from '@/elements/medias/video/classes/Video';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useFeedback } from '@/interactions/composables/useFeedback';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { MoveConfig } from '@/Types/types';

export const useSelectionKeyboardEvents = () => {
	const { selection, clearSelection, setSelection } = useSelection();

	const { removeElement } = useActivePage();
	const { deleteFeedback } = useFeedback();
	const { listen } = useOrderedKeyboardListener();
	const { moveable } = useInteractions();
	const temporalForeground = ref(ForegroundImage.create());
	const temporalImage = ref(Image.create());
	const temporalRef = ref(Shape.create());
	const { lockGroup, unlockGroup, isLockGroup } = useGroup(temporalRef);

	const { removeBg } = useImagePresets(temporalImage as Ref<Image>);
	const { imageBackground } = useForeground(temporalForeground as Ref<ForegroundImage>);
	const { hasForeground } = useLayersImage(temporalImage as Ref<Image>);
	const { textEditing } = useTextEditing();

	const isTextInputEvent = (e: Event | undefined) => {
		if (!e) return false;
		// @ts-ignore
		return e.target?.tagName === 'INPUT' || e.target?.tagName === 'TEXTAREA' || e.target?.isContentEditable;
	};

	// Move every element in selection using keyboard arrows
	const deleteByKeyboard = (e: Event) => {
		if (isTextInputEvent(e) || !selection.value.length) return;

		// Si la selección es una imagen con las capas separadas (sin el fondo ya borrado)
		// vamos a aplicar el preset remove bg en vez de eliminar el elemento
		if (selection.value[0] instanceof BaseImage && selection.value[0].opacity !== 0) {
			const element = selection.value[0];
			if (element instanceof Image) {
				temporalImage.value = element;

				if (hasForeground.value) {
					removeBg();
					return;
				}

				// no se pueden eiminar elementos bloqueados
				if (element.locked) return;
			}
			if (element instanceof ForegroundImage) {
				temporalForeground.value = element;
				temporalImage.value = imageBackground.value as Image;

				removeBg();
				return;
			}
		}

		GAnalytics.track(
			'click',
			'Template',
			`remove-${selection.value[0].type === 'text' ? selection.value[0].type : 'custom'}`,
			null
		);
		// Si todos los elementos comparten el mismo grupo, eliminamos los elementos de uno en uno
		// Si todos los elementos no pertenecen a ningún grupo o a grupos diferentes, eliminamos todos los elementos de la selección de golpe
		const isOneGroup =
			selection.value.every((el) => el.group === selection.value[0].group) &&
			selection.value.every((el) => el.group !== null && el.locked === false);
		if (isOneGroup) removeElement(selection.value[0]);
		else selection.value.forEach(removeElement);

		deleteFeedback();
	};

	const lockByKeyboard = (e: Event) => {
		if (isTextInputEvent(e) || !selection.value.length) return;
		const isLocked = selection.value[0].locked;
		selection.value.forEach((el) => {
			el.locked = !isLocked;
		});
	};

	const playVideo = () => {
		const videos = selection.value.filter((el) => el instanceof Video);

		const elements = videos.map((v) => v.domNode()?.querySelector('.play-btn')).filter(Boolean);

		elements.forEach((btn) => btn.dispatchEvent(new CustomEvent('play-video')));
	};

	const init = () => {
		listen('Escape', (e) => {
			if (isTextInputEvent(e)) return;
			clearSelection();
		});

		listen(['Delete', 'Backspace'], deleteByKeyboard);

		listen('ArrowUp', moveByArrow);
		listen('ArrowLeft', moveByArrow);
		listen('ArrowDown', moveByArrow);
		listen('ArrowRight', moveByArrow);
		listen(['Space', ' '], playVideo);
		listen(['g', 'G'], groupElements);
		listen(['b', 'B'], (e) => {
			// @ts-ignore
			if (isTextInputEvent(e)) {
				return;
			}
			if (e.metaKey || e.ctrlKey) {
				e.preventDefault();
				lockByKeyboard(e);
			}
		});
	};

	const groupElements = (e: KeyboardEvent) => {
		if (isTextInputEvent(e) || selection.value.length < 2) return;
		temporalRef.value = selection.value[0];

		if (e.metaKey || e.ctrlKey) {
			e.preventDefault();

			if (isLockGroup.value) {
				const currentSelection = selection.value;
				unlockGroup(currentSelection);
				setSelection(currentSelection);
				return;
			}

			lockGroup(selection.value);
		}
	};

	const moveByArrow = (e: KeyboardEvent) => {
		if (isTextInputEvent(e)) return;
		if (textEditing.value) return;

		e.preventDefault();

		GAnalytics.track('keypress', 'Template', `move-element-with-keyboard`, null);

		const config: MoveConfig = {
			axis: ['ArrowLeft', 'ArrowRight'].includes(e.key) ? 'x' : 'y',
			dir: ['ArrowRight', 'ArrowDown'].includes(e.key) ? 1 : -1,
		};

		const amount = config.dir * (e.shiftKey ? 10 : 1);

		moveable.value?.request(
			'draggable',
			{
				deltaX: config.axis === 'x' ? amount : 0,
				deltaY: config.axis === 'y' ? amount : 0,
			},
			true
		);
	};

	return {
		deleteByKeyboard,
		lockByKeyboard,
		init,
		moveByArrow,
	};
};
