<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { Fn } from '@vueuse/core';
import { computed, Ref, toRef } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Dropdown from '@/common/components/Dropdown.vue';
import NumberInput from '@/common/components/NumberInput.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { Box } from '@/elements/box/classes/Box';
import { useRounding } from '@/elements/box/composables/useRounding';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useI18n } from '@/i18n/useI18n';
import { Size } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

// Constants
const INPUT_CONFIG = {
	MAX: 100,
	MIN: 0,
};

type validInputKey = keyof Size | 'rotation' | 'rounding';

const props = defineProps<{ element: Element; allowKeepProportion: boolean; allowHeight: boolean }>();

const element = toRef(props, 'element');

const { adjustTo, toggleKeepProportions, rotate } = useElementTransformOrchestrator(element).value;
const { percentCalculated, setRadiusByPercent } = useRounding(element as Ref<Box>);

Bugsnag.leaveBreadcrumb(`TransformMenu, element: ${props.element.id} - ${props.element.type}`);
const { breadScrumbWithDebounce } = useBugsnag(element);
const { trans } = useI18n();

const { textEditing } = useTextEditing();
const isEditingText = computed(() => {
	return !!textEditing.value;
});
const onUpdate = (key: validInputKey, val: number) => {
	if (key === 'rotation') {
		rotate(val, false);
		breadScrumbWithDebounce('rotation');
		return;
	}

	if (key === 'rounding' && element.value instanceof Box) {
		const newPercent = MathTools.clamp(val, INPUT_CONFIG.MIN, INPUT_CONFIG.MAX);
		//  TODO: revisar en  implementación de tablas
		setRadiusByPercent(newPercent);
		return;
	}

	if (val < 1) return;

	if (element.value.keepProportions) {
		adjustTo(key as keyof Size, val);
	} else {
		element.value.size[key as keyof Size] = val;
	}

	breadScrumbWithDebounce('size');
};

const rotationOptions = computed(() => [
	{ label: '0', value: 0 },
	{ label: '45', value: 45 },
	{ label: '90', value: 90 },
	{ label: '270', value: 270 },
]);

const checkIfDropdownShouldOpen = (e: Event, openDrowdown: Fn) => {
	if (e.target instanceof HTMLSpanElement && e.target.classList.contains('cursor-pointer')) {
		return;
	}
	openDrowdown();
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-800">{{ trans('Size') }}</h4>
	<div
		class="flex flex-col"
		:class="{
			'mb-6': props.allowKeepProportion,
			'mb-2': !props.allowKeepProportion,
		}"
	>
		<div class="mb-3 flex items-center gap-3">
			<div class="flex w-full flex-col">
				<NumberInput
					class="h-10 text-sm"
					:disabled="element.locked"
					data-testid="input-width"
					:min="1"
					:value="element.size.width"
					@track-typing="trackTypingEvent('element-width')"
					@track-step="trackStepEvent('element-width')"
					@update="(width: number) => onUpdate('width', width)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Width') }}</label>
			</div>
			<div v-if="allowHeight" class="flex w-full flex-col">
				<NumberInput
					class="h-10 text-sm"
					:disabled="element.locked"
					data-testid="input-height"
					:min="1"
					:value="element.size.height"
					@track-typing="trackTypingEvent('element-height')"
					@track-step="trackStepEvent('element-height')"
					@update="(height) => onUpdate('height', height)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Height') }}</label>
			</div>
			<div class="flex w-full flex-col">
				<Dropdown :options="rotationOptions" @update="(ev) => onUpdate('rotation', ev.value)">
					<template #default="{ onToggleVisibility }">
						<NumberInput
							data-testid="input-rotation"
							class="h-10 w-full text-sm"
							:disabled="element.locked"
							:class="{ 'input-notAllowed': isEditingText }"
							:value="element.rotation"
							@track-typing="trackTypingEvent('element-rotation')"
							@track-step="trackStepEvent('element-rotation')"
							@update="(rotation) => onUpdate('rotation', rotation)"
							@click="checkIfDropdownShouldOpen($event, onToggleVisibility)"
						/>
					</template>
				</Dropdown>
				<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Rotation') }}</label>
			</div>
			<div v-if="element instanceof Box" class="flex w-full flex-col">
				<NumberInput
					class="h-10 text-sm"
					:disabled="element.locked"
					data-testid="input-rounding"
					:min="INPUT_CONFIG.MIN"
					:max="INPUT_CONFIG.MAX"
					:value="percentCalculated"
					@track-typing="trackTypingEvent('element-rounding')"
					@track-step="trackStepEvent('element-rounding')"
					@update="(rounding) => onUpdate('rounding', rounding)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">{{ trans('Rounding') }}</label>
			</div>
		</div>

		<div v-if="allowKeepProportion && !element.group">
			<button
				class="relative flex h-4 w-4 cursor-pointer items-center justify-center rounded-sm border text-white"
				data-testid="button-keep-proportion"
				:class="{
					'border-gray-600 bg-transparent': !element.keepProportions,
					'border-blue-500 bg-blue-500 slidesgo:border-purple-400 slidesgo:bg-purple-400 ': element.keepProportions,
				}"
				@click="toggleKeepProportions"
			>
				<SvgIcon v-if="element.keepProportions" name="check" class="h-3 w-3 fill-current"></SvgIcon>
				<label
					data-testid="keep-proportion"
					class="absolute left-0 top-0 ml-5 flex h-4 items-center whitespace-nowrap text-xs text-gray-100 mockup:text-fp-gray-700"
					>{{ trans('Keep proportion') }}</label
				>
			</button>
		</div>
	</div>
</template>
