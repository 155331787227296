<script lang="ts" setup>
import { ref, watch } from 'vue';

import { getUserGooglePhotos } from '@/api/UserApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

const { addInsertableImage } = useAddInsertableElement();

const { authenticatedInGoogle } = useUserImageProvider();
const { runOnMobile } = useDeviceInfo();

const store = useMainStore();
const page = ref('');
const concatData = ref(false);
const { data: images, isFetching, execute } = getUserGooglePhotos(page, { refetch: true }, concatData);

const loadMore = () => {
	if (isFetching.value || !images.value?.links?.next) return;
	concatData.value = true;
	page.value = images.value.links.next;
};

const selectHandler = async (img: ImageApi) => {
	await addInsertableImage(img);
	runOnMobile(() => (store.activePanel = null));
};

watch(authenticatedInGoogle, (isGoogleAuth) => {
	if (isGoogleAuth) {
		concatData.value = false;
		execute();
	}
});
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader title="Google Photos" />

		<InfiniteLoading
			:data="(images && images.data) || []"
			:is-fetching="isFetching"
			class="flex flex-col"
			with-masonry
			:masonry-cols="2"
			@load="loadMore"
		>
			<template #item="{ item }">
				<InsertableElement
					:data="(item as ImageApi)"
					:type="Panels.photos"
					draggable
					class="mb-2"
					@click="selectHandler(item as ImageApi)"
				>
					<ListMedia :image="(item as ImageApi)" />
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>

<style scoped></style>
