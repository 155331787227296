+
<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

import { getStickers } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { DraggableItemData, ElementCategory, InsertableApiType } from '@/Types/types';

const props = defineProps<{ activeElementCategory: ElementCategory | null; query: string; keyword: string }>();

const emit = defineEmits<{
	(e: 'elementAdded'): void;
	(e: 'setElementCategory'): void;
	(e: 'setHasElements', hasElements: boolean): void;
	(e: 'zeroResults', value: boolean): void;
}>();

const query = computed(() => props.query || props.keyword);

const { addInsertableSticker } = useAddInsertableElement();

const ELEMENT_URL_CONFIG = {
	ICON_TYPE: 'sticker',
	LIMIT: 100,
	PAGE: 1,
};

const urlElements = ref('');

const activeElementCategory = computed(() => props.activeElementCategory);

const elementsSource = computed(
	() =>
		`flaticon/search?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}&q=${query.value}&iconType=${ELEMENT_URL_CONFIG.ICON_TYPE}&tagsId=${tagsId.value}`
);

const tagsId = computed(() => (props.query ? '' : activeElementCategory.value?.id || ''));

const { data: stickers, isFetching } = getStickers(urlElements, {
	get: 'elements',
	immediate: false,
	refetch: true,
});

watchDebounced(
	stickers,
	() => {
		if (stickers.value?.data?.length === 0) {
			emit('zeroResults', true);
			return;
		}

		emit('zeroResults', false);
	},
	{ debounce: 500 }
);

watchDebounced(
	elementsSource,
	async (newVal) => {
		if (!newVal) return;
		urlElements.value = newVal;
	},
	{ debounce: 100, immediate: true }
);

watch(isFetching, (newVal, oldVal) => {
	if (newVal || !oldVal) return;
	emit('setHasElements', !!stickers.value?.data.length);
});

// Methods
const onClickSticker = async (url: string) => {
	await addInsertableSticker(url);
	emit('elementAdded');
};

const onLoadMoreElements = async () => {
	if (!stickers.value?.links?.next) return;
	urlElements.value = stickers.value!.links.next;
};
</script>

<template>
	<InfiniteLoading
		:container-classes="'grid gap-4 m-2 grid-cols-2'"
		:data="stickers?.data || []"
		:is-fetching="isFetching"
		@load="onLoadMoreElements"
	>
		<template #item="{ item }">
			<InsertableElement
				v-if="'svg' in item"
				:data="item"
				:type="InsertableApiType.Sticker"
				:draggable="true"
				class="cursor-pointer rounded px-3 py-2 transition-opacity duration-300 hover:opacity-50"
				@click.stop="onClickSticker(item.svg)"
			>
				<img
					:src="item.preview"
					:data-svg="item.svg"
					:alt="item.name"
					draggable="true"
					class="h-24 w-full object-contain"
				/>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
