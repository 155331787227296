<script lang="ts" setup>
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { Video } from '@/elements/medias/video/classes/Video';

const store = useMainStore();

const props = defineProps<{ element: Video }>();
const element = toRef(props, 'element');

const onClickCrop = () => {
	GAnalytics.trackDebounceEditBarGA4({
		category: 'video',
		link_text: 'cut',
	});

	store.croppingTimeId = element.value.id;
};
</script>

<template>
	<button
		tooltip="Cut"
		tooltip-position="top"
		data-testid="crop-btn"
		data-button-importance="normal"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-8 lg:w-auto lg:flex-row lg:justify-center lg:px-2"
		@click="onClickCrop"
	>
		<SvgIcon name="scissor" class="h-4 w-4 lg:scale-90" />
		<span class="text-2xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>Cut</span>
	</button>
</template>
