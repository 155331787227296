<script setup lang="ts">
import { onClickOutside, useClipboard } from '@vueuse/core';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useArtboard } from '@/project/composables/useArtboard';

const { trans } = useI18n();
const store = useMainStore();

const props = defineProps<{ uuid?: string }>();
const emit = defineEmits<{ (e: 'close'): void; (e: 'goback'): void }>();

const { APP_BASE } = useEnvSettings();
const { artboardSizeInPx } = useArtboard();
const ratio = ref((artboardSizeInPx.value.height / artboardSizeInPx.value.width) * 100);

const iframe = computed(
	() => `<div style="position: relative; width: 100%; height: 0; padding-top:96px; padding-bottom: ${
		ratio.value
	}%; overflow: hidden; will-change: transform;">
		<iframe loading="lazy" src="${APP_BASE}share/${
		props.uuid || store.userVector?.uuid
	}?embed=1" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"></iframe>
	</div>
	`
);
const { copy, copied } = useClipboard();

const modal = ref();

onClickOutside(modal, () => emit('close'));
const shareOnCopyLink = (e: Event) => {
	e.preventDefault();

	copy(iframe.value);

	GAnalytics.track('click', 'button', 'copy-link', null);
};
</script>

<template>
	<div class="flex flex-col">
		<div class="mb-3 flex w-full items-center gap-1">
			<button @click="emit('goback')"><SvgIcon name="arrow" class="h-5 w-5 rotate-90" /></button>
			<p class="text-lg font-semibold text-gray-700">{{ trans('Embed') }}</p>
		</div>
		<p class="mb-4 text-sm text-gray-600">{{ trans('HTML embed code') }}</p>
		<div class="mb-4 flex gap-2">
			<div class="relative flex w-full min-w-0">
				<input
					type="text"
					class="input-embed relative flex h-10 w-full min-w-0 items-center truncate rounded-tl rounded-bl border border-gray-100/75 px-3 text-sm text-gray-500 hover:text-gray-600"
					:value="iframe"
				/>
				<button
					class="h-10 rounded-tr rounded-br bg-blue-500 px-6 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400 lg:px-6"
					@click="shareOnCopyLink"
				>
					{{ trans('Copy') }}
				</button>
				<div v-if="copied" class="copied-animation absolute right-2 z-20 px-1 text-xs text-blue-500 opacity-0">
					{{ trans('Copied!') }}
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.input-embed::selection
	@apply bg-blue-500 slidesgo:bg-purple-400 text-white
.copied-animation
	animation: copied 1s forwards

	@keyframes copied
		0%
			opacity: 0
			top: -20px

		90%
			opacity: 1
			top: -30px

		100%
			opacity: 0
			top: -30px
</style>
