<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { useI18n } from '@/i18n/useI18n';
import { useFeedback } from '@/interactions/composables/useFeedback';
import { useActivePage } from '@/page/composables/useActivePage';

// Props
const props = defineProps<{ element: Element }>();
const emit = defineEmits(['remove']);

const { deleteFeedback } = useFeedback();

const { removeElement } = useActivePage();
const { trans } = useI18n();

const remove = () => {
	deleteFeedback();
	removeElement(props.element);

	emit('remove');

	Bugsnag.leaveBreadcrumb(`remove ${props.element.type}-${props.element.id}`);
};
</script>

<template>
	<button
		:id="`remove-${element.type}-toolbar`"
		data-testid="remove"
		:tooltip="trans('Delete')"
		tooltip-position="top"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-8 lg:justify-center"
		@click="remove"
	>
		<SvgIcon name="trash" :toolbar="true" class="h-4 w-4" />
		<span class="text-2xs lg:hidden">{{ trans('Remove') }}</span>
	</button>
</template>
