<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import { createVectorFolder } from '@/api/UserApiClient';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();
const emit = defineEmits(['close', 'created']);

const toast = useToast();

const creating = ref(false);
const folderName = ref('');

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Rename creation modal is closed`);
};

const createFolder = async () => {
	if (folderName.value.trim().length < 1 || creating.value) return;

	creating.value = true;

	const { data, error } = await createVectorFolder({ name: folderName.value });

	creating.value = false;

	if (error.value) {
		toast.error(trans("You can't have more than 20 folders"));
		return;
	}

	toast.success(trans('Created folder'));

	emit('created', data.value);
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative my-auto mx-auto max-w-lg rounded bg-white py-8 px-8 lg:w-[470px]">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<div>
				<h1 class="mb-4 text-center text-2xl font-semibold text-gray-800">{{ trans('Create folder') }}</h1>
				<div class="mb-0">
					<input
						v-model="folderName"
						type="text"
						class="h-12 w-full rounded border border-gray-100 px-4 text-gray-800 placeholder:text-gray-600"
						:placeholder="trans('Folder name...')"
					/>
				</div>

				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
						:disabled="creating"
						@click.stop="onClickClose"
					>
						{{ trans('Cancel') }}
					</button>

					<button
						class="mx-2 flex h-12 cursor-pointer items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-8 text-lg font-semibold text-white hover:border-blue-600 hover:bg-blue-600 slidesgo:border-purple-400 slidesgo:bg-purple-400"
						:disabled="creating || folderName.trim().length < 1"
						@click.stop="createFolder"
					>
						<SvgIcon v-if="creating" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans('Create folder') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
