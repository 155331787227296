+
<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { useDebounceFn } from '@vueuse/core';
import { computed, onBeforeMount, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import AutocompleteInput from '@/common/components/AutocompleteInput.vue';
import { useMainStore } from '@/editor/stores/store';
import StickersCategoriesPanel from '@/elements/shapes/sticker/components/panels/add/StickersCategoriesPanel.vue';
import StickersElementsPanel from '@/elements/shapes/sticker/components/panels/add/StickersElementsPanel.vue';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { ElementCategory } from '@/Types/types';

/**
 * AT INIT:
 *
 * 1. Fetch Stickers Categories regarding a keyword comming from
 * 		flaticonSearch or category or active element category
 *
 * 2. If no Related Categories found, fetch Stickers Elements
 * 		regarding the keyword
 *
 * 3. If no Related Elements, show Popular Categories
 */

const props = defineProps<{ goBackToggle: boolean }>();

const emit = defineEmits<{
	(e: 'elementAdded'): void;
	(e: 'setElementCategory', cat: any): void;
	(e: 'setManualSearch', search: string): void;
}>();

const store = useMainStore();
const project = useProjectStore();

const keyword = ref('');
const hasCategories = ref(true);
const showCategories = ref(true);
const query = ref('');
const activeElementCategory = ref<ElementCategory | null>(null);

const autocompleteSource = computed(() => `autocomplete/flaticon?search=${query.value}&type=sticker`);
const goBackToggle = computed(() => props.goBackToggle);

const { trans } = useI18n();

watch(goBackToggle, () => {
	let hadQuery = false;
	if (query.value) {
		updateSearchValue('');
		hadQuery = true;
	}

	if (showCategories.value) {
		if (hasCategories.value && keyword.value) {
			// From Related Categories to Popular Categories
			keyword.value = '';
			return;
		}
		// From Popular Categories to ElementsTypesPanel
		store.activeElementType = null;
		return;
	}

	if (activeElementCategory.value && hasCategories.value) {
		// From Related Elements to Related Categories
		activeElementCategory.value = null;
		activeElementCategory.value = null;
		showCategories.value = true;
		return;
	}

	if (keyword.value) {
		// From Related Elements to Popular Categories
		if (activeElementCategory.value) activeElementCategory.value = null;
		keyword.value = '';
		showCategories.value = true;
		return;
	}

	if (hadQuery) {
		// From Found Elements to Popular Categories
		showCategories.value = true;
	}
});

watch(query, (newVal) => {
	// Manual queries fetch elements and have priority
	if (activeElementCategory.value) activeElementCategory.value = null;
	if (newVal && showCategories.value) showCategories.value = false;
});

watch([keyword, query], (newVals) => {
	// Display Popular Categories when has no keyword nor query
	if (newVals.every((val) => !val)) showCategories.value = true;
});

onBeforeMount(() => {
	// Keyword is set every time this component inits
	keyword.value = project.flaticonSearch || project.category || activeElementCategory.value?.name || '';
});

// Methods
const onElementAdded = () => {
	emit('elementAdded');
};

const onSetElementCategory = (cat: any) => {
	if (!cat) {
		activeElementCategory.value = null;
		return;
	}
	showCategories.value = false;

	activeElementCategory.value = {
		id: cat.tagsId,
		name: cat.name,
	};
};

const onSetHasCategories = (val: boolean) => {
	hasCategories.value = val;
	if (!val && showCategories.value) showCategories.value = false;
};

const onSetHasElements = (val: boolean) => {
	// Fallback to show Popular Categories when searching elements
	// at init returns no results
	if (!val && keyword.value) keyword.value = '';
};

const updateSearchValue = (searchValue: string) => {
	query.value = searchValue;

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}

	Bugsnag.leaveBreadcrumb(`Search in Sticker panel: ${searchValue}`);
};

const onZeroResults = (val: boolean) => {
	GAnalytics.trackGA4('search_editor', {
		category: 'sticker',
		search_term: query.value,
		zero_results: `${val}`,
	});
};
</script>

<template>
	<div class="flex h-full flex-col">
		<AutocompleteInput
			:placeholder="trans(keyword ? keyword : 'Search sticker...')"
			:query="query"
			:autocomplete-source="autocompleteSource"
			@change="updateSearchValue"
		/>

		<!-- Categories -->
		<StickersCategoriesPanel
			v-if="showCategories"
			:active-element-category="activeElementCategory"
			:query="query"
			:keyword="keyword"
			@elementAdded="onElementAdded"
			@setElementCategory="onSetElementCategory"
			@setHasCategories="onSetHasCategories"
		></StickersCategoriesPanel>

		<!-- Elements -->
		<StickersElementsPanel
			v-else
			:active-element-category="activeElementCategory"
			:query="query"
			:keyword="keyword"
			@elementAdded="onElementAdded"
			@setHasElements="onSetHasElements"
			@zero-results="onZeroResults"
		></StickersElementsPanel>
	</div>
</template>
