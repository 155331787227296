<script setup lang="ts">
import { MaybeElementRef, until, useIntersectionObserver } from '@vueuse/core';
import { computed, Ref, ref, toRef, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { RenderQueue } from '@/common/composables/useTaskQueue';
import Page from '@/page/classes/Page';
import Canvas from '@/page/components/Canvas.vue';
import { useCanvasPreview } from '@/page/composables/useCanvasPreview';
import { useCanvasSizeContainer } from '@/project/composables/useCanvasSize';

// Props
const props = defineProps<{
	page: Page;
	scrollArea?: HTMLDivElement | string | null;
	previewName: string;
}>();

const page = toRef(props, 'page');
const scrollArea = toRef(props, 'scrollArea');
const container = ref();

const { canvasSize, scale } = useCanvasSizeContainer(container);

const { debouncedPage, shouldMount } = useCanvasPreview(container, page, scale, scrollArea);
</script>

<template>
	<div
		:id="`canvas-${page.id}-preview-container`"
		ref="container"
		class="relative flex h-full w-full items-center justify-center"
		style="contain: strict"
	>
		<img
			v-if="!shouldMount && page && page.preview"
			:src="page.preview"
			alt=""
			class="pointer-events-none absolute inset-0 z-10 m-auto w-full"
			:style="{
				width: canvasSize.width + 'px',
				height: canvasSize.height + 'px',
			}"
		/>

		<div
			v-if="!shouldMount"
			class="pointer-events-none absolute inset-0 z-10 m-auto flex w-full items-center justify-center"
		>
			<SvgIcon name="spinner" class="w/1-4 h-1/4 animate-spin opacity-50" />
		</div>

		<div v-if="shouldMount" class="preview-canvas" inert>
			<Canvas
				v-if="canvasSize.width !== 0"
				data-preview-canvas
				preview
				:preview-name="previewName"
				:page="debouncedPage"
				:size="canvasSize"
			/>
		</div>
	</div>
</template>
