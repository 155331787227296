<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, toRef, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import TagToolbar from '@/elements/element/components/toolbars/TagToolbar.vue';
import Toolbar from '@/elements/element/components/toolbars/Toolbar.vue';
import GroupToolbar from '@/elements/group/components/toolbars/GroupToolbar.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useCropPhotoMode } from '@/elements/medias/crop/composables/useCropPhotoMode';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ImageLayersToolbar from '@/elements/medias/images/image/components/toolbars/ImageLayersToolbar.vue';
import AiWriterToolbar from '@/elements/texts/text/components/toolbars/AiWriterToolbar.vue';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useFeedback } from '@/interactions/composables/useFeedback';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useInteractiveElements } from '@/interactions/composables/useInteractiveElements';
import { useSelectionKeyboardEvents } from '@/interactions/composables/useSelectionKeyboardEvents';

const store = useMainStore();
// Props
const props = defineProps<{ elements: Element[] }>();

// Lifecycle hooks
onMounted(() => (scrollArea.value = document.querySelector('#scroll-area')));

onBeforeUnmount(() => {
	store.croppingId = null;
	store.croppingTimeId = null;
});

// Data
const elements = toRef(props, 'elements');
const scrollArea = ref();

// Computeds
const selectedElement = computed(() => elements.value[0]);

// Using composables
const usingSelectionKeyboardEvents = useSelectionKeyboardEvents();
const { isMobile } = useDeviceInfo();
const { isZooming } = useZoom();
const { interactiveElementReady } = useInteractiveElements(elements);
const { isDragging, isRotating, isResizing, disableToolbar, isCropping } = useInteractions();
const { isCropPhotoModeReady } = useCropPhotoMode();
const { selectFeedback } = useFeedback();
const { isGrouped, isLockGroup } = useGroup(selectedElement);

const { textAI } = useAiWriter();
const isGroupToolbar = computed(
	() => isGrouped.value && interactiveElementReady.value && !shouldHide.value && !textAI.value
);
const shouldShowAiToolbar = computed(
	() =>
		textAI.value &&
		interactiveElementReady.value &&
		!shouldHide.value &&
		textAI.value.id === selectedElement.value.id &&
		!isMobile.value
);

const shouldHide = computed(
	() => !isCropping.value && (isDragging.value || isRotating.value || isResizing.value || disableToolbar.value)
);
const isToolbarVisible = computed(
	() =>
		!!(
			(isCropPhotoModeReady.value ||
				((elements.value.length === 1 || isLockGroup.value) && interactiveElementReady.value)) &&
			!shouldHide.value
		)
);

// Register keyboard events
usingSelectionKeyboardEvents.init();

selectFeedback();

// Set pointer-events none to group when is dragging to allow replacing images or apply masks
const draggingItem = toRef(store, 'draggingItem');
watch(draggingItem, (newVal) => {
	const portalTarget: HTMLElement | null = document.querySelector('#portalTarget');
	if (portalTarget) {
		portalTarget.style.pointerEvents = newVal ? 'none' : 'auto';
	}
});

const { isTagMode } = useEditorMode();
</script>

<template>
	<div class="flex w-screen overflow-auto">
		<template v-if="!isTagMode">
			<AiWriterToolbar v-if="shouldShowAiToolbar" :element="selectedElement" />
			<GroupToolbar v-if="isGroupToolbar" :elements="elements" />
			<ImageLayersToolbar
				v-if="selectedElement instanceof BaseImage && !isMobile && !isCropping && !isZooming"
				:key="selectedElement.id"
				:visibility="isToolbarVisible"
				:element="selectedElement"
			/>
			<Toolbar v-if="!isMobile" :key="selectedElement.id" :visibility="isToolbarVisible" :element="selectedElement" />
		</template>
		<template v-else>
			<TagToolbar :element="selectedElement" />
		</template>
	</div>
</template>

<style lang="sass">
#app
	.moveable-control-box
		@apply z-10 will-change-auto

		.moveable-area
			border: 2px dashed rgb(187 200 211) !important

		.moveable-line
			@apply bg-blue-500 w-[2px] h-[2px]

			&.moveable-guideline
				@apply bg-pink-400

				&.moveable-vertical
					@apply w-px

				&.moveable-horizontal
					@apply h-px

				&.moveable-dashed
					@apply bg-transparent border-pink-400

			&.moveable-direction
					@apply pointer-events-none

		.moveable-control
			@apply bg-transparent border-0 w-8 h-8 -mt-4 -ml-4

			&::before
				content: ''
				@apply absolute inset-0 border-2 border-blue-500 rounded-full m-[10px] bg-white

			&:hover::before
				@apply bg-blue-500

			&.moveable-n,
			&.moveable-s
				@apply bg-transparent border-0 w-10 h-7 -mt-3.5 -ml-5 rounded-full

				&::before
					@apply absolute inset-0 border-2 border-blue-500 rounded-full m-[10px] bg-white

				&:hover::before
					@apply bg-blue-500

			&.moveable-e,
			&.moveable-w
				@apply bg-transparent border-0 w-7 h-10 -mt-5 -ml-3.5 rounded-full

				&::before
					@apply absolute inset-0 border-2 border-blue-500 rounded-full m-[10px] bg-white

				&:hover::before
					@apply bg-blue-500

		.moveable-rotation
			.moveable-rotation-line
				@apply hidden

			.moveable-rotation-control
				@apply bg-blue-500 border-0 w-4 h-4 -mt-2 -ml-2 bg-no-repeat bg-center
				background-image: url('../../assets/svgs/rotation-white.svg')
				background-size: 8px
				transform: translate(0.5px) scale(1.3) rotate(180deg) !important
				&::before
					@apply hidden

				&:hover
					@apply bg-blue-400
					cursor: url('@/assets/svgs/rounded.svg') 12 12, auto

		&.moveable-origin
			@apply hidden

.slidesgo-mode #app .moveable-control-box
	.moveable-line.moveable-direction
		@apply bg-purple-400

	.moveable-control
		&::before
			@apply border-purple-400

		&:hover::before
				@apply bg-purple-400

	.moveable-rotation .moveable-rotation-control
		@apply bg-purple-400

		&:hover
			@apply bg-purple-300
</style>
