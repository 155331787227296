<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { v4 as uuidv4 } from 'uuid';
import { computed, ref, toRef, watch } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import { FilterPresetEmpty, presetTypes } from '@/elements/medias/filter/classes/FilterPresets';
import { GradientOverlay, SolidOverlay } from '@/elements/medias/filter/classes/Overlay';
import FilterOverlay from '@/elements/medias/filter/components/FilterOverlay.vue';
import PatternFilterMenu from '@/elements/medias/filter/components/menus/PatternFilterMenu.vue';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import { useMovePageOnEditPanelOverFocused } from '@/page/composables/useMovePageOnEditPanelOverFocused';
import { Color } from '@/Types/colorsTypes';
import { BackgroundModes } from '@/Types/types';

interface FilterField {
	name: string;
	key: FilterParam;
	unit: string;
	min: number;
	max: number;
	default: number;
}

const { trans } = useI18n();
const props = defineProps<{ element: BaseImage | Video }>();
const element = toRef(props, 'element');

const { isPhotoMode } = useEditorMode();
const { recalculatePanelSize } = useMovePageOnEditPanelOverFocused();
// Constante por defecto para el ancho y el alto de la imágen
const DEFAULT_IMAGE_BLUR_SIZE = 1000;
// Constante máxima por defecto para el valor del blur para una imagen de 1000x1000
const DEFAULT_MAX_BLUR_VALUE = 100;

const fields = [
	{ name: 'Contrast', key: 'contrast', unit: '%', min: 0, max: 200, default: 100 },
	{ name: 'Brightness', key: 'brightness', unit: '%', min: 0, max: 200, default: 100 },
	{ name: 'Saturate', key: 'saturate', unit: '%', min: 0, max: 200, default: 100 },
	{ name: 'Sepia', key: 'sepia', unit: '%', min: 0, max: 100, default: 0 },
	{ name: 'Grayscale', key: 'grayscale', unit: '%', min: 0, max: 100, default: 0 },
	{ name: 'Invert', key: 'invert', unit: '%', min: 0, max: 100, default: 0 },
	{ name: 'Hue rotation', key: 'hueRotate', unit: 'deg', min: 0, max: 360, default: 0 },
	{ name: 'Blur', key: 'blur', unit: '%', min: 0, max: DEFAULT_MAX_BLUR_VALUE, default: 0 },
] as FilterField[];

const { breadScrumbWithDebounce } = useBugsnag();
const { isSafari, isIOS, isAndroid } = useDeviceInfo();

const setPreset = (preset: Filter | null) => {
	props.element.filter = preset;
	if (preset) {
		Bugsnag.leaveBreadcrumb(`Set preset to element-${props.element.id}: ${preset.name}`);
	}
};

const reset = () => {
	setPreset(null);
	Bugsnag.leaveBreadcrumb(`Remove preset to: element-${props.element.id}`);
};

type FilterParam = 'contrast' | 'brightness' | 'saturate' | 'sepia' | 'grayscale' | 'invert' | 'hueRotate' | 'blur';
const hasSvgFilter = computed(() => element.value.filter && element.value.filter.toSvgFilter().length > 0);

const updateSetting = (field: FilterParam, value: number) => {
	if (!props.element.filter) {
		props.element.filter = new FilterPresetEmpty();
	}

	props.element.filter[field] = value;
	breadScrumbWithDebounce(field);

	props.element.filter.name = `Custom`;

	if (isIOS.value) {
		// creamos un nuevo nombre en el filtro por cada cambio realizado para dar soporte en IOS
		// ya que en IOS se guarda en caché, si cambiamos su valor entenderá que se trata de un filtro nuevo y se renderizarán los cambios
		// este valor se vinculará a la url del filtro
		props.element.filter.name = `Custom-${uuidv4()}`;
	}
	// Safari tiene un problema al renderizar por primera vez un filtro custom por el cual no refresca el render y no vemos los cambios reflejados
	// cambiarle el display al elemento forzamos su renderizado (tricky but works)
	if (isSafari) {
		const element = props.element.domNode()?.querySelector('div');

		element!.style.transform = 'rotateZ(0)';
		setTimeout(() => element!.style.removeProperty('transform'), 200);
	}
};

const { isMobile } = useDeviceInfo();

const isForeground = computed(() => {
	return (
		!(element.value as Image).backgroundMode || (element.value as Image).backgroundMode === BackgroundModes.Foreground
	);
});

const filterOption = ref<'filters' | 'settings'>(isForeground.value ? 'settings' : 'filters');

watch(isForeground, () => {
	if (isForeground.value) filterOption.value = 'settings';
});

const getFieldLineWidth = (field: FilterField) => {
	if (element.value.filter && element.value.filter[field.key])
		return (element.value.filter[field.key] as number) * (100 / field.max);
	return field.default * (100 / field.max);
};

const handlerChangeColorOverlay = (color: Color) => {
	if (!element.value.filter?.overlay) return;

	const newColorIsSolid = color instanceof SolidColor;
	// Tenemos dos tipos de overlays (sólido y gradiente) y dos tipos de colores (sólido y gradiente), estos tiene que coincidir siempre

	// Es un overlay sólido y el color es sólido
	if (element.value.filter.overlay instanceof SolidOverlay && newColorIsSolid) {
		element.value.filter.overlay.color = color;
	}
	// Es un overlay gradiente y el color es gradiente
	if (element.value.filter.overlay instanceof GradientOverlay && !newColorIsSolid) {
		element.value.filter.overlay.color = color;
	}
	// Es un overlay sólido y el color es gradiente
	if (element.value.filter.overlay instanceof SolidOverlay && !newColorIsSolid) {
		element.value.filter.overlay = element.value.filter.overlay.toGradientOverlay(color);
	}
	// Es un overlay gradiente y el color es sólido
	if (element.value.filter.overlay instanceof GradientOverlay && newColorIsSolid) {
		element.value.filter.overlay = element.value.filter.overlay.toSolidOverlay(color);
	}
};

// Calculamos el tamaño máximo del blur con respecto a la diagonal de la imagen
const maxBlurSize = computed(() => {
	const imageBlurSize = Math.sqrt(Math.pow(element.value.size.width, 2) + Math.pow(element.value.size.height, 2));

	return (
		(imageBlurSize * DEFAULT_MAX_BLUR_VALUE) /
		Math.sqrt(Math.pow(DEFAULT_IMAGE_BLUR_SIZE, 2) + Math.pow(DEFAULT_IMAGE_BLUR_SIZE, 2))
	);
});

// Calculamos el porcentaje correspondiente al blur
const currentBlurPercentage = computed(() =>
	Math.round(((element.value.filter?.blur || 0 * DEFAULT_MAX_BLUR_VALUE) / maxBlurSize.value) * 100)
);

const onSelectFilterOption = (option: 'filters' | 'settings') => {
	if (filterOption.value === option) return;
	filterOption.value = option;
	recalculatePanelSize();
};
</script>

<template>
	<div>
		<div
			v-if="!(isForeground && !isPhotoMode)"
			class="mx-4 mb-2 flex h-8 items-center overflow-hidden rounded-lg border border-gray-600 lg:hidden"
		>
			<button
				data-testid="filters-button"
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="[
					{
						'bg-gray-100 text-gray-800': filterOption === 'filters',
						'text-gray-300': filterOption !== 'filters',
					},
					isForeground ? 'pointer-events-none opacity-50' : '',
				]"
				@click="onSelectFilterOption('filters')"
			>
				{{ trans('Filters') }}
			</button>
			<button
				data-testid="settings-button"
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="filterOption === 'settings' ? 'bg-gray-100 text-gray-800' : 'text-gray-300'"
				@click="onSelectFilterOption('settings')"
			>
				{{ trans('Settings') }}
			</button>

			<button
				v-if="isMobile"
				data-testid="reset-button-mobile"
				class="flex h-8 w-8 items-center justify-center px-2 text-xs font-bold uppercase text-white"
				:class="!hasSvgFilter ? 'pointer-events-none opacity-50' : ''"
				@click="reset"
			>
				<SvgIcon name="reload" class="h-3 w-3" />
			</button>
		</div>
		<template v-if="!isForeground">
			<p class="mb-1 hidden text-sm font-bold uppercase text-gray-100 mockup:text-fp-gray-700 lg:flex lg:opacity-75">
				{{ trans('Presets') }}
			</p>
			<div
				v-show="!isMobile || filterOption === 'filters'"
				data-onboarding="filter"
				data-testid="presets-filters"
				class="flex gap-2 overflow-auto px-4 py-1 text-gray-800 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 mockup:text-white mockup:scrollbar-thumb-fp-gray-200 lg:-mx-[2px] lg:mb-3 lg:grid lg:max-h-[150px] lg:grid-cols-4 lg:pl-1 lg:pr-1 lg:text-gray-800"
			>
				<div :class="isMobile ? 'w-1/6 shrink-0' : ''">
					<button
						data-testid="remove-filter"
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-700 mockup:bg-fp-gray-200"
						:class="[
							!hasSvgFilter
								? 'border-2 border-blue-500 text-white mockup:text-blue-500 slidesgo:border-purple-400'
								: 'text-gray-100 mockup:text-fp-gray-700',
							isMobile ? 'h-30' : '',
						]"
						@click="reset"
					>
						<SvgIcon name="empty" class="h-6 w-6" />
					</button>
				</div>
				<div
					v-for="preset in presetTypes()"
					:key="(preset.name as string)"
					data-testid="item-filter"
					class="group relative flex w-1/6 shrink-0 cursor-pointer flex-col overflow-hidden rounded lg:w-full"
					:class="{
						'ring-2 ring-blue-500 slidesgo:ring-purple-400': preset.name === element.filter?.name,
					}"
					:title="(preset.name as string)"
					@click="setPreset(preset)"
				>
					<component
						:is="element instanceof BaseImage ? 'img' : 'video'"
						:src="element.url"
						alt=""
						class="aspect-square rounded object-cover"
						draggable="false"
						:style="{ filter: `url(#preset-${preset.name})` }"
					/>
					<FilterOverlay v-if="preset?.overlay" :overlay="preset.overlay" />
					<svg width="0" height="0">
						<defs>
							<filter :id="'preset-' + preset.name" v-html="preset.toSvgFilter()"></filter>
						</defs>
					</svg>
				</div>
			</div>
		</template>
		<div class="mb-2 hidden items-center justify-between lg:flex">
			<p class="text-sm font-bold uppercase text-gray-100 mockup:text-fp-gray-700 lg:opacity-75">
				{{ trans('Settings') }}
			</p>
			<button
				data-testid="reset-button"
				class="flex items-center text-xs text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500"
				@click="reset"
			>
				<SvgIcon name="reload" class="mr-2 h-3 w-3" />
				{{ trans('Reset') }}
			</button>
		</div>
		<div
			v-show="!isMobile || filterOption === 'settings'"
			data-testid="custom-filters"
			class="h-32 w-full flex-col overflow-scroll px-4 py-2 text-sm text-gray-100 lg:flex lg:h-auto lg:overflow-visible lg:p-0"
			data-onboarding="filter-settings"
		>
			<div v-for="field in fields" :key="field.key" class="mb-1">
				<p class="text-sm text-gray-200 mockup:text-fp-gray-600" v-text="trans(field.name)"></p>
				<div class="flex items-center">
					<!-- Si el campo es blur lo adaptamos -->
					<template v-if="field.name === 'Blur'">
						<div class="input-range relative mr-4 flex w-full" :class="{ 'is-android': isAndroid }">
							<input
								type="range"
								class="input-range h-[4px] w-full cursor-pointer appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200 sm:h-[2px]"
								:min="field.min"
								:max="maxBlurSize"
								:step="maxBlurSize / 1000"
								:value="(element.filter && element.filter[field.key]) || field.default"
								@dblclick="updateSetting(field.key, field.default)"
								@input="updateSetting(field.key, ($event.target as any).value)"
							/>
							<span
								:style="`width: ${currentBlurPercentage}%`"
								class="absolute left-0 top-[0px] h-[4px] rounded-full bg-blue-500 slidesgo:bg-purple-400 sm:h-[2px]"
							></span>
						</div>
						<span
							class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-600"
						>
							{{ currentBlurPercentage }}{{ field.unit }}
						</span>
					</template>
					<!-- Si el campo es genérico -->
					<template v-else>
						<div class="input-range relative mr-4 flex w-full" :class="{ 'is-android': isAndroid }">
							<input
								type="range"
								class="input-range h-[4px] w-full cursor-pointer appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200 sm:h-[2px]"
								:min="field.min"
								:max="field.max"
								:step="1"
								:value="(element.filter && element.filter[field.key]) || field.default"
								@dblclick="updateSetting(field.key, field.default)"
								@input="updateSetting(field.key, ($event.target as any).value)"
							/>
							<span
								:style="`width: ${getFieldLineWidth(field)}%`"
								class="absolute left-0 top-[0px] h-[4px] rounded-full bg-blue-500 slidesgo:bg-purple-400 sm:h-[2px]"
							></span>
						</div>
						<span
							class="flex h-5 w-12 items-center justify-center rounded-sm bg-gray-800 text-center text-xs font-semibold text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-600"
						>
							{{ (element.filter && element.filter[field.key]) || field.default }}{{ field.unit }}
						</span>
					</template>
				</div>
			</div>
			<div v-if="element.filter && element.filter.overlay && !isMobile" class="mt-3 flex justify-between">
				<div>
					<h4 class="mb-3 hidden text-sm font-bold uppercase text-gray-100 opacity-75 lg:block">Overlay</h4>
					<ColorPicker class="h-10 w-10" :color="element.filter.overlay.color" @change="handlerChangeColorOverlay" />
				</div>

				<PatternFilterMenu v-if="element.filter.overlay.pattern" :overlay="element.filter.overlay" />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
/* Para evitar que se pueda mover el slider de input range al hacer scroll involuntariamente en mobile (Android) */
.is-android {
	input[type='range'] {
		pointer-events: none;
		/* Webkit Browsers like Chrome and Safari */
		&::-webkit-slider-thumb {
			pointer-events: auto;
		}
		/* Firefox */
		&::-moz-range-thumb {
			pointer-events: auto;
		}
		/* Edge */
		&::-ms-thumb {
			pointer-events: auto;
		}
	}
}
</style>
