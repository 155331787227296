import { createSharedComposable } from '@vueuse/core';
import { computed, Ref, ref, watch } from 'vue';

import { createAiImage, getPrices } from '@/api/DataApiClient';
import { getAiImageFamily } from '@/api/DataApiClient';
import { ApiClient } from '@/api/utils';
import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useTracksJob } from '@/common/composables/useTracksJob';
import Image from '@/elements/medias/images/image/classes/Image';
import { AIPromptStyles, RandomPrompts } from '@/elements/medias/images/image/utils/AiConsts';
import { useReplaceElement } from '@/elements/medias/replace/useReplaceElement';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { ImageApi } from '@/Types/apiClient';
import { AIStyles, Ratio } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useAiImage = createSharedComposable(() => {
	const { trans } = useI18n();

	const { selection } = useSelection();

	const toast = useToast();
	const { limits } = useAuth();
	const temporalRef = ref<Image>(Image.create());
	const { replaceElement } = useReplaceElement(temporalRef as Ref<Image>);
	const { data: prices } = getPrices();

	const selectedImage = ref<ImageApi>();
	const prompt = ref(selectedImage.value?.metadata.prompt || '');
	const examplePrompt = ref(RandomPrompts[MathTools.randomBetween(0, RandomPrompts.length - 1)]);
	const seed = ref<number | null>(null);
	const images = ref(4);
	const steps = ref(30);
	const cfg = ref(7);
	const loading = ref(false);
	const randomSeed = ref(true);
	const generator = ref(null);
	const results = ref<ImageApi[]>([]);
	const selectedRatio = ref<Ratio>(Ratio.square);
	const limit = ref(-1);
	const remaining = ref(999);
	const credits = ref<number | null>(null);

	const ratio = computed(() => {
		const result = { width: 0, height: 0 };

		switch (selectedRatio.value) {
			case Ratio.landscape:
				result.width = 768;
				result.height = 512;
				break;
			case Ratio.portrait:
				result.width = 512;
				result.height = 768;
				break;
			default:
				result.width = 512;
				result.height = 512;
				break;
		}

		return result;
	});
	const finalPrompt = computed(() => `${prompt.value}, ${getGeneratedStyle(AIstyle.value)}`);

	const usingExampleAsPrompt = computed(() => RandomPrompts.includes(prompt.value));

	const getGeneratedStyle = (style: string) => {
		switch (style) {
			case AIStyles.photo:
				return generatePhotoStyle();
			case AIStyles.digitalArt:
				return generateDigtalArtStyle();
			case AIStyles.threeD:
				return generateThreeDStyle();
			case AIStyles.painting:
				return generatePaintingStyle();
			case AIStyles.surprise:
				return generateSurpriseStyle();
			default:
				return '';
		}
	};

	const generatePhotoStyle = () => AIPromptStyles.photo[0];

	const generateDigtalArtStyle = () => AIPromptStyles.digitalArt[0];

	const generateThreeDStyle = () => AIPromptStyles.threeD[0];

	const generatePaintingStyle = () => AIPromptStyles.painting[0];

	const generateSurpriseStyle = () => {
		const style = Object.keys(AIPromptStyles)[MathTools.randomBetween(0, Object.keys(AIPromptStyles).length - 1)];

		return AIPromptStyles[style][0];
	};

	const AIstyle = ref<string>('');
	const { APP_API_PATH } = useEnvSettings();
	const { trackJob } = useTracksJob();
	const generateImage = async () => {
		results.value = [];
		loading.value = true;

		const body = {
			prompt: finalPrompt.value,
			aspect_ratio: selectedRatio.value || 'square',
			seed: randomSeed.value ? null : seed.value,
			images: images.value,
			steps: steps.value,
			cfg: cfg.value,
			generator: generator.value,
		};

		try {
			const { statusCode, data, response } = await createAiImage(body);

			remaining.value = parseInt(response.value?.headers.get('x-ratelimit-remaining') || '') || 999;
			limit.value = parseInt(response.value?.headers.get('x-ratelimit-limit') || '') || 999;
			credits.value =
				['undefined', 'null'].includes(typeof response.value?.headers.get('x-credits')) ||
				!response.value?.headers.get('x-credits')
					? null
					: parseInt(response.value?.headers.get('x-credits') || '');
			if (statusCode.value === 429) {
				toast.error(trans('Too many requests, please try again later'));
				return;
			}

			const result = await trackJob(data.value.uuid as string, 80000, 15000);

			results.value = result.images as ImageApi[];
		} catch (e) {
			toast.error(trans('Something went wrong, maybe your prompt contains some forbidden words'));
			if (e?.response?.headers) {
				remaining.value = parseInt(e.response?.headers.get('x-ratelimit-remaining') || '') || 999;
				limit.value = parseInt(e.response?.headers.get('x-ratelimit-limit') || '') || 999;
				credits.value =
					['undefined', 'null'].includes(typeof e.response?.headers.get('x-credits')) ||
					!e?.response?.headers.get('x-credits')
						? null
						: parseInt(e.response.headers.get('x-credits'));
			}
		} finally {
			loading.value = false;
		}
	};

	const getPromptStyleToReplace = (promptString: string, emptyText = false) => {
		const result = { style: '', newText: '' };

		Object.keys(AIPromptStyles).forEach((style: string) => {
			AIPromptStyles[style].forEach((promptStyle: string) => {
				if (promptString.includes(promptStyle)) {
					result.style = `, ${promptStyle}`;
					result.newText = emptyText ? '' : `, ${AIStyles[style]}`;
				}
			});
		});

		return result;
	};

	const select = async (image: ImageApi) => {
		const body = {
			id: image.id,
		};

		ApiClient.request(`${APP_API_PATH}image/ai/use`, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
			},
			// @ts-ignore
			body,
		});
	};

	const saveExample = async (image: ImageApi) => {
		loading.value = true;

		const body = {
			id: image.id,
		};

		await ApiClient.request(`${APP_API_PATH}image/ai`, {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
			},
			// @ts-ignore
			body,
		});

		loading.value = false;
	};

	const generateExampleImage = () => {
		prompt.value = trans(examplePrompt.value);
		randomSeed.value = true;
		generateImage();
	};

	const refreshExample = () => {
		const examples = RandomPrompts.filter((example) => example !== examplePrompt.value);
		examplePrompt.value = examples[MathTools.randomBetween(0, examples.length - 1)];
	};

	const restoreAiImageFamily = async (imageId: string) => {
		const { statusCode, data } = await getAiImageFamily(imageId);

		if (statusCode.value !== 200) {
			throw new Error('Cannot restore Ai Image family.');
		}

		if (statusCode.value === 200 && data.value) {
			results.value = data.value;
		}
	};

	const parseImageToImageApi = (img: Image): ImageApi => {
		return {
			id: img.metadata?.imageApi?.id || img.id,
			metadata: {
				config: img.metadata.config,
				prompt: img.metadata.prompt,
				proportion: img.metadata.proportion,
				source: img.metadata.source,
			},
			url: img.metadata?.imageApi?.url || img.url,
			preview: img.metadata?.imageApi?.preview || img.preview,
			data: [],
			links: [],
			origin: img.metadata?.imageApi?.origin || 'ai',
			type: img.metadata?.imageApi?.type || 'image',
		};
	};

	const replaceAiImage = () => {
		if (selection.value[0] instanceof Image && selectedImage.value) {
			temporalRef.value = selection.value[0];

			replaceElement(selectedImage.value);
		}
	};

	const upscale = async (uuid: string, multiplier: number) => {
		const response = await ApiClient.request(
			`${APP_API_PATH}image/ai/upscale`,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
				},
				body: {
					// @ts-ignore
					ai_image: uuid,
					multiplier,
				},
			},
			true
		);

		if (response.status === 429) {
			throw new Error('Credits not enough to upscale Ai Image.');
		}
		if (response.status !== 200) {
			throw new Error('Cannot upscale Ai Image.');
		}

		const results = await response.json();

		const result = await trackJob(results.uuid as string, 40000, 10000);

		return {
			url: result.url,
			credits: parseInt(response.headers.get('x-credits') as number),
		};
	};

	watch(
		limits,
		async () => {
			limit.value = limits.value.aiImages?.maxDailyAttempts;
			remaining.value = limits.value.aiImages?.remaining;
			credits.value = limits.value.aiImages?.credits;
		},
		{ immediate: true }
	);

	return {
		AIPromptStyles,
		AIstyle,
		cfg,
		images,
		examplePrompt,
		loading,
		prompt,
		ratio,
		RandomPrompts,
		randomSeed,
		results,
		seed,
		selectedImage,
		selectedRatio,
		steps,
		usingExampleAsPrompt,
		generateExampleImage,
		generateImage,
		generateSurpriseStyle,
		getPromptStyleToReplace,
		restoreAiImageFamily,
		parseImageToImageApi,
		refreshExample,
		replaceAiImage,
		select,
		saveExample,
		generator,
		limit,
		remaining,
		credits,
		upscale,
		prices,
	};
});
