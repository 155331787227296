<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import GridImagePhotos from '@/assets/grid-login-photos.png';
import GridImageTemplates from '@/assets/grid-login-templates.png';
import { useAuth } from '@/auth/composables/useAuth';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

const { APP_BASE } = useEnvSettings();
const { isPhotoMode, isFreepikContext } = useEditorMode();

const emit = defineEmits(['close']);

const close = () => {
	emit('close');
};
const { set, remove } = useCookies();

const { isLogged, notClosable } = useAuth();

const login = async () => {
	let loginUrl = isFreepikContext.value
		? 'https://id.freepikcompany.com/v2/log-in?client_id=freepik'
		: APP_BASE + 'login?editor=1';

	try {
		const _ga = window.document.cookie.match('(?:^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || '';
		const fp_ga = window.document.cookie.match('(?:^|;)\\s*fp_ga\\s*=\\s*([^;]+)')?.pop() || '';
		const link = window.google_tag_data.glBridge.generate({
			_ga,
			fp_ga,
		});
		loginUrl += `&_gl=${link}&_ga=${_ga.split('GA1.')[1]}&fp_ga=${fp_ga.split('GA1.')[1]}`;
	} catch (error) {
		console.error(error);
	}

	if (isFreepikContext.value) {
		const backUrl = isPhotoMode.value
			? 'https://www.freepik.com/photo-editor/new-artboard?width=1024&height=1024&unit=px&size=Custom&close=1'
			: 'https://www.freepik.com/ai-images-editor?close=1';

		set('GR_LGURI', backUrl, { path: '/', domain: '.freepik.com' });
	}

	const loginWindow = window.open(loginUrl);

	if (!loginWindow) {
		return;
	}

	loginWindow.opener = null;

	await until(isLogged).toBe(true);
	Bugsnag.leaveBreadcrumb(`Login`);
	loginWindow.close();
	remove('GR_LGURI');
};

const onClickLogin = () => {
	login();
	GAnalytics.trackGA4('login_popup', { link_text: 'login' });
};
</script>

<template>
	<Modal open class="flex items-center justify-center" @close="notClosable ? false : close()">
		<div class="relative flex h-64 w-[320px] overflow-hidden rounded-md bg-white lg:w-[600px]">
			<div class="flex w-2/3 flex-col p-8 lg:w-1/2">
				<span class="mb-2 text-2xl font-bold text-gray-800">{{ trans(`Join now`) }}</span>
				<p class="text-gray-600">{{ trans(`Store your work in your account and edit it anywhere, anytime`) }}</p>

				<div class="mt-6 flex gap-4">
					<button
						class="h-12 rounded bg-blue-500 px-8 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
						@click="onClickLogin"
					>
						{{ trans(`Login`) }}
					</button>
				</div>
			</div>
			<img
				v-if="!isPhotoMode"
				:src="GridImageTemplates"
				class="absolute right-0 top-0 h-full w-3/5 rounded-br-md rounded-tr-md object-cover object-left lg:w-3/5"
			/>
			<img
				v-if="isPhotoMode"
				:src="GridImagePhotos"
				class="absolute right-0 top-0 h-full w-3/5 rounded-br-md rounded-tr-md object-cover object-left lg:w-3/5"
			/>
			<button
				v-if="!notClosable"
				class="absolute right-2 top-2 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-gray-800/75 font-semibold text-blue-500 backdrop-filter hover:bg-opacity-100 hover:text-white"
				@click="close()"
			>
				<SvgIcon name="cross" class="h-4 w-4 text-white" />
			</button>
		</div>
	</Modal>
</template>
