<script setup lang="ts">
import { computed, toRef } from 'vue';

import Dropdown from '@/common/components/Dropdown.vue';
import Storyset from '@/elements/storyset/classes/Storyset';
import { useStoryset } from '@/elements/storyset/composables/useStoryset';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Storyset }>();
const element = toRef(props, 'element');
const { trans } = useI18n();

const { storysetBackgrounds, currentStorysetBackground, changeStorysetBackground } = useStoryset(element);

const backgroundOptions = computed(() => {
	return storysetBackgrounds.value.map((bg) => ({
		label: `${trans(bg.name + ' background')}`,
		value: bg.name.toLowerCase(),
	}));
});
</script>

<template>
	<Dropdown
		data-testid="bg-story"
		class="flex items-center justify-center"
		:selected="(trans(currentStorysetBackground) as string)"
		:options="backgroundOptions"
		@update="(ev) => changeStorysetBackground(ev.value)"
	>
	</Dropdown>
</template>
