<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { promiseTimeout, until } from '@vueuse/core';
import { computed, nextTick, onActivated, Ref, ref, watch } from 'vue';

import { getPredefinedTexts } from '@/api/DataApiClient';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import ActivableItem from '@/common/components/ActivableItem.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useCircleType } from '@/elements/texts/curved/composables/useCircleType';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import TextsCategoryPanel from '@/elements/texts/text/components/panels/add/TextsCategoryPanel.vue';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { PredefinedTextApi } from '@/Types/apiClient';
import { TextsPanelTypes, TextWithValue } from '@/Types/types';

// Data
const categoryId = ref(0);
const categoryName = ref('');
const source = ref(`elements/category/2263/new`);
const temporalRef = ref(Text.create());

const { inUseFonts, loadFontsByName, fonts, fontLoading, getFontWeight } = useFonts();

const fontFamily = computed(() => {
	if (inUseFonts.value.length > 0) return inUseFonts.value[0].slug;

	const fontSlug =
		Object.values(fonts.value).find((f) => f.name === temporalRef.value.fontFamily)?.slug ||
		Object.values(fonts.value).find((f) => f.slug === temporalRef.value.fontFamily)?.slug ||
		Object.values(fonts.value).find((f) => f.name === 'Montserrat')?.slug;

	return fontSlug as string;
});

const activeTextType = ref<TextsPanelTypes | null>(null);

const title = computed(() => {
	if (!activeTextType.value) return 'Text';
	return categoryName.value;
});

const onGoBack = async () => {
	activeTextType.value = null;
};

const textCurved = ref();
const previewText = ref(Text.create({ fontSize: 24, fontWeight: 400, lineHeight: 1.2 }));
const previewCurvedText = ref();
const { init, unmount } = useCircleType(previewText as Ref<Text>, previewCurvedText, ref(1));
const { addInsertableText: addText, addInsertablePredefinedText: addPredefinedText } = useAddInsertableElement(
	previewText as Ref<Text>
);

const { getBoxInfo } = useCircleTypeInfo(previewText as Ref<Text>, ref(1));
const { trans } = useI18n();

onActivated(async () => {
	const fontNamesInUse = inUseFonts.value.map((font) => font.name);
	if (!fontNamesInUse.length) {
		await loadFontsByName(['Montserrat']);
	}
	await loadFontsByName(fontNamesInUse);
	await until(fontLoading).toBe(false);
	await promiseTimeout(10);
	await generateCurvedPreview(true);
});
const generateCurvedPreview = async (withUnmount = false) => {
	// como estamos falseando el composable para poder generar una preview, no va a encontrar el TextComponent y va a lanzar una excepción
	// la capturamos sin ningún efecto
	previewCurvedText.value?.remove();
	try {
		if (!textCurved.value) {
			textCurved.value = document.querySelector('[data-add-text="curved"]');
		}

		previewCurvedText.value = textCurved.value.cloneNode(true) as HTMLDivElement;
		previewCurvedText.value.id = 'clone-curved';
		textCurved.value.insertAdjacentElement('afterend', previewCurvedText.value);
		textCurved.value.classList.add('opacity-0');
		textCurved.value.classList.add('absolute');
		textCurved.value.classList.add('pointer-events-none');
		previewCurvedText.value.classList.remove('opacity-0');
		previewCurvedText.value.classList.remove('absolute');
		if (withUnmount) {
			unmount();
			await nextTick();
		}
		init();
	} catch (e) {}

	if (!previewCurvedText.value) return;

	await nextTick();
	previewCurvedText.value.querySelector('div[style*="-webkit-text-stroke"] ')?.remove();
	await promiseTimeout(100);

	const widthButton = textCurved.value.parentElement.getBoundingClientRect()?.width;
	const { width: widthCurvedText } = getBoxInfo(textCurved.value.closest('button'));

	if (widthCurvedText > widthButton) {
		const scale = widthButton / widthCurvedText;

		previewCurvedText.value.firstElementChild.style.transform = `scale(${scale})`;
	}
};

watch(
	inUseFonts,
	async (newVal) => {
		if (!newVal.length) {
			await loadFontsByName(['Montserrat']);
		}
		await until(fontLoading).toBe(false);
		await generateCurvedPreview(true);
	},
	{ immediate: true }
);

const textTypes = computed<TextWithValue[]>(() => [
	{
		text: trans('Add a heading'),
		fontSize: 24,
		lineHeight: 1.2,
		fontWeight: getFontWeight(fontFamily.value, true),
		fontFamily: `${fontFamily.value}`,
	},
	{
		text: trans('Add a subheading'),
		fontSize: 18,
		lineHeight: 1.2,
		fontWeight: getFontWeight(fontFamily.value, true),
		fontFamily: `${fontFamily.value}`,
	},
	{
		text: trans('Add a body text'),
		fontSize: 14,
		lineHeight: 1.2,
		fontWeight: getFontWeight(fontFamily.value),
		fontFamily: `${fontFamily.value}`,
	},
]);

const textEffects = computed(() => [
	{
		text: trans('Heading with outline'),
		fontSize: 24,
		lineHeight: 1.2,
		fontWeight: getFontWeight(fontFamily.value, true),
		outline: {
			color: SolidColor.gray(),
			width: 5,
		},
		fontFamily: `${fontFamily.value}`,
	},
	{
		text: trans('Add a curved text'),
		fontSize: 24,
		lineHeight: 1.2,
		fontWeight: getFontWeight(fontFamily.value),
		fontFamily: `${fontFamily.value}`,
		curvedProperties: true,
	},
	{
		text: trans('Add a gradient text'),
		fontSize: 18,
		lineHeight: 1.2,
		fontWeight: getFontWeight(fontFamily.value, true),
		fontFamily: `${fontFamily.value}`,
		colors: [GradientColor.defaultColor()],
	},
]);

const setCategory = (id: number, name: string) => {
	categoryId.value = id;
	categoryName.value = name;
	activeTextType.value = TextsPanelTypes.CategorizedTexts;
	Bugsnag.leaveBreadcrumb(`select category ${id}`);
};

const { data: texts, isFetching } = getPredefinedTexts(source, { refetch: true });
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader :title="title" :show-back-button="!!activeTextType" @goBack="onGoBack" />

		<TextsCategoryPanel
			v-if="activeTextType === TextsPanelTypes.CategorizedTexts"
			:id="categoryId"
			@predefinedTextAdded="(element) => addPredefinedText(element)"
		/>

		<template v-else>
			<InfiniteLoading
				:data="texts?.subcategories || []"
				class="flex flex-col"
				data-testid="text-panel"
				:is-fetching="isFetching"
			>
				<div class="mb-6 flex flex-col gap-2">
					<p class="font-semibold text-gray-100">{{ trans('Types') }}</p>
					<button
						v-for="style in textTypes"
						:key="style.text"
						class="w-full cursor-pointer rounded bg-gray-700 p-4 text-left text-xl font-bold focus:outline-none hover:bg-gray-600"
						:class="style.outline ? 'text-gray-700 hover:text-gray-600' : 'text-gray-100 hover:text-white'"
						:style="{
							fontSize: `${style.fontSize}px`,
							fontFamily: `'${style.fontFamily}'`,
							fontWeight: style.fontWeight,
							'text-shadow': style.outline
								? '-1px -1px 0 #bac8d3, 1px -1px 0 #bac8d3, -1px 1px 0 #bac8d3, 1px 1px 0 #bac8d3'
								: '',
						}"
						@click="addText(style as TextWithValue)"
					>
						<span
							:style="{
								'background-image': style.color ? style.color.toCssString() : '',
								'-webkit-text-fill-color': style.color ? 'transparent' : '',
								'-webkit-background-clip': style.color ? 'text' : '',
							}"
							>{{ style.text }}</span
						>
					</button>
				</div>
				<div class="mb-6 flex flex-col gap-2">
					<p class="font-semibold text-gray-100">{{ trans('Effects') }}</p>
					<button
						v-for="style in textEffects"
						:key="style.text"
						class="w-full cursor-pointer rounded bg-gray-700 p-4 text-left text-xl font-bold focus:outline-none hover:bg-gray-600"
						:class="style.outline ? 'text-gray-700 hover:text-gray-600' : 'text-gray-100 hover:text-white'"
						:style="{
							fontSize: `${style.fontSize}px`,
							fontFamily: `'${style.fontFamily}'`,
							fontWeight: style.fontWeight,
							'text-shadow': style.outline
								? '-1px -1px 0 #bac8d3, 1px -1px 0 #bac8d3, -1px 1px 0 #bac8d3, 1px 1px 0 #bac8d3'
								: '',
						}"
						@click="addText(style as TextWithValue)"
					>
						<span
							:style="{
								'background-image': style.colors && style.colors?.length > 0 ? style.colors[0].toCssString() : '',
								'-webkit-text-fill-color': style.colors && style.colors.length > 0 ? 'transparent' : '',
								'-webkit-background-clip': style.colors && style.colors.length > 0 ? 'text' : '',
							}"
							:data-add-text="style.curvedProperties ? 'curved' : ''"
							>{{ style.text }}</span
						>
					</button>
				</div>
				<p class="pb-3 font-semibold text-gray-100">{{ trans('Combinations') }}</p>
				<template #item="{ item }">
					<div
						v-if="'elements' in item && item.elements.length"
						class="list-elements group mb-4"
						:data-testid="`${item.name} category`"
					>
						<div class="mb-3 mt-0 flex items-center justify-between">
							<p class="text-xs font-bold uppercase text-gray-100">{{ trans(item.name) }}</p>
							<button
								v-if="item.elements.length > 2"
								:data-test-category="item.name"
								data-testid="see-all"
								class="pr-4 text-xs font-bold text-gray-100 group-hover:opacity-100 hover:text-white lg:opacity-0"
								@click="setCategory(Number(item.id), item.name)"
							>
								{{ trans('See all') }}
							</button>
						</div>
						<div class="flex h-auto snap-x gap-2 pb-4 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600">
							<template v-for="element in item.elements" :key="element.id">
								<button
									v-if="'active' in element"
									:data-testid="`predefined-text-${element.id}`"
									class="flex shrink-0 cursor-pointer snap-start items-center justify-center rounded bg-gray-700 px-3 py-2 transition-opacity duration-300 hover:bg-gray-600"
									@click="addPredefinedText(element as PredefinedTextApi)"
								>
									<ActivableItem :active="element.active">
										<img
											:src="element.preview"
											:alt="element.name"
											:class="{ 'opacity-75 invert filter': element.multicolor === false }"
											class="w-24 shrink-0 object-contain"
											draggable="false"
										/>
									</ActivableItem>
								</button>
							</template>
						</div>
					</div>
				</template>
			</InfiniteLoading>
		</template>
		<!-- Temporal -->
		<div class="absolute bottom-0 mb-4 mt-auto flex w-full justify-center gap-2"></div>
	</div>
</template>
