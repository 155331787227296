<script lang="ts" setup>
import { toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
import { useLineColors } from '@/color/composables/useLineColors';
import Line from '@/elements/line/classes/Line';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');

const { updateMarkerStartColor, updateMarkerEndColor } = useLineColors(element);
const { trans } = useI18n();
</script>

<template>
	<div class="mb-6">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Markers') }}</h4>

		<div class="grid grid-cols-6 gap-2">
			<ColorPicker
				v-if="element.markerStart"
				class="h-10 w-10"
				:color="element.markerStart.color"
				@change="updateMarkerStartColor"
			/>

			<ColorPicker
				v-if="element.markerEnd"
				class="h-10 w-10"
				:color="element.markerEnd.color"
				@change="updateMarkerEndColor"
			/>
		</div>
	</div>
</template>
