<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import NumberInput from '@/common/components/NumberInput.vue';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { useQRCodeAsSVG } from '@/elements/qr-code/composables/useQRCodeAsSVG';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{
	element: QRCode;
	editing: boolean;
}>();

const element = toRef(props, 'element');

const isReady = computed(() =>
	/(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.exec(
		element.value.url
	)
);

useQRCodeAsSVG(element);

const { bugsnagMsgWithDebounce } = useBugsnag();
const { trans } = useI18n();

const onUpdateColor = (key: 'front' | 'bg', color: SolidColor) => {
	if (key === 'front') {
		element.value.updateFrontColor(color);
		return;
	}

	element.value.updateBgColor(color);
};

const onUpdateMargin = (margin: number) => {
	element.value.margin = margin;
	bugsnagMsgWithDebounce(`Set margin to ${element.value.type}-${element.value.id}: ${margin}`);
};

const onUpdateText = (url: string) => {
	element.value.url = url;
	bugsnagMsgWithDebounce(`Typing the following url in QR input: ${url}`);
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div class="mb-4 flex flex-col gap-4">
		<div v-if="editing" class="flex gap-4">
			<div class="flex w-1/4 flex-col">
				<NumberInput
					class="h-10 text-sm"
					data-testid="input-margin-qr"
					:min="0"
					:value="element.margin"
					@track-typing="trackTypingEvent('margin-qr')"
					@track-step="trackStepEvent('margin-qr')"
					@update="onUpdateMargin"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">{{ trans('Margin') }}</label>
			</div>
			<ColorPicker
				:color="(element.frontColor as SolidColor)"
				:hide-gradient="true"
				class="h-10 w-10"
				@change="(color) => onUpdateColor('front', color as SolidColor)"
			/>
			<ColorPicker
				:color="(element.bgColor as SolidColor)"
				:hide-gradient="true"
				class="h-10 w-10"
				@change="(color) => onUpdateColor('bg', color as SolidColor)"
			/>
		</div>
		<div class="flex flex-col">
			<input
				:value="element.url"
				type="text"
				:placeholder="trans('Type or copy the URL here')"
				class="h-10 w-full appearance-none rounded bg-gray-900 px-4 text-sm text-gray-300 placeholder:text-gray-500 focus:outline-none"
				:class="{ 'border border-red-600': element.url.length && !isReady }"
				@change="(e) => onUpdateText((e.currentTarget as HTMLInputElement)?.value || '')"
				@input="(e) => onUpdateText((e.currentTarget as HTMLInputElement)?.value || '')"
			/>
			<label v-if="editing" class="mt-1 pl-1 text-2xs text-gray-100">{{ trans('QR code URL') }}</label>
		</div>
	</div>
</template>
