<script lang="ts" setup>
import { computed, Ref, ref, toRef, watch } from 'vue';

import ProjectColorsPanel from '@/color/components/ProjectColorsPanel.vue';
import ProjectColorsToolbar from '@/color/components/ProjectColorsToolbar.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import EditBorderBox from '@/elements/box/components/panels/edit/EditBorderBox.vue';
import EditBox from '@/elements/box/components/panels/edit/EditBox.vue';
import EditCornerRadius from '@/elements/box/components/panels/edit/EditCornerRadius.vue';
import BoxToolbar from '@/elements/box/components/toolbars/BoxToolbar.vue';
import EditGroup from '@/elements/group/components/panels/edit/EditGroup.vue';
import Line from '@/elements/line/classes/Line';
import EditLine from '@/elements/line/components/panels/edit/EditLine.vue';
import LineToolbar from '@/elements/line/components/toolbars/LineToolbar.vue';
import CropToolbar from '@/elements/medias/crop/components/toolbars/CropToolbar.vue';
import EditFilter from '@/elements/medias/filter/components/panels/edit/EditFilter.vue';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import ForegroundToolbar from '@/elements/medias/images/foreground/components/toolbars/ForegroundToolbar.vue';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import ImageLayerSelector from '@/elements/medias/images/image/components/menus/ImageLayerSelector.vue';
import EditImage from '@/elements/medias/images/image/components/panels/edit/EditImage.vue';
import EditImagePreset from '@/elements/medias/images/image/components/panels/edit/EditImagePreset.vue';
import ImageToolbar from '@/elements/medias/images/image/components/toolbars/ImageToolbar.vue';
import EditMask from '@/elements/medias/mask/components/panels/edit/EditMask.vue';
import { Video } from '@/elements/medias/video/classes/Video';
import EditVideo from '@/elements/medias/video/components/panels/edit/EditVideo.vue';
import VideoToolbar from '@/elements/medias/video/components/toolbars/VideoToolbar.vue';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import EditQRCode from '@/elements/qr-code/components/panels/edit/EditQRCode.vue';
import QRCodeToolbar from '@/elements/qr-code/components/toolbars/QRCodeToolbar.vue';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import EditShape from '@/elements/shapes/shape/components/panels/edit/EditShape.vue';
import ShapeToolbar from '@/elements/shapes/shape/components/toolbars/ShapeToolbar.vue';
import Storyset from '@/elements/storyset/classes/Storyset';
import EditStoryset from '@/elements/storyset/components/panels/edit/EditStoryset.vue';
import StorysetToolbar from '@/elements/storyset/components/toolbars/StorysetToolbar.vue';
import TextCurvature from '@/elements/texts/curved/components/menus/TextCurvature.vue';
import TextBorderMenu from '@/elements/texts/text/components/menus/TextBorderMenu.vue';
import TextLinkMenu from '@/elements/texts/text/components/menus/TextLinkMenu.vue';
import TextShadowMenu from '@/elements/texts/text/components/menus/TextShadowMenu.vue';
import EditText from '@/elements/texts/text/components/panels/edit/EditText.vue';
import EditTextEffects from '@/elements/texts/text/components/panels/edit/EditTextEffects.vue';
import AiWriterToolbar from '@/elements/texts/text/components/toolbars/AiWriterToolbar.vue';
import TextToolbar from '@/elements/texts/text/components/toolbars/TextToolbar.vue';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { EditPanels } from '@/Types/types';

const store = useMainStore();
const { selection: selectionCanvas } = useSelection();
const { isKeyboardOpen, isMobile } = useDeviceInfo();
const { isCropping } = useInteractions();

const editPanel = toRef(store, 'editPanel');

const temporalForeground = ref(ForegroundImage.create());
const { imageBackground } = useForeground(temporalForeground as Ref<ForegroundImage>);
const mainComponent = computed(() => (isMobile.value ? mainComponentMobile() : mainComponentDesktop()));

const mobileElementPanels = {
	allTextPanels: [
		EditPanels.Text,
		EditPanels.TextEffects,
		EditPanels.TextLink,
		EditPanels.TextShadow,
		EditPanels.TextCurve,
		EditPanels.TextBorder,
	],
	allImagePanels: [
		EditPanels.Filter,
		EditPanels.Image,
		EditPanels.Mask,
		EditPanels.ImageLayerSelector,
		EditPanels.ImagePresets,
	],
	allVideoPanels: [EditPanels.Filter, EditPanels.Image, EditPanels.Mask, EditPanels.Video],
	allForegroundPanels: [
		EditPanels.Filter,
		EditPanels.ImageLayerSelector,
		EditPanels.Foreground,
		EditPanels.ImagePresets,
	],
	allBoxPanels: [EditPanels.BorderBox, EditPanels.CornerBox, EditPanels.Box],
	shapePanel: EditPanels.Shape,
	linePanel: EditPanels.Line,
	qrPanel: EditPanels.QRCode,
	storysetPanel: EditPanels.Storyset,
};
const panelShouldChange = computed(() => {
	const [selectedElement] = selectionCanvas.value;
	if (!editPanel.value || !selectedElement) return false;
	return (
		(mobileElementPanels.allTextPanels.includes(editPanel.value) && !(selectedElement instanceof Text)) ||
		(mobileElementPanels.allImagePanels.includes(editPanel.value) && !(selectedElement instanceof Image)) ||
		(mobileElementPanels.allVideoPanels.includes(editPanel.value) && !(selectedElement instanceof Video)) ||
		(mobileElementPanels.allForegroundPanels.includes(editPanel.value) &&
			!(selectedElement instanceof ForegroundImage)) ||
		(mobileElementPanels.allBoxPanels.includes(editPanel.value) && !(selectedElement instanceof Box)) ||
		(mobileElementPanels.shapePanel === editPanel.value && !(selectedElement instanceof Shape)) ||
		(mobileElementPanels.linePanel === editPanel.value && !(selectedElement instanceof Line)) ||
		(mobileElementPanels.qrPanel === editPanel.value && !(selectedElement instanceof QRCode)) ||
		(mobileElementPanels.storysetPanel === editPanel.value && !(selectedElement instanceof Storyset))
	);
});
const secondaryComponent = computed(() => {
	if (!isMobile.value || isCropping.value) return null;
	if (editPanel.value === EditPanels.Filter) return EditFilter;
	if (editPanel.value === EditPanels.Mask) return EditMask;
	if (editPanel.value === EditPanels.TextEffects) return EditTextEffects;
	if (editPanel.value === EditPanels.TextLink) return TextLinkMenu;
	if (editPanel.value === EditPanels.TextShadow) return TextShadowMenu;
	if (editPanel.value === EditPanels.TextCurve) return TextCurvature;
	if (editPanel.value === EditPanels.TextBorder) return TextBorderMenu;
	if (editPanel.value === EditPanels.ImagePresets) return EditImagePreset;
	if (editPanel.value === EditPanels.ImageLayerSelector) return ImageLayerSelector;
	if (editPanel.value === EditPanels.BorderBox) return EditBorderBox;
	if (editPanel.value === EditPanels.CornerBox) return EditCornerRadius;

	return null;
});

const selectedElement = computed(() => {
	if (!selectionCanvas.value.length) {
		return null;
	}
	const element = selectionCanvas.value[0];
	// El panel ImagePresets siempre espera un elemento del tipo background por lo que si es un foreground pasamos su background
	if (element instanceof ForegroundImage && editPanel.value === EditPanels.ImagePresets) {
		// eslint-disable-next-line vue/no-side-effects-in-computed-properties
		temporalForeground.value = element;
		return imageBackground.value;
	}
	return element;
});

const isGlobalPanel = computed(() => {
	const globalPanels = [EditPanels.GlobalColors];

	return editPanel.value !== null && globalPanels.includes(editPanel.value);
});

const visibility = computed(() => (!!mainComponent.value && !!selectionCanvas.value.length) || isGlobalPanel.value);

const mainComponentMobile = () => {
	if (isCropping.value) return CropToolbar;
	if (!editPanel.value) return null;

	//  elementos con múltiples paneles en mobile
	if (
		mobileElementPanels.allForegroundPanels.includes(editPanel.value) &&
		selectedElement.value instanceof ForegroundImage
	)
		return ForegroundToolbar;
	if (mobileElementPanels.allImagePanels.includes(editPanel.value) && selectedElement.value instanceof Image)
		return ImageToolbar;

	if (mobileElementPanels.allVideoPanels.includes(editPanel.value) && selectedElement.value instanceof Video)
		return VideoToolbar;
	if (mobileElementPanels.allBoxPanels.includes(editPanel.value)) return BoxToolbar;
	if (mobileElementPanels.allTextPanels.includes(editPanel.value)) return TextToolbar;

	//  elementos con un único  panel en mobile
	if (editPanel.value === mobileElementPanels.shapePanel) return ShapeToolbar;
	if (editPanel.value === mobileElementPanels.linePanel) return LineToolbar;
	if (editPanel.value === mobileElementPanels.qrPanel) return QRCodeToolbar;
	if (editPanel.value === mobileElementPanels.storysetPanel) return StorysetToolbar;

	// paneles fuera del contexto de elementos
	if (editPanel.value === EditPanels.GlobalColors) return ProjectColorsToolbar;
	if (editPanel.value === EditPanels.WriterAI) return AiWriterToolbar;
	return null;
};

const mainComponentDesktop = () => {
	if (editPanel.value === EditPanels.GlobalColors) return ProjectColorsPanel;
	if (editPanel.value === EditPanels.Filter) return EditFilter;
	if (editPanel.value === EditPanels.Group) return EditGroup;
	if (editPanel.value === EditPanels.Image) return EditImage;
	if (editPanel.value === EditPanels.Line) return EditLine;
	if (editPanel.value === EditPanels.Mask) return EditMask;
	if (editPanel.value === EditPanels.QRCode) return EditQRCode;
	if (editPanel.value === EditPanels.Shape) return EditShape;
	if (editPanel.value === EditPanels.Box) return EditBox;
	if (editPanel.value === EditPanels.Storyset) return EditStoryset;
	if (editPanel.value === EditPanels.Text) return EditText;
	if (editPanel.value === EditPanels.Video) return EditVideo;
	if (editPanel.value === EditPanels.TextEffects) return EditTextEffects;
	if (editPanel.value === EditPanels.TextLink) return TextLinkMenu;

	if (editPanel.value === EditPanels.ImagePresets) return EditImagePreset;
	return null;
};

// Estamos atentos a los cambios en la selección múltiple, en caso de que haya un cambio en una selección múltiple:
// ? Desktop: si  tenemos un panel que no sea el de grupo,  cerramos el panel
// ? Mobile : si cambia la cantidad de elementos en la selección, podemos afirmar que es por que se ha borrado un elemento
//  en cuyo caso debemos de corregir el tipo de panel a mostrar si es necesario
watch(
	() => selectionCanvas.value.length,
	() => {
		if (!editPanel.value) return;
		const [selectedElement] = selectionCanvas.value;
		if (
			(!isMobile.value && store.editPanel && store.editPanel !== EditPanels.Group) ||
			(store.editPanel === EditPanels.Group && selectionCanvas.value.length < 2)
		) {
			store.editPanel = null;
			return;
		}
		if (panelShouldChange.value) store.setEditPanelMobile(selectedElement);
	}
);
</script>

<template>
	<div v-if="visibility">
		<div
			v-if="isMobile"
			id="edit-panel"
			:data-testid="`${editPanel}-panel-mobile`"
			class="toolbar fixed bottom-0 left-0 z-40 flex w-full flex-col rounded-tl-xl rounded-tr-xl bg-gray-700 py-3 transition-transform duration-300"
			:class="{
				'translate-y-32': isKeyboardOpen,
				'pointer-events-none opacity-0': !visibility,
			}"
		>
			<component
				:is="secondaryComponent"
				id="secondary-panel"
				class="z-20 w-full"
				data-testid="secondary-panel"
				:element="selectedElement"
			/>

			<component
				:is="mainComponent"
				id="main-panel"
				data-testid="main-panel"
				class="z-10 flex w-full items-center pt-2"
				:element="selectedElement"
			/>
		</div>

		<div
			v-else
			id="edit-panel"
			:data-testid="`${editPanel}-panel-desktop`"
			class="fixed right-0 z-40 h-[calc(100vh-56px)] w-[320px] overflow-auto bg-gray-800/95 p-4 text-gray-800 backdrop-blur scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 mockup:left-0 mockup:right-auto mockup:m-8 mockup:h-auto mockup:min-h-[32rem] mockup:rounded-lg mockup:bg-white mockup:text-white mockup:shadow-xs mockup:scrollbar-thumb-fp-gray-250"
		>
			<component :is="mainComponent" v-if="isGlobalPanel" />
			<component :is="EditGroup" v-else-if="editPanel === EditPanels.Group" :elements="selectionCanvas" />
			<component :is="mainComponent" v-else :element="selectedElement" />
		</div>
	</div>
</template>
