import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { DomNodesBox } from '@/elements/element/dom/DomNodesBox';
import { DomNodesElement } from '@/elements/element/dom/DomNodesElement';
import { DomNodesMedia } from '@/elements/element/dom/DomNodesMedia';
import { DomNodesStoryset } from '@/elements/element/dom/DomNodesStoryset';
import { DomNodesText } from '@/elements/element/dom/DomNodesText';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
// Para sincronizar visualmente las transformaciones aplicadas a través del moveable se usan las siguientes clases:
// - 'sync-on-interaction', que aplica a todos los elementos que la tengan las mismas propiedades que al original
// - 'sync-text', que aplica a los textos que la tengan los ajustes necesiaros
// - 'sync-crop', que aplica a las imágenes y a los vídeos que la tengan los ajustes necesiaros
// - 'sync-text-inside-box', que aplica a los textos y las cajas (Box) que la tengan los ajustes necesiaros
export class DomNodes {
	static setElement(element: Element): DomNodesElement | DomNodesMedia | DomNodesText | DomNodesStoryset | DomNodesBox {
		if (element instanceof Image || element instanceof Video || element instanceof ForegroundImage) {
			return new DomNodesMedia(element);
		}
		if (element instanceof Box) {
			return new DomNodesBox(element);
		}
		if (element instanceof Storyset) {
			return new DomNodesStoryset(element);
		}
		if (element instanceof Text) {
			return new DomNodesText(element);
		}
		return new DomNodesElement(element);
	}
}
