<script setup lang="ts">
import { until } from '@vueuse/core';
import { computed, nextTick, onMounted, ref, toRef, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useCircleText } from '@/elements/texts/curved/composables/useCircleText';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTemplate from '@/elements/texts/text/components/TextTemplate.vue';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useText } from '@/elements/texts/text/composables/useText';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextHeight } from '@/elements/texts/text/composables/useTextHeight';
import { getTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useDoubleClick } from '@/interactions/composables/useDoubleClick';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import MathTools from '@/utils/classes/MathTools';

const props = defineProps<{
	element: Text;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
	previewPage?: Page;
}>();

const scale = toRef(props, 'scale');
const element = toRef(props, 'element');
const previewPage = toRef(props, 'previewPage');

const textStyles = getTextStyles(element, previewPage);
const text = ref();

const { isResizing } = useInteractions();
const { selectionId, clearSelection, setSelection } = useSelection();
const { isPinching } = useZoom();
const { textEditing } = useTextEditing();

useCircleText(element, text, scale, props.previewName);

const startEditing = () => {
	if (textEditingId.value) return;

	if (selectionId.value.length > 1) {
		// Tenemos que limpiar antes porque el setSelection no funciona si el elemento ya está seleccionado
		clearSelection();
		setSelection(props.element);
	}

	// Se inicia el modo edición de texto
	textEditing.value = props.element;

	resetFirstClick(textEditingId);
};

const { firstClick, resetFirstClick } = useDoubleClick(element, startEditing);

const textEditingId = computed(() => {
	return textEditing.value?.id;
});
const isOutlinedText = computed(() => TextTools.haveOutlinedText(textStyles.value));
const isTextShadowText = computed(() => element.value.textShadow.length > 0);
const originalTextStyles = computed(() => TextTools.getOriginalTextStyles(element.value, textStyles.value));
const outlinedTextStyles = computed(() => TextTools.getOutlinedTextStyles(element.value, textStyles.value));
const textShadowStylesToIOS = computed(() => TextTools.getTextShadowStylesToIOS(element.value, textStyles.value));
// Fix de sombras para descarga de PDF en IOS
// -webkit-filter: opacity(1)

const { isRenderingContext } = useEditorMode();
const { fitHeight } = useText(element);
const { isCircleText } = useCircleTypeInfo(element, scale);
const { textAI } = useAiWriter();

watch(isCircleText, async () => {
	if (textEditing.value || isCircleText.value || props.previewName) {
		return;
	}
	// Esperamos un tick para dar suficiente tiempo al dom a reemplazar el texto curvo
	await nextTick();
	fitHeight();
});

// Si se está pinchando en la pantalla para hacer zoom se desactiva el modo edición
watch(isPinching, async () => {
	if (!isPinching.value) {
		return;
	}

	firstClick.value = false;
});

onMounted(() => {
	if (!props.interactive) return;
	const { height } = useTextHeight(text.value.text);
	// Actualizamos la altura del texto por si tiene cambios de altura debido a los colores
	watch(height, async (_, oldValue) => {
		// Calculamos que el alto del Dom tiene el mismo alto que el texto más un margen de tolerancia
		// para evitar los desfases del cálculo
		const finalHeight = parseFloat(height.value.toFixed(1));
		const finalTextHeight = parseFloat(element.value.size.height.toFixed(1));

		// Si el elemento tiene el mismo height que el tenemos en el elemento, no hacemos nada
		const isSameHeight = MathTools.compareWithTolerance(finalHeight, finalTextHeight);

		// Si el valor anterior es 0, omitimos el fitHeight para evitar un sincronizado del historial
		if (!oldValue && element.value.size.height && isSameHeight) return;
		// Si tiene altura 0 o el texto original no está activo, omitimos el fitHeight
		if (!height.value || text.value.text.style.display === 'none') return;

		// Si se está redimensionando el texto, esperamos a que termine para ajustar la altura
		// ya que si no se hace así, el height del texto se modifica en el resizeHandler y en el fitHeight al mismo tiempo
		await until(() => isResizing.value).toBe(false);
		fitHeight();
	});
});
</script>

<template>
	<TextTemplate
		ref="text"
		:class="textAI?.id === element.id ? 'invisible' : ''"
		:element="props.element"
		:in-rendering-context="isRenderingContext"
		:text-styles="originalTextStyles"
		:is-presentation="Boolean(previewName)"
		:is-outlined-text="isOutlinedText"
		:preview-name="previewName"
		:is-text-shadow-text="isTextShadowText"
		:editing="textEditingId === element.id"
		:outlined-text-styles="outlinedTextStyles"
		:text-shadow-styles-ios="textShadowStylesToIOS"
		:is-preview="!!previewName"
		:preview-page="previewPage"
	/>
</template>
