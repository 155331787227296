<script lang="ts" setup>
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { sortBy } from 'lodash-es';
import { ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { useHistoryStore } from '@/history/stores/history';
import InteractiveCanvas from '@/interactions/components/InteractiveCanvas.vue';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';

const props = defineProps<{
	scrollArea: HTMLElement;
}>();

const store = useMainStore();
const project = useProjectStore();
const history = useHistoryStore();

const canvases = ref();
const determineActivePage = useDebounceFn(() => {
	const heightScroll = props.scrollArea.scrollHeight; //tamaño en el que la barra se puede mover.
	const currentScroll = props.scrollArea.scrollTop; //Posicion actual de la barra
	const scrollShowedInScreen = props.scrollArea.clientHeight;
	const orderedCanvas = sortBy(canvases.value, (canvas) => canvas.$el.offsetTop); // canvas
	const heightEachCanva = (heightScroll - scrollShowedInScreen) / orderedCanvas.length;

	for (let index = 0; index < orderedCanvas.length; index += 1) {
		if (currentScroll < heightEachCanva * (index + 1)) {
			store.setActivePage(orderedCanvas[index].$props.page);
			break;
		}
	}
}, 300);
useEventListener(props.scrollArea, 'scroll', determineActivePage, { passive: true });
</script>

<template>
	<InteractiveCanvas
		v-for="page in project.pages"
		ref="canvases"
		:key="page.id + history.key"
		:page="(page as Page)"
		:scroll-area="scrollArea"
	/>
</template>

<style scoped></style>
