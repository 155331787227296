<script lang="ts" setup>
import { promiseTimeout, useTimeoutFn } from '@vueuse/core';
import { computed, onMounted, ref, toRef, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { TimeTools } from '@/common/utils/TimeTools';
import { useViewer } from '@/editor/composables/useViewer';
import { useMainStore } from '@/editor/stores/store';
import { useBackgroundableElement } from '@/elements/element/composables/useBackgroundableElement';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import FilterOverlay from '@/elements/medias/filter/components/FilterOverlay.vue';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { Video } from '@/elements/medias/video/classes/Video';
import PlayerVideo from '@/elements/medias/video/components/PlayerVideo.vue';
import { useVideoControls } from '@/elements/medias/video/composables/useVideoControls';
import { useHistoryStore } from '@/history/stores/history';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { usePage } from '@/page/composables/usePage';
import { useProjectControls } from '@/project/composables/useProjectControls';

const props = defineProps<{
	element: Video;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
}>();
const element = toRef(props, 'element');

const store = useMainStore();
const { selection } = useSelection();

const history = useHistoryStore();

const { isCropping, isCroppingTime, isResizing, isDragging } = useInteractions();
const { isSafari } = useDeviceInfo();

const { isBackground } = useBackgroundableElement(element);

const hasSvgFilter = computed(() => element.value.filter && element.value.filter.toSvgFilter().length > 0);

const video = ref();
const {
	playing,
	currentTime,
	togglePlayVideo,
	currentCroppedTimePercentage,
	durationInMS,
	croppedDuration,
	formatedCroppedDurationTime,
	error,
	muted,
	registerUpdateVideoUrl,
} = useVideoControls(element, video);

registerUpdateVideoUrl();
/**
 * Reproductor de video del proyecto
 */
const isVisibleInViewer = ref(false);

const { page } = usePageElement(element);
const { position: positionPage } = usePage(page);
const { pagesDuration, onUpdateTimeManually, isVideoPlayer, isPlaying: isPlayingProyect } = useProjectControls();

if (props.previewName === 'viewer') {
	const { onViewerChange } = useViewer();

	isVisibleInViewer.value = !positionPage.value;
	// Vigilamos la página activa del viewer para saber si tenemos que reproducir/parar el video
	onViewerChange(async (pageViewerActive) => {
		if (pageViewerActive.id !== page.value.id) {
			isVisibleInViewer.value = false;

			if (playing.value) {
				togglePlayVideo();
				currentTime.value = 0;
			}
			return;
		}

		isVisibleInViewer.value = true;
		await promiseTimeout(100);
		const isNotInSameStateThatProjectPlayer =
			(isVideoPlayer.value && isPlayingProyect.value && !playing.value) || (!isPlayingProyect.value && playing.value);

		// Si su página es la visible y no estamos en modo video queremos comenzar a reproducirlo siempre
		// Si su página es la visible y el video no está en el mismo estado (play/pause) que el video proyecto lo reproducimos/paramos
		if (!isVideoPlayer.value || isNotInSameStateThatProjectPlayer) {
			togglePlayVideo();
		}
	});

	// Si el tiempo del proyecto se cambia manualmente(método) vamos a sincronizar el tiempo del video con el del proyecto
	onUpdateTimeManually((newTime) => {
		const prevPagesDuration = pagesDuration.value.slice(0, positionPage.value).reduce((acc, time) => acc + time, 0);
		const pageDuration = prevPagesDuration + page.value.duration();
		const videoTime = prevPagesDuration + croppedDuration.value;
		if (context.value === 'viewer' && videoTime <= pageDuration) {
			currentTime.value = TimeTools.toSeconds(element.value.cropTime.start + newTime - prevPagesDuration);
		}
	});
}

const onDoubleClickInitCrop = async (e: MouseEvent) => {
	if ((e.target as HTMLElement).tagName === 'BUTTON') return;

	store.croppingId = element.value.id;
};

const { photoModeImage } = usePhotoMode();
const wasPlayed = ref(false);
const isPhotoModeImage = computed(() => photoModeImage.value?.id === element.value.id);

const isSelected = computed(
	() => !isPhotoModeImage.value && !!selection.value.length && selection.value.some((e) => e.id === props.element.id)
);

const toggleVideo = () => {
	if (isDragging.value) return;
	wasPlayed.value = true;
	togglePlayVideo();
};

watch(
	[isResizing, isCropping],
	() => {
		if (playing.value) {
			togglePlayVideo();
		}
	},
	{ immediate: true }
);

const toggleMute = () => {
	element.value.muted = !element.value.muted;
};

watch(isSelected, (selected) => {
	if (selected) return;
	wasPlayed.value = false;
	if (playing.value) {
		togglePlayVideo();
	}
});

if (isSafari) {
	watch(isCroppingTime, () => {
		video.value.style.webkitTransform = 'scale(1)';
		video.value.style.transform = 'scale(1)';

		useTimeoutFn(() => video.value!.style.removeProperty('transform'), 200);
	});
}

const browserLoaded = ref(false);

const playOnInit = () => {
	// Para dar feedback al usuario de que la plantilla contiene un vídeo vamos a autoreproducirlo al cargar la plantilla
	// Como el componente se monta cada vez que nos movemos en el historial el evento de canplaythrough se dispara cuando nos movemos por este
	// por lo que solo vamos a hacer si tenemos un único estado en el historial (primera vez)
	// Además solo se reproducira el vídeo si es en la primera página
	// Hay que poner en mute el video ya que saltará una excepción si el usuario no ha hecho ninguna interacción con el DOM
	// e intentamos reproducir un video con sonido desde js
	if (props.interactive && history.states.length === 1 && positionPage.value === 0) {
		toggleVideo();
	}
};

const videoContainer = ref();
const context = ref('');

onMounted(() => {
	context.value = videoContainer.value.closest('[data-context]')?.getAttribute('data-context') || '';
});
</script>

<template>
	<div
		ref="videoContainer"
		:data-test-crop="store.croppingId"
		:style="{
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
			clipPath: 'inset(0)',
			opacity: element.opacity,
		}"
		class="original group"
	>
		<PlayerVideo
			v-if="!isCropping && !isCroppingTime && !props.previewName && isSelected"
			:element="element"
			:has-error="error"
			:playing="playing"
			:muted="muted"
			:was-played="wasPlayed"
			:percentage="currentCroppedTimePercentage"
			:is-background="isBackground"
			:duration="formatedCroppedDurationTime"
			@changeTime="currentTime = $event"
			@play="toggleVideo"
			@mute="toggleMute"
		/>
		<div
			class="original"
			:style="({
				width: `${element.size.width}px`,
				height: `${element.size.height}px`,
				clipPath: element.mask ? `url(#${element.id}-${context}-${element.mask.id})` : null,
				transform: `
        	scaleX(${element.flipHTML.x})
        	scaleY(${element.flipHTML.y})
				`
			} as any)"
		>
			<div
				class="cropped"
				:style="{
					width: `${element.crop.size.width || element.size.width}px`,
					height: `${element.crop.size.height || element.size.height}px`,
					transform: `translate(${element.crop.position.x}px,${element.crop.position.y}px)`,
				}"
			>
				<div v-if="!browserLoaded && !props.forSmallPreview" class="absolute inset-0 bg-slate-100">
					<div class="absolute inset-0 animate-pulse bg-gray-100"></div>
				</div>

				<img v-if="error" :src="'/svg/image-not-found.svg'" class="pointer-events-none absolute h-full w-full" />
				<video
					ref="video"
					:data-test-isbackground="isBackground"
					data-replaceable
					class="db-crop absolute h-full w-full"
					:class="{
						invisible: error,
						'playing-video': playing,
					}"
					preload="auto"
					playsinline
					disablepictureinpicture
					alt=""
					:style="{
						filter: hasSvgFilter ? `url(#filter-${element.id})` : undefined,
						transform: `
							scaleX(${element.flipHTML.x})
							scaleY(${element.flipHTML.y})
						`,
					}"
					@dragstart.prevent
					@dblclick="onDoubleClickInitCrop"
					@loadeddata="browserLoaded = true"
				/>

				<FilterOverlay v-if="element.filter?.overlay" :image="element" :overlay="element.filter.overlay" />

				<svg v-if="element.mask" width="0" height="0">
					<clipPath
						:id="`${element.id}-${context}-${element.mask.id}`"
						clipPathUnits="objectBoundingBox"
						v-html="element.mask.content"
					></clipPath>
				</svg>
				<svg v-if="hasSvgFilter" width="0" height="0">
					<defs>
						<filter :id="'filter-' + element.id" v-html="(element.filter as Filter).toSvgFilter()"></filter>
					</defs>
				</svg>
			</div>
		</div>
	</div>
</template>
