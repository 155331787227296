<script setup lang="ts">
import { sortByIndex } from '@tldraw/indices';
import { MaybeElement, MaybeElementRef } from '@vueuse/core';
import { computed, isRef, Ref, ref, toRef } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import Element from '@/elements/element/components/Element.vue';
import { useCropPhotoMode } from '@/elements/medias/crop/composables/useCropPhotoMode';
import Page from '@/page/classes/Page';
import { useLazyCanvas } from '@/page/composables/useLazyCanvas';
import useStreamRendering from '@/page/composables/useStreamRendering';

// Props
const props = withDefaults(
	defineProps<{
		page: Page;
		scrollArea?: MaybeElementRef;
		lazyRender?: boolean | number;
		fitTo?: 'width' | 'height';
		size: {
			width: number;
			height: number;
			canvasWidthScaled: number;
			canvasHeightScaled: number;
			scale: number;
		};
		interactive?: boolean;
		preview?: boolean;
		previewName?: string;
		disableRendering?: boolean;
	}>(),
	{
		fitTo: 'width',
		interactive: false,
	}
);

const { isRenderingContext } = useEditorMode();

// Template refs
const canvas = ref<HTMLDivElement | undefined>();
const page = toRef(props, 'page');
const size = computed(() => props.size);

// Controlamos la visibilidad del canvas para renderizarlo o no en función de si esta en pantalla
const canvasIsVisible = ref(!props.lazyRender);

if (props.lazyRender) {
	const scrollArea = toRef(props, 'scrollArea');
	const visibleTime = typeof props.lazyRender === 'number' ? (props.lazyRender as number) : 500;
	useLazyCanvas(page, canvasIsVisible, scrollArea as Ref<MaybeElement>, canvas, visibleTime);
}

const scaleWithoutSubpixels = computed(() => {
	return Math.floor(size.value.width * size.value.scale) / size.value.width;
});

const canvasStyle = computed(() => {
	const style: any = {
		width: `${size.value.canvasWidthScaled}px`,
		height: `${size.value.canvasHeightScaled}px`,
		'background-size': `${16 / size.value.scale}px`,
	};

	if (!isRenderingContext) {
		// Calculamos cuanto tiene que ocupar sin subpixels vs con subpixels y recortamos ese % del clip path
		const diffX =
			(Math.round(size.value.width * scaleWithoutSubpixels.value) / (size.value.width * size.value.scale)) * 100;
		const diffY =
			(Math.round(size.value.height * scaleWithoutSubpixels.value) / (size.value.height * size.value.scale)) * 100;

		style.clipPath = `polygon(0 0, ${diffX}% 0, ${diffX}% ${diffY}%, 0% ${diffY}%)`;
	} else {
		style.clipPath = `polygon(0 0, 100% 0, 100% 100%, 0% 100%)`;
	}

	return style;
});

const shouldRenderContents = computed(() => {
	if (isRenderingContext || (props.preview && !props.lazyRender)) {
		return true;
	}

	return canvasIsVisible.value && !props.disableRendering && page.value.name !== 'temporal';
});

const { isCropPhotoModeReady } = useCropPhotoMode();
// Margen extra para que el fondo ocupe más espacio fuera del canvas para que no pinte subpixels blancos en el borde del pdf
const extraWidth = !isRenderingContext ? 0 : 4;
const { elements, renderCompleted } = useStreamRendering(
	page,
	shouldRenderContents,
	props.interactive ? Number(props.lazyRender) : 30
);

const orderedElements = computed(() => {
	return [...(isRef(elements) ? elements.value : elements)].sort(sortByIndex);
});
</script>

<template>
	<div
		:id="!preview && page.id ? `canvas-${page.id}` : `canvas-${page.id}-preview`"
		ref="canvas"
		class="canvas relative w-full"
		:class="{
			'm-auto': preview,
			'bg-transparent-pattern': !isCropPhotoModeReady && !isRenderingContext,
			'canvas-rendering': !renderCompleted,
			'canvas-rendering-finished': renderCompleted,
		}"
		:style="canvasStyle"
		:data-canvas-visible="!preview && page.id && shouldRenderContents"
		:data-id="page.id"
	>
		<div
			class="relative"
			:style="
      {
        width: `${preview ? size.canvasWidthScaled :  size.width + extraWidth }px`,
        height: `${preview ? size.canvasHeightScaled : size.height}px`,
        background: isCropPhotoModeReady ? 'none' : (page.background as SolidColor | GradientColor).toCssString(),
        transformOrigin: '0 0',
        transform: `scale(${size.scale})`,
      }
    "
		>
			<div
				class="h-full w-full overflow-hidden"
				data-elements-container
				:class="{
					'contain-layout': !isCropPhotoModeReady && !isRenderingContext,
				}"
			>
				<template v-if="shouldRenderContents">
					<Element
						v-for="element in orderedElements"
						:key="element.id"
						:data-test-element-type="`${element.type}-${element.id}`"
						:element="element"
						:for-small-preview="preview"
						:preview-name="previewName"
						:scale="size.scale"
						:interactive="interactive"
						:page="(page as Page)"
					/>
				</template>
				<slot></slot>
				<slot v-if="!shouldRenderContents" name="noRender"></slot>
			</div>
		</div>
	</div>
</template>
<style>
.contain-layout {
	contain: layout paint;
}
</style>
