<script lang="ts" setup>
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import Element from '@/elements/element/classes/Element';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformGroupMenu from '@/elements/group/components/menus/TransformGroupMenu.vue';
import { useI18n } from '@/i18n/useI18n';
import GroupElementColorPicker from '@/layout/components/GroupElementColorPicker.vue';

const { closePanel } = usePanelManagement();
const { trans } = useI18n();

defineProps<{ elements: Element[] }>();
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">{{ trans('Edit Group') }}</h2>
		<button class="text-gray-100 focus:outline-none hover:text-white" data-testid="close-panel" @click="closePanel()">
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<PositionMenu :element="elements[0]" />
		<OrderMenu :element="elements[0]" />
		<TransformGroupMenu :elements="elements" />
		<GroupElementColorPicker :group="elements" />
	</div>
</template>
