import { v4 as uuidv4 } from 'uuid';

import Page from '@/page/classes/Page';
import { Size, TagsColorTemplate, Unit } from '@/Types/types';

class Project {
	id: string;
	name: string | null;
	category: string | null;
	flaticonSearch: string | null;
	pages: Page[];
	size: Size;
	unit: Unit;
	sourceVectorId: number;
	scale: number;
	colorTags: TagsColorTemplate;

	constructor(width: number, height: number, unit: Unit, sourceVectorId: number, scale: number) {
		this.pages = [];
		this.size = {
			width,
			height,
		};
		this.unit = unit;
		this.sourceVectorId = sourceVectorId;
		this.id = uuidv4();
		this.name = '';
		this.category = '';
		this.flaticonSearch = '';
		this.scale = scale || 1;
		this.colorTags = {
			primary: undefined,
			secondary: undefined,
		};
	}
}

export default Project;
