<script setup lang="ts">
import { computed, Ref } from 'vue';

import { useElementRenderStyles } from '@/elements/element/composables/useElementRenderStyles';
import { Text } from '@/elements/texts/text/classes/Text';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { getTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import TextTools from '@/elements/texts/text/utils/TextTools';
const { textAI, content } = useAiWriter();

const textStyles = getTextStyles(textAI as Ref<Text>);
const { styles } = useElementRenderStyles(textAI as Ref<Text>);

const outlinedTextStyles = computed(() =>
	TextTools.getOutlinedTextStyles(textAI.value || Text.create(), textStyles.value)
);
const isTextShadowText = computed(() => textStyles.value.textShadow?.length);
const isOutlinedText = computed(() => TextTools.haveOutlinedText(textStyles.value));
</script>

<template>
	<div class="notranslate absolute left-0 top-0 z-50" translate="no" :style="styles">
		<div
			v-if="isOutlinedText || isTextShadowText"
			ref="outlinedEditable"
			:style="outlinedTextStyles"
			class="ai-writer-outlined-text pointer-events-none absolute z-20"
			v-html="content"
		></div>
		<div
			:id="`ai-writer-${textAI ? textAI.id : undefined}`"
			:style="textStyles"
			class="ring-custom-write-ai user-select-all text-element-final ai-writer-excluded absolute z-10 w-full break-normal outline-none"
			v-html="content"
		></div>
	</div>
</template>
<style>
.ring-custom-write-ai {
	box-shadow: 0 0 0 var(--zoom-ring) #5f5ff6;
}
</style>
