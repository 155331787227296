<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { computed, ref } from 'vue';

import { getImages } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useProjectStore } from '@/project/stores/project';
import { ImageApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

// Props
const props = withDefaults(
	defineProps<{
		search: string;
		modal?: boolean;
	}>(),
	{
		search: '',
		modal: false,
	}
);

// Emits
const emit = defineEmits<{
	(e: 'select', value: ImageApi): void;
	(e: 'zeroResults', value: boolean): void;
}>();

// Using composables
const project = useProjectStore();

// Computeds
const search = computed(() => props.search || project.flaticonSearch || project.category || '');

// Data
const source = ref(`images/freepik?query=${search.value}`);
const { data: images, isFetching } = getImages(source, { refetch: true });

watchDebounced(
	images,
	(newVal) => {
		if (newVal?.data?.length === 0) {
			emit('zeroResults', true);
			return;
		}

		emit('zeroResults', false);
	},
	{ debounce: 500 }
);

// Watches
watchDebounced(
	search,
	(newVal) => {
		source.value = `images/freepik?query=${newVal}`;
	},
	{ debounce: 500 }
);

// Methods
const loadMore = () => {
	if (isFetching.value || !images.value?.links?.next) return;
	source.value = images.value.links.next;
};
</script>

<template>
	<InfiniteLoading
		:data="(images && images.data) || []"
		:is-fetching="isFetching"
		:modal="modal"
		class="flex flex-col"
		with-masonry
		:masonry-cols="2"
		@load="loadMore"
	>
		<template #item="{ item }">
			<InsertableElement
				:data="(item as ImageApi)"
				:type="Panels.photos"
				:draggable="!props.modal"
				class="mb-2"
				@click="emit('select', item as ImageApi)"
			>
				<ListMedia :image="(item as ImageApi)" />
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
