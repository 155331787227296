<script setup lang="ts">
import { MaybeElementRef } from '@vueuse/core';
import { ref, toRef } from 'vue';

import Page from '@/page/classes/Page';
import Canvas from '@/page/components/Canvas.vue';
import { useDebouncedPage } from '@/page/composables/useDebouncedPage';
import { useCanvasSizeContainer } from '@/project/composables/useCanvasSize';

// Props
const props = withDefaults(
	defineProps<{
		page: Page;
		lazyRender?: boolean | number;
		scrollArea?: MaybeElementRef;
		previewName: string;
		preview?: boolean;
	}>(),
	{
		preview: false,
	}
);

const page = toRef(props, 'page');
const container = ref();

const { canvasSize, scale } = useCanvasSizeContainer(container);
const { debouncedPage } = useDebouncedPage(page, 0, scale);
</script>

<template>
	<div ref="container" class="relative flex h-full w-full items-center justify-center" style="contain: strict">
		<Canvas
			v-if="canvasSize.width !== 0"
			:preview="preview"
			:lazy-render="lazyRender"
			:preview-name="previewName"
			:page="debouncedPage"
			:scroll-area="scrollArea"
			:size="canvasSize"
		/>
	</div>
</template>
