<script lang="ts" setup>
import { computed } from 'vue';

import { useOfflineMode } from '@/common/composables/useOfflineMode';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import PhotoModeImagePanel from '@/elements/medias/images/image/components/panels/PhotoModeImagePanel.vue';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import ProjectColorPicker from '@/layout/components/ProjectColorPicker.vue';
import { useProjectStore } from '@/project/stores/project';

const project = useProjectStore();
const store = useMainStore();
const { isPhotoMode } = useEditorMode();
const { photoModeImage } = usePhotoMode();
const { isOffline } = useOfflineMode();
const isPhotoModeMainActionsVisible = computed(
	() => isPhotoMode.value && !!project.pages.length && !!project.pages[0].elements.size && !!photoModeImage.value
);
// class="topbar-color-mobile flex h-12 items-center overflow-auto border-t border-gray-600 bg-gray-700 px-2"
</script>

<template>
	<div
		class="topbar-color-mobile absolute top-12 flex h-12 w-full items-center justify-start border-t border-gray-600 bg-gray-700 px-2"
		:class="{
			'pointer-events-none': isOffline,
			invisible: store.croppingTime,
		}"
	>
		<ProjectColorPicker v-if="!isPhotoMode" class="flex" />
		<PhotoModeImagePanel v-if="isPhotoModeMainActionsVisible" />
	</div>
</template>
