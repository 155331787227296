class StringTools {
	static getAllWordsCapitalized(str: string): string {
		return str.replaceAll('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
	}

	static getSize(str: string): number {
		return new TextEncoder().encode(str).length;
	}

	static getTransformedLink = (link: string) => {
		const hasHttps = link.startsWith('https://');
		const hasHttp = link.startsWith('http://');
		return hasHttps || hasHttp ? link : `https://${link}`;
	};
}

export default StringTools;
