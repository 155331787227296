<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useCustomImagesActions } from '@/api/composables/useUploadImagesActions';
import { useAuth } from '@/auth/composables/useAuth';
import AutocompleteInput from '@/common/components/AutocompleteInput.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import UploadsPanel from '@/elements/element/components/panels/add/UploadsPanel.vue';
import ProviderButtons from '@/elements/medias/images/image/components/menus/ProviderButtons.vue';
import FreepikImages from '@/elements/medias/images/image/components/panels/add/FreepikImages.vue';
import FreepikVideos from '@/elements/medias/images/image/components/panels/add/FreepikVideos.vue';
import PexelImages from '@/elements/medias/images/image/components/panels/add/PexelImages.vue';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { ImageApi, UploadApi, VideoApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

// Emtis
const emits = defineEmits<{
	(e: 'closeModal'): void;
	(e: 'selection', image: ImageApi | VideoApi | UploadApi): void;
}>();

// Props
const props = defineProps<{ disableClose?: boolean; modal?: boolean }>();
// Using composables
const store = useMainStore();
const project = useProjectStore();
const { isPhotoMode } = useEditorMode();
const { selection } = useSelection();
const { userUploads, selectFromLocal, isUploading, uploadImages, uploadImageInput, isInvalidFile } =
	useUserImageProvider();
const { runOnMobile } = useDeviceInfo();
const { addInsertableElement } = useAddInsertableElement();
const { trans } = useI18n();

// Data
const search = ref('');
const isVideo = computed(() => selection.value.length && selection.value[0].type === 'video');
const provider = ref(isVideo.value ? 'Videos' : 'Freepik');

// Computeds
const fixedSearch = computed(() => search.value || project.flaticonSearch || project.category || '');
const mimeTypesAccepted = computed(() => {
	const imageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
	const videoTypes = ['video/mp4, video/webm, video/quicktime, .mov, video/3gpp, .3gp'];

	if (isPhotoMode.value) {
		return props.modal ? imageTypes.join(', ') : `${imageTypes.join(', ')}, image/svg+xml`;
	}

	if (provider.value === 'Uploads') {
		return props.modal
			? [...imageTypes, ...videoTypes].join(', ')
			: `${[...imageTypes, ...videoTypes].join(', ')}, image/svg+xml`;
	}

	return props.modal ? imageTypes.join(', ') : `${imageTypes.join(', ')}, image/svg+xml`;
});

const PROVIDERS = isPhotoMode.value ? ['Freepik'] : ['Freepik', 'Pexels'];
// if (props.modal) PROVIDERS.push(...['Text to image', 'Uploads']);
if (props.modal && !isPhotoMode.value) PROVIDERS.push('Videos', 'Uploads');
if (props.modal && isPhotoMode.value) PROVIDERS.push('Uploads');

// Solo para provider Google Photos
// -------------------------------------------------------------------------------------------------
const { uploadImage } = useCustomImagesActions();
const toast = useToast();
const { onFetchResponse: onUploadResponse, onFetchError: onUploadError, data: imageApi } = uploadImage();

onUploadResponse(() => {
	userUploads.value.unshift(imageApi.value);
	toast.success(trans('Image uploaded sucessfully'));
});

onUploadError(() => {
	toast.error(trans('Something went wrong with the upload'));
});

const autocompleteSource = computed(() => `autocomplete/freepik?search=${fixedSearch.value}`);

// -------------------------------------------------------------------------------------------------

// Methods
const onCloseModal = () => {
	emits('closeModal');
};

const onZeroResults = (val: boolean) => {
	GAnalytics.trackGA4('search_editor', {
		category: 'Images',
		search_term: `${search.value}`,
		zero_results: `${val}`,
	});
};

const onSearch = (val: string) => {
	search.value = val;
};

const { isLogged, requireAuth } = useAuth();
const onSetProvider = (prov: string) => {
	if (prov === 'Uploads' && !isLogged.value) {
		requireAuth();
		return;
	}

	return (provider.value = prov);
};

/**
 * Dependiendo de si el componente esta siendo usado en modal, emitimos o insertamos la imagen
 * @param imageApi
 */
const upload = async ($event: Event) => {
	uploadImages($event, !props.modal);
	if (props.modal) {
		await until(isUploading).toBe(false);
		if (isInvalidFile.value) return;
		emits('selection', userUploads.value[0] as ImageApi);
	}
	store.activePanel = Panels.uploads;
};
const selectImage = async (mediaApi: ImageApi | VideoApi | UploadApi) => {
	if (props.modal) {
		emits('selection', mediaApi);
		return;
	}

	await addInsertableElement(mediaApi);

	runOnMobile(() => (store.activePanel = null));
};

const updateSearchValue = (searchValue: string) => {
	onSearch(searchValue);

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in ${provider.value} images: ${searchValue}`);
};
</script>

<template>
	<div id="photos-panel" class="flex h-full flex-col">
		<PanelHeader
			:disable-close="disableClose"
			:modal="modal"
			:title="modal ? 'Select media' : 'Photos'"
			@closeModal="onCloseModal"
		/>
		<input
			id="upload-img"
			ref="uploadImageInput"
			class="hidden"
			type="file"
			:accept="mimeTypesAccepted"
			multiple
			@change="upload"
		/>
		<button
			class="mb-3 flex h-12 shrink-0 items-center justify-center rounded border-blue-500 bg-blue-500 px-5 font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
			:class="{ 'cursor-not-allowed opacity-50': isUploading }"
			:disabled="isUploading"
			@click="selectFromLocal"
		>
			<span v-if="isUploading" class="relative flex h-full w-full items-center gap-4">
				<span class="absolute left-0 top-0 flex h-full items-center">
					<SvgIcon data-testid="spinner" name="spinner" class="h-5 w-5 animate-spin" />
				</span>
				<div class="flex-1 text-center leading-tight">
					{{ trans('Updating...') }}
				</div>
			</span>
			<span v-else class="relative flex h-full w-full items-center gap-4">
				<span class="absolute left-0 top-0 flex h-full items-center">
					<SvgIcon data-testid="upload-image" name="upload-image" class="h-4 w-4" />
				</span>
				<div id="upload-photo-button" class="flex-1 text-center leading-tight">
					{{ trans('Upload media') }}
				</div>
			</span>
		</button>
		<AutocompleteInput
			:placeholder="trans('Search media in ') + `${trans(provider)}...`"
			:query="search"
			:autocomplete-source="autocompleteSource"
			:modal="modal"
			@change="updateSearchValue"
		/>
		<ProviderButtons
			v-if="modal || !isPhotoMode"
			:modal="modal"
			:tabs="PROVIDERS"
			:selected-tab="provider"
			@set-tab="onSetProvider"
		/>
		<!-- Freepik images -->
		<KeepAlive>
			<FreepikImages
				v-if="provider === 'Freepik'"
				:search="search"
				:modal="modal"
				@select="selectImage"
				@search="onSearch"
				@zero-results="onZeroResults"
			/>
		</KeepAlive>
		<!-- Pexels -->
		<KeepAlive>
			<PexelImages
				v-if="provider === 'Pexels'"
				:search="search"
				:modal="modal"
				@select="selectImage"
				@search="onSearch"
				@zero-results="onZeroResults"
			/>
		</KeepAlive>
		<!-- Freepik Videos -->
		<KeepAlive>
			<FreepikVideos
				v-if="provider === 'Videos'"
				:search="search"
				:modal="modal"
				@select="selectImage"
				@search="onSearch"
				@zero-results="onZeroResults"
			/>
		</KeepAlive>
		<!-- Uploads -->
		<KeepAlive>
			<UploadsPanel v-if="provider === 'Uploads'" modal @close="onCloseModal" @select="selectImage" />
		</KeepAlive>

		<!-- <KeepAlive>
			<TextToImage	Panel
				v-if="provider === 'Text to image'"
				:search="search"
				modal
				@select="selectImage"
				@search="onSearch"
			/>
		</KeepAlive> -->
	</div>
</template>
