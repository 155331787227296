import { useEventListener } from '@vueuse/core';
import { computed, Ref, ref, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { useSelection } from '@/interactions/composables/useSelection';
import { Size } from '@/Types/types';

export type ImageOutput = {
	resized: string;
	original: string;
	originalSize: Size;
};

/**
 * This object will store a reference of generated images to avoid generating them again.
 */
const maskCache = new Map<string, string>();

/**
 * This hooks manages the rendering of a image, base on the device, image on screen size and loading factor.
 * @param image
 * @param domImage
 */
export const useImageRendering = (image: Ref<Image>, domImage: Ref<HTMLImageElement>, forSmallPreview = false) => {
	const { isMobile, isIOS } = useDeviceInfo();
	const { isRenderingContext } = useEditorMode();
	const store = useMainStore();
	const { selection, selectionId } = useSelection();

	const loaded = ref(false);
	const url = ref<string | null>(null);
	const resized = ref<string | null>(null);
	const maskedImage = ref<string | null>(null);
	let maskInProcess = false;

	// The url that wants to be shown at the page.
	// Usually the url of the image, but can be the version without the background.
	// Also we use the raw image in the render process to better quality download.
	const finalTargetUrl = computed(() => {
		if (image.value.backgroundMode === 'background') {
			return maskedImage.value;
		}

		if (isRenderingContext) {
			return image.value.metadata?.raw || image.value.url || url.value;
		}

		if (forSmallPreview && image.value.preview) {
			return image.value.preview;
		}

		return image.value.url;
	});

	// The max size of the image that can be shown at the page. If the image is bigger, it will be resized.
	const targetSize = computed(() => {
		return {
			width: isMobile.value ? 1250 : 1500,
			height: isMobile.value ? 1250 : 1500,
		};
	});

	// Starts the loading of the image
	const load = () => {
		//
		watch(
			() => image.value.backgroundMode,
			async () => {
				if (
					image.value.backgroundMode === 'original' ||
					maskedImage.value ||
					maskInProcess ||
					!image.value.urlBackgroundRemoved
				) {
					return;
				}

				maskInProcess = true;

				if (!forSmallPreview) {
					store.finishedLoading = false;
				}

				store.proccesingCachedElements++;
				try {
					maskedImage.value = await maskImageCached(image.value.url, image.value.urlBackgroundRemoved);
				} catch (e) {
				} finally {
					// Si por lo que sea falla la carga de la imagen, no queremos bloquear el proceso de renderizado
					store.proccesingCachedElements--;
					store.finishedLoading = true;
					maskInProcess = false;
				}
			},
			{ immediate: true }
		);

		// If something went wrong while loading the image, we replace it with a broken one
		// Maybe we should return a broken status instead and do not change the url.
		useEventListener(domImage, 'error', () => {
			if (!image.value) return;

			url.value = `/svg/image-not-found.svg`;
			loaded.value = true;
			resized.value = null;

			if (selection.value && selection.value[0] === image.value) {
				selectionId.value = [];
			}
		});

		// If the image reference changes, we reset the derived images
		watch(
			() => image.value.url,
			() => {
				maskedImage.value = null;
				loaded.value = false;
			}
		);

		// If the image target changes (maybe because we want to show the background removed version), we will want to restart this process
		// In case we're showing a svg, a blob or are in rendering context, the url to be used its just that one.
		// In any other case, we'll check if the image is larger than the target size and if so, we'll resize it and use it
		watch(
			finalTargetUrl,
			async () => {
				if (!finalTargetUrl.value) {
					return;
				}

				if (
					isRenderingContext ||
					forSmallPreview ||
					finalTargetUrl.value.startsWith('blob:') ||
					finalTargetUrl.value.includes('.svg') ||
					maskedImage.value === finalTargetUrl.value ||
					image.value.backgroundMode !== 'original'
				) {
					url.value = finalTargetUrl.value;
					loaded.value = true;
					resized.value = null;
					return;
				}

				url.value = finalTargetUrl.value;
				loaded.value = true;
			},
			{ immediate: true }
		);
	};

	const loadWithRetry = (url: string, retries: number, initial = true) => {
		if (initial) store.proccesingCachedElements++;

		const img = document.createElement('img');
		img.src = url;
		img.onload = () => {
			load();
			store.proccesingCachedElements--;
		};

		img.onerror = () => {
			if (retries > 0) {
				setTimeout(() => {
					loadWithRetry(url, retries - 1, false);
				}, 100);
			} else {
				load();
				store.proccesingCachedElements--;
			}
		};
	};

	// Computes the real size of the image (visual area)
	const imageSize = computed(() => ({
		width: image.value.crop.size.width || image.value.size.width,
		height: image.value.crop.size.height || image.value.size.height,
	}));

	return {
		loaded,
		imageUrl: url,
		imageSize,
		load,
		loadWithRetry,
		maskedImage,
		maskCache,
	};
};

const maskImageCached = async (url: string, urlBackgroundRemoved: string): Promise<string> => {
	if (maskCache.has(url)) {
		return Promise.resolve(maskCache.get(url) as string);
	}

	const maskedUrl = await ImageTools.maskImage(url, urlBackgroundRemoved);

	maskCache.set(url, maskedUrl);

	return maskedUrl;
};
