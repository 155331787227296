<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { InputHTMLAttributes, nextTick, onMounted, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { GradientColor } from '@/color/classes/GradientColor';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { EditPanels } from '@/Types/types';
const { t: trans } = useI18n();

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');
const { isMobile } = useDeviceInfo();
const { percentageArc, getArcToApply } = useCircleTypeInfo(element);
const { fontSize, updateFontSize } = useTextStyles(element);
const store = useMainStore();
const { bugsnagMsgWithDebounce } = useBugsnag();
const { exitTextEditingIfPreviouslyActive } = useTextEditing();

const { closePanel } = usePanelManagement();

onMounted(() => {
	if (element.value.curvedProperties.minArc) {
		return;
	}

	element.value.colors.forEach((color) => {
		const rootNode = element.value.domNode()?.querySelector<HTMLElement>('.text-element-final');
		const childNodes = rootNode?.querySelectorAll<HTMLElement>('*');
		const nodes = childNodes?.length ? [rootNode, ...Array.from(childNodes)] : [rootNode];

		if (color instanceof GradientColor) {
			color.type = 'linear';
			color.rotation = 0;

			nodes.forEach((n) => {
				if (n) {
					const index = Object.values(n.style).findIndex((style) => style === `--${color.id}`);
					if (index >= 0) {
						n.style.removeProperty(`--${color}`);
						n.style.setProperty(`--${color.id}`, color.toCssString());

						const style = n.getAttribute('style');
						n.setAttribute('style', `${style} -webkit-background-clip: text;`);
					}
				}
			});
		}
	});

	handleCurve(0);
	Bugsnag.leaveBreadcrumb('Curved text is activated');
});

const handleCurve = async (e: Event | number) => {
	let percentage = e instanceof Event ? parseInt((e.target as InputHTMLAttributes).value) : e;

	exitTextEditingIfPreviouslyActive();
	await nextTick();
	if (fontSize.value.length > 1) {
		updateFontSize(element.value.fontSize);
	}
	props.element.curvedProperties.arc = getArcToApply(percentage);
	bugsnagMsgWithDebounce('Modify curved text values');
};

const onReset = () => {
	store.editPanel = EditPanels.Text;

	element.value.curvedProperties = {
		minArc: 0,
		arc: null,
		transformCurve: 0,
	};
};
</script>

<template>
	<div
		ref="textShadowContainer"
		:class="{
			'fixed bottom-16 left-0 z-10 w-full rounded-tl-2xl rounded-tr-2xl bg-gray-700 px-4 pb-4 pt-6 text-left': isMobile,
		}"
		data-testid="text-curve-panel"
	>
		<div v-if="isMobile" class="-mt-3 mb-4 flex justify-between">
			<div class="flex items-center">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Text curved') }}</h2>
				<button class="flex items-center text-xs text-gray-100 hover:text-white" @click="onReset">
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					{{ trans('Reset') }}
				</button>
			</div>
			<button
				data-testid="close-panel"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="closePanel(element)"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>
		<div class="flex items-center gap-2 sm:pt-4">
			<SvgIcon name="rotation" class="h-3 w-3 -rotate-90 text-gray-100 mockup:text-fp-gray-700" />
			<div class="overflow relative flex h-4 w-full flex-1 items-center">
				<input
					:value="percentageArc"
					class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200"
					:max="100"
					:min="-100"
					:step="0.1"
					type="range"
					@input="handleCurve"
				/>
				<span
					class="pointer-events-none absolute left-0 top-[7px] h-[4px] origin-top-left scale-50 rounded-full bg-blue-500 slidesgo:bg-purple-400"
					:style="{
						width: `${percentageArc + 100}%`,
					}"
				/>
			</div>
			<SvgIcon name="rotation" class="h-3 w-3 rotate-90 text-gray-100 mockup:text-fp-gray-700" />
		</div>
	</div>
</template>

<style scoped></style>
