import { createSharedComposable, watchDebounced } from '@vueuse/core';
import { cloneDeep, groupBy } from 'lodash-es';
import { computed, Ref, ref } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useBoxColors } from '@/color/composables/useBoxColors';
import { useShapeColors } from '@/color/composables/useShapeColors';
import { useTextColors } from '@/color/composables/useTextColors';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { Box } from '@/elements/box/classes/Box';
import ElementTools from '@/elements/element/utils/ElementTools';
import Line from '@/elements/line/classes/Line';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';

const colorSelected = ref<Color>();
const colorsIdFromSelected = ref<string[]>([]);
const { bugsnagMsgWithDebounce } = useBugsnag();

export const useProjectColors = createSharedComposable(() => {
	const elementsWithColor = ref<(Element | Page)[]>([]);
	const temporalShape = ref<Shape>(Shape.create());
	const temporalBox = ref<Box>(Box.create());
	const temporalText = ref<Text>(Text.create());

	const project = useProjectStore();
	const { isPhotoMode } = useEditorMode();

	const { updateColor, colors } = useShapeColors(temporalShape as Ref<Shape>);
	const { colors: boxesColors, updateColor: updateBoxesColors } = useBoxColors(temporalBox);
	const {
		updateColor: updateTextColor,
		updateOutlineColor,
		updateTextShadowColor,
		colors: textColors,
	} = useTextColors(temporalText as Ref<Text>);

	// Texts have main-color(s), border-color and shadow-color*
	const textsColors = computed(() => {
		return project.allTexts
			.map((text) => {
				temporalText.value = text;
				const all = [textColors.value];
				if (temporalText.value.outline.width) all.push([temporalText.value.outline.color]);
				if (temporalText.value.textShadow.length === 1) all.push([temporalText.value.textShadow[0].color]);
				return all;
			})
			.flat(2);
	});

	const linesStrokeColors = computed(() => {
		return project.allLines.map((line) => line.mainColor);
	});

	const qrColors = computed(() => project.allQRCodes.map((qrcode) => [qrcode.frontColor, qrcode.bgColor]).flat());

	const getColors = (repeated = false, alpha = false): Color[] => {
		const shapesColors = project.allShapes.map((e) => e.colors).flat();
		const textsColors = project.allTexts
			.map((e) => e.colors)
			.concat(project.allTexts.filter((e) => e.outline.width > 0).map((e) => e.outline.color))

			.concat(
				project.allTexts
					.filter((e) => e.textShadow.length === 1)
					.filter((e) => e.textShadow.some((ts) => ts.color.toRgb() !== SolidColor.black().toRgb()))
					.map((e) => e.textShadow.map((ts) => ts.color))
			)
			.flat();

		const boxesColors = project.allBoxes.map((e) => e.colors).flat();
		const linesColors = project.allLines.map((e) => e.colors).flat();
		const qrcodesColors = project.allQRCodes.map((e) => e.colors).flat();
		const storysetsColors = project.allStorysets.map((e) => e.colors).flat();

		const backgroundColors = isPhotoMode.value ? [] : project.pages.map((e) => e.background || []).flat();
		const elementsColors = [
			...shapesColors,
			...textsColors,
			...boxesColors,
			...linesColors,
			...qrcodesColors,
			...storysetsColors,
		];

		let colors = [...elementsColors, ...backgroundColors];

		if (!repeated) {
			colors = colors.filter(
				(color, index, colorList) =>
					index === colorList.findIndex((c) => c.toCssStringWithoutAlpha() === color.toCssStringWithoutAlpha())
			);
		}

		if (!alpha) {
			colors = colors.map((c) => c.withoutAlpha());
		}
		return colors as Color[];
	};

	const palette = computed(() => {
		const colorElements = projectColorsWithRepetitions.value;
		const globalColors = groupBy(colorElements, (color) => color.toCssString());

		return Object.keys(globalColors).map((key) => ({
			color: globalColors[key][0].toObject(),
			ids: globalColors[key].map((color) => color.id),
		}));
	});
	const projectColors = ref<Color[]>(getColors() || []);
	const projectColorsWithRepetitions = ref<Color[]>(getColors(true) || []);

	watchDebounced(
		[
			() => project.pages.map((page) => page.background),
			() => project.allElements.map((el) => 'colors' in el && el.colors).filter(Boolean),
			() => project.allTexts.map((text) => text.outline).filter(Boolean),
			() => project.allTexts.map((text) => text.textShadow).filter(Boolean),
			() => project.allElements.length,
		],
		() => {
			projectColors.value = getColors();
			projectColorsWithRepetitions.value = getColors(true);
		},
		{ deep: true, debounce: 1000 }
	);
	const getElementsWithColorSelected = () => {
		if (!colorSelected.value) {
			elementsWithColor.value = [];
			colorsIdFromSelected.value = [];
			return;
		}

		const colorsId: string[] = [];

		const shapes = project.allShapes.filter((shape) => {
			temporalShape.value = shape;

			const colorIsSelected = colors.value.filter(
				(c) => c.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha()
			);

			colorsId.push(...colorIsSelected.map((c) => c.id));

			return colorIsSelected.length > 0;
		});
		const boxes = project.allBoxes.filter((box) => {
			temporalBox.value = box;

			const colorIsSelected = boxesColors.value.filter(
				(color) => color.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha()
			);

			colorsId.push(...colorIsSelected.map((color) => color.id));

			return colorIsSelected.length > 0;
		});

		const storysets = project.allStorysets.filter((storyset) => {
			const colorIsSelected =
				storyset.mainColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha();

			if (colorIsSelected) {
				colorsId.push(storyset.mainColor.id);
			}

			return colorIsSelected;
		});

		const qrcodes = project.allQRCodes.filter((qrcode) => {
			const frontColorSelected =
				qrcode.frontColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha();
			const bgColorSelected =
				qrcode.bgColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha();

			if (frontColorSelected) colorsId.push(qrcode.frontColor.id);
			if (bgColorSelected) colorsId.push(qrcode.bgColor.id);

			return frontColorSelected || bgColorSelected;
		});

		const texts = project.allTexts.filter((text) => {
			temporalText.value = text;
			const all = [textColors.value];

			if (temporalText.value.outline.width) all.push([temporalText.value.outline.color]);
			if (temporalText.value.textShadow.length === 1) all.push([temporalText.value.textShadow[0].color]);
			const colorIsSelected = all.flat(2).filter((c) => {
				const colorString =
					c instanceof GradientColor ? c.toCssStringWithoutRotation() : (c as SolidColor).toCssStringWithoutAlpha();
				const colorSelectedString =
					colorSelected.value instanceof GradientColor
						? colorSelected.value.toCssStringWithoutRotation()
						: (colorSelected.value as SolidColor).toCssStringWithoutAlpha();

				return colorString === colorSelectedString;
			});
			colorsId.push(...colorIsSelected.map((c) => c.id));

			return colorIsSelected.length > 0;
		});
		const lines = project.allLines.filter((line) => {
			const mainColorSelected =
				line.mainColor.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha();
			const markerStartColorSelected =
				line.markerStart &&
				line.markerStart.color.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha();
			const markerEndColorSelected =
				line.markerEnd &&
				line.markerEnd.color.toCssStringWithoutAlpha() === (colorSelected.value as Color).toCssStringWithoutAlpha();

			if (mainColorSelected) colorsId.push(line.mainColor.id);
			if (markerStartColorSelected && line.markerStart) colorsId.push(line.markerStart.color.id);
			if (markerEndColorSelected && line.markerEnd) colorsId.push(line.markerEnd.color.id);

			return mainColorSelected || markerStartColorSelected || markerEndColorSelected;
		});

		const pages = project.pages.filter((page) => {
			const colorIsSelected =
				(page.background as Color).toCssStringWithoutAlpha() ===
				(colorSelected.value as Color).toCssStringWithoutAlpha();

			if (colorIsSelected) colorsId.push((page.background as Color).id);

			return colorIsSelected;
		});
		elementsWithColor.value = [...shapes, ...boxes, ...storysets, ...qrcodes, ...texts, ...lines, ...pages] as
			| Element[]
			| Page[];
		colorsIdFromSelected.value = colorsId;
	};

	const updatePageBgColor = (newColor: Color) => {
		const pages = elementsWithColor.value.filter((e) => e instanceof Page) as Page[];

		pages.forEach((page) => {
			page.updateBackgroundColor(ElementTools.getFixedColor(page.background, newColor));
		});
	};

	const updatePageBgColorById = (id: string, newColor: Color, page?: Page) => {
		const pages = page ? [page] : project.pages;

		pages.forEach((page) => {
			const color = page.background as Color;

			if (color.id === id && color.validForVariant) {
				page.updateBackgroundColor(ElementTools.getFixedColor(color, newColor));
			}
		});
	};

	const updateQRCodeColor = (oldColor: Color, newColor: Color) => {
		const qrcodes = elementsWithColor.value.filter((e) => e instanceof QRCode);

		qrcodes.forEach((qrcode: any) => {
			if (qrcode.frontColor.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				const fixedColor = ElementTools.getFixedColor(oldColor, newColor);
				qrcode.updateFrontColor(fixedColor);
			}

			if (qrcode.bgColor.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				const fixedColor = ElementTools.getFixedColor(oldColor, newColor);
				qrcode.updateBgColor(fixedColor);
			}
		});
	};

	const updateQRCodeColorById = (id: string, newColor: Color, qrCodes?: QRCode[]) => {
		const allQRCodes = qrCodes || project.allQRCodes;

		allQRCodes.forEach((qrcode: any) => {
			if (qrcode.frontColor.id === id && qrcode.frontColor.validForVariant) {
				const fixedColor = ElementTools.getFixedColor(qrcode.frontColor, newColor);
				qrcode.updateFrontColor(fixedColor);
			}

			if (qrcode.bgColor.id === id && qrcode.bgColor.validForVariant) {
				const fixedColor = ElementTools.getFixedColor(qrcode.bgColor, newColor);
				qrcode.updateBgColor(fixedColor);
			}
		});
	};

	const updateLinesColor = (oldColor: Color, newColor: Color) => {
		const lines = elementsWithColor.value.filter((e) => e instanceof Line);

		lines.forEach((line: any) => {
			if (line.mainColor.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				line.updateStrokeColor(ElementTools.getFixedColor(line.mainColor, newColor));
			}

			if (line.markerStart && line.markerStart.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				line.updateMarkerStartColor(ElementTools.getFixedColor(line.markerStart.color, newColor));
			}

			if (line.markerEnd && line.markerEnd.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()) {
				line.updateMarkerEndColor(ElementTools.getFixedColor(line.markerEnd.color, newColor));
			}
		});
	};

	const updateLinesColorById = (id: string, newColor: Color, lines?: Line[]) => {
		const allLines = lines || project.allLines;

		allLines.forEach((line: any) => {
			if (line.mainColor.id === id && line.mainColor.validForVariant) {
				line.updateStrokeColor(ElementTools.getFixedColor(line.mainColor, newColor));
			}

			if (line.markerStart && line.markerStart.color.id === id && line.markerStart.color.validForVariant) {
				line.updateMarkerStartColor(ElementTools.getFixedColor(line.markerStart.color, newColor));
			}

			if (line.markerEnd && line.markerEnd.color.id === id && line.markerEnd.color.validForVariant) {
				line.updateMarkerEndColor(ElementTools.getFixedColor(line.markerEnd.color, newColor));
			}
		});
	};

	const updateShapesColor = (oldColor: Color, newColor: Color) => {
		const shapes = elementsWithColor.value.filter((e) => e instanceof Shape);
		shapes.forEach((shape: any) => {
			temporalShape.value = shape;
			colors.value
				.filter((c) => c.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha() || c.id === oldColor.id)
				.forEach((c) => {
					updateColor(c, ElementTools.getFixedColor(c, newColor));
				});
		});
	};

	const updateBoxesColor = (oldColor: Color, newColor: Color) => {
		const boxes = elementsWithColor.value.filter((e) => e instanceof Box);
		boxes.forEach((box: any) => {
			temporalBox.value = box;
			boxesColors.value
				.filter(
					(color) => color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha() || color.id === oldColor.id
				)
				.forEach((color) => {
					updateBoxesColors(color, ElementTools.getFixedColor(color, newColor));
				});
		});
	};

	const updateShapesColorById = (id: string, newColor: Color, shapes?: Shape[]) => {
		const allShapes = shapes || project.allShapes;

		allShapes.forEach((shape: any) => {
			temporalShape.value = shape;
			colors.value
				.filter((c) => c.id === id && c.validForVariant)
				.forEach((c) => {
					updateColor(c, ElementTools.getFixedColor(c, newColor));
				});
		});
	};

	const updateBoxesColorById = (id: string, newColor: Color, boxes?: Box[]) => {
		const allBoxes = boxes || project.allBoxes;
		allBoxes.forEach((box: any) => {
			temporalBox.value = box;
			boxesColors.value
				.filter((color) => color.id === id && color.validForVariant)
				.forEach((color) => {
					updateBoxesColors(color, ElementTools.getFixedColor(color, newColor));
				});
		});
	};

	const updateStorysetsColor = (oldColor: Color, newColor: Color) => {
		const storysets = elementsWithColor.value.filter((e) => e instanceof Storyset);
		storysets.forEach((storyset: any) => storyset.updateColor(ElementTools.getFixedColor(oldColor, newColor)));
	};

	const updateStorysetsColorById = (id: string, newColor: Color, storysets?: Storyset[]) => {
		const allStorysets = storysets || project.allStorysets;

		allStorysets.forEach((storyset: any) => {
			if (storyset.mainColor.id === id && storyset.mainColor.validForVariant) {
				storyset.updateColor(ElementTools.getFixedColor(storyset.mainColor, newColor));
			}
		});
	};

	const updateTextsColor = (oldColor: Color, newColor: Color) => {
		const texts = elementsWithColor.value.filter((e) => e instanceof Text);
		texts.forEach((text: any) => {
			temporalText.value = text;
			const colorsToChange = textColors.value.filter((c) => {
				return c.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha();
			});
			colorsToChange.forEach((c) => {
				const fixedColor = ElementTools.getFixedColor(c, newColor);
				updateTextColor(c, fixedColor);
			});

			if (
				temporalText.value.outline.width &&
				temporalText.value.outline.color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()
			) {
				updateOutlineColor(
					temporalText.value.outline.color,
					ElementTools.getFixedColor(temporalText.value.outline.color, newColor)
				);
			}

			if (
				temporalText.value.textShadow.length &&
				temporalText.value.textShadow[0].color.toCssStringWithoutAlpha() === oldColor.toCssStringWithoutAlpha()
			) {
				updateTextShadowColor(
					temporalText.value.textShadow[0].color,
					ElementTools.getFixedColor(temporalText.value.textShadow[0].color, newColor)
				);
			}
		});
	};

	const updateTextsColorById = (id: string, newColor: Color, texts?: Text[]) => {
		const allTexts = texts || project.allTexts;

		allTexts.forEach((text: any) => {
			temporalText.value = text;

			const colorsToChange = textColors.value.filter((c) => c.id === id && c.validForVariant);

			colorsToChange.forEach((c) => {
				const fixedColor = ElementTools.getFixedColor(c, newColor);
				updateTextColor(c, fixedColor);
			});

			if (
				temporalText.value.outline &&
				temporalText.value.outline.color.id === id &&
				temporalText.value.outline.color.validForVariant
			) {
				updateOutlineColor(
					temporalText.value.outline.color,
					ElementTools.getFixedColor(temporalText.value.outline.color, newColor)
				);
			}

			if (
				temporalText.value.textShadow.length &&
				temporalText.value.textShadow[0].color.id === id &&
				temporalText.value.textShadow[0].color.validForVariant
			) {
				updateTextShadowColor(
					temporalText.value.textShadow[0].color,
					ElementTools.getFixedColor(temporalText.value.textShadow[0].color, newColor)
				);
			}
		});
	};

	const updateTemplateColor = (oldColor: Color, newColor: Color) => {
		project.$patch(() => {
			const clone = cloneDeep(oldColor);

			updatePageBgColor(newColor);
			updateQRCodeColor(clone, newColor);
			updateLinesColor(clone, newColor);
			updateShapesColor(clone, newColor);
			updateBoxesColor(clone, newColor);
			updateStorysetsColor(clone, newColor);
			updateTextsColor(clone, newColor);
			// Actualizamos el color completo o parcial según tipo para evitar que se fusionen colores globales
			if (colorSelected.value instanceof SolidColor && newColor instanceof SolidColor) {
				colorSelected.value.r = newColor.r;
				colorSelected.value.g = newColor.g;
				colorSelected.value.b = newColor.b;
				colorSelected.value.a = newColor.a;
				return;
			}

			colorSelected.value = newColor;
			bugsnagMsgWithDebounce(`Update template colors`);
		});
	};

	const updateTemplateColorById = (id: string, newColor: Color) => {
		project.$patch(() => {
			updatePageBgColorById(id, newColor);
			updateQRCodeColorById(id, newColor);
			updateLinesColorById(id, newColor);
			updateShapesColorById(id, newColor);
			updateBoxesColorById(id, newColor);
			updateStorysetsColorById(id, newColor);
			updateTextsColorById(id, newColor);

			bugsnagMsgWithDebounce(`Template variant applied`);
		});
	};

	const hasQrColor = (color: Color): boolean => {
		return qrColors.value.some((c) => c.toCssString() === color.toCssString());
	};

	const hasOutlineColor = (color: Color): boolean => {
		return project.allTexts.some(
			(text) => text.outline.color.toCssStringWithoutAlpha() === color.toCssStringWithoutAlpha() && text.outline.width
		);
	};

	const hasTextShadowColor = (color: Color): boolean => {
		return project.allTexts.some(
			(text) =>
				text.textShadow.length &&
				text.textShadow[0].color.toCssStringWithoutAlpha() === color.toCssStringWithoutAlpha() &&
				text.textShadow.length &&
				text.textShadow[0].color !== SolidColor.black()
		);
	};

	const hasCurvedTextColor = (color: Color): boolean | undefined => {
		if (!project.allTexts.length) return;
		const curvedTexts = project.allTexts.filter((text) => text.curvedProperties.arc);

		if (!curvedTexts.length) return;
		return curvedTexts.some((text) => text.colors.some((c) => c.toCssString() === color.toCssString()));
	};

	return {
		colorsIdFromSelected,
		colors: projectColors,
		palette,
		updateTemplateColor,
		updateTemplateColorById,
		getElementsWithColorSelected,
		colorSelected,
		hasQrColor,
		hasCurvedTextColor,
		hasOutlineColor,
		hasTextShadowColor,
		textsColors,
		linesStrokeColors,
		updatePageBgColorById,
		updateQRCodeColorById,
		getColors,
		updateLinesColorById,
		updateShapesColorById,
		updateBoxesColorById,
		updateStorysetsColorById,
		updateTextsColorById,
	};
});
