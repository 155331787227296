import Bugsnag from '@bugsnag/js';
import { Ref } from 'vue';

import Element from '@/elements/element/classes/Element';

export const useElementLock = (element: Ref<Element>) => {
	const toggleLockElement = () => {
		element.value.locked = !element.value.locked;

		Bugsnag.leaveBreadcrumb(`Lock element-${element.value.id}`);
	};

	return {
		toggleLockElement,
	};
};
