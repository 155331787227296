<script setup lang="ts">
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';
const { togglePanel } = usePanelManagement();

const { trans } = useI18n();

const props = defineProps<{
	element: Image | Video;
}>();

const onClickEditMask = () => {
	GAnalytics.trackDebounceEditBarGA4({
		category: props.element instanceof Video ? 'video' : 'photo',
		link_text: 'masks',
	});

	togglePanel(EditPanels.Mask, props.element);
};
</script>

<template>
	<div>
		<button
			:tooltip="trans('Mask')"
			tooltip-position="top"
			data-button-importance="normal"
			data-testid="mask"
			class="relative flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-full lg:flex-row lg:justify-center lg:px-2"
			:class="element.mask ? 'text-white' : ''"
			@click="onClickEditMask"
		>
			<span
				v-if="element.mask"
				class="absolute -top-0.5 right-4 z-10 h-2 w-2 rounded-full border border-blue-300 bg-blue-500 slidesgo:bg-purple-400 lg:left-[1.5rem] lg:top-2"
			></span>
			<SvgIcon name="mask" class="h-4 w-4 scale-110 lg:h-5 lg:w-5 lg:scale-100" />
			<span class="text-2xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{ trans('Masks') }}</span>
		</button>
	</div>
</template>
