<script setup lang="ts">
import { onClickOutside, useClipboard } from '@vueuse/core';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import ShareSendEmail from '@/export/share/components/ShareSendEmail.vue';
import { useI18n } from '@/i18n/useI18n';
import { Lang } from '@/Types/types';
const { trans, locale, routes } = useI18n();
const store = useMainStore();

const props = defineProps<{ uuid?: string }>();
const emit = defineEmits<{ (e: 'close'): void; (e: 'goback'): void }>();
const isViewer = computed(() => location.href.includes('/share/'));

const { APP_BASE } = useEnvSettings();
const urlShare = computed(() => {
	return `${APP_BASE}share/${isViewer.value ? window.renderData.uuid : props.uuid || store.userVector?.uuid}`;
});
const { copy, copied } = useClipboard();
const toast = useToast();

const modal = ref();
const title = "Take a look at this awesome template I've made on Wepik: ";

onClickOutside(modal, () => emit('close'));

const shareOnWhatsapp = () => {
	const url = `https://wa.me/?text=${title} ${encodeURIComponent(urlShare.value)}%23rs=whatsapp`;
	GAnalytics.track('click', 'Button', 'share-whatsapp', null);
	GAnalytics.trackGA4('send_link', { link_text: 'whatsapp' });

	window.open(url, '_blank');
};
const shareOnFacebook = () => {
	const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlShare.value)}%23rs=facebook`;

	GAnalytics.track('click', 'Button', 'share-facebook', null);
	GAnalytics.trackGA4('send_link', { link_text: 'facebook' });
	window.open(url, 'pagename', `resizable, height=600,width=500, top=200, left=600`);
};
const shareOnTwitter = () => {
	const url = `https://twitter.com/intent/tweet?text=${title}&url=${encodeURIComponent(urlShare.value)}%23rs=twitter`;
	GAnalytics.track('click', 'Button', 'share-twitter', null);
	GAnalytics.trackGA4('send_link', { link_text: 'twitter' });

	window.open(url, 'pagename', `resizable, height=600,width=500, top=200, left=600`);
};
const shareOnPinterest = () => {
	if (store.userVector && store.userVector.preview.length) {
		const url = `https://pinterest.com/pin/create/bookmarklet/?media=${
			store.userVector?.preview
		}&description=${title}&url=${encodeURIComponent(urlShare.value)}%23rs=pinterest`;

		GAnalytics.track('click', 'Button', 'share-pinterest', null);
		GAnalytics.trackGA4('send_link', { link_text: 'pinterest' });
		window.open(url, 'pagename', `resizable, height=600,width=500, top=200, left=600`);
	} else {
		toast.error(trans('Error to share template'));
	}
};
const shareOnCopyLink = (e: Event) => {
	e.preventDefault();

	const searchParam = new URLSearchParams();
	if (locale.value !== 'en') searchParam.set('lang', locale.value);

	const queryParams = searchParam.toString() ? `?${searchParam.toString()}` : '';
	copy(`${urlShare.value}${queryParams}` + '#rs=link');

	GAnalytics.track('click', 'button', 'copy-link', null);
	GAnalytics.trackGA4('send_link', { link_text: 'copied_link' });
};
</script>

<template>
	<div class="flex flex-col">
		<div class="mb-3 flex w-full items-center gap-1">
			<button @click="emit('goback')"><SvgIcon name="arrow" class="h-5 w-5 rotate-90" /></button>
			<p class="text-lg font-semibold text-gray-700">{{ trans('Send link') }}</p>
		</div>
		<p class="mb-4 text-sm text-gray-600">{{ trans('Copy the read-only link where you want to share your design') }}</p>
		<div class="mb-4 flex gap-2">
			<div class="relative w-full min-w-0">
				<a
					data-testid="copy-link"
					:href="urlShare"
					target="_blank"
					class="relative flex h-10 w-full min-w-0 items-center truncate rounded border border-gray-100/75 px-3 text-sm text-gray-400 hover:text-gray-500 lg:pointer-events-auto"
					@click="shareOnCopyLink"
				>
					<div class="truncate">{{ urlShare }}</div>
					<SvgIcon name="copy" class="h-4 w-4 shrink-0" />
				</a>
				<div v-if="copied" class="copied-animation absolute right-2 z-20 bg-white px-1 text-xs text-blue-500 opacity-0">
					{{ trans('Copied!') }}
				</div>
			</div>
			<div class="flex items-center gap-1">
				<button
					class="flex h-10 w-10 shrink-0 items-center justify-center rounded bg-[#4267B2] hover:opacity-90"
					@click="shareOnFacebook"
				>
					<SvgIcon name="facebook" class="h-4 w-4 text-white" />
				</button>
				<button
					class="flex h-10 w-10 shrink-0 items-center justify-center rounded bg-[#03A9F4] hover:opacity-90"
					@click="shareOnTwitter"
				>
					<SvgIcon name="twitter" class="h-4 w-4 text-white" />
				</button>
				<button
					class="flex h-10 w-10 shrink-0 items-center justify-center rounded bg-[#E60023] hover:opacity-90"
					@click="shareOnPinterest"
				>
					<SvgIcon name="pinterest" class="h-4 w-4 text-white" />
				</button>
				<button
					class="flex h-10 w-10 shrink-0 items-center justify-center rounded bg-[#25D366] hover:opacity-90"
					@click="shareOnWhatsapp"
				>
					<SvgIcon name="whatsapp" class="h-4 w-4 text-white" />
				</button>
			</div>
		</div>
		<hr class="mb-4 border-t-gray-100/50" />
		<ShareSendEmail />
	</div>
</template>
<style lang="sass" scoped>
.copied-animation
	animation: copied 1s forwards

	@keyframes copied
		0%
			opacity: 0
			top: 0

		90%
			opacity: 1
			top: -10px

		100%
			opacity: 0
			top: -10px
</style>
