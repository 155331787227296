import { computed, Ref } from 'vue';

import { createOrchestrator } from '@/composables/createOrchestrator';
import Element from '@/elements/element/classes/Element';
import { useElementOrder } from '@/elements/element/composables/useElementOrder';
import { useGroupOrder } from '@/elements/group/composables/useGroupOrder';

export const useElementOrderOrchestrator = (element: Ref<Element>) => {
	return createOrchestrator(() => {
		if (element.value.group) {
			return useGroupOrder(element);
		}

		return useElementOrder(element);
	});
};
