import { computed, Ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Color } from '@/Types/colorsTypes';

export const useStorysetColors = (storysetRef: Ref<Storyset>) => {
	const gradientColors = computed(() =>
		storysetRef.value.mainColor instanceof GradientColor ? [storysetRef.value.mainColor] : []
	);

	const updateColor = (newColor: Color): Storyset => {
		storysetRef.value.updateColor(newColor);
		return storysetRef.value;
	};

	return {
		gradientColors,
		updateColor,
	};
};
