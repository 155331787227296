<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import WithAuth from '@/auth/components/WithAuth.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import Image from '@/elements/medias/images/image/classes/Image';
import AiImageModal from '@/elements/medias/images/image/components/ai/AiImageModal.vue';
import { useAiImage } from '@/elements/medias/images/image/composables/useAiImage';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { ImageApi } from '@/Types/apiClient';
import { AiViewerMode } from '@/Types/types';
// Props
const props = defineProps<{ element: Element }>();

// Using composables
const { trans } = useI18n();
const { addInsertableImage } = useAddInsertableElement();
const { runOnMobile } = useDeviceInfo();
const store = useMainStore();

const { prompt, selectedImage, parseImageToImageApi } = useAiImage();

const viewerMode = ref<AiViewerMode | undefined>();
const isEditing = ref<boolean>(false);

onBeforeMount(() => {
	viewerMode.value = undefined;
	onCloseModal();
	isEditing.value = false;
});

const replaceImage = () => {
	if (!(props.element instanceof Image)) {
		return;
	}

	GAnalytics.trackDebounceEditBarGA4({ category: 'photo', link_text: 'image_details' });

	selectedImage.value = parseImageToImageApi(props.element);
	viewerMode.value = AiViewerMode.replace;
	isEditing.value = true;
};

const onCloseModal = () => {
	selectedImage.value = undefined;
	prompt.value = '';
	isEditing.value = false;
	viewerMode.value = undefined;
};

const onInsertImage = async (image: ImageApi) => {
	if (viewerMode.value) return;

	GAnalytics.track('add-image', 'AI', 'insert-image', null);
	GAnalytics.trackGA4('add_to_canvas_text_to_image');

	await addInsertableImage(image);

	runOnMobile(() => (store.activePanel = null));
};
</script>

<template>
	<button
		data-testid="replace-ai-img"
		:tooltip="trans('Image details')"
		tooltip-position="top"
		data-button-importance="important"
		class="flex h-11 w-[15vw] flex-col items-center justify-between text-gray-100 hover:text-white lg:h-8 lg:w-full lg:flex-row lg:justify-center lg:px-2"
		@click="replaceImage"
	>
		<SvgIcon name="subtitles" class="h-5 w-5 shrink-0 scale-90 lg:h-5 lg:w-5" />
		<span class="whitespace-nowrap text-2xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>{{
			trans('Image details')
		}}</span>
	</button>

	<WithAuth v-if="isEditing && selectedImage" @close="onCloseModal">
		<AiImageModal
			open
			:image="selectedImage"
			:viewer-mode="viewerMode"
			@change-viewer-mode="viewerMode = undefined"
			@select="onInsertImage"
			@close="onCloseModal"
		/>
	</WithAuth>
</template>
