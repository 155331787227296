<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import ColorPickerGroup from '@/color/components/ColorPickerGroup.vue';
import { useShapeColors } from '@/color/composables/useShapeColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import FlipMenuToolbar from '@/elements/element/components/menus/FlipMenuToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import SplitShapeButton from '@/elements/shapes/shape/components/buttons/SplitShapeButton.vue';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: Shape }>();

const { trans } = useI18n();

const element = toRef(props, 'element');

const { colors, updateColor } = useShapeColors(element);
const { isMobile } = useDeviceInfo();
const { togglePanel } = usePanelManagement();

const onClickMoreTools = () => {
	Bugsnag.leaveBreadcrumb('Open edit shape panel');
	togglePanel(EditPanels.Shape);

	GAnalytics.trackDebounceEditBarGA4({ category: 'shape', link_text: 'more_tools' });
};

const onFlip = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'shape', link_text: 'flip' });
};

const onRemove = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'shape', link_text: 'delete' });
};

const onClickColorPicker = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'shape', link_text: 'color' });
};
</script>

<template>
	<div class="overflow-auto lg:overflow-visible">
		<template v-if="!element.locked">
			<FlipMenuToolbar :element="element" @flip="onFlip" />

			<div
				id="color-picker-shape-toolbar"
				class="order-first flex items-center justify-center lg:order-none lg:mr-1 lg:h-10"
				:tooltip="trans('Color')"
				tooltip-position="top"
			>
				<ColorPickerGroup
					class="ml-3 mr-1 h-10 border-r border-gray-600 py-1 pr-4 lg:mx-0 lg:ml-0 lg:h-5 lg:border-0 lg:py-0 lg:pl-1 lg:pr-1"
					picker-class="w-8 lg:w-5"
					:max="3"
					:colors="(colors as Color[])"
					picker-parent=".toolbar"
					@click="onClickColorPicker"
					@change="({ color, newColor }) => updateColor(color, newColor)"
				/>
			</div>

			<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 lg:block" />

			<!-- Split -->
			<SplitShapeButton :element="element" />

			<!-- Order -->
			<OrderToolbar v-if="isMobile" :element="element" />

			<!-- Copy -->
			<CopyToolbar v-if="isMobile" :element="element" />

			<!-- Remove -->
			<RemoveToolbar :element="element" @remove="onRemove" />

			<MoreToolsToolbar :element="element" :panel="EditPanels.Shape" @more-tools="onClickMoreTools" />
		</template>
		<LockButtonToolbar v-if="!isMobile && element.locked" :element="element" />
	</div>
</template>
