import { computed, Ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import { useInteractions } from '@/interactions/composables/useInteractions';

export const useCollisionForegroundInfo = (element: Ref<ForegroundImage>) => {
	const { isDragging } = useInteractions();
	const { page } = usePageElement(element);
	const { imageBackground } = useForeground(element);
	const elementIndex = computed(() => element.value.index);
	const firstPosition = computed(() => (page.value.backgroundImageId ? 1 : 0));
	const lastPosition = computed(() => page.value.elements.size - 1);

	const canGoDown = computed(
		() =>
			!!collisioningSortedElementsUnder.value.length &&
			collisioningSortedElementsUnder.value[0].id !== element.value.image
	);
	const canGoUp = computed(() => !!collisioningSortedElementsOver.value.length);

	const collisioningElements = computed(() =>
		isDragging.value || !page.value ? [] : page.value.elementsAsArray().filter(collisionFilterCb)
	);

	const collisioningSortedElementsOver = computed(() =>
		collisioningElements.value.filter((el: Element) => {
			return el.index > element.value.index;
		})
	);

	// Array ordenado por cercanía al elemento, no por orden de renderizado
	const collisioningSortedElementsUnder = computed(() =>
		collisioningElements.value
			.filter((el: Element) => {
				return el.index < element.value.index;
			})
			.reverse()
	);

	const collisionFilterCb = (el: Element) =>
		el.id !== imageBackground.value?.id &&
		el.id !== element.value.id &&
		el.id !== page.value.backgroundImageId &&
		el.isCollided(element.value);

	return {
		canGoDown,
		canGoUp,
		collisioningSortedElementsOver,
		collisioningSortedElementsUnder,
		elementIndex,
		firstPosition,
		lastPosition,
		page,
		collisionFilterCb,
	};
};
