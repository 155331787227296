<script lang="ts" setup>
import { ref } from 'vue';

import LoginModal from '@/auth/components/LoginModal.vue';
import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import BrowserVersionErrorModal from '@/editor/components/BrowserVersionErrorModal.vue';
import ConfirmNavigationModal from '@/editor/components/ConfirmNavigationModal.vue';
import ConfirmNavigationPhotoModeModal from '@/editor/components/ConfirmNavigationPhotoModeModal.vue';
import ErrorModal from '@/editor/components/ErrorModal.vue';
import PhotoModeErrorModal from '@/editor/components/PhotoModeErrorModal.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useGlobalError } from '@/editor/composables/useGlobalError';
import useNavigation from '@/editor/composables/useNavigation';
import OnBoarding from '@/layout/components/OnBoarding.vue';
import OnBoardingMobile from '@/layout/components/OnBoardingMobile.vue';
import UserInterests from '@/layout/components/UserInterests.vue';
const { showUnsavedChangesModal } = useNavigation();
const { error } = useGlobalError();

const { authRequired } = useAuth();
const { isPhotoMode } = useEditorMode();
const { isMobile, isOldBrowser } = useDeviceInfo();

const toggleBrowserModal = ref(isOldBrowser.value);
</script>

<template>
	<ErrorModal v-if="error && !isPhotoMode" />
	<BrowserVersionErrorModal v-if="toggleBrowserModal" />
	<PhotoModeErrorModal v-if="error && isPhotoMode" />
	<LoginModal v-if="authRequired" @close="authRequired = false" />
	<component
		:is="isPhotoMode ? ConfirmNavigationPhotoModeModal : ConfirmNavigationModal"
		v-if="showUnsavedChangesModal"
		@close="showUnsavedChangesModal = false"
	/>
	<UserInterests />
	<OnBoarding v-if="!isMobile && !authRequired" />
	<OnBoardingMobile v-if="isMobile" />
</template>
