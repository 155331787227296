import { computed, Ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import Line from '@/elements/line/classes/Line';
import { Color } from '@/Types/colorsTypes';

export const useLineColors = (lineRef: Ref<Line>) => {
	const gradientColors = computed(() => {
		const gradients = [];

		if (lineRef.value.mainColor instanceof GradientColor) {
			gradients.push(lineRef.value.mainColor);
		}

		if (lineRef.value.markerStart?.color instanceof GradientColor) {
			gradients.push(lineRef.value.markerStart.color);
		}

		if (lineRef.value.markerEnd?.color instanceof GradientColor) {
			gradients.push(lineRef.value.markerEnd.color);
		}

		return gradients;
	});

	const hasSameColors = computed(() =>
		lineRef.value.colors.every((color) => color.toCssString() === lineRef.value.colors[0].toCssString())
	);

	const updateAllColors = (newColor: Color): Line => {
		lineRef.value.updateStrokeColor(newColor.copy());

		if (lineRef.value.markerStart) {
			lineRef.value.updateMarkerStartColor(newColor.copy());
		}

		if (lineRef.value.markerEnd) {
			lineRef.value.updateMarkerEndColor(newColor.copy());
		}

		return lineRef.value;
	};

	const updateStrokeColor = (newColor: Color): Line => {
		lineRef.value.updateStrokeColor(newColor);

		return lineRef.value;
	};

	const updateMarkerStartColor = (newColor: Color): Line => {
		if (lineRef.value.markerStart) {
			lineRef.value.updateMarkerStartColor(newColor);
		}

		return lineRef.value;
	};

	const updateMarkerEndColor = (newColor: Color): Line => {
		if (lineRef.value.markerEnd) {
			lineRef.value.updateMarkerEndColor(newColor);
		}

		return lineRef.value;
	};

	return {
		gradientColors,
		hasSameColors,
		updateAllColors,
		updateColor: updateStrokeColor,
		updateMarkerStartColor,
		updateMarkerEndColor,
	};
};
