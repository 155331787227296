<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useI18n } from '@/i18n/useI18n';
import { useArtboard } from '@/project/composables/useArtboard';

const props = defineProps<{ element: Element; inlineLabel?: boolean }>();
const element = toRef(props, 'element');

const { trans } = useI18n();
const store = useMainStore();
const { rotateArtboard } = useArtboard();
const { rotate } = useElementTransformOrchestrator(element).value;

const isCropping = computed(() => !!store.croppingId?.length);

const onRotate = (isClockwise: boolean) => {
	if (isCropping.value) return;
	rotateArtboard();
	rotate(isClockwise ? 90 : -90);
	Bugsnag.leaveBreadcrumb(`Rotate ${element.value.type}-${element.value.id} ${isClockwise ? 90 : -90} degrees`);
};
</script>

<template>
	<div class="flex gap-1 lg:w-full lg:gap-px">
		<div class="lg:flex-1">
			<button
				class="flex h-9 w-9 items-center justify-center rounded-bl rounded-tl bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white lg:h-8 lg:w-full lg:px-3"
				data-testid="rotate-clockwise"
				:disabled="isCropping"
				:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
				@click="onRotate(true)"
			>
				<SvgIcon name="rotation" class="h-4 w-4" />
			</button>
		</div>

		<div class="lg:flex-1">
			<button
				class="flex h-9 w-9 items-center justify-center rounded-br rounded-tr bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white lg:h-8 lg:w-full lg:px-3"
				data-testid="rotate-notclockwise"
				:disabled="isCropping"
				:class="{ 'disabled cursor-not-allowed opacity-50': isCropping }"
				@click="onRotate(false)"
			>
				<SvgIcon name="rotation" class="h-4 w-4 -scale-x-100" />
			</button>
		</div>
	</div>

	<label v-if="inlineLabel" class="mt-1 block text-center text-2xs text-gray-100">{{ trans('Rotate') }}</label>
</template>
