<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { DownloadFormat } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		format: Exclude<DownloadFormat, 'app'>;
		label: string;
		description: string;
		icon: string;
		selectedCondition: boolean;
		multiplyFactor?: number;
		disabled?: boolean;
	}>(),
	{
		multiplyFactor: 1,
		disabled: false,
	}
);

const emits = defineEmits(['selected', 'change']);

const project = useProjectStore();
const store = useMainStore();

const { isValidSize, artboardSizeInPx } = useArtboard();
const { isSlidesgoPremium } = useAuth();
const { locale, trans } = useI18n();

const multiplyFactor = toRef(props, 'multiplyFactor');
const format = toRef(props, 'format');

const STEPS_VALUES = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4];
const validSteps = STEPS_VALUES.filter((val) => {
	if (isValidSize(project.size.width * val, project.size.height * val, project.unit)) {
		return val;
	}
});
const maxValue = Math.max(...validSteps);
const MIN_VALUE_MULTIPLY_FACTOR = 0.5;
const stepsToMultiply = validSteps.length - 1;

const percentageCalculated = computed(() => {
	const widthPerStep = 100 / stepsToMultiply;
	return ((multiplyFactor.value - MIN_VALUE_MULTIPLY_FACTOR) / MIN_VALUE_MULTIPLY_FACTOR) * widthPerStep;
});

const artboardSizeMultiplied = computed(() => {
	const finalWidth = ((artboardSizeInPx.value.width * multiplyFactor.value) / store.scaleMaxAllowedSize).toFixed();
	const finalHeight = ((artboardSizeInPx.value.height * multiplyFactor.value) / store.scaleMaxAllowedSize).toFixed();
	return `${finalWidth} x ${finalHeight}px`;
});

const isValidFormat = computed(() => {
	return [DownloadFormat.png, DownloadFormat.tpng, DownloadFormat.jpg].includes(format.value);
});

const onMultiplySize = async (val: number) => {
	emits('change', val);
};

const onClickButton = () => {
	if (!isSlidesgoPremium.value) {
		window.open(locale.value === 'es' ? 'https://slidesgo.com/es/precios' : 'https://slidesgo.com/pricing', '_blank');
		return;
	}

	emits('selected');
};
</script>

<template>
	<button
		class="flex w-full flex-col items-center rounded p-4 text-gray-600 hover:bg-gray-100/10"
		:class="{
			'bg-gray-50 hover:bg-gray-50': selectedCondition,
			'pointer-events-none': disabled,
		}"
		@click="onClickButton"
	>
		<div class="flex w-full" :class="{ 'items-center': !isValidFormat }">
			<div class="flex-1">
				<div class="mb-2 flex items-center">
					<SvgIcon v-if="icon" :name="icon" class="mr-2 h-6 w-6" />
					<div class="flex items-center gap-1">
						<span class="font-semibold text-gray-700">{{ label }} </span
						><SvgIcon v-if="icon" :prefix="'icon-slidesgo'" :name="'slg-premium'" class="h-4 w-4" />
					</div>
				</div>
				<div class="flex flex-col justify-between text-left text-xs leading-none text-gray-400">
					<p class="flex items-center">
						{{ trans(description) }}
					</p>
					<p v-if="!isSlidesgoPremium" class="mt-2">
						<span class="font-bold" style="color: rgb(255, 178, 41)">{{ trans(' Go Premium') }}</span>
						{{ trans('from 1.67€/month to unlock this feature, and gain unlimited access.') }}
					</p>
				</div>
			</div>

			<div class="flex h-6 w-6 items-center justify-center">
				<SvgIcon v-if="selectedCondition" name="check" class="h-4 w-4 text-blue-500" />
			</div>
		</div>

		<div v-if="selectedCondition && isValidFormat" class="mt-2 w-full" :data-test-format="format">
			<div class="overflow relative flex h-4 w-full flex-1 items-center">
				<div class="relative flex w-full items-center">
					<input
						:value="multiplyFactor"
						class="input-range h-[3px] w-full appearance-none rounded-full bg-gray-100/50 focus:outline-none"
						:max="maxValue"
						:min="MIN_VALUE_MULTIPLY_FACTOR"
						step="0.5"
						type="range"
						@input="onMultiplySize(parseFloat(($event.target as HTMLInputElement).value))"
					/>
					<span
						class="rounted-full absolute left-0 top-1/2 -mt-[1.5px] h-[3px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
						:style="{
							width: `${percentageCalculated}%`,
						}"
					/>
				</div>
				<span class="ml-2 h-5 w-8 rounded text-center text-xs font-semibold text-gray-500">
					{{ multiplyFactor }}x
				</span>
			</div>
			<label class="block text-left text-xs font-semibold text-gray-500">
				{{ artboardSizeMultiplied }}
			</label>
		</div>
	</button>
</template>

<style>
.canvas-downloadPreview {
	width: 2em !important;
	height: 2em !important;
	@apply m-0 mr-4;
}

.input-range::-webkit-slider-thumb:hover {
	@apply bg-blue-300;
}
</style>
