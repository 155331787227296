import Bugsnag from '@bugsnag/js';
import { computed, ComputedRef, CSSProperties, nextTick, Ref, ref } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useTextLink } from '@/elements/texts/text/composables/useTextLink';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import Page from '@/page/classes/Page';
import { Color } from '@/Types/colorsTypes';
import { FontWeight, ListStyle, StyleProperties, TextAlign, TextTransform } from '@/Types/elements.d';
import { Font, SelectionResult, TextEffects } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';
type MaybeHtmlElements = (HTMLElement | null)[];

/**
 * Retorna los fonts sizes de un texto
 * @param elements
 */
export const getFontSizes = (text: Ref<Text>, elements: MaybeHtmlElements) => {
	// Hack para forzar el recomputado de los textos
	text.value.fontSize;

	const fontSizes = elements
		.filter(Boolean)
		.filter((el) => el.style?.fontSize || getComputedStyle(el)[StyleProperties.fontSize])
		.map((el) => {
			if (
				el.classList.contains('provisional-text') ||
				el === document.querySelector(`#editable-${text.value.id}`) ||
				el === document.querySelector(`#element-${text.value.id} .text-element-final`)
			) {
				return text.value.fontSize;
			}

			return parseFloat(el.style[StyleProperties.fontSize] || getComputedStyle(el)[StyleProperties.fontSize]);
		});

	if (fontSizes.length) {
		return [...new Set<number>(fontSizes.filter((size) => size >= 0))];
	}

	return [text.value.fontSize];
};

/**
 * Retorna el lineHeight de un texto
 * @param text texto principal
 * @param elements Elementos seleccionados
 */
export const getLineHeight = (text: Ref<Text>, elements: MaybeHtmlElements) => {
	// Hack para forzar el recomputado de los textos
	text.value.lineHeight;

	const lineHeight = elements
		.filter(Boolean)
		.filter((el) => el.style?.lineHeight.length)
		.map((el) => {
			if (
				el.classList.contains('provisional-text') ||
				el === document.querySelector(`#editable-${text.value.id}`) ||
				el === document.querySelector(`#element-${text.value.id} .text-element-final`)
			) {
				return MathTools.toFixedOrInt(text.value.lineHeight);
			}
			return MathTools.toFixedOrInt(parseFloat(el.style[StyleProperties.lineHeight]));
		});

	if (lineHeight.length) {
		return [...new Set<number>(lineHeight.filter((height) => height >= 0))];
	}

	return [MathTools.toFixedOrInt(text.value.lineHeight)];
};

/**
 * Retorna el letterSpacing de un texto
 * @param text texto principal
 * @param elements Elementos seleccionados
 */
export const getLetterSpacing = (text: Ref<Text>, elements: MaybeHtmlElements): number[] => {
	// Hack para forzar el recomputado de los textos
	text.value.letterSpacing;
	text.value.scale;

	const letterSpacing = elements
		.filter(Boolean)
		.filter((el) => el.style?.letterSpacing)
		.map((el) => {
			if (
				el.classList.contains('provisional-text') ||
				el === document.querySelector(`#editable-${text.value.id}`) ||
				el === document.querySelector(`#element-${text.value.id} .text-element-final`)
			) {
				return text.value.letterSpacing * text.value.scale;
			}
			return parseFloat(el.style[StyleProperties.letterSpacing]) * text.value.scale;
		});

	if (letterSpacing.length) {
		return [...new Set(letterSpacing)];
	}

	return [text.value.letterSpacing];
};

/**
 * Retorna el lineHeight de un texto
 * @param elements
 */
export const getAllLineHeight = (text: Ref<Text>, elements: MaybeHtmlElements, domNode: HTMLElement | null) => {
	// Hack para forzar el recomputado de los textos
	text.value.lineHeight;

	let lineHeight: number[] = [];

	const mainNode = domNode;

	lineHeight = elements
		.filter(Boolean)
		.filter((el) => !!el.style?.lineHeight.length)
		.map((el) => {
			if (el === mainNode) {
				return MathTools.toFixedOrInt(text.value.lineHeight);
			}
			return MathTools.toFixedOrInt(parseFloat(el.style[StyleProperties.lineHeight]));
		});
	if (lineHeight.length) {
		return [...new Set<number>(lineHeight.filter((height) => height >= 0))];
	}

	return [MathTools.toFixedOrInt(text.value.lineHeight)];
};

/**
 * Retorna los font families de un texto
 * @param text
 * @param elements
 */
export const getFontFamilies = (text: Ref<Text>, elements: MaybeHtmlElements) => {
	text.value.fontFamily;

	const families = elements
		.filter(Boolean)
		.filter((el) => el?.style?.fontFamily)
		.map((el) => el.style[StyleProperties.fontFamily].replace(/['"]+/g, ''));

	if (families.length) {
		return [...new Set<string>(families.filter((family) => !!family))];
	}

	return [text.value.fontFamily];
};

export const getTextStyles = (text: Ref<Text>) => {
	const { isRenderingContext } = useEditorMode();
	const { isIOS } = useDeviceInfo();

	const gradientPadding = computed(() => Math.round(text.value.getTextPadding()));

	return computed<Partial<CSSProperties>>(() => {
		const fixes: any = {};

		// ! si tiene sombra, aplicamos filter para que renderice bien en MAC / ios
		if (isRenderingContext && text.value.textShadow.some((ts) => ts.opacity)) {
			fixes['-webkit-filter'] = 'opacity(1)';
		}

		// si el texto contiene gradientes incluyendo alguno de sus nodos, calculamos el valor del padding que debemos aplicar para que
		// no haya recorte en el texto, este valor también se aplicará para un margin top en negativo, para corregir la posición del texto
		const hasOutline = text.value.outline && text.value.outline.width;

		//  Comprobamos el tipo de color que se le asigna al root y le aplicamos los estilos en función de si es gradiente o plano
		const rootColor = {
			color: '',
			backgroundImage: '',
			webkitTextFillColor: '',
			webkitBackgroundClip: '',
		};
		if (text.value.color.isGradient() && !(isRenderingContext && window.renderData.format === 'pptx')) {
			rootColor.backgroundImage = `var(--${text.value.color.id})`;
			rootColor.webkitTextFillColor = 'transparent';
			rootColor.webkitBackgroundClip = 'text';
		} else {
			rootColor.color = `var(--${text.value.color.id})`;
		}

		return {
			...fixes,
			[`--${text.value.color.id}`]: text.value.color.toCssString(),
			...rootColor,
			fontFamily: `"${text.value.fontFamily}"`,
			fontSize: `${text.value.fontSize}px`,
			fontStyle: text.value.fontStyle,
			fontWeight: text.value.fontWeight,
			textTransform: text.value.textTransform,
			letterSpacing: `${text.value.letterSpacing}px`,
			lineHeight: text.value.lineHeight,
			textAlign: text.value.textAlign,
			width: `${text.value.size.width / text.value.scale}px`,
			webkitTextStroke: `${text.value.outline.width}${text.value.outline.unit || 'px'} ${text.value.outline.color}`,
			transform: text.value.scale !== 1 ? `scale(${text.value.scale})` : undefined,
			transformOrigin: '0 0',
			wordBreak: 'break-word',
			// IOS no rederiza bien las sombras si el texto tiene outline, por lo que si tenemos outline aplicado en IOS no
			// aplicamos los estilos sobre el texto original , se renderizará uno a parte en el componente textTemplate que tenga el textShadow
			textShadow: isIOS.value && hasOutline ? '' : TextTools.textShadowToCssString(text.value.textShadow),
			paddingTop: text.value.curvedProperties.arc === null ? `${gradientPadding.value}px` : undefined,
			paddingBottom: text.value.curvedProperties.arc === null ? `${gradientPadding.value}px` : undefined,
		};
	});
};

/**
 * Retorna el font Weight de un texto
 * @param text
 * @param elements
 */
export const getCurrentFontWeight = (text: Ref<Text>, elements: MaybeHtmlElements): FontWeight[] => {
	text.value.fontFamily;
	text.value.fontWeight;
	text.value.fontStyle;
	let weights: FontWeight[] = [];

	weights = elements
		.filter(Boolean)
		.filter((el) => !!el.style[StyleProperties.fontWeight])
		.map((el) => parseInt(el.style[StyleProperties.fontWeight]) as FontWeight);
	if (!weights.length) {
		weights = elements
			.filter(Boolean)
			.filter((el) => getComputedStyle(el)[StyleProperties.fontWeight])
			.map((el) => parseInt(getComputedStyle(el)[StyleProperties.fontWeight]) as FontWeight);
	}

	return [...new Set<FontWeight>([...weights])];
};

/**
 * Retorna el italic de un texto
 * @param text
 * @param elements
 */
export const getCurrentFontStyle = (text: Ref<Text>, elements: MaybeHtmlElements) => {
	text.value.fontFamily;
	text.value.fontStyle;
	text.value.fontWeight;
	let style;

	style = elements
		.filter(Boolean)
		.filter((el) => el.style[StyleProperties.fontStyle])
		.map((el) => el.style[StyleProperties.fontStyle]);
	if (!style.length) {
		style = elements
			.filter(Boolean)
			.filter((el) => getComputedStyle(el)[StyleProperties.fontStyle])
			.map((el) => getComputedStyle(el)[StyleProperties.fontStyle]);
	}

	return style;
};

/**
 * Obtiene el fontSize del elemento sobre el que se ha dejado el cursor
 * @param colors Array de colores solidos o gradientes
 * @returns Devuelve el número del fontSize o false en caso de no encontrarlo
 */
const getCurrentColor = (
	text: Ref<Text>,
	colors: ComputedRef<Color[]>,
	domNode: Ref<HTMLElement | null>,
	selection: Ref<SelectionResult | null>
) => {
	const result: Color[] = [];

	if (selection.value?.selection && selection.value?.selection.anchorNode && domNode.value) {
		const fullRange = TextSelectionTools.detectFullRange(selection.value?.selection, domNode.value);
		const nodes = TextTools.getRangeSelectedNodes(selection.value?.selection.getRangeAt(0));

		let allTextColors;

		if (fullRange) {
			allTextColors = TextTools.getAllColorsFromText(domNode.value);
		}

		// Si está todo el texto seleccionado retornamos los colores
		if (fullRange && allTextColors) {
			allTextColors.forEach((col) => {
				const colorFound = text.value.colors.find((c) => `--${c.id}` === col);

				if (colorFound) {
					result.push(colorFound);
				}
			});

			return result.sort();
		}

		if (fullRange) {
			result.push(text.value.color);
		}

		nodes.forEach((node) => {
			if (domNode.value?.id) {
				const colorId = TextSelectionTools.getColorFromNode(node, domNode.value.id, node);
				const finalColor = text.value.colors.find((color) => color.id === colorId);

				if (finalColor && !result.find((c) => c.id === finalColor.id)) {
					result.push(finalColor);
				}
			}
		});
	}

	if (!result.length) colors.value.forEach((c) => result.push(c));

	return Array.from(new Set(result.sort().map((color) => color.toCssString()))).map((color) =>
		color.includes('-gradient') ? GradientColor.fromString(color) : SolidColor.fromString(color)
	);
};

/**
 * Versión no interactiva del hook de textos. No tiene en cuenta la selección.
 * @param text
 */
export const useTextStylesStatic = (text: Ref<Text>) => {
	const { getVariants } = useFonts();
	const { domNode: finalNode, selection } = useTextSelection();

	const domNode = ref(
		finalNode.value ||
			TextTools.createNodeFromString(
				`<div class='provisional-text' style="font-family: '${text.value.fontFamily}'">${text.value.content}</div>`
			)
	);

	const elements = computed(() => {
		return [domNode.value, ...Array.from<HTMLElement>(domNode.value?.querySelectorAll('*') || [])].filter(
			(el) => !!el && el.nodeName !== '#comment'
		);
	});

	const colors = computed(() => text.value.colors);

	const selectedColor = computed(() => {
		// Hack para recomputar colors cuando se rehaga la selección
		// hay casos en los que los colores no cambien pero necesite recomputar por la selección
		selection.value;
		return getCurrentColor(text, colors, domNode, selection);
	});

	const fontFamily = computed(() => getFontFamilies(text, elements.value));
	const finalFontFamily = computed(() => (fontFamily.value.length > 1 ? 'Mixed Fonts' : fontFamily.value[0]));
	const fontSize = computed(() => getFontSizes(text, elements.value));
	const fontSizeScaled = computed(() => fontSize.value.map((size) => Math.round(size * text.value.scale)));
	const lineHeight = computed(() => getAllLineHeight(text, elements.value, domNode.value));
	const lineHeightLabel = computed(() => getLineHeight(text, elements.value).map((lh) => MathTools.toFixedOrInt(lh)));
	const fontWeight = computed(() => getCurrentFontWeight(text, elements.value));
	const italic = computed(() => getCurrentFontStyle(text, elements.value));
	const fontVariants = computed(() => {
		text.value.fontWeight;
		text.value.fontStyle;
		text.value.fontFamily;
		elements.value;

		return getVariants(fontFamily.value);
	});

	const textTransform = computed(() => text.value.textTransform);
	const letterSpacing = computed(() => getLetterSpacing(text, elements.value));

	const outline = computed(() => text.value.outline);
	const textShadow = computed(() => text.value.textShadow);

	return {
		colors,
		selectedColor,
		fontFamily,
		finalFontFamily,
		fontSize,
		fontSizeScaled,
		lineHeight,
		lineHeightLabel,
		fontWeight,
		italic,
		fontVariants,
		textTransform,
		letterSpacing,
		outline,
		textShadow,
	};
};

/**
 * Hook para la interacción con texto, permite la obtención reactiva
 * de estilos de un texto y la manipulación en base a la selección
 * @param text
 */
export const useTextStyles = (text: Ref<Text>) => {
	const { loadFontsByName, getVariants } = useFonts();
	const store = useMainStore();
	const scale = computed(() => store.scale);
	const { isCircleText } = useCircleTypeInfo(text, scale);
	const { breadScrumbWithDebounce } = useBugsnag(text as Ref<Text>);
	const { hasEcho, hasNeon, refreshEffect } = useTextEffects(text);
	const { textEditing, textEditingContent } = useTextEditing();
	const { domNode, selection, selectedNodes, previousInputSelection } = useTextSelection();

	const resetTextShadow = () => {
		text.value.textShadow = Text.defaults().textShadow;
	};

	const selectedElements = computed(() => {
		textEditingContent.value;

		if (!selectedNodes.value || !selectedNodes.value[0]) return [text.value.domNode() as HTMLElement];
		return selectedNodes.value
			.map((el) => (el.nodeType !== Node.TEXT_NODE ? el : el.parentElement))
			.filter((el) => !!el && el.nodeName !== '#comment') as HTMLElement[];
	});

	const colors = computed(() => {
		selectedElements.value;
		textEditingContent.value;

		return text.value.colors;
	});

	const selectedColor = computed(() => {
		textEditingContent.value;

		return getCurrentColor(text, colors, domNode, selection);
	});

	const fontFamily = computed(() => {
		textEditingContent.value;

		return getFontFamilies(text, selectedElements.value);
	});
	const finalFontFamily = computed(() => (fontFamily.value.length > 1 ? 'Mixed Fonts' : fontFamily.value[0]));
	const fontSize = computed(() => {
		textEditingContent.value;

		return getFontSizes(text, selectedElements.value);
	});

	const fontSizeScaled = computed(() =>
		fontSize.value.map((size) => Math.ceil((size * text.value.scale) / store.scaleMaxAllowedSize))
	);

	const lineHeight = computed(() => {
		textEditingContent.value;

		return getAllLineHeight(text, selectedElements.value, domNode.value);
	});
	const lineHeightLabel = computed(() => getLineHeight(text, selectedElements.value).map((lh) => lh));

	const fontWeight = computed(() => {
		textEditingContent.value;

		return getCurrentFontWeight(text, selectedElements.value);
	});

	const italic = computed(() => {
		textEditingContent.value;

		return getCurrentFontStyle(text, selectedElements.value);
	});

	const fontVariants = computed(() => {
		text.value.fontWeight;
		text.value.fontStyle;
		text.value.fontFamily;
		selectedElements.value;
		textEditingContent.value;

		return getVariants(fontFamily.value);
	});
	const boldAvailable = computed(() => {
		textEditingContent.value;

		return checkBoldAvailability(text, selectedElements.value);
	});
	const italicAvailable = computed(() => {
		textEditingContent.value;

		return checkItalicAvailability(text, selectedElements.value);
	});

	const textTransform = computed(() => text.value.textTransform);

	const letterSpacing = computed(() => {
		textEditingContent.value;

		return getLetterSpacing(text, selectedElements.value);
	});
	const outline = computed(() => text.value.outline);

	const textShadow = computed(() => text.value.textShadow);

	const listStyle = computed(() => text.value.listStyle);
	const isMultiStyleText = computed(() => {
		textEditingContent.value;

		return checkMultiStyleText(text, domNode.value);
	});

	/**
	 * Comprueba si el texto es multi estilo
	 */
	const checkMultiStyleText = (text: Ref<Text>, domNode: HTMLElement | null) => {
		text.value.fontFamily;
		text.value.fontStyle;
		text.value.fontWeight;

		const children = domNode ? domNode.querySelectorAll('*') : [];

		if (children.length) {
			// Con .getAttribute("style") comprobamos los estilos en línea
			return !!Array.from(children).filter((el) => el.getAttribute('style')).length;
		}

		return false;
	};

	/**
	 * Comprueba si la funcionalidad de negrita está disponible disponible para la selección actual
	 * @param text elemento Text
	 * @param elements Elementos seleccionados
	 * @returns retorna peso de fuente
	 */
	const checkBoldAvailability = (text: Ref<Text>, elements: MaybeHtmlElements) => {
		text.value.fontFamily;
		text.value.fontStyle;
		text.value.fontWeight;

		if (fontVariants.value.length > 1) {
			return fontVariants.value.every((font: { family: string; weight: string[] }) => {
				if (fontWeight.value.length > 1) {
					let result = false;
					const elementStyles = elements
						.filter(Boolean)
						.map((el) => [getComputedStyle(el)[StyleProperties.fontFamily], getCurrentFontWeight(text, [el])]);

					elementStyles.forEach((el) => {
						if (el[0] === font.family && font.weight.find((weight: string) => parseInt(weight) >= 600)) {
							result = true;
						}
					});

					return result;
				}

				return font.weight.find((weight: string) => parseInt(weight) >= 600);
			});
		}

		return fontVariants.value.length
			? fontVariants.value[0].weight.find((weight: string) => parseInt(weight) >= 600)
			: fontVariants.value;
	};

	/**
	 * Comprueba si la funcionalidad de itálica está disponible disponible para la selección actual
	 * @param text elemento Text
	 * @param elements Elementos seleccionados
	 * @returns retorna peso con ítalica
	 */
	const checkItalicAvailability = (text: Ref<Text>, elements: MaybeHtmlElements) => {
		text.value.fontFamily;
		text.value.fontStyle;
		text.value.fontWeight;

		if (fontVariants.value.length > 1) {
			return fontVariants.value.every((font: { family: string; weight: string[] }) => {
				if (fontWeight.value.length > 1) {
					let result = false;
					const elementStyles = elements
						.filter(Boolean)
						.map((el) => [getComputedStyle(el)[StyleProperties.fontFamily], getCurrentFontWeight(text, [el])]);

					elementStyles.forEach((el) => {
						if (el[0] === font.family && font.weight.find((weight: string) => weight === `${el[1]}i`)) {
							result = true;
						}
					});

					return result;
				}

				return font.weight.find((weight: string) => weight === `${fontWeight.value[0]}i`);
			});
		}

		return fontVariants.value.length
			? fontVariants.value[0].weight.find((weight: string) => weight === `${fontWeight.value[0]}i`)
			: fontVariants.value;
	};

	/**
	 * Resetea una fuente y su texto
	 */
	const resetFont = () => {
		text.value.fontWeight = 400;
		resetChildrenStyle(StyleProperties.fontWeight);

		text.value.fontStyle = 'normal';
		resetChildrenStyle(StyleProperties.fontStyle);
	};

	/**
	 * Resetea a '' los valores de la propiedad indicada para los hijos del nodo seleccionado
	 * @param style Hará referencia a una propiedad CSS fontSize, color, fontFamily
	 */
	const resetChildrenStyle = (style: StyleProperties): void => {
		const nodes = Array.from((domNode.value?.querySelectorAll('*') as NodeListOf<Element>) || []) as HTMLElement[];
		// En caso de que no haya elementos span dentro del texto, salimos
		if (!nodes.length) return;
		Array.from(nodes).forEach((n) => {
			// Si el estilo es color, eliminamos también su variable asignada
			if (style === StyleProperties.color) {
				Object.values(n.style).forEach((style) => {
					if (style.includes('--color')) {
						n.style.removeProperty(style);
					}

					if (style === 'background-image') {
						n.style.removeProperty('background-image');
					}

					if (style === '-webkit-background-clip') {
						n.style.removeProperty('-webkit-background-clip');
					}

					if (style === 'background-clip') {
						n.style.removeProperty('background-clip');
					}

					if (style === '-webkit-text-fill-color') {
						n.style.removeProperty('-webkit-text-fill-color');
					}
				});
			}

			n.style[style] = '';
		});
	};

	/**
	 * Aplicamos el valor al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param style Propiedad CSS
	 * @param value Valor para la propiedad CSS
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyStyleToRootElement = (style: StyleProperties, value: any): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(textEditing.value && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			let finalValue: number | string = value;

			if (text.value[style] instanceof Number && typeof value === 'string') {
				finalValue = parseFloat(value);
			}
			if (typeof text.value[style] === 'string' && value instanceof Number) {
				finalValue = `${value}`;
			}

			// @ts-ignore
			text.value[style] = finalValue;

			if (domNode.value && domNode.value?.children.length) {
				resetChildrenStyle(style);
			}

			return true;
		}

		return false;
	};

	/**
	 * Aplicamos el fontFamily al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para la propiedad CSS
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyFontFamilyToRootElement = (value: string): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(textEditing.value && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			text.value[StyleProperties.fontFamily] = value;

			// Si el peso de la fuente no está dentro de los pesos de la familia de fuentes, le aplicamos la primera
			applyValidWeight(value);

			resetChildrenStyle(StyleProperties.fontFamily);
			if (domNode.value) {
				domNode.value.style.fontFamily = value;
			}

			return true;
		}

		return false;
	};

	const applyValidWeight = (value: string) => {
		const variants = getVariants([value])[0].weight;

		if (domNode.value) {
			const fontWeight = domNode.value.style.fontWeight || getComputedStyle(domNode.value).fontWeight;
			if (
				!fontWeight.length ||
				(fontWeight.length && !(variants.includes(fontWeight) || variants.includes(`${fontWeight}i`)))
			) {
				updateFontWeight(variants[0].includes('i') ? variants[0].split('i')[0] : variants[0]);
			}
		}
	};

	const removeUnusedColors = () => {
		const children = domNode.value?.querySelectorAll<HTMLElement>('*');
		const usedColors = [text.value.color];

		if (children) {
			Array.from(children).forEach((child) => {
				const childColor = Object.values(child.style).find((style) => style.includes('--color'));
				const finalColor = text.value.colors.find((color) => color.id === childColor?.split('--')[1]);

				const childNodes = Array.from(child.childNodes);

				// Comprobobamos si todos los hijos del elemento son nodos HTML y en caso afirmativo,
				// eliminamos el color que tenga para evitar colores que están usandose pero se están pisando con sus hijos
				if (
					childNodes &&
					childNodes.every(
						(childNode) =>
							childNode instanceof HTMLElement &&
							Object.values(childNode.style).find((style) => style.includes('--color'))
					)
				) {
					// Eliminamos los colores que pudiese tener anteriormente
					Object.values(child.style).forEach((style) => {
						if (style.startsWith('--color-')) {
							child.style.removeProperty(style);
						}
					});

					child.style.removeProperty('color');
				}

				if (finalColor) {
					usedColors.push(finalColor);
				}
			});
		}

		text.value.colors = Array.from(new Set(usedColors));
	};

	const applyColorsVarsToNodes = () => {
		// Aplicamos el color al root
		if (domNode.value) {
			TextTools.applyColorVarToTextNode(domNode.value, text.value.color, text.value.colors);
		}

		// Aplicamos el color a los hijos
		const children = domNode.value ? Array.from(domNode.value?.querySelectorAll<HTMLElement>('*')) : [];
		if (children.length) {
			Array.from(children).forEach((child) => {
				const color: string | undefined = Object.values(child.style).find((childStyle) => {
					if (childStyle.includes('--color-')) {
						return true;
					}
				});

				if (color) {
					const colorString = child.style.getPropertyValue(color);
					const finalColor = colorString.includes('gradient')
						? GradientColor.fromString(colorString)
						: SolidColor.fromString(colorString);
					finalColor.id = color.split('--')[1];
					TextTools.applyColorVarToTextNode(child, finalColor, text.value.colors);
				}
			});
		}
	};

	/**
	 * Aplicamos el fontFamily al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para la propiedad CSS
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyColorToRootElement = (value: Color): boolean => {
		const { updateLinkUnderline } = useTextLink(text);
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(text.value && !selection.value) //SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			const finalValue = value;

			let foundIndex = -1;
			const foundColor = text.value.colors.find((c, idx) => {
				foundIndex = idx;
				return c.toCssString() === finalValue.toCssString();
			});

			if (domNode.value) {
				if (!foundColor) {
					// Eliminamos los colores que pudiese tener anteriormente
					Object.values(domNode.value.style).forEach((style) => {
						if (style.startsWith('--color-')) {
							domNode.value?.style.removeProperty(style);
						}
					});

					// Añadimos el nuevo color
					domNode.value.style.setProperty(`--${finalValue.id}`, finalValue.toCssString());
					text.value.updateColor(finalValue);
				} else {
					if (foundColor instanceof GradientColor && finalValue instanceof GradientColor) {
						foundColor.stops = finalValue.stops;
						foundColor.type = finalValue.type;
						foundColor.rotation = finalValue.rotation;
					} else if (foundColor instanceof SolidColor && finalValue instanceof SolidColor) {
						foundColor.r = finalValue.r;
						foundColor.g = finalValue.g;
						foundColor.b = finalValue.b;
						foundColor.a = finalValue.a;
					}

					domNode.value.style.setProperty(`--${foundColor.id}`, foundColor.toCssString());

					text.value.updateColor(foundColor);
				}
			}

			if (domNode.value && domNode.value?.children.length) {
				resetChildrenStyle(StyleProperties.color);
			}

			applyColorsVarsToNodes();

			updateLinkUnderline(text);

			return true;
		}

		return false;
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param style Propiedad CSS
	 * @param value Valor para la propiedad CSS
	 */
	const applyFinalNodesStyle = (style: StyleProperties, value: SolidColor | GradientColor | string | number) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(selection.value.selection instanceof Selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value.selection?.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				el.style[style] = `${value}`;
			});
		}
	};

	/**
	 * Aplicamos el fontSize al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para el fontSize
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyFontSizeToRootElement = (value: number[], direction?: string) => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(textEditing.value && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// Comprobamos si tenemos más de un valor de fontSize y si se ha seleccionado alguna dirección y se lo aplicamos a sus hijos
			if (direction && value.length > 1) {
				// Si hemos cambiado el fontSize desde las flechas parseamos el fontSize que tenemos en el store y buscamos el que le corresponde al root
				const rootSize = Math.round(text.value[StyleProperties.fontSize]);
				const result = value.find((size) => (direction === 'plus' ? size === rootSize + 1 : size === rootSize - 1));

				text.value[StyleProperties.fontSize] = result ? result : value[0];
				applyMultiFontSizeToFinalNodes(value, direction);
			} else {
				// En caso contrario es porque solo hay un valor posible para aplicar a todo el texto y se limpiarán los hijos
				text.value[StyleProperties.fontSize] = value[0];

				if (domNode.value && domNode.value?.children.length) {
					resetChildrenStyle(StyleProperties.fontSize);
				}
			}

			return true;
		}

		return false;
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 * @param dir 'plus' or 'minus'
	 */
	const applyMultiFontSizeToFinalNodes = (value: number[], dir?: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(selection.value.selection instanceof Selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value.selection?.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un tamaño de letra
				if (value.length === 1) {
					el.style[StyleProperties.fontSize] = `${Math.round(value[0])}px`;
				} else {
					// Si tenemos varios tamaños de letra buscamos cual es el que le corresponde a cada elemento según la dirección indicada
					const foundValue = value.find(
						(prop: number) =>
							(dir === 'plus' && prop === Math.ceil(parseFloat(getComputedStyle(el)[StyleProperties.fontSize]) + 1)) ||
							(dir === 'minus' && prop === Math.ceil(parseFloat(getComputedStyle(el)[StyleProperties.fontSize]) - 1))
					);

					el.style[StyleProperties.fontSize] = `${foundValue}px`;
				}
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 */
	const applyMultiFontWeightToFinalNodes = (value: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(selection.value.selection instanceof Selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value?.selection?.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un font weight
				if ((value && typeof value === 'string') || (Array.isArray(value) && value.length === 1)) {
					el.style[StyleProperties.fontWeight] = Array.isArray(value) ? value[0] : value;
				} else {
					let boldValue: string | undefined = '400';

					// Si tenemos varios fontWeight buscamos cual es el que le corresponde a cada elemento
					fontVariants.value.forEach((font: { family: string; weight: string[] }) => {
						const elementStyles = getComputedStyle(el);
						if (font.family === elementStyles[StyleProperties.fontFamily]) {
							if (font.weight) {
								boldValue =
									parseInt(elementStyles[StyleProperties.fontWeight]) < 600
										? font.weight.find((f) => parseInt(f) >= 600)
										: font.weight.find((f) => parseInt(f) === 400);
							}
						}
					});

					if (boldValue) {
						el.style[StyleProperties.fontWeight] = boldValue;
					}
				}
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 * @param dir 'plus' or 'minus'
	 */
	const applyMultiLineHeightToFinalNodes = (value: number[], dir?: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.type.toLowerCase() === 'caret' && domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(selection.value.selection instanceof Selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value?.selection?.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un tamaño de letra
				if (value.length === 1) {
					el.style[StyleProperties.lineHeight] = `${MathTools.toFixedOrInt(value[0])}`;
				} else {
					const foundLineHeight = value.find(
						(prop: number) =>
							(dir === 'plus' &&
								prop === MathTools.toFixedOrInt((parseFloat(el.style[StyleProperties.lineHeight]) || 1.2) + 0.1)) ||
							(dir === 'minus' &&
								prop === MathTools.toFixedOrInt((parseFloat(el.style[StyleProperties.lineHeight]) || 1.2) - 0.1))
					);

					if (foundLineHeight) {
						el.style[StyleProperties.lineHeight] = `${foundLineHeight}`;
					}
				}
			});
		}
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Valor para la propiedad CSS
	 * @param dir 'plus' or 'minus'
	 */
	const applyMultiLetterSpacingToFinalNodes = (value: number[], dir?: string) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.isCollapsed && !domNode.value?.querySelectorAll('span').length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(selection.value.selection instanceof Selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value?.selection?.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// finalmente aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				// Si solo tenemos un tamaño de espaciado
				if (value.length === 1) {
					el.style[StyleProperties.letterSpacing] = `${value[0]}px`;
				} else {
					const foundLetterSpacing = value.find((prop: number) => {
						const spacing = getComputedStyle(el)[StyleProperties.letterSpacing];
						const finalSpacing = spacing === 'normal' ? 0 : parseFloat(spacing);
						const calculatedSpacing =
							dir === 'plus' ? MathTools.toFixedOrInt(finalSpacing + 0.1) : MathTools.toFixedOrInt(finalSpacing - 0.1);
						const floatedProp = MathTools.toFixedOrInt(prop);

						return floatedProp === calculatedSpacing;
					});

					el.style[StyleProperties.letterSpacing] = `${foundLetterSpacing}px`;
				}
			});
		}
	};

	/**
	 * 	Genera un nuevo rango que contiene el elemento sobre el que está el puntero (caret)
	 * @returns Retorna undefined o un objeto con el nodo y la posición del caret
	 */
	const generateRangefromCaret = () => {
		// Si tenemos algún hijo y la selección es de tipo caret sobre el padre debemos generar un nuevo span en la parte correspondiente al puntero
		// Ej: <div>Hola <span>mu</span>nd|o</div> --- > | representa a la selección de tipo caret
		let oldRange: undefined | { anchorNode: Node; anchorOffset: number };

		if (
			domNode.value &&
			selection.value &&
			selection.value.selection?.isCollapsed &&
			((selection.value.selection?.anchorNode?.nodeType === 1 &&
				selection.value.selection?.anchorNode === domNode.value) ||
				(selection.value.selection?.anchorNode?.nodeType === 3 &&
					selection.value.selection?.anchorNode.parentElement?.closest(`#${domNode.value.id}`))) &&
			domNode.value.querySelectorAll('*').length
		) {
			// Clonamos la selección para restaurarla después de generar el Span
			oldRange = {
				anchorNode: selection.value.selection?.anchorNode,
				anchorOffset: selection.value.selection?.anchorOffset,
			};

			const newRange = new Range();

			newRange.selectNodeContents(selection.value.selection.anchorNode);

			selection.value?.selection?.removeAllRanges();
			selection.value?.selection?.addRange(newRange);
		}

		return oldRange;
	};

	/**
	 * Se encarga de actualizar el fontSize
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateFontSize = (value: number | 'plus' | 'minus') => {
		if (previousInputSelection.value) {
			TextSelectionTools.createRange(previousInputSelection.value, domNode);

			previousInputSelection.value = null;
		}

		TextSelectionTools.fixTextSelection(domNode);

		let finalValue: number[];

		if (isCircleText.value) {
			text.value.setScale(1);

			if (typeof value === 'number') {
				text.value.fontSize = value;
			} else if (value === 'plus') {
				text.value.fontSize += 1;
			} else if (value === 'minus') {
				text.value.fontSize = text.value.fontSize - 1 < 1 ? 1 : (text.value.fontSize -= 1);
			}

			return;
		}

		if (typeof value === 'string') {
			// En caso de ser 'plus' o 'minus' calculamos todos los nuevos valores para la selección
			finalValue = fontSize.value.map((el) =>
				value === 'plus' ? Math.ceil(el * text.value.scale + 1) : Math.ceil(el * text.value.scale - 1)
			);
		} else {
			finalValue = [value];
			text.value.setScale(1);
		}

		if (finalValue.length === 1 && finalValue[0] === 0) {
			return;
		}

		// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
		// para poder saber que valor corresponde al root y a cada uno de los posibles hijos
		if (typeof value === 'string') {
			if (applyFontSizeToRootElement(finalValue, value)) {
				return;
			}
		} else if (applyFontSizeToRootElement(finalValue)) {
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
			// para poder saber que valor corresponde a cada uno de los posibles hijos
			if (typeof value === 'string') {
				applyMultiFontSizeToFinalNodes(finalValue, value);
			} else {
				applyMultiFontSizeToFinalNodes(finalValue);
			}
		});
	};

	const generateSpansAndApplyStylesToChildNodes = (callback: () => void) => {
		// En caso de tener una selección de tipo Caret, generamos un rango con el elemento correspondiente
		const caretRange = generateRangefromCaret();

		// Generamos los Span de forma nativa
		TextTools.generateChildrenSpan();

		// Eliminamos el background de la selección
		TextTools.removeChildrenBackground(domNode);

		// Recuperamos el nodo seleccionado de tipo caret
		if (caretRange && selection.value?.selection && selection.value.selection.anchorNode) {
			caretRange.anchorNode = selection.value.selection.anchorNode;
		}

		callback();
	};

	/**
	 * Se encarga de la gestión completa de la asignación de un estilo al texto
	 * @param value Valor para la propiedad CSS
	 */
	const updateColor = (value: Color) => {
		TextSelectionTools.fixTextSelection(domNode);

		// TODO: Pendiente de sacar a su propio hook porque crea dependencia circular entre useTextLink y useTextStyles
		const { updateLinkUnderline } = useTextLink(text);

		// Restringimos el cambio de angulo y tipo en textos curvos
		if (value instanceof GradientColor && isCircleText.value) {
			value.rotation = 0;
			value.type = 'linear';
		}

		// Algunos efectos dependen del color del texto y deben actualizarse
		let effectToRefresh;
		if (hasEcho.value) effectToRefresh = TextEffects.Echo;
		if (hasNeon.value) effectToRefresh = TextEffects.Neon;

		if (applyColorToRootElement(value)) {
			if (effectToRefresh) refreshEffect(effectToRefresh);
			removeUnusedColors();

			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		// En caso de tener una selección de tipo Caret, generamos un rango con el elemento correspondiente
		const oldRange = generateRangefromCaret();

		// Generamos los Span de forma nativa
		TextTools.generateChildrenSpan();

		// Eliminamos el background de la selección
		TextTools.removeChildrenBackground(domNode);

		// Recuperamos el nodo seleccionado de tipo caret
		if (oldRange && selection.value?.selection && selection.value.selection.anchorNode) {
			oldRange.anchorNode = selection.value?.selection?.anchorNode;
		}

		applyColorFinalNodesStyle(value);
		removeUnusedColors();
		updateLinkUnderline(text);

		if (effectToRefresh) refreshEffect(effectToRefresh);
	};

	/**
	 * Obtenemos los SPAN de los nodos finales después de la selección y
	 * le aplicamos el estilo y su valor junto a un dataset para vincular con el clon del stroke
	 * @param value Color
	 */
	const applyColorFinalNodesStyle = (value: Color) => {
		let isWholeTextSelected;

		let selectionNode;

		// Comprobamos si el nodo seleccionado no pertenece al texto para seleccionar el texto completo
		if (selection.value) {
			selectionNode =
				selection.value.selection?.anchorNode?.nodeType === 1
					? (selection.value.selection?.anchorNode as HTMLElement)
					: (selection.value.selection?.anchorNode?.parentElement as HTMLElement);
		}

		// Comprobamos si la selección completa de los textos se ha hecho con el puntero
		if (
			(selection.value?.selection?.isCollapsed && !domNode.value?.children.length) ||
			!selection.value ||
			(selectionNode && !selectionNode.closest(`[id$="${text.value.id}"]`)) ||
			(domNode.value &&
				selection.value.selection instanceof Selection &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value))
		) {
			isWholeTextSelected = true;
		}

		const finalSelectedNodes = TextTools.getSelectedNodes();

		let finalNodes;

		// Comprobamos si la selección ha encontrado algún nodo
		if (finalSelectedNodes && finalSelectedNodes.length > 0) {
			finalNodes = finalSelectedNodes;
		}

		// Si no es una selección completa, y uno de los nodos seleccionados no tiene ninguna letra seleccionada, lo eliminamos de la selección
		if (
			finalNodes &&
			!isWholeTextSelected &&
			!selection.value?.selection?.isCollapsed &&
			selection.value?.selection?.anchorNode &&
			selection.value.selection.focusNode
		) {
			TextSelectionTools.fixFinalnodesWhereHasEmptyNodes(finalNodes, selection);
		}

		// En caso de haber seleccionado el texto completo o no haber encontrado nodos, obtenemos los hijos del nodo raíz
		if (isWholeTextSelected || ((!finalNodes || (finalNodes && finalNodes?.length < 1)) && domNode.value)) {
			finalNodes = TextTools.getNodesFromRootNode(domNode.value as HTMLElement);
		}

		if (finalNodes) {
			const nodesToStyle = TextTools.getNodesToStyle(finalNodes);

			// finalmente, aplicamos los estilos a los nodos correspondientes
			nodesToStyle.forEach((el) => {
				let finalColor = value;

				if (value instanceof GradientColor) {
					finalColor = GradientColor.cloneGradientWithNewId(value);
				}

				const foundColor = text.value.colors.find((c) => c.toCssString() === finalColor.toCssString());

				// trackChangeColorToChildNodes(value, foundColor);

				// Eliminamos los colores que pudiese tener anteriormente
				Object.values(el.style).forEach((style) => {
					if (style.startsWith('--color-')) {
						el.style.removeProperty(style);
					}
				});

				if (!foundColor) {
					// Añadimos el nuevo color
					el.style.setProperty(`--${finalColor.id}`, finalColor.toCssString());
				} else {
					if (foundColor instanceof GradientColor && finalColor instanceof GradientColor) {
						foundColor.stops = finalColor.stops;
						foundColor.type = finalColor.type;
						foundColor.rotation = finalColor.rotation;
					} else if (foundColor instanceof SolidColor && finalColor instanceof SolidColor) {
						foundColor.r = finalColor.r;
						foundColor.g = finalColor.g;
						foundColor.b = finalColor.b;
						foundColor.a = finalColor.a;
					}

					// Añadimos el nuevo color
					el.style.setProperty(`--${foundColor.id}`, foundColor.toCssString());
				}

				applyColorsVarsToNodes();
			});
		}
	};

	/**
	 * Actualiza el fontWeight de la selección
	 * @param value Si recibe parámetro se aplica ese valor
	 */
	const updateFontWeight = async (value?: string) => {
		TextSelectionTools.fixTextSelection(domNode);

		let boldValue: string | undefined;

		// Si no ha llegado algún valor por parámetro comprobamos que tamaño se debe aplicar
		if (!value && domNode.value && selection.value?.selection) {
			let fw = text.value.fontWeight;
			// comprobamos si la seleccion es parte de un texto
			const selectAll = TextSelectionTools.detectFullRange(selection.value.selection, domNode.value);

			// En caso de tener 1 fuente seleccionada de un hijo y la selección es parte de un texto cogemos la fuente del hijo
			if (fontWeight.value.length === 1 && !selectAll) {
				fw = fontWeight.value[0];
			}

			boldValue = toggleFontWeight(fw);
			Bugsnag.leaveBreadcrumb(`Update font weight text-${text.value.id}: ${boldValue}`);
		} else {
			// Si ha llegado algún valor por parámetro
			if (value) boldValue = value;
			else if (boldAvailable.value) {
				boldValue = toggleFontWeight(text.value.fontWeight);
			}
		}

		if (boldValue && applyStyleToRootElement(StyleProperties.fontWeight, boldValue)) {
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			if (boldValue) {
				applyMultiFontWeightToFinalNodes(boldValue);
			}
		});
	};

	/**
	 * Toggle del fontWeight de la selección buscando su variante
	 * @param fw recibe el fontWeight actual
	 */

	const toggleFontWeight = (fw: number) => {
		let boldValue: string | undefined;
		fontVariants.value.forEach((font: { family: string; weight: string[] }) => {
			boldValue = fw < 600 ? font.weight.find((f) => parseInt(f) >= 600) : font.weight.find((f) => parseInt(f) === 400);
		});
		return boldValue;
	};

	/**
	 * Actualiza el fontWeight de la selección
	 * @param value Si recibe parámetro se aplica ese valor
	 */
	const updateFontStyle = (value?: boolean) => {
		TextSelectionTools.fixTextSelection(domNode);

		let result: string;

		if (
			(value !== false && italicAvailable.value && italic.value.length === 1 && text.value.fontStyle === 'normal') ||
			value
		) {
			result = 'italic';
			Bugsnag.leaveBreadcrumb(`Set font style to text-${text.value.id}: ${result}`);
		} else {
			result = 'normal';
			Bugsnag.leaveBreadcrumb(`Set font style to text-${text.value.id}: ${result}`);
		}

		if (applyStyleToRootElement(StyleProperties.fontStyle, result)) {
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		// Obtenemos los nodos seleccionados, y sacamos
		// el estilo que tienen para poder aplicar el contrario

		let selectedNodes: Node[] = [];

		if (selection.value?.selection) {
			selectedNodes = TextTools.getRangeSelectedNodes(selection.value.selection.getRangeAt(0));
		}

		if (selectedNodes.length) {
			selectedNodes.forEach((node) => {
				if (node.parentElement && node.parentElement.style.fontStyle.length) {
					result = node.parentElement.style.fontStyle === 'italic' ? 'normal' : 'italic';
				}
			});
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			applyFinalNodesStyle(StyleProperties.fontStyle, result);
		});
	};

	/**
	 * Se encarga de actualizar el font Family de una selección de texto
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateFontFamily = async (font: Font) => {
		if (
			selection.value?.selection &&
			domNode.value &&
			TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)
		) {
			resetFont();
		}

		if (previousInputSelection.value) {
			TextSelectionTools.createRange(previousInputSelection.value, domNode);

			previousInputSelection.value = null;
		}

		TextSelectionTools.fixTextSelection(domNode);

		await loadFontsByName([font.name]);

		// Asignamos comillas dobles a la fuente para que asigne
		// correctamente las fuentes que contengan números
		if (applyFontFamilyToRootElement(font.slug)) {
			Bugsnag.leaveBreadcrumb(`set font ${font.slug} to text-${text.value.id}`);
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// Asignamos comillas dobles a la fuente para que asigne
			// correctamente las fuentes que contengan números
			applyFinalNodesStyle(StyleProperties.fontFamily, `"${font.slug}"`);
			Bugsnag.leaveBreadcrumb(`set font ${font.slug} to child nodes from text-${text.value.id}`);

			// Si el peso de la fuente no está dentro de los pesos de la familia de fuentes, le aplicamos la primera
			applyValidWeight(font.slug);

			// Comprobamos si todo el contenido usa la misma fuente, si es así lo unificamos
			unifyFontFamilies();
		});
	};

	const getTextNodesIn = (node: any) => {
		const textNodes: any = [];

		const getTextNodes = (node: any) => {
			if (node.nodeType == 3) {
				textNodes.push(node);
			} else {
				for (let i = 0, len = node.childNodes.length; i < len; ++i) {
					getTextNodes(node.childNodes[i]);
				}
			}
		};

		getTextNodes(node);
		return textNodes;
	};

	const unifyFontFamilies = () => {
		// Recolectamos el nombre de las fuentes del texto
		const textNodes = getTextNodesIn(domNode.value);
		let fontFamiliesInText = textNodes.map((textNode: any) => {
			let fontFamily = textNode.parentElement.style.fontFamily;
			let parent = textNode.parentElement.parentElement;

			while (!fontFamily) {
				fontFamily = parent.style.fontFamily;
				parent = parent.parentElement;
			}

			return fontFamily;
		});
		fontFamiliesInText = [...new Set(fontFamiliesInText)];

		// Si solo hay 1 unificamos, si no, no hacemos nada
		if (domNode.value && fontFamiliesInText.length === 1) {
			domNode.value.querySelectorAll('[style*="font-family"]').forEach((el: any) => (el.style.fontFamily = null));
			text.value.fontFamily = fontFamiliesInText[0];
		}
	};

	/**
	 * Aplicamos el lineHeight al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para el lineHeight
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyLineHeightToRootElement = (value: number[], direction?: string): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(textEditing.value && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// Comprobamos si tenemos más de un valor de lineHeight y si se ha seleccionado alguna dirección y se lo aplicamos a sus hijos
			if (direction && value.length > 1) {
				// Si hemos cambiado el lineHeight desde las flechas parseamos el lineHeight que tenemos en el store y buscamos el que le corresponde al root
				const rootSize = MathTools.toFixedOrInt(text.value[StyleProperties.lineHeight]);
				const result = value.find((size) => (direction === 'plus' ? size === rootSize + 0.1 : size === rootSize - 0.1));

				text.value[StyleProperties.lineHeight] = result || value[0];
				applyMultiLineHeightToFinalNodes(value, direction);
			} else {
				// En caso contrario es porque solo hay un valor posible para aplicar a todo el texto y se limpiaraán los hijos
				text.value[StyleProperties.lineHeight] = value[0];

				if (domNode.value && domNode.value?.children.length) {
					resetChildrenStyle(StyleProperties.lineHeight);
				}
			}

			return true;
		}

		return false;
	};

	/**
	 * Se encarga de actualizar el lineHeight
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateLineHeight = (value: number | 'plus' | 'minus') => {
		TextSelectionTools.fixTextSelection(domNode);

		let finalValue: number[] = [];

		// En caso de ser 'plus' o 'minus' calculamos todos los nuevos valores para la selección
		if (typeof value === 'string') {
			finalValue = lineHeight.value.map((el) =>
				value === 'plus' ? MathTools.toFixedOrInt(el + 0.1) : MathTools.toFixedOrInt(el - 0.1)
			);

			breadScrumbWithDebounce('lineHeight');
		} else {
			finalValue = [MathTools.toFixedOrInt(value)];
		}

		if ((finalValue.length === 1 && finalValue[0] <= 0) || !finalValue.length) {
			return;
		}

		// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
		// para poder saber que valor corresponde al root y a cada uno de los posibles hijos
		if (typeof value === 'string') {
			if (applyLineHeightToRootElement(finalValue, value)) {
				return;
			}
		} else if (applyLineHeightToRootElement(finalValue)) {
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
			// para poder saber que valor corresponde a cada uno de los posibles hijos
			if (typeof value === 'string') {
				applyMultiLineHeightToFinalNodes(finalValue, value);
			} else {
				applyMultiLineHeightToFinalNodes(finalValue);
			}
		});
	};

	/**
	 * Aplicamos el letterSpacing al root del texto y eliminamos los estilos de sus hijos si está seleccionado al completo
	 * @param value Valor para el letterSpacing
	 * @returns Boolean en función de si se ha seleccionado el texto completo
	 */
	const applyLetterSpacingToRootElement = (value: number[], direction?: string): boolean => {
		// si no hemos seleccionado nada, actualizamos los valores raiz
		// quitamos los estilos de los hijos que coincidan
		if (
			!textEditing.value ||
			(selection.value?.selection &&
				domNode.value &&
				TextSelectionTools.detectFullRange(selection.value.selection, domNode.value)) ||
			(textEditing.value && selection.value === null) || // CUIDADO CON ESTA CONDICIÓN: ¿Sobra porque la de abajo hace lo mismo?
			(text.value && !selection.value) //CUIDADO CON ESTA CONDICIÓN: SE HA AÑADIDO PARA CONTROLAR CUANDO SE HACE CLICK EN EL ELEMENTO PERO SIN HACER SELECCIÓN
		) {
			// Comprobamos si tenemos más de un valor de letterSpacing y si se ha seleccionado alguna dirección y se lo aplicamos a sus hijos
			if (direction && value.length > 1) {
				// Si hemos cambiado el letterSpacing desde las flechas parseamos el letterSpacing que tenemos en el store y buscamos el que le corresponde al root
				const rootSize = MathTools.toFixedOrInt(text.value[StyleProperties.letterSpacing]);
				const result = value.find((size) =>
					direction === 'plus'
						? size === MathTools.toFixedOrInt(rootSize + 0.1)
						: size === MathTools.toFixedOrInt(rootSize - 0.1)
				);

				text.value[StyleProperties.letterSpacing] = result
					? MathTools.toFixedOrInt(result)
					: MathTools.toFixedOrInt(value[0]);
				applyMultiLetterSpacingToFinalNodes(value, direction);
			} else {
				// En caso contrario es porque solo hay un valor posible para aplicar a todo el texto y se limpiaraán los hijos
				text.value[StyleProperties.letterSpacing] = MathTools.toFixedOrInt(value[0]);

				if (domNode.value && domNode.value?.children.length) {
					resetChildrenStyle(StyleProperties.letterSpacing);
				}
			}

			return true;
		}

		return false;
	};

	/**
	 * Se encarga de actualizar el letterSpacing
	 * @param value Puede ser un número o 'plus' o 'minus' en función de si estamos incrementando,
	 *              decrementando o seleccionando un valor específico
	 */
	const updateLetterSpacing = (value: number | 'plus' | 'minus') => {
		TextSelectionTools.fixTextSelection(domNode);

		let finalValue: number[];

		// En caso de ser 'plus' o 'minus' calculamos todos los nuevos valores para la selección
		if (typeof value === 'string') {
			finalValue = letterSpacing.value.map((el) =>
				value === 'plus'
					? MathTools.toFixedOrInt((MathTools.toFixedOrInt(el) + 0.1) * text.value.scale)
					: MathTools.toFixedOrInt((MathTools.toFixedOrInt(el) - 0.1) * text.value.scale)
			);
			breadScrumbWithDebounce('letterSpacing');
		} else {
			finalValue = [value * text.value.scale];
		}

		// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
		// para poder saber que valor corresponde al root y a cada uno de los posibles hijos
		if (typeof value === 'string') {
			if (applyLetterSpacingToRootElement(finalValue, value)) {
				return;
			}
		} else if (applyLetterSpacingToRootElement(finalValue)) {
			return;
		}

		if (!domNode.value) {
			console.warn('Element not found');
			return;
		}

		generateSpansAndApplyStylesToChildNodes(() => {
			// En caso de ser 'plus' o 'minus' pasamos el array de valores finales y la dirección
			// para poder saber que valor corresponde a cada uno de los posibles hijos
			if (typeof value === 'string') {
				applyMultiLetterSpacingToFinalNodes(finalValue, value);
			} else {
				applyMultiLetterSpacingToFinalNodes(finalValue);
			}
		});
	};

	const updateTextTransform = (value: TextTransform) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textTransform = value;
		Bugsnag.leaveBreadcrumb(`Capitalize text-${text.value.id}: ${value}`);
	};

	const updateTextAlign = (value: TextAlign) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textAlign = value;
		Bugsnag.leaveBreadcrumb(`Align text-${text.value.id} to ${value}`);
	};

	const updateBorderColor = (color: SolidColor) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.outline.color = color;
	};
	const updateBorderWidth = (width: number) => {
		TextSelectionTools.fixTextSelection(domNode);

		if (text.value.outline.unit) delete text.value.outline.unit;
		text.value.outline.width = width;
	};

	const updateShadowAngle = (index: number, angle: number) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textShadow[index].angle = angle;
		if (angle) breadScrumbWithDebounce('shadowAngle');
	};

	const updateShadowColor = (index: number, color: SolidColor) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textShadow[index].color = color;
		if (color.r && color.g && color.b) breadScrumbWithDebounce('shadowColor');
	};

	const updateShadowOpacity = (index: number, opacity: number) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textShadow[index].opacity = opacity;
		if (opacity) breadScrumbWithDebounce('shadowOpacity');
	};

	const updateShadowDistance = (index: number, distance: number) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textShadow[index].distance = distance;
		if (distance) breadScrumbWithDebounce('shadowDistance');
	};

	const updateShadowBlur = (index: number, blur: number) => {
		TextSelectionTools.fixTextSelection(domNode);

		text.value.textShadow[index].blur = blur;
		if (blur) breadScrumbWithDebounce('shadowBlur');
	};

	/**
	 * Asignamos contenteditable a un nodo texto que no lo tenga para crear selecciones de texto en dicho nodo
	 * @param node Nodo HTML
	 */
	const setTemporalContentEditable = (node: HTMLElement) => {
		const isEditable = node.contentEditable !== 'inherit' ? node.contentEditable : false;

		if (!isEditable || isEditable === 'inherit') {
			node.setAttribute('contenteditable', 'true');
		}

		return isEditable;
	};

	/**
	 * Eliminamos contenteditable a un nodo texto
	 * @param node Nodo HTML
	 */
	const removeTemporalContentEditable = (node: HTMLElement) => {
		node.removeAttribute('contenteditable');
	};

	const updateList = async (type: ListStyle) => {
		TextSelectionTools.fixTextSelection(domNode);

		let oldRange;
		let oldOffset;

		if (domNode.value) {
			const isEditable = setTemporalContentEditable(domNode.value);

			// Si domNode.value.id es '' es porque no hay un texto editable seleccionado
			const textSelected = domNode.value.id !== '';

			if (!textSelected) {
				// Borrar cualquier selección actual
				document.getSelection()?.removeAllRanges();

				// Seleccionar párrafo
				await TextSelectionTools.selectAllText(domNode);
			}

			const caretSelection = selection.value?.selection?.isCollapsed;

			let lineIsNotEmpty = true;

			// Asignamos un nuevo rango para cuando se haya hecho una selección con el caret
			if (caretSelection && textSelected) {
				oldRange = selection.value?.selection?.getRangeAt(0).cloneRange();
				oldOffset = selection.value?.selection?.anchorOffset;

				if (
					selection.value?.selection?.anchorNode?.textContent &&
					(selection.value?.selection?.anchorNode?.textContent as string).length > 0
				) {
					lineIsNotEmpty = false;
				}

				const range = new Range();
				if (oldRange?.endContainer) {
					range.selectNodeContents(oldRange?.endContainer);
				}

				selection.value?.selection?.removeAllRanges();
				selection.value?.selection?.addRange(range);
			}

			if (!caretSelection || (caretSelection && !lineIsNotEmpty)) {
				if (type === '') {
					type = text.value.listStyle;
				}
				document.execCommand(type === 'ordered' ? 'insertOrderedList' : 'insertUnorderedList', false);
			}

			let list;

			const focusNode = (selection.value?.selection?.focusNode || domNode.value) as HTMLElement;
			const parentFocusNode = focusNode?.parentNode as HTMLElement;

			if (focusNode) {
				if (focusNode.nodeType === 3 && parentFocusNode) {
					list = parentFocusNode.closest(type === 'ordered' ? 'ol' : 'ul');
				} else if (focusNode?.getAttribute('contenteditable')) {
					list = focusNode?.querySelector(type === 'ordered' ? 'ol' : 'ul');
				} else {
					list = focusNode?.closest(type === 'ordered' ? 'ol' : 'ul');
				}
			}

			if (list) {
				list.style.listStyleType = type === 'ordered' ? 'decimal' : 'disc';
				const fontSize = Array.isArray(fontSizeScaled.value) ? fontSizeScaled.value[0] : fontSizeScaled.value;
				list.style.marginLeft = `${fontSize * 1.2}px`;
			}

			// Al deshacer la lista, comprobamos si los estilos del span creado son iguales al del padre
			if (parentFocusNode) {
				const baseDiv = parentFocusNode.closest('foreignObject')?.lastElementChild as HTMLElement;

				if (!list && baseDiv && parentFocusNode && !baseDiv.isEqualNode(parentFocusNode)) {
					const selectionStyles = TextTools.styleTextToObj(parentFocusNode.style.cssText);
					const baseStyles = TextTools.styleTextToObj(baseDiv.style.cssText);

					const equalsStyles = selectionStyles.every(
						(style: any) =>
							baseStyles.find((bStyle: any) => bStyle.name === style.name && bStyle.value === style.value) !== undefined
					);

					// Si son iguales eliminamos el span, nos quedamos solo con el texto y
					// restauramos la selección
					if (equalsStyles) {
						parentFocusNode.nextSibling?.remove();
						parentFocusNode.outerHTML = parentFocusNode.innerHTML;

						const newRange = document.createRange();
						newRange.selectNodeContents(parentFocusNode);
						selection.value?.selection?.removeAllRanges();
						selection.value?.selection?.addRange(newRange);
					}
				}
			}

			if (!isEditable) {
				removeTemporalContentEditable(domNode.value);
			}

			// En caso de existir una selección de tipo caret restauramos la antigua selección
			if (oldRange && oldOffset) {
				const anchorNode = selection.value?.selection?.anchorNode;

				selection.value?.selection?.removeAllRanges();
				const temporalRange = new Range();

				if (anchorNode) {
					temporalRange.setStart(anchorNode, oldOffset);
					temporalRange.setStart(anchorNode, oldOffset);
				}

				selection.value?.selection?.addRange(temporalRange);
			}
		}

		text.value.listStyle = text.value.listStyle === type ? '' : type;
		// En caso de no existir un textEditing, actualizamos el contenido del texto
		if (!textEditing.value) {
			text.value.content = domNode.value?.innerHTML || text.value.content;
		}

		Bugsnag.leaveBreadcrumb(`set ${text.value.listStyle} list to text-${text.value.id}`);
	};

	const rescaleText = () => {
		// Reseteamos el valor del Root del texto
		text.value.fontSize = text.value.fontSize * text.value.scale;
		text.value.letterSpacing = text.value.letterSpacing * text.value.scale;

		const children = domNode.value?.querySelectorAll('*');

		// En caso de tener nodos hijo seteamos el fontSize para su posterior recomputado
		if (children?.length) {
			const childrenArray = Array.from(children) as HTMLElement[];

			childrenArray.forEach((element) => {
				const fontSize = element.style[StyleProperties.fontSize];
				const letterSpacing = element.style[StyleProperties.letterSpacing];

				if (fontSize.length) {
					element.style[StyleProperties.fontSize] = `${parseFloat(fontSize) * text.value.scale}px`;
				}
				if (letterSpacing.length) {
					element.style[StyleProperties.letterSpacing] = `${parseFloat(letterSpacing) * text.value.scale}px`;
				}
			});
		}

		// A continuación, se reasigna la variable reactiva selection para que se recomputen todos los valores de los textos
		text.value.setScale(1);

		if (selection.value?.selection instanceof Selection) {
			const anchorNode = selection.value?.selection.anchorNode;
			const anchorOffset = selection.value?.selection.anchorOffset;
			const focusNode = selection.value?.selection.focusNode;
			const focusOffset = selection.value?.selection.focusOffset;
			const isCollapsed = selection.value?.selection.isCollapsed;

			nextTick(() => {
				if (anchorNode && anchorOffset && focusNode && focusOffset && isCollapsed) {
					TextSelectionTools.restoreSelection({
						anchorNode,
						anchorOffset,
						focusNode,
						focusOffset,
						isCollapsed,
						selection: selection.value,
						domNode: domNode.value,
					});
				}
			});
		}
	};

	return {
		domNode,
		colors,
		selectedColor,
		lineHeight,
		lineHeightLabel,
		removeTemporalContentEditable,
		fontSize,
		fontSizeScaled,
		fontFamily,
		finalFontFamily,
		fontWeight,
		italic,
		fontVariants,
		italicAvailable,
		boldAvailable,
		textTransform,
		letterSpacing,
		outline,
		textShadow,
		listStyle,
		isMultiStyleText,
		updateFontSize,
		updateColor,
		updateFontFamily,
		updateLineHeight,
		updateFontWeight,
		updateFontStyle,
		updateTextTransform,
		updateLetterSpacing,
		updateTextAlign,
		updateBorderColor,
		updateBorderWidth,
		setTemporalContentEditable,
		updateShadowAngle,
		updateShadowColor,
		updateShadowOpacity,
		updateShadowDistance,
		updateShadowBlur,
		updateList,
		rescaleText,
		resetTextShadow,
	};
};
