import { getIndicesBetween } from '@tldraw/indices';
import { computed, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useCollisionGroupInfo } from '@/collision/composables/useCollisionGroupInfo';
import Element from '@/elements/element/classes/Element';
import { useElementOrder } from '@/elements/element/composables/useElementOrder';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useGroup } from '@/elements/group/composables/useGroup';

export const useGroupOrder = (element: Ref<Element>) => {
	const temporalRef = ref<Element>(element.value);
	const { page } = usePageElement(element);
	const { group } = useGroup(element);
	const { group: targetGroup } = useGroup(temporalRef);
	const { firstPosition, lastPosition } = useElementOrder(element);
	const { collisioningWithUngroupedElements } = useCollisionGroupInfo(element);

	const collisioningSortedElementsOver = computed(() =>
		collisioningWithUngroupedElements.value.filter((el: Element) => el.index > maxIndexGroup.value)
	);
	const collisioningSortedElementsUnder = computed(() =>
		collisioningWithUngroupedElements.value.filter((el: Element) => el.index < minIndexGroup.value).reverse()
	);

	const maxIndexGroup = computed(() => group.value[group.value.length - 1].index);
	const minIndexGroup = computed(() => group.value[0].index);

	const canGoDown = computed(
		() =>
			!!collisioningSortedElementsUnder.value.length &&
			minIndexGroup.value >= page.value.elementsAsArray()[firstPosition.value].index
	);
	const canGoUp = computed(
		() =>
			!!collisioningSortedElementsOver.value.length &&
			maxIndexGroup.value < page.value.elementsAsArray()[page.value.elements.size - 1].index
	);

	const moveBetweenIndex = (below: Element | undefined, above: Element | undefined) => {
		const sortedElements = [...group.value];
		const indexes = getIndicesBetween(below?.index, above?.index, sortedElements.length);

		sortedElements.forEach((el, i) => {
			el.index = indexes[i];
		});
	};

	const moveUp = () => {
		GAnalytics.track('click', 'Button', 'forward', null);
		const elements = page.value.elementsAsArray();
		if (collisioningSortedElementsOver.value[0].group) {
			temporalRef.value = collisioningSortedElementsOver.value[0];
			const index = elements.findIndex((el) => el.id === targetGroup.value[targetGroup.value.length - 1].id);
			moveBetweenIndex(elements[index], elements[index + 1]);
			return;
		}

		const index = elements.findIndex((el) => el.id === collisioningSortedElementsOver.value[0].id);
		moveBetweenIndex(elements[index], elements[index + 1]);
	};

	const moveDown = () => {
		GAnalytics.track('click', 'Button', 'backward', null);
		const elements = page.value.elementsAsArray();
		if (collisioningSortedElementsUnder.value[collisioningSortedElementsUnder.value.length - 1].group) {
			temporalRef.value = collisioningSortedElementsUnder.value[collisioningSortedElementsUnder.value.length - 1];
			const index = elements.findIndex((el) => el.id === targetGroup.value[0].id);
			moveBetweenIndex(elements[index - 1], elements[index]);
			return;
		}

		const index = elements.findIndex((el) => el.id === collisioningSortedElementsUnder.value[0].id);
		moveBetweenIndex(elements[index - 1], elements[index]);
	};

	const moveToFront = () => {
		GAnalytics.track('click', 'Button', 'front', null);
		moveBetweenIndex(page.value.elementsAsArray()[lastPosition.value], undefined);
	};

	const moveToBack = () => {
		GAnalytics.track('click', 'Button', 'back', null);
		const elements = page.value.elementsAsArray();
		// Si tiene firstPosition es que tiene un elemento como background la page, por lo que queremos moverlo
		// entre ese background y el siguiente elemento y no entre ningún elemento y el primero.
		moveBetweenIndex(firstPosition.value ? elements[0] : undefined, elements[firstPosition.value]);
	};

	return {
		canGoDown,
		canGoUp,
		firstPosition,
		moveUp,
		moveDown,
		moveToBack,
		moveToFront,
		moveBetweenIndex,
	};
};
