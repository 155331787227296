import { useI18n } from '@/i18n/useI18n';
import { ElementType, InsertableApiType } from '@/Types/types';
const { trans } = useI18n();

export const elementTypes: ElementType[] = [
	// Flaticon
	{
		id: InsertableApiType.Flaticon,
		name: trans('Icons'),
		elements: [],
		order: 1,
	},
	// Stories
	{
		id: InsertableApiType.Storyset,
		name: trans('Illustrations'),
		elements: [],
		order: 5,
	},
	// Stickers
	{
		id: InsertableApiType.Sticker,
		name: trans('Stickers'),
		elements: [],
		order: 4,
	},
	// Basic forms
	{
		id: InsertableApiType.BasicShape,
		name: trans('Basic shapes'),
		elements: [],
		order: 2,
	},
	// Image mask
	{
		id: InsertableApiType.ImageMask,
		name: trans('Images mask'),
		elements: [],
		order: 6,
	},
	// Lines and Arrows
	{
		id: InsertableApiType.LineAndArrow,
		name: trans('Lines and Arrows'),
		elements: [],
		order: 3,
	},
];
