<script setup lang="ts">
import { computed, toRef } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import NumberInput from '@/common/components/NumberInput.vue';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Text }>();

// Using composables
const element = toRef(props, 'element');
const { textEditing } = useTextEditing();
const { letterSpacing, updateLetterSpacing, rescaleText } = useTextStyles(element);
const { breadScrumbWithDebounce } = useBugsnag(element);

const updateTextLetterSpacing = (letterSpacing: number | 'plus' | 'minus') => {
	if (element.value.scale !== 1) {
		rescaleText();
	}

	updateLetterSpacing(letterSpacing);
	breadScrumbWithDebounce('letterSpacing');
};

// Methods
const { trans } = useI18n();

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div class="flex flex-col">
		<NumberInput
			class="h-8 text-sm"
			:class="{ 'input-notAllowed': textEditing }"
			data-testid="input-spacing"
			:name="'input-spacing'"
			:placeholder="'Mix'"
			:step="0.1"
			:value="letterSpacing"
			@track-step="trackStepEvent('text-letter-spacing')"
			@track-typing="trackTypingEvent('text-letter-spacing')"
			@update="updateTextLetterSpacing"
			@update-array-one-step="updateTextLetterSpacing"
		/>
		<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Spacing') }}</label>
	</div>
</template>
