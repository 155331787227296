<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, ref, watch } from 'vue';

import { getElementsByType } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import LoadingImage from '@/common/components/LoadingImage.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { elementTypes } from '@/data/ElementTypes';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useProjectStore } from '@/project/stores/project';
import {
	BasicShapeApi,
	DataType,
	FlaticonElementApi,
	MaskApi,
	StickersElementApi,
	StorysetApi,
} from '@/Types/apiClient';
import { ElementCategory, ElementsPanelTypes, ElementType, InsertableApiType } from '@/Types/types';

const props = defineProps<{ activeElementCategory: ElementCategory | null }>();

const store = useMainStore();
const { trans } = useI18n();
const project = useProjectStore();
const { isMobile } = useDeviceInfo();
const { addInsertableElement } = useAddInsertableElement();

const fetchOptions = { concat: false, refetch: true };

const pages = ref({
	basicShapes: 1,
	flaticon: 1,
	masks: 1,
	stickers: 1,
	storysets: 1,
	lines: 1,
});

const activeElementCategory = computed(() => props.activeElementCategory);

const query = computed(() => project.flaticonSearch || project.category || activeElementCategory.value?.name || '');

const { isFetching, basicShapes, flaticons, masks, stickers, storysets, lines } = getElementsByType(
	pages,
	fetchOptions,
	query,
	false,
	true
);

const sortedElementTypes = ref(elementTypes.sort((a, b) => a.order - b.order));

const ELEMENT_URL_CONFIG = {
	LIMIT: 10,
	PAGE: 1,
	ORDER: 'recent',
};

const getPreviewsContainerClasses = (id: InsertableApiType): string => {
	if (
		[
			InsertableApiType.Flaticon,
			InsertableApiType.BasicShape,
			InsertableApiType.LineAndArrow,
			InsertableApiType.ImageMask,
		].includes(id)
	) {
		return `h-18 gap-6`;
	}
	if ([InsertableApiType.Sticker].includes(id)) {
		return `h-auto gap-5`;
	}
	if ([InsertableApiType.Storyset].includes(id)) {
		return `h-auto gap-2`;
	}

	return '';
};

const setCategory = (id: InsertableApiType) => {
	if (id === InsertableApiType.Flaticon) store.activeElementType = ElementsPanelTypes.Flaticon;
	if (id === InsertableApiType.Storyset) store.activeElementType = ElementsPanelTypes.Storysets;
	if (id === InsertableApiType.Sticker) store.activeElementType = ElementsPanelTypes.Stickers;
	if (id === InsertableApiType.BasicShape) store.activeElementType = ElementsPanelTypes.BasicShapes;
	if (id === InsertableApiType.ImageMask) store.activeElementType = ElementsPanelTypes.ImageMasks;
	if (id === InsertableApiType.LineAndArrow) store.activeElementType = ElementsPanelTypes.LinesAndArrows;

	Bugsnag.leaveBreadcrumb(`select category ${id}`);
};

const getElementsByCategoryId = (id: string) => {
	if (id === InsertableApiType.Flaticon) return flaticons.value?.data as FlaticonElementApi[];
	if (id === InsertableApiType.BasicShape) return basicShapes.value?.data as BasicShapeApi[];
	if (id === InsertableApiType.LineAndArrow) return lines.value?.data as MaskApi[];
	if (id === InsertableApiType.Sticker) return stickers.value?.data as StickersElementApi[];
	if (id === InsertableApiType.ImageMask) return masks.value?.data as MaskApi[];
	if (id === InsertableApiType.Storyset) return storysets.value?.data as StorysetApi[];
	return [];
};

const updateCategories = () => {
	sortedElementTypes.value.forEach((elementType: ElementType) => {
		elementType.elements = getElementsByCategoryId(elementType.id);
	});
};

watch(isFetching, (newVal, oldVal) => {
	if (newVal && !oldVal) return;
	updateCategories();
});
</script>

<template>
	<div class="flex h-full w-full flex-col gap-4 overflow-y-auto pb-4 pr-1 text-gray-800 scrollbar-thumb-gray-600">
		<!-- Element categories -->
		<InfiniteLoading :data="sortedElementTypes || []" :is-fetching="isFetching" class="flex flex-col">
			<template #item="{ item }">
				<div
					v-if="'elements' in item && typeof item.id !== 'number'"
					class="group mb-4"
					:class="{ 'list-elements': !isMobile }"
				>
					<div class="mb-3 mt-0 flex items-center justify-between">
						<p class="font-semibold text-gray-100">{{ trans(item.name) }}</p>
						<button
							:data-test-category="item.name"
							data-testid="see-all"
							class="pr-4 text-xs font-bold text-gray-100 group-hover:opacity-100 hover:text-white lg:opacity-0"
							@click="setCategory(item.id)"
						>
							{{ trans('See all') }}
						</button>
					</div>
					<div
						class="flex pb-4 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
						:class="getPreviewsContainerClasses(item.id)"
					>
						<InsertableElement
							v-for="element in item.elements.slice(0, ELEMENT_URL_CONFIG.LIMIT)"
							:key="element.id"
							:data-test-item-panel="item.name"
							:data="element"
							:type="item.id === InsertableApiType.LineAndArrow ? InsertableApiType.LineAndArrow : element.type"
							:draggable="true"
							class="hover:opacity-80"
							@click.stop="addInsertableElement(element as DataType)"
						>
							<LoadingImage
								v-if="element.type === InsertableApiType.Flaticon"
								:alt="element.name"
								:classes="!element.multicolor && !element.isCustom ? 'opacity-50 invert w-10' : 'w-10'"
								:data-svg="element.svg"
								:src="element.preview"
							/>
							<LoadingImage
								v-if="
									element.type === InsertableApiType.BasicShape ||
									element.type === InsertableApiType.ImageMask ||
									element.type === InsertableApiType.LineAndArrow
								"
								:alt="element.name"
								:classes="item.id === InsertableApiType.LineAndArrow ? 'opacity-50 invert w-16' : 'w-10'"
								:src="element.type === InsertableApiType.ImageMask ? element.mask.element : element.svg"
							/>
							<LoadingImage
								v-if="element.type === InsertableApiType.Sticker"
								:alt="element.name"
								:src="item.id === InsertableApiType.ImageMask ? element.svg : element.preview"
								:classes="'w-16'"
							/>
							<LoadingImage
								v-if="element.type === InsertableApiType.Storyset"
								:alt="element.illustration.name"
								:src="element.preview"
								:classes="'w-20 p-1 bg-gray-700 rounded'"
							/>
						</InsertableElement>
					</div>
				</div>
			</template>
		</InfiniteLoading>
	</div>
</template>
