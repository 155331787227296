<script setup lang="ts">
import { useUrlSearchParams } from '@vueuse/core';
import { computed } from 'vue';

import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useOfflineMode } from '@/common/composables/useOfflineMode';
import AlertLimitVideoDownload from '@/editor/components/AlertLimitVideoDownload.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import useNavigation from '@/editor/composables/useNavigation';
import { useMainStore } from '@/editor/stores/store';
import DownloadButton from '@/export/download/components/DownloadButton.vue';
import ShareButton from '@/export/share/components/ShareButton.vue';
import HistoryNavigation from '@/history/components/HistoryNavigation.vue';
import { useI18n } from '@/i18n/useI18n';
import AdminButton from '@/layout/components/AdminButton.vue';
import FixDpiButton from '@/layout/components/FixDpiButton.vue';
import PresentButton from '@/layout/components/PresentButton.vue';
import ProjectColorPicker from '@/layout/components/ProjectColorPicker.vue';
import SaveButton from '@/layout/components/SaveButton.vue';
import SavePredefinedTextButton from '@/layout/components/SavePredefinedTextButton.vue';
import UserMenu from '@/layout/components/UserMenu.vue';
import ArtboardPicker from '@/project/components/menus/ArtboardPicker.vue';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { Lang } from '@/Types/types';

const project = useProjectStore();
const store = useMainStore();
const { navigateWithoutLosingChanges } = useNavigation();
const { trans, locale, routes } = useI18n();
const { isOffline } = useOfflineMode();
const { projectIsPresentation, isAiPresentation } = useProject();
const urlToRedirect = routes?.home[locale.value as Lang];
const {
	isAdminMode,
	isEditorMode,
	isPredefinedTextMode,
	inSlidesgoContext,
	isReportContext,
	isIllustratorContext,
	isEmbeddedContext,
	isTagMode,
	isPhotoMode,
} = useEditorMode();
const { isMobile, isWebview } = useDeviceInfo();
const urlParams = useUrlSearchParams<{
	'fix-dpi'?: number;
}>();

// Events
const handleEnter = (e: KeyboardEvent) => {
	(e.target as HTMLElement).blur();
};

const modeName = computed(() => {
	if (isIllustratorContext.value) {
		return 'Modo illustrator';
	}

	if (isPredefinedTextMode.value) {
		return 'Modo crear texto predefinido';
	}

	if (isTagMode.value) {
		return 'Modo tag';
	}

	return 'Modo edición';
});
</script>
<template>
	<header
		data-testid="topbar"
		class="z-30 flex h-14 w-full shrink-0 items-center justify-between bg-gray-700 px-2 lg:px-4 lg:shadow-lg"
		:class="{ 'pointer-events-none': isOffline }"
	>
		<div class="flex flex-1 shrink-0 items-center gap-2 text-white lg:gap-4">
			<SvgIcon
				v-if="isEmbeddedContext"
				name="iso"
				class="ml-2 h-7 w-7 fill-current text-white opacity-90 group-hover:opacity-100"
			/>
			<a
				v-if="!isEmbeddedContext"
				href="https://wepik.com"
				tooltip-position="right"
				:tooltip="trans('Go to Wepik')"
				@click.prevent="navigateWithoutLosingChanges(urlToRedirect)"
			>
				<SvgIcon
					:name="isWebview ? 'return' : 'iso'"
					class="ml-2 h-7 w-7 fill-current text-white opacity-90 group-hover:opacity-100"
				/>
			</a>
			<div class="ml-1 flex items-center lg:ml-0 lg:gap-1">
				<ArtboardPicker
					v-if="
						!isPhotoMode &&
						!isIllustratorContext &&
						store.finishedLoading &&
						!isPredefinedTextMode &&
						!isEmbeddedContext
					"
				/>
				<hr class="hidden h-8 w-px border-0 bg-gray-600 lg:block" />
				<HistoryNavigation />
				<SaveButton v-if="store.finishedLoading" />
			</div>

			<span
				v-if="isAdminMode || isTagMode || isPredefinedTextMode"
				class="animate-bounce rounded bg-yellow-500 px-4 py-2 text-center text-xs font-bold uppercase text-white"
				:class="{
					'bg-[#F77800]': isIllustratorContext,
				}"
				style="animation-iteration-count: 2.5"
			>
				{{ modeName }}
			</span>
		</div>
		<div class="mr-2 flex items-center lg:mr-0">
			<label v-if="isEditorMode" class="relative hidden h-8 min-w-[20px] max-w-xs sm:block">
				<input
					v-model="project.name"
					maxlength="100"
					type="text"
					class="pii-cs absolute left-0 top-0 m-0 h-8 w-full text-ellipsis rounded bg-transparent px-3 text-gray-100 placeholder-gray-300 outline-none focus:bg-gray-800/50 focus:text-gray-100 focus:ring-1 focus:ring-white/25 hover:text-gray-100 hover:ring-1 hover:ring-white/25"
					:placeholder="project.category || trans('Untitled project')"
					@keyup.enter="handleEnter"
				/>
				<span class="pointer-events-none flex h-8 items-center whitespace-pre px-3 opacity-0">{{
					project.name || project.category || 'Untitled project'
				}}</span>
			</label>

			<div v-if="store.inSchedules" tooltip="Scheduled" tooltip-position="bottom">
				<span class="flex h-8 w-8 items-center justify-center text-white">
					<SvgIcon name="calendar" class="h-4 w-4 fill-white opacity-70" />
				</span>
			</div>
		</div>
		<div class="flex items-center justify-end gap-2 sm:pl-4 2xl:flex-1 2xl:pl-0">
			<ProjectColorPicker v-if="!isMobile" v-show="store.finishedLoading" />
			<div class="flex shrink-0 items-center gap-2">
				<PresentButton v-if="!inSlidesgoContext && !projectIsPresentation && !isEmbeddedContext && !isAiPresentation" />
				<ShareButton
					v-if="
						project.pages.length &&
						store.finishedLoading &&
						!isAdminMode &&
						!isTagMode &&
						!isWebview &&
						!isPredefinedTextMode &&
						!isEmbeddedContext
					"
				/>
				<DownloadButton v-if="!isAdminMode && !isPredefinedTextMode && !isTagMode" class="relative" />
				<FixDpiButton v-if="urlParams['fix-dpi'] && store.finishedLoading" />
				<AdminButton v-if="(isAdminMode || isTagMode) && store.finishedLoading" />
				<SavePredefinedTextButton v-else-if="isPredefinedTextMode" />
				<PresentButton
					v-else-if="(inSlidesgoContext || projectIsPresentation || isAiPresentation) && !isEmbeddedContext"
				/>
				<UserMenu v-if="!isMobile && !isWebview && !isEmbeddedContext" />
			</div>
		</div>
		<Popper v-if="isOffline" placement="bottom" class="z-10">
			<div
				class="mx-4 mt-14 flex h-12 items-center rounded-full bg-yellow-600 py-3 pl-2 pr-4 text-white lg:mx-0 lg:mt-3 lg:h-10"
			>
				<span class="mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-white lg:h-7 lg:w-7">
					<SvgIcon name="disconnected" class="mx-3 h-4 w-4 shrink-0 animate-pulse text-yellow-600 lg:h-4 lg:w-4" />
				</span>
				<p class="leading-none">You're currently offline, so we couldn't save your last changes.</p>
			</div>
		</Popper>
		<AlertLimitVideoDownload v-if="project.allVideos.length" />
	</header>
</template>
<style scoped>
input:after {
	content: attr(data-text);
}
</style>
