<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { Panels } from '@/Types/types';
const { trans } = useI18n();

const store = useMainStore();

// Using composables
const { isTouch } = useDeviceInfo();

// Props
const props = withDefaults(
	defineProps<{ disableClose?: boolean; modal?: boolean; title: string; showBackButton?: boolean }>(),
	{
		showBackButton: false,
	}
);

// Emits
const emit = defineEmits(['goBack', 'closeModal', 'closePanel']);

// Computeds
const computedActivePanel = computed(() => store.activePanel);

// Watchers
watch(computedActivePanel, (newVal) => {
	if (newVal !== Panels.templates) {
		store.activeTemplateCategory = null;
	}
});

// Methods
const onCloseModal = () => {
	if (props.disableClose) return;
	emit('closeModal');
};

const onClosePanel = () => {
	Bugsnag.leaveBreadcrumb(`Close ${store.activePanel} panel`);
	emit('closePanel');
	store.activePanel = null;
};

const onGoBack = () => emit('goBack');
</script>

<template>
	<div class="mb-3 flex items-center justify-between" :class="modal ? 'lg:mb-2' : 'lg:mb-4'">
		<div class="flex min-w-0 items-center">
			<button
				v-if="showBackButton"
				class="mr-1 h-6 w-6 cursor-pointer text-gray-100 hover:text-white"
				@click="onGoBack"
			>
				<SvgIcon name="return" class="h-6 w-6 fill-current" />
			</button>

			<slot name="title">
				<h3
					class="h-6 flex-1 truncate text-xl font-semibold leading-6"
					:class="modal ? 'text-gray-800' : 'text-gray-100'"
				>
					{{ trans(title) }}
				</h3>
			</slot>
		</div>

		<template v-if="!disableClose">
			<slot name="buttons"></slot>
			<button
				v-if="modal"
				data-testid="close-btn-img-modal"
				class="flex h-8 w-8 items-center justify-center rounded text-gray-700 hover:bg-gray-100/25 lg:bg-transparent"
				@click="onCloseModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 fill-current" />
			</button>
			<button
				v-else
				data-testid="hide-panel"
				class="group absolute right-0 top-0 mr-2 mt-2 flex h-8 w-8 scale-75 items-center justify-center rounded-full bg-gray-700 text-gray-100 hover:text-white lg:-right-3 lg:top-1/2 lg:-mt-14 lg:mr-0 lg:h-28 lg:w-4 lg:justify-end lg:rounded-none lg:bg-transparent"
				@click="onClosePanel"
			>
				<svg
					class="absolute right-0 top-0 hidden h-full w-full rotate-180 fill-current text-gray-800 lg:block"
					preserveAspectRatio="none"
					version="1.1"
					viewBox="0 0 15 96"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="m15 0h-3v1.01c-0.0011 3.42-0.91 8.97-4.27 12.3-0.178 0.175-0.353 0.347-0.527 0.518l-0.06 0.0589c-2.03 2-3.81 3.74-5.06 5.86-1.17 1.98-1.87 4.23-2.04 7.28h-0.0398v41c0 3.74 0.688 6.37 1.99 8.62 1.26 2.19 3.07 3.97 5.15 6.01l0.0625 0.0613c0.173 0.17 0.348 0.342 0.526 0.517 3.36 3.31 4.27 8.86 4.27 12.3v0.506h3z"
					></path>
				</svg>
				<SvgIcon v-if="isTouch" name="cross" class="relative z-10 h-5 w-5" />
				<SvgIcon
					v-else
					name="return"
					class="relative z-10 mr-1 h-6 w-6 shrink-0 fill-gray-300 group-hover:fill-white"
				/>
			</button>
		</template>
	</div>
</template>
