<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useBackgroundableElement } from '@/elements/element/composables/useBackgroundableElement';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
// Props
const props = defineProps<{ element: Image | Video }>();

// Data
const element = toRef(props, 'element');
const { trans } = useI18n();
const toast = useToast();
// Using composables
const { isPhotoMode } = useEditorMode();
const { canBeSetAsBackground, setElementAsBackground, removeElementAsBackground } = useBackgroundableElement(element);

const setAsBackground = () => {
	try {
		setElementAsBackground();
	} catch (e) {
		const message = EventTools.getErrorMessage(e);
		toast.error(trans(message));
	}
};
</script>

<template>
	<button
		v-if="element.locked"
		data-testid="to-background"
		class="relative flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
		@click="removeElementAsBackground"
	>
		<span
			class="absolute -top-2 right-2 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white hover:bg-blue-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
		>
			<SvgIcon name="lock" class="h-2 w-2" />
		</span>
		<SvgIcon name="background" class="h-4 w-4" />
		<label class="block whitespace-nowrap text-center text-2xs text-gray-100 lg:hidden">{{
			trans('Background')
		}}</label>
	</button>
	<button
		v-else
		data-testid="to-background"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
		:class="{
			'disabled cursor-not-allowed opacity-50': !canBeSetAsBackground || isPhotoMode,
			'hover:bg-gray-600 hover:text-white': canBeSetAsBackground && !isPhotoMode,
		}"
		:disabled="!canBeSetAsBackground || isPhotoMode"
		@click="setAsBackground"
	>
		<SvgIcon name="background" class="h-4 w-4" />
		<label class="block whitespace-nowrap text-center text-2xs text-gray-100 lg:hidden">{{
			trans('Background')
		}}</label>
	</button>
	<label class="mt-1 hidden text-center text-2xs leading-snug text-gray-100 lg:block">{{
		trans('Image to background')
	}}</label>
</template>
