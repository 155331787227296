import { computed, Ref, ref } from 'vue';

import { useCollisionInfoOrchestator } from '@/collision/composables/useCollisionInfoOrchestator';
import Element from '@/elements/element/classes/Element';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useGroup } from '@/elements/group/composables/useGroup';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { useInteractions } from '@/interactions/composables/useInteractions';

export const useCollisionGroupInfo = (element: Ref<Element>) => {
	const { group } = useGroup(element);
	const { isDragging } = useInteractions();

	const { page } = usePageElement(element);

	const temporalRef = ref<Element>(Shape.create());
	const collisionComposable = useCollisionInfoOrchestator(temporalRef);

	const collisioningWithUngroupedElements = computed(() => {
		if (isDragging.value) return [];

		const outOfGroupElements = page.value
			.elementsAsArray()
			.filter((el) => el.id !== page.value.backgroundImageId && !group.value.some((el1) => el1.id === el.id));
		if (!outOfGroupElements.length) return [];

		const collisioning = outOfGroupElements.filter((el) =>
			group.value.some((el1) => {
				temporalRef.value = el1;
				return collisionComposable.value.collisionFilterCb(el);
			})
		);

		return collisioning;
	});

	return {
		collisioningWithUngroupedElements,
	};
};
