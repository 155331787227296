import { DomNodesElement } from '@/elements/element/dom/DomNodesElement';
import { Text } from '@/elements/texts/text/classes/Text';
import { NodeOriginalData, NodeScaleTextData } from '@/Types/DomNodesTypes';

export class DomNodesText extends DomNodesElement {
	element: Text;
	constructor(element: Text) {
		super(element);
		this.element = element;
	}

	setScaleToFinalText(scale: number, isIOS = false) {
		if (!this.baseNode) return;

		const targetNode = this.element?.curvedProperties.arc ? '#clone-curved' : '.original-text';
		const textNodes = this.baseNode.querySelectorAll(`${targetNode}, .outline-text`) as NodeListOf<HTMLElement>;
		if (!textNodes.length) return;
		textNodes.forEach((node) => {
			node.style.transform = `scale(${scale})`;
		});

		// necesitamos reescalar el nodo que renderiza las sombras en IOS
		if (isIOS && this.element.outline?.width && this.element.textShadow.length) {
			const textShadowNode = this.baseNode.querySelector(`.text-shadow-ios`) as HTMLElement;
			textShadowNode.style.transform = `scale(${scale})`;
		}
	}

	setSizeToFinalTextNode(width: number, isIOS = false) {
		if (!this.baseNode) return;

		let targetNode = this.element?.curvedProperties.arc ? '#clone-curved' : '.original-text';
		if (!this.baseNode.querySelector(targetNode)) targetNode = '.original-text';
		const textNodes = this.baseNode.querySelectorAll(`${targetNode}, .outline-text`) as NodeListOf<HTMLElement>;

		const { scale } = this.scaleTextData as NodeScaleTextData;

		if (!textNodes.length) return;
		textNodes.forEach((node) => {
			node.style.width = `${width / (scale || 1)}px`;
		});

		// necesitamos reescalar el nodo que renderiza las sombras en IOS
		if (isIOS && this.element.outline?.width && this.element.textShadow.length) {
			const textShadowNode = this.baseNode.querySelector(`.text-shadow-ios`) as HTMLElement;
			textShadowNode.style.width = `${width / (scale || 1)}px`;
		}
	}
	get nodeData() {
		if (this.element instanceof Text) {
			return { ...(this.scaleTextData as NodeScaleTextData), ...(super.nodeData as NodeOriginalData) };
		}
	}

	get scaleTextData(): NodeScaleTextData | undefined {
		if (!this.baseNode) return;
		let targetNode = this.element?.curvedProperties.arc ? '#clone-curved' : '.original-text';
		if (!this.baseNode.querySelector(targetNode)) targetNode = '.original-text';
		const finalTextNode = this.baseNode.querySelector(targetNode) as HTMLElement;
		const regex = /[-+]?[0-9]*\.?[0-9]+/;
		const scale = finalTextNode.style.transform.match(regex) || ['1'];
		return { scale: scale && parseFloat(scale[0]) };
	}
}
