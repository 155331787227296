<script setup lang="ts">
import { Fn } from '@vueuse/core';
import { computed, toRef } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Dropdown from '@/common/components/Dropdown.vue';
import NumberInput from '@/common/components/NumberInput.vue';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Text }>();

// Using composables
const element = toRef(props, 'element');
const { textEditing } = useTextEditing();
const { breadScrumbWithDebounce } = useBugsnag(element);

const onUpdateRotation = (val: number) => {
	element.value.setRotation(val);
	breadScrumbWithDebounce('rotation');
	GAnalytics.track(`${window.event ? window.event.type : 'change'}`, 'Button', 'rotate-input', null);
};

// Methods
const isEditingText = computed(() => {
	return !!textEditing.value;
});
const { trans } = useI18n();

const rotationOptions = computed(() => [
	{ label: '0', value: 0 },
	{ label: '45', value: 45 },
	{ label: '90', value: 90 },
	{ label: '270', value: 270 },
]);

const checkIfDropdownShouldOpen = (e: Event, openDrowdown: Fn) => {
	if (e.target instanceof HTMLSpanElement && e.target.classList.contains('cursor-pointer')) {
		return;
	}
	openDrowdown();
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div class="flex flex-col">
		<Dropdown :options="rotationOptions" @update="(ev) => onUpdateRotation(ev.value)">
			<template #default="{ onToggleVisibility }">
				<NumberInput
					data-testid="input-rotation"
					class="h-8 w-full text-sm"
					:class="{ 'input-notAllowed': isEditingText }"
					:value="element.rotation"
					@track-typing="trackTypingEvent('text-rotation')"
					@track-step="trackStepEvent('text-rotation')"
					@update="(rotation) => onUpdateRotation(rotation)"
					@click="checkIfDropdownShouldOpen($event, onToggleVisibility)"
				/>
			</template>
		</Dropdown>
		<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Rotation') }}</label>
	</div>
</template>
