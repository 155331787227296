<script lang="ts" setup>
import { until } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import Moveable, { MoveableOptions, OnDrag } from 'moveable';
import { computed, onBeforeUnmount, Ref, ref, toRef, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { useElementRegardingPage } from '@/elements/element/composables/useElementRegardingPage';
import ElementTools from '@/elements/element/utils/ElementTools';
import Line from '@/elements/line/classes/Line';
import { useInteractions } from '@/interactions/composables/useInteractions';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProject } from '@/project/composables/useProject';
import { Position } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

// Props
const props = defineProps<{ element: Line }>();

// Data
const element = toRef(props, 'element');
const temporalRefPage = ref(Page.createDefault());

const store = useMainStore();
const { disableToolbar } = useInteractions();
const { isOutsidePage } = useElementRegardingPage(element);
const { removeElement } = usePage(temporalRefPage as Ref<Page>);
const { getPageFromElement } = useProject();

const handler1 = ref();
const handler2 = ref();
const handler1Moveable = ref<Moveable | null>(null);
const handler2Moveable = ref<Moveable | null>(null);
const transformHandler1 = ref({ x: 0, y: 0 });
const transformHandler2 = ref({ x: 0, y: 0 });
const handlerFocused = ref<number | null>(null);
const activePanel = computed(() => store.activePanel);
const infoPosition = computed(() => {
	const marginHandler1 = {
		x: element.value.rotation <= 295 && element.value.rotation >= 190 ? 15 : 0,
		y: element.value.rotation <= 295 && element.value.rotation >= 190 ? -30 : 15,
	};
	const marginHandler2 = {
		x: element.value.rotation <= 115 && element.value.rotation >= 15 ? -50 : 0,
		y: element.value.rotation <= 115 && element.value.rotation >= 15 ? -10 : 15,
	};
	const margin = handlerFocused.value === null || handlerFocused.value === 2 ? marginHandler2 : marginHandler1;

	if (handlerFocused.value === null || handlerFocused.value === 2) {
		return {
			x: transformHandler1.value.x + margin.x,
			y: transformHandler1.value.y + margin.y,
		};
	}

	return {
		x: transformHandler2.value.x + margin.x,
		y: transformHandler2.value.y + margin.y,
	};
});

const handlerSize = 10 / store.scale;
const lockAngles = [0, 22.5, 45, 67.5, 90, 112.5, 135, 157.5, 180, 202.5, 225, 247.5, 270, 292.5, 315, 337.5, 360];

const setHandlersPosition = () => {
	if (!store.activePage || !store.activePage.domNode()) return;

	// Calculamos la posición de los handlers
	const scrollArea = document.querySelector('#scroll-area') as HTMLElement;
	const origin = {
		x: element.value.position.x + element.value.size.width / 2 - handlerSize / 2,
		y: element.value.position.y + element.value.size.height / 2 - handlerSize / 2,
	};
	const absoluteOrigin = {
		x:
			origin.x * store.scale +
			(store.activePage.domNode() as HTMLElement).getBoundingClientRect().x -
			scrollArea.getBoundingClientRect().x +
			scrollArea.scrollLeft,
		y:
			origin.y * store.scale +
			(store.activePage.domNode() as HTMLElement).getBoundingClientRect().y -
			scrollArea.getBoundingClientRect().y +
			scrollArea.scrollTop,
	};

	const firstCoords = MathTools.rotatePoint(
		absoluteOrigin.x,
		absoluteOrigin.y,
		absoluteOrigin.x - (element.value.size.width / 2) * store.scale,
		absoluteOrigin.y,
		element.value.rotation
	);
	const lastCoords = MathTools.rotatePoint(
		absoluteOrigin.x,
		absoluteOrigin.y,
		absoluteOrigin.x + (element.value.size.width / 2) * store.scale,
		absoluteOrigin.y,
		element.value.rotation
	);

	handler1.value.style.transform = `translate(${firstCoords.x}px, ${firstCoords.y}px)`;
	handler2.value.style.transform = `translate(${lastCoords.x}px, ${lastCoords.y}px)`;

	transformHandler1.value = {
		x: firstCoords.x,
		y: firstCoords.y,
	};
	transformHandler2.value = {
		x: lastCoords.x,
		y: lastCoords.y,
	};
};

const dragLineHandler = (handler1Position: Position, handler2Position: Position, ev: OnDrag) => {
	// Los handlers están fuera de la página, en el scroll-area, así que hay que adaptar la posición
	// para calcular correctamente el tamaño y posición de la línea
	const scrollArea = document.querySelector('#scroll-area') as HTMLElement;
	const currentPageNode = (store.activePage as Page).domNode() as HTMLElement;
	const pageY = currentPageNode.getBoundingClientRect().y - scrollArea.getBoundingClientRect().y + scrollArea.scrollTop;
	const pageX =
		currentPageNode.getBoundingClientRect().x - scrollArea.getBoundingClientRect().x + scrollArea.scrollLeft;

	handler1Position.x = (handler1Position.x - pageX) / store.scale;
	handler1Position.y = (handler1Position.y - pageY) / store.scale;
	handler2Position.x = (handler2Position.x - pageX) / store.scale;
	handler2Position.y = (handler2Position.y - pageY) / store.scale;

	// La rotación y el ancho depende de los handlers
	let angle = MathTools.getAngle(handler1Position.x, handler1Position.y, handler2Position.x, handler2Position.y);
	const width = MathTools.getDistanceBetween2Points(
		handler1Position.x,
		handler1Position.y,
		handler2Position.x,
		handler2Position.y
	);

	// Posición real del elemento como tal
	const linePosition = {
		x: handler1Position.x + handlerSize / 2,
		y: handler1Position.y - element.value.size.height / 2 + handlerSize / 2,
	};

	// Centro del elemento para calcular el diff respecto a los handlers al mover la línea
	const origin = {
		x: linePosition.x + width / 2 - handlerSize / 2,
		y: linePosition.y + element.value.size.height / 2 - handlerSize / 2,
	};

	// Calculamos donde estará la línea tras la rotación para corregir su posición respecto al handler
	const rotatePosition = MathTools.rotatePoint(origin.x, origin.y, handler1Position.x, handler1Position.y, angle);

	const diff = {
		x: handler1Position.x - rotatePosition.x,
		y: handler1Position.y - rotatePosition.y,
	};

	if (ev.inputEvent.shiftKey) {
		// Buscamos el ángulo bloqueado más cercano
		let closestAngle = lockAngles.reduce((prev, curr) =>
			Math.abs(curr - angle) < Math.abs(prev - angle) ? curr : prev
		);

		// Para evitar saltos extraños cuando queremos usar líneas rectas
		if (closestAngle === 360) {
			closestAngle = 0;
		}

		if (ev.target.dataset.handler === '1') {
			// Calculamos el ángulo en base al handler 2 para poder calcular la posición del handler 1 bloqueado
			const supportAngle = MathTools.getAngle(
				handler2Position.x,
				handler2Position.y,
				handler1Position.x,
				handler1Position.y
			);

			// Buscamos el ángulo bloqueado más cercano
			let closestAngleHandler = lockAngles.reduce((prev, curr) =>
				Math.abs(curr - supportAngle) < Math.abs(prev - supportAngle) ? curr : prev
			);

			// Para evitar saltos extraños cuando queremos usar líneas rectas
			if (closestAngleHandler === 360) {
				closestAngleHandler = 0;
			}

			const newPosition = {
				x: handler2Position.x + handlerSize / 2 + width * Math.cos(MathTools.angleToRadians(closestAngleHandler)),
				y:
					handler2Position.y +
					handlerSize / 2 -
					element.value.size.height / 2 +
					width * Math.sin(MathTools.angleToRadians(closestAngleHandler)),
			};

			linePosition.x = newPosition.x;
			linePosition.y = newPosition.y;

			const tempHandlerPosition = {
				x: handler2Position.x + width * Math.cos(MathTools.angleToRadians(closestAngleHandler)),
				y: handler2Position.y + width * Math.sin(MathTools.angleToRadians(closestAngleHandler)),
			};

			const origin = {
				x: linePosition.x + width / 2 - handlerSize / 2,
				y: linePosition.y + element.value.size.height / 2 - handlerSize / 2,
			};

			const rotatePosition = MathTools.rotatePoint(
				origin.x,
				origin.y,
				tempHandlerPosition.x,
				tempHandlerPosition.y,
				closestAngle
			);

			diff.x = tempHandlerPosition.x - rotatePosition.x;
			diff.y = tempHandlerPosition.y - rotatePosition.y;
		} else {
			const rotatePosition = MathTools.rotatePoint(
				origin.x,
				origin.y,
				handler1Position.x,
				handler1Position.y,
				closestAngle
			);

			diff.x = handler1Position.x - rotatePosition.x;
			diff.y = handler1Position.y - rotatePosition.y;
		}

		angle = closestAngle;
	}

	store.$patch(() => {
		element.value.position = {
			x: linePosition.x + diff.x,
			y: linePosition.y + diff.y,
		};

		element.value.setRotation(angle);
		element.value.size.width = width;
	});
};

const setupLineHandlersEvents = () => {
	if (!handler1.value && !handler2.value) return;

	const moveableConfig: MoveableOptions = {
		renderDirections: false,
		hideDefaultLines: true,
		snappable: false,
		draggable: true,
		resizable: false,
		rotatable: false,
		pinchable: false,
		origin: false,
		keepRatio: true,
		edge: false,
		rootContainer: document.getElementById('scroll-area'),
		container: document.getElementById('scroll-area'),
		checkInput: true,
		className: 'moveable-lines',
	};

	handler1Moveable.value = new Moveable(document.body, {
		target: handler1.value,
		portalContainer: document.getElementById('portalTargetHandlerLine1'),
		...moveableConfig,
	});

	handler2Moveable.value = new Moveable(document.body, {
		target: handler2.value,
		portalContainer: document.getElementById('portalTargetHandlerLine2'),
		...moveableConfig,
	});

	handler1Moveable.value
		?.on('drag', (ev: OnDrag) => {
			const { beforeTranslate } = ev;

			// Obtenemos la posición del handler usando un transform
			const hanlder2Transform = ElementTools.getTransformValues((handler2.value as HTMLDivElement).style.transform);

			const handler1Position = {
				x: beforeTranslate[0],
				y: beforeTranslate[1],
			};

			const handler2Position = {
				x: hanlder2Transform[0],
				y: hanlder2Transform[1],
			};

			transformHandler1.value = {
				x: handler1Position.x,
				y: handler1Position.y,
			};

			dragLineHandler(handler1Position, handler2Position, ev);
		})
		.on('dragStart', () => dragStartHandler(1))
		.on('dragEnd', () => dragEndHandler());

	handler2Moveable.value
		?.on('drag', (ev: OnDrag) => {
			const { beforeTranslate } = ev;

			// Obtenemos la posición del handler usando un transform
			const hanlder1Transform = ElementTools.getTransformValues((handler1.value as HTMLDivElement).style.transform);

			const handler1Position = {
				x: hanlder1Transform[0],
				y: hanlder1Transform[1],
			};

			const handler2Position = {
				x: beforeTranslate[0],
				y: beforeTranslate[1],
			};

			transformHandler2.value = {
				x: handler2Position.x,
				y: handler2Position.y,
			};

			dragLineHandler(handler1Position, handler2Position, ev);
		})
		.on('dragStart', () => dragStartHandler(2))
		.on('dragEnd', () => dragEndHandler());
};

const dragStartHandler = (handler: number) => {
	handlerFocused.value = handler;
	disableToolbar.value = true;

	handler1.value.classList.add('opacity-0');
	handler2.value.classList.add('opacity-0');

	document.querySelector('#portalTarget')?.classList.add('opacity-0');
};

const dragEndHandler = () => {
	handlerFocused.value = null;
	disableToolbar.value = false;

	setHandlersPosition();
	handler1Moveable.value?.updateRect();
	handler2Moveable.value?.updateRect();

	handler1.value.classList.remove('opacity-0');
	handler2.value.classList.remove('opacity-0');

	document.querySelector('#portalTarget')?.classList.remove('opacity-0');

	// Si el elemento está completamente fuera de la página lo eliminamos
	if (isOutsidePage.value) {
		temporalRefPage.value = getPageFromElement(element.value) as Page;
		removeElement(element.value);
	}
};

const removeMoveableHandlers = () => {
	handler1Moveable.value?.destroy();
	handler2Moveable.value?.destroy();

	handler1Moveable.value = null;
	handler2Moveable.value = null;

	document.querySelector('#portalTargetHandlerLine1')?.removeAttribute('style');
	document.querySelector('#portalTargetHandlerLine2')?.removeAttribute('style');
};

until(computed(() => handler1.value !== undefined && handler2.value !== undefined))
	.toBeTruthy()
	.then(() => {
		setHandlersPosition();
		setupLineHandlersEvents();
	});

onBeforeUnmount(() => removeMoveableHandlers());

// Para actualizar los handler al modificar el elemento desde el panel
watch([props.element, activePanel], (newVal, oldVal) => {
	const panelOpened = !!document.querySelector('#line-panel');
	const activePanelChanged = newVal[1] !== oldVal[1];

	if (panelOpened || activePanelChanged) {
		setHandlersPosition();
		handler1Moveable.value?.updateRect();
		handler2Moveable.value?.updateRect();
	}
});
</script>

<template>
	<div
		ref="handler1"
		data-handler="1"
		class="line-handler h-[10px] w-[10px]"
		:style="{
			transform: `translate(${transformHandler1.x}px, ${transformHandler1.y}px)`,
		}"
	></div>
	<div
		ref="handler2"
		data-handler="2"
		class="line-handler h-[10px] w-[10px]"
		:style="{
			transform: `translate(${transformHandler2.x}px, ${transformHandler2.y}px)`,
		}"
	></div>
	<div
		v-if="handlerFocused"
		class="pointer-events-none absolute flex h-8 items-center rounded-full bg-gray-700/90 px-2 text-xs text-gray-100 shadow-lg backdrop-blur"
		:style="{
			transform: `translate(${infoPosition.x}px, ${infoPosition.y}px)`,
		}"
	>
		{{ element.rotation.toFixed(1) }}°
	</div>
</template>

<style lang="sass">
.moveable-element-line
	@apply pointer-events-none opacity-0

#portalTargetHandlerLine1[style], #portalTargetHandlerLine2[style]
	@apply pointer-events-none bg-white rounded-full border
	height: 10px
	width: 10px
	border-color: var(--moveable-color)

.line-handler
	@apply absolute left-0 top-0 cursor-move
	height: 10px
	width: 10px

#portalTargetHandlerLine1[class*='moveable-dragging'], #portalTargetHandlerLine2[class*='moveable-dragging'], .line-handler
	@apply opacity-0
</style>
