<script lang="ts" setup>
// Vue
import { onClickOutside } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import BlackAndWhitePresetPreview from '@/elements/medias/images/foreground/components/preset-preview/BlackAndWhitePresetPreview.vue';
import BlurPresetPreview from '@/elements/medias/images/foreground/components/preset-preview/BlurPresetPreview.vue';
import BrushBgPresetPreview from '@/elements/medias/images/foreground/components/preset-preview/BrushBgPresetPreview.vue';
import CircleBgPresetPreview from '@/elements/medias/images/foreground/components/preset-preview/CircleBgPresetPreview.vue';
import CircleShapePresetPreview from '@/elements/medias/images/foreground/components/preset-preview/CircleShapePresetPreview.vue';
import PatternPresetPreview from '@/elements/medias/images/foreground/components/preset-preview/PatternPresetPreview.vue';
import RemoveBgPresetPreview from '@/elements/medias/images/foreground/components/preset-preview/RemoveBgPresetPreview.vue';
import SquareBgPresetPreview from '@/elements/medias/images/foreground/components/preset-preview/SquareBgPresetPreview.vue';
import SquareShapePresetPreview from '@/elements/medias/images/foreground/components/preset-preview/SquareShapePresetPreview.vue';
import TitlePresetPreview from '@/elements/medias/images/foreground/components/preset-preview/TitlePresetPreview.vue';
import TriangleShapePresetPreview from '@/elements/medias/images/foreground/components/preset-preview/TriangleShapePresetPreview.vue';
import { useImagePresets } from '@/elements/medias/images/foreground/composables/useImagePresets';
import Image from '@/elements/medias/images/image/classes/Image';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { EditPanels } from '@/Types/types';

// Props
const props = defineProps<{ element: Image }>();
const emit = defineEmits<{
	(e: 'close'): void;
}>();
const { trans } = useI18n();

const element = toRef(props, 'element');
const store = useMainStore();
const { selection, setSelection } = useSelection();

const {
	reset,
	removeBg,
	squareBg,
	circleBg,
	brushBg,
	squareShape,
	circleShape,
	triangleShape,
	blurBg,
	patternBg,
	backAndWhiteBg,
	addTitle,
} = useImagePresets(element);

const { isBackground } = useIsBackground(element);

const { checkIsPhotoModeImage } = usePhotoMode();
const { isMobile } = useDeviceInfo();
const { closePanel } = usePanelManagement();
const onCloseEditPanel = () => {
	if (isMobile.value && checkIsPhotoModeImage(element.value)) {
		emit('close');
		return;
	}
	closePanel(props.element);
};

const openFilters = () => {
	store.editPanel = EditPanels.Filter;
};

const isPhotoModeImage = computed(() => element.value instanceof BaseImage && checkIsPhotoModeImage(element.value));
const container = ref();
onClickOutside(container, () => {
	if (!isPhotoModeImage.value) return;

	emit('close');
});

const applyPresetHandler = (preset: () => void) => {
	preset();
	if (selection.value[0]?.id !== element.value.id) {
		const panelToOpen = store.editPanel;
		setSelection(element.value);
		store.editPanel = panelToOpen;
	}
};
</script>

<template>
	<div ref="container" class="mb-3 px-4 pt-3 lg:p-0">
		<div v-if="!isPhotoModeImage" class="mb-4 flex h-6 items-center justify-between">
			<h2 class="text-sm font-bold uppercase text-gray-100 lg:text-xl lg:font-semibold lg:capitalize">
				{{ trans('Presets') }}
			</h2>
			<button
				data-testid="close-panel"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white lg:text-gray-100 lg:focus:outline-none lg:hover:text-white"
				@click="onCloseEditPanel"
			>
				<SvgIcon name="cross" class="h-3 w-3 lg:h-5 lg:w-5" />
			</button>
		</div>

		<div data-testid="sidebar-panel" class="flex flex-col">
			<!-- Edition -->
			<div
				class="flex grid-cols-3 gap-4 overflow-auto lg:grid lg:gap-4 lg:overflow-visible"
				:class="{
					'pb-3': isPhotoModeImage,
				}"
			>
				<div
					class="aspect-square h-16 w-16 rounded lg:h-auto lg:w-auto"
					:class="{
						'bg-gray-600 text-gray-100 lg:bg-gray-700': element.virtualGroup,
						'border-2 border-blue-500 bg-gray-600 text-white slidesgo:border-purple-400': !element.virtualGroup,
					}"
				>
					<button class="h-full w-full" @click="reset">
						<SvgIcon class="m-auto h-6 w-6 lg:h-8 lg:w-8" name="empty" />
					</button>
				</div>
				<div class="h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700">
					<RemoveBgPresetPreview
						data-testid="remove-bg-preset"
						:image="element"
						@click="applyPresetHandler(removeBg)"
					/>
				</div>
				<div
					class="group relative h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
				>
					<BlurPresetPreview
						data-testid="blur-bg-preset"
						:image="element"
						class="z-10"
						@click="applyPresetHandler(blurBg)"
					/>

					<button
						v-if="!isPhotoModeImage"
						class="absolute right-1 top-1 z-20 rounded bg-blue-500 p-1 text-white opacity-0 group-hover:opacity-100 hover:bg-blue-600 slidesgo:bg-purple-400"
						@click="
							applyPresetHandler(() => {
								openFilters();
								blurBg();
							})
						"
					>
						<SvgIcon name="filter" class="h-3 w-3" />
					</button>
				</div>
				<div class="h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700">
					<BlackAndWhitePresetPreview
						data-testid="black-white-bg-preset"
						:image="element"
						@click="applyPresetHandler(backAndWhiteBg)"
					/>
				</div>
				<div
					v-if="!checkIsPhotoModeImage(element)"
					class="h-16 w-16 shrink-0 rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
					:class="{
						'cursor-not-allowed opacity-50': isBackground,
						'cursor-pointer': !isBackground,
					}"
				>
					<SquareBgPresetPreview
						data-testid="square-bg-preset"
						:image="element"
						@click="applyPresetHandler(squareBg)"
					/>
				</div>
				<div
					v-if="!checkIsPhotoModeImage(element)"
					class="h-16 w-16 shrink-0 rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
					:class="{
						'cursor-not-allowed opacity-50': isBackground,
						'cursor-pointer': !isBackground,
					}"
				>
					<CircleBgPresetPreview
						data-testid="circle-bg-preset"
						:image="element"
						@click="applyPresetHandler(circleBg)"
					/>
				</div>
				<div
					v-if="!checkIsPhotoModeImage(element)"
					class="h-16 w-16 shrink-0 rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
					:class="{
						'cursor-not-allowed opacity-50': isBackground,
						'cursor-pointer': !isBackground,
					}"
				>
					<BrushBgPresetPreview data-testid="brush-bg-preset" :image="element" @click="applyPresetHandler(brushBg)" />
				</div>
				<div
					v-if="!checkIsPhotoModeImage(element)"
					class="h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
				>
					<SquareShapePresetPreview
						data-testid="square-shape-preset"
						:image="element"
						@click="applyPresetHandler(squareShape)"
					/>
				</div>
				<div
					v-if="!checkIsPhotoModeImage(element)"
					class="h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
				>
					<CircleShapePresetPreview
						data-testid="circle-shape-preset"
						:image="element"
						@click="applyPresetHandler(circleShape)"
					/>
				</div>
				<div class="h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700">
					<TitlePresetPreview data-testid="title-preset" :image="element" @click="applyPresetHandler(addTitle)" />
				</div>
				<div
					v-if="!checkIsPhotoModeImage(element)"
					class="h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
				>
					<TriangleShapePresetPreview
						data-testid="triangle-shape-preset"
						:image="element"
						@click="applyPresetHandler(triangleShape)"
					/>
				</div>
				<div
					class="group relative h-16 w-16 shrink-0 cursor-pointer rounded bg-gray-600 lg:h-auto lg:w-auto lg:bg-gray-700"
				>
					<PatternPresetPreview
						data-testid="patern-preset"
						:image="element"
						class="z-10"
						@click="applyPresetHandler(patternBg)"
					/>
					<button
						v-if="!isPhotoModeImage"
						class="absolute right-1 top-1 z-20 rounded bg-blue-500 p-1 text-white opacity-0 group-hover:opacity-100 hover:bg-blue-600 slidesgo:bg-purple-400"
						@click="
							applyPresetHandler(() => {
								openFilters();
								patternBg();
							})
						"
					>
						<SvgIcon name="filter" class="h-3 w-3" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
