<script setup lang="ts">
import { useFonts } from '@/elements/texts/text/composables/useFonts';
const { inUseFonts, loadedFonts, userFonts } = useFonts();
</script>

<template>
	<div class="mt-2 flex flex-col rounded bg-black bg-opacity-30 p-3 font-mono text-white">
		<h3 class="text-md py-2 font-bold">Fonts</h3>

		<div>
			<span class="opacity-70">Loaded fonts: </span>
			<span v-for="loadFont in loadedFonts" :key="loadFont" class="mb-1 text-green-500">{{ `${loadFont}, ` }}</span>
		</div>

		<div>
			<span class="opacity-70">In use fonts: </span>
			<span v-for="font in inUseFonts" :key="font.name" class="mb-1 text-green-500">{{ `${font.name}, ` }}</span>
		</div>

		<div>
			<span class="opacity-70">User fonts: </span>
			<span v-for="userFont in userFonts" :key="userFont.name" class="mb-1 text-green-500">{{
				`${userFont.name}, `
			}}</span>
		</div>
	</div>
</template>
