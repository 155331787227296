<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/shared';
import { computed, InputHTMLAttributes, onBeforeMount, ref, toRef, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import MathTools from '@/utils/classes/MathTools';

// Props
const props = withDefaults(
	defineProps<{
		hasInnerLabel?: boolean;
		isTransparent?: boolean;
		max?: number;
		min?: number;
		name?: string;
		placeholder?: string;
		step?: number;
		testId?: string;
		value: number | number[];
		disabled?: boolean;
		whiteVersion?: boolean;
	}>(),
	{
		max: Infinity,
		min: -Infinity,
		name: '',
		placeholder: '',
		step: 1,
		testId: '',
	}
);

// Emits
const emit = defineEmits<{
	(e: 'blur', value: number): void;
	(e: 'rawUpdate', value: number): void;
	(e: 'trackStep', value: number): void;
	(e: 'trackTyping', value: number): void;
	(e: 'update', value: number): void;
	(e: 'updateArrayOneStep', value: 'minus' | 'plus'): void;
}>();

const pressing: {
	interval: undefined | ReturnType<typeof setInterval>;
	timeout: undefined | ReturnType<typeof setTimeout>;
} = {
	interval: undefined,
	timeout: undefined,
};

// Template refs
const numberInput = ref();

const value = toRef(props, 'value');

const rawValue = ref();

const isValidRaw = computed(
	() => typeof rawValue.value === 'number' && rawValue.value >= props.min && rawValue.value <= props.max
);

const { isPhotoMode } = useEditorMode();

watch(value, (newVal) => {
	if (newVal !== rawValue.value) {
		setRawValue();
	}
});

onBeforeMount(() => {
	setRawValue();
});

const setRawValue = () => {
	if (Array.isArray(value.value)) {
		if (value.value.length === 1 && typeof value.value[0] === 'number') {
			rawValue.value = MathTools.toFixedOrInt(value.value[0]);
		}
		return;
	}

	rawValue.value = MathTools.toFixedOrInt(value.value);
};

const onUpdate = useDebounceFn((e: Event) => {
	const val = (e.target as InputHTMLAttributes).value;
	if (!val) return;
	rawValue.value = parseFloat(val);
	emit('trackTyping', rawValue.value);
	emit('rawUpdate', rawValue.value);
	if (!isValidRaw.value) return;
	emit('update', rawValue.value);
}, 333);

const onBlur = (e: FocusEvent) => {
	rawValue.value = undefined;

	let val = (e.target as InputHTMLAttributes).value;

	if (val) {
		const float = parseFloat(val);

		if (typeof float === 'number') {
			rawValue.value = MathTools.toFixedOrInt(float);
		} else if (!Array.isArray(value.value)) {
			rawValue.value = MathTools.toFixedOrInt(value.value);
		}
	}

	if (!val && !Array.isArray(value.value)) {
		rawValue.value = MathTools.toFixedOrInt(value.value);
	}

	if (!isValidRaw.value) {
		if (!Array.isArray(value.value)) rawValue.value = MathTools.toFixedOrInt(value.value);
	}

	if (typeof props.max === 'number' && rawValue.value > props.max) {
		rawValue.value = props.max;
	}

	if (typeof props.min === 'number' && rawValue.value < props.min) {
		rawValue.value = props.min;
	}

	emit('rawUpdate', rawValue.value);
	emit('update', rawValue.value);
	emit('blur', rawValue.value);
};

const onUpdateOneStep = (dir: number) => {
	if (props.disabled) return;

	emit('trackStep', dir);

	if (Array.isArray(value.value)) {
		emit('updateArrayOneStep', dir > 0 ? 'plus' : 'minus');
		return;
	}
	rawValue.value += dir * props.step;
	emit('rawUpdate', rawValue.value);
	if (!isValidRaw.value) return;
	emit('update', rawValue.value);
};

const onStart = (dir: number) => {
	if (props.disabled) return;

	emit('trackStep', dir);

	pressing.timeout = setTimeout(() => {
		pressing.interval = setInterval(() => {
			if (Array.isArray(value.value)) {
				emit('updateArrayOneStep', dir > 0 ? 'plus' : 'minus');
			} else {
				const val = Number(value.value);
				emit('rawUpdate', val + dir * props.step);
				if ((dir === -1 && val - 1 <= props.min) || (dir === 1 && val >= props.max) || !isValidRaw.value) {
					onStop();
					return;
				}

				emit('update', val + dir * props.step);
			}
		}, 50);
	}, 200);
};

const onStop = () => {
	if (pressing.interval) clearInterval(pressing.interval);
	if (pressing.timeout) clearTimeout(pressing.timeout);
};
</script>

<template>
	<div class="group relative">
		<input
			ref="numberInput"
			class="h-full w-full appearance-none rounded focus:outline-none"
			type="number"
			:class="{
				'pl-6': hasInnerLabel,
				'pl-2': !hasInnerLabel,
				'bg-transparent': isTransparent,
				'bg-white text-gray-500 focus:outline-1 focus:outline-offset-0 focus:outline-blue-500':
					whiteVersion && !isPhotoMode,
				'bg-gray-900 mockup:bg-fp-gray-150': !isTransparent,
				'text-gray-300 mockup:text-fp-gray-700': isValidRaw,
				'text-red-600': !isValidRaw,
				'disabled cursor-not-allowed opacity-50 hover:bg-gray-600 hover:text-white mockup:hover:bg-fp-gray-100':
					disabled,
			}"
			:disabled="disabled"
			:max="max"
			:min="min"
			:name="name || 'number-input'"
			:step="step"
			:value="rawValue"
			:placeholder="placeholder"
			:data-testid="testId"
			@change="onUpdate"
			@input="onUpdate"
			@blur="onBlur"
		/>
		<div
			class="absolute right-0 top-0 flex h-full w-4 flex-col rounded-br rounded-tr opacity-0 ring-1 ring-inset group-hover:opacity-100 group-focus:opacity-100 mockup:bg-white mockup:ring-fp-gray-200"
			:class="[whiteVersion && !isPhotoMode ? 'bg-white ring-white ' : 'bg-gray-800 ring-gray-900']"
		>
			<span
				class="flex flex-1 cursor-pointer items-center justify-center rounded-tr leading-none text-gray-300 mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800"
				:class="[
					{ 'cursor-not-allowed opacity-50': disabled },
					whiteVersion && !isPhotoMode ? 'lg:hover:text-gray-800' : 'hover:text-white',
				]"
				data-testid="selector-up"
				@click="onUpdateOneStep(1)"
				@mousedown="onStart(1)"
				@mouseup.stop="onStop"
				@mouseout.stop="onStop"
				>▴</span
			>
			<span
				class="flex flex-1 rotate-180 transform cursor-pointer items-center justify-center rounded-tl leading-none text-gray-300 mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800"
				:class="[
					{ 'cursor-not-allowed opacity-50': disabled },
					whiteVersion && !isPhotoMode ? 'lg:hover:text-gray-800' : 'hover:text-white',
				]"
				data-testid="selector-down"
				@click="onUpdateOneStep(-1)"
				@mousedown="onStart(-1)"
				@mouseup="onStop"
				@mouseout="onStop"
				>▴</span
			>
		</div>
	</div>
</template>
