<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: ForegroundImage }>();
const { isMobile } = useDeviceInfo();
const { trans } = useI18n();

const toggleOpacity = () => {
	props.element.opacity = props.element.opacity ? 0 : 1;
	Bugsnag.leaveBreadcrumb(`toggle opacity ${props.element.type}-${props.element.id}`);
};
const { checkIsPhotoModeImage } = usePhotoMode();
const isPhotoModeImage = computed(() => props.element instanceof BaseImage && checkIsPhotoModeImage(props.element));
</script>

<template>
	<div>
		<button
			:tooltip="element.opacity ? trans('Hide') : trans('Show')"
			tooltip-position="top"
			data-button-importance="normal"
			data-testid="opacity-foreground"
			class="flex shrink-0 flex-col items-center text-gray-100 hover:text-white lg:h-6 lg:w-full lg:flex-row lg:justify-center lg:px-2"
			:class="{
				'h-9 w-9 justify-center': isPhotoModeImage,
				'h-11 w-[15vw] justify-between': !isPhotoModeImage,
			}"
			@click="toggleOpacity"
		>
			<SvgIcon :name="element.opacity ? 'eye-through' : 'eye'" :toolbar="true" class="h-4 w-4 lg:scale-90" />
			<span v-if="!isPhotoModeImage || !isMobile" class="text-xs lg:mt-0 lg:ml-2 lg:text-sm" data-button-label>{{
				element.opacity ? trans('Hide') : trans('Show')
			}}</span>
		</button>
	</div>
</template>
