<script lang="ts" setup>
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';

const emits = defineEmits(['share']);

const { trans } = useI18n();

const project = useProjectStore();
const { isValidInstagramArtboard } = useSharePublish();

const shareModal = (socialNetwork: string) => {
	emits('share', socialNetwork);

	GAnalytics.track(`publish-${socialNetwork}`, 'Template', project.name ? project.name : 'custom-artboard', null);
};
</script>
<template>
	<div>
		<p class="mb-4 text-lg font-semibold text-gray-700">{{ trans('Publish your design') }}</p>
		<div class="flex items-start gap-2 xl:gap-3" data-testid="social-buttons-container">
			<div
				class="relative flex flex-col items-center text-gray-700"
				:class="{
					'hover:text-blue-500': isValidInstagramArtboard,
					'hover:text-gray-700': !isValidInstagramArtboard,
				}"
			>
				<button
					data-testid="instagram"
					class="bg-instagram mb-1 flex h-12 w-12 items-center justify-center rounded"
					tooltip="Instagram Business"
					tooltip-position="top"
					:class="{
						'hover:opacity-90': isValidInstagramArtboard,
						'pointer-events-none opacity-25': !isValidInstagramArtboard,
					}"
					:style="{
						'background-image': `linear-gradient(
										135deg,
										#405de6,
										#833ab4,
										#c13584,
										#e1306c,
										#fd1d1d,
										#f56040,
										#f77737,
										#fcaf45,
										#ffdc80
									)
									`,
					}"
					:disabled="!isValidInstagramArtboard"
					@click="() => shareModal('instagram')"
				>
					<SvgIcon name="instagram" class="h-6 w-6 text-white" />
				</button>
				<div v-if="!isValidInstagramArtboard" class="group absolute -top-1 -right-1 z-30 cursor-help">
					<SvgIcon name="alert" class="h-5 w-5 rounded-full bg-red-500 p-px text-white" />
					<div
						class="pointer-events-none absolute top-0 left-full ml-1 w-32 rounded bg-white p-2 text-xs opacity-0 shadow-lg transition-opacity duration-300 group-hover:pointer-events-auto group-hover:opacity-100"
					>
						{{ trans('Invalid image ratio by Instagram.') }}
						<a
							class="font-semibold text-blue-500 underline"
							href="https://help.instagram.com/1631821640426723"
							target="_blank"
							>More info</a
						>
					</div>
				</div>
			</div>
			<div class="flex flex-col items-center text-gray-600 hover:text-blue-500">
				<button
					data-testid="facebook"
					class="mb-1 flex h-12 w-12 items-center justify-center rounded bg-[#4267B2] hover:opacity-90"
					tooltip="Facebook Page"
					tooltip-position="top"
					@click="() => shareModal('facebook')"
				>
					<SvgIcon name="facebook" class="h-6 w-6 text-white" />
				</button>
			</div>
			<div class="flex flex-col items-center text-gray-600 hover:text-blue-500">
				<button
					data-testid="pinterest"
					class="mb-1 flex h-12 w-12 items-center justify-center rounded bg-[#E60023] hover:opacity-90"
					tooltip="Pinterest"
					tooltip-position="top"
					@click="() => shareModal('pinterest')"
				>
					<SvgIcon name="pinterest" class="h-6 w-6 text-white" />
				</button>
			</div>
			<div class="flex flex-col items-center text-gray-600 hover:text-blue-500" @click="() => shareModal('twitter')">
				<button
					data-testid="twitter"
					class="mb-1 flex h-12 w-12 items-center justify-center rounded bg-[#03A9F4] hover:opacity-90"
					tooltip="Twitter"
					tooltip-position="top"
				>
					<SvgIcon name="twitter" class="h-6 w-6 text-white" />
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.bg-instagram
	background-image: linear-gradient(135deg, rgb(64, 93, 230), rgb(131, 58, 180), rgb(193, 53, 132), rgb(225, 48, 108), rgb(253, 29, 29), rgb(245, 96, 64), rgb(247, 119, 55), rgb(252, 175, 69), rgb(255, 220, 128))
</style>
