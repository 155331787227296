<script lang="ts" setup>
import { ref, toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import ListMenu from '@/elements/texts/text/components/buttons/ListMenu.vue';
import TextUnderlineMenu from '@/elements/texts/text/components/buttons/TextUnderlineMenu.vue';
import TextEffectsMenu from '@/elements/texts/text/components/menus/TextEffectsMenu.vue';
import TextRotationMenu from '@/elements/texts/text/components/menus/TextRotationMenu.vue';
import TextSpacingMenu from '@/elements/texts/text/components/menus/TextSpacingMenu.vue';
import TextStyleMenu from '@/elements/texts/text/components/menus/TextStyleMenu.vue';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const { selectedColor, updateColor } = useTextStyles(element);
const { isGrouped } = useGroup(element);
const { trans } = useI18n();
const { isCircleText } = useCircleTypeInfo(element);
const { closePanel } = usePanelManagement();

const showMoreOptions = ref<boolean>(false);

const toggleMoreOptions = () => {
	showMoreOptions.value = !showMoreOptions.value;
};
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100 mockup:text-fp-gray-700">{{ trans('Edit text') }}</h2>
		<button
			class="text-gray-100 focus:outline-none hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800"
			data-testid="close-panel"
			@click="closePanel()"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col" data-keep-text-selection>
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<TextStyleMenu :element="element" />

		<div class="mb-6">
			<button
				data-testid="more-options-texts"
				class="flex items-center gap-1 text-sm font-semibold hover:text-white mockup:hover:text-blue-500"
				:class="{
					'text-gray-100 mockup:text-blue-500': showMoreOptions === true,
					'text-gray-300 mockup:text-fp-gray-700': showMoreOptions !== true,
				}"
				@click="toggleMoreOptions"
			>
				{{ trans('More options') }}
				<SvgIcon
					name="arrow"
					class="h-3 w-3"
					:class="{
						'rotate-180': showMoreOptions === true,
					}"
				/>
			</button>
			<div v-show="showMoreOptions" class="grid w-full grid-cols-4 gap-2 pt-2">
				<TextSpacingMenu :element="element" />
				<TextRotationMenu :element="element" />
				<ListMenu :element="element" />
				<TextUnderlineMenu :element="element" />
			</div>
		</div>

		<TextEffectsMenu :element="element" />

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-800">
			{{ trans('Colors') }}
		</h4>
		<ColorPicker
			class="h-10 w-10"
			:color="(selectedColor as Color[])"
			:hide-gradient="isCircleText"
			@change="updateColor"
		/>
	</div>
</template>
