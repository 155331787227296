<script setup lang="ts">
import { useHistoryStore } from '@/history/stores/history';
import { useProjectStore } from '@/project/stores/project';

const project = useProjectStore();
const history = useHistoryStore();
</script>

<template>
	<div class="mt-2 flex flex-col rounded bg-black bg-opacity-30 p-3 font-mono text-white">
		<h3 class="text-md py-2 font-bold">History</h3>

		<div>
			<span class="opacity-70">Current step: </span>
			<span class="mb-1 text-green-500">{{ (history.activeState?.index || 0) + 1 }}</span>
		</div>
		<div>
			<span class="opacity-70">History steps: </span>
			<span class="mb-1 text-green-500">{{ history.states.length }}</span>
		</div>
		<div>
			<span class="opacity-70">History paused: </span>
			<span class="mb-1 text-green-500">{{ project.isHistoryBlocked }}</span>
		</div>
		<div>
			<span class="opacity-70">Tried to save while paused: </span>
			<span class="mb-1 text-green-500">{{ project.triedToSaveWhilePaused }}</span>
		</div>
		<div>
			<span class="opacity-70">Pending sync: </span>
			<span class="mb-1 text-green-500">{{ project.pendingSync ? 'true' : 'false' }}</span>
		</div>
		<div>
			<span class="opacity-70">Syncing: </span>
			<span class="mb-1 text-green-500">{{ project.syncing ? 'true' : 'false' }}</span>
		</div>
		<div>
			<span class="opacity-70">All changes saved: </span>
			<span class="mb-1 text-green-500">{{ project.allChangesSaved ? 'true' : 'false' }}</span>
		</div>
		<div>
			<span class="opacity-70">Sync error: </span>
			<span class="mb-1 text-green-500">{{ project.syncError ? 'true' : 'false' }}</span>
		</div>
	</div>
</template>
