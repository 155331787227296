import { createSharedComposable } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import twitterText from 'twitter-text';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { createNewSchedulePublication } from '@/api/DataApiClient';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { MetaInfo, PinterestInfo, SchedulePublicationBody, TwitterInfo } from '@/Types/apiClient';
import { ShareModalType } from '@/Types/types';

export const useSharePublish = createSharedComposable(() => {
	const { trans, localeFormat } = useI18n();

	const store = useMainStore();
	const project = useProjectStore();
	const toast = useToast();
	const { selectedPages, share } = useDownloadsProject();

	const errors = ref<{ id: number; description: string }[]>([]);
	const title = ref('');
	const message = ref('');
	const website = ref('');
	const socialMediaPagesSelected = ref<string | null>('');
	const rrssProfilePages = ref<any>(null);
	const socialMediaModal = ref<null | ShareModalType>(null);

	const date = ref(new Date());
	const step = ref(1);

	const isValidInstagramArtboard = computed(() => {
		const min = 4 / 5;
		const max = 1.91 / 1;
		const aspectRatio = project.size.width / project.size.height;

		return aspectRatio >= min && aspectRatio <= max;
	});

	// Meta acepta como máximo 30 hashtags (#)
	const maxHashtagsExceeded = computed(() => {
		return message.value.split('#').length - 1 > 30;
	});

	// Meta acepta como máximo 20 menciones (@)
	const maxMentionsExceeded = computed(() => {
		return message.value.split('@').length - 1 > 20;
	});

	const isValidFacebookPublication = computed(() => {
		return (
			socialMediaModal.value === ShareModalType.facebook &&
			selectedPages.value.length &&
			socialMediaPagesSelected.value &&
			!maxHashtagsExceeded.value &&
			!maxMentionsExceeded.value
		);
	});

	const isValidInstagramPublication = computed(() => {
		return (
			socialMediaModal.value === ShareModalType.instagram &&
			selectedPages.value.length &&
			socialMediaPagesSelected.value &&
			!maxHashtagsExceeded.value &&
			!maxMentionsExceeded.value
		);
	});

	const isValidPinterestPublication = computed(() => {
		return (
			socialMediaModal.value === ShareModalType.pinterest &&
			selectedPages.value.length &&
			socialMediaPagesSelected.value
		);
	});

	const isValidTwitterPublication = computed(() => {
		return socialMediaModal.value === ShareModalType.twitter && selectedPages.value.length;
	});

	const tweetData = computed(() => {
		return twitterText.parseTweet(message.value);
	});

	const canPublishContent = computed(() => {
		return (
			isValidFacebookPublication.value ||
			isValidInstagramPublication.value ||
			isValidPinterestPublication.value ||
			isValidTwitterPublication.value
		);
	});

	const formattedDate = computed(() => {
		const hours = date.value.getHours() < 10 ? `0${date.value.getHours()}` : date.value.getHours();
		const minutes = date.value.getMinutes() < 10 ? `0${date.value.getMinutes()}` : date.value.getMinutes();

		const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
		const formatted = `${date.value.toLocaleDateString(
			localeFormat.value,
			options as { weekday: 'long'; year: 'numeric'; month: 'long'; day: 'numeric' }
		)}, ${hours}:${minutes}`;
		return formatted;
	});

	const scheduleServerDate = computed(() => {
		// DateFormat
		// 2022-12-21 00:00:06
		const year = date.value.getUTCFullYear();
		const month = date.value.getUTCMonth() + 1;
		const day = date.value.getUTCDate();

		const hours = date.value.getUTCHours();
		const minutes = date.value.getUTCMinutes();
		const seconds = date.value.getUTCSeconds();

		return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day} ${
			hours < 10 ? `0${hours}` : hours
		}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
	});

	const trackShareEvent = (succes: boolean) => {
		if (socialMediaModal.value) {
			GAnalytics.trackGA4('publish_on', {
				link_text: socialMediaModal.value,
				success_event: succes ? 'true' : 'false',
			});
		}
	};

	const shareInTwitter = async () => {
		if (store.sharing) return;

		errors.value = [];

		if (!selectedPages.value.length) {
			errors.value.push({
				id: 2,
				description: trans('Please select at least one page'),
			});
		}

		if (tweetData.value.weightedLength > 280) {
			errors.value.push({
				id: 3,
				description: trans('Your message is too long'),
			});
		}

		if (errors.value.length) {
			return;
		}

		const params = {
			twitter_message: message.value,
		};

		try {
			await share(
				ShareModalType.twitter,
				params,
				selectedPages.value.map((page) => page.id)
			);

			trackShareEvent(true);

			toast.success(trans('Your design has been published on Twitter'));
		} catch (error) {
			toast.error(trans('Your design has not been uploaded on Twitter'));

			trackShareEvent(false);
		}
	};

	const shareInFacebook = async () => {
		if (store.sharing) return;

		errors.value = [];

		if (!socialMediaPagesSelected.value) {
			errors.value.push({ id: 1, description: trans('Please select a facebook page') });
		}

		if (!selectedPages.value.length) {
			errors.value.push({ id: 2, description: trans('Please select at least one page') });
		}

		if (errors.value.length) {
			return;
		}

		const page: any = rrssProfilePages.value.find((fbPage: any) => fbPage.id == socialMediaPagesSelected.value);

		const params = {
			facebook_page_id: page.id,
			facebook_message: message.value,
		};

		try {
			await share(
				ShareModalType.facebook,
				params,
				selectedPages.value.map((page) => page.id)
			);

			trackShareEvent(true);

			toast.success(trans('Your design has been published on Facebook'));
		} catch (error) {
			toast.error(trans('Your design has not been uploaded on Facebook'));

			trackShareEvent(false);
		}
	};

	const shareInPinterest = async () => {
		if (store.sharing) return;

		errors.value = [];

		if (!socialMediaPagesSelected.value) {
			errors.value.push({ id: 1, description: trans('Please select a pinterest board') });
		}

		if (!selectedPages.value.length) {
			errors.value.push({ id: 2, description: trans('Please select at least one page') });
		}

		if (website.value.length > 0 && !TextTools.isValidUrl(website.value)) {
			errors.value.push({ id: 3, description: trans('Please select at least one page') });
		}

		if (errors.value.length) {
			return;
		}

		const params = {
			pinterest_board_id: socialMediaPagesSelected.value,
			pinterest_title: title.value,
			pinterest_url: website.value,
			pinterest_description: message.value,
		};

		try {
			await share(
				ShareModalType.pinterest,
				params,
				selectedPages.value.map((page) => page.id)
			);

			trackShareEvent(true);

			toast.success(trans('Your design has been published on Pinterest'));
		} catch (error) {
			toast.error(trans('Your design has not been uploaded on Pinterest'));

			trackShareEvent(false);
		}
	};

	const shareInInstagram = async () => {
		if (store.sharing) return;

		errors.value = [];

		if (!socialMediaPagesSelected.value) {
			errors.value.push({ id: 1, description: trans('Please select a instagram page') });
		}

		if (!selectedPages.value.length) {
			errors.value.push({ id: 2, description: trans('Please select at least one page') });
		}

		if (errors.value.length) {
			return;
		}

		const page: any = rrssProfilePages.value.find((fbPage: any) => fbPage.id == socialMediaPagesSelected.value);

		const params = {
			facebook_page_id: page.id,
			facebook_message: message.value,
		};

		try {
			await share(
				ShareModalType.instagram,
				params,
				selectedPages.value.map((page) => page.id)
			);

			trackShareEvent(true);

			toast.success(trans('Your design has been published on Instagram'));
		} catch (error) {
			toast.error(trans('Your design has not been uploaded on Instagram'));

			trackShareEvent(false);
		}
	};

	const updateDate = (hours: number, minutes: number) => {
		const newDate = cloneDeep(date.value);
		date.value = newDate;
		date.value.setHours(hours);
		date.value.setMinutes(minutes);
		date.value.setSeconds(0);
		date.value.setMilliseconds(0);
	};

	const validateShareInputs = () => {
		errors.value = [];

		switch (socialMediaModal.value) {
			case ShareModalType.facebook:
				if (!socialMediaPagesSelected.value) {
					errors.value.push({ id: 1, description: trans('Please select a facebook page') });
				}
				break;
			case ShareModalType.instagram:
				if (!socialMediaPagesSelected.value) {
					errors.value.push({ id: 1, description: trans('Please select a instagram page') });
				}
				break;
			case ShareModalType.pinterest:
				if (!socialMediaPagesSelected.value) {
					errors.value.push({ id: 1, description: trans('Please select a pinterest board') });
				}
				break;
		}

		if (selectedPages.value.length === 0) {
			errors.value.push({ id: 2, description: trans('Please select at least one page') });
		}
	};

	const createSchedulePublication = async (isDraft = false) => {
		if (selectedPages.value.length && socialMediaModal.value && scheduleServerDate.value && store.userVector) {
			let info: PinterestInfo | TwitterInfo | MetaInfo | undefined;

			switch (socialMediaModal.value) {
				case ShareModalType.facebook:
					if (socialMediaPagesSelected.value) {
						info = {
							facebook_page_id: socialMediaPagesSelected.value,
							facebook_message: message.value,
						};
					}
					break;
				case ShareModalType.instagram:
					if (socialMediaPagesSelected.value) {
						info = {
							facebook_page_id: socialMediaPagesSelected.value,
							facebook_message: message.value,
						};
					}
					break;
				case ShareModalType.pinterest:
					if (socialMediaPagesSelected.value) {
						info = {
							pinterest_board_id: socialMediaPagesSelected.value,
							pinterest_title: title.value,
							pinterest_url: website.value,
							pinterest_description: message.value,
						};
					}
					break;
				case ShareModalType.twitter:
					info = { twitter_message: message.value };
					break;
			}

			if (info) {
				const body: SchedulePublicationBody = {
					vector_uuid: store.userVector.uuid,
					pages: selectedPages.value.map((page) => page.id),
					social_network: socialMediaModal.value,
					is_draft: isDraft,
					published_at: scheduleServerDate.value,
					info: info,
				};

				const { statusCode } = await createNewSchedulePublication(body);

				if (statusCode.value === 200 || statusCode.value === 201) {
					toast.success(trans(`${isDraft ? 'Draft saved' : 'Publication scheduled'} correctly`));
				} else {
					toast.error(trans('Something went wrong'));
				}
			}
		}
	};

	return {
		canPublishContent,
		date,
		errors,
		formattedDate,
		isValidInstagramArtboard,
		message,
		rrssProfilePages,
		socialMediaModal,
		socialMediaPagesSelected,
		step,
		title,
		website,
		createSchedulePublication,
		validateShareInputs,
		maxHashtagsExceeded,
		maxMentionsExceeded,
		shareInFacebook,
		shareInInstagram,
		shareInPinterest,
		shareInTwitter,
		tweetData,
		updateDate,
	};
});
