<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import ColorPicker from '@/color/components/ColorPicker.vue';
import { useStorysetColors } from '@/color/composables/useStorysetColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import FlipMenuToolbar from '@/elements/element/components/menus/FlipMenuToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import Storyset from '@/elements/storyset/classes/Storyset';
import ChangeBackgroundButton from '@/elements/storyset/components/buttons/ChangeBackgroundButton.vue';
import { Color } from '@/Types/colorsTypes';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: Storyset }>();

const element = toRef(props, 'element');

const { updateColor } = useStorysetColors(element);
const { isMobile } = useDeviceInfo();
const { togglePanel } = usePanelManagement();

const onClickMoreTools = () => {
	Bugsnag.leaveBreadcrumb('Open edit story panel');
	togglePanel(EditPanels.Storyset);

	GAnalytics.trackDebounceEditBarGA4({ category: 'storyset', link_text: 'more_tools' });
};

const onClickColorPicker = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'storyset', link_text: 'color' });
};

const onFlip = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'storyset', link_text: 'flip' });
};

const onRemove = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'storyset', link_text: 'delete' });
};
</script>

<template>
	<div class="overflow-auto lg:overflow-visible">
		<template v-if="!element.locked">
			<ChangeBackgroundButton class="bg-storyset" :element="element" />
			<div
				class="order-first mr-2 h-10 border-r border-gray-600 py-1 pl-2 pr-4 lg:order-none lg:ml-1 lg:h-5 lg:w-5 lg:border-0 lg:p-0"
			>
				<ColorPicker
					parent=".toolbar"
					placement="right-start"
					class="h-8 w-8 lg:h-5 lg:w-5"
					:color="(element.mainColor as Color)"
					:hide-alpha="true"
					@click="onClickColorPicker"
					@change="updateColor"
				/>
			</div>
			<FlipMenuToolbar :element="element" @flip="onFlip" />

			<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 lg:block" />

			<OrderToolbar v-if="isMobile" :element="element" />

			<CopyToolbar v-if="isMobile" :element="element" />

			<RemoveToolbar :element="element" @remove="onRemove" />

			<MoreToolsToolbar :element="element" :panel="EditPanels.Storyset" @more-tools="onClickMoreTools" />
		</template>
		<LockButtonToolbar v-if="!isMobile && element.locked" :element="element" />
	</div>
</template>
