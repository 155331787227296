<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { useDebounceFn } from '@vueuse/core';
import { computed, nextTick, onActivated, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getTemplateCategories, getTemplates } from '@/api/DataApiClient';
import AutocompleteInput from '@/common/components/AutocompleteInput.vue';
import { ArtboardTools } from '@/common/utils/ArtboardTools';
import AppendReplaceModal from '@/editor/components/AppendReplaceModal.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import TemplateCategoriesPanel from '@/layout/components/TemplateCategoriesPanel.vue';
import TemplateList from '@/layout/components/TemplateList.vue';
import { useAvailableHeight } from '@/layout/composables/useAvailableHeight';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import { TemplateApiData } from '@/Types/apiClient';
import { TemplateCategory } from '@/Types/types';
const store = useMainStore();

// Data
const parentCategory = ref<TemplateCategory | null>(null);
const search = ref('');
const selectedTemplate = ref<TemplateApiData | null>(null);
const subcategories = ref<TemplateCategory[]>([]);

let isNewArtboard = false;

// Template refs
const container = ref();
const finalSource = ref('');
const queryFlag = ref(false);
const newArtboardFlag = ref(true);

// Using composables
useAvailableHeight(container);
const { trans } = useI18n();
const { isEmbeddedContext, embeddedContextData } = useEditorMode();

// Computeds
const activeTemplateCategory = computed(() => store.activeTemplateCategory);

const hasCategory = computed(() => !!activeTemplateCategory.value?.name);

const isSearching = computed(() => search.value?.length > 3);
const query = computed(() => (isSearching.value ? search.value : ''));
const firstLoad = ref(false);

const autocompleteSource = computed(() => {
	let url = 'autocomplete/wepikWeb';

	if (isSearching.value) {
		url += `?search=${search.value}`;

		if (activeTemplateCategory.value?.id) {
			url += `&category_id=${activeTemplateCategory.value?.id}`;
		}
	}

	return url;
});

const setSource = () => {
	const mode = isEmbeddedContext.value ? 'embedded' : 'editor';
	let url;

	if (isSearching.value) {
		url = hasCategory.value
			? `category/${activeTemplateCategory.value?.id}?q=${query.value}`
			: `search?mode=${mode}&q=${query.value}`;
	} else if (store.pack && firstLoad.value) {
		url = `search?mode=${mode}&q=${store.pack}`;
		firstLoad.value = false;
	} else if (hasCategory.value) {
		url = `category/${activeTemplateCategory.value?.id}`;
		firstLoad.value = false;
	}

	if (url && isEmbeddedContext.value && embeddedContextData.value.aspectRatio) {
		url += url.includes('?') ? '&' : '?';
		url += `aspect_ratio=${embeddedContextData.value.aspectRatio}`;
	}
	// si hemos aplicado un valor a la url , asignamos valor al source para que se haga refetch
	if (url) {
		finalSource.value = url;
	}
};

const handleTemplatesCategoryData = async () => {
	if (!activeTemplateCategory.value && !query.value.length) {
		execute();
	}
};

const hasFetchedPack = computed(() => !!(store.pack && finalSource.value.includes(store.pack)));

const title = computed(() => {
	if (hasFetchedPack.value) {
		return activeTemplateCategory.value ? store.pack : 'Templates';
	}
	return activeTemplateCategory.value?.name || 'Templates';
});

const { data: templatesData, isFetching: isloadingTemplates } = getTemplates(finalSource, {
	immediate: false,
	refetch: true,
});

const {
	data: templateCategoriesData,
	isFetching: isLoadingTemplateCategories,
	execute,
} = getTemplateCategories({ immediate: false });
const isFetchingTemplates = computed(() => {
	return isloadingTemplates.value || queryFlag.value;
});

onBeforeMount(() => {
	if (!templatesData.value?.data?.length && !firstLoad.value) {
		firstLoad.value = true;
		setSource();
	}

	isNewArtboard = ArtboardTools.checkIsNewArtboard();
});

onBeforeUnmount(() => {
	store.pack = null;
});

// Watchers
watch(
	activeTemplateCategory,
	(newVal) => {
		if (!newVal) {
			parentCategory.value = null;
			subcategories.value = [];
			return;
		}
	},
	{ immediate: true }
);

watch(isFetchingTemplates, (newVal, oldVal) => {
	if (newVal || !oldVal) return;
	if (store.pack && finalSource.value.includes(store.pack) && !templatesData.value?.data?.length) {
		let url = hasCategory.value ? `category/${activeTemplateCategory.value?.id}` : 'categories';

		if (isEmbeddedContext.value && embeddedContextData.value.aspectRatio) {
			url += url.includes('?') ? '&' : '?';
			url += `aspect_ratio=${embeddedContextData.value.aspectRatio}`;
		}

		finalSource.value = url;
		return;
	}

	if (isNewArtboard && newArtboardFlag.value) {
		newArtboardFlag.value = false;
		return;
	}

	if (
		hasCategory.value &&
		finalSource.value.includes(`category/${activeTemplateCategory.value?.id}`) &&
		!templatesData.value?.data?.length &&
		!isEmbeddedContext.value &&
		!newArtboardFlag.value
	) {
		store.activeTemplateCategory = null;
	}
});

watch([() => store.pack, activeTemplateCategory, query], async () => {
	// si no tenemos categoría asignada y no hay valor en la query, renderizamos el componente que muestra las categorías de templates
	// por lo que necesitamos realizar la consulta y establecer los datos de nuevo
	if (!activeTemplateCategory.value && !query.value.length) {
		handleTemplatesCategoryData();
		return;
	}
	// establecemos el source para que se haga refetch
	setSource();
});

watch(templatesData, (newVal) => {
	parentCategory.value = newVal?.parent || null;
	subcategories.value = newVal?.subcategories || [];

	// En el editor para terceros si no encontramos nada se intenta ir a una categoría
	if (isEmbeddedContext.value && !newVal?.data?.length && newVal?.id && newVal?.name) {
		store.activeTemplateCategory = newVal as TemplateCategory;
	}
});

// Methods
const breadcrumbSearchBugsnag = (searchValue: string) => {
	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in templates panel: ${searchValue}`);
};

const onClickTemplate = async (template: TemplateApiData) => {
	selectedTemplate.value = template;
	Bugsnag.leaveBreadcrumb(`Click on template id: ${template.id};  slug: ${template.slug}`);

	// Obtenemos la nueva info del template seleccionado
	const templateData = await TemplateLoader.getTemplateData(template.slug, false);

	// Actualizamos la categoría del template para la pantalla de descarga
	store.downloadTemplateCategory = templateData.category_tree[1];
};

const onCloseModal = () => {
	selectedTemplate.value = null;
};

const onGoBack = () => {
	search.value = '';
	if (hasFetchedPack.value) {
		if (firstLoad.value) firstLoad.value = false;
		setSource();
		return;
	}

	if (parentCategory.value?.id) {
		store.activeTemplateCategory = parentCategory.value;
		return;
	}

	store.activeTemplateCategory = null;
};

const onLoadMore = () => {
	if (isloadingTemplates.value || !templatesData.value?.links?.next) return;
	isloadingTemplates.value = true;
	finalSource.value = templatesData.value.links.next;
};

const updateSearchValue = async (searchValue: string | { id: number; name: string }) => {
	queryFlag.value = true;

	if (typeof searchValue !== 'string') {
		if (searchValue.id > 0) {
			search.value = searchValue.name;
			store.activeTemplateCategory = searchValue;
			await nextTick();
			breadcrumbSearchBugsnag(searchValue.name);
		}
		return;
	}

	search.value = searchValue;

	breadcrumbSearchBugsnag(searchValue);

	// Deshabilitamos el flag de búsqueda
	disableQueryFlag();
};

const disableQueryFlag = useDebounceFn(() => {
	queryFlag.value = false;
}, 500);

const trackGA4Event = useDebounceFn((zero_results) => {
	GAnalytics.trackGA4('search_editor', {
		category: 'templates',
		search_term: query.value,
		zero_results: zero_results,
	});
}, 500);
onActivated(() => {
	if (!hasCategory.value && !isSearching.value) {
		handleTemplatesCategoryData();
	}
});
watch(templatesData, () => {
	if (templatesData.value?.data?.length === 0 && query.value !== '') {
		trackGA4Event('true');
		return;
	}

	if (!templatesData.value || !templatesData.value?.data) {
		return;
	}

	if (query.value) {
		trackGA4Event('false');
	}
});
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader
			data-testid="title-panel-header"
			:title="title || ''"
			:show-back-button="!!store.activeTemplateCategory?.id"
			@goBack="onGoBack"
			@closeModal="onCloseModal"
		/>

		<AutocompleteInput
			:placeholder="trans('Search in ') + `${trans(title as string)}...`"
			:query="search"
			:autocomplete-source="autocompleteSource"
			@change="updateSearchValue"
		/>
		<TemplateCategoriesPanel
			v-if="!hasCategory && !isSearching"
			:data="templateCategoriesData || []"
			:loading="isLoadingTemplateCategories"
		/>
		<TemplateList
			v-else
			:data="(templatesData && templatesData.data) || []"
			:loading="isFetchingTemplates"
			:subcategories="subcategories"
			@open="onClickTemplate"
			@load="onLoadMore"
		/>
		<AppendReplaceModal v-if="!!selectedTemplate" :selected-template="selectedTemplate" @close="onCloseModal" />
	</div>
</template>
