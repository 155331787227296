import { Box } from '@/elements/box/classes/Box';
import { DomNodesElement } from '@/elements/element/dom/DomNodesElement';
import { Size } from '@/Types/types';
import { TypeBorder } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export class DomNodesBox extends DomNodesElement {
	element: Box;
	constructor(element: Box) {
		super(element);
		this.element = element;
	}
	setSizeToOriginalNode(width: number, height: number) {
		super.setSizeToOriginalNode(width, height);
		this.setBorderRadius({ width, height });
		if (!this.baseNode || !this.element?.text) return;
		const nodesToBeSynced = this.baseNode.querySelectorAll('.sync-text-inside-box') as NodeListOf<HTMLElement>;
		nodesToBeSynced.forEach((node) => {
			node.style.width = `${textInsideBox?.size.width}px`;
			if (!node.classList.contains('sync-text')) node.style.height = `${textInsideBox?.size.height}px`;
		});
	}

	setBorderRadius(size: Size) {
		// TODO: revisar en  implementación de tablas
		let calculatedRadius = this.element.border.radius[0];
		if (this.element.border.radius[0]) {
			const oldSize = { width: this.element.size.width, height: this.element.size.height };
			const oldMaxValue = Math.min(oldSize.width, oldSize.height) / 2;
			const currentMaxValue = Math.min(size.width, size.height) / 2;
			const oldPercent = (this.element.border.radius[0] * 100) / oldMaxValue;
			calculatedRadius = MathTools.ruleOfThree(100, currentMaxValue, oldPercent);
			if (!this.baseNode) return;
			const nodeToSyncBorderRadius = this.baseNode.querySelector('.sync-box') as HTMLElement;
			if (!nodeToSyncBorderRadius) return;
			nodeToSyncBorderRadius.style.borderRadius = `${calculatedRadius}px`;
		}

		this.resizeBorderPaths(size, calculatedRadius);
	}

	resizeBorderPaths = (size: Size, borderRadius: number) => {
		const svgNodes = this.baseNode?.querySelectorAll('.sync-box') as NodeListOf<SVGAElement>;
		const hasBorderRadius = this.element.border.radius.some((r) => !!r);
		const offsetSize =
			this.element.border.type === TypeBorder.Dotted || !hasBorderRadius ? this.element.border.size : 0;

		if (!svgNodes.length) return;
		svgNodes?.forEach((path: SVGAElement) => {
			path.style.width = `${size.width}px`;
			path.style.height = `${size.height}px`;
		});

		//  en caso de que el box tenga border sincronizamos sus nodos correspondientes
		if (this.element.border.size) {
			if (hasBorderRadius) {
				const pathNodes = this.baseNode?.querySelectorAll('.sync-path-box') as NodeListOf<SVGClipPathElement>;
				// ! en caso de que tengamos un border de tipo dotted, aplicamos el valor del border como offset , ya que este tiene que colocarse ajustarse
				// ! visualmente dentro del box
				// TODO: revisar en  implementación de tablas
				pathNodes.forEach((path) => {
					path.setAttribute(
						'd',
						Box.generateSvgBorder(size.width - offsetSize, size.height - offsetSize, [
							borderRadius,
							borderRadius,
							borderRadius,
							borderRadius,
						])
					);
				});

				// ? nodo que recorta el border por fuera: Tipo Dashed o Solid, y que tenga border radius aplicado
				// ? , ha de ajustarse solo en estos casos
				if (this.element.border.type !== TypeBorder.Dotted && hasBorderRadius) {
					const clipPathNode = this.baseNode?.querySelector('.sync-clipPath-box');

					clipPathNode?.setAttribute(
						'd',
						Box.generateSvgBorder(size.width, size.height, [borderRadius, borderRadius, borderRadius, borderRadius])
					);
					return;
				}
				return;
			}

			// ! en caso de no tener border radius aplicado se renderiza un elemento rect;
			// ! este elemento queda por dentro del box, y tiene aplicado también el offset
			const rectNode = this.baseNode?.querySelector('.sync-rect-box') as SVGAElement;
			rectNode.style.width = `${size.width - offsetSize}px`;
			rectNode.style.height = `${size.height - offsetSize}px`;
		}
	};
}
