<script lang="ts" setup>
import { ref } from 'vue';

import Image from '@/elements/medias/images/image/classes/Image';

withDefaults(
	defineProps<{
		alt?: string;
		classes?: string;
		dataSvg?: string;
		src: string;
	}>(),
	{
		alt: 'placeholder',
		classes: '',
		dataSvg: '',
		src: Image.defaults().url,
	}
);

const state = ref('');

const onLoad = () => (state.value = 'loaded');
</script>

<template>
	<div v-if="!state" class="absolute h-0 w-0 rounded bg-gray-700"></div>

	<img
		:alt="alt"
		:class="classes"
		:data-svg="dataSvg"
		:src="src"
		class="shrink-0 object-contain"
		draggable="true"
		loading="lazy"
		@load="onLoad"
	/>
</template>
