<script lang="ts" setup>
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { watchDebounced } from '@vueuse/shared';
import { useDebounceFn } from '@vueuse/shared';
import { computed, nextTick, ref, toRef } from 'vue';

import { getAutocompletedWords } from '@/api/DataApiClient';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePikiGame } from '@/editor/composables/usePikiGame';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';

interface CollectionElementInterface {
	id: number;
	name: string;
}

// Props
const props = defineProps<{ placeholder?: string; query: string; autocompleteSource: string; modal?: boolean }>();
// Emtis
const emits = defineEmits(['change']);

// Using composables
const store = useMainStore();
const { trans } = useI18n();
const { showPiki } = usePikiGame();

const query = toRef(props, 'query');
// Data
const collection = ref<string[] | CollectionElementInterface[]>([]);
const input = ref(query.value);
let lastEventIsPressArrow = false;

// Computeds
const customValue = computed(() => (store.activePanel === 'templates' ? { id: -1, name: query.value } : query.value));
const filteredCollection = computed(() => {
	// Comprobamos los datos obtenidos de la api y filtramos para ver si tenemos coincidencia con lo que hemos introducido por teclado
	// En caso de no encontrar coincidencia añadimos el query a los resultados y ocultamos el check de selección
	if (collection.value.length && query.value.length > 2) {
		return sortCollection(collection.value);
	}
	return collection.value;
});
const searchIsInResults = computed(() => {
	//TODO: FIX find type error
	// @ts-ignore
	return collection.value.find((d: CollectionElementInterface | string) => {
		if (typeof d === 'string') {
			return d.toLowerCase() === query.value.trim().toLowerCase();
		}
		return d.name.toLowerCase() === query.value.trim().toLowerCase();
	});
});

// Obtenemos la colección
const getCollection = async () => {
	const { data } = await getAutocompletedWords(props.autocompleteSource);

	collection.value = data.value || [];
};

const updateCollection = useDebounceFn(async (value: string) => {
	emits('change', value);

	// Si hemos escrito al menos dos carácteres obtenemos la nueva colección
	if (value.length > 2) {
		await nextTick();
		await getCollection();
	} else {
		collection.value = [];
	}
}, 200);

const handlerInput = async (e: any) => {
	let value: string | CollectionElementInterface = '';
	// Si existe un target, puede ser el input o un elemento de la lista
	if (e.target) {
		const parentLi = e.target?.closest('[id^="headlessui-combobox-option"]');

		value = parentLi
			? { id: parentLi.getAttribute('data-element-id'), name: parentLi.getAttribute('data-element-name') }
			: e.target.value;
	} else {
		// En caso de no haber un target no está mandando el valor introducido en el input
		value = e;
	}

	input.value = typeof value === 'string' ? value : value.name;
	await nextTick();
	// Actualizamos la colección
	updateCollection(input.value);
};

const sortCollection = (collect: string[] | CollectionElementInterface[]) =>
	collect.sort((a, b) =>
		typeof a === 'string' && typeof b === 'string'
			? a.length - b.length
			: (a as CollectionElementInterface).name.length - (b as CollectionElementInterface).name.length
	);

const getElementName = (element: unknown) => {
	if (!element) return '';

	return typeof element === 'string' ? element : (element as CollectionElementInterface).name;
};

const clearPlaceholder = () => {
	input.value = '';
};

// Events
const handleKeyPress = (e: KeyboardEvent) => {
	const ENTER_KEY_CODE = 13;
	const ARROW_KEY_CODES = [38, 40];
	if (e.keyCode === ENTER_KEY_CODE && !lastEventIsPressArrow) {
		(e.target as HTMLElement).blur();
		updateCollection(input.value);
	}
	if (ARROW_KEY_CODES.includes(e.keyCode)) {
		e.preventDefault();
		lastEventIsPressArrow = true;
		return;
	}
	lastEventIsPressArrow = false;
};

const mouseIsHover = () => {
	lastEventIsPressArrow = false;
};

// Watches
watchDebounced(
	input,
	(newVal) => {
		emits('change', newVal);
		showPiki.value = newVal.toLocaleLowerCase().includes('piki');
	},
	{ debounce: 500 }
);
</script>
<template>
	<div data-testid="autocomplete-container" class="z-20 mb-2 w-full">
		<Combobox :model-value="input" @update:model-value="handlerInput">
			<div
				class="relative w-full cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
			>
				<ComboboxInput
					autocomplete="off"
					class="flex h-10 w-full appearance-none rounded-full border pl-4 pr-10 text-sm shadow-none focus:outline-none lg:rounded"
					:class="
						modal
							? 'placeholder-gray-600/50b order-gray-200 bg-white text-gray-800 focus:border-gray-200'
							: 'border-transparent bg-gray-900 text-gray-100 placeholder-gray-300/50 focus:border-gray-700'
					"
					:placeholder="placeholder || trans('Search something...')"
					:display-value="getElementName"
					@keydown="handleKeyPress"
					@change="handlerInput"
				/>
				<ComboboxButton
					v-if="input.length > 0"
					class="absolute inset-y-0 right-8 flex w-10 items-center justify-center"
					@click="clearPlaceholder"
				>
					<span
						class="flex h-6 w-6 items-center justify-center rounded text-gray-300 hover:bg-gray-700 hover:text-gray-100"
					>
						<SvgIcon name="cross" class="h-3 w-3" />
					</span>
				</ComboboxButton>
				<ComboboxButton class="absolute inset-y-0 right-0 flex w-10 items-center justify-center">
					<SvgIcon name="search" class="h-4 w-4 fill-current text-gray-300" />
				</ComboboxButton>
			</div>

			<ComboboxOptions
				v-if="filteredCollection?.length > 0 && input.length > 0"
				class="absolute left-4 right-4 mt-1 max-h-60 overflow-auto rounded-md bg-gray-800 py-2 text-base text-gray-900 shadow-lg ring-1 ring-opacity-5 backdrop-blur scrollbar-thin scrollbar-thumb-gray-700 focus:outline-none sm:text-sm"
				:class="modal ? 'bg-white/95 ring-white' : 'bg-gray-900/95 ring-black'"
			>
				<!-- Use the `active` state to conditionally style the active option. -->
				<!-- Use the `selected` state to conditionally style the selected option. -->

				<ComboboxOption v-if="!searchIsInResults" v-slot="{ active, selected }" :value="customValue">
					<li
						class="relative mx-2 flex cursor-pointer select-none rounded px-4 py-2 capitalize"
						:class="
							modal
								? {
										'bg-gray-100/25 font-semibold text-gray-800': active,
										'text-gray-800/50': !active,
								  }
								: {
										'bg-gray-800/75 font-semibold text-white': active,
										'text-gray-100': !active,
								  }
						"
					>
						<span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
							{{ typeof customValue === 'string' ? customValue : customValue.name }}
						</span>
					</li>
				</ComboboxOption>

				<ComboboxOption
					v-for="element in filteredCollection"
					v-slot="{ active, selected }"
					:key="getElementName(element)"
					:value="element"
					as="template"
				>
					<li
						class="relative mx-2 flex cursor-pointer select-none rounded px-4 py-2"
						:class="
							modal
								? {
										'bg-gray-100/25 font-semibold text-gray-800': active,
										'text-gray-800/50': !active,
								  }
								: {
										'bg-gray-800/75 font-semibold text-white': active,
										'text-gray-100': !active,
								  }
						"
						:data-element-id="(element as CollectionElementInterface)?.id || null"
						:data-element-name="getElementName(element)"
						@mouseenter="() => mouseIsHover()"
					>
						<span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
							{{ typeof element === 'string' ? element : element.name }}
						</span>
						<span v-if="selected" class="-order-1 mr-2 flex items-center text-green-500">
							<SvgIcon data-test-id="check-export-modal" name="check" class="h-3 w-3 fill-current" />
						</span>
					</li>
				</ComboboxOption>
			</ComboboxOptions>
		</Combobox>
	</div>
</template>
