<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { useElementSize } from '@vueuse/core';
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import FlipMenuToolbar from '@/elements/element/components/menus/FlipMenuToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import CropButtonToolbar from '@/elements/medias/crop/components/buttons/CropButtonToolbar.vue';
import CropTimeButtonToolbar from '@/elements/medias/crop-time/components/CropTimeButton.vue';
import FilterButtonToolbar from '@/elements/medias/filter/components/buttons/FilterButtonToolbar.vue';
import ReplaceImageButtonToolbar from '@/elements/medias/images/image/components/buttons/ReplaceImageButtonToolbar.vue';
import ToBackgroundToolbar from '@/elements/medias/images/image/components/buttons/ToBackgroundToolbar.vue';
import MaskButtonToolbar from '@/elements/medias/mask/components/buttons/MaskButtonToolbar.vue';
import { Video } from '@/elements/medias/video/classes/Video';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

// Props
const props = defineProps<{ element: Video }>();
const element = toRef(props, 'element');

const { isPhotoMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { isBackground } = useIsBackground(element);

const { width } = useElementSize(element.value.domNode());
const { togglePanel } = usePanelManagement();
const scale = computed(() => store.scale);

const toolbarClass = computed(() => {
	const size = width.value * scale.value;
	if (size < 250) {
		return 'really-small-image';
	}
	if (size < 400) {
		return 'small-image';
	}

	return 'normal-image';
});

const onClickMoreTools = () => {
	Bugsnag.leaveBreadcrumb('Open edit video panel');
	GAnalytics.trackDebounceEditBarGA4({ category: 'video', link_text: 'more_tools' });

	togglePanel(EditPanels.Video);
};

const onClickRemove = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'video', link_text: 'delete' });
};
</script>

<template>
	<div :class="toolbarClass" class="overflow-auto lg:overflow-visible">
		<template v-if="!element.locked || isBackground">
			<CropTimeButtonToolbar :element="element" />
			<ReplaceImageButtonToolbar :element="element" />
			<MaskButtonToolbar :element="element" />
			<FilterButtonToolbar :element="element" />
			<CropButtonToolbar :element="element" />

			<template v-if="isMobile">
				<ToBackgroundToolbar :element="element" />
				<FlipMenuToolbar :element="element" />
				<OrderToolbar :element="element" />
				<CopyToolbar :element="element" />
				<RemoveToolbar :element="element" @remove="onClickRemove" />
			</template>

			<template v-else>
				<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 lg:block" />
				<RemoveToolbar :element="element" @remove="onClickRemove" />

				<MoreToolsToolbar :element="element" :panel="EditPanels.Group" @more-tools="onClickMoreTools" />
			</template>
		</template>
		<LockButtonToolbar v-if="!isPhotoMode && !isMobile && element.locked" :element="element" />
	</div>
</template>

<style scoped lang="scss">
@media (min-width: 1024px) {
	.normal-image :deep() *[tooltip]:before {
		display: none;
	}

	.small-image :deep() {
		[tooltip][data-button-importance='important']:before {
			display: none !important;
		}
		[data-button-importance='normal'] [data-button-label] {
			display: none !important;
		}
	}

	.really-small-image :deep() {
		[data-button-importance] [data-button-label] {
			display: none !important;
		}
	}
}
</style>
