import { MaybeElementRef, unrefElement, useResizeObserver } from '@vueuse/core';
import { ref, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';

export const useTextHeight = (text: MaybeElementRef) => {
	const store = useMainStore();

	const height = ref(0);

	function updateHeight() {
		const el = unrefElement(text);

		if (!el) {
			height.value = 0;
			return;
		}

		// Obtenemos el nodo principal del texto y su transformación
		const mainText = el.closest('[id^="element-"]') || el.closest('[id^="editable-"]');

		if (!(mainText instanceof HTMLElement)) {
			return;
		}

		const previousTransform = mainText.style.transform;

		// Si el texto tiene un rotate, lo rotamos a 0 grados para sacar el alto sin rotación
		if (previousTransform?.includes('rotate')) {
			const transformStart = `${previousTransform.split('rotate(')[0]} rotate(`;
			const transformEnd = `deg) ${previousTransform.split('deg)')[1]}`;

			mainText.style.transform = `${transformStart}0${transformEnd}`;
		}

		// Obtenemos el alto del texto
		const rect = el.getBoundingClientRect();
		height.value = rect.height / store.scale;

		// Restauramos el alto del texto
		if (previousTransform) {
			mainText.style.transform = previousTransform;
		}
	}

	useResizeObserver(text, updateHeight, { box: 'border-box' });

	watch(
		() => unrefElement(text),
		(ele) => !ele && requestAnimationFrame(updateHeight)
	);

	return { height };
};
