<script lang="ts" setup>
import { computed, ref, toRef } from 'vue';

import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import PhotosPanel from '@/elements/medias/images/image/components/panels/add/PhotosPanel.vue';
import NotPossibleInPhotoModeException from '@/elements/medias/replace/Exception/NotPossibleInPhotoModeException';
import { useReplaceElement } from '@/elements/medias/replace/useReplaceElement';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import { ImageApi, UploadApi } from '@/Types/apiClient';

// Props
const props = defineProps<{ element: Image | Video; labelPosition?: string }>();
const element = toRef(props, 'element');
const { replaceElement } = useReplaceElement(element);

// Data
const replacing = ref(false);

// Computeds
const isCropping = computed(() => !!store.croppingId?.length);

// Using composables
const store = useMainStore();
const toast = useToast();
// Methods
const onCloseModal = () => (replacing.value = false);

const replaceImage = async (image: UploadApi | ImageApi) => {
	if (image.type === 'svg') {
		toast.warning('Your svg has been uploaded, but it is not possible to replace a photo by an svg');
		replacing.value = false;
		return;
	}

	try {
		await replaceElement(image);
	} catch (error) {
		if (error instanceof NotPossibleInPhotoModeException) {
			toast.warning(error.message);
			return;
		}
		toast.warning('Something went wrong');
	}

	replacing.value = false;
};
const { trans } = useI18n();
</script>

<template>
	<div>
		<div>
			<button
				class="flex h-9 w-9 items-center justify-center rounded bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:w-full lg:px-3"
				data-testid="replace-image"
				:class="{
					'disabled cursor-not-allowed opacity-50': isCropping,
					'lg:h-9': labelPosition === 'right',
					'lg:h-8': labelPosition === 'bottom',
				}"
				:disabled="isCropping"
				@click="replacing = true"
			>
				<SvgIcon name="replace" class="h-4 w-4 scale-90" />
				<label v-if="labelPosition === 'right'" class="pointer-events-none ml-2 text-sm">{{ trans('Replace') }}</label>
			</button>
		</div>
		<label
			v-if="labelPosition === 'bottom'"
			class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700"
			>{{ trans('Replace') }}</label
		>

		<Modal
			v-if="replacing"
			content-classes="rounded w-full  lg:w-auto lg:max-w-lg lg:min-w-lg relative bg-white max-h-full py-4 lg:py-5 px-4 lg:px-6 h-[90vh]"
			open
			@close="replacing = false"
		>
			<PhotosPanel data-testid="replace-img-modal" modal @closeModal="onCloseModal" @selection="replaceImage" />
		</Modal>
	</div>
</template>
