import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import Element from '@/elements/element/classes/Element';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { Color } from '@/Types/colorsTypes';
import {
	FontStyle,
	FontWeight,
	LegacyTextValues,
	ListStyle,
	TextAlign,
	TextDTO,
	TextTransform,
} from '@/Types/elements.d';
import {
	CurvedProperties,
	PrimaryElementTypes,
	SerializedClass,
	Size,
	TextOutlineProperties,
	TextShadowProperties,
} from '@/Types/types';

export class Text extends Element {
	type: PrimaryElementTypes = 'text';
	content: string;
	fontFamily: string;
	fontWeight: FontWeight;
	fontStyle: FontStyle;
	fontSize: number;
	lineHeight: number;
	letterSpacing: number;
	textAlign: TextAlign;
	outline: TextOutlineProperties;
	colors: Color[];
	textTransform: TextTransform;
	scale: number;
	textShadow: TextShadowProperties[];
	listStyle: ListStyle;
	curvedProperties: CurvedProperties;

	protected constructor(textDTO: TextDTO) {
		super(textDTO);

		this.content = textDTO.content;
		this.fontFamily = textDTO.fontFamily;
		this.fontWeight = textDTO.fontWeight;
		this.fontStyle = textDTO.fontStyle;
		this.fontSize = textDTO.fontSize;
		this.lineHeight = textDTO.lineHeight;
		this.letterSpacing = textDTO.letterSpacing;
		this.textAlign = textDTO.textAlign;
		this.outline = textDTO.outline;
		this.colors = textDTO.colors;
		this.textTransform = textDTO.textTransform;
		this.scale = textDTO.scale;
		this.textShadow = textDTO.textShadow;
		this.listStyle = textDTO.listStyle;
		this.curvedProperties = textDTO.curvedProperties;
	}

	static defaults(): TextDTO {
		const blackColor = SolidColor.black();

		return {
			// Element
			...Element.defaults(),
			type: 'text',
			// Text
			content: 'Text',
			fontFamily: 'Montserrat',
			fontWeight: 400 as FontWeight,
			fontStyle: 'normal' as FontStyle,
			fontSize: 16,
			lineHeight: 1.2,
			letterSpacing: 0,
			textAlign: 'center' as TextAlign,
			outline: {
				color: SolidColor.gray(),
				width: 0,
			},
			colors: [blackColor],
			textTransform: '' as TextTransform,
			scale: 1,
			textShadow: [] as TextShadowProperties[],
			listStyle: '' as ListStyle,
			curvedProperties: {
				arc: null,
				minArc: 0,
				transformCurve: 0,
			},
		};
	}

	static create(config: Partial<TextDTO> = {}): Text {
		const textDTO = {
			...Text.defaults(),
			...config,
		};

		return new Text(textDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Text> & LegacyTextValues): Text {
		const textDTO = {
			...Text.defaults(),
			...data,
		} as TextDTO;

		// El valor inicial del outline.width será el fontSize/em al aplicar el efecto
		if (data.outline?.width && data.outline.unit === 'em' && data.fontSize) {
			const em = 16;
			const factor = data.fontSize / em;
			textDTO.outline.unit = 'px';
			textDTO.outline.width *= em * factor;
			textDTO.outline.width = parseInt(data.outline.width.toString());
		}

		// Parseamos el contenido del texto y convertimos los colores rgb a variable css
		// además solucionamos un problema con el tag <font> que nos da problemas con el
		// texto multi-estilo (issue #7917)
		textDTO.content = TextTools.fixFontTagInTextContent(TextTools.parseTextColorsToCssVars(data));

		const fixedTextTransform = Text.unserializeTextTransform(data.textTransform);

		if (fixedTextTransform) {
			textDTO.textTransform = fixedTextTransform;
		}

		// Gradients have id (and more) but Solids only have color channels (r,g,b,a)
		textDTO.outline = Text.unserializeOutline(data);

		textDTO.textShadow = Text.unserializeTextShadow(data);

		if (data.colors) {
			textDTO.colors = Array.isArray(data.colors)
				? data.colors.map((c) => ('stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)))
				: Object.values<Color>(data.colors).map((c) =>
						'stops' in c ? GradientColor.unserialize(c) : SolidColor.unserialize(c)
				  );
		}

		// durante un día se almacenaron las fuentes con comillas
		if (data.fontFamily && data.fontFamily.startsWith('"')) {
			textDTO.fontFamily = data.fontFamily.replaceAll('"', '');
		}

		if (data.size && !data.size.height) {
			// Hay textos que no tienen alto por un bug en el editor
			textDTO.size = {
				width: data.size.width,
				height: data.fontSize || 30,
			};
		}

		const elem = new Text(textDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	protected static unserializeTextTransform(textTransform: TextTransform | undefined) {
		return ['', 'lowercase', 'uppercase', 'capitalize'].includes(textTransform as string) ? textTransform : '';
	}

	protected static unserializeOutline(data: SerializedClass<Text> & LegacyTextValues): TextOutlineProperties {
		const { color, width } = Text.defaults().outline;
		const borderWidth = data.borderWidth || data.outline?.width || width;

		let fixedOutlineColor: Color = color;

		// Support legacy data structure
		if (data.borderColor) {
			fixedOutlineColor =
				'stops' in data.borderColor
					? GradientColor.unserialize(data.borderColor)
					: SolidColor.unserialize(data.borderColor);
		}

		// Support new data structure
		if (data.outline?.color) {
			fixedOutlineColor =
				'stops' in data.outline.color
					? GradientColor.unserialize(data.outline.color)
					: SolidColor.unserialize(data.outline.color);
		}

		if (!borderWidth) {
			fixedOutlineColor = Text.defaults().outline.color;
		}

		return {
			color: fixedOutlineColor,
			width: borderWidth,
			unit: data.outline?.unit || 'px',
		};
	}

	protected static unserializeTextShadow(data: SerializedClass<Text> & LegacyTextValues) {
		let fixedTextShadow = Text.defaults().textShadow;

		// en algun caso se almacena como objeto
		if (typeof data.textShadow === 'object') {
			data.textShadow = Object.values(data.textShadow as object);
		}

		// Fix por un error al parsear los textos nativos en el template loader
		data.textShadow = data.textShadow?.filter((ts) => !Array.isArray(ts));

		// Support legacy data structure
		if (Object.keys(data).includes('shadowAngle')) {
			fixedTextShadow = [
				{
					angle: typeof data.shadowAngle === 'string' ? parseFloat(data.shadowAngle) : data.shadowAngle,
					blur: typeof data.shadowBlur === 'string' ? parseFloat(data.shadowBlur) : data.shadowBlur,
					color: SolidColor.unserialize(data.shadowColor),
					distance: typeof data.shadowDistance === 'string' ? parseFloat(data.shadowDistance) : data.shadowDistance,
					opacity: typeof data.shadowOpacity === 'string' ? parseFloat(data.shadowOpacity) : data.shadowOpacity,
				},
			];
		}

		if (data.textShadow && data.textShadow.length) {
			const needConverToEmUnits = data.textShadow.some((shadow) => !shadow.unit || shadow.unit !== 'em');
			fixedTextShadow = needConverToEmUnits
				? data.textShadow.map((ts) => TextTools.convertShadowToEm(data, ts))
				: data.textShadow;
		}

		// Support new data structure
		if (fixedTextShadow.length) {
			fixedTextShadow = Array.isArray(data.textShadow)
				? fixedTextShadow.map((ts) => ({ ...ts, color: SolidColor.unserialize(ts.color) }))
				: Object.values<TextShadowProperties>(fixedTextShadow).map((ts) => ({
						...ts,
						color: SolidColor.unserialize(ts.color),
				  }));
		}

		return fixedTextShadow;
	}

	setScale(scale: number) {
		this.scale = scale;
	}

	updateColor(newColor: Color) {
		this.colors[0] = newColor;
	}

	htmlInstance() {
		const parser = new DOMParser();
		return parser.parseFromString(`<div>${this.content}</div>`, 'text/html');
	}

	scaleBy(scale: number) {
		super.scaleBy(scale);
		this.fontSize *= scale * this.scale;
		this.outline.width *= scale * this.scale;
		this.letterSpacing *= scale * this.scale;

		const temporalDiv = document.createElement('div');

		temporalDiv.innerHTML = this.content;

		temporalDiv
			.querySelectorAll<HTMLElement>('[style*="font-size"]')
			.forEach((e) => (e.style.fontSize = `${parseInt(e.style.fontSize) * scale * this.scale}px`));

		temporalDiv
			.querySelectorAll<HTMLElement>('[style*="letter-spacing"]')
			.forEach((e) => (e.style.letterSpacing = `${parseInt(e.style.letterSpacing) * scale * this.scale}px`));

		temporalDiv.querySelectorAll<HTMLOListElement | HTMLUListElement>('ol, ul').forEach((e) => {
			e.style.marginLeft = `${parseFloat(e.style.marginLeft) * scale * this.scale}px`;
		});

		this.content = temporalDiv.innerHTML;

		if (this.curvedProperties.arc) {
			this.curvedProperties.minArc *= scale * this.scale;
			this.curvedProperties.arc *= scale * this.scale;
			this.curvedProperties.transformCurve *= scale * this.scale;
		}

		this.scale = 1;
	}

	isValidSize(proposedSize: Size): boolean {
		return proposedSize.width > this.minBoxWidth && proposedSize.height > this.minBoxHeight;
	}

	updateContent(content: string) {
		this.content = this.fixTextStyles(content);
	}

	private fixTextStyles(content: string) {
		// De forma nativa al añadir un salto de linea en un texto con outline,
		// y eliminar el salto de linea se le aplica nativamente un -webkit-text-stroke-color,
		// este tiene que ser eliminado
		let fixed = TextTools.fixTextStrokeColorStyles(content);

		// Arreglamos los estilos de los textos con gradientes,
		// ya que al aplicar nuevos estilos se pierde el prefijo -webkit del background-clip
		fixed = TextTools.fixBackgroundClip(fixed);

		return fixed;
	}

	get minBoxWidth() {
		return 5;
	}

	get minBoxHeight() {
		return 5;
	}

	get color() {
		return this.colors[0];
	}

	getTextPadding() {
		return this.fontSize * 0.25;
	}
}
