<script lang="ts" setup>
import { until } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue';

import AlternativePalettes from '@/color/components/AlternativePalettes.vue';
import ColorPickerGroup from '@/color/components/ColorPickerGroup.vue';
import ColorSelector from '@/color/components/ColorSelector.vue';
import TagColors from '@/color/components/TagColors.vue';
import { useProjectColors } from '@/color/composables/useProjectColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const store = useMainStore();
const { isAdminMode, isTagMode } = useEditorMode();
const { closePanel } = usePanelManagement();

const { trans } = useI18n();
const history = useHistoryStore();

const {
	colors,
	textsColors,
	hasQrColor,
	hasOutlineColor,
	hasTextShadowColor,
	hasCurvedTextColor,
	linesStrokeColors,
	colorSelected,
	updateTemplateColor,
	getElementsWithColorSelected,
} = useProjectColors();

const colorSelectorKey = ref(colorSelected.value?.id);

watch(
	() => history.key,
	() => {
		// Buscamos la referencia del color a volver atrás
		const colorFound = colors.value.find((c) => {
			if (!colorSelected.value) return;
			return c.id === colorSelected.value.id;
		});

		// Si no ha encontrado un color seleccionamos el primero de los colores disponibles
		if (!colorFound && colors.value.length) {
			colorSelected.value = colors.value[0];
			colorSelectorKey.value = colors.value[0].id;
			return;
		}

		colorSelected.value = colorFound;
	}
);

const disableGradient = computed(
	() =>
		colorSelected.value &&
		(hasQrColor(colorSelected.value) ||
			hasTextShadowColor(colorSelected.value) ||
			hasOutlineColor(colorSelected.value) ||
			hasCurvedTextColor(colorSelected.value))
);

const updateColor = (color: Color) => {
	if (!colorSelected.value) return;
	updateTemplateColor(colorSelected.value, color);
};

const selectColor = (color: Color) => {
	colorSelected.value = color;
	colorSelectorKey.value = color.id;
};

const refreshColorSelector = () => {
	colorSelected.value = colors.value[0];
	colorSelectorKey.value = 'refresh-color-selector';
};

const selectionStyle = computed(() => {
	if (!colorSelected.value) return '';

	return `.project-colors-panel .cpb-${colorSelected.value.id}::after {
		content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: 3px;
    border-radius: 9999px;
    border-width: 3px;
    border-color: rgb(42 55 66 / 1);
	}
	`;
});

watch(
	colorSelected,
	() => {
		getElementsWithColorSelected();
	},
	{ immediate: true }
);

onMounted(async () => {
	await until(colors).toMatch((c) => !!c.length);
	if (!colorSelected.value) {
		colorSelected.value = colors.value[0];
	}
});
</script>

<template>
	<div data-testid="project-colors-panel" class="project-colors-panel">
		<component :is="'style'" type="text/css">
			{{ selectionStyle }}
		</component>

		<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
			<h2 class="text-xl font-semibold text-gray-100">{{ trans('Template style') }}</h2>
			<button class="text-gray-100 focus:outline-none hover:text-white" data-testid="close-panel" @click="closePanel()">
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>

		<div v-if="colorSelected" data-testid="sidebar-panel" class="flex flex-col">
			<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Colors') }}</h4>
			<ColorPickerGroup
				class="mb-4 flex-wrap lg:!gap-2"
				picker-class="w-6 lg:w-8 lg:h-8 shrink-0"
				:max="Infinity"
				:colors="colors"
				:texts-colors="textsColors"
				:lines-stroke-colors="linesStrokeColors"
				:prevent-change="true"
				:hide-alpha="true"
				@select="selectColor"
			/>
			<div class="rounded-lg bg-gray-900/50 p-2">
				<ColorSelector
					:key="colorSelectorKey"
					:color="colorSelected"
					:hide-alpha="true"
					:hide-gradient="disableGradient"
					@change="updateColor"
				/>
			</div>
			<hr v-if="isTagMode" class="my-6 border-gray-700" />

			<TagColors v-if="isTagMode" :color="colorSelected" />
			<AlternativePalettes
				v-if="store.colorPalettes.length > 1 || isAdminMode"
				@paletteSelected="refreshColorSelector"
			/>
		</div>
	</div>
</template>
<style lang="sass">
.project-colors-panel .custom-picker .vc-sketch .vc-sketch-saturation-wrap
	padding-bottom: 50%

	@media (max-width: 1023px)
		padding-bottom: 25%
</style>
