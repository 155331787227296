import { Ref } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import { useTransform } from '@/elements/element/composables/useTransform';
import { useArtboard } from '@/project/composables/useArtboard';
import { Anchor, BorderRadiusType, Position } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useBoxTransform = (element: Ref<Box>) => {
	const usingTransform = useTransform(element);
	const { artboardSizeInPx: sizeInPx } = useArtboard();

	const setupInPage = (mousePosition?: Position) => {
		// Guardamos el border size y el ratio para mantener la proporción
		const lastBorderSize = element.value.border.size;
		const minSide: 'width' | 'height' = element.value.size.width <= element.value.size.height ? 'width' : 'height';
		const lastMinSide = element.value.size[minSide];
		const lastRadius = element.value.border.radius;

		// Ajustamos el tamaño de la figura a la mitad de la página
		if (minSide === 'width') {
			usingTransform.adjustTo('height', sizeInPx.value.height / 2);
		} else {
			usingTransform.adjustTo('width', sizeInPx.value.width / 2);
		}

		const currentMinSide = element.value.size[minSide];

		// Calculamos el nuevo border size para que siempre se vea igual que la figura que se subió
		element.value.border.size = MathTools.ruleOfThree(lastMinSide, lastBorderSize, currentMinSide);

		// TODO: revisar en  implementación de tablas
		// Calculamos el nuevo border size para que siempre se vea igual que la figura que se subió
		const calculatedRadius = lastRadius.map((radius) => MathTools.ruleOfThree(lastMinSide, radius, currentMinSide));
		element.value.border.radius = element.value.border.radius.map(
			(_, index) => calculatedRadius[index]
		) as BorderRadiusType;

		if (mousePosition) {
			usingTransform.move(
				mousePosition.x - element.value.size.width / 2,
				mousePosition.y - element.value.size.height / 2,
				false
			);
		} else {
			usingTransform.align(Anchor.center);
		}
	};

	return {
		...usingTransform,
		setupInPage,
	};
};
