import { useElementBounding } from '@vueuse/core';
import { computed, Ref } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';

export const useToolbarTarget = (element: Ref<Element>) => {
	const domNode = computed(() => element.value.domNode());
	const { isPhotoMode } = useEditorMode();
	const TOPBAR_HEIGHT = 56;
	const isCurvedText = computed(() => element.value instanceof Text && element.value?.curvedProperties?.arc);

	const target = computed(() => {
		// En el modo foto mostramos el toolbar de los foregrounds, en estos casos hacemos que el target sea el elemento y no la página
		return isBackground.value && !(element.value instanceof ForegroundImage && isPhotoMode.value)
			? page.value.domNode()
			: domNode.value;
	});

	const { isBackground } = useIsBackground(element);
	const { page } = usePageElement(element);
	const getPopperPlacement = () => {
		const { top } = useElementBounding(domNode.value);

		if (isCurvedText.value) return 'top';
		if (
			(isBackground.value && !isPhotoMode.value) ||
			(element.value instanceof Image && top.value - 30 < TOPBAR_HEIGHT)
		)
			return 'bottom-end';

		return 'top-end';
	};
	return {
		target,
		getPopperPlacement,
	};
};
