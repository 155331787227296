<script setup lang="ts">
import { computed, toRef } from 'vue';

import GradientsRenderer from '@/color/components/GradientsRenderer.vue';
import { useLineColors } from '@/color/composables/useLineColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import Line from '@/elements/line/classes/Line';
import LineHandlers from '@/elements/line/components/LineHandlers.vue';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';

const props = defineProps<{
	element: Line;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
}>();

const element = toRef(props, 'element');

const mobileBoxSize = computed(() => {
	const extra = 2;
	const min = 15;
	const size = element.value.size.height * extra;

	return size < min ? min : size;
});

const store = useMainStore();
const { selection } = useSelection();

const { gradientColors } = useLineColors(element);
const { isIdle } = useInteractions();
const { isMobile } = useDeviceInfo();
const { isZooming, isPinching } = useZoom();
</script>

<template>
	<svg
		class="relative block h-full w-full overflow-visible"
		style="min-height: 1px; min-width: 1px"
		:style="{
			opacity: element.opacity,
			// hay que insitir con el overflow visible para que funcione al renderizar el canvas
			overflow: 'visible',
		}"
	>
		<g :transform="`scale(${element.size.height}) translate(0, 0.5)`">
			<!--
				Devido a una limitación del render de chrome las líneas rectas no pueden tener degradados,
				así que usamos un rect para que sea el que tenga el degradado y a su vez le aplicamos una
				máscara que será la línea
			-->
			<mask :id="`maskline-${element.id}`">
				<line
					y1="0.5"
					y2="0.5"
					:x2="element.size.width / element.size.height"
					:style="{
						stroke: '#FFF',
						'stroke-linecap': element.linecap,
						'stroke-dasharray': element.dasharrayToString(),
					}"
				/>
			</mask>
			<rect
				data-testid="line"
				height="1"
				:width="element.size.width / element.size.height + 1"
				transform="translate(0, -0.5)"
				:fill="element.mainColor.toElementReference(previewName)"
				:mask="`url(#maskline-${element.id})`"
				x="-0.5"
				y="0"
			/>
			<g
				v-if="element.markerStart"
				data-testid="marker-start"
				transform="translate(0)"
				:style="{
					'--start-sticky-color': element.markerStart.color.toElementReference(previewName),
				}"
				v-html="element.markerStart.element"
			></g>
			<g
				v-if="element.markerEnd"
				data-testid="marker-end"
				:transform="`translate(${element.size.width / element.size.height})`"
				:style="{
					'--end-sticky-color': element.markerEnd.color.toElementReference(previewName),
				}"
				v-html="element.markerEnd.element"
			></g>
		</g>
		<!-- Para mejorar la selección en mobile -->
		<rect
			v-if="isMobile"
			:transform="`translate(0, -${mobileBoxSize / 4})`"
			:width="element.size.width"
			:height="mobileBoxSize"
			style="fill: rgba(0, 0, 0, 0)"
		/>
	</svg>
	<GradientsRenderer :preview-name="previewName" :gradients="gradientColors" />
	<Teleport
		v-if="selection.length === 1 && selection[0] === element && isIdle && !isZooming && !isPinching"
		to="#portalTargetCustomHandlers"
	>
		<LineHandlers :element="element" />
	</Teleport>
</template>
