<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import NumberInput from '@/common/components/NumberInput.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useArtboardCustomSize } from '@/project/composables/useArtboardCustomSize';
import { useProjectStore } from '@/project/stores/project';
import { FullSize } from '@/Types/types';

const project = useProjectStore();
const store = useMainStore();

defineProps({
	dropdownMode: {
		type: Boolean,
		default: true,
	},
});

const { trans } = useI18n();
const emit = defineEmits(['changeArtboardSizeAndUnit']);

const proposedSize = ref<FullSize>({
	width: project.size.width / store.scaleMaxAllowedSize,
	height: project.size.height / store.scaleMaxAllowedSize,
	unit: project.unit,
});

const hasChanged = computed(
	() =>
		proposedSize.value.width !== project.size.width / store.scaleMaxAllowedSize ||
		proposedSize.value.height !== project.size.height / store.scaleMaxAllowedSize ||
		proposedSize.value.unit !== project.unit
);

const projectArtboard = computed(() => ({
	width: project.size.width / store.scaleMaxAllowedSize,
	height: project.size.height / store.scaleMaxAllowedSize,
	unit: project.unit,
}));

const maxMessage = computed(() =>
	proposedSize.value.unit === 'px' ? ARTBOARD_LIMIT_MESSAGE.MAX.PX : ARTBOARD_LIMIT_MESSAGE.MAX.MM
);

const minMessage = computed(() =>
	proposedSize.value.unit === 'px' ? ARTBOARD_LIMIT_MESSAGE.MIN.PX : ARTBOARD_LIMIT_MESSAGE.MIN.MM
);

const {
	ARTBOARD_LIMIT_MESSAGE,
	isValid,
	minValue,
	maxValue,
	getClampedSizeKeepingAspectRatio,
	getSizeKeepingAspectRatio,
} = useArtboardCustomSize(proposedSize);
const { isMobile } = useDeviceInfo();
const { isPhotoMode } = useEditorMode();
const { listen } = useOrderedKeyboardListener();

watch(
	projectArtboard,
	() => {
		proposedSize.value.width = Math.round(project.size.width / store.scaleMaxAllowedSize);
		proposedSize.value.height = Math.round(project.size.height / store.scaleMaxAllowedSize);
		proposedSize.value.unit = project.unit;
	},
	{ deep: true }
);

// Register events
listen('Enter', () => {
	if (!hasChanged.value || !isValid.value) return;
	onConfirmUpdateSize();
});

// Methods
const onBlur = (key: 'width' | 'height', value: number) => {
	if (isPhotoMode.value) {
		const clampedSizeKeepingAspectRatio = getClampedSizeKeepingAspectRatio(key, value, projectArtboard.value);
		if (clampedSizeKeepingAspectRatio) proposedSize.value = clampedSizeKeepingAspectRatio;
		return;
	}

	proposedSize.value[key] = Math.round(value);
	Bugsnag.leaveBreadcrumb(
		`Change artboard size to ${proposedSize.value.width} x ${proposedSize.value.height} ${proposedSize.value.unit}`
	);
};

const onChangeSize = (key: 'width' | 'height', value: number) => {
	if (isPhotoMode.value) {
		const sizeKeepingAspectRatio = getSizeKeepingAspectRatio(key, value, projectArtboard.value);
		if (sizeKeepingAspectRatio) proposedSize.value = sizeKeepingAspectRatio;
		return;
	}

	proposedSize.value[key] = Math.round(value);
	Bugsnag.leaveBreadcrumb(
		`Change artboard size to ${proposedSize.value.width} x ${proposedSize.value.height} ${proposedSize.value.unit}`
	);
};

const onConfirmUpdateSize = () => {
	emit(
		'changeArtboardSizeAndUnit',
		{
			width: proposedSize.value.width,
			height: proposedSize.value.height,
		},
		proposedSize.value.unit
	);
	Bugsnag.leaveBreadcrumb(
		`Confirm new artboard size to ${proposedSize.value.width} x ${proposedSize.value.height} ${proposedSize.value.unit}`
	);
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Input', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Input', `click-${category}`, null);
};
</script>

<template>
	<div
		class="flex w-full shrink-0 items-center gap-2"
		data-testid="artboard-custom-size"
		:class="{
			'pl-4': !isPhotoMode && dropdownMode,
			'w-12': !dropdownMode,
			'fixed left-0 top-14 h-12 w-screen border-t border-gray-600 bg-gray-700 px-3 lg:relative lg:top-auto lg:h-auto lg:w-auto lg:border-0 lg:bg-transparent':
				isPhotoMode || !dropdownMode,
		}"
	>
		<!-- Width -->
		<div class="relative w-full">
			<NumberInput
				class="h-9 rounded text-sm"
				:class="{
					'border border-gray-200': !isPhotoMode && dropdownMode,
					'bg-gray-800': isPhotoMode || !dropdownMode,
				}"
				:has-inner-label="!isMobile"
				:is-transparent="true"
				:max="maxValue"
				:min="minValue"
				:name="'w'"
				:white-version="dropdownMode"
				:test-id="'input-width'"
				:value="proposedSize.width"
				@blur="(width) => onBlur('width', width as number)"
				@rawUpdate="(width) => onChangeSize('width', width as number)"
				@track-step="trackStepEvent('update-artboard-width')"
				@track-typing="trackTypingEvent('update-artboard-width')"
			/>
			<span
				v-if="!isMobile"
				class="absolute top-0 left-0 flex h-full w-6 items-center justify-center text-2xs text-gray-300"
				>W</span
			>
		</div>

		<!-- Height -->
		<div class="relative w-full">
			<NumberInput
				class="h-9 rounded text-sm"
				:class="{
					'border border-gray-200': !isPhotoMode && dropdownMode,
					'bg-gray-800': isPhotoMode || !dropdownMode,
				}"
				:has-inner-label="!isMobile"
				:is-transparent="true"
				:max="maxValue"
				:min="minValue"
				:name="'h'"
				:white-version="dropdownMode"
				:test-id="'input-height'"
				:value="proposedSize.height"
				@blur="(height) => onBlur('height', height as number)"
				@rawUpdate="(height) => onChangeSize('height', height as number)"
				@track-step="trackStepEvent('update-artboard-height')"
				@track-typing="trackTypingEvent('update-artboard-height')"
			/>
			<span
				v-if="!isMobile"
				class="absolute top-0 left-0 flex h-full w-6 items-center justify-center text-2xs text-gray-300"
				>H</span
			>
		</div>

		<div v-if="!isValid" class="absolute -bottom-11 left-0 w-full rounded-sm bg-red-500 p-1 px-2 text-xs text-white">
			{{ trans('Resolution can not be greater than') }}
			<strong>{{ maxMessage }}</strong> {{ trans('or smaller than') }}
			<strong>{{ minMessage }}</strong>
		</div>

		<!-- Unit -->
		<div v-if="!isPhotoMode" class="relative w-14 shrink-0">
			<select
				v-model="proposedSize.unit"
				data-testid="select-unit"
				class="flex h-9 w-full cursor-pointer appearance-none items-center truncate rounded pl-2 pr-5 text-sm text-gray-500 focus:outline-none"
				:class="{
					'border border-gray-200': !isPhotoMode && dropdownMode,
					'bg-gray-800': isPhotoMode || !dropdownMode,
				}"
			>
				<option value="px">px</option>
				<option value="mm">mm</option>
			</select>

			<span
				class="pointer-events-none absolute top-0 right-0 flex h-full w-8 items-center justify-center focus:outline-none"
			>
				<SvgIcon name="arrow" class="h-3 w-3 text-gray-300" />
			</span>
		</div>

		<!-- Confirm size change -->
		<button
			data-testid="confirm-artboard-size"
			class="relative h-8 shrink-0 px-2 text-sm text-gray-100 lg:h-9"
			:class="{
				'text-green-400 hover:text-green-300': hasChanged,
				'cursor-not-allowed opacity-50 hover:text-gray-500': !hasChanged,
			}"
			:disabled="!hasChanged || !isValid"
			@click="onConfirmUpdateSize"
		>
			<SvgIcon v-if="isValid" data-testid="icon-check" name="check" class="h-4 w-4" />
			<SvgIcon v-else name="cross" data-testid="icon-cross" class="h-4 w-4 text-red-500" />
		</button>
	</div>
</template>
