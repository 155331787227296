<script setup lang="ts">
import { useMouseInElement } from '@vueuse/core';
import { computed, nextTick, onUnmounted, ref, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
import ContextMenuContainer from '@/interactions/components/context-menu/ContextMenuContainer.vue';
import ContextMenuOption from '@/interactions/components/context-menu/ContextMenuItem.vue';
import ContextMenuSeparator from '@/interactions/components/context-menu/ContextMenuSeparator.vue';
import { useContextMenu } from '@/interactions/composables/useContextMenu';
import { useSelection } from '@/interactions/composables/useSelection';
import { Position } from '@/Types/types';

const store = useMainStore();
const { selection: elements } = useSelection();

const history = useHistoryStore();

// Data
const domNode = ref();
const positionInPage = ref<Position>({
	x: 0,
	y: 0,
});

// Template refs
const container = ref();

// Using composables
const { trans } = useI18n();
const {
	canBeCopied,
	canBePasted,
	currentClipboardData,
	canGoUpAndDown,
	enabledToBg,
	isBackgroundableSelected,
	isLockGroup,
	lockGroup,
	unlockGroup,
	backward,
	copy,
	forward,
	paste,
	remove,
	lock,
	setAsBg,
	toBack,
	toFront,
} = useContextMenu();
const { elementX, elementY } = useMouseInElement(domNode);

// Computeds
const activePage = computed(() => store.activePage);
const activeState = computed(() => history.activeState);

// Watchers
watch(
	activePage,
	(newVal) => {
		domNode.value = newVal?.domNode();
	},
	{ immediate: true }
);

// Watcher para controlar el refresco de posición X e Y del ratón cuando hay un cambio en el historial
watch(activeState, async () => {
	// Forzamos la reasignación del nodo de la página
	domNode.value = undefined;

	await nextTick();

	setTimeout(() => {
		try {
			domNode.value = activePage.value?.domNode();
		} catch (error) {}
	}, 0);
});

// Methods
const onCopy = () => {
	copy();
	hideContextMenu();
};

const onPaste = () => {
	paste(positionInPage.value);
	hideContextMenu();
};

const onRemove = () => {
	remove();
	hideContextMenu();
};

const onForward = () => {
	forward();
	hideContextMenu();
};

const onBackward = () => {
	backward();
	hideContextMenu();
};

const onToFront = () => {
	toFront();
	hideContextMenu();
};

const onToBack = () => {
	toBack();
	hideContextMenu();
};

const onLock = () => {
	lock();
	hideContextMenu();
	store.editPanel = null;
};

const hideContextMenu = () => {
	container.value.hide();
};

const onShow = async () => {
	if (!domNode.value) {
		domNode.value = activePage.value?.domNode();
		await nextTick();
	}

	positionInPage.value = {
		x: elementX.value / store.scale,
		y: elementY.value / store.scale,
	};

	if (navigator.clipboard.readText) {
		const clipboardText = await navigator.clipboard.readText();
		currentClipboardData.value = EventTools.purifyClipboard(clipboardText);
	}
};

const onLockGroup = () => {
	lockGroup(elements.value);
};

const onUnlockGroup = () => {
	unlockGroup(elements.value);
};

const onSetAsBg = () => {
	setAsBg();
};

onUnmounted(() => {
	currentClipboardData.value = '';
});
</script>

<template>
	<context-menu-container ref="container" class="min-w-max" @show="onShow">
		<context-menu-option :enabled="!!canBeCopied" :run="onCopy">
			<SvgIcon name="copy" class="mr-2 h-3 w-3" /> {{ trans('Copy') }}
		</context-menu-option>
		<context-menu-option :enabled="!!canBePasted || !!currentClipboardData.length" :run="onPaste">
			<SvgIcon name="paste" class="mr-2 h-3 w-3" /> {{ trans('Paste') }}
		</context-menu-option>
		<context-menu-separator />
		<context-menu-option :enabled="!!canBeCopied" :run="onRemove" danger>
			<SvgIcon name="trash" class="mr-2 h-3 w-3" /> {{ trans('Delete') }}
		</context-menu-option>
		<context-menu-separator />
		<context-menu-option :enabled="!!canGoUpAndDown.forward" :run="onForward">
			<SvgIcon name="layer-up" class="mr-2 h-3 w-3" /> {{ trans('Forward') }}
		</context-menu-option>
		<context-menu-option :enabled="!!canGoUpAndDown.backward" :run="onBackward">
			<SvgIcon name="layer-down" class="mr-2 h-3 w-3" /> {{ trans('Backward') }}
		</context-menu-option>
		<context-menu-option :enabled="!!canGoUpAndDown.forward" :run="onToFront">
			<SvgIcon name="forward" class="mr-2 h-3 w-3" /> {{ trans('To front') }}
		</context-menu-option>
		<context-menu-option :enabled="!!canGoUpAndDown.backward" :run="onToBack">
			<SvgIcon name="backward" class="mr-2 h-3 w-3" /> {{ trans('To back') }}
		</context-menu-option>
		<context-menu-option v-if="elements.length > 1 && !isLockGroup" :enabled="!isLockGroup" :run="onLockGroup">
			<SvgIcon name="lock" class="mr-2 h-3 w-3" /> {{ trans('Lock Group') }}
		</context-menu-option>
		<context-menu-option v-if="elements.length > 1 && isLockGroup" :enabled="isLockGroup" :run="onUnlockGroup">
			<SvgIcon name="unlock" class="mr-2 h-3 w-3" /> {{ trans('Unlock Group') }}
		</context-menu-option>
		<context-menu-option v-if="isBackgroundableSelected && enabledToBg" :enabled="enabledToBg" :run="onSetAsBg">
			<SvgIcon name="background" class="mr-2 h-3 w-3" /> {{ trans('Background') }}
		</context-menu-option>
		<context-menu-separator />
		<context-menu-option v-if="elements.length" enabled :run="onLock">
			<SvgIcon :name="elements[0].locked ? 'unlock' : 'lock'" class="mr-2 h-3 w-3" />
			{{ elements[0].locked ? trans('Unlock') : trans('Lock') }}
		</context-menu-option>
	</context-menu-container>
</template>
