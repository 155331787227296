<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useOfflineMode } from '@/common/composables/useOfflineMode';
import { useMainStore } from '@/editor/stores/store';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';

const project = useProjectStore();
const historyStore = useHistoryStore();
const store = useMainStore();

const { isMac } = useDeviceInfo();
const { trans } = useI18n();

const canGoForward = computed(
	() =>
		!project.isHistoryBlocked &&
		historyStore.states.length &&
		historyStore.activeState !== historyStore.states[historyStore.states.length - 1]
);
const canGoBackward = computed(
	() => !project.isHistoryBlocked && historyStore.states.length && historyStore.activeState !== historyStore.states[0]
);

const backward = () => {
	if (!canGoBackward.value) {
		return;
	}
	Bugsnag.leaveBreadcrumb(`Undo changes`);
	GAnalytics.track('click', 'Button', 'Undo', null);
	historyStore.rollback();

	store.editPanel = null;
};

const forward = () => {
	if (!canGoForward.value) {
		return;
	}
	Bugsnag.leaveBreadcrumb(`Redo changes`);
	GAnalytics.track('click', 'Button', 'Redo', null);
	historyStore.forward();
};

const { isOffline } = useOfflineMode();

const commandKey = computed(() => (isMac.value ? '⌘' : 'Ctrl'));
</script>

<template>
	<div class="flex lg:gap-2 lg:px-2" data-testid="history" :class="{ 'pointer-events-auto': isOffline }">
		<button
			class="group relative flex h-10 w-10 items-center justify-center"
			:class="!canGoBackward ? `pointer-events-none opacity-10` : 'opacity-50 hover:opacity-100'"
			@click="backward"
		>
			<SvgIcon name="back" class="h-5 w-5 text-white" />
			<span
				class="pointer-events-none absolute top-full left-1/2 z-10 mt-1.5 flex -translate-x-1/2 items-center gap-1 whitespace-nowrap rounded bg-gray-800 pt-1 pb-0.5 pl-2 pr-1 text-xs text-gray-100 opacity-0 transition-all duration-300 group-hover:mt-0 group-hover:opacity-100"
			>
				{{ trans('Undo') }}
				<div class="flex gap-0.5">
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">{{ commandKey }}</span>
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">Z</span>
				</div>
			</span>
		</button>
		<button
			class="group relative flex h-10 w-10 items-center justify-center"
			:class="!canGoForward ? `pointer-events-none opacity-10` : 'opacity-50 hover:opacity-100'"
			@click="forward"
		>
			<SvgIcon name="next" class="h-5 w-5 text-white" />
			<span
				class="pointer-events-none absolute top-full left-1/2 z-10 mt-1.5 flex -translate-x-1/2 items-center gap-1 whitespace-nowrap rounded bg-gray-800 pt-1 pb-0.5 pl-2 pr-1 text-xs text-gray-100 opacity-0 transition-all duration-300 group-hover:mt-0 group-hover:opacity-100"
			>
				{{ trans('Redo') }}
				<div v-if="isMac" class="flex gap-0.5">
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">⌘</span>
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">⇧</span>
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">Z</span>
				</div>
				<div v-if="!isMac" class="flex gap-0.5">
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">Ctrl</span>
					<span class="flex h-6 items-center justify-center rounded bg-black px-2">Y</span>
				</div>
			</span>
		</button>
	</div>
</template>
