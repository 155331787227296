import { sortByIndex } from '@tldraw/indices';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import Element from '@/elements/element/classes/Element';
import { Video } from '@/elements/medias/video/classes/Video';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import { Color } from '@/Types/colorsTypes';
import { SerializedClass } from '@/Types/types';

const DEFAULT_MILLISECONDS_DURATION_WITHOUT_VIDEO = 5000;

class Page {
	id: string;
	sourceId: string | null;
	name: string;
	background: Color;
	backgroundImageId: string | null;
	preview: string | null;
	elements: Map<string, Element>;

	protected constructor(
		name: string,
		background: Color,
		elements: Map<string, Element>,
		sourceId: string | null,
		backgroundImageId: string | null,
		preview: string | null
	) {
		this.id = uuidv4();
		this.name = name;
		this.background = background;
		this.elements = elements;
		this.sourceId = sourceId;
		this.backgroundImageId = backgroundImageId;
		this.preview = preview;
	}

	static defaults() {
		return {
			name: 'New page',
			background: SolidColor.white(),
			elements: new Map(),
			sourceId: '',
			backgroundImageId: '',
			preview: null,
		};
	}

	static create(config?: Partial<Page>): Page {
		const defaults = Page.defaults();

		const page = new Page(
			config?.name || defaults.name,
			config?.background || defaults.background,
			config?.elements || defaults.elements,
			config?.sourceId || defaults.sourceId,
			config?.backgroundImageId || defaults.backgroundImageId,
			config?.preview || defaults.preview
		);

		page.id = config?.id || page.id;

		return page;
	}

	static unserialize(data: SerializedClass<Page>): Page {
		const defaults = Page.defaults();
		const { name, background, elements, sourceId, backgroundImageId, preview } = data;

		let fixedBg: Color = defaults.background;

		if (background) {
			fixedBg = 'stops' in background ? GradientColor.unserialize(background) : SolidColor.unserialize(background);
		}

		/**
		 * Los elementos desde el json van a poder venir de 3 formas:
		 *
		 * - Array de elementos: [element]
		 * - Objeto clave valor desde el array (bug librería) : {0: element}
		 * - Objeto clave valor a partir del map: {id: element}
		 *
		 * Como no sabemos en que formato va a venir, nos quedamos unicamente con los valores y formamos el map nuevo
		 */
		let fixedElements: Map<string, Element> | null = null;
		if (elements) {
			fixedElements = Array.isArray(elements)
				? new Map(
						elements.map((el) => {
							const element = TemplateLoader.unserializeElement(el);
							return [element.id, element];
						})
				  )
				: new Map(Array.from(Object.values(elements)).map((el) => [el.id, TemplateLoader.unserializeElement(el)]));
		}

		const page = new Page(
			name || defaults.name,
			fixedBg,
			fixedElements || defaults.elements,
			sourceId || defaults.sourceId,
			backgroundImageId || defaults.backgroundImageId,
			preview || defaults.preview
		);

		page.id = data.id as string;

		return page;
	}

	public static createDefault(): Page {
		const defaults = Page.defaults();

		return new Page(
			defaults.name,
			defaults.background,
			defaults.elements,
			defaults.sourceId,
			defaults.backgroundImageId,
			defaults.preview
		);
	}

	get contentSize(): {
		width: number;
		height: number;
		x: number;
		y: number;
	} {
		// Calculamos las coordenadas finales de los elementos
		const endCoords = this.elementsAsArray()
			.filter((el) => {
				const isBackground = el.locked && el.id === this.backgroundImageId;
				return !isBackground;
			})
			.map((element) => {
				const startX = element.position.x;
				const startY = element.position.y;
				const endX = element.position.x + element.size.width;
				const endY = element.position.y + element.size.height;

				return {
					startX,
					startY,
					endX,
					endY,
				};
			});

		const diffX = endCoords.reduce((acc, coord) => {
			return Math.min(acc, coord.startX);
		}, Infinity);

		const diffY = endCoords.reduce((acc, coord) => {
			return Math.min(acc, coord.startY);
		}, Infinity);

		const diffWidth = endCoords.reduce((acc, coord) => {
			return Math.max(acc, coord.endX);
		}, 0);

		const diffHeight = endCoords.reduce((acc, coord) => {
			return Math.max(acc, coord.endY);
		}, 0);

		return {
			height: diffHeight - diffY,
			width: diffWidth - diffX,
			x: diffX < 0 ? 0 : diffX,
			y: diffY < 0 ? 0 : diffY,
		};
	}

	/**
	 * Retorna la primera aparicion
	 */
	domNode(): HTMLElement | null {
		return document.querySelector(`#canvas-${this.id}`);
	}

	/**
	 * Retorna el nodo en las preview (CanvasNavigation)
	 */
	domPreviewNode(): HTMLElement | null {
		return document.querySelector(`#canvas-${this.id}-preview-container`);
	}

	updateBackgroundColor(newColor: Color) {
		const cloneNewColor = cloneDeep(newColor);
		cloneNewColor.id = this.background.id;
		this.background = cloneNewColor;
	}

	replace(page: Page) {
		this.id = page.id;
		this.name = page.name;
		this.background = page.background;
		this.elements = page.elements;
		this.sourceId = page.sourceId;
		this.backgroundImageId = page.backgroundImageId;
		this.preview = page.preview || this.preview;
	}

	containsVideo() {
		return this.elementsAsArray().some((el) => el instanceof Video && el.url !== '/video/not-found.mp4');
	}

	duration() {
		if (!this.containsVideo()) {
			return DEFAULT_MILLISECONDS_DURATION_WITHOUT_VIDEO;
		}

		const videos = this.elementsAsArray().filter<Video>((el): el is Video => el instanceof Video);

		return Math.max(...videos.map((v) => v.croppedDuration()));
	}

	scaleBy(factor: number) {
		this.elements.forEach((el) => el.scaleBy(factor));
	}

	elementsAsArray(): Element[] {
		return Array.from(this.elements.values()).sort(sortByIndex);
	}
}

export default Page;
