import { useEventListener } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { ImageOutput } from '@/elements/medias/images/image/composables/useImageRendering';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { useSelection } from '@/interactions/composables/useSelection';
import { Size } from '@/Types/types';

const imageCache = new Map<string, ImageOutput>();

export const useForegroundRendering = (image: Ref<ForegroundImage>, domImage: Ref<HTMLImageElement>) => {
	const store = useMainStore();
	const { selection, selectionId } = useSelection();

	const { isMobile } = useDeviceInfo();
	const loaded = ref(false);
	const url = ref<string | null>(null);
	const resized = ref<string | null>(null);

	// The max size of the image that can be shown at the page. If the image is bigger, it will be resized.
	const targetSize = computed(() => {
		return {
			width: isMobile.value ? 1250 : 1500,
			height: isMobile.value ? 1250 : 1500,
		};
	});

	// Starts the loading of the image
	const load = () => {
		// If something went wrong while loading the image, we replace it with a broken one
		// Maybe we should return a broken status instead and do not change the url.
		useEventListener(domImage, 'error', () => {
			if (!image.value) return;

			url.value = `/svg/image-not-found.svg`;
			loaded.value = true;
			resized.value = null;

			if (selection.value && selection.value[0] === image.value) {
				selectionId.value = [];
			}
		});

		useEventListener(domImage, 'load', () => {
			loaded.value = true;
		});
	};

	// Computes the real size of the image (visual area)
	const imageSize = computed(() => ({
		width: image.value.crop.size.width || image.value.size.width,
		height: image.value.crop.size.height || image.value.size.height,
	}));

	// Computes the real size of the image on screen
	const screenWidth = computed(() => {
		return Math.round(imageSize.value.width * store.scale) * window.devicePixelRatio;
	});

	// Gets the image to be rendered based on the url and the target size, and caches the result
	const getImageToBeRendered = async (url: string, targetSize: Size): Promise<ImageOutput> => {
		if (imageCache.has(url)) {
			return imageCache.get(url) as ImageOutput;
		}

		const { url: blobUrl, type } = await ImageTools.getImageAsBlobUrl(url);
		const img = await ImageTools.loadImg(blobUrl);
		const size = ImageTools.sizeInDom(img);

		if (size.width * size.height < 700 * 700) {
			const output = {
				resized: url,
				original: url,
				originalSize: size,
			};

			imageCache.set(url, output);
			return output;
		}

		const newUrl = await ImageTools.resizeDomImage(img, targetSize, type);
		URL.revokeObjectURL(blobUrl);

		const output = {
			resized: newUrl,
			original: url,
			originalSize: size,
		};

		imageCache.set(url, output);
		return output;
	};

	return {
		loaded,
		imageSize,
		load,
		imageCache,
	};
};
