<script setup lang="ts">
import { computed, onMounted, ref, toRef } from 'vue';

import { useTextColors } from '@/color/composables/useTextColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import TextCurvature from '@/elements/texts/curved/components/menus/TextCurvature.vue';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import TextBorderMenu from '@/elements/texts/text/components/menus/TextBorderMenu.vue';
import TextShadowMenu from '@/elements/texts/text/components/menus/TextShadowMenu.vue';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels, TextEffects } from '@/Types/types';

const store = useMainStore();
const props = defineProps<{ element: Text; showFull?: boolean }>();
const { textEditing } = useTextEditing();
const element = toRef(props, 'element');
const scale = toRef(store, 'scale');
const curveBtn = ref();
const showTextOutlinePanel = computed(() => currentEffect.value === TextEffects.Outline);
const showTextShadowPanel = computed(() => currentEffect.value === TextEffects.Shadow);
const showTextCurvePanel = computed(() => currentEffect.value === TextEffects.Curve);
const { closePanel } = usePanelManagement();

const { isCircleText } = useCircleTypeInfo(element, scale);
const { hasGradient } = useTextColors(element);
const { isMobile } = useDeviceInfo();

const { trans } = useI18n();
const {
	textEffectHandler,
	resetEffect,
	currentEffect,
	hasEcho,
	hasGlitch,
	hasNeon,
	hasOutline,
	hasMultiShadow,
	hasShadow,
	hasSticker,
} = useTextEffects(element);

/*
 Cuando estamos editando un texto el textEditing no va a refrescar el contenido ya que por defecto cuando tocamos el panel
 queremos mantener la selección.
 En estos casos no es así, por lo que quitamos el atributo data-keep-text-selection temporalmente para que actualice el content
 */
const refreshTextContentWhenEditing = () => {
	if (!textEditing.value) {
		return;
	}
	const divWithKeePSelection = curveBtn.value.closest('[data-keep-text-selection]');

	divWithKeePSelection?.removeAttribute('data-keep-text-selection');
	const editableDiv = document.querySelector<HTMLDivElement>(
		`#editable-${element.value ? element.value.id : undefined}`
	);
	if (editableDiv) {
		editableDiv.dispatchEvent(new Event('blur'));
	}
	divWithKeePSelection?.setAttribute('data-keep-text-selection', '');
};

const onClickEffect = (effect: TextEffects) => {
	refreshTextContentWhenEditing();
	textEffectHandler(effect);
};

const onClickMoreEffects = () => {
	if (store.editPanel === EditPanels.TextEffects) {
		store.editPanel = isMobile.value ? EditPanels.Text : null;
		return;
	}

	store.editPanel = EditPanels.TextEffects;
};

// Cuando se abre el panel de efectos, si no hay ningún efecto activo, se activa el primero que esté disponible excepto cuando tenga una sombra compleja
const showFirstOpenedPanel = () => {
	if (hasMultiShadow.value) return;
	if (hasOutline.value && currentEffect.value === TextEffects.None) {
		currentEffect.value = TextEffects.Outline;
	} else if (hasShadow.value && currentEffect.value === TextEffects.None) {
		currentEffect.value = TextEffects.Shadow;
	} else if (isCircleText.value && currentEffect.value === TextEffects.None) {
		currentEffect.value = TextEffects.Curve;
	} else {
		currentEffect.value = TextEffects.None;
	}
};

onMounted(() => {
	showFirstOpenedPanel();
});
</script>

<template>
	<div>
		<div class="mb-6 hidden lg:block" :class="{ 'pt-4': isMobile }">
			<div class="mb-3 flex items-center justify-between">
				<h4 class="text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-700">
					{{ showFull ? 'Editable' : 'Effects' }}
				</h4>

				<button
					v-if="!showFull"
					class="text-sm font-semibold text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500"
					@click="onClickMoreEffects"
				>
					{{ trans('More effects') }}
				</button>
			</div>

			<div class="relative z-0 grid grid-cols-4 gap-3">
				<button
					data-testid="active-outline"
					class="group relative text-gray-100 hover:text-white"
					@click="onClickEffect(TextEffects.Outline)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'text-white mockup:text-fp-gray-700': hasOutline,
							'border-2 border-blue-500 text-white mockup:text-blue-500 slidesgo:border-purple-400': hasOutline,
						}"
					>
						<SvgIcon name="outline" class="h-7 w-7" />
					</div>
					<button
						v-if="hasOutline"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Outline)"
					>
						<div tooltip="Remove border" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">{{ trans('Outline') }}</label>
				</button>

				<button
					data-testid="active-text-shadow"
					class="group relative text-gray-100 hover:text-white"
					@click="onClickEffect(TextEffects.Shadow)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'text-white mockup:text-blue-500': hasShadow && !hasMultiShadow,
							'border-2 border-blue-500 text-white slidesgo:border-purple-400': hasShadow && !hasMultiShadow,
						}"
					>
						<SvgIcon name="text-shadow" class="h-7 w-7" />
					</div>
					<button
						v-if="hasShadow && !hasMultiShadow"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Shadow)"
					>
						<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">{{ trans('Shadow') }}</label>
				</button>

				<button
					ref="curveBtn"
					:disabled="hasGradient"
					data-testid="active-curved-text"
					class="group relative text-gray-100 hover:text-white"
					:class="hasGradient ? 'pointer-events-none opacity-50' : ''"
					@click="onClickEffect(TextEffects.Curve)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'text-white mockup:text-blue-500': isCircleText,
							'border-2 border-blue-500 text-white slidesgo:border-purple-400': isCircleText,
						}"
					>
						<SvgIcon name="curved" class="h-7 w-7" />
					</div>
					<button
						v-if="isCircleText"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Curve)"
					>
						<div tooltip="Remove curve" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">{{ trans('Curved') }}</label>
				</button>
			</div>

			<TextBorderMenu v-if="showTextOutlinePanel" :element="element" />

			<TextShadowMenu v-if="showTextShadowPanel" :element="element" />

			<TextCurvature v-if="showTextCurvePanel" :element="element" />
		</div>

		<div v-if="showFull" class="z-10 -mb-12 rounded-xl bg-gray-700 px-4 pb-2 pt-4 lg:mb-0 lg:bg-transparent lg:p-0">
			<div class="mb-3 hidden items-center justify-between lg:flex">
				<h4 class="text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-700">
					{{ trans('Presets') }}
				</h4>
			</div>
			<div class="bg flex justify-between lg:hidden">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Effects') }}</h2>
				<button
					data-testid="close-panel"
					class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
					@click="closePanel(element)"
				>
					<SvgIcon name="cross" class="h-3 w-3" />
				</button>
			</div>

			<div class="relative z-0 flex grid-cols-4 gap-3 overflow-auto pt-1 lg:-mx-4 lg:grid lg:overflow-hidden lg:px-4">
				<button
					data-testid="active-text-neon"
					class="group relative w-16 text-gray-100 hover:text-white lg:w-auto"
					@click="onClickEffect(TextEffects.Neon)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'border-2 border-blue-500 slidesgo:border-purple-400': hasNeon,
						}"
					>
						<SvgIcon name="neon" class="h-10 w-10" />
					</div>
					<button
						v-if="hasNeon"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Neon)"
					>
						<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">Neon</label>
				</button>

				<button
					data-testid="active-text-glitch"
					class="group relative w-16 text-gray-100 hover:text-white lg:w-auto"
					@click="onClickEffect(TextEffects.Glitch)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'border-2 border-blue-500 slidesgo:border-purple-400': hasGlitch,
						}"
					>
						<SvgIcon name="glitch" class="h-7 w-7" />
					</div>
					<button
						v-if="hasGlitch"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Glitch)"
					>
						<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">Glitch</label>
				</button>

				<button
					data-testid="active-text-echo"
					class="group relative w-16 text-gray-100 hover:text-white lg:w-auto"
					@click="onClickEffect(TextEffects.Echo)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'border-2 border-blue-500 slidesgo:border-purple-400': hasEcho,
						}"
					>
						<SvgIcon name="echo" class="h-7 w-7" />
					</div>
					<button
						v-if="hasEcho"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Echo)"
					>
						<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">Echo</label>
				</button>

				<button
					data-testid="active-text-sticker"
					class="group relative w-16 text-gray-100 hover:text-white lg:w-auto"
					@click="onClickEffect(TextEffects.Sticker)"
				>
					<div
						class="flex aspect-square w-full items-center justify-center rounded bg-gray-600 group-hover:bg-gray-600 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:group-hover:bg-fp-gray-150 mockup:group-hover:text-blue-500 lg:bg-gray-700"
						:class="{
							'border-2 border-blue-500 slidesgo:border-purple-400': hasSticker,
						}"
					>
						<SvgIcon name="sticker" class="-mb-1 -mr-1 h-8 w-8" />
					</div>
					<button
						v-if="hasSticker"
						class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-white slidesgo:bg-purple-400"
						@click.stop="resetEffect(TextEffects.Sticker)"
					>
						<div tooltip="Remove shadow" tooltip-position="top" class="flex h-full w-full items-center justify-center">
							<SvgIcon name="cross" class="h-2.5 w-2.5" />
						</div>
					</button>
					<label class="mt-1 text-center text-xs mockup:text-fp-gray-700">Sticker</label>
				</button>
			</div>
		</div>
	</div>
</template>
