<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import { ApiClient } from '@/api/utils';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { TemplateApiData } from '@/Types/apiClient';

const { trans } = useI18n();
const props = defineProps<{ selectedTemplate: TemplateApiData | null }>();

const emit = defineEmits(['close', 'renamed']);

const toast = useToast();
const project = useProjectStore();
const { APP_API_PATH } = useEnvSettings();

const renaming = ref(false);
const vectorName = ref(props.selectedTemplate?.name || 'Unnamed');

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Rename creation modal is closed`);
};

const renameTemplate = () => {
	const name = vectorName.value.trim();

	if (!props.selectedTemplate?.uuid || renaming.value || !name.length) return;

	renaming.value = true;

	const body = {
		name,
	};

	ApiClient.request(`${APP_API_PATH}user/creation/${props.selectedTemplate.uuid}/rename`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
		},
		// @ts-ignore
		body,
	})
		.then(() => {
			if (props.selectedTemplate?.uuid === project.id) {
				project.name = name;
			}

			toast.success(trans('Renamed template'));
			emit('renamed');
		})
		.catch(() => toast.error(trans('The template has not been renamed')))
		.finally(() => (renaming.value = false));
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative mx-auto my-auto max-w-lg rounded bg-white px-8 py-8 lg:w-[470px]">
			<button
				class="absolute right-0 top-0 mr-2 mt-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<div>
				<h1 class="mb-4 text-center text-2xl font-semibold text-gray-800">{{ trans('Rename template') }}</h1>
				<div class="mb-0">
					<input
						v-model="vectorName"
						minlength="1"
						maxlength="255"
						type="text"
						class="h-12 w-full rounded border border-gray-300 px-4 text-gray-800 placeholder:text-gray-600"
						placeholder="Template name..."
					/>
				</div>

				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
						:disabled="renaming"
						@click.stop="onClickClose"
					>
						{{ trans('Cancel') }}
					</button>

					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-8 text-lg font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
						:disabled="renaming"
						@click.stop="renameTemplate"
					>
						<SvgIcon v-if="renaming" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans('Rename') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
