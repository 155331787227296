<script lang="ts" setup>
import { until } from '@vueuse/core';
import { ref, toRef, watch } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import useNavigation from '@/editor/composables/useNavigation';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { Lang } from '@/Types/types';

const emit = defineEmits(['close']);

const close = () => emit('close');
const isSaving = ref(false);

const { trans, locale, routes } = useI18n();
const { confirmIntendedNavigation, navigateWithoutLosingChanges } = useNavigation();
const { requireAuth, isLogged } = useAuth();
const { performSyncWithStateInUse, saveProject } = useProjectStore();

const allChangesSaved = toRef(useProjectStore(), 'allChangesSaved');

const saveChanges = async () => {
	if (!isLogged.value) {
		requireAuth();
		await until(isLogged).toBeTruthy();
		isSaving.value = true;
		await saveProject?.();
		performSyncWithStateInUse?.();

		return;
	}
	isSaving.value = true;
	performSyncWithStateInUse?.();
};
watch(allChangesSaved, (newVal) => {
	// If all changes are saved and modal is saving changes, then redirect to home
	if (isSaving.value && newVal) {
		isSaving.value = false;
		const urlToRedirect = routes.home[locale.value as Lang];
		navigateWithoutLosingChanges(urlToRedirect);
	}
});
</script>

<template>
	<Modal open @close="close">
		<div class="relative mx-auto flex max-w-lg overflow-hidden rounded">
			<button class="absolute right-0 top-0 mr-2 mt-2 rounded-sm bg-white p-1 hover:bg-gray-100/25" @click="close()">
				<SvgIcon name="cross" class="h-5 w-5 fill-current text-gray-700 lg:h-6 lg:w-6" />
			</button>
			<div class="flex flex-col bg-white p-8 text-center">
				<h2 class="mb-4 mt-2 text-center text-xl font-semibold text-gray-800 lg:text-2xl">
					{{ trans('Are you sure you want to leave?') }}
				</h2>
				<p class="mb-0 text-center text-gray-600 lg:text-lg">
					{{ trans('You can save your template changes to continue working on it later') }}
				</p>
				<div class="mt-6 flex flex-col-reverse items-center justify-center gap-4 lg:flex-row">
					<button
						class="flex h-10 items-center justify-center rounded border-2 border-gray-200 px-6 font-semibold text-gray-700 hover:border-gray-300 lg:h-12 lg:px-8 lg:text-lg"
						@click="confirmIntendedNavigation"
					>
						{{ trans('Leave') }}
					</button>
					<button
						class="h-10 rounded border-2 border-blue-500 bg-blue-500 px-6 font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300 lg:h-12 lg:px-8 lg:text-lg"
						:class="{ 'cursor-not-allowed opacity-50': isSaving }"
						:disabled="isSaving"
						@click="saveChanges"
					>
						<span v-if="isSaving" class="flex items-center justify-center">
							<SvgIcon data-testid="spinner" name="spinner" class="mr-2 h-6 w-6 animate-spin" />
							<span>{{ trans('Saving changes...') }}</span>
						</span>
						<span v-else>{{ trans('Save changes & leave') }}</span>
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
