<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { useFeedback } from '@/interactions/composables/useFeedback';
import { useActivePage } from '@/page/composables/useActivePage';

// Props
const props = defineProps<{ element: Element }>();

const { deleteFeedback } = useFeedback();

const { removeElement } = useActivePage();

const remove = () => {
	deleteFeedback();
	removeElement(props.element);
	Bugsnag.leaveBreadcrumb(`Remove ${props.element.type}-${props.element.id}`);
};
</script>

<template>
	<button
		:id="`remove-text-toolbar`"
		data-testid="remove"
		class="flex h-8 w-8 shrink-0 items-center justify-center rounded bg-gray-600 text-sm text-white"
		@click="remove()"
	>
		<SvgIcon name="trash" :toolbar="true" class="h-3 w-3" />
	</button>
</template>
