<script lang="ts" setup>
import sysend from 'sysend';
import { onBeforeUnmount, onMounted, watch } from 'vue';

import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useShare } from '@/export/share/composables/useShare';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

const emits = defineEmits(['close', 'goBack', 'onAuth']);

const store = useMainStore();
const {
	pinterestAuthenticated,
	loading,
	pinterestBoards: hasBoards,
	initPinterest,
	checkPinterestAuthentication,
	pinterestLogout,
} = useShare();

onBeforeUnmount(() => {
	if (pinterestAuthenticated.value) emits('onAuth');

	sysend.off('oauth:pinterest');
});

onMounted(() => {
	checkPinterestAuthentication();
});

const onClickLogout = () => {
	pinterestLogout();
	emits('close');
};

const closeModal = () => {
	if (store.sharing) return;
	emits('close');
};

const goBack = () => {
	if (store.sharing) return;
	emits('goBack');
};

watch(hasBoards, (newValue) => {
	if (newValue !== 0 && pinterestAuthenticated.value) closeModal();
});
</script>
<template>
	<Modal
		v-if="!pinterestAuthenticated || (pinterestAuthenticated && (hasBoards === 0 || hasBoards === 2))"
		open
		content-classes="w-full max-w-lg"
		@close="closeModal"
	>
		<div class="relative max-h-full w-full overflow-auto rounded-lg bg-white py-4 px-4 lg:px-8 lg:pt-8 lg:pb-8">
			<button class="absolute top-2 left-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25" @click="goBack">
				<SvgIcon name="right-small" class="h-5 w-5 rotate-180 text-gray-700" />
			</button>
			<button
				class="absolute top-2 right-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>

			<div v-if="!pinterestAuthenticated" class="flex flex-col">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800">
					{{ trans('Publish directly on Pinterest!') }}
				</h3>
				<p class="px-6 text-center text-gray-600 lg:px-12">
					{{ trans('Connect your social media to share content in the blink of an eye!') }}
				</p>
				<div class="flex items-center justify-center gap-6 py-10">
					<SvgIcon name="iso" class="h-16 w-16 text-blue-500" />
					<SvgIcon name="left-right" class="h-10 w-10 text-gray-100" />
					<div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#E60023]">
						<SvgIcon name="pinterest" class="h-8 w-8 text-white" />
					</div>
				</div>
				<button
					class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
					@click="initPinterest"
				>
					<span v-if="!loading">{{ trans('Connect with Pinterest') }}</span>
					<SvgIcon v-if="loading" name="spinner" class="mx-auto h-7 w-7 animate-spin" />
				</button>
			</div>

			<div
				v-if="pinterestAuthenticated && hasBoards === 0"
				class="flex h-32 flex-col items-center justify-center gap-4"
			>
				<div class="flex h-12 w-12 items-center justify-center rounded-full bg-[#E60023]">
					<SvgIcon name="pinterest" class="h-6 w-6 text-white" />
				</div>
				<p class="text-lg font-semibold text-gray-800">{{ trans('Loading boards...') }}</p>
			</div>
			<div v-if="pinterestAuthenticated && hasBoards === 2" class="flex flex-col items-center justify-center gap-4">
				<div class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-[#E60023]">
					<SvgIcon name="pinterest" class="h-6 w-6 text-white" />
				</div>
				<div class="text-center">
					<p class="text-lg font-semibold text-gray-800">{{ trans(`Ups! you don't have any board`) }}</p>
					<a
						href="https://help.pinterest.com/article/create-a-board"
						target="_blank"
						class="font-semibold text-blue-500 hover:underline"
					>
						{{ trans('How to create a board?') }}
					</a>
				</div>
				<button
					class="rounded border-2 border-blue-500 py-3 px-6 font-semibold text-blue-500 hover:bg-blue-500 hover:text-white slidesgo:border-purple-400 slidesgo:bg-purple-400"
					@click="onClickLogout"
				>
					{{ trans('Logout') }}
				</button>
			</div>
		</div>
	</Modal>
</template>
