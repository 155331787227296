<script lang="ts" setup>
import { OnClickOutside } from '@vueuse/components';
import { useEventListener, useUrlSearchParams } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getBrandKits } from '@/api/UserApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import Modal from '@/common/components/Modal.vue';
import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import BrandKitsResources from '@/editor/brand-kits/BrandKitsResources.vue';
import BrandKitsTemplates from '@/editor/brand-kits/BrandKitsTemplates.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useI18n } from '@/i18n/useI18n';
import { Lang } from '@/Types/types';

const { trans, locale, routes } = useI18n();
const { isWebview, isMobile } = useDeviceInfo();
const { isLogged, requireAuth } = useAuth();
const store = useMainStore();
const { data, isFetching, error, execute } = getBrandKits({ immediate: false });
const { loadUserFonts } = useFonts();
const urlParams = useUrlSearchParams<{
	brandkit?: string;
	brandkitcollection?: string;
}>();

const selectBrandKitVisible = ref(false);
const brandKitSelected = ref<any>(null);
const brandKitTabSelected = ref<string | null>(null);
const brandKits = computed<any[]>(() => (data.value as any) || []);
const editUrl = computed(() => {
	const url = `${routes.brandkitRoutes[locale.value as Lang]}${brandKitSelected.value.slug}`;
	return url;
});

const loadBrandKits = async () => {
	// El usuario puede añadir fuentes desde fuera del editor
	// y abrir el panel de nuevo, así que refrescamos el listado
	// de fuentes para asegurarnos de que las tiene cargadas
	await loadUserFonts();

	if (isFetching.value || (data.value && data.value.length > 0)) return;

	await execute();

	if (data.value?.length > 0) {
		brandKitSelected.value = data.value[0];
	}
};

const selectBrandKit = (slug: string) => {
	brandKitTabSelected.value = null;

	brandKitSelected.value = brandKits.value.find((brandKit) => brandKit.slug === slug);
	selectBrandKitVisible.value = false;
};

watch(isLogged, () => {
	if (isLogged.value) loadBrandKits();
});

watch(brandKits, () => {
	// Actualizamos el listado inicial que nos llegó con el usuario
	if (store.user) {
		store.user.brandkits = brandKits.value;
	}
	// Para seleccionar el brand kit y la colección si viene desde la url,
	// esto pasa en el brand kit collection del backend
	if (!urlParams.brandkit || !urlParams.brandkitcollection) {
		return;
	}

	const brandKit = data.value.find((brandKit: any) => brandKit.slug === urlParams.brandkit);

	if (brandKit) {
		const brandKitCollection = brandKit.userCollections.find(
			(userCollection: any) => userCollection.slug === urlParams.brandkitcollection
		);

		if (brandKitCollection) {
			brandKitSelected.value = brandKit.slug;
			brandKitTabSelected.value = brandKitCollection.slug;
		}
	}
});

useEventListener(document, 'visibilitychange', () => {
	loadBrandKits();
});

const onClickSelectBrand = () => {
	selectBrandKitVisible.value = !selectBrandKitVisible.value;
};

const onClickBrandUserCollection = (slug: string) => {
	brandKitTabSelected.value = slug;

	GAnalytics.track('click', 'Brandkit', `change-brandtemplate-editor`);
};

onMounted(() => {
	loadBrandKits();
});
</script>

<template>
	<div class="flex h-full flex-col" data-test-brandkits>
		<PanelHeader :title="brandKits.length === 0 || !isLogged ? 'My brand' : brandKits[0].name">
			<template v-if="brandKits.length > 1 && brandKitSelected && isLogged" #title>
				<OnClickOutside @trigger="selectBrandKitVisible = false">
					<button
						class="flex items-center text-gray-100 hover:text-white"
						:tooltip="trans('Select brand kit')"
						tooltip-position="bottom"
						@click="onClickSelectBrand"
					>
						<span class="w-auto max-w-[180px] flex-1 truncate text-left text-xl font-semibold">{{
							brandKitSelected.name
						}}</span>
						<SvgIcon name="arrow" class="ml-4 h-4 w-4 fill-current" />
					</button>

					<Popper v-if="selectBrandKitVisible && !isMobile" placement="top-start">
						<div
							class="mb-1 w-36 overflow-y-scroll rounded bg-white px-2 pt-2 pb-2 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
						>
							<button
								v-for="brandKit in brandKits"
								:key="brandKit.slug"
								class="w-full rounded px-3 py-1.5 text-sm hover:bg-gray-100/25 hover:text-gray-800"
								:class="
									brandKit.slug === brandKitSelected.slug ? 'bg-gray-100/25 font-bold text-gray-800' : 'text-gray-500'
								"
								@click="() => selectBrandKit(brandKit.slug)"
							>
								<p class="items-baseline truncate whitespace-nowrap text-left">
									{{ brandKit.name }}
								</p>
							</button>
						</div>
					</Popper>
				</OnClickOutside>

				<Modal
					v-if="selectBrandKitVisible && isMobile"
					to="body"
					open
					content-classes="py-8"
					@close="selectBrandKitVisible = false"
				>
					<div
						data-testid="element-background-mdal"
						class="flex w-64 flex-col items-center rounded-lg bg-gray-800 p-2 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
					>
						<p class="flex px-3 pt-4 pb-1 text-center font-bold text-white">{{ trans('Select brand kit') }}</p>
						<button
							v-for="brandKit in brandKits"
							:key="brandKit.slug"
							class="w-full rounded px-3 py-1.5 text-sm hover:bg-gray-100/25 hover:text-gray-800"
							:class="
								brandKit.slug === brandKitSelected.slug ? 'bg-gray-100/25 font-bold text-gray-100' : 'text-gray-100'
							"
							@click="() => selectBrandKit(brandKit.slug)"
						>
							<p class="items-baseline truncate whitespace-nowrap text-left">
								{{ brandKit.name }}
							</p>
						</button>
					</div>
				</Modal>
			</template>

			<template v-if="brandKitSelected && !isWebview" #buttons>
				<div class="absolute top-5 right-14 text-gray-100 hover:text-white">
					<a
						:href="editUrl"
						target="_blank"
						:tooltip="trans('Edit')"
						tooltip-position="left"
						class="text-gray-100 hover:text-white"
					>
						<SvgIcon name="settings" class="h-4 w-4" />
					</a>
				</div>
			</template>
		</PanelHeader>

		<div
			v-if="isLogged"
			class="flex h-full max-h-[calc(100vh-112px)] w-full flex-col overflow-y-auto pb-4 pr-1 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
		>
			<div v-if="isFetching" class="absolute inset-0 flex w-full items-center justify-center text-gray-100">
				<SvgIcon name="spinner" class="h-10 w-10 animate-spin" />
			</div>

			<div v-if="!isFetching && error" class="text-center text-sm text-gray-100">
				{{ trans('Ups... The brand kits have not been loaded.') }}
				<button class="mt-2 rounded bg-gray-600 py-1 px-3 font-semibold" @click="loadBrandKits">
					{{ trans('Refresh') }}
				</button>
			</div>

			<div v-if="!isFetching && brandKits.length === 0">
				<p class="mb-3 text-center text-gray-100">{{ trans('You have not created any brand kits.') }}</p>

				<a
					v-if="!isWebview"
					href="/brand-kits?create=1"
					target="_blank"
					class="flex h-10 w-full cursor-pointer items-center justify-center rounded bg-blue-500 text-center text-sm font-semibold text-white focus:outline-none hover:bg-blue-600 slidesgo:bg-purple-400"
				>
					{{ trans('Create brand kit') }}
				</a>
			</div>

			<div v-if="!isFetching && brandKits.length > 0 && brandKitSelected">
				<div class="mb-3 flex gap-1.5 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-600">
					<button
						class="h-6 shrink-0 rounded-full px-3 text-xs font-semibold"
						:class="{
							'bg-blue-500 text-white slidesgo:bg-purple-400': brandKitTabSelected === null,
							'bg-gray-600 text-gray-100 hover:bg-gray-600 hover:text-white': brandKitTabSelected !== null,
						}"
						@click="brandKitTabSelected = null"
					>
						{{ trans('Brand Kit') }}
					</button>

					<button
						v-for="userCollection in brandKitSelected.userCollections"
						:key="userCollection.slug"
						data-testid="brandkit-collection"
						class="h-6 shrink-0 rounded-full px-3 text-xs font-semibold"
						:class="{
							'bg-blue-500 text-white slidesgo:bg-purple-400': brandKitTabSelected === userCollection.slug,
							'bg-gray-600 text-gray-100 hover:bg-gray-600 hover:text-white':
								brandKitTabSelected !== userCollection.slug,
						}"
						@click="onClickBrandUserCollection(userCollection.slug)"
					>
						{{ userCollection.name }}
					</button>
				</div>
			</div>

			<BrandKitsResources
				v-if="!isFetching && brandKits.length > 0 && brandKitSelected && brandKitTabSelected === null"
				:key="'kit-' + brandKitSelected.slug"
				:brand-kit-selected="brandKitSelected"
			/>

			<BrandKitsTemplates
				v-if="!isFetching && brandKits.length > 0 && brandKitSelected && brandKitTabSelected !== null"
				:key="'collection-' + brandKitTabSelected"
				:brand-kit-slug="brandKitSelected.slug"
				:collection-slug="brandKitTabSelected"
			/>
		</div>

		<div v-else>
			<p class="mb-3 text-center text-gray-100">{{ trans('To use the brand kits you must log in.') }}</p>

			<button
				class="itmes-center mx-auto flex h-10 cursor-pointer items-center justify-center rounded bg-blue-500 px-8 text-center font-semibold text-white focus:outline-none hover:bg-blue-600 slidesgo:bg-purple-400"
				@click="requireAuth()"
			>
				{{ trans('Login') }}
			</button>
		</div>
	</div>
</template>
