<script lang="ts" setup>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { onKeyStroke } from '@vueuse/core';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';

interface Props {
	open: boolean;
	contentClasses?: string;
	overlay?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	open: false,
	contentClasses: '',
	overlay: true,
});

const emits = defineEmits(['close']);

const closeModal = () => emits('close');

onKeyStroke('Escape', () => closeModal());

const { isMobile } = useDeviceInfo();

const { heightWithoutKeyboard } = useDeviceInfo();
</script>

<template>
	<TransitionRoot appear :show="props.open" as="template">
		<Dialog class="fixed inset-0 z-50 overflow-y-auto px-6" @close="closeModal">
			<div v-if="isMobile" tabindex="0"></div>
			<!-- hack para no usar autofocus en móvil -->
			<div class="flex items-center justify-center" :style="`height: ${heightWithoutKeyboard}px`">
				<TransitionChild
					v-if="overlay"
					as="template"
					enter="duration-300 ease-out"
					enter-from="opacity-0"
					enter-to="opacity-100"
					leave="duration-200 ease-in"
					leave-from="opacity-100"
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 bg-gray-900 p-4 opacity-75 transition-all duration-300" />
				</TransitionChild>
				<TransitionChild
					as="template"
					enter="duration-300 ease-out"
					enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100"
					leave="duration-200 ease-in"
					leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95"
				>
					<div :class="contentClasses" class="relative my-auto flex max-h-[80vh] flex-col justify-center" @click.stop>
						<slot />
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
