<script setup lang="ts">
import { computed, ref, toRef } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import FilterOverlay from '@/elements/medias/filter/components/FilterOverlay.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import { useImageRendering } from '@/elements/medias/images/image/composables/useImageRendering';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';

const store = useMainStore();

const props = defineProps<{
	element: Image;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
}>();
const element = toRef(props, 'element');

const { isRenderingContext } = useEditorMode();
const image = ref();

const { hasForeground } = useLayersImage(element);
const { isBackground } = useIsBackground(element);
const { imageUrl, loadWithRetry, imageSize, maskedImage, maskCache } = useImageRendering(
	element,
	image,
	props.forSmallPreview
);

// le pasamos un argumento con el núemro de intentos (en este caso 2) para que si falla la carga de la imagen, vuelva a intentar el load(). En caso de fallar de nuevo, se mostrará el placeholder
loadWithRetry(element.value.url, 2);

const browserLoaded = ref(false);

const imagePosition = computed(() => ({
	x: props.element.crop.position.x,
	y: props.element.crop.position.y,
}));

const hasSvgFilter = computed(() => element.value.filter && element.value.filter.toSvgFilter().length > 0);

const onDoubleClickInitCrop = async () => {
	// Evitamos que se pueda entrar en el modo crop si no tiene placeholder
	if (props.element.mask?.isPlaceholder) {
		return;
	}

	if (store.removingBackground.includes(element.value.id)) {
		return;
	}

	store.croppingId = element.value.id;
};

const opacity = computed(() => {
	return element.value.opacity === 0 && hasForeground.value ? 1 : element.value.opacity;
});

const maskId = computed(() => {
	if (!element.value.mask) return null;

	if (props.previewName) return `${element.value.id}-${element.value.mask.id}-preview-${props.previewName}`;

	return `${element.value.id}-${element.value.mask.id}`;
});

const filterId = computed(() => {
	return `filter-${element.value.id}-${(element.value.filter as Filter).name}`;
});
</script>

<template>
	<div
		:data-test-crop="store.croppingId"
		:class="previewName ? `original-${previewName}` : 'original'"
		:style="{
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
			clipPath: 'inset(0)',
			opacity,
		}"
	>
		<div
			:class="previewName ? `original-${previewName}` : 'original'"
			:style="({
				width: `${element.size.width}px`,
				height: `${element.size.height}px`,
				clipPath: `url(#${maskId})`,
				transform: `
        	scaleX(${element.flipHTML.x})
        	scaleY(${element.flipHTML.y})
				`
			} as any)"
		>
			<!-- Esta capa evita que firefox haga el clip path deformado -->
			<div
				:class="previewName ? `original-${previewName}` : 'original'"
				:style="{
					overflow: 'hidden',
					width: `${element.size.width}px`,
					height: `${element.size.height}px`,
					contain: 'strict',
				}"
			>
				<div
					:class="previewName ? `cropped-${previewName}` : 'cropped'"
					:style="{
						width: `${imageSize.width}px`,
						height: `${imageSize.height}px`,
						transform: `translate(${imagePosition.x}px,${imagePosition.y}px)`,
					}"
				>
					<div
						v-if="!browserLoaded && !maskCache.has(element.url) && !isRenderingContext && !props.previewName"
						class="absolute inset-0 bg-slate-100"
					>
						<div class="absolute inset-0 animate-pulse bg-gray-100"></div>
					</div>

					<!-- La versión en baja resolución mientras carga el resto -->
					<img
						v-if="
							props.element.preview &&
							!isRenderingContext &&
							!props.forSmallPreview &&
							!browserLoaded &&
							(!hasForeground || (element.opacity !== 0 && hasForeground)) &&
							!isRenderingContext
						"
						:src="props.element.preview"
						class="db-crop pointer-events-none absolute h-full w-full"
						data-preview
						alt=""
						:style="{
							filter: hasSvgFilter ? `url(#${filterId})` : '',
							transform: `
								scaleX(${element.flipHTML.x})
								scaleY(${element.flipHTML.y})
							`,
						}"
					/>

					<img
						v-if="maskedImage && element.backgroundMode === 'both'"
						data-background
						:src="maskedImage"
						:data-test-isbackground="isBackground"
						class="db-crop absolute h-full w-full"
						alt=""
						:style="{
							filter: hasSvgFilter ? `url(#${filterId})` : '',
							transform: `scaleX(${element.flipHTML.x}) scaleY(${element.flipHTML.y})`,
						}"
					/>

					<FilterOverlay v-if="element.filter?.overlay" :element="element" :overlay="element.filter.overlay" />

					<img
						v-if="imageUrl && !(element.opacity === 0 && hasForeground)"
						ref="image"
						data-final-image
						data-replaceable
						:alt="element.metadata?.ai?.search"
						:src="imageUrl"
						:data-test-isbackground="isBackground"
						class="db-crop absolute h-full w-full"
						:style="{
							filter: hasSvgFilter ? `url(#${filterId})` : '',
							transform: `
								scaleX(${element.flipHTML.x})
								scaleY(${element.flipHTML.y})
							`,
						}"
						@dragstart.prevent
						@dblclick="onDoubleClickInitCrop"
						@load="browserLoaded = true"
					/>

					<img
						v-if="element.opacity === 0 && hasForeground"
						ref="image"
						data-final-image
						data-replaceable
						:src="element.urlBackgroundRemoved || ''"
						:data-test-isbackground="isBackground"
						class="db-crop absolute h-full w-full"
						:style="{
							filter: hasSvgFilter ? `url(#${filterId})` : '',
							transform: `
								scaleX(${element.flipHTML.x})
								scaleY(${element.flipHTML.y})
							`,
						}"
						@dragstart.prevent
						@dblclick="onDoubleClickInitCrop"
						@load="browserLoaded = true"
					/>

					<FilterOverlay
						v-if="element.filter?.overlay && ['both', 'foreground'].includes(element.backgroundMode) === false"
						:element="element"
						:overlay="element.filter.overlay"
					/>

					<svg v-if="element.mask" width="0" height="0">
						<clipPath :id="maskId || ''" clipPathUnits="objectBoundingBox" v-html="element.mask.content"></clipPath>
					</svg>
					<svg v-if="hasSvgFilter" width="0" height="0">
						<defs>
							<filter :id="filterId" v-html="element.filter?.toSvgFilter()"></filter>
						</defs>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>
