<script lang="ts" setup>
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { TemplateCategory } from '@/Types/types';
const store = useMainStore();

const { isPhotoMode } = useEditorMode();
const { trans } = useI18n();

defineProps<{
	subcategories: TemplateCategory[];
}>();

const onClickSubcategory = (subcategory: TemplateCategory) => (store.activeTemplateCategory = subcategory);
</script>

<template>
	<div class="flex h-10 shrink-0 items-center overflow-x-auto text-gray-800 scrollbar-thin scrollbar-thumb-gray-600">
		<button
			v-for="subcategory in subcategories"
			:key="subcategory.name"
			class="mr-2 h-6 shrink-0 rounded-full border px-3 text-xs font-semibold"
			:class="{
				'border-gray-100 text-gray-600 hover:bg-gray-100/25 hover:text-gray-800': isPhotoMode,
				'border-gray-600 text-gray-100 hover:bg-gray-600 hover:text-white': !isPhotoMode,
			}"
			@click="onClickSubcategory(subcategory)"
		>
			{{ trans(subcategory.name) }}
		</button>
	</div>
</template>
