<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { computed, nextTick, ref, toRef, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';

// Stores
const store = useMainStore();

// Props
const props = defineProps<{ element: Element }>();

// Template refs
const toolbar = ref();

// Data
const element = toRef(props, 'element');
const popper = ref();

// Computeds
const croppingId = computed(() => store.croppingId);
const size = computed(() => element.value.size);
const position = computed(() => element.value.position);

// Watchers
watch([position, size], async () => {
	await nextTick();

	if (!popper.value) {
		initPopper();
		return;
	}

	popper.value.update();
});

watch(
	[croppingId, element],
	async () => {
		await nextTick();
		initPopper();
	},
	{ immediate: true }
);

// Methods
const initPopper = () => {
	const domNode = element.value.domNode();
	if (!domNode || !toolbar.value) return;

	popper.value = createPopper(domNode, toolbar.value, {
		placement: 'top-start',
		modifiers: [
			{ name: 'offset', options: { offset: [0, 5] } },
			{
				name: 'preventOverflow',
				options: {
					tether: false,
				},
			},
		],
	});
};
</script>

<template>
	<teleport to="#toolbarTarget">
		<div ref="toolbar" class="toolbar z-20 flex gap-2 pl-2">
			<div
				v-for="tag in element.tags"
				:key="tag"
				class="bold mr-1 w-auto rounded-tr-lg bg-yellow-500 px-2 text-xs text-white"
			>
				{{ tag }}
			</div>
		</div>
	</teleport>
</template>
