<script setup lang="ts">
import { onClickOutside, useElementBounding, useTitle } from '@vueuse/core';
import { computed, defineAsyncComponent, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import DebugPanel from '@/common/components/DebugPanel.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import useNavigation from '@/editor/composables/useNavigation';
import { usePikiGame } from '@/editor/composables/usePikiGame';
import { useResizeContainerEditor } from '@/editor/composables/useResizeContainerEditor';
import Piki from '@/editor/piki-game/Piki.vue';
import { useMainStore } from '@/editor/stores/store';
import CropTime from '@/elements/medias/crop-time/components/CropTime.vue';
import { useCopyStyles } from '@/elements/texts/text/composables/useCopyStyles';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import ContextMenu from '@/interactions/components/context-menu/ContextMenu.vue';
import TeleportTargets from '@/interactions/components/TeleportTargets.vue';
import TeleportToolbar from '@/interactions/components/TeleportToolbar.vue';
import { useEditorKeyboardEvents } from '@/interactions/composables/useEditorKeyboardEvents';
import { useEditorMouseEvents } from '@/interactions/composables/useEditorMouseEvents';
import { useInteractions } from '@/interactions/composables/useInteractions';
import BottomBar from '@/layout/components/BottomBar.vue';
import EditorModalContainer from '@/layout/components/EditorModalContainer.vue';
import EditPanel from '@/layout/components/EditPanel.vue';
import Navigation from '@/layout/components/Navigation.vue';
import TopBar from '@/layout/components/TopBar.vue';
import TopbarPanelMobile from '@/layout/components/TopbarPanelMobile.vue';
import { useTemplateLoader } from '@/loader/composables/useTemplateLoader';
import CanvasContainer from '@/page/components/CanvasContainer.vue';
import { useMovePageOnEditPanelOverFocused } from '@/page/composables/useMovePageOnEditPanelOverFocused';
import { useProjectStore } from '@/project/stores/project';

const props = withDefaults(
	defineProps<{
		showCanvas: boolean;
		title?: string;
	}>(),
	{
		showCanvas: true,
		title: 'Wepik',
	}
);

const scrollArea = ref<HTMLElement | undefined>();
const wrapper = ref<HTMLElement | undefined>();
const { showPiki, showPikiGame } = usePikiGame();
const PikiGame = defineAsyncComponent(() => import('../../editor/piki-game/PikiGameWrapper.vue'));

const store = useMainStore();
const project = useProjectStore();
const { textEditing } = useTextEditing();

const { checkLoginStatus, requireAuth } = useAuth();
const { isMobile, isOldApp } = useDeviceInfo();
const {
	detect,
	isIllustratorContext,
	isDebugMode,
	isDebugPanel,
	isEditorMode,
	isCypressContext,
	isWepikContext,
	isEmbeddedContext,
} = useEditorMode();
const { width, left, update } = useElementBounding(scrollArea);
const { watchFonts, preload } = useFonts();
const { isCropping, setupInteraction } = useInteractions();
const { guardAgainstUnsavedChanges } = useNavigation();
const { isFirstLoad } = useTemplateLoader();

detect();
guardAgainstUnsavedChanges();
checkLoginStatus().then(() => {
	const isWepikEditor = isEditorMode.value && isWepikContext.value && !isCypressContext.value;

	if (isWepikEditor || isEmbeddedContext.value) requireAuth();
});
watchFonts();
preload();
useTitle(computed(() => `${project.name || project.category || 'Editor'} | ${props.title}`));
useEditorKeyboardEvents();
useEditorMouseEvents();
useMovePageOnEditPanelOverFocused();
useResizeContainerEditor(wrapper);
const { copiedStyles, clearCopiedStyles } = useCopyStyles();

onBeforeMount(() => {
	const modeStyleClasses = [];
	if (isDebugMode.value) modeStyleClasses.push('debug-mode');
	if (props.title === 'Slidesgo') modeStyleClasses.push('slidesgo-mode');
	modeStyleClasses.forEach((cls) => document.body.classList.add(cls));
});

onMounted(() => {
	// Si es la app antigua no hacemos nada
	if (isOldApp.value) return;

	setupInteraction();
});

// useElementBounding solo vigila cambios en el elemento y en window,
// pero no se entera de cuando se abre el panel y este desplaza al elemento,
// actualizamos nosotros los datos
watch(
	() => store.activePanel,
	async () => {
		await nextTick();
		update();
	}
);

onClickOutside(wrapper, () => {
	if (Object.keys(copiedStyles.value).length) {
		clearCopiedStyles();
	}
});
</script>

<template>
	<div class="select-none bg-gray-800 selection:bg-blue-600">
		<div class="flex flex-col lg:h-screen">
			<slot name="topbar">
				<TopBar />
			</slot>

			<EditorModalContainer />

			<slot name="before-main"></slot>

			<div id="container-editor-base" class="flex w-full flex-col-reverse lg:flex-1 lg:flex-row">
				<Navigation class="text-white" />
				<ContextMenu v-if="!isMobile && !isIllustratorContext && !isCropping && !textEditing" />

				<div ref="wrapper" class="relative flex flex-1 flex-col">
					<slot name="crop-time">
						<CropTime v-if="store.croppingTime" :width="width" :left="left" />
					</slot>
					<div
						id="scroll-area"
						ref="scrollArea"
						class="relative z-0 flex flex-1 flex-col bg-gray-900 text-gray-900 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 lg:z-10"
						:class="{ 'transform transition duration-150 ease-in-out': isMobile }"
					>
						<TeleportTargets />
						<CanvasContainer v-if="showCanvas && !isFirstLoad && scrollArea" :scroll-area="scrollArea" />
						<div v-else class="absolute z-20 flex h-full w-full flex-col items-center justify-center text-gray-100">
							<SvgIcon name="spinner" class="h-10 w-10 animate-spin" />
						</div>
						<!-- Para evitar que los botones de abajo solapen la última página -->
					</div>
					<BottomBar v-if="!isFirstLoad" :width="width" :left="left" />
				</div>
				<TopbarPanelMobile v-if="isMobile" />
				<EditPanel v-if="store.activePage" />
				<TeleportToolbar />

				<slot name="after-main"></slot>
			</div>
		</div>
	</div>

	<DebugPanel v-if="isDebugPanel" />

	<Teleport v-if="showPiki" to="body">
		<PikiGame
			v-if="showPikiGame"
			class="absolute left-0 top-0 z-50 h-full w-full"
			:show-exit-button="true"
			@exit="() => (showPikiGame = false)"
		/>

		<button
			class="absolute bottom-28 left-0 right-0 z-40 mx-auto h-28 w-28 lg:bottom-8 lg:left-[0.124rem] lg:mx-0 lg:h-20 lg:w-20"
			@click="showPikiGame = true"
		>
			<Piki />
		</button>
	</Teleport>
</template>
