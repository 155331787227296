import { computed, Ref } from 'vue';

import Element from '@/elements/element/classes/Element';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';

export const usePageElement = (element: Ref<Element>) => {
	const project = useProjectStore();

	const page = computed(() => project.pages.find((page) => page.elements.has(element.value.id)) as Page);

	return { page };
};
