import Bugsnag from '@bugsnag/js';
import { getIndexBetween } from '@tldraw/indices';
import { promiseTimeout } from '@vueuse/core';
import { computed, nextTick, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import ApiClient from '@/api/utils/ApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { FilesTools } from '@/common/utils/FilesTools';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useSyncForeground } from '@/elements/medias/images/foreground/composables/useSyncForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { ImageApi } from '@/Types/apiClient';
import { EditPanels } from '@/Types/types';

export const useLayersImage = (image: Ref<Image | ForegroundImage>) => {
	const store = useMainStore();
	const { setSelection, clearSelection } = useSelection();

	const project = useProjectStore();
	const { isPhotoMode, isCypressContext } = useEditorMode();
	const { page } = usePageElement(image);
	const { requireAuth } = useAuth();
	const { trans } = useI18n();
	const removingBackground = computed(() => store.removingBackground.includes(image.value?.id));
	const { APP_API_PATH } = useEnvSettings();

	const foreground = computed(() => {
		const foreground =
			page.value &&
			page.value.elementsAsArray().find((e) => e instanceof ForegroundImage && e.image === image.value?.id);
		if (!foreground) {
			return undefined;
		}
		return foreground as ForegroundImage;
	});

	const hasForeground = computed(() => !!foreground.value);

	const separateLayers = async (selectForeground = true): Promise<void> => {
		if (hasForeground.value || image.value instanceof ForegroundImage) {
			return;
		}

		if (!image.value.urlBackgroundRemoved && !isPhotoMode.value && !isCypressContext.value && requireAuth()) {
			return;
		}

		if (!image.value.urlBackgroundRemoved) {
			await removeBackground();
		}

		const foregroundImage = await ForegroundImage.fromImage(image.value);
		const { syncPositionWithBackground } = useSyncForeground(ref(foregroundImage) as Ref<ForegroundImage>);
		image.value.backgroundMode = 'background';

		const elements = page.value.elementsAsArray();
		const imageIndex = elements.findIndex((e) => e.id === image.value.id);
		foregroundImage.index = getIndexBetween(elements[imageIndex].index, elements[imageIndex + 1]?.index);
		page.value.elements.set(foregroundImage.id, foregroundImage);
		// En caso de que la imagen tenga rotación el rotate se va aplicar desde distintos centro ya que las imagenes no tiene el mismo size
		// tenemos que corregir la posición
		if (image.value.rotation) {
			clearSelection();
			await promiseTimeout(0);
			await syncPositionWithBackground();
		}

		if (selectForeground) {
			await setSelection(foregroundImage);
			await nextTick();
			store.editPanel = EditPanels.ImagePresets;
		}
	};

	const unifyLayers = () => {
		if (!image.value.opacity) {
			image.value.opacity = 1;
		}

		if (image.value instanceof ForegroundImage) {
			const original = page.value.elements.get(image.value.image);
			if (!original || !(original instanceof Image)) return;
			original.backgroundMode = 'original';
			page.value.elements.delete(image.value.id);
			return;
		}

		image.value.backgroundMode = 'original';

		page.value.elements.delete(foreground.value?.id || '');
	};

	const removeBackground = async () => {
		if (image.value.urlBackgroundRemoved) {
			store.lastBackgroundRemoved = image.value.id;
			return -1;
		}

		project.pauseAutoSave?.();

		store.removingBackground.push(image.value.id);
		Bugsnag.leaveBreadcrumb('Loading remove background');
		let response = null;

		if (!isPhotoMode.value && !isCypressContext.value) {
			const body = {
				url: image.value.url,
				userUpload: image.value.metadata.uploadId,
			};

			response = await ApiClient.request(
				`${APP_API_PATH}remove-background`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
					},
					// @ts-ignore
					body,
				},
				true
			);
		} else {
			const body = {
				image: await FilesTools.urlToBase64(image.value.url),
			};

			response = await ApiClient.request(
				`${APP_API_PATH}remove-background-anonymous`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
					},
					// @ts-ignore
					body,
				},
				true
			);
		}

		if (response.status >= 400) {
			project.resumeAutoSave?.();
			store.removingBackground = store.removingBackground.filter((id) => id !== image.value.id);

			if (response.status !== 429) {
				GAnalytics.track('click', 'Button', 'remove-background-error', null);
				throw new Error(
					trans('There was an error while separating the background from the foreground, try with another image')
				);
			}

			throw new Error(trans(`You've reached background removals for today`));
		}

		if (!isPhotoMode.value && !isCypressContext.value) {
			const apiImage = (await response.json()) as ImageApi;

			if (!apiImage.backgroundRemoved) {
				GAnalytics.track('click', 'Button', 'remove-background-error', null);
				project.resumeAutoSave?.();
				throw new Error(
					trans('There was an error while separating the background from the foreground, try with another image')
				);
			}

			image.value.url = apiImage.url || apiImage.preview;
			image.value.preview = apiImage.preview;
			image.value.metadata = apiImage.metadata || {};
			image.value.urlBackgroundRemoved = apiImage.backgroundRemoved;
		} else {
			const url = window.URL.createObjectURL(await response.blob());
			image.value.urlBackgroundRemoved = url;
		}

		const remainingRemovals = parseInt(response.headers.get('X-RateLimit-Remaining')) || 'Unlimited';

		GAnalytics.track('click', 'Button', isPhotoMode ? 'remove-bg-photoeditor' : 'remove-background', null);

		store.removingBackground = store.removingBackground.filter((id) => id !== image.value.id);
		store.lastBackgroundRemoved = image.value.id;
		project.resumeAutoSave?.();

		return remainingRemovals;
	};

	return {
		removingBackground,
		unifyLayers,
		separateLayers,
		removeBackground,
		foreground,
		hasForeground,
	};
};
