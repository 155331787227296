<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, ref, toRef, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getFlaticon } from '@/api/DataApiClient';
import AutocompleteInput from '@/common/components/AutocompleteInput.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import ColorFilterFlaticon from '@/elements/shapes/flaticon/components/menus/ColorFilterFlaticon.vue';
import StyleFilterFlaticon from '@/elements/shapes/flaticon/components/menus/StyleFilterFlaticon.vue';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useProjectStore } from '@/project/stores/project';
import { InsertableApiType } from '@/Types/types';

// Props
const props = defineProps<{ query: string }>();
// Data
const query = toRef(props, 'query');

const { trans } = useI18n();
const project = useProjectStore();

const emit = defineEmits<{ (e: 'elementAdded'): void }>();

const { addInsertableFlaticon } = useAddInsertableElement();

const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

const noResultsWithQuery = ref(false);
const search = ref('');
const color = ref<string | null>(null);
const style = ref<string | undefined>();

const autocompleteSource = computed(() => `autocomplete/flaticon?search=${search.value}&type=icon`);

const querySearch = computed(() => {
	if (search.value) return search.value;
	else if (!noResultsWithQuery.value && project.flaticonSearch) return project.flaticonSearch;
	else return '';
});

const source = computed(
	() =>
		`flaticon/search?q=${querySearch.value}&limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}${
			color.value ? `&color=${color.value}` : ''
		}${style.value ? `&style=${style.value}` : ''}`
);

const url = ref('');

watch(
	source,
	(newVal) => {
		if (!newVal) return;
		url.value = newVal;
	},
	{ immediate: true }
);

const { data: flaticons, isFetching } = getFlaticon(url, { refetch: true });

const onClickIcon = async (url: string) => {
	await addInsertableFlaticon(url);
	emit('elementAdded');
};

watch(flaticons, (newVal) => {
	if (!newVal) return;
	if (newVal.data.length === 0) {
		GAnalytics.trackGA4('search_editor', {
			category: 'icon',
			search_term: `${search.value}`,
			zero_results: `true`,
		});
		if (!search.value) noResultsWithQuery.value = true;
		return;
	}

	GAnalytics.trackGA4('search_editor', {
		category: 'icon',
		search_term: `${search.value}`,
		zero_results: `false`,
	});
});

const updateSearchValue = (searchValue: string) => {
	search.value = searchValue;

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in Flaticon panel: ${searchValue}`);
};

const loadMore = () => {
	const shouldLoadMore = !isFetching.value && !!flaticons.value?.links?.next;
	if (!shouldLoadMore) return;
	url.value = flaticons.value!.links.next;
};

// TODO Si cargas mas de una página y cambias de filtros se quedan nodos fuera de la reactividad de vue (wtf), refrescamos
// el componente para solucionar esto
</script>

<template>
	<AutocompleteInput
		:placeholder="trans(query ? query : 'Search Icon...')"
		:query="search"
		:autocomplete-source="autocompleteSource"
		@change="updateSearchValue"
	/>

	<div class="flex gap-3 py-1">
		<div class="relative">
			<ColorFilterFlaticon v-model="color" :style="style" />
		</div>
		<div class="relative">
			<StyleFilterFlaticon v-model="style" :color="color" />
		</div>
	</div>

	<InfiniteLoading
		:container-classes="'grid grid-cols-4 gap-x-10 gap-y-6 pt-2'"
		:data="flaticons?.data || []"
		:is-fetching="isFetching"
		@load="loadMore"
	>
		<template #item="{ item }">
			<!-- Elements -->
			<InsertableElement
				v-if="'svg' in item"
				v-show="item.svg"
				data-testid="flaticon-icon"
				:data="item"
				:draggable="true"
				:type="InsertableApiType.Flaticon"
				class="flex cursor-pointer rounded transition-opacity duration-300 hover:opacity-50"
				@click.stop="onClickIcon(item.svg)"
			>
				<img
					:src="item.preview"
					:data-svg="item.svg"
					:alt="item.name"
					draggable="true"
					loading="lazy"
					class="aspect-ratio w-full object-contain"
					:class="{
						'opacity-50 invert': !item.multicolor && (('isCustom' in item && !item?.isCustom) || !('isCustom' in item)),
					}"
				/>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
