<script lang="ts" setup>
import { computed } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { Size } from '@/Types/types';

interface Props {
	opacity?: number;
	size: Size;
}
withDefaults(defineProps<Props>(), { opacity: 50 });

const lineSize = computed(() => (isFirefox ? { w: 'w-0.5', h: 'h-0.5' } : { w: 'w-px', h: 'h-px' }));

const { isFirefox } = useDeviceInfo();
</script>

<template>
	<div
		:style="{
			transform: `translate(${size.width / 3}px, 0px)`,
		}"
		:class="`pointer-events-none absolute top-0 h-full ${lineSize.w} bg-white opacity-${opacity}`"
	></div>
	<div
		:style="{
			transform: `translate(${(size.width / 3) * 2}px, 0px)`,
		}"
		:class="`pointer-events-none absolute top-0 h-full ${lineSize.w} bg-white opacity-${opacity}`"
	></div>
	<div
		:style="{
			transform: `translate(0px, ${size.height / 3}px)`,
		}"
		:class="`pointer-events-none absolute top-0 ${lineSize.h} w-full bg-white opacity-${opacity}`"
	></div>
	<div
		:style="{
			transform: `translate(0px, ${(size.height / 3) * 2}px)`,
		}"
		:class="`pointer-events-none absolute top-0 ${lineSize.h} w-full bg-white opacity-${opacity}`"
	></div>
</template>
