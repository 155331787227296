import { createSharedComposable, onStartTyping, useEventListener } from '@vueuse/core';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useToast } from '@/common/composables/useToast';
import { useEditorClipboard } from '@/editor/composables/useEditorClipboard';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';

export const useEditorKeyboardEvents = createSharedComposable(() => {
	const store = useMainStore();
	const { selection, clearSelection } = useSelection();

	const project = useProjectStore();
	const history = useHistoryStore();
	const { text, copy, copySelection, pasteSelection, pasteNewText, pasteSVG, hasContent } = useEditorClipboard();
	const { listen } = useOrderedKeyboardListener();
	const { decreaseZoom, increaseZoom } = useZoom();
	const { getClipboardFilesAndUploadImages } = useUserImageProvider();
	const { trans } = useI18n();
	const toast = useToast();
	const { selectAll } = useInteractions();
	const { textEditing } = useTextEditing();

	const isTextInputEvent = (e: KeyboardEvent | ClipboardEvent) => {
		const target = <HTMLInputElement>e.target;
		return (
			(target.tagName === 'INPUT' && target?.type !== 'range') ||
			target?.tagName === 'TEXTAREA' ||
			target?.isContentEditable
		);
	};

	listen('ArrowLeft', (e) => {
		if (isTextInputEvent(e) || textEditing.value) {
			return;
		}
		e.preventDefault();
		const newIndex = Math.max(0, project.pages.findIndex((page) => page.id === store.activePageId) - 1);

		const page = project.pages[newIndex] as Page;
		if (!page) return;
		store.setActivePage(page);
		page.domPreviewNode()?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	});
	listen('ArrowRight', (e) => {
		if (isTextInputEvent(e) || textEditing.value) {
			return;
		}

		e.preventDefault();

		const newIndex = Math.min(
			project.pages.length - 1,
			project.pages.findIndex((page) => page.id === store.activePageId) + 1
		);
		const page = project.pages[newIndex] as Page;
		if (!page) return;
		store.setActivePage(page);
		page.domPreviewNode()?.scrollIntoView({ behavior: 'smooth', block: 'end' });
	});

	listen('Escape', (e) => {
		if (isTextInputEvent(e)) {
			return;
		}

		clearSelection();
		store.illustratorSelection.clear();
	});

	listen(['z', 'Z'], (e) => {
		// @ts-ignore
		if (isTextInputEvent(e)) {
			return;
		}

		// Mac: command + shift + Z -> rehace cambios
		if (e.metaKey && e.shiftKey) {
			e.preventDefault();
			history.forward();
			return;
		}

		if (e.ctrlKey || e.metaKey) {
			e.preventDefault();
			history.rollback();
		}
	});

	listen(['y', 'Y'], (e) => {
		// @ts-ignore
		if (isTextInputEvent(e)) {
			return;
		}
		if (e.ctrlKey) {
			e.preventDefault();
			history.forward();
		}
	});

	listen(['a', 'A'], (e: KeyboardEvent) => {
		if (isTextInputEvent(e) || textEditing.value) {
			return;
		}

		if (e.metaKey || e.ctrlKey) {
			e.preventDefault();
			selectAll();
		}
	});

	// Ctrl+c
	useEventListener(document.body, 'copy', async (e: ClipboardEvent) => {
		// Si es un INPUT, permitimos que copie y salimos
		if (isTextInputEvent(e)) {
			return;
		}

		e.preventDefault();

		// Si no existe ninguna selección, salimos
		if (!selection.value.length) {
			return;
		}

		GAnalytics.track('click', 'Template', 'copy', null);
		await copySelection();

		if (hasContent.value) {
			toast.info(trans(`${selection.value.length > 1 ? 'Items' : 'Item'} added to clipboard`));
		}
	});

	// Ctrl+v
	useEventListener(document.body, 'paste', async (e: ClipboardEvent) => {
		// Si es un INPUT, analizamos el texto para evitar insertar elementos y actualizamos el input, luego salimos

		// @ts-ignore
		if (e.target?.tagName === 'INPUT' || e.target?.tagName === 'TEXTAREA') {
			const text = e.clipboardData?.getData('Text');

			// Prevenimos el comportamiento en caso de que empiece por 'wepik|'
			if (text && text.startsWith('wepik|')) {
				e.preventDefault();
			}

			return;
		}

		e.preventDefault();

		// Obtenemos el data del clipboard
		let data = await e.clipboardData?.getData('text/plain');

		if (data) {
			data = EventTools.purifyClipboard(data);
		}

		const isNewPaste = text.value !== data;
		const isWepikData = data?.startsWith('wepik|') || false;
		const isSVG = data?.startsWith('<svg') || false;

		// Si hay archivos para pegar priorizamos sobre elementos copiados
		if (e.clipboardData?.files.length) {
			await getClipboardFilesAndUploadImages(e);
			return;
		}

		// Si tenemos algún elemento copiado y no estamos editando texto
		if (hasContent.value && !isNewPaste) {
			GAnalytics.track('click', 'Template', 'paste', null);
			pasteSelection();
			return;
		}

		// Si estamos pegando desde otro archivo de wepik,
		// comprobamos que el data sea más largo y empiece por wekip|
		if (data?.length && isNewPaste && isWepikData) {
			await copy(data);
			pasteSelection();
			return;
		}

		// Para poder pegar SVGs desde el clipboard, por ejemplo desde figma
		if (data?.length && isNewPaste && isSVG) {
			pasteSVG(data);
			return;
		}

		// Si no tenemos un elemento seleccionado o es un texto pero no está en modo edición y estamos intentando pegar un texto
		if (
			(!selection.value.length || !(selection.value[0] instanceof Text && textEditing.value)) &&
			data?.length &&
			isNewPaste
		) {
			pasteNewText(data, selection.value[0] instanceof Text && !textEditing.value);
			return;
		}
	});

	// Control +|- to zoom in|out
	listen(['-'], (e) => {
		if (isTextInputEvent(e)) {
			return;
		}

		if (!(e.metaKey || e.ctrlKey)) {
			return;
		}

		e.preventDefault();
		decreaseZoom(undefined, 10);
	});

	listen(['+'], (e) => {
		if (isTextInputEvent(e)) {
			return;
		}

		if (!(e.metaKey || e.ctrlKey)) {
			return;
		}

		e.preventDefault();
		increaseZoom(undefined, 10);
	});

	// When your trying to write on a non writable element
	onStartTyping(() => {
		const currentSelection = (selection.value.length ? selection.value[0] : false) as Text | false;
		// if the selected element is a text and we arent editing, we will start to edit this
		if (!textEditing.value && currentSelection && currentSelection.type === 'text') {
			if (currentSelection.locked) return;

			textEditing.value = currentSelection;
		}
	});
});
