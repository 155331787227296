<script setup lang="ts">
import { createPopper, Placement } from '@popperjs/core';
import { computed, nextTick, onBeforeUnmount, Ref, ref, watch } from 'vue';

import { SolidColor } from '@/color/classes/SolidColor';
import ColorSelector from '@/color/components/ColorSelector.vue';
import ColorSelectorMobile from '@/color/components/ColorSelectorMobile.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import OnClickOutsideWithPortals from '@/interactions/components/OnClickOutsideWithPortals';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';
// Props
const props = defineProps<{
	color: Color | Color[];
	preventChange?: boolean;
	hideAlpha?: boolean;
	hideGradient?: boolean;
	parent?: string;
	placement?: Placement;
}>();

// Emits
const emit = defineEmits<{
	(e: 'select', color: Color | Color[] | null): void;
	(e: 'change', color: Color): void;
	(e: 'track'): void;
}>();

// Template refs
const button = ref();
const picker = ref() as Ref<HTMLElement>;
// Constants
const rgbaGridTemplateColumns = `repeat(${props.hideAlpha ? '5' : '6'}, minmax(0, 1fr))`;
const rgbaAlphaChannelDisplay = props.hideAlpha ? 'none' : 'initial';
const { isMobile } = useDeviceInfo();
const { refreshMobilePanel } = usePanelManagement();
// Data
const isOpen = ref(false);
const currentColor = ref();
const store = useMainStore();
const project = useProjectStore();

// Computeds
const colors = computed(() => {
	const colorList = Array.isArray(props.color) ? props.color : [props.color];

	if (colorList.length === 1) return colorList;

	return colorList.flatMap((color) => {
		if (color instanceof SolidColor) return color;
		return color.stops.map((stop) => SolidColor.fromObject({ r: stop.r, g: stop.g, b: stop.b, a: stop.a }));
	});
});

const colorPreview = computed(() => {
	if (colors.value.length === 1) {
		return colors.value[0].toCssString();
	}

	const steps = parseFloat((100 / colors.value.length).toFixed(0));
	let gradient = `${colors.value[0]} ${steps}%`;

	for (let i = 1; i < colors.value.length; i++) {
		const start = steps * i;
		const end = steps * (i + 1);
		const color = colors.value[i];
		gradient += `,${color} ${start}%, ${color} ${end}%`;
	}

	return `conic-gradient(${gradient})`;
});

// Using composables
const { listen } = useOrderedKeyboardListener();

listen('Escape', () => {
	if (!isOpen.value) return true;
	isOpen.value = false;
});

// Methods
const getPickerPlacement = (reference: HTMLElement) => {
	const placement = props.placement || 'bottom';

	if (isMobile.value || !reference.classList.contains('toolbar')) return placement;

	const editPanel = document.getElementById('edit-panel');
	const leftPanel = document.querySelector('[data-test-active-panel]');

	const multicanvas = project.pages.length > 1;
	const leftPanelRBox = leftPanel?.getBoundingClientRect();
	const toolbarRBox = reference.getBoundingClientRect();
	const toolbarPosition = reference.getBoundingClientRect().top;
	const horizontalSpace = Math.abs(toolbarRBox.right - window.innerWidth) > 200;

	if (multicanvas && !horizontalSpace) return Math.abs(toolbarPosition) > 300 ? 'top' : 'bottom';

	if (!editPanel) return placement;
	const editPanelRBox = editPanel.getBoundingClientRect();
	const popperOnRight = Math.abs(toolbarRBox.right - editPanelRBox.left) > 200;

	if (!popperOnRight) {
		if (editPanelRBox && !leftPanelRBox) return 'left-start';
		if (editPanelRBox && leftPanelRBox) {
			return Math.abs(leftPanelRBox.right - toolbarRBox.left) <= 200 ? 'top' : 'left-start';
		}
	}
	return placement;
};

const toggleColorPicker = async (ev: MouseEvent) => {
	// Evitamos que se abra el color picker al hacer click con el botón derecho
	if (ev.button !== 0) return;

	// Si se hace click en un color picker que está en un toolbar, cerramos el panel
	if (ev.target instanceof HTMLElement && ev.target.closest('.toolbar') && !isMobile.value) {
		store.editPanel = null;

		await nextTick();
	}

	if (props.preventChange) {
		emit('select', props.color);
		return;
	}

	isOpen.value = !isOpen.value;

	if (isOpen.value && !isMobile.value) {
		requestAnimationFrame(() => {
			const reference = props.parent ? button.value.closest(props.parent) : button.value;
			const activatePreventOferFlow = project.pages.length === 1;
			let placement = getPickerPlacement(reference);

			createPopper(reference, picker.value, {
				placement,
				modifiers: [
					{
						name: 'preventOverflow',
						enabled: activatePreventOferFlow,
						options: {
							boundary: document.querySelector(`#interactive-canvas-${(store.activePage as Page).id}`),
						},
					},
					{
						name: 'flip',
						options: {
							fallbackPlacements: ['right', 'left', 'top'],
						},
					},
				],
			});
		});
	}

	if (isOpen.value) emit('select', props.color);
	if (!isOpen.value) emit('select', null);
};

watch(isOpen, () => checkForColorPickers());

const checkForColorPickers = async () => {
	await nextTick();

	requestAnimationFrame(async () => {
		document.documentElement.classList.toggle('color-picking', !!document.querySelector('[data-color-picker]'));
	});
};

onBeforeUnmount(async () => {
	checkForColorPickers();
	isOpen.value = false;
});

const closePicker = () => {
	if (!isOpen.value) {
		return;
	}
	// en mobile refrescamos el panel si hubiese alguno activo para que el composable useMovePageOnEditPanelOverFocused se entere del cambio
	refreshMobilePanel();
	isOpen.value = false;
	emit('select', null);
};

const changeColor = (color: Color) => {
	color.validForVariant = false;
	currentColor.value = color;
	emit('track');
	emit('change', color);
};
</script>

<template>
	<OnClickOutsideWithPortals @trigger="closePicker">
		<div
			class="color-picker relative h-full w-full"
			:class="{
				'last:mr-0 lg:-mr-2': colors.length > 5,
			}"
		>
			<div class="bg-transparent-pattern absolute inset-0 rounded-full" style="clip-path: circle(49% at 50% 50%)"></div>
			<button
				ref="button"
				data-testid="open-colorpicker"
				:class="`absolute left-0 top-0 h-full w-full rounded-full cpb-${Array.isArray(color) ? '' : color.id}`"
				:style="{
					background: colorPreview,
				}"
				@click="toggleColorPicker"
			>
				<span class="pointer-events-none absolute inset-0 rounded-full border border-white/20"></span>
			</button>

			<div
				v-if="isOpen && !isMobile"
				ref="picker"
				data-color-picker
				class="z-30 w-full rounded-tl-lg rounded-tr-lg bg-gray-800/95 p-2 text-left backdrop-blur mockup:!m-1 mockup:bg-white lg:!mx-2 lg:w-48 lg:rounded-md lg:bg-opacity-100 lg:shadow-custom"
			>
				<ColorSelector
					:color="(color as Color)"
					:hide-alpha="hideAlpha"
					:hide-gradient="hideGradient"
					@change="changeColor"
				/>
			</div>
		</div>
		<teleport to="#toolbar-bottom-portal, #navigation-portal">
			<ColorSelectorMobile
				v-if="isOpen && isMobile"
				:hide-gradient="hideGradient"
				:color="color"
				@change="changeColor"
				@close="closePicker"
			/>
		</teleport>
	</OnClickOutsideWithPortals>
</template>

<style lang="scss">
.vc-sketch .vc-sketch-field {
	grid-template-columns: v-bind(rgbaGridTemplateColumns);

	.vc-sketch-field--single:last-of-type {
		display: v-bind(rgbaAlphaChannelDisplay);
	}
}
</style>
