<script setup lang="ts">
import { onClickOutside, useDebounceFn, useThrottleFn } from '@vueuse/core';
import { computed, InputHTMLAttributes, ref, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useI18n } from '@/i18n/useI18n';

// Using composables
const {
	ZOOM_OPTIONS,
	scaleAsPercentage,
	fitZoomScale,
	setZoomDirectly,
	foundZoomIndex,
	findClosestZoomIndex,
	increaseZoom,
	decreaseZoom,
	onZoomFinish,
} = useZoom();
const { trans } = useI18n();

// Data
const showZoomOptions = ref<boolean>(false);
const selector = ref();

const toggleZoomOptions = () => {
	showZoomOptions.value = !showZoomOptions.value;
};

const closeZoomOptions = () => {
	showZoomOptions.value = false;
};

onClickOutside(selector, closeZoomOptions);

const zoomInputHandler = (e: Event) => {
	const value = (e.target as InputHTMLAttributes).value;

	requestAnimationFrame(() => {
		const index = findClosestZoomIndex(value);
		setZoomDirectly(ZOOM_OPTIONS[index]);
	});
};

const percentageRangeInput = computed(() => (foundZoomIndex.value / ZOOM_OPTIONS.length) * 100);

const DROPDOWN_OPTIONS = [10, 25, 50, 75, 100, 125, 200, 300, 500];
</script>

<template>
	<div
		class="navigator z-10 hidden h-10 items-center justify-center gap-4 rounded bg-gray-800/90 px-4 text-sm font-bold text-gray-100 backdrop-blur lg:flex"
		data-testid="zoom-controls"
	>
		<div
			class="relative flex h-10 w-10 flex-1 cursor-pointer items-center justify-center text-center hover:text-white"
			@click="toggleZoomOptions"
		>
			<div data-testid="current-zoom" class="w-9">{{ scaleAsPercentage.toFixed(0) }}%</div>
			<div
				v-show="showZoomOptions"
				ref="selector"
				data-testid="show-options"
				class="absolute bottom-0 mb-12 flex flex-col overflow-hidden rounded bg-gray-800"
				style="left: 50%; transform: translateX(-50%)"
			>
				<div
					class="h-48 overflow-auto text-gray-800 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
				>
					<button
						v-for="option in DROPDOWN_OPTIONS"
						:key="`${option}-zoom`"
						:class="{
							'text-gray-300 hover:text-gray-100': true,
							'bg-gray-700 text-gray-100': false,
						}"
						class="w-full py-2 pl-4 pr-1 text-sm"
						@click="setZoomDirectly(option)"
					>
						{{ option }}%
					</button>
				</div>
				<button class="rounded-sm bg-gray-700 py-2 text-sm text-gray-100 hover:text-gray-100" @click="fitZoomScale()">
					{{ trans('Fit') }}
				</button>
			</div>
		</div>
		<div class="relative flex w-full items-center">
			<input
				:value="scaleAsPercentage"
				type="range"
				class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-600 focus:outline-none"
				:min="ZOOM_OPTIONS[0]"
				:max="ZOOM_OPTIONS[ZOOM_OPTIONS.length - 1]"
				step="0.001"
				@input="zoomInputHandler"
			/>
			<span
				class="pointer-events-none absolute left-0 top-0 h-[2px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
				:style="{ width: `${percentageRangeInput}%` }"
			/>
		</div>
	</div>
</template>
