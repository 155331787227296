import { until, useDebounceFn, useWindowSize } from '@vueuse/core';
import { onMounted, Ref, ref, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import Element from '@/elements/element/classes/Element';
import { useInteractions } from '@/interactions/composables/useInteractions';

export const useSmartSelection = (element: Ref<Element>) => {
	const smartSelection = ref(false);

	const { width: screenWidth, height: screenHeight } = useWindowSize();
	const { action } = useInteractions();
	const { isAdminMode } = useEditorMode();

	const updateSmartSelection = useDebounceFn(async () => {
		await until(action).toBe('idle');
		const { width: elWidth, height: elHeight } = element.value.domNode()?.getBoundingClientRect() || {
			width: 0,
			height: 0,
		};

		smartSelection.value = elWidth > screenWidth.value / 8 || elHeight > screenHeight.value / 8;
	}, 100);

	if (element.value.type === 'shape' && !isAdminMode.value) {
		watch([screenWidth, screenHeight, () => element.value.size.width], () => updateSmartSelection());
		onMounted(() => updateSmartSelection());
	}

	return {
		smartSelection,
	};
};
