<script lang="ts" setup>
import { useEyeDropper } from '@vueuse/core';
import { onMounted, ref } from 'vue';

import { SolidColor } from '@/color/classes/SolidColor';
import SvgIcon from '@/common/components/SvgIcon.vue';
import EventTools from '@/interactions/classes/EventTools';

const { isSupported, open, sRGBHex } = useEyeDropper();

const emit = defineEmits<{ (e: 'change', color: SolidColor): void }>();

const showColorPickerButton = ref(false);
onMounted(() => (showColorPickerButton.value = isSupported.value));

const pickingColor = ref(false);
const root = ref();

const startPicking = async () => {
	pickingColor.value = true;

	const pickerRoot = root.value?.closest('.color-picker');
	pickerRoot?.classList.add('opacity-0');

	await EventTools.sleep(200);

	open()
		.then(() => {
			const color = SolidColor.fromString(sRGBHex.value);
			color.a = 1;
			emit('change', color);
		})
		.finally(() => {
			pickingColor.value = false;
			pickerRoot?.classList.remove('opacity-0');
		})
		.catch(() => 0);
};
</script>

<template>
	<button
		v-if="showColorPickerButton"
		ref="root"
		class="order-first hidden h-6 w-6 items-center justify-center rounded-sm text-gray-100 hover:bg-gray-700 hover:text-white lg:flex"
		@click="startPicking"
	>
		<SvgIcon name="dropper" class="h-3 w-3 fill-current" />
	</button>
</template>
