<script lang="ts" setup>
import { until, useUrlSearchParams } from '@vueuse/core';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getImages } from '@/api/DataApiClient';
import WithAuth from '@/auth/components/WithAuth.vue';
import { useAuth } from '@/auth/composables/useAuth';
import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import AiImageModal from '@/elements/medias/images/image/components/ai/AiImageModal.vue';
import AiImageVisor from '@/elements/medias/images/image/components/ai/AiImageVisor.vue';
import TextToImageExamples from '@/elements/medias/images/image/components/panels/add/text-to-image/TextToImageExamples.vue';
import TextToImageUserImages from '@/elements/medias/images/image/components/panels/add/text-to-image/TextToImageUserImages.vue';
import { useAiImage } from '@/elements/medias/images/image/composables/useAiImage';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { ImageApi } from '@/Types/apiClient';
import { AiViewerMode } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		modal?: boolean;
	}>(),
	{
		modal: false,
	}
);

const emit = defineEmits<{
	(e: 'select', value: ImageApi): void;
}>();

const showExamples = ref<boolean>(false);
const isViewing = ref<AiViewerMode | null>(null);
const isUnloggedViewing = computed(() => isViewing.value && !isLogged.value);

const { trans } = useI18n();
const params = useUrlSearchParams<{
	panel?: string;
}>();

const editing = ref<boolean | ImageApi>(false);

//Data
const sourceUser = ref('user/ai-images');
const { data: imagesUser, isFetching: isFetchingUser } = getImages(sourceUser, { refetch: true });
const aiImages = computed(() => {
	return imagesUser.value?.data
		.map((el) => {
			return el.ai_images?.map((img) => {
				return {
					preview: img.media,
					url: img.media,
					id: img.uuid,
					type: 'image',
					origin: 'ai',
					metadata: {
						prompt: el.prompt,
					},
				};
			});
		})
		.flat();
});
const userHasIaImages = computed(() => aiImages.value?.length);

const store = useMainStore();
const { addInsertableImage } = useAddInsertableElement();
const { isLogged, onSuccessLogin } = useAuth();
const { prompt, selectedImage } = useAiImage();
const { runOnMobile } = useDeviceInfo();

onSuccessLogin(async () => {
	await until(isLogged).toBe(true);
	editing.value = params.panel === 'AI';
});

const loadMore = async () => {
	if (isFetchingUser.value || !imagesUser.value?.links?.next) return;
	sourceUser.value = imagesUser.value.links.next;
};

const onGenerateImage = () => {
	editing.value = true;
	GAnalytics.track('click', 'AI', 'try-it_button', null);
	GAnalytics.trackGA4('text_to_image_start', { action: 'try_it' });
};

const onInsertImage = async (image: ImageApi) => {
	if (isViewing.value) return;

	GAnalytics.track('add-image', 'AI', 'insert-image', null);
	GAnalytics.trackGA4('add_to_canvas_text_to_image');

	if (props.modal) {
		emit('select', image);
		return;
	}

	await addInsertableImage(image);

	runOnMobile(() => (store.activePanel = null));
};

const toggleExamples = async () => {
	GAnalytics.trackGA4('text_to_image_start', { action: 'find_inspiration' });
	GAnalytics.track('click', 'AI', 'find-inspiration');
	showExamples.value = !showExamples.value;
};

const onClickViewer = (image: ImageApi) => {
	isViewing.value = AiViewerMode.viewer;

	selectedImage.value = image;

	editing.value = image;
};

const onCloseModal = () => {
	editing.value = false;
	prompt.value = '';

	if (isViewing.value) isViewing.value = null;
};

const onCloseVisor = () => {
	editing.value = false;
	isViewing.value = null;
	selectedImage.value = undefined;
};
</script>

<template>
	<ReserveSideSize ref="el" class="text-gray-800 scrollbar-thumb-gray-600">
		<template v-if="!modal">
			<PanelHeader :show-back-button="showExamples" title="Text to image" @goBack="toggleExamples" />
		</template>
		<!-- Home page -->
		<template v-if="!showExamples">
			<!-- Has NOT AI user images -->
			<div v-if="!userHasIaImages && !isFetchingUser" class="mb-8">
				<div class="animation-ai relative mb-6 hidden aspect-square rounded-lg bg-gray-700 lg:block">
					<span
						class="bar absolute left-16 top-20 z-10 h-32 w-4 overflow-hidden rounded bg-blue-500 slidesgo:bg-purple-400"
					>
						<span class="block h-full w-2 bg-blue-400"></span>
					</span>
					<div class="absolute inset-0 flex items-center justify-center">
						<SvgIcon name="ai-image-1" class="h-24 w-24" />
					</div>
					<div class="image-2 absolute inset-0 flex items-center justify-center opacity-0">
						<SvgIcon name="ai-image-2" class="h-24 w-24" />
					</div>
				</div>
				<p class="mb-4 text-lg font-semibold text-white">
					{{ trans('What do you wish to see? Just type and let the magic happen') }}
				</p>
				<p class="text-gray-100">
					{{ trans('Ask our new feature, the AI image generator, and turn your ideas into images.') }}
				</p>
				<button
					class="mb-2 h-9 w-full rounded bg-white px-2 font-semibold text-gray-800 hover:bg-gray-50"
					@click="toggleExamples"
				>
					{{ trans('Find inspiration') }}
				</button>
				<button
					class="mb-4 h-9 w-full rounded bg-gradient-to-r from-blue-500 to-purple-400 px-2 font-semibold text-white hover:opacity-80"
					@click="onGenerateImage"
				>
					{{ trans('Try it') }}
				</button>
			</div>
			<!-- Has AI user images -->
			<TextToImageUserImages
				v-else
				:ai-images="aiImages"
				:modal="props.modal"
				:is-fetching-user="isFetchingUser"
				@insert="onInsertImage"
				@viewer="onClickViewer"
				@toggle="toggleExamples"
				@try="onGenerateImage"
				@load="loadMore"
			></TextToImageUserImages>
		</template>
		<!-- Example images page -->
		<KeepAlive>
			<TextToImageExamples
				v-if="showExamples"
				:modal="props.modal"
				@viewer="onClickViewer"
				@insert="onInsertImage"
			></TextToImageExamples>
		</KeepAlive>
		<!-- Modal -->
		<AiImageVisor
			v-if="editing && isUnloggedViewing"
			:hide-try-prompt="true"
			:viewer-mode="isViewing"
			@select="onInsertImage(value)"
			@close="onCloseVisor"
		/>
		<WithAuth v-else-if="editing" @close="editing = false">
			<AiImageModal
				open
				:image="editing"
				:viewer-mode="isViewing"
				@close="onCloseModal"
				@select="onInsertImage"
				@changeViewerMode="isViewing = null"
			/>
		</WithAuth>
	</ReserveSideSize>
</template>
<style lang="sass">
.animation-ai
	.bar
		animation: animation-ai linear 10s infinite
		animation-delay: 0.5s

		@keyframes animation-ai
			0%
				transform: translateX(0)

			10%
				transform: translateX(150px)

			40%
				transform: translateX(150px)

			50%
				transform: translateX(0)

	.image-2
		animation: ai-image linear 10s infinite
		animation-delay: 0.5s

		@keyframes ai-image
			0%
				opacity: 0

			10%
				opacity: 1

			40%
				opacity: 1

			50%
				opacity: 0
</style>
