<script lang="ts" setup>
// Vue
import { toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
import { useShapeColors } from '@/color/composables/useShapeColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import FlipMenu from '@/elements/element/components/menus/FlipMenu.vue';
import OpacitySlider from '@/elements/element/components/menus/OpacitySlider.vue';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformMenu from '@/elements/element/components/menus/TransformMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ element: Shape }>();
const { closePanel } = usePanelManagement();

const element = toRef(props, 'element');

const { colors, updateColor } = useShapeColors(element);
const { isGrouped } = useGroup(element);
const { trans } = useI18n();
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">{{ trans('Edit shape') }}</h2>
		<button class="text-gray-100 focus:outline-none hover:text-white" data-testid="close-panel" @click="closePanel()">
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<div>
			<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Edition') }}</h4>
			<div class="mb-6 w-1/2">
				<FlipMenu :inline-label="true" :element="element" />
			</div>
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="true" />

		<!-- Colors -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">
			{{ trans('Colors') }}
		</h4>

		<div class="mb-6 grid grid-cols-6 gap-2">
			<ColorPicker
				v-for="color in colors"
				:key="color.id"
				:color="(color as Color)"
				class="h-10 w-10"
				@change="(newColor) => updateColor(color, newColor)"
			/>
		</div>

		<OpacitySlider :element="element" :opacity="element.opacity" />
	</div>
</template>
