import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { nextTick, ref } from 'vue';

import { useNamedDebounce } from '@/common/composables/useNamedDebounce';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { HistoryState } from '@/history/classes/HistoryState';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';

export interface HistoryStore {
	states: HistoryState[];
	activeState: HistoryState | null;
	key: string;
	lastChangeFromNavigation: boolean;
}

export const useHistoryStore = defineStore('history', () => {
	const store = useMainStore();
	const project = useProjectStore();
	const { selectionId } = useSelection();

	const { textEditing } = useTextEditing();
	const { fitZoomScale } = useZoom();

	const states = ref<HistoryState[]>([]);
	const activeState = ref<HistoryState>();
	const key = ref('history-key');
	const lastChangeFromNavigation = ref(false);

	const forward = () => {
		if (project.isHistoryBlocked) return;
		if (activeState.value === states.value[states.value.length - 1]) return;

		useNamedDebounce('hforward', 100, async () => {
			const newState = states.value[(activeState.value as HistoryState).index + 1] as HistoryState;
			const artboardChange =
				activeState.value?.height !== newState.height ||
				activeState.value?.width !== newState.width ||
				activeState.value.unit !== newState.unit;

			await setState(newState);

			if (artboardChange) {
				fitZoomScale();
			}
		});
	};

	const rollback = () => {
		if (project.isHistoryBlocked) return;
		if (activeState.value?.index === 0) return;

		useNamedDebounce('wrollback', 100, async () => {
			const newState = states.value[(activeState.value as HistoryState).index - 1] as HistoryState;
			const artboardChange =
				activeState.value?.height !== newState.height ||
				activeState.value?.width !== newState.width ||
				activeState.value.unit !== newState.unit;

			await setState(newState);

			if (artboardChange) {
				fitZoomScale();
			}
		});
	};

	const setInitialState = (forceSync = false) => {
		// @ts-ignore
		const h = new HistoryState(project, Object.keys(states.value).length);
		activeState.value = h;
		states.value.push(h);

		project.initSync?.(forceSync);
	};

	const setState = async (state: HistoryState) => {
		// @ts-ignore
		window.moving = true;
		activeState.value = state;

		project.$patch(() => {
			project.size.width = state.width;
			project.size.height = state.height;
			project.unit = state.unit;
			project.pages = cloneDeep(state.pages);
			project.scale = state.scale;
		});

		store.$patch(() => {
			textEditing.value = store.croppingId = null;
			selectionId.value = [];
		});

		// Esperamos a que los watchers terminen de trabajar
		// ya que si no, crearan nuevos estados si se hace un cambio
		// (afecta al foreground image y a los textos curvos, debido al mutationObserver)
		await nextTick();
		await nextTick();

		if (window.moving) delete window.moving;

		key.value = uuidv4();
		lastChangeFromNavigation.value = true;
	};

	const removeUserImageFromStates = (url: string) => {
		states.value.forEach((state) => {
			state.pages.forEach((page) => {
				page.elements.forEach((el) => {
					if (el instanceof Image || el instanceof Video) {
						const urlToSearch = url.split('?')[0];

						if (el.url.includes(urlToSearch)) {
							el.toNotFound();
						}
					}
				});
			});
		});
	};

	return {
		activeState,
		key,
		lastChangeFromNavigation,
		states,
		forward,
		rollback,
		setInitialState,
		setState,
		removeUserImageFromStates,
	};
});
