<script lang="ts" setup>
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import ColorPicker from '@/color/components/ColorPicker.vue';
import { useLineColors } from '@/color/composables/useLineColors';
import NumberInput from '@/common/components/NumberInput.vue';
import Line from '@/elements/line/classes/Line';
import { useLine } from '@/elements/line/composables/useLine';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ element: Line }>();

const element = toRef(props, 'element');

const { updateStrokeWidth } = useLine(element);
const { updateColor } = useLineColors(element);
const { trans } = useI18n();

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div class="mb-3">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Stroke') }}</h4>
		<div class="flex gap-2">
			<ColorPicker class="h-10 w-10" :color="(element.mainColor as Color)" @change="updateColor" />

			<div class="flex flex-col" data-testid="input-line-element-height">
				<NumberInput
					class="h-10 w-20 text-sm"
					:min="1"
					:value="element.size.height"
					@track-step="trackStepEvent('line-width')"
					@track-typing="trackTypingEvent('line-width')"
					@update="updateStrokeWidth"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">{{ trans('Width') }}</label>
			</div>
		</div>
	</div>
</template>
