import Bugsnag from '@bugsnag/js';
import { until, useIntervalFn } from '@vueuse/core';
import { computed, nextTick, Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransform } from '@/elements/element/composables/useElementTransform';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { Text } from '@/elements/texts/text/classes/Text';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useInsertablePosition } from '@/interactions/composables/useInsertablePosition';
import { useMoveable } from '@/interactions/composables/useInteractiveElements';
import { useSelection } from '@/interactions/composables/useSelection';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import { useActivePage } from '@/page/composables/useActivePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { PredefinedTextApi } from '@/Types/apiClient';
import { Anchor, EditPanels, TextWithValue } from '@/Types/types';

const DEFAULT_SCREEN_SIZE = 500;

export const useAddInsertableText = (temporalRef: Ref<Text>, previewText: Ref<Text>) => {
	const store = useMainStore();
	const { selection, setSelection, clearSelection } = useSelection();

	const { resize, centerInVisibleZone } = useElementTransform(temporalRef);
	const { updateColor } = useTextStyles(temporalRef);
	const { addElement } = useActivePage();
	const { artboardSizeInPx } = useArtboard();

	const { runOnMobile } = useDeviceInfo();
	const { inUseFonts, fonts, getFontWeight } = useFonts();
	const { isCreatingElement, toggleMoveableOpacity } = useMoveable();
	const { generateContent, content, openAIWriter, textAI } = useAiWriter();

	const { group, setOpacity: setGroupOpacity } = useGroup(temporalRef);
	const { align: groupAlign, resize: resizeGroup, sizeWithRotation: groupSize } = useGroupTransform(group);
	const { resetLastPosition, offsetInsertedElement } = useInsertablePosition(temporalRef);

	const addInsertableText = async (style: TextWithValue, useStyleFontFamily?: boolean) => {
		const text = Text.create({
			...style,
			content: style.text,
		});
		Bugsnag.leaveBreadcrumb(` ${style.text} text to canvas`);
		GAnalytics.track('click', 'Button', 'add-new-text', null);
		temporalRef.value = text;

		// Conversión de tamaño de fuente para adaptarse al ancho de las pantallas
		text.fontSize = (artboardSizeInPx.value.width * text.fontSize) / DEFAULT_SCREEN_SIZE;

		if (style.outline) {
			text.outline.width = text.fontSize / 10;
		}

		if (style.curvedProperties) {
			// Le ponemos un arc inferior al minArc para que al añadirlo se le ponga le default
			// Poner el arc a 1.0 es para indicar que es un texto nuevo
			text.curvedProperties = { ...previewText.value.curvedProperties, arc: 1.0 };
		}

		// Cambio de fontFamily para que tenga el mismo que en la plantilla
		text.fontFamily = inUseFonts.value.length > 0 && !useStyleFontFamily ? inUseFonts.value[0].slug : text.fontFamily;

		// Obtenemos el peso del estilo o le aplicamos uno por defecto
		text.fontWeight = style.fontWeight || getFontWeight(text.fontFamily, true);
		if (!store.activePage) return;

		addElement(text);

		resetLastPosition();

		await centerTextElement(text, style);

		GAnalytics.trackGA4('add_to_canvas', { category: 'text' });

		runOnMobile(() => (store.activePanel = null));
	};

	const addInsertablePredefinedText = async (text: PredefinedTextApi) => {
		if (!store.activePage) return;

		let elements: Element[] = [];

		try {
			elements = await TemplateLoader.fromPredefinedText(text.svg);
		} catch (error) {
			throw new Error('Something wrong on fetch predefined text');
		}

		if (!elements?.length) return;

		// Eliminamos la selección para que no se reemplace el texto predefinido encima de otro texto seleccionado
		clearSelection();

		const opacities: any = {};
		elements.forEach((el) => {
			opacities[el.id] = el.opacity;
		});

		// Migramos las fuentes a sus slugs
		elements.forEach((newElement: Element) => {
			addElement(newElement);

			// Hay textos predefinidos que contienen elementos que no son de tipo texto,
			// si el elemento no es de tipo texto, salimos
			if (!(newElement instanceof Text)) return;

			const fontSlug = getFontSlug(newElement.fontFamily);
			newElement.fontFamily = fontSlug as string;

			const content = newElement.htmlInstance();

			Array.from(content.querySelectorAll('[style*="font-family"]'))
				.map((el) => el as HTMLElement)
				.forEach((el: HTMLElement) => {
					const fontFamily = el.style.fontFamily;
					const fontSlug = getFontSlug(fontFamily);

					el.style.fontFamily = fontSlug as string;
				});

			if (content.body.firstElementChild) newElement.content = content.body.firstElementChild.innerHTML.toString();
		});

		resetLastPosition();

		// Si se trata de un grupo de elementos dejamos seleccionado el elmento posicionado más arriba respecto al eje Y.
		const firstElement = elements.filter(
			(el) => el.position.y === Math.min(...elements.map((i) => i.position.y))
		)[0] as Text;
		temporalRef.value = firstElement;
		// If ungrouped elements, resize the element and align it
		if (elements.length === 1) {
			resizeElementAndCenter(firstElement);
		}

		// If grouped elements
		if (elements.length > 1) {
			isCreatingElement.value = true;
			setSelection(firstElement);

			toggleMoveableOpacity(true);

			await resizeGroup(artboardSizeInPx.value.width / 2);
			await groupAlign(Anchor.center);
			offsetInsertedElement();

			setGroupOpacity(0);
			await new Promise((resolve) => {
				const interval = useIntervalFn(() => {
					if (store.activePage?.domNode()?.classList.contains('canvas-rendering-finished')) {
						interval.pause();
						resolve(store.activePage);
					}
				}, 100);
			});
			firstElement.domNode()?.classList.remove('ring-custom-select');
			setSelection(firstElement);

			selection.value.forEach((el) => el.setOpacity(opacities[el.id]));

			toggleMoveableOpacity(false);

			firstElement.domNode()?.classList.add('ring-custom-select');
		}

		// si nos llega las sombras de los textos con unidades diferentes a em , ajustamos su valor al tamaño de la fuente que esté usando
		//  y establecemos la unidad a em
		elements
			.filter((el) => el instanceof Text && el.textShadow.length)
			.filter((text) => (text as Text).textShadow.some((shadow) => !shadow.unit || shadow.unit !== 'em'))
			.forEach((text) => {
				TextTools.fitEmShadowToFontSize(text as Text);
			});
		Bugsnag.leaveBreadcrumb(
			`Add the follow predefined text to canvas: ${elements.map((el) => 'text-' + el.id).join('; ')}`
		);

		GAnalytics.trackGA4('add_to_canvas', { category: 'predefined-text' });
		runOnMobile(() => (store.activePanel = null));
	};

	const addInsertableAIText = async (style: TextWithValue, visible = false) => {
		const text = Text.create({
			...style,
			content: '',
		});
		Bugsnag.leaveBreadcrumb(` AI text to canvas`);
		GAnalytics.track('click', 'Button', 'add-new-ai-text', null);
		temporalRef.value = text;
		openAIWriter(temporalRef.value);
		generateContent(visible);
		// Conversión de tamaño de fuente para adaptarse al ancho de las pantallas
		text.fontSize = (artboardSizeInPx.value.width * text.fontSize) / DEFAULT_SCREEN_SIZE;

		if (style.outline) {
			text.outline.width = text.fontSize / 10;
		}

		if (style.curvedProperties) {
			// Le ponemos un arc inferior al minArc para que al añadirlo se le ponga le default
			// Poner el arc a 1.0 es para indicar que es un texto nuevo
			text.curvedProperties = { ...previewText.value.curvedProperties, arc: 1.0 };
		}

		// Cambio de fontFamily para que tenga el mismo que en la plantilla
		text.fontFamily = inUseFonts.value.length > 0 ? inUseFonts.value[0].slug : text.fontFamily;

		// Obtenemos el peso del estilo o le aplicamos uno por defecto
		text.fontWeight = style.fontWeight || getFontWeight(text.fontFamily, true);

		if (!store.activePage) return;

		await until(content).toBeTruthy();
		addElement(text);

		resetLastPosition();

		await centerTextElement(text, style);

		runOnMobile(() => {
			store.activePanel = null;
			store.editPanel = EditPanels.WriterAI;
		});

		textAI.value = temporalRef.value;
	};

	const getFontSlug = (fontFamily: CSSStyleDeclaration | string) => {
		return (
			Object.values(fonts.value).find((f) => f.name === fontFamily)?.slug ||
			Object.values(fonts.value).find((f) => f.slug === fontFamily)?.slug ||
			Object.values(fonts.value).find((f) => f.name === 'Montserrat')?.slug
		);
	};

	const resizeElementAndCenter = async (element: Text, style?: TextWithValue) => {
		const elementScale = artboardSizeInPx.value.width / 2 / DEFAULT_SCREEN_SIZE;
		element.setScale(element.scale * elementScale);

		await centerTextElement(element, style);
	};

	const centerTextElement = async (element: Text, style?: TextWithValue) => {
		resize(artboardSizeInPx.value.width / 2, 0);
		if (style?.color) updateColor(style.color);
		await nextTick();

		setSelection(element);

		centerInVisibleZone(element);

		offsetInsertedElement();
	};

	return {
		addInsertableText,
		addInsertablePredefinedText,
		addInsertableAIText,
	};
};
