<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { computed, onMounted, watch } from 'vue';

import PagesListbox from '@/common/components/PagesListbox.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useShare } from '@/export/share/composables/useShare';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';

const emits = defineEmits(['close']);
const { errors, message, rrssProfilePages, socialMediaPagesSelected, maxHashtagsExceeded, maxMentionsExceeded } =
	useSharePublish();

const { trans } = useI18n();
const { logoutFacebook } = useShare();
const { selectedPages } = useDownloadsProject();

const dropdownrrssProfilePagesOptions = computed(() => {
	return rrssProfilePages.value.map((page: any) => ({
		label: page.name,
		value: page.id,
	}));
});

const pagesListError = computed(() => !!errors.value.find((error) => error.id === 2));
const instagramProfileError = computed(() => !!errors.value.find((error) => error.id === 1));

onMounted(async () => {
	if (rrssProfilePages.value.length > 0) {
		socialMediaPagesSelected.value = rrssProfilePages.value[0].id;
	}
});

const onInputMessage = (e: Event) => {
	if (e.target instanceof HTMLTextAreaElement) {
		message.value = e.target.value;
	}
};

const logout = async () => {
	await logoutFacebook();
	emits('close');
};

watch(socialMediaPagesSelected, (value: string | null) => {
	if (value && instagramProfileError) {
		const index = errors.value.findIndex((error) => error.id === 1);
		errors.value.splice(index);
	}
});

watch(selectedPages, (value: Page[]) => {
	if (value.length && pagesListError) {
		const index = errors.value.findIndex((error) => error.id === 2);
		errors.value.splice(index);
	}
});
</script>
<template>
	<div v-if="rrssProfilePages.length" class="flex flex-col">
		<div class="mb-4 flex flex-col gap-4 lg:flex-row">
			<div class="w-full">
				<Multiselect
					v-model="socialMediaPagesSelected"
					mode="single"
					placeholder="Instagram page"
					:classes="{
						container: 'multiselect text-gray-600 !text-sm',
						containerActive: 'border-gray-800',
						tag: 'bg-blue-500 slidesgo:bg-purple-400 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
						groupLabelSelected: 'bg-blue-500 slidesgo:bg-purple-400 text-white',
						groupLabelSelectedPointed: 'bg-blue-500 slidesgo:bg-purple-400 text-white opacity-90',
						groupLabelSelectedDisabled:
							'text-blue-100 bg-blue-500 slidesgo:bg-purple-400 bg-opacity-50 cursor-not-allowed',
						optionSelected: 'text-blue-500 font-semibold opacity-100 bg-gray-100/50',
						optionSelectedPointed: 'text-white bg-blue-500 slidesgo:bg-purple-400 opacity-90',
						optionSelectedDisabled: 'text-blue-100 bg-blue-500 slidesgo:bg-purple-400 bg-opacity-50 cursor-not-allowed',
					}"
					:class="{ 'facebookpages-error': instagramProfileError }"
					:hide-selected="false"
					:can-clear="false"
					:options="dropdownrrssProfilePagesOptions"
				/>
			</div>
			<div class="w-full">
				<PagesListbox />
			</div>
		</div>
		<div class="relative flex flex-col">
			<textarea
				:value="message"
				maxlength="2000"
				class="pii-cs h-52 w-full resize-none rounded border border-gray-200 px-4 pb-6 pt-3 text-sm text-gray-700 placeholder:text-gray-100 focus:border-gray-400 focus:outline-none"
				@input="onInputMessage"
			></textarea>
			<span class="absolute bottom-1 right-1 bg-white px-2 py-1 text-xs text-gray-100"
				>({{ message.length }}/2000)
			</span>
		</div>

		<div v-for="(error, i) in errors" :key="`${i}${error}`" class="mt-2">
			<p class="text-sm font-semibold text-red-500">- {{ error.description }}</p>
		</div>

		<div v-if="maxHashtagsExceeded" class="mt-2 text-sm font-semibold text-red-500">
			{{ trans('The message can not have more than 30 hashtags (#)') }}
		</div>
		<div v-if="maxMentionsExceeded" class="mt-2 text-sm font-semibold text-red-500">
			{{ trans('The message can not have more than 20 mentions (@)') }}
		</div>
	</div>

	<div v-if="!rrssProfilePages.length" class="flex flex-col items-center">
		<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800 lg:px-0">
			{{ trans('You account is not an Instagram for business') }}
		</h3>
		<ul class="mb-8 mt-6 flex flex-col gap-4 px-6 text-gray-600 lg:px-12">
			<li class="relative">
				<span class="absolute -left-4 -top-3 text-4xl font-bold text-blue-100/50">1</span>
				<p class="relative z-10">
					{{ trans('Make your Instagram account into a Business Account') }}
					<a
						href="https://help.instagram.com/502981923235522"
						target="_blank"
						class="font-semibold text-blue-500 hover:underline"
					>
						{{ trans('Learn how') }}
					</a>
				</p>
			</li>
			<li class="relative">
				<span class="absolute -left-4 -top-3 text-4xl font-bold text-blue-100/50">2</span>
				<p class="relative z-10">
					{{ trans('Link it with your Facebook Page') }}
					<a
						href="https://help.instagram.com/570895513091465"
						target="_blank"
						class="font-semibold text-blue-500 hover:underline"
					>
						{{ trans('Learn how') }}
					</a>
				</p>
			</li>
			<li class="relative">
				<span class="absolute -left-4 -top-3 text-4xl font-bold text-blue-100/50">3</span>
				<p class="relative z-10">{{ trans(`Now everything's connected and ready to publish your designs!`) }}</p>
			</li>
		</ul>
		<button
			class="rounded border-2 border-blue-500 px-6 py-3 font-semibold text-blue-500 hover:bg-blue-500 hover:text-white slidesgo:border-purple-400 slidesgo:bg-purple-400"
			@click="logout"
		>
			{{ trans('Logout') }}
		</button>
	</div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
