<script lang="ts" setup>
import { OnClickOutside } from '@vueuse/components';
import { until, useVirtualList } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, ref, toRef, watch } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import RemoveFontModal from '@/editor/components/RemoveFontModal.vue';
import { Text } from '@/elements/texts/text/classes/Text';
import FontSelector from '@/elements/texts/text/components/menus/FontSelector.vue';
import { useFontPicker } from '@/elements/texts/text/composables/useFontPicker';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useI18n } from '@/i18n/useI18n';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { FontMultipleWeights, SelectedFont } from '@/Types/types';

const props = defineProps<{
	element: Text;
	position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
}>();

const emit = defineEmits<{
	(e: 'uploadFont'): void;
	(e: 'updateFont', { font, weight, style }: SelectedFont): void;
}>();

const { trans } = useI18n();
const { inUseFontsWeight } = useFonts();
const { selectionLock } = useInteractions();

const element = toRef(props, 'element');

const removeModalRef = ref();

const {
	picker,
	showSpinner,
	showFontPicker,
	fileInput,
	tab,
	fontName,
	finalFont,
	fontList,
	openDeleteModal,
	usedFonts,
	uploadFont,
	onConfirmDelete,
	onCloseModal,
	onSelectToDelete,
} = useFontPicker(element);
const { requireAuth, isLogged } = useAuth();

const { list, containerProps, wrapperProps } = useVirtualList(fontList, {
	itemHeight: 32,
	overscan: 80,
});

const finalPosition = computed(() => {
	let result = 'bottom-left';

	if (picker.value && showFontPicker.value) {
		const { width, x, height, y } = picker.value.getBoundingClientRect();

		if (x + width > window.innerWidth) {
			result = result.replace('left', 'right');
		}

		if (y + height > window.innerHeight) {
			result = result.replace('bottom', 'top');
		}
	}

	return result;
});

const positionStyle = computed(() => {
	let style = 'lg:absolute ';

	switch (finalPosition.value) {
		case 'bottom-left': {
			style += 'lg:top-8 lg:bottom-auto lg:left-0 lg:right-auto';
			break;
		}
		case 'bottom-right': {
			style += 'lg:top-8 lg:bottom-auto lg:left-auto lg:right-full';
			break;
		}
		case 'top-left': {
			style += 'lg:top-auto lg:bottom-8 lg:left-0 lg:right-auto';
			break;
		}
		case 'top-right': {
			style += 'lg:top-auto lg:bottom-8 lg:left-auto lg:right-full';
			break;
		}
		default: {
			style += 'lg:top-8 lg:bottom-auto lg:left-0 lg:right-auto';
			break;
		}
	}
	return style;
});

onBeforeUnmount(() => {
	selectionLock.value = false;
});

watch(showFontPicker, (value) => {
	selectionLock.value = value;
});

const toggleFontPicker = async () => {
	if (showSpinner.value) return;

	showFontPicker.value = !showFontPicker.value;

	if (showFontPicker.value) {
		await nextTick();

		// Para evitar que el selector no sea visible en ciertos casos
		const { width, x } = picker.value.getBoundingClientRect();

		if (x + width > window.innerWidth) {
			picker.value.style.left = '0px';
			picker.value.style.top = '90px';
		}
	}
};

const hideFontPicker = (ev: MouseEvent) => {
	const target = ev.target as HTMLInputElement;
	const isRemoveModal = target.id === 'remove-font-modal' || target.closest('#remove-font-modal');

	if (target.id === 'inputFileFont' || isRemoveModal) return;

	const isSlotButton = target.id === 'fontPickerListButton' || target.parentElement?.id === 'fontPickerListButton';

	if (isSlotButton) return;

	if (showFontPicker.value) {
		showFontPicker.value = false;
	}
};

const useWepikFont = (ev: SelectedFont) => {
	if (showSpinner.value) return;

	showFontPicker.value = false;

	const selectedFont: SelectedFont = {
		font: ev.source!,
		weight: ev.weight.endsWith('i') ? ev.weight.split('i')[0] : ev.weight,
		style: ev.weight.endsWith('i'),
	};

	emit('updateFont', selectedFont);
};

const useWepikFontMiddleWeight = (ev: FontMultipleWeights) => {
	// cogemos el weight más cercano a 400
	const nearestWeight400 = TextTools.getNearestWeight(ev.name, 400).weight.toString();

	const selectedFont = {
		font: ev.source,
		weight: nearestWeight400,
		style: nearestWeight400.endsWith('i'),
	};

	emit('updateFont', selectedFont);
};

const useWepikFontUsedWeight = (ev: FontMultipleWeights) => {
	// cogemos el mismo weight que ya se está usando con esa fuente
	const sameFontFamilyFound = inUseFontsWeight.value.find((el) => el.family === ev.source.name);

	const selectedFont = {
		font: ev.source,
		weight: sameFontFamilyFound?.weight,
		style: sameFontFamilyFound?.weight.endsWith('i'),
	};

	emit('updateFont', selectedFont);
};

const onUploadFont = async () => {
	await uploadFont();

	emit('uploadFont');
};

const onUploadFontLoggin = async () => {
	if (!isLogged.value) {
		requireAuth();
		await until(isLogged).toBeTruthy();
	}
};

const scrollTop = (element: HTMLElement) => {
	element.scrollIntoView({ block: 'center' });
};
</script>
<template>
	<div class="relative flex-1 lg:flex-auto">
		<slot :toggle="toggleFontPicker">
			<div class="group relative flex h-full items-center">
				<button
					id="fontPickerListButton"
					class="group flex h-8 w-full cursor-pointer items-center justify-center rounded bg-gray-600 pl-3 pr-6 text-gray-700 hover:text-gray-800 lg:w-32 lg:bg-transparent lg:pl-2"
					:class="{ 'items-center justify-center': showSpinner }"
					@click="toggleFontPicker"
				>
					<SvgIcon v-if="showSpinner" name="spinner" class="h-6 w-6 animate-spin fill-current text-blue-500" />

					<img
						v-if="!showSpinner && finalFont?.preview"
						:src="finalFont.preview"
						class="h-3.5 w-full object-contain object-left opacity-80 invert group-hover:opacity-100"
						loading="lazy"
					/>
					<span
						v-else-if="!showSpinner && !finalFont"
						class="pointer-event-none w-16 flex-1 truncate text-left text-sm text-gray-100 lg:w-auto"
					>
						{{ trans('Mixed Fonts') }}
					</span>
					<span
						class="absolute right-1 top-0 flex h-full w-6 items-center justify-center text-white opacity-80 group-hover:opacity-100 lg:right-0"
					>
						<SvgIcon name="arrow" class="h-4 w-4" />
					</span>
				</button>
			</div>
		</slot>
		<RemoveFontModal v-if="openDeleteModal" ref="removeModalRef" @confirm="onConfirmDelete" @close="onCloseModal" />

		<OnClickOutside :options="{ ignore: [removeModalRef] }" @trigger="hideFontPicker">
			<div
				v-if="showFontPicker"
				ref="picker"
				data-testid="font-picker"
				class="fixed bottom-0 left-0 z-50 mx-auto flex w-full flex-col gap-3 overflow-hidden rounded-t-lg bg-white px-4 pt-4 shadow-xl lg:bottom-auto lg:left-auto lg:top-full lg:w-56 lg:rounded lg:p-2"
				:class="positionStyle"
			>
				<div class="flex items-center gap-2">
					<div class="relative flex-1">
						<input
							v-model="fontName"
							data-text-input
							:placeholder="trans('Search fonts')"
							maxlength="255"
							type="text"
							class="h-9 w-full rounded bg-gray-100/25 pl-8 pr-2 text-sm text-gray-700 placeholder:text-gray-500"
						/>
						<SvgIcon name="search" class="absolute left-2.5 top-3 h-3 w-3 text-gray-600" />
					</div>
					<button
						class="flex h-9 w-9 items-center justify-center rounded bg-gray-100/25 text-xs text-gray-600 hover:text-gray-900 lg:hidden"
						data-testid="toggle-font-picker"
						@click="toggleFontPicker"
					>
						<SvgIcon name="cross" class="h-3.5 w-3.5" />
					</button>
				</div>
				<div class="flex flex-col">
					<div class="flex border-b border-gray-50">
						<button
							class="border-b px-4 py-2 text-sm font-semibold lg:px-3 lg:pb-1 lg:pt-0 lg:text-xs"
							:class="{
								'border-blue-500 font-bold text-blue-500': tab === 'recent',
								'border-transparent text-gray-400 hover:text-blue-500': tab !== 'recent',
							}"
							@click="tab = 'recent'"
						>
							{{ trans('All') }}
						</button>
						<button
							class="border-b px-4 py-2 text-sm font-semibold lg:px-3 lg:pb-1 lg:pt-0 lg:text-xs"
							:class="{
								'border-blue-500 font-bold text-blue-500': tab === 'suggested',
								'border-transparent text-gray-400 hover:text-blue-500': tab !== 'suggested',
							}"
							@click="tab = 'suggested'"
						>
							{{ trans('Featured') }}
						</button>
						<button
							class="border-b px-4 py-2 text-sm font-semibold lg:px-3 lg:pb-1 lg:pt-0 lg:text-xs"
							:class="{
								'border-blue-500 font-bold text-blue-500': tab === 'userfonts',
								'border-transparent text-gray-400 hover:text-blue-500': tab !== 'userfonts',
							}"
							@click="tab = 'userfonts'"
						>
							{{ trans('My fonts') }}
						</button>
					</div>

					<div
						v-bind="containerProps"
						class="flex h-64 flex-col text-white scrollbar-thin scrollbar-thumb-gray-100"
						:class="{
							'lg:h-60': isLogged && tab === 'userfonts',
							'lg:h-72': isLogged && tab !== 'userfonts',
						}"
					>
						<div v-bind="wrapperProps" :class="{ 'flex h-8 items-center justify-center': showSpinner }">
							<div
								v-if="tab === 'recent' && !fontName.length"
								class="mb-2 flex flex-col border-b border-gray-100/25 pb-3 pt-1"
							>
								<span class="mt-2 px-2 text-xs font-bold uppercase text-gray-500">{{ trans('fonts in use') }}</span>
								<div v-for="item in usedFonts" :key="item.slug" data-testid="font-selectors" class="flex-1">
									<FontSelector
										:source="item"
										@select="useWepikFont"
										@select-multiple-weights="useWepikFontUsedWeight"
										@delete-font="onSelectToDelete(item)"
										@scroll="scrollTop"
									/>
								</div>
							</div>
							<span v-if="list.length && tab === 'recent'" class="mt-2 px-2 text-xs font-bold uppercase text-gray-500">
								{{ trans('All fonts') }}
							</span>
							<SvgIcon v-if="showSpinner" name="spinner" class="h-8 w-8 animate-spin" />
							<div v-for="item in list" v-else :key="item.data.slug" class="flex-1">
								<FontSelector
									:source="item.data"
									:can-be-deleted="tab === 'userfonts'"
									@select="useWepikFont"
									@select-multiple-weights="useWepikFontMiddleWeight"
									@delete-font="onSelectToDelete(item.data)"
									@scroll="scrollTop"
								/>
							</div>
						</div>
					</div>
					<div v-if="isLogged && tab === 'userfonts'" class="hidden h-12 pt-2 lg:block">
						<input
							id="inputFileFont"
							ref="fileInput"
							type="file"
							class="hidden"
							accept="font/ttf,application/x-font-ttf,application/x-font-truetype,application/vnd.ms-opentype,application/x-font-opentype,application/x-font-otf,.ttf,.otf"
							@change="onUploadFont"
						/>

						<label for="inputFileFont">
							<div
								class="flex h-full w-full cursor-pointer items-center justify-center gap-2 rounded bg-darkblue-300 text-white hover:bg-blue-500"
							>
								<SvgIcon name="upload" class="h-3 w-3 fill-current" />
								<p class="text-sm font-semibold">
									{{ trans('Upload your font') }}
								</p>
							</div>
						</label>
					</div>
					<div
						v-else-if="tab === 'userfonts'"
						class="absolute inset-0 top-20 hidden flex-col items-center justify-center gap-4 p-2 lg:flex"
					>
						<SvgIcon name="text" class="h-8 w-8 text-gray-300" />
						<p class="px-4 text-center font-semibold leading-5 text-gray-600">
							{{ trans('You have not uploaded any font') }}
						</p>
						<button
							class="flex w-full cursor-pointer items-center justify-center gap-2 rounded bg-darkblue-300 py-2 text-white hover:bg-darkblue-500"
							@click="onUploadFontLoggin"
						>
							<SvgIcon name="upload" class="h-3 w-3 fill-current" />
							<p class="text-sm font-semibold">
								{{ trans('Upload your first font') }}
							</p>
						</button>
					</div>
				</div>
			</div>
		</OnClickOutside>
	</div>
</template>
<style lang="sass" scoped>
.font-box
    img
        @apply h-5 object-cover object-left

    &:first-child img
        @apply h-6

    &:last-child img
        @apply h-4
</style>
