<script setup lang="ts">
import { createPopper } from '@popperjs/core';
import { onClickOutside, until, useScrollLock, useTextareaAutosize } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, toRef, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useZoom } from '@/editor/composables/useZoom';
import Element from '@/elements/element/classes/Element';
import ConfirmCancelAIWriterModal from '@/elements/texts/text/components/ConfirmCancelAIWriterModal.vue';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useI18n } from '@/i18n/useI18n';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useMoveable } from '@/interactions/composables/useInteractiveElements';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useToolbarTarget } from '@/layout/composables/useToolbarTarget';
import { useActivePage } from '@/page/composables/useActivePage';

const { isMobile } = useDeviceInfo();
const { trans } = useI18n();
const { isZooming } = useZoom();
const {
	textAI,
	prompt,
	hasAIResponse,
	confirmChange,
	generateContent,
	closeAIWriter,
	generatingContent,
	content,
	warning,
	removeLastAiWriterText,
} = useAiWriter();

const props = defineProps<{
	element: Element;
}>();

const element = toRef(props, 'element');
const openModal = ref(false);

const updatePopper = async () => {
	await nextTick();
	popper.value?.update();
};
watch([() => textAI.value?.size, () => textAI.value?.position], async () => {
	await updatePopper();
});

const toolbar = ref();
const popper = ref();

const { target } = useToolbarTarget(element);

const tones = [trans('Professional'), trans('Casual'), trans('Confident'), trans('Friendly')];
const selectTone = ref(false);

const toggleTone = () => {
	selectTone.value = !selectTone.value;
};

const closeModal = () => {
	openModal.value = false;
};

watch(
	[element],
	async () => {
		if (isMobile.value) return;
		await nextTick();
		initPopper();
	},
	{ immediate: true }
);

const initPopper = () => {
	if (!target.value || !toolbar.value) return;

	popper.value = createPopper(target.value, toolbar.value, {
		placement: 'right-start',
		modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
	});
};
const { waitForMoveableAreaReady } = useMoveable();

const { selectionLock } = useInteractions();
onMounted(async () => {
	await waitForMoveableAreaReady();
	await updatePath();
	prompt.value = '';
	selectionLock.value = true;
	await until(() => isZooming.value).toBe(false);
	textarea.value?.focus();
});

onBeforeUnmount(() => {
	selectionLock.value = false;
});

const path = ref('');
const generateExcludedSVGPath = (elements: HTMLElement[]) => {
	const excludedRects = elements.map((element: HTMLElement) => {
		// Calculamos cuantos px tiene el round del elemento para poder replicarlo en el path
		let rounded = 0;
		if (element.className.includes('rounded')) {
			rounded = 4;
		}

		if (element.className.includes('rounded-md')) {
			rounded = 6;
		}

		if (element.className.includes('rounded-lg')) {
			rounded = 8;
		}
		return {
			rounded,
			box: element.getBoundingClientRect(),
		};
	});

	const exclude = excludedRects.map((rect) => ({
		leftTop: `M${rect.box.left + rect.rounded} ${rect.box.top} Q${rect.box.left} ${rect.box.top} ${rect.box.left} ${
			rect.box.top + rect.rounded
		}`,
		rightTop: `V${rect.box.top + rect.rounded} Q${rect.box.right} ${rect.box.top} ${rect.box.right - rect.rounded} ${
			rect.box.top
		}`,
		rightBottom: `H${rect.box.right - rect.rounded} Q${rect.box.right} ${rect.box.bottom} ${rect.box.right} ${
			rect.box.bottom - rect.rounded
		}`,
		leftBottom: `V${rect.box.bottom - rect.rounded} Q${rect.box.left} ${rect.box.bottom} ${
			rect.box.left + rect.rounded
		} ${rect.box.bottom}`,
	}));

	return `
  M${innerWidth} ${innerHeight}
  H0V0
  H${innerWidth} V${innerHeight}
  Z
  ${exclude
		.map((pointsPath) => `${pointsPath.leftTop}${pointsPath.leftBottom}${pointsPath.rightBottom}${pointsPath.rightTop}`)
		.join('')}
  Z
`;
};
const updatePath = async () => {
	if (isMobile.value) return;
	await nextTick();
	const excludedElements = document.querySelectorAll<HTMLElement>('.ai-writer-excluded');
	path.value = generateExcludedSVGPath(Array.from(excludedElements));
};

watch([content, selectTone, generatingContent, warning], async () => {
	await updatePath();
});

const lockedScroll = useScrollLock(document.querySelector('#scroll-area') as HTMLElement);

lockedScroll.value = true;

onUnmounted(() => {
	lockedScroll.value = false;
});

const { removeElement } = useActivePage();
const exitAiWriter = async () => {
	if (!textAI.value?.content) {
		removeElement(element.value);
	}
	removeLastAiWriterText();
	closeAIWriter();
};

const modal = ref();
onClickOutside(
	toolbar,
	(e) => {
		if (isMobile.value && e.target instanceof HTMLElement && e.target.closest('#edit-panel')) return;
		if (e.target instanceof HTMLElement && e.target.closest('.modal')) return;

		if (hasAIResponse.value) {
			confirmChange();
		}
		closeAIWriter();
	},
	{ ignore: [modal] }
);

const { listen } = useOrderedKeyboardListener();

listen('Escape', (e) => {
	e.preventDefault();
	closeAIWriter();
});

const rewriteText = (tone = null) => {
	prompt.value = `Rephrase the content${tone ? ` using a ${tone} tone` : ''}`;

	if (tone) {
		GAnalytics.trackDebounceEditBarGA4({
			category: 'ai_writer',
			link_text: `change_tone_${tone}`,
		});
	} else {
		GAnalytics.trackDebounceEditBarGA4({
			category: 'ai_writer',
			link_text: 'rewrite_text',
		});
	}

	generateContent();
	if (tone) {
		selectTone.value = false;
	}
};

const summarize = () => {
	prompt.value = `Make the content shorter`;

	GAnalytics.trackDebounceEditBarGA4({
		category: 'ai_writer',
		link_text: 'make_it_shorter',
	});

	generateContent();
};
const makeLonger = () => {
	prompt.value = `Make the content longer`;

	GAnalytics.trackDebounceEditBarGA4({
		category: 'ai_writer',
		link_text: 'make_it_longer',
	});

	generateContent();
};

const handlerSubmit = (e: KeyboardEvent) => {
	if (e.code !== 'Enter' || e.metaKey || e.shiftKey || !prompt.value) {
		return;
	}
	e.preventDefault();
	generateContent();

	GAnalytics.trackDebounceEditBarGA4({
		category: 'ai_writer',
		link_text: 'generate',
	});
};

const onClickSaveChanges = () => {
	confirmChange();

	GAnalytics.trackDebounceEditBarGA4({
		category: 'ai_writer',
		link_text: 'save_changes',
	});
};

const onClickDiscard = () => {
	openModal.value = true;

	GAnalytics.trackDebounceEditBarGA4({
		category: 'ai_writer',
		link_text: 'discard_text',
	});
};

const onClickCross = () => {
	if (hasAIResponse.value) {
		confirmChange();
	}

	closeAIWriter();
};

const { textarea } = useTextareaAutosize({ onResize: () => updatePath(), watch: [prompt] });
const isOpenInMobile = ref(false);
const shouldShowOptionsPanel = computed(() => !isMobile.value || (isMobile.value && isOpenInMobile.value));
</script>
<template>
	<div>
		<teleport v-if="!isMobile" to="body">
			<svg
				id="svgOverlay"
				style="
					width: 100%;
					height: 100%;
					position: fixed;
					top: 0;
					left: 0;
					opacity: 0.7;
					z-index: 50;
					fill: #0b2239;
					pointer-events: none;
				"
			>
				<path :d="path" />
			</svg>
		</teleport>
		<component :is="isMobile ? 'div' : 'teleport'" to="#aiWriterToolbarTarget" :class="isMobile ? 'w-full p-4' : ''">
			<div v-if="isMobile" class="mb-4 flex items-center justify-between">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Ai Writer') }}</h2>
				<div v-if="isMobile" class="flex">
					<button
						class="mr-2 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
						@click="isOpenInMobile = !isOpenInMobile"
					>
						<SvgIcon name="more" class="h-3 w-3" />
					</button>
					<button
						data-testid="close-panel"
						class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
						@click="onClickCross"
					>
						<SvgIcon name="cross" class="h-3 w-3" />
					</button>
				</div>
			</div>
			<div ref="toolbar" class="toolbar-group z-10 flex flex-col gap-2">
				<template v-if="generatingContent">
					<div
						class="ai-writer-excluded flex w-full items-center gap-2 rounded-md bg-gradient-to-l from-[#BFB4FF] to-[#7AB6FF] px-3 py-3.5 text-sm font-normal text-gray-800 lg:ml-1 lg:w-max lg:bg-white lg:bg-none lg:px-2 lg:py-1 lg:font-bold lg:text-purple-400"
					>
						<div class="flex animate-pulse items-center">
							<SvgIcon name="time" class="mr-2 h-4 w-4" />
							{{ trans('AI is writing...') }}
						</div>
					</div>
				</template>
				<template v-else>
					<form
						class="ai-writer-excluded flex flex-col rounded-md bg-gray-600 py-1 pl-2 pr-2 lg:ml-1 lg:bg-white lg:pr-1"
						@submit.prevent="generateContent"
					>
						<div class="flex items-end gap-2">
							<SvgIcon name="wand" class="my-auto hidden h-4 w-4 text-purple-400 lg:block" />
							<textarea
								ref="textarea"
								v-model="prompt"
								class="my-auto w-full resize-none bg-gray-600 px-1 py-2.5 text-sm text-white focus:outline-none lg:w-56 lg:bg-white lg:px-0 lg:py-0 lg:text-gray-700"
								:placeholder="trans('Give AI your instructions')"
								@keydown="handlerSubmit"
							/>
							<button
								class="mb-1 flex h-8 w-14 items-center justify-center rounded bg-gradient-to-r from-blue-500 to-purple-400 text-white hover:opacity-80 lg:mb-0 lg:h-7 lg:w-10"
								:class="{ 'pointer-events-none cursor-not-allowed opacity-50': prompt.length < 3 }"
							>
								<SvgIcon name="right-small" class="h-4 w-4" />
							</button>
						</div>
						<div v-if="warning" class="mt-1 flex w-64 text-sm leading-none text-red-600">
							<span v-text="warning"></span>
						</div>
					</form>
					<div
						v-show="shouldShowOptionsPanel"
						class="ai-writer-excluded fixed bottom-32 left-0 right-0 mx-1 flex flex-col gap-0.5 rounded-md bg-gray-700 p-2 lg:relative lg:bottom-auto lg:right-auto lg:ml-1 lg:w-40 lg:bg-white"
					>
						<p v-if="!hasAIResponse" class="hidden px-2 pb-1 text-xs text-gray-300 lg:block">
							{{ trans('Edit or review') }}
						</p>
						<button
							v-if="hasAIResponse"
							class="flex items-center gap-2 rounded-md bg-purple-100 px-4 py-3 text-sm text-purple-400 lg:bg-transparent lg:px-2 lg:py-1.5 lg:hover:bg-purple-100/60"
							@click="onClickSaveChanges"
						>
							<SvgIcon name="check" class="h-3 w-3" />
							{{ trans('Save changes') }}
						</button>
						<button
							class="flex items-center gap-2 rounded-md px-2 py-2 text-sm text-gray-100 hover:bg-gray-100/20 lg:py-1.5 lg:text-gray-600"
							@click="rewriteText()"
						>
							<SvgIcon name="reload" class="h-3 w-3" />
							{{ trans('Rewrite text') }}
						</button>
						<button
							class="flex items-center gap-2 rounded-md px-2 py-2 text-sm text-gray-100 hover:bg-gray-100/20 lg:py-1.5 lg:text-gray-600"
							:class="{ 'pointer-events-none opacity-50': content.length < 50 }"
							@click="summarize()"
						>
							<SvgIcon name="shorter" class="h-3 w-3" />
							{{ trans('Make it shorter') }}
						</button>
						<button
							class="flex items-center gap-2 rounded-md px-2 py-2 text-sm text-gray-100 hover:bg-gray-100/20 lg:py-1.5 lg:text-gray-600"
							@click="makeLonger()"
						>
							<SvgIcon name="longer" class="h-3 w-3" />
							{{ trans('Make it longer') }}
						</button>
						<div class="relative w-full">
							<button
								class="flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm text-gray-100 hover:bg-gray-100/20 lg:py-1.5 lg:text-gray-600"
								@click="toggleTone"
							>
								<SvgIcon name="tone" class="h-3 w-3" />
								<div class="flex-1 text-left">
									{{ trans('Change tone') }}
								</div>
								<SvgIcon name="caret" class="-mr-1 h-3 w-3 -rotate-90 text-gray-400" />
							</button>
							<div
								v-if="selectTone && !isMobile"
								class="ai-writer-excluded absolute left-full top-1/2 ml-2 hidden -translate-y-1/2 flex-col gap-0.5 rounded-md px-2 py-1.5 shadow-lg lg:flex lg:bg-white"
							>
								<button
									v-for="toneOption in tones"
									:key="toneOption"
									class="py-1.py-2 lg:5 flex items-center rounded-md px-3 text-left text-sm text-gray-100 hover:bg-gray-100/20 lg:text-gray-600"
									@click="rewriteText(toneOption)"
								>
									{{ toneOption }}
								</button>
							</div>
						</div>
						<button
							v-if="hasAIResponse"
							class="flex items-center gap-2 rounded-md px-2 py-1.5 text-sm text-red-500 hover:bg-red-100/20"
							@click="onClickDiscard"
						>
							<SvgIcon name="trash" class="h-3 w-3" />
							{{ trans('Discard text') }}
						</button>
					</div>
					<div
						v-if="selectTone && isMobile"
						class="ai-writer-excluded fixed bottom-32 left-0 right-0 mx-1 flex flex-col gap-0.5 rounded-md bg-gray-700 p-2 lg:relative lg:bottom-auto lg:right-auto lg:ml-1 lg:w-40"
					>
						<button class="flex items-center gap-2 px-2 py-1 text-xs text-gray-300" @click="toggleTone">
							<SvgIcon name="right-small" class="h-3 w-3 rotate-180" />
							{{ trans('Change tone') }}
						</button>
						<button
							v-for="toneOption in tones"
							:key="toneOption"
							class="lg:5 flex items-center rounded-md px-3 py-1 py-2 text-left text-sm text-gray-100 hover:bg-gray-100/20 lg:text-gray-600"
							@click="rewriteText(toneOption)"
						>
							{{ toneOption }}
						</button>
					</div>
				</template>
			</div>
			<ConfirmCancelAIWriterModal v-if="openModal" ref="modal" @confirm="exitAiWriter" @close="closeModal" />
		</component>
	</div>
</template>
<style scoped>
#svgOverlay > * {
	pointer-events: auto;
}
</style>
