import { promiseTimeout } from '@vueuse/core';
import { ComputedRef, onMounted, onUnmounted, Ref, ref, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useSelection } from '@/interactions/composables/useSelection';
const currentOffset = ref(0);
/**
 * It moves the page to the top when the colorpicker panel is open and the focused element is under it
 * @param {Ref<HTMLElement>} panel   colorpicker panel
 * @param {ComputedRef<string>} colorType - it detect color type to trigger the watcher and recalculate page displacement
 */
export const useMovePageOnColorpickerPanelOrverFocused = (panel: Ref<HTMLElement>, colorType: ComputedRef<string>) => {
	const { selection } = useSelection();

	const focusedElement = selection.value[0] || null;
	const { isMobile } = useDeviceInfo();
	const scrollArea = document.querySelector('#scroll-area') as HTMLElement;
	const elementNode = focusedElement && focusedElement.domNode();
	const canvasNode = elementNode && elementNode.closest('[id^=canvas]');
	const MARGIN = 5;

	watch(colorType, async () => {
		await translateScrollArea();
	});

	onMounted(async () => {
		await translateScrollArea();
	});

	onUnmounted(async () => {
		await translateScrollArea(false);
	});

	const translateScrollArea = async (isOpen = true) => {
		if (!isMobile.value) return;
		scrollArea.style.transform = ``;
		if (isOpen && scrollArea) {
			const { isUnder, offset } = await movePageOnElementUnderColorpickerPanel();
			scrollArea.style.transform = `translateY(${isUnder ? offset : 0}px)`;
			return;
		}
		currentOffset.value = 0;
		scrollArea.style.transform = ``;
	};

	const movePageOnElementUnderColorpickerPanel = async (): Promise<{ isUnder: boolean; offset: number }> => {
		// Hay que esperar a que se monte el panel ya que inyectamos elementos en el color picker con teleports y no están
		// renderizados hasta que el color picker como tal se monta
		await promiseTimeout(0);
		const mainPanel = document.getElementById('main-panel');
		if (!elementNode || !canvasNode || !mainPanel) return { isUnder: false, offset: 0 };
		const panelBounds = panel.value.getBoundingClientRect();
		const elementBounds = elementNode.getBoundingClientRect();
		const canvasBounds = canvasNode.getBoundingClientRect();
		const mainPanelRBox = mainPanel.getBoundingClientRect();

		const topSideLimitPanel = panelBounds.y;
		const bottomSideLimitElement = elementBounds.y + elementBounds.height;
		const bottomSideLimitCanvas = canvasBounds.y + canvasBounds.height;
		const topSideLimitMainPanel = mainPanelRBox.y;

		const elementIsPartiallyOutside = bottomSideLimitElement > bottomSideLimitCanvas;
		const offsetWithMainPanel =
			bottomSideLimitElement > topSideLimitMainPanel ? bottomSideLimitElement - topSideLimitMainPanel : 0;
		currentOffset.value = elementIsPartiallyOutside
			? topSideLimitPanel - bottomSideLimitCanvas
			: topSideLimitPanel - bottomSideLimitElement;

		const shouldMovePage = elementIsPartiallyOutside
			? bottomSideLimitCanvas > topSideLimitPanel
			: bottomSideLimitElement > topSideLimitPanel;

		if (elementIsPartiallyOutside && offsetWithMainPanel) {
			currentOffset.value = currentOffset.value - (bottomSideLimitElement - bottomSideLimitCanvas);
		}
		if (shouldMovePage) {
			return {
				isUnder: true,
				offset: currentOffset.value - MARGIN + offsetWithMainPanel,
			};
		}
		return { isUnder: false, offset: 0 };
	};
};
