<script setup lang="ts">
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { useElementOrderOrchestrator } from '@/elements/element/composables/useElementOrderOrchestrator';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');
const { trans } = useI18n();

const { moveToFront, moveToBack, moveUp, moveDown, canGoUp, canGoDown } = useElementOrderOrchestrator(element).value;
</script>

<template>
	<div>
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-800">
			{{ trans('Order') }}
		</h4>

		<div data-testid="order" class="button-group update-position-div mb-6 flex flex-1">
			<div class="mr-px flex-1">
				<button
					:id="`forward-${element.type}-toolbar`"
					class="flex h-8 w-full items-center justify-center rounded-bl rounded-tl bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoUp,
						'hover:bg-gray-600 hover:text-white': canGoUp,
					}"
					:disabled="!canGoUp"
					@click="moveUp"
				>
					<SvgIcon name="layer-up" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{
					trans('Forward')
				}}</label>
			</div>

			<div class="mr-px flex-1">
				<button
					:id="`backward-${element.type}-toolbar`"
					class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoDown,
						'hover:bg-gray-600 hover:text-white': canGoDown,
					}"
					:disabled="!canGoDown"
					@click="moveDown"
				>
					<SvgIcon name="layer-down" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{
					trans('Backward')
				}}</label>
			</div>

			<div class="mr-px flex-1">
				<button
					:id="`to-front-${element.type}-toolbar`"
					class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoUp,
						'hover:bg-gray-600 hover:text-white': canGoUp,
					}"
					:disabled="!canGoUp"
					@click="moveToFront"
				>
					<SvgIcon name="forward" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{
					trans('To Front')
				}}</label>
			</div>

			<div class="mr-px flex-1">
				<button
					:id="`to-back-${element.type}-toolbar`"
					class="flex h-8 w-full items-center justify-center rounded-br rounded-tr bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
					:class="{
						'cursor-not-allowed opacity-50 hover:bg-gray-700': !canGoDown,
						'hover:bg-gray-600 hover:text-white': canGoDown,
					}"
					:disabled="!canGoDown"
					@click="moveToBack"
				>
					<SvgIcon name="backward" class="h-5 w-5 fill-current" />
				</button>
				<label class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{
					trans('To Back')
				}}</label>
			</div>
		</div>
	</div>
</template>
