<script setup lang="ts">
import 'v-calendar/dist/style.css';

import { useDebounceFn } from '@vueuse/core';
import { DatePicker } from 'v-calendar';
import { computed, nextTick, onMounted, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import MoreMenuButton from '@/common/components/MoreMenuButton.vue';
import SocialListbox from '@/common/components/SocialListbox.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import PublishFacebookModal from '@/export/share/components/PublishFacebookModal.vue';
import PublishInstagramModal from '@/export/share/components/PublishInstagramModal.vue';
import PublishPinterestModal from '@/export/share/components/PublishPinterestModal.vue';
import PublishTwitterModal from '@/export/share/components/PublishTwitterModal.vue';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import { ShareModalType } from '@/Types/types';

const { trans, locale } = useI18n();

const emits = defineEmits<{
	(e: 'close'): void;
	(e: 'goback'): void;
	(e: 'goToPublishNow'): void;
	(e: 'share', socialMedia: ShareModalType): void;
}>();

const {
	canPublishContent,
	date,
	errors,
	formattedDate,
	socialMediaModal,
	step,
	createSchedulePublication,
	updateDate,
	validateShareInputs,
} = useSharePublish();

const currentTime = ref<Date>(new Date());
const timepicker = ref();

const isInvalidTime = computed(() => {
	const newDate = new Date();
	let minutes = newDate.getMinutes();
	let hours = newDate.getHours();

	const finalMinutes = minutes + 30;

	minutes = finalMinutes > 59 ? finalMinutes - 60 : finalMinutes;
	if (finalMinutes > 59) {
		hours = hours === 23 ? 0 : hours + 1;
	}

	// Establecemos el currentTime como la hora actual + 30 minutes para que la fecha de publicación sea actual
	currentTime.value.setMinutes(minutes);
	currentTime.value.setHours(hours);
	currentTime.value.setSeconds(0);
	currentTime.value.setMilliseconds(0);

	return date.value < currentTime.value;
});
const currentDateFormatted = computed(() =>
	formattedDate.value
		.split(', ')
		.map((date) => trans(date))
		.join(', ')
);
const publishSocialMedia = computed(() => {
	switch (socialMediaModal.value) {
		case ShareModalType.instagram: {
			return PublishInstagramModal;
		}
		case ShareModalType.facebook: {
			return PublishFacebookModal;
		}
		case ShareModalType.pinterest: {
			return PublishPinterestModal;
		}
		case ShareModalType.twitter: {
			return PublishTwitterModal;
		}
		default:
			return null;
	}
});

const onClickBackStep = () => {
	errors.value = [];

	if (step.value === 2) {
		step.value = 1;
		return;
	}

	emits('goback');
};

const onUpdateTime = useDebounceFn(() => {
	const timeSplited = timepicker.value.value.split(':');

	if (!timeSplited[0].length || !timeSplited[1].length) {
		timepicker.value.value = `${currentTime.value.getHours()}:${currentTime.value.getMinutes()}`;
		return;
	}

	let hours = parseInt(timeSplited[0]);
	let minutes = parseInt(timeSplited[1]);

	updateDate(hours, minutes);
}, 500);

const setTimepicker = (hours: number, minutes: number) => {
	timepicker.value.value = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

const onShareSocial = (socialMedia: ShareModalType) => {
	emits('share', socialMedia);
	step.value = 2;
};

const onClickPublishNow = () => {
	errors.value = [];

	step.value = 1;
	emits('goToPublishNow');
};

const onClickSaveDraft = () => {
	validateShareInputs();

	// Si no tiene errores mandamos la petición a la api
	if (!errors.value.length) {
		createSchedulePublication(true);
		GAnalytics.track('click', 'Share', 'save-draft-button');
		emits('close');
	}
};
const onClickSchedule = () => {
	validateShareInputs();

	// Si no tiene errores mandamos la petición a la api
	if (!errors.value.length) {
		createSchedulePublication();
		GAnalytics.track('click', 'Share', 'schedule-button');

		if (socialMediaModal.value) {
			GAnalytics.trackGA4('social_media_schedule_completed', {
				link_text: socialMediaModal.value,
				success_event: 'true',
			});
		}

		emits('close');
	}
};

const onClickNextStep = async () => {
	currentTime.value = new Date();

	await nextTick();

	if (!isInvalidTime.value) {
		step.value = 2;
	}
};

onMounted(() => {
	const date = new Date();
	const hours = date.getHours();
	const finalHour = hours < 23 ? hours + 1 : 0;

	updateDate(finalHour, date.getMinutes());
	setTimepicker(finalHour, date.getMinutes());
});
</script>

<template>
	<div>
		<div class="mb-4 flex w-full items-center justify-between gap-1">
			<div class="flex items-center gap-1">
				<button @click="onClickBackStep"><SvgIcon name="arrow" class="h-5 w-5 rotate-90" /></button>
				<p class="text-lg font-semibold text-gray-700">{{ trans('Social Media Schedule') }}</p>
			</div>
			<span class="text-sm text-gray-400">{{ `${trans('Step')} ${step}/2` }}</span>
		</div>

		<div v-show="step === 1" class="flex flex-col">
			<div class="datePicker-container flex flex-col gap-2 rounded-md border border-gray-100/50 p-4">
				<DatePicker
					v-model="date"
					:locale="locale"
					class="border-none"
					is-expanded
					:min-date="new Date()"
					day-names="dayNamesShorter"
					:first-day-of-week="1"
					:attributes="[
						{
							key: 'today',
							dates: new Date(),
						},
					]"
				/>
				<hr class="border-t-gray-100/50" />

				<div class="mt-2 flex items-center justify-between gap-4">
					<input
						id="timepicker"
						ref="timepicker"
						type="time"
						class="h-10 rounded border border-gray-300 px-4 outline-none"
						:class="{ 'border-red-500 bg-red-100': isInvalidTime }"
						name="timepicker"
						:min="`00:00`"
						max="23:59"
						@change="onUpdateTime"
					/>
					<button
						class="flex h-10 flex-1 items-center justify-center rounded bg-blue-500 text-white slidesgo:bg-purple-400"
						:class="{ 'bg-gray-500 opacity-50': isInvalidTime }"
						:disabled="isInvalidTime"
						@click="onClickNextStep"
					>
						{{ trans('Next') }}
					</button>
				</div>
				<span v-if="isInvalidTime" class="text-sm text-red-500">
					{{ trans('The selected minimum time must be the current time plus 30 minutes.') }}
				</span>
			</div>
		</div>
		<div v-show="step === 2" class="flex flex-col">
			<div class="flex flex-col gap-4">
				<div class="flex h-6 items-center justify-between text-gray-800">
					<span class="flex items-center gap-2 text-sm">
						<SvgIcon name="calendar" class="h-4 w-4" />
						{{ formattedDate }}
					</span>
					<MoreMenuButton>
						<button
							class="flex w-full items-center gap-2 py-2 px-3 text-gray-500 hover:bg-gray-50/75 hover:text-gray-700"
							@click="onClickBackStep"
						>
							<SvgIcon name="calendar" class="h-4 w-4" /> {{ trans('Change date and time') }}
						</button>
						<button
							v-if="socialMediaModal"
							class="flex w-full items-center gap-2 py-2 px-3 text-gray-500 hover:bg-gray-50/75 hover:text-gray-700"
							@click="onClickPublishNow"
						>
							<SvgIcon name="publish" class="h-4 w-4" /> {{ trans('Publish now') }}
						</button>
					</MoreMenuButton>
				</div>
				<SocialListbox @share="onShareSocial" />
				<component :is="publishSocialMedia"></component>

				<div v-if="step === 2 && socialMediaModal" class="flex items-center justify-between gap-4">
					<button
						class="text-md flex flex-1 justify-center rounded bg-gray-100/50 p-2 font-semibold text-gray-800 hover:bg-gray-100"
						:class="{ 'bg-gray-400/50 text-gray-600': !canPublishContent }"
						@click="onClickSaveDraft"
					>
						{{ trans('Save draft') }}
					</button>
					<button
						class="text-md flex flex-1 justify-center rounded bg-blue-500 p-2 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
						:class="{ 'bg-gray-400/50 text-gray-600': !canPublishContent }"
						@click="onClickSchedule"
					>
						{{ trans('Schedule') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="sass">
.datePicker-container
	.vc-header
		@apply pt-0 mb-4

	.vc-title
		@apply font-body text-gray-900

	.vc-arrows-container
		@apply pt-0

	.vc-weeks
		@apply pt-0

	.vc-weekday
		@apply font-body text-gray-800 py-2

	.vc-day
		@apply my-1 text-gray-900 font-body rounded

		&:hover
			@apply bg-gray-50

		&.is-today .vc-day-content
			@apply text-blue-500 font-bold

		&.in-prev-month
			@apply pointer-events-none opacity-0

		&.in-next-month
			@apply hidden

	.vc-day-content
		@apply font-semibold w-full rounded h-full

		&:hover,
		&:focus
			@apply bg-transparent

		&.is-disabled
			@apply text-gray-300 cursor-not-allowed

			&:hover
				@apply bg-white

	.vc-highlights
		.vc-highlight
			@apply w-full h-full pointer-events-none rounded bg-blue-500 slidesgo:bg-purple-400 #{!important}

			&:hover
				@apply bg-blue-500 slidesgo:bg-purple-400

		& +.vc-day-content
			@apply pointer-events-none

	.vc-day.is-not-in-month *
		@apply opacity-100 text-gray-100 pointer-events-none

	& > div:first-child
		@apply border-none
</style>
