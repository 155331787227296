import { useMediaControls } from '@vueuse/core';
import { computed, onMounted, Ref, ref, watch } from 'vue';

import { TimeTools } from '@/common/utils/TimeTools';
import { useMainStore } from '@/editor/stores/store';
import { Video } from '@/elements/medias/video/classes/Video';

const videoCache = new Map<string, Promise<string>>();

const preloadVideo = async (src: string): Promise<string> => {
	if (videoCache.has(src)) {
		return videoCache.get(src) as Promise<string>;
	}
	// eslint-disable-next-line no-async-promise-executor
	const promise = new Promise<string>(async (resolve) => {
		const res = await fetch(src, { credentials: 'omit' });
		const blob = await res.blob();

		resolve(URL.createObjectURL(blob));
	});

	videoCache.set(src, promise);

	return promise;
};

export const useVideoControls = (video: Ref<Video>, domRef?: Ref<HTMLVideoElement>) => {
	const getVideoFromDom = () => (domRef ? domRef.value : video.value?.domNode()?.querySelector('video'));
	const store = useMainStore();

	const videoDom = ref(getVideoFromDom());

	onMounted(() => {
		if (videoDom.value) return;
		videoDom.value = getVideoFromDom();
		muted.value = video.value.muted;
	});

	const src = ref('');
	const setVideoSrc = async (newUrl = '') => {
		store.proccesingCachedElements++;
		const url = newUrl || store.uploads.get(video.value?.metadata?.imageApi?.id)?.url || video.value.url;
		try {
			src.value = await preloadVideo(url);
		} catch (e) {
			if (e instanceof Error) {
				console.error(`Error on preload video: ${e.message}`);
			}
		} finally {
			store.proccesingCachedElements--;
		}
	};

	setVideoSrc();
	const updateVideoSrc = async (newUrl: string) => {
		videoCache.clear();

		await setVideoSrc(newUrl);
	};

	const { playing, currentTime, duration, onSourceError, muted } = useMediaControls(videoDom, {
		src,
	});

	const error = ref(false);
	onSourceError(() => (error.value = true));

	const currentTimeInMS = computed(() => TimeTools.toMS(currentTime.value));
	const durationInMS = computed(() => TimeTools.toMS(duration.value));

	const togglePlayVideo = () => {
		muted.value = video.value.muted;
		if (playing.value) {
			playing.value = false;
			return;
		}

		if (currentTimeInMS.value < video.value?.cropTime?.start || currentTimeInMS.value >= video.value.cropTime.end) {
			currentTime.value = TimeTools.toSeconds(video.value.cropTime.start);
		}

		playing.value = true;
	};

	watch(currentTimeInMS, (newCurrentTime) => {
		if (!playing.value || newCurrentTime < video.value.cropTime.end) return;

		playing.value = false;
		currentTime.value = 0;
	});

	watch(
		() => video.value.muted,
		() => {
			muted.value = video.value.muted;
		}
	);

	const formatedCurrentTime = computed(() => TimeTools.formatMillisecondsToMMSS(currentTimeInMS.value));
	const formatedTotalDurationTime = computed(() => TimeTools.formatMillisecondsToMMSS(durationInMS.value));
	const formatedCroppedDurationTime = computed(() => TimeTools.formatMillisecondsToMMSS(video.value.croppedDuration()));

	const currentCroppedTimePercentage = computed(() => {
		const cropDuration = video.value.cropTime.end - video.value.cropTime.start;
		const currentCropPosition = currentTimeInMS.value - video.value.cropTime.start;
		const rawPercentage = (currentCropPosition / cropDuration) * 100;

		return Math.max(0, Math.min(rawPercentage, 100));
	});

	const currentStartPercentage = computed(() => (video.value.cropTime.start / durationInMS.value) * 100);
	const currentEndPercentage = computed(
		() => ((durationInMS.value - video.value.cropTime.end) / durationInMS.value) * 100
	);
	const currentTotalTimePercentage = computed(() => (currentTimeInMS.value / durationInMS.value) * 100);

	const croppedDuration = computed(() => video.value.croppedDuration());

	const url = computed(() => video.value.url);

	const registerUpdateVideoUrl = () => {
		watch(url, async (newVal) => {
			await updateVideoSrc(newVal);
			error.value = false;
		});
	};

	return {
		croppedDuration,
		currentTime,
		currentTimeInMS,
		duration,
		durationInMS,
		formatedCroppedDurationTime,
		formatedCurrentTime,
		formatedTotalDurationTime,
		currentCroppedTimePercentage,
		currentTotalTimePercentage,
		currentStartPercentage,
		currentEndPercentage,
		playing,
		muted,
		togglePlayVideo,
		updateVideoSrc,
		onSourceError,
		error,
		src,
		registerUpdateVideoUrl,
	};
};
