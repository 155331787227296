<script setup lang="ts">
import { watch } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useOfflineMode } from '@/common/composables/useOfflineMode';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
import { useProjectControls } from '@/project/composables/useProjectControls';
import { useProjectStore } from '@/project/stores/project';

const store = useMainStore();
const project = useProjectStore();
const { trans } = useI18n();

const { isLogged, requireAuth } = useAuth();
const { isWebview, webviewVersion } = useDeviceInfo();
const { isEmbeddedContext } = useEditorMode();
const { isOffline } = useOfflineMode();
const { overLimitToDownload } = useProjectControls();

// Enviamos el estado del sync a la app para poder prevenir el goBack solo si es necesario
watch([() => project.allChangesSaved, () => project.syncing], ([newAllChangesSaves, newSyncing]) => {
	// Solo disponible en versión 2 superiores, ya que hacen faltas cambios en la app
	if (isWebview.value && webviewVersion.value >= 2) {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				action: 'sync',
				data: {
					allChangesSaved: newAllChangesSaves,
					syncing: newSyncing,
				},
			})
		);
	}

	// Para informar que la plantilla ha sido guardada en el editor para terceros
	if (isEmbeddedContext.value && newAllChangesSaves) {
		EventTools.emitUrlToIframe({
			eventName: 'autoSave',
			templateId: project.id,
		});
	}
});
</script>

<template>
	<!-- No se ha guardado por no poder hacer login -->
	<div v-if="!isLogged && !isOffline">
		<button
			class="relative flex items-center justify-center gap-2 border-l border-gray-600 pl-4 text-sm text-white opacity-50 hover:opacity-100 lg:h-8"
			tooltip="Save changes"
			tooltip-position="bottom"
			@click="requireAuth()"
		>
			<SvgIcon name="save" class="h-5 w-5 lg:h-6 lg:w-6" />
		</button>
	</div>
	<!-- resto de estados: guardado, guardando y en espera de ser guardado -->
	<div
		v-if="store.userVector && isLogged && !isOffline"
		class="relative ml-2 flex items-center justify-center gap-2 border-l border-gray-600 pl-4 text-sm text-white lg:ml-0 lg:h-8"
	>
		<span v-if="project.allChangesSaved" :tooltip="trans('All your changes saved')" tooltip-position="bottom">
			<SvgIcon name="autosave-ok" class="h-7 w-7 lg:h-8 lg:w-8" />
		</span>
		<span v-else>
			<SvgIcon name="autosave-pending" class="h-7 w-7 lg:h-8 lg:w-8" />
		</span>
		<span v-if="project.allChangesSaved" class="saved hidden text-sm text-gray-300 lg:block">{{ trans('Saved') }}</span>
		<span v-else class="hidden text-sm text-gray-300 lg:block">{{ trans('Saving...') }}</span>
	</div>
</template>
<style lang="sass" scoped>
.saved
	animation: saved 3s forwards

	@keyframes saved
		90%
			@apply opacity-100

		100%
			@apply opacity-0
</style>
