export class ArtboardTools {
	static checkIsNewArtboard = () => {
		const pathNameSplit = window.location.pathname.split('/').filter<string>((a) => !!a);
		let slug = pathNameSplit.length === 0 ? 'new-artboard' : pathNameSplit.pop() || 'new-artboard';

		slug = ['editor', 'slidesgo'].includes(slug) ? 'new-artboard' : slug;

		return slug === 'new-artboard';
	};
}
