<script setup lang="ts">
import { intersection } from 'lodash-es';
import { computed, Ref, ref, toRef, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDebugPanel } from '@/common/composables/useDebugPanel';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import BoxComponent from '@/elements/box/components/BoxComponent.vue';
import Element from '@/elements/element/classes/Element';
import ElementDebug from '@/elements/element/components/debug/ElementDebug.vue';
import { useElementRegardingPage } from '@/elements/element/composables/useElementRegardingPage';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useSmartSelection } from '@/elements/element/composables/useSmartSelection';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import IllustratorComponent from '@/elements/illustrator/components/IllustratorComponent.vue';
import LineComponent from '@/elements/line/components/LineComponent.vue';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import ForegroundComponent from '@/elements/medias/images/foreground/components/ForegroundComponent.vue';
import ImageComponent from '@/elements/medias/images/image/components/ImageComponent.vue';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import FrameVideoComponent from '@/elements/medias/video/components/FrameVideoComponent.vue';
import VideoComponent from '@/elements/medias/video/components/VideoComponent.vue';
import QRCodeComponent from '@/elements/qr-code/components/QRCodeComponent.vue';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import ShapeComponent from '@/elements/shapes/shape/components/ShapeComponent.vue';
import StorysetComponent from '@/elements/storyset/components/StorysetComponent.vue';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import TextComponent from '@/elements/texts/text/components/TextComponent.vue';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { InteractionAction } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

const store = useMainStore();
const { selection, selectionId } = useSelection();

// Props
const props = defineProps<{
	element: Element;
	interactive?: boolean;
	forSmallPreview?: boolean;
	page?: Page;
	previewName?: string;
	scale: number;
}>();

// Data
const el = ref();
const element = toRef(props, 'element');
const page = toRef(props, 'page');
const temporalRef = ref<Element>(Shape.create());

// Using composables

const usingElementTransform = useElementTransformOrchestrator(temporalRef);
const { isOutsidePage } = props.interactive ? useElementRegardingPage(element) : { isOutsidePage: ref(false) };
const { group, isGrouped, isLockGroup } = props.interactive
	? useGroup(element)
	: { group: ref([]), isGrouped: ref(false), isLockGroup: ref(false) };
const { isSelecting, isResizing, action } = useInteractions();
const { photoModeImage } = usePhotoMode();
const { isIllustratorContext, isTagMode, isRenderingContext } = useEditorMode();
const { smartSelection } = useSmartSelection(element);
const { isOutsidePage: groupIsOutside, sizeWithRotation: sizeGroup } = props.interactive
	? useGroupTransform(group)
	: { isOutsidePage: ref(false), sizeWithRotation: ref({ width: 0, height: 0 }) };

// Computeds
const heightElement = computed(() => element.value.size.height);
const isEditable = computed(
	() => props.interactive && !isSelected.value && !isPhotoModeImage.value && element.value.type !== 'illustrator'
);
const isFirstSelection = computed(
	() => props.interactive && !!selectionId.value.length && selectionId.value[0] === element.value.id
);
const isInActivePage = computed(() => store.activePage?.id === props.page?.id);
const isPhotoModeImage = computed(() => photoModeImage.value?.id === element.value.id);
const isRuler = computed(() => isInActivePage.value && !props.previewName && !isSelected.value);
const isSelected = computed(
	() => !isPhotoModeImage.value && !!selection.value.length && selection.value.some((e) => e.id === element.value.id)
);
const shouldVibrate = computed(
	() => props.interactive && (isGrouped.value ? groupIsOutside.value : isOutsidePage.value)
);

const sizeRemove = computed(() => {
	const size = {
		width: element.value.size.width * store.scale,
		height: element.value.size.height * store.scale,
	};
	if (!isFirstSelection.value || !isGrouped.value) {
		return size;
	}

	return sizeGroup;
});

const isTarget = computed(
	() =>
		props.interactive &&
		isInActivePage.value &&
		isSelected.value &&
		!isPhotoModeImage.value &&
		element.value.type !== 'illustrator'
);

const curvedTextInfo = element.value instanceof Text ? useCircleTypeInfo(element as Ref<Text>) : null;

// Observamos la altura del elemento para que al estar agrupado
// se empuje hacia abajo los elementos que correspondan al editar
// un texto

watch(
	heightElement,
	async (newHeight, oldHeight) => {
		if (
			!props.interactive ||
			!group.value.length ||
			!isFirstSelection.value ||
			newHeight === oldHeight ||
			element.value.type !== 'text' ||
			!selection.value.length ||
			isResizing.value
		) {
			return;
		}

		// Asignamos el elemento
		temporalRef.value = element.value;

		// Obtenemos el ancho y la posición X con la rotación aplicada
		const { widthWithRotation: elementWidthWithRotation, elementBoxWithRotation } = usingElementTransform.value;

		const textWithNegativeCurvature =
			curvedTextInfo && curvedTextInfo.isCircleText.value && curvedTextInfo.percentageArc.value < 0;

		const toMove = textWithNegativeCurvature ? oldHeight - newHeight : newHeight - oldHeight;
		const xElementEnd = elementBoxWithRotation.value.left + elementWidthWithRotation.value;
		const rangePositionsElement = MathTools.range(elementBoxWithRotation.value.left, xElementEnd).map((val) =>
			Math.round(val)
		);

		group.value
			.filter((el) => el.id !== element.value.id)
			// Solo elementos por debajo excepto si es texto curvado y tiene curvatura negativa
			.filter((el) => {
				if (textWithNegativeCurvature) return el.position.y < element.value.position.y;
				else return el.position.y > element.value.position.y;
			})
			// Solo elementos que estén dentro de su x - x + width
			.filter((el) => {
				// Asignamos el elemento filtrado
				temporalRef.value = el;

				// Obtenemos el ancho y la posición X con la rotación aplicada
				const { widthWithRotation, elementBoxWithRotation } = usingElementTransform.value;

				const rangePositionEl = MathTools.range(
					elementBoxWithRotation.value.left,
					elementBoxWithRotation.value.left + widthWithRotation.value
				).map((val) => Math.round(val));

				const intersect = intersection(rangePositionsElement, rangePositionEl);

				return intersect.length;
			})
			.forEach((el) => {
				temporalRef.value = el;
				usingElementTransform.value.move(0, toMove);
			});
	},
	{ deep: true }
);

// Methods
const componentType = (element: Element) => {
	if (element.type === 'video' && isRenderingContext && window.renderData.format === 'mp4') {
		return FrameVideoComponent;
	}
	switch (element.type) {
		case 'image': {
			return ImageComponent;
		}
		case 'foregroundImage': {
			return ForegroundComponent;
		}
		case 'shape': {
			return ShapeComponent;
		}
		case 'box': {
			return BoxComponent;
		}
		case 'storyset': {
			return StorysetComponent;
		}
		case 'text': {
			return TextComponent;
		}
		case 'line': {
			return LineComponent;
		}
		case 'illustrator': {
			return IllustratorComponent;
		}
		case 'qrcode': {
			return QRCodeComponent;
		}
		case 'video': {
			return VideoComponent;
		}
	}
};

const illustratorSelectionStyle = computed(() => {
	if (!isIllustratorContext.value) return '';
	const strokeWidth = 3 / store.scale;
	return `
	.tree-element-selection {
		stroke: currentColor !important;
		stroke-width:${strokeWidth};
		color: rgb(255 0 0 / 1) !important;
	}
	`;
});
const { isDebugPanel } = useEditorMode();
const { isElementDebugVisible } = useDebugPanel();
</script>

<template>
	<div
		:id="previewName ? `element-${element.id}-preview-${previewName}` : `element-${element.id}`"
		ref="el"
		:data-test-group="element.group"
		data-testid="editable-element"
		:class="{
			// Solo queremos esta clase si está en el activeCanvas y no es la preview, para evitar líneas mágneticas fuera de
			// su propia Page
			target: isTarget,
			editable: isEditable,
			ruler: isRuler,
			'ring-custom-tag': isTagMode && element.tags.length,
			'ring-blue-500 slidesgo:ring-purple-400': interactive && !isSelected,
			'ring-custom': interactive && !isSelecting && !isSelected,
			locked: element.locked,
			// Se muestra cuando es una selección temporal (grupo no bloqueado) o cuando es grupo bloqueado y es el primer elemento
			'ring-custom-select':
				interactive &&
				!element.locked &&
				isSelected &&
				((isGrouped && !isLockGroup) || (isLockGroup && isFirstSelection)),
			'main-image': store.croppingId === element.id && !forSmallPreview,
			'pointer-events-none': (smartSelection && !isSelected) || element.type === 'foregroundImage',
			'will-change-transform': !isRenderingContext && interactive && isSelected && action !== InteractionAction.Idle,
		}"
		class="element absolute left-0 top-0"
		:style="{
			transform: `
        translate(${element.position.x}px,${element.position.y}px)
        rotate(${element.rotation}deg)
      `,
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
		}"
	>
		<ElementDebug
			v-if="isDebugPanel && isElementDebugVisible && interactive"
			:element="element"
			:page="page"
			:scale="scale"
		/>
		<div
			class="flex h-full w-full"
			:style="{
				transform: `
        scaleX(${element.flipHTML.x})
        scaleY(${element.flipHTML.y})
      `,
			}"
		>
			<component :is="'style'" v-if="illustratorSelectionStyle" type="text/css">
				{{ illustratorSelectionStyle }}
			</component>

			<component
				:is="componentType(element)"
				:key="element.id"
				:smart-selection="smartSelection"
				:element="element"
				:interactive="interactive"
				:for-small-preview="props.forSmallPreview"
				v-bind="props.previewName && element instanceof ForegroundImage ? { previewPage: props.page } : null"
				:preview-name="previewName"
				:scale="props.scale"
			/>
		</div>
		<Teleport v-if="interactive && isFirstSelection && (isGrouped ? groupIsOutside : isOutsidePage)" to="#portalTarget">
			<div
				class="flex items-center justify-center"
				:style="{
					width: `${sizeRemove.width}px`,
					height: `${sizeRemove.height}px`,
				}"
			>
				<div
					v-if="!element.locked"
					data-test-svg-icon="trash"
					class="outside flex h-7 w-7 items-center justify-center rounded-full text-white"
					:class="{
						'bg-red-500': isOutsidePage,
						vibrate: shouldVibrate,
					}"
				>
					<SvgIcon name="trash" class="h-2/4 w-2/4" />
				</div>
			</div>
		</Teleport>
	</div>
</template>
<style lang="css">
.ring-custom-select {
	box-shadow: 0 0 0 var(--zoom-ring) rgb(18 115 235);
}

.slidesgo-mode .ring-custom-select {
	box-shadow: 0 0 0 var(--zoom-ring) rgb(95 95 246);
}

.ring-custom-tag {
	box-shadow: 0 0 0 var(--zoom-ring) #eab308;
}

@media screen(lg) {
	.ring-custom:hover {
		box-shadow: 0 0 0 var(--zoom-ring) rgb(18 115 235);
	}
	.ring-custom.locked:hover {
		box-shadow: 0 0 0 var(--zoom-ring) rgb(220 220 220);
	}

	.slidesgo-mode .ring-custom:hover {
		box-shadow: 0 0 0 var(--zoom-ring) rgb(95 95 246);
	}
}

.vibrate {
	animation: vibrate 1.5s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-22 10:16:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@keyframes vibrate {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}

.delete {
	animation: delete 0.4s ease-in both;
}

@keyframes delete {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 #f99696;
		opacity: 1;
	}
	100% {
		transform: scale(1.3);
		box-shadow: 0 0 0 8px #f99696;
		opacity: 0;
	}
}

.move {
	animation: move 0.4s ease-in both;
}

@keyframes move {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 #96a5f9;
		opacity: 1;
	}
	100% {
		transform: scale(1.3);
		box-shadow: 0 0 0 8px #96a5f9;
		opacity: 0;
	}
}
</style>
