<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { computed, ref } from 'vue';

import { getImages } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import ListMedia from '@/elements/medias/images/image/components/ListMedia.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useProjectStore } from '@/project/stores/project';
import { VideoApi } from '@/Types/apiClient';
import { DraggableItemData, Panels } from '@/Types/types';

// Props
const props = withDefaults(
	defineProps<{
		search: string;
		modal?: boolean;
	}>(),
	{
		search: '',
		modal: false,
	}
);

// Emits
const emit = defineEmits<{
	(e: 'select', value: VideoApi): void;
	(e: 'zeroResults', value: boolean): void;
}>();

// Using composables
const project = useProjectStore();

// Computeds
const search = computed(() => props.search || project.flaticonSearch || project.category || '');

// Data
const source = ref(`videos/freepik?query=${search.value}`);
const { data: videos, isFetching } = getImages(source, { refetch: true });

watchDebounced(
	videos,
	(newVal) => {
		if (newVal?.data?.length === 0) {
			emit('zeroResults', true);
			return;
		}

		emit('zeroResults', false);
	},
	{ debounce: 500 }
);

// Watches
watchDebounced(
	search,
	(newVal) => {
		source.value = `videos/freepik?query=${newVal}`;
	},
	{ debounce: 500 }
);

// Methods
const loadMore = () => {
	if (isFetching.value || !videos.value?.links?.next) return;
	source.value = videos.value.links.next;
};
</script>

<template>
	<InfiniteLoading
		:data="(videos && videos.data) || []"
		:is-fetching="isFetching"
		:modal="modal"
		class="flex flex-col"
		with-masonry
		:masonry-cols="2"
		@load="loadMore"
	>
		<template #item="{ item }">
			<InsertableElement
				:data="(item as DraggableItemData)"
				:type="Panels.videos"
				:draggable="!props.modal"
				class="relative mb-2"
				@click="emit('select', item as VideoApi)"
			>
				<ListMedia :image="(item as VideoApi)">
					<div
						v-if="(item as VideoApi).duration"
						class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/75 px-2 pb-1 pt-4 text-right text-xs font-semibold text-white opacity-0 group-hover:opacity-100"
					>
						{{ (item as VideoApi).duration }}
					</div>
				</ListMedia>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
