<script lang="ts" setup>
import { computed, ref } from 'vue';

import Modal from '@/common/components/Modal.vue';
import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useI18n } from '@/i18n/useI18n';
import OnClickOutsideWithPortals from '@/interactions/components/OnClickOutsideWithPortals';

type option = { label: string | number; value: string | number };

// Props
const props = defineProps<{
	options: option[];
	selected?: number | string | option;
	hideOptions?: boolean | undefined;
	useLightMode?: boolean;
	disabled?: boolean;
}>();

// Data
const visible = ref(false);

// Emits
const emit = defineEmits(['update']);

// Computeds
const isMix = computed(() => Array.isArray(props.selected) && props.selected.length > 1);

const label = computed(() => {
	if (isMix.value) return 'Mix';
	if (props.selected && typeof props.selected === 'object') return props.selected.label;
	if (!props.selected || !props.selected.toString().length) return 'Select an option...';

	return props.selected;
});
const { trans } = useI18n();

// Methods
const onSelectOption = (option: option) => {
	emit('update', option);
	visible.value = false;
};

const onToggleVisibility = () => {
	visible.value = !visible.value;
};

const { isMobile } = useDeviceInfo();
</script>

<template>
	<OnClickOutsideWithPortals class="relative" @trigger="visible = false">
		<slot :on-toggle-visibility="onToggleVisibility" :value="label">
			<button
				:data-testid="label"
				class="relative flex h-10 w-full items-center rounded pl-3 pr-4 text-sm focus:outline-none"
				:class="{
					'bg-white text-gray-600 hover:text-gray-500': useLightMode,
					'bg-gray-900 text-gray-100 hover:text-gray-700 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:hover:text-white':
						!useLightMode,
					'cursor-not-allowed opacity-50': disabled,
					'cursor-pointer': !disabled,
				}"
				@click.stop="onToggleVisibility"
			>
				{{ trans(label) }}
				<SvgIcon name="arrow" class="absolute right-3 top-3 h-4 w-4 fill-current" />
			</button>
		</slot>

		<!-- Dropdown-->
		<Popper v-if="!hideOptions && visible && !isMobile" placement="bottom-start" class="z-20 w-full min-w-min">
			<div
				class="mt-1 max-h-64 w-full overflow-auto rounded bg-white py-1 pb-1 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
			>
				<button
					v-for="opt1 in options"
					:key="opt1.value"
					data-testid="dropdown-option"
					class="w-full px-3 py-1.5 text-sm hover:bg-gray-100/10 hover:text-gray-800"
					:class="[selected].includes(opt1.value) ? 'bg-gray-100/10 font-bold text-gray-800' : 'text-gray-500'"
					@click.stop.prevent="onSelectOption(opt1)"
				>
					<span class="flex items-baseline whitespace-nowrap">
						{{ opt1.label }}
					</span>
				</button>
			</div>
		</Popper>

		<Modal v-if="visible && isMobile && !hideOptions" to="body" open>
			<div class="my-auto flex w-64 flex-col items-center rounded-lg bg-gray-800 py-2 text-white">
				<slot name="title"></slot>
				<div
					class="max-h-[33vh] w-full overflow-y-scroll rounded bg-gray-800 p-2 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
				>
					<button
						v-for="opt2 in options"
						:key="opt2.value"
						data-testid="dropdown-option"
						class="w-full px-3 py-2 text-sm hover:bg-gray-800 hover:text-white"
						:class="[selected].includes(opt2.value) ? 'bg-gray-800 text-white' : 'text-gray-100'"
						@click.stop.prevent="onSelectOption(opt2)"
					>
						<span class="flex items-baseline justify-center whitespace-nowrap">
							{{ opt2.label }}
						</span>
					</button>
				</div>
			</div>
		</Modal>
	</OnClickOutsideWithPortals>
</template>
