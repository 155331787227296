<script lang="ts" setup>
import { Combobox, ComboboxButton, ComboboxInput } from '@headlessui/vue';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import ElementsTypesPanel from '@/editor/components/ElementsTypesPanel.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import SearchAllPanel from '@/editor/components/SearchAllPanel.vue';
import { useMainStore } from '@/editor/stores/store';
import LinesAndArrowsPanel from '@/elements/line/components/panels/add/LinesAndArrowsPanel.vue';
import MasksPanel from '@/elements/medias/mask/components/panels/add/MasksPanel.vue';
import FlaticonPanel from '@/elements/shapes/flaticon/components/panels/add/FlaticonPanel.vue';
import BasicShapesPanel from '@/elements/shapes/shape/components/panels/add/BasicShapesPanel.vue';
import StickersPanel from '@/elements/shapes/sticker/components/panels/add/StickersPanel.vue';
import StorysetPanel from '@/elements/storyset/components/panels/add/StorysetPanel.vue';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { ElementCategory, ElementsPanelTypes } from '@/Types/types';
import StringTools from '@/utils/classes/StringTools';

const store = useMainStore();
const project = useProjectStore();

interface InputFileEvent extends Event {
	target: HTMLInputElement;
}

const { trans } = useI18n();
const { runOnMobile } = useDeviceInfo();

const activeElementCategory = ref<ElementCategory | null>(null);

const query = computed(() => project.flaticonSearch || project.category || activeElementCategory.value?.name || '');

// Used to notify children components that Back button was cliked
const goBackToggle = ref(false);
const searchAllQuery = ref<string>('');

const elementTypeComponent = computed(() => {
	if (store.activeElementType === ElementsPanelTypes.Flaticon) return FlaticonPanel;
	if (store.activeElementType === ElementsPanelTypes.Storysets) return StorysetPanel;
	if (store.activeElementType === ElementsPanelTypes.Stickers) return StickersPanel;
	if (store.activeElementType === ElementsPanelTypes.BasicShapes) return BasicShapesPanel;
	if (store.activeElementType === ElementsPanelTypes.ImageMasks) return MasksPanel;
	if (store.activeElementType === ElementsPanelTypes.LinesAndArrows) return LinesAndArrowsPanel;

	return null;
});
const dynamicProps = computed(() => {
	// En caso de estar en el panel de stickers se le pasa un objeto, que será la prop al componente, y de no ser así se le pasa null
	// esto hace que el paso de la prop sea opcional usando el v-bind sobre los componentes dinámicos
	if (store.activeElementType === ElementsPanelTypes.Stickers) {
		return { goBackToggle: goBackToggle.value };
	}
	if (store.activeElementType === ElementsPanelTypes.Flaticon) {
		return { query: query.value };
	}
	return null;
});

const title = computed(() => {
	if (!store.activeElementType) return 'Elements';

	if (activeElementCategory.value) {
		return activeElementCategory.value?.name;
	}

	return StringTools.getAllWordsCapitalized(store.activeElementType);
});

const onGoBack = () => {
	goBackToggle.value = !goBackToggle.value;

	if (searchAllQuery.value.length) {
		searchAllQuery.value = '';
		return;
	}

	// Stickers goBack flow is managed in StickersPanel
	if (store.activeElementType === ElementsPanelTypes.Stickers) return;

	store.activeElementType = null;
};

const onChangeSearchElement = (e: InputFileEvent) => {
	searchAllQuery.value = e.target.value;
};

const onElementAdded = () => {
	runOnMobile(() => (store.activePanel = null));
};

const onZeroResults = (val: boolean) => {
	GAnalytics.trackGA4('search_editor', {
		category: 'elements',
		search_term: `${searchAllQuery.value}`,
		zero_results: `${val}`,
	});
};
</script>
<template>
	<div class="flex h-full flex-col">
		<PanelHeader :title="title" :show-back-button="!!store.activeElementType" @goBack="onGoBack" />

		<Combobox v-if="!store.activeElementType" v-model="searchAllQuery">
			<div
				class="relative mb-2 w-full shrink-0 cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
			>
				<ComboboxInput
					class="flex h-10 w-full appearance-none rounded-full border border-transparent bg-gray-900 pl-4 pr-10 text-sm text-gray-100 placeholder-gray-300/50 shadow-none focus:border-gray-700 focus:outline-none lg:rounded"
					:placeholder="trans(query ? query : 'Search element...')"
					@change="onChangeSearchElement"
				/>
				<ComboboxButton class="absolute inset-y-0 right-0 flex w-10 items-center justify-center">
					<SvgIcon name="search" class="h-4 w-4 text-gray-300" />
				</ComboboxButton>
			</div>
		</Combobox>

		<SearchAllPanel v-if="!!searchAllQuery" :query="searchAllQuery" @zero-results="onZeroResults" />

		<component
			:is="elementTypeComponent"
			v-else-if="elementTypeComponent"
			v-bind="dynamicProps"
			@elementAdded="onElementAdded"
		/>

		<ElementsTypesPanel v-else :active-element-category="activeElementCategory" />
	</div>
</template>
