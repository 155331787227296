<script setup lang="ts">
import { computed, onMounted, Ref, ref, toRef, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import { useForegroundRendering } from '@/elements/medias/images/foreground/composables/useForegroundRendering';
import { useSyncForeground } from '@/elements/medias/images/foreground/composables/useSyncForeground';
import Page from '@/page/classes/Page';

const store = useMainStore();

const props = defineProps<{
	element: ForegroundImage;
	forSmallPreview?: boolean;
	previewName?: string;
	previewPage?: Page;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
}>();
const element = toRef(props, 'element');
const previewPage = (toRef(props, 'previewPage') as Ref<Page> | undefined) || undefined;
const image = ref();
const { isRenderingContext } = useEditorMode();
const { loaded, load } = useForegroundRendering(element, image);

load();

const hasSvgFilter = computed(() => element.value.filter && element.value.filter.toSvgFilter().length > 0);

const { imageBackground } = useForeground(element, previewPage);
const { watchSourceImage, syncPositionWithBackground, syncSizeWithBackground } = useSyncForeground(
	element,
	previewPage,
	props.previewName
);

onMounted(() => {
	watchSourceImage();
});

const opacity = computed(() => {
	return imageBackground.value?.opacity === 0 ? 0 : element.value.opacity;
});

const filterId = computed(() => {
	return `filter-${element.value.id}-${(element.value.filter as Filter).name}`;
});

watch(loaded, async () => {
	// Si es una preview y ha terminado de cargar la imagen, hacemos un sync de la posición y el size
	if (loaded.value && previewPage?.value) {
		syncSizeWithBackground();
		await syncPositionWithBackground();
	}
});
</script>

<template>
	<span
		v-if="store.lastBackgroundRemoved === imageBackground?.id && loaded"
		class="animate-layers pointer-events-none absolute inset-0 m-auto inline-flex aspect-square w-full scale-0 rounded-full bg-white"
	></span>
	<div
		:class="previewName ? `original-${previewName}` : 'original'"
		:style="{
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
			clipPath: 'inset(0)',
			opacity,
		}"
	>
		<div
			:class="{
				'animate-layers-foreground': store.lastBackgroundRemoved === imageBackground?.id && loaded,
				original: !previewName,
				'original-nav': previewName,
			}"
			:style="({
				contain: 'strict',
				width: `${element.size.width}px`,
				height: `${element.size.height}px`,
				transform: `
        	scaleX(${element.flipHTML.x})
        	scaleY(${element.flipHTML.y})
				`
			} as any)"
		>
			<div
				:class="previewName ? `original-${previewName}` : 'cropped'"
				:style="{
					width: `${element.crop.size.width || element.size.width}px`,
					height: `${element.crop.size.height || element.size.height}px`,
					transform: `translate(${element.crop.position.x}px,${element.crop.position.y}px)`,
				}"
			>
				<!-- La versión en baja resolución mientras carga el resto -->
				<img
					v-if="props.element.preview && !isRenderingContext && !props.previewName && !loaded"
					:src="props.element.preview"
					class="pointer-events-none absolute h-full w-full"
					data-preview
					alt=""
					:style="({
						filter: hasSvgFilter ? `url(#${filterId})` : null,
						transform: `
							scaleX(${element.flipHTML.x})
							scaleY(${element.flipHTML.y})
						`,
					} as any)"
				/>

				<img
					ref="image"
					data-final-image
					data-foreground
					:src="element.urlBackgroundRemoved || ''"
					class="absolute h-full w-full"
					alt=""
					:style="({
						filter: hasSvgFilter ? `url(#${filterId})` : null,
						transform: `
							scaleX(${element.flipHTML.x})
							scaleY(${element.flipHTML.y})
						`,
					} as any)"
					@dragstart.prevent
					@dblclick="store.croppingId = element.id"
				/>
				<svg v-if="hasSvgFilter" width="0" height="0">
					<defs>
						<filter :id="filterId" v-html="(element.filter as Filter).toSvgFilter()"></filter>
					</defs>
				</svg>
			</div>
		</div>
	</div>
</template>

<style>
.animate-layers-foreground {
	animation: layers-foreground 1s linear forwards;
	animation-delay: 0.2s;
	transform-origin: 50% 50%;
}
@keyframes layers-foreground {
	0% {
		filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
	}
	50% {
		filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2)) brightness(1.1);
	}
	99% {
		filter: drop-shadow(0 0 0px rgba(0, 0, 0, 0.2));
	}
	100% {
	}
}

.animate-layers {
	display: block;
	animation: layers 1s ease-out;
}
@keyframes layers {
	0% {
		transform: scale(0);
		filter: blur(0);
		opacity: 0.5;
	}
	100% {
		transform: scale(2);
		filter: blur(10px);
		opacity: 0;
	}
}
</style>
