<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import { duplicateVectorFolder } from '@/api/UserApiClient';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useI18n } from '@/i18n/useI18n';
import { TemplateApiData } from '@/Types/apiClient';

const { trans } = useI18n();

const props = defineProps<{ selectedTemplate: TemplateApiData | null }>();

const emit = defineEmits(['close', 'duplicated']);

const toast = useToast();

const duplicating = ref(false);

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Duplicate creation modal is closed`);
};

const duplicateTemplate = async () => {
	if (!props.selectedTemplate?.uuid || duplicating.value) return;

	duplicating.value = true;

	const { error } = await duplicateVectorFolder(props.selectedTemplate.uuid);

	duplicating.value = false;

	if (error.value) {
		toast.error(trans('The template has not been duplicated'));
		return;
	}

	toast.success(trans('Duplicated template'));

	emit('duplicated');
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative my-auto w-full max-w-lg rounded bg-white py-8 px-8">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<div>
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">{{ trans('We need confirmation!') }}</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					{{ trans('Are you sure you want to duplicate this template?') }}
				</p>

				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
						:disabled="duplicating"
						@click.stop="onClickClose"
					>
						{{ trans('Cancel') }}
					</button>

					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-8 text-lg font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
						:disabled="duplicating"
						@click.stop="duplicateTemplate"
					>
						<SvgIcon v-if="duplicating" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans('Duplicate') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
