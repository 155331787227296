import { OnPinch } from 'gesto';
import { OnResize } from 'moveable';

import Element from '@/elements/element/classes/Element';
import { IframeAutoSaveEvent, IframePublishEvent, Position } from '@/Types/types';

class EventTools {
	static getEventPositionInElement(element: HTMLElement, event: MouseEvent | OnPinch): Position | undefined {
		if (!event || event.type === 'keydown') return;

		const rbox = element.getBoundingClientRect();
		const clientX = event.clientX;
		const clientY = event.clientY;

		const x = clientX - rbox.left - element.clientLeft;
		const y = clientY - rbox.top - element.clientTop;

		return {
			x,
			y,
		};
	}

	static getErrorMessage(error: unknown) {
		if (error instanceof Error) return error.message;
		return String(error);
	}

	static getElementByEvent(elements: Element[], event: OnResize) {
		return elements.find((el: Element) => el.id === event.target.id.replace('element-', ''));
	}

	static isCornerEvent(ev: OnResize): boolean {
		// Si las dos direcciones del evento son distintas de 0 signfica que se está moviendo tanto en x o como en y
		// y por lo tanto el evento proviene de uno de los handlers de las esquinas
		return ev.direction[0] !== 0 && ev.direction[1] !== 0;
	}

	static sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	static idleCallback() {
		const requestIdleCallback = window.requestIdleCallback || window.requestAnimationFrame;
		return new Promise((resolve) => requestIdleCallback(resolve));
	}

	static purifyClipboard(string: string) {
		if (string.includes('<svg') && string.includes('</svg>')) {
			string = string.substring(string.indexOf('<svg'), string.indexOf('</svg>') + 6);
		}

		return string.trim();
	}

	static emitUrlToIframe(data: IframeAutoSaveEvent | IframePublishEvent) {
		window.parent.postMessage(data, '*');
	}
}

export default EventTools;
