<script setup lang="ts">
import { toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Box } from '@/elements/box/classes/Box';
import { useRounding } from '@/elements/box/composables/useRounding';
import MathTools from '@/utils/classes/MathTools';

// Props
const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');
const { closePanel } = usePanelManagement();

// Using composable
const { t: trans } = useI18n();

const { percentCalculated, setRadiusByPercent, resetRadius } = useRounding(element);
const { isMobile } = useDeviceInfo();

// Data
const INPUT_CONFIG = {
	MAX: 100,
	MIN: 0,
	STEP: 1,
	TYPE: 'range',
};

// Methods
const onInput = (value: number, cornerIndex?: number) => {
	const newPercent = MathTools.clamp(value, INPUT_CONFIG.MIN, INPUT_CONFIG.MAX);
	//  TODO: revisar en  implementación de tablas

	// ! si especificamos el index actúa sobre la esquina, si no afecta sobre todas las esquinas
	setRadiusByPercent(newPercent, cornerIndex);
};

const onReset = () => {
	//  TODO: revisar en  implementación de tablas
	resetRadius();
	closePanel(props.element);
};
</script>

<template>
	<div
		class="mb-1 w-screen rounded-tl-xl rounded-tr-xl bg-gray-700/90 px-3 pb-4 pt-4 backdrop-blur lg:mb-2 lg:w-48 lg:rounded lg:bg-white lg:py-2 lg:pb-2 lg:shadow-xl"
	>
		<div v-if="isMobile" class="flex h-12 w-screen justify-between rounded-tl-xl bg-gray-700/90">
			<div class="-mt-4 flex items-center p-1">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Corner rounding') }}</h2>
				<button
					class="flex items-center text-xs text-gray-100 hover:text-white"
					:class="{ 'pointer-events-none opacity-50': !element.border.radius }"
					@click="onReset"
				>
					<SvgIcon name="reload" class="mr-2 h-3 w-3"></SvgIcon>
					{{ trans('Reset') }}
				</button>
			</div>
			<button
				class="mr-6 h-6 rounded-full bg-gray-800/50 p-1 text-xs text-gray-100 hover:text-white"
				@click="closePanel(element)"
			>
				<SvgIcon name="cross" class="h-3 w-4"></SvgIcon>
			</button>
		</div>

		<p class="hidden text-sm text-gray-500 lg:block">{{ trans('Corner rounding') }}</p>
		<div class="flex flex-1 items-center">
			<div class="input-range relative flex w-full">
				<input
					data-testid="input-corner-radius"
					:min="INPUT_CONFIG.MIN"
					:max="INPUT_CONFIG.MAX"
					:step="INPUT_CONFIG.STEP"
					:type="INPUT_CONFIG.TYPE"
					class="input-range h-1 w-full cursor-pointer appearance-none rounded-full bg-gray-300 focus:outline-none lg:bg-gray-100/50"
					:value="percentCalculated"
					@input="onInput(parseInt(($event.target as HTMLInputElement).value))"
				/>
				<span
					data-testid="roandingInput"
					:style="{
						width: `${percentCalculated}%`,
					}"
					class="absolute left-0 top-0 h-1 rounded-full bg-blue-500 slidesgo:bg-purple-400"
				></span>
			</div>
			<div
				data-testid="radius-box"
				class="min-w-14 ml-2 whitespace-nowrap text-right text-sm text-gray-100 lg:text-gray-600"
			>
				{{ percentCalculated }} %
			</div>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.input-range::-webkit-slider-thumb:hover
	@apply bg-blue-600
</style>
