<script lang="ts" setup>
import { computed, Ref, toRef } from 'vue';

import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import SeparateLayersButton from '@/elements/medias/images/image/components/buttons/SeparateLayersButtonToolbar.vue';
import LayerSelectorToolbar from '@/elements/medias/images/image/components/toolbars/LayerSelectorToolbar.vue';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';

const props = defineProps<{ element: BaseImage; visibility: boolean }>();
const element = toRef(props, 'element');

const { hasForeground } = useLayersImage(element as Ref<Image>);

const isImage = computed(() => element.value instanceof Image && !hasForeground.value);
const isBackground = computed(() => element.value instanceof Image && hasForeground.value);
const isForeground = computed(() => element.value instanceof ForegroundImage);
const { checkIsPhotoModeImage } = usePhotoMode();

const isPlaceholder = isImage.value && element.value.url.includes('svg/mask-placeholder.svg');
</script>

<template>
	<SeparateLayersButton
		v-if="visibility && isImage && !checkIsPhotoModeImage(element) && !isPlaceholder"
		:key="element.id"
		:element="element as Image"
	/>
	<LayerSelectorToolbar
		v-if="((visibility || checkIsPhotoModeImage(element)) && isBackground) || isForeground"
		:key="element.id"
		:element="element"
	/>
</template>

<style scoped></style>
