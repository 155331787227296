import { createEventHook, createSharedComposable, useEventListener, useMutationObserver } from '@vueuse/core';
import { Ref, ref } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import { useMainStore } from '@/editor/stores/store';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import { useTextTransform } from '@/elements/texts/text/composables/useTextTransform';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useActivePage } from '@/page/composables/useActivePage';
import { Position } from '@/Types/types';

export const useTextEditing = createSharedComposable(() => {
	const editable = ref();
	const textEditing = ref();
	const mouseSelectionFlag = ref(false);
	const temporalRef = ref(Text.create());
	const finishEditing = createEventHook();
	const onFinishEditingText = finishEditing.on;
	const { isPartiallyOutside } = useTextTransform(temporalRef as Ref<Text>);
	const tempText = ref(Text.create());
	const { isGrouped, group } = useGroup(tempText);
	const { groupIsPartiallyOutside } = useGroupTransform(group);
	const { bugsnagMsgWithDebounce } = useBugsnag();
	const { page } = usePageElement(textEditing);
	const { removeElement } = useActivePage();
	const store = useMainStore();
	const { previousInputSelection, selection } = useTextSelection();
	const textEditingContent = ref('');

	const updateTextContent = () => {
		editable.value = document.querySelector(`#editable-${textEditing.value ? textEditing.value.id : undefined}`);

		if (!textEditing.value || !editable.value) return;

		textEditing.value.updateContent(editable.value.innerHTML);
	};

	const initListeners = () => {
		// Mutation para actualizar el elemento outlined
		useMutationObserver(
			editable,
			(e: MutationRecord[]) => {
				const el = document.querySelector('.editable-outlined-text');

				if (e.some((mutation) => mutation.type === 'attributes' && mutation.attributeName === 'style')) {
					textEditingContent.value = editable.value.innerHTML;
				}

				if (el) {
					el.innerHTML = TextTools.fixTextStrokeColorStyles(editable.value.innerHTML);
					updateBox();
				}
			},
			{
				childList: true,
				subtree: true,
				characterData: true,
				attributeFilter: ['style'],
			}
		);

		useEventListener(editable.value, 'copy', async (e) => {
			const text = selection.value?.selection?.getRangeAt(0).toString();

			if (text?.length) {
				await window.navigator.clipboard.writeText(text);
			}
		});

		// En caso de estar seleccionando parte del texto, deshabilitamos los handlers
		useEventListener(editable.value, 'mousedown', async (e) => {
			if (mouseSelectionFlag.value) return;

			// Si el ratón se pulsa dentro del los límites del elemento, deshabilitamos los handlers
			mouseSelectionFlag.value = true;
			document.querySelectorAll<HTMLElement>('.moveable-control').forEach((el) => (el.style.pointerEvents = 'none'));
		});

		const canvas = editable.value.closest('[id^="canvas-"]');

		// Si levantamos el ratón, rehabilitamos los handlers
		useEventListener(canvas, 'mouseup', async (e) => {
			if (!mouseSelectionFlag.value) return;

			document.querySelectorAll<HTMLElement>('.moveable-control').forEach((el) => (el.style.pointerEvents = 'auto'));
			mouseSelectionFlag.value = false;
		});

		useEventListener(editable.value, 'paste', async (e) => {
			e.preventDefault();
			const dataList = e.clipboardData?.items;
			let finalString = '';
			// Si tenemos uno o varios tipos de texto, damos prioridad al HTML
			if (dataList && dataList.length > 1 && Array.from(dataList).find((el) => el.type === 'text/html')) {
				const el = Array.from(dataList).find((el) => el.type === 'text/html');

				if (el) {
					await el.getAsString((str) => {
						if (str.length) {
							const div = document.createElement('div');
							div.innerHTML = str;
							finalString = Array.from(div.children)
								.map((c) => {
									return c.textContent;
								})
								.filter((content) => !!content)
								.join('');

							if (finalString.length) {
								document.execCommand('insertText', false, finalString);

								temporalRef.value = textEditing.value;
							}
						}
					});
				}
				// Si no tenemos HTML, buscamos el texto plano
			} else if (dataList && Array.from(dataList).find((el) => el.type === 'text/plain')) {
				const wholeTextSelected = TextSelectionTools.detectFullRange(
					selection.value?.selection as Selection,
					editable.value
				);

				const plainText = e.clipboardData.getData('text');
				//  si lo que tenemos en el clipboard es un element o iframe, salimos
				const contentCantBePasted = plainText.startsWith('wepik|') || plainText.includes('<iframe');
				// Si no tenemos texto plano o no puede ser pegado por ser un elemento o un iframe, salimos
				if (!plainText.length || contentCantBePasted) {
					return;
				}

				// Si tenemos todo el texto seleccionado, lo reemplazamos por completo
				if (wholeTextSelected && !selection.value?.selection?.isCollapsed) {
					editable.value.innerHTML = plainText;
					return;
				}

				document.execCommand('insertText', false, plainText);
			}
		});
	};

	const updateBox = () => {
		bugsnagMsgWithDebounce(`Typing in ${editable.value.id}: ${editable.value.textContent}`);
		temporalRef.value = textEditing.value;

		requestAnimationFrame(() => {
			tempText.value = textEditing.value;

			if (!tempText.value || !temporalRef.value) {
				return;
			}

			// En caso de que la altura de la caja de texto sobrepase los límites del canvas, fixeamos el scroll
			const isOutSide = isGrouped.value ? groupIsPartiallyOutside.value : isPartiallyOutside.value;
			if (isOutSide) {
				fixScrollOnTyping();
			}
		});
	};

	const fixScrollOnTyping = () => {
		// los navegadores tratan de hacer scroll para que sea visible el texto que editamos...
		// asi que tras actualizar la caja, forzamos el scroll hacia arriba y hacia la izquierda del navegador
		const node = page.value?.domNode();
		if (!node) return;
		const itemsContainer = node.querySelector('[data-elements-container]') as HTMLElement;
		const toolbar = document.querySelector('.toolbar') as HTMLElement;
		const matrix = new DOMMatrix(getComputedStyle(toolbar).transform) as DOMMatrix;
		const toolbarPosX = matrix.m41 as number;
		const TOOLBAR_OFFSET = 12;
		const editableElement = textEditing.value.domNode().getBoundingClientRect();

		// Corregimos la posición del toolbar antes de forzar la corrección del scroll para evitar el flickering, tomando como referencia el texto editable
		let toolbarPosYFixed = Math.trunc(
			editableElement.y -
				toolbar.getBoundingClientRect().height -
				parseInt(getComputedStyle(toolbar).marginBottom) -
				TOOLBAR_OFFSET
		);
		//  en caso de que se detecte valor de scroll en el eje Y , añadimos el desfase que este provoca
		toolbarPosYFixed = Math.trunc(toolbarPosYFixed + itemsContainer.scrollTop * store.scale);

		toolbar.style.transform = `translate(${toolbarPosX}px, ${toolbarPosYFixed}px`;
		node.scrollTop = 0;
		itemsContainer.scrollTop = 0;
		node.scrollLeft = 0;
		itemsContainer.scrollLeft = 0;

		requestAnimationFrame(() => {
			toolbar.style.transform = `translate(${toolbarPosX}px, ${toolbarPosYFixed}px`;
			node.scrollTop = 0;
			itemsContainer.scrollTop = 0;
			node.scrollLeft = 0;
			itemsContainer.scrollLeft = 0;
		});
	};

	const handleBlur = (mouse: Position, forceClose = false) => {
		if (!textEditing.value) return;

		const elementUnderMouse = document.elementFromPoint(mouse.x, mouse.y);
		const fontPicker = elementUnderMouse?.closest('[data-font-picker]');
		const textInput = elementUnderMouse?.closest('[data-text-input]');

		// Si es un elemento input o fontPicker guardamos la selección previa
		if (fontPicker || textInput) {
			if (!selection.value?.selection) return;

			const { anchorNode, anchorOffset, focusNode, focusOffset, isCollapsed } = selection.value.selection;

			if (anchorNode && focusNode) {
				const temporalSel = selection.value?.selection;
				const isWholeTextSelected = TextSelectionTools.detectFullRange(temporalSel, editable.value);

				// Si tenemos todo el texto seleccionado, lo reemplazamos por completo para limpiar la selección
				if (isWholeTextSelected) {
					temporalSel.removeAllRanges();

					const range = document.createRange();
					range.selectNodeContents(editable.value);
					temporalSel.addRange(range);

					previousInputSelection.value = {
						anchorNode: editable.value,
						anchorOffset,
						focusNode: editable.value,
						focusOffset,
						isCollapsed,
						wholeTextSelected: isWholeTextSelected,
					};

					// Eliminamos la selección para que el texto no se quede con el foco
					temporalSel.removeAllRanges();

					return;
				}

				// Guardamos la selección previa si no es una selección de todo el texto
				if (anchorNode.parentElement?.closest(`[id$='${textEditing.value?.id}']`)) {
					previousInputSelection.value = {
						anchorNode,
						anchorOffset,
						focusNode,
						focusOffset,
						isCollapsed,
						wholeTextSelected: isWholeTextSelected,
					};
				}

				return;
			}
		}

		const keepTextSelection = elementUnderMouse?.closest('[data-keep-text-selection]');

		if (!textInput && !fontPicker && keepTextSelection && !forceClose) {
			// si el blur se va a un componente que permite mantener la seleccion
			// guardamos la seleccion y dejamos en modo edicion
			const editingText = document.querySelector<HTMLElement>(`#editable-${textEditing.value.id}`);
			editingText && editingText.focus();
			return;
		}

		previousInputSelection.value = null;
		updateTextContent();

		handleContent(mouse);
		finishEditing.trigger(textEditing.value);
	};

	const setCursorPosition = (): void => {
		const temporalSelection = document.getSelection();

		if (!temporalSelection) return;

		const range = document.createRange();
		range.selectNodeContents(editable.value);
		temporalSelection.removeAllRanges();
		temporalSelection.addRange(range);

		selection.value = { text: temporalSelection?.toString(), selection: temporalSelection };
	};

	const handleContent = (mouse?: Position) => {
		const elementUnderMouse = mouse ? document.elementFromPoint(mouse.x, mouse.y) : undefined;
		const editableDiv = elementUnderMouse?.hasAttribute('contenteditable') || false;

		const onlySpacesOrLineBreaks =
			textEditing.value?.content?.replace(/(&nbsp;|<div><br><\/div>)/g, '').trim().length === 0;

		const emptyText = (textEditing.value && !textEditing.value?.content) || onlySpacesOrLineBreaks;

		if (emptyText) {
			removeElement(textEditing.value);
		}

		if (!editableDiv || emptyText) {
			exitTextEditingIfPreviouslyActive();
		}
	};

	const exitTextEditingIfPreviouslyActive = () => {
		if (textEditing.value) {
			if (textEditing.value.content !== editable.value?.innerHTML) {
				updateTextContent();
			}
		}

		textEditing.value = null;
	};
	const scrollIntoView = () => {
		if (editable.value && editable.value.getBoundingClientRect().y) {
			const screenY = editable.value.getBoundingClientRect().y;
			const scrollArea = document.querySelector('#scroll-area') as HTMLElement;

			const windowHeight = window.visualViewport?.height || window.innerHeight;
			const targetY = screenY + scrollArea.scrollTop - windowHeight / 4;
			scrollArea.scrollTo(scrollArea.scrollLeft, targetY);
		}
	};

	return {
		textEditing,
		textEditingContent,
		handleContent,
		fixScrollOnTyping,
		editable,
		handleBlur,
		scrollIntoView,
		updateTextContent,
		updateBox,
		setCursorPosition,
		onFinishEditingText,
		initListeners,
		exitTextEditingIfPreviouslyActive,
	};
});
