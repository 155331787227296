<script setup lang="ts">
import { ref } from 'vue';

import { useAdminActions } from '@/admin/composables/useAdminActions';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';

const props = defineProps<{
	saving: boolean;
}>();
const emit = defineEmits<{
	(e: 'close'): void;
	(e: 'update', info: { reason: string; finished: boolean }): void;
}>();
const { declineTemplate } = useAdminActions();
const store = useMainStore();
const reasonsDecline = ['Something went wrong', 'Carga lenta', 'Bugs', 'Jpg en el interior'];
const reasonToUpdate = ref('');
const reasonToDecline = ref('');
const isFinished = ref(false);

const toast = useToast();
const decline = async () => {
	if (!reasonToDecline.value) {
		return toast.error('You must select a reason to decline the template');
	}

	try {
		await declineTemplate(reasonToDecline.value);
		toast.success('Template declined!');
		emit('close');
	} catch (e) {
		toast.error('Error while declining the template');
	}
};
</script>

<template>
	<Modal open @close="emit('close')">
		<div class="relative mx-auto flex max-w-lg overflow-hidden rounded">
			<button
				class="absolute right-0 top-0 mr-2 mt-2 rounded-sm bg-white p-1 hover:bg-gray-100/25"
				@click="emit('close')"
			>
				<SvgIcon name="cross" class="h-5 w-5 fill-current text-gray-700 lg:h-6 lg:w-6" />
			</button>
			<div class="flex flex-col bg-white p-8 text-center">
				<h2 class="mb-4 mt-2 text-center text-xl font-semibold text-gray-800 lg:text-2xl">Confirm your action</h2>

				<p class="mb-0 text-center text-gray-600 lg:text-lg">Specify a reason to update the template</p>

				<div class="mt-2 flex flex-col-reverse items-center justify-center gap-4">
					<div v-if="store.isFromFreepik" class="flex justify-between">
						<select v-model="reasonToDecline" class="rounded bg-gray-50 p-2 focus:outline-none">
							<option value="">Select a option</option>
							<option v-for="reason in reasonsDecline" :key="reason" :value="reason">{{ reason }}</option>
						</select>
						<button
							class="h-10 rounded rounded-l-none border-2 border-red-500 bg-red-500 px-6 font-semibold text-white hover:border-red-400 hover:bg-red-400 lg:h-12 lg:px-8 lg:text-lg"
							@click="decline"
						>
							Decline
						</button>
					</div>

					<p v-if="store.isFromFreepik" class="mb-0 text-center text-sm text-gray-600">
						This template cannot be imported? Select why
					</p>
					<hr v-if="store.isFromFreepik" class="w-full border-t border-gray-100" />
					<div class="flex w-full flex-col">
						<input
							v-model="reasonToUpdate"
							data-testid="input-reason"
							class="w-full rounded bg-gray-100 bg-opacity-40 p-2 focus:outline-none"
							placeholder="Reason to update"
						/>

						<label v-if="store.isFromFreepik" class="flex text-sm">
							<input v-model="isFinished" type="checkbox" /> <span class="ml-1.5 mt-0.5">Ready to review</span>
						</label>

						<div class="flex justify-center">
							<button
								class="mt-2 h-10 w-32 rounded border-2 border-blue-500 bg-blue-500 px-6 font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300 lg:h-12 lg:px-8 lg:text-lg"
								:class="{ 'cursor-not-allowed opacity-50': saving }"
								:disabled="saving"
								data-testid="button-update-template-admin"
								@click="emit('update', { reason: reasonToUpdate, finished: isFinished })"
							>
								<span v-if="saving" class="flex items-center justify-center">
									<SvgIcon data-testid="spinner" name="spinner" class="mr-2 h-6 w-6 animate-spin" />
								</span>
								<span v-else>Update</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Modal>
</template>
