<script lang="ts" setup>
import { toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
import { useBoxColors } from '@/color/composables/useBoxColors';
import { Box } from '@/elements/box/classes/Box';

const props = defineProps<{ element: Box }>();

const element = toRef(props, 'element');

const { updateColor } = useBoxColors(element);
</script>

<template>
	<div class="flex gap-2">
		<ColorPicker
			:color="element.background"
			class="h-10 w-10"
			@change="($event) => updateColor(element.background, $event)"
		/>
	</div>
</template>
