<script lang="ts" setup>
import { useEventListener } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, onMounted, toRef, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import CropGrid from '@/elements/medias/crop/components/CropGrid.vue';
import { useCrop, useGhostMoveable } from '@/elements/medias/crop/composables/useCrop';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { MoveConfig } from '@/Types/types';

const store = useMainStore();

const props = defineProps<{ image: Image }>();

const image = toRef(props, 'image');

const { applyCrop, initCrop, cancelCrop, fromApplyCrop } = useCrop(image);
const { hasForeground } = useLayersImage(image);
const { listen } = useOrderedKeyboardListener();

const { ghostMoveable } = useGhostMoveable();

const moveByArrow = (e: KeyboardEvent) => {
	e.preventDefault();

	const config: MoveConfig = {
		axis: ['ArrowLeft', 'ArrowRight'].includes(e.key) ? 'x' : 'y',
		dir: ['ArrowRight', 'ArrowDown'].includes(e.key) ? 1 : -1,
	};

	const amount = config.dir * (e.shiftKey ? 10 : 1);

	ghostMoveable.value?.request(
		'draggable',
		{
			deltaX: config.axis === 'x' ? amount : 0,
			deltaY: config.axis === 'y' ? amount : 0,
		},
		true
	);
};

// Register events
listen('Enter', (ev) => {
	ev.preventDefault();
	applyCrop();
});

listen('Escape', (ev) => {
	ev.preventDefault();
	cancelCrop();
});

listen('ArrowUp', moveByArrow);
listen('ArrowLeft', moveByArrow);
listen('ArrowDown', moveByArrow);
listen('ArrowRight', moveByArrow);

onMounted(async () => {
	await initCrop();
});

onBeforeUnmount(() => {
	if (fromApplyCrop.value) {
		fromApplyCrop.value = false;
		return;
	}
	cancelCrop();
});

useEventListener('resize', () => {
	cancelCrop();
});

const ghostImageSize = computed(() => ({
	width: props.image.crop?.size.width || props.image.size.width,
	height: props.image.crop?.size.height || props.image.size.height,
}));

const ghostImagePosition = computed(() => ({
	x: props.image.crop?.position.x,
	y: props.image.crop?.position.y,
}));

const scale = toRef(store, 'scale');
watch(scale, async () => {
	await nextTick();
	ghostMoveable.value?.updateTarget();
});

const urlImage = computed(() => {
	if (hasForeground.value && image.value.opacity === 0) {
		return image.value.urlBackgroundRemoved;
	}

	return image.value.url;
});

watch(
	() => store.activePanel,
	(oldVal, newVal) => {
		if ((oldVal !== null && newVal === null) || (oldVal === null && newVal !== null)) {
			cancelCrop();
		}
	}
);
</script>

<template>
	<div
		class="ghost-image-wrapper absolute left-0 top-0"
		:style="{
			transform: `
        translate(${image.position.x}px,${image.position.y}px)
        rotate(${image.rotation}deg)
      `,
			width: `${image.size.width}px`,
			height: `${image.size.height}px`,
		}"
	>
		<div
			class="h-full w-full opacity-25"
			:style="{
				transform: `
					scaleX(${image.flipHTML.x})
					scaleY(${image.flipHTML.y})
				`,
			}"
		>
			<div
				:data-test-crop="store.croppingId"
				:style="{
					width: `${image.size.width}px`,
					height: `${image.size.height}px`,
				}"
			>
				<div
					:style="{
						width: `${image.size.width}px`,
						height: `${image.size.height}px`,
						transform: `
							scaleX(${image.flipHTML.x})
							scaleY(${image.flipHTML.y})
						`,
					}"
				>
					<div
						class="ghost-image"
						:style="{
							width: `${ghostImageSize.width}px`,
							height: `${ghostImageSize.height}px`,
							transform: `
								translate(${ghostImagePosition.x}px,${ghostImagePosition.y}px)
							`,
						}"
					>
						<component
							:is="image instanceof Image ? 'img' : 'video'"
							:src="urlImage"
							class="h-full w-full"
							:style="{
								opacity: image.opacity,
								transform: `
									scaleX(${image.flipHTML.x})
									scaleY(${image.flipHTML.y})
								`,
							}"
							@dragstart.prevent
						/>
					</div>
				</div>
			</div>
		</div>
		<CropGrid :size="image.size" />
	</div>
</template>
