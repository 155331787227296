<script lang="ts" setup>
import { computed } from 'vue';

import SelectInput from '@/common/components/SelectInput.vue';
import Element from '@/elements/element/classes/Element';
import { ImageTag } from '@/Types/types';

const props = defineProps<{
	element: Element;
}>();

const imageTags = Object.values(ImageTag);

const addTagToImage = (tag?: ImageTag | undefined) => {
	if (!tag) {
		props.element.tags = props.element.tags.filter((t) => !imageTags.includes(t as ImageTag));
		return;
	}
	props.element.tags = [tag, ...(props.element.tags as ImageTag[]).filter((t) => !imageTags.includes(t))];
};

const selectedTag = computed(
	() => (props.element.tags as ImageTag[]).find((el) => imageTags.includes(el)) || 'Select type'
);
</script>

<template>
	<div data-keep-text-selection>
		<div class="hidden w-32 gap-2 rounded-2xl bg-white p-2 shadow-lg lg:flex lg:flex-col">
			<SelectInput
				:selected="selectedTag"
				:list="imageTags"
				label-empty-value="Sin tag"
				@change="(e) => addTagToImage(e as ImageTag)"
			/>
		</div>
	</div>
</template>
