<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useElementSize, useTimeoutFn, watchThrottled } from '@vueuse/core';
import { computed, nextTick, ref, watch } from 'vue';
import { Container, Draggable } from 'vue-dndrop';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';
import CanvasPreview from '@/page/components/CanvasPreview.vue';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

interface VueDnropEvent {
	removedIndex: number | null;
	addedIndex: number | null;
	payload: any;
}

const props = defineProps<{
	isOpen: boolean;
}>();

const toast = useToast();

const store = useMainStore();
const project = useProjectStore();
const { aspectRatio } = useArtboard();
const { isSlidesgoMode } = useEditorMode();

const { isMobile } = useDeviceInfo();
const emit = defineEmits<{
	(e: 'close'): void;
}>();
const { trans } = useI18n();

const {
	removePage,
	canMoveDown,
	canMoveUp,
	movePage,
	findPosition,
	copyPage,
	addEmptyPage,
	canAddPages,
	addPage: addPageToTemplate,
	duplicatePage,
} = useProject();

const move = (page: Page, direction: 'up' | 'down') => {
	const newIndex = findPosition(page) + (direction === 'down' ? 1 : -1);

	movePage(page, newIndex);
};

const moveLeft = () => {
	if (!canMoveLeft.value || !store.activePage) return;

	move(store.activePage, 'up');
};

const moveRight = () => {
	if (!canMoveRight.value || !store.activePage) return;

	move(store.activePage, 'down');
};

const copy = (page: Page) => {
	if (!canAddPages.value) {
		toast.warning(trans("You've reached the page limit to edit."));
		return;
	}

	copyPage(page);
};

const addPage = async (page?: Page) => {
	if (!canAddPages.value) {
		toast.warning(trans("You've reached the page limit to edit."));
		return;
	}

	await addEmptyPage(page);
	// tras añadir la nueva página movemos desplazamos el scroll horizontal hasta el final del eje x
	if (!page) container.value.scrollTo({ left: container.value.scrollWidth, behavior: 'smooth' });
};

const canMoveRight = computed(() => store.activePage && canMoveDown(store.activePage));
const canMoveLeft = computed(() => store.activePage && canMoveUp(store.activePage));
const scrollArea = ref();
const container = ref();

const handleScroll = (e: WheelEvent) => {
	container.value.scrollBy(e.deltaY, 0);
};

const applyDrag = (arr: any[], dragResult: VueDnropEvent) => {
	const { removedIndex, addedIndex, payload } = dragResult;

	if (removedIndex === null && addedIndex === null) return arr;

	const result = [...arr];
	let itemToAdd = payload;

	if (removedIndex !== null) {
		itemToAdd = result.splice(removedIndex, 1)[0];
	}

	if (addedIndex !== null) {
		result.splice(addedIndex, 0, itemToAdd);
	}

	return result;
};
const onDrop = (dropResult: VueDnropEvent) => (project.pages = applyDrag(project.pages, dropResult));

const toggleDragDropElementClasses = (e: DragEvent, isDragging: boolean) => {
	const targetElement = (e.target as HTMLElement).closest('.plusElementParent');
	const targetDivElement = targetElement?.querySelector('div.plusElement');
	const targetButtonElement = targetElement?.querySelector('button.plusElement');
	const targetSvgElement = targetElement?.querySelector('svg.plusElement');
	const classMethod = !isDragging ? 'add' : 'remove';
	useTimeoutFn(
		() => {
			targetDivElement?.classList[classMethod]('transition-all', 'duration-300');
			targetButtonElement?.classList[classMethod]('transition-[width]', 'delay-300', 'duration-300');
			targetSvgElement?.classList[classMethod]('transition-opacity', 'delay-300', 'duration-300');
		},
		isDragging ? 0 : 200
	);
	targetDivElement?.classList.toggle('opacity-100', isDragging);
	targetButtonElement?.classList.toggle('w-10', isDragging);
	targetSvgElement?.classList.toggle('opacity-100', isDragging);
};

const dropPlusElement = (e: DragEvent, currentPage: Page) => {
	toggleDragDropElementClasses(e, false);
	if (!canAddPages.value) {
		toast.warning(trans("You've reached the page limit to edit."));
		store.draggingPage = null;
		return;
	}
	if (store.draggingPage !== null) {
		const newPage = duplicatePage(Page.create(store.draggingPage as Page));
		addPageToTemplate(newPage, currentPage);
		store.draggingPage = null;
	}
};

const dragContainer = ref();
const hasScroll = ref(false);
const { width } = useElementSize(dragContainer);

watchThrottled(
	width,
	() => {
		hasScroll.value = container.value.scrollWidth > container.value.clientWidth;
	},
	{ throttle: 100 }
);

watch(
	() => props.isOpen,
	async (newVal) => {
		if (!newVal) return;
		await nextTick();
		const activePage = document.querySelector(`[data-key="${store.activePageId}"]`);
		if (!activePage) return;
		activePage.scrollIntoView({ behavior: 'smooth', block: 'end' });
	},
	{ immediate: true }
);

const deletePage = (e: KeyboardEvent) => {
	if (!['Delete', 'Backspace'].includes(e.key)) return;

	const index = project.pages.findIndex((page) => page.id === store.activePageId);

	removePage(project.pages[index] as Page);
};
</script>

<template>
	<div
		v-if="project.pages.length > 1 || isSlidesgoMode"
		ref="scrollArea"
		data-testid="canvas-navigation"
		tabindex="1"
		class="fixed bottom-14 z-20 h-auto w-full flex-col items-stretch overflow-x-auto rounded-tl-xl rounded-tr-xl bg-gray-700 px-4 outline-0 lg:absolute lg:bottom-0 lg:flex-auto lg:rounded-none"
		:class="{ 'h-full': !isMobile }"
		@wheel="handleScroll"
		@keydown="deletePage"
	>
		<div class="flex items-center gap-4 pt-3 lg:hidden">
			<button
				class="flex items-center py-1 text-xs font-bold text-gray-100 hover:text-white"
				:class="{ 'opacity-50': !canAddPages }"
				@click="addPage(store.activePage!)"
			>
				<SvgIcon name="plus" class="mr-1 h-4 w-4" /> {{ trans('New page') }}
			</button>
			<button
				class="flex items-center py-1 text-xs font-bold text-gray-100 hover:text-white sm:hidden"
				:class="{ 'opacity-50': !canMoveLeft }"
				:disabled="!canMoveLeft"
				@click="moveLeft"
			>
				<SvgIcon name="arrow" class="mr-1 h-4 w-4 rotate-90" /> {{ trans('Move left') }}
			</button>
			<button
				class="flex items-center py-1 text-xs font-bold text-gray-100 hover:text-white sm:hidden"
				:class="{ 'opacity-50': !canMoveRight }"
				:disabled="!canMoveRight"
				@click="moveRight"
			>
				<SvgIcon name="arrow" class="mr-1 h-4 w-4 -rotate-90" /> {{ trans('Move right') }}
			</button>
		</div>
		<div
			ref="container"
			data-onboarding="pages"
			class="flex items-center overflow-x-auto overflow-y-hidden px-1 text-gray-700 scrollbar-thin scrollbar-thumb-gray-500"
			:class="{
				'py-4 pb-[4px]': !hasScroll,
				'pb-1 pt-4': hasScroll,
				'h-full': !isMobile,
			}"
		>
			<Container
				ref="dragContainer"
				orientation="horizontal"
				behaviour="contain"
				drag-class="opacity-ghost"
				@drop="onDrop"
			>
				<Draggable v-for="(page, index) in (project.pages as Page[])" :key="page.id" :data-key="page.id">
					<div
						class="plusElementParent mr-2 flex sm:mr-0"
						@dragover.prevent="(e) => toggleDragDropElementClasses(e, true)"
						@dragleave.prevent="(e) => toggleDragDropElementClasses(e, false)"
						@drop.prevent.stop="(e) => dropPlusElement(e, page)"
					>
						<div
							class="group peer relative flex h-24 min-w-[3rem] max-w-xs shrink-0 cursor-pointer items-center rounded"
							:style="{
								aspectRatio: aspectRatio,
							}"
							:class="{
								'bg-blue-500 ring-2 ring-blue-500 slidesgo:bg-purple-400 slidesgo:ring-purple-400':
									page.id === store.activePage?.id,
								'bg-gray-600': page.id !== store.activePage?.id,
							}"
						>
							<span
								class="absolute bottom-1 left-1 z-10 flex h-6 w-6 items-center justify-center rounded text-sm font-semibold"
								:class="
									page.id === store.activePage?.id
										? 'bg-blue-500 text-white slidesgo:bg-purple-400'
										: 'bg-gray-800 text-gray-100'
								"
							>
								{{ index + 1 }}
							</span>
							<button
								data-testid="remove-page"
								class="absolute right-1 top-1 z-10 flex h-5 w-5 items-center justify-center gap-2 rounded bg-red-500 text-white lg:hidden"
								:class="{
									'pointer-events-none opacity-0': page.id !== store.activePage?.id,
								}"
								@click="removePage(page)"
							>
								<SvgIcon name="trash" class="h-2.5 w-2.5" />
							</button>
							<Menu v-if="store.finishedLoading" v-slot="{ open }">
								<MenuButton
									class="absolute right-0 top-0 z-10 m-1 hidden h-5 rounded px-2 text-white opacity-0 group-hover:opacity-100 lg:block"
									:class="{
										'bg-blue-400 slidesgo:bg-purple-300': open,
										'bg-blue-500 slidesgo:bg-purple-400': !open,
										'opacity-100': page.id === store.activePage?.id,
									}"
									><SvgIcon name="more" class="h-3.5 w-3.5"
								/></MenuButton>
								<MenuItems class="absolute left-full top-0 z-20 origin-top-right focus:outline-none">
									<div class="ml-2 rounded-md bg-white p-1 shadow">
										<MenuItem>
											<button
												class="flex w-full items-center gap-2 rounded px-4 py-1 text-sm text-gray-600 hover:bg-gray-100/25 hover:text-gray-800"
												:class="{ 'opacity-50': !canAddPages }"
												@click="copy(page)"
											>
												<SvgIcon name="copy" class="h-3.5 w-3.5 shrink-0" />
												{{ trans('Duplicate') }}
											</button>
										</MenuItem>
										<MenuItem>
											<button
												class="flex w-full items-center gap-2 rounded px-4 py-1 text-sm text-red-700 hover:bg-red-100/25 hover:text-red-700"
												@click="removePage(page)"
											>
												<SvgIcon name="trash" class="h-3.5 w-3.5 shrink-0" />
												{{ trans('Delete') }}
											</button>
										</MenuItem>
									</div>
								</MenuItems>
							</Menu>

							<CanvasPreview
								:key="page.id"
								data-context="navigation"
								:page="page"
								class="draggable-item z-0 h-full w-full min-w-[3rem] max-w-xs overflow-hidden rounded-md"
								:style="{
									background: aspectRatio,
								}"
								preview-name="nav"
								:scroll-area="scrollArea"
								:class="{
									'opacity-70': page.id === store.activePage?.id,
								}"
								@click="store.setActivePage(page, true)"
							/>
						</div>
						<div
							v-if="project.pages.length - 1 !== index && !isMobile"
							class="plusElement group px-2 opacity-0 transition-all duration-300 peer-hover:opacity-100 hover:opacity-100"
							@drop.prevent.stop="(e) => dropPlusElement(e, page)"
						>
							<button
								class="plusElement group flex h-full w-1 items-center justify-center rounded bg-gray-500 transition-[width] delay-300 duration-300 group-hover:w-10 hover:bg-gray-500"
								@click="addPage(page)"
							>
								<SvgIcon
									name="plus"
									class="plusElement h-5 w-5 text-white opacity-0 transition-opacity delay-300 duration-300 group-hover:opacity-100"
								/>
							</button>
						</div>
					</div>
				</Draggable>
			</Container>
			<div
				class="flex h-24 min-w-[3rem] max-w-xs lg:ml-4"
				:style="{
					aspectRatio: aspectRatio,
				}"
			>
				<button
					class="group flex w-full items-center justify-center rounded bg-gray-600 hover:bg-gray-500"
					@click="addPage()"
				>
					<SvgIcon name="plus" class="h-7 w-7 text-white" />
				</button>
			</div>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.opacity-ghost
	opacity: 50%
</style>
