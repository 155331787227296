import Normalize from 'color-normalize';

class FilterConversor {
	private static createSVGElement(tagname: string, attributes: any, subElements?: any) {
		let elem = '<' + tagname;

		for (const key in attributes) {
			elem += ' ' + key + '="' + attributes[key] + '"';
		}

		if (subElements !== undefined) {
			elem += '>';

			for (let i = 0; i < subElements.length; i++) {
				elem += subElements[i];
			}

			elem += '</' + tagname + '>';
		} else {
			elem += ' />';
		}

		return elem;
	}

	private static getLength(amount: number, unit: 'px' | 'em' | 'rem') {
		switch (unit) {
			case 'px':
				break;
			case 'em':
			case 'rem':
				amount *= 16;
				break;
		}

		return amount;
	}

	private static getAngle(amount: number, unit: 'deg' | 'rad' | 'grad' | 'turn') {
		switch (unit) {
			case 'deg':
				break;
			case 'grad':
				amount = (180 * amount) / 200;
				break;
			case 'rad':
				amount = (180 * amount) / Math.PI;
				break;
			case 'turn':
				amount = 360 * amount;
				break;
		}

		return amount;
	}

	static none() {
		const properties: any = {};

		// CSS
		properties.filtersCSS = ['none'];

		// SVG
		properties.filtersSVG = ['none'];

		return properties;
	}

	static grayscale(amount: number, unit) {
		amount = amount || 0;

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['grayscale(' + amount + ')'];

		// SVG
		const svg = this.createSVGElement('feColorMatrix', {
			type: 'matrix',
			'color-interpolation-filters': 'sRGB',
			values:
				0.2126 +
				0.7874 * (1 - amount) +
				' ' +
				(0.7152 - 0.7152 * (1 - amount)) +
				' ' +
				(0.0722 - 0.0722 * (1 - amount)) +
				' 0 0 ' +
				(0.2126 - 0.2126 * (1 - amount)) +
				' ' +
				(0.7152 + 0.2848 * (1 - amount)) +
				' ' +
				(0.0722 - 0.0722 * (1 - amount)) +
				' 0 0 ' +
				(0.2126 - 0.2126 * (1 - amount)) +
				' ' +
				(0.7152 - 0.7152 * (1 - amount)) +
				' ' +
				(0.0722 + 0.9278 * (1 - amount)) +
				' 0 0 0 0 0 1 0',
		});
		properties.filtersSVG = [svg];

		return properties;
	}

	static sepia(amount: number, unit) {
		amount = amount || 0;

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['sepia(' + amount + ')'];

		// SVG
		const svg = this.createSVGElement('feColorMatrix', {
			type: 'matrix',
			'color-interpolation-filters': 'sRGB',
			values:
				0.393 +
				0.607 * (1 - amount) +
				' ' +
				(0.769 - 0.769 * (1 - amount)) +
				' ' +
				(0.189 - 0.189 * (1 - amount)) +
				' 0 0 ' +
				(0.349 - 0.349 * (1 - amount)) +
				' ' +
				(0.686 + 0.314 * (1 - amount)) +
				' ' +
				(0.168 - 0.168 * (1 - amount)) +
				' 0 0 ' +
				(0.272 - 0.272 * (1 - amount)) +
				' ' +
				(0.534 - 0.534 * (1 - amount)) +
				' ' +
				(0.131 + 0.869 * (1 - amount)) +
				' 0 0 0 0 0 1 0',
		});
		properties.filtersSVG = [svg];

		return properties;
	}

	static saturate(amount: number, unit) {
		amount = amount || 1;

		const properties: any = {};

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		// CSS
		properties.filtersCSS = ['saturate(' + amount + ')'];

		// SVG
		const svg = this.createSVGElement('feColorMatrix', {
			type: 'matrix',
			'color-interpolation-filters': 'sRGB',
			values:
				0.213 +
				0.787 * amount +
				' ' +
				(0.715 - 0.715 * amount) +
				' ' +
				(0.072 - 0.072 * amount) +
				' 0 0 ' +
				(0.213 - 0.213 * amount) +
				' ' +
				(0.715 + 0.295 * amount) +
				' ' +
				(0.072 - 0.072 * amount) +
				' 0 0 ' +
				(0.213 - 0.213 * amount) +
				' ' +
				(0.715 - 0.715 * amount) +
				' ' +
				(0.072 + 0.928 * amount) +
				' 0 0 0 0 0 1 0',
		});
		properties.filtersSVG = [svg];

		// IE
		// no filter

		return properties;
	}

	static hueRotate(angle: number, unit) {
		angle = angle || 0;

		angle = this.getAngle(angle, unit);

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['hue-rotate(' + angle + 'deg)'];

		// SVG
		const svg = this.createSVGElement('feColorMatrix', {
			type: 'hueRotate',
			'color-interpolation-filters': 'sRGB',
			values: angle,
		});
		properties.filtersSVG = [svg];

		// IE
		// no filter

		return properties;
	}

	static invert(amount: number, unit) {
		amount = amount || 0;

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['invert(' + amount + ')'];

		// SVG
		const svgSub1 = this.createSVGElement('feFuncR', {
			type: 'table',
			tableValues: amount + ' ' + (1 - amount),
		});
		const svgSub2 = this.createSVGElement('feFuncG', {
			type: 'table',
			tableValues: amount + ' ' + (1 - amount),
		});
		const svgSub3 = this.createSVGElement('feFuncB', {
			type: 'table',
			tableValues: amount + ' ' + (1 - amount),
		});
		const svg = this.createSVGElement(
			'feComponentTransfer',
			{
				'color-interpolation-filters': 'sRGB',
			},
			[svgSub1, svgSub2, svgSub3]
		);
		properties.filtersSVG = [svg];

		return properties;
	}

	static opacity(amount: number, unit) {
		amount = amount || 1;

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['opacity(' + amount + ')'];

		// SVG
		const svgSub1 = this.createSVGElement('feFuncA', {
			type: 'table',
			tableValues: '0 ' + amount,
		});
		const svg = this.createSVGElement(
			'feComponentTransfer',
			{
				'color-interpolation-filters': 'sRGB',
			},
			[svgSub1]
		);
		properties.filtersSVG = [svg];

		// IE
		// no filter

		return properties;
	}

	static brightness(amount: number, unit) {
		amount = amount || 1;

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['brightness(' + amount + ')'];

		// SVG
		const svgSub1 = this.createSVGElement('feFuncR', {
			type: 'linear',
			slope: amount,
		});
		const svgSub2 = this.createSVGElement('feFuncG', {
			type: 'linear',
			slope: amount,
		});
		const svgSub3 = this.createSVGElement('feFuncB', {
			type: 'linear',
			slope: amount,
		});
		const svg = this.createSVGElement(
			'feComponentTransfer',
			{
				'color-interpolation-filters': 'sRGB',
			},
			[svgSub1, svgSub2, svgSub3]
		);
		properties.filtersSVG = [svg];

		return properties;
	}

	static contrast(amount: number, unit) {
		amount = amount || 1;

		if (typeof unit !== 'undefined') {
			amount /= 100;
		}

		const properties: any = {};

		// CSS
		properties.filtersCSS = ['contrast(' + amount + ')'];

		// SVG
		const svgSub1 = this.createSVGElement('feFuncR', {
			type: 'linear',
			slope: amount,
			intercept: -(0.5 * amount) + 0.5,
		});
		const svgSub2 = this.createSVGElement('feFuncG', {
			type: 'linear',
			slope: amount,
			intercept: -(0.5 * amount) + 0.5,
		});
		const svgSub3 = this.createSVGElement('feFuncB', {
			type: 'linear',
			slope: amount,
			intercept: -(0.5 * amount) + 0.5,
		});
		const svg = this.createSVGElement(
			'feComponentTransfer',
			{
				'color-interpolation-filters': 'sRGB',
			},
			[svgSub1, svgSub2, svgSub3]
		);
		properties.filtersSVG = [svg];

		// IE
		// no filter

		return properties;
	}

	static blur(amount: number, unit) {
		amount = amount || 0;

		const properties: any = {};

		if (unit === '' && amount !== 0) {
			return properties;
		}

		amount = this.getLength(amount, unit);

		// CSS
		properties.filtersCSS = ['blur(' + amount + 'px)'];

		// SVG
		// Estos 2 elementos son para prevenir que el blur rompa el contorno
		const fixBlurError1 = this.createSVGElement('feColorMatrix', {
			type: 'matrix',
			'color-interpolation-filters': 'sRGB',
			values: '1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 9 0',
		});

		const fixBlurError2 = this.createSVGElement('feComposite', {
			in2: 'SourceGraphic',
			operator: 'in',
		});

		const svg = this.createSVGElement('feGaussianBlur', {
			stdDeviation: amount,
		});
		properties.filtersSVG = [svg, fixBlurError1, fixBlurError2];

		return properties;
	}

	static dropShadow(offsetX, unitX, offsetY, unitY, radius, unitRadius, spread, unitSpread, color) {
		offsetX = Math.round(offsetX) || 0;
		offsetY = Math.round(offsetY) || 0;
		radius = Math.round(radius) || 0;
		color = color || '#000000';

		const properties: any = {};

		if (
			(unitX === ' ' && offsetX !== 0) ||
			(unitY === ' ' && offsetY !== 0) ||
			(unitRadius === ' ' && radius !== 0) ||
			spread
		) {
			return properties;
		}

		offsetX = this.getLength(offsetX, unitX);
		offsetY = this.getLength(offsetY, unitY);
		radius = this.getLength(radius, unitRadius);

		// CSS
		properties.filtersCSS = ['drop-shadow(' + offsetX + 'px ' + offsetY + 'px ' + radius + 'px ' + color + ')'];

		// SVG
		const svg1 = this.createSVGElement('feGaussianBlur', {
			in: 'SourceAlpha',
			stdDeviation: radius,
		});
		const svg2 = this.createSVGElement('feOffset', {
			dx: offsetX + 1,
			dy: offsetY + 1,
			result: 'offsetblur',
		});
		const [r, g, b, a] = Normalize(color);
		const svg3 = this.createSVGElement('feFlood', {
			'flood-color': `rgba(${r * 255}, ${g * 255}, ${b * 255}, ${a})`,
		});
		const svg4 = this.createSVGElement('feComposite', {
			in2: 'offsetblur',
			operator: 'in',
		});
		const svg5Sub1 = this.createSVGElement('feMergeNode', {});
		const svg5Sub2 = this.createSVGElement('feMergeNode', {
			in: 'SourceGraphic',
		});
		const svg5 = this.createSVGElement('feMerge', {}, [svg5Sub1, svg5Sub2]);
		properties.filtersSVG = [svg1, svg2, svg3, svg4, svg5];

		return properties;
	}
}

export default FilterConversor;
