<script setup lang="ts">
import { ref } from 'vue';

import { getBasicShapes } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { InsertableApiType } from '@/Types/types';

const { addInsertableBasicShape } = useAddInsertableElement();

const emit = defineEmits(['elementAdded']);

// Constants
const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

// Data
const url = ref(`elements/category/1740?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}`);

const { data: basicShapes, isFetching } = getBasicShapes(url, { refetch: true });

// Methods
const onClickBasicShape = async (url: string) => {
	await addInsertableBasicShape(url);
	emit('elementAdded');
};

const loadMore = () => {
	if (isFetching.value || !basicShapes.value?.links?.next) return;
	url.value = basicShapes.value.links.next;
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<InfiniteLoading
			data-testid="container-items"
			:container-classes="'grid grid-cols-5 gap-6'"
			:data="basicShapes?.data || []"
			:is-fetching="isFetching"
			@load="loadMore"
		>
			<template #item="{ item }">
				<InsertableElement
					v-if="'svg' in item && 'active' in item"
					:data="item"
					:type="InsertableApiType.BasicShape"
					:draggable="true"
					class="flex"
					data-testid="basic-shape"
					@click="onClickBasicShape(item.svg)"
				>
					<img v-if="item" :src="item.svg" :alt="item.name" class="h-10 w-10 object-contain" />
				</InsertableElement>
			</template>
		</InfiniteLoading>
	</div>
</template>
