<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextLink } from '@/elements/texts/text/composables/useTextLink';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';
import StringTools from '@/utils/classes/StringTools';

const props = defineProps<{ element: Text }>();

const textElement = toRef(props, 'element');
const emit = defineEmits<{ (e: 'close', link?: string): void }>();

const { trans } = useI18n();
const { closePanel } = usePanelManagement();
const { linkContent, deleteLink, submitLink } = useTextLink(textElement);
const textLink = ref(linkContent.value || '');
const store = useMainStore();
const input = ref();
// Methods

const isValidLink = computed(() => {
	return (
		textLink.value &&
		textLink.value.trim().length > 0 &&
		TextTools.isValidUrl(StringTools.getTransformedLink(textLink.value.trim()))
	);
});

const onSubmitLink = () => {
	submitLink(textLink);

	isMobile.value ? (store.editPanel = EditPanels.Text) : emit('close', textLink.value);
};

const onDeleteLink = () => {
	deleteLink();
	isMobile.value ? (store.editPanel = EditPanels.Text) : emit('close');
};
const { isMobile } = useDeviceInfo();

onClickOutside(input, (event) => {
	const target = event.target as HTMLElement | null;
	const targetIsLinkMenu = target?.closest('.link-menu');

	if (targetIsLinkMenu) return;
	isMobile.value ? (store.editPanel = EditPanels.Text) : emit('close');
});
</script>

<template>
	<div
		class="link-menu z-10 -mb-12 w-full flex-col items-center rounded bg-gray-700 lg:right-0 lg:top-auto lg:-ml-6 lg:-mt-20 lg:w-72 lg:bg-white lg:shadow-lg"
		:class="{ 'absolute p-2': !isMobile, 'px-4 pb-2 pt-4': isMobile }"
	>
		<div class="bg mb-4 flex justify-between sm:hidden">
			<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Link') }}</h2>
			<button
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="closePanel(element)"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>
		<div class="flex">
			<input
				ref="input"
				v-model="textLink"
				data-text-input
				class="placehoder-gray-200 lg:placehoder-gray-500 h-8 flex-1 rounded bg-gray-100/25 px-2 text-sm text-white focus:outline-none lg:h-7 lg:text-gray-800"
				type="text"
				:placeholder="trans('Type a URL (example: https://wepik.com)')"
			/>
			<button
				class="ml-2 flex h-8 w-8 items-center justify-center rounded bg-green-500 lg:h-7 lg:w-7"
				:class="{
					'cursor-not-allowed opacity-50': !isValidLink,
					'hover:bg-green-600': isValidLink,
				}"
				data-testid="submitLink"
				:disabled="!isValidLink"
				@click="onSubmitLink"
			>
				<SvgIcon name="check" class="h-4 w-4 scale-90 fill-current text-white group-hover:text-white" />
			</button>
			<button
				v-if="textLink.length > 0"
				data-testid="deleteLink"
				class="absolute right-0 top-0 mr-14 mt-3.5 flex h-5 w-5 items-center justify-center rounded-sm bg-gray-700 lg:mr-12 lg:mt-3 lg:bg-white"
				@click="onDeleteLink"
			>
				<SvgIcon
					name="trash"
					class="h-3 w-3 scale-90 fill-current text-gray-100 group-hover:text-white hover:text-gray-300 lg:text-gray-600"
				/>
			</button>
		</div>
	</div>
</template>
