<script lang="ts" setup>
// Vue
import { computed, toRef } from 'vue';

// Components
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
// Composables
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useBackgroundableElement } from '@/elements/element/composables/useBackgroundableElement';
// Classes
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import EventTools from '@/interactions/classes/EventTools';
// Props
const props = defineProps<{ element: Image | Video }>();

// Data
const element = toRef(props, 'element');
const toast = useToast();
// Using composables
const { isPhotoMode } = useEditorMode();
const { canBeSetAsBackground, setElementAsBackground } = useBackgroundableElement(element);
const { trans } = useI18n();
// Methods
const setAsBackground = () => {
	try {
		setElementAsBackground();
	} catch (e) {
		const message = EventTools.getErrorMessage(e);
		toast.error(trans(message));
	}
};
</script>

<template>
	<div class="flex flex-col">
		<button
			data-testid="to-background"
			class="flex h-8 w-full items-center justify-center rounded bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700"
			:class="{
				'disabled cursor-not-allowed opacity-50': !canBeSetAsBackground || isPhotoMode,
				'hover:bg-gray-600 hover:text-white mockup:hover:text-blue-500': canBeSetAsBackground && !isPhotoMode,
			}"
			:disabled="!canBeSetAsBackground || isPhotoMode"
			@click="setAsBackground"
		>
			<SvgIcon name="background" class="h-4 w-4" />
		</button>
		<label class="mt-1 text-center text-2xs leading-snug text-gray-100 mockup:text-fp-gray-700">{{
			trans('Set as background')
		}}</label>
	</div>
</template>
