<script lang="ts" setup>
import { createPopper, Offsets, Placement } from '@popperjs/core';
import { useMutationObserver } from '@vueuse/core';
import { onMounted, ref } from 'vue';

// Template refs
const element = ref();
const arrow = ref();

const props = withDefaults(
	defineProps<{
		placement: Placement;
		arrow?: boolean;
		offsets?: Offsets;
		parentSelector?: Element;
	}>(),
	{
		arrow: false,
		offsets: undefined,
		parentSelector: undefined,
	}
);

onMounted(() => {
	const parent = props.parentSelector ? props.parentSelector : element.value.parentElement;

	const instance = createPopper(parent, element.value, {
		placement: props.placement,
		modifiers: [
			props.arrow ? { name: 'arrow', options: { element: arrow.value } } : false,
			props.offsets ? { name: 'offset', options: { offset: props.offsets } } : false,
		].filter((el) => !!el) as [],
	});

	useMutationObserver(
		element,
		() => {
			instance.update();
		},
		{
			childList: true,
			subtree: true,
		}
	);
});
</script>

<template>
	<div ref="element" class="z-10">
		<div v-if="props.arrow" ref="arrow" data-popper-arrow></div>
		<slot></slot>
	</div>
</template>

<style>
[data-popper-arrow]::before {
	transition: transform 0.2s ease-out 0s, visibility 0.2s ease-out 0s;
	visibility: visible;
	transform: translate(0px, -5px) rotate(45deg);
	transform-origin: center center;
	border-radius: 2px;

	content: '';
	background: rgb(255, 255, 255);
	top: 0px;
	left: 0px;

	width: 10px;
	height: 10px;

	position: absolute;
	z-index: -1;
}
[data-popper-arrow] {
	width: 10px;
	height: 10px;
	position: absolute;
	z-index: -1;
}
</style>
