import { ClipPath, Element as SVGJSElement } from '@svgdotjs/svg.js';

import Image from '@/elements/medias/images/image/classes/Image';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import Mask from '@/elements/medias/mask/classes/Mask';
import { Size } from '@/Types/types';

export class SlidesgoImageParser {
	static masksToProcess = new Map<SVGJSElement, Image>();

	static async init(wrapper: SVGJSElement) {
		const image = wrapper.node.tagName === 'image' ? wrapper : (wrapper.findOne('image') as SVGJSElement);
		if (!image) {
			console.warn('parseImage could not find <image/> to parse');
			return;
		}

		const size = {
			width: parseFloat(image.width().toString()),
			height: parseFloat(image.height().toString()),
		};

		const url = image.attr('xlink:href') || image.attr('href') || image.attr('src');

		const crop = await this.parseCrop(size, url);

		const mask = this.parseMask(wrapper);

		const newImage = Image.create({
			url,
			crop,
			mask,
			position: {
				x: parseFloat(image.x().toString()),
				y: parseFloat(image.y().toString()),
			},
			size,
		});

		return newImage;
	}

	static isImage(wrapper: SVGJSElement) {
		return (
			wrapper.node.tagName === 'image' ||
			wrapper.classes().includes('Graphic') ||
			(wrapper.classes().some((cls) => cls.includes('CustomShape')) &&
				!!wrapper.findOne('clippath, mask') &&
				!!wrapper.findOne('image'))
		);
	}

	static async parseCrop(size: Size, url: string) {
		const ratio = size.width / size.height;
		const { width, height } = await ImageTools.getRealImageSize(url);
		const realRatio = width / height;
		const cropSize = {
			width: size.height * realRatio,
			height: size.height,
		};
		const cropPosition = {
			x: -Math.abs(cropSize.width - size.width) / 2,
			y: -Math.abs(cropSize.height - size.height) / 2,
		};

		if (Math.abs(ratio - realRatio) < 0.1) {
			cropPosition.x = 0;
			cropPosition.y = 0;
			cropSize.width = 0;
			cropSize.height = 0;
		}

		return {
			position: cropPosition,
			size: cropSize,
		};
	}

	static parseMask(wrapper: SVGJSElement) {
		const image = wrapper.findOne('image') as SVGJSElement;
		if (!image) return;
		let imageClipPathId = image.parent()?.attr('clip-path');
		if (!imageClipPathId) return;
		imageClipPathId = imageClipPathId.split('(')[1].split(')')[0];
		const clipPathNode = wrapper.findOne(imageClipPathId) as ClipPath;
		const viewBox = `${image.x()} ${image.y()} ${image.width()} ${image.height()}`;
		const stringSvg = `<svg viewBox="${viewBox}">${clipPathNode.node.innerHTML}</svg>`;
		return Mask.fromStringSvg(new Date().getTime(), 'Slidesgo custom mask', stringSvg);
	}
}
