<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { cloneDeep } from 'lodash';
import RoundSlider from 'vue3-slider';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import NumberInput from '@/common/components/NumberInput.vue';
import { Color } from '@/Types/colorsTypes';

const props = defineProps<{ gradient: GradientColor; disableAngle?: boolean }>();
const emit = defineEmits<{ (e: 'change', gradient: Color): void }>();

const updateAngle = (angle: number) => {
	const clone = cloneDeep(props.gradient);
	clone.rotation = angle;
	emit('change', clone);
};

const trackTypingEvent = (category: string, value: number) => {
	Bugsnag.leaveBreadcrumb(`type ${value} in ${category}`);

	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<div v-if="gradient.type === 'linear'" class="flex justify-end gap-2">
		<div class="roundSlider-wrapper" :class="{ disableAngle }">
			<RoundSlider
				:model-value="gradient.rotation"
				:height="3"
				orientation="circular"
				:min="0"
				:max="360"
				:step="1"
				:repeat="true"
				track-color="#485D6D"
				color="#485D6D"
				class="shadow-round-slider w-full max-w-[1.5rem]"
				:class="disableAngle ? 'disableAngle' : ''"
				@update:model-value="updateAngle"
			/>
		</div>
		<NumberInput
			:name="'input-gradient-rotation'"
			:test-id="'input-gradient-rotation'"
			:value="gradient.rotation"
			class="h-6 w-14 text-xs"
			:disabled="disableAngle"
			@trackStep="() => trackStepEvent('rotation')"
			@trackTyping="(value) => trackTypingEvent('rotation', value)"
			@update="updateAngle"
		/>
	</div>
</template>

<style lang="sass">
.vue3-slider.circular .handle-container .handle
	@apply bg-gray-100 w-3 h-3 rounded-full border-2 border-gray-800 -m-1 #{!important}

.roundSlider-wrapper.disableAngle
	@apply cursor-not-allowed

	.vue3-slider.circular
		@apply pointer-events-none

		& .handle-container
			@apply cursor-not-allowed

			& .handle
				@apply cursor-not-allowed
</style>
