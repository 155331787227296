<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { computed, nextTick, ref, toRef, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import TagImageToolbar from '@/elements/medias/images/image/components/toolbars/TagImageToolbar.vue';
import TagTextToolbar from '@/elements/texts/text/components/toolbars/TagTextToolbar.vue';

// Stores
const store = useMainStore();

// Props
const props = defineProps<{ element: Element }>();

// Template refs
const toolbar = ref();

// Data
const element = toRef(props, 'element');
const popper = ref();

// Computeds
const croppingId = computed(() => store.croppingId);
const size = computed(() => element.value.size);
const position = computed(() => element.value.position);

// Watchers
watch([position, size], async () => {
	await nextTick();

	if (!popper.value) {
		initPopper();
		return;
	}

	popper.value.update();
});

watch(
	[croppingId, element],
	async () => {
		await nextTick();
		initPopper();
	},
	{ immediate: true }
);

// Methods
const componentType = () => {
	switch (element.value.type) {
		case 'image':
			return TagImageToolbar;
		case 'text':
			return TagTextToolbar;
	}
};

const initPopper = () => {
	const domNode = element.value.domNode();
	if (!domNode || !toolbar.value) return;

	popper.value = createPopper(domNode, toolbar.value, {
		placement: 'left-start',
		modifiers: [
			{ name: 'offset', options: { offset: [0, 5] } },
			{
				name: 'preventOverflow',
				options: {
					tether: false,
				},
			},
		],
	});
};
</script>

<template>
	<teleport to="#toolbarTarget">
		<div ref="toolbar" class="toolbar z-20 flex flex-col gap-2 pl-2">
			<component :is="componentType()" :element="props.element" class="flex items-center rounded-full px-2" />
		</div>
	</teleport>
</template>
