import { promiseTimeout } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { computed, nextTick, onMounted, Ref, ref, watch } from 'vue';

import { useCollisionImageInfo } from '@/collision/composables/useCollisionImageInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useElementOrder } from '@/elements/element/composables/useElementOrder';
import { DomNodes } from '@/elements/element/dom/DomNodes';
import { DomNodesMedia } from '@/elements/element/dom/DomNodesMedia';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useImageTransform } from '@/elements/medias/images/image/composables/useImageTransform';
import { useInteractions } from '@/interactions/composables/useInteractions';
import Page from '@/page/classes/Page';
import { useArtboard } from '@/project/composables/useArtboard';
import MathTools from '@/utils/classes/MathTools';

export const useSyncForeground = (element: Ref<ForegroundImage>, previewPage?: Ref<Page>, previewName?: string) => {
	const { imageBackground } = useForeground(element, previewPage);
	const { isCropping } = useInteractions();
	const { artboardSizeInPx } = useArtboard();
	const store = useMainStore();
	const { flipAxis } = useImageTransform(element);
	const { isRenderingContext } = useEditorMode();
	const { elementIndex: backgroundIndex } = useCollisionImageInfo(imageBackground as Ref<Image>);
	const { elementIndex, moveElementBetweenIndex, moveUp } = useElementOrder(element);
	const isPreview = !!previewName;
	const position = computed(() => {
		return imageBackground.value?.position;
	});
	const cropPosition = computed(() => imageBackground.value?.crop.position);
	const size = computed(() => imageBackground.value?.size);
	const cropSize = computed(() => imageBackground.value?.crop.size);
	const rotation = computed(() => imageBackground.value?.rotation);
	const flipX = computed(() => imageBackground.value?.flip.x);
	const flipY = computed(() => imageBackground.value?.flip.y);
	const syncingPosition = ref(false);

	const positionForeground = ref(cloneDeep(element.value.position));
	const sizeForeground = ref(cloneDeep(element.value.size));
	const cropPositionForeground = ref(cloneDeep(element.value.crop.position));
	const cropSizeForeground = ref(cloneDeep(element.value.crop.size));
	const positionAdjustmentData = ref({ x: 0, y: 0 });
	const sizeAdjustmentData = ref({
		width: 0,
		height: 0,
		crop: { size: { width: 0, height: 0 }, position: { x: 0, y: 0 } },
	});

	const fgPositionX = ref(imageBackground.value);
	const fgPositionY = ref();

	const getPositionWithBackground = () => {
		if (!imageBackground.value || syncingPosition.value || window.moving) return;
		syncingPosition.value = true;

		const foreground = DomNodes.setElement(element.value) as DomNodesMedia;
		const background = DomNodes.setElement(imageBackground.value) as DomNodesMedia;

		const { originalPosition: fgImgPosition } = foreground.nodeData;
		const { rotation: backgroundRotation } = background.nodeData;

		// En caso de que rotemos la imagen necesitamos aplicar el valor de su rotación inicial del foreground para su posterior cálculo (rotateHandler)
		foreground.setRotationToOriginalNode(backgroundRotation, fgImgPosition.x, fgImgPosition?.y);

		positionAdjustmentData.value.x = fgImgPosition.x;
		positionAdjustmentData.value.y = fgImgPosition.y;
		const boundingImage = isPreview
			? imageBackground.value?.domPreviewNode(previewName)?.querySelector('img')?.getBoundingClientRect()
			: imageBackground.value?.domNode()?.querySelector('img')?.getBoundingClientRect();

		const boundingClone = isPreview
			? element.value.domPreviewNode(previewName)?.querySelector('img')?.getBoundingClientRect()
			: element.value.domNode()?.querySelector('img')?.getBoundingClientRect();

		if (!positionForeground.value.x || !positionForeground.value.y) {
			positionForeground.value.x = element.value.position.x;
			positionForeground.value.y = element.value.position.y;
		}
		if (boundingImage && boundingClone) {
			const originalX = boundingImage.x;
			const originalY = boundingImage.y;
			const clonedX = boundingClone.x;
			const clonedY = boundingClone.y;

			// a la previa le vienen ya los elementos escalados, por lo que no debemos de tener en cuenta la escala
			// Para corregirlo usamos la diferencia entre las x e y de los bounding de la img ( que siempre tiene el tamaño original)
			// y dependiendo del angulo de rotación le hacemos la correción que necesita
			const diffX = isPreview ? originalX - clonedX : (originalX - clonedX) / store.scale;
			const diffY = isPreview ? originalY - clonedY : (originalY - clonedY) / store.scale;

			positionAdjustmentData.value.x += diffX;
			positionAdjustmentData.value.y += diffY;
			syncingPosition.value = false;

			return {
				x: positionAdjustmentData.value.x,
				y: positionAdjustmentData.value.y,
				rotation: backgroundRotation,
			};
		}

		syncingPosition.value = false;
	};
	const getSizeWithBackground = () => {
		if (!imageBackground.value || syncingSize.value || window.moving) return;

		const background = new DomNodesMedia(imageBackground.value);
		const foreground = new DomNodesMedia(element.value);

		const { cropSize: bgCropSize, originalSize: bgImgSize } = background.nodeData;
		const {
			cropSize: fgCropSize,
			cropPosition: fgCropPosition,
			originalPosition: fgImgPosition,
			originalSize: fgImgSize,
		} = foreground.nodeData;
		syncingSize.value = true;

		sizeAdjustmentData.value.width = fgImgSize.width;
		sizeAdjustmentData.value.height = fgImgSize.height;

		sizeAdjustmentData.value.crop.size.width = fgCropSize.width;
		sizeAdjustmentData.value.crop.size.height = fgCropSize.height;

		sizeAdjustmentData.value.crop.position.x = fgCropPosition.x;
		sizeAdjustmentData.value.crop.position.y = fgCropPosition.y;
		const oldCropSizeWidth = sizeAdjustmentData.value.crop.size.width;
		const oldCropSizeHeight = sizeAdjustmentData.value.crop.size.height;

		// Las imagenes completas(sin el div que hace de recorte) queremos que siempre estén sincronizadas por lo que siempre que cambie el tamaño del background
		// seteamos el tamaño del crop.size(tamaño completo) del background (o el tamaño visible si no tiene crop) al foreground
		sizeAdjustmentData.value.crop.size.width = bgCropSize.width || bgImgSize.width;
		sizeAdjustmentData.value.crop.size.height = bgCropSize.height || bgImgSize.height;

		positionAdjustmentData.value.x = fgImgPosition.x;
		positionAdjustmentData.value.y = fgImgPosition.y;
		// ratio entre el tamaño total de la imagen y la parte visible(cropeada)
		const ratioWidth = sizeAdjustmentData.value.width / oldCropSizeWidth;
		const ratioHeight = sizeAdjustmentData.value.height / oldCropSizeHeight;

		// Para calcular el nuevo tamaño que debe tener el crop respecto al tamaño completo de la imagen
		// multiplicamos el tamaño total de la image por el ratio de recorte que tenía
		const newCropWidth = sizeAdjustmentData.value.crop.size.width * ratioWidth;
		const newCropHeight = sizeAdjustmentData.value.crop.size.height * ratioHeight;

		// Aplicamos dicha cantidad
		sizeAdjustmentData.value.width = newCropWidth;
		sizeAdjustmentData.value.height = newCropHeight;

		// Cálculamos la posición que deber tener el crop para que siga recortando la misma parte de la imagen visualmente
		const newCropX = MathTools.ruleOfThree(
			oldCropSizeWidth,
			sizeAdjustmentData.value.crop.position.x,
			sizeAdjustmentData.value.crop.size.width
		);
		const newCropY = MathTools.ruleOfThree(
			oldCropSizeHeight,
			sizeAdjustmentData.value.crop.position.y,
			sizeAdjustmentData.value.crop.size.height
		);

		// Tenemos que mover también el elemento para que visualmente siga coincidiendo con su background
		// para ello movemos la misma cantidad que hemos movido la crop del x e y
		const newX = newCropX - fgCropPosition.x;
		const newY = newCropY - sizeAdjustmentData.value.crop.position.y;

		sizeAdjustmentData.value.crop.position.x = newCropX;
		sizeAdjustmentData.value.crop.position.y = newCropY;

		// Aplicamos las posiciones calculadas
		positionAdjustmentData.value.x -= newX;
		positionAdjustmentData.value.y -= newY;

		const finalData = { position: positionAdjustmentData.value, ...sizeAdjustmentData.value };

		syncingSize.value = false;
		return finalData;
	};

	const syncPositionWithBackground = async () => {
		if (!imageBackground.value || syncingPosition.value || window.moving) return;
		syncingPosition.value = true;
		await nextTick();

		const boundingImage = isPreview
			? imageBackground.value
					?.domPreviewNode(previewName)
					?.querySelector(`.cropped-${previewName}`)
					?.getBoundingClientRect()
			: imageBackground.value?.domNode()?.querySelector('img')?.getBoundingClientRect();

		const boundingClone = isPreview
			? element.value.domPreviewNode(previewName)?.querySelector('img')?.getBoundingClientRect()
			: element.value.domNode()?.querySelector('img')?.getBoundingClientRect();

		if (boundingImage && boundingClone) {
			const originalX = boundingImage.x;
			const originalY = boundingImage.y;
			const clonedX = boundingClone.x;
			const clonedY = boundingClone.y;
			// a la previa le vienen ya los elementos escalados, por lo que no debemos de tener en cuenta la escala
			// Para corregirlo usamos la diferencia entre las x e y de los bounding de la img ( que siempre tiene el tamaño original)
			// y dependiendo del angulo de rotación le hacemos la correción que necesita
			const diffX = isPreview ? originalX - clonedX : (originalX - clonedX) / store.scale;
			const diffY = isPreview ? originalY - clonedY : (originalY - clonedY) / store.scale;

			positionAdjustmentData.value.x = element.value.position.x;
			positionAdjustmentData.value.y = element.value.position.y;
			if (Math.abs(diffX) > 0.02 || Math.abs(diffY) > 0.02) {
				element.value.position.x += diffX;
				element.value.position.y += diffY;
			}
		}

		syncingPosition.value = false;
	};

	const syncingSize = ref(false);
	const syncSizeWithBackground = () => {
		if (!imageBackground.value || syncingSize.value || window.moving) return;

		syncingSize.value = true;
		const oldCropSizeWidth = element.value.crop.size.width;
		const oldCropSizeHeight = element.value.crop.size.height;

		// Las imagenes completas(sin el div que hace de recorte) queremos que siempre estén sincronizadas por lo que siempre que cambie el tamaño del background
		// seteamos el tamaño del crop.size(tamaño completo) del background (o el tamaño visible si no tiene crop) al foreground
		element.value.crop.size.width = imageBackground.value?.crop.size.width || imageBackground.value?.size.width;
		element.value.crop.size.height = imageBackground.value?.crop.size.height || imageBackground.value?.size.height;

		// ratio entre el tamaño total de la imagen y la parte visible(cropeada)
		const ratioWidth = element.value.size.width / oldCropSizeWidth;
		const ratioHeight = element.value.size.height / oldCropSizeHeight;

		// Para calcular el nuevo tamaño que debe tener el crop respecto al tamaño completo de la imagen
		// multiplicamos el tamaño total de la image por el ratio de recorte que tenía
		const newCropWidth = element.value.crop.size.width * ratioWidth;
		const newCropHeight = element.value.crop.size.height * ratioHeight;

		// Aplicamos dicha cantidad

		element.value.size.width = newCropWidth;
		element.value.size.height = newCropHeight;

		// Cálculamos la posición que deber tener el crop para que siga recortando la misma parte de la imagen visualmente
		const newCropX = MathTools.ruleOfThree(
			oldCropSizeWidth,
			element.value.crop.position.x,
			element.value.crop.size.width
		);
		const newCropY = MathTools.ruleOfThree(
			oldCropSizeHeight,
			element.value.crop.position.y,
			element.value.crop.size.height
		);
		// Tenemos que mover también el elemento para que visualmente siga coincidiendo con su background
		// para ello movemos la misma cantidad que hemos movido la crop del x e y
		const newX = newCropX - element.value.crop.position.x;
		const newY = newCropY - element.value.crop.position.y;

		// Aplicamos las posiciones calculadas
		element.value.position.x -= newX;
		element.value.position.y -= newY;

		element.value.crop.position.x = newCropX;
		element.value.crop.position.y = newCropY;

		syncingSize.value = false;
	};
	const watchSourceImage = () => {
		watch(
			position,
			async (newPosition, oldPosition) => {
				if (!newPosition || !oldPosition) return;
				await nextTick();
				syncPositionWithBackground();
			},
			{ deep: true }
		);

		watch(
			size,
			async (newSize, oldSize) => {
				if (!newSize || !oldSize || !imageBackground.value) return;
				syncSizeWithBackground();
				await syncPositionWithBackground();
			},
			{ deep: true }
		);

		watch(
			cropSize,
			async (newSize, oldSize) => {
				if (!newSize || !oldSize || !imageBackground.value) return;
				if (!isCropping.value) return;
				await nextTick();

				syncSizeWithBackground();
				await syncPositionWithBackground();
			},
			{ deep: true }
		);

		watch(
			cropPosition,
			async () => {
				if (!isCropping.value) {
					return;
				}
				await nextTick();

				await syncPositionWithBackground();
			},
			{ deep: true }
		);

		watch(rotation, async (newRotation) => {
			if (newRotation === undefined) {
				return;
			}
			element.value.setRotation(newRotation);
			await syncPositionWithBackground();
		});

		watch(isCropping, async () => {
			await syncPositionWithBackground();
		});

		watch(flipX, async (newFlip) => {
			if (newFlip === undefined) return;

			flipAxis('x');
			await nextTick();

			await syncPositionWithBackground();
		});

		watch(flipY, async (newFlip) => {
			if (newFlip === undefined) return;

			flipAxis('y');
			await nextTick();

			await syncPositionWithBackground();
		});

		watch(backgroundIndex, async (newIndex, prevIndex) => {
			await nextTick();
			if (newIndex < prevIndex) {
				return;
			}

			if (elementIndex.value > newIndex) {
				moveUp();
				return;
			}
			const elements = store.activePage?.elementsAsArray() || [];
			const index = elements?.findIndex((el) => el.id === imageBackground.value?.id) || -1;

			moveElementBetweenIndex(elements[index], elements[index + 1]);
		});
	};

	onMounted(async () => {
		await nextTick();
		if (!imageBackground.value || isRenderingContext) return;
		syncSizeWithBackground();
		await syncPositionWithBackground();
	});

	return {
		watchSourceImage,
		getSizeWithBackground,
		syncPositionWithBackground,
		syncSizeWithBackground,
		positionForeground,
		getPositionWithBackground,
		fgPositionX,
		fgPositionY,
		sizeForeground,
		position,
		cropPositionForeground,
		cropSizeForeground,
	};
};
