import { computed, Ref } from 'vue';

import { useTransform } from '@/elements/element/composables/useTransform';
import { Text } from '@/elements/texts/text/classes/Text';

export const useTextTransform = (text: Ref<Text>) => {
	const usingTransform = useTransform(text);
	const { bottom, right, top, left } = usingTransform;

	const isPartiallyOutside = computed(() => {
		const partiallyOutsideBottom = text.value.position.y > bottom.value;
		const partiallyOutsideRight = text.value.position.x > right.value;
		const partiallyOutsideTop = text.value.position.y < top.value;
		const partiallyOutsideLeft = text.value.position.x < left.value;
		return partiallyOutsideBottom || partiallyOutsideRight || partiallyOutsideTop || partiallyOutsideLeft;
	});

	return {
		...usingTransform,
		isPartiallyOutside,
	};
};
