import Bugsnag from '@bugsnag/js';
import sha256 from 'crypto-js/sha256';
import { diff, jsonPatchPathConverter } from 'just-diff';
import { cloneDeep, findIndex, uniq } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

import Page from '@/page/classes/Page';
import Project from '@/project/classes/Project';
import { SerializedPageState } from '@/Types/history';
import { Unit } from '@/Types/types';

export class HistoryState {
	id: string;
	index: number;
	pages: Page[];
	width: number;
	height: number;
	scale: number;
	unit: Unit;
	date: number;

	constructor(state: Project, index: number, previous: HistoryState | null) {
		this.id = uuidv4();
		this.index = index;
		this.width = state.size.width;
		this.height = state.size.height;
		this.unit = state.unit;
		this.scale = state.scale;

		this.pages = cloneDeep(state.pages);
		this.date = Math.round(new Date().getTime() / 1000);
	}

	static generateSyncData(
		serverVersions: { [p: string]: Page },
		pagesAtSync: { [p: string]: Page },
		fullSyncRequests: string[] = [],
		scale: number
	): SerializedPageState[] {
		// Filter pages that arent in the server, or doesnt have the same version hash
		return Object.values(pagesAtSync).map((page): SerializedPageState => {
			// Como podemos estar aplicando una escala al documento para evitar problemas de memoria en IOS, vamos a clonar
			// cada página y vamos a quitarle la escala que tiene aplicada para la sesión "visual" del editor
			const clone = cloneDeep(page);
			clone.scaleBy(1 / scale);

			const elements = Object.fromEntries(clone.elements);
			// Convertimos los elementos a un objeto para que se serialice correctamente y el diff no de problemas
			// tanto en el estado actual como en el serverVersion

			clone.elements = elements;
			const serverVersion = serverVersions[page.id] && cloneDeep(serverVersions[page.id]);
			let serverVersionElement;
			if (serverVersion) {
				serverVersionElement = Object.fromEntries(serverVersions[page.id].elements);
				serverVersion.elements = serverVersionElement;
			}

			const content = !serverVersions[page.id] || fullSyncRequests.includes(clone.id) ? JSON.stringify(clone) : null;
			const newDiff = !content ? diff(serverVersion, clone, jsonPatchPathConverter) : null;
			const pagePosition = findIndex(Object.values(pagesAtSync), (p) => page.id === p.id);

			return {
				id: page.id,
				hash: HistoryState.hash(page),
				diff: newDiff,
				content,
				position: pagePosition,
			};
		});
	}

	private static hash(page: Page): string {
		let content = `${page.id}`;

		page.elements.forEach((element) => {
			content = `${content}${element.type}-${element.id}`;
		});

		return sha256(content).toString();
	}
}
