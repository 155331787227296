<script setup lang="ts">
import { ref } from 'vue';

import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';
import { useProjectControls } from '@/project/composables/useProjectControls';
import { useProjectStore } from '@/project/stores/project';

const { trans } = useI18n();
const project = useProjectStore();
const { overLimitToDownload } = useProjectControls();
const open = ref(true);
</script>
<template>
	<Popper v-if="overLimitToDownload && open" placement="bottom" class="z-10">
		<div
			class="mx-4 mt-14 flex rounded-lg bg-yellow-600/90 py-2 pl-1 pr-2 text-white shadow-lg backdrop-blur lg:mx-0 lg:mt-3 lg:h-9 lg:items-center lg:rounded-full"
		>
			<span class="mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-white lg:h-7 lg:w-7">
				<SvgIcon name="publish" class="mx-3 h-3 w-3 shrink-0 text-yellow-600" />
			</span>
			<p class="text-sm leading-5 lg:leading-none">
				{{
					trans(
						"Currently, it's only possible to download videos with a maximum of 3 minutes. Please, consider reducing the length of your project to be able to download it in mp4."
					)
				}}
			</p>
			<button
				class="ml-3 flex h-6 w-6 items-center justify-center rounded-full text-white hover:bg-yellow-800"
				@click="open = false"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>
	</Popper>
</template>
