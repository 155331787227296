<script setup lang="ts">
import { ref } from 'vue';

import { getBrandKitCollections } from '@/api/UserApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import { useToast } from '@/common/composables/useToast';
import AppendReplaceModal from '@/editor/components/AppendReplaceModal.vue';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { TemplateApiData } from '@/Types/apiClient';

const props = defineProps<{ brandKitSlug: string; collectionSlug: string }>();

const { trans } = useI18n();
const toast = useToast();
const project = useProjectStore();
const store = useMainStore();

const selectedTemplate = ref<TemplateApiData | null>(null);
const modalSelected = ref('');
const url = ref(`brand-kit/${props.brandKitSlug}/collection/${props.collectionSlug}?page=1`);

const { data: templatesData, isFetching: templatesIsFetching } = getBrandKitCollections(url, { refetch: true });

const loadMore = async () => {
	if (templatesIsFetching.value || !templatesData.value?.links?.next) return;

	url.value = templatesData.value.links.next;
};

const openTemplate = (item: TemplateApiData) => {
	if (item.uuid === project.id) {
		toast.warning(trans('You are already inside this template'));
		return;
	}

	selectedTemplate.value = item;
	modalSelected.value = 'open';

	// Actualizamos la categoría del template para la pantalla de descarga
	store.downloadTemplateCategory = null;
};

const onCloseModal = () => {
	selectedTemplate.value = null;
	modalSelected.value = '';
};
</script>

<template>
	<div class="h-full">
		<InfiniteLoading
			:data="(templatesData && templatesData.data) || []"
			:is-fetching="templatesIsFetching"
			class="flex flex-col"
			with-masonry
			:masonry-cols="2"
			@load="loadMore"
		>
			<template #item="{ item }">
				<button
					v-if="'uuid' in item"
					class="group relative mb-2 w-full rounded bg-gray-700"
					:style="{ aspectRatio: `${item.previews[0]?.width || 1000} / ${item.previews[0]?.height || 1000}` }"
					@click.stop="openTemplate(item)"
				>
					<img
						:src="item.previews[0]?.thumb"
						:alt="item.name"
						class="inset-0 w-full cursor-pointer rounded bg-gray-600 object-contain"
						draggable="false"
					/>

					<div v-if="item.uuid === project.id" class="absolute left-0 top-0 h-full w-full">
						<div class="h-full rounded border-2 border-blue-500 slidesgo:border-purple-400" />
					</div>
				</button>
			</template>
		</InfiniteLoading>

		<AppendReplaceModal
			v-if="!!selectedTemplate && modalSelected === 'open'"
			:selected-template="selectedTemplate"
			@close="onCloseModal"
		/>
	</div>
</template>
