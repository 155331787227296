<script lang="ts" setup>
import { computed, ref, toRef, watch } from 'vue';

import { useFramesProject } from '@/composables/useFramesProject';
import { useViewer } from '@/editor/composables/useViewer';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import FilterOverlay from '@/elements/medias/filter/components/FilterOverlay.vue';
import { Video } from '@/elements/medias/video/classes/Video';
import { usePage } from '@/page/composables/usePage';

const props = defineProps<{ element: Video }>();
const element = toRef(props, 'element');

const hasSvgFilter = computed(() => element.value.filter && element.value.filter.toSvgFilter().length > 0);

const { frame, getSrcToVideoFrame, urlFrames } = useFramesProject();

const nextFrame = ref<string | null>(null);
const { page } = usePageElement(element);
const { position: positionPage } = usePage(page);
const { onViewerChange } = useViewer();
const isVisibleInViewer = ref(!positionPage.value);
onViewerChange((pageViewerActive) => {
	if (pageViewerActive.id !== page.value.id) {
		isVisibleInViewer.value = false;
		return;
	}

	isVisibleInViewer.value = true;
});

const srcFrameRender = ref<string | null>(nextFrame);

watch(
	[frame, urlFrames, isVisibleInViewer],
	async () => {
		const nextFrameToRender = await getSrcToVideoFrame(element.value, isVisibleInViewer.value);
		// Si esta visible(se renderiza su page) y el frame es el mismo que tenía forzamos un renderizado
		// esto puede suceder por ejemplo cuando tenemos un proyecto a 60fps y el video va a 20fps por lo que no se
		// producen cambios de imagen y tenemos que forzar el renderizado
		if (nextFrameToRender && isVisibleInViewer.value && nextFrameToRender === nextFrame.value) {
			window.dispatchEvent(new Event('force-frame'));
		}

		nextFrame.value = nextFrameToRender;
	},
	{ immediate: true }
);
</script>

<template>
	<div
		:style="{
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
			clipPath: 'inset(0)',
			opacity: element.opacity,
		}"
		class="group"
	>
		<div
			:style="({
				width: `${element.size.width}px`,
				height: `${element.size.height}px`,
				clipPath: element.mask ? `url(#${element.id}-${element.mask.id})` : null,
				transform: `
        	scaleX(${element.flipHTML.x})
        	scaleY(${element.flipHTML.y})
				`
			} as any)"
		>
			<div
				:style="{
					width: `${element.crop.size.width || element.size.width}px`,
					height: `${element.crop.size.height || element.size.height}px`,
					transform: `translate(${element.crop.position.x}px,${element.crop.position.y}px)`,
				}"
			>
				<img
					:src="srcFrameRender || ''"
					class="absolute h-full w-full"
					:style="{
						filter: hasSvgFilter ? `url(#filter-${element.id})` : '',
						transform: `scaleX(${element.flipHTML.x}) scaleY(${element.flipHTML.y})`,
					}"
				/>
				<FilterOverlay v-if="element.filter?.overlay" :image="element" :overlay="element.filter.overlay" />

				<svg v-if="element.mask" width="0" height="0">
					<clipPath
						:id="`${element.id}-${element.mask.id}`"
						clipPathUnits="objectBoundingBox"
						v-html="element.mask.content"
					></clipPath>
				</svg>
				<svg v-if="hasSvgFilter" width="0" height="0">
					<defs>
						<filter :id="'filter-' + element.id" v-html="element.filter?.toSvgFilter()"></filter>
					</defs>
				</svg>
			</div>
		</div>
	</div>
</template>
