import { computed, Ref } from 'vue';

import { TransformTools } from '@/elements/element/utils/TransformTools';
import { useArtboard } from '@/project/composables/useArtboard';
import { FullSize, Size } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useArtboardCustomSize = (fullSize: Ref<FullSize>) => {
	const { isValidSize, maxArtboardSize, MIN_ARTBOARD_SIZE, MM_TO_PX } = useArtboard();

	const ARTBOARD_LIMIT_MESSAGE = {
		MAX: {
			MM: `${Math.round(Math.sqrt(maxArtboardSize.value) / MM_TO_PX)} x ${Math.round(
				Math.sqrt(maxArtboardSize.value) / MM_TO_PX
			)} mm`,
			PX: `${Math.round(Math.sqrt(maxArtboardSize.value))} x ${Math.round(Math.sqrt(maxArtboardSize.value))} px`,
		},
		MIN: {
			MM: `${Math.round(Math.sqrt(MIN_ARTBOARD_SIZE) / MM_TO_PX)} x ${Math.round(
				Math.sqrt(MIN_ARTBOARD_SIZE) / MM_TO_PX
			)} mm`,
			PX: `${Math.round(Math.sqrt(MIN_ARTBOARD_SIZE))} x ${Math.round(Math.sqrt(MIN_ARTBOARD_SIZE))} px`,
		},
	};

	const maxValue = computed(() =>
		Math.round(Math.sqrt(maxArtboardSize.value) / (fullSize.value.unit === 'px' ? 1 : MM_TO_PX))
	);

	const minValue = computed(() => Math.round(fullSize.value.unit === 'px' ? Math.sqrt(MIN_ARTBOARD_SIZE) : 1));

	const isValid = computed(() => isValidSize(fullSize.value.width, fullSize.value.height, fullSize.value.unit));

	const getClampedSizeKeepingAspectRatio = (key: 'width' | 'height', value: number, currentSize: Size) => {
		let size;

		if (key === 'width') {
			size = TransformTools.getSizeKeepingAspectRatioByWidth(
				currentSize,
				MathTools.clamp(value, minValue.value, maxValue.value)
			);

			if (size.height < minValue.value) {
				size = TransformTools.getSizeKeepingAspectRatioByHeight(size, minValue.value);
			} else if (size.height > maxValue.value) {
				size = TransformTools.getSizeKeepingAspectRatioByHeight(size, maxValue.value);
			}
		}

		if (key === 'height') {
			size = TransformTools.getSizeKeepingAspectRatioByHeight(
				currentSize,
				MathTools.clamp(value, minValue.value, maxValue.value)
			);

			if (size.width < minValue.value) {
				size = TransformTools.getSizeKeepingAspectRatioByWidth(size, minValue.value);
			} else if (size.width > maxValue.value) {
				size = TransformTools.getSizeKeepingAspectRatioByWidth(size, maxValue.value);
			}
		}

		if (!size) return;

		return { width: Math.round(size.width), height: Math.round(size.height), unit: fullSize.value.unit };
	};

	const getSizeKeepingAspectRatio = (key: 'width' | 'height', value: number, currentSize: Size) => {
		const size =
			key === 'width'
				? TransformTools.getSizeKeepingAspectRatioByWidth(currentSize, value)
				: TransformTools.getSizeKeepingAspectRatioByHeight(currentSize, value);

		return { width: Math.round(size.width), height: Math.round(size.height), unit: fullSize.value.unit };
	};

	return {
		ARTBOARD_LIMIT_MESSAGE,
		maxValue,
		minValue,
		isValid,
		getClampedSizeKeepingAspectRatio,
		getSizeKeepingAspectRatio,
	};
};
