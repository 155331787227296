export const AIPromptStyles = {
	noStyle: [],
	photo: ['f/1.2, 85 mm, dslr photography'],
	digitalArt: ['Extremely detailed vector, creative, digital art'],
	threeD: ['3d extremely detailed, octane render'],
	painting: ['Oil painting, creative, extremely detailed brush stroke'],
};

export const RandomPrompts = [
	'A cute grey cat in a floral field',
	'A fox in the snow in the middle of the forest',
	'A terrifying clown in a dark room',
	'A magical unicorn in the space',
	'An ancient rome landscape',
	'A human robot in a futuristic city',
	'A lovely witch in a haunted house',
	'A friendly alien in an extraordinary planet',
];
