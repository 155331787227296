<script setup lang="ts">
import { InputHTMLAttributes, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Box } from '@/elements/box/classes/Box';
import { useBoxStroke } from '@/elements/box/composables/useBoxStroke';
import { TypeBorder } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

// Props
const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');

// Using composables
const { t: trans } = useI18n();
const { isMobile } = useDeviceInfo();
const { updateTypeBorder, percentCalculated, setSizeByPercent } = useBoxStroke(element);
const { closePanel } = usePanelManagement();

// Data
const INPUT_CONFIG = {
	MAX: 100,
	MIN: 0,
	STEP: 1,
	TYPE: 'range',
};

// Methods
const onInput = (e: Event) => {
	const newPercent = MathTools.clamp((e.target as InputHTMLAttributes).value, INPUT_CONFIG.MIN, INPUT_CONFIG.MAX);
	setSizeByPercent(newPercent);
};

const onReset = () => {
	updateTypeBorder(TypeBorder.None);
	closePanel(props.element);
};
</script>

<template>
	<div
		class="mb-1 w-screen rounded-tl-xl rounded-tr-xl bg-gray-700/90 px-3 pb-2 pt-4 backdrop-blur lg:z-50 lg:mb-2 lg:w-48 lg:rounded lg:bg-white lg:py-2 lg:shadow-xl"
	>
		<div v-if="isMobile" class="-mt-3 mb-4 flex justify-between">
			<div class="flex items-center p-1">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Stroke') }}</h2>
				<button
					class="flex items-center text-xs text-gray-100 hover:text-white"
					:class="{ 'pointer-events-none opacity-50': element.border.type === TypeBorder.None }"
					@click="onReset"
				>
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					{{ trans('Reset') }}
				</button>
			</div>
			<button
				data-testid="close-panel"
				class="mt-2 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="closePanel(element)"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>
		<div class="mb-4 grid max-w-[60%] grid-cols-4 gap-2 lg:mb-2 lg:max-w-none">
			<button
				data-testid="remove-border-btn"
				class="flex aspect-square items-center justify-center rounded"
				:class="{
					'border-2 border-blue-500 text-white slidesgo:border-purple-400 lg:text-blue-500':
						element.border.type === TypeBorder.None,
					'border border-gray-500 text-gray-300 hover:text-gray-100 lg:border-gray-100 lg:text-gray-600 lg:hover:text-gray-700':
						element.border.type !== TypeBorder.None,
					'text-gray-100': isMobile,
				}"
				@click="updateTypeBorder(TypeBorder.None)"
			>
				<SvgIcon name="empty" class="h-4 w-4" />
			</button>
			<button
				data-testid="solid-border-btn"
				:tooltip="trans('Border stroke')"
				class="flex aspect-square items-center justify-center rounded border"
				:class="{
					'border-2 border-blue-500 text-white slidesgo:border-purple-400 lg:text-blue-500':
						element.border.type === TypeBorder.Solid,
					'border border-gray-500 text-gray-300 hover:text-gray-100 lg:border-gray-100 lg:text-gray-600 lg:hover:text-gray-700':
						element.border.type !== TypeBorder.Solid,
					'text-gray-100': isMobile,
				}"
				@click="updateTypeBorder(TypeBorder.Solid)"
			>
				<SvgIcon name="border-dasharray-0" class="h-4 w-4" />
			</button>
			<button
				data-testid="dashed-border-btn"
				class="flex aspect-square items-center justify-center rounded border"
				:class="{
					'border-2 border-blue-500 text-white slidesgo:border-purple-400 lg:text-blue-500':
						element.border.type === TypeBorder.Dashed,
					'border border-gray-500 text-gray-300 hover:text-gray-100 lg:border-gray-100 lg:text-gray-600 lg:hover:text-gray-700':
						element.border.type !== TypeBorder.Dashed,
					'text-gray-100': isMobile,
				}"
				@click="updateTypeBorder(TypeBorder.Dashed)"
			>
				<SvgIcon name="border-dasharray-2" class="h-4 w-4" />
			</button>
			<button
				data-testid="dotted-border-btn"
				class="flex aspect-square items-center justify-center rounded border"
				:class="{
					'border-2 border-blue-500 text-white slidesgo:border-purple-400 lg:text-blue-500':
						element.border.type === TypeBorder.Dotted,
					'border border-gray-500 text-gray-300 hover:text-gray-100 lg:border-gray-100 lg:text-gray-600 lg:hover:text-gray-700':
						element.border.type !== TypeBorder.Dotted,
					'text-gray-100': isMobile,
				}"
				@click="updateTypeBorder(TypeBorder.Dotted)"
			>
				<SvgIcon name="border-dasharray-3" class="h-6 w-6" />
			</button>
		</div>
		<p class="hidden text-sm text-gray-500 lg:block">{{ trans('Border weight') }}</p>
		<div class="flex flex-1 items-center">
			<div class="input-range relative flex w-full">
				<input
					data-testid="border-box-input"
					:max="INPUT_CONFIG.MAX"
					:min="INPUT_CONFIG.MIN"
					:step="INPUT_CONFIG.STEP"
					:type="INPUT_CONFIG.TYPE"
					class="input-range h-1 w-full cursor-pointer appearance-none rounded-full bg-gray-300 focus:outline-none lg:bg-gray-100/50"
					:value="percentCalculated"
					@input="onInput"
				/>
				<span
					:style="{
						width: `${percentCalculated}%`,
					}"
					class="absolute left-0 top-0 h-1 rounded-full bg-blue-500 slidesgo:bg-purple-400"
				></span>
			</div>
			<div
				data-testid="border-box"
				class="ml-2 w-6 whitespace-nowrap text-right text-sm text-gray-100 lg:text-gray-600"
			>
				{{ percentCalculated }}
			</div>
		</div>
	</div>
</template>
<style lang="sass" scoped>
.input-range::-webkit-slider-thumb:hover
	@apply bg-blue-600
</style>
