<script setup lang="ts">
import { computed, defineAsyncComponent, ref, watch } from 'vue';

import { useUsersnap } from '@/analytics/usersnap/composables/useUsersnap';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import DownloadStatus from '@/export/download/components/DownloadStatus.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import CanvasNavigationButton from '@/layout/components/CanvasNavigationButton.vue';
import TemplateSuggestionsPanel from '@/layout/components/TemplateSuggestionsPanel.vue';
import ZoomControls from '@/layout/components/ZoomControls.vue';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

defineProps<{
	width: number;
	left: number;
}>();

const Help = defineAsyncComponent(() => import('@/layout/components/Help.vue'));

const project = useProjectStore();

const { selection, clearSelection } = useSelection();
const { downloads } = useDownloadsProject();
const { addEmptyPage } = useProject();
const { isAdminMode, isPhotoMode, isIllustratorContext, isEmbeddedContext, isSlidesgoMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { openReportBug } = useUsersnap();
const { trans } = useI18n();

const canvasNavigationIsOpen = ref(false);

const showCanvasNavigationButton = computed(() => {
	if (isSlidesgoMode.value) return true;

	if (isMobile.value) {
		return project.pages.length > 1 && !isIllustratorContext.value && !selection.value.length;
	}

	return project.pages.length > 1 && !isIllustratorContext.value;
});

watch(
	() => project.pages.length,
	() => {
		if ((project.pages.length > 1 || isSlidesgoMode.value) && !isMobile.value) {
			canvasNavigationIsOpen.value = true;
		}
	},
	{ immediate: true }
);

const nuevaPagina = () => {
	clearSelection();
	addEmptyPage();
	canvasNavigationIsOpen.value = true;
};
</script>

<template>
	<div
		class="pointer-events-none absolute right-0 z-20 flex items-end justify-between p-4 sm:left-0"
		:class="{
			'bottom-2 sm:bottom-0': project.pages.length <= 1 || !canvasNavigationIsOpen,
			'bottom-36': (project.pages.length > 1 || isSlidesgoMode) && canvasNavigationIsOpen,
		}"
	>
		<DownloadStatus v-if="downloads.size" />
		<div v-if="!isPhotoMode" class="flex-1">
			<TemplateSuggestionsPanel v-if="isAdminMode" class="pointer-events-auto mb-2 hidden w-fit gap-2 lg:grid" />
			<button
				v-if="!isEmbeddedContext"
				:data-onboarding="project.pages.length <= 1 ? 'pages' : null"
				data-testid="addNewPage bottom"
				class="flex h-10 w-10 items-center justify-center rounded-full bg-gray-800/90 text-sm font-semibold text-gray-100 backdrop-blur hover:bg-gray-700 sm:h-auto sm:w-auto sm:rounded sm:px-4 sm:py-2"
				:class="{
					'pointer-events-none opacity-0': project.pages.length > 1 || isSlidesgoMode,
					'pointer-events-auto': project.pages.length <= 1,
				}"
				@click="nuevaPagina()"
			>
				<div v-if="!isMobile" class="flex items-center">
					<SvgIcon name="plus" class="mr-2 h-4 w-4" /> {{ trans('Add new page') }}
				</div>
				<div v-else>
					<SvgIcon name="pages" class="h-6 w-6" />
					<div class="absolute left-3 top-4 flex items-center justify-center">
						<SvgIcon name="plus" class="h-3 w-3" />
					</div>
				</div>
			</button>
		</div>

		<div class="absolute flex flex-1 gap-2 lg:fixed lg:right-4 lg:justify-end" :class="'pointer-events-auto'">
			<ZoomControls />
			<Help />
			<button
				v-if="!isAdminMode"
				class="pointer-events-auto hidden h-10 items-center rounded bg-gray-800/90 px-3 text-sm font-semibold text-gray-100 backdrop-blur hover:bg-blue-600 hover:text-white lg:flex"
				@click="openReportBug"
			>
				<SvgIcon name="alert" class="mr-2 h-4 w-4" /> {{ trans('Report an issue') }}
			</button>
		</div>
	</div>
	<CanvasNavigationButton
		v-if="showCanvasNavigationButton"
		:open="canvasNavigationIsOpen"
		class="sticky bottom-0 z-20"
		:class="{ 'h-[139px]': !isMobile && canvasNavigationIsOpen }"
		@close="canvasNavigationIsOpen = false"
		@open="canvasNavigationIsOpen = true"
	/>
</template>
