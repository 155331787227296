import Bugsnag from '@bugsnag/js';
import { cloneDeep } from 'lodash';
import { Ref } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import { CroppeableElement } from '@/elements/medias/crop/types/croppeable.type';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import NotPossibleInPhotoModeException from '@/elements/medias/replace/Exception/NotPossibleInPhotoModeException';
import { Video } from '@/elements/medias/video/classes/Video';
import { VideoTools } from '@/elements/medias/video/utils/VideoTools';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useActivePage } from '@/page/composables/useActivePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { ImageApi, UploadApi, VideoApi } from '@/Types/apiClient';
import { DraggableItemData } from '@/Types/types';

/**
 * This function is used to replace an element with another element.
 * @param element - Ref<Image | Video>
 * @returns An object with a function called replaceElement.
 */
export const useReplaceElement = (element: Ref<Image | Video>) => {
	const store = useMainStore();
	const project = useProjectStore();

	const { setArtboardSize, maxArtboardSize } = useArtboard();
	const { isPhotoMode } = useEditorMode();
	const { foreground } = useLayersImage(element as Ref<Image>);
	const { page } = usePageElement(element);
	const { setupElementInPage } = useAddInsertableElement();
	const { removeElement, addElement } = useActivePage();
	const { fitZoomScale } = useZoom();

	/**
	 * "Adjust the crop size of the target element to the given height, keeping the aspect ratio."
	 *
	 * The function is a bit more complicated than that, but that's the gist of it
	 * @param {CroppeableElement} target - CroppeableElement - the element to be cropped
	 * @param {number} height - The height of the target element.
	 */
	const adjustCropToHeight = (target: CroppeableElement, height: number) => {
		target.crop.size = TransformTools.getSizeKeepingAspectRatioByHeight(target.crop.size, height);
	};

	/**
	 * "Adjust the crop size of the target element to the given width, keeping the aspect ratio."
	 *
	 * The function is a bit more complicated than that, but that's the gist of it
	 * @param {CroppeableElement} target - CroppeableElement
	 * @param {number} width - The width of the element you want to crop.
	 */
	const adjustCropToWidth = (target: CroppeableElement, width: number) => {
		target.crop.size = TransformTools.getSizeKeepingAspectRatioByWidth(target.crop.size, width);
	};

	/**
	 * If the media is an image, return the preview URL, otherwise return the URL.
	 * @param {ImageApi | UploadApi} apiMedia - The media object from the API.
	 * @returns The url to load the image.
	 */
	const getUrlToLoad = (apiMedia: ImageApi | VideoApi | UploadApi) => {
		if (apiMedia.type === 'image') {
			return apiMedia.preview || apiMedia.url;
		}
		return apiMedia.url;
	};

	/**
	 * Fit element crop (position and size) regarding the element size
	 * @param {CroppeableElement} target - CroppeableElement - The element that is being cropped.
	 */
	const fitCrop = (target: CroppeableElement) => {
		const cropRatio = target.crop.size.width / target.crop.size.height;
		const prevRatio = target.size.width / target.size.height;

		if (prevRatio >= 1) {
			if (cropRatio >= prevRatio) {
				adjustCropToHeight(target, element.value.size.height);
			} else {
				adjustCropToWidth(target, element.value.size.width);
			}
		} else {
			if (cropRatio < prevRatio) {
				adjustCropToWidth(target, element.value.size.width);
			} else {
				adjustCropToHeight(target, element.value.size.height);
			}
		}

		target.crop.position = {
			x: target.crop.size.width * -0.5 + target.size.width * 0.5,
			y: target.crop.size.height * -0.5 + target.size.height * 0.5,
		};
	};

	/**
	 * It takes a target element and an API media object, and it replaces the target element's image with
	 * the API media object's image, while keeping the target element's aspect ratio
	 * @param {CroppeableElement} target - CroppeableElement - The cropped element to be replaced
	 * @param {ImageApi | UploadApi} apiMedia - The media object that was uploaded or selected from the
	 * media library.
	 */
	const fitAndCenterOnReplace = async (target: CroppeableElement, apiMedia: ImageApi | VideoApi | UploadApi) => {
		// Get state to locate it correctly later
		const prevSize = {
			width: element.value.size.width,
			height: element.value.size.height,
		};

		// Set element to load size in order to fix aspect ratio
		const urlToLoad = getUrlToLoad(apiMedia);
		const { width, height } = await TransformTools.getRealSizeFrom(urlToLoad, apiMedia.type);
		target.crop.size = { width, height };
		target.size = prevSize;

		fitCrop(target);
	};

	/**
	 * Replaces image by antoher image from media library or upload
	 * @param {ImageApi | UploadApi} apiData - ImageApi | UploadApi
	 */
	const replaceImageByImage = async (apiData: ImageApi | UploadApi) => {
		if (!(element.value instanceof Image)) return;

		// Unlock image temporarily to set position and size on replace
		const wasLocked = element.value.locked;
		if (element.value.locked) element.value.setLocked(false);

		element.value.url = apiData.url;
		element.value.flip.x = false;
		element.value.flip.y = false;
		element.value.preview = apiData.preview;
		element.value.metadata = apiData.metadata || {};

		element.value.metadata.imageApi = { ...apiData, data: [], links: [] };
		delete element.value.metadata.imageApi.metadata;

		const isPhotoModeImage = element.value.id === page.value?.backgroundImageId;
		isPhotoMode.value && isPhotoModeImage && wasLocked
			? await replacePhotoModeImage(apiData)
			: await fitAndCenterOnReplace(element.value, apiData);

		if (wasLocked) element.value.setLocked(true);
		Bugsnag.leaveBreadcrumb(`Replace image-${element.value.id}: ${apiData.url}`);

		if (foreground.value) {
			page.value.elements.delete(foreground.value.id);
		}

		element.value.urlBackgroundRemoved = apiData.backgroundRemoved || null;
		element.value.backgroundMode = 'original';

		if (element.value.mask && element.value.mask.isPlaceholder) element.value.mask.isPlaceholder = false;
		if (!element.value.opacity) element.value.opacity = 1;
	};

	/**
	 * It replaces the current image|video with a new video|image, and tries to keep the same position,
	 * rotation, and other properties
	 * @param {ImageApi | VideoApi | UploadApi} apiData - ImageApi | UploadApi
	 */
	const replaceMedia = async (apiData: ImageApi | VideoApi | UploadApi) => {
		if (isPhotoMode.value) {
			throw new NotPossibleInPhotoModeException('You cannot replace a image with a video in this mode');
		}
		const media = apiData.type === 'video' ? await Video.fromApi(apiData) : await Image.fromApiImage(apiData);

		if (!(media instanceof Image) && !(media instanceof Video)) {
			throw new Error('Replace media failed because inserted element is not Image or Video');
		}
		media.filter = element.value.filter;
		media.group = element.value.group;
		media.locked = element.value.locked;
		media.opacity = element.value.opacity;
		media.position = element.value.position;
		media.rotation = element.value.rotation;

		if (element.value.mask) {
			media.mask = cloneDeep(element.value.mask);
		}

		await fitAndCenterOnReplace(media, apiData);
		addElement(media);

		setupElementInPage(media);
		project.ignoreAutoSave?.(() => {
			removeElement(element.value);
		});
	};

	/**
	 * It replaces the current video with a new one
	 * @param {UploadApi} apiData - UploadApi
	 */
	const replaceVideoByVideo = async (apiData: UploadApi) => {
		if (!(element.value instanceof Video)) return;

		// Unlock video temporarily to set position and size on replace
		const wasLocked = element.value.locked;
		if (element.value.locked) element.value.setLocked(false);

		const metadata = await VideoTools.getMetadata(apiData.url);
		element.value.url = getUrlToLoad(apiData);
		element.value.cropTime.end = metadata.duration;
		element.value.flip.x = false;
		element.value.flip.y = false;
		element.value.preview = apiData.preview;
		element.value.metadata = apiData.metadata || {};

		element.value.metadata.imageApi = { ...apiData, data: [], links: [] };
		delete element.value.metadata.imageApi.metadata;

		await fitAndCenterOnReplace(element.value, apiData);

		if (wasLocked) element.value.setLocked(true);
		Bugsnag.leaveBreadcrumb(`Replace video-${element.value.id}: ${apiData.url}`);
	};

	/**
	 * It replaces the current photo mode image with a new one
	 * @param {ImageApi | UploadApi} apiMedia - ImageApi | UploadApi
	 */
	const replacePhotoModeImage = async (apiMedia: ImageApi | UploadApi) => {
		// pausamos el autosave durante el ajuste de la imagen en el artboard
		project.pauseAutoSave?.();

		// Set load image size in order to fix aspect ratio
		const { width, height } = await TransformTools.getRealSizeFrom(apiMedia.url || apiMedia.preview, apiMedia.type);
		let newWidth = width;
		let newHeight = height;

		if (newWidth * newHeight > maxArtboardSize.value) {
			const ratio = newWidth / newHeight;

			newWidth = Math.floor(Math.sqrt(ratio * maxArtboardSize.value));
			newHeight = Math.floor(newWidth / ratio);
		}

		setArtboardSize(newWidth, newHeight, 'px');

		// reanudamos el autosave
		project.resumeAutoSave?.();

		store.$patch(() => {
			element.value.crop.position = { x: 0, y: 0 };
			element.value.crop.size = { width: 0, height: 0 };
			element.value.setPosition(0, 0);
			element.value.setRotation(0);
			element.value.setSize(newWidth, newHeight);
		});

		fitZoomScale();
	};

	/**
	 * Entry point to orchestrate the replace process regarding the type of the element
	 * @param {DraggableItemData} apiData - The data returned from the API.
	 */
	const replaceElement = async (apiData: DraggableItemData) => {
		if (element.value instanceof Image) {
			if (apiData.type === 'image') {
				await replaceImageByImage(apiData);
			}
			if (apiData.type === 'video') {
				await replaceMedia(apiData);
			}
		}

		if (element.value instanceof Video) {
			if (apiData.type === 'image') {
				await replaceMedia(apiData);
			}
			if (apiData.type === 'video') {
				await replaceVideoByVideo(apiData);
			}
		}

		// Add more cases in the future if needed
	};

	return { replaceElement };
};
