<script lang="ts" setup>
import { useTimeoutFn } from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useSharedMouse } from '@/common/composables/useSharedMouse';
import { useEditorClipboard } from '@/editor/composables/useEditorClipboard';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useElementRenderStyles } from '@/elements/element/composables/useElementRenderStyles';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { useText } from '@/elements/texts/text/composables/useText';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextHeight } from '@/elements/texts/text/composables/useTextHeight';
import { getTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useTextTransform } from '@/elements/texts/text/composables/useTextTransform';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';

const store = useMainStore();
const { selection, selectionId, selectionOrdered } = useSelection();

const { hideContentClipboard, restoreContentClipboard } = useEditorClipboard();
const {
	setCursorPosition,
	handleContent,
	scrollIntoView,
	handleBlur,
	fixScrollOnTyping,
	updateBox,
	initListeners,
	editable,
	textEditing,
	exitTextEditingIfPreviouslyActive,
	updateTextContent,
} = useTextEditing();
const { fitHeight } = useText(textEditing);

const { stopPropagation } = useOrderedKeyboardListener();
const { isPartiallyOutside } = useTextTransform(textEditing);

stopPropagation('Delete');
stopPropagation('Backspace');
stopPropagation(['z', 'Z']);
stopPropagation(['y', 'Y']);
stopPropagation(['c', 'C']);
stopPropagation(['v', 'V']);

const { onBeforeClearSelection } = useInteractions();

const { isMac, isMobile, isAndroid, isFirefox, isSafari } = useDeviceInfo();
const mouse = useSharedMouse();

const initialScroll = ref({ x: 0, y: 0 });
const scrollArea = ref();
const { onZoomFinish } = useZoom();
const project = useProjectStore();
const { isCircleText } = useCircleTypeInfo(textEditing);
const { height } = useTextHeight(editable);

onMounted(() => {
	project.pauseAutoSave?.();

	scrollArea.value = document.querySelector('#scroll-area');
	initialScroll.value = { x: scrollArea.value?.scrollLeft || 0, y: scrollArea.value?.scrollTop || 0 };

	editable.value.focus();
	setCursorPosition();

	// Al montarse el componente, si el texto sobresale parcialmente del canvas, se produce scroll. Ejecutamos fixScroll.
	if (isPartiallyOutside.value) {
		fixScrollOnTyping();
	}

	if (isMobile.value) {
		useTimeoutFn(scrollIntoView, 800);
	}

	initListeners();

	// Vaciamos el clipboard por si tiene un elemento copiado no exponer toda la estructura de datos en caso de pegar
	hideContentClipboard();
});

// Actualizamos la altura del texto por si tiene cambios de altura debido a los colores
watch(height, () => {
	fitHeight(editable.value);
});

onBeforeUnmount(() => {
	// Restauramos el clipboard
	restoreContentClipboard();

	// Ejecutamos la función handleBlur aquí también porque en firefox
	// se destruye el componente antes de hacer el blur al hacer clearSelection
	isFirefox && handleBlur({ x: mouse.x.value, y: mouse.y.value });

	if (isAndroid)
		useTimeoutFn(() => {
			scrollArea.value.scrollTo(initialScroll.value.x, initialScroll.value.y);
		}, 300);

	project.resumeAutoSave?.();
});

onBeforeClearSelection((e) => {
	handleBlur({ x: e.clientX, y: e.clientY });
});

const textStyles = getTextStyles(textEditing);
const { styles } = useElementRenderStyles(textEditing);

const outlinedTextStyles = computed(() => TextTools.getOutlinedTextStyles(textEditing.value, textStyles.value));
const isTextShadowText = computed(() => textStyles.value.textShadow?.length);
const isOutlinedText = computed(() => TextTools.haveOutlinedText(textStyles.value));

// En Safari macOS, cuando haces doble click sobre un texto, se selecciona por completo,
// haces click dentro de la caja sin tocar el texto para quitar la selección completa
// y luego haces click sobre otro elemento, el evento blur no se dispara y el texto
// desaparece. Esto fuerza que se ejecute el handleBlur vigilando la selección.
if (isMac.value && isSafari) {
	watch(selectionId, () => {
		handleBlur({ x: mouse.x.value, y: mouse.y.value });
	});
}

const editingCss = computed(() => {
	if (!textEditing.value) return '';
	return '.moveable-area { pointer-events: none !important; }';
});

onZoomFinish(() => {
	updateTextContent();
	handleContent();
});

onUnmounted(() => exitTextEditingIfPreviouslyActive());
</script>

<template>
	<div class="notranslate absolute left-0 top-0" translate="no" :style="styles">
		<div
			v-if="!isCircleText && (isOutlinedText || isTextShadowText)"
			ref="outlinedEditable"
			:style="textStyles"
			contenteditable
			class="editable-outlined-text pointer-events-none absolute"
			v-html="textEditing ? textEditing.content : ''"
		></div>
		<div
			:id="`editable-${textEditing ? textEditing.id : undefined}`"
			ref="editable"
			:style="isOutlinedText || isTextShadowText ? outlinedTextStyles : textStyles"
			contenteditable
			class="user-select-all text-element-final z-1 absolute w-full break-normal outline-none"
			@blur="handleBlur({ x: mouse.x.value, y: mouse.y.value })"
			@keydown="updateBox"
			@dragstart.prevent
			v-html="textEditing ? textEditing.content : ''"
		></div>
		<component :is="'style'" v-if="editingCss.length" type="text/css">
			{{ editingCss }}
		</component>
	</div>
</template>

<style>
[contenteditable] {
	-webkit-user-select: text;
	user-select: text;
}
html:not(.color-picking) [contenteditable]::selection,
html:not(.color-picking) [contenteditable] *::selection {
	/* @apply text-black; */
	-webkit-text-fill-color: rgb(0, 0, 0);
}
.color-picking [contenteditable],
.color-picking [contenteditable] * {
	@apply selection:bg-black/10 !important;
}

/* actúa sobre la primera línea de los textos, anulamos así su background para que sea visible el estilo aplicado arriba */
.color-picking [contenteditable]:not([style*='gradient']) {
	background-color: rgba(0, 0, 0, 0);
}
</style>
