<script lang="ts" setup>
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';

const { isTagMode, mode } = useEditorMode();

const toggleMode = () => {
	mode.value = isTagMode.value ? 'admin' : 'tag';
};
</script>

<template>
	<button
		class="m-2 flex flex-col items-center justify-center rounded hover:text-white sm:px-6 lg:h-16 lg:w-16 lg:px-0"
		:class="isTagMode ? 'bg-yellow-500 text-white' : 'text-gray-100'"
		@click="toggleMode"
	>
		<SvgIcon name="tag" class="mb-1 h-6 w-6 fill-current lg:h-5 lg:w-5" />
		<span class="text-2xs leading-normal">Tag mode</span>
	</button>
</template>

<style scoped></style>
