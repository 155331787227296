<script setup lang="ts">
// Classes
// Composables
import { toRef } from 'vue';

// Components
import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useI18n } from '@/i18n/useI18n';
const { trans } = useI18n();

// Props
const props = defineProps<{ element: Element }>();
const emit = defineEmits(['flip']);
const element = toRef(props, 'element');

// Using composables
const { flipAxis } = useElementTransformOrchestrator(element).value;

const onClickFlipX = () => {
	flipAxis('x');
	emit('flip');
};
const onClickFlipY = () => {
	flipAxis('y');
	emit('flip');
};
</script>

<template>
	<button
		data-testid="flip-x"
		:tooltip="trans('Flip Horizontal')"
		tooltip-position="top"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-10 lg:w-10 lg:justify-center"
		@click="onClickFlipX"
	>
		<SvgIcon name="flip-hor" class="h-4 w-4 scale-90 lg:scale-100" />
		<span class="text-2xs lg:hidden">{{ trans('Flip') }} Hor.</span>
	</button>
	<button
		data-testid="flip-y"
		:tooltip="trans('Flip Vertical')"
		tooltip-position="top"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-10 lg:w-10 lg:justify-center"
		@click="onClickFlipY"
	>
		<SvgIcon name="flip-ver" class="h-4 w-4 scale-90 lg:scale-100" />
		<span class="text-2xs lg:hidden">{{ trans('Flip') }} Ver.</span>
	</button>
</template>
