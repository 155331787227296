<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { until } from '@vueuse/core';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useCustomImagesActions } from '@/api/composables/useUploadImagesActions';
import AutocompleteInput from '@/common/components/AutocompleteInput.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import FreepikVideos from '@/elements/medias/images/image/components/panels/add/FreepikVideos.vue';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useProjectStore } from '@/project/stores/project';
import { VideoApi } from '@/Types/apiClient';
import { Panels } from '@/Types/types';

// Emtis
const emits = defineEmits<{
	(e: 'closeModal'): void;
	(e: 'selection', video: VideoApi): void;
}>();

// Props
const props = defineProps<{ disableClose?: boolean; modal?: boolean }>();

// Data
const search = ref('');

// Using composables
const store = useMainStore();
const project = useProjectStore();
const { isPhotoMode } = useEditorMode();
const { userUploads, selectFromLocal, isUploading, uploadImages, uploadImageInput, isInvalidFile } =
	useUserImageProvider();
const { runOnMobile } = useDeviceInfo();
const { addInsertableElement } = useAddInsertableElement();
const { trans } = useI18n();

// Computeds
const fixedSearch = computed(() => search.value || project.flaticonSearch || project.category || '');
const mimeTypesAccepted = computed(() => {
	const videoTypes = ['video/mp4, video/webm, video/quicktime, .mov, video/3gpp, .3gp'];

	return videoTypes.join(',');
});

// Solo para provider Google Photos
// -------------------------------------------------------------------------------------------------
const { uploadImage } = useCustomImagesActions();
const toast = useToast();
const { onFetchResponse: onUploadResponse, onFetchError: onUploadError, data: videoApi } = uploadImage();

onUploadResponse(() => {
	userUploads.value.unshift(videoApi.value);
	toast.success(trans('Image uploaded sucessfully'));
});

onUploadError(() => {
	toast.error(trans('Something went wrong with the upload'));
});

const autocompleteSource = computed(() => `autocomplete/freepik?search=${fixedSearch.value}`);

// -------------------------------------------------------------------------------------------------

// Methods
const onCloseModal = () => {
	emits('closeModal');
};

const onZeroResults = (val: boolean) => {
	GAnalytics.trackGA4('search_editor', {
		category: 'Videos',
		search_term: `${search.value}`,
		zero_results: `${val}`,
	});
};

const onSearch = (val: string) => {
	search.value = val;
};

/**
 * Dependiendo de si el componente esta siendo usado en modal, emitimos o insertamos la imagen
 * @param $event
 */
const upload = async ($event: Event) => {
	uploadImages($event, !props.modal);
	if (props.modal) {
		await until(isUploading).toBe(false);
		if (isInvalidFile.value) return;
		emits('selection', userUploads.value[0] as VideoApi);
	}
	store.activePanel = Panels.uploads;
};
const selectVideo = async (videoApi: VideoApi) => {
	if (props.modal) {
		emits('selection', videoApi);
		return;
	}

	await addInsertableElement(videoApi);

	runOnMobile(() => (store.activePanel = null));
};

const updateSearchValue = (searchValue: string) => {
	onSearch(searchValue);

	if (!searchValue) {
		Bugsnag.leaveBreadcrumb('Remove search value');
		return;
	}
	Bugsnag.leaveBreadcrumb(`Search in videos: ${searchValue}`);
};
</script>

<template>
	<div id="photos-panel" class="flex h-full flex-col">
		<PanelHeader
			:disable-close="disableClose"
			:modal="modal"
			:title="modal ? 'Select video' : 'Videos'"
			@closeModal="onCloseModal"
		/>
		<input
			id="upload-img"
			ref="uploadImageInput"
			class="hidden"
			type="file"
			:accept="mimeTypesAccepted"
			multiple
			@change="upload"
		/>
		<button
			class="mb-3 flex h-12 shrink-0 items-center justify-center rounded border-blue-500 bg-blue-500 px-5 font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
			:class="{ 'cursor-not-allowed opacity-50': isUploading }"
			:disabled="isUploading"
			@click="selectFromLocal"
		>
			<span v-if="isUploading" class="relative flex h-full w-full items-center gap-4">
				<span class="absolute top-0 left-0 flex h-full items-center">
					<SvgIcon data-testid="spinner" name="spinner" class="h-5 w-5 animate-spin" />
				</span>
				<div class="flex-1 text-center leading-tight">
					{{ trans('Updating...') }}
				</div>
			</span>
			<span v-else class="relative flex h-full w-full items-center gap-4">
				<span class="absolute top-0 left-0 flex h-full items-center">
					<SvgIcon data-testid="upload-image" name="upload-image" class="h-4 w-4" />
				</span>
				<div class="flex-1 text-center leading-tight">
					{{ trans('Upload video') }}
				</div>
			</span>
		</button>
		<AutocompleteInput
			:placeholder="trans('Search Video')"
			:query="search"
			:autocomplete-source="autocompleteSource"
			:modal="modal"
			@change="updateSearchValue"
		/>
		<FreepikVideos
			:search="search"
			:modal="modal"
			@select="selectVideo"
			@search="onSearch"
			@zero-results="onZeroResults"
		/>
	</div>
</template>
