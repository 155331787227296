<script setup lang="ts">
import { computed, CSSProperties, onMounted, ref, toRef } from 'vue';

import { SolidColor } from '@/color/classes/SolidColor';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { DownloadFormat } from '@/Types/types';

const { isIOS } = useDeviceInfo();

// Si modificas este archivo recuerda también modificar el del backend
// por incompatibilidad de momento no podemos usar el mismo, puede que
// terminando de migrar a vue 3 en el backend se solucione, ya que
// actualmente usamos @vue/compat para retrocompatibilidad
const props = defineProps<{
	element: Text;
	inRenderingContext: boolean;
	isPresentation: boolean;
	textStyles: Partial<CSSProperties>;
	isOutlinedText: boolean;
	isTextShadowText: boolean;
	editing: boolean;
	outlinedTextStyles: Partial<CSSProperties>;
	isPreview: boolean;
	previewName?: string;
	textShadowStylesIos?: Partial<CSSProperties>;
}>();

const element = toRef(props, 'element');
const isViewer = props.previewName === 'viewer';
const text = ref<HTMLDivElement | null>();
const textHasLink = ref(false);

const emit = defineEmits<{ (e: 'endInteraction', event?: MouseEvent): void }>();
const { downloadFormat } = useDownloadsProject();
const stylesToRealTextNode = computed(() => {
	//  En caso de que estemos en IOS y el texto sea curvo, le volvemos a pasar los estilos de las sombras al nodo del texto real,
	//  para que el observer del useCircleText se entere de los cambios realizados sobre las sombras
	const styles =
		props.element.curvedProperties.minArc && isIOS.value
			? { ...props.textStyles, textShadow: TextTools.textShadowToCssString(props.element.textShadow) }
			: props.textStyles;

	return styles;
});

const stylesToLinkTextNode = computed(() => {
	const styles = {
		...props.textStyles,
		textShadow: undefined,
		WebkitTextStroke: undefined,
		WebkitFilter: undefined,
		color: SolidColor.transparent().toCssString(),
	};
	return styles;
});
defineExpose({ text });
const checkIfHasLink = () => {
	if (!['viewer', 'render'].includes(props.previewName || '')) return;
	const domNode = props.element.domPreviewNode(props.previewName);
	if (!domNode) return;

	const anchorElements = Array.from(domNode.querySelectorAll('a'));
	textHasLink.value = anchorElements.some((a) => a.href);
};

onMounted(() => {
	checkIfHasLink();
});
</script>

<template>
	<div
		class="notranslate h-full w-full"
		translate="no"
		:data-use-double-click="true"
		:style="{ opacity: props.element.opacity }"
		@mouseup="(event) => emit('endInteraction', event)"
		@touchend.prevent="emit('endInteraction')"
		@keyup="emit('endInteraction')"
	>
		<!-- Texto real -->
		<div
			v-show="!editing || isPreview"
			ref="text"
			data-testid="real-text"
			:class="[element.curvedProperties.minArc ? 'opacity-0' : '', { 'pointer-events-none': !isPresentation }]"
			class="text-element-final original-text absolute z-10 w-full"
			:style="stylesToRealTextNode"
			v-html="props.element.content"
		></div>
		<!-- !Se coloca por delante para que el color del texto sea visible en caso de tener aplicado outline 
		! este texto ha de renderizarse si tenemos sombras también, para que el color esté por encima de las sombras (Gradientes) -->
		<div
			v-if="isOutlinedText || isTextShadowText"
			v-show="!editing || isPreview"
			data-testid="outline-text"
			:class="element.curvedProperties.minArc ? 'opacity-0' : ''"
			class="outline-text pointer-events-none absolute z-20 w-full"
			:style="outlinedTextStyles"
			v-html="props.element.content"
		></div>
		<!-- Para que los pdf links funcionen -->
		<div
			v-if="((inRenderingContext && downloadFormat === DownloadFormat.pdf) || isViewer) && textHasLink"
			data-testid="text-link"
			class="text-element-final absolute"
			:class="{
				'opacity-0': element.curvedProperties.minArc,
				'z-50': inRenderingContext || isViewer,
			}"
			:style="stylesToLinkTextNode"
			v-html="props.element.content"
		></div>

		<!-- Texto que renderiza las sombras en IOS en caso de que el texto contenga outline (IOS no renderiza correctamente las sombras si el elemento
			tiene también outline - este texto tiene un color transparente aplicado, por lo que solo serán visible las sombras) -->
		<div
			v-if="isIOS && textShadowStylesIos && isTextShadowText && isOutlinedText"
			v-show="!editing"
			data-testid="text-shadow-ios"
			:class="[element.curvedProperties.minArc ? 'opacity-0' : '', { 'pointer-events-none': !isPresentation }]"
			class="text-shadow-ios pointer-events-none absolute z-0 w-full"
			:style="textShadowStylesIos"
			v-html="props.element.content"
		></div>
	</div>
</template>
