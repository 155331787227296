<script lang="ts" setup>
import { computed } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import AlternativePaletteEditAdminTools from '@/color/components/AlternativePaletteEditAdminTools.vue';
import PaletteVariant from '@/color/components/PaletteVariant.vue';
import { useProjectColors } from '@/color/composables/useProjectColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useProjectStore } from '@/project/stores/project';

const emit = defineEmits(['paletteSelected']);

const store = useMainStore();
const { isAdminMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { palette } = useProjectColors();
const palettes = computed(() => store.colorPalettes.filter((p) => p.active || isAdminMode.value));

const saveColorPalette = () => {
	const color_palette = palette.value.map((colorCollection: any) => {
		return {
			...colorCollection,
			color: colorCollection.color.type
				? GradientColor.unserialize(colorCollection.color)
				: SolidColor.unserialize(colorCollection.color),
		};
	});
	const mostUsedColor = color_palette
		.filter((colorCollection: any) => colorCollection.color instanceof SolidColor)
		.reduce((a, b) => (a.ids.length > b.ids.length ? a : b))
		.color.toHex();

	const variant = {
		color_palette,
		id: null,
		name: '',
		main_color: mostUsedColor,
		active: true,
	};
	store.colorPalettes.push(variant as any);
};
</script>

<template>
	<div data-testid="alternative-palettes-container">
		<hr v-if="!isMobile" class="my-6 border-gray-700" />
		<h4 v-if="!isMobile" class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">Palettes</h4>
		<div class="grid w-screen grid-cols-3 gap-4 px-4 lg:w-auto lg:grid-cols-2 lg:px-0">
			<div v-for="(palette, id) in palettes" :key="'alternative-' + palette.id" class="flex w-full flex-col">
				<p v-if="!isAdminMode" class="mb-2 text-sm text-gray-300">
					{{ palette.name || 'Unnamed' }}
				</p>

				<AlternativePaletteEditAdminTools v-if="isAdminMode" :id="id" :palette="palette" />

				<PaletteVariant :palette="palette" @paletteSelected="emit('paletteSelected')" />
			</div>
		</div>

		<button
			v-if="isAdminMode && store.colorPalettes.length > 0"
			data-testid="add-palette-btn"
			class="mt-4 w-full rounded border-2 border-gray-600 p-2 text-center text-gray-300"
			@click="saveColorPalette"
		>
			<span>Add current palette</span>
		</button>

		<div v-if="isAdminMode && store.colorPalettes.length < 1" class="rounded bg-yellow-300 p-2 text-center text-sm">
			Para añadir una variante primero tienes que guardar el template para generar la variante original.
		</div>
	</div>
</template>
