<script setup lang="ts">
import { useAdminActions } from '@/admin/composables/useAdminActions';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';

const { createPredefinedText } = useAdminActions();
const toast = useToast();
const { onFetchResponse, onFetchError, isFetching, createText } = createPredefinedText();

onFetchResponse(() => toast.success('Predefined text created'));
onFetchError(() => toast.error('Something went wrong'));
</script>

<template>
	<div>
		<button
			data-testid="predefined-text-save-btn"
			class="relative flex h-10 w-8 items-center justify-center rounded bg-blue-500 font-bold text-white hover:bg-yellow-600 slidesgo:bg-purple-400 lg:w-auto lg:px-6 lg:py-3"
			@click="createText"
		>
			<span v-if="isFetching">
				<SvgIcon name="blank" class="h-4 w-4 animate-spin" />
			</span>
			<span v-else>Create Text</span>
		</button>
	</div>
</template>
