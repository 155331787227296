<script lang="ts" setup>
import { watchDebounced } from '@vueuse/shared';
import { computed, ref, toRef, watch } from 'vue';

import { getElementsByType } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import LoadingImage from '@/common/components/LoadingImage.vue';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { DataType } from '@/Types/apiClient';
import { DraggableItemData, ElementsPanelTypes, InsertableApiType } from '@/Types/types';
const { trans } = useI18n();

const props = defineProps<{
	query: string;
}>();

const emits = defineEmits(['zeroResults']);

interface Tab {
	enabled: boolean;
	label: string;
	selected: boolean;
	type: 'all' | ElementsPanelTypes;
}
const tabs = ref<Tab[]>([
	{
		label: 'All',
		type: 'all',
		selected: true,
		enabled: true,
	},
	{
		label: 'Icons',
		type: ElementsPanelTypes.Flaticon,
		selected: false,
		enabled: true,
	},
	{
		label: 'Stickers',
		type: ElementsPanelTypes.Stickers,
		selected: false,
		enabled: true,
	},
	{
		label: 'Illustrations',
		type: ElementsPanelTypes.Storysets,
		selected: false,
		enabled: true,
	},
	{
		label: 'Basic Shapes',
		type: ElementsPanelTypes.BasicShapes,
		selected: false,
		enabled: true,
	},
	{
		label: 'Masks',
		type: ElementsPanelTypes.ImageMasks,
		selected: false,
		enabled: true,
	},
	{
		label: 'Lines and Arrows',
		type: ElementsPanelTypes.LinesAndArrows,
		selected: false,
		enabled: true,
	},
]);

const query = toRef(props, 'query');

const data = ref<DataType[]>([]);

const debouncedQuery = ref('');

const fetchOptions = { concat: false, immediate: true, refetch: true };

const pages = ref({
	basicShapes: 1,
	flaticon: 1,
	masks: 1,
	stickers: 1,
	storysets: 1,
	lines: 1,
});

const {
	isFetching,
	isFetchingBasicShapes,
	isFetchingFlaticons,
	isFetchingMasks,
	isFetchingStickers,
	isFetchingStorysets,
	isFetchingLines,
	basicShapes,
	flaticons,
	masks,
	stickers,
	storysets,
	lines,
} = getElementsByType(pages, fetchOptions, debouncedQuery, true);
const { addInsertableElement } = useAddInsertableElement();

const isSelectedAllTab = computed(() => tabs.value.find((t) => t.type === 'all')?.selected);

const isSelectedBasicShapesTab = computed(
	() => tabs.value.find((t) => t.type === ElementsPanelTypes.BasicShapes)?.selected
);

const isSelectedFlaticonTab = computed(() => tabs.value.find((t) => t.type === ElementsPanelTypes.Flaticon)?.selected);

const isSelectedMasksTab = computed(() => tabs.value.find((t) => t.type === ElementsPanelTypes.ImageMasks)?.selected);

const isSelectedStickersTab = computed(() => tabs.value.find((t) => t.type === ElementsPanelTypes.Stickers)?.selected);

const isSelectedStorysetsTab = computed(
	() => tabs.value.find((t) => t.type === ElementsPanelTypes.Storysets)?.selected
);

const isSelectedLinesAndArrowsTab = computed(
	() => tabs.value.find((t) => t.type === ElementsPanelTypes.LinesAndArrows)?.selected
);
watchDebounced(
	query,
	async (newVal) => {
		if (newVal === debouncedQuery.value) return;
		data.value = [];
		resetPage();
		debouncedQuery.value = newVal;
	},
	{ debounce: 500 }
);

watch(isFetching, (newVal, oldVal) => {
	if (newVal && !oldVal) return;
	updateData();
	updateTabsVisibility();
});

const onClickTab = (tabType: 'all' | ElementsPanelTypes) => {
	tabs.value.forEach((t) => (t.selected = t.type === tabType));
};

const onLoadMore = async () => {
	if (isFetching.value) return;

	loadNextBasicShapePage();
	loadNextFlaticonPage();
	loadNextMaskPage();
	loadNextStickersPage();
	loadNextStorysetPage();
	loadNextLinesAndArrowsPage();
};

const loadNextBasicShapePage = () => {
	const shouldLoadNextBasicShapePage = !!(
		((!isFetchingBasicShapes.value && isSelectedBasicShapesTab.value) ||
			(!isFetching.value && isSelectedAllTab.value)) &&
		basicShapes.value?.links?.next
	);

	if (shouldLoadNextBasicShapePage) pages.value.basicShapes += 1;
};

const loadNextFlaticonPage = () => {
	const shouldLoadNextFlaticonPage = !!(
		((!isFetchingFlaticons.value && isSelectedFlaticonTab.value) || (!isFetching.value && isSelectedAllTab.value)) &&
		flaticons.value?.links?.next
	);

	if (shouldLoadNextFlaticonPage) pages.value.flaticon += 1;
};

const loadNextMaskPage = () => {
	const shouldLoadNextMaskPage = !!(
		((!isFetchingMasks.value && isSelectedMasksTab.value) || (!isFetching.value && isSelectedAllTab.value)) &&
		masks.value?.links?.next
	);

	if (shouldLoadNextMaskPage) pages.value.masks += 1;
};

const loadNextStickersPage = () => {
	const shouldLoadNextStickersPage = !!(
		((!isFetchingStickers.value && isSelectedStickersTab.value) || (!isFetching.value && isSelectedAllTab.value)) &&
		stickers.value?.links?.next
	);

	if (shouldLoadNextStickersPage) pages.value.stickers += 1;
};

const loadNextStorysetPage = () => {
	const shouldLoadNextStorysetPage = !!(
		((!isFetchingStorysets.value && isSelectedStorysetsTab.value) || (!isFetching.value && isSelectedAllTab.value)) &&
		storysets.value?.links?.next
	);

	if (shouldLoadNextStorysetPage) pages.value.storysets += 1;
};

const loadNextLinesAndArrowsPage = () => {
	const shouldLoadNextLinesAndArrowsPage = !!(
		((!isFetchingLines.value && isSelectedLinesAndArrowsTab.value) || (!isFetching.value && isSelectedAllTab.value)) &&
		lines.value?.links?.next
	);

	if (shouldLoadNextLinesAndArrowsPage) pages.value.lines += 1;
};

const resetPage = () => {
	pages.value.basicShapes = 1;
	pages.value.flaticon = 1;
	pages.value.masks = 1;
	pages.value.stickers = 1;
	pages.value.storysets = 1;
	pages.value.lines = 1;
};

const updateData = () => {
	// Prepare new items chunk
	const newBasicShapes = (basicShapes.value?.data?.length && basicShapes.value?.data) || [];
	const newFlaticons = (flaticons.value?.data?.length && flaticons.value?.data) || [];
	const newMasks = (masks.value?.data?.length && masks.value?.data) || [];
	const newStickers = (stickers.value?.data?.length && stickers.value?.data) || [];
	const newStorysets = (storysets.value?.data?.length && storysets.value?.data) || [];
	const newLinesAndArrows = (lines.value?.data?.length && lines.value?.data) || [];

	// Set them randomly in array
	const newShuffledChunk = [
		...newBasicShapes,
		...newFlaticons,
		...newMasks,
		...newStickers,
		...newStorysets,
		...newLinesAndArrows,
	].sort(() => 0.5 - Math.random());

	// Merge new chunk with loaded data
	data.value = [...data.value, ...(newShuffledChunk as DataType[])];

	emits('zeroResults', data.value.length === 0);
};

const updateTabsVisibility = () => {
	const basicShapesTab = tabs.value.find((t) => t.type === ElementsPanelTypes.BasicShapes);
	if (basicShapesTab) basicShapesTab.enabled = !!data.value.find((item) => item.type === InsertableApiType.BasicShape);

	const flaticonTab = tabs.value.find((t) => t.type === ElementsPanelTypes.Flaticon);
	if (flaticonTab) flaticonTab.enabled = !!data.value.find((item) => item.type === InsertableApiType.Flaticon);

	const masksTab = tabs.value.find((t) => t.type === ElementsPanelTypes.ImageMasks);
	if (masksTab) masksTab.enabled = !!data.value.find((item) => item.type === InsertableApiType.ImageMask);

	const stickersTab = tabs.value.find((t) => t.type === ElementsPanelTypes.Stickers);
	if (stickersTab) stickersTab.enabled = !!data.value.find((item) => item.type === InsertableApiType.Sticker);

	const storysetTab = tabs.value.find((t) => t.type === ElementsPanelTypes.Storysets);
	if (storysetTab) storysetTab.enabled = !!data.value.find((item) => item.type === InsertableApiType.Storyset);

	const linesAndArrowsTab = tabs.value.find((t) => t.type === ElementsPanelTypes.LinesAndArrows);
	if (linesAndArrowsTab)
		linesAndArrowsTab.enabled = !!data.value.find((item) => item.type === InsertableApiType.BasicShape);

	const everyTabDisabled = tabs.value.every((tab) => !tab.enabled);
	if (everyTabDisabled) {
		const allTab = tabs.value.find((t) => t.type === 'all');
		if (allTab) allTab.enabled = true;
	}
};

const containerClasses = computed(() => {
	if (isSelectedStorysetsTab.value) {
		return 'grid grid-cols-2 gap-2';
	}

	if (isSelectedStickersTab.value) {
		return 'grid grid-cols-3 gap-4';
	}

	return 'grid grid-cols-4 gap-6';
});
</script>

<template>
	<div
		class="mb-4 flex h-10 shrink-0 items-center overflow-x-auto text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
	>
		<div v-for="tab in tabs" :key="tab.type">
			<button
				v-if="tab.enabled"
				class="mr-2 h-6 shrink-0 whitespace-nowrap rounded-full border border-gray-600 px-3 text-xs font-semibold"
				:class="{
					'bg-gray-600 text-white': tab.selected,
					'text-gray-100 hover:bg-gray-600 hover:text-white': !tab.selected,
				}"
				@click="onClickTab(tab.type)"
			>
				{{ trans(tab.label) }}
			</button>
		</div>
	</div>

	<InfiniteLoading
		data-testid="container-items"
		class="mb-2 pb-0"
		:container-classes="containerClasses"
		:data="data"
		:is-fetching="isFetching"
		@load="onLoadMore"
	>
		<template #item="{ item }">
			<InsertableElement
				v-if="
					'type' in item &&
					(isSelectedAllTab ||
						(isSelectedBasicShapesTab && item.type === InsertableApiType.BasicShape) ||
						(isSelectedFlaticonTab && item.type === InsertableApiType.Flaticon) ||
						(isSelectedMasksTab && item.type === InsertableApiType.ImageMask) ||
						(isSelectedStickersTab && item.type === InsertableApiType.Sticker) ||
						(isSelectedStorysetsTab && item.type === InsertableApiType.Storyset) ||
						(isSelectedLinesAndArrowsTab && item.type === InsertableApiType.LineAndArrow))
				"
				:key="`${item.type}-${item.id}`"
				:data="(item as DraggableItemData)"
				:draggable="true"
				:type="(item.type as InsertableApiType)"
				class="relative h-full w-full"
				@click.stop="addInsertableElement(item as DataType)"
			>
				<!-- Basic Shapes + Lines and arrows -->
				<LoadingImage
					v-if="
						'svg' in item &&
						(item.type === InsertableApiType.BasicShape || item.type === InsertableApiType.LineAndArrow)
					"
					:alt="item.name"
					:classes="'object-contain'"
					:src="item.svg"
				/>

				<!-- Flaticon -->
				<LoadingImage
					v-if="'multicolor' in item && item.type === InsertableApiType.Flaticon"
					:alt="item.name"
					:classes="!item.multicolor && !item.isCustom ? 'opacity-50 invert' : ''"
					:data-svg="item.svg"
					:src="item.preview"
				/>

				<!-- Masks -->
				<LoadingImage
					v-if="'mask' in item && item.type === InsertableApiType.ImageMask"
					:alt="item.name"
					:classes="'object-contain'"
					:src="item.mask.element"
				/>

				<!-- Stickers -->
				<LoadingImage
					v-if="'multicolor' in item && item.type === InsertableApiType.Sticker"
					:alt="item.name"
					:src="item.preview"
					:classes="!item.multicolor && !item.isCustom ? 'opacity-50 invert' : ''"
				/>

				<!-- Storyset -->
				<LoadingImage
					v-if="'illustration' in item && item.type === InsertableApiType.Storyset"
					:alt="item.illustration.name"
					:src="item.preview"
					:classes="'h-full rounded bg-gray-700 object-contain'"
				/>
			</InsertableElement>
		</template>
	</InfiniteLoading>
</template>
