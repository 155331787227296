<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { computed, nextTick, ref, toRef, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import BoxToolbar from '@/elements/box/components/toolbars/BoxToolbar.vue';
import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import LineToolbar from '@/elements/line/components/toolbars/LineToolbar.vue';
import CropToolbar from '@/elements/medias/crop/components/toolbars/CropToolbar.vue';
import ForegroundToolbar from '@/elements/medias/images/foreground/components/toolbars/ForegroundToolbar.vue';
import ImageToolbar from '@/elements/medias/images/image/components/toolbars/ImageToolbar.vue';
import VideoToolbar from '@/elements/medias/video/components/toolbars/VideoToolbar.vue';
import QRCodeToolbar from '@/elements/qr-code/components/toolbars/QRCodeToolbar.vue';
import ShapeToolbar from '@/elements/shapes/shape/components/toolbars/ShapeToolbar.vue';
import StorysetToolbar from '@/elements/storyset/components/toolbars/StorysetToolbar.vue';
import { Text } from '@/elements/texts/text/classes/Text';
import TextToolbar from '@/elements/texts/text/components/toolbars/TextToolbar.vue';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useToolbarTarget } from '@/layout/composables/useToolbarTarget';

// Stores
const store = useMainStore();

// Props
const props = defineProps<{ element: Element; visibility: boolean }>();

// Template refs
const toolbar = ref();

// Data
const element = toRef(props, 'element');
const popper = ref();

// Computeds
const croppingId = computed(() => store.croppingId);
const size = computed(() => element.value.size);
const position = computed(() => element.value.position);
const { isPhotoMode } = useEditorMode();

// Using composables
const { target, getPopperPlacement } = useToolbarTarget(element);
const { isCropping, isRotating, isDragging } = useInteractions();
const activePanel = computed(() => store.activePanel);
const activeEditPanel = computed(() => store.editPanel);
const isCurvedText = computed(() => element.value instanceof Text && element.value?.curvedProperties?.arc);
const { isBackground } = useIsBackground(element);

// Watchers
watch(
	[position, isRotating, size, activePanel, activeEditPanel, isCurvedText, isDragging],
	async () => {
		await nextTick();

		if (!popper.value) {
			initPopper();
			return;
		}
		if (isCurvedText.value) {
			popper.value.state.options.placement = 'top';
		}
		requestAnimationFrame(() => {
			popper.value.update();
			popper.value.state.options.placement = getPopperPlacement();
		});
	},
	{ deep: true } // Debe ser deep ya que si se cambia el position o size como propiedades y no como objeto no se va enterar
);

watch(
	[croppingId, element],
	() => {
		requestAnimationFrame(() => initPopper());
	},
	{ immediate: true }
);

// Methods
const componentType = () => {
	if (isCropping.value) return CropToolbar;

	switch (element.value.type) {
		case 'image': {
			return ImageToolbar;
		}
		case 'shape': {
			return ShapeToolbar;
		}
		case 'box': {
			return BoxToolbar;
		}
		case 'storyset': {
			return StorysetToolbar;
		}
		case 'text': {
			return TextToolbar;
		}
		case 'line': {
			return LineToolbar;
		}
		case 'qrcode': {
			return QRCodeToolbar;
		}
		case 'foregroundImage': {
			return ForegroundToolbar;
		}
		case 'video': {
			return VideoToolbar;
		}
	}
};

const initPopper = () => {
	if (!target.value || !toolbar.value) return;
	const finalPlacement = getPopperPlacement();
	// Si el elemento es una imagen background ajustamos el offset del toolbar, ya que este se reposiciona en bottom
	const finalOffset = isBackground.value && !isPhotoMode.value ? [-5, -45] : [0, 5];
	popper.value = createPopper(target.value, toolbar.value, {
		placement: finalPlacement,
		modifiers: [
			{ name: 'offset', options: { offset: finalOffset } },
			{
				name: 'preventOverflow',
				options: {
					tether: false,
				},
			},
			{
				name: 'flip',
				options: {
					fallbackPlacements: ['bottom-end'],
					boundary: document.querySelector('#scroll-area'),
				},
			},
		],
	});
};
</script>

<template>
	<teleport to="#toolbarTarget">
		<div
			ref="toolbar"
			class="toolbar z-20 !mb-2 mockup:!fixed mockup:!bottom-auto mockup:!left-1/2 mockup:!right-auto mockup:!top-14 mockup:!mt-8 mockup:!-translate-x-1/2"
			:class="{
				'pointer-events-none opacity-0': !visibility,
			}"
		>
			<component
				:is="componentType()"
				:element="props.element"
				class="flex h-10 items-center rounded-lg bg-gray-700/90 px-2 shadow-lg backdrop-blur mockup:rounded-lg mockup:bg-white mockup:shadow-xs"
			/>
		</div>
	</teleport>
</template>

<style lang="sass">
.paint-roll-active
	cursor: url('@/assets/svgs/paint-roll-filled.svg') 20 20, auto !important
</style>
