<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import { until, useClipboard, useEventListener, useTextareaAutosize } from '@vueuse/core';
import { computed, onMounted, Ref, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getUserAiWriters, removeAIWriterAnswer } from '@/api/UserApiClient';
import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import { Text } from '@/elements/texts/text/classes/Text';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useI18n } from '@/i18n/useI18n';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useSelection } from '@/interactions/composables/useSelection';
import { AIWriterAnswer, AiWriterInfoApi } from '@/Types/apiClient';
import { AIWriterSelectedAnswer, TextWithValue } from '@/Types/types';

const { error, prompt, generatingContent, getAnswers, onDiscardText } = useAiWriter();
const store = useMainStore();
const { clearSelection } = useSelection();
const { copy } = useClipboard();
const { textarea, input } = useTextareaAutosize();
const { trans } = useI18n();
const { inUseFonts } = useFonts();
const toast = useToast();
const { isMobile } = useDeviceInfo();
const prevAnswers = ref();
const optionsAreOpen = ref(false);
const option = ref();
const initialStateSelectedAnswer = { id: '', prompt: '', generation: '' };
const selectedAnswer = ref<AIWriterSelectedAnswer>({ ...initialStateSelectedAnswer });
const copiedAnswer = ref<string>();
const generatePromptFlag = ref('');

const previewText = ref(Text.create({ fontSize: 10, lineHeight: 1.2 }));
const { addInsertableText: addText, addInsertableAIText } = useAddInsertableElement(previewText as Ref<Text>);

const havePrevAnswerToday = computed(() => {
	return prevAnswers.value && Object.values((prevAnswers.value as AIWriterAnswer).today).length;
});
const havePrevAnswerOtherDay = computed(() => Object.values((prevAnswers.value as AIWriterAnswer).others).length);

onMounted(async () => {
	prompt.value = '';
	prevAnswers.value = await getAnswers();
});

const promptExamples = [
	trans('Ideas to improve the social media presence of my business.'),
	trans('Steps to create an effective marketing plan.'),
	trans('Tips to increase productivity at work.'),
	trans('Questions to ask during a job interview.'),
];

onDiscardText(async () => {
	prevAnswers.value = await getAnswers();
});

const generateAIText = async () => {
	clearSelection();
	const insertableText = {
		text: trans('Add a body text'),
	};
	generatePromptFlag.value = prompt.value;
	await addInsertableAIText(insertableText, true);
	await until(generatingContent).toBe(false);
	const { data } = await getUserAiWriters();
	prevAnswers.value = data.value;
	input.value = '';
	prompt.value = '';
};

const addPrompt = async (textValue: string, generate: boolean) => {
	input.value = textValue;
	optionsAreOpen.value = false;
	prompt.value = input.value;
	if (!generate) return;
	generateAIText();
	const { data } = await getUserAiWriters();
	prevAnswers.value = data.value;
};

const addPrevAnswer = (textValue: string) => {
	const style: TextWithValue = {
		fontFamily: inUseFonts.value[0]?.name || 'Montserrat',
		text: textValue,
	};

	addText(style, true);

	optionsAreOpen.value = false;
};

const handlerOnClickOutside = (ev: Event) => {
	if (ev.target && (ev.target as HTMLElement).closest('.previous-answer')) return;
	optionsAreOpen.value = false;
	selectedAnswer.value = { ...initialStateSelectedAnswer };
};

const deletePrevAnswer = async () => {
	optionsAreOpen.value = false;
	try {
		const { statusCode, error } = await removeAIWriterAnswer(selectedAnswer.value.id);
		if (statusCode.value === 200) {
			toast.success(trans('Your AI-generated response has been successfully deleted'));
		}
		if (error.value) throw new Error();
	} catch (e) {
		toast.error(trans('Your AI-generated could not be deleted'));
	}

	const { data } = await getUserAiWriters();
	prevAnswers.value = data.value;
	selectedAnswer.value = { ...initialStateSelectedAnswer };
};
const onClickOptions = (selected: AIWriterSelectedAnswer) => {
	optionsAreOpen.value = !optionsAreOpen.value;
	if (!optionsAreOpen.value) {
		selectedAnswer.value = { ...initialStateSelectedAnswer };
		return;
	}
	selectedAnswer.value = selected;
};

const copyAnswer = () => {
	copy(selectedAnswer.value.generation);
	copiedAnswer.value = selectedAnswer.value.generation;
	optionsAreOpen.value = false;
	selectedAnswer.value = { ...initialStateSelectedAnswer };

	useEventListener(
		'animationend',
		() => {
			copiedAnswer.value = '';
		},
		{ once: true }
	);
};

watch(generatingContent, (newVal, oldVal) => {
	if (oldVal && !newVal && generatePromptFlag.value.length) {
		GAnalytics.trackGA4('ai-writter_generate', {
			category: 'ai_writer',
			link_text: 'generate',
			prompt: generatePromptFlag.value,
			successfully_generated: `${!!error.value}`,
		});

		generatePromptFlag.value = '';
	}
});
</script>
<template>
	<div class="flex h-full flex-col">
		<PanelHeader title="AI Writer" />
		<div class="mt-4 flex flex-col gap-2 lg:mt-1">
			<textarea
				ref="textarea"
				v-model="input"
				class="max-h-40 resize-none rounded bg-gray-700 px-3 py-3 text-sm text-gray-50 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-purple-400"
				:placeholder="trans('Ask AI to write any text you need')"
				@blur="prompt = input"
			/>

			<button
				class="flex items-center justify-center gap-1 rounded p-2 hover:opacity-90"
				:class="{
					'pointer-events-none cursor-not-allowed bg-gray-600 text-gray-400': !input || input.trim().length < 8,
					'bg-gradient-to-r from-blue-500 to-purple-400 text-white':
						(input && input.trim().length >= 8) || generatingContent,
				}"
				@click="generateAIText()"
			>
				<SvgIcon
					:name="generatingContent ? 'spinner' : 'wand'"
					class="mr-1 h-4 w-4"
					:class="{ 'animate-spin': generatingContent }"
				/>
				{{ generatingContent ? trans('AI is writing...') : trans('Generate') }}
			</button>
			<hr class="mx-1 mt-2 border-gray-600" />
		</div>
		<ReserveSideSize ref="el" data-testid="scroll-container" class="text-gray-800 scrollbar-thumb-gray-600">
			<div class="mt-5 hidden lg:block">
				<h3 class="text-md mb-3 font-semibold text-gray-100">{{ trans('Prompt inspiration') }}</h3>
				<div
					v-for="example in promptExamples"
					:key="example"
					class="my-1 w-full cursor-pointer rounded bg-gray-700 p-3 text-sm text-gray-100 hover:text-white"
					@click="addPrompt(example, true)"
				>
					{{ example }}
				</div>
			</div>
			<div v-if="prevAnswers" class="mt-8">
				<h3 class="text-md mb-3 font-semibold text-gray-100">{{ trans('Your previous answers') }}</h3>

				<template v-if="havePrevAnswerToday">
					<span class="text-sm font-semibold text-gray-300">{{ trans('Today') }}</span>
					<div ref="option" class="pb-2">
						<div
							v-for="{ generation, id, prompt } in prevAnswers.today"
							:key="id"
							class="relative my-1 flex h-12 w-full cursor-pointer rounded py-3 pl-4 pr-1 text-sm text-gray-100"
							:class="{
								'bg-gray-600 text-white': selectedAnswer.generation === generation,
								'bg-gray-700': selectedAnswer.generation !== generation,
							}"
							@click="addPrevAnswer(generation)"
						>
							<div class="previous-answer flex w-full items-center justify-between">
								{{ generation.substring(0, 35) + '...' }}
								<button class="relative p-1" @click.stop="onClickOptions({ id, generation, prompt })">
									<SvgIcon name="more" class="mr-1 h-3 w-3 rotate-90" />
									<div
										v-show="copiedAnswer === generation"
										placement="right"
										class="copy-info absolute -left-10 -top-1 z-50 rounded-md bg-green-500 p-1 px-3 text-xs font-bold text-white opacity-0"
									>
										{{ trans('Copied!') }}
									</div>
								</button>
								<OnClickOutside
									v-if="optionsAreOpen && selectedAnswer.id === id"
									class="absolute bottom-full right-0 z-40 mb-1 ml-5 min-w-max rounded bg-white pb-2 pt-2 text-white shadow-xl"
									@trigger="handlerOnClickOutside($event)"
								>
									<button
										class="mb-1 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100/20"
										@click.stop="addPrevAnswer(selectedAnswer.generation)"
									>
										<SvgIcon name="grid" class="h-4 w-4" />

										<p class="text-left">
											{{ trans('Use on template') }}
										</p>
									</button>
									<button
										v-if="!isMobile"
										class="my-0.5 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-gray-800 hover:bg-gray-100/20"
										@click.stop="copyAnswer"
									>
										<SvgIcon name="copy" class="h-4 w-4" />

										<p class="text-left">{{ trans('Copy') }}</p>
									</button>
									<button
										class="my-0.5 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-gray-800 hover:bg-gray-100/20"
										@click.stop="addPrompt(selectedAnswer.prompt, false)"
									>
										<SvgIcon name="eye" class="h-4 w-4" />

										<p class="text-left">{{ trans('Check this prompt') }}</p>
									</button>
									<button
										class="mb-1 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-red-600 hover:bg-gray-100/20"
										@click.stop="deletePrevAnswer"
									>
										<SvgIcon name="trash" class="h-4 w-4" />

										<p class="text-left">{{ trans('Delete') }}</p>
									</button>
								</OnClickOutside>
							</div>
						</div>
					</div>
				</template>

				<template v-if="havePrevAnswerOtherDay">
					<span class="text-sm font-semibold text-gray-300">{{ trans('Other days') }}</span>
					<div ref="option" class="pb-2">
						<div
							v-for="{ generation, id } in (prevAnswers.others as AiWriterInfoApi[])"
							:key="id"
							class="relative my-1 flex h-12 w-full cursor-pointer rounded py-3 pl-4 pr-1 text-sm text-gray-100"
							:class="{
								'bg-gray-600 text-white': selectedAnswer.generation === generation,
								'bg-gray-700': selectedAnswer.generation !== generation,
							}"
							@click="addPrevAnswer(generation)"
						>
							<div class="previous-answer flex w-full items-center justify-between">
								{{ generation.substring(0, 30) + '...' }}
								<button
									class="relative p-1"
									@click.stop="onClickOptions({ generation, id: id.toString() } as AIWriterSelectedAnswer)"
								>
									<SvgIcon name="more" class="mr-1 h-3 w-3 rotate-90" />
									<div
										v-show="copiedAnswer === generation"
										:arrow="true"
										placement="right"
										class="copy-info absolute -left-10 -top-1 z-50 rounded-md bg-green-500 p-1 px-3 text-xs font-bold text-white opacity-0"
									>
										{{ trans('Copied!') }}
									</div>
								</button>
								<OnClickOutside
									v-if="optionsAreOpen && selectedAnswer.id === id.toString()"
									class="absolute bottom-full right-0 z-40 mb-1 ml-5 min-w-max rounded bg-white pb-2 pt-2 text-white shadow-xl"
									@trigger="handlerOnClickOutside($event)"
								>
									<button
										class="mb-1 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100/20"
										@click.stop="addPrevAnswer(selectedAnswer.generation)"
									>
										<SvgIcon name="grid" class="h-4 w-4" />

										<p class="text-left">
											{{ trans('Use on template') }}
										</p>
									</button>
									<button
										v-if="!isMobile"
										class="my-0.5 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-gray-800 hover:bg-gray-100/20"
										@click.stop="copyAnswer"
									>
										<SvgIcon name="copy" class="h-4 w-4" />

										<p class="text-left">{{ trans('Copy') }}</p>
									</button>
									<button
										class="my-0.5 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-gray-800 hover:bg-gray-100/20"
										@click.stop="addPrompt(selectedAnswer.prompt, false)"
									>
										<SvgIcon name="eye" class="h-4 w-4" />

										<p class="text-left">{{ trans('Check this prompt') }}</p>
									</button>
									<button
										class="mb-1 flex w-full items-center gap-2 rounded px-3 py-1.5 text-sm text-red-600 hover:bg-gray-100/20"
										@click.stop="deletePrevAnswer"
									>
										<SvgIcon name="trash" class="h-4 w-4" />

										<p class="text-left">{{ trans('Delete') }}</p>
									</button>
								</OnClickOutside>
							</div>
						</div>
					</div>
				</template>
			</div>
		</ReserveSideSize>
	</div>
</template>

<style>
.previous-text {
	text-overflow: ellipsis;
}

.copy-info {
	animation-duration: 2s;
	animation-name: copy-info-visibility;
}

@keyframes copy-info-visibility {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.scrollbar-thin::-webkit-scrollbar {
	width: 14px;
	height: 14px;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
	border: 4px solid;
}
</style>
