import { createSharedComposable, useUrlSearchParams } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { computed, Ref, ref, watch } from 'vue';

import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { ApiClient } from '@/api/utils';
import Reports from '@/auth/utils/Reports';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useProjectColors } from '@/color/composables/useProjectColors';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { useText } from '@/elements/texts/text/composables/useText';
import { useHistoryStore } from '@/history/stores/history';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { StopGradient } from '@/Types/colorsTypes';
import StringTools from '@/utils/classes/StringTools';

export const useAdminActions = createSharedComposable(() => {
	const project = useProjectStore();
	const history = useHistoryStore();
	const store = useMainStore();
	const { isAdminMode } = useEditorMode();
	const textRef = ref(Text.create());
	const shapeRef = ref(Shape.create());
	const { fitHeight } = useText(textRef as Ref<Text>);
	const { palette, updateTemplateColorById, colorSelected } = useProjectColors();
	const { APP_API_PATH } = useEnvSettings();
	const { MM_TO_PX } = useArtboard();

	const params = useUrlSearchParams<{
		convert?: number;
		crawl?: number;
		stop?: number;
		report_id?: string;
		report_type?: string;
		report_data?: string;
	}>();

	const showReports = async () => {
		if (!params.report_type) return;

		if (params.report_type === 'shape-over-text') {
			const data = JSON.parse(params.report_data as string) as any;

			data.shapes.forEach((shape: any) => {
				document
					.querySelector(`#element-${shape.id}, [data-source-hash="hash-${shape.hash || -1}"]`)
					?.classList.add('report-error');
			});
		}
	};

	const showTemplateSuggestions = async () => {
		if (!isAdminMode.value) return;

		const performChecks = () => {
			store.templateSuggestions = [];

			Reports.findOverlapingTexts().forEach((report) => {
				// @ts-ignore
				report.data.shapes.forEach((shape: any) => {
					store.templateSuggestions.push({
						element: shape.id,
						message: 'A text is overlapping with a shape',
					});
				});
			});

			Reports.findContentOutside().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'A element is outside of the template',
				});
			});

			Reports.findUnsupportedPatternElement().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'An element has an unsupported pattern associated',
					fix: () => {
						shapeRef.value = report.element;

						const metadata = shapeRef.value.metadata;
						delete metadata.hasInvalidPattern;

						shapeRef.value.metadata = metadata;
					},
				});
			});

			Reports.findWrongTextHeight().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'The text has wrong sizing',
					fix: () => {
						textRef.value = report.element;
						fitHeight();
					},
				});
			});

			Reports.findBlendModes().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'A element is using a blend mode',
				});
			});

			Reports.findTextsWithInvalidFont().forEach((report) => {
				store.templateSuggestions.push({
					element: report.data.element,
					message: 'Invalid font',
					fix: () => {
						textRef.value = report.element;
						textRef.value.setMetadata({});
					},
				});
			});
		};

		if (isAdminMode.value) {
			watch(() => history.activeState, performChecks);
			setTimeout(() => performChecks(), 1000);
		}
	};

	const createPredefinedText = () => {
		const { onFetchResponse, onFetchError, isFetching, post } = useEditorApiFetch('predefined-text', {
			immediate: false,
		}).json();

		const createText = () => {
			const pages = cloneDeep(project.pages);
			pages.forEach((p) => (p.elements = p.elementsAsArray()));

			return post({
				page: pages[0],
				size: { ...project.size, unit: project.unit },
			}).execute();
		};

		return { onFetchResponse, onFetchError, isFetching, createText };
	};

	const declineTemplate = async (reason: string) => {
		await useEditorApiFetch(`vectors/${project.sourceVectorId}/decline`, {}).post({ reason }).json();
	};

	const nodesLength = ref(document.querySelectorAll('[id*="element-"] svg:first-child *:not(g)').length);
	const documentSize = ref(
		StringTools.getSize(
			Array.from(document.querySelectorAll('[id*="canvas-"]'))
				.map((el) => el.outerHTML)
				.join('')
		)
	);

	const projectData = computed(() => {
		return {
			nodesLength: nodesLength.value,
			documentSize: documentSize.value,
		};
	});

	const duplicateTemplate = async (
		name: string,
		flaticonSearch: string,
		keepTags: boolean,
		keepCollection: boolean,
		copyVariants: boolean
	) => {
		const body = {
			name,
			flaticonSearch,
			keepTags,
			keepCollection,
			copyVariants,
		};

		// TODO: Migrar al hook
		const copy = await ApiClient.request(`${APP_API_PATH}vectors/${project.sourceVectorId}/clone`, {
			method: 'POST',
			// @ts-ignore
			body,
		});

		project.sourceVectorId = copy.id;

		if (copyVariants) {
			for (let i = 0; i < store.colorPalettes.length; i++) {
				store.colorPalettes[i].id = copy.variants[i].id;
			}
		}

		window.history.replaceState('', '', `/edit/${copy.slug}?admin=1`);
	};
	const updateTemplate = async (reason: string, finished = false, convert = false) => {
		// Si hay más de una paleta recuparemos los colores en base a la original para
		// evitar cambios no deseados en los colores base de la plantilla
		if (store.colorPalettes.length > 1) {
			store.colorPalettes[0].color_palette.forEach((colorCollection: any) => {
				colorCollection.ids.forEach((id: string) => updateTemplateColorById(id, colorCollection.color));
			});
		}

		const color_palette = palette.value.map((colorCollection: any) => {
			return {
				...colorCollection,
				color: colorCollection.color.type
					? GradientColor.unserialize(colorCollection.color)
					: SolidColor.unserialize(colorCollection.color),
			};
		});

		let mostUsedColor = color_palette.reduce((a, b) => (a.ids.length > b.ids.length ? a : b));

		if (mostUsedColor.color instanceof SolidColor) mostUsedColor = mostUsedColor.color.toHex();
		else {
			const { r, g, b, a } = mostUsedColor.color.stops[0];
			const color = SolidColor.fromObject({ r, g, b, a });

			mostUsedColor = color.toHex();
		}

		// Si solo hay 1 variante la refrescamos ya que es la original
		// así la tenemos actualizada
		if (store.colorPalettes.length === 1) {
			const mainColorExist = color_palette
				.flatMap((colorCollection: any) => {
					// Si es un degradado pillamos los stops
					if (colorCollection.color.type) {
						return colorCollection.color.stops.map((colorStop: StopGradient) => {
							const { r, g, b, a } = colorStop;
							const color = SolidColor.fromObject({ r, g, b, a });

							return color;
						});
					}

					return [colorCollection.color];
				})
				.find((color: SolidColor) => color.toHex() === store.colorPalettes[0].main_color);
			// Si el main color ha dejado de existir usamos el más usado
			const main_color = mainColorExist ? store.colorPalettes[0].main_color : mostUsedColor;

			store.colorPalettes = [
				{
					...store.colorPalettes[0],
					main_color,
					color_palette,
				},
			];

			store.editPanel = null;
			colorSelected.value = undefined;
		}

		// Si no hay paletas usaremos esto como base para general la original
		const originalVariant = {
			color_palette,
			id: null,
			name: 'Original',
			main_color: mostUsedColor,
		};

		const primaryColor =
			project.colorTags && project.colorTags.primary && Array.isArray(project.colorTags.primary)
				? project.colorTags.primary
				: undefined;

		const secondaryColor =
			project.colorTags && project.colorTags.secondary && Array.isArray(project.colorTags.secondary)
				? project.colorTags.secondary
				: undefined;

		const pages = cloneDeep(project.pages);
		pages.forEach((p) => (p.elements = Object.fromEntries(p.elements)));

		const body = {
			pages: pages,
			comment: reason,
			report_type: params.report_type,
			properties: {
				height: project.size.height,
				width: project.size.width,
				aspect_ratio: parseFloat((project.size.width / project.size.height).toFixed(5)),
				unit: project.unit,
				dpi: MM_TO_PX,
				primaryColor,
				secondaryColor,
			},
			convert,
			finished,
			variants: store.colorPalettes.length > 0 ? store.colorPalettes : [originalVariant],
		};

		// TODO: Migrar al hook
		const res = await ApiClient.request(`${APP_API_PATH}vectors/${project.sourceVectorId}?uuid=${uuidv4()}`, {
			method: 'PATCH',
			// @ts-ignore
			body,
		});

		return res;
	};

	return {
		createPredefinedText,
		updateTemplate,
		duplicateTemplate,
		declineTemplate,
		projectData,
		showReports,
		showTemplateSuggestions,
	};
});
