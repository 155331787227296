<script lang="ts" setup>
import { OnClickOutside } from '@vueuse/components';
import { useTimeoutFn } from '@vueuse/core';
import { onBeforeUnmount, ref, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useI18n } from '@/i18n/useI18n';
import CanvasNavigation from '@/layout/components/CanvasNavigation.vue';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

defineProps<{ open: boolean }>();
const emit = defineEmits<{
	(e: 'open'): void;
	(e: 'close'): void;
}>();
const project = useProjectStore();
const { totalPages } = useProject();
const { isMobile } = useDeviceInfo();
const addPageEvent = ref(false);
const { trans } = useI18n();

watch(totalPages, (newNumOfPages, oldNumOfPages) => {
	if (oldNumOfPages > newNumOfPages) return;

	addPageEvent.value = true;

	useTimeoutFn(() => (addPageEvent.value = false), 1000);
});

const handleCloseOutside = () => {
	if (!isMobile.value) return;

	emit('close');
};

onBeforeUnmount(() => {
	emit('close');
});
</script>

<template>
	<OnClickOutside @trigger="handleCloseOutside">
		<!-- añadimos un margen al abrir el  CanvasNavigation para que el Artboard quede centrado y no sea tapado por este panel-->
		<div v-if="open" class="pb-24"></div>
		<button
			data-testid="mobile-btn-page"
			class="fixed bottom-16 right-4 mb-4 flex h-10 w-10 items-center justify-center rounded-full bg-gray-800 text-gray-100 shadow-lg hover:bg-gray-700 lg:absolute lg:left-4 lg:w-28 lg:rounded"
			:class="{
				'added-page': addPageEvent,
				'lg:bottom-0': !open,
				'lg:bottom-36': open,
			}"
			@click="open ? emit('close') : emit('open')"
		>
			<span v-if="isMobile" class="flex items-center">
				<SvgIcon name="pages" class="h-6 w-6" />
				<span
					class="absolute bottom-[10px] left-0 right-1 flex items-center justify-center rounded text-[11px] font-bold"
				>
					{{ project.pages.length }}
				</span>
			</span>
			<span v-else class="flex items-center text-sm">
				<SvgIcon name="pages" class="mr-2 h-4 w-4 shrink-0" /> {{ trans('Pages') }}:
				<strong class="ml-1">{{ project.pages.length }}</strong>
			</span>
		</button>
		<CanvasNavigation v-show="open" :is-open="open" @close="emit('close')" />
	</OnClickOutside>
</template>

<style scoped lang="sass">
@media (max-width: 1023px)
	.added-page
		animation: added-page 0.7s ease-in forwards

		@keyframes added-page
			0%
				transform: scale(1)

			50%
				transform: scale(1.1)
				@apply bg-gray-700 text-white

			100%
				transform: scale(1)
</style>
