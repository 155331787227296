<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Box } from '@/elements/box/classes/Box';
import EditBorderBox from '@/elements/box/components/panels/edit/EditBorderBox.vue';
import { EditPanels, TypeBorder } from '@/Types/types';

// Props
const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');

const { togglePanel } = usePanelManagement();
// Using composables
const { t: trans } = useI18n();
const { isMobile } = useDeviceInfo();

// Data
const showStroke = ref(false);
const borderStrokebutton = ref();

// Methods
onClickOutside(borderStrokebutton, (event) => {
	const target = event.target as HTMLElement | null;

	if (target?.closest('#popper-stroke')) return;

	return (showStroke.value = false);
});
const onClickButton = () => {
	if (!isMobile.value) {
		showStroke.value = !showStroke.value;
		return;
	}

	togglePanel(EditPanels.BorderBox, props.element);
};
</script>

<template>
	<button
		ref="borderStrokebutton"
		data-testid="border-stroke-toolbar"
		:tooltip="trans('Border stroke')"
		tooltip-position="top"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:-ml-1 lg:h-10 lg:w-10 lg:justify-center"
		@click="onClickButton"
	>
		<SvgIcon
			name="stroke"
			class="h-5 w-5"
			:class="!showStroke ? 'text-gray-300 hover:text-gray-100' : 'text-gray-100'"
		/>
		<span class="text-2xs lg:hidden">{{ trans('Stroke') }}</span>
	</button>
	<Popper v-if="showStroke && !isMobile" id="popper-stroke" class="z-30" placement="top-end">
		<EditBorderBox :element="element" />
	</Popper>
</template>
<style lang="sass" scoped>
.input-range::-webkit-slider-thumb:hover
	@apply bg-blue-600
</style>
