import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import { Filterable } from '@/elements/medias/filter/types/filterable.type';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import Mask from '@/elements/medias/mask/classes/Mask';
import { Maskable } from '@/elements/medias/mask/types/maskable.type';
import { ImageApi, UploadApi } from '@/Types/apiClient';
import { ImageDTO } from '@/Types/elements';
import { InsertableApiType, SerializedClass } from '@/Types/types';

export type LegacyImageValues = {
	useBackgroundRemoved: boolean;
};
export type BackgroundModes = 'foreground' | 'background' | 'both' | 'original';

class Image extends BaseImage implements Maskable, Filterable {
	type: 'image' | 'foregroundImage' = 'image';
	backgroundMode: BackgroundModes;
	mask: Mask | null;

	protected constructor(imageDTO: ImageDTO) {
		super(imageDTO);

		this.mask = imageDTO.mask;
		this.backgroundMode = imageDTO.backgroundMode;
	}

	static defaults(): ImageDTO {
		return {
			...BaseImage.defaults(),
			mask: null,
			backgroundMode: 'original' as BackgroundModes,
		};
	}

	static create(config: Partial<ImageDTO> = {}): Image {
		const imageDTO = {
			...Image.defaults(),
			...config,
		};

		return new Image(imageDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Image> & LegacyImageValues): Image {
		const imageDTO = {
			...Image.defaults(),
			...data,
		} as ImageDTO;

		imageDTO.backgroundMode =
			data.useBackgroundRemoved !== undefined
				? data.useBackgroundRemoved
					? 'foreground'
					: 'original'
				: (data.backgroundMode as BackgroundModes);

		imageDTO.filter = data.filter ? Filter.unserialize(data.filter) : null;

		imageDTO.mask = data.mask ? Mask.unserialize(data.mask) : null;

		const elem = new Image(imageDTO);

		if (data.id) {
			elem.id = data.id;
		}

		return elem;
	}

	static async fromApiImage(img: ImageApi | UploadApi): Promise<Image> {
		if (img.type !== InsertableApiType.Image) {
			throw new Error('Invalid image type');
		}

		const image = Image.create({
			url: img.url,
			metadata: img.metadata,
			preview: img.preview,
			urlBackgroundRemoved: img.backgroundRemoved,
			backgroundMode: img.backgroundRemoved ? 'background' : 'original',
		});

		await ImageTools.getRealImageSize(img.preview || img.url).then((size) => image.setSize(size.width, size.height));

		return image;
	}

	static async fromUrl(url: string): Promise<Image> {
		const image = Image.create({
			url,
		});

		await ImageTools.getRealImageSize(url).then((size) => image.setSize(size.width, size.height));

		return image;
	}

	public setMask(mask: Mask | null) {
		this.mask = mask;
	}

	public toNotFound() {
		this.url = '/svg/image-not-found.svg';
		this.preview = '/svg/image-not-found.svg';
		this.metadata = {};
		this.backgroundMode = 'original';
		this.urlBackgroundRemoved = null;
	}

	scaleBy(scale: number) {
		super.scaleBy(scale);

		this.filter?.scaleBy(scale);
	}
}

export default Image;
