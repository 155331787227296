import Bugsnag from '@bugsnag/browser';
import { createSharedComposable, until, useEventListener, useIntervalFn, useUrlSearchParams } from '@vueuse/core';
import { useCookies } from '@vueuse/integrations/useCookies';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getCsrfCookie, getUserData, logout as apiLogout } from '@/api/UserApiClient';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { Lang, User } from '@/Types/types';

const notClosable = ref(false);

export const useAuth = createSharedComposable(() => {
	const authRequired = ref(false);
	const { get, remove } = useCookies();
	const { locale, routes } = useI18n();

	const store = useMainStore();
	const { isPhotoMode, mode } = useEditorMode();
	const { data, error, onFetchResponse, onFetchFinally, execute } = getUserData({ immediate: false });

	const isLogged = computed<boolean>(() => !!store.user);
	const user = computed<User | null>(() => store.user);
	const toast = useToast();
	const limits = ref<
		Record<
			string,
			{
				maxDailyAttempts: number;
				remaining: number;
				credits: number | null;
			}
		>
	>({});

	const refreshCsrfToken = async () => {
		await getCsrfCookie();
	};

	const refreshCsrfTokenInterval = useIntervalFn(refreshCsrfToken, 1000 * 60 * 30);

	refreshCsrfTokenInterval.pause();

	const checkLoginStatus = async () => {
		await refreshCsrfToken();

		try {
			await execute();
			if (!data.value || error.value) throw new Error('User data is empty');

			GAnalytics.trackUser(
				data.value.userData.fpId,
				data.value.userData.id,
				'logged',
				data.value.userData.isAdmin ? 'internal-staff' : 'registered'
			);

			GAnalytics.setUserDimension(data.value.userData.isAdmin ? 'internal-staff' : 'registered');

			limits.value = data.value.limits;
			store.user = data.value.userData;

			refreshCsrfTokenInterval.resume();
		} catch (error) {
			if (store.user && !isPhotoMode.value) {
				toast.warning("You've been logged out. Please log in again to save your changes.");
			}

			store.user = null;
			delete window.localStorage.logged;

			GAnalytics.trackUser('', '', 'not-logged', 'anonymous');

			GAnalytics.setUserDimension('anonymous');
		}
	};

	const logout = async () => {
		await apiLogout();
		delete window.localStorage.logged;

		remove('wepik_session');
		remove('wepik_session_v2');
		remove('GR_REFRESH');
		remove('GR_TOKEN');
		checkLoginStatus();
		Bugsnag.leaveBreadcrumb(`Logout`);
		if (store.userVector) {
			window.location.href = routes.home[locale.value as Lang];
		}
	};

	const requireAuth = () => {
		notClosable.value = mode.value === 'editor';
		if (user.value) return false;

		authRequired.value = true;

		const interval = useIntervalFn(() => {
			if (!window.localStorage.logged) return;
			interval.pause();
			checkLoginStatus();
			until(isLogged)
				.toBe(true)
				.then(() => {
					authRequired.value = false;
				});
		}, 1000);

		return () => {
			authRequired.value = false;
		};
	};

	const isAdmin = computed(() => {
		return user.value?.isAdmin || false;
	});

	const isSlidesgoPremium = computed(() => {
		return user.value?.slidesgoPremium || false;
	});

	const urlParams = useUrlSearchParams<{
		close?: string;
	}>();

	const recoverFreepikSession = () => {
		// En cuanto estemos volviendo de la autenticación de freepik, se guarda en el localstorage
		// y retornamos true para mostrar la modal de que ya puedes cerrar esta ventana
		if (urlParams.close) {
			window.localStorage.setItem('auth_done', '1');
			return true;
		}

		// En cambio, si no estamos con el parametro close, esperamos a que este el auth done de la otra
		// ventana en estorage
		const interval = useIntervalFn(() => {
			if (!window.localStorage.getItem('auth_done')) return;
			window.localStorage.removeItem('auth_done');
			interval.pause();
			checkLoginStatus();
			remove('GR_LGURI');

			until(isLogged)
				.toBe(true)
				.then(() => {
					authRequired.value = false;
				});
		}, 1000);

		return false;
	};

	const watchFocusForAuth = () => {
		useEventListener(document, 'visibilitychange', () => {
			if (!document.hidden && !isLogged.value) {
				checkLoginStatus();
			}
		});
	};

	const isFreepikUser = computed(() => {
		return user.value?.email.endsWith('@freepik.com') || false;
	});

	return {
		isLogged,
		notClosable,
		user,
		checkLoginStatus,
		logout,
		requireAuth,
		onSuccessLogin: onFetchResponse,
		onFetchLogin: onFetchFinally,
		limits,
		authRequired,
		isAdmin,
		watchFocusForAuth,
		isFreepikUser,
		recoverFreepikSession,
		refreshCsrfToken,
		isSlidesgoPremium,
	};
});
