<script lang="ts" setup>
import { useEventListener, useMouseInElement } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { StopGradient } from '@/Types/colorsTypes';
import MathTools from '@/utils/classes/MathTools';

const props = defineProps<{ gradient: GradientColor; selected: StopGradient }>();
const emit = defineEmits<{
	(e: 'select', stop: StopGradient): void;
	(e: 'change', stop: StopGradient): void;
	(e: 'new', gradient: GradientColor): void;
}>();

const hue = ref();
const dragging = ref(false);
const { elementX, elementWidth } = useMouseInElement(hue);

const selectStop = (stop: StopGradient) => {
	emit('select', stop);
};

const deleteStop = (stop: StopGradient) => {
	if (props.gradient.stops.length <= 2) {
		return;
	}
	const nextStop = props.gradient.stops[props.gradient.stops.indexOf(stop) - 1];
	const stops = props.gradient.stops.filter(
		(s) => props.gradient.stopToString(stop) !== props.gradient.stopToString(s)
	);
	const gradient = new GradientColor(props.gradient.type, props.gradient.rotation, stops);
	emit('new', gradient);
	selectStop(nextStop);
};

const addStop = () => {
	const clone = cloneDeep(props.gradient);
	const newStop = { ...props.selected, offset: MathTools.ruleOfThree(elementWidth.value, 100, elementX.value) };
	clone.stops = [...props.gradient.stops, newStop].sort((a, b) => a.offset - b.offset);
	emit('new', clone);
	emit('select', newStop);
};

const moveOffsetStop = () => {
	if (!dragging.value) return;

	const newStop: StopGradient = {
		...props.selected,
		offset: MathTools.clamp(MathTools.ruleOfThree(elementWidth.value, 100, elementX.value), 0, 100),
	};
	emit('change', newStop);
};

const initMoveOffset = (e: TouchEvent) => {
	if (!(e.target as HTMLElement).closest('.gradients-stop-container')) return;

	dragging.value = true;
};

useEventListener(window, 'touchstart', initMoveOffset);
useEventListener(window, 'mousedown', initMoveOffset);

useEventListener(window, 'touchend', () => (dragging.value = false));
useEventListener(window, 'mouseup', () => (dragging.value = false));

useEventListener(window, 'mousemove', moveOffsetStop);
useEventListener(window, 'touchmove', moveOffsetStop);
</script>

<template>
	<div
		ref="container"
		class="gradients-stop-container absolute top-3 left-2 right-2 flex h-8 lg:top-2 lg:left-3 lg:right-3"
	>
		<button
			v-for="(stop, i) in gradient.stops"
			:key="`stop-${i}`"
			data-testid="stopsGradients"
			class="gradient-button bg-transparent-pattern group absolute z-20 flex h-6 w-6 flex-col items-center rounded-full border-2 shadow lg:h-7 lg:w-7"
			:class="
				gradient.stopToString(stop) === gradient.stopToString(selected)
					? 'z-50 border-blue-500 slidesgo:border-purple-400'
					: 'border-gray-600'
			"
			:style="{
				left: `${stop.offset}%`,
				top: `-2px`,
				marginLeft: '-14px',
			}"
			style="background-size: 10px"
			@mousedown="selectStop(stop)"
			@touchstart="selectStop(stop)"
		>
			<span
				class="block h-full w-full rounded-full"
				:style="{
					backgroundColor: SolidColor.fromObject(stop).toCssString(),
				}"
			></span>
			<span class="-mb-5 mt-2 hidden h-3 w-3 rounded-full border border-gray-100 bg-white lg:block"></span>
			<span
				class="delete-gradient absolute top-0 right-0 -mt-1 -mr-1 flex h-3 w-3 items-center justify-center rounded-full bg-gray-800 ring-2 ring-gray-600 group-hover:opacity-100 hover:ring-blue-500 slidesgo:ring-purple-400 lg:opacity-0"
				@click.stop="deleteStop(stop)"
			>
				<SvgIcon name="cross" class="block h-2 w-2 fill-current text-white" />
			</span>
		</button>
	</div>
	<div class="z-10 mt-3 flex w-full lg:mt-0 lg:mb-4 lg:h-12 lg:items-end lg:justify-end" @click="addStop">
		<div
			class="bg-transparent-pattern mx-2 mt-auto flex h-3 w-full overflow-hidden rounded-full lg:w-full"
			style="background-size: 10px"
		>
			<div
				ref="hue"
				class="h-3 w-full cursor-copy rounded-full"
				data-testid="gradient-slider"
				:style="{
					background: gradient
						.toCssString()
						.replace(`${gradient.rotation}deg`, '90deg')
						.replace('farthest-side', '90deg')
						.replace('radial', 'linear'),
				}"
			></div>
		</div>
	</div>
</template>
<style scoped lang="sass">
.gradient-button:first-child,
.gradient-button:last-child
	.delete-gradient
		@apply hidden
</style>
