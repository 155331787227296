import domtoimage from 'dom-to-image-more';
import { cloneDeep } from 'lodash';
import { Ref, ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { FilesTools } from '@/common/utils/FilesTools';
import { Box } from '@/elements/box/classes/Box';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { StopGradient } from '@/Types/colorsTypes';

export const useDownloadCustomBox = (element: Ref<Box>) => {
	const gradientCounter = ref(0);

	// Genera una imagen usando domtoimage de un div y la descargue
	const downloadPreview = async (div: HTMLElement, fileName: string) => {
		// Convertir el div a un blob
		const blob = await domtoimage.toBlob(div, {
			width: div.getBoundingClientRect().width,
			height: div.getBoundingClientRect().height,
			style: {},
			filter: (doc: HTMLElement) => {
				// Solucionamos problemas de cors en la copia
				if (doc instanceof HTMLImageElement) {
					doc.src = ImageTools.getImageUrlViaProxy(doc.src);
				}

				return true;
			},
		});

		// Convertir el blob a base64 y descargarlo
		FilesTools.blobToBase64(blob).then((base64) => {
			// Crear un elemento anchor
			const link = document.createElement('a');

			// Establecer el atributo href del anchor
			link.setAttribute('href', base64 as string);
			// Establecer el atributo download del anchor
			link.setAttribute('download', `${fileName}.png`);
			// Simular un click en el anchor
			link.click();
		});
	};

	const downloadSVG = (svg: SVGSVGElement, fileName: string) => {
		// Obtener la cadena SVG
		const svgData = new XMLSerializer().serializeToString(svg);

		// Crear un objeto Blob a partir de la cadena SVG
		const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

		// Crear una URL de datos del objeto Blob
		const url = URL.createObjectURL(blob);

		// Crear un enlace de descarga
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', fileName);

		// Simular un clic en el enlace de descarga
		link.click();
	};

	// Obtiene el degradado lineal
	const getLinearGradient = (defs: SVGDefsElement, gradientColor: GradientColor): string => {
		// Si el elemento tiene un fondo degradado, hay que crear el elemento linearGradient
		const linearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');

		const linearGradientName = `linearGrad-${gradientCounter.value}`;
		gradientCounter.value++;

		linearGradient.setAttribute('id', `${linearGradientName}`);
		linearGradient.setAttribute('gradientTransform', `rotate(${gradientColor.rotation - 90}, 0.5, 0.5)`);

		// Clonar los stops del color degradado
		const stops = cloneDeep(gradientColor.stops);

		stops.forEach((stop: StopGradient) => {
			const { r, g, b, a } = stop;

			const stopElement = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
			// Dividimos el offset entre 100 porque el offset de los stops va de 0 a 1
			stopElement.setAttribute('offset', `${stop.offset / 100}`);
			stopElement.setAttribute('stop-color', `rgba(${r}, ${g}, ${b}, ${a})`);
			stopElement.setAttribute('stop-opacity', `${a}`);

			linearGradient.appendChild(stopElement);
		});

		defs.appendChild(linearGradient);

		return linearGradientName;
	};

	// Obtiene el degradado radial
	const getRadialGradient = (defs: SVGDefsElement, gradientColor: GradientColor): string => {
		// Si el elemento tiene un fondo degradado, hay que crear el elemento linearGradient
		const radialGradient = document.createElementNS('http://www.w3.org/2000/svg', 'radialGradient');

		const radialGradientName = `radialGrad-${gradientCounter.value}`;
		gradientCounter.value++;

		radialGradient.setAttribute('id', `${radialGradientName}`);
		radialGradient.setAttribute('gradientTransform', `rotate(${gradientColor.rotation - 90}, 0.5, 0.5)`);

		// Clonar los stops del color degradado
		const stops = cloneDeep(gradientColor.stops);

		stops.forEach((stop: StopGradient) => {
			const { r, g, b, a } = stop;

			const stopElement = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
			// Dividimos el offset entre 100 porque el offset de los stops va de 0 a 1
			stopElement.setAttribute('offset', `${stop.offset / 100}`);
			stopElement.setAttribute('stop-color', `rgba(${r}, ${g}, ${b}, ${a})`);
			stopElement.setAttribute('stop-opacity', `${a}`);

			radialGradient.appendChild(stopElement);
		});

		defs.appendChild(radialGradient);

		return radialGradientName;
	};

	// Establecer el fondo de un rectángulo
	const setBoxBackground = (svg: SVGElement, defs: SVGDefsElement, rect: SVGRectElement, element: Box) => {
		// Si el elemento tiene un fondo degradado, hay que crear el elemento linearGradient
		if (element.background instanceof GradientColor) {
			const gradientName =
				element.background.type === 'radial'
					? getRadialGradient(defs, element.background)
					: getLinearGradient(defs, element.background);
			rect.setAttribute('fill', `url(#${gradientName})`);
		} else {
			rect.setAttribute('fill', `${element.background.toCssString()}`);
		}
	};

	// Establecer el borde de un rectángulo
	const setBoxBorder = (svg: SVGElement, defs: SVGDefsElement, rect: SVGRectElement, element: Box) => {
		// Si el elemento tiene un fondo degradado, hay que crear el elemento linearGradient
		if (element.border.color instanceof GradientColor) {
			const gradientName =
				element.border.color.type === 'radial'
					? getRadialGradient(defs, element.border.color)
					: getLinearGradient(defs, element.border.color);
			rect.setAttribute('stroke', `url(#${gradientName})`);
		} else {
			rect.setAttribute('stroke', `${element.border.color.toCssString()}`);
		}

		if (element.border.type === 'dashed') {
			rect.setAttribute('stroke-dasharray', '50');
		}

		rect.setAttribute('stroke-type', element.border.type);

		rect.setAttribute('stroke-width', `${element.border.size}px`);
		// Si el elemento tiene un radio de borde mayor que 0, significa que es un borde redondeado
		// hay que establecer los atributos rx y ry y el atributo stroke-linejoin
		if (element.border.radius > 0) {
			rect.setAttribute('rx', `${element.border.radius}px`);
			rect.setAttribute('ry', `${element.border.radius}px`);
			rect.setAttribute('stroke-linejoin', 'round');
		}
	};

	// Establecer el tamaño del SVG y el rectángulo
	const setSvgAndRectSize = (svg: SVGElement, rect: SVGRectElement, element: Box) => {
		// Establecemos los atributos del elemento SVG
		svg.setAttribute('width', `${element.size.width}px`);
		svg.setAttribute('height', `${element.size.height}px`);
		svg.setAttribute(
			'viewBox',
			`-${element.border.size / 2} -${element.border.size / 2} ${element.size.width + element.border.size} ${
				element.size.height + element.border.size
			}`
		);

		// Establecer los atributos del rectángulo SVG
		rect.setAttribute('width', `${element.size.width}px`);
		rect.setAttribute('height', `${element.size.height}px`);
	};

	const convertAndDownload = async () => {
		// Crear el elemento rect SVG
		const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

		// Crear el elemento SVG
		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

		// Establecer el tamaño del elemento SVG y del rectángulo SVG
		setSvgAndRectSize(svg, rect, element.value);

		// Creamos el elemento defs
		const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

		// Agregamos el elemento defs al elemento SVG
		svg.appendChild(defs);
		// Agregamos el rectángulo SVG al elemento SVG
		svg.appendChild(rect);

		// Agregamos el elemento SVG al body
		document.body.appendChild(svg);

		// Establecer el fondo del elemento
		setBoxBackground(svg, defs, rect, element.value);
		// Establecer el borde del elemento
		setBoxBorder(svg, defs, rect, element.value);

		const elementNode = element.value.domNode()?.querySelector<HTMLElement>(`.box-element-final`);

		if (elementNode) {
			const fileName = `box-${element.value.id}`;

			// Descargar el preview
			await downloadPreview(elementNode, `${fileName}`);

			// Descargar el SVG
			downloadSVG(svg, `${fileName}.svg`);
		}
	};

	return {
		convertAndDownload,
	};
};
