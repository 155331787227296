<script lang="ts" setup>
import { computed, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Line from '@/elements/line/classes/Line';
import { useLine } from '@/elements/line/composables/useLine';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Line }>();
const element = toRef(props, 'element');
const { updateDasharray } = useLine(element);
const { trans } = useI18n();

const dashArrayList = Line.defaultDasharrays();
const currentDashArrayListIndex = computed(() =>
	dashArrayList.findIndex((dashArray) => dashArray.toString() === element.value.dasharray?.toString())
);

const toggleDasharray = () => {
	const nextDashArrayIndex =
		dashArrayList[currentDashArrayListIndex.value + 1] !== undefined ? currentDashArrayListIndex.value + 1 : 0;

	updateDasharray(dashArrayList[nextDashArrayIndex]);
};
</script>

<template>
	<button
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-white lg:h-8 lg:w-8 lg:justify-center"
		:tooltip="trans('Border style')"
		tooltip-position="top"
		@click="toggleDasharray"
	>
		<SvgIcon :name="'border-dasharray-' + currentDashArrayListIndex" class="h-6 w-6 lg:h-5 lg:w-5" />
		<p class="text-2xs lg:hidden">{{ trans('Style') }}</p>
	</button>
</template>
