<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { isEqual } from 'lodash-es';
import { computed, onBeforeMount, ref } from 'vue';

import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { TemplateApiData } from '@/Types/apiClient';

// Using composables
const { appendTemplate, replaceTemplate, openUserTemplate, canAddPages, totalPages, MAX_PAGES } = useProject();
const project = useProjectStore();
const { trans } = useI18n();
const { isEmbeddedContext } = useEditorMode();
const { runOnMobile, isIOS } = useDeviceInfo();

const store = useMainStore();
const { clearSelection } = useSelection();

const toast = useToast();
let hasSameRatio = false;
let selectedTemplateSize = { width: 0, height: 0, unit: 'px' };
// Props
const props = defineProps<{ selectedTemplate: TemplateApiData }>();
// Data
let isSameArtboard = false;
const isUserTemplate = computed(
	() => props.selectedTemplate && Object.keys(props.selectedTemplate).includes('viewbox')
);

const canAppendTemplate = computed(
	() => canAddPages.value && totalPages.value + props.selectedTemplate.pages <= MAX_PAGES
);
// Emits
const emit = defineEmits(['close']);

// Lifecycle hooks
onBeforeMount(() => (isSameArtboard = getIsSameArtboard()));

// Methods
const getIsSameArtboard = () => {
	if (!props.selectedTemplate) {
		return false;
	}

	const w = props.selectedTemplate.artboard?.width || props.selectedTemplate.size.width;
	const h = props.selectedTemplate.artboard?.height || props.selectedTemplate.size.height;
	const unit = props.selectedTemplate.artboard?.unit || props.selectedTemplate.size.unit;

	const currentArtboardSize = { ...project.size, unit: project.unit };
	selectedTemplateSize = {
		width: w,
		height: h,
		unit: unit,
	};

	const currentRatio = currentArtboardSize.width / currentArtboardSize.height;
	const selectedRatio = (props.selectedTemplate.artboard?.width || 1) / (props.selectedTemplate.artboard?.height || 13);
	hasSameRatio = Math.trunc(currentRatio * 100) / 100 === Math.trunc(selectedRatio * 100) / 100;

	if (isIOS.value) {
		selectedTemplateSize.width *= store.scaleMaxAllowedSize;
		selectedTemplateSize.height *= store.scaleMaxAllowedSize;
	}

	return isEqual(currentArtboardSize, selectedTemplateSize);
};

const appending = ref(false);
const onClickAppend = async () => {
	if (!props.selectedTemplate) return;
	if (!canAppendTemplate.value) {
		toast.warning(trans("The template you're trying to add exceeds the limit of pages to edit."));
		emit('close');
		return;
	}
	// borramos la paleta de colores al hacer append si la template actual tuviese
	if (store.colorPalettes.length) store.colorPalettes = [];
	appending.value = true;
	runOnMobile(() => (store.activePanel = null));
	// Si la template seleccionada tiene diferente artboard pero el mismo ratio, necesitamos reescalar los elementos al reemplazar la template
	const elementsNeedScaled = !isSameArtboard && hasSameRatio;
	if (elementsNeedScaled) {
		const scaleElements = TransformTools.getFactorToFitInNewRatio(project.size, selectedTemplateSize);
		await appendTemplate(props.selectedTemplate.slug, scaleElements);
	} else {
		await appendTemplate(props.selectedTemplate.slug);
	}
	Bugsnag.leaveBreadcrumb(`Append template`);
	emit('close');
	appending.value = false;
};

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Template replacement modal is closed`);
};

const onClickReplace = async () => {
	clearSelection();
	if (!props.selectedTemplate) return;
	runOnMobile(() => (store.activePanel = null));
	const searchParam = new URLSearchParams(window.location.search);
	// Eliminamos los queryParams relacionados con el size del canvas
	if (searchParam.get('size')) {
		searchParam.delete('width');
		searchParam.delete('height');
		searchParam.delete('unit');
		searchParam.delete('size');
	}
	const queryParams = searchParam.toString() ? `?${searchParam.toString()}` : '';
	await replaceTemplate(`${props.selectedTemplate.slug}${queryParams}`);
	Bugsnag.leaveBreadcrumb(`Replace template`);
	emit('close');
};

const onClickOpenNewTab = () => {
	clearSelection();
	if (!props.selectedTemplate) return;
	runOnMobile(() => (store.activePanel = null));
	const searchParam = new URLSearchParams(window.location.search);
	// Eliminamos los queryParams relacionados con el size del canvas
	if (searchParam.get('size')) {
		searchParam.delete('width');
		searchParam.delete('height');
		searchParam.delete('unit');
		searchParam.delete('size');
	}
	const queryParams = searchParam.toString() ? `?${searchParam.toString()}` : '';
	window.open(`${window.location.origin}/edit/${props.selectedTemplate.slug}${queryParams}`, '_blank');
	Bugsnag.leaveBreadcrumb(`Open template in new tab`);
	emit('close');
};

const onClickOpenUserTemplate = () => {
	clearSelection();
	if (!props.selectedTemplate) return;
	runOnMobile(() => (store.activePanel = null));
	const searchParam = new URLSearchParams(window.location.search);
	// Eliminamos los queryParams relacionados con el size del canvas
	if (searchParam.get('size')) {
		searchParam.delete('width');
		searchParam.delete('height');
		searchParam.delete('unit');
		searchParam.delete('size');
	}
	const queryParams = searchParam.toString() ? `?${searchParam.toString()}` : '';
	openUserTemplate(`${props.selectedTemplate.uuid}${queryParams}`)
		.catch(() => {
			toast.error(trans('The template has not been loaded'));
		})
		.finally(() => {
			Bugsnag.leaveBreadcrumb('Open user template');
			emit('close');
		});
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative mx-auto my-auto max-w-lg rounded bg-white px-8 py-8 lg:w-[470px]">
			<button
				class="absolute right-0 top-0 mr-2 mt-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<!-- Ask user for APPEND or REPLACE -->
			<template v-if="(isSameArtboard && !isUserTemplate) || (hasSameRatio && !isUserTemplate)">
				<slot>
					<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">
						{{ trans('We need confirmation!') }}
					</h1>
					<p class="mb-0 text-center leading-normal text-gray-600">
						{{ trans('You can choose between adding the template as another page or replacing the current one.') }}
					</p>
				</slot>
				<div class="mt-6 flex flex-col items-center justify-center md:flex-row">
					<button
						class="mx-2 mb-2 flex h-12 w-full items-center justify-center rounded border-2 border-gray-200 px-4 text-lg font-semibold text-gray-700 hover:border-gray-300 md:mb-0"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickReplace"
					>
						<SvgIcon v-show="!store.finishedLoading" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans(store.finishedLoading ? 'Replace' : 'Replacing') }}
					</button>
					<button
						v-if="!isEmbeddedContext"
						class="mx-2 flex h-12 w-full items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-4 text-lg font-semibold text-white hover:border-blue-400 hover:bg-blue-400 slidesgo:border-purple-400 slidesgo:bg-purple-400 slidesgo:hover:bg-purple-300"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickAppend"
					>
						<SvgIcon v-show="appending" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans(appending ? 'Adding' : 'Add as new page') }}
					</button>
				</div>
			</template>

			<!-- Ask user for REPLACE confirmation -->
			<template v-if="!isSameArtboard && !isUserTemplate && !hasSameRatio">
				<SvgIcon name="alert" class="mx-auto mb-2 h-12 w-12 fill-current text-yellow-500" />
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">{{ trans('We need confirmation!') }}</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					{{ trans('The new template has a different artboard, the existing pages will be removed.') }}
				</p>

				<div class="mt-6 flex flex-col items-center justify-center md:flex-row">
					<button
						class="mx-2 mb-2 flex h-12 w-full items-center justify-center rounded border-2 border-gray-200 px-4 text-lg font-semibold text-gray-700 hover:border-gray-300 md:mb-0"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickReplace"
					>
						<SvgIcon v-show="!store.finishedLoading" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ store.finishedLoading ? 'Replace' : 'Replacing' }}
					</button>
					<button
						v-if="!isEmbeddedContext"
						class="mx-2 flex h-12 w-full items-center justify-center rounded border-2 border-yellow-500 bg-yellow-500 px-4 text-lg font-semibold text-white hover:border-yellow-400 hover:bg-yellow-400"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickOpenNewTab"
					>
						{{ trans('Open in a new tab') }}
					</button>
				</div>
			</template>

			<!-- Ask user for OPEN confirmation -->
			<template v-if="isUserTemplate">
				<SvgIcon name="alert" class="mx-auto mb-2 h-12 w-12 fill-current text-yellow-500" />
				<slot>
					<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">
						{{ trans('We need confirmation!') }}
					</h1>
					<p class="mb-0 text-center leading-normal text-gray-600">
						{{ trans('Do you want to open the template?') }}
					</p>
				</slot>
				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-yellow-500 bg-yellow-500 px-8 text-lg font-semibold text-white hover:border-yellow-400 hover:bg-yellow-400"
						:disabled="!store.finishedLoading || appending"
						@click.stop="onClickOpenUserTemplate"
					>
						<SvgIcon v-show="!store.finishedLoading" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans('Open') }}
					</button>
				</div>
			</template>
		</div>
	</Modal>
</template>
