<script lang="ts" setup>
import { useEventListener } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, onMounted, toRef, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import CropGrid from '@/elements/medias/crop/components/CropGrid.vue';
import { useCropPhotoMode } from '@/elements/medias/crop/composables/useCropPhotoMode';
import { useCropPhotoModeMoveable } from '@/elements/medias/crop/composables/useCropPhotoMode';
import Image from '@/elements/medias/images/image/classes/Image';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
const store = useMainStore();

// Props
const props = defineProps<{ image: Image }>();

// Data
const image = toRef(props, 'image');
const scale = toRef(store, 'scale');

// Using composables
const { applyCrop, initCrop, cancelCrop, fromApplyCrop } = useCropPhotoMode();
const { cropPhotoModeMoveable } = useCropPhotoModeMoveable();
const { listen } = useOrderedKeyboardListener();

// Watchers
watch(scale, async () => {
	await nextTick();
	cropPhotoModeMoveable.value?.updateTarget();
});

watch(
	() => store.activePanel,
	(oldVal, newVal) => {
		if ((oldVal !== null && newVal === null) || (oldVal === null && newVal !== null)) {
			cancelCrop();
		}
	}
);

// Lifecycle hooks
onMounted(async () => {
	await initCrop();
});

onBeforeUnmount(() => {
	if (fromApplyCrop.value) {
		fromApplyCrop.value = false;
		return;
	}
	cancelCrop();
});

// Register events
listen('Enter', (ev) => {
	ev.preventDefault();
	applyCrop();
});

listen('Escape', (ev) => {
	ev.preventDefault();
	cancelCrop();
});

useEventListener('resize', () => {
	cancelCrop();
});

// Methods
const ghostImageSize = computed(() => ({
	width: props.image.crop?.size.width || props.image.size.width,
	height: props.image.crop?.size.height || props.image.size.height,
}));

const ghostImagePosition = computed(() => ({
	x: props.image.crop?.position.x,
	y: props.image.crop?.position.y,
}));
</script>

<template>
	<div
		class="ghost-image-wrapper pointer-events-none absolute left-0 top-0"
		:style="{
			transform: `
        translate(${image.position.x}px, ${image.position.y}px)
				rotate(${image.rotation}deg
      `,
			width: `${image.size.width}px`,
			height: `${image.size.height}px`,
		}"
	>
		<div
			class="h-full w-full opacity-25"
			:style="{
				transform: `
					scaleX(${image.flipHTML.x})
					scaleY(${image.flipHTML.y})
				`,
			}"
		>
			<div
				:data-test-crop="store.croppingId"
				:style="{
					width: `${image.size.width}px`,
					height: `${image.size.height}px`,
				}"
			>
				<div
					:style="{
						width: `${image.size.width}px`,
						height: `${image.size.height}px`,
						transform: `
							scaleX(${image.flipHTML.x})
							scaleY(${image.flipHTML.y})
						`,
					}"
				>
					<div
						class="ghost-image"
						:style="{
							width: `${ghostImageSize.width}px`,
							height: `${ghostImageSize.height}px`,
							transform: `
								translate(${ghostImagePosition.x}px, ${ghostImagePosition.y}px)
							`,
						}"
					>
						<img
							:src="image.url"
							class="h-full w-full"
							:style="{
								opacity: image.opacity,
								transform: `
									scaleX(${image.flipHTML.x})
									scaleY(${image.flipHTML.y})
								`,
							}"
							@dragstart.prevent
						/>
					</div>
				</div>
			</div>
		</div>
		<CropGrid :size="image.size" />
	</div>
</template>
