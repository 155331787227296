<script setup lang="ts">
import { toRef } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import GradientsRenderer from '@/color/components/GradientsRenderer.vue';
import { useBoxColors } from '@/color/composables/useBoxColors';
import { Box } from '@/elements/box/classes/Box';
import { useBoxStroke } from '@/elements/box/composables/useBoxStroke';
import { useRounding } from '@/elements/box/composables/useRounding';
import { TypeBorder } from '@/Types/types';
const props = defineProps<{ element: Box; previewName?: string }>();
const element = toRef(props, 'element');

const { isBorderGradient } = useBoxColors(element);
const { typeBorderStyle } = useBoxStroke(element);
const { hasBorderRadius } = useRounding(element);
</script>

<template>
	<svg
		data-testid="border-svg"
		class="svg-container sync-box absolute"
		:width="element.size.width"
		:height="element.size.height"
		:style="{ width: `${element.size.width}px`, height: `${element.size.height}px` }"
	>
		<defs>
			<clipPath :id="`rounded-outer-clip-${element.id}-${previewName || ''}`">
				<path
					class="sync-clipPath-box"
					:d="Box.generateSvgBorder(element.size.width, element.size.height, element.border.radius)"
				/>
			</clipPath>
		</defs>
		<path
			v-if="element.border.type === TypeBorder.Dotted && hasBorderRadius"
			data-testid="dotted-border-path"
			:stroke-width="element.border.size"
			class="sync-path-box"
			fill="none"
			:style="{
				transform: `translate(${element.border.size / 2}px, ${element.border.size / 2}px)`,
				strokeDasharray: typeBorderStyle.strokeDashArray,
				strokeLinecap: typeBorderStyle.strokeLinecap,
			}"
			:stroke="element.border.color.toElementReference(previewName)"
			:d="
				Box.generateSvgBorder(
					element.size.width - element.border.size,
					element.size.height - element.border.size,
					element.border.radius
				)
			"
		/>

		<rect
			v-else-if="!hasBorderRadius"
			data-testid="border-rect"
			class="sync-rect-box"
			:stroke-width="element.border.size"
			fill="none"
			:style="{
				transform: `translate(${element.border.size / 2}px, ${element.border.size / 2}px)`,
				strokeDasharray: typeBorderStyle.strokeDashArray,
				strokeLinecap: typeBorderStyle.strokeLinecap,
				width: `${element.size.width - element.border.size}px`,
				height: `${element.size.height - element.border.size}px`,
			}"
			:stroke="element.border.color.toElementReference(previewName)"
		/>
		<path
			v-else
			data-testid="border-path"
			class="sync-path-box"
			:clip-path="`url(#rounded-outer-clip-${element.id}-${previewName || ''}`"
			:stroke-width="element.border.size * 2"
			fill="none"
			:style="{ strokeDasharray: typeBorderStyle.strokeDashArray, strokeLinecap: typeBorderStyle.strokeLinecap }"
			:stroke="element.border.color.toElementReference(previewName)"
			:d="Box.generateSvgBorder(element.size.width, element.size.height, element.border.radius)"
		/>
	</svg>
	<GradientsRenderer
		v-if="isBorderGradient"
		:preview-name="previewName"
		:gradients="([element.border.color] as GradientColor[])"
	/>
</template>
