<script lang="ts" setup>
import { Switch } from '@headlessui/vue';
import { computed } from 'vue';

import { useProjectColors } from '@/color/composables/useProjectColors';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { Color } from '@/Types/colorsTypes';

const { isMobile } = useDeviceInfo();
const project = useProjectStore();
const { trans } = useI18n();

const props = defineProps<{
	color: Color;
}>();

const { colorsIdFromSelected } = useProjectColors();

const isPrimaryColor = computed(() =>
	Array.isArray(project.colorTags.primary) ? project.colorTags.primary?.includes(props.color.id) : false
);
const isSecondaryColor = computed(() =>
	Array.isArray(project.colorTags.secondary) ? project.colorTags.secondary?.includes(props.color.id) : false
);

const selectAsPrimaryColor = () => {
	if (isSecondaryColor.value) {
		project.colorTags.secondary = undefined;
	}

	project.colorTags.primary = isPrimaryColor.value ? undefined : colorsIdFromSelected.value;
};

const selectAsSecondaryColor = () => {
	if (!props.color) return;

	if (isPrimaryColor.value) {
		project.colorTags.primary = undefined;
	}

	project.colorTags.secondary = isSecondaryColor.value ? undefined : colorsIdFromSelected.value;
};
</script>

<template>
	<h4 v-if="!isMobile" class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Tag colors') }}</h4>
	<div class="grid grid-cols-2">
		<div class="flex justify-evenly text-white">
			<div class="text-sm text-gray-100">{{ trans('Primary') }}</div>
			<Switch
				:model-value="!!isPrimaryColor"
				:class="isPrimaryColor ? 'bg-blue-600' : 'bg-gray-100'"
				class="relative inline-flex h-6 w-11 items-center rounded-full"
				@update:modelValue="selectAsPrimaryColor"
			>
				<span
					:class="isPrimaryColor ? 'translate-x-6' : 'translate-x-1'"
					class="inline-block h-4 w-4 transform rounded-full bg-white transition"
				/>
			</Switch>
		</div>

		<div class="flex justify-evenly text-white">
			<div class="text-sm text-gray-100">{{ trans('Secondary') }}</div>
			<Switch
				:model-value="!!isSecondaryColor"
				:class="isSecondaryColor ? 'bg-blue-600' : 'bg-gray-100'"
				class="relative inline-flex h-6 w-11 items-center rounded-full"
				@update:modelValue="selectAsSecondaryColor"
			>
				<span
					:class="isSecondaryColor ? 'translate-x-6' : 'translate-x-1'"
					class="inline-block h-4 w-4 transform rounded-full bg-white transition"
				/>
			</Switch>
		</div>
	</div>
</template>

<style scoped></style>
