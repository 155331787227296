import { createSharedComposable, until } from '@vueuse/core';
import { useIdle } from '@vueuse/core';

import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useProject } from '@/project/composables/useProject';
export const useUsersnap = createSharedComposable(() => {
	const { isMobile } = useDeviceInfo();
	const { inSlidesgoContext, isAdminMode } = useEditorMode();
	const { user } = useAuth();
	const { APP_VERSION } = useEnvSettings();
	const { PROD } = useEnvSettings();
	const { isAiPresentation } = useProject();
	const { idle } = useIdle(5000);
	const openReportBug = () => {
		if (isAdminMode.value) return;

		return new Promise(() => {
			const script = document.createElement('script');
			const nonceScript = document.querySelector('[nonce]');
			const nonce = nonceScript?.getAttribute('nonce') || '';
			script.setAttribute('nonce', nonce);

			script.src = `https://widget.usersnap.com/global/load/0d0df480-fb29-49b7-85d6-37546e404002?onload=onUsersnapCXLoaded&n=${nonce}`;

			window.onUsersnapCXLoaded = (api) => {
				api.init({
					useSystemFonts: true,
					custom: {
						version: APP_VERSION,
					},
					user: {
						userId: user.value?.id,
						email: user.value?.email,
					},
				});

				api.logEvent('start');
				api.on('close', () => {
					api.destroy();
				});
			};

			document.head.appendChild(script);
		});
	};

	const loadUserSnap = () => {
		let userSnapId = '2c065a18-383a-4dfd-8135-0685ffd6442c';
		const base = 'https://widget.usersnap.com/load/';

		if (isAiPresentation.value) {
			userSnapId = '76a0f6b4-a065-41f3-81e0-665d1c54113b';
		} else if (inSlidesgoContext.value) {
			userSnapId = '34166b03-8fb6-49bc-a90e-1b73079a5bb5';
		} else if (isMobile.value && !isAiPresentation.value) {
			userSnapId = '0c706d9b-2de1-405b-b938-6cb2d7212a12';
		}

		window.onUsersnapCXLoad = function onUsersnapCXLoaded(api) {
			api.init({
				button: {
					isHidden: true,
				},
			});
			window.UsersnapCX = api;
		};
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.id = 'usersnap-load';
		const nonceScript = document.querySelector('[nonce]');
		const nonce = nonceScript?.getAttribute('nonce') || '';
		script.setAttribute('nonce', nonce);
		script.src = `${base}${userSnapId}?onload=onUsersnapCXLoad&n=${nonce}`;

		document.head.appendChild(script);
	};

	const showUsersnap = () => {
		if (!window.UsersnapCX) {
			return;
		}

		window.UsersnapCX.open();
	};

	const showUserSnapAfterMinutes = () => {
		if (!PROD) return;
		loadUserSnap();
		setTimeout(async () => {
			if (window.localStorage.getItem('usersnap')) {
				return;
			}
			await until(idle).toBeTruthy();
			window.localStorage.setItem('usersnap', 'true');
			showUsersnap();
		}, 180000);
	};

	return {
		loadUserSnap,
		showUsersnap,
		openReportBug,
		showUserSnapAfterMinutes,
	};
});
