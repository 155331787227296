<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';

import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useGroupAlign } from '@/elements/group/composables/useGroupAlign';
import { useI18n } from '@/i18n/useI18n';

const showButtons = ref(false);

const { hAlignment, vAlignment, alignHandler, getAlignment } = useGroupAlign();
const { trans } = useI18n();

onBeforeMount(() => {
	const { h, v } = getAlignment();
	hAlignment.value = h;
	vAlignment.value = v;
});

const onClickToggleButton = () => {
	showButtons.value = !showButtons.value;
};
</script>

<template>
	<div class="alignment-button">
		<button
			class="flex h-6 items-center justify-center rounded-full bg-gray-700 px-2 text-xs text-white/80 shadow hover:text-white"
			data-testid="align-items-position"
			:tooltip="trans('Align items')"
			tooltip-position="right"
			@click="onClickToggleButton"
		>
			<SvgIcon name="position" class="h-3 w-3" />
		</button>

		<Popper v-if="showButtons" class="z-20" arrow placement="right-start" partent-selector=".alignment-button">
			<div class="ml-1 flex flex-col gap-1 rounded bg-white p-2 shadow-lg">
				<div class="flex gap-1">
					<button
						class="flex h-7 w-7 items-center justify-center rounded text-gray-600 hover:bg-gray-100/20 hover:text-gray-800"
						:class="{ 'bg-gray-100/20 text-gray-800': hAlignment === 'left' }"
						@click="alignHandler('left')"
					>
						<SvgIcon name="left" class="h-4 w-4 fill-current" />
					</button>
					<button
						class="flex h-7 w-7 items-center justify-center rounded text-gray-600 hover:bg-gray-100/20 hover:text-gray-800"
						:class="{ 'bg-gray-100/20 text-gray-800': hAlignment === 'center' }"
						@click="alignHandler('center')"
					>
						<SvgIcon name="center" class="h-4 w-4 fill-current" />
					</button>
					<button
						class="flex h-7 w-7 items-center justify-center rounded text-gray-600 hover:bg-gray-100/20 hover:text-gray-800"
						:class="{ 'bg-gray-100/20 text-gray-800': hAlignment === 'right' }"
						@click="alignHandler('right')"
					>
						<SvgIcon name="right" class="h-4 w-4 fill-current" />
					</button>
				</div>

				<div class="flex gap-1">
					<button
						class="flex h-7 w-7 items-center justify-center rounded text-gray-600 hover:bg-gray-100/20 hover:text-gray-800"
						:class="{ 'bg-gray-100/20 text-gray-800': vAlignment === 'top' }"
						@click="alignHandler('top')"
					>
						<SvgIcon name="left" class="h-4 w-4 rotate-90 fill-current" />
					</button>
					<button
						class="flex h-7 w-7 items-center justify-center rounded text-gray-600 hover:bg-gray-100/20 hover:text-gray-800"
						:class="{ 'bg-gray-100/20 text-gray-800': vAlignment === 'middle' }"
						@click="alignHandler('middle')"
					>
						<SvgIcon name="center" class="h-4 w-4 rotate-90 fill-current" />
					</button>
					<button
						class="flex h-7 w-7 items-center justify-center rounded text-gray-600 hover:bg-gray-100/20 hover:text-gray-800"
						:class="{ 'bg-gray-100/20 text-gray-800': vAlignment === 'bottom' }"
						@click="alignHandler('bottom')"
					>
						<SvgIcon name="right" class="h-4 w-4 rotate-90 fill-current" />
					</button>
				</div>
			</div>
		</Popper>
	</div>
</template>
