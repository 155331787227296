<script lang="ts" setup>
import { useEditorMode } from '@/editor/composables/useEditorMode';
import ScrollCanvasContainer from '@/page/components/ScrollCanvasContainer.vue';
import SingleCanvasContainer from '@/page/components/SingleCanvasContainer.vue';

const props = defineProps<{
	scrollArea: HTMLElement;
}>();
const { isIllustratorContext } = useEditorMode();
</script>

<template>
	<ScrollCanvasContainer v-if="isIllustratorContext" :scroll-area="scrollArea" />
	<SingleCanvasContainer v-else :scroll-area="scrollArea" />
</template>
