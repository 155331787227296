<script lang="ts" setup>
import { computed, ref } from 'vue';

import { Color } from '@/Types/colorsTypes';

const props = defineProps<{
	colors: Color[];
	max: number;
}>();

const visibleColors = computed(() => {
	if (props.colors.length > props.max) return [];

	return props.colors;
});
const invisibleColors = computed(() => {
	return props.colors;
});
const allVisible = computed(() => visibleColors.value.length >= props.colors.length);
const container = ref();
</script>

<template>
	<div
		ref="container"
		data-testid="colorpicker-group"
		class="color-group flex items-center gap-2"
		:class="props.colors.length > 5 ? 'lg:gap-0' : 'lg:gap-2'"
	>
		<div
			v-for="color in visibleColors"
			:key="color.id"
			class="relative h-6 w-6 overflow-hidden rounded-full lg:h-7 lg:w-7"
			:class="{
				'last:mr-0 lg:-mr-2': props.colors.length > 5 && props.max !== Infinity,
			}"
		>
			<span
				:data-color-id="color.id"
				:class="`absolute left-0 top-0 h-full w-full rounded-full`"
				:style="{
					background: color.toCssString(),
				}"
			></span>
		</div>
		<div
			v-if="!allVisible"
			class="all-colors relative h-6 h-full w-6 shrink-0 overflow-hidden rounded-full text-white transition-all duration-300 lg:h-7 lg:w-7"
		>
			<span class="flex h-full w-full items-center justify-center bg-gray-900/25 text-xs font-bold">{{
				invisibleColors.length
			}}</span>
		</div>
	</div>
</template>
