import { createSharedComposable, useEventListener } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useSelection } from '@/interactions/composables/useSelection';
import { PreviousSelection, SelectionResult } from '@/Types/types';

export const useTextSelection = createSharedComposable(() => {
	const { selection: selectionElements } = useSelection();
	const previousInputSelection = ref<PreviousSelection | null>(null);
	const selection = ref<SelectionResult | null>(null);
	const domNode = ref<HTMLElement | null>(null);

	// Watcher para actualizar la selección si se selecciona un texto, o limpiarla si no
	watch(
		() => selectionElements.value[0],
		(value) => {
			if (!(value instanceof Text)) {
				domNode.value = null;
				return;
			}

			domNode.value = document.querySelector<HTMLElement>(`#element-${value.id} .text-element-final`);
			selection.value = null;
		}
	);

	// Listener para actualizar la selección
	useEventListener(document, 'selectionchange', (e) => {
		const finalSelection = window.getSelection();

		// Si no hay selección, pero el elemento seleccionado es un texto, lo seleccionamos
		// si no, limpiamos la selección
		if (!finalSelection?.anchorNode) {
			if (selectionElements.value[0] instanceof Text) {
				const textNode = document.querySelector<HTMLElement>(
					`#element-${selectionElements.value[0].id} .text-element-final`
				);

				domNode.value = textNode || null;
				selection.value = null;
				return;
			}

			domNode.value = null;
			selection.value = null;
			return;
		}

		// Obtenemos el nodo inicial de la selección y comprobamos si es un texto
		const { anchorNode } = finalSelection;

		const isTextElement =
			anchorNode.parentElement?.closest('.text-element-final') ||
			(anchorNode instanceof HTMLElement && anchorNode.classList.contains('text-element-final'));

		// Si no es un texto, limpiamos la selección
		if (!isTextElement) {
			domNode.value = null;
			selection.value = null;

			return;
		}

		// Si es un texto, seleccionamos el elemento como domNode
		// y guardamos la selección
		domNode.value = anchorNode.parentElement?.closest('.text-element-final') || (anchorNode as HTMLElement);

		selection.value = {
			text: window.getSelection()?.toString(),
			selection: window.getSelection(),
		};
	});

	const selectedNodes = computed<HTMLElement[] | Node[]>(() => {
		if (!domNode.value) {
			// Si no hay nodo, pero el elemento seleccionado es un texto, lo asignamos a los nodos seleccionados
			if (selectionElements.value[0] instanceof Text) {
				domNode.value =
					document.querySelector<HTMLElement>(`#editable-${selectionElements.value[0].id}`) ||
					document.querySelector<HTMLElement>(`#element-${selectionElements.value[0].id} .text-element-final`);

				return [domNode.value, ...Array.from(domNode.value?.querySelectorAll('*') || [])];
			}

			return;
		}

		if (!selection.value?.selection) {
			const editableText = domNode.value?.querySelector<HTMLDivElement>(':scope > div > div');
			if (editableText) {
				return [...Array.from(editableText.querySelectorAll('*') || [])];
			}
			return [domNode.value, ...Array.from(domNode.value?.querySelectorAll('*') || [])];
		}

		let finalNodes: HTMLElement[] | Node[];

		finalNodes = [domNode.value];

		const anchorNode = selection.value.selection.anchorNode as HTMLElement;

		if (
			anchorNode &&
			((anchorNode.nodeType === 1 && anchorNode.closest('.text-element-final')) ||
				(anchorNode.parentElement && anchorNode.parentElement.closest('.text-element-final')))
		) {
			const range = selection.value.selection.getRangeAt(0);

			// Obtenemos los nodos finales de la selección
			finalNodes = TextTools.getRangeSelectedNodes(range);
		} else {
			const range = new Range();

			range.selectNodeContents(domNode.value as HTMLElement);

			finalNodes = TextTools.getRangeSelectedNodes(range);
		}

		if (finalNodes.length === 1 && finalNodes[0] === domNode.value && domNode.value?.querySelectorAll('*').length) {
			finalNodes = [...finalNodes, ...Array.from(domNode.value?.querySelectorAll('*'))];
		}

		return finalNodes;
	});

	return {
		domNode,
		selection,
		selectedNodes,
		previousInputSelection,
	};
});
