import { Ref, ref } from 'vue';

import { useCustomImagesActions } from '@/api/composables/useUploadImagesActions';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { useReplaceElement } from '@/elements/medias/replace/useReplaceElement';
import { Video } from '@/elements/medias/video/classes/Video';
import { useHistoryStore } from '@/history/stores/history';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { ImageApi, UploadApi, VideoApi } from '@/Types/apiClient';

export const useUserUploads = () => {
	const temporalRef = ref<Image | Video>(Image.create());

	const { selection } = useSelection();

	const project = useProjectStore();
	const history = useHistoryStore();

	const { userUploads } = useUserImageProvider();
	const { replaceElement } = useReplaceElement(temporalRef);
	const { unifyLayers, hasForeground } = useLayersImage(temporalRef as Ref<Image>);
	const { addInsertableElement } = useAddInsertableElement();
	const { isPhotoMode } = useEditorMode();
	const { deleteUpload: deleteUploadFromServer } = useCustomImagesActions();

	const insertUploadElement = async (dataApi: ImageApi | UploadApi | VideoApi, replaceElem = false) => {
		if (replaceElem) {
			if (!isPhotoMode.value && (selection.value[0] instanceof Image || selection.value[0] instanceof Video)) {
				temporalRef.value = selection.value[0];
				await replaceElement(dataApi);
			}
		} else {
			await addInsertableElement(dataApi);
		}
	};

	const deleteUploadElement = async (imageToDelete: ImageApi | VideoApi | null) => {
		if (!imageToDelete) return;

		// Borramos imagen en server
		const { error } = await deleteUploadFromServer(imageToDelete.id);

		if (error.value) {
			throw error.value;
		}

		// En caso de que exista algún foregroundImage vinculado a esta imagen eliminamos el elemento
		project.allElements.forEach((el) => {
			if (el instanceof Image) {
				temporalRef.value = el;

				if (hasForeground.value) {
					unifyLayers();
				}
			}
		});

		// La quitamos del array de subidas ( panel )
		userUploads.value = userUploads.value.filter((el) => el.id !== imageToDelete.id);

		// Buscamos si se está usando en el proyecto y la sustituimos por not-found
		const urlToSearch = imageToDelete.url.split('?')[0];
		const mediasUsed = project.allMedias.filter((media) => media.url.includes(urlToSearch));

		mediasUsed.forEach((media) => media.toNotFound());

		history.removeUserImageFromStates(imageToDelete.url);
	};

	return {
		insertUploadElement,
		deleteUploadElement,
	};
};
