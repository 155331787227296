import { createSharedComposable } from '@vueuse/core';
import { ref } from 'vue';

export const usePikiGame = createSharedComposable(() => {
	const showPiki = ref(false);
	const showPikiGame = ref(false);

	return {
		showPiki,
		showPikiGame,
	};
});
