<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { createPopper } from '@popperjs/core';
import { SVG } from '@svgdotjs/svg.js';
import { sortByIndex } from '@tldraw/indices';
import { promiseTimeout } from '@vueuse/core';
import { difference } from 'lodash-es';
import { computed, nextTick, onMounted, Ref, ref, toRef, watch } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import GroupAlignmentShortcut from '@/elements/group/components/menus/GroupAlignmentShortcut.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextStyles, useTextStylesStatic } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useMoveable } from '@/interactions/composables/useInteractiveElements';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { Color } from '@/Types/colorsTypes';
import { EditPanels } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

const { trans } = useI18n();
const store = useMainStore();
const { selection, setSelection } = useSelection();

// Props
const props = defineProps<{ elements: Element[] }>();

// Using composables
const elements = toRef(props, 'elements');
const { isAdminMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { waitForMoveableAreaReady } = useMoveable();
const temporalRef = ref<Element>(elements.value[0]);
const temporalTransformRef = ref<Element>(Shape.create());
const { textEditing } = useTextEditing();
const { isCropping } = useInteractions();
const { onZoomFinish, onZoomStart, isZooming } = useZoom();
const { addElement, removeElement } = useActivePage();
const { isLockGroup, lockGroup, unlockGroup, group } = useGroup(temporalRef);
const { sizeWithRotation, position } = useGroupTransform(group);
const toast = useToast();
const isMounted = ref(false);
const useTransform = useElementTransformOrchestrator(temporalTransformRef);
// Methods
const onLockGroup = () => {
	lockGroup(elements.value);
};

const onUnlockGroup = () => {
	unlockGroup(elements.value);
};

const isAllElementsLocked = computed(() => {
	return elements.value.every((el) => el.locked);
});

const toggleLockGroupedElements = () => {
	let lockStatus = !isAllElementsLocked.value;
	elements.value.forEach((el) => {
		if (el instanceof ForegroundImage) return;
		el.locked = lockStatus;
	});
};

const deleteGroup = () => {
	Bugsnag.leaveBreadcrumb(
		`Delete ${isLockGroup.value ? 'group' : 'selection'}: ${elements.value.map((el) => ` ${el.type}-${el.id}`)}`
	);
	selection.value.forEach((element) => removeElement(element));
};

const mergeSelection = () => {
	const onlyShapes = elements.value.every((el) => el.type === 'shape');

	if (!onlyShapes) {
		toast.error(trans('Only can merge shapes'));
		return;
	}

	const shapes = elements.value as Shape[];

	const { selectionY, maxSelectionY, selectionX, maxSelectionX } = elements.value.reduce(
		(coords, el) => {
			temporalTransformRef.value = el;
			const { elementBoxWithRotation } = useTransform.value;
			coords.selectionY = Math.min(coords.selectionY, elementBoxWithRotation.value.top);
			coords.maxSelectionY = Math.max(coords.maxSelectionY, elementBoxWithRotation.value.bottom);
			coords.selectionX = Math.min(coords.selectionX, elementBoxWithRotation.value.left);
			coords.maxSelectionX = Math.max(coords.maxSelectionX, elementBoxWithRotation.value.right);
			return coords;
		},
		{ selectionY: Infinity, maxSelectionY: -Infinity, selectionX: Infinity, maxSelectionX: -Infinity }
	);

	const selectionWidth = maxSelectionX - selectionX;
	const selectionHeight = maxSelectionY - selectionY;

	const colors = shapes.flatMap((shape) => shape.colors);

	const colorsUnique = colors.filter(
		(color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString())
	);

	const colorsToChange = difference(colors, colorsUnique);

	// Pasamos las props del elemento al svg para poder fusionarlos
	const childs = shapes
		.sort(sortByIndex)
		.map((el) => {
			const svg = SVG(`<svg viewBox="${el.viewbox}"><g>${el.content}</g></svg>`);
			const viewbox = el.viewboxObject;
			const scaleX = MathTools.ruleOfThree(viewbox.width, 1, el.size.width);
			const scaleY = MathTools.ruleOfThree(viewbox.height, 1, el.size.height);

			const g = svg.first();
			const newX = el.position.x - selectionX;
			const newY = el.position.y - selectionY;

			g.attr('transform', `matrix(${scaleX},0,0,${scaleY},${newX},${newY})`);

			if (el.flip.x) {
				g.flip('x');
			}

			if (el.flip.y) {
				g.flip('y');
			}

			g.rotate(el.rotation);

			g.children().forEach((elChild) => elChild.transform(g.transform(), true));
			g.attr('transform', null);

			// Si hay colores repetido tenemos que cambiar la referencia a la varaible css para unificar colores que sean iguales
			if (colorsToChange.length) {
				g.find('*:not(g):not(defs)').forEach((elChild) => {
					if (!elChild.attr('style').includes('fill')) {
						return;
					}

					const style = elChild.attr('style');
					const colorToChange = colorsToChange.find((color) => style.includes(color.id));

					if (colorToChange) {
						const colorToApply = colorsUnique.find((color) => color.toCssString() === colorToChange.toCssString());
						elChild.attr('style', style.replaceAll(colorToChange.id, colorToApply?.id));
					}
				});
			}

			return g.node.innerHTML;
		})
		.flat()
		.join();

	// Eliminamos los originales y creamos el nuevo
	elements.value.forEach((el) => removeElement(el));

	const newViewBox = `0 0 ${selectionWidth} ${selectionHeight}`;
	const size = {
		width: selectionWidth,
		height: selectionHeight,
	};
	const position = { x: selectionX, y: selectionY };
	const flip = { x: false, y: false };
	const newShape = Shape.create({
		viewbox: newViewBox,
		content: childs,
		size,
		position,
		flip,
		colors: colorsUnique,
	});

	addElement(newShape);

	setSelection(newShape);
};

// Instanciamos el texto que posteriormente modificaremos para obtener su selectedColor
const finalText = ref(Text.create()) as Ref<Text>;
const staticText = ref(Text.create()) as Ref<Text>;

// Instanciamos el hook con nuestro texto default
const { selectedColor, updateColor } = useTextStyles(finalText);
const staticSelectedColor = useTextStylesStatic(staticText).selectedColor;

const selectedGroupColors = computed(() => {
	// Solo queremos devolver los colores del grupo para el botón del toolbar cuando todos los elementos son textos y no son curvos
	const onlyTexts = selection.value.every((el) => el instanceof Text && !el?.curvedProperties.arc);
	if (!onlyTexts) return [];

	let finalColors: Color[] = [];

	if (isLockGroup.value) {
		// Instanciamos el hook con nuestro texto default
		selection.value.forEach((el) => {
			/// Si el elemento es de tipo Text reemplazamos nuestro textoDefault para poder acceder a su selectedColor y así poder obtener los colores computados de sus hijos
			if (el instanceof Text) {
				let colors: SolidColor | GradientColor | SolidColor[] | undefined | any;

				if (textEditing.value && textEditing.value.id.includes(el.id)) {
					finalText.value = el;
					colors = selectedColor.value;
				} else {
					staticText.value = el;
					colors = staticSelectedColor.value;
				}

				finalColors = [...finalColors, ...colors];
			}
		});
	}
	return Array.from(new Set(finalColors.map((c) => c.toCssString())))
		.map((c) => (c.includes('gradient') ? GradientColor.fromString(c) : SolidColor.fromString(c)))
		.sort();
});

const updateTextColor = (color: Color) => {
	selection.value.map((el) => {
		if (el instanceof Text) {
			const finalNode =
				document.querySelector(`#editable-${el.id}`) || el.domNode()?.querySelector('.text-element-final');

			if (finalNode) {
				finalText.value = el;

				updateColor(color);

				el.updateContent(finalNode.innerHTML);
			}
		}
	});
};
const updatePopper = async () => {
	// Cuando se añade un texto predefinido este tarda un poco más en posicionarse y no le da tiempo suficiente con el nextTick
	// Le damos 100ms para que se monte y coloque correctamente y luego actualizamos el popper
	await promiseTimeout(100);
	popper.value?.update();
};
watch([sizeWithRotation, position], async () => {
	await updatePopper();
});

watch(
	() => [store.activePanel, store.editPanel],
	async () => {
		await promiseTimeout(10);
		popper.value?.update();
	}
);

onZoomStart(() => {
	const parent = document.querySelector('#portalTarget') as HTMLElement;
	parent.style.opacity = '0';
});

onZoomFinish(async () => {
	const parent = document.querySelector('#portalTarget') as HTMLElement;

	await waitForMoveableAreaReady();

	parent.style.opacity = '1';

	await nextTick();
	await updatePopper();
});

const toolbar = ref();
const popper = ref();

// El toolbar de los grupos trabaja respecto al elemento moveable-area. Este elemento
// no esta listo de primeras y no sabemos cuando va a estar listo y en su sitio
// asi que revisamos la transformación de su padre para ver que no este
// 0 0 0 y en ese momento mostramos el toolbar y creamos el popper
onMounted(async () => {
	await nextTick();
	const container = document.querySelector('.moveable-area') as HTMLElement;
	const parent = document.querySelector('#portalTarget') as HTMLElement;

	await waitForMoveableAreaReady();
	isMounted.value = true;

	await nextTick();

	if (!container || !parent || !toolbar.value) return;

	popper.value = createPopper(container, toolbar.value, {
		placement: 'right-start',
		modifiers: [
			{ name: 'offset', options: { offset: [0, 5] } },
			{
				name: 'flip',
				options: {
					//  evitamos que se solape con el topbar
					fallbackPlacements: ['right-end'],
					boundary: [document.querySelector('#scroll-area')],
				},
			},
		],
	});
});

const onClickMoreTools = () => {
	if (store.editPanel === EditPanels.Group) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.Group;
	Bugsnag.leaveBreadcrumb(`Open edit group panel`);
};
</script>

<template>
	<teleport v-if="!isMobile && isMounted" to="#groupToolbarTarget">
		<div
			v-show="!isCropping && !isZooming"
			ref="toolbar"
			data-testid="btns-group"
			class="toolbar-group z-20 flex flex-col gap-1"
		>
			<!-- Color picker -->
			<div
				v-if="selectedGroupColors.length"
				:tooltip="trans('Text color')"
				tooltip-position="right"
				class="h-6 w-6 rounded-full border-2 border-gray-600"
			>
				<ColorPicker
					class="h-5 w-5"
					parent=".toolbar-group"
					placement="right-start"
					:color="selectedGroupColors as Color[]"
					@change="updateTextColor"
				/>
			</div>
			<button
				v-if="!isLockGroup"
				class="flex h-6 items-center justify-center rounded-full bg-gray-700 px-2 text-xs text-white/80 shadow hover:text-white"
				tooltip="Ctrl + G"
				tooltip-position="right"
				@mouseup="onLockGroup"
			>
				{{ trans('Group') }}
			</button>
			<button
				v-if="isLockGroup"
				class="flex h-6 items-center justify-center rounded-full bg-blue-500 px-2 text-xs text-white shadow hover:text-white slidesgo:bg-purple-400"
				tooltip="Ctrl + G"
				tooltip-position="right"
				@mousedown="onUnlockGroup"
			>
				{{ trans('Ungroup') }}
			</button>

			<button
				v-if="!isLockGroup && isAdminMode"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white shadow hover:text-blue-600 slidesgo:bg-purple-400"
				tooltip="Merge shapes"
				tooltip-position="right"
				@click="mergeSelection"
			>
				<SvgIcon name="chain" class="h-3 w-3" />
			</button>
			<GroupAlignmentShortcut v-if="!isAllElementsLocked" />
			<button
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-xs text-white/80 shadow hover:text-white"
				:tooltip="trans('Delete group')"
				tooltip-position="right"
				@click="deleteGroup"
			>
				<SvgIcon name="trash" class="h-3 w-3" />
			</button>

			<button
				class="flex h-6 w-6 items-center justify-center rounded-full text-xs text-white/80 shadow hover:text-white"
				:tooltip="trans(isAllElementsLocked ? 'Unlock group' : 'Lock group')"
				tooltip-position="right"
				:class="isAllElementsLocked ? 'bg-blue-500' : 'bg-gray-700'"
				@click="toggleLockGroupedElements"
			>
				<SvgIcon :name="isAllElementsLocked ? 'lock' : 'unlock'" class="h-3 w-3" />
			</button>

			<button
				v-if="isLockGroup && !isAllElementsLocked"
				data-testid="open-more-tools-group"
				:tooltip="trans('More')"
				tooltip-position="right"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-xs text-white/80 shadow hover:text-white"
				@click="onClickMoreTools"
			>
				<span :class="store.editPanel === EditPanels.Group ? 'text-white' : ''">
					<SvgIcon name="more" class="h-3 w-3" />
				</span>
			</button>
		</div>
	</teleport>

	<teleport v-if="isMobile && isMounted" to=".topbar-color-mobile">
		<div class="ml-auto flex" data-testid="group-toolbar">
			<!-- Color picker -->
			<div v-if="selectedGroupColors.length" class="mr-3 border-r border-gray-600 pr-3">
				<ColorPicker class="h-6 w-6" :color="(selectedGroupColors as Color[])" @change="updateTextColor" />
			</div>
			<button
				v-if="isLockGroup"
				class="flex h-6 items-center justify-center rounded-full bg-blue-500 px-2 text-xs text-white shadow hover:text-white slidesgo:bg-purple-400"
				@click="onUnlockGroup"
			>
				{{ trans('Ungroup') }}
			</button>

			<button
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-700 text-gray-100 hover:bg-opacity-90"
				@click="deleteGroup"
			>
				<SvgIcon name="trash" class="h-4 w-4 scale-90 fill-current" />
			</button>
		</div>
	</teleport>
</template>
