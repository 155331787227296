import { createSharedComposable } from '@vueuse/core';
import { ref } from 'vue';

export const useGlobalError = createSharedComposable(() => {
	const error = ref<Error | null>(null);

	const setGlobalError = (e: Error | string) => {
		if (typeof e === 'string') {
			e = new Error(e);
		}

		error.value = e;
	};

	return {
		error,
		setGlobalError,
	};
});
