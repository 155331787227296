<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';

const moreButton = ref();
const visible = ref(false);

onClickOutside(moreButton, () => {
	visible.value = false;
});

const toggleVisibility = () => {
	visible.value = !visible.value;
};
</script>
<template>
	<button
		ref="moreButton"
		class="relative flex h-6 w-6 items-center justify-center rounded hover:bg-gray-100/20"
		@click="toggleVisibility"
	>
		<SvgIcon name="more" class="h-4 w-4" />
		<div v-if="visible" class="absolute top-6 right-0 z-10 w-48 overflow-hidden rounded-md bg-white text-sm shadow-xl">
			<slot></slot>
		</div>
	</button>
</template>
<style></style>
