<script lang="ts" setup>
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useEditorClipboard } from '@/editor/composables/useEditorClipboard';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
const { trans } = useI18n();
// Props
defineProps<{ element: Element }>();

// Using composables
const { copySelection, pasteSelection } = useEditorClipboard();
const toast = useToast();
const { isMobile } = useDeviceInfo();

const store = useMainStore();
const { selection } = useSelection();

const copyAndPasteElement = async () => {
	await copySelection();
	await pasteSelection();
	if (!isMobile) toast.info(trans(`${selection.value.length > 1 ? 'Items' : 'Item'} added to clipboard`));
};
</script>

<template>
	<button
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between"
		:class="{
			'text-gray-300 opacity-75': element.locked,
			'text-gray-100 hover:text-white': !element.locked,
		}"
		data-testid="copy"
		:disabled="element.locked"
		@click="copyAndPasteElement"
	>
		<SvgIcon name="copy" class="h-4 w-4 fill-current group-hover:text-white" />
		<span class="text-2xs">{{ trans('Copy') }}</span>
	</button>
</template>
