<script setup lang="ts">
import { onClickOutside, useEventListener } from '@vueuse/core';
import { onMounted, Ref, ref } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProject } from '@/project/composables/useProject';

const CONTEXT_MENU_HEIGHT = 206;

// Emits
const emit = defineEmits(['show']);

const contextmenu = ref();
const left = ref(0);
const top = ref(0);
const visible = ref(false);
const temporalRefPage = ref(Page.createDefault());
const temporalRefElement = ref(Image.create());

const store = useMainStore();
const { selection, selectionId, setSelection, clearSelection } = useSelection();

const { getPageFromDom, getPageFromElement } = useProject();
const { getElementFromDom } = usePage(temporalRefPage as Ref<Page>);
const { isPhotoMode } = useEditorMode();
const { isBackground } = useIsBackground(temporalRefElement);

const show = async (e: MouseEvent) => {
	e.preventDefault();

	const target = e.target as HTMLElement;
	const inCanvas = target && target.closest('.canvas') !== null;
	const inMoveable = target && target.closest('.moveable-control-box') !== null;

	if (!inCanvas && !inMoveable && !isPhotoMode.value) return;

	let pageInDom = target.closest('.canvas') as HTMLElement;
	const elementInDom = target.closest('.element') as HTMLElement;

	// Si no hay página comprobamos si el elemento que está debajo si que está en un canvas por si el moveable está por
	// delante o está haciendo click en moveable pero parte de este está fuera del canvas
	if (!pageInDom && e.target instanceof HTMLElement) {
		e.target.style.pointerEvents = 'none';
		const targetBehind = document.elementFromPoint(e.clientX, e.clientY) as HTMLElement;
		e.target.style.pointerEvents = 'auto';

		pageInDom = (targetBehind.closest('.canvas') as HTMLElement) || targetBehind?.querySelector('.canvas');
	}

	// si no hay página, y es un grupo, la página es quien contenga el elemento seleccionado.
	// esto se da cuando hay un grupo y hacemos click derecho
	if (!pageInDom && selectionId.value.length) {
		pageInDom = selection.value[0].domNode()?.closest('.canvas') as HTMLElement;
	}

	// Si hay un elemento bajo el mouse lo seleccionamos
	if (elementInDom) {
		const page = getPageFromDom(pageInDom as HTMLElement) as Page;

		temporalRefPage.value = page;
		const element = getElementFromDom(elementInDom);

		if (element) {
			// Si el elemento es una imagen de fondo no la seleccionamos
			let forceSelection = true;

			if (element.type === 'image') {
				temporalRefElement.value = element as Image;
				forceSelection = !isBackground.value;
			}

			if (forceSelection) {
				setSelection(element);
			}
		}
	} else if (selectionId.value.length > 0) {
		if (!pageInDom) {
			throw new Error('Cannot found where was the click made');
		}

		const page = getPageFromDom(pageInDom) as Page;

		if (getPageFromElement(selection.value[0])?.id !== page.id) {
			clearSelection();
			store.setActivePage(page);
		}
	}

	visible.value = true;

	left.value = e.clientX;
	top.value = e.clientY;

	// Para evitar que el contextmenu se salga de la página
	if (window.innerHeight < e.pageY + CONTEXT_MENU_HEIGHT) {
		top.value = e.pageY - CONTEXT_MENU_HEIGHT;
	}

	emit('show');
};

const hide = () => {
	visible.value = false;
};

onMounted(() => {
	const target = document.querySelector('#scroll-area');
	useEventListener(target, 'contextmenu', show);
});

onClickOutside(contextmenu, hide);

defineExpose({
	hide,
	top,
	left,
});
</script>

<template>
	<ul
		v-if="visible"
		ref="contextmenu"
		class="ContextMenu is-open fixed z-30 m-0 hidden w-32 list-none overflow-hidden rounded p-0 py-1 text-sm shadow-custom outline-none"
		tabindex="-1"
		:style="{
			left: `${left}px`,
			top: `${top}px`,
		}"
	>
		<slot />
	</ul>
</template>

<style lang="scss">
.ContextMenu {
	background-color: rgba(255, 255, 255, 0.98);

	.ContextMenu-item {
		& > * {
			@apply px-3 py-1 text-gray-700;

			&:hover,
			&:focus {
				@apply bg-gray-100 bg-opacity-25;
			}

			&.ContextMenu-item-disable {
				@apply cursor-not-allowed text-gray-100;

				&:hover,
				&:focus {
					@apply bg-transparent;
				}
			}
		}

		&:focus {
			outline: 0;
		}
	}

	.ContextMenu-divider {
		background-color: #e3e9ed;
	}

	&.is-open {
		@apply block;
	}
}

.ContextMenu-item {
	@apply block cursor-pointer overflow-hidden truncate;
}

.ContextMenu-divider {
	@apply my-1 h-px;
}
</style>
