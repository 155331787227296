<script setup lang="ts">
import { Ref, ref } from 'vue';

import PagesListbox from '@/common/components/PagesListbox.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import OnClickOutsideWithPortals from '@/interactions/components/OnClickOutsideWithPortals';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';

const store = useMainStore();
const project = useProjectStore();
const toast = useToast();
const { trans } = useI18n();

const open = ref<boolean>(false);
const applingFix = ref<boolean>(false);
const tempPageRef = ref(Page.createDefault());
const { adjustContent } = usePage(tempPageRef as Ref<Page>);
const { convertMmToPx } = useArtboard();
const { selectedPages } = useDownloadsProject();

const fixDpi = () => {
	if (applingFix.value) return;

	applingFix.value = true;

	const { width, height } = project.size;
	const dpi = store.dpi;
	const OLD_MM_TO_PX = 11.811;

	selectedPages.value.forEach((page) => {
		tempPageRef.value = page;

		const currentSize = {
			width: width * dpi,
			height: height * dpi,
		};
		const newSize = {
			width: width * OLD_MM_TO_PX,
			height: height * OLD_MM_TO_PX,
		};

		adjustContent(currentSize, newSize);
		adjustContent(convertMmToPx(width, height), {
			width: Math.round(width * dpi),
			height: Math.round(height * dpi),
		});
	});

	toast.success(trans('Fix applied!'));

	applingFix.value = false;
};

const closeModal = () => {
	if (applingFix.value) return;

	open.value = false;
};
</script>

<template>
	<OnClickOutsideWithPortals ignore-selector=".ignore-click" @trigger="closeModal">
		<button
			class="relative flex h-10 w-8 items-center justify-center rounded bg-amber-500 font-bold text-white hover:bg-amber-600 lg:w-auto lg:px-6 lg:py-3"
			@click="open = true"
		>
			<SvgIcon name="blank" class="h-5 w-5 fill-current lg:h-4 lg:w-4" />
			<span class="ml-2 hidden sm:block">{{ $t('Fix dpi') }}</span>
		</button>

		<div
			v-if="open"
			class="overflow-overlay fixed right-2 top-16 w-screen max-w-[95%] overflow-auto rounded-md bg-white p-4 shadow-xl scrollbar-thin scrollbar-thumb-gray-100 lg:max-h-[calc(100vh-70px)] lg:max-w-sm"
		>
			<p class="mb-4 text-lg font-semibold text-gray-700">{{ trans('Fix dpi') }}</p>

			<PagesListbox :multiple="true" />

			<p class="mt-2 text-sm text-gray-400">{{ trans('This fix adjusts the template content to use the new dpi.') }}</p>

			<button
				class="mt-5 flex h-10 w-full items-center justify-center rounded bg-amber-500 font-semibold text-white hover:bg-amber-600"
				@click="fixDpi"
			>
				<SvgIcon v-if="applingFix" name="spinner" class="h-4 w-4 animate-spin" />
				<span v-else>{{ trans('Apply fix') }}</span>
			</button>
		</div>
	</OnClickOutsideWithPortals>
</template>
