import { Dom, Element as SvgElement, SVG } from '@svgdotjs/svg.js';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import Element from '@/elements/element/classes/Element';
import ElementTools from '@/elements/element/utils/ElementTools';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Color } from '@/Types/colorsTypes';
import { BoxDTO } from '@/Types/elements';
import { Border, BorderRadiusType, SerializedClass, TypeBorder } from '@/Types/types';

export class Box extends Element {
	type: 'box' = 'box';
	border: Border;
	background: Color;

	protected constructor(boxDTO: BoxDTO) {
		super(boxDTO);

		this.border = boxDTO.border;
		this.background = boxDTO.background;
	}

	static defaults(): BoxDTO {
		return {
			// Element
			...Element.defaults(),
			type: 'box',
			keepProportions: true,
			// Box
			border: {
				type: TypeBorder.None,
				size: 0,
				color: SolidColor.black(),
				radius: [0, 0, 0, 0] as BorderRadiusType,
			},
			background: SolidColor.lightGray(),
		};
	}

	static create(config: Partial<BoxDTO> = {}): Box {
		const boxDTO = {
			...Box.defaults(),
			...config,
		};

		return new Box(boxDTO);
	}

	public scaleBy(scale: number) {
		super.scaleBy(scale);
		//  TODO: revisar en  implementación de tablas
		this.border.radius = this.border.radius.map((r) => (r *= scale)) as BorderRadiusType;
		this.border.size *= scale;
	}

	/**
	 * Parsea el borde de un rect usando expresiones regulares
	 * @param data
	 * @returns
	 */
	static parseBorder = (data: SerializedClass<Shape>) => {
		if (!data.content || !data.size) return 0;
		const rawRx = data.content.match(/rx="(\d+)/);
		const rx: number = rawRx ? parseInt(rawRx[1]) : 0;
		return rx * (Math.max(data.size.width, data.size.height) / Math.min(data.size.width, data.size.height)) * 2;
	};

	/**
	 * Obtiene el estilo de borde de un rect sin usar expresiones regulares
	 * @param rect
	 * @param defs
	 * @returns
	 */
	static getStrokeStyle = (rect: Dom | null, defs: Dom | null): Border => {
		// TODO: revisar en  implementación de tablas
		// Si el rect no existe, devolvemos un borde vacío
		if (!rect) return { type: TypeBorder.None, size: 0, color: SolidColor.black(), radius: [0, 0, 0, 0] };

		// Si el rect no tiene un stroke, devolvemos un borde vacío
		const strokeStyle = rect.node.getAttribute('stroke') || rect.node.style.stroke;
		let strokeColor: Color = SolidColor.black();

		// TODO: revisar en  implementación de tablas
		// Si el stroke es transparente, devolvemos un borde vacío
		if (strokeStyle === 'transparent')
			return { type: TypeBorder.None, size: 0, color: SolidColor.black(), radius: [0, 0, 0, 0] };

		// Si el stroke es un gradiente, lo convertimos a objeto
		if (rect.node.getAttribute('stroke')?.includes('url(') && defs) {
			const gradientId = rect.node.getAttribute('stroke')?.replace('url(#', '').replace(')', '');
			const gradient = defs.findOne(`#${gradientId}`);

			if (gradient instanceof SvgElement) {
				strokeColor = ElementTools.svgGradientToObject(gradient);
			}
			// Si el stroke es un color sólido, lo convertimos a objeto
		} else if (strokeStyle.length) {
			strokeColor = SolidColor.fromString(strokeStyle);
			// TODO: revisar en  implementación de tablas
			// Si el stroke es transparente, devolvemos un borde vacío
			if (strokeColor.a === 0)
				return { type: TypeBorder.None, size: 0, color: SolidColor.black(), radius: [0, 0, 0, 0] };
		}

		// Obtenemos el stroke-width
		const strokeWidthStyle = rect.node.getAttribute('stroke-width') || rect.node.style.strokeWidth;
		const strokeWidth = strokeWidthStyle ? parseFloat(strokeWidthStyle) : 0;

		// Obtenemos el stroke-dasharray
		const strokeDashArray = rect.node.getAttribute('stroke-dasharray') || rect.node.style.strokeDasharray;

		// Si el stroke-width es 0, devolvemos un borde vacío
		let typeBorder = strokeWidth ? TypeBorder.Solid : TypeBorder.None;

		if (rect.node.getAttribute('stroke-type')) {
			typeBorder = rect.node.getAttribute('stroke-type') as TypeBorder;
		} else {
			// Si el stroke-dasharray es 0, devolvemos un borde sólido
			if (strokeDashArray.length) {
				typeBorder = strokeDashArray.length > 1 ? TypeBorder.Dashed : TypeBorder.Solid;
			}
		}
		return {
			type: typeBorder,
			size: strokeWidth,
			color: strokeColor,
			radius: this.getBorderRadius(rect),
		};
	};

	/**
	 * Obtiene el estilo de fondo de un rect
	 * @param rect
	 * @param defs
	 *
	 * @returns
	 */
	static getRectFillStyle = (rect: Dom | null, defs: Dom | null): Color => {
		// Si el rect no existe, devolvemos un fondo vacío
		if (!rect) return SolidColor.black();

		// Si el rect no tiene un fill, devolvemos un fondo vacío
		let fillColor: Color = SolidColor.black();

		// Si el fill es un gradiente, lo convertimos a objeto
		if (rect.node.getAttribute('fill')?.includes('url(') && defs) {
			const gradientId = rect.node.getAttribute('fill')?.replace('url(#', '').replace(')', '');
			const gradient = defs.findOne(`#${gradientId}`);

			if (gradient instanceof SvgElement) {
				fillColor = ElementTools.svgGradientToObject(gradient);
			}
		} else {
			fillColor = SolidColor.fromString(rect.node.getAttribute('fill') || rect.node.style.fill);
		}

		return fillColor;
	};

	/**
	 * Devuelve el radio de los bordes del rectángulo
	 * @param rect
	 * @returns
	 */
	private static getBorderRadius = (rect: Dom): BorderRadiusType => {
		const rectNode = rect.node;
		const rx = rectNode.getAttribute('rx');

		if (!rx) {
			return [0, 0, 0, 0];
		}
		// TODO: revisar en  implementación de tablas  IMPORTANTE :
		//  TODO :  Aún no se contemplan svgs que se puedan añadir al editor que tengan valores distintos en el redondeo de los bordes , de momento vienen con un mismo valor para el border radius
		const finalBorderRadius = parseFloat(rx);
		return [finalBorderRadius, finalBorderRadius, finalBorderRadius, finalBorderRadius];
	};

	@PurifyUnserialize()
	static fromShape(data: SerializedClass<Shape> | Shape): Box {
		const boxDTO = {
			...Box.defaults(),
			...data,
		} as BoxDTO;

		if (data.colors) {
			boxDTO.background =
				'stops' in data.colors[0] ? GradientColor.unserialize(data.colors[0]) : SolidColor.unserialize(data.colors[0]);
		}

		if (data.content && data.size) {
			const rawRx = this.parseBorder(data);
			boxDTO.border.radius = [rawRx, rawRx, rawRx, rawRx];
		}

		const box = new Box(boxDTO);

		if (data.id) {
			box.id = data.id;
		}

		return box;
	}

	@PurifyUnserialize()
	static unserialize(data: BoxDTO): Box {
		const boxDTO = {
			...Box.defaults(),
			...data,
		};

		if (data.background) {
			boxDTO.background =
				'stops' in data.background
					? GradientColor.unserialize(data.background)
					: SolidColor.unserialize(data.background);
		}

		const { radius } = boxDTO.border;
		const radiusFixed = !Array.isArray(radius) ? (Array(4).fill(radius) as BorderRadiusType) : radius;
		boxDTO.border = {
			...boxDTO.border,
			radius: radiusFixed,
			color:
				'stops' in boxDTO.border.color
					? GradientColor.unserialize(boxDTO.border.color)
					: SolidColor.unserialize(boxDTO.border.color),
		};

		const box = new Box(boxDTO);

		if (data.id) {
			box.id = data.id;
		}

		return box;
	}

	updateStrokeColor(newColor: Color) {
		this.border.color = newColor;
	}

	updateBackgroundColor(newColor: Color) {
		this.background = newColor;
	}

	static fromSvg(rawSvg: string): Box {
		rawSvg = rawSvg.substring(rawSvg.indexOf('<svg'));
		const shapeSvg = SVG(rawSvg);

		// Añadimos un <g> temporal para obtener info del contenido
		shapeSvg.node.innerHTML = `<g>${shapeSvg.node.innerHTML}</g>`;

		const mainG = shapeSvg.first();

		// Extraemos los datos que queremos añadir al Box
		const { x, y, height, width } = mainG.bbox();
		const rect = shapeSvg.findOne('rect');
		const defs = shapeSvg.findOne('defs');
		const background = this.getRectFillStyle(rect, defs);
		const currentStroke: Border = this.getStrokeStyle(rect, defs);

		const newBox = Box.create({
			position: {
				x,
				y,
			},
			size: {
				width,
				height,
			},
			border: currentStroke,
			background,
		});

		return newBox;
	}
	// TODO: revisar en  implementación de tablas
	static generateSvgBorder(w: number, h: number, borderRadius: BorderRadiusType) {
		const [topLeftBorder, topRightBorder, bottomRightBorder, bottomLeftBorder] = borderRadius;
		return `M 0 ${topLeftBorder} A ${topLeftBorder} ${topLeftBorder} 0 0 1 ${topLeftBorder} 0 L ${
			w - topRightBorder
		} 0 A ${topRightBorder} ${topRightBorder} 0 0 1 ${w} ${topRightBorder} L ${w} ${
			h - bottomRightBorder
		} A ${bottomRightBorder} ${bottomRightBorder} 0 0 1 ${
			w - bottomRightBorder
		} ${h} L ${bottomLeftBorder} ${h} A ${bottomLeftBorder} ${bottomLeftBorder} 0 0 1 0 ${h - bottomLeftBorder} Z`;
	}

	get colors(): Color[] {
		const colors = [this.background];

		if (this.border.size) {
			colors.push(this.border.color);
		}

		return colors;
	}

	clone(): this {
		const element = super.clone();

		// Modificamos los id a los colores de la copia
		element.colors.forEach((color) => {
			color.id = 'color-' + uuidv4();
		});

		return element;
	}
}
