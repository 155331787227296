<script lang="ts" setup>
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useImagePresets } from '@/elements/medias/images/foreground/composables/useImagePresets';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');
const { separateLayers, hasForeground } = useLayersImage(element);
const { removeBg, reset } = useImagePresets(element);
const { trans } = useI18n();

const toast = useToast();
const { isLogged } = useAuth();
// Data
const removeBackground = async () => {
	try {
		if (!hasForeground.value) {
			await separateLayers();
		}

		if (!isLogged.value && !element.value.urlBackgroundRemoved) {
			return;
		}

		if (isRemoved.value) {
			reset();
			return;
		}

		removeBg();
	} catch (error) {
		toast.error(trans('There was an error while removing the background, try with another image'));
	}
	GAnalytics.track('click', 'Button', 'remove-background', null);
	GAnalytics.trackDebounceEditBarGA4({ category: 'photo', link_text: 'remove_bg' });
};

const isRemoved = computed(() => element.value.opacity === 0);
</script>

<template>
	<button
		data-testid="remove-recover-img-bg"
		:tooltip="isRemoved ? trans('Recover bg') : trans('Remove bg')"
		tooltip-position="top"
		data-button-importance="important"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-full lg:shrink lg:flex-row lg:justify-center lg:px-2"
		@click="removeBackground"
	>
		<SvgIcon name="remove-bg" class="h-5 w-5 shrink-0 scale-90" />
		<span class="whitespace-nowrap text-2xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{
			isRemoved ? trans('Recover bg') : trans('Remove bg')
		}}</span>
	</button>
</template>
