import Bugsnag from '@bugsnag/js';
import { createEventHook, createSharedComposable, useMousePressed, useTimeoutFn } from '@vueuse/core';
import { isEmpty } from 'lodash';
import Selecto, { OnDragStart, OnSelect, OnSelectEnd } from 'selecto';
import { computed, onBeforeUnmount, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useGhostMoveable } from '@/elements/medias/crop/composables/useCrop';
import { useCropPhotoModeMoveable } from '@/elements/medias/crop/composables/useCropPhotoMode';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Text } from '@/elements/texts/text/classes/Text';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useCopyStyles } from '@/elements/texts/text/composables/useCopyStyles';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useMoveable } from '@/interactions/composables/useInteractiveElements';
import { useSelection } from '@/interactions/composables/useSelection';
import { useActivePage } from '@/page/composables/useActivePage';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { ElementClass, InteractionAction } from '@/Types/types';

export const useInteractions = createSharedComposable(() => {
	// Para ejecutar correctamente el onBlur del TextEditing y salir del modo edición
	// debemos ejecutarlo antes de que el clear selection se ejecute.
	const selecto = ref();
	const disableToolbar = ref(false);
	const { ghostMoveable } = useGhostMoveable();
	const { generatingContent, closeAIWriter, confirmChange, textAI } = useAiWriter();
	const { cropPhotoModeMoveable } = useCropPhotoModeMoveable();
	const { copiedStyles, pasteStyles } = useCopyStyles();

	const { pressed } = useMousePressed();

	const { moveable, action, isMiddleHandler, isCreatingElement } = useMoveable();
	const isDragging = computed(() => action.value === InteractionAction.Drag);
	const isResizing = computed(() => action.value === InteractionAction.Resize);
	const isSelecting = computed(() => action.value === InteractionAction.Select);
	const isRotating = computed(() => action.value === InteractionAction.Rotate);
	const isIdle = computed(() => action.value === InteractionAction.Idle);

	const onBeforeClearSelection = createEventHook();
	const { textEditing } = useTextEditing();
	const store = useMainStore();
	const { selection, setSelection, clearSelection, removeFromSelection } = useSelection();

	const isCropping = computed(() => !!store.croppingId);
	const isCroppingTime = computed(() => !!store.croppingTimeId);
	const isEditingText = computed(() => !!textEditing.value);
	const { getElementFromDom } = useProject();
	const { getElementFromDom: getElementFromActivePageDom } = useActivePage();
	const temporalRef = ref<Element>(Image.create());
	const { page } = usePageElement(temporalRef);
	const { isBackground } = useIsBackground(temporalRef);
	const { isPhotoMode, isIllustratorContext } = useEditorMode();
	const { isMobile, isTouch } = useDeviceInfo();
	const project = useProjectStore();
	const { onZoomStart, onZoomFinish, isPinching } = useZoom();
	const selectionLock = ref(false);

	const prevSelection = ref();

	const setupInteraction = () => {
		watch(isIdle, (isIdle) => {
			if (isIdle) {
				project.resumeAutoSave?.();
			} else {
				project.pauseAutoSave?.();
			}
		});

		watch([() => selection.value[0], () => selection.value[0]?.keepProportions], () => {
			if (selection.value.length !== 1) return;
			toggleMiddleHandlers();
		});

		selecto.value = createSelectoInstance();
		registerEvents();
	};

	onBeforeUnmount(() => {
		selecto.value?.destroy();
		selecto.value = null;
	});

	onZoomStart(() => {
		if (!selection.value.length || selectionLock.value) return;

		handleAiWriterAtResize();

		prevSelection.value = selection.value.length ? selection.value[0] : null;
		clearSelection();
	});

	onZoomFinish(() => {
		if (!prevSelection.value) return;

		setSelection(prevSelection.value);
	});

	/**
	 * Si estamos generando contenido con el AI Writer o ya ha terminado de generarlo,
	 * cerramos el AI Writer, en caso de no haber terminado de generarlo establecemos un texto por defecto
	 */
	const handleAiWriterAtResize = () => {
		if (generatingContent.value && selection.value[0] instanceof Text) {
			selection.value[0].content = selection.value[0].content.length ? selection.value[0].content : 'Ai Writer Text';
			closeAIWriter();
			return;
		}

		if (textAI.value && !generatingContent.value && selection.value[0] instanceof Text) {
			confirmChange();
			closeAIWriter();
			return;
		}
	};

	const createSelectoInstance = () => {
		const scrollContainer = document.getElementById('scroll-area');
		return new Selecto({
			// The container to add a selection element
			container: scrollContainer,
			// Selecto's root container (No transformed container. (default: null)
			rootContainer: null,
			// Targets to select. You can register a queryselector or an Element.
			selectableTargets: ['.target', '.editable', '[data-illustrator-link]'],
			// Whether to select by click (default: true)
			selectByClick: true,
			// Whether to select from the target inside (default: true)
			selectFromInside: true,
			// After the select, whether to select the next target with the selected target (deselected if the target is selected again).
			continueSelect: false,
			// Determines which key to continue selecting the next target via keydown and keyup.
			toggleContinueSelect: isIllustratorContext.value ? null : ['shift'],
			// The container for keydown and keyup events
			keyContainer: window,
			// The rate at which the target overlaps the drag area to be selected. (default: 100)
			hitRate: isIllustratorContext.value ? 0 : 1,
			// Añadido un scroll options para poder mantener la selección
			scrollOptions: { container: scrollContainer as HTMLElement },
		});
	};

	const registerEvents = () => {
		selecto.value
			.on('select', (e: OnSelect) => selectingHandler(e))
			.on('selectEnd', (e: OnSelectEnd) => selectionHandler(e))
			.on('dragStart', dragStartHandler);

		selecto.value.container.addEventListener('scroll', (e: any) => {
			selecto.value.checkScroll(e);
		});
	};

	/**
	 * e nos devuelve un array con elementos seleccionados (e.added) y eliminados (e.removed)
	 * añadimos o eliminamos de la selección según corresponda
	 * */
	const selectionHandler = (e: OnSelectEnd) => {
		if (isIllustratorContext.value) {
			selectionIllustratorMode(e);
			return;
		}

		selectionEditorMode(e);
	};
	const selectionEditorMode = (e: OnSelectEnd) => {
		if (!store.activePage) return;

		// Comprobamos si solo tenemos seleccionado un elemento. En caso de ser una imagen establecida como background, establecemos la selección
		// y salimos
		if (e.selected.length === 1) {
			const element = getElementFromDom(e.selected[0] as HTMLElement);
			if (!element) return;

			temporalRef.value = element;

			if (isBackground.value) {
				setSelection(temporalRef.value);
				return;
			}
		}
		const selectedElements = e.selected
			.map((domEl: HTMLElement | SVGElement) => getElementFromActivePageDom(domEl as HTMLElement))
			.filter((el) => !!el)
			.filter((element): element is Exclude<ElementClass, ForegroundImage> => {
				if (element instanceof ForegroundImage) {
					return false;
				}

				return !element?.locked;
			});
		store.$patch(() => {
			setSelection(selectedElements, !!selection.value.length);
		});

		action.value = InteractionAction.Idle;

		if (!isEmpty(selectedElements)) {
			Bugsnag.leaveBreadcrumb(`Mouse selection: ${selectedElements.map((el) => `${el.type}-${el.id}`)}`);

			if (selectedElements.every((e) => e.type === 'text') && selectedElements.length > 1) {
				// En caso de haber más de un elemento de tipo texto
				GAnalytics.track('click', 'Template', 'select-multiple-texts', null);
			} else if (selectedElements.length > 1) {
				// En caso de haber más de un elemento de varios tipos
				GAnalytics.track('click', 'Template', 'select-multiple-elements', null);
			} else {
				// En caso de solo haber un elemento
				GAnalytics.track('click', 'Template', `select-${selectedElements[0].type}`, null);
			}
		}
	};
	const selectionIllustratorMode = (e: OnSelectEnd) => {
		if (!store.activePage) {
			return;
		}

		const shiftIsPressed = e.inputEvent?.shiftKey || false;

		if (e.inputEvent?.target?.classList.contains('interactive-canvas')) {
			store.illustratorSelection.clear();
			document.querySelectorAll('.tree-element-selection').forEach((el) => {
				el.classList.remove('tree-element-selection');
			});
		}

		const illustratorSelection = e.selected.filter((el) => el.dataset.illustratorLink);
		store.$patch(() => {
			illustratorSelection.forEach((el) => {
				if (
					shiftIsPressed &&
					el.dataset.illustratorLink &&
					store.illustratorSelection.has(el.dataset.illustratorLink)
				) {
					store.illustratorSelection['delete'](el.dataset.illustratorLink as string);
					el.classList.remove('tree-element-selection');
				} else {
					store.illustratorSelection['add'](el.dataset.illustratorLink as string);
					el.classList.add('tree-element-selection');
				}
			});
		});

		action.value = InteractionAction.Idle;
	};

	const selectingHandler = (e: OnSelect) => {
		if (isIllustratorContext.value) {
			handleSelectingIllustratorMode(e);
			return;
		}

		handleSelectingEditorMode(e);
	};

	const handleSelectingEditorMode = (e: OnSelect) => {
		e.added.forEach((el) => {
			if (!el.closest(`#canvas-${store.activePage?.id}`)) {
				return;
			}
			// No añadimos el anillo de selección a las imágenes foreground
			const element = getElementFromDom(el as HTMLElement);
			if (element instanceof ForegroundImage) return;
			if (element?.locked) return;

			el.classList.add('ring-custom-select');
		});

		e.removed.forEach((el) => {
			el.classList.remove('ring-custom-select');
		});
	};

	const handleSelectingIllustratorMode = (e: OnSelect) => {
		if (e.inputEvent.target.closest('.illustrator-page-btns')) return;

		const shiftIsPressed = e.inputEvent?.shiftKey || false;

		e.added.forEach((el) => {
			if (!el.closest(`#canvas-${store.activePage?.id}`)) return;

			el.classList.add('tree-element-selection');

			if (!shiftIsPressed) {
				store.illustratorSelection['add'](el.dataset.illustratorLink as string);
			}
		});
	};

	const dragStartHandler = (e: OnDragStart) => {
		// Para el ai writer
		if (selectionLock.value) {
			e.stop();
		}

		// Si estamos en modo ai writer deshabilitamos la selección a partir de la caja de selección
		if (!selection.value.length && e.inputEvent.shiftKey) return;

		const target = getRealTarget(e);
		const isGroup = selection.value.length > 1;
		const isVideoPlayer = target.closest('.video-player-element');

		const isEditableElement = (target.closest('.target') || target.closest('.editable')) as
			| HTMLElement
			| SVGElement
			| null;
		const isGhostImage = target.classList.contains('ghost-image');
		const isLineHandler = target.classList.contains('line-handler');
		const isToolbar = target.classList.contains('toolbar') || !!target.closest('.toolbar');

		const isMoveableArea = e.inputEvent.target.classList.contains('moveable-area');

		if (!isLineHandler) {
			action.value = InteractionAction.Idle;
		}

		// Si el elemento seleccionado no está en la página activa seteamos como activa la página a la que pertenece
		if (isEditableElement && !isEditableElement.closest(`#canvas-${store.activePage?.id}`)) {
			const element = getElementFromDom(isEditableElement as HTMLElement);

			if (element) {
				temporalRef.value = element;
				store.setActivePage(page.value, true);
			}

			if (textEditing.value) {
				const mouseStartTarget = document.elementFromPoint(e.clientX, e.clientY);

				if (mouseStartTarget?.closest('[contenteditable]')) {
					e.stop();
					return;
				}
			}
		}

		// Devolvemos si en la función para cada caso (mobile y desktop), se ha de cortar la ejecución
		let shouldReturn = false;

		if (isMobile.value && isTouch.value) {
			shouldReturn = handlerMobileCases(e, isEditableElement, isGhostImage);
		} else {
			shouldReturn = handlerDesktopCases(e, target, isToolbar, isMoveableArea);
		}

		if (shouldReturn) return;

		if (!isCropping.value && (isEditableElement || isGhostImage) && !isPinching.value) {
			setSelectedAndDrag(e, isEditableElement as HTMLElement);
			return;
		}

		const isClearSelection = typeof target.dataset.elementsContainer === 'string';

		// Si llegamos hasta aquí con un grupo o donde el target es el interactive canvas (zona gris del editor) y el click está dentro del moveable y no es un elemento de reproductor de un video
		// queremos arrastrar el elemento/grupo directamente (esto nos permite arrastrar aunque el click este en una zona "muerta" del grupo
		if (!isVideoPlayer && (isGroup || !isClearSelection) && moveable.value?.isInside(e.clientX, e.clientY)) {
			setSelectedAndDrag(e, selection.value[0].domNode() as HTMLElement);
			return;
		}

		// Si es un elemento seleccionado, es un elemento de Moveable (rect, handlers....) o una parte de la
		// interfaz que se usa mientras tenemos el elemento seleccionado evitamos perder el foco del elemento
		if (
			moveable.value?.isMoveableElement(target) ||
			cropPhotoModeMoveable.value?.isMoveableElement(target) ||
			target.closest('.color-picker') ||
			target.closest('.target') ||
			target.closest('.toolbar') ||
			target.closest('.toolbar-group') ||
			target.closest('.ContextMenu') ||
			target.closest('.ghost-image') ||
			target.closest('.line-handler') ||
			isVideoPlayer
		) {
			// Si el usuario lanza alguna acción sobre el toolbar o target cancelamos el crop del video
			if (isCroppingTime.value && !target.closest('.crop-time')) {
				store.croppingTimeId = null;
			}

			e.stop();
		} else {
			// paramos el evento si estamos en mobile para evitar las selecciones multiples
			if (isMobile.value && isTouch.value) {
				e.stop();
			}

			if (isPhotoMode.value && isCropping.value) {
				store!.croppingId = null;
			}

			if (selectionLock.value) return;

			onBeforeClearSelection.trigger(e);

			// En caso de ser mobile, necesitamos esperar para saber si estamos haciendo zoom o no
			// si no hacemos zoom, borramos la selección
			if (isMobile.value) {
				useTimeoutFn(() => {
					if (!isPinching.value) {
						clearSelection();
						prevSelection.value = null;
					}
				}, 100);
			} else {
				clearSelection();
				prevSelection.value = null;
			}
		}
	};

	const handlerMobileCases = (
		e: OnDragStart,
		isEditableElement: HTMLElement | SVGElement | null,
		isGhostImage: boolean
	): boolean => {
		// Usamos changedTouches para saber si es multitouch, ya que touches
		// no da el dato correcto según que gestos se hagan
		const isMultitouch = e.inputEvent?.changedTouches?.length > 1;
		// El evento de drag start en móvil se lanza dos veces. Esto provoca errores
		// como que el crop se active sin querer al recibir doble evento en muy poco tiempo.
		const isDragEvent = isTouch.value && e.inputEvent.type === 'mousedown';

		if (isMultitouch || isDragEvent) {
			e.stop();
			return true;
		}

		if (isTouch.value && !isCropping.value && (isEditableElement || isGhostImage)) {
			// Si es touch, no queremos que se seleccione cuando estamos haciendo scroll, para poder comprobarlo
			// retrasamos la selección para así poder comprobar si el usuario está haciendo scroll.
			const scrollArea = document.querySelector('#scroll-area')!;
			const scroll = {
				x: scrollArea.scrollLeft,
				y: scrollArea.scrollTop,
			};

			e.stop();

			useTimeoutFn(() => {
				if (scroll.x !== scrollArea.scrollLeft || scroll.y !== scrollArea.scrollTop || isPinching.value) return;

				// Si se selecciona un elemento en versión mobile, se desactiva el panel activo
				store.activePanel = null;

				setSelectedAndDrag(e, isEditableElement as HTMLElement);
			}, 100);

			return true;
		}

		return false;
	};

	const handlerDesktopCases = (
		e: OnDragStart,
		target: HTMLElement,
		isToolbar: boolean,
		isMoveableArea: boolean
	): boolean => {
		const isLockedGroup =
			selection.value.length && selection.value.every((s) => s.group && s.group === selection.value[0].group);
		// Si hay elementos seleccionados y pulsamos shift, no permitimos selección con selecto
		// además si es un elemento gestionamos la selección
		// Si es un grupo bloqueado no permitimos la selección
		if (e.inputEvent.shiftKey && selection.value.length && !isLockedGroup) {
			const target = getRealTarget(e, true);
			const isTarget = target.closest('.target');
			const isEditable = target.closest('.editable');

			e.stop();

			if ((!isTarget && !isEditable) || selection.value[0] instanceof ForegroundImage || selection.value[0].locked) {
				return true;
			}

			if (isTarget) {
				const element = getElementFromActivePageDom(isTarget as HTMLElement)!;

				if (element?.group) {
					const elementsGroup = store.activePage
						?.elementsAsArray()
						.filter((el) => el.group === element.group) as Element[];
					removeFromSelection(elementsGroup);
				} else removeFromSelection(element);

				return true;
			}

			if (isEditable) {
				const element = getElementFromActivePageDom(isEditable as HTMLElement);

				if (element && !element.locked) {
					setSelection(element, true);
				}

				return true;
			}
		}
		return false;
	};

	/**
	 *  Si el target del evento es el dragarea del Moveable devuelve el elemento que hay debajo realmente
	 *
	 * @param e
	 * @param force Te devuelve el elemento debajo del moveable area siempre
	 * @return Elemento editable del dom
	 */
	const getRealTarget = (e: OnDragStart, force = false): HTMLElement => {
		let target = e.inputEvent.target;

		if (!moveable.value) {
			return target;
		}

		const isLockedGroup =
			selection.value.length && selection.value.every((s) => s.group && s.group === selection.value[0].group);
		const isMoveableArea = target.closest('.moveable-element');
		const isLineHandler = target.classList.contains('line-handler');
		const isVideoPlayer = !target.closest('.video-player-element') && target.closest('.video-player');

		// Crop
		if (isCropping.value && isMoveableArea) {
			const ghostImage = document.querySelector('.ghost-image');

			if (ghostImage) {
				target = ghostImage;
			}
		}

		// Groups
		if (!isCropping.value && ((isMoveableArea && moveable.value && isLockedGroup) || isVideoPlayer || force)) {
			const targetBehind = document
				.elementsFromPoint(e.clientX, e.clientY)
				.find((el) => el?.closest('.target') || el?.closest('.editable'));
			const isMoveableHandler = document.elementsFromPoint(e.clientX, e.clientY)[0].closest('.moveable-control');

			if (targetBehind && !isMoveableHandler && !isLineHandler) {
				target = targetBehind;
			}
		}

		return target;
	};
	/**
	 * Añade a la selección y permite hacer drag sin tener que hacer click de nuevo
	 * */
	const setSelectedAndDrag = (e: OnDragStart, editableElement: HTMLElement | SVGElement) => {
		if (!store.activePage) return;

		if (isCropping.value) {
			e.stop();

			requestAnimationFrame(() => {
				if (pressed.value) {
					ghostMoveable.value?.dragStart(e.inputEvent);
				}
			});

			return;
		}

		const element = getElementFromActivePageDom(editableElement as HTMLElement);

		if (!element) return;

		// Si hay estilos copiados y el elemento es un texto, pegamos los estilos
		if ((element instanceof Box || element instanceof Text) && Object.keys(copiedStyles.value).length) {
			pasteStyles(element);
			e.stop();
			return;
		}

		// En caso de que el target sea una imagen establecida como background, salimos, evitando el evento dragStart
		temporalRef.value = element;

		if (isBackground.value && selection.value[0] === element && !isPinching.value) {
			if (isMobile.value) {
				clearSelection();
				e.stop();
			}

			return;
		}

		setSelection(element, e.inputEvent.shiftKey);

		Bugsnag.leaveBreadcrumb(`Select ${element.type}-${element.id}`);
		if (!isEmpty(selection.value)) {
			if (selection.value.every((e) => e.type === 'text') && selection.value.length > 1) {
				// En caso de haber más de un elemento de tipo texto
				GAnalytics.track('click', 'Template', 'select-multiple-texts', null);
			} else if (selection.value.length > 1) {
				// En caso de haber más de un elemento de varios tipos
				GAnalytics.track('click', 'Template', 'select-multiple-elements', null);
			} else {
				// En caso de solo haber un elemento
				GAnalytics.track('click', 'Template', `select-${element.type}`, null);
			}
		}

		e.stop();

		requestAnimationFrame(() => {
			if (pressed.value) {
				moveable.value?.dragStart(e.inputEvent);
				Bugsnag.leaveBreadcrumb(`drag element: ${element.type}-${element.id} `);
			} else {
				// Para evitar falsos positivos del drag
				action.value = InteractionAction.Idle;
			}
		});
	};

	const toggleMiddleHandlers = () => {
		const focusedElement = selection.value[0];

		// Ignoramos esto en las líneas ya que sus handlers son independientes al resto
		if (!moveable.value || !moveable.value.renderDirections || focusedElement.type === 'line') return;

		const allHandles = ['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se'];
		const handlerWithoutMiddles = ['nw', 'ne', 'sw', 'se'];

		if (focusedElement instanceof Image) {
			moveable.value.renderDirections = focusedElement.mask?.keepRatio ? handlerWithoutMiddles : allHandles;
			return;
		}

		moveable.value.renderDirections = (moveable.value.renderDirections as string[]).includes('n')
			? handlerWithoutMiddles
			: allHandles;
	};

	const selectAll = () => {
		const alreadySelected = store.activePage?.elementsAsArray().every((el) => selection.value.includes(el));
		// Evitamos la selección si previamente tenemos todos los elementos seleccionados o estamos arrastramos los elementos
		if (alreadySelected || isDragging.value) return;

		setSelection(store.activePage?.elementsAsArray() || []);
	};

	return {
		selecto,
		setupInteraction,
		action,
		disableToolbar,
		moveable,
		isSelecting,
		isDragging,
		isResizing,
		isRotating,
		isCropping,
		isCroppingTime,
		isIdle,
		selectAll,
		isEditingText,
		isMiddleHandler,
		isCreatingElement,
		toggleMiddleHandlers,
		onBeforeClearSelection: onBeforeClearSelection.on,
		selectionLock,
	};
});
