<script lang="ts" setup>
import { nextTick } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';

const store = useMainStore();
const { selection, clearSelection } = useSelection();

const props = defineProps<{ element: Image | Video; labelPosition?: string }>();

const { photoModeImage } = usePhotoMode();
const { trans } = useI18n();

const onClickCrop = async () => {
	if (props.element.id === photoModeImage.value?.id) {
		clearSelection();
		await nextTick();
		store.croppingId = photoModeImage.value.id;
		return;
	}

	store.croppingId = props.element.id;
};
</script>

<template>
	<div>
		<button
			data-onboarding="crop"
			data-testid="crop-btn"
			class="flex h-9 w-9 items-center justify-center rounded bg-gray-700 text-gray-100 focus:outline-none hover:bg-gray-600 hover:text-white mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:w-full lg:px-3"
			:class="{
				'lg:h-9': labelPosition === 'right',
				'lg:h-8': labelPosition === 'bottom',
			}"
			@click="onClickCrop"
		>
			<SvgIcon name="crop" class="h-4 w-4 scale-90" />
			<label v-if="labelPosition === 'right'" class="pointer-events-none ml-2 text-sm">{{ trans('Crop') }}</label>
		</button>
	</div>
	<label
		v-if="labelPosition === 'bottom'"
		class="mt-1 block text-center text-2xs text-gray-100 mockup:text-fp-gray-700"
		>{{ trans('Crop') }}</label
	>
</template>
