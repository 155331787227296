<script lang="ts" setup>
// Vue
import { toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import ColorsBoxMenu from '@/elements/box/components/menus/ColorsBoxMenu.vue';
import StrokeMenu from '@/elements/box/components/menus/StrokeMenu.vue';
import OpacitySlider from '@/elements/element/components/menus/OpacitySlider.vue';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformMenu from '@/elements/element/components/menus/TransformMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';

const store = useMainStore();

const props = defineProps<{ element: Box }>();

const element = toRef(props, 'element');

const { isGrouped } = useGroup(element);
const { t: trans } = useI18n();

const onCloseEditPanel = () => (store.editPanel = null);
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">{{ trans('Edit box') }}</h2>
		<button
			class="text-gray-100 focus:outline-none hover:text-white"
			data-testid="close-panel"
			@click="onCloseEditPanel"
		>
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="true" />

		<!-- Colors -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">
			{{ trans('Fill') }}
		</h4>

		<ColorsBoxMenu :element="element" />

		<StrokeMenu :element="element" />

		<OpacitySlider :element="element" :opacity="element.opacity" />
	</div>
</template>
