import { createSharedComposable } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';
import { ref } from 'vue';

type Log = { id: string } & Message;

interface Message {
	msg: string;
	type: 'error' | 'info' | 'warning';
}

export const useDebugPanel = createSharedComposable(() => {
	const isElementDebugVisible = ref(true);

	const logs = ref<Log[]>([
		{
			id: uuidv4(),
			msg: `Welcome to console in ${window.location.href}`,
			type: 'warning',
		},
		{
			id: uuidv4(),
			msg: `1. Blue button toggles visibility`,
			type: 'info',
		},
		{
			id: uuidv4(),
			msg: `2. Gray button changes background opacity`,
			type: 'info',
		},
		{
			id: uuidv4(),
			msg: `3. Red button toggles interactions`,
			type: 'info',
		},
		{
			id: uuidv4(),
			msg: `4. Clear button removes all logs`,
			type: 'info',
		},
	]);

	const fixed = (message: object | string) => {
		const newLog: Log = {
			id: uuidv4(),
			msg: typeof message === 'string' ? message : JSON.stringify(message, undefined, 2),
			type: 'info',
		};
		logs.value = [newLog];
	};

	const log = (message: Message) => {
		const newLog = {
			...message,
			id: uuidv4(),
		};
		logs.value.push(newLog);
	};

	const error = (message: object | string) => {
		const newLog: Log = {
			id: uuidv4(),
			msg: typeof message === 'string' ? message : JSON.stringify(message, undefined, 2),
			type: 'error',
		};
		logs.value.push(newLog);
	};

	const info = (message: object | string) => {
		const newLog: Log = {
			id: uuidv4(),
			msg: typeof message === 'string' ? message : JSON.stringify(message, undefined, 2),
			type: 'info',
		};
		logs.value.push(newLog);
	};

	const warning = (message: object | string) => {
		const newLog: Log = {
			id: uuidv4(),
			msg: typeof message === 'string' ? message : JSON.stringify(message, undefined, 2),
			type: 'warning',
		};
		logs.value.push(newLog);
	};

	const toggleElementDebug = () => {
		isElementDebugVisible.value = !isElementDebugVisible.value;
	};

	return {
		isElementDebugVisible,
		logs,
		error,
		fixed,
		info,
		log,
		warning,
		toggleElementDebug,
	};
});
