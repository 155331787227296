<script lang="ts" setup>
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';

const { isMobile } = useDeviceInfo();
const { togglePanel } = usePanelManagement();
const props = defineProps<{ element: Image | ForegroundImage | Video }>();

const element = toRef(props, 'element');

const onClickEditFilters = () => {
	GAnalytics.trackDebounceEditBarGA4({
		category: element.value instanceof Video ? 'video' : 'photo',
		link_text: 'filters',
	});

	togglePanel(EditPanels.Filter, props.element);
};
const hasSvgFilter = computed(() => element.value.filter && element.value.filter.toSvgFilter().length > 0);

const { checkIsPhotoModeImage } = usePhotoMode();
const { trans } = useI18n();
const isPhotoModeImage = computed(() => element.value instanceof BaseImage && checkIsPhotoModeImage(element.value));
</script>

<template>
	<div>
		<button
			:id="`filters-${element.type}-toolbar`"
			:tooltip="trans('Filters')"
			tooltip-position="top"
			data-button-importance="normal"
			data-testid="filters"
			class="flex shrink-0 flex-col items-center text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-full lg:flex-row lg:justify-center lg:px-2"
			:class="{
				'text-white': hasSvgFilter,
				'h-9 w-9 justify-center': isPhotoModeImage,
				'h-11 w-[15vw] justify-between': !isPhotoModeImage,
			}"
			@click="onClickEditFilters"
		>
			<span
				v-if="hasSvgFilter"
				class="absolute -top-0.5 right-4 z-10 h-2 w-2 rounded-full border border-blue-300 bg-blue-500 slidesgo:bg-purple-400 lg:left-[1.5rem] lg:top-2"
			></span>
			<SvgIcon name="filter" class="h-4 w-4 scale-110 lg:h-5 lg:w-5 lg:scale-100" />
			<span v-if="!isPhotoModeImage || !isMobile" class="text-xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{
				trans('Filters')
			}}</span>
		</button>
	</div>
</template>
