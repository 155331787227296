<script lang="ts" setup>
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

const props = defineProps<{ page: Page }>();
const page = toRef(props, 'page');
const { trans } = useI18n();
const { position } = usePage(page);
const { copyPage, removePage, addEmptyPage, canAddPages } = useProject();
const { isIllustratorContext } = useEditorMode();
const toast = useToast();
const emit = defineEmits(['close']);
const project = useProjectStore();

const showIllustratorButtons = computed(
	() => isIllustratorContext.value && position.value === 0 && project.pages.length > 1
);

const addPage = () => {
	if (!canAddPages.value) {
		toast.warning(trans("You've reached the page limit to edit."));
		return;
	}
	addEmptyPage(page.value);
	GAnalytics.track('click', 'Template', 'add-new-page', null);
	emit('close');
};

const copy = (page: Page) => {
	if (!canAddPages.value) {
		toast.warning(trans("You've reached the page limit to edit."));
		return;
	}
	copyPage(page);
	emit('close');
};

const deletePage = () => {
	removePage(page.value);
	GAnalytics.track('click', 'Template', 'delete-page', null);
	emit('close');
};
</script>

<template>
	<button
		v-if="!isIllustratorContext"
		data-testid="copy-page"
		:tooltip="trans('Duplicate page')"
		tooltip-position="top"
		class="tooltip flex h-8 w-full items-center rounded px-2 text-gray-600 hover:bg-gray-100/25 lg:h-6 lg:w-6 lg:justify-center lg:px-0 lg:text-gray-300 lg:hover:bg-transparent lg:hover:text-white"
		:class="{ 'opacity-50': !canAddPages }"
		@click="copy(page)"
	>
		<SvgIcon name="copy" class="h-5 w-5 scale-75 lg:h-4 lg:w-4 lg:scale-100" />
		<p class="ml-2 text-sm lg:hidden">{{ trans('Copy page') }}</p>
	</button>
	<button
		v-if="!isIllustratorContext"
		data-testid="add-page"
		:tooltip="trans('Add page')"
		tooltip-position="top"
		class="tooltip flex h-8 w-full items-center rounded px-2 text-gray-600 hover:bg-gray-100/25 lg:h-6 lg:w-6 lg:justify-center lg:px-0 lg:text-gray-300 lg:hover:bg-transparent lg:hover:text-white"
		:class="{ 'opacity-50': !canAddPages }"
		@click="addPage"
	>
		<SvgIcon name="add-page" class="h-5 w-5 scale-75 lg:h-4 lg:w-4 lg:scale-100" />
		<p class="ml-2 text-sm lg:hidden">{{ trans('Add page') }}</p>
	</button>

	<button
		v-if="showIllustratorButtons || !isIllustratorContext"
		data-testid="delete-page"
		:tooltip="trans('Delete')"
		tooltip-position="top"
		class="tooltip flex h-8 w-full items-center rounded px-2 text-gray-600 hover:bg-gray-100/25 lg:h-6 lg:w-6 lg:justify-center lg:px-0 lg:text-gray-300 lg:hover:bg-transparent lg:hover:text-white"
		:class="project.pages.length === 1 ? 'pointer-events-none opacity-50' : ''"
		@click="deletePage"
	>
		<SvgIcon name="trash" class="h-5 w-5 scale-75 lg:h-4 lg:w-4 lg:scale-100" />
		<p class="ml-2 text-sm lg:hidden">{{ trans('Delete') }}</p>
	</button>
</template>
