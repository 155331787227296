<script lang="ts" setup>
import { useElementSize } from '@vueuse/core';
import { computed, toRef } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import FilterButtonToolbar from '@/elements/medias/filter/components/buttons/FilterButtonToolbar.vue';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import ForegroundOpacityToolbar from '@/elements/medias/images/foreground/components/buttons/ForegroundOpacityToolbar.vue';

const props = defineProps<{ element: ForegroundImage; visibility?: boolean }>();
const element = toRef(props, 'element');

const store = useMainStore();
const { width } = useElementSize(props.element.domNode());
const scale = computed(() => store.scale);

const toolbarClass = computed(() => {
	const size = width.value * scale.value;
	if (size < 250) {
		return 'really-small-image';
	}
	if (size < 400) {
		return 'small-image';
	}

	return 'normal-image';
});
</script>

<template>
	<div :class="toolbarClass">
		<!--		add preset button in mobile-->
		<FilterButtonToolbar :element="element" />

		<!-- Order -->
		<OrderToolbar :element="element" />
		<ForegroundOpacityToolbar :element="element" />
	</div>
</template>

<style scoped></style>
