import { createSharedComposable, Pausable, useEventListener, useFetch, useIntervalFn } from '@vueuse/core';
import { ref } from 'vue';
export const useOfflineMode = createSharedComposable(() => {
	const isOffline = ref(false);
	const reconnectCount = ref(0);
	let interval: Pausable;

	const connection = ref(navigator.connection);

	const reset = () => {
		interval?.pause();
		isOffline.value = false;
		reconnectCount.value = 0;
	};
	useEventListener('online', () => {
		reset();
	});

	useEventListener('offline', () => {
		tryingToReconnect();
	});

	useEventListener('focus', () => {
		if (navigator.onLine) {
			reset();
			return;
		}
		tryingToReconnect();
	});
	useEventListener(connection, 'change', () => {
		tryingToReconnect();
	});

	const tryingToReconnect = async () => {
		try {
			// si falla la petición, significará de que el usuario no tiene conexión
			const { error } = await reconnect();

			if (error.value) throw new Error();

			isOffline.value = false;
		} catch (er) {
			isOffline.value = true;
			// en caso de que falle la petición, intentamos la reconexión
			if (interval) interval.pause();

			interval = useIntervalFn(async () => {
				const { error, statusCode } = await reconnect();
				console.log('Reconnecting...');
				reconnectCount.value += 1;
				if (!error.value || statusCode.value === 200) {
					console.log('Reconnected!', statusCode.value);
					reset();
				}
			}, 1000);
		}
	};

	const reconnect = async () => {
		return await useFetch('https://wepik.com/cdn-cgi/trace', { method: 'HEAD' });
	};

	return { isOffline, reconnectCount, reset, connection };
});
