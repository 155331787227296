import {
	AiPresentationLanguages,
	Brandkit,
	Font,
	InsertableApiType,
	ModifierType,
	Size,
	Unit,
	UserTokens,
} from '@/Types/types';

export interface SyncProjectResponse {
	id: string;
	success: boolean;
}

export interface SaveProjectResponse {
	artboard: ArtboardApi | null;
	categories: CategoryApi[];
	media: string[];
	name: string | null;
	positionAdjustment: {
		x: number | null;
		y: number | null;
	};
	previews: Preview[];
	size: Size;
	tags: string[];
	user_vector: boolean;
	uuid: string;
	vector: ProjectDataApi;
	viewbox: {
		height: number | null;
		width: number | null;
	};
}

export interface ArtboardApi {
	id: number;
	name: string;
	width: number;
	height: number;
	unit: Unit;
	landscape: boolean;
}

export interface FeaturedArtboardApi {
	id: number;
	name: string;
	image: string;
	url: string;
	isSuggested: boolean;
}

export interface AiWriterInfoApi {
	id: number;
	prompt: string;
	generation: string;
}

export interface AIWriterAnswer {
	today: AiWriterInfoApi[];
	others: AiWriterInfoApi[];
}

export interface ProjectDataApi {
	id: number;
	freepik_id: string | null;
	artboard_id: number;
	name: string;
	slug: string;
	media: string[];
	editor_en: string;
	artboard: {
		id: number;
		name: string;
		width: number;
		height: number;
		visible: boolean;
		unit: Unit;
		lanscape: boolean;
		created_at: string;
		updated_at: string;
	};
	category: any[];
	tags: string[];
	tag_primary: string;
	previews: Preview[];
	category_tree: {
		id: number;
		name: string;
	}[];
	user_vector?: boolean;
}

export enum InsertableMediaOriginApiType {
	Freepik = 'freepik',
	GooglePhotos = 'google-photos',
	Pexels = 'pexels',
	Uploads = 'uploads',
}

export interface MediaApi {
	id: number | string;
	type: InsertableApiType.Image | InsertableApiType.Video | InsertableApiType.SVG;
	metadata: MediaMetadataApi;
	url: string;
	preview: string;
	data: any[];
	links: any[];
	ai_images?: any[];
	prompt?: string;
	backgroundRemoved?: string;
	origin: InsertableMediaOriginApiType;
}

export interface ImageApi extends MediaApi {
	type: InsertableApiType.Image | InsertableApiType.SVG;
}

export interface VideoApi extends MediaApi {
	type: InsertableApiType.Video;
	duration: string;
}

export interface UploadApi extends MediaApi {
	backgroundRemoved: string | null | undefined;
	type: InsertableApiType.Image | InsertableApiType.SVG | InsertableApiType.Video;

	duration?: string;
}

export interface MediaMetadataApi {
	raw?: string;
	prompt?: string;
	uploadId?: string;
	duration?: number;
	prompt?: { image: string };
	size?: {
		width: number;
		height: number;
	};
}

export interface MaskApi {
	id: number;
	mask: {
		preview: string;
		element: string;
	};
	multicolor: boolean;
	name: string;
	preview: string;
	svg: string;
	type: InsertableApiType.ImageMask;
	active: boolean;
	subcategories: {
		id: number;
		name: string;
		active: boolean;
		elements: {
			active: boolean;
			id: number;
			mask: {
				preview: string;
				element: string;
			};
			multicolor: boolean;
			name: string;
			preview: string;
			svg: string;
			type: InsertableApiType.ImageMask;
		}[];
	}[];
}

export interface PredefinedTextApi {
	id: number;
	svg: string;
	mask: {
		preview: string;
	};
	multicolor: boolean;
	name: string;
	preview: string;
	svg: string;
	type: InsertableApiType.BasicShape;
	active: boolean;
}

export interface PredefinedTextElementsApi {
	id: number;
	name: string;
	active: boolean;
	subcategories: PredefinedTextElementsSubcategoriesApi[];
}

export interface PredefinedTextElementsSubcategoriesApi {
	id: number;
	name: string;
	active: boolean;
	elements: MaskApi[];
}

export interface UserApi {
	userData: {
		id: string;
		fpId: string;
		name: string;
		email: string;
		avatar: string;
		isAdmin: boolean;
		interests: string;
		tokens: UserTokens;
		fonts: Font[];
		brandkits: Brandkit[];
	};
	limits: Record<
		string,
		{
			maxDailyAttempts: number;
			remaining: number;
			credits: number | null;
		}
	>;
}

export interface TemplateApi {
	data?: TemplateApiData[];
	id?: number;
	name?: string;
	parent?: { id: number; name: string };
	subcategories?: { id: number; name: string }[];
	links: ApiLinks;
	meta: ApiMeta;
}

export interface TemplateApiData {
	id: number | string;
	uuid?: string;
	name: string;
	slug: string;
	metadata: { proportion: number };
	size: {
		width: number;
		height: number;
		unit: string;
	};
	preview: string;
	previews: Preview[];
	pages: number;
	artboard?: {
		height: number;
		width: number;
		unit: Unit;
	};
	tags: string[];
	subcategory: string;
	folder: {
		name: string;
		slug: string;
	} | null;
	vector: ProjectDataApi;
	brand_kits_users_collections_id?: number;
	media: TemplateMediaApi[];
	updated_at: TemplateTimeApi;
	deleted_at: TemplateTimeApi;
	shareLink: string;
	editLink: string;
	type?: string;
	resource_id: number;
}

export interface ApiLinks {
	first: null | string;
	last: null | string;
	next: null | string;
	prev: null | string;
}

export interface ApiMeta {
	current_page: number;
	from: number;
	path: string;
	per_page: number;
	to: number;
	last_page: number;
	total: number;
}

export interface TemplateCategoriesApiData {
	id: number;
	name: string;
	subcategories: SubcategoryTemplateData[];
}

export interface TemplateMediaApi {
	uuid: string;
	url: string;
	previousState: string;
}

export interface TemplateTimeApi {
	date: string;
	read: string;
}

export interface SubcategoryTemplateData {
	count?: number;
	id: number;
	name: string;
	preview?: Preview;
}
export interface Preview {
	original: string;
	width: number;
	height: number;
	thumb: string;
	preview: string;
}

export interface UserFoldersApi {
	id: number;
	name: string;
	slug: string;
	vectors_count: number;
}

export interface FlaticonElementApi {
	id: number;
	multicolor: boolean;
	name: string;
	svg: string;
	download: string;
	type: InsertableApiType.Flaticon;
	isCustom: boolean;
	preview: string;
}

export interface FlaticonCategoryApi {
	added: number;
	category: string;
	category_id: string;
	color: number;
	colors: string;
	description: string;
	designer_id: number;
	designer_name: string;
	designer_slug: string;
	designer_website: string;
	detail: number;
	downloads: string;
	elements: FlaticonElementApi[];
	equivalents: number;
	family_id: number;
	family_name: string;
	family_slug: string;
	id: number;
	images: { sprite: string };
	item_equivalent: string;
	license: string;
	pack_id: number;
	pack_items: number;
	pack_name: string;
	pack_priority: string;
	pack_slug: string;
	premium: number;
	priority: string;
	priority_style: string;
	selection: number;
	slug: string;
	state: number;
	stroke: number;
	style: number;
	style_class: string;
	style_color: string;
	style_id: number;
	style_name: string;
	style_shape: string;
	style_slug: satring;
	tags: string;
	tags_id: string;
	type: number;
	views: string;
}

export interface StorysetTagApi {
	description: string;
	meta_description: string;
	meta_title: string;
	name: string;
	slug: string;
	title: string;
}

export interface StorysetApi {
	freepik_id: string;
	freepik_page: string;
	id: number;
	illustration: { slug: string; name: string };
	preview: string;
	preview_no_bg: string;
	published_at: string;
	slug: string;
	src: string;
	style: string;
	tags: StorysetTagApi[];
	total_downloads: number;
	url: string;
	type: InsertableApiType.Storyset;
}

export interface StickersCategoryApi {
	tagsId: number;
	name: string;
	previews: {
		id: string | number;
		multicolor: boolean;
		name: string;
		svg: string;
	}[];
}

export interface StickersElementApi {
	id: number;
	multicolor: boolean;
	name: string;
	svg: string;
	type: InsertableApiType.Sticker;
	preview: string;
	isCustom: boolean;
}

export interface BasicShapeApi {
	id: number;
	mask: { preview: string; element: string };
	multicolor: boolean;
	name: string;
	preview: string;
	svg: string;
	type: InsertableApiType.BasicShape;
}

export interface MappedApiResponse<T> {
	data: T;
	links: {
		prev?: string;
		next: string;
	};
	meta?: {
		per_page?: number | string;
	};
}

export interface LinesAndArrowsApi {
	id: number;
	type: InsertableApiType.LineAndArrow;
	active: boolean;
	mask: { preview: string; element: string };
	multicolor: boolean;
	name: string;
	preview: string;
	svg: string;
}

export type ServicesProviders = 'dropbox' | 'google' | 'facebook' | 'pinterest' | 'twitter';

export interface CommonRawResponseApi<T> {
	images?: ImageApi[];
	data?: T[];
	links?: {
		prev?: string;
		next: string;
	};
	meta?: {
		per_page?: number | string;
	};
}

interface SchedulePublicationBody {
	vector_uuid: string;
	pages: string[];
	social_network: string;
	is_draft: boolean;
	published_at: string;
	info: MetaInfo | PinterestInfo | TwitterInfo;
}

interface MetaInfo {
	facebook_page_id: string;
	facebook_message: string;
}

interface PinterestInfo {
	pinterest_board_id: string;
	pinterest_title: string;
	pinterest_url: string;
	pinterest_description: string;
}

interface TwitterInfo {
	twitter_message: string;
}

interface AiPresentationBody {
	topic: string;
	tone: string;
	language?: AiPresentationLanguages;
	vector: number;
}

export interface AiImageApi {
	data: AiImageApiData[];
	links: ApiLinks;
	meta: ApiMeta;
}

export interface AiImageApiData {
	ai_images: AiImageApiDataImage[];
	config: AiImageApiDataConfig;
	created_at: string;
	date_for_humans: string;
	id: number;
	prompt: string;
}

export interface AiImageApiDataImage {
	uuid: string;
	in_use: number;
	media: string;
	media_upscaled: string;
	model: string;
	nsfw: number;
	thumb: string;
	upscaled: boolean;
}

export interface AiImageApiDataConfig {
	aspectRatio: string;
	cfgScale: number;
	generator: string;
	height: number;
	imageUrl: string;
	images: number;
	maskUrl: string;
	modifiers: { type: ModifierType; name: string }[];
	negativePrompt: string;
	price: number;
	requester: string;
	seed: number;
	steps: number;
	width: number;
}

export type DataType =
	| BasicShapeApi
	| FlaticonElementApi
	| MaskApi
	| StickersElementApi
	| StorysetApi
	| LinesAndArrowsApi;
