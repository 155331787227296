<script lang="ts" setup>
import { computed, ref } from 'vue';

import { getPageFromMedia } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { useAvailableHeight } from '@/layout/composables/useAvailableHeight';
import { useTemplateLoader } from '@/loader/composables/useTemplateLoader';
import { TemplateVectorMedia } from '@/loader/types/templateLoaderData';
import Page from '@/page/classes/Page';
import { useProject } from '@/project/composables/useProject';
import { Preview } from '@/Types/apiClient';

const { templateData } = useTemplateLoader();
const { addPage, duplicatePage, canAddPages } = useProject();
const store = useMainStore();
const toast = useToast();
const { trans } = useI18n();

// Template refs
const container = ref();
const localPages = ref<{ [key: string]: Page }>();
const addingPage = ref(false);

// Using composables
useAvailableHeight(container);

// Data
const templateMediasWithPreviews = computed(() => {
	return templateData.value?.vector?.media?.map((media, index): TemplateVectorMedia & Preview => {
		const preview: Preview = templateData.value?.vector?.previews[index];

		return { ...media, ...preview };
	});
});

const preparePage = async (url: string) => {
	if (!canAddPages.value) {
		toast.warning(trans("You've reached the page limit to edit."));
		return;
	}
	addingPage.value = true;

	const pageData = await getPageFromUrl(url);

	if (pageData !== null) {
		addPageToTemplate(pageData);
	}

	addingPage.value = false;
};

const preparePageToDrag = async (url: string) => {
	if (!canAddPages.value) return;

	const pageData = await getPageFromUrl(url);

	if (pageData !== null) {
		store.draggingPage = pageData;
	}
};

const addPageToTemplate = (page: Page) => {
	const newPage = duplicatePage(Page.create(page));
	addPage(newPage);
	store.setActivePage(newPage);
};

const getPageFromUrl = async (url: string) => {
	if (localPages.value && localPages.value[url]) return localPages.value[url];

	const { statusCode, data } = await getPageFromMedia(url);

	if (statusCode.value === 200 && data.value !== null) {
		const pageData = Page.unserialize(data.value);
		localPages.value = { ...localPages.value, [url]: pageData };
		return pageData;
	} else {
		toast.error('Error getting page from media');
	}

	return null;
};
</script>

<template>
	<div class="flex h-full flex-col">
		<div v-if="addingPage" class="absolute inset-0 z-50 flex items-center justify-center bg-black/70">
			<SvgIcon name="spinner" class="mr-2 h-16 w-16 animate-spin" />
		</div>

		<PanelHeader title="Template" />

		<p v-if="templateData?.vector.name" class="mb-3 text-sm font-semibold text-gray-100">
			{{ templateData.vector.name }}
		</p>

		<InfiniteLoading
			:data="templateMediasWithPreviews || []"
			:is-fetching="false"
			class="flex flex-col"
			with-masonry
			:masonry-cols="2"
		>
			<template #item="{ item }">
				<button v-if="'url' in item && 'thumb' in item" class="relative mb-2" @click="preparePage(item.url)">
					<img
						v-if="item"
						:src="item.thumb"
						class="inset-0 w-full transform cursor-pointer rounded bg-gray-600 object-contain transition duration-500 hover:opacity-80"
						draggable
						@dragend="store.draggingPage = null"
						@dragstart.stop="preparePageToDrag(item.url)"
					/>
				</button>
			</template>
		</InfiniteLoading>
	</div>
</template>
