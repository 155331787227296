<script lang="ts" setup>
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';

const store = useMainStore();
const { trans } = useI18n();

defineProps<{ panel: EditPanels; element: Element }>();

const emit = defineEmits<{ (e: 'moreTools'): void }>();

const onClickMoreTools = () => {
	emit('moreTools');
};
</script>

<template>
	<div data-testid="open-more-tools">
		<button
			:id="`more-tools-${element.type}-toolbar`"
			:tooltip="trans('More')"
			tooltip-position="top"
			class="hidden h-10 w-8 items-center justify-center text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:flex"
			@click="onClickMoreTools"
		>
			<SvgIcon
				name="more"
				:toolbar="true"
				class="h-4 w-4 lg:scale-110"
				:class="store.editPanel === panel ? 'text-white mockup:text-blue-500' : ''"
			/>
		</button>
	</div>
</template>
