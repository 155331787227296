<script setup lang="ts">
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';

// Props
const props = defineProps<{ element: Element }>();

// Data
const element = toRef(props, 'element');
</script>

<template>
	<div
		class="collision-feedback pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/40"
		:style="{
			transform: `
				translate(${element.position.x}px,${element.position.y}px)
				rotate(${element.rotation}deg)
			`,
			width: `${element.size.width}px`,
			height: `${element.size.height}px`,
		}"
	>
		<span
			class="flex aspect-square w-2/12 items-center justify-center rounded-full bg-blue-500 p-2 text-white slidesgo:bg-purple-400"
		>
			<SvgIcon name="replace" class="h-full w-full" />
		</span>
	</div>
</template>
