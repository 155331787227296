<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import { ApiClient } from '@/api/utils';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useToast } from '@/common/composables/useToast';
import { useI18n } from '@/i18n/useI18n';
import { TemplateApiData } from '@/Types/apiClient';

const { trans } = useI18n();

const props = defineProps<{ selectedTemplate: TemplateApiData | null }>();

const emit = defineEmits(['close', 'deleted']);

const toast = useToast();

const deleting = ref(false);
const { APP_API_PATH } = useEnvSettings();

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Delete creation modal is closed`);
};

const deleteTemplate = () => {
	if (!props.selectedTemplate?.uuid || deleting.value) return;

	deleting.value = true;

	ApiClient.request(`${APP_API_PATH}user/creation/${props.selectedTemplate.uuid}/delete`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
		},
	})
		.then(() => {
			toast.success(trans('Deleted template'));
			emit('deleted');
		})
		.catch(() => toast.error(trans('The template has not been deleted')))
		.finally(() => (deleting.value = false));
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative my-auto w-full max-w-lg rounded bg-white py-8 px-8">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<div>
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">{{ trans('We need confirmation!') }}</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					{{ trans('Are you sure you want to delete this template?') }}
				</p>

				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
						:disabled="deleting"
						@click.stop="onClickClose"
					>
						{{ trans('Cancel') }}
					</button>

					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-red-500 bg-red-500 px-8 text-lg font-semibold text-white hover:border-red-400 hover:bg-red-400"
						:disabled="deleting"
						@click.stop="deleteTemplate"
					>
						<SvgIcon v-if="deleting" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans('Delete') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
