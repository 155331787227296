<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import AlternativePalettes from '@/color/components/AlternativePalettes.vue';
import ColorPickerGroup from '@/color/components/ColorPickerGroup.vue';
import ColorSelector from '@/color/components/ColorSelector.vue';
import { useProjectColors } from '@/color/composables/useProjectColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const store = useMainStore();
const { isAdminMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { trans } = useI18n();
const {
	colors,
	textsColors,
	hasQrColor,
	hasOutlineColor,
	hasTextShadowColor,
	hasCurvedTextColor,
	linesStrokeColors,
	colorSelected,
	updateTemplateColor,
	getElementsWithColorSelected,
} = useProjectColors();

const tab = ref<'colors' | 'palettes'>('colors');
const colorSelectorKey = ref(colorSelected.value?.id);
const disableGradient = computed(
	() =>
		colorSelected.value &&
		(hasQrColor(colorSelected.value) ||
			hasTextShadowColor(colorSelected.value) ||
			hasOutlineColor(colorSelected.value) ||
			hasCurvedTextColor(colorSelected.value))
);

const onClickClose = () => {
	store.editPanel = null;
};

watch(
	colorSelected,
	() => {
		getElementsWithColorSelected();
	},
	{ immediate: true }
);

const updateColor = (color: Color) => {
	if (!colorSelected.value) return;

	updateTemplateColor(colorSelected.value, color);
	color.id = colorSelected.value.id;
	colorSelected.value = color;
};

const selectColor = (color: Color) => {
	colorSelected.value = color;
	colorSelectorKey.value = color.id;
};

const selectionStyle = computed(() => {
	if (!colorSelected.value) return '';

	return `.project-colors-panel .cpb-${colorSelected.value.id}::after {
		content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: 3px;
    border-radius: 9999px;
    border-width: 3px;
    border-color: rgb(42 55 66 / 1);
	}
	`;
});
</script>

<template>
	<div class="flex flex-col px-3">
		<div v-if="store.colorPalettes.length > 1 || isAdminMode" class="mb-3 flex w-full">
			<button
				class="h-6 flex-1 rounded-full px-2 text-sm font-bold uppercase lg:text-xs"
				:class="{
					'text-white': tab === 'colors',
					'text-gray-100': tab !== 'colors',
				}"
				@click="tab = 'colors'"
			>
				{{ trans('Global colors') }}
			</button>
			<hr class="h-6 w-px border-0 bg-gray-600" />
			<button
				class="h-6 flex-1 rounded-full px-2 text-sm font-bold uppercase lg:text-xs"
				:class="{
					'text-white': tab === 'palettes',
					'text-gray-100': tab !== 'palettes',
				}"
				@click="tab = 'palettes'"
			>
				{{ trans('Palettes') }}
			</button>
			<button
				v-if="isMobile"
				data-testid="close-panel"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="onClickClose"
			>
				<SvgIcon name="cross" class="pointer-events-none h-3 w-3" />
			</button>
		</div>
		<div v-else class="mb-3 flex w-full justify-between">
			<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Global colors') }}</h2>
			<button
				data-testid="close-panel"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="onClickClose"
			>
				<SvgIcon name="cross" class="pointer-events-none h-3 w-3" />
			</button>
		</div>
		<div v-if="tab === 'colors'" class="project-colors-panel flex w-full max-w-full flex-col">
			<component :is="'style'" type="text/css">
				{{ selectionStyle }}
			</component>
			<div class="mb-4 lg:mb-0 lg:rounded-lg lg:bg-gray-800/50 lg:p-2">
				<ColorSelector
					:key="colorSelectorKey"
					:color="(colorSelected as Color)"
					:hide-gradient="disableGradient"
					:hide-alpha="true"
					:hide-palettes="true"
					@change="updateColor"
				/>
			</div>
			<ColorPickerGroup
				class="h-8 overflow-auto lg:h-5"
				picker-class="w-8 h-8 lg:w-5 shrink-0"
				:max="Infinity"
				:colors="(colors as Color[])"
				:texts-colors="textsColors"
				:lines-stroke-colors="linesStrokeColors"
				:prevent-change="true"
				:hide-alpha="true"
				@select="selectColor"
			/>
		</div>

		<div v-if="tab === 'palettes'">
			<AlternativePalettes v-if="store.colorPalettes.length || isAdminMode" />
		</div>
	</div>
</template>
