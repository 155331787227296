<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { computed, nextTick, onMounted, Ref, ref, toRef, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useGroup } from '@/elements/group/composables/useGroup';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { useToolbarTarget } from '@/layout/composables/useToolbarTarget';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: BaseImage }>();
const element = toRef(props, 'element');
const store = useMainStore();
const { setSelection, clearSelection } = useSelection();

const { isGrouped } = useGroup(element);
const { target } = useToolbarTarget(element);

const { imageBackground } = useForeground(element as Ref<ForegroundImage>);
const temporalRef = ref<Element | BaseImage>(Image.create());
const { unifyLayers, foreground } = useLayersImage(temporalRef as Ref<Image>);
const { trans } = useI18n();

const size = computed(() => props.element.size);
const position = computed(() => props.element.position);
const { onZoomFinish } = useZoom();

const popper = ref();
const toolbar = ref();

const isForeground = computed(() => element.value instanceof ForegroundImage);

// Watchers
watch(
	[position, size, () => store.activePanel, () => store.editPanel],
	async () => {
		await nextTick();

		if (!popper.value) {
			initPopper();
			return;
		}
		requestAnimationFrame(() => popper.value.update());
	},
	{ deep: true }
);

onMounted(() => {
	requestAnimationFrame(() => initPopper());
});

const initPopper = () => {
	if (!target.value) return;

	const finalPlacement = isGrouped.value ? 'left-start' : 'right-start';

	popper.value = createPopper(target.value, toolbar.value, {
		placement: finalPlacement,
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, 10],
				},
			},
			{
				name: 'preventOverflow',
				enabled: true,
				options: {
					tether: false,
				},
			},
			{
				name: 'eventListeners',
				options: {
					scroll: false,
				},
			},

			{
				name: 'flip',
				options: {
					// cambia la posición a end en caso de que los botones se solapen con el topbar
					fallbackPlacements: [finalPlacement.replace('start', 'end')],
					boundary: [document.querySelector('#scroll-area')],
				},
			},
		],
	});
};

const selectBackground = async () => {
	if (!isForeground.value || !imageBackground.value) {
		return;
	}
	const presetsPanelIsOpen = store.editPanel === EditPanels.ImagePresets;
	await setSelection(imageBackground.value);

	if (presetsPanelIsOpen) {
		store.editPanel = EditPanels.ImagePresets;
	}
};

const selectForeground = async () => {
	temporalRef.value = element.value;

	if (isForeground.value || !foreground.value) {
		return;
	}

	const presetsPanelIsOpen = store.editPanel === EditPanels.ImagePresets;
	await setSelection(foreground.value);

	if (presetsPanelIsOpen) {
		store.editPanel = EditPanels.ImagePresets;
	}
};

const onClickUnifyLayers = () => {
	clearSelection();

	if (isForeground.value && imageBackground.value) {
		temporalRef.value = imageBackground.value;
		unifyLayers();
		setSelection(temporalRef.value);
		return;
	}

	temporalRef.value = element.value;
	unifyLayers();
	setSelection(element.value);
	GAnalytics.track('click', 'Button', 'remove-background-unifylayers', null);
};

const onClickOpen = () => {
	if (store.editPanel === EditPanels.ImagePresets) {
		store.editPanel = null;
		return;
	}

	store.editPanel = EditPanels.ImagePresets;
	GAnalytics.track('click', 'Button', 'remove-background-clickpreset', null);
};

onZoomFinish(() => popper.value.update());
</script>

<template>
	<teleport to="#layerSelectorToolbarTarget">
		<div ref="toolbar" class="toolbar -ml-36 -mt-36 flex flex-col gap-2 pl-2">
			<button
				data-testid="separate-unify-layers"
				:tooltip="trans('Unify layers')"
				tooltip-position="right"
				class="toolbar flex h-7 w-7 items-center justify-center rounded-full bg-white text-blue-500 shadow-lg"
				@click="onClickUnifyLayers"
			>
				<SvgIcon name="layers" class="h-3 w-3" />
			</button>
			<button
				:tooltip="trans('Background')"
				tooltip-position="right"
				class="toolbar flex h-7 w-7 items-center justify-center rounded-full shadow-lg"
				:class="!isForeground ? 'bg-blue-500 text-white slidesgo:bg-purple-400' : 'bg-white text-blue-500'"
				@click="selectBackground"
			>
				<SvgIcon name="background-img" class="h-3 w-3" />
			</button>
			<button
				:tooltip="trans('Foreground')"
				tooltip-position="right"
				class="toolbar flex h-7 w-7 items-center justify-center rounded-full shadow-lg"
				:class="{
					'opacity-50': !isForeground && element.opacity === 0,
					'bg-blue-500 text-white slidesgo:bg-purple-400': isForeground,
					'bg-white text-blue-500': !isForeground,
				}"
				:disabled="!isForeground && element.opacity === 0"
				@click="selectForeground"
			>
				<SvgIcon name="foreground" class="h-3 w-3" />
			</button>
			<button
				:tooltip="trans('Presets')"
				tooltip-position="right"
				class="flex h-7 w-7 items-center justify-center rounded-full bg-white text-blue-500"
				@click.stop.prevent="onClickOpen"
			>
				<SvgIcon name="more" class="h-3 w-3" />
			</button>
		</div>
	</teleport>
</template>

<style scoped></style>
