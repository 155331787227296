import { useDebounceFn, useEventListener } from '@vueuse/core';
import { computed, onMounted, Ref, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useZoom } from '@/editor/composables/useZoom';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';

export const useResizeContainerEditor = (scrollArea: Ref<HTMLElement | undefined>) => {
	const { isMobile, isAndroid } = useDeviceInfo();
	const { fitZoomScale, isPinching } = useZoom();
	const textEditingJustFinished = ref(false);
	const { textEditing, onFinishEditingText } = useTextEditing();
	const shouldFitZoom = computed(() => {
		return !isPinching.value && !textEditingJustFinished.value && !textEditing.value;
	});
	const resizeContainer = () => {
		if (!scrollArea.value) return;

		scrollArea.value.style.width = '';
		scrollArea.value.style.height = '';

		const height = window.innerHeight;
		const nav = document.querySelector('#navigation');
		if (!nav) return;

		const navBounding = nav.getBoundingClientRect();
		const offsetY =
			(isMobile.value ? scrollArea.value?.offsetTop + navBounding.height : scrollArea.value?.offsetTop) || 0;
		const offsetX = isMobile.value ? 0 : navBounding.width || 0;
		if (isMobile.value) {
			scrollArea.value.classList.remove('flex-1');
		}

		scrollArea.value.style.width = `${
			(window.visualViewport?.width || scrollArea.value.getBoundingClientRect().width) - offsetX
		}px`;
		scrollArea.value.style.height = `${height - offsetY}px`;

		if (shouldFitZoom.value) {
			fitZoomScale();
		}
		textEditingJustFinished.value = false;
	};

	onFinishEditingText(() => {
		if (isMobile.value) {
			textEditingJustFinished.value = true;
		}
	});

	if (!isAndroid) {
		useEventListener(window, 'resize', useDebounceFn(resizeContainer, 100), { passive: true });
	} else {
		useEventListener(window, 'orientationchange', useDebounceFn(resizeContainer, 100), { passive: true });
	}

	onMounted(() => resizeContainer());

	return {
		resizeContainer,
	};
};
