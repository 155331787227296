<script lang="ts" setup>
import { onBeforeMount, ref, toRef } from 'vue';

import { TransformTools } from '@/elements/element/utils/TransformTools';
import Image from '@/elements/medias/images/image/classes/Image';
import ImageComponent from '@/elements/medias/images/image/components/ImageComponent.vue';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { MediaHover } from '@/Types/types';

// Props
const props = defineProps<{ image: MediaHover | any; scale: number }>();

// Data
const image = toRef(props, 'image');

const proposed = ref<Image>(
	Image.create({
		url: image.value.data.preview || image.value.data.url,
		size: {
			width: image.value.target.size.width,
			height: image.value.target.size.height,
		},
		position: {
			x: image.value.target.position.x,
			y: image.value.target.position.y,
		},
		rotation: image.value.target.rotation,
		group: image.value.target.group,
		locked: image.value.target.locked,
		keepProportions: image.value.target.keepProportions,
		opacity: image.value.target.opacity,
		preview: image.value.data.preview,
		filter: image.value.target.filter,
		mask: image.value.target.mask,
	})
);

// Lifecycle hooks
onBeforeMount(async () => {
	await setProposedImageCrop();
});

// Methods
const setProposedImageCrop = async () => {
	const proposedRealSize = await ImageTools.getRealImageSize(image.value.data.preview || image.value.data.url);

	const cropRatio = image.value.target.size.width / image.value.target.size.height;
	const proposedRatio = proposedRealSize.width / proposedRealSize.height;

	if (cropRatio >= 1) {
		if (proposedRatio >= cropRatio) {
			proposed.value.crop.size = TransformTools.getSizeKeepingAspectRatioByHeight(
				proposedRealSize,
				image.value.target.size.height
			);
		} else {
			proposed.value.crop.size = TransformTools.getSizeKeepingAspectRatioByWidth(
				proposedRealSize,
				image.value.target.size.width
			);
		}
	} else {
		if (proposedRatio >= cropRatio) {
			proposed.value.crop.size = TransformTools.getSizeKeepingAspectRatioByHeight(
				proposedRealSize,
				image.value.target.size.height
			);
		} else {
			proposed.value.crop.size = TransformTools.getSizeKeepingAspectRatioByWidth(
				proposedRealSize,
				image.value.target.size.width
			);
		}
	}

	proposed.value.crop.position.x = (proposed.value.size.width - proposed.value.crop.size.width) / 2;
	proposed.value.crop.position.y = (proposed.value.size.height - proposed.value.crop.size.height) / 2;
};
</script>

<template>
	<div
		class="pointer-events-none absolute left-0 top-0"
		:style="{
			transform: `
        translate(${image.target.position.x}px,${image.target.position.y}px)
        rotate(${image.target.rotation}deg)
      `,
			width: `${image.target.size.width}px`,
			height: `${image.target.size.height}px`,
			opacity: image.target.opacity,
		}"
	>
		<div class="h-full w-full">
			<ImageComponent :element="proposed" :scale="scale" :interactive="false" />
		</div>
	</div>
</template>
