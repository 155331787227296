<script lang="ts" setup>
// Vue
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Dropdown from '@/common/components/Dropdown.vue';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { WeightProps } from '@/Types/types';

const { t: trans } = useI18n();

// Props
const props = defineProps<{
	selected: string | string[];
	options: WeightProps;
}>();

// Const
const WEIGHT_DICCIONARY: { [key: string]: string } = {
	'100': 'Thin',
	'100i': 'Thin italic',
	'200': 'Extralight',
	'200i': 'Extralight italic',
	'300': 'Light',
	'300i': 'Light italic',
	'400': 'Regular',
	'400i': 'Regular italic',
	'500': 'Medium',
	'500i': 'Medium italic',
	'600': 'Semibold',
	'600i': 'Semibold italic',
	'700': 'Bold',
	'700i': 'Bold italic',
	'800': 'Extrabold',
	'800i': 'Extrabold italic',
	'900': 'Black',
	'900i': 'Black italic',
};

// Emits
const emit = defineEmits(['update']);

// Computeds vars
const computedOptions = computed(() => {
	const result: { label: string; value: string }[] = [];

	if (Array.isArray(props.options)) {
		if (props.options.length === 1) {
			props.options[0].weight.forEach((w) => result.push({ label: WEIGHT_DICCIONARY[w], value: w }));
		}

		if (props.options.length > 1) {
			TextTools.getCommonWeights(props.options).forEach((w) => result.push({ label: WEIGHT_DICCIONARY[w], value: w }));
		}
	}

	return result;
});

const computedSelected = computed(() => {
	return computedOptions.value.length
		? WEIGHT_DICCIONARY[Array.isArray(props.selected) ? props.selected[0] : props.selected]
		: trans('Mixed Weights');
});

// Methods
const onUpdate = (val: string) => emit('update', val);
</script>

<template>
	<Dropdown
		data-testid="font-style"
		class="flex w-full items-center justify-center"
		:selected="computedSelected"
		:options="computedOptions"
		:disabled="!computedOptions.length"
		:hide-options="!computedOptions.length"
		@update="(ev) => onUpdate(ev.value)"
	/>
</template>
