<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { computed } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

const props = defineProps<{ modelValue: string | null; style?: string }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: string | null): void }>();

const options = computed(() => ({
	black: { value: 'Black', disable: !!(props.style && ['lineal-color'].includes(props.style)) },
	color: { value: 'Color', disable: false },
	gradient: { value: 'Gradient', disable: !!(props.style && ['hand-drawn'].includes(props.style)) },
}));

const reset = (e: MouseEvent) => {
	e.stopPropagation();
	emit('update:modelValue', null);
};
</script>

<template>
	<Menu>
		<MenuButton
			data-testid="filter-color-btn"
			class="flex h-7 items-center justify-center gap-1 rounded-full bg-gray-700 pl-2 pr-3 text-xs text-gray-100 hover:text-white"
		>
			<template v-if="modelValue">
				<span
					class="mr-1 h-4 w-4 shrink-0 rounded-full"
					:class="{
						'bg-gray-900': modelValue === 'black',
						'all-colors': modelValue === 'color',
						'bg-gradient-to-t from-blue-500 to-green-600': modelValue === 'gradient',
					}"
				></span>
				{{ options[modelValue as keyof typeof options].value }}
				<button
					data-testid="reset-filter-color-btn"
					class="flex h-4 w-4 items-center justify-center rounded-sm hover:bg-gray-800"
					@click="reset"
				>
					<SvgIcon name="cross" class="h-3 w-3" />
				</button>
			</template>
			<span v-else class="ml-2">{{ trans('All Colors') }}</span>

			<!-- Visible si tenemos alguna opción selccionada. Si pulsamos volvemos al All

			<!-->
			<SvgIcon name="arrow" class="h-4 w-4" />
		</MenuButton>
		<MenuItems
			class="absolute left-0 top-10 z-20 w-auto origin-top-right rounded-md bg-white py-1 shadow focus:outline-none"
		>
			<MenuItem v-for="(option, key) in options" v-slot="{ active }" :key="key" :disabled="option.disable">
				<button
					:data-testid="`flaticon-filter-${key}-btn`"
					class="flex w-full items-center gap-2 whitespace-nowrap px-4 py-1 text-left text-sm hover:bg-gray-100/25 hover:text-gray-800"
					:class="[
						active ? 'bg-gray-100/25 text-gray-800' : 'text-gray-600',
						option.disable ? 'pointer-events-none opacity-25' : '',
					]"
					@click="emit('update:modelValue', key)"
				>
					<span
						class="h-4 w-4 shrink-0 rounded-full"
						:class="{
							'bg-gray-900': key === 'black',
							'all-colors': key === 'color',
							'bg-gradient-to-t from-blue-500 to-green-600': key === 'gradient',
						}"
					></span>

					{{ trans(option.value) }}
				</button>
			</MenuItem>
		</MenuItems>
	</Menu>
</template>
