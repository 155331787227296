import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import CollisionTools from '@/collision/utils/CollisionTools';
import { ElementDTO } from '@/Types/elements';
import { Flip, Position, PrimaryElementTypes, Size } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

abstract class Element {
	id: string;
	abstract type: PrimaryElementTypes;
	metadata: object | any | null;
	size: Size;
	position: Position;
	rotation: number;
	flip: Flip;
	group: string | null;
	locked: boolean;
	keepProportions: boolean;
	opacity: number;
	virtualGroup: string | null;
	tags: string[];
	index: string;

	protected constructor(elementDTO: ElementDTO) {
		this.id = uuidv4();
		this.metadata = elementDTO.metadata;
		this.size = elementDTO.size;
		this.position = elementDTO.position;
		this.rotation = elementDTO.rotation;
		this.flip = elementDTO.flip;
		this.locked = elementDTO.locked;
		this.group = elementDTO.group;
		this.keepProportions = elementDTO.keepProportions;
		this.opacity = elementDTO.opacity;
		this.virtualGroup = elementDTO.virtualGroup;
		this.tags = elementDTO.tags || [];
		this.index = elementDTO.index || '';
	}

	get flipHTML(): { x: number; y: number } {
		return {
			x: this.flip.x ? -1 : 1,
			y: this.flip.y ? -1 : 1,
		};
	}

	setSize(width: number, height: number): this {
		this.size.width = width;
		this.size.height = height;
		return this;
	}

	setPosition(x: number, y: number) {
		this.position = {
			x,
			y,
		};
	}

	setMetadata(metadata: object) {
		this.metadata = metadata;
	}

	setOpacity(opacity: number) {
		this.opacity = opacity;
	}

	setLocked(locked: boolean) {
		this.locked = locked;
	}

	setGroup(group: string | null) {
		this.group = group;
	}

	setRotation(rotation: number) {
		this.rotation = rotation % 360;
	}

	/**
	 * Retorna la primera aparicion
	 */
	domNode(): HTMLElement | null {
		return document.querySelector(`#element-${this.id}`);
	}

	/**
	 * Retorna el nodo en las preview (CanvasNavigation)
	 */
	domPreviewNode(previewName?: string): HTMLElement | null {
		return document.querySelector(`#element-${this.id}-preview-${previewName}`);
	}

	/**
	 * devuelve las posiciones de cada una de las esquinas de un elemento
	 * a veces necesitará la posición del elemento del dom (en los casos en los que se arrastre el elemento)
	 *
	 * @param domElementPosition? posición que nos proporciona el elemento del dom
	 * @returns  {leftTopCorner | rightTopCorner | rightBottomCorner | leftBottomCorner} posición de las esquinas de un elemento
	 */
	getCorners(domElementPosition?: Position) {
		let angle = this.rotation;
		const leftTopCorner = MathTools.getRotatedTopLeftCornerOfRect(
			domElementPosition?.x || this.position.x,
			domElementPosition?.y || this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		const vecLength = MathTools.getVectorLength(this.position.x, this.position.y, this.size.width, this.size.height);

		angle += MathTools.getAngleForNextCorner(this.size.width / 2, vecLength);
		const rightTopCorner = MathTools.getRotatedTopLeftCornerOfRect(
			domElementPosition?.x || this.position.x,
			domElementPosition?.y || this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		angle += MathTools.getAngleForNextCorner(this.size.height / 2, vecLength);
		const rightBottomCorner = MathTools.getRotatedTopLeftCornerOfRect(
			domElementPosition?.x || this.position.x,
			domElementPosition?.y || this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		angle += MathTools.getAngleForNextCorner(this.size.width / 2, vecLength);
		const leftBottomCorner = MathTools.getRotatedTopLeftCornerOfRect(
			domElementPosition?.x || this.position.x,
			domElementPosition?.y || this.position.y,
			this.size.width,
			this.size.height,
			angle
		);

		return [leftTopCorner, rightTopCorner, rightBottomCorner, leftBottomCorner];
	}

	isCollided(el: Element, domElementPosition?: Position) {
		// This check is lighter than checking with rotation
		const hasCollisionWithBox = CollisionTools.checkCollisionWithBox(
			this.domNode() as HTMLElement,
			el.domNode() as HTMLElement
		);

		if (!hasCollisionWithBox) return hasCollisionWithBox;

		// Used only to check if elements are really collisioning
		return CollisionTools.checkCollisionWithRotation(this, el, domElementPosition);
	}

	scaleBy(scale: number) {
		this.size.width *= scale;
		this.size.height *= scale;
		this.position.x *= scale;
		this.position.y *= scale;
	}

	isValidSize(proposedSize: Size) {
		if (proposedSize.height !== undefined) return proposedSize.width > 0 && proposedSize.height > 0;
		return proposedSize.width > 0;
	}

	clone() {
		const element = cloneDeep(this);

		element.id = uuidv4();

		return element;
	}

	static defaults(): Omit<ElementDTO, 'type'> {
		return {
			metadata: {},
			size: { height: 0, width: 0 },
			position: { x: 0, y: 0 },
			rotation: 0,
			flip: { x: false, y: false },
			group: null,
			locked: false,
			keepProportions: true,
			opacity: 1,
			virtualGroup: null,
			tags: [],
			index: '',
		};
	}
}

export default Element;
