<script setup lang="ts">
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Text }>();
const element = toRef(props, 'element');

// Using composables
const { listStyle, updateList, rescaleText } = useTextStyles(element);
const { isCircleText } = useCircleTypeInfo(element);
const { trans } = useI18n();

const listClickManager = () => {
	if (element.value.scale !== 1) {
		rescaleText();
	}

	switch (listStyle.value) {
		case '': {
			updateList('unordered');
			break;
		}
		case 'unordered': {
			updateList('ordered');
			break;
		}
		case 'ordered': {
			updateList('');
			break;
		}
	}
	GAnalytics.track('click', 'Button', `text-list-${listStyle.value}`, null);
};
</script>

<template>
	<div class="flex flex-col">
		<button
			data-testid="text-list"
			:disabled="isCircleText"
			:class="isCircleText ? 'cursor-not-allowed opacity-25' : 'hover:bg-gray-600'"
			class="flex h-8 w-full items-center justify-center rounded bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700"
			data-title="alignText"
			@click="listClickManager()"
		>
			<SvgIcon v-if="listStyle === ''" name="minus" class="h-5 w-5" />
			<SvgIcon v-if="listStyle === 'unordered'" name="list" class="h-5 w-5 text-white mockup:text-blue-500" />
			<SvgIcon v-if="listStyle === 'ordered'" name="list-ordened" class="h-5 w-5 text-white mockup:text-blue-500" />
		</button>
		<label
			class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700"
			:class="isCircleText ? 'opacity-50' : ''"
		>
			{{ trans('List') }}
		</label>
	</div>
</template>
