<script lang="ts" setup>
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import TemplateSubcategoriesButtons from '@/layout/components/TemplateSubcategoriesButtons.vue';
import { SubcategoryTemplateData, TemplateApiData } from '@/Types/apiClient';

defineProps<{ data: TemplateApiData[]; loading: boolean; subcategories: SubcategoryTemplateData[] }>();
const { isEmbeddedContext } = useEditorMode();

const emit = defineEmits(['load', 'open']);
const loadMore = () => {
	emit('load');
};

const onClickTemplate = (template: TemplateApiData) => {
	emit('open', template);
};
</script>

<template>
	<InfiniteLoading
		:data="data"
		:is-fetching="loading"
		class="flex flex-col"
		with-masonry
		:masonry-cols="2"
		@load="loadMore"
	>
		<template #default>
			<TemplateSubcategoriesButtons v-if="subcategories?.length" :subcategories="subcategories" />
		</template>
		<template #item="{ item }">
			<button
				v-if="'metadata' in item && 'previews' in item"
				class="relative mb-2 w-full overflow-hidden rounded bg-gray-700"
				:style="{ aspectRatio: ('proportion' in item?.metadata && item?.metadata.proportion) || 1 }"
				@click.stop="onClickTemplate(item as TemplateApiData)"
			>
				<img
					v-if="item?.previews.length"
					:src="item?.previews[0].thumb"
					:alt="item?.name"
					class="inset-0 w-full cursor-pointer rounded bg-gray-600 object-contain"
					draggable="false"
				/>
				<div class="absolute right-2 top-2 z-10 flex items-center gap-1">
					<span
						v-if="item?.tags.includes('video')"
						class="flex h-4 w-6 items-center justify-center rounded-full bg-blue-500 text-xs text-white backdrop-blur slidesgo:bg-purple-400/80"
					>
						<SvgIcon name="video" class="h-3 w-3 fill-current" />
					</span>
					<span
						v-if="item?.pages > 1 && !isEmbeddedContext"
						class="flex h-4 items-center justify-center rounded-full bg-darkblue-300/80 px-2 text-xs font-bold text-white"
						v-text="item?.pages"
					></span>
				</div>
			</button>
		</template>
	</InfiniteLoading>
</template>
