<script lang="ts" setup>
import { useEventListener } from '@vueuse/core';
import { ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { DraggableItemData, InsertableApiType, Panels } from '@/Types/types';

const store = useMainStore();

const { isMobile } = useDeviceInfo();

// Props
const props = defineProps<{
	draggable: boolean;
	data: DraggableItemData;
	type: InsertableApiType | Panels;
}>();

// Data
const el = ref();
const dragging = ref(false);

// Methods
useEventListener(el, 'dragstart', (e: DragEvent) => {
	if (!e.dataTransfer) return;

	if (isMobile.value || !props.draggable) {
		e.preventDefault();
		return false;
	}

	dragging.value = true;
	store.draggingItem = {
		data: props.data,
		type: props.type,
	};
	e.dataTransfer.effectAllowed = 'copyMove';
});

useEventListener(el, 'dragend', () => {
	store.draggingItem = null;
	dragging.value = false;
});
</script>

<template>
	<button
		ref="el"
		data-testid="item-panel"
		class="group flex shrink-0 items-center justify-center focus-visible:outline-0"
		:class="dragging ? 'opacity-25' : ''"
	>
		<slot></slot>
	</button>
</template>
