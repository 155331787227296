import { createSharedComposable, until } from '@vueuse/core';
import { createEventHook } from '@vueuse/core';
import sysend from 'sysend';
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { checkProvider } from '@/api/UserApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useMainStore } from '@/editor/stores/store';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import { ShareModalType } from '@/Types/types';

export const useShare = createSharedComposable(() => {
	const { trans } = useI18n();

	const { isLogged, user, requireAuth } = useAuth();
	const toast = useToast();
	const store = useMainStore();
	const { rrssProfilePages } = useSharePublish();

	const loading = ref(false);
	const metaAuthenticated = ref(false);
	const pinterestAuthenticated = ref(false);
	const twitterAuthenticated = ref(false);

	const onAuth = createEventHook<ShareModalType>();
	const pinterestBoards = ref(0);
	const oauthWindow = ref<Window | null>(null);

	const { APP_BASE } = useEnvSettings();

	// Facebook + Instagram
	const authenticatedInFacebook = computed(() => user.value?.tokens.facebook.status);
	const {
		data: checkFacebook,
		execute: executeCheckFacebook,
		onFetchResponse: onFetchFacebookResponse,
	} = checkProvider('facebook');

	const getFacebookPages = async () => {
		const { data } = await useEditorApiFetch('facebook/pages').get().json();
		return data;
	};

	const getInstagramAccounts = async () => {
		const { data } = await useEditorApiFetch('facebook/instagram-accounts').get().json();
		return data;
	};

	const initMeta = async (socialMedia: ShareModalType.facebook | ShareModalType.instagram) => {
		if (store.sharing || loading.value) return;

		if (!isLogged.value) {
			requireAuth();
			await until(isLogged).toBeTruthy();
		}

		loading.value = true;

		sysend.on('oauth:facebook', async () => {
			oauthWindow.value?.close();

			if (socialMedia === ShareModalType.facebook) {
				await checkFacebookAuthentication();
			} else {
				await checkInstagramAuthentication();
			}

			if (metaAuthenticated.value) {
				sysend.off('oauth:facebook');

				GAnalytics.track('request', 'Share', 'meta-link-ok');
				toast.success(trans('Facebook account linked successfully!'));
				if (metaAuthenticated.value) onAuth.trigger(ShareModalType.facebook);
			} else {
				GAnalytics.track('request', 'Share', 'meta-link-failure');
			}
		});

		oauthWindow.value = window.open(
			`${APP_BASE}auth/provider?provider=facebook`,
			`Login in Facebook`,
			'height=800,width=700'
		);
	};

	const checkFacebookAuthentication = async () => {
		if (!isLogged.value) {
			return;
		}

		if (authenticatedInFacebook.value) {
			metaAuthenticated.value = true;
		}

		if (!metaAuthenticated.value) {
			await executeCheckFacebook();

			if (checkFacebook.value.status && user.value) {
				metaAuthenticated.value = true;
				user.value.tokens.facebook = {
					status: true,
					token: checkFacebook.value.token,
				};
			}
		}

		if (metaAuthenticated.value) {
			const pagesRes = await getFacebookPages();
			rrssProfilePages.value = pagesRes.value;
			if (metaAuthenticated.value) onAuth.trigger(ShareModalType.facebook);
		}

		loading.value = false;
	};

	const checkInstagramAuthentication = async () => {
		if (!isLogged.value) {
			return;
		}

		if (authenticatedInFacebook.value) {
			metaAuthenticated.value = true;
		}

		if (!metaAuthenticated.value) {
			await executeCheckFacebook();

			if (checkFacebook.value.status && user.value) {
				metaAuthenticated.value = true;
				user.value.tokens.facebook = {
					status: true,
					token: checkFacebook.value.token,
				};
			}
		}

		if (metaAuthenticated.value) {
			const pagesRes = await getInstagramAccounts();
			rrssProfilePages.value = pagesRes.value;
			if (metaAuthenticated.value) onAuth.trigger(ShareModalType.instagram);
		}

		loading.value = false;
	};

	const logoutFacebook = async () => {
		await useEditorApiFetch('facebook/logout').post();
		if (user.value) {
			user.value.tokens.facebook.status = false;
			user.value.tokens.facebook.token = null;
		}
	};

	// Pinterest
	const authenticatedInPinterest = computed(() => user.value?.tokens.pinterest.status);
	const {
		data: checkPinterest,
		execute: executeCheckPinterest,
		onFetchResponse: onFetchPinterestResponse,
	} = checkProvider('pinterest');

	const hasPinterestBoards = async () => {
		const { data } = await useEditorApiFetch('pinterest/boards').get().json();

		return data.value?.items?.length > 0 || false;
	};

	const logoutPinterest = async () => {
		await useEditorApiFetch('pinterest/logout').post();
		if (user.value) {
			user.value.tokens.pinterest.status = false;
			user.value.tokens.pinterest.token = null;
		}
	};

	const initPinterest = async () => {
		loading.value = true;
		if (!isLogged.value) {
			requireAuth();
			await until(isLogged).toBeTruthy();
		}

		sysend.on('oauth:pinterest', async () => {
			oauthWindow.value?.close();
			await checkPinterestAuthentication();

			if (pinterestAuthenticated.value) {
				sysend.off('oauth:pinterest');

				GAnalytics.track('request', 'Share', 'pinterest-link-ok');
				toast.success(trans('Pinterest account linked successfully!'));
				onAuth.trigger(ShareModalType.pinterest);
			} else {
				GAnalytics.track('request', 'Share', 'pinterest-link-failure');
			}

			loading.value = false;
		});

		oauthWindow.value = window.open(
			`${APP_BASE}auth/provider?provider=pinterest`,
			`Login in Pinterest`,
			'height=800,width=700'
		);
	};

	const checkPinterestAuthentication = async () => {
		if (!isLogged.value) {
			return;
		}

		if (authenticatedInPinterest.value) {
			pinterestAuthenticated.value = true;
		}

		if (!pinterestAuthenticated.value) {
			await executeCheckPinterest();

			if (checkPinterest.value.status && user.value) {
				pinterestAuthenticated.value = true;
				user.value.tokens.pinterest = {
					status: true,
					token: checkPinterest.value.token,
				};
			}
		}

		const checkIfHasBoards = await hasPinterestBoards();
		// 0 -> Loading
		// 1 -> Has boards
		// 2 -> No boards
		pinterestBoards.value = checkIfHasBoards ? 1 : 2;
	};

	const pinterestLogout = async () => {
		await logoutPinterest();
		pinterestAuthenticated.value = false;
	};

	// Twitter
	const authenticatedInTwitter = computed(() => user.value?.tokens.twitter.status);
	const {
		data: checkTwitter,
		execute: executeCheckTwitter,
		onFetchResponse: onFetchTwitterResponse,
	} = checkProvider('twitter');

	const logoutTwitter = async () => {
		await useEditorApiFetch('twitter/logout').post();
		if (user.value) {
			user.value.tokens.twitter.status = false;
			user.value.tokens.twitter.token = null;
		}
	};

	const initTwitter = async () => {
		if (store.sharing || loading.value) return;

		if (!isLogged.value) {
			requireAuth();
			await until(isLogged).toBeTruthy();
		}

		loading.value = true;

		sysend.on('oauth:twitter', async () => {
			oauthWindow.value?.close();
			await checkTwitterAuthentication();

			if (twitterAuthenticated.value) {
				sysend.off('oauth:twitter');

				GAnalytics.track('request', 'Share', 'twitter-link-ok');
				toast.success(trans('Twitter account linked successfully!'));
				if (twitterAuthenticated.value) onAuth.trigger(ShareModalType.twitter);
			} else {
				GAnalytics.track('request', 'Share', 'twitter-link-failure');
			}
		});

		oauthWindow.value = window.open(
			`${APP_BASE}auth/provider?provider=twitter`,
			`Login in Twitter`,
			'height=800,width=700'
		);
	};

	const checkTwitterAuthentication = async () => {
		if (!isLogged.value) {
			return;
		}

		if (authenticatedInTwitter.value) {
			twitterAuthenticated.value = true;
		}

		if (!twitterAuthenticated.value) {
			await executeCheckTwitter();

			if (checkTwitter.value.status && user.value) {
				twitterAuthenticated.value = true;
				user.value.tokens.twitter = {
					status: true,
					token: checkTwitter.value.token,
				};
			}
		}

		loading.value = false;
		if (twitterAuthenticated.value) onAuth.trigger(ShareModalType.twitter);
	};

	return {
		loading,
		onAuth: onAuth.on,
		// Facebook + Instagram
		metaAuthenticated,
		authenticatedInFacebook,
		checkFacebook,
		initMeta,
		checkFacebookAuthentication,
		checkInstagramAuthentication,
		executeCheckFacebook,
		onFetchFacebookResponse,
		getFacebookPages,
		getInstagramAccounts,
		logoutFacebook,
		// Pinterest
		pinterestBoards,
		pinterestAuthenticated,
		authenticatedInPinterest,
		checkPinterest,
		initPinterest,
		checkPinterestAuthentication,
		pinterestLogout,
		executeCheckPinterest,
		onFetchPinterestResponse,
		hasPinterestBoards,
		logoutPinterest,
		// Twitter
		twitterAuthenticated,
		authenticatedInTwitter,
		checkTwitter,
		initTwitter,
		checkTwitterAuthentication,
		executeCheckTwitter,
		onFetchTwitterResponse,
		logoutTwitter,
	};
});
