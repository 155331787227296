import { computed, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';
import { Size, Unit } from '@/Types/types';

export const useArtboard = () => {
	const { isPhotoMode, isAdminMode, isRenderingContext } = useEditorMode();
	const { isIOS } = useDeviceInfo();
	const store = useMainStore();
	const { clearSelection } = useSelection();

	const project = useProjectStore();

	// Valores max y min en px
	const MAX_ARTBOARD_SIZE = 557998884; // (2000mm * MM_TO_PX) x (2000mm * MM_TO_PX)
	const MAX_ARTBOARD_SIZE_PHOTO_MODE = 58982400; // 7680px x 7680px (8K Ready)

	// Si se cambia esto hay que cambiar también el del converter
	const MAX_ARTBOARD_SIZE_TO_DOWNLOAD = 58982401;

	const MIN_ARTBOARD_SIZE = 8100; // 90 x 90 px

	const MM_TO_PX = 3.7795275590551185;

	const MAX_PX_ALLOWED = 1000; // Máximos px que vamos a permitir en pantalla para una plantilla

	const maxArtboardSize = computed(() => (isPhotoMode.value ? MAX_ARTBOARD_SIZE_PHOTO_MODE : MAX_ARTBOARD_SIZE));

	const artboardSizeInPx = computed(() => {
		if (project.unit === 'mm') {
			return convertMmToPx(project.size.width, project.size.height);
		}

		return project.size;
	});

	const artboardSizeInMm = computed(() => {
		if (project.unit === 'px') {
			return convertPxToMm(project.size.width, project.size.height);
		}

		return project.size;
	});

	const aspectRatio = computed(() => project.size.width / project.size.height);

	const isLandscape = computed(() => project.size.width >= project.size.height);
	const isRotable = computed(() => project.size.width !== project.size.height);
	const ratio = computed(() => project.size.width / project.size.height);

	const widerSide = computed(() => Math.max(artboardSizeInPx.value.width, artboardSizeInPx.value.height));

	const convertMmToPx = (width: number, height: number): Size => {
		return {
			width: Math.round(width * MM_TO_PX),
			height: Math.round(height * MM_TO_PX),
		};
	};

	const convertPxToMm = (width: number, height: number): Size => {
		return {
			width: Math.round(width / MM_TO_PX),
			height: Math.round(height / MM_TO_PX),
		};
	};

	const setArtboardSize = (width: number, height: number, unit: Unit, allowInvalidSize = false) => {
		if (!width || !height || !unit) return;

		const hasChanges = width !== project.size.width || height !== project.size.height || unit !== project.unit;
		const hasValidSize = isValidSize(width, height, unit);

		if (!hasChanges) return;
		if (!hasValidSize && !allowInvalidSize) throw new Error('Proposed size and unit are invalid.');

		clearSelection();

		// Rectificamos el tamaño si el artboard sobrepasa el límite permitido para IOS
		const fixedSizeToMaxAllowedInIos = resizeToAllowedSize(width, height, unit);

		project.$patch((state) => {
			state.size = {
				width: fixedSizeToMaxAllowedInIos.width,
				height: fixedSizeToMaxAllowedInIos.height,
			};
			state.unit = unit;
		});

		store.scaleMaxAllowedSize = fixedSizeToMaxAllowedInIos.scale;
	};

	const rotateArtboard = () => {
		if (project.size.height !== project.size.width) {
			setArtboardSize(
				project.size.height / store.scaleMaxAllowedSize,
				project.size.width / store.scaleMaxAllowedSize,
				project.unit
			);
		}
	};

	const isValidSize = (width: number, height: number, unit: Unit): boolean => {
		const realWidth = width * (unit === 'mm' ? MM_TO_PX : 1);
		const realHeight = height * (unit === 'mm' ? MM_TO_PX : 1);

		const areSidesBiggerThanMin =
			realWidth >= Math.sqrt(MIN_ARTBOARD_SIZE) && realHeight >= Math.sqrt(MIN_ARTBOARD_SIZE);

		const areSidesSmallerThanMax =
			realWidth <= Math.sqrt(maxArtboardSize.value) && realHeight <= Math.sqrt(maxArtboardSize.value);

		const isSmallArtboard = realWidth * realHeight < MIN_ARTBOARD_SIZE;
		const isBigArtboard = realHeight * realWidth > maxArtboardSize.value;

		return areSidesBiggerThanMin && areSidesSmallerThanMax && !isSmallArtboard && !isBigArtboard;
	};

	const isValidSizeToDownload = (width: number, height: number, unit: Unit): boolean => {
		const realWidth = width * (unit === 'mm' ? MM_TO_PX : 1);
		const realHeight = height * (unit === 'mm' ? MM_TO_PX : 1);

		return realHeight * realWidth < MAX_ARTBOARD_SIZE_TO_DOWNLOAD;
	};

	/**
	 * IOS se queda sin memoria cuando trata de escalar elementos muy grandes, por lo que tenemos que falsear el tamaño
	 * de la plantilla para que estas funcionen, la escalamos al máximo permitido manteniendo el aspect ratio.
	 *
	 * @returns tamaño rectificado en caso de que estemos en IOS + escala aplicada o el mismo valor que se le pasa si no hay que rectificar
	 */
	// IOS se queda sin memoria cuando trata de escalar elementos muy grandes, por lo que tenemos que falsear el tamaño
	// de la plantilla para que estas funcionen, la escalamos al máximo permitido manteniendo el aspect ratio
	const resizeToAllowedSize = (width: number, height: number, unit: Unit): Size & { scale: number } => {
		if (isAdminMode.value || isRenderingContext || !isIOS.value)
			return { width, height, scale: store.scaleMaxAllowedSize };

		const sizeInPx = unit === 'mm' ? convertMmToPx(width, height) : { width, height };
		// Si el artboard tiene un tamaño permitido resetamos la escala
		const widerSide = Math.max(sizeInPx.width, sizeInPx.height);

		if (widerSide <= MAX_PX_ALLOWED) {
			return { width, height, scale: 1 };
		}

		// Calculamos la escala a aplicar al proyecto y se la aplicamos
		const projectScale = MAX_PX_ALLOWED / widerSide;

		return { width: width * projectScale, height: height * projectScale, scale: projectScale };
	};

	return {
		maxArtboardSize,
		MIN_ARTBOARD_SIZE,
		MM_TO_PX,
		artboardSizeInPx,
		artboardSizeInMm,
		widerSide,
		aspectRatio,
		isLandscape,
		isRotable,
		ratio,
		isValidSize,
		isValidSizeToDownload,
		setArtboardSize,
		rotateArtboard,
		resizeToAllowedSize,
		convertMmToPx,
	};
};
