import { SolidColor } from '@/color/classes/SolidColor';
import { Box } from '@/elements/box/classes/Box';
import Element from '@/elements/element/classes/Element';
import { Illustrator } from '@/elements/illustrator/classes/Illustrator';
import Line from '@/elements/line/classes/Line';
import { Crop } from '@/elements/medias/crop/types/croppeable.type';
import { Filter } from '@/elements/medias/filter/classes/Filter';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import Mask from '@/elements/medias/mask/classes/Mask';
import { Video } from '@/elements/medias/video/classes/Video';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { Text } from '@/elements/texts/text/classes/Text';
import { Color } from '@/Types/colorsTypes';
import {
	CurvedProperties,
	Flip,
	Position,
	PrimaryElementTypes,
	Size,
	TextOutlineProperties,
	TextShadowProperties,
} from '@/types/types';

export type StorysetBackground = 'hidden' | 'simple' | 'complete';

export type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type FontStyle = 'normal' | 'italic';
export type RangeOption = 'full' | Selection;
export type TextAlign = 'left' | 'center' | 'right' | 'justify';
export type TextTransform = '' | 'lowercase' | 'uppercase' | 'capitalize';
export type ListStyle = '' | 'ordered' | 'unordered';

export enum TextDecoration {
	underline = 'underline',
	none = 'none',
}

export type LegacyTextValues = {
	shadowAngle: number;
	shadowColor: SolidColor;
	shadowOpacity: number;
	shadowDistance: number;
	shadowBlur: number;
	borderColor: SolidColor;
	borderWidth: number;
};

export enum StyleProperties {
	fontSize = 'fontSize',
	color = 'color',
	fontFamily = 'fontFamily',
	lineHeight = 'lineHeight',
	fontWeight = 'fontWeight',
	fontStyle = 'fontStyle',
	letterSpacing = 'letterSpacing',
}

// Verifica si los tipos X e Y son iguales. Si son iguales, devuelve el tipo A; de lo contrario, devuelve el tipo B.
type IfEquals<X, Y, A, B> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B;

// Para ignorar los getters y setters de los objetos
type WritableKeysOf<T> = {
	[P in keyof T]: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P, never>;
}[keyof T];

type WritablePart<T> = Pick<T, WritableKeysOf<T>>;

// Para ignorar las funciones de los objetos
// eslint-disable-next-line @typescript-eslint/ban-types
type NonFunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? never : K }[keyof T];
type WithoutFunctions<T> = Pick<T, NonFunctionPropertyNames<T>>;

// Vamos usando lo anterior para solo mantener los atributos de los objetos, ignorando los id
type OnlyAttributtes<T> = WritablePart<WithoutFunctions<T>>;
type OnlyAttributtesWithoutId<T> = Omit<OnlyAttributtes<T>, 'id'>;

export type ElementDTO = OnlyAttributtesWithoutId<Element>;
export type BoxDTO = OnlyAttributtesWithoutId<Box>;
export type IllustratorDTO = OnlyAttributtesWithoutId<Illustrator>;
export type LineDTO = OnlyAttributtesWithoutId<Line>;
export type BaseImageDTO = OnlyAttributtesWithoutId<BaseImage>;
export type ForegroundImageDTO = OnlyAttributtesWithoutId<ForegroundImage>;
export type ImageDTO = OnlyAttributtesWithoutId<Image>;
export type MaskDTO = OnlyAttributtesWithoutId<Mask>;
export type VideoDTO = OnlyAttributtesWithoutId<Video>;
export type QRCodeDTO = OnlyAttributtesWithoutId<QRCode>;
export type ShapeDTO = OnlyAttributtesWithoutId<Shape>;
export type StorysetDTO = OnlyAttributtesWithoutId<Storyset>;
export type TextDTO = OnlyAttributtesWithoutId<Text>;
