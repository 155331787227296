<script setup lang="ts">
import { MaybeElementRef } from '@vueuse/core';
import { intersection } from 'lodash-es';
import { computed, nextTick, onMounted, Ref, ref, toRef, watch } from 'vue';

import CollisionFeedback from '@/collision/components/CollisionFeedback.vue';
import ImageReplacePreview from '@/collision/components/ImageReplacePreview.vue';
import { useCollisionHandlerOnDrag } from '@/collision/composables/useCollisionHandlerOnDrag';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import TaggedToolbar from '@/elements/element/components/toolbars/TaggedToolbar.vue';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import Crop from '@/elements/medias/crop/components/Crop.vue';
import CropPhotoMode from '@/elements/medias/crop/components/CropPhotoMode.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import TextEditing from '@/elements/texts/text/components/TextEditing.vue';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import TextAIWriter from '@/elements/texts/text/TextAIWriter.vue';
import { useI18n } from '@/i18n/useI18n';
import ElementsSelection from '@/interactions/components/ElementsSelection.vue';
import { useHandleDraggables } from '@/interactions/composables/useHandleDraggables';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import Page from '@/page/classes/Page';
import Canvas from '@/page/components/Canvas.vue';
import CanvasTools from '@/page/components/CanvasTools.vue';
import { useCanvasSize } from '@/project/composables/useCanvasSize';
import { InsertableApiType, PrimaryElementTypes } from '@/Types/types';

const { trans } = useI18n();

const store = useMainStore();
const { selection: selectionCanvas } = useSelection();
// Props
const props = withDefaults(
	defineProps<{
		page: Page;
		scrollArea: MaybeElementRef;
		toolbar?: boolean;
	}>(),
	{
		toolbar: true,
	}
);

// Data
const page = toRef(props, 'page');

const canvas = ref();
const canvasTool = ref();
const interactiveContainer = ref();
const showSelection = ref(false);

// Using composables
const { isPhotoMode } = useEditorMode();
const { isMobile } = useDeviceInfo();
const { textEditing } = useTextEditing();
const { textAI } = useAiWriter();
const { width, height, canvasWidthScaled, canvasHeightScaled, canvasToolHeight } = useCanvasSize(
	canvas,
	canvasTool,
	interactiveContainer
);
const { draggingElement, hoveredElement } = useCollisionHandlerOnDrag(ref<PrimaryElementTypes[]>(['image', 'video']));
const { isBackground } = useIsBackground(draggingElement as Ref<Image>);
// Computeds
const showHoverFeedback = computed(() => {
	if (isBackground.value) return false;
	const isDraggingImage = draggingElement.value && draggingElement.value instanceof Image;
	const isDraggingVideo = draggingElement.value && draggingElement.value instanceof Video;
	const isHoveringImage = hoveredElement.value && hoveredElement.value instanceof Image;
	const isHoveringVideo = hoveredElement.value && hoveredElement.value instanceof Video;
	const placeholders = [!!draggingElement.value?.mask?.isPlaceholder, !!hoveredElement.value?.mask?.isPlaceholder];
	const isOnlyOnePlaceholder = placeholders.filter((p: boolean) => p).length === 1;

	const showOnElementOverElement =
		((isDraggingImage && isHoveringImage) ||
			(isDraggingImage && isHoveringVideo) ||
			(isDraggingVideo && isHoveringImage)) &&
		isOnlyOnePlaceholder;
	return showOnElementOverElement;
});
const activePageId = computed(() => store.activePageId);
// Avoid blank empty canvas flash in photo mode
const isReadyToBeShown = computed(() => !isPhotoMode.value || page.value.elementsAsArray()[0]?.locked);
const isIllustratorPage = computed(() => page.value.elementsAsArray().find((e) => e.type === 'illustrator'));

const { hoveredMedia, draggingFile } = useHandleDraggables(page, interactiveContainer);
const isMaskFromPanelOverMedia = computed(
	() =>
		hoveredMedia.value && !hoveredMedia.value.target.locked && store.draggingItem?.type === InsertableApiType.ImageMask
);

// Lifecycle hooks
onMounted(() => {
	if (page.value.name == 'temporal') return;
	store.activePageId = store.activePageId || page.value.id;
});

watch(
	selectionCanvas,
	async (value, oldValue) => {
		// Crop toolbar debe mostrarse cuando hacemos crop a la foto del modo photo
		if (isPhotoMode.value && !!store.croppingId) {
			showSelection.value = true;
			return;
		}

		// Si la selección es la misma no queremos desmontar el componente ya que estaremos haciendo un cambio de z-index
		// o similar
		const newIds = value.map((e) => e.id);
		const oldIds = (oldValue || []).map((e) => e.id);

		// Intersection = elementos en ambos array
		const isSameSelection = oldIds.length === newIds.length && intersection(oldIds, newIds).length === newIds.length;
		if (isSameSelection) {
			return;
		}
		showSelection.value = false;
		if (!value.length) {
			return;
		}
		await nextTick();
		showSelection.value = true;
	},
	{ immediate: true }
);

const { isTagMode } = useEditorMode();
</script>

<template>
	<div
		:id="`interactive-canvas-${page.id}`"
		ref="interactiveContainer"
		class="interactive-canvas relative mx-4 my-auto select-none"
		:data-page-id="page.id"
	>
		<div
			class="mx-auto my-12 lg:my-0 lg:px-8"
			:style="{
				width: `${!isMobile ? canvasWidthScaled + 64 + 'px' : canvasWidthScaled + 'px'}`,
				height: `${
					!isMobile ? canvasHeightScaled + canvasToolHeight + 64 + 'px' : canvasHeightScaled + canvasToolHeight + 'px'
				}`,
			}"
		>
			<CanvasTools v-if="toolbar && !isPhotoMode" ref="canvasTool" :page="page" />
			<div>
				<ElementsSelection
					v-if="showSelection && selectionCanvas.length && !isIllustratorPage"
					:elements="selectionCanvas"
				/>
				<template v-if="isTagMode">
					<TaggedToolbar v-for="element in store.activePage.elements" :key="element.id" :element="element" />
				</template>

				<div ref="canvas" class="relative h-0" style="transform: translateZ(0)">
					<Canvas
						v-show="isReadyToBeShown"
						:page="(page as Page)"
						:scroll-area="scrollArea"
						interactive
						:size="{
							width,
							height,
							canvasWidthScaled,
							canvasHeightScaled,
							scale: store.scale,
						}"
					>
						<template #default>
							<template v-if="store.cropping && selectionCanvas.includes(store.cropping)">
								<CropPhotoMode
									v-if="isPhotoMode && page.backgroundImageId === store.croppingId"
									:image="store.cropping"
								/>
								<Crop v-else :image="store.cropping" />
							</template>
							<TextEditing v-if="textEditing && selectionCanvas.includes(textEditing)" />
							<TextAIWriter v-if="textAI && selectionCanvas.map((e) => e.id).includes(textAI.id)" />
							<ImageReplacePreview
								v-if="hoveredMedia && !hoveredMedia.target.locked && !isMaskFromPanelOverMedia"
								:image="hoveredMedia"
								:scale="store.scale"
							/>
							<CollisionFeedback v-else-if="hoveredElement && showHoverFeedback" :element="hoveredElement" />
						</template>
						<template #noRender>
							<div
								class="pointer-events-none absolute inset-0 z-10 m-auto flex w-full items-center justify-center bg-black bg-opacity-10"
							>
								<SvgIcon name="spinner" class="h-16 w-16 animate-spin opacity-50" />
							</div>
						</template>
					</Canvas>

					<div
						v-if="draggingFile"
						class="pointer-events-none absolute left-0 top-0 flex flex-col items-center justify-center border-2 border-dashed border-white bg-black/75 text-white"
						:style="{ width: `${canvasWidthScaled}px`, height: `${canvasHeightScaled}px` }"
					>
						<span class="text-xl font-bold">{{
							store.draggingPage !== null ? trans('Replace page') : trans('Drop file')
						}}</span>
						<SvgIcon name="drop-zone" class="mt-8 h-32 w-32" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
