<script lang="ts" setup>
import { computed } from '@vue/reactivity';
import { OnClickOutside } from '@vueuse/components';
import { nextTick, Ref, ref, watch } from 'vue';

import { getMyCreations, getUserFolders } from '@/api/UserApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import AppendReplaceModal from '@/editor/components/AppendReplaceModal.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import AddToFolderCreationModal from '@/editor/my-creations/AddToFolderCreationModal.vue';
import CreateFolderModal from '@/editor/my-creations/CreateFolderModal.vue';
import DeleteCreationModal from '@/editor/my-creations/DeleteCreationModal.vue';
import DuplicateCreationModal from '@/editor/my-creations/DuplicateCreationModal.vue';
import RenameCreationModal from '@/editor/my-creations/RenameCreationModal.vue';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';
import CanvasPreview from '@/page/components/CanvasPreview.vue';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { UserFoldersApi } from '@/Types/apiClient';
const { artboardSizeInPx } = useArtboard();

const { trans } = useI18n();
const url = ref('user/creations?page=1');
const folderName = ref('');
const selectedTemplate = ref<any>(null);
const showOptions = ref('');
const modalSelected = ref('');

const store = useMainStore();
const project = useProjectStore();
const { isLogged, requireAuth } = useAuth();
const toast = useToast();
const { data: folderData, execute: folderExecute } = getUserFolders();
const {
	data: templatesData,
	isFetching: templatesIsFetching,
	execute: executeMyCreations,
	abort: abortMyCreations,
} = getMyCreations(url, { refetch: true });

const isFolder = computed(() => url.value.includes('folder'));
const projectName = computed(() => project.name);

const onGoBack = () => {
	abortMyCreations();

	url.value = 'user/creations?page=1';
	folderName.value = '';
};

const openTemplate = (item: any) => {
	if (item.uuid === project.id) {
		toast.warning(trans('You are already inside this template'));
		return;
	}

	selectedTemplate.value = item;
	modalSelected.value = 'open';

	// Actualizamos la categoría del template para la pantalla de descarga
	store.downloadTemplateCategory = null;
};

const openAction = (item: any, action: string) => {
	if (action === 'delete' && item.uuid === project.id) {
		toast.warning(trans("You can't delete this template, it is in use"));
		return;
	}

	selectedTemplate.value = item;
	modalSelected.value = action;
};

const openFolder = (folder: any) => {
	abortMyCreations();

	url.value = `user/folder/${folder.slug}?page=1`;
	folderName.value = folder.name;
};

const loadMore = async () => {
	if (templatesIsFetching.value || !templatesData.value?.links?.next) return;

	url.value = templatesData.value.links.next;
};

const onCloseModal = () => {
	selectedTemplate.value = null;
	modalSelected.value = '';
};

const createdFolder = (folder: UserFoldersApi) => {
	modalSelected.value = '';
	const currentFolderList = folderData.value || [];

	folderData.value = currentFolderList.filter((item) => item.slug !== folder.slug);
	folderData.value.push(folder);
};

const refresh = () => {
	modalSelected.value = '';
	templatesData.value = null;
	url.value = url.value.split('?')[0] + '?page=1';

	if (!templatesIsFetching.value) {
		executeMyCreations();
	}
};

const deleteTemplate = () => {
	modalSelected.value = '';
	// eliminamos del templatesData solo el elemento a borrar.
	if (templatesData.value) {
		templatesData.value = {
			...templatesData.value,
			data: templatesData.value.data?.filter((item) => item.uuid !== selectedTemplate.value.uuid),
		};
	}
};

const handlerOnClickOutside = (ev: Event) => {
	if (ev.target && (ev.target as HTMLButtonElement).classList.contains('btn-options')) return;
	showOptions.value = '';
};

const toggleOptions = (uuid: string) => {
	showOptions.value = showOptions.value == uuid ? '' : uuid;
};

watch(isLogged, (newValue) => {
	folderName.value = '';
	showOptions.value = '';
	url.value = 'user/creations?page=1';

	if (newValue) {
		folderExecute();
		refresh();
	}
});

// Si modificamos el nombre en el header lo actualizamos
// en el listado
watch(projectName, (newValue) => {
	templatesData.value?.data?.forEach((el: any) => {
		if (el.uuid === project.id) {
			el.name = newValue;
		}
	});
});
const currentPreview = ref(project.pages[0]) as Ref<Page>;
const forceRenderPreview = ref(true);
watch(
	() => project.pages,
	async () => {
		forceRenderPreview.value = false;
		currentPreview.value = project.pages[0] as Page;
		await nextTick();
		forceRenderPreview.value = true;
	}
);
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader :title="isFolder ? folderName : 'My projects'" :show-back-button="isFolder" @goBack="onGoBack">
			<template v-if="isLogged" #buttons>
				<div class="absolute right-14 top-4 flex h-6 items-center justify-center text-gray-100 hover:text-white">
					<button
						class="text-gray-100 hover:text-white"
						:tooltip="trans('Create folder')"
						tooltip-position="left"
						@click="openAction(null, 'createFolder')"
					>
						<SvgIcon name="create-folder" class="h-4 w-4" />
					</button>
				</div>
			</template>
		</PanelHeader>

		<div v-if="!isLogged">
			<p class="mb-3 text-center text-gray-100">{{ trans('To use my projects you must log in.') }}</p>

			<button
				class="itmes-center mx-auto flex h-10 cursor-pointer items-center justify-center rounded bg-blue-500 px-8 text-center font-semibold text-white focus:outline-none hover:bg-blue-600 slidesgo:bg-purple-400"
				@click="requireAuth()"
			>
				{{ trans('Login') }}
			</button>
		</div>

		<div
			v-if="!isFolder && isLogged"
			class="flex w-full overflow-y-auto pb-2 text-gray-800 scrollbar-thin scrollbar-thumb-gray-600"
		>
			<button
				v-for="folder in folderData"
				:key="folder.slug"
				class="mr-2 h-6 shrink-0 rounded-full border border-gray-600 px-3 text-xs font-semibold text-gray-100 hover:bg-gray-600 hover:text-white"
				@click="openFolder(folder)"
			>
				{{ folder.name }}
			</button>
		</div>

		<InfiniteLoading
			v-if="isLogged"
			:data="(templatesData && templatesData.data) || []"
			:is-fetching="templatesIsFetching"
			class="flex flex-col"
			with-masonry
			:masonry-cols="2"
			@load="loadMore"
		>
			<template #item="{ item }">
				<button
					v-if="'editLink' in item"
					class="group relative mb-2 w-auto rounded bg-gray-700"
					:style="{
						aspectRatio: `${(item.uuid === project.id ? artboardSizeInPx.width : item.previews[0]?.width) || 1000} / ${
							(item.uuid === project.id ? artboardSizeInPx.height : item.previews[0]?.height) || 1000
						}`,
					}"
					@click.stop="openTemplate(item)"
					@contextmenu.prevent="showOptions = item.uuid || ''"
				>
					<img
						v-if="item.uuid !== project.id"
						:src="item.previews[0]?.thumb"
						:alt="item.name"
						class="inset-0 h-full w-full cursor-pointer rounded bg-gray-600 object-contain"
						draggable="false"
					/>

					<div
						v-if="item.uuid === project.id && forceRenderPreview"
						:id="currentPreview.id + 'my-proyect-preview'"
						class="h-full w-full"
					>
						<CanvasPreview
							preview-name="my-project"
							class="h-full w-full rounded border-2 border-blue-500 slidesgo:border-purple-400"
							:page="currentPreview"
						/>
					</div>
					<OnClickOutside class="absolute right-2 top-2" @click.stop @trigger="handlerOnClickOutside">
						<button
							class="btn-options flex h-5 w-5 cursor-pointer items-center justify-center rounded bg-blue-500 text-white opacity-0 group-hover:opacity-100 hover:bg-blue-600 slidesgo:bg-purple-400"
							@click.stop="toggleOptions(item.uuid || '')"
						>
							<SvgIcon name="more" class="h-3 w-3 rotate-90" />
						</button>

						<Popper v-if="showOptions === item.uuid" placement="bottom-start" partent-selector=".btn-options">
							<div class="mt-1 flex min-w-max flex-col rounded bg-white p-2 text-sm shadow">
								<div class="mx-1 mb-1 border-b border-gray-100/50 pb-2">
									<p class="truncate font-semibold text-gray-600">{{ item.name || 'Unnamed' }}</p>
								</div>

								<button
									class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
									@click="openAction(item, 'rename')"
								>
									<SvgIcon name="edit" class="mr-2 h-3 w-3" />
									{{ trans('Rename') }}
								</button>

								<button
									class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
									@click="openAction(item, 'delete')"
								>
									<SvgIcon name="trash" class="mr-2 h-3 w-3" />
									{{ trans('Delete') }}
								</button>

								<button
									class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
									@click="openAction(item, 'duplicate')"
								>
									<SvgIcon name="copy" class="mr-2 h-3 w-3" />
									{{ trans('Duplicate') }}
								</button>

								<button
									class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
									@click="openAction(item, 'addToFolder')"
								>
									<SvgIcon name="add-to-folder" class="mr-2 h-3 w-3" />
									{{ trans('Add to folder') }}
								</button>
							</div>
						</Popper>
					</OnClickOutside>
				</button>
			</template>
		</InfiniteLoading>

		<CreateFolderModal v-if="modalSelected === 'createFolder'" @close="onCloseModal" @created="createdFolder" />
		<AppendReplaceModal
			v-if="!!selectedTemplate && modalSelected === 'open'"
			:selected-template="selectedTemplate"
			@close="onCloseModal"
		>
			<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">
				{{ trans('You’re going to open one of your previous projects') }}
			</h1>
			<p class="mb-0 text-center leading-normal text-gray-600">
				{{ trans('You can choose between adding the template as another page or replacing the current one.') }}
			</p>
		</AppendReplaceModal>
		<RenameCreationModal
			v-if="!!selectedTemplate && modalSelected === 'rename'"
			:selected-template="selectedTemplate"
			@close="onCloseModal"
			@renamed="refresh"
		/>
		<DeleteCreationModal
			v-if="!!selectedTemplate && modalSelected === 'delete'"
			:selected-template="selectedTemplate"
			@close="onCloseModal"
			@deleted="deleteTemplate"
		/>
		<DuplicateCreationModal
			v-if="!!selectedTemplate && modalSelected === 'duplicate'"
			:selected-template="selectedTemplate"
			@close="onCloseModal"
			@duplicated="refresh"
		/>
		<AddToFolderCreationModal
			v-if="!!selectedTemplate && modalSelected === 'addToFolder'"
			:selected-template="selectedTemplate"
			:folders="folderData"
			@close="onCloseModal"
			@added="refresh"
			@created="createdFolder"
		/>
	</div>
</template>
