<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { computed, ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import Palette from '@/color/components/Palette.vue';
import { useProjectColors } from '@/color/composables/useProjectColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';
import { Option, PaletteOptionsValues } from '@/Types/types';

const props = defineProps<{ selected: Color; hideGradient?: boolean }>();
const emit = defineEmits<{ (e: 'change', color: Color): void }>();

const store = useMainStore();
const palette = ref('document');
const selector = ref();
const { colors } = useProjectColors();
const userPalettes = computed(() => {
	if (!store.user) return [];
	return store.user.brandkits.map((brandKit: any) => ({
		name: brandKit.name,
		colors: brandKit.colors.map((color: string) => SolidColor.fromString(color)),
	}));
});
const brandkitHasColors = computed(() => !!userPalettes.value.some((palette) => palette.colors.length));

const documentPalette = computed(() => {
	const newColors = getProjectColors();

	// Combinamos los colores iniciales del proyecto con los nuevos colores que pueda haber añadido el usuario
	initialDocumentPalette.forEach((color) => {
		if (!newColors.some((c) => c.toCssString() === color.toCssString())) {
			newColors.push(color);
		}
	});

	// Ordenamos newColors para que siempre tengan el mismo orden y si hay algún color que no esté en initialDocumentPalette lo añadimos al final
	// Esto es para que los colores que se añadan al proyecto se añadan al final de la paleta
	newColors.sort((a, b) => {
		const aIndex = initialDocumentPalette.findIndex((c) => c.toCssString() === a.toCssString());
		const bIndex = initialDocumentPalette.findIndex((c) => c.toCssString() === b.toCssString());

		if (aIndex === -1) return 1;
		if (bIndex === -1) return -1;

		return aIndex - bIndex;
	});

	return newColors;
});

const presetColors = computed(() => {
	// Paletas del usuario
	const userPalette = userPalettes.value.find((p: any) => p.name === palette.value.replace('custom-', ''));

	if (palette.value === 'document' || !userPalette) {
		// Devolvemos los gradientes si el color que vamos a cambiar es un color sólido
		// si es un gradiente devolvemos los colores que componen los gradientes

		return documentPalette.value;
	}

	return userPalette.colors;
});

const getProjectColors = () =>
	colors.value
		.map((color) => {
			if (color.isGradient() && props.selected.isGradient()) {
				return [color, ...(color as GradientColor).stops.map((c) => SolidColor.fromObject(c))];
			}

			return color;
		})
		.flatMap((c) => c)
		.filter((c) => {
			// Si tenemos desactivada la pestaña de degradados
			// los ocultamos en la paleta de colores
			if (props.hideGradient && c.isGradient()) {
				return false;
			}

			return true;
		})
		// Como podemos estar devolviendo SolidColors que ya están en otros gradientes hacemos que queden colores unicos
		.filter((color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString()));

// Almacenamos la paleta del documento para evitar que se mergeen los colores hasta que no se cierre el componente
// además, los ordenamos para que siempre tengan el mismo orden
const initialDocumentPalette = getProjectColors();

const setPalette = (type: string) => {
	palette.value = type;
};

const options = computed(() => {
	const userPalettesNames = userPalettes.value
		.filter((p: any) => p.colors.length > 0)
		.map((p: any) => `custom-${p.name}`);

	return ['document', ...userPalettesNames];
});

const { trans } = useI18n();

const dropdownOptions: Option[] = [
	{
		value: PaletteOptionsValues.DOCUMENT,
		label: trans('Document colors'),
	},
	{
		value: PaletteOptionsValues.BRANDS,
		label: trans('My brand colors'),
	},
];

const currentOption = ref<Option>(dropdownOptions[0]);
const showOptions = ref(false);
const paletteSelector = ref();

const onSelectOption = (option: Option) => {
	showOptions.value = false;

	currentOption.value = option;

	// Si el usuario ha seleccionado la paleta de colores del documento, seleccionamos la paleta del documento
	if (option.value === PaletteOptionsValues.DOCUMENT) {
		setPalette(options.value[0]);
	}
	// Si el usuario ha seleccionado la paleta de colores de su marca, seleccionamos la primera paleta
	if (option.value === PaletteOptionsValues.BRANDS) {
		setPalette(options.value[1]);
	}
};

const openOptions = (e: MouseEvent) => {
	if (e.target instanceof HTMLElement && e.target.closest('.palette-option')) return;

	showOptions.value = !showOptions.value;
};

onClickOutside(paletteSelector, () => {
	showOptions.value = false;
});
</script>

<template>
	<Teleport to=".vc-sketch-presets">
		<div>
			<button
				v-if="brandkitHasColors"
				ref="paletteSelector"
				class="relative z-10 flex w-full items-center justify-between rounded py-2 text-sm text-gray-300 hover:text-gray-100 lg:py-1 lg:text-xs"
				@click="openOptions"
			>
				{{ currentOption.label }}
				<SvgIcon name="arrow" class="h-4 w-4" />
				<ul
					v-if="showOptions"
					class="absolute bottom-full right-0 w-1/2 rounded-md bg-white py-1 shadow-lg lg:bottom-auto lg:left-0 lg:right-auto lg:top-full lg:w-auto"
				>
					<li
						v-for="option in dropdownOptions"
						:key="option.value"
						class="palette-option w-full px-4 py-2 text-left text-gray-500 hover:bg-gray-100/10 hover:text-gray-800 lg:py-1.5 lg:text-xs"
						@click="onSelectOption(option)"
					>
						<button>{{ option.label }}</button>
					</li>
				</ul>
			</button>
			<label
				v-else
				class="flex w-full items-center justify-between rounded py-2 text-sm text-gray-300 lg:py-1 lg:text-xs"
			>
				{{ dropdownOptions[0].label }}
			</label>
			<div class="pt-2">
				<div
					v-if="brandkitHasColors && currentOption.value === PaletteOptionsValues.BRANDS"
					ref="selector"
					class="flex overflow-auto pb-4 text-gray-800 scrollbar-thin scrollbar-thumb-gray-700 lg:pb-1"
				>
					<button
						v-for="colorPalette in options"
						v-show="colorPalette !== PaletteOptionsValues.DOCUMENT"
						:key="colorPalette"
						class="h-7 cursor-pointer rounded-full px-5 text-left text-xs font-semibold uppercase lg:h-5 lg:px-3 lg:font-normal lg:capitalize"
						:class="{
							'bg-gray-600 text-white lg:bg-gray-700 lg:text-gray-100': palette === colorPalette,
							'text-gray-100 hover:text-gray-100 lg:text-gray-300': palette !== colorPalette,
						}"
						@click="setPalette(colorPalette)"
					>
						<span class="whitespace-nowrap">{{ trans(colorPalette.replace('custom-', '')) }}</span>
					</button>
				</div>
				<div
					class="mb-2 overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 lg:max-h-32 lg:pb-0 lg:pr-1 lg:text-gray-800/75"
					style="overflow: overlay"
				>
					<div class="flex grid-cols-6 gap-2 lg:grid">
						<Palette
							:key="palette"
							:colors="presetColors"
							:selected="selected"
							@change="emit('change', $event)"
						></Palette>
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>
