import { createSharedComposable } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { TimeTools } from '@/common/utils/TimeTools';
import { useMainStore } from '@/editor/stores/store';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { Video } from '@/elements/medias/video/classes/Video';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProjectStore } from '@/project/stores/project';

interface FramesData {
	[key: string]: {
		fps: number;
		totalFrames: number;
		urls: string[];
	};
}
export const useFramesProject = createSharedComposable(() => {
	const frame = ref(0); // Empezamos siempre en el frame anterior al que queremos empezar a grabar
	const urlFrames = ref<FramesData>();
	const project = useProjectStore();
	const store = useMainStore();

	const temporalElement = ref(Video.create());
	const { page } = usePageElement(temporalElement);
	const { position: positionPage } = usePage(page as Ref<Page>);

	const totalFrames = computed(() => framesPerPage.value.reduce((acc, total) => acc + total, 0));

	const fpsProject = computed(() => {
		if (!urlFrames.value) return 0;

		return Math.max(...Object.values(urlFrames.value).map((video) => video.fps));
	});

	const framesPerPage = computed(() =>
		project.pages.map((page) => {
			const videos = page.elementsAsArray().filter((el): el is Video => el instanceof Video);

			if (!videos.length) {
				return fpsProject.value * TimeTools.toSeconds(page.duration());
			}

			return Math.max(
				...videos.map((v) => {
					if (!urlFrames.value || !urlFrames.value[v.id]) return 0;

					const fpsVideo = urlFrames.value[v.id].fps;

					const ratio = fpsVideo / fpsProject.value;
					return urlFrames.value[v.id].urls.length / ratio;
				})
			);
		})
	);

	const preloadImageToShow = (url: string): Promise<string> =>
		new Promise((resolve) => {
			const img = new Image();
			img.src = url;
			if (img.complete) {
				resolve(img.src);
				return;
			}
			store.proccesingCachedElements++;
			img.onload = img.onerror = () => {
				store.proccesingCachedElements--;
				resolve(img.src);
			};
		});

	const getSrcToVideoFrame = async (video: Video, isVisible = true): Promise<string> => {
		if (!urlFrames.value) return '';
		temporalElement.value = video;
		const prevPagesFrame = framesPerPage.value.slice(0, positionPage.value).reduce((acc, frames) => acc + frames, 1);
		const start = window.renderData.renderConfig.framesInfo?.start || 0;
		const framesAvailableFromVideo = urlFrames.value[video.id].urls;
		const fpsVideo = urlFrames.value[video.id].fps;
		const ratio = fpsVideo / fpsProject.value;

		const willBeVisible =
			framesPerPage.value[positionPage.value] + prevPagesFrame > window.renderData.renderConfig.framesInfo?.start;

		// Calculamos cual es primer frame del vídeo que se va a mostrar en esta petición del render
		const minimunIndexToShow = Math.min(
			Math.floor((start - prevPagesFrame) * ratio),
			framesAvailableFromVideo.length - 1
		);

		// Si no está visible y lo va a estar en esta petición del render, devolvemos el primer frame a renderizar para evitar flasheos
		if (!isVisible) return willBeVisible ? urlFrames.value[video.id].urls[minimunIndexToShow] : '';

		// Nos quedamos con el número mas bajo de:
		// - frame correspondiente en la página * ratio (diferencia entre fps del vídeo y fps del proyecto)
		// - último frame disponible del vídeo
		const indexToShow = Math.min(
			Math.floor((frame.value - prevPagesFrame) * ratio),
			framesAvailableFromVideo.length - 1
		);

		if (indexToShow < 0) return '';

		// Mandamos al navegador a precargar las 10 urls siguientes
		framesAvailableFromVideo.slice(indexToShow, indexToShow + 10).forEach((url) => {
			const img = new Image();
			img.src = url;
		});

		// Nos aseguramos de que la imagen que se va a mostrar está ya precargada por el navegador
		// para evitar que se repinte dos veces (cambios de url en dom + render)
		return preloadImageToShow(framesAvailableFromVideo[Math.max(indexToShow, 0)]);
	};

	return { frame, urlFrames, getSrcToVideoFrame, framesPerPage, totalFrames, fpsProject, preloadImageToShow };
});
