<script lang="ts" setup>
import { ref } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { Font } from '@/Types/types';

const props = defineProps<{
	source: Font;
	canBeDeleted?: boolean;
}>();

const emit = defineEmits(['select', 'selectMultipleWeights', 'scroll', 'deleteFont']);

const showWeights = ref(false);

const NAMES = {
	100: 'Thin',
	200: 'Extra Light',
	300: 'Light',
	400: 'Regular',
	500: 'Medium',
	600: 'Semi Bold',
	700: 'Bold',
	800: 'Extra Bold',
	900: 'Black',
};

const getFontWeight = (weight: string) => {
	const w = parseFloat(weight);
	const variant = weight.replace(w.toString(), '').length > 0;

	return NAMES[w as keyof typeof NAMES] + (variant ? ' Italic' : '');
};

const selectFont = () => {
	if (props.source.weights.length > 1) {
		emit('selectMultipleWeights', {
			name: props.source.name,
			weight: props.source.weights,
			source: props.source,
		});
	} else {
		emit('select', {
			name: props.source.name,
			weight: props.source.weights[0],
			source: props.source,
		});
	}
};

const openDropdown = (ev: MouseEvent) => {
	showWeights.value = !showWeights.value;

	if (showWeights.value) {
		emit('scroll', ev.target);
	}
};

const selectWeight = (weight: string) => {
	emit('select', {
		name: props.source.name,
		weight: weight,
		source: props.source,
	});
};

const onDeleteFont = () => {
	emit('deleteFont');
};
</script>

<template>
	<div :data-test-font="source.name" class="flex items-center justify-around py-1">
		<button
			class="group flex w-full items-center justify-between px-2 hover:bg-gray-50"
			data-testid="font-button"
			:class="{
				'rounded-t bg-gray-50': showWeights,
				rounded: !showWeights,
			}"
		>
			<button
				class="w-full py-2 opacity-75 group-hover:opacity-100"
				:data-testid="`font-preview-${source.name}`"
				@click="selectFont"
			>
				<img :src="source.preview" class="h-3.5 object-contain" loading="lazy" />
			</button>

			<div v-if="source.weights.length > 1" :data-testid="`font-dropdown-${source.name}`" @click="openDropdown">
				<SvgIcon
					class="h-4 w-4 text-gray-600 group-hover:text-gray-800"
					:class="{ 'rotate-180': showWeights }"
					name="arrow"
				/>
			</div>
		</button>

		<button
			v-if="canBeDeleted"
			class="rounded p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-800"
			data-testid="delete-font-button"
			@click="onDeleteFont"
		>
			<SvgIcon name="trash" class="h-3 w-3" />
		</button>
	</div>

	<div v-if="showWeights" class="flex flex-col gap-1 rounded-b bg-gray-50 px-2 py-1">
		<button
			v-for="weight in source.weights"
			:key="weight"
			:data-testid="`font-weight-button-${getFontWeight(weight)}`"
			class="w-full py-0.5 text-left text-sm text-gray-700 hover:text-gray-900"
			@click="() => selectWeight(weight)"
		>
			<span
				:style="`font-weight: ${weight.replaceAll('i', '')}; font-style: ${
					weight.endsWith('i') ? 'italic' : 'normal'
				};`"
				>{{ getFontWeight(weight) }}
			</span>
		</button>
	</div>
</template>
