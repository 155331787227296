<script lang="ts" setup>
import { useI18n } from '@/i18n/useI18n';
const { trans } = useI18n();

defineProps<{ modal?: boolean; selectedTab: string; tabs: string[] }>();

const emit = defineEmits<{ (e: 'setTab', tab: string): void }>();

const onClickProvider = (prov: string) => emit('setTab', prov);
</script>

<template>
	<div data-testid="provider-images" class="mb-3 flex shrink-0 justify-between lg:gap-1">
		<button
			v-for="tab in tabs"
			:key="`provider-${tab}`"
			class="flex h-8 flex-1 items-center justify-center whitespace-nowrap rounded px-2 text-sm font-semibold lg:px-0"
			:class="{
				'bg-gray-700 text-gray-100': selectedTab === tab && !modal,
				'bg-gray-100/50 text-gray-700': selectedTab === tab && modal,
				'text-gray-200 hover:text-gray-100': selectedTab !== tab && !modal,
				'text-gray-700 hover:bg-gray-100/25': selectedTab !== tab && modal,
			}"
			@click="onClickProvider(tab)"
		>
			{{ trans(tab) }}
		</button>
	</div>
</template>
