<script setup lang="ts">
import ColorGroup from '@/color/components/ColorGroup.vue';
import { useProjectColors } from '@/color/composables/useProjectColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { EditPanels } from '@/Types/types';

const store = useMainStore();
const { isPhotoMode } = useEditorMode();
const { colors, colorSelected } = useProjectColors();
const { togglePanel } = usePanelManagement();
const onTogglePanel = (e: MouseEvent) => {
	togglePanel(EditPanels.GlobalColors);
	colorSelected.value =
		colors.value.find((c) => c.id === (e.target as HTMLElement)?.dataset.colorId) || colors.value[0];
};
</script>

<template>
	<button
		data-onboarding="project-colors"
		class="project-color-picker ml-2 flex items-center rounded-full lg:ml-0 lg:px-3 lg:py-1.5"
		:class="{
			'mr-1 border-r border-gray-600 pr-3': isPhotoMode && !!colors.length,
		}"
		@click="onTogglePanel"
	>
		<div class="relative">
			<SvgIcon name="palette" class="mr-3 h-5 w-5 text-gray-100" />
			<span
				v-if="store.colorPalettes.length > 1"
				class="absolute -top-2 right-0 flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-600 bg-blue-500 text-xs font-bold text-white slidesgo:bg-purple-400"
			>
				{{ store.colorPalettes.length }}
			</span>
		</div>
		<ColorGroup
			class="h-6 lg:h-7"
			picker-class="w-6 lg:w-7 "
			:max="3"
			:colors="colors"
			:hide-alpha="true"
			:prevent-change="true"
		/>
	</button>
</template>
<style lang="sass">
.project-color-picker .color-group
	@apply gap-0

	& > div
		@apply -mr-2 last:mr-0
</style>
