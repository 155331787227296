<script lang="ts" setup>
import { computed, Ref, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import FlipMenu from '@/elements/element/components/menus/FlipMenu.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import CropButton from '@/elements/medias/crop/components/buttons/CropButton.vue';
import FilterButtonToolbar from '@/elements/medias/filter/components/buttons/FilterButtonToolbar.vue';
import ElementFilter from '@/elements/medias/filter/components/menus/ElementFilter.vue';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import ForegroundOpacityToolbar from '@/elements/medias/images/foreground/components/buttons/ForegroundOpacityToolbar.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import RemoveBgButtonPhotoMode from '@/elements/medias/images/image/components/buttons/RemoveBgButtonPhotoMode.vue';
import ReplaceImageButton from '@/elements/medias/images/image/components/buttons/ReplaceImageButton.vue';
import SeparateLayersButton from '@/elements/medias/images/image/components/buttons/SeparateLayersButton.vue';
import PhotoImageLayerSelector from '@/elements/medias/images/image/components/menus/PhotoImageLayerSelector.vue';
import RotateMenu from '@/elements/medias/images/image/components/menus/RotateMenu.vue';
import EditImagePreset from '@/elements/medias/images/image/components/panels/edit/EditImagePreset.vue';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useSelection } from '@/interactions/composables/useSelection';

const store = useMainStore();
const { selection, selectionOrdered } = useSelection();

const { isMobile } = useDeviceInfo();
const { isPhotoMode } = useEditorMode();

const photoImageLayerSelected = ref<'background' | 'foreground' | 'presets'>('background');
const { photoModeImage } = usePhotoMode();
const { hasForeground, foreground } = useLayersImage(photoModeImage as Ref<Image>);
const photoImageLayerSelectedElement = computed(() => {
	if (!photoModeImage.value) return undefined;

	if (hasForeground.value && photoImageLayerSelected.value === 'foreground') {
		return foreground.value;
	}
	return photoModeImage.value;
});
const hasPhotoModeImageSeparateLayers = computed(() => {
	return isPhotoMode.value && hasForeground.value;
});

const isMobilePhotoModeFiltersVisible = computed(
	() => isMobile.value && photoModeImage.value?.id === selection.value[0]?.id
);

const handlerPhotoImageLayer = (panelSelected: 'background' | 'foreground' | 'presets') => {
	photoImageLayerSelected.value = panelSelected;
};
</script>

<template>
	<div class="flex items-center gap-1">
		<template v-if="photoImageLayerSelectedElement instanceof Image">
			<SeparateLayersButton :element="(photoImageLayerSelectedElement as Image)" />
			<RemoveBgButtonPhotoMode :element="(photoImageLayerSelectedElement as Image)" />
			<CropButton :element="(photoImageLayerSelectedElement as Image)" />
			<ReplaceImageButton :element="(photoImageLayerSelectedElement as Image)" />
			<FlipMenu :element="(photoImageLayerSelectedElement as Image)" />
			<RotateMenu :element="(photoImageLayerSelectedElement as Image)" />
		</template>
		<template v-if="photoImageLayerSelectedElement instanceof ForegroundImage">
			<SeparateLayersButton :element="(photoImageLayerSelectedElement as ForegroundImage)" />
			<FilterButtonToolbar :element="(photoImageLayerSelectedElement as ForegroundImage)" />
			<OrderToolbar :element="(photoImageLayerSelectedElement as ForegroundImage)" />
			<ForegroundOpacityToolbar :element="(photoImageLayerSelectedElement as ForegroundImage)" />
		</template>
	</div>

	<template v-if="photoImageLayerSelected === 'presets'">
		<EditImagePreset
			:element="(photoImageLayerSelectedElement as Image)"
			class="absolute left-0 top-[2.9rem] z-20 w-full bg-gray-700"
			@close="handlerPhotoImageLayer('background')"
		/>
	</template>
	<template v-else>
		<PhotoImageLayerSelector
			v-if="hasPhotoModeImageSeparateLayers"
			:element="(photoImageLayerSelectedElement as BaseImage)"
			class="absolute left-0 top-[2.9rem] z-20 w-full bg-gray-700"
			@change="handlerPhotoImageLayer($event)"
		/>
	</template>
	<Teleport v-if="isMobilePhotoModeFiltersVisible" to="#container-editor-base">
		<div class="fixed bottom-14 left-0 w-full bg-gray-800 pb-2 pt-4">
			<ElementFilter v-if="photoImageLayerSelectedElement" :element="(photoImageLayerSelectedElement as BaseImage)" />
		</div>
	</Teleport>
</template>

<style scoped></style>
