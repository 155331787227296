<script lang="ts" setup>
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

const store = useMainStore();

const props = defineProps<{ element: Image | Video }>();

const onClickCrop = () => {
	store.croppingId = props.element.id;

	GAnalytics.trackDebounceEditBarGA4({
		category: props.element instanceof Video ? 'video' : 'photo',
		link_text: 'crop',
	});
};
</script>

<template>
	<button
		:id="`crop-${element.type}-toolbar`"
		:tooltip="trans('Crop')"
		tooltip-position="top"
		data-testid="crop-btn"
		data-button-importance="normal"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:h-10 lg:w-auto lg:flex-row lg:justify-center lg:px-2"
		@click="onClickCrop"
	>
		<SvgIcon v-if="element.mask" name="adjust" class="h-4 w-4 lg:scale-90" />
		<SvgIcon v-else name="crop" class="h-4 w-4" />
		<span class="text-2xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{
			element.mask ? trans('Adjust') : trans('Crop')
		}}</span>
	</button>
</template>
