<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useImagePresets } from '@/elements/medias/images/foreground/composables/useImagePresets';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');
const { separateLayers, removingBackground, hasForeground } = useLayersImage(element);
const { removeBg, recoverBg } = useImagePresets(element);
const { trans } = useI18n();

const toast = useToast();

const removeBackground = async () => {
	try {
		if (!hasForeground.value) {
			await separateLayers();
		}
		removeBg();
	} catch (error) {
		toast.error(trans('There was an error while removing the background, try with another image'));
	}
};

const toggleBackground = async () => {
	if (hasForeground.value && element.value.opacity === 0) {
		recoverBg();
		return;
	}

	await removeBackground();
};
</script>

<template>
	<button
		data-button-importance="important"
		class="flex h-9 w-9 items-center justify-center rounded text-gray-100 focus:outline-none hover:text-white lg:w-full lg:bg-gray-700 lg:hover:bg-gray-600"
		@click="toggleBackground"
	>
		<div class="flex w-full items-center justify-center">
			<SvgIcon v-if="!removingBackground" name="remove-bg" class="h-5 w-5 shrink-0 scale-90 lg:h-4 lg:w-4" />
			<SvgIcon v-else name="spinner" class="animate-reverse h-5 w-5 shrink-0 animate-spin lg:h-4 lg:w-4" />
			<span class="mt-0 ml-2 hidden text-sm lg:block" data-button-label>{{
				hasForeground && element.opacity === 0 ? trans('Recover Bg') : trans('Remove Bg')
			}}</span>
		</div>
	</button>
</template>
