<script setup lang="ts">
import { computed, toRef } from 'vue';

import GradientsRenderer from '@/color/components/GradientsRenderer.vue';
import { useStorysetColors } from '@/color/composables/useStorysetColors';
import Storyset from '@/elements/storyset/classes/Storyset';

const props = defineProps<{
	element: Storyset;
	forSmallPreview?: boolean;
	previewName?: string;
	scale: number;
	smartSelection?: boolean;
	interactive: boolean;
}>();

const element = toRef(props, 'element');

const { gradientColors } = useStorysetColors(element);
</script>

<template>
	<svg
		data-testid="storyset-svg"
		class="original"
		:viewBox="element.viewbox"
		:width="element.size.width"
		:height="element.size.height"
		:style="{
			'--main-storyset-color': element.mainColor.toElementReference(previewName),
			opacity: element.opacity,
		}"
		preserveAspectRatio="none"
		v-html="element.content"
	></svg>
	<GradientsRenderer :preview-name="previewName" :gradients="gradientColors" />
</template>
