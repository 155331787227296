// Bugsnag
import Bugsnag from '@bugsnag/js';
import { computed, nextTick, Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useElementOrderOrchestrator } from '@/elements/element/composables/useElementOrderOrchestrator';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { usePageElement } from '@/elements/element/composables/usePageElement';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useCroppeableTransform } from '@/elements/medias/crop/composables/useCroppeableTransform';
import { BackgroundableElement } from '@/elements/medias/crop/types/croppeable.type';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProjectStore } from '@/project/stores/project';

export const useBackgroundableElement = (image: Ref<BackgroundableElement>) => {
	const { selectionId, setSelection, clearSelection } = useSelection();

	const { isPhotoMode } = useEditorMode();
	const { getElementById } = useProjectStore();

	const { fitAndCenterInArtboard } = useCroppeableTransform(image);
	const { removeFromGroup } = useGroup(image);
	const { isBackground } = useIsBackground(image);
	const { page } = usePageElement(image);
	const { moveToBack } = useElementOrderOrchestrator(image).value;

	const canBeSetAsBackground = computed(() => !image.value.locked && !page.value?.backgroundImageId);

	const setElementAsBackground = async () => {
		if (!canBeSetAsBackground.value) throw new Error('This image can not be set as background');

		// Guardamos el id de la imagen para restaurarlo posteriormente en caso de perder la referencia
		const lastImageId = image.value.id;

		// Clear rotation, remove mask
		image.value.setRotation(0);
		if ('mask' in image.value) {
			image.value.setMask(null);
		}

		fitAndCenterInArtboard();

		// Set image as locked to disable transforms (translate|rotate|scale)
		image.value.setLocked(true);

		// reset group (background cannot group)
		removeFromGroup();
		clearSelection();
		await nextTick();
		// When initializing in photo mode, set the selectionId to enable the filters menu
		if (!isPhotoMode.value) {
			// Restauramos la imagen que hemos pasado como background
			if (lastImageId) {
				const image = getElementById(lastImageId);

				if (image) {
					await setSelection(image);
				}
			}
		} else {
			selectionId.value = [image.value.id];
		}

		// Set as first in Page.elements[]
		moveToBack();

		// Set as background in Page
		page.value!.backgroundImageId = image.value.id;
		GAnalytics.track('click', 'Button', 'background-image', null);
		Bugsnag.leaveBreadcrumb(`image-${image.value.id} to background`);
	};

	const removeElementAsBackground = () => {
		page.value!.backgroundImageId = null;

		image.value.locked = false;

		Bugsnag.leaveBreadcrumb(`Remove image-${image.value.id} to background`);
	};

	return {
		isBackground,
		canBeSetAsBackground,
		setElementAsBackground,
		removeElementAsBackground,
	};
};
