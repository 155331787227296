import { MaybeRef, until, useDebounceFn, useEventListener, useTimeoutFn } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { ComputedRef, nextTick, Ref, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useToast } from '@/common/composables/useToast';
import { useMainStore } from '@/editor/stores/store';
import { Box } from '@/elements/box/classes/Box';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import Line from '@/elements/line/classes/Line';
import Image from '@/elements/medias/images/image/classes/Image';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import Mask from '@/elements/medias/mask/classes/Mask';
import { useMaskeableElement } from '@/elements/medias/mask/composables/useMaskeableElement';
import { MaskableElement } from '@/elements/medias/mask/types/maskable.type';
import NotPossibleInPhotoModeException from '@/elements/medias/replace/Exception/NotPossibleInPhotoModeException';
import { useReplaceElement } from '@/elements/medias/replace/useReplaceElement';
import { Video } from '@/elements/medias/video/classes/Video';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import Storyset from '@/elements/storyset/classes/Storyset';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProject } from '@/project/composables/useProject';
import { ImageApi, MaskApi } from '@/Types/apiClient';
import { DraggableItemData, InsertableApiType, MediaHover, Panels, Position, Size } from '@/Types/types';

const { isFirefox } = useDeviceInfo();

/**
 * Maneja los eventos de drop de archivos para insertar elementos
 * @param page
 * @param container
 */
export const useHandleDraggables = (page: Ref<Page>, container: MaybeRef<HTMLElement>) => {
	const store = useMainStore();
	const toast = useToast();

	const hoveredMedia = ref<MediaHover | null>(null);
	const draggingFile = ref<boolean>(false);
	const temporalRef: Ref<Image | Line | Shape | Storyset | Video | Box> = ref(Image.create()) as Ref<
		Image | Line | Shape | Storyset | Video | Box
	>;
	const positionMouseOnDrop = ref<Position>({ x: 0, y: 0 });

	const usingTransformOrchestrator: ComputedRef = useElementTransformOrchestrator(
		temporalRef as Ref<Image | Line | Shape | Storyset | Box>
	);

	const { replaceElement } = useReplaceElement(temporalRef as Ref<Video | Image>);
	const { applyMask } = useMaskeableElement(temporalRef as Ref<MaskableElement>);
	const { getElementFromDom } = usePage(page);
	const { getPageElementUnderMouse, replacePage } = useProject();
	const { uploadFromLocal, onFetchResponse, actualUpload } = useUserImageProvider();

	interface PrevImageData {
		crop:
			| {
					position: Position;
					size: Size;
			  }
			| undefined;
		mask: Mask | undefined;
		position: Position | undefined;
		size: Size | undefined;
	}

	const prevImageData: PrevImageData = {
		crop: undefined,
		mask: undefined,
		position: undefined,
		size: undefined,
	};

	const {
		addInsertableFlaticon,
		addInsertableSticker,
		addInsertableStoryset,
		addInsertableImageMask,
		addInsertableBasicShape,
		addInsertableImage,
		addInsertableLine,
		addInsertableVideo,
	} = useAddInsertableElement();

	onFetchResponse(async () => {
		store.draggingItem = {
			data: actualUpload.value,
			type: Panels.photos,
		};
		// Si es un video tenemos que esperar a tener el blob disponible
		if (actualUpload.value.type === 'video') {
			await until(store.uploads).toMatch((el) => el.has(actualUpload.value.id));
		}
		await nextTick();
		await insertCommon();
	});

	// Inserta o reemplaza el elemento que se esta arrastrando
	const insertCommon = async () => {
		if (!store.draggingItem) return;

		const insertableData = store.draggingItem.data as DraggableItemData;

		let inserted: Image | Line | Shape | Storyset | Video | Box | undefined;

		if (insertableData.type === InsertableApiType.Flaticon) {
			inserted = await addInsertableFlaticon(insertableData.svg);
		}

		if (insertableData.type === InsertableApiType.Sticker) {
			inserted = await addInsertableSticker(insertableData.svg);
		}

		if (insertableData.type === InsertableApiType.Storyset) {
			inserted = await addInsertableStoryset(insertableData.src);
		}

		if (insertableData.type === InsertableApiType.BasicShape) {
			inserted = await addInsertableBasicShape(insertableData.svg);
		}

		if (insertableData.type === InsertableApiType.SVG) {
			inserted = await addInsertableBasicShape(insertableData.url);
		}

		if (insertableData.type === InsertableApiType.ImageMask) {
			// Si soltamos una máscara sobre una imagen o vídeo la aplicamos
			if (hoveredMedia.value && !hoveredMedia.value.target.locked) {
				temporalRef.value = hoveredMedia.value.target as Image;
				await applyMask(hoveredMedia.value.data as MaskApi);
				return null;
			}

			inserted = await addInsertableImageMask(insertableData as MaskApi);
		}

		if (insertableData.type === InsertableApiType.LineAndArrow) {
			inserted = await addInsertableLine(insertableData.svg);
		}

		if (insertableData.type === InsertableApiType.Image || insertableData.type === InsertableApiType.Video) {
			// Si soltamos una imagen sobre otra la reemplazamos
			if (hoveredMedia.value && !hoveredMedia.value.target.locked) {
				temporalRef.value = hoveredMedia.value.target;
				try {
					await replaceElement(insertableData);
				} catch (error) {
					if (error instanceof NotPossibleInPhotoModeException) {
						toast.warning(error.message);
						return;
					}
					toast.warning('Something went wrong');
				}
				return null;
			}
			if (insertableData.type === InsertableApiType.Image) {
				inserted = await addInsertableImage(insertableData);
			}

			if (insertableData.type === InsertableApiType.Video) {
				inserted = await addInsertableVideo(insertableData);
			}
		}

		if (!inserted) throw new Error('No element added added to page!');

		// Calculamos la posición del cursor en la página al hacer drop
		const pageUnderCursor = getPageElementUnderMouse(positionMouseOnDrop.value);
		if (!pageUnderCursor) return;

		const pageUnderCursorEl = pageUnderCursor.domNode();
		if (!pageUnderCursorEl) throw new Error('No page HTML element to drop in!');

		const { left, top } = pageUnderCursorEl.getBoundingClientRect();

		const position = {
			x: positionMouseOnDrop.value.x - left + window.pageXOffset,
			y: positionMouseOnDrop.value.y - top + window.pageYOffset,
		};

		// Posicionamos el elemento soltado en la posición del cursor
		temporalRef.value = inserted;
		usingTransformOrchestrator.value.setupInPage({
			x: position.x / store.scale,
			y: position.y / store.scale,
		});

		store.draggingItem = null;

		positionMouseOnDrop.value = { x: 0, y: 0 };
	};

	const setHoveredMedia = (e: DragEvent) => {
		if (!store.draggingItem) return;

		const element = getHoveredElement(e);
		if (!element) return;
		if (element.locked) return;
		if (element.id === hoveredMedia.value?.target.id) return;
		if (!(element instanceof Image) && !(element instanceof Video)) return;
		if (store.draggingItem.data.type === InsertableApiType.SVG) return;

		// Media over Media
		const isMediaFromPanelOverMedia = ['image', 'video'].includes(store.draggingItem.data.type);

		if (isMediaFromPanelOverMedia) {
			hoveredMedia.value = { target: element, data: store.draggingItem.data as ImageApi };
			return;
		}

		// Mask over Image
		const isMaskFromPanelOverMedia =
			!element.mask?.isPlaceholder && store.draggingItem.data.type === InsertableApiType.ImageMask;

		if (isMaskFromPanelOverMedia) {
			hoveredMedia.value = { target: element, data: store.draggingItem.data as MaskApi };
			return;
		}
	};

	// para que el drop funcione hay que cancelar el enter y el over
	useEventListener(container, 'dragenter', (e: DragEvent) => {
		if (store.disableDraggable) return;
		e.preventDefault();

		if (e.dataTransfer) {
			e.dataTransfer.dropEffect = 'copy';
		}

		// Si estabamos arrastrando un DraggableItem
		if (store.draggingItem) return;

		// Si no hay draggingItem, estamos arrastrando un archivo desde el ordenador
		draggingFile.value = true;

		// Hay casos donde no podemos escuchar el evento de que ya no estamos arrastrando el archivo,
		// asi que lo cancelamos pasados un par de segundos
		useTimeoutFn(() => (draggingFile.value = false), 2000);
	});

	// si salimos definitivamente del canvas con el dragleave (se lanza tambien al hacer leave entre elementos)
	useEventListener(container, 'dragleave', async (e: DragEvent) => {
		if (store.disableDraggable) return;
		const underMouse = document.elementFromPoint(e.clientX, e.clientY);
		if (underMouse?.closest('.editable, .target')) return;

		const target = getCurrentTarget(e);
		if (!target) return;

		// Restauramos la imagen original si salimos del elemento Image
		const node = target.closest('.editable, .target') as HTMLElement;

		if (node) {
			const element = getElementFromDom(node);
			const isDraggingMedia = store.draggingItem && ['image', 'video'].includes(store.draggingItem.data.type);
			const isDraggingMask = store.draggingItem && store.draggingItem.data.type === InsertableApiType.ImageMask;
			const isHoveringMedia =
				hoveredMedia.value &&
				(hoveredMedia.value.target instanceof Image || hoveredMedia.value.target instanceof Video);

			if ((isDraggingMedia || isDraggingMask) && isHoveringMedia && hoveredMedia.value) {
				if (node.querySelector('[data-replaceable]')) {
					const leavingSameMedia =
						(element instanceof Image || element instanceof Video) && node.id.includes(hoveredMedia.value.target.id);
					const restoreMask = hoveredMedia.value?.target.mask?.id === store.draggingItem?.data.id;

					if (leavingSameMedia && restoreMask) {
						if (prevImageData.crop) hoveredMedia.value.target.crop = prevImageData.crop;
						if (prevImageData.position) hoveredMedia.value.target.position = prevImageData.position;
						if (prevImageData.size) hoveredMedia.value.target.size = prevImageData.size;

						if (prevImageData.mask) {
							if (hoveredMedia.value) hoveredMedia.value.target.mask = prevImageData.mask;
						} else {
							hoveredMedia.value.target.setMask(null);
						}
					}
				}

				hoveredMedia.value = null;
			}
		}

		if (target.classList.contains('interactive-canvas') && hoveredMedia.value) {
			hoveredMedia.value = null;
		}

		if (target.closest('.interactive-canvas')) {
			return;
		}

		draggingFile.value = false;
	});

	// En cada evento del over, seteamos el dropeffect para que salga el simbolo
	// sobre el raton
	useEventListener(container, 'dragover', async (e: DragEvent) => {
		if (store.disableDraggable) return;
		e.preventDefault();

		if (e.dataTransfer) {
			e.dataTransfer.dropEffect = 'copy';
		}

		if (
			!hoveredMedia.value &&
			store.draggingItem &&
			[InsertableApiType.Image, InsertableApiType.Video, InsertableApiType.ImageMask].includes(
				store.draggingItem.data.type
			)
		) {
			setHoveredMedia(e);
			await previewMaskOverImage();
		}
	});

	// Al soltar lo que sea, tratamos de insertarlo
	useEventListener(container, 'drop', async (e: DragEvent) => {
		if (store.disableDraggable) return;
		e.preventDefault();

		if (store.draggingPage !== null) {
			replacePage(store.draggingPage as Page, store.activePage as Page);
			store.draggingPage = null;
			return;
		}

		const target = getCurrentTarget(e);
		if (!target || target.classList.contains('interactive-canvas')) {
			draggingFile.value = false;
			hoveredMedia.value = null;
			prevImageData.crop = undefined;
			prevImageData.mask = undefined;
			prevImageData.position = undefined;
			prevImageData.size = undefined;
			return;
		}

		positionMouseOnDrop.value = { x: e.clientX, y: e.clientY };

		if (store.draggingItem) {
			const targetPage = e.currentTarget;

			// Si el elemento sobre el que arrastramos existe, obtenemos su page id y si no coincide actualizamos la store
			if (targetPage instanceof HTMLElement) {
				const targetPageId = targetPage.getAttribute('data-page-id');

				if (targetPageId && targetPageId !== store.activePageId) {
					store.activePageId = targetPageId;
				}
			}

			insertCommon();
		} else {
			uploadFromLocal(e);
		}

		GAnalytics.track('add-image', 'Button', 'drag-and-drop', null);

		draggingFile.value = false;
		hoveredMedia.value = null;
		prevImageData.crop = undefined;
		prevImageData.mask = undefined;
		prevImageData.position = undefined;
		prevImageData.size = undefined;
	});

	const previewMaskOverImage = useDebounceFn(async () => {
		if (
			!hoveredMedia.value ||
			hoveredMedia.value.target.locked ||
			store.draggingItem?.type !== InsertableApiType.ImageMask
		)
			return;
		temporalRef.value = hoveredMedia.value.target;
		prevImageData.crop = temporalRef.value.crop;
		prevImageData.position = temporalRef.value.position;
		prevImageData.size = temporalRef.value.size;
		prevImageData.mask = temporalRef.value.mask ? cloneDeep(temporalRef.value.mask) : undefined;
		await applyMask(hoveredMedia.value.data as MaskApi);
	}, 500);

	const getHoveredElement = (e: DragEvent) => {
		const target = getCurrentTarget(e);
		if (!target) return;

		const node = target.closest('.editable, .target') as HTMLElement;
		if (!node) return;

		return getElementFromDom(node);
	};

	return {
		draggingFile,
		hoveredMedia,
	};
};

const getCurrentTarget = (e: DragEvent): HTMLElement | null => {
	// @ts-ignore
	if (isFirefox) {
		// @ts-ignore
		return e.target;
		// @ts-ignore
	} else if (e.toElement) {
		// @ts-ignore
		return e.toElement;
	} else if (e.currentTarget) {
		// @ts-ignore
		return e.currentTarget;
	} else if (e.srcElement) {
		// @ts-ignore
		return e.srcElement;
	} else {
		return null;
	}
};
