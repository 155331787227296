import { defineStore } from 'pinia';

import { Pack, Prompt } from '@/Types/types';

export interface FocusedImage {
	uuid: string;
	media: string;
	upscaled: boolean;
	in_use: boolean;
}

export interface Prices {
	upscale: number;
	image: number;
	packs: Pack[];
}

export interface AiEditorState {
	amount: number;
	prompt: Prompt;
	dailyImages: number;
	remainingImages: number;
	credits: number | null;
	modal: boolean;
	prices: Prices;
}

export const useAiEditorStore = defineStore('aieditor', {
	state: (): AiEditorState => ({
		amount: 4,
		dailyImages: 0,
		remainingImages: 0,
		credits: null,
		prompt: {
			query: '',
			modifiers: [],
			aspectRatio: 'square',
		},
		modal: false,
		prices: undefined,
	}),
	getters: {},

	actions: {},
});
