<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { Fn } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Dropdown from '@/common/components/Dropdown.vue';
import NumberInput from '@/common/components/NumberInput.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { Text } from '@/elements/texts/text/classes/Text';
import AlignmentMenu from '@/elements/texts/text/components/buttons/AlignmentMenu.vue';
import TextTransformMenu from '@/elements/texts/text/components/buttons/TextTransformMenu.vue';
import FontPicker from '@/elements/texts/text/components/menus/FontPicker.vue';
import WeightPicker from '@/elements/texts/text/components/menus/WeightPicker.vue';
import { useFontPicker } from '@/elements/texts/text/composables/useFontPicker';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';
import { Font, SelectedFont } from '@/Types/types';

const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const { textEditing } = useTextEditing();
const {
	lineHeightLabel,
	fontSizeScaled,
	finalFontFamily,
	fontWeight,
	italic,
	fontVariants,
	updateFontSize,
	updateFontFamily,
	updateLineHeight,
	updateFontWeight,
	updateFontStyle,
	rescaleText,
	updateTextAlign,
} = useTextStyles(element);

const { showSpinner, finalFont } = useFontPicker(element);

const { breadScrumbWithDebounce } = useBugsnag(element);
const { trans } = useI18n();

const inputFontSizeValue = ref<number[]>(fontSizeScaled.value);
const inputLineHeihtValue = ref<number[]>(lineHeightLabel.value);

const computedFontWeight = computed(() => `${fontWeight.value[0]}${italic.value[0] === 'italic' ? 'i' : ''}`);

const onUpdate = (val: string) => {
	Bugsnag.leaveBreadcrumb(`Set font style to ${props.element.id}: ${val}`);
	if (val.includes('i')) {
		updateFontWeight(val.split('i')[0]);
		updateFontStyle(true);
	} else {
		updateFontWeight(val);
		updateFontStyle(false);
	}
};

const onUpdateLineHeight = (lineHeight: number | 'plus' | 'minus') => {
	// checkeamos si el elemento tiene un line height de 0.1 y si es así no le dejamos bajar más
	if (element.value.lineHeight === 0.1 && lineHeight === 'minus') return;
	updateLineHeight(lineHeight);
	breadScrumbWithDebounce('lineHeight');
};

const onUpdateFontSize = (fontSize: number | 'plus' | 'minus') => {
	if (element.value.scale !== 1) {
		rescaleText();
	}

	updateFontSize(fontSize);
	breadScrumbWithDebounce('fontSize');
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};

const trackFontFamily = async (font: Font) => {
	await updateFontFamily(font);
	GAnalytics.track('click', 'Button', `change-font-family`, null);
};

const isEditingText = computed(() => {
	return !!textEditing.value;
});

const lineHeightOptions = computed(() => [
	{ label: 1, value: 1 },
	{ label: '1,2', value: 1.2 },
	{ label: '1,5', value: 1.5 },
	{ label: 2, value: 2 },
]);

const checkIfDropdownShouldOpen = (e: Event, openDrowdown: Fn) => {
	if (e.target instanceof HTMLSpanElement && e.target.classList.contains('cursor-pointer')) {
		return;
	}
	openDrowdown();
};

const updateFont = async (selectedFont: SelectedFont) => {
	await trackFontFamily(selectedFont.font);
	await updateFontWeight(selectedFont.weight || '400');
	await updateFontStyle(selectedFont.style || false);
};
</script>

<template>
	<div class="mb-2">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-800">
			{{ trans('Fonts') }}
		</h4>
		<div class="mb-2 flex gap-2">
			<FontPicker :key="'toolbar-' + finalFontFamily" :element="element" @updateFont="updateFont">
				<template #default="{ toggle }">
					<button
						id="fontPickerListButton"
						data-testid="font"
						class="group relative flex h-10 w-full cursor-pointer items-center rounded bg-gray-900 pl-3 pr-10 text-sm text-gray-100 focus:outline-none hover:text-gray-700 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500 lg:w-36 lg:hover:text-white"
						@click="toggle"
					>
						<img
							v-if="!showSpinner && finalFont?.preview"
							:src="finalFont.preview"
							class="h-4 w-full object-contain object-left opacity-80 invert group-hover:opacity-100"
							loading="lazy"
						/>
						<span
							v-else-if="!showSpinner && !finalFont"
							class="pointer-event-none w-16 flex-1 truncate text-left text-sm text-gray-100 lg:w-auto"
						>
							{{ trans('Mixed Fonts') }}
						</span>
						<SvgIcon name="arrow" class="absolute right-3 top-3 h-4 w-4 fill-current" />
					</button>
				</template>
			</FontPicker>

			<WeightPicker :options="fontVariants" :selected="computedFontWeight" @update="onUpdate" />
		</div>

		<div class="grid grid-cols-4 gap-2">
			<div class="flex flex-col">
				<NumberInput
					class="h-9 w-full text-sm"
					:class="{ 'input-notAllowed': isEditingText }"
					data-testid="input-fontsize"
					:min="1"
					:name="'input-fontsize'"
					:placeholder="'Mix'"
					:value="inputFontSizeValue"
					@track-step="trackStepEvent('text-font-size')"
					@track-typing="trackTypingEvent('text-font-size')"
					@update="onUpdateFontSize"
					@update-array-one-step="onUpdateFontSize"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Size') }}</label>
			</div>
			<div class="flex flex-col">
				<Dropdown
					:selected="element.lineHeight"
					:options="lineHeightOptions"
					@update="(ev) => onUpdateLineHeight(ev.value)"
				>
					<template #default="{ onToggleVisibility }">
						<NumberInput
							class="h-9 w-full text-sm"
							:class="{
								'input-notAllowed': isEditingText,
								'disable-minus': element.lineHeight === 0.1,
							}"
							data-testid="input-lineheight"
							:min="0.1"
							:name="'input-lineheight'"
							:placeholder="'Mix'"
							:step="0.1"
							:value="inputLineHeihtValue"
							@click.stop="checkIfDropdownShouldOpen($event, onToggleVisibility)"
							@track-step="trackStepEvent('text-line-height')"
							@track-typing="trackTypingEvent('text-line-height')"
							@update="onUpdateLineHeight"
							@update-array-one-step="onUpdateLineHeight"
						/>
					</template>
				</Dropdown>
				<label class="mt-1 pl-1 text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Line height') }}</label>
			</div>
			<AlignmentMenu :element="element" @update="updateTextAlign" />
			<TextTransformMenu :element="element" />
		</div>
	</div>
</template>

<style lang="scss">
.input-notAllowed input {
	@apply cursor-not-allowed text-gray-600 placeholder-gray-600;
}

.disable-minus > div > span:last-child {
	opacity: 0.3;
	@apply cursor-not-allowed text-gray-600 placeholder-gray-600;
}
</style>
