<script setup lang="ts">
import { useFps, useMemory } from '@vueuse/core';

import { useAdminActions } from '@/admin/composables/useAdminActions';
import { useProjectStore } from '@/project/stores/project';
import MathTools from '@/utils/classes/MathTools';

const { projectData } = useAdminActions();
const project = useProjectStore();
const fps = useFps();
const { isSupported, memory } = useMemory();
</script>

<template>
	<div v-if="isSupported && memory" class="mt-2 flex flex-col rounded bg-black bg-opacity-30 p-3 font-mono text-white">
		<h3 class="text-md py-2 font-bold">Performance</h3>
		<div>
			<span class="opacity-70">Fps: </span> <span class="mb-1 text-green-500">{{ fps }}</span>
		</div>

		<div>
			<span class="opacity-70">Used: </span>
			<span class="mb-1 text-green-500">{{ MathTools.formatBytes(memory.usedJSHeapSize, 2) }}</span>
		</div>

		<div>
			<span class="opacity-70">Allocated: </span>
			<span class="mb-1 text-green-500">{{ MathTools.formatBytes(memory.totalJSHeapSize, 2) }}</span>
		</div>

		<div>
			<span class="opacity-70">Limit: </span>
			<span class="mb-1 text-green-500">{{ MathTools.formatBytes(memory.jsHeapSizeLimit, 2) }}</span>
		</div>

		<div>
			<span class="opacity-70">Shape nodes: </span>
			<span class="mb-1 text-green-500">{{ projectData.nodesLength }}</span>
		</div>

		<div>
			<span class="opacity-70">Size: </span>
			<span class="mb-1 text-green-500">{{ MathTools.formatBytes(projectData.documentSize, 2) }}</span>
		</div>

		<div>
			<span class="opacity-70">Scale Project: </span>
			<span class="mb-1 text-green-500">{{ project.scale.toFixed(2) }}</span>
		</div>
	</div>
</template>
