import { useDebounceFn } from '@vueuse/shared';
import { cloneDeep } from 'lodash-es';
import { computed, Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useVariantsColors } from '@/color/composables/useVariantsColors';
import { Box } from '@/elements/box/classes/Box';
import { Color } from '@/Types/colorsTypes';

export const useBoxColors = (element: Ref<Box>) => {
	// Computeds
	const colors = computed(() => {
		const boxColors: Color[] = [];
		boxColors.push(element.value.background);
		if (element.value.border.size > 0) {
			boxColors.push(element.value.border.color);
		}
		return boxColors;
	});

	// Using composables
	const { isApplyingVariantColors } = useVariantsColors();

	const trackAnalyticsDebounced = useDebounceFn((color1: Color, color2: Color) => {
		trackChangeColor(color1, color2);
	}, 300);
	const isBorderGradient = computed(() => element.value.border.color.isGradient());
	// Methods
	const updateColor = (oldColor: Color, newColor: Color, type?: 'changeBackground' | 'changeStroke'): Box => {
		const cloneNewColor = cloneDeep(newColor);
		cloneNewColor.id = oldColor.id;
		// Si no se está actualizando el la variante de color del template trackeamos el color
		if (!isApplyingVariantColors.value) {
			trackAnalyticsDebounced(oldColor, newColor);
		}
		const isUpdatingGradient =
			oldColor instanceof GradientColor &&
			cloneNewColor instanceof GradientColor &&
			oldColor.id === cloneNewColor.id &&
			oldColor.rotation === cloneNewColor.rotation;

		if (isUpdatingGradient) {
			const gradientToChange = colors.value.find(
				(gradient) => gradient.toCssString() === oldColor.toCssString()
			) as GradientColor | null;

			if (gradientToChange && newColor instanceof GradientColor) {
				(gradientToChange as GradientColor).stops = [...cloneNewColor.stops];
				gradientToChange.type = newColor.type;
			}
		} else {
			if (element.value.background.toCssString() === oldColor.toCssString() && type !== 'changeStroke') {
				element.value.updateBackgroundColor(cloneNewColor);
			}

			if (element.value.border.color.toCssString() === oldColor.toCssString() && type !== 'changeBackground') {
				element.value.updateStrokeColor(cloneNewColor);
			}
		}
		return element.value;
	};

	const trackChangeColor = (color1: Color, color2: Color) => {
		// En caso de cambio de solido a solido
		if (color1 instanceof SolidColor && color2 instanceof SolidColor) {
			if (color1.r === color2.r && color1.g === color2.g && color1.b === color2.b && color1.a !== color2.a) {
				GAnalytics.track('click', 'Button', 'change-element-opacity', null);
			} else if (color1.r !== color2.r || color1.g !== color2.g || color1.b !== color2.b) {
				GAnalytics.track('click', 'Button', 'change-element-color', null);
			}
		}

		// En caso de cambio de solido a Gradiente
		if (
			(color1 instanceof SolidColor && color2 instanceof GradientColor) ||
			(color1 instanceof GradientColor && color2 instanceof SolidColor)
		) {
			GAnalytics.track('click', 'Button', 'change-element-color', null);
		}

		// En caso de cambio de gradiente a Gradiente
		if (color1 instanceof GradientColor && color2 instanceof GradientColor) {
			const oldColors = color1.stops.map((c) => {
				return { r: c.r, g: c.g, b: c.b, a: c.a, offset: c.offset };
			});

			const newColors = color2.stops.map((c) => {
				return { r: c.r, g: c.g, b: c.b, a: c.a, offset: c.offset };
			});

			// En caso de tener menos colores
			if (oldColors.length < newColors.length) {
				GAnalytics.track('click', 'Button', 'change-element-color', null);
			} else {
				// En otro caso si se ha modificado cualquiera de los colores de las paradas del gradiente
				oldColors.forEach((c) => {
					newColors.forEach((nc) => {
						if (nc.r === c.r && nc.g === c.g && nc.b === c.b && nc.a !== c.a && nc.offset === c.offset) {
							GAnalytics.track('click', 'Button', 'change-element-opacity', null);
						} else if ((nc.r !== c.r || nc.g !== c.g || nc.b !== c.b) && nc.offset === c.offset) {
							GAnalytics.track('click', 'Button', 'change-element-color', null);
						}
					});
				});
			}
		}
	};

	return {
		colors,
		updateColor,
		isBorderGradient,
	};
};
