<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core';
import { computed } from 'vue';
import { ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Dropdown from '@/common/components/Dropdown.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import { useI18n } from '@/i18n/useI18n';

const { isMobile } = useDeviceInfo();
const { trans } = useI18n();

const { selection } = useTextSelection();

// Const
const FONT_SIZE_OPTIONS = [
	{ label: 12, value: 12 },
	{ label: 14, value: 14 },
	{ label: 16, value: 16 },
	{ label: 18, value: 18 },
	{ label: 24, value: 24 },
	{ label: 30, value: 30 },
	{ label: 48, value: 48 },
	{ label: 56, value: 56 },
	{ label: 64, value: 64 },
	{ label: 96, value: 96 },
	{ label: 128, value: 128 },
	{ label: 160, value: 160 },
	{ label: 198, value: 198 },
	{ label: 232, value: 232 },
];

// Props
const props = defineProps<{
	selected: number[];
}>();

// Data
const pressing: {
	interval: undefined | ReturnType<typeof setInterval>;
	timeout: undefined | ReturnType<typeof setTimeout>;
} = {
	interval: undefined,
	timeout: undefined,
};

// Computeds
const selectedText = computed(() => {
	const currentFontSize = getCurrentFontSize();

	if (currentFontSize) {
		return currentFontSize;
	}

	return props.selected.length > 1 ? 'MIX' : props.selected[0];
});

// Emits
const emit = defineEmits(['update']);

// Methods
const onUpdate = (val: number) => {
	emit('update', val);
};

const onUpdateByStep = (dir: number) => {
	if (dir > 0) {
		emit('update', 'plus');
		if (typeof mobileFontSize.value === 'number') mobileFontSize.value++;
	} else {
		emit('update', 'minus');
		if (typeof mobileFontSize.value === 'number') mobileFontSize.value--;
	}
};

const onStart = (dir: number) => {
	if (pressing.interval) clearInterval(pressing.interval);
	if (pressing.timeout) clearTimeout(pressing.timeout);

	pressing.timeout = setTimeout(() => {
		pressing.interval = setInterval(() => {
			onUpdateByStep(dir);
		}, 100);
	}, 200);
};

const onStop = () => {
	if (pressing.interval) clearInterval(pressing.interval);
	if (pressing.timeout) clearTimeout(pressing.timeout);
};

/**
 * Obtiene el fontSize del elemento sobre el que se ha dejado el cursor
 * @returns Devuelve el número del fontSize o false en caso de no encontrarlo
 */
const getCurrentFontSize = (): number | false => {
	const sel = selection.value?.selection;

	if (sel?.type.toLowerCase() === 'caret' && sel.anchorNode && props.selected.length > 1) {
		let result;

		if (sel.anchorNode.nodeType === 1) {
			result = props.selected.find((fs) => fs === parseInt(getComputedStyle(sel.anchorNode as Element).fontSize));
		} else {
			result = props.selected.find((fs) => {
				if (sel.anchorNode && sel.anchorNode.parentElement) {
					return fs === parseInt(getComputedStyle(sel.anchorNode?.parentElement).fontSize);
				}
			});
		}

		return result ? result : false;
	}

	return false;
};

const fontSizeValue = ref(selectedText.value);
const mobileFontSize = ref(selectedText.value);

const onNumberInput = useDebounceFn((ev: Event) => {
	if (ev.target instanceof HTMLInputElement) {
		const val = parseInt(ev.target.value);
		onUpdate(!val || val < 1 ? 1 : val);
	}
}, 400);

const setCursorToEndofInput = (ev: Event) => {
	if (ev.target instanceof HTMLInputElement) {
		ev.target.setSelectionRange(ev.target.value.length, ev.target.value.length);
	}
};

const onclickUpdateMinusStep = () => {
	onUpdateByStep(-1);

	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'size' });
};
const onclickUpdatePlusStep = () => {
	onUpdateByStep(1);

	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'size' });
};
const onUpdateDropDown = (ev: { label: string | number; value: number }) => {
	onUpdate(ev.value);
	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'size' });
};

watch(selectedText, (val) => {
	if (isMobile.value) {
		mobileFontSize.value = val;
		return;
	}
	fontSizeValue.value = val;
});
</script>

<template>
	<div
		class="flex h-full items-center justify-center rounded border border-gray-600 lg:h-10 lg:border-0"
		:tooltip="trans('Font size')"
		tooltip-position="top"
	>
		<!-- Font size dropdown -->
		<button
			data-testid="decrease-font-size"
			class="flex h-8 w-8 items-center justify-center border-r border-gray-600 text-gray-100 hover:text-white mockup:text-fp-gray-400 mockup:hover:text-blue-500 lg:w-6 lg:border-0"
			@click="onclickUpdateMinusStep"
			@mousedown="onStart(-1)"
			@mouseout="onStop"
			@mouseup="onStop"
			@touchstart="onStart(-1)"
			@touchend="onStop"
		>
			<SvgIcon name="minus" class="h-4 w-4 lg:h-5 lg:w-5" />
		</button>

		<Dropdown
			v-if="!isMobile"
			class="flex w-12 items-center justify-center lg:w-auto"
			:selected="selectedText"
			:options="FONT_SIZE_OPTIONS"
			@update="onUpdateDropDown"
		>
			<template #title>
				<span class="flex p-2 text-center font-bold">{{ trans('Select a font size') }}</span>
			</template>
			<template #default="{ onToggleVisibility, value }">
				<label v-if="fontSizeValue === 'MIX'" class="pointer-events-none absolute text-white">{{
					fontSizeValue
				}}</label>
				<input
					data-text-input
					:type="fontSizeValue === 'MIX' ? 'text' : 'number'"
					data-testid="list-font-size"
					class="relative flex h-6 w-8 appearance-none items-center justify-center rounded-sm bg-transparent text-center font-bold text-white focus:bg-gray-700 focus:outline-none mockup:text-fp-gray-700 lg:text-lg"
					min="1"
					:value="value === 'MIX' ? '' : value"
					@click.stop="onToggleVisibility"
					@input="onNumberInput"
				/>
			</template>
		</Dropdown>
		<input
			v-else
			v-model="mobileFontSize"
			type="tel"
			data-testid="list-font-size"
			class="relative flex h-6 w-8 appearance-none items-center justify-center rounded-sm bg-transparent text-center font-bold text-white focus:bg-gray-700 focus:outline-none mockup:text-fp-gray-700 lg:text-lg"
			@input="onNumberInput"
			@click="setCursorToEndofInput"
		/>

		<button
			data-testid="increase-font-size"
			class="flex h-8 w-8 items-center justify-center border-l border-gray-600 text-gray-100 hover:text-white mockup:text-fp-gray-400 mockup:hover:text-blue-500 lg:w-6 lg:border-0"
			@click="onclickUpdatePlusStep"
			@mousedown="onStart(1)"
			@mouseout="onStop"
			@mouseup="onStop"
			@touchstart="onStart(1)"
			@touchend="onStop"
		>
			<SvgIcon name="plus" class="h-4 w-4 lg:h-5 lg:w-5" />
		</button>
	</div>
</template>
<style lang="sass" scoped>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button
	@apply appearance-none m-0
</style>
