<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useI18n } from '@/i18n/useI18n';

const { isMobile } = useDeviceInfo();
const { trans } = useI18n();
const store = useMainStore();
const props = defineProps<{
	element: BaseImage;
}>();

const emit = defineEmits<{
	(e: 'change', value: 'background' | 'foreground' | 'presets'): void;
}>();

const element = toRef(props, 'element');

const selectBackground = () => {
	if (!isForeground.value) {
		return;
	}

	emit('change', 'background');
};

const isForeground = computed(() => element.value instanceof ForegroundImage);

const selectForeground = () => {
	if (isForeground.value) {
		return;
	}

	emit('change', 'foreground');
};

const presetPanelToggle = () => {
	emit('change', 'presets');
};
</script>

<template>
	<div>
		<div class="mx-4 mb-2 mt-3 flex h-8 items-center overflow-hidden rounded-lg border border-gray-600 lg:hidden">
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="{
					'bg-gray-100 text-gray-800': !isForeground,
					'text-gray-300': isForeground,
				}"
				@click="selectBackground"
			>
				{{ trans('Background') }}
			</button>
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="{
					'opacity-50': !isForeground && element.opacity === 0,
					'text-gray-300': !isForeground,
					'bg-gray-100 text-gray-800': isForeground,
				}"
				:disabled="!isForeground && element.opacity === 0"
				@click="selectForeground"
			>
				{{ trans('Foreground') }}
			</button>
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase text-gray-300"
				@click="presetPanelToggle"
			>
				{{ trans('Presets') }}
			</button>
		</div>
	</div>
</template>

<style scoped></style>
