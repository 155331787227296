import { debouncedWatch, until } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { nextTick, Ref, ref, watch, WatchStopHandle } from 'vue';

import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useHistoryStore } from '@/history/stores/history';
import { useInteractions } from '@/interactions/composables/useInteractions';
import Page from '@/page/classes/Page';

export function useDebouncedPage(pageRef: Ref<Page>, debounce: number, scale: Ref<number>) {
	const history = useHistoryStore();
	const { isIdle } = useInteractions();
	const clonePage = (page?: Page) => {
		const clone = cloneDeep<Page>(page || pageRef.value);

		if (!scale.value) return clone;

		clone.elements.forEach((el) => {
			el.scaleBy(scale.value);
		});

		return clone;
	};

	const debouncedPage = ref<Page>(clonePage(pageRef.value));
	let watcher: WatchStopHandle | undefined;
	const registerWatcher = () => {
		if (watcher) watcher();
		watcher = debouncedWatch(
			pageRef.value,
			async (page: Page) => {
				await until(isIdle).toBeTruthy();
				debouncedPage.value = clonePage(page);
			},
			{ debounce: debounce }
		);
	};

	registerWatcher();

	watch(
		() => history.key,
		async () => {
			await nextTick();
			debouncedPage.value = clonePage();
			registerWatcher();
		}
	);

	// Al iniciar el editor la escala aún no está calculada por lo que los cálculos de los tamaños son erróneos
	// para solucionarlo, escuchamos los cambios de escala(esta escala no es la propia del editor sino la de la preview, que no es dinámica) para actualizar la previa
	watch(scale, async (newScale) => {
		if (!newScale) return;
		debouncedPage.value = clonePage();
	});

	return {
		debouncedPage,
	};
}
