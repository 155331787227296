<script lang="ts" setup>
import { useAdminActions } from '@/admin/composables/useAdminActions';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useGlobalError } from '@/editor/composables/useGlobalError';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();
const { error } = useGlobalError();
const { isAdminMode } = useEditorMode();
const store = useMainStore();
const toast = useToast();
const { declineTemplate } = useAdminActions();

const onTryAgain = () => window.location.reload();

const decline = async () => {
	try {
		await declineTemplate('Something went wrong');
		toast.success('Template declined!');
	} catch (e) {
		toast.error('Error while declining the template');
	}
};
</script>

<template>
	<Modal open>
		<div
			class="relative mx-auto flex max-w-sm flex-col items-center justify-center overflow-hidden rounded-md bg-white px-8 py-8"
		>
			<SvgIcon name="alert" class="mb-6 h-10 w-10 text-red-500" />
			<h1 class="text-2xl font-bold text-gray-800">{{ trans(`Something went wrong`) }}</h1>
			<p class="text-md text-center text-gray-600">{{ error?.message }}</p>
			<div class="mt-6 flex flex-col gap-4 sm:flex-row">
				<button
					class="rounded border-2 border-blue-500 bg-blue-500 px-4 py-2 font-semibold text-white hover:border-blue-600 hover:bg-blue-600 slidesgo:border-purple-400 slidesgo:bg-purple-400"
					@click="onTryAgain"
				>
					{{ trans('Try again') }}
				</button>
				<a
					class="rounded border-2 border-blue-500 px-4 py-2 font-semibold text-blue-500 hover:border-blue-500 hover:bg-blue-500 hover:text-white slidesgo:border-purple-400 slidesgo:text-purple-400 slidesgo:hover:bg-purple-400 slidesgo:hover:text-white"
					href="/"
				>
					{{ trans('Search more templates') }}
				</a>
			</div>
			<div
				v-if="isAdminMode && store.isFromFreepik"
				class="mt-6 flex flex-col-reverse items-center justify-center gap-4"
			>
				<div class="flex justify-between">
					<button
						class="h-10 rounded border-2 border-red-500 bg-red-500 px-6 font-semibold text-white hover:border-red-400 hover:bg-red-400 lg:h-12 lg:px-8 lg:text-lg"
						@click="decline"
					>
						Decline Freepik Vector
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
