import Normalize from 'color-normalize';
import { v4 as uuidv4 } from 'uuid';

import { GradientColor } from '@/color/classes/GradientColor';
import { ColorInterface, SolidColorDTO } from '@/Types/colorsTypes';
import MathTools from '@/utils/classes/MathTools';
import { SerializableClass } from '@/utils/classes/SerializableClass';

export class SolidColor extends SerializableClass implements ColorInterface {
	id: string;
	r: number;
	g: number;
	b: number;
	a: number;
	validForVariant: boolean;

	constructor(r: number, g: number, b: number, a: number, id?: string) {
		super();
		this.id = id || 'color-' + uuidv4();
		this.r = r;
		this.g = g;
		this.b = b;
		this.a = a;
		this.validForVariant = true;
	}

	static fromString(color: string): SolidColor {
		const [r, g, b, a] = Normalize(color);

		if (r === null || g === null || b === null) {
			return SolidColor.black();
		}

		return new SolidColor(r * 255, g * 255, b * 255, a);
	}

	static fromObject(rgba: SolidColorDTO): SolidColor {
		const { r, g, b, a } = rgba;

		return new SolidColor(r, g, b, a);
	}

	static unique(colors: SolidColor[]): SolidColor[] {
		return colors.filter(
			(color, index, colors) => index === colors.findIndex((c) => c.toCssString() === color.toCssString())
		);
	}

	private toString(): string {
		return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
	}

	toCssStringWithoutAlpha(): string {
		return this.toRgb();
	}

	toRgb(): string {
		return `rgb(${this.r}, ${this.g}, ${this.b})`;
	}

	toCssString(): string {
		return this.toString();
	}

	toElementReference(): string {
		return this.toString();
	}

	toHex(withAlpha?: boolean): string {
		const outParts = [this.r.toString(16), this.g.toString(16), this.b.toString(16)];

		if (withAlpha) {
			const alpha = Math.round(this.a * 255)
				.toString(16)
				.substring(0, 2);
			outParts.push(alpha);
		}

		// Pad single-digit output values
		outParts.forEach(function (part, i) {
			if (part.length === 1) {
				outParts[i] = '0' + part;
			}
		});

		return '#' + outParts.join('');
	}

	toObject(): { r: number; g: number; b: number; a: number } {
		return {
			r: this.r,
			g: this.g,
			b: this.b,
			a: this.a,
		};
	}

	update(r: number, g: number, b: number, a: number) {
		this.r = r;
		this.g = g;
		this.b = b;
		this.a = a;
	}

	convertToGradient(): GradientColor {
		const type = 'linear';
		const rotation = 0;
		const stops = [
			{ r: this.r, g: this.g, b: this.b, a: this.a, offset: 0 },
			{
				r: MathTools.randomBetween(0, 255),
				g: MathTools.randomBetween(0, 255),
				b: MathTools.randomBetween(0, 255),
				a: 1,
				offset: 100,
			},
		];

		return new GradientColor(type, rotation, stops);
	}

	toRegex(): RegExp {
		const colorSplitted = this.toString().split(',');
		const regexString = [colorSplitted[0], ...colorSplitted.slice(1).map((e) => `\\s*${e}`)]
			.join(',')
			.replaceAll('(', '\\(')
			.replaceAll(')', '\\)')
			.replaceAll(/\s/g, '');

		return new RegExp(regexString, 'g');
	}

	isGradient(): boolean {
		return false;
	}

	isSolid(): boolean {
		return true;
	}

	withoutAlpha(): SolidColor {
		return new SolidColor(this.r, this.g, this.b, 1, this.id);
	}

	copy() {
		return new SolidColor(this.r, this.g, this.b, this.a);
	}

	static white(): SolidColor {
		return new SolidColor(255, 255, 255, 1);
	}

	static gray(): SolidColor {
		return new SolidColor(134, 159, 178, 1);
	}

	static black(): SolidColor {
		return new SolidColor(0, 0, 0, 1);
	}

	static random(): SolidColor {
		return new SolidColor(
			MathTools.randomBetween(0, 255),
			MathTools.randomBetween(0, 255),
			MathTools.randomBetween(0, 255),
			1
		);
	}

	static transparent(): SolidColor {
		return new SolidColor(255, 255, 255, 0);
	}

	static unserialize(data: SolidColor): SolidColor {
		const { r, g, b, a, id } = data;

		if (r === null || g === null || b === null) {
			return SolidColor.black();
		}

		return new SolidColor(r, g, b, a, id);
	}

	static lightGray(): SolidColor {
		return new SolidColor(206, 206, 206, 1);
	}
}
