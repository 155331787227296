<script lang="ts" setup>
import { until } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { computed, onMounted, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import CollectionListbox from '@/common/components/CollectionListbox.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';
import CanvasPreview from '@/page/components/CanvasPreview.vue';
import { useProjectStore } from '@/project/stores/project';
import { ItemList } from '@/Types/types';

const { trans } = useI18n();

const props = defineProps<{ multiple?: boolean; showError?: boolean; maxPagesSelected?: number }>();

const store = useMainStore();
const project = useProjectStore();
const { selectedPages, updateSelectedPages } = useDownloadsProject();

const togglePages = ref<boolean>();
const pages = ref<ItemList[]>([]);

const allPagesSelected = computed(() => pages.value.length === selectedPages.value.length);
const allPagesLabel = computed(() => trans('All pages') + ` (1 - ${pages.value.length})`);

const placeholder = computed(() => {
	if (allPagesSelected.value) {
		return allPagesLabel.value;
	}
	return pages.value
		.filter((page) => selectedPages.value.find((sP) => sP.id === page.id))
		.map((p) => p.name)
		.join(', ');
});

onMounted(async () => {
	await until(() => store.finishedLoading).toBe(true);

	pages.value = [
		...cloneDeep(project.pages).map((page, index) => {
			let selectedItem = false;

			// Si es multiple, seleccionamos todas las páginas
			if (props.multiple && !props.maxPagesSelected) selectedItem = true;
			// Si no es multiple, seleccionamos la primera página
			else if (index === 0) selectedItem = true;

			return {
				id: page.id,
				name: `${trans('Page')} ${index + 1}`,
				selected: selectedItem,
			};
		}),
	];

	// Mandamos el estado inicial para poder descargarse posteriormente
	if (props.multiple && !props.maxPagesSelected) {
		updateSelectedPages(pages.value.map((p) => p.id));
	} else {
		updateSelectedPages([pages.value[0]?.id]);
	}
});

const canBeUnchecked = (pageId: string) => selectedPages.value.length === 1 && selectedPages.value[0].id === pageId;

const getRealPage = (pageId: string): Page => project.pages.find((pP) => pP.id === pageId) as Page;

const onSelectListOption = (newVal: ItemList[]) => {
	GAnalytics.track('click', 'Download', `select-pages-${newVal.length}`, null);
	updateSelectedPages(newVal.map((page) => page.id));
};

const onClickAllPages = async () => {
	togglePages.value = !togglePages.value;

	if (selectedPages.value.length !== pages.value.length) {
		updateSelectedPages(pages.value.map((p) => p.id));
	} else {
		updateSelectedPages([pages.value[0].id]);
	}
};
</script>
<template>
	<div id="page-list-box" class="relative">
		<CollectionListbox
			v-if="pages.length > 1"
			:collection="pages"
			:placeholder="placeholder"
			:can-be-unchecked="canBeUnchecked"
			:multiple="multiple"
			:filled-checked="true"
			:show-error="showError"
			:max-items-selected="maxPagesSelected"
			:toggle-all-items="togglePages"
			@selected="onSelectListOption"
		>
			<template v-if="!maxPagesSelected" #custombutton>
				<div
					v-if="multiple"
					class="flex h-10 items-center overflow-hidden px-3 focus:bg-gray-100/25 hover:bg-gray-100/25"
					:class="{ 'bg-gray-100/25': allPagesSelected }"
					@click="onClickAllPages"
				>
					<span class="text-gray-700">{{ trans('All pages') }}</span>
					<span
						v-if="allPagesSelected"
						class="bg-blue absolute right-4 flex h-4 w-4 items-center justify-center rounded-sm border border-blue-400 bg-blue-400"
					>
						<SvgIcon name="check" class="h-3 w-3 text-white" />
					</span>
					<span
						v-else
						class="bg-blue absolute right-4 flex h-4 w-4 items-center justify-center rounded-sm border border-blue-400 bg-white"
					>
					</span>
				</div>
			</template>
			<template #item="{ item }">
				<div v-if="store.finishedLoading" class="mr-2 h-7 w-7 overflow-hidden rounded">
					<CanvasPreview
						:scroll-area="'#page-list-box ul'"
						:preview-name="'download-options-blur'"
						:page="getRealPage(item.id)"
					/>
				</div>
			</template>
		</CollectionListbox>
		<div v-else-if="pages.length === 1">
			<span
				class="texts-gray-700 flex h-10 w-full flex-1 resize-none items-center rounded border border-gray-200 px-4 text-sm text-gray-700"
			>
				{{ trans('Page 1') }}
			</span>
		</div>
		<div v-else>
			<span
				class="texts-gray-700 flex h-10 w-full flex-1 resize-none items-center justify-center rounded border border-gray-200 bg-gray-200/20 px-4 text-sm text-gray-700"
			>
				<SvgIcon name="spinner" class="mr-2 h-6 w-6 animate-spin" /> {{ trans('Loading') }}...
			</span>
		</div>
	</div>
</template>
