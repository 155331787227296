<script lang="ts" setup>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';

const store = useMainStore();
const project = useProjectStore();
const { trans } = useI18n();
const toast = useToast();
const { requireAuth, isLogged } = useAuth();
const { share } = useDownloadsProject();
const { isPhotoMode } = useEditorMode();

const emails = ref('');

const sendToEmails = async (ev: Event) => {
	ev.preventDefault();

	if (store.sharing) return;

	requireAuth();

	if (!isLogged || !store.userVector?.uuid) {
		toast.error(trans('You must log in and save the template to send email'));
		return;
	}

	const emailsArray = emails.value.split(',').map((email) => email.trim());

	try {
		await share('email', { emails: emailsArray }, [project.pages[0].id]);

		GAnalytics.trackGA4('send_link', { text_link: 'email' });

		toast.success(trans('Email sent'));
		emails.value = '';
	} catch (error) {
		toast.error(trans('Error sending email'));
	}

	GAnalytics.track('share-by-mail', 'Template', project.name ? project.name : 'custom-artboard', null);
};
</script>
<template v-if="!isPhotoMode">
	<p class="mb-1 w-full text-left text-sm font-semibold text-gray-800">{{ trans('Share by email') }}</p>
	<form class="mb-1 flex w-full" @submit="sendToEmails">
		<input
			v-model="emails"
			type="mail"
			class="h-10 w-full rounded-bl rounded-tl border-b border-l border-t border-gray-100/75 px-3 text-sm text-gray-700 placeholder:text-gray-300 focus:border-blue-500 focus:outline-none slidesgo:border-purple-400"
			placeholder="info@wepik.com"
			required
			minlength="3"
		/>
		<button
			type="submit"
			class="h-10 rounded-br rounded-tr bg-blue-500 px-6 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400 lg:px-6"
		>
			<div v-if="!store.sharing">{{ trans('Send') }}</div>
			<SvgIcon v-if="store.sharing" data-testid="spinner" name="spinner" class="mr-1 h-7 w-7 animate-spin" />
		</button>
	</form>
	<p class="w-full text-left text-xs text-gray-400">
		{{ trans('Use comma to add 2 or more emails. Max 10 emails') }}
	</p>
</template>
