import { useElementBounding, useElementSize } from '@vueuse/core';
import { computed, Ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { useArtboard } from '@/project/composables/useArtboard';

export const useCanvasSize = (canvas: any, canvasTool: any, canvasContainer: any) => {
	const store = useMainStore();
	const { artboardSizeInPx } = useArtboard();
	const canvasWidthScaled = computed(() => artboardSizeInPx.value.width * store.scale);
	const canvasHeightScaled = computed(() => artboardSizeInPx.value.height * store.scale);
	const width = computed(() => artboardSizeInPx.value.width);
	const height = computed(() => artboardSizeInPx.value.height);

	// Diferencia en altura del container y canvas (container = canvastool + canvas)

	// Canvas
	const { height: canvasHeight } = useElementBounding(canvas);
	// CanvasTool
	const { height: canvasToolHeight } = useElementBounding(canvasTool);
	// CanvasTool + Canvas
	const { height: canvasContainerHeight } = useElementBounding(canvasContainer);

	return {
		width,
		height,
		canvasWidthScaled,
		canvasHeightScaled,
		canvasHeight,
		canvasToolHeight,
		canvasContainerHeight,
		scale: store.scale,
	};
};

export const useCanvasSizeContainer = (container: Ref<HTMLElement>) => {
	const { artboardSizeInPx } = useArtboard();
	const { width, height } = useElementSize(container);

	const artboardProportion = computed(() => artboardSizeInPx.value.width / artboardSizeInPx.value.height);
	const canvasProportion = computed(() => width.value / height.value);

	const finalSize = computed(() => {
		if (artboardProportion.value > canvasProportion.value) {
			const scale = width.value / artboardSizeInPx.value.width;

			return {
				width: width.value,
				height: artboardSizeInPx.value.height * scale,
			};
		}

		const scale = height.value / artboardSizeInPx.value.height;
		return {
			width: artboardSizeInPx.value.width * scale,
			height: height.value,
		};
	});

	const scale = computed(() => {
		if (artboardProportion.value < canvasProportion.value) return height.value / artboardSizeInPx.value.height;
		return width.value / artboardSizeInPx.value.width;
	});

	const canvasSize = computed(() => {
		return {
			width: finalSize.value.width,
			height: finalSize.value.height,
			canvasWidthScaled: finalSize.value.width,
			canvasHeightScaled: finalSize.value.height,
			scale: 1,
		};
	});

	return { canvasSize, scale };
};
