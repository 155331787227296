<script setup lang="ts">
import { Svg } from '@svgdotjs/svg.js';
import { v4 as uuidv4 } from 'uuid';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { useI18n } from '@/i18n/useI18n';
import { useActivePage } from '@/page/composables/useActivePage';
import MathTools from '@/utils/classes/MathTools';

const props = defineProps<{ element: Shape }>();

const { trans } = useI18n();
const toast = useToast();
const { isAdminMode } = useEditorMode();
const { addElement, removeElement } = useActivePage();

const splitShape = () => {
	const svg = props.element.svgInstance();
	const viewbox = (svg as Svg).viewbox();

	const defsIds = Array.from(svg.find('defs').flatMap((el) => el.attr('id'))) as string[];

	let elements = svg.children().filter((el) => el.type !== 'defs');
	let check = true;

	if (elements.length === 1 && elements[0].type !== 'g') {
		toast.error(trans('This shape cannot be split'));
		return;
	}

	// El elemento puede estar anidado en grupos, así que buscamos el grupo final
	while (check) {
		// El grupo final es aquel que no tiene atributos además de un id
		check =
			elements.length === 1 &&
			elements[0].type === 'g' &&
			!Object.keys(elements[0].attr()).filter((value) => value !== 'id').length;

		if (check) {
			elements = elements[0].children().filter((el) => el.type !== 'defs');
		}
	}

	const gId = elements.length > 1 ? uuidv4() : null;

	elements
		.filter((el) => el.type !== 'defs' && el.find('defs').length === 0)
		.forEach((el) => {
			let flipX = false;
			let flipY = false;

			const rotate = el.transform().rotate || 0;
			// Anulamos el flip para que no afecte al cálculo de la posición
			if ((el.transform()?.a || 0) < 0) {
				flipX = true;
				el.flip('x');
			}

			if ((el.transform()?.d || 0) < 0) {
				flipY = true;
				el.flip('y');
			}

			const { x, y, width, height } = el.bbox();
			const { translateX, translateY, scaleX, scaleY } = el.transform();
			const scale = MathTools.ruleOfThree(viewbox.width, 1, props.element.size.width);
			// Volvemos a aplicar el flip
			if (flipX) {
				el.flip('x');
			}

			if (flipY) {
				el.flip('y');
			}

			if (rotate) {
				el.rotate(-(rotate || 0));
			}
			const realX = x + (translateX || 0);
			const realY = y + (translateY || 0);
			const realW = width * (scaleX || 1);
			const realH = height * (scaleY || 1);

			if (rotate) {
				const transformWithoutRotation = el.transform();
				el.translate(
					(translateX || 0) - (transformWithoutRotation.translateX || 0),
					(translateY || 0) - (transformWithoutRotation.translateY || 0)
				);
			}

			const content = el.node.outerHTML.toString();

			const colors = props.element.colors.filter((color) => content.includes(color.id));
			const idsInUse = defsIds.filter((id) => content.includes(id));
			const defsElements = idsInUse.map((id) => svg.findOne(`#${id}`));

			const newShape = Shape.create();
			newShape.content = content + `<defs>${defsElements.map((el) => el?.node.outerHTML || '').join('')}</defs>`;
			newShape.position = {
				x: props.element.position.x + realX * scale,
				y: props.element.position.y + realY * scale,
			};
			newShape.viewbox = `${realX} ${realY} ${realW} ${realH}`;
			newShape.size = {
				width: realW * scale,
				height: realH * scale,
			};

			newShape.setRotation(rotate);
			newShape.colors = colors;
			newShape.group = gId;

			addElement(newShape);
		});

	removeElement(props.element);

	toast.success(trans('Shape splitted'));
};
</script>

<template>
	<button
		v-if="isAdminMode"
		class="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-white shadow hover:text-blue-600 slidesgo:bg-purple-400"
		:tooltip="trans('Split shape')"
		tooltip-position="right"
		@click="splitShape"
	>
		<SvgIcon name="split" class="h-3 w-3" />
	</button>
</template>
