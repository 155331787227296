import { AfterFetchContext } from '@vueuse/core';
import { Ref } from 'vue';

import { useSetNextPageInURL } from '@/api/composables/useSetNextPageInURL';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import {
	BasicShapeApi,
	CommonRawResponseApi,
	FlaticonCategoryApi,
	FlaticonElementApi,
	ImageApi,
	MappedApiResponse,
	MaskApi,
	StickersCategoryApi,
	StickersElementApi,
	StorysetApi,
	TemplateApiData,
} from '@/Types/apiClient';

export const concatenatedUserCreationsResponseMapper = (ctx: AfterFetchContext, concatenatedData: Ref<any[]>) => {
	const { APP_API_PATH } = useEnvSettings();
	if (ctx.data.links.next) {
		ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
	}

	const nextPage = parseFloat(new URLSearchParams((ctx.data.links.next || '').split('?')[1]).get('page') || '2');

	// Si estamos en la página 1 y hay contenido lo eliminamos
	if (nextPage === 2 && ctx.data.length) {
		concatenatedData.value = [];
	}

	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data.data);

	// Fixed api context
	ctx.data.data = concatenatedData.value;

	return ctx;
};

export const concatenatedTemplatesResponseMapper = (
	ctx: AfterFetchContext,
	concatenatedData: Ref<TemplateApiData[] | BasicShapeApi[] | MaskApi[] | StickersCategoryApi[]>
): AfterFetchContext<MappedApiResponse<TemplateApiData | BasicShapeApi | MaskApi | StickersCategoryApi>> => {
	const { APP_API_PATH } = useEnvSettings();

	if (ctx.data.data?.length) {
		concatenatedData.value = concatenatedData.value.concat(ctx.data.data);
	}
	ctx.data.data = concatenatedData.value;
	if (ctx.data.links?.next) {
		ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
	}

	return ctx;
};

export const splittedTemplatesResponseMapper = (
	ctx: AfterFetchContext
): AfterFetchContext<MappedApiResponse<BasicShapeApi | MaskApi>> => {
	const { APP_API_PATH } = useEnvSettings();
	// Fixed api context
	if (ctx.data.links.next) {
		ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
	}
	return ctx;
};

export const concatenatedFlaticonResponseMapper = (
	ctx: AfterFetchContext,
	concatenatedData: Ref<FlaticonCategoryApi[] | FlaticonElementApi[] | StickersElementApi[]>,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<FlaticonCategoryApi | FlaticonElementApi | StickersElementApi>> => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data);

	// Set pagination regarding page param in url
	ctx.data = {
		data: concatenatedData.value,
		links: {
			next: useSetNextPageInURL<FlaticonElementApi>(url, ctx.data).value,
		},
	};

	// Fixed api context
	return ctx;
};

export const splittedFlaticonResponseMapper = (ctx: AfterFetchContext, url: Ref<string>) => {
	// Set pagination regarding page param in url
	ctx.data = {
		data: ctx.data,
		links: {
			next: useSetNextPageInURL<FlaticonElementApi>(url, ctx.data).value,
		},
	};

	// Fixed api context
	return ctx;
};

export const imageResponseMapper = (
	ctx: AfterFetchContext<CommonRawResponseApi<ImageApi> | MappedApiResponse<ImageApi>>,
	concatenatedData: Ref<ImageApi[]>,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<ImageApi>> => {
	const { APP_API_PATH } = useEnvSettings();

	if (ctx.data && 'data' in ctx.data) {
		if (ctx.data?.links?.next) {
			ctx.data.links.next = ctx.data.links.next.replace(APP_API_PATH, '');
		}

		concatenatedData.value = concatenatedData.value.concat(ctx.data.data);

		ctx.data.data = concatenatedData.value;
	}

	if (ctx.data && 'images' in ctx.data) {
		// Concatenate data from api for InfiniteLoading
		if (ctx.data.images && Array.isArray(ctx.data.images)) {
			concatenatedData.value = concatenatedData.value.concat(ctx.data.images);
		}

		let next = '';
		if (ctx.data.images?.length) {
			next = useSetNextPageInURL<ImageApi>(url, ctx.data?.images).value;
		}

		// Images api returns nextPageId:number instead of links[prev, next] as the other cases
		// so we need to set it correctly
		ctx.data = {
			data: concatenatedData.value,
			links: {
				next,
			},
		};
	}

	// Fixed api context
	return ctx as AfterFetchContext<MappedApiResponse<ImageApi>>;
};

export const concatenatedStorysetsResponseMapper = (
	ctx: AfterFetchContext,
	concatenatedData: Ref<StorysetApi[]>,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<StorysetApi>> => {
	// Concatenate data from api for InfiniteLoading
	concatenatedData.value = concatenatedData.value.concat(ctx.data.data);
	ctx.data.data = concatenatedData.value;

	// Set pagination regarding page param in url
	let next = '';
	if (ctx.data.data?.length) {
		next = useSetNextPageInURL<StorysetApi>(url, ctx.data.data).value;
	}
	ctx.data.links.next = next;

	// Fixed api context
	return ctx;
};

export const splittedStorysetsResponseMapper = (
	ctx: AfterFetchContext,
	url: Ref<string>
): AfterFetchContext<MappedApiResponse<StorysetApi>> => {
	if (ctx.data.data.length === 0) {
		ctx.data.links.next = '';
		return ctx;
	}

	// Set pagination regarding page param in url
	let next = '';
	if (ctx.data.data?.length) {
		next = useSetNextPageInURL<StorysetApi>(url, ctx.data.data).value;
	}
	ctx.data.links.next = next;

	// ctx.data.links.next = getNextUrl(url.value);

	// Fixed api context
	return ctx;
};
