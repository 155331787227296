<script lang="ts" setup>
import { cloneDeep } from 'lodash';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { useI18n } from '@/i18n/useI18n';
import { GradientType } from '@/Types/colorsTypes';

const props = defineProps<{ gradient: GradientColor; disableRadial?: boolean }>();

const emit = defineEmits<{ (e: 'change', value: GradientColor): void }>();

const setGradientType = (type: GradientType) => {
	const clone = cloneDeep(props.gradient);
	clone.type = type;
	emit('change', clone);

	GAnalytics.track('click', 'Button', `add-gradient-${type}`, null);
};
const { trans } = useI18n();
</script>

<template>
	<div class="flex">
		<button
			class="flex items-center rounded py-1 px-2 text-xs font-semibold"
			:class="gradient.type === 'linear' ? 'bg-gray-600 text-white' : 'text-gray-300 hover:text-white'"
			@click="setGradientType('linear')"
		>
			{{ trans('Linear') }}
		</button>
		<button
			class="flex items-center rounded py-1 px-2 text-xs font-semibold"
			:class="[
				gradient.type === 'radial' && !disableRadial ? 'bg-gray-600 text-white' : 'text-gray-300 hover:text-white',
				disableRadial ? 'cursor-not-allowed  text-gray-100 hover:text-gray-100' : '',
			]"
			:disabled="disableRadial"
			@click="setGradientType('radial')"
		>
			{{ trans('Radial') }}
		</button>
	</div>
</template>
