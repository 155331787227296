import { isClient } from '@vueuse/shared';
import qrcode from 'qrcode';
import { computed, Ref, ref, watch } from 'vue';

import { QRCode } from '@/elements/qr-code/classes/QRCode';
import StringTools from '@/utils/classes/StringTools';

export function useQRCodeAsSVG(element: Ref<QRCode>) {
	const result = ref('');

	const src = computed(() => StringTools.getTransformedLink(element.value.url));

	const options = computed(() => ({
		margin: element.value.margin,
		color: {
			dark: element.value.frontColor.toHex(true),
			light: element.value.bgColor.toHex(true),
		},
		type: 'svg', // svg | terminal | utf8
	}));

	const getQR = async () => {
		if (src.value && isClient) {
			result.value = await qrcode.toString(src.value, options.value);
		}
	};

	watch([options, src], () => getQR(), { deep: true, immediate: true });
	watch(
		result,
		(rawSvg) => {
			if (!rawSvg) return;
			element.value.content = QRCode.getInnerSvg(rawSvg);
			element.value.viewbox = QRCode.getViewBoxFromRawSvg(rawSvg);
		},
		{ immediate: true }
	);

	return result;
}
