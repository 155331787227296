<script lang="ts" setup>
import { OnClickOutside } from '@vueuse/components';
import { useDebounceFn } from '@vueuse/core';
import { computed, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import TreeDStyle from '@/assets/ai/3d.png';
import DigitalArtStyle from '@/assets/ai/digital-art.png';
import PaintingStyle from '@/assets/ai/painting.png';
import PhotoStyle from '@/assets/ai/photo.png';
import SurpriseStyle from '@/assets/ai/surprise.png';
import { useAuth } from '@/auth/composables/useAuth';
import Modal from '@/common/components/Modal.vue';
import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import AiImageVisor from '@/elements/medias/images/image/components/ai/AiImageVisor.vue';
import CreditsModal from '@/elements/medias/images/image/components/ai/CreditsModal.vue';
import { useAiImage } from '@/elements/medias/images/image/composables/useAiImage';
import { useI18n } from '@/i18n/useI18n';
import { ImageApi } from '@/Types/apiClient';
import { AiModalType, AIStyles, AiViewerMode, Ratio } from '@/Types/types';

const props = defineProps<{
	open: boolean;
	image?: ImageApi | true;
	viewerMode?: AiViewerMode | null;
	hideTryPrompt?: boolean;
}>();

const emit = defineEmits<{
	(e: 'close'): void;
	(e: 'changeViewerMode'): void;
	(e: 'select', value: ImageApi): void;
}>();

const { trans, locale } = useI18n();
const { isAdmin, user } = useAuth();
const { isPhotoMode } = useEditorMode();
const {
	AIstyle,
	cfg,
	examplePrompt,
	images,
	loading,
	prompt,
	randomSeed,
	results,
	seed,
	steps,
	selectedImage,
	selectedRatio,
	usingExampleAsPrompt,
	generateImage,
	getPromptStyleToReplace,
	restoreAiImageFamily,
	refreshExample,
	select,
	saveExample,
	generator,
	limit,
	remaining,
	credits,
	prices,
} = useAiImage();
const { isWebview, isMobile } = useDeviceInfo();

const showAdvancedOptions = ref(false);
const showViewer = ref(false);
const canReplace = ref(false);
const modalType = ref<AiModalType>();
const showModalCredits = ref(false);
const showOptions = ref('');

onMounted(() => {
	if (typeof props.image !== 'boolean' && props.image?.metadata?.prompt) {
		prompt.value = props.image.metadata.prompt;
	}
});

// Con esto obtenemos un array con la longitud máxima de imágenes seleccionadas para generar
// con el fin de mostrar el número de cajas sombreadas que se van a generar
const maxBoxes = computed(() => new Array(images.value).fill(0));

const handlerPromptInput = useDebounceFn((e: Event) => {
	GAnalytics.track('add-image', 'Button', 'AI-generate-new-image-ok', null);
}, 300);

const onInsertImage = async (image: ImageApi) => {
	await select(image);
	onSelect(image);

	GAnalytics.track('add-image', 'Button', `include-image-modal_${image.id}`, null);
	GAnalytics.trackGA4('text_to_image_final', { action: 'include_in_my_design' });
};

const onClickExample = () => {
	prompt.value = trans(examplePrompt.value);
};

const onClose = () => {
	prompt.value = '';
	AIstyle.value = '';
	emit('close');
};

const onSelect = (image: ImageApi) => {
	emit('select', image);
	emit('close');
};

const onClickRefreshInspiration = () => {
	// Excluimos el ejemplo que ya existe y seleccionamos otro
	refreshExample();
};

const onGoBack = () => {
	loading.value = false;
	results.value = [];
	prompt.value = '';
};

const onClickEditPrompt = () => {
	loading.value = false;
	results.value = [];

	GAnalytics.track('click', 'AI', 'edit-prompt');
	GAnalytics.trackGA4('text_to_image_final', { action: 'edit_prompt' });
};

const onOpenViewer = (image: ImageApi) => {
	showViewer.value = true;
	selectedImage.value = image;
};

const onCloseAiVisor = () => {
	if (props.viewerMode) {
		emit('close');
		results.value = [];

		return;
	}

	showViewer.value = false;
};

const onTryThisPrompt = async () => {
	results.value = [];

	if (props.viewerMode) {
		emit('changeViewerMode');

		await nextTick();
	}

	showViewer.value = false;

	if (typeof props.image === 'boolean') return;

	const promptStyle = getPromptStyleToReplace(props.image?.metadata?.prompt || '', true);

	prompt.value =
		modalType.value === AiModalType.generator
			? prompt.value
			: props.image?.metadata?.prompt?.replaceAll(promptStyle.style, promptStyle.newText) || '';

	modalType.value = AiModalType.generator;
};

onBeforeMount(async () => {
	if (props.viewerMode && typeof props.image !== 'boolean') {
		showViewer.value = true;
		selectedImage.value = props.image;

		if (props.viewerMode === AiViewerMode.replace && selectedImage.value?.id) {
			await restoreAiImageFamily(`${selectedImage.value.id}`);

			if (results.value.length) {
				canReplace.value = true;
			}
		}
		return;
	}

	if (props.image === true) {
		modalType.value = AiModalType.generator;
		results.value = [];
	}
});

const onClickSaveExample = (image: ImageApi) => {
	saveExample(image);
	onClose();
};

const onClickDownload = (image: ImageApi | undefined) => {
	if (image?.id) {
		GAnalytics.track('Click', 'AI', `download-modal_${image.id}`);
		GAnalytics.trackGA4('text_to_image_final', { action: 'download' });
	}
};

const checkCanGenerateImage = () => {
	if (typeof credits.value === 'number' && credits.value < prices.value.price_image * images.value) {
		showModalCredits.value = true;
		return false;
	}

	if (typeof credits.value !== 'number' && remaining.value < images.value) {
		openLimitModal.value = true;
		return false;
	}

	return true;
};
const onClickGenerateImage = () => {
	if (!checkCanGenerateImage()) return;

	GAnalytics.track('Click', 'AI', `generate-images_${AIstyle.value}_${selectedRatio.value}`);
	GAnalytics.trackGA4('text_to_image_generate', {
		example_used: `${usingExampleAsPrompt.value}`,
		dimension: selectedRatio.value,
		images_number: `${images.value}`,
		steps: steps.value.toString(),
		guidance_scale: cfg.value.toString(),
		style: AIstyle.value,
	});
	generateImage();
};
const onClickTryAgain = () => {
	if (!checkCanGenerateImage()) return;

	GAnalytics.track('Click', 'AI', `try-again`);
	GAnalytics.trackGA4('text_to_image_final', { action: 'try_again' });
	generateImage();
};

const handlerOnClickOutside = (ev: any) => {
	if (ev.target.classList.contains('btn-options')) return;
	showOptions.value = '';
};

const toggleOptions = (id: string) => {
	showOptions.value = showOptions.value == id ? '' : id;
};

watch(randomSeed, () => {
	results.value = [];
	return (seed.value = Math.random() * 100000000);
});

const openLimitModal = ref(false);
const onClickGoPremium = () => {
	GAnalytics.trackGA4('microfunnel', {
		location: 'text_to_image_limit_reached',
		action: 'go_premium',
		interaction_type: 'click',
	});
};

const onClickExploreFreepik = () => {
	GAnalytics.trackGA4('ai_limit_explore_freepik', {
		page_location: document.location.href,
		location: 'text_to_image',
		ai_generator_count: `${limit.value - remaining.value}`,
		ai_limit_reached: remaining.value <= 0 ? 'yes' : 'no',
	});
};

const closeModal = () => {
	if (showModalCredits.value || showViewer.value) return;

	emit('close');
};
</script>

<template>
	<Modal v-if="modalType === AiModalType.generator" open class="py-8" @close="closeModal">
		<div class="relative mx-auto flex max-w-screen-md flex-col rounded bg-white p-6 lg:w-full lg:p-8">
			<button
				class="absolute right-2 top-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>
			<div class="flex">
				<h2
					v-if="image === true"
					class="flex h-6 items-center text-xl font-semibold leading-6 text-gray-800 lg:text-2xl"
				>
					<button v-if="results.length || loading" class="mr-1 text-gray-800" @click="onGoBack">
						<SvgIcon name="arrow" class="h-7 w-7 rotate-90" />
					</button>
					{{ trans('Text to image') }}
				</h2>
				<h2 v-if="image !== true" class="h-6 text-xl font-semibold leading-6 text-gray-800 lg:text-2xl">
					{{ trans('Text to image') }}
				</h2>
				<div class="ml-auto mr-7 lg:mr-4">
					<button
						v-if="typeof credits === 'number'"
						class="flex h-8 items-center justify-center gap-2 rounded-full border border-gray-100 pl-2 pr-4 text-sm text-blue-500 hover:border-gray-500 hover:bg-gray-50"
						@click="showModalCredits = true"
					>
						<SvgIcon name="coin" class="h-4 w-4" />
						{{ credits / 100 }} credits
					</button>
					<div v-else>
						<span
							v-if="limit >= 0 && !loading"
							class="mt-1 flex items-center font-bold sm:ml-auto sm:mt-0"
							:class="{
								'text-red-500': remaining === 0,
								'text-yellow-600': remaining > 0 && remaining < 12,
								'text-green-500': remaining >= 12,
							}"
						>
							{{ remaining / 4 }} / {{ limit / 4 }}
							{{ trans('left') }}
						</span>
					</div>
				</div>
			</div>
			<div v-if="!results.length && !loading">
				<div
					class="mt-6 flex max-h-[calc(100vh-250px)] w-full flex-col overflow-y-auto pr-1 text-white scrollbar-thin scrollbar-thumb-gray-100"
				>
					<div class="mb-6">
						<div class="flex flex-col gap-2">
							<div class="flex justify-between">
								<label class="font-semibold text-gray-600">
									{{ trans('Prompt') }}
									<span class="hidden font-normal lg:inline-block">{{
										trans('(Explain what would you like to see)')
									}}</span>
								</label>
								<a
									href="https://support.wepik.com/s/article/AI-image-generator?Id=ka03V0000008Y0bQAE"
									target="_blank"
									class="text-sm font-semibold text-blue-500 hover:text-blue-600"
								>
									{{ trans('Need help?') }}
								</a>
							</div>
							<label class="relative w-full">
								<input
									v-model="prompt"
									data-testid="ai-prompt-input"
									type="text"
									class="block w-full rounded border border-gray-200 bg-white px-3 py-3 text-sm text-gray-600 placeholder-gray-600 focus:border-gray-300 focus:outline-none"
									@input="handlerPromptInput"
								/>

								<label v-if="isAdmin" class="mt-2 flex text-sm text-black">
									<select v-model="generator" class="p-1">
										<option :value="null">Default</option>
										<option value="stable-diffusion">Stable Diffusion</option>
										<option value="openai">OpenAi</option>
									</select>
									<span class="ml-1 text-gray-400">(admin)</span>
								</label>
							</label>
						</div>
					</div>
					<div class="mb-6 border-b border-gray-100/50 pb-6">
						<div class="mb-3 flex items-center justify-between">
							<label class="text-gray-600">{{ trans('An example') }}</label>
							<button
								class="flex items-center text-sm text-gray-600 hover:text-gray-900"
								@click="onClickRefreshInspiration"
							>
								<SvgIcon name="reload" class="mr-2 h-3 w-3" />
								{{ trans('Refresh example') }}
							</button>
						</div>
						<div class="flex flex-wrap items-center">
							<button
								data-testid="example-prompt-ai-images"
								class="rounded-lg border border-gray-100/50 px-3 py-1 text-sm text-gray-600 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-800"
								@click="onClickExample"
								v-text="trans(examplePrompt)"
							></button>
						</div>
					</div>
					<div class="mb-6 flex flex-col gap-2 border-b border-gray-100/50 lg:pb-6">
						<div class="flex justify-between">
							<label class="font-semibold text-gray-600">
								{{ trans('Dimension') }}
							</label>
							<button
								class="flex items-center text-sm"
								:class="{
									'text-blue-500 hover:text-blue-600': showAdvancedOptions,
									'text-gray-600 hover:text-gray-900': !showAdvancedOptions,
								}"
								@click="showAdvancedOptions = !showAdvancedOptions"
							>
								<SvgIcon name="filter" class="mr-2 h-4 w-4" />
								{{ trans('Advanced options') }}
							</button>
						</div>
						<div class="flex gap-4">
							<label for="aspect-square" class="cursor-pointer">
								<input
									id="aspect-square"
									v-model="selectedRatio"
									type="radio"
									class="hidden"
									name="ratio"
									checked
									:value="Ratio.square"
								/>
								<span
									class="flex aspect-square h-20 w-full items-center justify-center rounded border border-gray-100 bg-gray-50 text-gray-300 hover:border-gray-300 hover:text-gray-300"
								>
									<span>1:1</span>
								</span>
							</label>
							<label for="aspect-portrait" class="cursor-pointer">
								<input
									id="aspect-portrait"
									v-model="selectedRatio"
									type="radio"
									class="hidden"
									name="ratio"
									:value="Ratio.portrait"
								/>
								<span
									class="flex aspect-[3/4] h-20 w-full items-center justify-center rounded border border-gray-100 bg-gray-50 text-gray-300 hover:border-gray-300 hover:text-gray-300"
								>
									<span>3:4</span>
								</span>
							</label>
							<label for="aspect-landscape" class="cursor-pointer">
								<input
									id="aspect-landscape"
									v-model="selectedRatio"
									type="radio"
									class="hidden"
									name="ratio"
									:value="Ratio.landscape"
								/>
								<span
									class="flex aspect-[4/3] h-20 w-full items-center justify-center rounded border border-gray-100 bg-gray-50 text-gray-300 hover:border-gray-300 hover:text-gray-300"
								>
									<span>4:3</span> </span
								>onCloseAiVisor
							</label>
						</div>
						<div v-if="showAdvancedOptions" class="mt-4 grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-6">
							<div v-if="randomSeed" class="relative">
								<label class="mb-1 text-xs text-gray-700"> {{ trans('Images') }}</label>
								<div class="relative flex h-4 w-full flex-1 items-center">
									<input
										v-model="images"
										class="input-range h-[3px] w-full appearance-none rounded-full bg-gray-100 focus:outline-none"
										:min="1"
										:max="4"
										:step="1"
										type="range"
									/>
									<span
										class="absolute left-0 top-1.5 h-[3px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
										:style="{ width: `${((images - 1) / 3) * 100}%` }"
									/>
								</div>
								<span class="absolute right-0 top-0 font-semibold text-gray-700" v-text="images"></span>
							</div>
							<div class="relative" :class="{ 'cursor-not-allowed opacity-50 ': !isAdmin }">
								<label class="mb-1 text-xs text-gray-700">{{ trans('Steps') }}</label>
								<div class="relative flex h-4 w-full flex-1 items-center">
									<input
										v-model="steps"
										class="input-range h-[3px] w-full appearance-none rounded-full bg-gray-100 focus:outline-none"
										:max="150"
										:min="1"
										:step="1"
										type="range"
										:disabled="!isAdmin"
									/>
									<span
										class="absolute left-0 top-1.5 h-[3px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
										:style="{ width: `${((steps - 1) / 149) * 100}%` }"
									/>
								</div>
								<span class="absolute right-0 top-0 font-semibold text-gray-700" v-text="steps"></span>
							</div>
							<div class="relative">
								<label class="mb-1 text-xs text-gray-700">{{ trans('Guidance Scale') }}</label>
								<div class="relative flex h-4 w-full flex-1 items-center">
									<input
										v-model="cfg"
										class="input-range h-[3px] w-full appearance-none rounded-full bg-gray-100 focus:outline-none"
										max="20"
										:min="0"
										:step="1"
										type="range"
									/>
									<span
										class="absolute left-0 top-1.5 h-[3px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
										:style="{ width: `${((cfg - 1) / 19) * 100}%` }"
									/>
								</div>
								<span class="absolute right-0 top-0 font-semibold text-gray-700" v-text="cfg"></span>
							</div>
						</div>
					</div>
					<div class="mb-6 flex flex-col gap-2">
						<label class="font-semibold text-gray-600">
							{{ trans('Style') }}
						</label>
						<div
							class="flex w-[calc(100vw-100px)] gap-4 overflow-scroll text-white scrollbar-thin scrollbar-thumb-gray-100 lg:grid lg:w-auto lg:grid-cols-6"
						>
							<label for="default" class="w-1/4 shrink-0 cursor-pointer text-gray-100 lg:w-auto">
								<input
									id="default"
									v-model="AIstyle"
									type="radio"
									class="hidden"
									name="styles"
									checked
									:value="AIStyles.default"
								/>
								<div class="flex aspect-square items-center justify-center rounded border border-gray-100 bg-gray-50">
									<SvgIcon name="empty" class="h-10 w-10" />
								</div>
								<p class="mt-1 text-sm text-gray-600">{{ trans('None') }}</p>
							</label>
							<label for="photo" class="w-1/4 shrink-0 cursor-pointer lg:w-auto">
								<input id="photo" v-model="AIstyle" type="radio" class="hidden" name="styles" :value="AIStyles.photo" />
								<img
									:src="PhotoStyle"
									class="flex aspect-square w-full items-center justify-center rounded border border-gray-100 object-cover text-gray-300 hover:border-gray-300 hover:text-gray-300"
								/>
								<p class="mt-1 text-sm text-gray-600">{{ trans('Photo') }}</p>
							</label>
							<label for="digital-art" class="w-1/4 shrink-0 cursor-pointer lg:w-auto">
								<input
									id="digital-art"
									v-model="AIstyle"
									type="radio"
									class="hidden"
									name="styles"
									:value="AIStyles.digitalArt"
								/>
								<img
									:src="DigitalArtStyle"
									class="flex aspect-square w-full items-center justify-center rounded border border-gray-100 object-cover text-gray-300 hover:border-gray-300 hover:text-gray-300"
								/>
								<p class="mt-1 text-sm text-gray-600">{{ trans('Digital Art') }}</p>
							</label>
							<label for="3d" class="w-1/4 shrink-0 cursor-pointer lg:w-auto">
								<input id="3d" v-model="AIstyle" type="radio" class="hidden" name="styles" :value="AIStyles.threeD" />
								<img
									:src="TreeDStyle"
									class="flex aspect-square w-full items-center justify-center rounded border border-gray-100 object-cover text-gray-300 hover:border-gray-300 hover:text-gray-300"
								/>
								<p class="mt-1 text-sm text-gray-600">3D</p>
							</label>
							<label for="painting" class="w-1/4 shrink-0 cursor-pointer lg:w-auto">
								<input
									id="painting"
									v-model="AIstyle"
									type="radio"
									class="hidden"
									name="styles"
									:value="AIStyles.painting"
								/>
								<img
									:src="PaintingStyle"
									class="flex aspect-square w-full items-center justify-center rounded border border-gray-100 object-cover text-gray-300 hover:border-gray-300 hover:text-gray-300"
								/>
								<p class="mt-1 text-sm text-gray-600">{{ trans('Painting') }}</p>
							</label>
							<label for="surprise" class="w-1/4 shrink-0 cursor-pointer lg:w-auto">
								<input
									id="surprise"
									v-model="AIstyle"
									type="radio"
									class="hidden"
									name="styles"
									:value="AIStyles.surprise"
								/>
								<img
									:src="SurpriseStyle"
									class="flex aspect-square w-full items-center justify-center rounded border border-gray-100 object-cover text-gray-300 hover:border-gray-300 hover:text-gray-300"
								/>
								<p class="mt-1 text-sm text-gray-600">{{ trans('Surprise!') }}</p>
							</label>
						</div>
					</div>
				</div>

				<button
					class="text-semibold flex h-12 w-full items-center justify-center rounded bg-gradient-to-r from-blue-500 to-purple-400 px-6 text-white hover:bg-blue-600"
					:class="{ 'pointer-events-none opacity-50': !prompt.length }"
					@click="onClickGenerateImage"
				>
					{{ trans('Generate image') }}
				</button>
				<p class="mt-4 text-center text-xs text-gray-300">
					{{ trans('By clicking on') }} <strong>{{ trans('Generate') }}</strong
					>, {{ trans('you accept the') }}
					<a href="https://huggingface.co/spaces/CompVis/stable-diffusion-license" target="_blank" class="underline">
						{{ trans('Stable Diffusion license') }}</a
					>
				</p>
			</div>
			<div v-if="results.length" data-testid="ai-images-container" class="mt-6 flex flex-col">
				<!---<p class="mb-4 text-gray-600">Promt: {{ results[0].metadata.prompt }}</p>-->
				<div class="mb-4">
					<p class="font-semibold text-gray-600">
						{{ trans('Voilà! Your images are ready.') }}
					</p>
					<p class="font-semibold text-gray-600">
						{{ trans('Choose the one you want to use as example') }}
					</p>
				</div>
				<div
					class="mb-6 grid max-h-[calc(100vh-300px)] gap-2 overflow-auto text-white scrollbar-thin scrollbar-thumb-gray-100 lg:gap-4"
					:class="randomSeed ? 'grid-cols-2' : ''"
				>
					<div
						v-for="(image, index) in results"
						:key="image.preview"
						class="group relative flex aspect-square items-center justify-center rounded-lg bg-gray-100/50"
					>
						<img
							:data-testid="`generated-ai-image-${index + 1}`"
							:src="image.preview"
							alt=""
							class="h-full w-full object-contain"
						/>
						<!-- Hover options for desktop -->
						<div
							v-if="!isMobile"
							class="absolute inset-0 flex flex-col items-center justify-end gap-2 bg-gray-800/70 p-4 opacity-0 group-hover:opacity-100"
						>
							<button
								class="absolute right-3 top-3 rounded bg-gray-100/50 p-2 backdrop-blur-xl hover:bg-gray-100/75"
								:data-testid="`ai-visor-${index + 1}`"
								@click="onOpenViewer(image)"
							>
								<SvgIcon name="fullscreen" class="h-5 w-5 text-white" />
							</button>
							<button
								v-if="isAdmin"
								class="absolute left-2 top-2 hidden items-center justify-center gap-2 rounded bg-blue-500 px-4 py-2 text-sm font-semibold shadow-lg hover:bg-blue-600 slidesgo:bg-purple-400 lg:flex"
								@click="onClickSaveExample(image)"
							>
								<SvgIcon name="nova" class="h-4 w-4 fill-current" />
								{{ trans('Save to Nova') }}
							</button>
							<a
								v-if="!isWebview"
								:href="image.url"
								class="flex w-full items-center justify-center gap-2 rounded bg-gray-50 px-4 py-2 text-sm font-semibold text-gray-800 shadow-lg hover:bg-gray-100"
								@click="onClickDownload(image)"
							>
								<SvgIcon name="download" class="h-4 w-4" />
								{{ trans('Download') }}
							</a>
							<button
								class="flex w-full items-center justify-center gap-2 rounded bg-green-500 px-4 py-2 text-sm font-semibold text-white shadow-lg hover:bg-green-600"
								@click="onInsertImage(image)"
							>
								<SvgIcon name="remove-bg" class="h-4 w-4" />
								{{ trans('Include in my design') }}
							</button>
						</div>
						<!-- More button for mobile -->
						<OnClickOutside v-else class="absolute right-2 top-2" @click.stop="" @trigger="handlerOnClickOutside">
							<button
								class="btn-options absolute right-2 top-2 flex h-7 w-7 cursor-pointer items-center justify-center rounded bg-blue-500 text-white hover:bg-blue-600 slidesgo:bg-purple-400"
								@click.stop="toggleOptions(image.id.toString())"
							>
								<SvgIcon name="more" class="h-4 w-4 rotate-90" />
							</button>
							<Popper v-if="showOptions === image.id" placement="bottom-start" partent-selector=".btn-options">
								<div class="mt-1 flex min-w-max flex-col rounded bg-white p-2 text-sm shadow">
									<button
										class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
										@click="onOpenViewer(image)"
									>
										<SvgIcon name="fullscreen" class="mr-2 h-3 w-3" />
										{{ trans('Full screen') }}
									</button>
									<button
										class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
										@click="onClickDownload(image)"
									>
										<SvgIcon name="download" class="mr-2 h-3 w-3" />
										{{ trans('Download') }}
									</button>
									<button
										class="flex items-center p-1 text-left text-gray-500 hover:text-blue-500"
										@click="onInsertImage(image)"
									>
										<SvgIcon name="remove-bg" class="mr-2 h-3 w-3" />
										{{ trans('Include in my design') }}
									</button>
								</div>
							</Popper>
						</OnClickOutside>
					</div>
				</div>
				<button
					class="text-semibold flex h-12 w-full items-center justify-center rounded border-2 border-darkblue-500 bg-white px-6 font-semibold text-darkblue-500 hover:bg-darkblue-500 hover:text-white"
					@click="onClickTryAgain"
				>
					<SvgIcon name="reload" class="mr-2 h-4 w-4" />
					{{ trans('Try again') }}
				</button>
				<button
					class="text-semibold mt-4 flex h-12 w-full items-center justify-center rounded bg-blue-500 px-6 text-white hover:bg-blue-600 slidesgo:bg-purple-400"
					@click="onClickEditPrompt"
				>
					{{ trans('Edit prompt') }}
				</button>
			</div>
			<div v-if="loading" class="mt-6 flex flex-col">
				<div class="mb-4">
					<p class="text-gray-600">
						{{ trans('Please wait a minute. Our magicians are working hard to make:') }}
					</p>
					<p class="font-semibold text-gray-600">"{{ prompt }}"</p>
				</div>
				<div
					class="relative mb-6 grid max-h-[calc(100vh-300px)] grid-cols-2 gap-2 overflow-auto text-white scrollbar-thin scrollbar-thumb-gray-100 lg:gap-4"
				>
					<div class="absolute inset-0">
						<SvgIcon
							name="star"
							class="animate-star absolute right-10 top-10 h-4 w-4 rotate-12 text-gray-100 opacity-0"
						/>
						<SvgIcon
							name="star"
							class="animate-star absolute left-10 top-20 h-8 w-8 -rotate-12 text-gray-100 opacity-0"
						/>
						<SvgIcon
							name="star"
							class="animate-star absolute right-32 top-1/2 h-6 w-6 rotate-6 text-gray-100 opacity-0"
						/>
						<SvgIcon
							name="star"
							class="animate-star absolute left-32 top-2/3 h-5 w-5 -rotate-6 text-gray-100 opacity-0"
						/>
						<SvgIcon name="star" class="animate-star absolute bottom-12 right-16 h-7 w-7 text-gray-100 opacity-0" />
					</div>
					<div
						v-for="(value, index) in maxBoxes"
						:key="`imageBox-${index}`"
						class="flex aspect-square animate-pulse items-center justify-center overflow-hidden rounded-lg bg-gray-100/50"
					></div>
				</div>
			</div>
			<Modal
				v-if="openLimitModal && !user?.freepikPremium"
				open
				content-classes="w-full flex items-center justify-center"
			>
				<div class="fixed inset-0 z-50 flex items-center justify-center bg-darkblue-500/75">
					<div class="relative w-full max-w-xl rounded bg-white px-6 py-8">
						<button
							class="absolute right-0 top-0 mr-2 mt-2 rounded bg-white p-2 hover:bg-gray-200"
							@click="openLimitModal = false"
						>
							<SvgIcon name="close" class="h-4 w-4 fill-current text-gray-800" />
						</button>
						<h3 class="mb-3 text-center text-2xl font-semibold text-gray-800">
							{{ trans("You have reached today's limit") }}
						</h3>
						<p class="mb-6 text-center text-lg leading-normal text-gray-700">
							{{
								trans("Don't stop creating! Get up to 10 daily AI-generated images and enjoy Freepik at the fullest")
							}}
						</p>
						<div class="flex items-center justify-center gap-2">
							<button
								class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300 md:mb-0"
								@click="openLimitModal = false"
							>
								{{ trans('Not now') }}
							</button>
							<a
								:href="locale === 'en' ? 'https://freepik.com/' : 'https://freepik.es/'"
								class="mx-2 flex h-12 items-center justify-center rounded border-2 border-yellow-500 bg-yellow-500 px-8 text-lg font-semibold text-white hover:border-yellow-400 hover:bg-yellow-400"
								@click="onClickGoPremium"
							>
								{{ trans('Go Premium') }}
							</a>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				v-if="openLimitModal && user?.freepikPremium"
				open
				content-classes="w-full flex items-center justify-center"
			>
				<div class="fixed inset-0 z-50 flex items-center justify-center bg-darkblue-500/75">
					<div class="relative w-full max-w-xl rounded bg-white px-6 py-8">
						<button
							class="absolute right-0 top-0 mr-2 mt-2 rounded bg-white p-2 hover:bg-gray-200"
							@click="openLimitModal = false"
						>
							<SvgIcon name="close" class="h-4 w-4 fill-current text-gray-800" />
						</button>
						<h3 class="mb-3 text-center text-2xl font-semibold text-gray-800">
							{{ trans("You've hit today's limit") }}
						</h3>
						<p class="mb-6 text-center text-lg leading-normal text-gray-700">
							{{
								trans(
									'Come back tomorrow for more. What now? Download the prompts and images of our AI-generated gallery or explore Freepik'
								)
							}}
						</p>
						<div class="flex items-center justify-center gap-2">
							<a
								:href="locale === 'en' ? 'https://freepik.com/' : 'https://freepik.es/'"
								class="mx-2 flex h-12 items-center justify-center rounded border-2 border-white bg-blue-500 px-8 text-lg font-semibold text-white hover:border-gray-300 md:mb-0"
								@click="onClickExploreFreepik"
							>
								{{ trans('Explore Freepik') }}
							</a>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	</Modal>
	<AiImageVisor
		v-if="showViewer"
		:hide-try-prompt="hideTryPrompt"
		:viewer-mode="viewerMode"
		@select="closeModal"
		@close="onCloseAiVisor"
		@tryprompt="onTryThisPrompt"
	/>
	<CreditsModal v-if="showModalCredits && prices" :packs="prices.packs" @close="showModalCredits = false" />
</template>
<style lang="sass" scoped>
input[type="radio"]:checked
	& + *
		@apply border-2 border-blue-500 slidesgo:border-purple-400 text-blue-500 font-bold

		& + p
			@apply text-blue-500 font-semibold

.animate-star
	animation: animate-star 2s infinite
	@keyframes animate-star
		0%
			opacity: 1

		75%, 100%
			transform: scale(2.5)
			opacity: 0

	&:nth-child(1)
		animation-delay: 0.2s
	&:nth-child(4)
		animation-delay: 0.4s
	&:nth-child(2)
		animation-delay: 0.6s
	&:nth-child(5)
		animation-delay: 0.8s
	&:nth-child(3)
		animation-delay: 1s
</style>
