<script setup lang="ts">
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import NumberInput from '@/common/components/NumberInput.vue';
import Element from '@/elements/element/classes/Element';
import { TransformTools } from '@/elements/element/utils/TransformTools';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { useI18n } from '@/i18n/useI18n';
import { Size } from '@/Types/types';

const props = defineProps<{ elements: Element[] }>();

const elements = toRef(props, 'elements');

const { sizeWithRotation: size, resize } = useGroupTransform(elements);
const { trans } = useI18n();

const onUpdate = (key: keyof Size, val: number) => {
	let newSize = {
		width: 0,
		height: 0,
	};

	if (key === 'width') {
		newSize = TransformTools.getSizeKeepingAspectRatioByWidth(size.value, val);
	}

	if (key === 'height') {
		newSize = TransformTools.getSizeKeepingAspectRatioByHeight(size.value, val);
	}

	resize(newSize.width);
};

const trackTypingEvent = (category: string) => {
	GAnalytics.track('change', 'Button', `change-${category}`, null);
};

const trackStepEvent = (category: string) => {
	GAnalytics.track('click', 'Button', `change-${category}`, null);
};
</script>

<template>
	<h4 class="mt-3 text-sm font-bold uppercase text-gray-100 opacity-75">Size</h4>
	<div class="mb-6 flex flex-col">
		<div class="mb-3 flex items-center gap-3">
			<div class="flex flex-col">
				<NumberInput
					class="h-10 text-sm"
					data-testid="input-width"
					:max="Infinity"
					:min="1"
					:value="size.width"
					@track-step="trackStepEvent('group-width')"
					@track-typing="trackTypingEvent('group-width')"
					@update="(width: number) => onUpdate('width', width)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">{{ trans('Width') }}</label>
			</div>
			<div class="flex flex-col">
				<NumberInput
					class="h-10 text-sm"
					data-testid="input-height"
					:max="Infinity"
					:min="1"
					:value="size.height"
					@track-step="trackStepEvent('group-height')"
					@track-typing="trackTypingEvent('group-height')"
					@update="(height: number) => onUpdate('height', height)"
				/>
				<label class="mt-1 pl-1 text-2xs text-gray-100">{{ trans('Height') }}</label>
			</div>
		</div>
	</div>
</template>
