import { OnResize } from 'moveable';
import { Ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { DomNodesText } from '@/elements/element/dom/DomNodesText';
import { Text } from '@/elements/texts/text/classes/Text';
import EventTools from '@/interactions/classes/EventTools';

export const useInteractiveText = (element: Ref<Text>) => {
	// Guardamos a que escala esta el texto seleccionado al comienzo para tenerlo en cuenta
	// en el momento en el que lo queramos actualizar
	let initialScale: any = {};
	let initialWidth: any = {};
	let shouldRestrict: any = {};
	const { isIOS } = useDeviceInfo();
	const keepInitialValues = () => {
		initialScale[element.value.id] = element.value.scale;
		initialWidth[element.value.id] = element.value.size.width;
	};

	const resetInitValues = () => {
		// Si es un texto, al comienzo del resize actualizamos la escala en la que estaba
		initialScale = {};
		initialWidth = {};
		shouldRestrict = {};
	};

	const resizeHandler = (ev: OnResize) => {
		const { delta, width, height, drag } = ev;
		const { translate } = drag;
		const textNode = new DomNodesText(element.value);

		if (!EventTools.isCornerEvent(ev) && !element.value.isValidSize({ width, height })) return;

		if (EventTools.isCornerEvent(ev)) {
			if (shouldRestrict[element.value.id] && delta[0] > 0) {
				delete shouldRestrict[element.value.id];
			}

			if (!shouldRestrict[element.value.id]) {
				shouldRestrict[element.value.id] = delta[0] < 0 && !element.value.isValidSize({ width, height });
			}

			if (shouldRestrict[element.value.id]) return;

			let widthToApply = width;
			if (widthToApply < element.value.minBoxWidth) {
				widthToApply = element.value.minBoxWidth;
			}
			const scaleToApply = (widthToApply * initialScale[element.value.id]) / initialWidth[element.value.id];
			textNode.setScaleToFinalText(scaleToApply, isIOS.value);
			return;
		}
		textNode.setSizeToFinalTextNode(width, isIOS.value);
	};

	return {
		keepInitialValues,
		resetInitValues,
		resizeHandler,
	};
};
