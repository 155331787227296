<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useI18n } from '@/i18n/useI18n';
import OnClickOutsideWithPortals from '@/interactions/components/OnClickOutsideWithPortals';
import { EditPanels } from '@/Types/types';

usePanelManagement();
const props = defineProps<{ element: Text }>();

const element = toRef(props, 'element');

const { hasOutline } = useTextEffects(element);
const { togglePanel } = usePanelManagement();
const { trans } = useI18n();

const onClickTextBorderBtn = () => togglePanel(EditPanels.TextBorder, props.element);
</script>

<template>
	<button
		data-testid="outline-text"
		class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between hover:text-white lg:h-8 lg:w-8 lg:justify-center"
		:class="{
			'text-white': hasOutline,
			'text-gray-100': !hasOutline,
		}"
		tooltip="Text shadow"
		tooltip-position="top"
		@click="onClickTextBorderBtn"
	>
		<span
			v-if="hasOutline"
			class="absolute top-0 right-2 h-2 w-2 rounded-full border border-blue-400 bg-blue-500 slidesgo:bg-purple-400"
		></span>
		<SvgIcon name="outline" class="h-5 w-5 lg:h-4 lg:w-4" />
		<p class="text-2xs lg:hidden">{{ trans('Outline') }}</p>
	</button>
</template>
