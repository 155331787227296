<script setup lang="ts">
import { useDebounceFn } from '@vueuse/shared';
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue';

import { useBugsnag } from '@/analytics/bugsnag/composables/useBugsnag';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import GradientPicker from '@/color/components/GradientPicker.vue';
import SolidColorPicker from '@/color/components/SolidColorPicker.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

// Props
const props = defineProps<{
	color: Color;
	hideAlpha?: boolean;
	hideGradient?: boolean;
	hidePalettes?: boolean;
}>();

// Emits
const emit = defineEmits<{
	(e: 'select', color: Color | Color[] | null): void;
	(e: 'change', color: Color): void;
	(e: 'track'): void;
}>();

// Constants
const rgbaGridTemplateColumns = `repeat(${props.hideAlpha ? '5' : '6'}, minmax(0, 1fr))`;
const rgbaAlphaChannelDisplay = props.hideAlpha ? 'none' : 'initial';
const { breadScrumbWithDebounce } = useBugsnag();
const { trans } = useI18n();
const { isMobile } = useDeviceInfo();

// Data
const currentColor = ref();

// Computeds
const finalColor = computed(() => (Array.isArray(props.color) ? props.color[0] : props.color));
const isGradient = computed(() => {
	return finalColor.value instanceof GradientColor;
});

const checkForColorPickers = async () => {
	await nextTick();
	requestAnimationFrame(async () => {
		document.documentElement.classList.toggle('color-picking', !!document.querySelector('[data-color-picker]'));
	});
};

onBeforeUnmount(async () => {
	checkForColorPickers();
});

const changeColor = (color: Color) => {
	currentColor.value = color;
	breadScrumbWithDebounce(color);
	emit('track');
	emit('change', color);
};

watch(
	currentColor,
	useDebounceFn(() => {
		GAnalytics.track('click', 'Button', 'colorpicker', null);
	}, 100)
);

const gradientToSolid = (gradient: GradientColor) => {
	if (!isGradient.value) return;
	emit('change', gradient.convertToSolidColor());
};

const solidToGradient = (color: SolidColor) => {
	if (isGradient.value) return;
	emit('change', color.convertToGradient());
};
</script>

<template>
	<div>
		<div v-if="!hideGradient" class="color-type mb-2 flex">
			<button
				class="h-6 flex-1 text-xs font-bold uppercase"
				:class="{
					rounded: !isMobile,
					'rounded-full': isMobile,
					'bg-gray-600 text-white mockup:bg-fp-gray-200 mockup:text-fp-gray-700': !isGradient,
					'text-gray-100 hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-700': isGradient,
				}"
				@click="gradientToSolid(finalColor as GradientColor)"
			>
				{{ trans('Solid') }}
			</button>

			<button
				class="h-6 flex-1 text-xs font-bold uppercase"
				:class="{
					rounded: !isMobile,
					'rounded-full': isMobile,
					'bg-gray-600 text-white mockup:bg-fp-gray-200 mockup:text-fp-gray-700': isGradient,
					'text-gray-100 hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-700': !isGradient,
				}"
				@click="solidToGradient(finalColor as SolidColor)"
			>
				{{ trans('Gradient') }}
			</button>
		</div>

		<div>
			<GradientPicker
				v-if="isGradient"
				:gradient="(finalColor as GradientColor)"
				:hide-alpha="hideAlpha"
				:hide-palettes="hidePalettes"
				@change="changeColor"
			/>

			<SolidColorPicker
				v-else
				:color="(finalColor as SolidColor)"
				:hide-gradient="hideGradient"
				:hide-alpha="hideAlpha"
				:hide-palettes="hidePalettes"
				@change="changeColor"
			/>
		</div>
	</div>
</template>

<style lang="scss">
.vc-sketch .vc-sketch-field {
	grid-template-columns: v-bind(rgbaGridTemplateColumns) !important;

	.vc-sketch-field--single:last-of-type {
		display: v-bind(rgbaAlphaChannelDisplay) !important;
	}
}
</style>
