<script setup lang="ts">
import Bugsnag from '@bugsnag/js';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';
import { useArtboard } from '@/project/composables/useArtboard';
import { Artboard } from '@/Types/types';

const { trans } = useI18n();
const { setArtboardSize } = useArtboard();
defineProps<{
	preset: Artboard;
}>();

const emit = defineEmits(['close']);

const getPresetIconName = (name: string) => `artboards-${name.toLowerCase().replace(/ /g, '_')}`;
const onUpdateArtboard = async (preset: Artboard) => {
	emit('close');
	setArtboardSize(preset.width, preset.height, preset.unit);
	GAnalytics.track('click', 'select', 'change-artboard-size', null);
	Bugsnag.leaveBreadcrumb(`Update artboard to ${preset.name}: ${preset.width}, ${preset.height}, ${preset.unit}`);
};
</script>

<template>
	<button
		:data-testid="getPresetIconName(preset.name)"
		class="flex w-full cursor-pointer justify-between py-2 pl-4 pr-1 text-left text-sm text-gray-600 hover:bg-gray-100/25 hover:text-gray-800 lg:items-center"
		@click="onUpdateArtboard(preset)"
	>
		<SvgIcon :name="getPresetIconName(preset.name)" class="mr-2 mt-1 h-3.5 w-3.5 shrink-0 text-gray-800 lg:mt-0" />
		<div class="flex w-full flex-col lg:flex-row lg:items-center">
			<span class="flex-1">{{ trans(preset.name) }}</span>
			<span class="text-xs text-gray-500 opacity-75">
				{{ Math.round(preset.width) }}x{{ Math.round(preset.height) }}{{ preset.unit }}
			</span>
		</div>
	</button>
</template>
