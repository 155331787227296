<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import WithAuth from '@/auth/components/WithAuth.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import FacebookModal from '@/export/share/components/FacebookModal.vue';
import InstagramModal from '@/export/share/components/InstagramModal.vue';
import PinterestModal from '@/export/share/components/PinterestModal.vue';
import ShareModal from '@/export/share/components/ShareModal.vue';
import TwitterModal from '@/export/share/components/TwitterModal.vue';
import { useShare } from '@/export/share/composables/useShare';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { ShareModalType } from '@/Types/types';

const { trans } = useI18n();
const project = useProjectStore();
const { rrssProfilePages, socialMediaModal, step } = useSharePublish();
const { onAuth } = useShare();

const showModal = ref<null | ShareModalType>(null);
const showShareModal = () => {
	showModal.value = ShareModalType.share;
	Bugsnag.leaveBreadcrumb(`Open share modal`);

	const projectName = project.name?.length ? project.name : 'custom-artboard';

	GAnalytics.track('click-share', 'Template', projectName, null);
};

const onGoBack = () => {
	showModal.value = ShareModalType.share;
};
const onGoSocialMediaModal = (socialMedia: ShareModalType) => {
	showModal.value = socialMedia;
	socialMediaModal.value = null;
};

onAuth((value) => {
	openSocialMediaDownload(value);
});

const openSocialMediaDownload = (socialMedia: ShareModalType) => {
	showModal.value = ShareModalType.share;
	socialMediaModal.value = socialMedia;
};

const onCloseModal = () => {
	showModal.value = null;
	socialMediaModal.value = null;
	rrssProfilePages.value = null;
	step.value = 1;
};
const onCloseSocialModal = () => {
	showModal.value = step.value === 2 ? ShareModalType.share : null;
};
</script>

<template>
	<div>
		<button
			data-testid="btn-share"
			data-onboarding="btn-share"
			class="btn-share relative flex h-8 w-8 items-center justify-center rounded bg-blue-500 text-white slidesgo:bg-purple-400 lg:h-10 lg:w-10"
			:tooltip="trans('Share')"
			tooltip-position="bottom"
			@click="showShareModal"
		>
			<SvgIcon name="share" class="h-4 w-4" />
		</button>
		<WithAuth v-if="showModal" @close="onCloseModal">
			<ShareModal
				v-if="showModal === ShareModalType.share"
				@goSocialMediaModal="onGoSocialMediaModal"
				@close="onCloseModal"
			/>

			<InstagramModal
				v-else-if="showModal === ShareModalType.instagram && project.pages.length"
				@close="onCloseSocialModal"
				@go-back="onGoBack"
				@on-auth="openSocialMediaDownload(ShareModalType.instagram)"
			/>

			<FacebookModal
				v-else-if="showModal === ShareModalType.facebook && project.pages.length"
				class="ignore-click"
				@close="onCloseSocialModal"
				@go-back="onGoBack"
				@on-auth="openSocialMediaDownload(ShareModalType.facebook)"
			/>
			<PinterestModal
				v-else-if="showModal === ShareModalType.pinterest && project.pages.length"
				class="ignore-click"
				@close="onCloseSocialModal"
				@go-back="onGoBack"
				@on-auth="openSocialMediaDownload(ShareModalType.pinterest)"
			/>
			<TwitterModal
				v-else-if="showModal === ShareModalType.twitter && project.pages.length"
				class="ignore-click"
				@close="onCloseSocialModal"
				@go-back="onGoBack"
				@on-auth="openSocialMediaDownload(ShareModalType.twitter)"
			/>
		</WithAuth>
	</div>
</template>
