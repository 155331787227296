<script lang="ts" setup>
import { until } from '@vueuse/core';
import { computed, onMounted } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAuth } from '@/auth/composables/useAuth';
import PagesListbox from '@/common/components/PagesListbox.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import DownloadOptionsButtons from '@/export/download/components/DownloadOptionsButtons.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectControls } from '@/project/composables/useProjectControls';
import { useProjectStore } from '@/project/stores/project';
import { DownloadFormat } from '@/Types/types';

const emits = defineEmits(['error', 'close']);
const props = defineProps<{ templateDistribution: string }>();

const { download, selectedPages, downloadFormat, multiplyFactor, downloadOptions } = useDownloadsProject();
const { isPhotoMode } = useEditorMode();
const project = useProjectStore();
const { overLimitToDownload } = useProjectControls();

const store = useMainStore();
const { trans } = useI18n();
const { requireAuth, isLogged } = useAuth();
const { isValidSizeToDownload } = useArtboard();

const showWarning = computed(() => {
	return (
		!isValidSizeToDownload(
			(project.size.width * multiplyFactor.value) / store.scaleMaxAllowedSize,
			(project.size.height * multiplyFactor.value) / store.scaleMaxAllowedSize,
			project.unit
		) && downloadFormat.value !== DownloadFormat.pdf
	);
});

onMounted(() => {
	if (project.allVideos.length && !overLimitToDownload.value && !isPhotoMode.value) {
		downloadFormat.value = DownloadFormat.mp4;
	}

	// miramos si el artboard está en px y si es así, cambiamos el formato de descarga a .png
	if (project.unit === 'px') {
		downloadFormat.value = DownloadFormat.png;
	}

	if (isPhotoMode.value) {
		downloadFormat.value = DownloadFormat.jpg;
	}
	if (props.templateDistribution === 'present' || project.unit === 'mm') {
		downloadFormat.value = DownloadFormat.pdf;
	}

	multiplyFactor.value = 1;
});

const downloadJpg = () => {
	GAnalytics.track('download-jpg', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.jpg);
};

const downloadPng = () => {
	GAnalytics.track('download-png', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.png);
};

const downloadTpng = () => {
	GAnalytics.track(
		'download-png-transparent',
		'Template',
		project.name?.length ? project.name : 'custom-artboard',
		null
	);
	downloadProject(DownloadFormat.tpng);
};

const downloadPdf = () => {
	GAnalytics.track('download-pdf', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.pdf);
};

const downloadVideo = async () => {
	requireAuth();
	await until(isLogged).toBeTruthy();

	GAnalytics.track('download-pdf', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.mp4);
};

const downloadPptx = async () => {
	GAnalytics.track('download-pptx', 'Template', project.name?.length ? project.name : 'custom-artboard', null);
	downloadProject(DownloadFormat.pptx);
};

const downloadProject = async (format: DownloadFormat) => {
	let downloadWithoutErrors = true;

	try {
		await download(
			format,
			selectedPages.value.map((sP) => sP.id)
		);
	} catch (er) {
		downloadWithoutErrors = false;
		emits('error');
	}

	GAnalytics.trackGA4('file_download_global', {
		file_extension: downloadFormat.value,
		file_name: project.name?.toLowerCase() || 'custom-artboard',
		file_id: project.id,
		category: project.category || '',
		license: 'free',
		location: 'editor',
		success_event: `${downloadWithoutErrors}`,
	});
};

const setSelectedFormat = (format: DownloadFormat) => {
	if (downloadFormat.value === format) return;
	multiplyFactor.value = 1;
	downloadFormat.value = format;
	GAnalytics.track('click', 'Download', `download-format-${format}`, null);
};

const downloadFile = async () => {
	GAnalytics.track('click', 'Download', `download-button`, null);

	GAnalytics.trackGA4('file_download_button', {
		file_extension: downloadFormat.value,
		file_name: project.name || 'custom-artboard',
		file_id: project.id,
		category: project.category || '',
		location: window.location.href,
		author_name: store.user?.name || 'anonymous',
	});

	const downloadFunctions: { [key in Exclude<DownloadFormat, 'app'>]: () => Promise<void> | void } = {
		[DownloadFormat.jpg]: downloadJpg,
		[DownloadFormat.png]: downloadPng,
		[DownloadFormat.tpng]: downloadTpng,
		[DownloadFormat.pdf]: downloadPdf,
		[DownloadFormat.mp4]: downloadVideo,
		[DownloadFormat.pptx]: downloadPptx,
	};

	const selectedDownloadFunction = downloadFunctions[downloadFormat.value as Exclude<DownloadFormat, 'app'>];

	if (selectedDownloadFunction) {
		if (downloadFormat.value === DownloadFormat.mp4) {
			await selectedDownloadFunction();
		} else {
			selectedDownloadFunction();
		}
	}

	emits('close');
};

const setMultiplySize = (val: number) => {
	multiplyFactor.value = val;
};
</script>

<template>
	<div class="relative w-full">
		<p class="mb-4 text-lg font-semibold text-gray-700">{{ trans('Download your project') }}</p>
		<PagesListbox :multiple="true" />
		<div class="mt-4 flex flex-col gap-4">
			<DownloadOptionsButtons
				:options="downloadOptions"
				:selected="downloadFormat"
				@change="setMultiplySize"
				@selected="setSelectedFormat"
			></DownloadOptionsButtons>
			<!-- Download button -->
			<button
				class="flex h-10 w-full items-center justify-center rounded bg-green-500 font-semibold text-white hover:bg-green-600"
				:class="{ 'pointer-events-none opacity-50': selectedPages.length === 0 }"
				@click="downloadFile"
			>
				<SvgIcon name="download" class="mr-2 h-4 w-4" />
				{{ trans('Download') }}
			</button>
			<div v-if="showWarning" class="flex rounded bg-yellow-600/20 px-3 py-2 text-center text-sm text-gray-800">
				<SvgIcon name="alert" class="mr-2 mt-1 h-4 w-4 shrink-0 text-yellow-800" />
				<p class="text-left leading-5">
					{{
						trans('This template exceeds our maximum download resolution (4k), so it will be downloaded at a lower one')
					}}
				</p>
			</div>
		</div>
	</div>
</template>
