<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import FlipMenuToolbar from '@/elements/element/components/menus/FlipMenuToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import { EditPanels } from '@/Types/types';

// Props
const props = defineProps<{ element: QRCode }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { isMobile } = useDeviceInfo();
const { togglePanel } = usePanelManagement();

// Methods
const onUpdateColor = (key: 'front' | 'bg', color: SolidColor) => {
	if (key === 'front') {
		element.value.updateFrontColor(color);
	} else {
		element.value.updateBgColor(color);
	}
};

const onClickMoreTools = () => {
	GAnalytics.trackGA4('edit_qr_bar', { category: 'qr', link_text: 'more_tools' });
	Bugsnag.leaveBreadcrumb('Open edit QRcode panel');
	togglePanel(EditPanels.QRCode);
};

const onClickColorPicker = () => {
	GAnalytics.trackGA4('edit_qr_bar', { category: 'qr', link_text: 'color' });
};

const onFlip = () => {
	GAnalytics.trackGA4('edit_qr_bar', { category: 'qr', link_text: 'flip' });
};

const onRemove = () => {
	GAnalytics.trackGA4('edit_qr_bar', { category: 'qr', link_text: 'delete' });
};
</script>

<template>
	<div class="overflow-auto lg:overflow-visible">
		<template v-if="!element.locked">
			<FlipMenuToolbar :element="element" @flip="onFlip" />

			<div
				id="color-picker-qr-toolbar"
				class="order-first mx-2 flex items-center gap-2 py-1 pr-4 lg:order-none lg:mx-0 lg:h-10 lg:py-0 lg:pl-1 lg:pr-2"
				tooltip="Color"
				tooltip-position="top"
			>
				<ColorPicker
					parent=".toolbar"
					placement="right-start"
					class="h-8 w-8 lg:h-5 lg:w-5"
					:color="(element.frontColor as SolidColor)"
					:hide-gradient="true"
					@click="onClickColorPicker"
					@change="(color) => onUpdateColor('front', color as SolidColor)"
				/>

				<ColorPicker
					parent=".toolbar"
					placement="right-start"
					class="h-8 w-8 lg:h-5 lg:w-5"
					:color="(element.bgColor as SolidColor)"
					:hide-gradient="true"
					@click="onClickColorPicker"
					@change="(color) => onUpdateColor('bg', color as SolidColor)"
				/>
			</div>

			<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 lg:block" />

			<!-- Order -->
			<OrderToolbar v-if="isMobile" :element="element" />

			<!-- Copy -->
			<CopyToolbar v-if="isMobile" :element="element" />

			<!-- Remove -->
			<RemoveToolbar :element="element" @remove="onRemove" />

			<MoreToolsToolbar :element="element" :panel="EditPanels.QRCode" @more-tools="onClickMoreTools" />
		</template>
		<LockButtonToolbar v-if="!isMobile && element.locked" :element="element" />
	</div>
</template>
