<script lang="ts" setup>
import { onBeforeUnmount, onMounted } from 'vue';

import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useShare } from '@/export/share/composables/useShare';
import { useI18n } from '@/i18n/useI18n';
import { ShareModalType } from '@/Types/types';

const { trans } = useI18n();
const emits = defineEmits(['close', 'goBack', 'onAuth']);

const store = useMainStore();
const { metaAuthenticated, loading, initMeta, checkFacebookAuthentication } = useShare();

const closeModal = () => {
	if (store.sharing) return;
	emits('close');
};

const goBack = () => {
	if (store.sharing) return;
	emits('goBack');
};

onBeforeUnmount(() => {
	if (metaAuthenticated.value) emits('onAuth');
});

onMounted(async () => {
	await checkFacebookAuthentication();
});
</script>
<template>
	<Modal v-if="!metaAuthenticated" open content-classes="w-full max-w-lg" @close="closeModal">
		<div class="relative max-h-full w-full overflow-auto rounded-lg bg-white py-4 px-4 lg:px-8 lg:pt-8 lg:pb-8">
			<button class="absolute top-2 left-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25" @click="goBack">
				<SvgIcon name="right-small" class="h-5 w-5 rotate-180 text-gray-700" />
			</button>
			<button
				class="absolute top-2 right-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>
			<div class="flex flex-col items-center">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800">
					{{ trans('Publish directly on Facebook!') }}
				</h3>
				<p class="px-6 text-center text-gray-600 lg:px-12">
					{{ trans('Connect your social media to share content in the blink of an eye!') }}
				</p>
				<div class="flex items-center justify-center gap-6 py-10">
					<SvgIcon name="iso" class="h-16 w-16 text-blue-500" />
					<SvgIcon name="left-right" class="h-10 w-10 text-gray-100" />
					<div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#4267B2]">
						<SvgIcon name="facebook" class="h-8 w-8 text-white" />
					</div>
				</div>
				<button
					class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
					@click="() => initMeta(ShareModalType.facebook)"
				>
					<span v-if="!loading">{{ trans('Connect with Facebook') }}</span>
					<SvgIcon v-if="loading" name="spinner" class="mx-auto h-7 w-7 animate-spin" />
				</button>
			</div>
		</div>
	</Modal>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
