// @ts-ignore
import Queue from 'queue-promise';
const queues: { [id: string]: Array<() => void> } = {};

export const useTaskQueue = (queueName: string, callback: () => void, delayInMs: number) => {
	if (!queues[queueName]) {
		queues[queueName] = [];

		setInterval(() => {
			const callback = queues[queueName].shift();

			if (!callback) {
				return;
			}

			callback();
		}, delayInMs);
	}

	return new Promise((resolve) => {
		queues[queueName].unshift(() => {
			callback();
			resolve(0);
		});
	});
};

export const RenderQueue = new Queue({
	concurrent: 1,
	interval: 100,
});
