<script lang="ts" setup>
import { isEqual } from 'lodash';
import { computed, onBeforeMount, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import CollectionListbox from '@/common/components/CollectionListbox.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import { ItemList, ShareModalType } from '@/Types/types';

const { trans } = useI18n();

const emits = defineEmits<{ (e: 'share', rrss: ShareModalType): void }>();

const { isValidInstagramArtboard, socialMediaModal } = useSharePublish();

const socialMedia = ref<ItemList[]>([
	{ name: ShareModalType.instagrambusiness, id: '1' },
	{ name: ShareModalType.facebook, id: '2' },
	{ name: ShareModalType.pinterest, id: '3' },
	{ name: ShareModalType.twitter, id: '4' },
]);

const selectedSocial = ref<ItemList[]>([]);

const placeholder = computed(() => {
	if (!selectedSocial.value.length) {
		return trans('Select a channel');
	}

	return TextTools.capitalizeFirstLetter(selectedSocial.value[0].name);
});

onBeforeMount(() => {
	socialMedia.value[0].disabled = !isValidInstagramArtboard.value;

	// Si existe una social Media abierta, la seleccionamos como por defecto
	if (socialMediaModal.value) {
		selectedSocial.value = socialMedia.value.filter(
			(sM) =>
				sM.name === socialMediaModal.value ||
				(sM.name.includes(ShareModalType.instagram) && socialMediaModal.value === ShareModalType.instagram)
		);
	}
});

const isInstagramBussines = (rrss: ShareModalType): rrss is ShareModalType.instagrambusiness =>
	rrss === ShareModalType.instagrambusiness;
const isFacebook = (rrss: string) => rrss === ShareModalType.facebook;
const isTwitter = (rrss: string) => rrss === ShareModalType.twitter;
const isPinterest = (rrss: string) => rrss === ShareModalType.pinterest;

const onSelectListOption = (newVal: ItemList[]) => {
	if (isEqual(selectedSocial.value, newVal)) return;
	if (newVal.length) {
		GAnalytics.track('click', 'Share', `select-socialMedia-${newVal[0].name}`, null);

		const rrss = newVal[0].name as ShareModalType;

		// Reemplazar por instagram bussines si en algún momento se separan las modales
		if (isInstagramBussines(rrss)) {
			emits('share', ShareModalType.instagram);
		} else {
			emits('share', ShareModalType[rrss]);
		}
	}

	selectedSocial.value = newVal;
};
</script>
<template>
	<div class="relative">
		<CollectionListbox :collection="socialMedia" :placeholder="placeholder" @selected="onSelectListOption">
			<template #item="{ item }">
				<div>
					<button
						v-if="isInstagramBussines(item.name as ShareModalType)"
						data-testid="instagram"
						class="mb-1 mr-2 flex h-8 w-8 items-center justify-center rounded"
						:style="{
							'background-image': `linear-gradient(
										135deg,
										#405de6,
										#833ab4,
										#c13584,
										#e1306c,
										#fd1d1d,
										#f56040,
										#f77737,
										#fcaf45,
										#ffdc80
									)
									`,
						}"
					>
						<SvgIcon name="instagram" class="h-4 w-4 text-white" />
					</button>
					<button
						v-if="isFacebook(item.name)"
						data-testid="facebook"
						class="mb-1 mr-2 flex h-8 w-8 items-center justify-center rounded bg-[#4267B2]"
					>
						<SvgIcon name="facebook" class="h-4 w-4 text-white" />
					</button>
					<button
						v-if="isPinterest(item.name)"
						data-testid="pinterest"
						class="mb-1 mr-2 flex h-8 w-8 items-center justify-center rounded bg-[#E60023]"
					>
						<SvgIcon name="pinterest" class="h-4 w-4 text-white" />
					</button>
					<button
						v-if="isTwitter(item.name)"
						data-testid="twitter"
						class="mb-1 mr-2 flex h-8 w-8 items-center justify-center rounded bg-[#03A9F4]"
					>
						<SvgIcon name="twitter" class="h-4 w-4 text-white" />
					</button>
				</div>
			</template>
		</CollectionListbox>
	</div>
</template>
