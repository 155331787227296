import { createSharedComposable, useUrlSearchParams } from '@vueuse/core';
import { computed, Ref, ref } from 'vue';

import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { useMainStore } from '@/editor/stores/store';
import { Panels } from '@/Types/types';

export type EditorMode = 'editor' | 'photo' | 'admin' | 'predefined-text' | 'tag' | 'slidesgo';
export type EditorContext = 'wepik' | 'slidesgo' | 'report' | 'illustrator' | 'embedded';

export const useEditorMode = createSharedComposable(() => {
	const store = useMainStore();
	const mode: Ref<EditorMode> = ref('editor');
	const context: Ref<EditorContext> = ref('wepik');

	const isEditorMode = computed(() => mode.value === 'editor');
	const isAdminMode = computed(() => mode.value === 'admin');
	const isPhotoMode = computed(() => mode.value === 'photo');
	const isPredefinedTextMode = computed(() => mode.value === 'predefined-text');
	const isTagMode = computed(() => mode.value === 'tag');
	const isSlidesgoMode = computed(() => mode.value === 'slidesgo');
	const isWepikContext = computed(() => window.location.host.includes('wepik'));
	const inSlidesgoContext = computed(() => context.value === 'slidesgo');
	const isIllustratorContext = computed(() => context.value === 'illustrator');
	const isEmbeddedContext = computed(() => context.value === 'embedded');
	const isReportContext = computed(() => context.value === 'report');
	const isRenderingContext = window.EDITOR_MODE === 'RENDER';
	const isViewerContext = window.EDITOR_MODE === 'VIEWER';
	const isCypressContext = computed(
		() => window.Cypress !== undefined && !localStorage.getItem('loginRequiredForRemoveBgTest')
	);
	const urlParams = useUrlSearchParams<{
		photo?: string;
		admin?: number;
		illustrator?: number;
		debug?: number;
		'debug-panel'?: number;
		flag?: string;
		convert?: number;
		mode?: string;
		project?: string;
		report?: string;
		panel?: string;
		brandkit?: string;
		brandkitcollection?: string;
		height?: number;
		width?: number;
		unit?: string;
		format?: string;
		api_key?: string;
		version?: string;
		category?: string;
		svg?: string;
		json?: string;
		data?: string;
	}>();
	const { MODE } = useEnvSettings();
	const isDebugPanel = computed(() => urlParams['debug-panel']);
	const isDebugMode = computed(() => urlParams.debug);

	const flag = computed(() => urlParams.flag);
	const isDevelopmentEnvironment = MODE === 'development';
	const isFreepikContext = computed(() => {
		return isFreepikContextDetect();
	});
	const cypressContextData = computed(() => {
		return {
			svg: urlParams.svg,
			json: urlParams.json,
			data: urlParams.data,
		};
	});
	const embeddedContextData = computed(() => {
		return {
			width: urlParams.width || 1000,
			height: urlParams.height || 1000,
			unit: urlParams.unit || 'px',
			format: urlParams.format || 'jpeg',
			apiKey: urlParams.api_key,
			version: urlParams.version,
			category: urlParams.category,
			aspectRatio:
				urlParams.width && urlParams.height ? parseFloat((urlParams.width / urlParams.height).toFixed(5)) : null,
		};
	});

	const detectMode = () => {
		if (window.EDITOR_MODE === 'SLIDESGO') {
			mode.value = 'slidesgo';
		} else if (window.EDITOR_MODE === 'PHOTO') {
			mode.value = 'photo';
		} else if (urlParams.admin && urlParams.mode === 'predefined-text') {
			mode.value = 'predefined-text';
		} else if (urlParams.admin) {
			mode.value = 'admin';
		}
	};

	const detectContext = () => {
		if (
			window.location.hostname.includes('slidesgo') ||
			urlParams.project === 'slidesgo' ||
			store.userVector?.project === 'slidesgo'
		) {
			context.value = 'slidesgo';
		} else if (urlParams.illustrator == 1) {
			context.value = 'illustrator';
		} else if (urlParams.height && urlParams.width && urlParams.unit && urlParams.api_key) {
			context.value = 'embedded';
		}

		if (urlParams.report && urlParams.admin) {
			context.value = 'report';
		}
	};

	const detectPanel = () => {
		if (mode.value === 'photo') {
			store.activePanel = Panels.photoEdit;
		} else if (urlParams.panel === 'AI') {
			store.activePanel = Panels.moreTools;
		} else if (context.value === 'illustrator') {
			store.activePanel = Panels.treeEditor;
		} else if (urlParams.brandkit) {
			store.activePanel = Panels.brandKits;
		}
	};

	const initMode = () => {
		detectMode();
		detectContext();
		detectPanel();
	};

	return {
		mode,
		detect: initMode,
		isEditorMode,
		isAdminMode,
		isPhotoMode,
		isPredefinedTextMode,
		isTagMode,
		isDebugMode,
		isSlidesgoMode,
		isDebugPanel,
		isWepikContext,
		inSlidesgoContext,
		isIllustratorContext,
		isEmbeddedContext,
		isRenderingContext,
		isReportContext,
		isViewerContext,
		flag,
		isDevelopmentEnvironment,
		isCypressContext,
		isFreepikContext,
		embeddedContextData,
		cypressContextData,
		urlParams,
	};
});

export const isFreepikContextDetect = () =>
	window.location.hostname.includes('freepik.') || window.location.search.includes('freepik=1');
