<script lang="ts" setup>
import { useDebounceFn, useElementBounding, useTemplateRefsList } from '@vueuse/core';
import { parseInt } from 'lodash-es';
import { API } from 'nouislider';
import { computed, InputHTMLAttributes, onBeforeUnmount, Ref, ref, toRef, watch } from 'vue';

import MultiInputRange from '@/common/components/MultiInputRange.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { TimeTools } from '@/common/utils/TimeTools';
import { useMainStore } from '@/editor/stores/store';
import { Video } from '@/elements/medias/video/classes/Video';
import { useVideoControls } from '@/elements/medias/video/composables/useVideoControls';
import MathTools from '@/utils/classes/MathTools';
const store = useMainStore();
const element = toRef(store, 'croppingTime');

const timelineContainer = ref();
const { width: containerWidth } = useElementBounding(timelineContainer);
const { isIOS } = useDeviceInfo();

const props = defineProps<{
	width: number;
	left: number;
}>();

const { isMobile } = useDeviceInfo();
if (!isMobile.value) {
	store.editPanel = null;
}

const ratio = computed(() => {
	if (!element.value) return 0;

	return element.value.hasCrop()
		? element.value.crop.size.width / element.value.crop.size.height
		: element.value?.size.width / element.value?.size.height;
});

const heightImages = 40; // h-10 -> 2.5rem -> 40px
const widthImages = computed(() => heightImages * ratio.value);

const images = computed(() => {
	if (!duration.value) {
		return [];
	}
	const secondsArray = MathTools.range(0, Math.round(duration.value));
	const numOfImages = secondsArray.length;
	const numOfNeededImages = containerWidth.value / widthImages.value || 0;

	// Si el número de imagenes es superior al número que vamos a mostrar tenemos que quedarnos solo con el número a mostrar
	// intercalando las elegidas para que visualmente el usuario vea los keyframes de su video al completo
	if (numOfImages > numOfNeededImages) {
		const toRemove = numOfImages / numOfNeededImages;

		const module = Math.round(numOfImages / toRemove);

		const imagesKeeped = secondsArray
			// Eliminamos las que su modulo sea distinto de 0, de esta forma estamos eliminando una primera parte de imagenes
			// que no necesitamos de manera intercalada y en el siguiente paso podemos trabajar con un número de imagenes
			// que es múltiplo de las que debemos mantener
			.filter((_, i) => i % module !== 0)
			// De las que quedan ahora vamos a eliminar las que sobran de dividir las que tenemos entre las que necesitamos
			// esto va a ser seguramente un resultado con decimales, pero como no podemos eliminar 1 imagen y parte de otra
			// sino que tienen que ser enteras, lo redondeamos para trabajar con cifras enteras
			.filter((_, i) => i % Math.round(toRemove) === 0);
		const discarted = imagesKeeped.filter((_, i) => i % Math.round(toRemove) !== 0);

		// Como resultado del redondeo anterior puede haber casos en el que estemos eliminando una de mas y casos en los que
		// eliminemos una de menos, corregimos el caso concreto añadiendo una de las descartadas en la posición que le corresponde
		const needMore = numOfNeededImages > imagesKeeped?.length;
		const needLess = numOfNeededImages < imagesKeeped?.length;

		if (needMore) {
			Array.from({ length: numOfNeededImages - imagesKeeped?.length }).forEach((_, i) => {
				const positionToInsert = Math.round(toRemove) * (i + 1);
				if (i % 2 === 0) {
					imagesKeeped?.splice(positionToInsert, 0, discarted[i] || imagesKeeped[i]);
					return;
				}

				imagesKeeped?.splice(imagesKeeped.length - positionToInsert, 0, discarted[discarted.length - i]);
			});
		}

		if (needLess) {
			Array.from({ length: numOfNeededImages - imagesKeeped?.length }).forEach((_, i) => {
				const positionToInsert = Math.round(toRemove) * (i + 1);
				if (i % 2 === 0) {
					imagesKeeped?.splice(positionToInsert, 1);
					return;
				}

				imagesKeeped?.splice(imagesKeeped.length - positionToInsert, 1);
			});
		}

		return imagesKeeped;
	}

	// en el caso de que necesitemos mas imagenes vamos a repetir cada una de ellas tantas veces como sea necesario
	const repeatedPerImage = Math.round(numOfNeededImages / numOfImages);

	return []
		.concat(...Array.from({ length: repeatedPerImage }, () => secondsArray))
		.sort((a, b) => secondsArray.indexOf(a) - secondsArray.indexOf(b));
});

const {
	playing,
	duration,
	durationInMS,
	currentTime,
	currentTimeInMS,
	formatedCroppedDurationTime,
	formatedTotalDurationTime,
	togglePlayVideo,
	currentTotalTimePercentage,
	currentStartPercentage,
	currentEndPercentage,
	src,
} = useVideoControls(element as Ref<Video>);

const applyCropTime = () => {
	store.croppingTimeId = null;
};

// Al cerrar reseteamos el currentTime al inicio ya que se ha tocado el cropend el tiempo de reproducción estará en este
onBeforeUnmount(() => {
	currentTime.value = TimeTools.toSeconds(element.value?.cropTime.start || 0);
});

const updateTimeEndCrop = useDebounceFn((e: InputEvent) => {
	if (!e.target) return;

	const val = (e.target as InputHTMLAttributes).value;

	fromInput.value = true;
	const time = val.split(':');
	const minutes = parseInt(time[0]);
	const seconds = parseInt(time[1]);

	const totalMilliseconds = TimeTools.toMS(minutes * 60 + seconds);

	if (totalMilliseconds > durationInMS.value) {
		element.value!.cropTime.end = durationInMS.value;
		if (e.target instanceof HTMLInputElement) {
			e.target.value = formatedTotalDurationTime.value;
		}
		return;
	}
	element.value!.cropTime.end = totalMilliseconds;
}, 300);

type CropProperties = {
	positions: number[];
	values: number[];
};

const fromInput = ref(false);
const update = (e: CropProperties) => {
	element.value!.cropTime.start = e.values[0];
	element.value!.cropTime.end = e.values[1];
};

const startTime = computed(() => element.value?.cropTime.start || 0);
const endTime = computed(() => element.value?.cropTime.end);
const multiRangeInstance = ref<API>();

watch(startTime, (start) => {
	if (typeof start !== 'number') return;
	currentTime.value = TimeTools.toSeconds(start);
});

watch(endTime, (end) => {
	if (typeof end !== 'number') return;
	currentTime.value = TimeTools.toSeconds(end);

	if (multiRangeInstance.value && fromInput.value) {
		multiRangeInstance.value.set([startTime.value, currentTimeInMS.value]);
		fromInput.value = false;
	}
});

const setMultiInputRangeInstance = (instance: API) => (multiRangeInstance.value = instance);

// Si se abre un panel de edición en mobile confirmamos crop y salimos del modo croptime
watch(
	() => store.editPanel,
	() => {
		if (!isMobile.value) return;
		applyCropTime();
	}
);

const videosPreviews = useTemplateRefsList<HTMLVideoElement>();

watch(
	videosPreviews,
	(prev, post) => {
		const currentTimes = videosPreviews.value.map((v) => v.currentTime);
		if (prev.length === post.length && currentTimes.some((time) => time)) return;
		videosPreviews.value.forEach((v, i) => {
			v.currentTime = images.value[i];
		});
	},
	{ deep: true }
);
</script>

<template>
	<div
		class="toolbar crop-time fixed left-0 right-0 top-14 z-30 flex items-center gap-4 border-b border-gray-700 bg-gray-900/95 p-2 text-white backdrop-blur lg:gap-2 lg:px-4 lg:py-2"
		:style="{ width: `${props.width}px`, left: `${props.left}px` }"
	>
		<button class="h-full pr-1 text-gray-100 hover:text-white" @click="togglePlayVideo">
			<SvgIcon :name="playing ? 'pause' : 'play'" class="h-5 w-5" />
		</button>
		<!--		<button class="px-2 text-gray-100 hover:text-white">{{ formatedCurrentTime }}</button>-->
		<input
			type="time"
			:value="formatedCroppedDurationTime"
			class="hidden cursor-text items-center justify-center rounded-md bg-transparent px-2 py-1 text-gray-100 focus:bg-gray-800 focus:outline-none hover:text-white lg:flex"
			@input="(e) => updateTimeEndCrop(e as InputEvent)"
		/>
		<div ref="timelineContainer" class="relative flex h-10 flex-1">
			<div class="absolute flex h-full w-full flex-1" :class="!images.length ? 'bg-gray-700' : ''">
				<template v-if="images.length">
					<div v-for="keyframe in images" :key="keyframe" class="h-10 flex-1">
						<video :ref="videosPreviews.set" class="h-full w-full object-cover" :autoplay="isIOS">
							<source :src="src" type="video/mp4" />
						</video>
					</div>
				</template>
			</div>
			<div :style="{ width: `${currentStartPercentage}%` }" class="absolute left-0 h-full w-full bg-gray-900/75"></div>
			<MultiInputRange
				:values="element && element.cropTime ? [element.cropTime.start, element.cropTime.end] : []"
				:min="0"
				:max="durationInMS"
				:step="0.1"
				@update="update"
				@instance="setMultiInputRangeInstance"
			/>
			<div :style="{ width: `${currentEndPercentage}%` }" class="absolute right-0 h-full w-full bg-gray-900/75"></div>
			<span class="absolute h-full w-0.5 rounded bg-gray-900" :style="{ left: `${currentTotalTimePercentage}%` }">
			</span>
		</div>
		<button
			class="group flex items-center justify-center px-2 text-sm text-gray-100 hover:text-white"
			@click="applyCropTime"
		>
			<span class="flex items-center justify-center rounded-full bg-gray-100 p-1 group-hover:bg-white lg:mr-2">
				<SvgIcon name="check" class="h-2 w-2 text-gray-900" />
			</span>
			<span class="hidden lg:block">Done</span>
		</button>
	</div>
</template>

<style lang="sass">
input[type='time']::-webkit-calendar-picker-indicator
	@apply hidden

.noUi-target.noUi-horizontal
	@apply h-10 bg-transparent border-0 shadow-none

	.noUi-handle
		@apply flex h-10 top-0 border-0 shadow-none w-3 -right-1.5 cursor-col-resize items-center justify-center rounded-sm bg-blue-500 slidesgo:bg-purple-400

		&:hover
			@apply bg-blue-400

		&::before,
		&::after
			@apply h-6 bg-white/75 top-2

		&:before
			@apply left-1

		&::after
			@apply left-1.5

		&:active .noUi-tooltip
			@apply opacity-100

		.noUi-tooltip
			@apply -bottom-full border-0 bg-darkblue-300 text-sm px-4 rounded mt-4 text-white opacity-0 pointer-events-none

			&::after
				@apply bottom-full left-1/2 border-4 border-transparent border-b-darkblue-300 content-[''] absolute pointer-events-none -ml-1
</style>
