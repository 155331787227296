import { AnyFn, until, useTimeoutFn, watchOnce } from '@vueuse/core';
import { computed, ComputedRef, onMounted, Ref, ref, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import Element from '@/elements/element/classes/Element';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { Position } from '@/Types/types';

export const useDoubleClick = (element: Ref<Element>, cb: AnyFn) => {
	const node = ref<HTMLElement | null>(null);

	const firstClickElementPos = ref<Position>({ x: 0, y: 0 });
	const firstClick = ref(true);

	const hasSelection = computed(() => selection.value.length);

	const { isTouch } = useDeviceInfo();
	const { isDebugPanel } = useEditorMode();
	const { lockedGroup } = useGroup(element);
	const { isDragging } = useInteractions();
	const { selection, selectionId } = useSelection();

	onMounted(() => {
		node.value = element.value?.domNode()?.querySelector('[data-use-double-click="true"]') || null;
		if (!node.value) return;

		// Touch devices
		if (isTouch.value) {
			node.value.addEventListener('touchend', async (event) => {
				if (isDebugPanel.value) debugTouches.value++;
				debug();
				event.preventDefault();
				const execute = await eventHandler(event);
				if (execute) cb(event);
			});
			return;
		}

		// Desktop
		node.value.addEventListener('dblclick', (event) => {
			event.preventDefault();
			if (isDebugPanel.value) debugTouches.value = -2;
			debug();
			cb(event);
		});
	});

	// Esta función sirve para interpretar los toques sobre el elemento. El segundo click se interpreta
	// como doble click. El primer click se interpreta como un click normal.
	const eventHandler = async (event?: MouseEvent | TouchEvent): Promise<boolean> => {
		// Necesitamos que la store esté actualizada con el valor del elemento seleccionado
		if (isTouch.value) {
			try {
				await until(hasSelection).toBeTruthy({ throwOnTimeout: true, timeout: 5000 });
			} catch (error) {
				return false;
			}
		}

		// Si el elemento ha cambiado de posición entre el primer click y el segundo
		// se da por hecho que no se quiere activar el modo edición,  añadimos un pequeño margen
		// para evitar falsos positivos.
		await until(isDragging).not.toBeTruthy({ timeout: 200 });
		const margin = 5;
		const movedInX = isTouch.value
			? Math.abs(firstClickElementPos.value.x - element.value.position.x) > margin
			: firstClickElementPos.value.x !== element.value.position.x;
		const movedInY = isTouch.value
			? Math.abs(firstClickElementPos.value.y - element.value.position.y) > margin
			: firstClickElementPos.value.y !== element.value.position.y;

		if (movedInX || movedInY || !firstClickElementPos.value.x || !firstClickElementPos.value.y) {
			firstClickElementPos.value = {
				x: element.value.position.x,
				y: element.value.position.y,
			};
			return false;
		}
		if (movedInX || movedInY) {
			firstClick.value = false;
			return false;
		}

		// Controlamos el click derecho
		// MouseEvent.button === 0 es el click izquierdo
		// MouseEvent.button === 1 es el click central
		// MouseEvent.button === 2 es el click derecho
		if (event instanceof MouseEvent && event.button === 2) return false;

		// Comprobamos que el elemento clicado es el mismo que el seleccionado
		if (selectionId.value[0] !== element.value.id) return false;

		// Comprobamos si estamos en un grupo temporal
		if (selectionId.value.length > 1 && lockedGroup.value.length === 0) return false;

		if (!firstClick.value) {
			firstClick.value = true;
			// Comprobamos que cuando se cambie la selección y se pulsa sobre otro texto se pone a false el firstClick
			watchOnce(
				() => selectionId.value[0],
				(newVal) => {
					if (!firstClick.value || newVal === element.value.id) return;
					firstClick.value = false;
				}
			);
			return false;
		}

		firstClick.value = false;
		return true;
	};

	const resetFirstClick = (watching: ComputedRef | Ref) => {
		watchOnce(watching, () => {
			if (firstClick.value) firstClick.value = false;
		});
	};
	const debugNode = ref<HTMLElement | null>(null);
	const debugTouches = ref(0);
	const debug = () => {
		if (!isDebugPanel.value) return;
		if (debugNode.value) debugNode.value.remove();
		debugNode.value = document.createElement('div');
		debugNode.value.style.position = 'absolute';
		debugNode.value.style.top = '50%';
		debugNode.value.style.left = '50%';
		debugNode.value.style.zIndex = '99';
		debugNode.value.style.fontSize = '20rem';
		debugNode.value.style.fontWeight = 'bold';
		debugNode.value.style.pointerEvents = 'none';
		debugNode.value.style.userSelect = 'none';
		debugNode.value.style.transform = 'translate(-50%, -50%)';
		debugNode.value.style.color = 'rgba(0,0,0,0.35)';
		debugNode.value.style.webkitTextStroke = '0.2rem rgba(255,255,255,0.5)';
		debugNode.value.innerHTML = `${debugTouches.value}`;
		document.querySelector('#scroll-area')?.insertAdjacentElement('beforeend', debugNode.value);
		useTimeoutFn(() => {
			debugNode.value?.remove();
		}, 500);
	};
	if (isDebugPanel.value) {
		watch(firstClick, (newVal) => {
			debugTouches.value = newVal ? 1 : 0;
		});
	}

	return { firstClick, resetFirstClick };
};
