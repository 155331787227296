<script lang="ts" setup>
import { computed } from 'vue';

import SelectInput from '@/common/components/SelectInput.vue';
import Element from '@/elements/element/classes/Element';
import { SizeTextTags, TextTags, TypeTextTags } from '@/Types/types';

const props = defineProps<{
	element: Element;
}>();

const sizeTags = Object.values(SizeTextTags);
const typeTags = Object.values(TypeTextTags);

const addTagToText = (tag: TextTags | undefined, type: 'size' | 'type') => {
	if (!tag) {
		props.element.tags = props.element.tags.filter((tag) =>
			type === 'size' ? !sizeTags.includes(tag) : !typeTags.includes(tag)
		);
		return;
	}

	if (sizeTags.includes(tag)) {
		props.element.tags = [tag, ...(props.element.tags as SizeTextTags[]).filter((t) => !sizeTags.includes(t))];
	}

	if (typeTags.includes(tag)) {
		props.element.tags = [tag, ...(props.element.tags as TypeTextTags[]).filter((t) => !typeTags.includes(t))];
	}
};

const selectedSize = computed(
	() => (props.element.tags as SizeTextTags[]).find((el) => sizeTags.includes(el)) || 'Select size'
);
const selectedType = computed(
	() => (props.element.tags as TypeTextTags[]).find((el) => typeTags.includes(el)) || 'Select type'
);
</script>

<template>
	<div data-keep-text-selection>
		<div class="hidden w-32 gap-2 rounded-2xl bg-white p-2 shadow-lg lg:flex lg:flex-col">
			<SelectInput
				:selected="selectedSize"
				:list="sizeTags"
				label-empty-value="Sin tag"
				@change="addTagToText($event as TextTags, 'size')"
			/>
			<SelectInput
				:selected="selectedType"
				:list="typeTags"
				label-empty-value="Sin tag"
				@change="addTagToText($event as TextTags, 'type')"
			/>
		</div>
	</div>
</template>
