<script setup lang="ts">
import { computed } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useCrop } from '@/elements/medias/crop/composables/useCrop';
import { useCropPhotoMode } from '@/elements/medias/crop/composables/useCropPhotoMode';
import Image from '@/elements/medias/images/image/classes/Image';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useI18n } from '@/i18n/useI18n';

const store = useMainStore();

const image = computed(() => store.cropping as Image);

const label = computed(() => ({
	width: parseInt(image.value.size.width.toString()),
	height: parseInt(image.value.size.height.toString()),
}));

const { photoModeImage } = usePhotoMode();
const { trans } = useI18n();
const { applyCrop, cancelCrop } = image.value.id === photoModeImage.value?.id ? useCropPhotoMode() : useCrop(image);
</script>

<template>
	<div class="crop-toolbar -mt-1 flex h-16 w-full items-center justify-center justify-center px-1 lg:h-8">
		<div class="mr-3 border-r border-gray-600 mockup:border-fp-gray-300">
			<p class="px-3 text-xs text-gray-300 mockup:text-fp-gray-400">
				<span class="mr-1 text-gray-100 mockup:text-fp-gray-600">{{ label.width }}</span
				>x<span class="ml-1 text-gray-100 mockup:text-fp-gray-600">{{ label.height }}</span>
			</p>
		</div>

		<button
			class="flex h-8 items-center justify-center rounded-full bg-blue-500 px-4 text-sm font-bold text-white hover:bg-blue-600 slidesgo:bg-purple-400 lg:h-6 lg:bg-green-500 lg:pl-2 lg:pr-4 lg:hover:bg-green-600"
			@click="applyCrop"
		>
			<SvgIcon name="check" class="ml-1 mr-2 h-3 w-3 fill-current" />
			{{ trans('Apply') }}
		</button>

		<button
			class="flex h-8 items-center justify-center px-4 text-sm font-semibold text-white opacity-75 hover:opacity-100 mockup:text-fp-gray-700 lg:h-6 lg:px-3"
			@click="cancelCrop"
		>
			<SvgIcon name="cross" class="mr-1 h-4 w-4 fill-current" />
			{{ trans('Cancel') }}
		</button>
	</div>
</template>
