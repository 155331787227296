<script setup lang="ts">
import { ref } from 'vue';

import { getMasks } from '@/api/DataApiClient';
import ActivableItem from '@/common/components/ActivableItem.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import { MaskApi } from '@/Types/apiClient';

// Props
const props = defineProps<{ id: number }>();

// Constants
const ELEMENT_URL_CONFIG = {
	LIMIT: 100,
	PAGE: 1,
};

// Data
const emit = defineEmits(['predefinedTextAdded']);
const url = ref(`elements/category/2263/${props.id}?limit=${ELEMENT_URL_CONFIG.LIMIT}&page=${ELEMENT_URL_CONFIG.PAGE}`);

const { data: predefinedTextsCategory, isFetching } = getMasks(url, { refetch: true });

// Methods
const onClickPredefinedText = (element: MaskApi) => {
	emit('predefinedTextAdded', element);
};

const loadMore = () => {
	if (isFetching.value || !predefinedTextsCategory.value?.links?.next) return;
	url.value = predefinedTextsCategory.value.links.next;
};
</script>

<template>
	<div data-testid="add-elements-panel" class="flex h-full flex-col">
		<InfiniteLoading
			data-testid="container-items"
			:container-classes="'grid grid-cols-3 sm:grid-cols-2 gap-4'"
			:data="predefinedTextsCategory?.data || []"
			:is-fetching="isFetching"
			@load="loadMore"
		>
			<template #item="{ item }">
				<button
					v-if="'id' in item && 'active' in item"
					:data-testid="`predefined-text-${item.id}`"
					class="flex aspect-square cursor-pointer items-center justify-center rounded bg-gray-700 px-3 py-2 transition-opacity duration-300 hover:bg-gray-600"
					@click="onClickPredefinedText(item as MaskApi)"
				>
					<ActivableItem :active="item.active">
						<img
							v-if="'preview' in item"
							:src="item.preview"
							:alt="item.name"
							:class="{ 'opacity-75 invert filter': item.multicolor === false }"
							class="h-full w-full shrink-0 object-contain"
							draggable="false"
						/>
					</ActivableItem>
				</button>
			</template>
		</InfiniteLoading>
	</div>
</template>
