<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { computed, onBeforeMount, toRef, watch } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useOfflineMode } from '@/common/composables/useOfflineMode';
import BrandKitsPanel from '@/editor/brand-kits/BrandKitsPanel.vue';
import DevPanel from '@/editor/components/DevPanel.vue';
import MoreToolsPanel from '@/editor/components/MoreToolsPanel.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import MyCreationsPanel from '@/editor/my-creations/MyCreationsPanel.vue';
import { useMainStore } from '@/editor/stores/store';
import ElementsPanel from '@/elements/element/components/panels/add/ElementsPanel.vue';
import QRCodesPanel from '@/elements/element/components/panels/add/QRCodesPanel.vue';
import UploadsPanel from '@/elements/element/components/panels/add/UploadsPanel.vue';
import TreeEditorPanel from '@/elements/illustrator/components/TreeEditorPanel.vue';
import AiPresentationPanel from '@/elements/medias/images/image/components/panels/add/AiPresentationPanel.vue';
import PhotosPanel from '@/elements/medias/images/image/components/panels/add/PhotosPanel.vue';
import TextToImagePanel from '@/elements/medias/images/image/components/panels/add/text-to-image/TextToImagePanel.vue';
import PhotoEditPanel from '@/elements/medias/images/image/components/panels/edit/PhotoEditPanel.vue';
import VideosPanel from '@/elements/medias/video/components/panels/add/VideosPanel.vue';
import AiWriterPanel from '@/elements/texts/text/components/panels/add/AiWriterPanel.vue';
import TextsPanel from '@/elements/texts/text/components/panels/add/TextsPanel.vue';
import GooglePhotosPanel from '@/google/photos/components/panels/add/GooglePhotosPanel.vue';
import NavigationButtons from '@/layout/components/NavigationButtons.vue';
import ShowTaggedButton from '@/layout/components/ShowTaggedButton.vue';
import TemplatePagesPanel from '@/layout/components/TemplatePagesPanel.vue';
import TemplatesPanel from '@/layout/components/TemplatesPanel.vue';
import { usePanelElements } from '@/layout/composables/usePanelElements';
import { Panels } from '@/Types/types';

const store = useMainStore();

const { isOffline } = useOfflineMode();
const { onSuccessLogin } = useAuth();
const { selectElement } = usePanelElements();

// Data
const isPanelVisible = toRef(store, 'activePanel');

// Computeds
const showPanel = computed(
	() =>
		!!isPanelVisible.value &&
		(!isPhotoMode.value || !(isPhotoMode.value && isMobile.value && store.activePanel === Panels.photoEdit))
);

// Using composables
const { isMobile } = useDeviceInfo();
const { isPhotoMode, isAdminMode, isSlidesgoMode, isIllustratorContext, isTagMode, urlParams } = useEditorMode();

// Lifecycle hooks
onBeforeMount(async () => {
	if (isMobile.value || isAdminMode.value || urlParams.panel === 'AI') return;

	if (isPhotoMode.value) {
		store.activePanel = Panels.photoEdit;
	} else if (isSlidesgoMode.value) {
		store.activePanel = Panels.templatePages;
	} else {
		store.activePanel = Panels.templates;
	}
});

// Methods
const getPanelType = (panel: Panels | null) => {
	if (panel === Panels.templates) return TemplatesPanel;
	if (panel === Panels.texts) return TextsPanel;
	if (panel === Panels.elements) return ElementsPanel;
	if (panel === Panels.photos) return PhotosPanel;
	if (panel === Panels.videos) return VideosPanel;
	if (panel === Panels.myCreations) return MyCreationsPanel;
	if (panel === Panels.textToImage) return TextToImagePanel;
	if (panel === Panels.aiWriter) return AiWriterPanel;
	if (panel === Panels.aiPresentation) return AiPresentationPanel;
	if (panel === Panels.photoEdit) return PhotoEditPanel;
	if (panel === Panels.brandKits) return BrandKitsPanel;
	if (panel === Panels.uploads) return UploadsPanel;
	if (panel === Panels.moreTools) return MoreToolsPanel;
	if (panel === Panels.googlePhotos) return GooglePhotosPanel;
	if (panel === Panels.qrCode) return QRCodesPanel;
	if (panel === Panels.dev) return DevPanel;
	if (panel === Panels.templatePages) return TemplatePagesPanel;
};

const panelHeight = computed(() => {
	const panelsWithAutoHeight = [Panels.brandKits, Panels.qrCode, Panels.moreTools];

	if (store.activePanel && panelsWithAutoHeight.includes(store.activePanel)) {
		return 'h-auto pb-4';
	}

	return 'h-2/3';
});

const setPanel = (panel: Panels) => {
	if (store.activePanel === panel) {
		store.activePanel = null;
		Bugsnag.leaveBreadcrumb(`Close ${panel} panel`);
		return;
	}

	store.activePanel = panel;
	Bugsnag.leaveBreadcrumb(`Open ${panel} panel`);
};

watch(isOffline, () => {
	if (store.activePanel && isOffline.value) {
		store.activePanel = null;
	}
});

onSuccessLogin(async () => {
	const queryParams = new URLSearchParams(window.location.search);

	if (queryParams.get('aipresentation') && !isMobile.value) {
		selectElement(Panels.aiPresentation, true);
	}
});
</script>

<template>
	<nav
		id="navigation"
		class="panel-elements relative flex h-14 shrink-0 bg-gray-800 lg:z-20 lg:h-full"
		:class="{ 'cursor-not-allowed opacity-50': isOffline }"
	>
		<div id="navigation-portal"></div>
		<ReserveSideSize
			class="relative flex h-full w-full items-center justify-between gap-1 !overflow-hidden bg-gray-700 px-2 text-gray-800 scrollbar-thumb-gray-600 sm:justify-center lg:w-[88px] lg:flex-col lg:justify-start lg:!overflow-auto lg:bg-transparent lg:px-0 lg:pt-3"
			:horizontal="isMobile"
			:class="{
				'navbar h-auto': isMobile,
				'pointer-events-none': isOffline,
			}"
		>
			<NavigationButtons @setPanel="setPanel" />
			<ShowTaggedButton v-if="isAdminMode || isTagMode" />
		</ReserveSideSize>
		<div id="aiWriterTarget" class="absolute z-50"></div>

		<div
			v-if="showPanel && store.activePanel !== Panels.treeEditor"
			:data-test-active-panel="store.activePanel"
			:class="panelHeight"
			class="fixed bottom-14 left-0 right-0 z-40 rounded-tl-2xl rounded-tr-2xl border-gray-700 bg-gray-800/95 pl-4 pr-4 pt-4 backdrop-blur lg:relative lg:bottom-0 lg:z-10 lg:h-full lg:w-[320px] lg:rounded-none lg:border-l lg:bg-gray-800"
		>
			<KeepAlive>
				<component :is="getPanelType(store.activePanel)" />
			</KeepAlive>
		</div>

		<div
			v-if="isIllustratorContext && store.finishedLoading"
			v-show="showPanel && store.activePanel === Panels.treeEditor"
			class="fixed bottom-14 left-0 right-0 z-40 h-2/3 rounded-tl-2xl rounded-tr-2xl border-l border-gray-700 bg-gray-800 pl-4 pr-4 pt-4 lg:relative lg:bottom-0 lg:z-10 lg:h-full lg:w-[320px] lg:rounded-none"
		>
			<TreeEditorPanel />
		</div>
	</nav>
</template>
