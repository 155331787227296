<script lang="ts" setup>
import sysend from 'sysend';
import { onBeforeUnmount, onMounted } from 'vue';

import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { useShare } from '@/export/share/composables/useShare';
import { useI18n } from '@/i18n/useI18n';
import { ShareModalType } from '@/Types/types';

const emits = defineEmits(['close', 'goBack', 'onAuth']);

const { trans } = useI18n();
const store = useMainStore();
const { metaAuthenticated, loading, initMeta, checkInstagramAuthentication } = useShare();

const closeModal = () => {
	if (store.sharing) return;
	emits('close');
};

const goBack = () => {
	if (store.sharing) return;
	emits('goBack');
};

onMounted(async () => {
	await checkInstagramAuthentication();
});

onBeforeUnmount(() => {
	if (metaAuthenticated.value) {
		emits('onAuth');
	}

	sysend.off('oauth:pinterest');
});
</script>
<template>
	<Modal v-if="!metaAuthenticated" open content-classes="w-full max-w-lg" @close="closeModal">
		<div class="relative max-h-full w-full overflow-auto rounded-lg bg-white py-4 px-4 lg:px-8 lg:pt-8 lg:pb-8">
			<button class="absolute top-2 left-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25" @click="goBack">
				<SvgIcon name="right-small" class="h-5 w-5 rotate-180 text-gray-700" />
			</button>
			<button
				class="absolute top-2 right-2 rounded bg-white p-2 text-gray-700 hover:bg-gray-100/25"
				@click="closeModal"
			>
				<SvgIcon name="cross" class="h-5 w-5 text-gray-700" />
			</button>
			<div class="flex flex-col items-center">
				<h3 class="mb-1 px-8 text-center text-xl font-semibold text-gray-800 lg:px-0">
					{{ trans('Publish your creations on Instagram for business!') }}
				</h3>
				<p class="text-center text-gray-600">
					{{ trans('Connect your social media and share content quickly and easily') }}
				</p>
				<ul class="mt-6 mb-8 flex flex-col gap-4 px-6 text-gray-600 lg:px-12">
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-bold text-blue-100/50">1</span>
						<p class="relative z-10">
							{{ trans('Make your Instagram account into a Business Account') }}
							<a
								href="https://help.instagram.com/502981923235522"
								target="_blank"
								class="font-semibold text-blue-500 hover:underline"
							>
								{{ trans('Learn how') }}
							</a>
						</p>
					</li>
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-bold text-blue-100/50">2</span>
						<p class="relative z-10">
							{{ trans('Link it with your Facebook Page') }}
							<a
								href="https://help.instagram.com/570895513091465"
								target="_blank"
								class="font-semibold text-blue-500 hover:underline"
							>
								{{ trans('Learn how') }}
							</a>
						</p>
					</li>
					<li class="relative">
						<span class="absolute -top-3 -left-4 text-4xl font-bold text-blue-100/50">3</span>
						<p class="relative z-10">{{ trans(`Now everything's connected and ready to publish your designs!`) }}</p>
					</li>
				</ul>
				<button
					class="rounded bg-blue-500 py-3 px-6 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
					@click="() => initMeta(ShareModalType.instagram)"
				>
					<span v-if="!loading">{{ trans('Connect via Facebook') }}</span>
					<SvgIcon v-if="loading" name="spinner" class="mx-auto h-7 w-7 animate-spin" />
				</button>
			</div>
		</div>
	</Modal>
</template>
