<script lang="ts" setup>
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';

// Using composables
const { trans } = useI18n();

// Emits
const emit = defineEmits(['close', 'confirm']);

// Methods
const onClickClose = () => {
	emit('close');
};
const onClickGenerate = () => {
	emit('confirm');
};
</script>

<template>
	<Modal id="remove-font-modal" open @close="onClickClose">
		<div class="relative my-auto mx-auto max-w-lg rounded bg-white py-8 px-8 lg:w-[470px]">
			<button
				id="close-font-modal"
				class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<div>
				<SvgIcon name="alert" class="mx-auto mb-2 h-12 w-12 fill-current text-red-500" />
				<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">{{ trans('We need confirmation!') }}</h1>
				<p class="mb-0 text-center leading-normal text-gray-600">
					{{ trans('You will remove the selected font from all sites where you have used it.') }}
				</p>
				<button
					id="delete-font-modal-button"
					class="mx-2 mt-6 flex h-14 w-full items-center justify-center rounded border-2 border-red-500 bg-red-500 px-4 text-lg font-semibold text-white hover:border-red-400 hover:bg-red-400"
					@click.stop="onClickGenerate"
				>
					{{ trans('Delete font') }}
				</button>
			</div>
		</div>
	</Modal>
</template>
