<script setup lang="ts">
// Vue
import { computed, ref, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
// Components
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
// Classes
import { Text } from '@/elements/texts/text/classes/Text';
import TextLinkMenu from '@/elements/texts/text/components/menus/TextLinkMenu.vue';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';

// Props
const props = defineProps<{ element: Text }>();
const textElement = toRef(props, 'element');
const { isCircleText } = useCircleTypeInfo(textElement);
// Data
const showLink = ref(false);
const linkIsActive = ref(false);
const { isMobile } = useDeviceInfo();
const { togglePanel } = usePanelManagement();
// Methods
const handleLink = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'text', link_text: 'italic' });

	// El menu de los links en desktop no entra dentro del flujo de los paneles, por lo que controlamos su visibilidad desde aquí
	if (!isMobile.value) {
		showLink.value = !showLink.value;

		return;
	}
	// mobile
	togglePanel(EditPanels.TextLink, props.element);
};

const onCloseTextLinkMenu = (link?: string) => {
	showLink.value = !showLink.value;
	linkIsActive.value = !!link && !!link.length;
};

const { trans } = useI18n();
const activeLink = computed(() => {
	const domNode = textElement.value.domNode();
	if (!domNode) return false;
	return linkIsActive.value || Array.from(domNode.querySelectorAll('a')).find((a) => a.href);
});
</script>

<template>
	<div>
		<button
			:disabled="isCircleText"
			class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white mockup:hidden lg:h-10 lg:w-10 lg:justify-center"
			:tooltip="trans('Link')"
			tooltip-position="top"
			:class="{ 'text-white': activeLink, 'cursor-not-allowed opacity-25': isCircleText }"
			@click="handleLink"
		>
			<SvgIcon name="chain" class="h-5 w-5 rotate-45 scale-90" />
			<p class="text-2xs lg:hidden">{{ trans('Link') }}</p>
		</button>
		<TextLinkMenu v-if="showLink && !isMobile" :element="textElement" @close="onCloseTextLinkMenu" />
	</div>
</template>
