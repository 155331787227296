<script lang="ts" setup>
import { useEventListener } from '@vueuse/core';
import { computed, InputHTMLAttributes, ref, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { TimeTools } from '@/common/utils/TimeTools';
import { useMainStore } from '@/editor/stores/store';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { Video } from '@/elements/medias/video/classes/Video';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { useArtboard } from '@/project/composables/useArtboard';

const store = useMainStore();
const { selection } = useSelection();

const props = defineProps<{
	element: Video;
	hasError: boolean;
	playing: boolean;
	muted: boolean;
	wasPlayed: boolean;
	percentage: number;
	isBackground: boolean;
	duration: string;
}>();
const emit = defineEmits<{
	(e: 'changeTime', value: number): void;
	(e: 'play'): void;
	(e: 'mute'): void;
}>();
const element = toRef(props, 'element');
const { photoModeImage } = usePhotoMode();
const { isIdle } = useInteractions();

const rangeThumbSize = computed(() => `${Math.round(12 / store.scale)}px`);

const isPhotoModeImage = computed(() => photoModeImage.value?.id === element.value.id);

const isSelected = computed(
	() => !isPhotoModeImage.value && !!selection.value.length && selection.value.some((e) => e.id === props.element.id)
);

const onDoubleClickInitCrop = async (e: MouseEvent) => {
	const target: EventTarget | null = e.target;
	if (target instanceof HTMLElement && target.tagName === 'BUTTON') return;

	store.croppingId = element.value.id;
};

const smallerSide = computed(() =>
	element.value.size.width < element.value.size.height ? element.value.size.width : element.value.size.height
);

// Si el lado mas pequeño del elemento es superior a 100px reales, queremos que el botón tenga un width de 40px reales(quitamos escala), si no 25px
const widthPlayButton = computed(() =>
	smallerSide.value * store.scale > 100 ? `${Math.round(40 / store.scale)}px` : `${Math.round(25 / store.scale)}px`
);

const handlerPlayerTime = (e: InputEvent) => {
	const time = parseFloat((e.target as InputHTMLAttributes).value);
	emit('changeTime', TimeTools.toSeconds((time / 100) * element.value.cropTime.end));
};

const isWiderThanArtboard = computed(() => artboardSizeInPx.value.width < widthWithRotation.value);
const isHigherThanArtboard = computed(() => artboardSizeInPx.value.height < heightWithRotation.value);
const shouldShowPlayerBar = computed(
	() => props.wasPlayed && (props.isBackground || !isWiderThanArtboard.value) && !props.hasError
);

// Si el video es mas grande que el artboard vamos a tener estos dos casos:
// - Es background -> adaptamos la barrqa de reproducción al tamaño y posición del artboard
// - No es background -> ocultamos la barra de reproducción para evitar problemas con la rotación
const { artboardSizeInPx } = useArtboard();
const { widthWithRotation, heightWithRotation } = useElementTransformOrchestrator(element).value;
const leftPlayerBar = computed(() =>
	props.isBackground && isWiderThanArtboard.value && element.value.position.x < 0 ? `${-element.value.position.x}px` : 0
);
const topPlayerBar = computed(() =>
	props.isBackground && isHigherThanArtboard.value && element.value.position.y < 0
		? `${-element.value.position.y}px`
		: 0
);

const btn = ref();
// Tenemos que escuchar este evento custom ya que puede que se intente dar al play estando el elemento agrupado y por tanto
// tendríamos la capa del grupo por encima y no recibiríamos el evento aquí y lo simulamos desde el useInteractions
useEventListener(btn, 'play-video', () => {
	emit('play');
});
</script>
<template>
	<teleport :to="`#canvas-${store.activePage?.id}>div`">
		<div
			v-show="isIdle"
			:style="{
				width: `${element.size.width}px`,
				height: `${element.size.height}px`,
				transform: `
			translate(${element.position.x}px,${element.position.y}px)
        rotate(${element.rotation}deg)
        	scaleX(${element.flipHTML.x})
        	scaleY(${element.flipHTML.y})
				`,
			}"
			class="db-crop video-player group pointer-events-none absolute inset-0 z-10 flex items-center justify-center text-white"
			@dblclick="onDoubleClickInitCrop"
		>
			<button
				v-if="isSelected"
				ref="btn"
				:disabled="hasError"
				class="play-btn video-player-element pointer-events-auto z-10 flex aspect-square items-center justify-center rounded-full bg-darkblue-500/80 hover:bg-darkblue-500"
				:class="{
					'opacity-0 transition-opacity duration-300 group-hover:opacity-100': playing,
					'opacity-50': hasError,
				}"
				:style="{
					width: widthPlayButton,
				}"
				style="max-width: 40%"
				@click="emit('play')"
			>
				<SvgIcon v-if="playing" name="pause" class="h-1/2 w-1/2" />
				<SvgIcon v-else name="play" class="h-1/2 w-1/2" />
			</button>
			<div
				v-if="isSelected && !shouldShowPlayerBar"
				class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/75 text-right text-sm font-semibold text-white"
				:style="{
					fontSize: `${Math.round(16 / store.scale)}px`,
					paddingRight: `${Math.round(16 / store.scale)}px`,
					paddingBottom: `${Math.round(8 / store.scale)}px`,
					paddingTop: `${Math.round(32 / store.scale)}px`,
				}"
			>
				{{ duration }}
			</div>
			<div
				v-if="shouldShowPlayerBar"
				class="video-player-element absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/50"
				:style="{
					paddingLeft: `${Math.round(12 / store.scale)}px`,
					paddingRight: `${Math.round(12 / store.scale)}px`,
					paddingBottom: `${Math.round(16 / store.scale)}px`,
					paddingTop: `${Math.round(32 / store.scale)}px`,
					maxWidth: isBackground ? `${artboardSizeInPx.width}px` : '',
					left: leftPlayerBar,
					bottom: topPlayerBar,
				}"
			>
				<div v-if="smallerSide * store.scale > 100" class="relative flex items-center justify-between">
					<div
						class="relative flex w-11/12"
						:style="{
							height: `${Math.round(4 / store.scale)}px`,
						}"
					>
						<input
							type="range"
							class="input-range pointer-events-auto w-full cursor-pointer appearance-none rounded-full bg-white/50 focus:outline-none sm:h-[2px]"
							:style="{
								height: `${Math.round(4 / store.scale)}px`,
							}"
							:value="percentage"
							@input="(e) => handlerPlayerTime(e as InputEvent)"
						/>
						<span
							class="pointer-events-auto absolute left-0 top-0 block h-full rounded-full bg-white"
							:style="{ width: `${percentage}%` }"
						></span>
						<span
							class="pointer-events-auto absolute -top-1 left-0 block h-3 w-3 rounded-full bg-white shadow-custom"
							:style="{
								top: `${-Math.round(4 / store.scale)}px`,
								left: `${percentage}%`,
								height: rangeThumbSize,
								width: rangeThumbSize,
							}"
						></span>
					</div>
					<button
						class="pointer-events-auto w-1/12"
						:style="{
							height: `${Math.round(16 / store.scale)}px`,
							width: `${Math.round(16 / store.scale)}px`,
						}"
						@click="emit('mute')"
					>
						<SvgIcon :name="muted ? 'mute' : 'sound'" class="h-full w-full" />
					</button>
				</div>
			</div>
		</div>
	</teleport>
</template>

<style scoped>
.input-range::-webkit-slider-thumb {
	@apply opacity-0 shadow-custom;
	width: v-bind(rangeThumbSize);
	height: v-bind(rangeThumbSize);
}

.input-range::-moz-range-thumb {
	@apply opacity-0 shadow-custom;
	width: v-bind(rangeThumbSize);
	height: v-bind(rangeThumbSize);
}
</style>
