<script lang="ts" setup>
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { useHistoryStore } from '@/history/stores/history';
import InteractiveCanvas from '@/interactions/components/InteractiveCanvas.vue';
import Page from '@/page/classes/Page';
import { useProjectStore } from '@/project/stores/project';

const props = defineProps<{
	scrollArea: HTMLElement;
}>();

const project = useProjectStore();
const history = useHistoryStore();
const store = useMainStore();
const { isMobile } = useDeviceInfo();

if (!store.activePage) {
	store.activePageId = project.pages[0].id;
}

watch(
	() => store.activePage,
	(newActivePage) => {
		if (newActivePage) return;

		store.activePageId = project.pages[0].id;
	}
);

if (!isMobile.value) {
	const handlerActivePageOnScroll = async (e: MouseEvent | KeyboardEvent | WheelEvent) => {
		if (e.ctrlKey || (e.target instanceof HTMLElement && !e.target.closest('#scroll-area'))) return;
		const currentPageIndex = project.pages.findIndex((page) => page.id === store.activePageId);
		const doesntHasScroll = props.scrollArea.scrollHeight <= props.scrollArea.clientHeight && 'deltaY' in e;
		if (doesntHasScroll && e.deltaY < 0) {
			store.setActivePage(project.pages[Math.max(0, currentPageIndex - 1)] as Page);
		}

		if (doesntHasScroll && e.deltaY > 0) {
			store.setActivePage(project.pages[Math.min(project.pages.length - 1, currentPageIndex + 1)] as Page);
		}
	};

	useEventListener('wheel', useDebounceFn(handlerActivePageOnScroll, 100));
}
</script>

<template>
	<InteractiveCanvas
		ref="canvases"
		:key="store.activePageId + history.key"
		:page="store.activePage || Page.createDefault() as Page"
		:scroll-area="scrollArea"
	/>
</template>
