import Bugsnag from '@bugsnag/js';
import { createSharedComposable, until } from '@vueuse/core';
import { keyBy, sortBy } from 'lodash-es';
import { computed, nextTick, Ref, ref, watch } from 'vue';
import WebFontLoader from 'webfontloader';

import { getFonts, getFontsBySlug, getUserFonts } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useEnvSettings } from '@/common/composables/useEnvSettings';
import { Text } from '@/elements/texts/text/classes/Text';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useProjectStore } from '@/project/stores/project';
import { FontWeight } from '@/Types/elements';
import { Dictionary, Font, LoadFont } from '@/Types/types';

export const useFonts = createSharedComposable(() => {
	const store = useProjectStore();
	const { user } = useAuth();

	const userFonts = computed<Dictionary<Font>>(() => {
		if (!user.value) return {};

		return keyBy(user.value.fonts, 'slug');
	});
	const fontSlugsToBeLoad: Ref<string[]> = ref([]);
	const temporalRef = ref(Text.create());
	const { APP_API_PATH } = useEnvSettings();

	const loadedFonts = ref<string[]>([]);
	const preloadedFonts = ref<string[]>([]);
	const fontsReadyToLoad = ref<string[]>([]);

	const fontLoading = ref(true);
	const firstLoadRequest = ref(true);
	const fonts = ref<Dictionary<Font>>({});
	const invalidFonts = ref(new Set());
	const forceLoadedFonts = ref<Font[]>([]);
	const finishedLoadedFonts = ref<LoadFont[]>([]);
	const sortedFonts = computed(() => {
		// Descartamos duplicados con el de los usuarios
		const all = Object.values(allFonts.value).filter(
			(value, index, self) =>
				index === self.findIndex((t) => t.name === value.name && t.weights.every((val, i) => val === value.weights[i]))
		);

		return sortBy(all, 'name');
	});

	const recommendedFonts = computed(() => {
		const all = Object.values(fonts.value).filter((f) => !!f.recommended);
		return sortBy(all, 'name');
	});

	const allFonts = computed(() => {
		const all = Object.values(fonts.value);
		const user = Object.values(userFonts.value);

		return keyBy([...all, ...user], 'slug');
	});

	const preload = async () => {
		if (window.preloadFonts && window.preloadFonts.length > 0) {
			fonts.value = keyBy(window.preloadFonts, 'slug');
			return;
		}

		const { data } = await getFonts();

		fonts.value = keyBy(data.value, 'slug');
	};

	const preloadCustomFonts = async () => {
		const families = store.allTexts.flatMap((text: Text) => {
			temporalRef.value = text;
			const childFonts = Array.from(text.htmlInstance().querySelectorAll<HTMLElement>('[style*="font-family"]')).map(
				(el) => el.style.fontFamily.replace(/['"]+/g, '')
			);
			return [text.fontFamily, ...childFonts];
		});
		await loadFontsBySlug([...new Set(families)]);
	};

	const loadFonts = async (fonts: Font[], keepCurrentlyLoaded = true, isRetry = false) => {
		// A menos que sea la primera vez que se cargan fuentes, esperamos a que no se este cargando nada
		if (!firstLoadRequest.value) {
			await until(fontLoading).toBe(false, { timeout: 15000, throwOnTimeout: false });
		}

		if (!fonts.length) {
			return;
		}
		const notLoadedFontsRequested = fonts.some((font) => !loadedFonts.value.includes(font.slug));

		if (!notLoadedFontsRequested) return;
		// cargamos las pedidas más las que ya hay en uso
		fontSlugsToBeLoad.value = [
			...new Set([
				...fontSlugsToBeLoad.value,
				...fonts.filter((f) => !!f.slug.length).map((f) => f.slug),
				...inUseFonts.value.map((f) => f.slug),
				...(keepCurrentlyLoaded ? loadedFonts.value : []),
			]),
		] as string[];
		const fontLoadingPath = APP_API_PATH;
		firstLoadRequest.value = false;

		WebFontLoader.load({
			timeout: 5000,
			custom: {
				families: fontSlugsToBeLoad.value,
				urls: [`${fontLoadingPath}fonts/css/${fontSlugsToBeLoad.value.sort().join(',').replaceAll(' ', '___')}`],
			},

			loading() {
				fontLoading.value = true;
			},

			active() {
				// Para la carga de fuentes necesitamos usar el slug
				// unificamos lógica y establecemos el mismo valor para todo el flujo de la carga de fuentes
				fontsReadyToLoad.value = [];
				fontsReadyToLoad.value = [...new Set(fonts.map((f) => f.slug))];
				fontLoading.value = false;
			},

			fontinactive(e: any) {
				console.error('Error loading font', e, fontSlugsToBeLoad.value);
				if (!isRetry) {
					console.warn('Retrying font loading');

					loadFonts(fonts, keepCurrentlyLoaded, isRetry);
				}
			},
		});
	};

	// Devuelve que fuentes + peso están siendo usados en el proyecto + las que hayan sido precargadas previamente
	const inUseFontsWeight = computed(() => {
		const families = store.allTexts.flatMap((text: Text) => {
			temporalRef.value = text;
			const childFonts = Array.from(text.htmlInstance().querySelectorAll<HTMLElement>('[style*="font-family"]')).map(
				(el) => ({ family: el.style.fontFamily.replace(/['"]+/g, ''), weight: el.style.fontWeight })
			);
			return [{ family: text.fontFamily, weight: text.fontWeight.toString() }, ...childFonts];
		});

		return [...families, ...forceLoadedFonts.value.map((f) => ({ family: f.slug, weight: f.weight }))].filter(
			(font, index, fonts) => index === fonts.findIndex((f) => f.family === font.family && f.weight === font.weight)
		);
	});

	const inUseFonts = computed(() => {
		const families = store.allTexts.flatMap((text: Text) => {
			temporalRef.value = text;
			const childFonts = Array.from(text.htmlInstance().querySelectorAll<HTMLElement>('[style*="font-family"]')).map(
				(el) => el.style.fontFamily.replace(/['"]+/g, '')
			);
			return [text.fontFamily, ...childFonts];
		});

		return [...new Set([...families])]
			.map((family) => {
				if (!allFonts.value[family] && Object.keys(allFonts.value).length > 0) {
					console.warn(`Font ${family} not found`);
				}

				return allFonts.value[family];
			})
			.filter((e) => !!e) as Font[];
	});

	const forceLoad = async (fontSlug: string, weight: string) => {
		const forceFont = fonts.value[fontSlug];
		// Comprobamos si esa combinación de fuente + weight ya ha sido cargada previeamente
		const fontAlreadyExist = !!inUseFontsWeight.value.find(
			(font) => font.family === fontSlug && font.weight === weight
		);

		if (fontAlreadyExist || !forceFont) {
			await TextTools.waitFont(fontSlug, weight);
			return;
		}

		forceLoadedFonts.value = [...new Set([...forceLoadedFonts.value, ...[{ ...forceFont, weight }]])];

		// Esperamos a que se ejecute el computed de la carga de fuentes
		await nextTick();
		await until(fontLoading).toBe(false, { timeout: 15000, throwOnTimeout: false });

		return await preloadRequiredFont(forceFont.slug, weight);
	};

	const watchFonts = () => {
		watch(
			[inUseFonts],
			async () => {
				await loadFonts([...inUseFonts.value], false);
			},
			{ immediate: true }
		);

		watch(fontsReadyToLoad, () => {
			if (!fontsReadyToLoad.value?.length) return;
			// si no tenemos fuentes en uso, precargamos la fuente que queremos cargar
			if (!inUseFonts.value.length) {
				fontsReadyToLoad.value.forEach((font) => {
					preloadRequiredFont(font);
				});
				return;
			}
			inUseFonts.value.forEach((font) => {
				preloadRequiredFont(font.slug);
			});
		});
	};

	const getFont = (fontName: string) => {
		const font = Object.values(allFonts.value).find((font) => font.name === fontName || font.slug === fontName);
		const lato = Object.values(allFonts.value).find((font) => font.name === 'Lato');

		let userFont;

		if (user.value && user.value.fonts) {
			userFont = Object.values(user.value?.fonts).find((font) => font.slug === fontName);
		}

		return font || userFont || lato;
	};

	const getVariants = (fontsFamilies: string[]): { family: string; weight: string[] }[] => {
		const variants = <{ family: string; weight: string[] }[]>[];

		// Reemplazamos las comillas dobles contiene necesarias para las fuentes con números
		fontsFamilies.forEach((fontSlug) => {
			const fontData = allFonts.value[fontSlug];

			if (!fontData) {
				return;
			}

			variants.push({
				family: fontSlug,
				weight: fontData.weights,
			});
		});

		return variants;
	};

	const getFontWeight = (fontFamily: string, isBold = false) => {
		const variants = getVariants([fontFamily]);

		// Asignamos negrita por defecto
		let fontWeight = 700;

		if (variants.length > 0) {
			const weights = [...new Set(variants[0].weight.map((weight) => parseInt(weight.replace('i', ''))))].sort(
				(a, b) => a - b
			);
			const maximumWeight = Math.max(...weights);

			// Asignamos el valor más alto de la fuente
			fontWeight = maximumWeight;

			// Si no es negrita, asignamos el peso medio
			if (weights.length > 1 && !isBold) {
				fontWeight = weights[Math.floor((weights.length - 1) / 2)];
			}
		}

		return fontWeight as FontWeight;
	};

	const loadFontsByName = async (fontNames: string[]) => {
		// Nos quedamos con las fuentes que aún no han cargado
		const fonts = fontNames
			.map((fontName) => getFont(fontName))
			.filter(Boolean)
			.filter((font) => !loadedFonts.value.includes(font.name));
		// Si todas están cargadas pues pasamos
		if (!fonts.length) {
			return true;
		}
		await loadFonts(fonts);

		try {
			await until(fontLoading).toBe(false, { timeout: 15000, throwOnTimeout: true });

			await Promise.all(
				fonts.map((font) => until(loadedFonts).toContains(font.slug, { timeout: 10000, throwOnTimeout: true }))
			);
			await Promise.all(
				fonts.map((font) => until(preloadedFonts).toContains(font.slug, { timeout: 10000, throwOnTimeout: true }))
			);
		} catch (e) {
			console.error('Fallo al cargar fuentes', {
				fontLoading: fontLoading.value,
				requested: fonts.map((f) => f.slug),
				loaded: loadedFonts.value,
				readyToLoad: fontsReadyToLoad.value,

				preloaded: preloadedFonts.value,
			});
		} finally {
			fontLoading.value = false;
		}
	};

	const loadUserFonts = async () => {
		if (!user.value) return;
		const { data, error } = await getUserFonts();
		if (error.value || !data.value) return;

		user.value.fonts = data.value;
		loadFonts(user.value.fonts);
	};

	const preloadRequiredFont = async (fontSlug: string, weight?: string) => {
		const allFontsRequired = [...inUseFonts.value, ...forceLoadedFonts.value];
		// Si no encontramos fuentes en uso o no forzamos la carga de fuentes, precargamos las que estén listas para cargar
		let currentFont = allFontsRequired.find((fontRequired) => fontRequired.slug === fontSlug);

		// Si la fuente solo ha sido cargada con un peso y no ha sido definido en la precarga, establecemos ese valor
		weight = !weight && currentFont?.weights.length === 1 ? currentFont.weights[0] : weight;

		if (!currentFont) {
			currentFont = fontsReadyToLoad.value
				.map((fontName) => getFont(fontName))
				.find((fontLoaded) => fontLoaded?.slug === fontSlug);
		}
		if (!currentFont) return;

		// comprobamos si el peso es válido para la fuente que queremos precargar, en caso de que no sea válido buscamos el mas cercano
		const correctWeight = weight ? currentFont.weights.find((validWeight) => validWeight === weight) : weight;

		const validWeight = correctWeight
			? correctWeight
			: TextTools.getNearestWeight(currentFont.name, weight ? Number(weight) : 400).weight.toString();

		try {
			await TextTools.preloadFont(currentFont, validWeight);
		} catch (e) {
			//console.error(`Error on load missing fonts: ${allFontsRequired.map((f) => f.name).join(', ')}`);
			//Bugsnag.notify(`Error on load missing fonts: ${allFontsRequired.map((f) => f.name).join(', ')}`);
		} finally {
			// las fuentes listas para cargar ya están cargadas, asignamos como valor el slug
			preloadedFonts.value = fontsReadyToLoad.value;
			loadedFonts.value = fontsReadyToLoad.value;

			//  una vez se han terminado de precargar las fuentes, las guardamos como fuentes  que ya han sido cargadas
			if (!finishedLoadedFonts.value.some((f) => f.slug === currentFont?.slug && f.weight === validWeight)) {
				finishedLoadedFonts.value.push({ slug: currentFont.slug, weight: validWeight });
			}
		}
	};

	const ongoingRequests = new Map<string, any>();
	/**
	 * Le pasamos los slugs de las fuentes para que compruebe que existen, si existen las mergeamos al listado global
	 * esto lo hacemos para que los usuarios puedan usar fuentes de otros usuarios que han compartido su plantilla
	 * @param {string[]} fontSlugs - An array of font slugs.
	 */
	const loadFontsBySlug = async (fontSlugs: string[]) => {
		// Ya que este metodo puede ser llamado varias veces al mismo tiempo
		// controlamos de esperar a la que ya esta en proceso antes
		// de continuar
		const key = fontSlugs.join(',');

		if (!key) return;

		let request = ongoingRequests.get(key);

		if (!request) {
			request = getFontsBySlug(fontSlugs);
			ongoingRequests.set(key, request);
		}

		const { data } = await request;

		const newFonts = data.value?.filter((f: Font) => !fonts.value[f.slug]) || [];

		fonts.value = keyBy([...Object.values(fonts.value), ...newFonts], 'slug');
	};

	return {
		preload,
		fonts: allFonts,
		userFonts,
		invalidFonts,
		finishedLoadedFonts,
		sortedFonts,
		inUseFonts,
		inUseFontsWeight,
		getFont,
		getVariants,
		loadedFonts,
		getFontWeight,
		fontLoading,
		recommendedFonts,
		watchFonts,
		loadFontsByName,
		loadUserFonts,
		loadFonts,
		preloadRequiredFont,
		loadFontsBySlug,
		forceLoad,
		preloadCustomFonts,
	};
});
