+
<script lang="ts" setup>
import { watchDebounced } from '@vueuse/core';
import { computed, ref, watch } from 'vue';

import { getStickers } from '@/api/DataApiClient';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useAddInsertableElement } from '@/interactions/composables/useAddInsertableElement';
import { StickersCategoryApi } from '@/Types/apiClient';
import { DraggableItemData, ElementCategory, InsertableApiType } from '@/Types/types';

const props = defineProps<{ activeElementCategory: ElementCategory | null; query: string; keyword: string }>();

const emit = defineEmits<{
	(e: 'elementAdded'): void;
	(e: 'setElementCategory', cat: StickersCategoryApi): void;
	(e: 'setHasCategories', hasCategories: boolean): void;
}>();

const CATEGORY_URL_CONFIG = {
	ICON_TYPE: 'sticker',
	LIMIT: 20,
	PAGE: 1,
};

const urlCategories = ref('');

const activeElementCategory = computed(() => props.activeElementCategory);

const query = computed(() => props.query || props.keyword);

const categoriesSource = computed(
	() =>
		`flaticon/stickers?limit=${CATEGORY_URL_CONFIG.LIMIT}&page=${CATEGORY_URL_CONFIG.PAGE}&q=${query.value}&iconType=${CATEGORY_URL_CONFIG.ICON_TYPE}&tagsId=${tagsId.value}`
);

const tagsId = computed(() => activeElementCategory.value?.id || '');

const { addInsertableSticker } = useAddInsertableElement();
const { trans } = useI18n();

const { data: categories, isFetching } = getStickers(urlCategories, {
	get: 'categories',
	immediate: false,
	refetch: true,
});

watchDebounced(
	categoriesSource,
	(newVal) => {
		if (!newVal) return;
		urlCategories.value = newVal;
	},
	{ debounce: 100, immediate: true }
);

watch(isFetching, (newVal, oldVal) => {
	if (newVal || !oldVal) return;
	emit('setHasCategories', !!categories.value?.data.length);
});

// Methods
const onClickSticker = async (url: string) => {
	await addInsertableSticker(url);
	emit('elementAdded');
};

const onClickSeeAll = (cat: StickersCategoryApi) => {
	emit('setElementCategory', cat);
};

const onLoadMoreCategories = () => {
	if (isFetching.value || !categories.value?.links?.next) return;
	urlCategories.value = categories.value.links.next;
};
</script>

<template>
	<InfiniteLoading
		:container-classes="'grid gap-8 pt-2'"
		:data="categories?.data || []"
		:is-fetching="isFetching"
		@load="onLoadMoreCategories"
	>
		<template #item="{ item }">
			<div v-if="'name' in item && 'previews' in item" class="group">
				<div class="mb-3 mt-0 flex items-center justify-between">
					<p class="text-xs font-bold uppercase text-gray-100">{{ trans(item.name) }}</p>
					<button
						data-testid="see-all"
						class="text-xs font-bold text-gray-100 group-hover:opacity-100 hover:text-white lg:opacity-0"
						@click.stop="onClickSeeAll(item as StickersCategoryApi)"
					>
						{{ trans('See all') }}
					</button>
				</div>

				<div class="grid w-full grid-cols-3 gap-4">
					<InsertableElement
						v-for="el in ((item as StickersCategoryApi).previews || []).slice(0, 6)"
						:key="el.id"
						:data="(el as DraggableItemData)"
						:type="InsertableApiType.Sticker"
						:draggable="true"
						data-testid="stickers"
						class="flex flex-col"
						@click.stop="onClickSticker(el.svg)"
					>
						<img :src="(el as DraggableItemData).preview" :alt="item.name" class="h-16 w-16 object-contain" />
					</InsertableElement>
				</div>
			</div>
		</template>
	</InfiniteLoading>
</template>
