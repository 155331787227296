<script setup lang="ts">
import { until } from '@vueuse/core';
import { ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useOfflineMode } from '@/common/composables/useOfflineMode';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import DownloadOptions from '@/export/download/components/DownloadOptions.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import OnClickOutsideWithPortals from '@/interactions/components/OnClickOutsideWithPortals';
import { useSelection } from '@/interactions/composables/useSelection';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { DownloadFormat } from '@/Types/types';

const { trans } = useI18n();

const open = ref<boolean>(false);

const { download, downloading } = useDownloadsProject();
const preparingDownloadForApp = ref<boolean>(false);

const { isWebview } = useDeviceInfo();
const { inSlidesgoContext, isEmbeddedContext, embeddedContextData } = useEditorMode();
const project = useProjectStore();
const store = useMainStore();
const { clearSelection } = useSelection();

const { isOffline } = useOfflineMode();
const { projectIsPresentation, isAiPresentation } = useProject();
const { setupForDownload, canDownload } = useDownloadsProject();

const openDownloadModal = async () => {
	if (downloading.value) return;
	// Borramos las selecciones de los elementos para cerrar las toolbars
	clearSelection();

	const projectName = project.name?.length ? project.name : 'custom-artboard';

	GAnalytics.track('click-download', 'Template', projectName, null);
	GAnalytics.trackGA4('download_button', {
		file_name: projectName.toLowerCase(),
		file_id: project.id,
		category: project.category?.length ? project.category : 'custom-artboard',
		license: 'free',
	});

	if (isWebview.value) {
		preparingDownloadForApp.value = true;

		setupForDownload();
		await until(() => canDownload.value || project.syncError);
		await download(DownloadFormat.app);

		preparingDownloadForApp.value = false;
		return;
	}

	if (isEmbeddedContext.value) {
		setupForDownload();
		await until(() => canDownload.value || project.syncError);
		await download(embeddedContextData.value.format as DownloadFormat);
		return;
	}

	open.value = true;
};
</script>

<template>
	<OnClickOutsideWithPortals ignore-selector=".ignore-click" @trigger="open = false">
		<template v-if="isWebview">
			<button
				data-onboarding="btn-download"
				class="btn-download relative flex h-8 w-8 items-center justify-center rounded font-bold text-white lg:h-10 lg:w-auto lg:px-6 lg:py-3"
				:class="{
					'cursor-not-allowed bg-gray-600 text-gray-300': isOffline,
					'bg-green-500 hover:bg-green-600': !isOffline,
				}"
				@click="openDownloadModal"
			>
				<SvgIcon v-if="preparingDownloadForApp" name="spinner" class="h-4 w-4 animate-spin" />

				<div v-else>
					<SvgIcon name="download" class="h-4 w-4 lg:mr-2" />
					<span class="hidden lg:block">{{ trans('Download') }}</span>
				</div>
			</button>
		</template>

		<template v-else-if="inSlidesgoContext || projectIsPresentation || isAiPresentation">
			<button
				data-onboarding="btn-download"
				class="relative flex h-10 items-center justify-center rounded px-2 lg:w-10 lg:bg-gray-600 lg:px-0"
				:class="{
					'text-white/50 focus:text-white hover:bg-gray-500 hover:text-white  lg:text-gray-100': !isOffline,
					'cursor-not-allowed text-white/25': isOffline,
				}"
				:tooltip="trans(isEmbeddedContext ? 'Continue' : 'Download')"
				tooltip-position="bottom"
				@click="openDownloadModal"
			>
				<SvgIcon :name="isEmbeddedContext ? 'check' : 'download'" class="h-4 w-4" />
			</button>
		</template>

		<template v-else>
			<button
				data-onboarding="btn-download"
				data-testid="download-btn"
				class="btn-download relative flex h-8 w-8 items-center justify-center rounded font-bold text-white lg:h-10 lg:w-auto lg:px-6 lg:py-3"
				:class="{
					'cursor-not-allowed bg-gray-600 text-gray-300': isOffline,
					'bg-green-500 hover:bg-green-600': !isOffline,
				}"
				@click="openDownloadModal"
			>
				<SvgIcon v-if="downloading" name="spinner" class="h-4 w-4 animate-spin" />
				<SvgIcon v-if="!downloading" :name="isEmbeddedContext ? 'check' : 'download'" class="h-4 w-4 lg:mr-2" />
				<span v-if="!downloading" class="hidden lg:block">{{
					trans(isEmbeddedContext ? 'Continue' : 'Download')
				}}</span>
			</button>
		</template>

		<DownloadOptions v-if="open" @close="open = false" />
	</OnClickOutsideWithPortals>
</template>
