<script lang="ts" setup>
import { useInfiniteScroll, watchDebounced } from '@vueuse/core';
import { ref, toRef } from 'vue';

import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';
import { TemplateVectorMedia } from '@/loader/types/templateLoaderData';
import {
	AiImageApiData,
	DataType,
	ImageApi,
	MaskApi,
	MediaApi,
	PredefinedTextApi,
	PredefinedTextElementsSubcategoriesApi,
	Preview,
	StickersCategoryApi,
	StickersElementApi,
	TemplateApiData,
	UploadApi,
	VideoApi,
} from '@/Types/apiClient';
import { ElementType } from '@/Types/types';

const props = withDefaults(
	defineProps<{
		containerClasses?: string;
		modal?: boolean;
		withMasonry?: boolean;
		masonryCols?: number;
		horizontal?: boolean;
		isFetching?: boolean;
		data:
			| TemplateApiData[]
			| ElementType[]
			| ImageApi[]
			| AiImageApiData[]
			| DataType[]
			| VideoApi[]
			| MediaApi[]
			| UploadApi[]
			| MaskApi[]
			| StickersCategoryApi[]
			| StickersElementApi[]
			| PredefinedTextApi[]
			| (TemplateVectorMedia & Preview)[]
			| PredefinedTextElementsSubcategoriesApi[]
			| undefined;
	}>(),
	{
		containerClasses: '',
		masonryCols: 2,
	}
);

const emit = defineEmits<{
	(e: 'load'): void;
}>();

const el = ref();

const { trans } = useI18n();
const data = toRef(props, 'data');

useInfiniteScroll(
	el,
	() => {
		emit('load');
	},
	{
		distance: props.horizontal ? window.outerWidth / 5 : window.outerHeight / 5,
	}
);

// En ciertas ocasiones al cambiar de búsqueda se hacen cambios de paneles y tanto el data como el isFetching se resetean
// provocando un flickeo entre los estados loading y no results cuando de cara al usuario realmente lo único que esta pasando
//  es que está cargando, para evitar esto vamos a hacer el cambio de estas variables con un debounced así tenemos tiempo para
// volver a establecer correctamente los valores sin cambios visuales
const withoutResults = ref(false);
watchDebounced(
	data,
	() => {
		if (data.value?.length) {
			withoutResults.value = false;
			return;
		}

		withoutResults.value = true;
	},
	{ debounce: 200 }
);

const isFetchingDebounced = ref(props.isFetching ? props.isFetching : false);
watchDebounced(
	() => props.isFetching,
	(isFetching) => {
		isFetchingDebounced.value = isFetching;
	},
	{ debounce: 200 }
);
</script>

<template>
	<ReserveSideSize
		data-testid="scroll-container"
		:horizontal="horizontal"
		:class="modal ? 'text-white scrollbar-thumb-gray-100' : 'text-gray-800 scrollbar-thumb-gray-600'"
		@element="el = $event"
	>
		<slot></slot>

		<!-- Masonry -->
		<masonry
			v-if="withMasonry"
			:cols="masonryCols"
			:gutter="10"
			:resolve-slot="true"
			:column-class="`masonry-column flex ${!horizontal ? 'flex-col' : ''}`"
		>
			<slot v-for="img in data" name="item" :item="img"></slot>
		</masonry>

		<!-- No masonry -->
		<div v-else :class="`masonry-column ${containerClasses} flex ${!horizontal ? 'flex-col' : ''}`">
			<template v-if="data && data.length">
				<!-- Static element -->
				<slot name="default-item"></slot>
				<!-- Dynamic elements -->
				<slot v-for="item in data" name="item" :item="item"></slot>
			</template>
		</div>

		<!-- Loading -->
		<template v-if="isFetchingDebounced">
			<div v-if="data?.length" class="absolute bottom-8 flex w-full items-center justify-center">
				<span
					class="flex items-center rounded-full bg-opacity-75 p-1 pr-3 text-center text-sm font-semibold backdrop-blur"
					:class="modal ? 'bg-white text-gray-700' : 'bg-gray-900 text-gray-100'"
				>
					<SvgIcon data-testid="spinner" name="spinner" class="mr-1 h-7 w-7 animate-spin" /> {{ trans('Loading') }}
				</span>
			</div>

			<div v-else class="absolute inset-0 flex w-full flex-col items-center justify-center text-gray-100">
				<SvgIcon name="spinner" class="h-10 w-10 animate-spin" />
			</div>
		</template>

		<div
			v-else-if="withoutResults"
			class="mx-auto my-8 rounded-full px-8 py-1 text-center text-xs"
			:class="modal ? 'bg-gray-100/50 text-gray-800' : 'bg-gray-600 text-gray-100'"
		>
			{{ trans('No results found') }}
		</div>
	</ReserveSideSize>
</template>

<style scoped></style>
