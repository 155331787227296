<script lang="ts" setup>
import { toRef } from 'vue';

import ColorPicker from '@/color/components/ColorPicker.vue';
import { useStorysetColors } from '@/color/composables/useStorysetColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import FlipMenu from '@/elements/element/components/menus/FlipMenu.vue';
import OpacitySlider from '@/elements/element/components/menus/OpacitySlider.vue';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformMenu from '@/elements/element/components/menus/TransformMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import Storyset from '@/elements/storyset/classes/Storyset';
import ChangeBackgroundDropdown from '@/elements/storyset/components/menus/ChangeBackgroundDropdown.vue';
import { useI18n } from '@/i18n/useI18n';
import { Color } from '@/Types/colorsTypes';

const { trans } = useI18n();

const props = defineProps<{ element: Storyset }>();

const element = toRef(props, 'element');

const { updateColor } = useStorysetColors(element);
const { isGrouped } = useGroup(element);
const { closePanel } = usePanelManagement();
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">{{ trans('Edit Storyset element') }}</h2>
		<button class="text-gray-100 focus:outline-none hover:text-white" data-testid="close-panel" @click="closePanel()">
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<!-- Edition -->
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Edition') }}</h4>

		<div class="flex">
			<div class="w-1/3">
				<FlipMenu :inline-label="true" :element="element" />
			</div>
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="true" />

		<OpacitySlider :element="element" :opacity="element.opacity" />

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Background') }}</h4>
		<ChangeBackgroundDropdown :element="element" />

		<!-- Main color -->
		<h4 class="mb-3 mt-8 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Color') }}</h4>

		<div class="grid grid-cols-6 gap-2">
			<ColorPicker
				class="h-10 w-10"
				:color="(element.mainColor as Color)"
				:hide-alpha="true"
				@change="(newColor) => updateColor(newColor)"
			/>
		</div>
	</div>
</template>
