import TextTools from '@/elements/texts/text/utils/TextTools';
import MathTools from '@/utils/classes/MathTools';

export interface LetterRotations {
	total: number;
	rotations: number[];
}

export interface Box {
	height: number;
	left: number;
	top: number;
	width: number;
}

class CurvedTextTools {
	public static getLetterRotation(metrics: Box[], r: number): LetterRotations {
		return metrics.reduce<LetterRotations>(
			(data, { width }) => {
				const rotation = MathTools.radiandsToAngle(width / r);

				return {
					total: data.total + rotation,
					rotations: data.rotations.concat([data.total + rotation / 2]),
				};
			},
			{ total: 0, rotations: [] }
		);
	}

	public static getRect(element: HTMLSpanElement, scale: number): Box {
		const rect = element.getBoundingClientRect();

		return {
			height: rect.height / scale,
			left: (rect.left + window.pageXOffset) / scale,
			top: (rect.top + window.pageYOffset) / scale,
			width: rect.width / scale,
		};
	}

	public static splitNode(node: HTMLElement): HTMLSpanElement[] {
		const chars: HTMLSpanElement[] = [];
		const rootColor = node.style.backgroundImage;

		/**
		 * Divide los nodos de tipo de texto en spans con los estilos del padre (si lo tuviera) por cada carácter del texto
		 * Si no es un nodo de tipo texto se recorre los hijos recursivamente para aplicar la misma lógica teniendo como resultado
		 * un array de spans por carácter con los estilos del padre
		 * */
		const generateSpanCharsWithStyles = (element: HTMLElement) => {
			const parentSpan = element.parentElement?.closest<HTMLElement>('[data-unique]');

			if (element.nodeType === 3) {
				TextTools.split(element.textContent || '').forEach((char) => {
					const parent = wrapperElement.cloneNode() as HTMLSpanElement;

					parent.insertAdjacentHTML('afterbegin', char === ' ' ? '&nbsp;' : char);

					if (parentSpan) {
						parent.setAttribute('style', parentSpan.getAttribute('style') || '');
					}

					// Si el root tiene un color gradiente y el span generado no tiene color asignado,
					// asignamos el gradiente al current Span puesto que el gradiente del root es transparente y no se visualiza correctamente
					if (rootColor.length && !parent.style.backgroundImage.length && !parent.style.color.length) {
						parent.style.backgroundImage = rootColor;
						parent.style.webkitTextFillColor = 'transparent';
						const styleWithClip = `${parent.getAttribute('style')} -webkit-background-clip: text;`;
						parent.setAttribute('style', styleWithClip);
					}

					chars.push(parent);
				});
			}
			const childrens = Array.from(element.childNodes);

			childrens.forEach((child) => {
				// Si no es un nodo de texto vacio o con salto de linea se recorre recursivamente
				if (!(child.textContent?.includes('\n') || child.textContent?.includes('\r'))) {
					generateSpanCharsWithStyles(child as HTMLElement);
				}
			});
		};

		const wrapperElement = document.createElement('span');
		wrapperElement.style.display = 'inline-block';
		generateSpanCharsWithStyles(node);

		return chars;
	}
}

export default CurvedTextTools;
