<script lang="ts" setup>
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { Text } from '@/elements/texts/text/classes/Text';
import TextEffectsMenu from '@/elements/texts/text/components/menus/TextEffectsMenu.vue';
import { useI18n } from '@/i18n/useI18n';

const { isMobile } = useDeviceInfo();
const { trans } = useI18n();

defineProps<{ element: Text }>();

const { closePanel } = usePanelManagement();
</script>

<template>
	<TextEffectsMenu v-if="isMobile" :element="element" :show-full="true" />

	<template v-else>
		<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
			<h2 class="text-xl font-semibold text-gray-100 mockup:text-fp-gray-700">{{ trans('Edit text effects') }}</h2>

			<button
				class="text-gray-100 focus:outline-none hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800"
				data-testid="close-panel"
				@click="closePanel()"
			>
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>

		<div data-testid="sidebar-panel" class="flex flex-col" data-keep-text-selection>
			<TextEffectsMenu :element="element" :show-full="true" />
		</div>
	</template>
</template>
