import { createSharedComposable, useLocalStorage } from '@vueuse/core';
import { computed } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { useI18n } from '@/i18n/useI18n';
import { useTemplateLoader } from '@/loader/composables/useTemplateLoader';
import { useProjectStore } from '@/project/stores/project';
import { PanelElement, PanelElementType, Panels } from '@/Types/types';

export const usePanelElements = createSharedComposable(() => {
	const { isPhotoMode, isIllustratorContext, isSlidesgoMode, mode, isEmbeddedContext } = useEditorMode();
	const {
		selectFromDropbox,
		selectFromDrive,
		selectFromPhotos,
		registerSysendDropbox,
		registerSysendGoogle,
		authenticatedInGoogle,
		authenticatedInDropbox,
	} = useUserImageProvider();
	const { isMobile, isWebview } = useDeviceInfo();
	const { trans } = useI18n();
	const { isLogged } = useAuth();
	const { user, isAdmin } = useAuth();
	const project = useProjectStore();
	const store = useMainStore();
	const { templateData } = useTemplateLoader();

	const currentExtensiblePanels = useLocalStorage('user-extensible-panels', store.userExtensiblePanels);

	const panelButtons = computed<PanelElement[]>(() => {
		const elements = [
			{
				type: PanelElementType.button,
				data: {
					name: trans('Templates'),
					panel: Panels.templates,
					icon: 'template',
					hide: !getPanelVisibility(Panels.templates) || isSlidesgoMode.value,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Template'),
					panel: Panels.templatePages,
					icon: 'template',
					hide: !isSlidesgoMode.value || (isSlidesgoMode.value && (templateData.value?.vector.media.length || 0) <= 1),
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Edit'),
					panel: Panels.photoEdit,
					icon: 'edit',
					hide: !getPanelVisibility(Panels.photoEdit),
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Texts'),
					panel: Panels.texts,
					icon: 'create-text',
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Elements'),
					panel: Panels.elements,
					icon: 'create-element',
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Photos'),
					panel: Panels.photos,
					icon: 'photo',
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Videos'),
					panel: Panels.videos,
					icon: 'video',
					hide: !getPanelVisibility(Panels.videos),
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Uploads'),
					panel: Panels.uploads,
					icon: 'upload-image',
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('My Projects'),
					panel: Panels.myCreations,
					icon: 'folder',
					hide: !getPanelVisibility(Panels.myCreations),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Text to img'),
					panel: Panels.textToImage,
					icon: 'text-to-img',
					hide: !getPanelVisibility(Panels.textToImage) || !isLogged.value,
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('AI Presentation'),
					panel: Panels.aiPresentation,
					icon: 'ai-presentation',
					hide: !getPanelVisibility(Panels.aiPresentation) || !isLogged.value,
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('AI Writer'),
					panel: Panels.aiWriter,
					icon: 'wand',
					hide: !getPanelVisibility(Panels.aiWriter) || !isLogged.value,
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('My brand'),
					panel: Panels.brandKits,
					icon: 'brand-kit',
					hide: !getPanelVisibility(Panels.brandKits),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('QR Code'),
					panel: Panels.qrCode,
					icon: 'qr',
					hide: !getPanelVisibility(Panels.qrCode),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: 'Dropbox',
					panel: Panels.dropbox,
					icon: 'integrations-dropbox',
					hide: !getPanelVisibility(Panels.dropbox),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: 'Drive',
					panel: Panels.googleDrive,
					icon: 'integrations-drive',
					hide: !getPanelVisibility(Panels.googleDrive),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: 'Photos',
					panel: Panels.googlePhotos,
					icon: 'integrations-photos',
					hide: !getPanelVisibility(Panels.googlePhotos),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: 'Dev',
					panel: Panels.dev,
					icon: 'dev',
					hide: !getPanelVisibility(Panels.dev),
					removable: true,
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: trans('Tools'),
					panel: Panels.moreTools,
					icon: 'tools',
				},
			},
			{
				type: PanelElementType.button,
				data: {
					name: 'SVG Tree Editor',
					panel: Panels.treeEditor,
					icon: 'tree',
					hide: !isIllustratorContext.value,
				},
			},
			{
				type: PanelElementType.line,
				data: {
					hide: user.value ? !user.value.isAdmin : true,
				},
			},
			{
				type: PanelElementType.link,
				data: {
					url: `/nova/resources/vectors/${project.sourceVectorId}`,
					name: 'Nova',
					subtitle: `ID: ${project.sourceVectorId}`,
					icon: 'nova',
					hide: !user.value?.isAdmin,
				},
			},
		];
		return elements.filter((i) => !i.data?.hide);
	});

	const getPanelVisibility = (panel: Panels) => {
		const isActivePanel = currentExtensiblePanels.value.includes(panel);

		if (panel === Panels.templates) {
			return !['photo', 'admin', 'tag'].includes(mode.value);
		}

		if (panel === Panels.photoEdit) {
			return isPhotoMode.value && !isMobile.value;
		}

		if (panel === Panels.treeEditor) {
			return isIllustratorContext.value;
		}

		// ! Ocultos en modo foto, app, mobile, y editor para terceros
		if ([Panels.myCreations, Panels.brandKits, Panels.aiPresentation].includes(panel)) {
			return isActivePanel && !isWebview.value && !isPhotoMode.value && !isMobile.value && !isEmbeddedContext.value;
		}

		if ([Panels.dropbox, Panels.googleDrive, Panels.googlePhotos].includes(panel)) {
			return isActivePanel && !isWebview.value && !isMobile.value && !isEmbeddedContext.value;
		}
		if ([Panels.qrCode, Panels.textToImage, Panels.aiWriter].includes(panel)) {
			return isActivePanel && !isMobile.value;
		}

		if (panel === Panels.dev) {
			return isActivePanel && (isAdmin.value || process.env.NODE_ENV !== 'production') && !isMobile.value;
		}

		if (panel === Panels.videos) {
			return !isPhotoMode.value;
		}
		return isActivePanel;
	};

	const selectElement = async (element: Panels | undefined, checked = false) => {
		if (checked && element && !currentExtensiblePanels.value.includes(element)) {
			store.userExtensiblePanels = [...currentExtensiblePanels.value, element];
			setActivePanelsInStorage();
		}
		if (!checked) {
			store.userExtensiblePanels = currentExtensiblePanels.value.filter((panel: Panels) => panel !== element);
			setActivePanelsInStorage();
		}
		if (
			element &&
			[
				Panels.brandKits,
				Panels.myCreations,
				Panels.textToImage,
				Panels.aiPresentation,
				Panels.aiWriter,
				Panels.qrCode,
				Panels.dev,
			].includes(element)
		) {
			if (checked) store.activePanel = element;
		}

		if (checked && element) openGoogleOrDropboxModal(element);
	};

	const setActivePanelsInStorage = () => (currentExtensiblePanels.value = store.userExtensiblePanels);

	const openGoogleOrDropboxModal = async (panel: Panels) => {
		if (panel === Panels.dropbox) {
			if (!authenticatedInDropbox.value) registerSysendDropbox();
			selectFromDropbox();
		}
		if (panel === Panels.googleDrive) {
			if (!authenticatedInGoogle.value) registerSysendGoogle();
			selectFromDrive();
		}
		if (panel === Panels.googlePhotos) {
			if (!authenticatedInGoogle.value) registerSysendGoogle();
			if (await selectFromPhotos()) store.activePanel = Panels.googlePhotos;
		}
	};
	return {
		setActivePanelsInStorage,
		panelButtons,
		selectElement,
		openGoogleOrDropboxModal,
	};
});
