<script setup lang="ts">
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import { useGroup } from '@/elements/group/composables/useGroup';
import { useGroupTransform } from '@/elements/group/composables/useGroupTransform';
import { useI18n } from '@/i18n/useI18n';
import { Anchor } from '@/Types/types';

const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');

const { isGrouped, group } = useGroup(element);
const { trans } = useI18n();

const { align, isAlignLeft, isAlignCenter, isAlignRight, isAlignTop, isAlignBottom, isAlignMiddle } = isGrouped.value
	? useGroupTransform(group)
	: useElementTransformOrchestrator(element).value;
</script>

<template>
	<div>
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75 mockup:text-fp-gray-800">
			{{ trans('Position') }}
		</h4>

		<div data-testid="position" class="mb-6 flex justify-between gap-2">
			<div class="flex flex-1">
				<div class="flex flex-1 flex-col items-center">
					<button
						:id="`align-left-${element.type}-toolbar`"
						class="flex h-8 w-full items-center justify-center rounded-bl rounded-tl bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignLeft,
							'hover:bg-gray-600 hover:text-white': !isAlignLeft,
						}"
						:disabled="element.locked || isAlignLeft"
						@click="align(Anchor.left)"
					>
						<SvgIcon name="left" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Left') }}</label>
				</div>

				<div class="mx-px flex flex-1 flex-col items-center">
					<button
						:id="`align-center-${element.type}-toolbar`"
						class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignCenter,
							'hover:bg-gray-600 hover:text-white': !isAlignCenter,
						}"
						:disabled="element.locked || isAlignCenter"
						@click="align(Anchor.centerX)"
					>
						<SvgIcon name="center" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Center') }}</label>
				</div>

				<div class="flex flex-1 flex-col items-center">
					<button
						:id="`align-right-${element.type}-toolbar`"
						class="flex h-8 w-full items-center justify-center rounded-br rounded-tr bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignRight,
							'hover:bg-gray-600 hover:text-white': !isAlignRight,
						}"
						:disabled="element.locked || isAlignRight"
						@click="align(Anchor.right)"
					>
						<SvgIcon name="right" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Right') }}</label>
				</div>
			</div>

			<div class="flex flex-1">
				<div class="flex flex-1 flex-col items-center">
					<button
						:id="`anchor-top-${element.type}-toolbar`"
						class="flex h-8 w-full items-center justify-center rounded-bl rounded-tl bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignTop,
							'hover:bg-gray-600 hover:text-white': !isAlignTop,
						}"
						:disabled="element.locked || isAlignTop"
						@click="align(Anchor.top)"
					>
						<SvgIcon name="top" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Top') }}</label>
				</div>

				<div class="mx-px flex flex-1 flex-col items-center">
					<button
						:id="`anchor-center-${element.type}-toolbar`"
						class="flex h-8 w-full items-center justify-center bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignMiddle,
							'hover:bg-gray-600 hover:text-white': !isAlignMiddle,
						}"
						:disabled="element.locked || isAlignMiddle"
						@click="align(Anchor.centerY)"
					>
						<SvgIcon name="middle" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Middle') }}</label>
				</div>

				<div class="flex flex-1 flex-col items-center">
					<button
						:id="`anchor-bottom-${element.type}-toolbar`"
						class="flex h-8 w-full items-center justify-center rounded-br rounded-tr bg-gray-700 px-3 text-gray-100 mockup:bg-fp-gray-150 mockup:text-fp-gray-700 mockup:hover:text-blue-500"
						:class="{
							'cursor-not-allowed opacity-50': element.locked || isAlignBottom,
							'hover:bg-gray-600 hover:text-white': !isAlignBottom,
						}"
						:disabled="element.locked || isAlignBottom"
						@click="align(Anchor.bottom)"
					>
						<SvgIcon name="bottom" class="h-4 w-4" />
					</button>
					<label class="mt-1 text-center text-2xs text-gray-100 mockup:text-fp-gray-700">{{ trans('Bottom') }}</label>
				</div>
			</div>
		</div>
	</div>
</template>
