<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import FlipMenu from '@/elements/element/components/menus/FlipMenu.vue';
import CropButton from '@/elements/medias/crop/components/buttons/CropButton.vue';
import ElementFilter from '@/elements/medias/filter/components/menus/ElementFilter.vue';
import UnifyLayersButton from '@/elements/medias/images/foreground/components/buttons/UnifyLayersButton.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import RemoveBgButtonPhotoMode from '@/elements/medias/images/image/components/buttons/RemoveBgButtonPhotoMode.vue';
import ReplaceImageButton from '@/elements/medias/images/image/components/buttons/ReplaceImageButton.vue';
import SeparateLayersButton from '@/elements/medias/images/image/components/buttons/SeparateLayersButton.vue';
import RotateMenu from '@/elements/medias/images/image/components/menus/RotateMenu.vue';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';

const { trans } = useI18n();
const project = useProjectStore();
const history = useHistoryStore();

const pages = computed(() => project.pages);

const image = ref<Image>(Image.create());
const { hasForeground, removingBackground } = useLayersImage(image);
watch(
	pages,
	(newVal) => {
		const page = newVal[0];
		if (!page?.backgroundImageId) return;
		image.value = page.elements.get(page.backgroundImageId) as Image;
	},
	{ deep: true, immediate: true }
);
</script>

<template>
	<div v-if="image" class="flex h-full flex-col" data-test-photomode>
		<div v-if="removingBackground" class="absolute inset-0 z-30 cursor-wait bg-black bg-opacity-60"></div>
		<PanelHeader :title="'Edit image'" />

		<ReserveSideSize class="text-gray-800">
			<div class="mb-6">
				<div class="mb-3 grid grid-cols-2 gap-3">
					<CropButton :label-position="'right'" :element="image" />
					<ReplaceImageButton :label-position="'right'" :element="image" />
				</div>
				<div class="mb-3 grid grid-cols-2 gap-3">
					<RemoveBgButtonPhotoMode :element="image" />
					<SeparateLayersButton v-if="!hasForeground" :element="image" />
					<UnifyLayersButton v-if="hasForeground" :element="image" />
				</div>
			</div>
			<p class="mb-2 hidden text-sm font-bold uppercase text-gray-100 lg:flex lg:opacity-75">
				{{ trans('Flip & Rotation') }}
			</p>
			<div class="mb-3 flex gap-3">
				<div class="w-1/2">
					<FlipMenu :inline-label="true" :element="image" />
				</div>

				<div class="w-1/2">
					<RotateMenu :inline-label="true" :element="image" />
				</div>
			</div>

			<ElementFilter :key="history.key" :element="image" :grid-filters="true" />
		</ReserveSideSize>
	</div>
</template>
