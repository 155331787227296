<script lang="ts" setup>
import { Ref, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Element from '@/elements/element/classes/Element';
import { useBackgroundableElement } from '@/elements/element/composables/useBackgroundableElement';
import { useElementLock } from '@/elements/element/composables/useElementLock';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import { BackgroundableElement } from '@/elements/medias/crop/types/croppeable.type';
import { useI18n } from '@/i18n/useI18n';
// Props
const props = defineProps<{ element: Element }>();
const element = toRef(props, 'element');
const { trans } = useI18n();
const { isBackground } = useIsBackground(element);
const { removeElementAsBackground } = useBackgroundableElement(element as Ref<BackgroundableElement>);

const { toggleLockElement } = useElementLock(element);
</script>

<template>
	<hr
		v-if="!element.locked || isBackground"
		class="mx-2 hidden h-5 w-px shrink-0 border-0 bg-white/20 mockup:bg-fp-gray-250 lg:block"
	/>
	<button
		:id="`lock-${element.type}-toolbar`"
		data-testid="locked-img"
		class="disabled flex h-full items-center justify-center mockup:hidden"
		:class="{ 'pointer-events-none cursor-not-allowed opacity-50': element.group }"
		:tooltip="element.locked ? trans('Unlock') : trans('Lock')"
		tooltip-position="top"
		@click="isBackground ? removeElementAsBackground() : toggleLockElement()"
	>
		<span
			class="hover:text-whiteslidesgo:bg-purple-400 flex h-6 w-6 items-center justify-center rounded-md text-gray-100 hover:text-white slidesgo:hover:bg-purple-300"
			:class="{
				'bg-blue-500 text-white hover:bg-blue-400': element.locked,
			}"
		>
			<SvgIcon :name="element.locked ? 'lock' : 'unlock'" class="h-4 w-4" />
		</span>
		<Transition
			appear
			enter-active-class="transition duration-100 ease-out"
			enter-from-class="transform -translate-y-4 opacity-0"
		>
			<span
				v-if="element.locked"
				class="whitespace-nowrap text-2xs text-gray-100 lg:ml-2 lg:mt-0 lg:text-sm"
				data-button-label
				>{{ 'Locked' }}
			</span>
		</Transition>
	</button>
</template>
