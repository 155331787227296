import Bugsnag from '@bugsnag/js';
import { createSharedComposable } from '@vueuse/core';
import { findIndex } from 'lodash-es';
import { computed, nextTick, Ref, ref } from 'vue';

import { getArtboards } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import ElementTools from '@/elements/element/utils/ElementTools';
import { useHistoryStore } from '@/history/stores/history';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import { Position, Unit } from '@/Types/types';

export const useProject = createSharedComposable(() => {
	const store = useMainStore();

	const history = useHistoryStore();
	const project = useProjectStore();
	const { isIOS } = useDeviceInfo();
	const MAX_PAGES = 60;
	const temporalRefPage = ref(Page.createDefault());
	const { isEmbeddedContext, embeddedContextData } = useEditorMode();

	const artboard = useArtboard();
	const { fitZoomScale } = useZoom();
	const { isLogged } = useAuth();
	const { addElement, adjustContent } = usePage(temporalRefPage as Ref<Page>);

	const addPage = (page: Page, after?: Page) => {
		if (after) {
			const indexOfReference = project.pages.indexOf(after);
			project.pages.splice(indexOfReference + 1, 0, page);
			return;
		}
		project.pages.push(page);
	};

	const movePage = async (page: Page, position: number) => {
		const currentIndex = findIndex(project.pages, page);

		const prevValue = project.pages[position];

		project.$patch(() => {
			project.pages[position] = page;
			project.pages[currentIndex] = prevValue;

			// Ya que es  un cambio de posición no esta siendo detectado
			// forzamos guardado
			project.saveState?.();
		});

		// Es necesario refrescar componentes para que se actualice la posición de las páginas
		await nextTick();

		// Para conseguir el efecto de movimiento al desplazar
		// página hacia abajo y retrocedemos el scroll
		if (currentIndex < position) {
			scrollToPage({ page, fallback: true });
		} else if (position > 1 && position < project.pages.length - 2) {
			// Si la página es una página intermedia y va en dirección hacia arriba
			// da un salto de 3 páginas para ello debemos hacer un scroll intermedio a la segunda página
			scrollToPage({ page: project.pages[currentIndex] as Page, middlePage: true });
		}

		scrollToPage({ page });

		Bugsnag.leaveBreadcrumb(`Move page ${page.id} from ${currentIndex} to ${position}`);
	};

	const addPages = (pages: Page[]) => {
		pages.forEach((page) => addPage(page));
	};

	const addEmptyPage = async (after?: Page) => {
		const emptyPage = Page.create();
		addPage(emptyPage, after);

		await nextTick();

		store.setActivePage(emptyPage);

		Bugsnag.leaveBreadcrumb(`Add new page : ${emptyPage.id}`);
	};

	const duplicatePage = (page: Page) => {
		const { name, background, sourceId, backgroundImageId } = page;
		const newPage = Page.create({ name: `${name} copy`, background: background.copy(), sourceId, backgroundImageId });

		newPage.preview = page.preview;
		temporalRefPage.value = newPage;

		const newElements = ElementTools.fixRepeatedElementIds(project.allElements, page.elementsAsArray());

		if (page.backgroundImageId?.length) {
			const backgroundImageIndex = page.elementsAsArray().findIndex((el) => el.id === page.backgroundImageId);

			if (backgroundImageIndex >= 0) {
				newPage.backgroundImageId = newElements[backgroundImageIndex].id;
			}
		}

		newElements.forEach((el) => {
			addElement(el);
		});

		return newPage;
	};

	const copyPage = async (page: Page) => {
		const newPage = duplicatePage(page);

		addPage(newPage, page);

		await nextTick();

		store.setActivePage(newPage);

		Bugsnag.leaveBreadcrumb(`Add page copy : ${newPage.id}`);
	};

	const removePage = (page: Page) => {
		if (project.pages.length === 1) return;
		const indexRemovingPage = findIndex(project.pages, page);
		project.pages = project.pages.filter((toRemove) => toRemove.id !== page.id);

		if (page.id === store.activePageId) {
			store.setActivePage(
				project.pages[indexRemovingPage !== project.pages.length ? indexRemovingPage : project.pages.length - 1] as Page
			);
		}
		Bugsnag.leaveBreadcrumb(`Remove page: ${page.id}`);
	};

	const replacePage = (newPageData: Page, currentPage: Page) => {
		const index = findIndex(project.pages, currentPage);
		const newPage = duplicatePage(Page.create(newPageData as Page));
		project.pages.splice(index, 1, newPage);
		store.setActivePage(newPage);
	};

	const removeAllPages = () => (project.pages = []);

	const getPageFromElement = (element: Element) => {
		const page = project.pages.find((p) => p.elementsAsArray().some((el) => el.id === element.id));
		// if (!page) throw new Error('Page instance not found from Element');
		return page as Page;
	};

	const getPageFromDom = (canvas: HTMLElement) => {
		const id = canvas.id.substring(7);
		const page = project.pages.find((page) => page.id === id);
		if (!page) throw new Error('Page instance not found from HTML node');
		return page as Page;
	};

	const appendTemplate = async (slug: string, scaleElements?: number) => {
		const { pages, templateData, isSvg } = await TemplateLoader.fromSlug(slug);
		const { width, height, unit, dpi } = templateData.artboard;
		const size = { width, height };

		pages.forEach((page) => {
			page.elements = new Map(
				ElementTools.fixRepeatedElementIds(project.allElements, page.elementsAsArray()).map((el) => [el.id, el])
			);
			if (scaleElements) {
				page.elements.forEach((el) => el.scaleBy(scaleElements));
			}

			const applyDpiFix = !isSvg && unit === 'mm' && dpi !== artboard.MM_TO_PX;

			// Fix para el cambio de dpi
			if (applyDpiFix) {
				temporalRefPage.value = page;

				const currentSize = project.unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

				adjustContent(currentSize, {
					width: Math.round(width * dpi),
					height: Math.round(height * dpi),
				});
			}

			// Si es iOS aplicamos la escala calculada para esta sesión a todos los elementos
			if (isIOS.value && !applyDpiFix) {
				page.scaleBy(store.scaleMaxAllowedSize);
			}
		});

		addPages(pages);
	};

	const replaceTemplate = async (slug: string) => {
		store.finishedLoading = false;

		const { pages, templateData, isSvg } = await TemplateLoader.fromSlug(slug);
		const { width, height, unit, dpi } = templateData.artboard;
		const selectedPages = pages.filter((page, key) => !isEmbeddedContext.value || key === 0);
		const size = { width, height };

		if (templateData.category_tree.length) {
			store.projectCategory = templateData.category_tree.reverse()[0];
		}

		if (templateData.pack) {
			store.pack = templateData.pack;
		}

		if (!isLogged.value && store.userVector) {
			store.userVector = null;
		}

		// Si no estamos en una plantilla del usuario cambiamos la url
		if (!store.userVector && !isEmbeddedContext.value) {
			window.history.replaceState('', '', `/edit/${slug}`);
		}

		project.$patch(() => {
			project.flaticonSearch = templateData.flaticonSearch;
			project.sourceVectorId = templateData.id;
			project.scale = 1;
			project.category = store.projectCategory?.name as string;
			removeAllPages();
			artboard.setArtboardSize(width, height, unit);
			selectedPages.forEach((page, index) => {
				// Si el proyecto trae escala del backend vamos a reescalar todos los elementos al tamaño original
				// ya que en el backend ahora siempre vamos a tener los elementos a su tamaño original, esto deshace
				// la anterior versión del fix de ios que guardaba los elementons escalados en el backend
				if (templateData.scale !== 1) {
					page.scaleBy(1 / templateData.scale);
				}

				const applyDpiFix = !isSvg && unit === 'mm' && dpi !== artboard.MM_TO_PX;

				// Fix para el cambio de dpi
				if (applyDpiFix) {
					temporalRefPage.value = page;

					const currentSize = project.unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

					adjustContent(currentSize, {
						width: Math.round(width * dpi),
						height: Math.round(height * dpi),
					});
				}

				// En el modo para terceros siempre debemos de mantener el tamaño del proyecto base
				if (isEmbeddedContext.value && embeddedContextData.value.width && embeddedContextData.value.height) {
					temporalRefPage.value = page;

					const currentSize =
						project.unit === 'mm' ? artboard.convertMmToPx(project.size.width, project.size.height) : project.size;
					const newSize = artboard.convertMmToPx(embeddedContextData.value.width, embeddedContextData.value.height);

					artboard.setArtboardSize(embeddedContextData.value.width, embeddedContextData.value.height, 'mm');
					adjustContent(newSize, currentSize);
				}

				// Si es iOS aplicamos la escala calculada para esta sesión a todos los elementos
				if (isIOS.value && !applyDpiFix) {
					page.scaleBy(store.scaleMaxAllowedSize);
				}

				// En la página se esta guardado la preview que puede estar desfasada
				// Usamos la que hay en la respuesta de la api del vector si esta disponible
				page.preview = templateData.pages[index].preview || page.preview;
			});
			addPages(selectedPages);
		});

		fitZoomScale();

		store.$patch(() => {
			store.colorPalettes = templateData.colorPalettes || [];
			store.activePageId = selectedPages[0].id;
			store.finishedLoading = true;
		});

		// si no había cambios y era plantilla nueva, empezamos de 0
		// el autoguardado ya se encarga de crear el estado inicial de nuevo
		if (history.states.length === 1 && !store.userVector) {
			history.states = [];
		}
	};

	const openUserTemplate = async (slug: string) => {
		store.finishedLoading = false;
		store.inSchedules = false;
		let pages: any = null;
		let templateData: any = null;

		try {
			const res = await TemplateLoader.fromSlug(slug);
			pages = res.pages;
			templateData = res.templateData;

			const { width, height, unit, dpi } = templateData.artboard;
			const size = { width, height };

			pages.forEach((page: Page) => {
				// Fix para el cambio de dpi
				if (!res.isSvg && unit === 'mm' && dpi !== artboard.MM_TO_PX) {
					temporalRefPage.value = page;

					const currentSize = unit === 'mm' ? artboard.convertMmToPx(size.width, size.height) : size;

					adjustContent(currentSize, {
						width: Math.round(width * dpi),
						height: Math.round(height * dpi),
					});
					project.invalidateServerVersion?.(temporalRefPage.value);
				}
			});
		} catch (error) {
			store.finishedLoading = true;
			return Promise.reject();
		}

		const { width, height, unit } = templateData.artboard;

		if (templateData.category_tree.length) {
			store.projectCategory = templateData.category_tree.reverse()[0];
		}

		if (templateData.pack) {
			store.pack = templateData.pack;
		}

		if (templateData.inSchedules) {
			store.inSchedules = templateData.inSchedules;
		}

		if (!isLogged.value && store.userVector) {
			store.userVector = null;
		}

		window.history.replaceState('', '', `/edit/${slug}`);

		project.$patch(() => {
			project.flaticonSearch = templateData.flaticonSearch;
			project.sourceVectorId = templateData.id;
			project.scale = templateData.scale;
			project.id = templateData.userVectorId as string;
			project.name = templateData.name;

			removeAllPages();
			artboard.setArtboardSize(width, height, unit);
			addPages(pages);
		});

		fitZoomScale();

		store.$patch(() => {
			store.colorPalettes = templateData.colorPalettes || [];
			store.activePageId = pages[0].id;
			store.userVector = {
				uuid: templateData.userVectorId,
				project: templateData.project || 'wepik',
				preview: templateData.preview || '',
			};
			store.finishedLoading = true;
		});

		history.states = [];
	};

	const setArtboardFromUrl = async () => {
		const isNewArtboard = location.href.includes('new-artboard');

		if (!isNewArtboard) {
			return false;
		}

		const params = new URLSearchParams(location.href.split('?')[1]);
		const hasCustomSizeInUrl = params.get('width') && params.get('height') && params.get('unit');
		const hasCustomArtboardInUrl = params.get('size');

		let height = null;
		let width = null;
		let unit = null;

		if (hasCustomSizeInUrl) {
			width = !Number.isNaN(Number(params.get('width'))) ? Number(params.get('width')) : 500;
			height = !Number.isNaN(Number(params.get('height'))) ? Number(params.get('height')) : 500;
			unit = ['px', 'mm'].includes(params.get('unit') as string) ? (params.get('unit') as Unit) : 'px';
		} else if (hasCustomArtboardInUrl) {
			const artboards = await getArtboards();
			const artboardName = params.get('size');

			if (!artboards.length) return false;

			const artboardData = artboards.find((artboard) => artboard.name === artboardName);

			height = artboardData?.height;
			width = artboardData?.width;
			unit = artboardData?.unit;
		}

		if (!height || !width || !unit) {
			return false;
		}

		artboard.setArtboardSize(width, height, unit);

		return true;
	};

	const canMoveDown = (page: Page) => {
		const position = findIndex(project.pages, page);
		return project.pages.length > 1 && position < project.pages.length - 1;
	};

	const canMoveUp = (page: Page) => {
		const position = findIndex(project.pages, page);
		return project.pages.length > 1 && position > 0;
	};

	const findPosition = (page: Page) => {
		return findIndex(project.pages, page);
	};

	const totalPages = computed(() => {
		return project.pages.length;
	});

	const canAddPages = computed(() => totalPages.value < MAX_PAGES);

	const getElementFromDom = (element: HTMLElement): Element | undefined => {
		return project.pages.flatMap((t) => Object.values(t.elementsAsArray())).find((e) => element.id.includes(e.id));
	};

	const getPageElementUnderMouse = (screenPosition: Position): Page | null => {
		const found = document.elementFromPoint(screenPosition.x, screenPosition.y);
		if (!found) return null;

		const pageElementContainer = found.hasAttribute('data-elements-container')
			? found
			: found.closest('[data-elements-container]');
		if (!pageElementContainer) return null;

		const pageElement = pageElementContainer.closest('[id^="interactive-canvas-"');
		if (!pageElement) return null;

		const pageUnderMouse = project.pages.find((p) => pageElement.id.endsWith(p.id)) as Page;
		if (!pageUnderMouse) return null;

		return pageUnderMouse;
	};

	/*
	 *	Método para el control de scroll de páginas
	 *	Recibe un objeto con una page a la que hacer scroll,
	 *  fallback por si necesitas que retroceda a la posición anterior
	 *  y un middlePage por si necesitas que vaya instantaneamente a la página anterior
	 */
	const scrollToPage = ({ page, fallback, middlePage }: { page: Page; fallback?: boolean; middlePage?: boolean }) => {
		const scrollArea = document.querySelector('#scroll-area');
		const pageElement = document.querySelector<HTMLElement>(`[data-page-id='${page.id}']`);
		if (scrollArea && pageElement) {
			if (fallback) {
				scrollArea.scrollTo(0, pageElement.offsetTop - pageElement.offsetHeight);
				return;
			}

			if (middlePage) {
				scrollArea.scrollTo(0, pageElement.offsetTop);
				return;
			}

			scrollArea.scrollTo({
				left: 0,
				top: pageElement.offsetTop,
				behavior: 'smooth',
			});
		}
	};

	const isAiPresentation = computed(() => {
		return store.generator === 'ai-presentations';
	});

	const projectIsPresentation = computed(() => {
		const ID_PRESENTATION_CATEGORY = 1870;

		return store.projectCategory?.id === ID_PRESENTATION_CATEGORY;
	});

	return {
		...artboard,
		addPage,
		addEmptyPage,
		duplicatePage,
		copyPage,
		removePage,
		replacePage,
		removeAllPages,
		getPageFromElement,
		getPageFromDom,
		appendTemplate,
		replaceTemplate,
		openUserTemplate,
		movePage,
		findPosition,
		canMoveDown,
		canMoveUp,
		totalPages,
		MAX_PAGES,
		getElementFromDom,
		setArtboardFromUrl,
		addPages,
		canAddPages,
		getPageElementUnderMouse,
		isAiPresentation,
		projectIsPresentation,
	};
});
