<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { getAiPresentationStyles, postAiPresentation } from '@/api/DataApiClient';
import WithAuth from '@/auth/components/WithAuth.vue';
import Modal from '@/common/components/Modal.vue';
import ReserveSideSize from '@/common/components/ReserveSideSize.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useTracksJob } from '@/common/composables/useTracksJob';
import GenerateAiPresentation from '@/editor/components/GenerateAiPresentation.vue';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useMainStore } from '@/editor/stores/store';
import LangListbox from '@/elements/medias/images/image/components/ai/LangListbox.vue';
import PresentationWaiter from '@/elements/medias/images/image/components/ai/PresentationWaiter.vue';
import ToneListbox from '@/elements/medias/images/image/components/ai/ToneListbox.vue';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useI18n } from '@/i18n/useI18n';
import { AiPresentationLanguages, AiPresentationParam, AiPresentationStyle, AiPresentationTone } from '@/Types/types';

const step = ref(1);
const topic = ref('');
const slides = ref(8);
const showGenerateModal = ref(false);
const showLoadingModal = ref(false);
const tone = ref(TextTools.capitalizeFirstLetter(AiPresentationTone.unspecified));
const lang = ref<AiPresentationLanguages>();
const activeStyle = ref<AiPresentationStyle>();
const styles = ref<AiPresentationStyle[]>([]);

const store = useMainStore();
const { trans } = useI18n();
const { trackJob } = useTracksJob();
const toast = useToast();

const onTryIt = async () => {
	step.value = 2;
	store.mobileNavigationPanelAutoHeight = false;

	if (!styles.value.length) {
		const result = await getAiPresentationStyles();

		styles.value = result.data.value;
		activeStyle.value = styles.value[0];
	}
};

const onCloseModal = () => {
	step.value = 1;
	store.mobileNavigationPanelAutoHeight = true;
};

const onClickGenerateModal = () => {
	showGenerateModal.value = true;
};

const onCloseGenerateModal = () => {
	showGenerateModal.value = false;
};

const onClickStyle = (style: AiPresentationStyle) => {
	if (activeStyle.value?.id === style.id) return;

	activeStyle.value = style;
};

const onSelectedTone = (newTone: AiPresentationTone) => {
	tone.value = newTone;
};
const onSelectedLang = (newLang: AiPresentationLanguages) => {
	lang.value = newLang;
};

const setUrlLang = (url: string, lang: string): string => {
	var conector = url.indexOf('?') < 0 ? '?' : '&';

	return `${url}${conector}lang=${lang}`;
};

const onGeneratePresentation = async () => {
	GAnalytics.trackGA4('ai-presentation-generate', {
		topic: topic.value,
		tone: tone.value,
		slides: slides.value,
		page_location: window.location.href,
		language: lang.value || 'Auto',
		style: activeStyle.value?.name || 'Unespecified',
	});

	let finalStyle = 0;

	if (activeStyle.value?.id) {
		finalStyle = activeStyle.value.id;
	}

	let finalTone = '';

	if (tone.value.toLowerCase() !== AiPresentationTone.unspecified) {
		finalTone = TextTools.capitalizeFirstLetter(tone.value);
	}

	if (activeStyle.value?.id) {
		finalStyle = activeStyle.value.id;
	}

	const body = {
		topic: topic.value || '',
		tone: finalTone,
		language: lang.value,
		vector: finalStyle,
		slides: slides.value,
	};

	try {
		showLoadingModal.value = true;

		const job = await postAiPresentation(body);

		const result = await trackJob(job.data.value.job, 160000, 30000);
		showGenerateModal.value = false;
		showLoadingModal.value = false;

		if (!result?.url) throw new Error('Something went wrong, please try again later.');

		window.location.href = useI18n().locale.value == 'en' ? result.url : setUrlLang(result.url, useI18n().locale.value);
	} catch (error) {
		console.error(error);
		showGenerateModal.value = false;
		showLoadingModal.value = false;
		toast.error(trans('Something went wrong, please try again later.'));
	}
};

onMounted(async () => {
	store.mobileNavigationPanelAutoHeight = true;

	if (!styles.value.length) {
		const result = await getAiPresentationStyles();

		if (result.error.value) return console.error(result.error);

		styles.value = result.data.value;
		activeStyle.value = styles.value[0];
	}

	setQueryParams();
});

const setQueryParams = () => {
	const queryParams = new URLSearchParams(window.location.search);

	const aiPresentationParam = queryParams.get('aipresentation');

	if (aiPresentationParam) {
		const aiPresentation: AiPresentationParam = JSON.parse(aiPresentationParam) as AiPresentationParam;

		if (aiPresentation) {
			if (aiPresentation.language) lang.value = aiPresentation.language as AiPresentationLanguages;
			if (aiPresentation.tone) tone.value = aiPresentation.tone.toLowerCase();
			if (aiPresentation.topic) topic.value = aiPresentation.topic;
			if (aiPresentation.slides) slides.value = aiPresentation.slides;
			if (aiPresentation.vector) activeStyle.value = styles.value.find((s) => s.id === aiPresentation.vector);
		}

		step.value = 2;
	}
};

onUnmounted(() => {
	store.mobileNavigationPanelAutoHeight = false;
});
</script>

<template>
	<ReserveSideSize :class="'text-gray-800 scrollbar-thumb-gray-600'">
		<div>
			<PanelHeader
				:show-back-button="false"
				:title="trans('AI Presentation')"
				@close-modal="onCloseModal"
				@close-panel="onCloseModal"
			/>
		</div>
		<div v-if="step === 1">
			<div class="mb-8">
				<div class="animation-ai relative mb-6 hidden aspect-square rounded-lg bg-gray-700 lg:block">
					<span class="bar absolute left-16 top-20 z-10 h-32 w-4 rounded bg-blue-500">
						<span class="block h-full w-2 rounded bg-blue-400"></span>
						<span class="stars image-2 absolute -right-10 top-2 h-6 w-6 opacity-0">
							<svg
								class="absolute -left-2 -top-2 h-4 w-4 -rotate-12 animate-pulse"
								fill="url(#grad1)"
								viewBox="0 0 71 71"
								xmlns="http://www.w3.org/2000/svg"
							>
								<defs>
									<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
										<stop offset="0%" style="stop-color: #7ab6ff; stop-opacity: 1" />
										<stop offset="100%" style="stop-color: #b4b4ff; stop-opacity: 1" />
									</linearGradient>
								</defs>
								<path
									d="M66.8811 29.9516L50.2515 23.6078C48.7109 23.0188 47.5327 21.8406 46.9437 20.3L40.5999 3.67031C38.7421 -1.22344 31.8093 -1.22344 29.9515 3.67031L23.6078 20.3C23.0187 21.8406 21.8406 23.0188 20.3 23.6078L3.67031 29.9516C-1.22344 31.8094 -1.22344 38.7422 3.67031 40.6L20.3 46.9438C21.8406 47.5328 23.0187 48.7109 23.6078 50.2516L29.9515 66.8813C31.8093 71.775 38.7421 71.775 40.5999 66.8813L46.9437 50.2516C47.5327 48.7109 48.7109 47.5328 50.2515 46.9438L66.8811 40.6C71.7749 38.7422 71.7749 31.8094 66.8811 29.9516Z"
								/>
							</svg>
							<svg
								class="absolute -top-2 right-0 h-3.5 w-3.5 rotate-45 animate-pulse"
								fill="url(#grad1)"
								viewBox="0 0 71 71"
								xmlns="http://www.w3.org/2000/svg"
							>
								<defs>
									<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
										<stop offset="0%" style="stop-color: #7ab6ff; stop-opacity: 1" />
										<stop offset="100%" style="stop-color: #b4b4ff; stop-opacity: 1" />
									</linearGradient>
								</defs>
								<path
									d="M66.8811 29.9516L50.2515 23.6078C48.7109 23.0188 47.5327 21.8406 46.9437 20.3L40.5999 3.67031C38.7421 -1.22344 31.8093 -1.22344 29.9515 3.67031L23.6078 20.3C23.0187 21.8406 21.8406 23.0188 20.3 23.6078L3.67031 29.9516C-1.22344 31.8094 -1.22344 38.7422 3.67031 40.6L20.3 46.9438C21.8406 47.5328 23.0187 48.7109 23.6078 50.2516L29.9515 66.8813C31.8093 71.775 38.7421 71.775 40.5999 66.8813L46.9437 50.2516C47.5327 48.7109 48.7109 47.5328 50.2515 46.9438L66.8811 40.6C71.7749 38.7422 71.7749 31.8094 66.8811 29.9516Z"
								/>
							</svg>
							<svg
								class="absolute right-1 top-2 h-4 w-4 -rotate-12 animate-pulse"
								fill="url(#grad1)"
								viewBox="0 0 71 71"
								xmlns="http://www.w3.org/2000/svg"
							>
								<defs>
									<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
										<stop offset="0%" style="stop-color: #7ab6ff; stop-opacity: 1" />
										<stop offset="100%" style="stop-color: #b4b4ff; stop-opacity: 1" />
									</linearGradient>
								</defs>
								<path
									d="M66.8811 29.9516L50.2515 23.6078C48.7109 23.0188 47.5327 21.8406 46.9437 20.3L40.5999 3.67031C38.7421 -1.22344 31.8093 -1.22344 29.9515 3.67031L23.6078 20.3C23.0187 21.8406 21.8406 23.0188 20.3 23.6078L3.67031 29.9516C-1.22344 31.8094 -1.22344 38.7422 3.67031 40.6L20.3 46.9438C21.8406 47.5328 23.0187 48.7109 23.6078 50.2516L29.9515 66.8813C31.8093 71.775 38.7421 71.775 40.5999 66.8813L46.9437 50.2516C47.5327 48.7109 48.7109 47.5328 50.2515 46.9438L66.8811 40.6C71.7749 38.7422 71.7749 31.8094 66.8811 29.9516Z"
								/>
							</svg>
						</span>
					</span>
					<div class="absolute inset-0 flex items-center justify-center">
						<SvgIcon name="ai-presentation-image-1" class="h-36 w-36" />
					</div>
					<div class="image-2 absolute inset-0 flex items-center justify-center opacity-0">
						<SvgIcon name="ai-presentation-image-2" class="h-36 w-36" />
					</div>
				</div>
				<p class="mb-4 text-lg font-semibold text-white">
					{{ trans('Make a presentation about anything - Thanks to AI, type and let the magic happen') }}
				</p>
				<p class="text-gray-100">
					{{ trans('Ask our new AI Feature to generate personalized presentations in seconds!') }}
				</p>
			</div>
			<button
				class="mb-4 h-9 w-full rounded bg-gradient-to-r from-blue-500 to-purple-400 px-2 font-semibold text-white hover:opacity-80"
				@click="onTryIt"
			>
				{{ trans('Try it') }}
			</button>
		</div>

		<WithAuth v-else-if="step === 2" @close="onCloseModal">
			<div class="h-[calc(100%-44px)]">
				<ReserveSideSize data-testid="scroll-container" :class="'text-gray-800 scrollbar-thumb-gray-600'">
					<form class="flex flex-col gap-3" @submit.prevent="onClickGenerateModal">
						<div class="hidden lg:block">
							<p class="py-4 text-gray-100">
								{{ trans('Please specify so that we can provide you with the best presentation.') }}
							</p>
							<hr class="my-3 border-gray-600" />
						</div>
						<div class="mt-2 flex flex-col gap-4 lg:mt-0 lg:gap-6">
							<div class="flex flex-col gap-2">
								<h2 class="text-gray-100">{{ trans('Topic') }}</h2>
								<input
									id="ai-presentation-topic"
									v-model="topic"
									:placeholder="trans('Ex: Benefits of working from home')"
									class="flex h-10 w-full appearance-none rounded-sm border border-transparent bg-gray-900 p-2 pl-4 pr-10 text-sm text-gray-100 placeholder-gray-300/50 shadow-none focus:border-blue-500 focus:outline-none lg:rounded"
								/>
							</div>
							<div class="flex justify-between gap-4">
								<div class="flex w-full flex-col gap-2">
									<h2 class="text-gray-100">{{ trans('Tone') }}</h2>
									<ToneListbox :tone="tone" @select="onSelectedTone" />
								</div>
								<div class="flex w-full flex-col gap-2">
									<h2 class="text-gray-100">{{ trans('Pages') }}</h2>
									<input
										v-model="slides"
										type="number"
										min="6"
										max="20"
										class="flex h-10 w-full appearance-none rounded-sm border border-transparent bg-gray-900 p-2 pl-4 pr-10 text-sm text-gray-100 placeholder-gray-300/50 shadow-none focus:border-blue-500 focus:outline-none lg:rounded"
										placeholder="6"
									/>
								</div>
							</div>
							<div class="flex justify-between gap-4">
								<div class="flex w-full flex-col gap-2">
									<h2 class="text-gray-100">{{ trans('Language') }}</h2>
									<LangListbox :lang="lang" @select="onSelectedLang" />
								</div>
							</div>
							<div class="flex flex-col gap-2">
								<h2 class="text-gray-100">{{ trans('Style') }}</h2>
								<div class="grid grid-cols-2 gap-3">
									<div
										v-for="style in styles"
										:key="style.id"
										class="relative overflow-hidden rounded-md border-2"
										:class="activeStyle?.id === style.id ? 'border-blue-500' : 'border-transparent'"
										@click="onClickStyle(style)"
									>
										<span
											v-if="activeStyle?.id === style.id"
											class="absolute right-2 top-2 flex items-center justify-center rounded-full bg-blue-500"
										>
											<SvgIcon name="check" class="m-1 h-2 w-2 text-white" />
										</span>
										<img :src="style.image" :alt="style.name" />
									</div>
								</div>
							</div>
							<button
								class="mb-4 flex h-9 w-full items-center justify-center gap-4 rounded bg-gradient-to-r from-blue-500 to-purple-400 px-2 font-semibold text-white hover:opacity-80"
								type="submit"
							>
								<SvgIcon name="wand" class="h-4 w-4" />
								<span class="text-sm">{{ trans('Generate AI Presentation') }}</span>
							</button>
						</div>
					</form>
				</ReserveSideSize>
			</div>
		</WithAuth>
		<GenerateAiPresentation v-if="showGenerateModal" @close="onCloseGenerateModal" @confirm="onGeneratePresentation" />
		<Modal v-if="showLoadingModal" :open="true">
			<PresentationWaiter :topic="topic" />
		</Modal>
	</ReserveSideSize>
</template>

<style lang="sass">
.select-container:before
	@apply bg-red-600/80 text-white shadow-lg

.animation-ai
	.bar
		animation: animation-ai linear 10s infinite
		animation-delay: 0.5s

		@keyframes animation-ai
			0%
				transform: translateX(0)

			10%
				transform: translateX(150px)

			40%
				transform: translateX(150px)

			50%
				transform: translateX(0)

	.stars
		svg:fist-child
			animation-delay: 0.5s

		svg:nth-child(2)
			animation-delay: 1s

		svg:last-child
			animation-delay: 1.5s
	.image-2
		animation: ai-image linear 10s infinite
		animation-delay: 0.5s

		@keyframes ai-image
			0%
				opacity: 0

			10%
				opacity: 1

			40%
				opacity: 1

			50%
				opacity: 0
</style>
