import { computed, Ref } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import { BorderRadiusType, TypeBorder } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useRounding = (element: Ref<Box>) => {
	const maxValue = computed(() => Math.min(element.value.size.width, element.value.size.height) / 2);
	const hasBorderRadius = computed(() => {
		return !!element.value.border.radius.some((r) => !!r);
	});
	//  TODO: revisar en  implementación de tablas : habría que calcular el porcentaje de cada corner a partir de su valor
	const percentCalculated = computed(() => {
		// redondeamos al entero inferior para que no se salga del rango de 0 a 100
		const newPercent = Math.floor(MathTools.ruleOfThree(maxValue.value, 100, element.value.border.radius[0]));
		return newPercent;
	});
	//  TODO: revisar en  implementación de tablas
	const setRadiusByPercent = (newPercent: number, cornerIndex?: number) => {
		if (cornerIndex) {
			element.value.border.radius[cornerIndex] = MathTools.ruleOfThree(
				100 * element.value.border.size,
				maxValue.value,
				newPercent
			);
			return;
		}
		element.value.border.radius = Array(4).fill(
			MathTools.ruleOfThree(100, maxValue.value, newPercent)
		) as BorderRadiusType;
	};

	const resetRadius = () => {
		element.value.border.radius = Box.defaults().border.radius;
	};

	return {
		maxValue,
		hasBorderRadius,
		resetRadius,
		percentCalculated,
		setRadiusByPercent,
	};
};
