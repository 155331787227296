import Bugsnag from '@bugsnag/js';
import { createEventHook, createSharedComposable, promiseTimeout, useEventListener } from '@vueuse/core';
import { useDebounceFn } from '@vueuse/shared';
import Gesto, { OnPinch } from 'gesto';
import { computed, nextTick, onMounted, Ref, ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import EventTools from '@/interactions/classes/EventTools';
import { useMoveable } from '@/interactions/composables/useInteractiveElements';
import { useArtboard } from '@/project/composables/useArtboard';
import MathTools from '@/utils/classes/MathTools';

export const useZoom = createSharedComposable(() => {
	const isPinching = ref(false);
	const isZooming = ref(false);
	const finishHook = createEventHook();
	const startHook = createEventHook();
	const { isTouch, isMobile } = useDeviceInfo();
	const { moveable, toggleMoveableOpacity } = useMoveable();
	const { textAI } = useAiWriter();

	onMounted(() => {
		if (isTouch.value) {
			initZoomMobile();
		}
	});

	const ZOOM_OPTIONS = MathTools.range(10, 500, 1);
	const scrollArea = ref(document.querySelector('.scroll-area')) as Ref<HTMLElement>;

	const store = useMainStore();
	const { artboardSizeInPx } = useArtboard();

	const scaleAsPercentage = computed(() => (store.scale || 0) * 100);

	const foundZoomIndex = computed(() => findClosestZoomIndex(scaleAsPercentage.value));
	const isMaxZoom = computed(() => foundZoomIndex.value === ZOOM_OPTIONS.length - 1);
	const isMinZoom = computed(() => !foundZoomIndex.value);

	const decreaseZoom = async (ev?: MouseEvent | OnPinch, step = 1) => {
		if (isMinZoom.value) return;

		const indexToGo = foundZoomIndex.value - step;

		const previous = ZOOM_OPTIONS[Math.max(0, indexToGo)];
		ev ? await setMouseZoom(previous, ev) : setZoomDirectly(previous);
		Bugsnag.leaveBreadcrumb(
			`Decrease zoom ${(ev?.type as string) === 'wheel' ? 'using mouse wheel' : 'to'}: ${scaleAsPercentage.value}%`
		);
	};

	const increaseZoom = async (ev?: MouseEvent | OnPinch, step = 1) => {
		if (isMaxZoom.value) return;

		const lastIndex = ZOOM_OPTIONS.length - 1;
		const indexToGo = foundZoomIndex.value + step;
		const validatedIndex = lastIndex <= indexToGo ? lastIndex : indexToGo;

		const next = ZOOM_OPTIONS[validatedIndex];
		ev ? await setMouseZoom(next, ev) : setZoomDirectly(next);

		Bugsnag.leaveBreadcrumb(
			`Increase zoom ${(ev?.type as string) === 'wheel' ? 'using mouse wheel' : 'to'}: ${scaleAsPercentage.value}%`
		);
	};

	const fitZoomScale = (maxScale?: number) => {
		const scrollArea = document.querySelector('#scroll-area');
		if (!scrollArea) throw new Error('scrollArea is undefined');

		const { width: scrollAreaWidth, height: scrollAreaHeight } = scrollArea.getBoundingClientRect();
		const { height, width } = artboardSizeInPx.value;

		const pageProportion = width / height;
		const scrollAreaProportion = scrollAreaWidth / scrollAreaHeight;

		// Si el svg es más alto que el canvas, ajustamos a lo alto. Si no, a lo ancho.
		const ratio = scrollAreaProportion > pageProportion ? scrollAreaHeight / height : scrollAreaWidth / width;
		// calculamos el factor de multiplicación del valor mínimo para calcular el ratio en mobile,
		// si la página tiene mucho alto con respecto al ancho, aplicamos un factor de multiplicación menor
		const scaleFactorToMobile = pageProportion < 0.8 ? 0.7 : 0.9;

		const scale = Math.min(ratio * (isMobile.value ? scaleFactorToMobile : 0.66), maxScale || Infinity);
		setZoomDirectly(scale * 100);
	};

	const setZoomDirectly = async (zoom: number) => {
		setScrollArea();
		startHook.trigger(store.scale);
		store.setScale(zoom / 100);

		Bugsnag.leaveBreadcrumb(`Set zoom from options: ${scaleAsPercentage.value}%`);
		finishZoom();
	};

	const fixScrollPosition = (scrollArea: Ref<HTMLElement>, scrollToX: number, scrollToY: number) => {
		// Si no hay barra de scroll fixeamos la posición del scroll, si no manetenemos su valor
		if (scrollArea.value.scrollWidth <= scrollArea.value.clientWidth) {
			scrollToX = 0;
		}

		if (scrollArea.value.scrollHeight <= scrollArea.value.clientHeight) {
			scrollToY = 0;
		}
		scrollArea.value.scrollTo(scrollToX, scrollToY);
	};

	const setScrollArea = () => {
		if (!scrollArea.value) scrollArea.value = document.querySelector('#scroll-area') as HTMLElement;
	};
	const setScrollInNewScale = async (zoom: number, ev: MouseEvent | OnPinch) => {
		if (textAI.value) return;
		setScrollArea();
		if (!scrollArea.value) throw new Error('scrollArea is undefined');
		// Guardamos lo necesario para calcular el nuevo scroll antes de hacer el zoom
		const mousePos = getEventPosition(ev);
		const previousWidth = scrollArea.value.scrollWidth;
		const previousHeight = scrollArea.value.scrollHeight;
		const boundContainer = scrollArea.value.getBoundingClientRect();

		const { x, y } = getScrollPosition(ev);

		const percentageScrollWidth = MathTools.ruleOfThree(previousWidth, 1, x);
		const percentageScrollHeight = MathTools.ruleOfThree(previousHeight, 1, y);

		store.setScale(zoom / 100);

		await nextTick();

		const scrollPosX = scrollArea.value.scrollWidth * percentageScrollWidth;
		const scrollPosY = scrollArea.value.scrollHeight * percentageScrollHeight;

		const scrollToX = mousePos ? scrollPosX - mousePos.x : scrollPosX - boundContainer.width / 2;
		const scrollToY = mousePos ? scrollPosY - mousePos.y : scrollPosY - boundContainer.height / 2;

		fixScrollPosition(scrollArea, scrollToX, scrollToY);
	};

	const setMouseZoom = async (zoom: number, ev: MouseEvent | OnPinch) => {
		setScrollArea();

		if (!scrollArea.value) throw new Error('scrollArea is undefined');

		startHook.trigger(store.scale);

		await setScrollInNewScale(zoom, ev);

		finishZoom();
	};

	const finishZoom = useDebounceFn(() => finishHook.trigger(store.scale), 400);

	const findClosestZoomIndex = (scalePercentage: number) => {
		let min = Infinity;
		let closest = 0;

		// Buscamos el zoom más cercano al valor dado
		ZOOM_OPTIONS.forEach((option, index) => {
			const diff = scalePercentage - option;

			if (diff < 0) return;

			if (diff < min) {
				min = diff;
				closest = index;
			}
		});

		return closest;
	};

	const getEventPosition = (ev: MouseEvent | OnPinch) => {
		setScrollArea();
		// CHECKPOINT: hablar en la reunión
		if (!scrollArea.value) {
			throw new Error('scrollArea is undefined');
		}
		return EventTools.getEventPositionInElement(scrollArea.value, ev);
	};

	const getScrollPosition = (ev: MouseEvent | OnPinch) => {
		setScrollArea();
		// CHECKPOINT: hablar en la reunión
		if (!scrollArea.value) {
			throw new Error('scrollArea is undefined');
		}

		const mousePos = getEventPosition(ev);
		const boundContainer = scrollArea.value.getBoundingClientRect();

		let x;
		let y;

		if (mousePos) {
			// Posición del ratón
			({ x, y } = mousePos);
			x += scrollArea.value.scrollLeft;
			y += scrollArea.value.scrollTop;
		} else {
			// Centro del canvas
			x = boundContainer.width / 2 + scrollArea.value.scrollLeft;
			y = boundContainer.height / 2 + scrollArea.value.scrollTop;
		}

		return {
			x,
			y,
		};
	};

	const initZoomMobile = () => {
		const scrollArea = document.querySelector('#scroll-area');
		if (!scrollArea) return;

		disableIosZoomNative();
		new Gesto(scrollArea, {
			container: scrollArea,
			events: ['touch'],
			preventDefault: false,
		})
			.on('pinchStart', async (ev) => {
				isPinching.value = true;
				ev.datas.startZoom = store.scale;
				toggleMoveableOpacity(true);
			})
			.on('pinch', async (e) => {
				const zoom = e.datas.startZoom * e.scale * 100;
				if (zoom < ZOOM_OPTIONS[0]) {
					return;
				}
				if (zoom > ZOOM_OPTIONS[ZOOM_OPTIONS.length - 1]) {
					return;
				}

				await setScrollInNewScale(zoom, e);
			})
			.on('pinchEnd', async () => {
				// Control del zoom para el ring de selección de los elementos
				const ringWithZoom = 2 / store.scale;
				document.documentElement.style.setProperty('--zoom-ring', `${ringWithZoom}px`);
				//acttualizamos las dimensiones de moveable y recuperamos el opacity del target
				moveable.value?.updateRect();
				await promiseTimeout(50);
				toggleMoveableOpacity(false);

				isPinching.value = false;
			});
	};

	const disableIosZoomNative = () => {
		useEventListener(window.document, 'touchmove', (event: any) => {
			if (event.touches.length === 1) return;
			event = event.originalEvent || event;
			if (event.scale !== 1) {
				event.preventDefault();
			}
		});
		useEventListener(window.document, 'gesturestart', (event) => {
			event.preventDefault();
		});
	};

	const onZoomStart = startHook.on;
	const onZoomFinish = finishHook.on;

	onZoomStart(() => {
		isZooming.value = true;
	});

	onZoomFinish(() => {
		// Control del zoom para el ring de selección de los elementos
		const ringWithZoom = 2 / store.scale;
		document.documentElement.style.setProperty('--zoom-ring', `${ringWithZoom}px`);
		isZooming.value = false;

		moveable.value?.updateTarget();
	});

	return {
		ZOOM_OPTIONS,
		foundZoomIndex,
		isMaxZoom,
		isMinZoom,
		scaleAsPercentage,
		isPinching,
		isZooming,
		decreaseZoom,
		fitZoomScale,
		increaseZoom,
		setZoomDirectly,
		initZoomMobile,
		findClosestZoomIndex,
		onZoomStart,
		onZoomFinish,
	};
});
