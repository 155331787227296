<script lang="ts" setup>
import { computed, toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import Element from '@/elements/element/classes/Element';
import { useElementOrderOrchestrator } from '@/elements/element/composables/useElementOrderOrchestrator';
import BaseImage from '@/elements/medias/images/base-image/classes/BaseImage';
import { usePhotoMode } from '@/elements/medias/images/image/composables/usePhotoMode';
import { useI18n } from '@/i18n/useI18n';

// Props
const props = defineProps<{ element: Element }>();

// Data
const element = toRef(props, 'element');

// Using composables
const { moveUp, moveDown, canGoUp, canGoDown } = useElementOrderOrchestrator(element).value;
const { checkIsPhotoModeImage } = usePhotoMode();
const { isMobile } = useDeviceInfo();
const { trans } = useI18n();

const isPhotoModeImage = computed(() => element.value instanceof BaseImage && checkIsPhotoModeImage(element.value));
</script>

<template>
	<div class="flex">
		<button
			:id="`backward-${element.type}-toolbar`"
			data-testid="backward"
			class="flex shrink-0 flex-col items-center lg:w-auto lg:flex-row lg:px-2"
			:class="{
				'text-gray-300 opacity-75': !canGoDown,
				'text-gray-100 hover:text-white': canGoDown,
				'h-9 w-9 justify-center': isPhotoModeImage,
				'h-11 w-[15vw] justify-between': !isPhotoModeImage,
			}"
			:disabled="!canGoDown"
			@click="moveDown"
		>
			<SvgIcon name="layer-down" class="h-5 w-5 fill-current" />
			<span v-if="!isPhotoModeImage || !isMobile" class="text-2xs lg:ml-2 lg:text-sm">{{ trans('Backward') }}</span>
		</button>
		<button
			:id="`forward-${element.type}-toolbar`"
			data-testid="forward"
			class="flex shrink-0 flex-col items-center lg:w-auto lg:flex-row lg:px-2"
			:class="{
				'text-gray-300 opacity-75': !canGoUp,
				'text-gray-100 hover:text-white': canGoUp,
				'h-9 w-9 justify-center': isPhotoModeImage,
				'h-11 w-[15vw] justify-between': !isPhotoModeImage,
			}"
			:disabled="!canGoUp"
			@click="moveUp"
		>
			<SvgIcon name="layer-up" class="h-5 w-5 fill-current" />
			<span v-if="!isPhotoModeImage || !isMobile" class="text-2xs lg:ml-2 lg:text-sm">{{ trans('Forward') }}</span>
		</button>
	</div>
</template>
