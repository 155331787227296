<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { useElementSize } from '@vueuse/core';
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import CopyToolbar from '@/elements/element/components/buttons/CopyToolbar.vue';
import LockButtonToolbar from '@/elements/element/components/buttons/LockButtonToolbar.vue';
import MoreToolsToolbar from '@/elements/element/components/buttons/MoreToolsToolbar.vue';
import RemoveToolbar from '@/elements/element/components/buttons/RemoveToolbar.vue';
import FlipMenuToolbar from '@/elements/element/components/menus/FlipMenuToolbar.vue';
import OrderToolbar from '@/elements/element/components/menus/OrderToolbar.vue';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import CropButtonToolbar from '@/elements/medias/crop/components/buttons/CropButtonToolbar.vue';
import FilterButtonToolbar from '@/elements/medias/filter/components/buttons/FilterButtonToolbar.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import RemoveBgButtonToolbar from '@/elements/medias/images/image/components/buttons/RemoveBgButtonToolbar.vue';
import ReplaceAiImageButtonToolbar from '@/elements/medias/images/image/components/buttons/ReplaceAiImageButtonToolbar.vue';
import ReplaceImageButtonToolbar from '@/elements/medias/images/image/components/buttons/ReplaceImageButtonToolbar.vue';
import SeparateLayersButtonToolbar from '@/elements/medias/images/image/components/buttons/SeparateLayersButtonToolbar.vue';
import ToBackgroundToolbar from '@/elements/medias/images/image/components/buttons/ToBackgroundToolbar.vue';
import { useImage } from '@/elements/medias/images/image/composables/useImage';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import MaskButtonToolbar from '@/elements/medias/mask/components/buttons/MaskButtonToolbar.vue';
import { EditPanels } from '@/Types/types';

const store = useMainStore();

// Props
const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');
const { isBackground } = useIsBackground(element);

// Using composables
const { removingBackground, hasForeground } = useLayersImage(element);
const { isPlaceholder, isBase64, isAiImage } = useImage(element);

const { isPhotoMode } = useEditorMode();
const { isMobile } = useDeviceInfo();

const { width } = useElementSize(element.value.domNode());

const scale = computed(() => store.scale);
const { togglePanel } = usePanelManagement();

const toolbarClass = computed(() => {
	const size = width.value * scale.value;
	if (size < 250) {
		return 'really-small-image';
	}
	if (size < 400) {
		return 'small-image';
	}

	return 'normal-image';
});
const toolbarLocked = computed(() => removingBackground.value);

const onClickMoreTools = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'photo', link_text: 'more_tools' });

	Bugsnag.leaveBreadcrumb('Open edit image panel');
	togglePanel(EditPanels.Image);
};

const onClickRemove = () => {
	GAnalytics.trackDebounceEditBarGA4({ category: 'photo', link_text: 'delete' });
};
</script>

<template>
	<template v-if="!isPlaceholder">
		<div :class="toolbarClass" class="overflow-auto lg:overflow-visible">
			<template v-if="!element.locked || isBackground">
				<SeparateLayersButtonToolbar v-if="isMobile && !hasForeground" :element="element" />
				<RemoveBgButtonToolbar v-if="!isBase64" :element="element" />
				<ReplaceImageButtonToolbar :element="element" />
				<MaskButtonToolbar v-if="!element.locked" :element="element" />
				<FilterButtonToolbar :element="element" />
				<CropButtonToolbar :element="element" />
				<ReplaceAiImageButtonToolbar v-if="isAiImage" :element="element" />

				<template v-if="isMobile">
					<ToBackgroundToolbar :element="element" />
					<FlipMenuToolbar :element="element" />
					<OrderToolbar :element="element" />
					<CopyToolbar :element="element" />
					<RemoveToolbar :element="element" @remove="onClickRemove" />
				</template>

				<template v-else>
					<hr class="mx-1 hidden h-5 w-px shrink-0 border-0 bg-white/20 mockup:bg-fp-gray-250 lg:block" />
					<RemoveToolbar :element="element" @remove="onClickRemove" />
					<MoreToolsToolbar :element="element" :panel="EditPanels.Group" @more-tools="onClickMoreTools" />
				</template>

				<div
					v-if="toolbarLocked"
					class="absolute inset-0 z-30 cursor-wait rounded-tl-xl rounded-tr-xl bg-black bg-opacity-60 lg:rounded-full"
				></div>
			</template>
			<LockButtonToolbar v-if="!isPhotoMode && !isMobile && element.locked" :element="element" />
		</div>
	</template>

	<template v-else>
		<div>
			<template v-if="!element.locked">
				<ReplaceImageButtonToolbar :element="element" />
				<RemoveToolbar :element="element" @remove="onClickRemove" />
			</template>
			<LockButtonToolbar v-if="!isPhotoMode && !isMobile && element.locked" :element="element" />
		</div>
	</template>
</template>

<style scoped lang="scss">
@media (min-width: 1024px) {
	.normal-image :deep() *[tooltip]:before {
		display: none;
	}

	.small-image :deep() {
		[tooltip][data-button-importance='important']:before {
			display: none !important;
		}
		[data-button-importance='normal'] [data-button-label] {
			display: none !important;
		}
	}

	.really-small-image :deep() {
		[data-button-importance] [data-button-label] {
			display: none !important;
		}
	}
}
</style>
