<script lang="ts" setup>
import { createPopper } from '@popperjs/core';
import { OnClickOutside } from '@vueuse/components';
import { useUrlSearchParams } from '@vueuse/core';
import { nextTick, onMounted, ref, watch } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';
import { usePanelElements } from '@/layout/composables/usePanelElements';
import { PanelElementType, Panels } from '@/Types/types';

const store = useMainStore();
const { isMobile } = useDeviceInfo();
const { panelButtons, selectElement, setActivePanelsInStorage, openGoogleOrDropboxModal } = usePanelElements();
const emit = defineEmits(['setPanel']);
const { trans } = useI18n();

const msgAiWriter = ref();
const params = useUrlSearchParams<{ 'ai-writer'?: number }>();
const aiWriterMsgVisible = ref(false);
const { isLogged, requireAuth } = useAuth();

const initPopper = async () => {
	createPopper(document.querySelector('[data-button="AI Writer"]') as HTMLElement, msgAiWriter.value, {
		placement: 'right',
		modifiers: [
			{ name: 'offset', options: { offset: [-5, -5] } },

			{
				name: 'arrow',
				options: {
					padding: 5,
				},
			},
		],
	});
};

const getPanelClasses = (panel: Panels | undefined) => {
	if (panel === Panels.dropbox) {
		return 'bg-dropbox-500 text-white';
	}
	if (panel === Panels.googleDrive) {
		return 'bg-white text-gray-900';
	}
	if (panel && [Panels.googleDrive, Panels.googlePhotos].includes(panel)) {
		return 'bg-white text-gray-900';
	}

	if (
		(panel === Panels.textToImage && panel === store.activePanel) ||
		(panel === Panels.aiPresentation && panel === store.activePanel)
	) {
		return 'bg-gray-700';
	}
	if (panel === store.activePanel) {
		return 'bg-gray-700 text-white';
	}
	return 'text-gray-100 hover:text-white';
};

const getPanelTextClasses = (panel: Panels | undefined) => {
	let classes = panel === Panels.aiPresentation ? 'leading-none ' : 'leading-normal ';
	if (
		(panel === Panels.textToImage && panel === store.activePanel) ||
		(panel === Panels.aiWriter && panel === store.activePanel) ||
		(panel === Panels.aiPresentation && panel === store.activePanel)
	) {
		classes += 'bg-gradient-to-r from-[#7AB6FF] to-[#B4B4FF] bg-clip-text text-transparent';
	}

	return classes;
};

const getPanelIconClasses = (panel: Panels | undefined) => {
	if (panel && [Panels.dropbox, Panels.googleDrive, Panels.googlePhotos].includes(panel)) {
		return 'h-6 w-6';
	}
	if (panel === Panels.videos) {
		return 'h-5 w-5 scale-110 sm:h-6 w-6';
	}
	if (panel === Panels.textToImage || panel === Panels.aiPresentation) {
		return 'h-7 w-7';
	}
	return 'h-5 w-5';
};

const onClickButton = (panel: Panels | undefined) => {
	if (!panel) {
		console.error('No panel has been selected');
		return;
	}

	if (panel === Panels.textToImage) GAnalytics.track('click', 'AI', 'text-to-image_panel', null);

	GAnalytics.trackGA4('navigation-button', { link_text: panel });

	if (panel === Panels.uploads && !isLogged.value) {
		requireAuth();
		return;
	}

	if ([Panels.dropbox, Panels.googleDrive, Panels.googlePhotos].includes(panel)) {
		openGoogleOrDropboxModal(panel);
		return;
	}

	emit('setPanel', panel);
};

watch(
	() => store.inOnboarding,
	() => {
		if (!store.inOnboarding && !isMobile.value && params['ai-writer']) {
			showPopper();
		}
	}
);
const showPopper = async () => {
	aiWriterMsgVisible.value = true;
	await nextTick();
	await initPopper();
};

onMounted(async () => {
	if (params['ai-writer'] && !isMobile.value) {
		store.userExtensiblePanels.push(Panels.aiWriter);
		setActivePanelsInStorage();
		if (!store.inOnboarding) {
			await showPopper();
		}
		store.activePanel = Panels.templates;
	}
});
</script>

<template>
	<div
		v-for="(option, i) in panelButtons"
		:key="option.data.name"
		class="h-14 flex-1 lg:h-auto lg:flex-none"
		:class="{
			'hidden lg:block': option.type === PanelElementType.line || option.type === PanelElementType.link,
		}"
	>
		<button
			v-if="option.type === PanelElementType.button"
			:key="option.data?.name"
			:data-button="option.data.name"
			:data-onboarding="`btn-panel-${option.data?.panel}`"
			class="group relative mx-auto flex h-full w-full flex-col items-center justify-center rounded sm:px-6 lg:mx-0 lg:h-16 lg:w-16 lg:px-0"
			:class="getPanelClasses(option.data?.panel)"
			@click="onClickButton(option.data?.panel)"
		>
			<SvgIcon
				v-if="option.data.panel !== Panels.aiWriter"
				:name="option.data?.icon || 'not-allowed'"
				class="mb-1 fill-current"
				:class="getPanelIconClasses(option.data?.panel)"
			/>
			<svg
				v-if="option.data.panel === Panels.aiWriter"
				viewBox="0 0 23 22"
				fill="url(#grad1)"
				class="mb-1 h-6 w-6"
				xmlns="http://www.w3.org/2000/svg"
			>
				<defs>
					<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" style="stop-color: #7ab6ff; stop-opacity: 1" />
						<stop offset="50%" style="stop-color: #b4b4ff; stop-opacity: 1" />
						>
					</linearGradient>
				</defs>
				<path
					d="M19.82 4.53674L19.1052 5.83975C18.8695 6.26925 18.2601 6.25868 18.0761 5.82103L17.5072 4.49479L16.084 4.25607C15.6128 4.17902 15.4583 3.58945 15.8198 3.2587L16.9165 2.25577L16.7542 0.805169C16.6999 0.326892 17.2152 -0.0254924 17.6238 0.205908L18.8683 0.910886L20.191 0.25237C20.6264 0.0366336 21.0978 0.406983 20.9913 0.881014L20.6646 2.32201L21.6442 3.36432C21.9657 3.70649 21.7453 4.29058 21.2678 4.35103L19.82 4.53674ZM11.3838 3.17622L11.9481 4.17987C12.134 4.51081 11.8775 4.90728 11.5143 4.84908L10.4092 4.68016L9.66938 5.51845C9.42578 5.7968 8.97474 5.65703 8.90522 5.28385L8.69469 4.15185L7.67417 3.66463C7.33737 3.50451 7.31662 3.0213 7.6361 2.84735L8.60938 2.31746L8.71788 1.17788C8.75425 0.803228 9.19086 0.64482 9.459 0.908996L10.273 1.71389L11.3596 1.49642C11.7163 1.42505 12.0102 1.80915 11.8544 2.14798L11.3838 3.17622ZM14.3551 7.10097L14.9653 7.71113C15.4895 8.23534 15.7817 8.94003 15.7817 9.68339C15.7817 10.431 15.4938 11.1314 14.9653 11.66L5.87308 20.7521C5.87308 20.7521 3.99601 22.7846 1.63898 20.4274C-0.71805 18.0703 1.3141 16.1932 1.3141 16.1932L10.4063 7.10097C11.4934 6.01386 13.268 6.01386 14.3551 7.10097ZM3.14886 18.9174C3.87321 19.6417 4.66996 18.9174 4.66996 18.9174L10.8618 12.7256L9.34066 11.2045L3.14886 17.3963C3.14886 17.3963 2.42451 18.193 3.14886 18.9174ZM12.3829 11.2002L13.4442 10.1389C13.5645 10.0185 13.6289 9.85097 13.6289 9.67909C13.6289 9.50722 13.5602 9.34394 13.4399 9.22363L12.834 8.61777C12.5848 8.36855 12.1723 8.36855 11.9231 8.61777L10.8618 9.67909L12.3829 11.2002ZM17.0475 13.1394L18.1254 13.5444L18.4269 14.6343C18.5257 14.9939 18.9978 15.0987 19.2249 14.8145L19.9167 13.9487L21.0577 14.0409C21.4329 14.0706 21.6651 13.6683 21.4519 13.3582L20.8015 12.4161L21.2054 11.3842C21.338 11.0454 21.0111 10.689 20.6503 10.7832L19.5557 11.067L18.666 10.3361C18.3726 10.0952 17.9374 10.2785 17.9301 10.6483L17.9034 11.766L16.9488 12.348C16.6334 12.5372 16.6922 13.0057 17.0475 13.1394Z"
				/>
			</svg>
			<span class="text-2xs" :class="getPanelTextClasses(option.data?.panel)">{{ option.data?.name }}</span>
			<OnClickOutside v-if="option.data.removable" class="absolute -right-1 -top-1" @click.stop>
				<button
					class="relative flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-800 bg-gray-600 text-xs font-bold text-gray-100 opacity-0 group-hover:opacity-100 hover:text-white"
					@click.stop="selectElement(option.data?.panel)"
				>
					<SvgIcon name="cross" class="h-2.5 w-2.5" />
				</button>
			</OnClickOutside>
		</button>

		<div
			v-if="option.type === PanelElementType.line"
			:key="`line-${i}`"
			class="border-gray-700"
			:class="{
				'mx-2 h-8 border-l': isMobile,
				'my-2 w-16 border-t': !isMobile,
			}"
		/>

		<a
			v-if="option.type === PanelElementType.link"
			:key="option.data?.name"
			:href="option.data?.url"
			target="_blank"
			class="flex h-14 flex-col items-center justify-center rounded bg-blue-500 px-6 text-white focus:outline-none slidesgo:bg-purple-400 lg:h-16 lg:w-16 lg:px-0"
		>
			<SvgIcon name="nova" class="mb-2 h-6 w-6 fill-current lg:h-5 lg:w-5" />
			<span class="truncate text-xs">{{ option.data?.subtitle }}</span>
		</a>
	</div>
	<Teleport to="#aiWriterTarget">
		<div
			v-if="aiWriterMsgVisible"
			ref="msgAiWriter"
			class="popover-aiwriter pointer-events-none relative w-[300px] rounded bg-blue-500 p-4 text-white"
		>
			<h4 class="mb-2 font-bold">{{ trans('Your generated text is stored here') }}</h4>
			<p class="text-sm leading-normal">
				{{ trans('Use it whenever you need it, and apply your assets to any template') }}
			</p>
			<div class="triangle absolute inset-0 -left-3 top-9"></div>
			<SvgIcon name="caret" class="absolute -left-3 top-1/2 -mt-2.5 h-5 w-5 rotate-90 text-blue-500"></SvgIcon>
		</div>
	</Teleport>
</template>
<style lang="sass" scoped>
.popover-aiwriter
	animation: opacity-popover 5s forwards

	@keyframes opacity-popover
		90%
			@apply opacity-100

		100%
			@apply opacity-0
</style>
