<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import ShareEmbed from '@/export/share/components/ShareEmbed.vue';
import SharePublishNow from '@/export/share/components/SharePublishNow.vue';
import SharePublishSocial from '@/export/share/components/SharePublishSocial.vue';
import ShareSendLink from '@/export/share/components/ShareSendLink.vue';
import ShareSocialSchedule from '@/export/share/components/ShareSocialSchedule.vue';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { ShareModalOption, ShareModalType } from '@/Types/types';

const { trans } = useI18n();

const emits = defineEmits<{ (e: 'close'): void; (e: 'goSocialMediaModal', socialMedia: ShareModalType): void }>();

const store = useMainStore();
const project = useProjectStore();
const history = useHistoryStore();
const { step, socialMediaModal } = useSharePublish();

const shareModal = ref<ShareModalOption | null>(null);
const modal = ref();

const isViewer = computed(() => location.href.includes('/share/'));

onClickOutside(modal, () => emits('close'));

onMounted(() => {
	if (!store.userVector && !isViewer.value && !project.pendingSync) {
		project.triggerSync?.();
	}

	if (history.lastChangeFromNavigation && !isViewer.value) {
		project.performSyncWithStateInUse?.();
	}

	if (socialMediaModal.value) {
		shareModal.value = step.value === 2 ? ShareModalOption.schedule : ShareModalOption.publishnow;
	}
});

const goToSendLink = () => {
	shareModal.value = ShareModalOption.send;
	GAnalytics.track('click', 'Share', 'sendlink-button', null);
};
const goToEmbed = () => {
	shareModal.value = ShareModalOption.embed;
	GAnalytics.track('click', 'Share', 'embed-button', null);
};
const goToSocialSchedule = () => {
	shareModal.value = ShareModalOption.schedule;
	GAnalytics.track('click', 'Share', 'socialSchedule-button', null);
	GAnalytics.trackGA4('social_media_schedule_button');
};
const backToShareModal = () => {
	shareModal.value = null;
	socialMediaModal.value = null;
};
const openSocialMediaModal = (socialMedia: ShareModalType, fromSchedule?: boolean) => {
	shareModal.value = fromSchedule ? ShareModalOption.schedule : ShareModalOption.publishnow;
	emits('goSocialMediaModal', socialMedia);
};
const goToPublishNow = () => {
	shareModal.value = ShareModalOption.publishnow;
};

const goToSchedule = () => {
	shareModal.value = ShareModalOption.schedule;
};
</script>

<template>
	<template v-if="(store.userVector && project.allChangesSaved) || isViewer">
		<div
			ref="modal"
			class="fixed right-2 flex w-screen max-w-[95%] flex-col items-center justify-center rounded-md bg-white px-6 pt-4 pb-6 shadow-xl lg:max-w-[26rem]"
			:class="isViewer ? 'bottom-16 right-[2.5%]  lg:right-12' : 'top-16 right-2 '"
		>
			<div v-if="shareModal === ShareModalOption.send" class="w-full">
				<ShareSendLink @goback="backToShareModal" />
			</div>
			<div v-else-if="shareModal === ShareModalOption.embed" class="w-full">
				<ShareEmbed @goback="backToShareModal" />
			</div>
			<div v-else-if="shareModal === ShareModalOption.schedule" class="w-full">
				<ShareSocialSchedule
					@share="(socialMedia) => openSocialMediaModal(socialMedia, true)"
					@goback="backToShareModal"
					@goToPublishNow="goToPublishNow"
					@close="emits('close')"
				/>
			</div>
			<div v-else-if="shareModal === ShareModalOption.publishnow && socialMediaModal" class="w-full">
				<SharePublishNow @share="openSocialMediaModal" @goback="backToShareModal" @goToSchedule="goToSchedule" />
			</div>

			<div v-else class="flex w-full flex-col gap-4">
				<div>
					<!-- Share Publish Social -->
					<SharePublishSocial @share="openSocialMediaModal" />
				</div>
				<div class="flex flex-col gap-2">
					<!-- Social Media Schedule -->
					<button
						class="flex h-11 w-full items-center justify-between rounded bg-gray-100/20 px-3 text-gray-700 hover:bg-gray-100/40"
						@click="goToSocialSchedule"
					>
						<div class="flex items-center font-semibold">
							<SvgIcon name="calendar" class="mr-3 h-4 w-4" />
							{{ trans('Social Media Schedule') }}
							<span
								class="ml-2 flex h-6 items-center justify-center rounded bg-blue-500 px-2 text-xs font-bold text-white slidesgo:bg-purple-400"
							>
								NEW
							</span>
						</div>
						<SvgIcon name="arrow" class="h-5 w-5 rotate-[270deg]" />
					</button>
					<!-- Send Link -->
					<button
						class="flex h-11 w-full items-center justify-between rounded bg-gray-100/20 px-3 text-gray-700 hover:bg-gray-100/30"
						@click="goToSendLink"
					>
						<div class="flex items-center font-semibold">
							<SvgIcon name="send" class="mr-3 h-4 w-4" />
							{{ trans('Send Link') }}
						</div>
						<SvgIcon name="arrow" class="h-5 w-5 rotate-[270deg]" />
					</button>
					<!-- Embed -->
					<button
						class="flex h-11 w-full items-center justify-between rounded bg-gray-100/20 px-3 text-gray-700 hover:bg-gray-100/30"
						@click="goToEmbed"
					>
						<div class="flex items-center font-semibold">
							<SvgIcon name="code" class="mr-3 h-4 w-4 scale-[1.15]" />
							{{ trans('Embed') }}
						</div>
						<SvgIcon name="arrow" class="h-5 w-5 rotate-[270deg]" />
					</button>
				</div>
			</div>
		</div>
	</template>
	<template v-else>
		<div
			class="fixed top-16 right-2 flex w-auto items-center justify-center rounded bg-white px-4 py-3 text-gray-700 shadow-xl"
		>
			<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin text-blue-500" />
			<span class="text-sm font-semibold">{{ trans('Saving your changes...') }}</span>
		</div>
	</template>
</template>
<style lang="sass" scoped>
.copied-animation
	animation: copied 1s forwards

	@keyframes copied
		0%
			opacity: 0

		90%
			opacity: 1
			left: 2.5rem

		100%
			opacity: 0
			left: 2.5rem
</style>
