import { computed, nextTick, Ref } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { useCircleTypeInfo } from '@/elements/texts/curved/composables/useCircleTypeInfo';
import CurvedTextTools, { Box } from '@/elements/texts/curved/utils/CurvedTextTools';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import MathTools from '@/utils/classes/MathTools';

export const useCircleType = (text: Ref<Text>, elem: Ref<HTMLDivElement>, scale: Ref<number>, previewName?: string) => {
	const { moveable } = useInteractions();
	const { textEditing } = useTextEditing();
	const { isIOS } = useDeviceInfo();
	const shouldFixStylesInIos = computed(() => {
		return isIOS.value && text.value.outline && text.value.outline.width && text.value.textShadow.length;
	});
	const { selection } = useSelection();
	const { minRadius, direction, getArcToApply, getBoxInfo, percentageArc } = useCircleTypeInfo(
		text,
		scale,
		previewName
	);
	let radius = Math.abs(text.value.curvedProperties.arc || 0);
	let fontSizeNumber = 0;
	let lineHeightNumber = 0;
	let metrics: Box[] = [];
	let letters: HTMLSpanElement[] = [];
	let lettersStroke: HTMLSpanElement[] = [];
	let lettersTextShadow: HTMLSpanElement[] = [];

	let container = document.createElement('div');
	let strokeContainer = document.createElement('div');
	let originalPositionY: number | null = null;

	const generate = () => {
		// Preparamos los container de las letras
		container = document.createElement('div');
		const fragment = document.createDocumentFragment();
		container.setAttribute('aria-label', elem.value.innerText);
		container.style.position = 'relative';
		if (shouldFixStylesInIos.value) {
			container.style.textShadow = 'none';
		}
		container.style.zIndex = '1';

		// Separamos en spans por letra el texto completo
		letters = CurvedTextTools.splitNode(elem.value);
		letters.forEach((letter) => fragment.appendChild(letter));

		container.appendChild(fragment);

		// Creamos una copia para el efecto stroke
		strokeContainer = container.cloneNode(true) as HTMLDivElement;
		strokeContainer.style.webkitTextStroke = '0px';
		strokeContainer.style.textShadow = 'none';
		lettersStroke = Array.from(strokeContainer.children) as HTMLSpanElement[];
		let textShadowContainer = document.createElement('div');

		if (shouldFixStylesInIos.value) {
			textShadowContainer = container.cloneNode(true) as HTMLDivElement;
			textShadowContainer.style.webkitTextStroke = '0px';
			textShadowContainer.style.textShadow = TextTools.textShadowToCssString(text.value.textShadow);
			textShadowContainer.style.zIndex = '0';
			lettersTextShadow = Array.from(textShadowContainer.children) as HTMLSpanElement[];
		}
		//  creamos también una copia para el textShadow

		// Los añadimos a la copia del texto
		elem.value.innerHTML = '';
		elem.value.appendChild(container);
		elem.value.appendChild(strokeContainer);
		if (shouldFixStylesInIos.value) {
			elem.value.appendChild(textShadowContainer);
		}

		const { fontSize, lineHeight } = window.getComputedStyle(elem.value);

		fontSizeNumber = parseFloat(fontSize);
		lineHeightNumber = parseFloat(lineHeight) || fontSizeNumber;

		// Cuando estamos en el 0% necesitamos guardar la posición Y que tiene el elemento para colocar correctamente la caja
		// en caso de que el arc sea negativo
		if (percentageArc.value === 0 || originalPositionY === null) {
			originalPositionY = text.value.position.y;
		}

		// Como el texto puede estar rotado y nos daría unas medidas/posiciones incorrectas le anulamos temporalmente la rotación
		// recogemos el rect y le restauramos el transform
		const transform = elem.value.style.transform;
		elem.value.style.transform = `${transform} rotate(${-text.value.rotation}deg)`;

		const scaleContext = scale.value * text.value.scale;

		metrics = letters.map((letter) => CurvedTextTools.getRect(letter, scaleContext));
		elem.value.style.transform = transform;

		// Para sacar el arco minímo (hacer un círculo completo) necesitamos saber cual es el width total del texto que no es mas
		// que la suma de todos los width de las letras
		const totalWidth = metrics.reduce((sum, { width }) => sum + width, 0);
		const minArc = totalWidth / Math.PI / 2 + lineHeightNumber;

		if (Math.trunc(minArc) !== Math.trunc(text.value.curvedProperties.minArc)) {
			text.value.curvedProperties.minArc = minArc;
		}

		// Cuando el radius es 0 es un texto curvo nuevo, por lo que sobreescribimos y le aplicamos un arc de un 40% ya que
		// nunca va a tener un radius 0
		if (radius === 0) {
			// Si es una preview no vamos a pisarle el arc ya que el dato que nos llega es la fuente de verdad (debe tener el mismo que original)
			if (!previewName) text.value.curvedProperties.arc = getArcToApply(40);
			radius = text.value.curvedProperties.arc || 0;
		}

		const isNewCurvedText = text.value.curvedProperties.arc === 1.0;

		// Si el texto no tiene arc o es un nuevo texto curvo, le aplicamos un arc de un 40%
		if (!text.value.curvedProperties.arc || isNewCurvedText) {
			text.value.curvedProperties.arc = getArcToApply(40);

			radius = text.value.curvedProperties.arc;
			return;
		}

		// Si el arc es menor que el minArc el texto curvo no se va ver correctamente, por lo que aplicamos la curvatura mínima
		if (
			text.value.curvedProperties.arc &&
			Math.trunc(text.value.curvedProperties.minArc) > Math.abs(Math.trunc(text.value.curvedProperties.arc))
		) {
			text.value.curvedProperties.arc =
				text.value.curvedProperties.minArc * (text.value.curvedProperties.arc < 0 ? -1 : 1);
			radius = text.value.curvedProperties.minArc;
			return;
		}
	};

	/**
	 * Devuelve el nodo del texto original en el dom en su estado actual
	 */
	const getFreshNode = (): HTMLDivElement | undefined => {
		let node = (
			previewName ? text.value.domPreviewNode(previewName) : text.value.domNode()
		)?.querySelector<HTMLDivElement>('.text-element-final');
		if (textEditing.value && !previewName) {
			node = document.querySelector<HTMLDivElement>(`#editable-${text.value ? text.value.id : undefined}`);
		}

		if (!node) {
			return;
		}

		return node;
	};

	const render = (fromInit = true) => {
		if (!letters.length) {
			return;
		}
		if (!originalPositionY) {
			originalPositionY = text.value.position.y;
		}

		// Si la posición que tenemos guardada como original del texto - el transformCurve que aplicamos no coincide con
		// la posición del texto, el texto ha sido movido de posición por lo que le recalcumamos la posición original del texto
		// entendemos como posición original como la que tendría el texto sin ser curvo
		if (
			!text.value.curvedProperties.transformCurve ||
			Math.round(originalPositionY - text.value.curvedProperties.transformCurve) !== Math.round(text.value.position.y)
		) {
			originalPositionY = text.value.position.y + text.value.curvedProperties.transformCurve;
		}

		// Calculamos el origin que debemos aplicar los span que contienen las letras
		const originY = direction.value === -1 ? -radius + lineHeightNumber : radius;
		const origin = `center ${originY / fontSizeNumber}em`;

		const innerRadius = radius - lineHeightNumber;
		// Calculamos sus rotaciones a traves del radio
		const { rotations, total } = CurvedTextTools.getLetterRotation(metrics, innerRadius);

		const halfOf = (number: number) => number * 0.5; // Devuelve la mitad de un número

		const setStylesToLetter = (letter: HTMLSpanElement, index: number) => {
			const { style } = letter;
			const textHasOutline = text.value.outline && text.value.outline.width;
			const rotate = (-halfOf(total) + rotations[index]) * direction.value;
			const translateX = -halfOf(metrics[index].width) / fontSizeNumber;
			const transform = `translateX(${translateX}em) rotate(${rotate}deg)`;
			style.position = 'absolute';
			style.bottom = 'auto';
			style.left = '50%';
			style.transform = transform;
			style.transformOrigin = origin;
			style.webkitTransform = transform;
			style.webkitTransformOrigin = origin;
			if (textHasOutline) {
				style.color = text.value.color.toCssString();
			}
		};

		// Por cada letra calculamos su rotación y translate
		letters.forEach((letter: HTMLSpanElement, index: number) => setStylesToLetter(letter, index));
		lettersStroke.forEach((letter: HTMLSpanElement, index: number) => setStylesToLetter(letter, index));
		lettersTextShadow.forEach((letter: HTMLSpanElement, index: number) => setStylesToLetter(letter, index));

		const height =
			total > 180 ? MathTools.sagitta(radius, total) : MathTools.sagitta(innerRadius, total) + lineHeightNumber;

		// Seteamos altura al container de los spans
		container.style.height = `${height / scale.value / fontSizeNumber}em`;
		container.style.transform = '';
		// Aplicamos transform al container de los strokes para que visualmente esté en el mismo sitio que el container de los spans
		strokeContainer.style.marginTop = `-${height / scale.value / fontSizeNumber}em`;

		// Calculamos el tamaño y posición Y que debe tener la caja
		const { width: widthBox, height: heightBox, y: yBox } = getBoxInfo();

		if (Math.round(heightBox) !== Math.round(text.value.size.height)) {
			text.value.size.height = heightBox;
		}

		// Si el arc es negativo el texto se va a desplazar hacía arriba, pero no su caja, por lo que tenemos que aplicar
		// un translate a dicha caja para que el rect de selección esté visualmente conteniendo el texto
		if (text.value.curvedProperties.arc! < 0) {
			const yToApply = Math.abs(yBox);
			// Si se está haciendo desde el init no queremos corregir la posición ya que está ya estará corregida en los datos
			if (!fromInit) {
				text.value.position.y = originalPositionY - yToApply;
				text.value.curvedProperties.transformCurve = yToApply;
			}

			// Margen para controlar el desfase en px del texto respecto a la caja
			const marginPositionY = 10;
			// Si inicialmente, el texto se ha movido más de 10px de su posición original, lo corregimos
			if (
				fromInit &&
				(text.value.position.y > originalPositionY - yToApply + marginPositionY ||
					text.value.position.y < originalPositionY - yToApply - marginPositionY)
			) {
				text.value.position.y = originalPositionY - yToApply;
				text.value.curvedProperties.transformCurve = yToApply;
			}

			// Tanto el container como el strokeContainer están siendo afectados por la escala de los textos, por lo tanto a la y
			// tenemos que quitarle esta escala para estos elementos
			container.style.transform = `translateY(${text.value.curvedProperties.transformCurve / text.value.scale}px)`;
			strokeContainer.style.marginTop = `calc(${text.value.curvedProperties.transformCurve / text.value.scale}px - ${
				container.style.height
			})`;
		}

		const diff = widthBox - text.value.size.width;
		const xToApply = diff / 2;

		// Al cambiar el width de la caja va a hacer que se produzca un movimiento de la posición de la caja
		// corregimos dicho movimiento para que se mantenga visualmente en el sitio
		if (diff !== 0 && Math.round(widthBox) !== Math.round(text.value.size.width)) {
			text.value.size.width = widthBox;
			text.value.position.x -= xToApply;
		}

		// Un texto curvo nunca va a poder ser deformable por lo que dejamos solos los handlers de las esquinas
		if (moveable.value && !previewName) {
			moveable.value!.renderDirections = ['nw', 'ne', 'sw', 'se'];
		}

		// En cambios relacionados con fuentes, parece que a veces se da algunas condiciones por el que se genera mas de un texto curvo
		// nos vamos a quedar solo con el primero ya que el resto se están generando erroneamente
		const nodes =
			(previewName ? text.value.domPreviewNode(previewName) : text.value.domNode())?.querySelectorAll(
				'#clone-curved'
			) || [];
		if (nodes.length > 1) {
			const arrayNodes = Array.from(nodes);
			const [first, ...toRemove] = arrayNodes;
			toRemove.forEach((el) => el.remove());
		}
	};

	const setRadius = (value: number) => {
		radius = Math.max(minRadius.value, value);
		render(false);
	};

	const refresh = (fromInit = true) => {
		const freshNode = getFreshNode();
		if (!freshNode) {
			elem.value.innerHTML = '';
			elem.value.setAttribute('style', '');
			return;
		}

		elem.value.innerHTML = freshNode.innerHTML || '';
		// nos qudamos con el shadow porque el style del textEditing nunca tiene el shadow aplicado
		const shadow = elem.value.style.textShadow;
		elem.value.setAttribute('style', freshNode.getAttribute('style')!);
		if (textEditing.value) elem.value.style.textShadow = shadow;
		elem.value.style.display = 'block';

		init(fromInit);
		// Si estás editando un texto tenemos que aplicar una opacidad del 50% al texto curvo, hay que esperar un tick
		// para saber si está editando el texto o no
		nextTick().then(() => {
			if (textEditing.value) {
				elem.value.style.opacity = '50%';
			} else {
				elem.value.style.opacity = '100%';
			}
		});
	};

	const init = (fromInit = true) => {
		generate();
		render(fromInit);
	};

	const destroy = () => {
		text.value.curvedProperties.transformCurve = 0;
		text.value.curvedProperties.minArc = 0;
		text.value.curvedProperties.arc = null;
		originalPositionY = null;
		radius = 0;

		unmount();
		// Si es un grupo no volvemos a aplicar los middle handlers
		if (moveable.value && selection.value.length === 1) {
			moveable.value!.renderDirections = ['nw', 'ne', 'w', 'e', 'sw', 'se'];
		}
	};

	const unmount = () => {
		container.remove();
		strokeContainer.remove();
	};

	return {
		init,
		refresh,
		destroy,
		setRadius,
		unmount,
	};
};
