import { useFetch, UseFetchOptions, UseFetchReturn } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';
import { computed, ComputedRef, Ref, ref, watch } from 'vue';

import { getConcatenatedBasicShapes, getSplittedBasicShapes } from '@/api/client/BasicShapeApiClient';
import { getConcatenatedFlaticon, getSplittedFlaticon } from '@/api/client/FlaticonApiClient';
import { getConcatenatedMasks, getSplittedMasks } from '@/api/client/ImageMaskApiClient';
import { getConcatenatedStickersCategories, getStickersElements } from '@/api/client/StickersApiClient';
import { getConcatenatedStorysets, getSplittedStorysets } from '@/api/client/StorysetApiClient';
import { concatenatedTemplatesResponseMapper, imageResponseMapper } from '@/api/ClientApiMapper';
import { useChangeUrlEndpoint } from '@/api/composables/useChangeUrlEndpoint';
import { useEditorApiFetch } from '@/api/composables/useEditorApiFetch';
import { useEmptyDataOnUrlParamChange } from '@/api/composables/useEmptyDataOnUrlParamChange';
import { useSessionInfo } from '@/common/composables/useSessionInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import Page from '@/page/classes/Page';
import {
	AiPresentationBody,
	ArtboardApi,
	BasicShapeApi,
	FlaticonElementApi,
	ImageApi,
	MappedApiResponse,
	MaskApi,
	PredefinedTextElementsApi,
	ProjectDataApi,
	SchedulePublicationBody,
	StorysetApi,
	TemplateApi,
	TemplateApiData,
} from '@/Types/apiClient';
import { AiImageBody, Font, TrackedJobResponse, UseApiFetchOptions } from '@/Types/types';

// Methods
export const getArtboards = async (): Promise<ArtboardApi[]> => {
	if (window.preloadArtboards) {
		return window.preloadArtboards;
	}
	const { data } = await useEditorApiFetch('artboards').json();

	return data.value;
};

export const getFlaticon = (
	url: Ref<string>,
	options: UseApiFetchOptions = {}
): UseFetchReturn<MappedApiResponse<FlaticonElementApi[]>> => {
	if (!('concat' in options)) options.concat = true;

	if (options.concat) {
		return getConcatenatedFlaticon(url, options);
	}

	return getSplittedFlaticon(url, options);
};

export const getFonts = (options: UseFetchOptions = {}): UseFetchReturn<Font[]> => {
	return useEditorApiFetch('fonts', options).json();
};

export const getFontsBySlug = (fontSlugs: string[], options: UseFetchOptions = {}): UseFetchReturn<Font[]> => {
	return useEditorApiFetch(`fonts/${fontSlugs.join(',')}`, options).json();
};

export const getUserFonts = (options: UseFetchOptions = {}): UseFetchReturn<Font[]> => {
	return useEditorApiFetch('user/fonts', options).json();
};

export const getImages = (
	url: Ref<string>,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<ImageApi[]>> => {
	const concatenatedData = ref<ImageApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => imageResponseMapper(ctx, concatenatedData, url),
	})
		.get()
		.json();
};

export const getProject = (slug: string, options: UseFetchOptions = {}): UseFetchReturn<ProjectDataApi> => {
	const uuid = uuidv4();
	return useEditorApiFetch(`vectors/${slug}?uuid=${uuid}`, {}).json();
};

export const getStorysets = (
	url: Ref<string>,
	options: UseApiFetchOptions = {}
): UseFetchReturn<MappedApiResponse<StorysetApi[]>> => {
	if (!('concat' in options)) options.concat = true;

	if (options.concat) {
		return getConcatenatedStorysets(url, options);
	}

	return getSplittedStorysets(url, options);
};

export const getSvg = (url: string, options: UseFetchOptions = {}): UseFetchReturn<string> => {
	return useFetch(url, options);
};

export const getTemplates = (url: Ref<string>, options: UseFetchOptions = {}): UseFetchReturn<TemplateApi> => {
	const concatenatedData = ref<TemplateApiData[]>([]);

	useChangeUrlEndpoint(url, 'category', concatenatedData);
	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => concatenatedTemplatesResponseMapper(ctx, concatenatedData),
	}).json();
};

export const getTemplateCategories = (options: UseFetchOptions = {}) => {
	const { isEmbeddedContext, embeddedContextData } = useEditorMode();
	let url = 'categories';

	if (isEmbeddedContext.value && embeddedContextData.value.aspectRatio) {
		url += `?aspect_ratio=${embeddedContextData.value.aspectRatio}`;
	}

	return useEditorApiFetch(url, { ...options })
		.get()
		.json();
};
export const getBasicShapes = (
	url: Ref<string>,
	options: UseApiFetchOptions = {}
): UseFetchReturn<MappedApiResponse<BasicShapeApi[]>> => {
	if (!('concat' in options)) options.concat = true;

	if (options.concat) {
		return getConcatenatedBasicShapes(url, options);
	}

	return getSplittedBasicShapes(url, options);
};

export const getMasks = (
	url: Ref<string>,
	options: UseApiFetchOptions = {}
): UseFetchReturn<MappedApiResponse<MaskApi[]>> => {
	if (!('concat' in options)) options.concat = true;

	if (options.concat) {
		return getConcatenatedMasks(url, options);
	}

	return getSplittedMasks(url, options);
};

export const getPredefinedTexts = (
	url: Ref<string>,
	options: UseApiFetchOptions = {}
): UseFetchReturn<PredefinedTextElementsApi> => {
	return useEditorApiFetch(url, {
		...options,
	})
		.get()
		.json();
};

export const getStickers = (url: Ref<string>, options: UseApiFetchOptions = {}) => {
	if (!('concat' in options)) options.concat = true;

	if (options.get === 'categories') {
		return getConcatenatedStickersCategories(url, options);
	}

	return getStickersElements(url, options);
};

export const getAutocompletedWords = (url: Ref<string> | string, options: UseFetchOptions = {}) => {
	return useEditorApiFetch(url, {
		...options,
	})
		.get()
		.json();
};

export const createAiImage = (body: AiImageBody, options: UseFetchOptions = {}): UseFetchReturn<TrackedJobResponse> => {
	return useEditorApiFetch('image/ai', {
		...options,
	})
		.post(body)
		.json();
};

export const getAiImageFamily = (
	uuid: string,
	options: UseFetchOptions = {}
): UseFetchReturn<MappedApiResponse<ImageApi>> => {
	const url = ref(`ai/${uuid}/family`);

	const concatenatedData = ref<ImageApi[]>([]);

	useEmptyDataOnUrlParamChange(url, concatenatedData);

	return useEditorApiFetch(url, {
		...options,
		afterFetch: (ctx) => imageResponseMapper(ctx, concatenatedData, url),
	})
		.get()
		.json();
};

export const getElementsByType = (
	pages: Ref<Record<string, number>>,
	fetchOptions: UseApiFetchOptions,
	query: ComputedRef<string> | Ref<string>,
	searchAllElements?: boolean,
	withFallbackWhenNotFoundElements?: boolean
) => {
	const URL_CONFIG = {
		LIMIT: 50,
		ORDER: 'recent',
	};

	const customQuery = computed(() => {
		if (!searchAllElements) return '';
		return query.value;
	});

	// Necessary for update flaticon, stickers and storyset urls
	watch(customQuery, () => {
		flaticonQuery.value = customQuery.value;
		stickersQuery.value = customQuery.value;
		storysetQuery.value = customQuery.value;
	});

	const basicShapesUrl = computed(
		() => `elements/category/1740?limit=${URL_CONFIG.LIMIT}&page=${pages.value.basicShapes}&query=${customQuery.value}`
	);

	const flaticonQuery = ref(query.value);
	const flaticonUrl = computed(
		() => `flaticon/search?q=${flaticonQuery.value}&limit=${URL_CONFIG.LIMIT}&page=${pages.value.flaticon}`
	);

	const masksUrl = computed(
		() => `elements/category/1741?limit=${URL_CONFIG.LIMIT}&page=${pages.value.masks}&query=${customQuery.value}`
	);

	const stickersQuery = ref(query.value);
	const stickersUrl = computed(
		() =>
			`flaticon/search?limit=${URL_CONFIG.LIMIT}&page=${pages.value.stickers}&q=${stickersQuery.value}&iconType=sticker&tagsId=`
	);

	const storysetQuery = ref(query.value);
	const storysetsUrl = computed(
		() =>
			`https://stories.freepiklabs.com/api/vectors?order=${URL_CONFIG.ORDER}&limit=30&page=${pages.value.storysets}&query=${storysetQuery.value}&style=`
	);
	const linesAndArrowsUrl = computed(
		() => `elements/category/2328?limit=${URL_CONFIG.LIMIT}&page=${pages.value.lines}&query=${customQuery.value}`
	);

	const { data: basicShapes, isFetching: isFetchingBasicShapes } = getBasicShapes(basicShapesUrl, {
		...fetchOptions,
	});
	const { data: flaticons, isFetching: isFetchingFlaticons } = getFlaticon(flaticonUrl, {
		...fetchOptions,
	});
	const { data: masks, isFetching: isFetchingMasks } = getMasks(masksUrl, {
		...fetchOptions,
	});
	const { data: stickers, isFetching: isFetchingStickers } = getStickers(stickersUrl, {
		...fetchOptions,
		get: 'elements',
	});
	const { data: storysets, isFetching: isFetchingStorysets } = getStorysets(storysetsUrl, {
		...fetchOptions,
	});
	const { data: lines, isFetching: isFetchingLines } = getMasks(linesAndArrowsUrl, { ...fetchOptions });

	const isFetching = computed(
		() =>
			isFetchingBasicShapes.value ||
			isFetchingFlaticons.value ||
			isFetchingMasks.value ||
			isFetchingStickers.value ||
			isFetchingStorysets.value ||
			isFetchingLines.value
	);

	watch(isFetchingFlaticons, (newVal, oldVal) => {
		if (newVal && !oldVal) return;

		if (withFallbackWhenNotFoundElements && !newVal && oldVal && !flaticons.value?.data.length) {
			flaticonQuery.value = '';
		}
	});

	watch(isFetchingStickers, (newVal, oldVal) => {
		if (newVal && !oldVal) return;

		if (withFallbackWhenNotFoundElements && !newVal && oldVal && !stickers.value?.data.length) {
			stickersQuery.value = '';
		}
	});

	watch(isFetchingStorysets, (newVal, oldVal) => {
		if (newVal && !oldVal) return;

		if (withFallbackWhenNotFoundElements && !newVal && oldVal && !storysets.value?.data.length) {
			storysetQuery.value = '';
		}
	});

	return {
		isFetching,
		isFetchingBasicShapes,
		isFetchingFlaticons,
		isFetchingMasks,
		isFetchingStickers,
		isFetchingStorysets,
		isFetchingLines,
		basicShapes,
		flaticons,
		masks,
		stickers,
		storysets,
		lines,
	};
};

export const createNewSchedulePublication = (body: SchedulePublicationBody) => {
	const url = ref('social-media-schedule/create');

	return useEditorApiFetch(url).post(body).json();
};

export const checkApiKey = (apiKey: string) => {
	const url = ref('check-api-key');

	return useEditorApiFetch(url).post({ api_key: apiKey }).json();
};

export const checkCategory = (slug: string) => {
	const url = ref('check-category');

	return useEditorApiFetch(url).post({ slug }).json();
};

export const getAiPresentationStyles = () => {
	const url = ref('ai-presentations');

	return useEditorApiFetch(url).json();
};

export const postAiPresentation = (body: AiPresentationBody) => {
	const url = ref('generate-presentation');

	return useEditorApiFetch(url).post(body).json();
};

export const uploadUserFont = (formData: FormData) => {
	const url = ref(`user/fonts`);

	return useEditorApiFetch(url).post(formData).formData().json();
};

export const deleteUserFont = (slug: string) => {
	const url = ref(`user/fonts/${slug}`);

	return useEditorApiFetch(url).delete().json();
};

export const calculatePrice = (images: number, aspectRatio: string): UseFetchReturn<any> => {
	const url = computed(() => `credits/calculate?aspect_ratio=${aspectRatio}&images=${images}`);
	return useEditorApiFetch(url).json();
};
export const getPrices = () => {
	return useEditorApiFetch('credits/price').json();
};

export const getPageFromMedia = (url: string, options: UseFetchOptions = {}): UseFetchReturn<Partial<Page>> => {
	return useEditorApiFetch(url, { ...options })
		.get()
		.json();
};
