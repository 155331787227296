<script lang="ts" setup>
import { ref } from 'vue';

import { useDebugPanel } from '@/common/composables/useDebugPanel';

const scroll = ref();
const isVisible = ref(true);
const bgOpacity = ref<50 | 75 | 95>(50);
const isInteractive = ref(false);

const { logs, toggleElementDebug } = useDebugPanel();

const onClear = () => {
	logs.value = [];
};

const onToggleInteractive = () => {
	isInteractive.value = !isInteractive.value;
};

const onToggleVisibility = () => {
	isVisible.value = !isVisible.value;
};

const onToggleOpacity = () => {
	if (bgOpacity.value === 50) {
		bgOpacity.value = 75;
		return;
	}
	if (bgOpacity.value === 75) {
		bgOpacity.value = 95;
		return;
	}
	if (bgOpacity.value === 95) {
		bgOpacity.value = 50;
		return;
	}
};

const onToggleElementDebug = () => {
	toggleElementDebug();
};
</script>

<template>
	<div
		v-if="isVisible"
		id="debug-panel"
		class="absolute top-2 left-1/2 z-50 h-[60vh] w-[95vw] -translate-x-1/2 overflow-hidden overflow-y-scroll rounded px-2 pt-8 pb-2 font-mono scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
		:class="{
			'bg-black/50': bgOpacity === 50,
			'bg-black/75': bgOpacity === 75,
			'bg-black/95': bgOpacity === 95,
			'pointer-events-none': !isInteractive,
		}"
	>
		<div ref="scroll" class="relative flex h-auto w-full flex-col justify-end">
			<p
				v-for="log in logs"
				:key="log.id"
				:class="{
					'text-red-500': log.type === 'error',
					'text-white': log.type === 'info',
					'text-yellow-500': log.type === 'warning',
				}"
				class="text-xs"
			>
				<span v-if="log.type === 'info'" class="opacity-50">[I]</span
				><span v-if="log.type === 'warning'" class="opacity-50">[W]</span
				><span v-if="log.type === 'error'" class="opacity-50">[E]</span> {{ log.msg }}
			</p>
		</div>
	</div>

	<div class="absolute left-4 top-4 z-50 flex gap-2">
		<button class="h-4 w-4 rounded-full bg-blue-500 slidesgo:bg-purple-400" @click="onToggleVisibility"></button>
		<button v-if="isVisible" class="h-4 w-4 rounded-full bg-gray-500" @click="onToggleOpacity"></button>
		<button
			v-if="isVisible"
			class="h-4 w-4 rounded-full"
			:class="{
				'bg-green-800': isInteractive,
				'bg-red-800': !isInteractive,
			}"
			@click="onToggleInteractive"
		></button>
		<button
			v-if="isVisible"
			class="flex items-center justify-center rounded px-1 text-xs text-white ring-1 ring-white"
			@click="onToggleElementDebug"
		>
			Indexes
		</button>
		<button
			v-if="isVisible"
			class="flex items-center justify-center rounded px-1 text-xs text-white ring-1 ring-white"
			@click="onClear"
		>
			Clear
		</button>
	</div>
</template>

<style scoped></style>
