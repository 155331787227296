<script setup lang="ts">
import { computed, watch } from 'vue';

import DownloadOptionButton from '@/export/download/components/DownloadOptionButton.vue';
import DownloadPptxButton from '@/export/download/components/DownloadPptxButton.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import { useProjectControls } from '@/project/composables/useProjectControls';
import { useProjectStore } from '@/project/stores/project';
import { DownloadFormat } from '@/Types/types';

const emit = defineEmits<{
	(e: 'change', val: number): void;
	(e: 'selected', format: DownloadFormat): void;
}>();

const props = defineProps<{
	options: DownloadFormat[];
	selected: DownloadFormat;
}>();

const project = useProjectStore();
const { selectedPages } = useDownloadsProject();
const { trans } = useI18n();
const { multiplyFactor } = useDownloadsProject();
const { LIMIT_TIME_DOWNLOAD } = useProjectControls();

const onChangeMultiplySize = (val: number) => {
	emit('change', val);
};

const onSelectFormat = (format: DownloadFormat) => {
	emit('selected', format);
};

const overLimitToDownload = computed(() => {
	return selectedPages.value.reduce((total, page) => total + page.duration(), 0) > LIMIT_TIME_DOWNLOAD;
});

const hasVideo = computed(() => {
	return selectedPages.value.some((page) => page.containsVideo());
});

// Vigilamos si las páginas seleccionadas tienen video para cambiar de formato mp4, cuando no haya video en la selección
watch(hasVideo, () => {
	if (hasVideo.value || props.selected !== DownloadFormat.mp4) return;
	onSelectFormat(DownloadFormat.pdf);
});
</script>

<template>
	<div>
		<!-- PNG -->
		<DownloadOptionButton
			v-if="options.includes(DownloadFormat.png)"
			:format="DownloadFormat.png"
			:label="DownloadFormat.png.toUpperCase()"
			:multiply-factor="multiplyFactor"
			:icon="'png'"
			:description="trans('To keep high quality in complex images and illustrations')"
			:selected-condition="selected === DownloadFormat.png"
			@change="onChangeMultiplySize"
			@selected="onSelectFormat(DownloadFormat.png)"
		/>
		<!-- Transparent PNG -->
		<DownloadOptionButton
			v-if="options.includes(DownloadFormat.tpng)"
			:format="DownloadFormat.tpng"
			:label="'PNG (without background)'"
			:multiply-factor="multiplyFactor"
			:icon="'png-t'"
			:description="trans('Ideal for logos and web assets')"
			:selected-condition="selected === DownloadFormat.tpng"
			@change="onChangeMultiplySize"
			@selected="onSelectFormat(DownloadFormat.tpng)"
		/>
		<!-- JPG -->
		<DownloadOptionButton
			v-if="options.includes(DownloadFormat.jpg)"
			:format="DownloadFormat.jpg"
			:label="DownloadFormat.jpg.toUpperCase()"
			:multiply-factor="multiplyFactor"
			:icon="'jpg'"
			:description="trans('Ideal for digital sharing and space-saving')"
			:selected-condition="selected === DownloadFormat.jpg"
			@change="onChangeMultiplySize"
			@selected="onSelectFormat(DownloadFormat.jpg)"
		/>
		<!-- PDF -->
		<DownloadOptionButton
			v-if="options.includes(DownloadFormat.pdf)"
			:format="DownloadFormat.pdf"
			:label="DownloadFormat.pdf.toUpperCase()"
			:icon="'pdf'"
			:description="trans('Ideal for documents or printing')"
			:selected-condition="selected === DownloadFormat.pdf"
			@selected="onSelectFormat(DownloadFormat.pdf)"
		/>
		<!-- MP4 -->
		<DownloadOptionButton
			v-if="options.includes(DownloadFormat.mp4) && project.allVideos.length"
			:format="DownloadFormat.mp4"
			:class="!hasVideo || overLimitToDownload ? 'pointer-events-none opacity-25' : ''"
			:disabled="!hasVideo || overLimitToDownload"
			:label="`${DownloadFormat.mp4.toUpperCase()} video`"
			:icon="'mp4'"
			:description="'Ideal for high quality video and audiovisual content'"
			:selected-condition="selected === DownloadFormat.mp4"
			@selected="onSelectFormat(DownloadFormat.mp4)"
		/>
		<!-- PPTX -->
		<DownloadPptxButton
			v-if="options.includes(DownloadFormat.pptx)"
			:format="DownloadFormat.pptx"
			:label="DownloadFormat.pptx.toUpperCase()"
			:icon="'pptx'"
			:description="'Ideal for adding transitions and animations'"
			:selected-condition="selected === DownloadFormat.pptx"
			@selected="onSelectFormat(DownloadFormat.pptx)"
		>
		</DownloadPptxButton>
	</div>
</template>
