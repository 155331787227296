import { Svg } from '@svgdotjs/svg.js';
import { computed, ComputedRef, Ref } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import { Illustrator } from '@/elements/illustrator/classes/Illustrator';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProjectStore } from '@/project/stores/project';

export const useIllustrator = (illustrator: Ref<Illustrator>) => {
	const store = useMainStore();
	const project = useProjectStore();
	const secondPage = computed(() => project.pages[1]);
	const { addElement } = usePage(secondPage as ComputedRef<Page>);

	const moveTexts = (ids: string[]) => {
		if (!ids.length) return;

		const illustratorSvg = illustrator.value.contentSvg.addTo(document.body) as Svg;
		const viewbox = illustratorSvg.viewbox();

		illustratorSvg.height(viewbox.height);
		illustratorSvg.width(viewbox.width);
		illustratorSvg.attr('class', 'absolute top-0 left-0 opacity-0 -z-50');

		const selector = ids.map((id) => `[data-illustrator-link="${id}"]`).join(',');

		const texts = illustratorSvg.find(selector).map((text) => {
			const illustratorLink = text.data('illustrator-link');
			const newText = TemplateLoader.getNativeText(text);

			store.illustratorElementsMoved.add(illustratorLink);

			newText.setMetadata({
				...newText.metadata,
				illustratorLinks: [illustratorLink],
			});

			return newText;
		});

		illustratorSvg.remove();

		texts.forEach((t) => addElement(t));
	};

	const moveShapes = (ids: string[]) => {
		if (!ids.length) return;

		const illustratorSvg = illustrator.value.contentSvg as Svg;
		const selector = ids.map((id) => `[data-illustrator-link="${id}"]`).join(',');
		const tempG = illustratorSvg.group();

		illustratorSvg.find(selector).forEach((shape) => {
			const illustratorLink = shape.data('illustrator-link');

			store.illustratorElementsMoved.add(illustratorLink);

			tempG.add(shape);
		});

		const newShape = Shape.fromSvg(
			`<svg><defs>${illustratorSvg.defs().node.innerHTML}</defs>${tempG.node.outerHTML}</svg>`
		);

		newShape.setMetadata({
			...newShape.metadata,
			illustratorLinks: ids,
		});

		tempG.remove();

		addElement(newShape);
	};

	const removeElements = (ids: string[]) => {
		if (!ids.length) return;

		const illustratorSvg = illustrator.value.contentSvg as Svg;
		const selector = ids.map((id) => `[data-illustrator-link="${id}"]`).join(',');

		illustratorSvg.find(selector).forEach((el) => {
			el.remove();
		});

		illustratorSvg.find('g').forEach((el) => {
			if (!el.node.childElementCount) {
				el.remove();
			}
		});

		illustrator.value.content = illustratorSvg.node.outerHTML.toString();
	};

	return {
		moveTexts,
		moveShapes,
		removeElements,
	};
};
