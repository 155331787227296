<script lang="ts" setup>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { computed, ComputedRef, onMounted, ref, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { ItemList } from '@/Types/types';

const emits = defineEmits(['selected']);

const props = withDefaults(
	defineProps<{
		collection: ItemList[];
		canBeUnchecked?: (pageId: string) => boolean;
		placeholder?: string | ComputedRef<string>;
		multiple?: boolean;
		filledChecked?: boolean;
		showCheck?: boolean;
		showError?: boolean;
		maxItemsSelected?: number;
		toggleAllItems?: boolean;
		customButtonClass?: string;
	}>(),
	{
		collection: () => [
			{
				name: '',
				id: '',
				icon: '',
				disabled: false,
				selected: false,
			},
		],
		canBeUnchecked: () => false,
		placeholder: '',
		multiple: false,
		filledChecked: false,
		showError: false,
		showCheck: true,
		maxItemsSelected: 100,
		toggleAllItems: false,
		customButtonClass: '',
	}
);

const selectedItems = ref<ItemList[]>([]);

const toggleAllItems = computed(() => props.toggleAllItems);
const allItemsSelected = computed(
	() => selectedItems.value.length && props.collection.length === selectedItems.value.length
);

const customButtonStyles = computed(() => {
	let styles = '';

	if (props.showError) {
		styles += 'border-red-400 ';
	}

	if (props.customButtonClass.length) {
		styles += props.customButtonClass;
	} else {
		styles += 'rounded border border-gray-200 pl-2 pr-6 text-sm text-gray-600';
	}

	return styles;
});

const onChangeListOption = (newVal: ItemList[] | ItemList) => {
	selectedItems.value = Array.isArray(newVal) ? newVal : [newVal];
	emits('selected', selectedItems.value);
};

const isChecked = (selectedItem: ItemList, itemId: string) =>
	(selectedItem && !allItemsSelected.value) ||
	allItemsSelected.value ||
	(!props.multiple && selectedItems.value.length && selectedItems.value[0].id === itemId);

onMounted(() => {
	if (props.multiple && props.maxItemsSelected) {
		selectedItems.value = props.collection.filter((item) => item.selected);
		return;
	}

	const selectedItem = props.collection.find((item) => item.selected);
	selectedItems.value = selectedItem ? [selectedItem] : [];
});

watch(toggleAllItems, () => {
	selectedItems.value =
		selectedItems.value.length === props.collection.length ? [props.collection[0]] : props.collection;
});
</script>

<template>
	<div
		class="listoptions-container"
		:tooltip="maxItemsSelected === selectedItems.length ? `Select a maximum of ${maxItemsSelected}` : null"
		tooltip-position="top"
	>
		<Listbox
			v-if="collection.length > 1"
			v-model="selectedItems"
			:multiple="multiple"
			@update:model-value="onChangeListOption"
		>
			<ListboxButton
				class="relative h-10 w-full text-left"
				:class="customButtonStyles.length ? customButtonStyles : ''"
			>
				<div class="truncate">
					{{ placeholder }}
				</div>
				<SvgIcon name="caret" class="absolute right-2 top-3 h-4 w-4" />
			</ListboxButton>

			<ListboxOptions
				class="absolute top-full z-20 max-h-64 w-full overflow-auto rounded border border-gray-100 bg-white p-2 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100"
			>
				<slot name="custombutton"></slot>

				<ListboxOption
					v-for="item in collection"
					:key="item.id"
					v-slot="{ selected }"
					:value="item"
					:disabled="item.disabled"
					:class="{
						'pointer-events-none':
							(canBeUnchecked && canBeUnchecked(item.id)) ||
							(selectedItems.length === maxItemsSelected &&
								!selectedItems.find((selectedItem) => selectedItem.id === item.id)),
						'bg-gray-100/25 opacity-50':
							item.disabled ||
							(selectedItems.length === maxItemsSelected &&
								!selectedItems.find((selectedItem) => selectedItem.id === item.id)),
					}"
				>
					<div
						class="flex h-9 items-center rounded px-2.5 text-gray-600 focus:bg-gray-100/25"
						:class="{
							'bg-gray-100/25': showCheck && selected,
							'bg-gray-100/25 opacity-50':
								showCheck &&
								selectedItems.length === maxItemsSelected &&
								!selectedItems.find((selectedItem) => selectedItem.id === item.id),
							'bg-blue-500 text-white': !showCheck && selectedItems[0]?.id === item?.id,
							'cursor-pointer hover:text-blue-500': !showCheck,
						}"
					>
						<slot name="item" :item="item"></slot>

						{{ TextTools.capitalizeFirstLetter(item.name) }}

						<span
							v-if="
								showCheck &&
								(isChecked(selected, item.id) || placeholder === TextTools.capitalizeFirstLetter(item.name))
							"
							class="absolute right-4 flex h-4 w-4 items-center justify-center rounded-sm border"
							:class="filledChecked ? 'border-blue-400 bg-blue-400' : 'border-none'"
						>
							<SvgIcon name="check" class="h-3 w-3" :class="filledChecked ? 'text-white' : 'text-blue-600'" />
						</span>
						<span
							v-else-if="showCheck"
							:class="{
								'absolute right-4 flex h-4 w-4 items-center justify-center rounded-sm border border-blue-400 bg-white':
									filledChecked,
							}"
						>
						</span>
					</div>
				</ListboxOption>
			</ListboxOptions>
		</Listbox>
	</div>
</template>
<style lang="sass">
.listoptions-container[tooltip-position=top]:before
	@apply bg-red-600/80 text-white shadow-lg
</style>
