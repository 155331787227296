<script lang="ts" setup>
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Image from '@/elements/medias/images/image/classes/Image';
import { useImage } from '@/elements/medias/images/image/composables/useImage';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';

const props = withDefaults(
	defineProps<{
		image: Image;
		showBackground?: boolean;
		bgStyle?: string;
		preset: string;
	}>(),
	{
		showBackground: false,
		bgStyle: '',
	}
);

const element = toRef(props, 'image');
const { foreground } = useLayersImage(element);

const width = computed(() => {
	return foreground.value ? `${(foreground.value.size.width / element.value.crop.size.width) * 100}%` : 0;
});
</script>

<template>
	<div
		class="z-10"
		:class="{
			'rounded border-2 border-blue-500 slidesgo:border-purple-400 slidesgo:ring-purple-400 lg:border-0 lg:ring-2 lg:ring-blue-500':
				element.virtualGroup?.startsWith(props.preset),
		}"
		@click="() => GAnalytics.track('click', 'Button', `remove-background-${preset}`, null)"
	>
		<div class="group relative flex shrink-0 flex-col overflow-hidden rounded">
			<img
				v-if="showBackground"
				:src="image.url"
				:style="bgStyle"
				alt=""
				class="absolute aspect-square h-full rounded object-cover"
				draggable="false"
			/>
			<img
				v-if="image.urlBackgroundRemoved"
				:src="image.urlBackgroundRemoved"
				alt=""
				class="z-10 aspect-square rounded object-cover"
				draggable="false"
			/>
			<div
				:style="{
					width,
				}"
				class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
			>
				<slot></slot>
			</div>
			<div class="absolute w-full">
				<div>
					<slot name="header"></slot>
				</div>
			</div>
			<div class="absolute bottom-0 w-full">
				<div>
					<slot name="footer"></slot>
				</div>
			</div>
			<div class="absolute inset-0">
				<slot name="overlay"></slot>
			</div>
		</div>
	</div>
</template>

<style scoped></style>
