<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { computed, ref } from 'vue';

import { addUserVectorToUserFolder } from '@/api/UserApiClient';
import Dropdown from '@/common/components/Dropdown.vue';
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useToast } from '@/common/composables/useToast';
import { useI18n } from '@/i18n/useI18n';
import { TemplateApiData, UserFoldersApi } from '@/Types/apiClient';

const { trans } = useI18n();
const MAX_FOLDERS = 20;

const props = defineProps<{ selectedTemplate: TemplateApiData | null; folders: UserFoldersApi[] | null }>();

const emit = defineEmits(['close', 'added', 'created']);

const toast = useToast();

const adding = ref(false);
const showDropdown = ref(props.folders !== null && props.folders.length > 0);
const currentFolderSlug = ref(props.selectedTemplate?.folder?.slug);
const currentFolder = ref(props.selectedTemplate?.folder?.name || '');
const newFolderName = ref('');

const folders = computed(() =>
	(props.folders || []).map((f) => ({
		value: f.slug,
		label: f.name,
	}))
);

const onClickClose = () => {
	emit('close');
	Bugsnag.leaveBreadcrumb(`Add to folder creation modal is closed`);
};

const addToFolderTemplate = async () => {
	const name = newFolderName.value.trim();

	if (
		(!currentFolderSlug.value && showDropdown.value) ||
		!props.selectedTemplate ||
		(!showDropdown.value && !name.length)
	)
		return;

	adding.value = true;

	const { data, error } = await addUserVectorToUserFolder(props.selectedTemplate.uuid as string, {
		folder: currentFolderSlug.value,
		folderName: name.trim(),
	});

	adding.value = false;

	if (error.value) {
		toast.error(trans('The template could not be added to the folder'));
		return;
	}

	toast.success(trans('Template added to the folder'));

	emit('added');
	emit('created', data.value);
};

const changeFolder = (option: any) => {
	currentFolder.value = option.label;
	currentFolderSlug.value = option.value;
};

const toggleMode = () => {
	if (adding.value) return;

	showDropdown.value = !showDropdown.value;
	newFolderName.value = '';
};
</script>

<template>
	<Modal open @close="onClickClose">
		<div class="relative my-auto mx-auto max-w-lg rounded bg-white py-8 px-8 lg:w-[470px]">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded bg-white p-1 hover:bg-gray-100 hover:bg-opacity-25"
				@click="onClickClose"
			>
				<SvgIcon name="close-modal" class="h-6 w-6 fill-current text-gray-700" />
			</button>

			<div>
				<h1 class="mb-4 text-center text-2xl font-semibold text-gray-800">{{ trans('Add template to folder') }}</h1>
				<div class="flex flex-col">
					<Dropdown
						v-if="showDropdown"
						class="flex h-12 w-full items-center justify-center rounded border border-gray-100 text-gray-800 placeholder:text-gray-600"
						:selected="currentFolder"
						:options="folders"
						:use-light-mode="true"
						@update="changeFolder"
					/>

					<input
						v-else
						v-model="newFolderName"
						type="text"
						minlength="1"
						maxlength="255"
						class="h-12 w-full rounded border border-gray-100 px-4 text-gray-800 placeholder:text-gray-600"
						:placeholder="trans('Folder name...')"
					/>

					<button
						v-if="folders.length > 0 && folders.length < MAX_FOLDERS"
						class="mx-auto mt-4 text-sm text-gray-600 hover:text-gray-800"
						:disabled="adding"
						@click="toggleMode"
					>
						{{ showDropdown ? trans('Or create a new folder') : trans('Or use existing folder') }}
					</button>
				</div>

				<div class="mt-4 flex items-center justify-center">
					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-gray-200 px-8 text-lg font-semibold text-gray-700 hover:border-gray-300"
						:disabled="adding"
						@click.stop="onClickClose"
					>
						{{ trans('Cancel') }}
					</button>

					<button
						class="mx-2 flex h-12 items-center justify-center rounded border-2 border-blue-500 bg-blue-500 px-8 text-lg font-semibold text-white hover:border-blue-600 hover:bg-blue-600 slidesgo:border-purple-400 slidesgo:bg-purple-400"
						:disabled="adding"
						@click.stop="addToFolderTemplate"
					>
						<SvgIcon v-if="adding" name="spinner" class="mr-1 h-6 w-6 animate-spin" />
						{{ trans('Add to folder') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>
