import Bugsnag from '@bugsnag/browser';
import { promiseTimeout, useIntervalFn, watchDebounced } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { computed, Ref, ref, watch, WatchStopHandle } from 'vue';

import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useElementTransformOrchestrator } from '@/elements/element/composables/useElementTransformOrchestrator';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useSelection } from '@/interactions/composables/useSelection';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useProject } from '@/project/composables/useProject';
import { Anchor, Position } from '@/Types/types';
export const useElementRegardingPage = (element: Ref<Element>) => {
	const store = useMainStore();
	const { setSelection } = useSelection();

	const temporalRefPage = ref(Page.createDefault());

	const temporalRef = ref<Element>(Image.create());
	const usingTransform = useElementTransformOrchestrator(temporalRef);
	const elementTransform = useElementTransformOrchestrator(element);
	const { foreground, hasForeground } = useLayersImage(temporalRef as Ref<Image>);
	const { addElement, removeElement } = usePage(temporalRefPage as Ref<Page>);
	const { getPageFromElement } = useProject();

	const isOutsidePage = computed(() => {
		const isOutsideLeft =
			element.value.position.x + elementTransform.value.widthWithRotation.value < elementTransform.value.left.value;
		const isOutsideRight =
			element.value.position.x - elementTransform.value.widthWithRotation.value > elementTransform.value.right.value;
		const isOutsideX = isOutsideLeft || isOutsideRight;

		const isOutsideTop =
			element.value.position.y + elementTransform.value.heightWithRotation.value < elementTransform.value.top.value;
		const isOutsideBottom =
			element.value.position.y - elementTransform.value.heightWithRotation.value > elementTransform.value.bottom.value;
		const isOutsideY = isOutsideTop || isOutsideBottom;

		return isOutsideX || isOutsideY;
	});

	// Sin uso pero quizás se pueda reaprovechar para enviar a otra página desde menú
	const moveToAnotherPage = async (elements: Element[], newPage: Page, positions?: Map<string, Position>) => {
		let elementsToMove = elements.map((el) => TemplateLoader.unserializeElement(cloneDeep(el)));

		Bugsnag.leaveBreadcrumb(`Move the follow elements to ${newPage.id}: ${elements.map((el) => el.id).join('; ')}`);

		await promiseTimeout(700);

		// Buscamos posibles Foregrounds en caso de haber seleccionado imágenes
		const foregroundsToMove = getRelatedForegrounds(elements);

		// Eliminar de la página en la que estaba la selección
		temporalRefPage.value = getPageFromElement(elements[0]) as Page;

		//Ordenamos el listado de elementos antes de insertarlo en la página nueva
		elementsToMove = elementsToMove.sort((a, b) => a.index - b.index);

		elements.forEach((elem) => {
			removeElement(elem);
		});

		// Añadir a la nueva página la selección
		temporalRefPage.value = newPage;
		elementsToMove.forEach((elem) => {
			if (positions) {
				const position = positions.get(elem.id);
				position && elem.setPosition(position.x, position.y);
			} else {
				temporalRef.value = elem;
				usingTransform.value.align(Anchor.center);
			}
			addElement(elem);

			// Si hay un Foreground asociado se debe añadir también a la página
			const foregroundToMove = foregroundsToMove.get(elem.id);
			foregroundToMove && addElement(foregroundToMove);
		});

		store.setActivePage(temporalRefPage.value as Page);
		await new Promise((resolve) => {
			const interval = useIntervalFn(() => {
				if (temporalRefPage.value?.domNode()?.classList.contains('canvas-rendering-finished')) {
					interval.pause();
					resolve(store.activePage);
				}
			}, 100);
		});

		elementsToMove.forEach((el, i) => {
			setSelection(el, i !== 0);
		});
	};

	const getRelatedForegrounds = (elements: Element[]) => {
		const foregroundsToMove = new Map<string, ForegroundImage>();

		elements
			.filter((el): el is Image => el instanceof Image)
			.forEach((img) => {
				// Si es una imagen tenemos que comprobar si tiene foreground
				// para llevar la copia también a la nueva página
				temporalRef.value = img;
				hasForeground.value &&
					foregroundsToMove.set(
						img.id,
						TemplateLoader.unserializeElement(cloneDeep(foreground.value)) as ForegroundImage
					);
			});

		return foregroundsToMove;
	};

	return {
		isOutsidePage,
		moveToAnotherPage,
	};
};
