<script lang="ts" setup>
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';
const { trans } = useI18n();
</script>

<template>
	<Modal open>
		<div
			class="relative mx-auto flex max-w-sm flex-col items-center justify-center overflow-hidden rounded-md bg-white px-8 py-8"
		>
			<SvgIcon name="alert" class="mx-auto mb-2 h-12 w-12 fill-current text-yellow-500" />
			<h1 class="mb-3 text-center text-2xl font-semibold text-gray-800">
				{{ trans(`You are running an old browser version`) }}
			</h1>
			<p class="mb-0 text-center leading-normal text-gray-600">
				{{ trans('You need to update your browser to use Wepik and have the best experience.') }}
			</p>
		</div>
	</Modal>
</template>
