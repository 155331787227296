<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: Image }>();
const element = toRef(props, 'element');

const { unifyLayers } = useLayersImage(element);
const { trans } = useI18n();
</script>

<template>
	<button
		data-button-importance="important"
		class="flex h-9 w-9 items-center justify-center rounded text-gray-100 focus:outline-none hover:text-white slidesgo:hover:bg-purple-500 lg:w-full lg:bg-gray-700 lg:hover:bg-gray-600"
		@click="unifyLayers"
	>
		<div class="flex w-full items-center justify-center">
			<SvgIcon name="layers" class="h-5 w-5 shrink-0 scale-90 lg:h-4 lg:w-4" />
			<span class="mt-0 ml-2 hidden text-sm lg:block" data-button-label>{{ trans('Unify') }}</span>
			<span
				class="font-sm absolute -top-1 right-0 flex h-4 shrink-0 scale-75 items-center justify-center rounded bg-blue-500 px-1 text-2xs font-bold text-white slidesgo:bg-purple-400 lg:relative lg:top-auto lg:right-auto lg:-mr-1 lg:ml-2 lg:scale-100"
			>
				<p class="mt-px">{{ trans('NEW') }}</p>
			</span>
		</div>
	</button>
</template>
