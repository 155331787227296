<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { until, watchOnce } from '@vueuse/core';
import { onMounted, ref, toRef, watch } from 'vue';

import { useAdminActions } from '@/admin/composables/useAdminActions';
import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useUsersnap } from '@/analytics/usersnap/composables/useUsersnap';
import { useTracking } from '@/api/composables/useTracking';
import { checkApiKey, checkCategory } from '@/api/DataApiClient';
import { useAuth } from '@/auth/composables/useAuth';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useGlobalError } from '@/editor/composables/useGlobalError';
import { useMainStore } from '@/editor/stores/store';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useHistoryStore } from '@/history/stores/history';
import { useI18n } from '@/i18n/useI18n';
import CanvasPreviewGenerator from '@/layout/components/CanvasPreviewGenerator.vue';
import EditorBase from '@/layout/components/EditorBase.vue';
import { useTemplateLoader } from '@/loader/composables/useTemplateLoader';
import { useProjectStore } from '@/project/stores/project';

const store = useMainStore();
const project = useProjectStore();
const history = useHistoryStore();
const showCanvas = ref(false);
const { init } = useTemplateLoader();
const { invalidFonts } = useFonts();
const { isMobile, isTouch, isWebview, isOldApp } = useDeviceInfo();

const { isAdmin, onFetchLogin, isLogged } = useAuth();
const { detect: detectEditorMode, mode, isAdminMode, isEmbeddedContext, embeddedContextData } = useEditorMode();

const { setGlobalError } = useGlobalError();
const { showReports, showTemplateSuggestions } = useAdminActions();
const { track } = useTracking();
const { showUserSnapAfterMinutes } = useUsersnap();
const { trans } = useI18n();

onMounted(async () => {
	// Si es la app antigua no hacemos nada
	if (isOldApp.value) return;

	if (isEmbeddedContext.value && embeddedContextData.value.apiKey) {
		const res = await checkApiKey(embeddedContextData.value.apiKey);
		const { website } = res.data.value;
		const isLocalDomain = ['localhost', '.local', '127.0.0.1'].find((domain) => document.referrer.includes(domain));

		if (!document.referrer.includes(website) && !isLocalDomain) {
			const err = new Error('This API key is not valid for this website');

			setGlobalError(err);
			throw err;
		}
	}

	if (isEmbeddedContext.value && embeddedContextData.value.category) {
		const res = await checkCategory(embeddedContextData.value.category);
		const { id, name } = res.data.value;

		if (id && name) {
			store.activeTemplateCategory = {
				id,
				name,
			};
		}
	}

	try {
		await init();

		if (invalidFonts.value.size > 0 && !isAdminMode.value) {
			GAnalytics.track('error', 'Font', Array.from(invalidFonts.value).join(', '), null);
		}
	} catch (e: any) {
		setGlobalError(e);
		throw e;
	}

	showReports();
	showTemplateSuggestions();
	showUserSnapAfterMinutes();

	setTimeout(() => {
		track('edit', {
			vector_id: project.sourceVectorId,
			user_vector_id: store.userVector?.uuid,
		});
	}, 5000);

	// TODO: Eliminar Watch cuando se implementen todos los nuevos eventos de Analytics de la versión 4
	watchOnce(
		() => history.states,
		() => {
			if (history.states.length && store.generator) {
				GAnalytics.trackGA4('edit_general');
			}
		},
		{ deep: true }
	);
	showCanvas.value = true;
});

// Al cargar el UserVector comprobamos si la plantilla requiere slidesgo context
const userVector = toRef(store, 'userVector');
watch(userVector, () => {
	detectEditorMode();

	// Si estamos en el editor para terceros tenemos que comprobar que la plantilla tenga el mismo aspect ratio
	// que el dado por el dev, si no es igual no dejamos editar
	if (isEmbeddedContext.value) {
		const aspectRatio = parseFloat((project.size.width / project.size.height).toFixed(5));

		if (embeddedContextData.value.aspectRatio !== aspectRatio) {
			const err = new Error(trans('This template does not use a valid size.'));

			setGlobalError(err);
			throw err;
		}
	}
});

onFetchLogin(() => {
	if (!isAdminMode.value) return;

	mode.value = isAdmin.value ? 'admin' : 'editor';

	// Comprobamos si está logeado para que fuerce una sincronización del template del usuario
	until(isLogged)
		.toBe(true)
		.then(() => project.triggerSync?.());
});

Bugsnag.leaveBreadcrumb(
	`Mode ${mode.value} ${isMobile.value ? 'responsive; ' : ''} ${isTouch.value ? 'is touch; ' : ''} ${
		isWebview.value ? 'is webView.' : ''
	}`
);
</script>

<template>
	<EditorBase :show-canvas="showCanvas">
		<!--		<template #after-main><CanvasPreviewGenerator v-if="project.pages.length" /></template>-->
	</EditorBase>
</template>
