<script setup lang="ts">
import { promiseTimeout, TransitionPresets, useTransition } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { TimeTools } from '@/common/utils/TimeTools';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';
import CanvasPreview from '@/page/components/CanvasPreview.vue';
import { useProjectStore } from '@/project/stores/project';
import { DownloadFormat, DownloadInfo } from '@/Types/types';
const props = defineProps<{
	download: DownloadInfo;
}>();

const emit = defineEmits<{
	(e: 'delete'): void;
}>();

const { trans } = useI18n();
const projectStore = useProjectStore();

const total = ref(0);
const getDuration = () => {
	if (props.download.format === DownloadFormat.mp4) {
		return props.download.duration * 2.5 + 90000; // Duración del vídeo * 2 (grabación) + minuto y medio de preparación entorno
	}
	if (props.download.format === DownloadFormat.pdf) {
		return 20000;
	}

	return 10000; // 10 segundos para cualquier otro formato
};

const duration = ref(getDuration());
const finished = ref(false);
const progress = useTransition(total, {
	duration,
	transition: TransitionPresets.easeInSine,
	disabled: finished,
});

const minutesLeft = computed(() => {
	const consumedDurationInMs = (progress.value / 100) * duration.value;
	const remainingDurationInMs = duration.value - consumedDurationInMs;

	return Math.ceil(TimeTools.toMinutes(remainingDurationInMs));
});

onMounted(() => (total.value = 90));

watch(
	() => props.download.status,
	async () => {
		total.value = 100;
		// Esperamos 3 segundos para mostrar el estado de descarga completada y después mostrar el estado de descarga completada con enlace
		await promiseTimeout(3000);
		finished.value = true;
	}
);
const page = computed((): Page | null => {
	const [page] = projectStore.pages;
	if (page instanceof Page) return page;
	return null;
});

const deleteDownload = () => {
	emit('delete');
};
</script>
<template>
	<div class="group relative flex items-center">
		<div class="relative mr-4 aspect-square w-12 shrink-0 overflow-hidden rounded bg-gray-100/50">
			<CanvasPreview
				v-if="page"
				:page="page"
				class="z-0 h-full w-full"
				preview-name="download-info"
				scroll-area="#downloads-container"
			/>
		</div>
		<div class="mr-2 min-w-0 flex-1">
			<p class="truncate text-sm font-semibold text-gray-600">{{ download.name }}</p>
			<template v-if="download.status === 'progress'">
				<p class="text-xs text-gray-500">
					{{
						minutesLeft <= 1
							? trans('It will be ready in a few moments')
							: trans('This action take {time} minutes left', { time: minutesLeft })
					}}
				</p>
				<div class="relative mt-1 h-1 overflow-hidden rounded-lg bg-gray-100/50">
					<div class="absolute inset-0 bg-blue-500 slidesgo:bg-purple-400" :style="{ width: progress + '%' }"></div>
				</div>
			</template>
			<div v-else-if="download.status === 'done' && !finished" class="text-green-500">
				<div class="flex items-center text-xs font-semibold">
					<SvgIcon name="check" class="mr-2 h-3.5 w-3.5" />
					{{ trans('Your project is ready') }}
				</div>
				<div class="relative mt-1 h-1 overflow-hidden rounded-lg bg-green-500" />
			</div>
			<div v-else-if="download.status === 'done'" class="text-xs text-gray-500">
				{{ trans("If the download didn't start") }}
				<a class="text-blue-500 underline hover:text-blue-600" :href="download.url">{{ trans('click here') }}</a>
			</div>
			<div v-else-if="download.status === 'error'" class="flex items-center text-xs text-red-500">
				<SvgIcon name="alert" class="mr-1 h-3 w-3" />
				<span class="hidden lg:block">{{ trans('An error occurred while downloading the file') }}</span>
				<span class="lg:hidden">{{ trans('An error occurred') }}</span>
			</div>
		</div>
		<button
			class="rounded-sm bg-white p-1 opacity-0 group-hover:opacity-100 hover:bg-gray-100/25"
			@click="deleteDownload"
		>
			<SvgIcon name="cross" class="h-3 w-3 fill-current" />
		</button>
	</div>
</template>
