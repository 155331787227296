<script lang="ts" setup>
import Modal from '@/common/components/Modal.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();

defineProps<{
	open: boolean;
	deleteClipboardMessage: boolean;
}>();

const emit = defineEmits<{
	(e: 'close'): void;
	(e: 'confirm'): void;
}>();
</script>

<template>
	<Modal :open="open" @close="emit('close')">
		<div class="relative mx-auto flex max-w-lg overflow-hidden rounded">
			<button
				class="absolute top-0 right-0 mt-2 mr-2 rounded-sm bg-white p-1 hover:bg-gray-100/25"
				@click="emit('close')"
			>
				<SvgIcon name="cross" class="h-5 w-5 fill-current text-gray-700 lg:h-6 lg:w-6" />
			</button>
			<div class="flex flex-col bg-white p-8 text-center">
				<h2 class="mt-2 mb-4 text-center text-xl font-semibold text-gray-800 lg:text-2xl">
					{{ trans('Delete upload') }}
				</h2>
				<p class="mb-0 text-center text-gray-600 lg:text-lg">
					{{
						trans('This image will no longer be available in all your templates where it is being used. Are you sure?')
					}}
				</p>

				<p v-if="deleteClipboardMessage" class="mt-2 text-center text-gray-600 lg:text-lg">
					{{ trans('This image is in the clipboard, the clipboard will be deleted.') }}
				</p>
				<div class="mt-6 flex items-center justify-center">
					<button
						class="mx-2 flex h-10 items-center justify-center rounded border-2 border-gray-200 px-6 font-semibold text-gray-700 hover:border-gray-300 lg:h-12 lg:px-8 lg:text-lg"
						@click="emit('close')"
					>
						{{ trans('Go Back') }}
					</button>
					<button
						class="mx-2 flex h-10 items-center justify-center rounded border-2 border-red-500 bg-red-500 px-6 font-semibold text-white hover:border-red-400 hover:bg-red-400 lg:h-12 lg:px-8 lg:text-lg"
						@click="emit('confirm')"
					>
						{{ trans('Delete') }}
					</button>
				</div>
			</div>
		</div>
	</Modal>
</template>

<style scoped></style>
