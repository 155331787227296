import Bugsnag from '@bugsnag/js';
import { promiseTimeout } from '@vueuse/core';
import { computed, nextTick, Ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import { useTextSelection } from '@/elements/texts/text/composables/useTextSelection';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import TextSelectionTools from '@/elements/texts/text/utils/TextSelectionTools';
import TextTools from '@/elements/texts/text/utils/TextTools';

export const useTextLink = (text: Ref<Text>) => {
	const { textEditing, exitTextEditingIfPreviouslyActive } = useTextEditing();

	const { setTemporalContentEditable, removeTemporalContentEditable } = useTextStyles(text);

	const { selection, selectedNodes, previousInputSelection, domNode } = useTextSelection();

	const linkContent = computed(() => {
		const domNode = text.value.domNode();
		const selectedNodes = TextTools.getSelectedNodes();
		if (!domNode) return '';
		// Si el texto no tiene aplicado un enlace devolvemos la cadena vacía
		if (domNode && !domNode.querySelectorAll('a').length) return '';
		//  Comprobamos si la selección se hace sobre el texto editable y devolvemos su valor href en caso de que lo tenga aplicado
		if (
			textEditing.value &&
			selectedNodes.length &&
			(selectedNodes[0].parentNode as HTMLElement).closest(`#editable-${text.value.id}`)
		) {
			const hrefFromNodes = selectedNodes
				.map((node) => node && (node.parentElement as HTMLAnchorElement).href)
				.filter((href) => !!href);
			const uniqueHref = hrefFromNodes.every((href) => href === hrefFromNodes[0]);
			if (uniqueHref) return uniqueHref ? hrefFromNodes[0] : '';
		}

		// si no estamos en modo edición , y el texto tiene un solo enlace aplicado, o todos los nodos tienen el mismo enlace aplicado
		//  mostramos el enlace que tiene aplicado
		if (
			(!textEditing.value && domNode.querySelectorAll('a').length === 1) ||
			Array.from(domNode.querySelectorAll('a')).every((a) => a.href === domNode.querySelectorAll('a')[0].href)
		) {
			const firstLinkFromText = domNode.querySelectorAll('a')[0].href;
			return firstLinkFromText;
		}

		// para los demás casos devolvemos la cadena vacía
		return '';
	});

	const restorePreviousSelection = async () => {
		if (previousInputSelection.value) {
			TextSelectionTools.createRange(previousInputSelection.value, domNode);

			previousInputSelection.value = null;

			// Esperamos para que se pueda disparar el evento de cambio de selección del DOM,
			// para que se aplique la nueva selección
			await promiseTimeout(1);
		}
	};

	const updateLink = async (link: string) => {
		if (!domNode.value) return;

		TextSelectionTools.fixTextSelection(domNode);

		let selectAll;
		let isEditable;

		if (link !== 'unlink') {
			if (!textEditing.value) {
				isEditable = setTemporalContentEditable(domNode.value);
			}

			await restorePreviousSelection();

			if (selection.value?.selection) {
				selectAll = TextSelectionTools.detectFullRange(selection.value?.selection, domNode.value);
			} else {
				selectAll = true;
			}

			const temporalSelection = selection.value?.selection;
			const focusNode = temporalSelection?.focusNode as HTMLElement;

			if (temporalSelection?.anchorNode) {
				const finalNode =
					temporalSelection?.anchorNode.nodeType === 1
						? temporalSelection?.anchorNode
						: temporalSelection?.anchorNode.parentElement;
				if (
					finalNode instanceof HTMLElement &&
					!finalNode.closest(`#editable-${text.value.id}`) &&
					!finalNode.closest(`#element-${text.value.id} .text-element-final`)
				) {
					selectAll = true;
				}
			}

			if (!focusNode || focusNode.id === 'text-floating') {
				selectAll = true;
			}

			if (selectAll) {
				await TextSelectionTools.selectAllText(domNode);
			}

			link = link.trim();

			if (!link.startsWith('http://') && !link.startsWith('https://')) {
				link = `https://${link}`;
			}

			let anchorElement;
			if (temporalSelection) {
				anchorElement = getAnchorElement(temporalSelection);
			}

			if (temporalSelection?.isCollapsed && anchorElement instanceof HTMLAnchorElement) {
				anchorElement.href = link;
			} else {
				// Para evitar conflictos con execCommand eliminamos temporalmente los estilos y attrs
				// de los enlaces, después los recuperamos
				domNode.value.querySelectorAll('a').forEach((a) => {
					a.removeAttribute('target');
					a.removeAttribute('style');
				});

				document.execCommand('createLink', false, link);

				domNode.value.querySelectorAll('a').forEach((a) => {
					// borramos los nodos <a> que no tengan texto y recuperamos los estilos de aquellos nodos que contienen texto
					if (!a.textContent && a.parentElement?.nodeName === 'DIV') {
						a.parentElement.innerHTML = a.innerHTML;
					}
					a.setAttribute('target', '_blank');
				});
			}
		} else {
			const temporalSelection = selection.value?.selection;

			if (!textEditing.value) {
				isEditable = setTemporalContentEditable(domNode.value);
			}

			if (temporalSelection) {
				selectAll = TextSelectionTools.detectFullRange(temporalSelection, domNode.value);
			} else {
				selectAll = true;
			}

			const focusNode = temporalSelection?.focusNode;

			if (focusNode instanceof HTMLElement && focusNode?.id === 'text-floating') {
				selectAll = true;
			}

			if (selectAll) {
				await TextSelectionTools.selectAllText(domNode);
			}

			if (temporalSelection?.isCollapsed) {
				const anchorElement = getAnchorElement(temporalSelection);

				if (anchorElement instanceof HTMLAnchorElement) {
					anchorElement.removeAttribute('href');
					anchorElement.removeAttribute('style');
					anchorElement.removeAttribute('target');
				}

				// Reemplazamos los nodos A por spans
				domNode.value.innerHTML = domNode.value.innerHTML.replace(/<a>/g, '<span>').replace(/<\/a>/g, '</span>');
			} else {
				document.execCommand('unlink');

				// Reemplazamos los nuevos nodos U por spans
				domNode.value.innerHTML = domNode.value.innerHTML.replace(/<u>/g, '<span>').replace(/<\/u>/g, '</span>');
			}
		}

		if (!isEditable) {
			removeTemporalContentEditable(domNode.value);
		}

		if (domNode.value) {
			text.value.updateContent(domNode.value?.innerHTML);
		}

		text.value.content = domNode.value?.innerHTML;

		await nextTick();

		updateLinkUnderline(text);

		exitTextEditingIfPreviouslyActive();
	};

	const getAnchorElement = (selection: Selection) => {
		return selection?.anchorNode?.nodeType === 3 ? selection?.anchorNode?.parentElement : selection?.anchorNode;
	};

	const updateLinkUnderline = (text: Ref<Text>) => {
		const rootNode =
			text.value.domNode()?.querySelector<HTMLElement>('[id^=editable-]') ||
			text.value.domNode()?.querySelector<HTMLElement>('.text-element-final');

		if (rootNode) {
			const links = rootNode.querySelectorAll('a');

			links?.forEach((link) => {
				const colorToApplyString = getColorFromLink(link, rootNode);
				if (colorToApplyString.length) {
					const colorToApply = colorToApplyString.includes('-gradient')
						? GradientColor.fromString(colorToApplyString)
						: SolidColor.fromString(colorToApplyString);

					let color = text.value.colors.find((c) => c.toCssString() === colorToApply.toCssString());

					if (color) {
						color =
							color instanceof GradientColor
								? SolidColor.fromObject({
										r: color.stops[0].r,
										g: color.stops[0].g,
										b: color.stops[0].b,
										a: color.stops[0].a,
								  })
								: color;

						link.style.removeProperty('text-decoration-color');

						const style = link.getAttribute('style');
						link.setAttribute('style', color ? `${style} text-decoration-color:${color.toCssString()}` : `${style}`);
					}
				}
			});
		}
	};

	const getColorFromLink = (node: HTMLElement, root: HTMLElement): string => {
		const color = Object.values(node.style).find((style) => style.startsWith('--color'));

		if ((!color || !color?.length) && node.parentElement && node !== root) {
			return getColorFromLink(node.parentElement, root);
		}

		if (color) {
			return node.style.getPropertyValue(color);
		}

		return '';
	};
	const submitLink = (textLink: Ref<string>) => {
		updateLink(textLink.value);
		Bugsnag.leaveBreadcrumb(`Add text link to text-${text.value.id}: ${textLink.value}`);
		GAnalytics.track('click', 'Button', `insert-link`, null);
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement?.blur();
		}
	};

	const deleteLink = () => {
		updateLink('unlink');
		Bugsnag.leaveBreadcrumb(`Remove text link to text-${text.value.id}`);

		GAnalytics.track('click', 'Button', `remove-link`, null);
		//  Para que se apliquen correctamente los links en IOS (no hace submit correctamente y cuando vuelves a abrir el menu para hacer submit se vuelve a abrir el teclado virtual)
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement?.blur();
		}
	};

	return {
		submitLink,
		deleteLink,
		linkContent,
		updateLinkUnderline,
	};
};
