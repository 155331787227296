<script setup lang="ts">
import { computed, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import Dropdown from '@/common/components/Dropdown.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import Storyset from '@/elements/storyset/classes/Storyset';
import { useStoryset } from '@/elements/storyset/composables/useStoryset';
import { useI18n } from '@/i18n/useI18n';
import { StorysetBackground } from '@/Types/elements';

const props = defineProps<{
	element: Storyset;
}>();
const element = toRef(props, 'element');
const { trans } = useI18n();

// Methods
const { storysetBackgrounds, currentStorysetBackground, changeStorysetBackground } = useStoryset(element);

const backgroundOptions = computed(() => {
	return storysetBackgrounds.value.map((bg) => ({
		label: `${trans(bg.name)}`,
		value: bg.name.toLowerCase() as StorysetBackground,
	}));
});

const onChangeStorysetBackground = (ev: { label: string; value: StorysetBackground }) => {
	changeStorysetBackground(ev.value);

	GAnalytics.trackDebounceEditBarGA4({ category: 'storyset', link_text: 'background' });
};
</script>

<template>
	<Dropdown
		class="flex h-auto w-12 items-center justify-center lg:w-auto"
		:selected="currentStorysetBackground.toLocaleLowerCase()"
		:options="backgroundOptions"
		@update="onChangeStorysetBackground"
	>
		<template #default="{ onToggleVisibility }">
			<button
				tooltip="Change Background"
				tooltip-position="top"
				class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:mr-1 lg:h-10 lg:w-full lg:shrink lg:flex-row lg:justify-center lg:px-2"
				:class="'text-gray-100 hover:text-white'"
				@click.stop="onToggleVisibility"
			>
				<SvgIcon name="background" class="h-4 w-4 fill-current group-hover:text-white" />
				<span class="whitespace-nowrap text-2xs lg:ml-2 lg:mt-0 lg:text-sm" data-button-label>{{
					currentStorysetBackground
				}}</span>
			</button>
		</template>
	</Dropdown>
</template>
