import { createSharedComposable } from '@vueuse/core';
import { cloneDeep } from 'lodash';
import { ref } from 'vue';

import { Box } from '@/elements/box/classes/Box';
import { useRounding } from '@/elements/box/composables/useRounding';
import { Text } from '@/elements/texts/text/classes/Text';
import { BoxDTO, TextDTO } from '@/Types/elements';
import { BorderRadiusType } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

export const useCopyStyles = createSharedComposable(() => {
	const copiedStyles = ref<Partial<TextDTO | BoxDTO>>({});

	const tempRefBox = ref<Box>(Box.create());
	const { maxValue } = useRounding(tempRefBox);

	const clearCopiedStyles = () => {
		copiedStyles.value = {};
		const scrollArea = document.querySelector('#scroll-area');
		if (scrollArea) scrollArea.classList.remove('paint-roll-active');
	};

	const copyStyles = (element: Box | Text) => {
		if (!(element instanceof Box) && !(element instanceof Text)) return;

		if (element instanceof Box) {
			copyBoxStyles(element);
		}

		if (element instanceof Text) {
			copyTextStyles(element);
		}

		const scrollArea = document.querySelector('#scroll-area');
		if (scrollArea) scrollArea.classList.add('paint-roll-active');
	};

	const copyBoxStyles = (element: Box) => {
		tempRefBox.value = element;
		const radiusAsPercent = element.border.radius.map((r) => MathTools.ruleOfThree(maxValue.value, 100, r));

		copiedStyles.value = {
			type: element.type,
			border: {
				...cloneDeep(element.border),
				color: element.border.color.copy(),
				radius: radiusAsPercent as BorderRadiusType,
			},
			background: element.background.copy(),
		};
	};

	const copyTextStyles = (element: Text) => {
		copiedStyles.value = {
			type: element.type,
			fontFamily: element.fontFamily,
			fontWeight: element.fontWeight,
			fontStyle: element.fontStyle,
			fontSize: element.fontSize,
			lineHeight: element.lineHeight,
			letterSpacing: element.letterSpacing,
			textAlign: element.textAlign,
			outline: {
				...cloneDeep(element.outline),
				color: element.outline.color.copy(),
			},
			colors: element.colors.map((color) => color.copy()),
			textTransform: element.textTransform,
			scale: element.scale,
			textShadow: element.textShadow.map((shadow) => ({ ...cloneDeep(shadow), color: shadow.color.copy() })),
			curvedProperties: cloneDeep(element.curvedProperties),
		};
	};

	const pasteStyles = (element: Box | Text) => {
		if (!Object.keys(copiedStyles.value).length || (!(element instanceof Box) && !(element instanceof Text))) return;
		if (copiedStyles.value.type !== element.type) {
			clearCopiedStyles();
			return;
		}

		if (element instanceof Box) {
			pasteBoxStyles(element);
			return;
		}

		if (element instanceof Text) {
			pasteTextStyles(element);
		}
	};

	const pasteBoxStyles = (element: Box) => {
		if (!('border' in copiedStyles.value) || !copiedStyles.value.border) return;

		// Check if entry radius is higher than max value
		tempRefBox.value = element;
		const fixedBorder = copiedStyles.value.border.radius.map((r) => {
			return MathTools.clamp((r / 100) * maxValue.value, 0, maxValue.value);
		}) as BorderRadiusType;

		Object.assign(element, { ...copiedStyles.value, border: { ...copiedStyles.value.border, radius: fixedBorder } });
	};

	const pasteTextStyles = (element: Text) => {
		const domNode = element.domNode();
		if (!domNode) return;
		domNode.querySelectorAll('[style]:not(.text-element-final)').forEach((node) => node.removeAttribute('style'));
		Object.assign(element, copiedStyles.value);
	};

	return {
		copiedStyles,
		clearCopiedStyles,
		copyStyles,
		pasteStyles,
	};
});
