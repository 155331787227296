<script lang="ts" setup>
import { useMutationObserver } from '@vueuse/core';
import { nextTick, ref, watch } from 'vue';

import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';

const { moveable } = useInteractions();
const { isPinching, scaleAsPercentage, onZoomFinish } = useZoom();
const store = useMainStore();
const { selectionId } = useSelection();

const portalTarget = ref();
const portalTargetCrop = ref();

watch(scaleAsPercentage, async () => {
	await nextTick();
	if (portalTarget.value) {
		portalTarget.value.style.display = 'none';
	}
	if (portalTargetCrop.value) {
		portalTargetCrop.value.style.display = 'none';
	}
});

// Moveable en grupos hace un flasheo al montarse, para evitarlo solo le ponemos el display block cuando ya tiene
// transform(está desplazado a la posición del elemento)
useMutationObserver(
	portalTarget,
	() => {
		if (selectionId.value.length <= 1) {
			return;
		}
		const transform = portalTarget.value.style.transform;
		if (!transform || transform === 'translate3d(0px, 0px, 0px)') {
			portalTarget.value.style.display = 'none';
		} else {
			portalTarget.value.style.display = 'block';
		}
	},
	{ attributes: true, attributeFilter: ['style'] }
);

onZoomFinish(() => {
	moveable.value?.updateTarget();
	if (portalTarget.value) {
		portalTarget.value.style.display = 'block';
	}
	if (portalTargetCrop.value) {
		portalTargetCrop.value.style.display = 'block';
	}
});
</script>

<template>
	<div v-show="!isPinching" id="portalTarget" ref="portalTarget" class="absolute"></div>
	<div id="portalTargetCrop" ref="portalTargetCrop" class="absolute"></div>
	<div id="portalTargetSupport" class="absolute"></div>
	<div id="portalTargetCustomHandlers" class="absolute z-50"></div>
	<div id="portalTargetHandlerLine1" class="absolute"></div>
	<div id="portalTargetHandlerLine2" class="absolute"></div>
</template>

<style scoped></style>
