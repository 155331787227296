<script lang="ts" setup>
import { computed } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useProjectColors } from '@/color/composables/useProjectColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ palette: any }>();
const emit = defineEmits(['paletteSelected']);
const { updateTemplateColorById } = useProjectColors();
const { isMobile } = useDeviceInfo();
const { trans } = useI18n();

const colorsPalette = computed(() => {
	return props.palette.color_palette.slice(0, 4);
});

const applyVariant = () => {
	props.palette.color_palette.forEach((colorCollection: any) => {
		colorCollection.ids.forEach((id: string) => updateTemplateColorById(id, colorCollection.color));
	});

	GAnalytics.track('Variants', 'click', `set-variant-${props.palette.id}`);

	emit('paletteSelected');
};

const getStyle = (color: SolidColor | GradientColor) => {
	if (color instanceof SolidColor) {
		return {
			backgroundColor: color.toCssStringWithoutAlpha(),
		};
	}

	return {
		background: color.toCssString(),
	};
};
</script>

<template>
	<button data-testid="variant-btn" class="group relative flex h-8 overflow-hidden rounded-md" @click="applyVariant">
		<span
			v-for="colorCollection in colorsPalette"
			class="h-full flex-1"
			:style="getStyle(colorCollection.color)"
		></span>
		<div
			v-if="!isMobile"
			class="pointer-events-none absolute inset-0 flex items-center justify-center bg-blue-700/75 text-sm font-semibold text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"
		>
			<SvgIcon name="shuffle" class="mr-2 h-4 w-4" /> {{ trans('Apply') }}
		</div>
	</button>
</template>
