<script lang="ts" setup>
import { InputHTMLAttributes, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import ColorPicker from '@/color/components/ColorPicker.vue';
import { useBoxColors } from '@/color/composables/useBoxColors';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { Box } from '@/elements/box/classes/Box';
import { useBoxStroke } from '@/elements/box/composables/useBoxStroke';
import { TypeBorder } from '@/Types/types';
import MathTools from '@/utils/classes/MathTools';

// Props
const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');

// Constants
const INPUT_CONFIG = {
	MAX: 100,
	MIN: 0,
	STEP: 1,
	TYPE: 'range',
};

// Use composables
const { updateColor } = useBoxColors(element);
const { percentCalculated, setSizeByPercent, updateTypeBorder } = useBoxStroke(element);
const { t: trans } = useI18n();

// Methods
const onInput = (e: Event) => {
	const newPercent = MathTools.clamp((e.target as InputHTMLAttributes).value, INPUT_CONFIG.MIN, INPUT_CONFIG.MAX);
	setSizeByPercent(newPercent);
};
</script>

<template>
	<div class="my-6">
		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Border') }}</h4>
		<div class="flex items-center gap-4">
			<ColorPicker
				class="h-10 w-10 shrink-0"
				:color="element.border.color"
				@change="updateColor(element.border.color, $event)"
			/>
			<div class="flex w-full items-end gap-2">
				<div class="relative flex flex-1 flex-col">
					<label class="mb-2 text-xs text-gray-100">
						{{ trans('Border weight') }}
					</label>
					<input
						data-testid="border-box-input"
						class="input-range mb-2 h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none"
						:max="INPUT_CONFIG.MAX"
						:min="INPUT_CONFIG.MIN"
						:step="INPUT_CONFIG.STEP"
						:type="INPUT_CONFIG.TYPE"
						:value="percentCalculated"
						@input="onInput"
					/>

					<span
						class="pointer-events-none absolute bottom-2 left-0 h-[2px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
						:style="{
							width: `${percentCalculated}%`,
						}"
					/>
				</div>

				<span class="w-12 text-center text-sm text-gray-100">{{ percentCalculated }}</span>
			</div>
		</div>
		<div class="button-group mt-4 flex flex-1 gap-px">
			<button
				data-testid="remove-border-btn"
				class="flex h-8 w-full items-center justify-center rounded-bl rounded-tl px-3"
				:class="{
					'bg-gray-600 text-white': element.border.type === TypeBorder.None,
					'bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white': element.border.type !== TypeBorder.None,
				}"
				@click="updateTypeBorder(TypeBorder.None)"
			>
				<SvgIcon name="empty" class="h-4 w-4" />
			</button>
			<button
				data-testid="solid-border-btn"
				class="flex h-8 w-full items-center justify-center px-3"
				:class="{
					'bg-gray-600 text-white': element.border.type === TypeBorder.Solid,
					'bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white': element.border.type !== TypeBorder.Solid,
				}"
				@click="updateTypeBorder(TypeBorder.Solid)"
			>
				<SvgIcon name="border-dasharray-0" class="h-6 w-6" />
			</button>
			<button
				data-testid="dashed-border-btn"
				class="flex h-8 w-full items-center justify-center px-3"
				:class="{
					'bg-gray-600 text-white': element.border.type === TypeBorder.Dashed,
					'bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white': element.border.type !== TypeBorder.Dashed,
				}"
				@click="updateTypeBorder(TypeBorder.Dashed)"
			>
				<SvgIcon name="border-dasharray-2" class="h-6 w-6" />
			</button>
			<button
				data-testid="dotted-border-btn"
				class="flex h-8 w-full items-center justify-center rounded-br rounded-tr px-3"
				:class="{
					'bg-gray-600 text-white': element.border.type === TypeBorder.Dotted,
					'bg-gray-700 text-gray-100 hover:bg-gray-600 hover:text-white': element.border.type !== TypeBorder.Dotted,
				}"
				@click="updateTypeBorder(TypeBorder.Dotted)"
			>
				<SvgIcon name="border-dasharray-3" class="h-6 w-6" />
			</button>
		</div>
	</div>
</template>
