<script lang="ts" setup>
import { promiseTimeout, useFullscreen, useScroll, useWindowSize } from '@vueuse/core';
import { computed, InputHTMLAttributes, nextTick, onMounted, onUnmounted, ref, toRef, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useViewer } from '@/editor/composables/useViewer';
import { useOrderedKeyboardListener } from '@/interactions/composables/useOrderedKeyboardListener';
import CanvasFit from '@/page/components/CanvasFit.vue';
import Project from '@/project/classes/Project';
import { useProjectControls } from '@/project/composables/useProjectControls';

const props = defineProps<{
	project: Project;
	loaded?: boolean;
	fullscreen?: boolean;
	page: number;
	videoPlayerMode?: boolean;
}>();

const project = toRef(props, 'project');

const container = ref();
const widthToSum = ref(0);
const activePage = toRef(props, 'page');
const mounted = ref(false);

const { isTouch, isWebview, isIOS, isMobile } = useDeviceInfo();
const { x: scrollX } = useScroll(container);
const { isFullscreen, isSupported, toggle: toggleFullscreen } = useFullscreen(container);
const { width: windowWidth } = useWindowSize();
const { isRenderingContext, isViewerContext } = useEditorMode();

// Computeds
const totalPages = computed(() => project.value.pages.length);
const activePageTouch = computed(() => Math.round(scrollX.value / widthToSum.value) + 1);
const activePageToShow = computed(() => {
	if (isTouch.value) {
		return activePageTouch.value;
	}

	return activePage.value + 1;
});

// Lifehooks
onMounted(async () => {
	widthToSum.value = container.value.offsetWidth;
	container.value.scrollLeft = widthToSum.value * activePage.value;
	const prevScreenHeight = screen.height;
	if (props.fullscreen) {
		await promiseTimeout(0);
		toggleVideo();
	}

	mounted.value = true;

	// corregimos la altura del container en android
	if (isMobile.value && !isIOS.value && !isWebview.value) container.value.style.height = `${prevScreenHeight}px`;
});

const { trigger: dispatchViewerChange } = useViewer();
watch(activePage, async (newActive) => {
	container.value.scrollLeft = widthToSum.value * newActive;
	if (currentTime.value === 0 && !isRenderingContext) return;
	// Le damos 200ms, hasta que el scroll se recalcule
	// en los artboards pequeños puede tardar un poco
	await promiseTimeout(200);
	dispatchViewerChange(props.project.pages[newActive]);
});

const {
	currentTime,
	duration,
	pause,
	play,
	isPlaying,
	currentTimePercentage,
	currentTimeInMMSSFormat,
	durationInMMSSFormat,
	pagesDuration,
	updateTime,
} = useProjectControls();

const startTimeActivePage = computed(() => {
	const prevPagesDuration = pagesDuration.value.slice(0, activePage.value).reduce((acc, time) => acc + time, 0);

	return prevPagesDuration;
});

const endTimeActivePage = computed(() => {
	const prevPagesDuration = pagesDuration.value.slice(0, activePage.value).reduce((acc, time) => acc + time, 0);
	const activePageDurationInMs = props.project.pages[activePage.value].duration();

	return prevPagesDuration + activePageDurationInMs;
});

onUnmounted(() => {
	currentTime.value = 0;
	dispatchViewerChange(props.project.pages[activePage.value]);
});

watch(currentTime, () => {
	if (!props.videoPlayerMode) return;
	// Si la reproducción está en el rango de la página activa no hacemos nada
	if (startTimeActivePage.value < currentTime.value && currentTime.value < endTimeActivePage.value) {
		return;
	}
	// Si la reproducción a pasado el tiempo máximo de la página activa, pasamos a la siguiente
	if (currentTime.value > endTimeActivePage.value) {
		nextSlide();
	}
	// Si la reproducción es inferior al tiempo de inicio de la página activa, volvemos a la anterior
	if (currentTime.value < startTimeActivePage.value) {
		prevSlide();
	}
	// Si ha pasado la duración total de la plantilla, reiniciamos
	if (duration.value <= currentTime.value) {
		dispatchEvent(new CustomEvent('stop-record'));
		pause();
		currentTime.value = 0;
		emit('activePage', 0);
	}
});

pause();

const toggleVideo = () => {
	isPlaying.value ? pause() : play();
	dispatchViewerChange(props.project.pages[activePage.value]);
};

// Si hace resize a la pantalla actualizamos el valor del width
watch(windowWidth, () => (widthToSum.value = container.value.offsetWidth));
const { listen } = useOrderedKeyboardListener();

listen(['ArrowLeft'], (e) => {
	if (props.videoPlayerMode) return;
	e.preventDefault();
	prevSlide();
});
listen(['ArrowRight'], (e) => {
	if (props.videoPlayerMode) return;
	e.preventDefault();
	nextSlide();
});

// Methods
const closeFullscreen = () => {
	// Fix para el webview de android
	if (isWebview.value && !isIOS.value) {
		document.exitFullscreen();
	}

	emit('fullscreenExit');
};

const prevSlide = () => {
	if (activePage.value === 0) return;
	emit('activePage', activePage.value - 1);
};

const nextSlide = () => {
	if (activePage.value === totalPages.value - 1) return;
	emit('activePage', activePage.value + 1);
};

const emit = defineEmits(['activePage', 'fullscreenExit']);

watch(
	() => props.fullscreen,
	() => {
		if (!isSupported.value) return;
		toggleFullscreen();
	},
	{ immediate: !isViewerContext }
);

watch(isFullscreen, () => {
	if (!isFullscreen.value) closeFullscreen();
});

const handleInputTime = async (e: InputEvent) => {
	let shouldPlay = false;
	if (isPlaying.value) {
		toggleVideo();
		shouldPlay = true;
	}
	const target = e.target as InputHTMLAttributes;
	const percentage = parseFloat(target.value);

	const value = (percentage / 100) * duration.value;

	updateTime(value);
	await nextTick();
	if (shouldPlay) toggleVideo();
};
</script>

<template>
	<div
		ref="container"
		class="flex h-[calc(100vh-6rem)] w-screen snap-x snap-mandatory"
		:class="{
			'scroll-smooth': !isTouch && !videoPlayerMode && mounted,
			'm-auto': !isMobile,
			'scrollbar-fullscreen fixed inset-0 z-50 overflow-auto bg-gray-900': isFullscreen,
			'overflow-hidden': !isFullscreen,
		}"
	>
		<div
			v-if="!isRenderingContext && videoPlayerMode"
			class="absolute z-10 flex items-center justify-center text-white"
		>
			<div class="fixed inset-0 flex items-center justify-center" @click="toggleVideo"></div>
			<div
				class="fixed left-0 flex h-12 w-full items-center gap-6 bg-gradient-to-t from-black/50 px-6 pb-4"
				:class="isViewerContext && !isFullscreen ? 'bottom-14' : 'bottom-0'"
			>
				<button class="text-white" @click="toggleVideo">
					<SvgIcon :name="isPlaying ? 'pause' : 'play'" class="h-4 w-4" />
				</button>
				<div class="relative h-1 flex-1">
					<div class="relative flex w-full">
						<input
							type="range"
							class="input-range z-10 w-full cursor-pointer appearance-none rounded-full bg-white/50 focus:outline-none sm:h-[2px]"
							style="height: 4px"
							:value="currentTimePercentage"
							@input="handleInputTime"
						/>
						<span
							class="absolute left-0 top-0 block h-full rounded-full bg-white"
							:style="{ width: `${currentTimePercentage}%` }"
						></span>
						<span
							class="absolute -top-1 left-0 block h-3 w-3 rounded-full bg-white"
							:style="{ left: `${currentTimePercentage}%` }"
						></span>
					</div>
				</div>
				<div class="flex w-26 items-center text-white">
					<span class="w-16 text-center">{{ currentTimeInMMSSFormat }}</span>
					/
					<span class="w-16 text-center">{{ durationInMMSSFormat }}</span>
				</div>
				<button
					v-if="!isViewerContext || (isViewerContext && isFullscreen)"
					class="text-white"
					@click="closeFullscreen"
				>
					<SvgIcon name="fullscreen-invert" class="h-4 w-4" />
				</button>
			</div>
		</div>

		<div
			v-for="p in project.pages"
			:key="p.id"
			class="mr-2 flex h-full w-full shrink-0 snap-center"
			:class="{
				'opacity-0': loaded === false && !isRenderingContext,
				'transition-opacity duration-75': !isRenderingContext,
			}"
		>
			<CanvasFit
				:page="p"
				:lazy-render="100"
				:scroll-area="container"
				:preview-name="'viewer'"
				class="h-full w-full"
				data-context="viewer"
			/>
		</div>

		<template v-if="!isRenderingContext">
			<button
				v-if="isWebview && !isIOS"
				class="fixed right-0 top-0 z-20 h-14 w-14 items-start justify-end text-gray-600 hover:text-gray-100 lg:flex"
				@click="closeFullscreen"
			>
				<span data-testid="close-btn-viewer" class="rounded-full bg-realblack/75">
					<SvgIcon name="close-modal" class="h-full w-full rotate-90 transform fill-current px-2 pb-2 pt-3" />
				</span>
			</button>

			<!-- Prev page button -->
			<button
				v-show="activePageToShow > 1 && !videoPlayerMode"
				class="fixed bottom-1/2 left-0 z-10 flex h-1/6 w-1/6 translate-y-1/2 justify-start text-gray-600 opacity-0 focus:outline-none hover:text-gray-100 lg:top-1/2 lg:h-fit lg:w-1/12 lg:opacity-100"
				:class="fullscreen ? 'opacity-0' : ''"
				@click="prevSlide"
			>
				<span data-testid="back-btn-viewer" class="ml-8 h-16 w-16 rounded-full bg-realblack/75">
					<SvgIcon name="arrow" class="h-full w-full rotate-90 transform fill-current px-2 pb-2 pt-3" />
				</span>
			</button>

			<!-- Next page button -->
			<button
				v-show="activePageToShow !== totalPages && !videoPlayerMode"
				class="fixed bottom-1/2 right-0 z-10 flex h-1/6 w-1/6 translate-y-1/2 justify-end text-gray-600 opacity-0 focus:outline-none hover:text-gray-100 lg:top-1/2 lg:h-fit lg:w-1/12 lg:opacity-100"
				:class="fullscreen ? 'opacity-0' : ''"
				@click="nextSlide"
			>
				<span data-testid="next-btn-viewer" class="mr-8 h-16 w-16 rounded-full bg-realblack/75">
					<SvgIcon name="arrow" class="h-full w-full -rotate-90 transform fill-current px-2 pb-2 pt-3" />
				</span>
			</button>
		</template>
		<!-- Close fullscreen button -->
	</div>
	<slot></slot>
</template>
<style scoped>
.scrollbar-fullscreen::-webkit-scrollbar {
	@apply hidden;
}
.input-range::-webkit-slider-thumb {
	@apply opacity-0;
}

.input-range::-moz-range-thumb {
	@apply opacity-0 shadow-custom;
}
</style>
