<script setup lang="ts">
import { computed } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import TextTools from '@/elements/texts/text/utils/TextTools';
import PublishFacebookModal from '@/export/share/components/PublishFacebookModal.vue';
import PublishInstagramModal from '@/export/share/components/PublishInstagramModal.vue';
import PublishPinterestModal from '@/export/share/components/PublishPinterestModal.vue';
import PublishTwitterModal from '@/export/share/components/PublishTwitterModal.vue';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import { ShareModalType } from '@/Types/types';

const { trans } = useI18n();

const emits = defineEmits(['close', 'goback', 'goToSchedule']);

const store = useMainStore();
const {
	errors,
	canPublishContent,
	rrssProfilePages,
	socialMediaModal,
	validateShareInputs,
	shareInTwitter,
	shareInFacebook,
	shareInInstagram,
	shareInPinterest,
} = useSharePublish();

const disablePublishButton = computed(
	() =>
		!(
			(!rrssProfilePages.value && socialMediaModal.value === ShareModalType.facebook) ||
			(!rrssProfilePages.value && socialMediaModal.value === ShareModalType.instagram)
		)
);
const publishNowTitle = computed(() =>
	socialMediaModal.value ? TextTools.capitalizeFirstLetter(socialMediaModal.value) : 'Social Media'
);

const onClose = () => {
	errors.value = [];
	emits('close');
};
const onClickBackStep = () => {
	errors.value = [];
	emits('goback');
};

const closeModal = () => {
	if (store.sharing) return;
	errors.value = [];
	emits('close');
};

const shareSocialMedia = () => {
	switch (socialMediaModal.value) {
		case ShareModalType.facebook:
			shareInFacebook();
			break;
		case ShareModalType.twitter:
			shareInTwitter();
			break;
		case ShareModalType.instagram:
			shareInInstagram();
			break;
		case ShareModalType.pinterest:
			shareInPinterest();
			break;
	}

	closeModal();
};

const goToSchedule = () => {
	emits('goToSchedule');
};

const onClickPublish = () => {
	if (canPublishContent.value) {
		shareSocialMedia();
	} else {
		validateShareInputs();
	}
};
</script>

<template>
	<div>
		<div class="mb-3 flex w-full items-center justify-between gap-1">
			<div class="flex items-center gap-1">
				<button @click="onClickBackStep"><SvgIcon name="arrow" class="h-5 w-5 rotate-90" /></button>
				<p class="flex items-center gap-2 text-lg font-semibold text-gray-700">
					{{ trans(`Post on ${TextTools.capitalizeFirstLetter(publishNowTitle)}`) }}
				</p>
			</div>
		</div>
		<div class="mb-4 flex w-full items-center justify-between text-gray-800">
			<span class="flex items-center gap-2 text-sm">
				<SvgIcon name="publish" class="h-4 w-4" />
				{{ trans('Today, right now') }}
			</span>
			<button
				class="flex h-8 items-center gap-2 rounded bg-gray-100/20 px-3 text-sm text-gray-600 hover:bg-gray-100/50 hover:text-gray-800"
				@click="goToSchedule"
			>
				<SvgIcon name="calendar" class="h-4 w-4" /> {{ trans('Schedule it') }}
			</button>
		</div>
		<PublishPinterestModal v-if="socialMediaModal === ShareModalType.pinterest" />
		<PublishTwitterModal v-else-if="socialMediaModal === ShareModalType.twitter" />
		<PublishFacebookModal v-else-if="socialMediaModal === ShareModalType.facebook" @close="onClose" />
		<PublishInstagramModal v-else-if="socialMediaModal === ShareModalType.instagram" @close="onClose" />
		<div v-if="disablePublishButton" class="w-full">
			<button
				class="mt-4 w-full rounded py-3 px-6 font-semibold text-white"
				:class="{
					'bg-blue-500 hover:bg-blue-600 slidesgo:bg-purple-400': !store.sharing && canPublishContent,
					'pointer-events-none bg-blue-600': store.sharing && canPublishContent,
					' bg-gray-400/50 text-gray-600': !canPublishContent,
				}"
				@click="onClickPublish"
			>
				<span v-if="!store.sharing">{{ trans('Publish') }}</span>
				<span v-else class="flex items-center justify-center">
					<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin" />
					{{ trans('Publishing') }}
				</span>
			</button>
		</div>
	</div>
</template>
<style lang="sass"></style>
