<script lang="ts" setup>
import { computed, ComputedRef } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import { useForeground } from '@/elements/medias/images/foreground/composables/useForeground';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useI18n } from '@/i18n/useI18n';
import { useSelection } from '@/interactions/composables/useSelection';
import { EditPanels } from '@/Types/types';

const { isMobile } = useDeviceInfo();
const store = useMainStore();
const { selection, setSelection } = useSelection();

const element = computed(() => selection.value[0]);

const { foreground } = useLayersImage(element as ComputedRef<Image | ForegroundImage>);
const { imageBackground } = useForeground(element as ComputedRef<ForegroundImage>);
const { trans } = useI18n();

const selectBackground = () => {
	if (!isForeground.value) {
		return;
	}

	setSelection(imageBackground.value);
};

const isForeground = computed(() => element.value instanceof ForegroundImage);

const selectForeground = () => {
	if (isForeground.value && foreground.value) {
		return;
	}

	if (foreground.value) setSelection(foreground.value);
};

const presetPanelToggle = () => {
	if (store.editPanel === EditPanels.ImagePresets) {
		store.editPanel = isMobile.value ? EditPanels.ImageLayerSelector : null;
		return;
	}

	store.editPanel = EditPanels.ImagePresets;
};
</script>

<template>
	<div class="image-layers">
		<div class="mx-4 mb-4 flex h-8 items-center overflow-hidden rounded-lg border border-gray-600 lg:hidden">
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="{
					'bg-gray-100 text-gray-800': !isForeground,
					'text-gray-300': isForeground,
				}"
				@click="selectBackground"
			>
				{{ trans('Background') }}
			</button>
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase"
				:class="{
					'opacity-50': !isForeground && element.opacity === 0,
					'text-gray-300': !isForeground,
					'bg-gray-100 text-gray-800': isForeground,
				}"
				:disabled="!isForeground && element.opacity === 0"
				@click="selectForeground"
			>
				{{ trans('Foreground') }}
			</button>
			<button
				class="h-8 flex-1 border-r border-gray-600 px-2 text-xs font-bold uppercase text-gray-300"
				@click="presetPanelToggle"
			>
				{{ trans('Presets') }}
			</button>
		</div>
	</div>
</template>

<style scoped></style>
