<script setup lang="ts">
import { computed, watch } from 'vue';

import PagesListbox from '@/common/components/PagesListbox.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import PinterestBoardsInput from '@/export/share/components/PinterestBoardsInput.vue';
import { useSharePublish } from '@/export/share/composables/useSharePublish';
import { useI18n } from '@/i18n/useI18n';
import Page from '@/page/classes/Page';

const { trans } = useI18n();

const { errors, title, message, socialMediaPagesSelected, website } = useSharePublish();
const { selectedPages } = useDownloadsProject();

const onInputTitle = (e: Event) => {
	if (e.target instanceof HTMLInputElement) {
		title.value = e.target.value;
	}
};
const onInputWebsite = (e: Event) => {
	if (e.target instanceof HTMLInputElement) {
		website.value = e.target.value;
	}
};
const onInputMessage = (e: Event) => {
	if (e.target instanceof HTMLTextAreaElement) {
		message.value = e.target.value;
	}
};
const onSelectPinterestBoard = (pinterestBoard: string | null) => {
	socialMediaPagesSelected.value = pinterestBoard;
};

const pagesListError = computed(() => {
	return !!errors.value.find((error) => error.id === 2);
});

const pinterestBoardError = computed(() => {
	return !!errors.value.find((error) => error.id === 1);
});

watch(socialMediaPagesSelected, (value: string | null) => {
	if (value && pinterestBoardError) {
		const index = errors.value.findIndex((error) => error.id === 1);
		errors.value.splice(index);
	}
});

watch(selectedPages, (value: Page[]) => {
	if (value.length && pagesListError) {
		const index = errors.value.findIndex((error) => error.id === 2);
		errors.value.splice(index);
	}
});
</script>

<template>
	<div class="flex flex-col">
		<div class="flex w-full flex-col gap-4">
			<div class="flex flex-col gap-4 lg:flex-row">
				<div class="w-full">
					<PinterestBoardsInput
						:class="{ 'pinterestboard-error': pinterestBoardError }"
						@select="onSelectPinterestBoard"
					/>
				</div>
				<div class="relative w-full">
					<PagesListbox :show-error="pagesListError" />
				</div>
			</div>
			<div class="relative">
				<input
					:value="title"
					type="text"
					placeholder="Title"
					class="pii-cs h-10 w-full resize-none rounded border border-gray-200 px-4 text-sm focus:border-gray-400 focus:outline-none"
					@input="onInputTitle"
				/>
			</div>
			<div class="relative flex flex-col">
				<textarea
					:value="message"
					maxlength="500"
					placeholder="What do you want to tell the world?"
					class="pii-cs h-52 w-full resize-none rounded border border-gray-200 px-4 pb-6 pt-3 text-sm text-gray-700 placeholder:text-gray-100 focus:border-gray-400 focus:outline-none"
					@input="onInputMessage"
				></textarea>
				<span class="absolute bottom-1 right-1 bg-white px-2 py-1 text-xs text-gray-100"
					>({{ message.length }}/500)
				</span>
			</div>
			<div class="relative">
				<input
					:value="website"
					type="text"
					:placeholder="trans('Website url (example: https://wepik.com)')"
					class="pii-cs h-10 w-full resize-none rounded border border-gray-200 px-4 text-sm focus:border-gray-400 focus:outline-none"
					@input="onInputWebsite"
				/>
			</div>

			<div v-for="(error, i) in errors" :key="`${i}${error}`" class="mb-4">
				<p class="text-sm font-semibold text-red-500">- {{ trans(error.description) }}</p>
			</div>
		</div>
	</div>
</template>
<style lang="sass">
.pinterestboard-error.multiselect
	@apply border-red-400
</style>
