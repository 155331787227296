<script lang="ts" setup>
import { InputHTMLAttributes, onBeforeMount, toRef } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import NumberInput from '@/common/components/NumberInput.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import { Text } from '@/elements/texts/text/classes/Text';
import { useTextEffects } from '@/elements/texts/text/composables/useTextEffects';
import { useTextStyles } from '@/elements/texts/text/composables/useTextStyles';
import { useI18n } from '@/i18n/useI18n';
import { EditPanels } from '@/Types/types';

const props = defineProps<{ element: Text }>();
const { isMobile } = useDeviceInfo();
const element = toRef(props, 'element');
const store = useMainStore();
const { hasOutline } = useTextEffects(element);
const { trans } = useI18n();
const { outline, updateBorderColor, updateBorderWidth } = useTextStyles(element);
const { closePanel } = usePanelManagement();

onBeforeMount(() => {
	if (!hasOutline.value) updateBorderSize(element.value.fontSize / 10);
});

const updateColor = (color: SolidColor): void => {
	// Trackeamos si ha cambiado la opacidad del borde
	if (
		color instanceof SolidColor &&
		color.r === element.value.outline.color.r &&
		color.g === element.value.outline.color.g &&
		color.b === element.value.outline.color.b &&
		color.a !== element.value.outline.color.a
	) {
		GAnalytics.track('click', 'Button', `change-stroke-opacity`, null);
	}

	// Trackeamos si ha cambiado el color
	if (color.toCssString() !== element.value.outline.color.toCssString()) {
		GAnalytics.track('click', 'Button', `change-stroke-color`, null);
	}
	updateBorderColor(color);
};

const updateBorderSize = (width: number) => {
	updateBorderWidth(width);
	GAnalytics.track('click', 'Button', `change-stroke-width`, null);
};
const onReset = () => {
	store.editPanel = EditPanels.Text;
	element.value.outline.width = 0;
};
</script>

<template>
	<div
		ref="textShadowContainer"
		:class="{
			'fixed bottom-16 left-0 z-10 w-full rounded-tl-2xl rounded-tr-2xl bg-gray-700 px-4 py-6 text-left': isMobile,
		}"
		data-testid="text-outline-panel"
	>
		<div v-if="isMobile" class="-mt-3 mb-4 flex justify-between">
			<div class="flex items-center">
				<h2 class="mr-4 text-sm font-bold uppercase text-gray-100">{{ trans('Text outline') }}</h2>
				<button class="flex items-center text-xs text-gray-100 hover:text-white" @click="onReset">
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					{{ trans('Reset') }}
				</button>
			</div>
			<button
				data-testid="close-panel"
				class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
				@click="closePanel(element)"
			>
				<SvgIcon name="cross" class="h-3 w-3" />
			</button>
		</div>
		<div class="flex items-end gap-4 lg:pt-4">
			<ColorPicker
				class="h-10 w-10 shrink-0"
				:color="outline.color"
				:hide-gradient="true"
				@change="(newColor) => updateColor(newColor as SolidColor)"
			/>
			<div class="flex w-full items-center gap-2">
				<div class="relative flex flex-1">
					<label class="absolute -top-7 text-xs text-gray-100">{{ trans('Width') }}</label>
					<input
						:value="outline.width"
						class="input-range h-[2px] w-full appearance-none rounded-full bg-gray-900 focus:outline-none mockup:bg-fp-gray-200"
						:min="0"
						:step="1"
						type="range"
						@input="updateBorderSize(parseInt(($event.target as InputHTMLAttributes).value))"
					/>
					<span
						class="pointer-events-none absolute left-0 top-0 h-[2px] rounded-full bg-blue-500 slidesgo:bg-purple-400"
						data-testid="opacityInput"
						:style="{
							width: `${outline.width}%`,
						}"
					/>
				</div>
				<NumberInput
					class="h-6 w-12 text-xs"
					data-testid="text-border"
					:min="0"
					:step="1"
					:value="outline.width"
					@update="updateBorderSize"
				/>
			</div>
		</div>
	</div>
</template>
