import { Dom, Element as SVGJSElement, SVG, Svg } from '@svgdotjs/svg.js';
import Normalize from 'color-normalize';
import { v4 as uuidv4 } from 'uuid';
import { ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import { useZoom } from '@/editor/composables/useZoom';
import { useMainStore } from '@/editor/stores/store';
import { useCroppeableTransform } from '@/elements/medias/crop/composables/useCroppeableTransform';
import Image from '@/elements/medias/images/image/classes/Image';
import ImageTools from '@/elements/medias/images/image/utils/ImageTools';
import { Shape } from '@/elements/shapes/shape/classes/Shape';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import FontFamilyVariantsTools from '@/elements/texts/text/utils/FontFamilyVariantsTools';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useHistoryStore } from '@/history/stores/history';
import { SlidesgoImageParser } from '@/loader/slidesgo/SlidesgoImageParser';
import { SlidesgoLineParser } from '@/loader/slidesgo/SlidesgoLineParser';
import { SlidesgoShapeParser } from '@/loader/slidesgo/SlidesgoShapeParser';
import {
	ElementsToAdd,
	ParsedElementClass,
	PPTXData,
	SlideEntry,
	SlideGradients,
} from '@/loader/slidesgo/slidesgoTemplateLoaderTypes';
import { SlidesgoTextParser } from '@/loader/slidesgo/SlidesgoTextParser';
import { TemplateLoaderData } from '@/loader/types/templateLoaderData';
import TemplateLoader from '@/loader/utils/TemplateLoader';
import Page from '@/page/classes/Page';
import { usePage } from '@/page/composables/usePage';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';
import { Color, StopGradient } from '@/Types/colorsTypes';
import { LoadFont } from '@/Types/types';

export class SlidesgoTemplateLoader {
	svg: Svg | undefined;
	svgScale: number;
	pptxData: PPTXData;
	slidesData: SlideEntry[];
	current: SlideEntry[];
	pending: SlideEntry[];
	isFirstLoad: boolean;
	chunkIndex: number;
	debug: boolean;

	constructor() {
		this.svg = undefined;
		this.svgScale = 1;
		this.pptxData = {} as PPTXData;
		this.slidesData = [];
		this.current = [];
		this.pending = [];
		this.isFirstLoad = true;
		this.chunkIndex = 0;
		this.debug = false; // Set debug mode here!
	}

	/**
	 * Main flow functions
	 */

	async parseMonolithicSvg(rawSvg: string, templateData: TemplateLoaderData) {
		if (this.debug) console.log(rawSvg);

		this.svg = SVG(rawSvg).addTo(document.body) as Svg;
		const viewbox = this.svg.viewbox();
		this.svg.size(viewbox.width, viewbox.height);
		this.svg.attr('class', this.debug ? 'absolute inset-0 z-50' : 'absolute inset-0 opacity-0 -z-50');

		const pageSvgs = this.svg.find('[id^="container-id"]');
		const indexToStartIgnoring = pageSvgs.findIndex((pageSvg) =>
			pageSvg.findOne('.TitleText')?.node.textContent?.includes('Instructions for use')
		);
		const usedPages = indexToStartIgnoring >= 0 ? pageSvgs.slice(0, indexToStartIgnoring) : pageSvgs;
		const defaultPages = usedPages.map(() => Page.create({ name: 'temporal' }));
		const { addPages } = useProject();
		addPages(defaultPages);

		const hasArtboardSize = !!(templateData.artboard?.width && templateData.artboard.height);

		if (!hasArtboardSize) {
			// Si el artboard no tiene ni width ni height usamos el viewbox del svg
			const ratio = viewbox.width / viewbox.height;
			templateData.artboard.height = viewbox.height;
			templateData.artboard.width = viewbox.width;

			// Comprobamos si el ratio es el de una presentación 16:9
			if (ratio.toFixed(2) === (16 / 9).toFixed(2)) {
				templateData.artboard.width = 1920;
				templateData.artboard.height = 1080;
				templateData.artboard.unit = 'px';
			}

			// Comprobamos si el ratio es el de una presentación 4:3
			if (ratio.toFixed(2) === (4 / 3).toFixed(2)) {
				templateData.artboard.width = 1440;
				templateData.artboard.height = 1080;
				templateData.artboard.unit = 'px';
			}
		}

		// Comprobamos si se respeta el landscape si no pues lo forzamos
		TemplateLoader.fixArtboardLandscape(viewbox, templateData);

		// Si tiene artboard calculamos la escala del svg
		this.svgScale = TemplateLoader.getArtboardScale(viewbox.width, templateData);

		// Load fonts
		await this.loadFontsFromSvg();

		// Get PPTX data
		this.pptxData = (this.svg.findOne('[id="payload"]')?.data('pptx-data') as PPTXData) || {};
		if (this.debug) console.log(this.pptxData);

		// Get pages dictionary
		const metaSlides = this.svg.findOne('[id="ooo-meta_slides"]');
		metaSlides?.children().forEach((metaSlide) => {
			if (!metaSlide.id().startsWith('ooo-meta_slide_')) return;
			const slide = metaSlide.attr('ooo-slide');
			const master = metaSlide.attr('ooo-master');
			const textIds =
				this.svg?.findOne(`.TextShapeIndex > g[ooo-slide="${slide}"]`)?.attr('ooo-id-list').split(' ') || [];
			const entry: SlideEntry = {
				slide,
				master,
				textIds,
			};
			if (!entry.slide || !entry.master) return;
			this.slidesData.push(entry);
		});

		this.pending = this.slidesData.slice(1);
		this.current = this.slidesData.slice(0, 1);

		await this.parseByChunks(templateData);
	}

	private async parseByChunks(templateData: TemplateLoaderData) {
		if (!this.svg) return;

		// Hemos terminado de cargar todas las páginas del proyecto
		if (this.pending.length === 0 && this.current.length === 0) {
			if (this.debug) {
				this.svg.css('width', '60%');
				this.svg.css('height', '60%');
				this.svg.css('background', '#ccc');
				this.svg.find('[visibility="hidden"]').forEach((el) => el.node.removeAttribute('visibility'));
			} else {
				// Remove source svg added previously to be parsed
				this.svg.node.remove();
			}

			// Init history manager
			const history = useHistoryStore();
			history.setInitialState(templateData.forceSync);

			// Set load process as finished
			const store = useMainStore();
			store.finishedLoading = true;
			return;
		}

		// Siguiente tanda de páginas
		if (this.pending.length > 0 && this.current.length === 0) {
			this.current = this.pending.splice(0, 5);
		}

		// Parseo de páginas
		const parsedPages = this.current.map(async (entry) => this.parsePage(entry));

		// Setup project on end parsing
		await Promise.all(parsedPages).then(async (pages) => {
			if (this.isFirstLoad) {
				this.isFirstLoad = false;

				// Set project artboard size
				const { setArtboardSize } = useArtboard();
				const { width, height, unit } = templateData.artboard;
				setArtboardSize(width, height, unit, true);

				// Ajustamos la escala de zoom por defecto
				const { fitZoomScale } = useZoom();
				fitZoomScale();
			}

			// Add page instances to project
			const project = useProjectStore();
			project.$patch(() => {
				pages.forEach((page, i) => {
					if (!page || i + this.chunkIndex >= project.pages.length) return;
					project.pages[i + this.chunkIndex].replace(page);
				});
			});

			// Set as background possible image from master slide
			const temporalRef = ref<ParsedElementClass>(Shape.create());
			project.pages.forEach((page) => {
				if (!page) return;
				page.elements.forEach((element) => {
					temporalRef.value = element;
					if (temporalRef.value instanceof Image && temporalRef.value.metadata.setAsBackground) {
						page.backgroundImageId = temporalRef.value.id;
						const { fitAndCenterInArtboard } = useCroppeableTransform(temporalRef);
						fitAndCenterInArtboard();
					}
				});
			});

			this.current = [];
			this.chunkIndex += pages.length;
			await this.parseByChunks(templateData);
		});
	}

	private async loadFontsFromSvg() {
		if (!this.svg) return;
		const { fonts, loadFontsByName } = useFonts();

		const names = Object.values(fonts.value).map((f) => f.name);
		const weightSuffixes = Object.values(FontFamilyVariantsTools.weights)
			.map((val) => Object.values(val).map((v) => v))
			.flat(2);

		const fontFamilies = [
			...new Set(
				this.svg.find('[font-family]').map((el) => {
					const fontFamilySplit = el.attr('font-family').split(', ');
					let fontName = fontFamilySplit[0]?.replaceAll('"', '') || 'Montserrat';
					const isKnownFont = names.includes(fontName);
					if (!isKnownFont) {
						weightSuffixes.forEach((suffix) => {
							if (fontName.includes(suffix)) {
								fontName = fontName.replace(suffix, `-${suffix}`);
							}
						});
					}
					const fontWeightInName = FontFamilyVariantsTools.findVariantInName(fontName);
					const fontWeight = fontWeightInName?.toString() || el.attr('font-weight') || '400';
					const fontWithoutSuffix = TextTools.getFontNameWithoutSuffixVariant(fontName);
					const fontWithSpaces = TextTools.getFontNameSeparatedByCapitalLetters(fontName);
					const fontWithoutSuffixButWithSpaces = TextTools.getFontNameWithoutSuffixVariant(
						TextTools.getFontNameSeparatedByCapitalLetters(fontName)
					);
					const fontSlug =
						Object.values(fonts.value).find((f) => f.name === fontName)?.slug ||
						Object.values(fonts.value).find((f) => f.name === fontWithoutSuffix)?.slug ||
						Object.values(fonts.value).find((f) => f.name === fontWithSpaces)?.slug ||
						Object.values(fonts.value).find((f) => f.name === fontWithoutSuffixButWithSpaces)?.slug ||
						fontName;

					el.attr('font-family', fontSlug);
					el.attr('font-weight', fontWeight);
					return {
						slug: fontSlug,
						weight: fontWeight,
					};
				})
			),
		];
		// Borramos los duplicados
		const uniqueFontFamilies: LoadFont[] = [];
		fontFamilies.forEach((font) => {
			if (uniqueFontFamilies.find((f) => f.slug === font.slug && f.weight === font.weight)) return;
			uniqueFontFamilies.push(font);
		});
		await loadFontsByName(uniqueFontFamilies.map((f) => f.slug));
	}

	/**
	 * Parsers
	 */

	private parseElement(wrapper: SVGJSElement, gradients: SlideGradients) {
		if (wrapper.attr('visibility') === 'hidden') return;

		// Remove when support Tables
		const isTable = wrapper.classes().some((className) => className.includes('TableShape'));
		if (isTable) return;

		// Identify elements which should not be rendered
		this.identifyTextAsShape(wrapper);

		// Group
		if (wrapper.classes().includes('Group')) {
			return this.parseGroup(wrapper, gradients);
		}

		// Shape + Text
		if (this.isShapeWithText(wrapper)) {
			return this.parseShapesWithTexts(wrapper, gradients);
		}

		// Image
		if (SlidesgoImageParser.isImage(wrapper)) {
			return SlidesgoImageParser.init(wrapper);
		}

		// Line
		if (SlidesgoLineParser.isLine(wrapper)) {
			return SlidesgoLineParser.init(wrapper);
		}

		// Shape
		if (SlidesgoShapeParser.isShape(wrapper)) {
			return SlidesgoShapeParser.init(wrapper, gradients.restOfGradients);
		}

		// Text
		if (SlidesgoTextParser.isText(wrapper)) {
			return SlidesgoTextParser.init(wrapper, gradients.textGradients);
		}
	}

	private parseGroup(wrapper: SVGJSElement, gradients: SlideGradients): Shape | ElementsToAdd | undefined {
		// Actually a single shape formed by multiple shapes
		const children = wrapper.children();
		const isMultipleShape =
			children.every((child) => SlidesgoShapeParser.isShape(child)) &&
			children.every((child) => !this.isShapeWithText(child));
		if (isMultipleShape) return SlidesgoShapeParser.init(children, gradients.restOfGradients);

		// Group formed by shapes and texts
		if (this.isShapeWithText(wrapper)) {
			return this.parseShapesWithTexts(wrapper, gradients);
		}

		const parsed: ElementsToAdd | undefined = wrapper
			.children()
			.map((child) => this.parseElement(child, gradients))
			.filter((el) => el)
			.flatMap((el) => el);

		return parsed.length ? parsed : undefined;
	}

	private parseShapesWithTexts(wrapper: SVGJSElement, gradients: SlideGradients) {
		const elementsToAdd: (Shape | Text)[] = [];

		// Find and parse texts inside wrapper
		const textShapes = wrapper.find('.SVGTextShape, .TextShape');
		if (textShapes.length) {
			textShapes.forEach((text) => {
				if (!text.parent() || !SlidesgoTextParser.isText(text.parent() as SVGJSElement)) return;
				const textInstance = SlidesgoTextParser.init(text.parent() as SVGJSElement, gradients.textGradients);
				if (textInstance) elementsToAdd.push(textInstance);
			});
		}

		// Find and parse custom shapes inside wrapper
		const customShapes = wrapper.classes().includes('Group') ? wrapper.find('g[class$="CustomShape"]') : [wrapper];
		// Texts are parsed before so we ignore them
		const filteredCustomShapes = customShapes.filter(
			(customShape) =>
				!customShape.findOne('.SVGTextShape, .TextShape') ||
				customShape.find('.SVGTextShape, .TextShape').every((text) => !text.node.textContent?.trim().length)
		);
		if (filteredCustomShapes.length) {
			const shapeInstance = SlidesgoShapeParser.init(
				filteredCustomShapes.length > 1 ? filteredCustomShapes : filteredCustomShapes[0],
				gradients.restOfGradients
			);
			// Custom shapes are usually rendered behind texts so we mind z-order
			if (shapeInstance) elementsToAdd.unshift(shapeInstance);
		}

		// Find simple shapes (paths, rects, circles, etc) inside wrapper
		const individualShapes = wrapper
			.find('path, rect:not(.BoundingBox), circle, ellipse, polygon, polyline')
			.filter((shape) => !shape.parent('g[class$="CustomShape"]') && !shape.parent('defs'));
		if (individualShapes.length) {
			const shapeInstance = SlidesgoShapeParser.init(individualShapes, gradients.restOfGradients);
			// Custom shapes are usually rendered behind texts so we mind z-order
			if (shapeInstance) elementsToAdd.unshift(shapeInstance);
		}

		if (elementsToAdd.length) {
			if (elementsToAdd.length === 1) return elementsToAdd;
			const groupId = uuidv4();
			elementsToAdd.forEach((element) => {
				if (!element || !('group' in element)) return;
				element.group = groupId;
			});
			return elementsToAdd;
		}
	}

	private async parseMasterSlide(master: Dom, gradients: SlideGradients, slide: string) {
		if (!this.svg)
			return {
				bgColor: SolidColor.white(),
				bgElements: [],
			};

		const bgElement = master.findOne('.Background > [fill]') as SVGJSElement;
		const bgColor = bgElement ? TemplateLoader.getPageBackgroundColor(this.svg, bgElement.fill()) : SolidColor.white();

		const bgElements: ElementsToAdd = [];
		let elementsToBeAdded: ElementsToAdd = [];

		// Find possible background image
		const slideMetadata = this.pptxData.slides?.find(
			(sld) => sld.master === `ppt/slides/slide${slide.replace('id', '')}.xml`
		);
		const bgImageUrl = slideMetadata?.bg?.image;
		if (bgImageUrl) {
			const size = await ImageTools.getRealImageSize(bgImageUrl);
			const bgImage = Image.create({
				url: bgImageUrl,
				size,
			});
			bgImage.metadata.setAsBackground = true;
			elementsToBeAdded.push(bgImage);
		}

		master.find('.Background use').forEach((use) => {
			const matchedElement = this.parseUse(use, gradients);
			if (Array.isArray(matchedElement)) {
				elementsToBeAdded = [...elementsToBeAdded, ...matchedElement];
			} else {
				elementsToBeAdded.push(matchedElement);
			}
		});

		master.find('.BackgroundObjects > g').forEach((bgObjectWrapper) => {
			const matchedElement = this.parseElement(bgObjectWrapper, gradients);
			if (Array.isArray(matchedElement)) {
				elementsToBeAdded = [...elementsToBeAdded, ...matchedElement];
			} else {
				elementsToBeAdded.push(matchedElement);
			}
		});

		await Promise.all(elementsToBeAdded).then((els) => {
			els.forEach((el) => {
				if (!el) return;
				const isImageAsBackground =
					this.svg?.viewbox() &&
					el instanceof Image &&
					((Math.round(el.size.width / this.svg.viewbox().width) === 1 &&
						el.size.height >= this.svg.viewbox().height) ||
						(Math.round(el.size.height / this.svg.viewbox().height) === 1 &&
							el.size.width >= this.svg.viewbox().width));

				if (isImageAsBackground) el.metadata.setAsBackground = true;
				if (!el.metadata.setAsBackground) el.group = 'background-elements';
				el.locked = true;
				bgElements.push(el);
			});

			const grouped = bgElements.filter((el) => el.group === 'background-elements');
			if (grouped.length === 1) grouped[0].group = null;
		});

		return {
			bgColor,
			bgElements,
		};
	}

	private parseUse(use: SVGJSElement, gradients: SlideGradients) {
		const found = this.svg?.findOne(`[id="${use.attr('xlink:href').replace('#', '')}"]`) as SVGJSElement;
		if (!found) return;
		const useTransform = use.attr('transform');
		if (useTransform && found.data('fixed-size') !== true) {
			const width = found.width() as number;
			const height = found.height() as number;
			const scaleX = use.transform().scaleX || 1;
			const scaleY = use.transform().scaleY || 1;
			const translateX = use.transform().translateX || 0;
			const translateY = use.transform().translateX || 0;
			found.size(width * scaleX, height * scaleY);
			found.dmove(translateX, translateY);
			found.data('fixed-size', true);
		}
		return this.parseElement(found, gradients);
	}

	private async parsePage(entry: SlideEntry) {
		const slide = this.svg?.findOne(`#${entry.slide}`);
		const master = this.svg?.findOne(`#${entry.master}`);
		if (!slide || !master) return;

		const newPage = Page.create();
		const { addElement, setBackground } = usePage(newPage);

		// Gradients from PPTX data
		const gradients = this.getSlideGradients(entry);

		// Master Slide
		const { bgColor, bgElements } = await this.parseMasterSlide(master, gradients, entry.slide);
		let slideBackgroundColor: Color = bgColor;

		// Some slides have the background color defined in the slide, not in the master
		const elementWithFill = slide.findOne('.Page > .Background > [fill]') as SVGJSElement;
		if (this.svg && elementWithFill) {
			slideBackgroundColor = TemplateLoader.getPageBackgroundColor(this.svg, elementWithFill.fill());
		}
		setBackground(slideBackgroundColor);

		let elementsToBeAdded: ElementsToAdd = [...bgElements];

		// Parse elements in defs
		slide.find('.Page > defs.SlideBackground').forEach((defsWrapper) => {
			defsWrapper.find('.Background use').forEach((use) => {
				const matchedElement = this.parseUse(use, gradients);
				if (!matchedElement) return;
				if (Array.isArray(matchedElement)) {
					elementsToBeAdded = [...elementsToBeAdded, ...matchedElement];
				} else {
					elementsToBeAdded.push(matchedElement);
				}
			});
		});

		// Find elements
		slide.find('.Page > g').forEach((objectWrapper) => {
			const matchedElement = this.parseElement(objectWrapper, gradients);
			if (!matchedElement) return;
			if (Array.isArray(matchedElement)) {
				elementsToBeAdded = [...elementsToBeAdded, ...matchedElement];
			} else {
				elementsToBeAdded.push(matchedElement);
			}
		});

		await Promise.all(elementsToBeAdded).then((els) => {
			const temporalRef = ref<ParsedElementClass>(Shape.create());

			// Add found elements to page
			els.forEach((elToAdd) => {
				if (!elToAdd) return;
				temporalRef.value = elToAdd;
				elToAdd.scaleBy(this.svgScale);
				addElement(elToAdd);
			});

			// Add missing texts with gradients when exporting from PPTX
			const contents = els.filter((el) => el instanceof Text).map((el) => el.content);
			gradients.textGradients.forEach((gradient) => {
				if (!contents.some((content) => content.includes(gradient.text))) {
					const newText = Text.create({
						colors: [gradient.gradient],
						content: gradient.text,
					});

					const provisionalTextNode = document.createElement('div');
					provisionalTextNode.innerHTML = `<span>${newText.content}</span>`;
					gradients.textGradients.forEach((textGradient) => {
						const gradient = textGradient.gradient;
						const text = textGradient.text;
						const span = Array.from(provisionalTextNode.querySelectorAll('span')).find((el) =>
							el.textContent?.includes(text)
						);
						if (!span) return;
						span.style.setProperty(`--${gradient.id}`, gradient.toCssString());
						TextTools.applyColorVarToTextNode(span, gradient);
						newText.content = provisionalTextNode.innerHTML;
					});

					newText.content = TextTools.parseTextColorsToCssVars(newText);
					temporalRef.value = newText;
					newText.scaleBy(this.svgScale);
					addElement(newText);
				}
			});
		});

		await TextTools.applyTextFixes(newPage);

		return newPage;
	}

	private parseGradientFromPPTX(gradient: any, isTextGradient = false) {
		const stopsList = isTextGradient ? gradient.text.p.r.rPr.gradFill.gsLst || [] : gradient.outerHtml.gsLst;
		const stops: StopGradient[] = stopsList.gs.map((stop: any) => {
			let srgbClr = '#000000';
			if (stop.srgbClr) {
				srgbClr = `#${stop.srgbClr['@attributes'].val}`;
			} else {
				const stopColorAlias = stop.schemeClr['@attributes'].val;
				srgbClr = `#${this.pptxData.themes[0].contents.themeElements.clrScheme[stopColorAlias].srgbClr['@attributes'].val}`;
			}
			const [r, g, b] = Normalize(srgbClr);
			const color = new SolidColor(r * 255, g * 255, b * 255, 1);
			const offset = parseInt(stop['@attributes'].pos) / 1000 || 0;
			return { ...color.toObject(), offset };
		});
		const isLinear = isTextGradient ? gradient.text.p.r.rPr.gradFill.lin : gradient.outerHtml.lin;
		let rotation = 0;
		if (isLinear) {
			if (isTextGradient) {
				rotation = gradient.text.p.r.rPr.gradFill.lin['@attributes'].ang / 9525;
			} else {
				rotation = parseInt(gradient.outerHtml.lin['@attributes'].ang) / 9525;
			}
		}
		return GradientColor.create(isLinear ? 'linear' : 'radial', stops, { rotation });
	}

	private getSlideGradients(entry: SlideEntry): SlideGradients {
		// Text gradients
		const rawTextGradients = this.pptxData.gradients
			? Object.values(
					Object.values(this.pptxData.gradients).find((gradient: any) => {
						const slideId = entry.slide.replace('id', '');
						return gradient.slide === `ppt/slides/slide${slideId}.xml`;
					})?.textWithGradients || {}
			  )
			: [];

		const textGradients = rawTextGradients.map((gradient: any) => {
			const text = gradient.text.p.r.t;
			const grad = this.parseGradientFromPPTX(gradient, true);

			return { text, gradient: grad };
		});

		// Rest of gradients
		const rawRestOfGradients = this.pptxData.gradients
			? Object.values(this.pptxData.gradients).find((gradient: any) => {
					const slideId = entry.slide.replace('id', '');
					return gradient.slide === `ppt/slides/slide${slideId}.xml`;
			  })?.gradients
			: [];

		const allRestOfGradients: GradientColor[] = (rawRestOfGradients || []).map((gradient: any) =>
			this.parseGradientFromPPTX(gradient)
		);

		const restOfGradients: GradientColor[] = [];
		allRestOfGradients.forEach((gradient) => {
			// PPTX may have duplicate gradients
			const alreadyExists = restOfGradients.find((g) => g.toCssString() === gradient.toCssString());
			if (!alreadyExists) restOfGradients.push(gradient);
		});

		return { restOfGradients, textGradients };
	}

	private isShapeWithText(wrapper: SVGJSElement) {
		const hasSomeShape = !!wrapper.find('circle, ellipse, line, path, polygon, polyline, rect:not(.BoundingBox)')
			.length;
		const hasSomeText = !!wrapper.find('.SVGTextShape, .TextShape').length;
		return hasSomeShape && hasSomeText;
	}

	private identifyTextAsShape(wrapper: SVGJSElement) {
		const isGroup = wrapper.classes().includes('Group');
		if (!isGroup) return;
		const mainElements = wrapper.find('[id^="id"]');
		const blackenedPaths = mainElements.filter((element) => {
			const hasBoundingBox = !!element.findOne('rect.BoundingBox');
			const hasGradients = !!element.findOne('linearGradient') || !!element.findOne('radialGradient');
			const hasOneBlackPath = element.find('path[fill="rgb(0,0,0)"]').length === 1;
			const isText = SlidesgoTextParser.isText(element);
			return hasBoundingBox && !hasGradients && hasOneBlackPath && !isText;
		});
		if (mainElements.length !== blackenedPaths.length) return;
		blackenedPaths.forEach((element) => element.parent()?.addClass('ignore-this-element'));
	}
}
