import { SVG } from '@svgdotjs/svg.js';

import Element from '@/elements/element/classes/Element';
import { PurifyUnserialize } from '@/elements/element/utils/PurifyUnserialize';
import { IllustratorDTO } from '@/Types/elements';
import { SerializedClass, ViewBox } from '@/Types/types';

export class Illustrator extends Element {
	type: 'illustrator' = 'illustrator';
	viewbox: string;
	content: string;

	protected constructor(illustratorDTO: IllustratorDTO) {
		super(illustratorDTO);

		this.viewbox = illustratorDTO.viewbox;
		this.content = illustratorDTO.content;
	}

	public get viewboxObject(): ViewBox {
		const viewboxData = this.viewbox.split(' ').map(Number);

		return {
			x: viewboxData[0],
			y: viewboxData[1],
			width: viewboxData[2],
			height: viewboxData[3],
		};
	}

	public get contentSvg() {
		const tempContent = `<svg viewBox="${this.viewbox}">${this.content}</svg>`;

		return SVG(tempContent);
	}

	static defaults(): IllustratorDTO {
		return {
			...Element.defaults(),
			type: 'illustrator',
			// Illustrator
			viewbox: '0 0 100 100',
			content: '',
		};
	}

	static create(config: Partial<IllustratorDTO> = {}) {
		const IllustratorDTO = {
			...Illustrator.defaults(),
			...config,
		};

		return new Illustrator(IllustratorDTO);
	}

	@PurifyUnserialize()
	static unserialize(data: SerializedClass<Illustrator>): Illustrator {
		const illustratorDTO = {
			...Illustrator.defaults(),
			...data,
		};

		return new Illustrator(illustratorDTO);
	}
}
