class NotPossibleInPhotoModeException extends Error {
	// @ts-ignore
	constructor(...params) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, NotPossibleInPhotoModeException);
		}

		this.name = 'NotPossibleInPhotoModeException';
	}
}

export default NotPossibleInPhotoModeException;
