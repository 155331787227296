<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { Ref, ref, toRef } from 'vue';

import { getMasks } from '@/api/DataApiClient';
import ActivableItem from '@/common/components/ActivableItem.vue';
import InfiniteLoading from '@/common/components/InfiniteLoading.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import { useMainStore } from '@/editor/stores/store';
import Image from '@/elements/medias/images/image/classes/Image';
import { useMaskeableElement } from '@/elements/medias/mask/composables/useMaskeableElement';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';
import InsertableElement from '@/interactions/components/InsertableElement.vue';
import { useInteractions } from '@/interactions/composables/useInteractions';
import { useSelection } from '@/interactions/composables/useSelection';
import { MaskApi } from '@/Types/apiClient';
import { InsertableApiType } from '@/Types/types';

const store = useMainStore();
const { selection } = useSelection();

const maskPanelContainerMobile = ref() as Ref<HTMLElement>;
const props = defineProps<{ element: Image | Video }>();

const element = toRef(props, 'element');

const url = ref(`elements/category/1741?limit=50`);
const { isMobile } = useDeviceInfo();
const { toggleMiddleHandlers, isCropping } = useInteractions();
const { applyMask, removeMask } = useMaskeableElement(element);
const { closePanel } = usePanelManagement();
const { trans } = useI18n();

const { data: masks, isFetching } = getMasks(url, { refetch: true });

const onApplyMask = async (maskApi: MaskApi) => {
	try {
		const mask = await applyMask(maskApi);
		if (mask && 'id' in mask)
			Bugsnag.leaveBreadcrumb(
				mask
					? `Apply mask to image-${props.element.id}: ${mask.name} - ${mask.id}`
					: `Remove mask to image-${props.element.id}`
			);
	} catch (error) {
		if (error instanceof Error) throw new Error(error.message);
	}
};

const onLoadMore = async () => {
	if (isFetching.value || !masks.value?.links?.next) return;
	url.value = masks.value.links.next;
};

const toggleKeepRatioMask = () => {
	props.element.mask?.toggleRatio();
	toggleMiddleHandlers();
};
</script>

<template>
	<div class="h-full">
		<template v-if="isMobile">
			<div ref="maskPanelContainerMobile" class="flex justify-between px-4 py-3">
				<div>
					<span class="text-sm font-bold uppercase text-gray-100">{{ trans('Masks') }}</span>
					<button
						v-if="element.mask"
						data-testid="toggle-proportion-mask"
						class="flex h-6 w-6 items-center justify-center rounded-full text-xs text-white"
						:class="{
							'bg-blue-500 slidesgo:bg-purple-400': element.mask.keepRatio,
							'bg-gray-600': !element.mask.keepRatio,
						}"
						@click="toggleKeepRatioMask"
					>
						<SvgIcon v-show="element.mask.keepRatio" name="chain" class="h-3 w-3" />
						<SvgIcon v-show="!element.mask.keepRatio" name="chain-open" class="h-3 w-3" />
					</button>
				</div>

				<div>
					<button
						data-testid="close-panel"
						class="flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white"
						@click="closePanel(element)"
					>
						<SvgIcon name="cross" class="h-3 w-3" />
					</button>
				</div>
			</div>
			<InfiniteLoading
				data-testid="container-items"
				horizontal
				class="mb-2 pb-0 text-gray-700"
				container-classes="flex items-center h-12 w-full gap-4"
				:data="(masks && masks.data) || []"
				:is-fetching="isFetching"
				@load="onLoadMore"
			>
				<template #default-item>
					<div>
						<button
							class="ml-4 flex h-12 w-12 items-center justify-center rounded bg-gray-600"
							:class="
								!element.mask ? 'border-2 border-blue-500 text-white slidesgo:border-purple-400' : 'text-gray-100'
							"
							@click="removeMask"
						>
							<SvgIcon name="empty" class="h-5 w-5" />
						</button>
					</div>
				</template>

				<template #item="{ item }">
					<div>
						<InsertableElement
							v-if="'mask' in item && 'element' in item.mask"
							:data="item"
							:type="InsertableApiType.ImageMask"
							:draggable="true"
							class="h-10 w-10 last-of-type:mr-4"
							@click="onApplyMask(item as MaskApi)"
						>
							<img
								:src="item.mask.element"
								class="h-full w-full cursor-pointer object-contain"
								:style="element.mask?.id === item.id ? 'filter: hue-rotate(15deg) saturate(5);' : ''"
							/>
						</InsertableElement>
					</div>
				</template>
			</InfiniteLoading>
		</template>

		<template v-else>
			<div class="flex h-full flex-col">
				<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
					<h2 class="text-xl font-semibold text-gray-100 mockup:text-fp-gray-700">{{ trans('Masks') }}</h2>
					<div class="flex gap-4">
						<button
							v-if="element.mask && selection.length === 1"
							data-testid="toggle-proportion-mask"
							class="text-gray-100 focus:outline-none hover:text-white"
							:class="{ 'pointer-events-none cursor-not-allowed opacity-25': isCropping }"
							@click="toggleKeepRatioMask"
						>
							<span
								v-show="element.mask.keepRatio"
								tooltip="Unlock"
								tooltip-position="left"
								class="flex h-6 w-6 items-center justify-center rounded-full bg-blue-500 text-sm text-white slidesgo:bg-purple-400"
							>
								<SvgIcon name="chain" class="h-3 w-3" />
							</span>
							<span
								v-show="!element.mask.keepRatio"
								tooltip="Lock"
								tooltip-position="left"
								class="rounded-ful flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-sm text-gray-100"
							>
								<SvgIcon name="chain-open" class="h-3 w-3" />
							</span>
						</button>
						<button
							data-testid="close-panel"
							class="text-gray-100 focus:outline-none hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800"
							@click="closePanel(element)"
						>
							<SvgIcon name="cross" class="h-5 w-5" />
						</button>
					</div>
				</div>

				<InfiniteLoading
					:data="(masks && masks.data) || []"
					:is-fetching="isFetching"
					:container-classes="'grid grid-cols-4 gap-4'"
					@load="onLoadMore"
				>
					<template #default-item>
						<div>
							<button
								data-testid="remove-mask"
								class="flex aspect-square w-full items-center justify-center rounded bg-gray-700 text-white mockup:bg-fp-gray-200"
								:class="
									!element.mask
										? 'border-2 border-blue-500 text-white mockup:text-blue-500 slidesgo:border-purple-400'
										: 'text-gray-100 mockup:text-fp-gray-700'
								"
								@click="removeMask"
							>
								<SvgIcon name="empty" class="h-6 w-6" />
							</button>
						</div>
					</template>

					<template #item="{ item }">
						<InsertableElement
							v-if="'mask' in item"
							:data="item"
							data-testid="item-panel"
							:type="InsertableApiType.ImageMask"
							:draggable="true"
							class="flex flex-col"
							@click="onApplyMask(item as MaskApi)"
						>
							<ActivableItem :active="'active' in item && item.active">
								<img
									v-if="item && 'element' in item.mask"
									:src="item.mask.element"
									class="w-full cursor-pointer rounded object-contain p-2"
									:style="element.mask?.id === item.id ? 'filter: hue-rotate(15deg) saturate(5);' : ''"
								/>
							</ActivableItem>
						</InsertableElement>
					</template>
				</InfiniteLoading>
			</div>
		</template>
	</div>
</template>
