import { promiseTimeout, watchOnce } from '@vueuse/core';
import { computed, nextTick, onBeforeUnmount, Ref, ref, watch } from 'vue';

import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useCircleType } from '@/elements/texts/curved/composables/useCircleType';
import { Text } from '@/elements/texts/text/classes/Text';
import { useFonts } from '@/elements/texts/text/composables/useFonts';
import { useTextEditing } from '@/elements/texts/text/composables/useTextEditing';
import TextTools from '@/elements/texts/text/utils/TextTools';
import { useArtboard } from '@/project/composables/useArtboard';
import { useProjectStore } from '@/project/stores/project';
import MathTools from '@/utils/classes/MathTools';

export const useCircleText = async (
	text: Ref<Text>,
	dom: Ref<HTMLDivElement | any>,
	canvasScale: Ref<number>,
	previewName?: string | undefined
) => {
	const project = useProjectStore();
	const { artboardSizeInPx } = useArtboard();
	const { isRenderingContext } = useEditorMode();
	const { fontLoading } = useFonts();
	const { textEditing } = useTextEditing();
	const arc = computed(() => (text.value.curvedProperties.arc !== null ? text.value.curvedProperties.arc : null));
	const arcAbs = computed(() =>
		text.value.curvedProperties.arc !== null ? Math.abs(text.value.curvedProperties.arc) : null
	);
	const isCurved = computed(() => !!arcAbs.value);
	const temporalClone = ref(document.createElement('div'));
	const scale = computed(() => text.value.scale);
	const fontSize = computed(() => text.value.fontSize);
	const fontStyle = computed(() => text.value.fontStyle);
	const fontWeight = computed(() => text.value.fontWeight);
	const fontFamily = computed(() => text.value.fontFamily);
	const outline = computed(() => !!text.value.outline.width);
	const textShadow = computed(() => text.value.textShadow.length);
	const textTransform = computed(() => text.value.textTransform);
	const color = computed(() => text.value.color);

	const observerOptions = {
		childList: true,
		attributes: true,
		subtree: true,
		characterData: true,
		attributeFilter: ['style'],
		attributeOldValue: true,
	};

	const prevWidth = ref(text.value.size.width);
	const prevPositionY = ref(text.value.position.y);
	const prevFontSize = ref(0);
	const width = computed(() => text.value.size.width);
	const refreshCurvedText = async (mutations: MutationRecord[]) => {
		if (isRenderingContext) return;

		// Si es un preview siempre vamos a querer refrescar el nodo
		if (previewName) {
			refresh();
			return;
		}
		const withoutChanges = mutations.every(
			(m) => m.type === 'characterData' || m.oldValue === (m.target as HTMLDivElement).getAttribute('style')
		);

		if (textEditing.value && textEditing.value.id !== text.value.id) {
			return;
		}

		if (arcAbs.value === null || (withoutChanges && !textEditing.value)) {
			return;
		}

		// Si la mutación es del width no queremos refrescar el texto ya que este cambio vendrá del propio refresh del texto curvo
		if (prevWidth.value.toFixed(4) !== text.value.size.width.toFixed(4) && prevFontSize.value === text.value.fontSize) {
			prevWidth.value = text.value.size.width;
			return;
		}

		// Si la mutación es del position no queremos refrescar el texto ya que este cambio vendrá del propio refresh del texto curvo
		if (prevPositionY.value !== text.value.position.y && prevFontSize.value === text.value.fontSize) {
			prevPositionY.value = text.value.position.y;
			return;
		}

		prevFontSize.value = text.value.fontSize;

		refresh(false);
	};

	const observer = new MutationObserver(refreshCurvedText);

	const { init, refresh, destroy, unmount, setRadius } = useCircleType(text, temporalClone, canvasScale, previewName);

	const createCurvedText = async () => {
		await document.fonts.ready;
		const textFinal = (previewName ? text.value.domPreviewNode(previewName) : text.value.domNode())?.querySelector(
			'.text-element-final'
		);

		if (!textFinal) {
			throw new Error('Text element final not found in DOM');
		}
		observer.observe(textFinal, observerOptions);
		temporalClone.value = textFinal?.cloneNode(true) as HTMLDivElement;
		temporalClone.value.id = 'clone-curved';
		temporalClone.value.classList.remove('opacity-0');
		dom.value.text.insertAdjacentElement('afterend', temporalClone.value);

		init();

		temporalClone.value.classList.add('curved-text-background');
	};

	const destroyCurvedText = () => {
		observer.takeRecords();
		observer.disconnect();
		destroy();
		temporalClone.value.remove();
	};

	const unmountCurvedText = () => {
		observer.takeRecords();
		observer.disconnect();
		unmount();
		temporalClone.value.remove();
	};

	watch(dom, async () => {
		// Si no están las fuentes no queremos hacer nada ya que los cálculos no serían correctos
		if (fontLoading.value) {
			return;
		}

		// Si no es curvo no queremos hacer nada
		if (!isCurved.value) {
			return;
		}

		if (!previewName) {
			project.pauseAutoSave?.();
		}

		try {
			await nextTick();
			await createCurvedText();
		} catch (error) {
			console.error(error);
		} finally {
			if (!previewName) {
				project.resumeAutoSave?.(true);
			}
		}
	});

	watchOnce(fontLoading, async () => {
		if (!isCurved.value) {
			return;
		}

		await createCurvedText();
	});

	watch(arc, async (newArc, prevArc) => {
		const isDestroy = newArc === null;
		const isNew = newArc !== null && prevArc === null;

		if (isDestroy) {
			destroyCurvedText();
			return;
		}

		if (isNew) {
			await createCurvedText();
			return;
		}
		// isUpdate
		setRadius(Math.abs(newArc));
	});

	watch(textEditing, async (newVal, oldValue) => {
		if (!isCurved.value) {
			return;
		}

		if (!newVal && oldValue?.id === text.value.id) {
			const textFinal = (previewName ? text.value.domPreviewNode(previewName) : text.value.domNode())?.querySelector(
				'.text-element-final'
			);
			if (!textFinal) {
				throw new Error('Text element final not found in DOM');
			}

			observer.takeRecords();
			observer.disconnect();
			observer.observe(textFinal, observerOptions);
			await nextTick();
			refresh();
			return;
		}

		if (newVal?.id !== text.value.id) {
			return;
		}

		await nextTick();
		const domElement = document.querySelector(`#editable-${text.value ? text.value.id : undefined}`);
		if (!domElement) {
			throw new Error('Text element final not found in DOM');
		}

		observer.takeRecords();
		observer.disconnect();

		observer.observe(domElement, observerOptions);
		await nextTick();

		refresh();
	});

	watch(scale, (newScale) => {
		if (!isCurved.value) {
			return;
		}
		temporalClone.value.style.transform = `scale(${newScale})`;
	});

	watch(artboardSizeInPx, async () => {
		if (!isCurved.value) {
			return;
		}
		await nextTick();
		refresh(false);
	});

	watch(width, (newWidth) => {
		if (!isCurved.value) {
			return;
		}
		temporalClone.value.style.width = `${newWidth / text.value.scale}px`;
	});

	watch(textTransform, (newTextTransform) => {
		if (!isCurved.value) {
			return;
		}
		temporalClone.value.style.textTransform = newTextTransform;
	});

	watch(outline, () => {
		if (!isCurved.value) {
			return;
		}

		temporalClone.value.style.webkitTextStroke = `${text.value.outline.width}${text.value.outline.unit || 'px'} ${
			text.value.outline.color
		}`;
	});

	watch(textShadow, () => {
		if (!isCurved.value) {
			return;
		}

		temporalClone.value.style.textShadow = TextTools.textShadowToCssString(text.value.textShadow);
	});

	watch([fontStyle, fontWeight], async () => {
		if (!isCurved.value || textEditing.value) {
			return;
		}
		// Cuando cambiamos el fontStyle o el fontWeight el que se encarga de pedir la nueva fuente es el navegador
		// no tenemos manera de saber cuando el texto está renderizado con la fuente que queremos, si no esperamos cogeremos
		// medidas incorrectas.
		// Damos algo de tiempo para crear el texto nuevo y destruirlo
		await promiseTimeout(10);
		unmountCurvedText();
		// Y volvemos a generar el texto nuevo ya si con las medidas correctas
		await promiseTimeout(10);
		await createCurvedText();
	});

	// Solo queremos escuchar estos watchers si es el texto original y no la preview ya que en las previews
	// siempre se refrescan los datos desde el observer
	if (!previewName) {
		// Cuando actualizamos el fontsize estamos actualizando el minArc ya que el width es distinto, tenemos que actualizar
		// el arc aplicado en relación para que no se produzca un desfase en la curvatura aplicada
		watch([fontSize, fontStyle, fontWeight, fontFamily], async () => {
			if (!isCurved.value) {
				return;
			}
			const prevMinArc = text.value.curvedProperties.minArc;
			const prevArc = text.value.curvedProperties.arc!;
			await nextTick();

			const newArc = MathTools.ruleOfThree(prevMinArc, prevArc, text.value.curvedProperties.minArc);
			if (prevMinArc !== newArc) {
				text.value.curvedProperties.arc = newArc;
			}
		});

		watchOnce(color, async () => {
			if (!isCurved.value) {
				return;
			}
			await nextTick();
			refresh();
		});
	}

	onBeforeUnmount(() => {
		observer.takeRecords();
		observer.disconnect();
	});
};
