<script lang="ts" setup>
import { useStorage, watchOnce } from '@vueuse/core';
import { useVOnboarding, VOnboardingStep, VOnboardingWrapper } from 'v-onboarding';
import { computed, ref } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMountedState } from '@/common/composables/useMountedState';
import { onBoardingSteps, OnboardingTypes } from '@/data/onBoardingSteps';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import { useI18n } from '@/i18n/useI18n';

const steps = ref();
const wrapper = ref();

const ready = useMountedState();
const store = useMainStore();

const { isSlidesgoMode } = useEditorMode();
const { start } = useVOnboarding(wrapper);
const { trans } = useI18n();
const state = useStorage('on-boarding', {
	slidesgoEditorMobile: false,
	wepikMobile: false,
});

const onboarding = computed<Extract<OnboardingTypes, 'slidesgoEditorMobile' | 'wepikMobile'>>(() => {
	if (isSlidesgoMode.value) return 'slidesgoEditorMobile';
	return 'wepikMobile';
});

watchOnce(ready, async () => {
	if (state.value[onboarding.value]) return;
	store.inOnboarding = true;
	steps.value = onBoardingSteps(onboarding.value);
	start();
});
</script>

<template>
	<div id="onboarding"></div>

	<VOnboardingWrapper ref="wrapper" class="z-50" :steps="steps">
		<template #default="{ next, exit, step, isFirst, isLast }">
			<VOnboardingStep>
				<template v-if="isFirst">
					<div
						class="inset-0 z-50 -mt-16 flex h-screen w-full items-center justify-center overflow-y-auto before:fixed"
					>
						<div class="max-w-xs rounded-xl bg-white">
							<div class="flex w-72 max-w-sm flex-col items-center p-6 text-center leading-normal text-darkblue-500">
								<div class="text-gray-600">
									<h3 class="mb-2 text-xl font-semibold text-gray-800">👋 {{ trans(step.content.title) }}</h3>
									<p class="text-gray-500">
										{{ trans(step.content.description) }}
									</p>
								</div>
							</div>
							<div class="flex justify-center pb-6">
								<button
									class="rounded bg-blue-500 px-6 py-3 font-semibold text-white hover:bg-blue-600 slidesgo:bg-purple-400"
									@click="
										next();
										state[onboarding] = true;
									"
								>
									{{ trans(`Let's go`) }}
								</button>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="relative m-4 w-[300px] -translate-y-2 rounded bg-blue-500 p-4 text-white slidesgo:bg-purple-400">
						<button
							class="absolute right-4 top-4 text-white"
							@click="
								exit();
								store.inOnboarding = false;
							"
						>
							<SvgIcon name="cross" class="h-4 w-4" />
						</button>
						<h3 class="mb-2 font-bold">{{ step.content.title }}</h3>
						<p v-if="step.content.description" class="mb-4 text-sm leading-normal">{{ step.content.description }}</p>
						<div class="mt-4 flex w-full items-center justify-between">
							<button
								v-if="!isFirst"
								class="flex items-center text-sm font-bold hover:opacity-75"
								@click="
									exit();
									store.inOnboarding = false;
								"
							>
								{{ trans('Skip') }}
							</button>
							<button v-if="!isLast" class="flex items-center text-sm font-bold hover:opacity-75" @click="next">
								{{ trans('Next') }}
								<SvgIcon name="right-small" class="ml-2 h-4 w-4" />
							</button>
							<button
								v-else
								class="flex items-center text-sm font-bold hover:opacity-75"
								@click="
									next();
									store.inOnboarding = false;
								"
							>
								<SvgIcon name="check" class="mr-2 h-3 w-3" />
								{{ trans('Finish') }}
							</button>
						</div>
					</div>
				</template>
			</VOnboardingStep>
		</template>
	</VOnboardingWrapper>
</template>

<style lang="sass">
.v-onboarding-overlay
	@apply fixed inset-0 fill-gray-900 opacity-75 w-full h-full pointer-events-none
</style>
