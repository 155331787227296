<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import OpacitySlider from '@/elements/element/components/menus/OpacitySlider.vue';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformMenu from '@/elements/element/components/menus/TransformMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import Line from '@/elements/line/classes/Line';
import LineMarkers from '@/elements/line/components/menus/LineMarkers.vue';
import LineStroke from '@/elements/line/components/menus/LineStroke.vue';
import LineStyle from '@/elements/line/components/menus/LineStyle.vue';
import { useLine } from '@/elements/line/composables/useLine';
import { useI18n } from '@/i18n/useI18n';

const { closePanel } = usePanelManagement();

const props = defineProps<{ element: Line }>();

const element = toRef(props, 'element');
const { hasMarkers } = useLine(element);
const { trans } = useI18n();
const { isGrouped } = useGroup(element);
</script>

<template>
	<div id="line-panel">
		<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
			<h2 class="text-xl font-semibold text-gray-100">{{ trans('Edit Line & Arrow') }}</h2>
			<button class="text-gray-100 focus:outline-none hover:text-white" data-testid="close-panel" @click="closePanel()">
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>

		<div data-testid="sidebar-panel" class="flex flex-col">
			<template v-if="!isGrouped">
				<PositionMenu :element="element" />
				<OrderMenu :element="element" />
			</template>

			<!-- Edition -->
			<div class="w-2/3">
				<TransformMenu :element="element" :allow-height="false" :allow-keep-proportion="false" />
			</div>

			<LineStroke :element="element" />

			<LineStyle :element="element" />

			<LineMarkers v-if="hasMarkers" :element="element" />

			<OpacitySlider :element="element" :opacity="element.opacity" />
		</div>
	</div>
</template>
