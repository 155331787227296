import { createSharedComposable, promiseTimeout, until, useEventListener } from '@vueuse/core';
import { computed, nextTick, onMounted, Ref, ref, watch } from 'vue';

import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import Element from '@/elements/element/classes/Element';
import { useIsBackground } from '@/elements/element/composables/useIsBackground';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { useLayersImage } from '@/elements/medias/images/image/composables/useLayersImage';
import { useSelection } from '@/interactions/composables/useSelection';
import { EditPanels } from '@/Types/types';

/**
 * It moves the page to the left when the edit panel is open and the focused element is under it [DESKTOP]
 * It moves the page to the top when the edit panel is open and the focused element is under it [RESPONSIVE]
 */
export const useMovePageOnEditPanelOverFocused = createSharedComposable(() => {
	const store = useMainStore();
	const { selection } = useSelection();

	const { isMobile } = useDeviceInfo();
	const scrollArea = ref<HTMLElement | null>(null);
	onMounted(() => {
		scrollArea.value = document.querySelector<HTMLElement>('#scroll-area');
	});
	const MARGIN = 5;
	const currentOffset = ref(0);
	const activeEditPanel = computed(() => store.editPanel);
	const prevPanel = ref(store.editPanel);
	const imageLayerspanelHeight = ref();
	const temporalRef = ref<Element>(Image.create());
	const { hasForeground } = useLayersImage(temporalRef as Ref<Image>);
	const { isBackground } = useIsBackground(temporalRef);
	const transitionEnd = ref(false);
	const isSecondaryPanel = computed(
		() =>
			activeEditPanel.value === 'Filter' ||
			activeEditPanel.value === 'Mask' ||
			activeEditPanel.value === 'ImagePresets' ||
			activeEditPanel.value === 'TextShadow' ||
			activeEditPanel.value === 'TextCurve' ||
			activeEditPanel.value === 'TextBorder' ||
			activeEditPanel.value === 'TextLink' ||
			activeEditPanel.value === 'TextEffects' ||
			activeEditPanel.value === 'BorderBox' ||
			activeEditPanel.value === 'CornerBox'
	);
	const focused = computed(() => (selection.value.length && selection.value[0]) || null);

	const shouldMove = computed(() => {
		if (focused.value && focused.value.type === 'image' && isMobile.value) {
			temporalRef.value = focused.value;
			if (isBackground.value) return false;
		}
		return true;
	});
	const onlyToolbarAppears = computed(
		() =>
			activeEditPanel.value === EditPanels.Shape ||
			activeEditPanel.value === EditPanels.Image ||
			activeEditPanel.value === EditPanels.Text
	);

	watch(activeEditPanel, async (newVal) => {
		if (!shouldMove.value && !isMobile.value) return;

		await nextTick();
		if (!scrollArea.value) return;
		if (newVal === prevPanel.value) {
			currentOffset.value = 0;
		}
		if (newVal !== prevPanel.value) {
			initListener();
			scrollArea.value.style.transform = '';
		}
		// MOBILE
		if (isMobile.value) {
			await nextTick();
			const { isUnder, offset } = await isFocusedUnderMobilePanel();
			scrollArea.value.style.transform = newVal && isUnder ? `translateY(-${offset}px)` : '';
			return;
		}
		// DESKTOP
		if ((newVal && !scrollArea.value.style.transform) || !newVal) {
			const { isUnder, offset } = await isFocusedUnderEditPanel();
			scrollArea.value.style.transform = newVal && isUnder ? `translateX(-${offset}px)` : '';
		}
	});

	/**
	 * It checks if the focused element is under the edit panel
	 * @returns An object with two properties: isUnder and offset.
	 */
	const isFocusedUnderEditPanel = async () => {
		await nextTick();

		const domElements = getElementBoundings(true);
		if (!domElements) return { isUnder: false, offset: 0 };
		const { elRBox, canvasRBox, editPanelRBox } = domElements;

		const canvasX2 = canvasRBox.x + canvasRBox.width;
		const elX2 = elRBox.x + elRBox.width;
		const dist = elX2 - editPanelRBox.x;
		const isUnder = canvasX2 > editPanelRBox.x && dist > 0;

		const offset = editPanelRBox.width;

		return { isUnder, offset };
	};

	/**
	 * It checks if the focused element is under the edit panel in responsive
	 * @returns An object with two properties: isUnder and offset
	 */
	const isFocusedUnderMobilePanel = async () => {
		await promiseTimeout(150);
		const domElements = getElementBoundings();
		if (!domElements) return { isUnder: false, offset: 0 };
		const { canvasRBox, elRBox, editPanelRBox, mainPanelRBox } = domElements;
		if (!focused.value || !editPanelRBox || !editPanelRBox || !elRBox || !mainPanelRBox)
			return { isUnder: false, offset: 0 };
		if (onlyToolbarAppears.value) return { isUnder: false, offset: 0 };

		if (!canvasRBox) return { isUnder: false, offset: 0 };

		const topSideLimitPanel = editPanelRBox.y;
		const bottomSideLimitElement = elRBox.y + elRBox.height;
		const bottomSideLimitCanvas = canvasRBox.y + canvasRBox.height;
		const topSideLimitMainPanel = mainPanelRBox.y;

		const elementIsPartiallyOutside = bottomSideLimitElement > bottomSideLimitCanvas;

		const imageLayerPanel = document.querySelector('.image-layers');
		if (!imageLayerspanelHeight.value && imageLayerPanel) {
			imageLayerspanelHeight.value = imageLayerPanel.getBoundingClientRect().height;
		}

		temporalRef.value = focused.value;
		const diff = hasForeground.value || focused.value instanceof ForegroundImage ? imageLayerspanelHeight.value : 0;

		const offsetWithMainPanel =
			bottomSideLimitElement > topSideLimitMainPanel - diff ? bottomSideLimitElement - topSideLimitMainPanel + diff : 0;

		let calculatedOffset = elementIsPartiallyOutside
			? topSideLimitPanel - bottomSideLimitCanvas
			: topSideLimitPanel - bottomSideLimitElement;

		const shouldMovePage = elementIsPartiallyOutside
			? bottomSideLimitCanvas > topSideLimitPanel
			: bottomSideLimitElement > topSideLimitPanel;

		if (elementIsPartiallyOutside && offsetWithMainPanel) {
			calculatedOffset = calculatedOffset - (bottomSideLimitElement - bottomSideLimitCanvas);
		}
		prevPanel.value = store.editPanel;
		currentOffset.value = shouldMovePage && isSecondaryPanel.value ? Math.abs(calculatedOffset) : 0;

		if (shouldMovePage) {
			return {
				isUnder: true,
				offset: Math.abs(currentOffset.value) + MARGIN - offsetWithMainPanel,
			};
		}
		return { isUnder: false, offset: 0 };
	};

	/**
	 *
	 * @param {boolean} isDesktop - if true, only return necessary bounding to desktop  mode
	 * @returns bounding elements
	 */
	const getElementBoundings = (isDesktop = false) => {
		if (!focused.value) return null;
		const elementDOM = focused.value.domNode();
		if (!elementDOM) return null;
		const canvasDOM = elementDOM.closest('[id^="canvas-"]');
		const elRBox = elementDOM.getBoundingClientRect();
		const editPanelDOM = document.getElementById('secondary-panel') || document.getElementById('edit-panel');
		const mainPanelDOM = document.getElementById('main-panel');

		if (isDesktop) {
			if (!canvasDOM || !elRBox || !editPanelDOM || !elementDOM) return null;
			const canvasRBox = canvasDOM.getBoundingClientRect();
			const editPanelRBox = editPanelDOM.getBoundingClientRect();
			return {
				canvasRBox,
				elRBox,
				editPanelRBox,
			};
		}
		if (!canvasDOM || !elRBox || !editPanelDOM || !mainPanelDOM || !elementDOM) return null;

		const canvasRBox = canvasDOM.getBoundingClientRect();
		const editPanelRBox = editPanelDOM.getBoundingClientRect();
		const mainPanelRBox = mainPanelDOM.getBoundingClientRect();
		return {
			canvasRBox,
			elRBox,
			editPanelRBox,
			mainPanelRBox,
		};
	};

	/**
	 * Force to recalculate movement of scroll area without changing panel
	 * @returns void
	 */
	const recalculatePanelSize = async () => {
		if (!scrollArea.value || !shouldMove.value) return;
		initListener();
		scrollArea.value.style.transform = '';
		await until(transitionEnd).toBeTruthy();

		requestAnimationFrame(async () => {
			const { isUnder, offset } = await isFocusedUnderMobilePanel();
			scrollArea.value!.style.transform = isUnder ? `translateY(-${offset}px)` : '';
		});

		transitionEnd.value = false;
	};

	const initListener = () => {
		if (!scrollArea.value) return;
		useEventListener(scrollArea.value, 'transitionend', () => (transitionEnd.value = true), { once: true });
	};

	return {
		recalculatePanelSize,
	};
});
