<script lang="ts" setup>
import { OnClickOutside } from '@vueuse/components';
import { useUrlSearchParams } from '@vueuse/core';
import { computed, ref } from 'vue';

import GAnalytics from '@/analytics/ganalytics/utils/GAnalytics';
import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import PanelHeader from '@/editor/components/PanelHeader.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import GoogleLogoutModal from '@/google/photos/components/GoogleLogoutModal.vue';
import { useI18n } from '@/i18n/useI18n';
import { usePanelElements } from '@/layout/composables/usePanelElements';
import { Panels } from '@/Types/types';

const params = useUrlSearchParams<{
	panel?: string;
}>();

const { trans } = useI18n();
const { isLogged } = useAuth();
const { isWebview, isMobile } = useDeviceInfo();
const { selectElement } = usePanelElements();
const { isPhotoMode, isSlidesgoMode, isEmbeddedContext } = useEditorMode();
const { isAdmin } = useAuth();
const { authenticatedInDropbox, authenticatedInGoogle, logoutGoogle } = useUserImageProvider();

const modalOpened = ref(false);

if (params.panel) selectElement(Panels.textToImage, true);

const logout = () => {
	logoutGoogle();
	modalOpened.value = false;
};

const textToImageClicked = () => {
	GAnalytics.track('click', 'AI', 'text-to-image_panel', null);

	selectElement(Panels.textToImage, true);
};

const aiWriterClicked = () => {
	selectElement(Panels.aiWriter, true);
};

const aiPresentationClicked = () => {
	GAnalytics.track('click', 'AI', 'ai-presentation_panel', null);

	selectElement(Panels.aiPresentation, true);
};

const showAdminPanel = computed(() => {
	return isAdmin.value || process.env.NODE_ENV !== 'production';
});
</script>

<template>
	<div class="flex h-full flex-col">
		<PanelHeader title="Tools" />
		<KeepAlive>
			<div class="flex flex-col">
				<div class="mt-2">
					<div class="grid grid-cols-5 gap-4 sm:grid-cols-8 lg:grid-cols-4 lg:gap-3">
						<!-- Dropbox button -->
						<button v-if="!isWebview && !isEmbeddedContext" class="group" @click="selectElement(Panels.dropbox, true)">
							<label
								class="group relative mb-2 flex w-full cursor-pointer items-center justify-center rounded bg-dropbox-500 pb-full text-center font-bold text-white focus:outline-none"
							>
								<SvgIcon
									name="integrations-dropbox"
									class="absolute inset-0 h-full w-full fill-current p-4 text-dropbox-100 group-hover:text-white"
								/>
								<span
									v-if="authenticatedInDropbox"
									class="absolute right-0 top-0 m-1 flex h-4 w-4 items-center justify-center rounded-full bg-green-600 text-xs font-bold text-white"
								>
									<SvgIcon name="link" class="h-2 w-2 fill-current text-white"></SvgIcon>
								</span>
							</label>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">Dropbox</p>
						</button>
						<!-- Google Drive button -->
						<button
							v-if="!isWebview && !isEmbeddedContext"
							class="group"
							@click="selectElement(Panels.googleDrive, true)"
						>
							<div
								class="group relative mb-2 flex w-full cursor-pointer flex-col items-center justify-center rounded bg-white pb-full text-center text-white focus:outline-none"
							>
								<SvgIcon
									name="integrations-drive"
									class="absolute inset-0 h-full w-full fill-current p-4 text-dropbox-100 group-hover:text-white"
								/>
								<OnClickOutside v-if="authenticatedInGoogle" class="absolute right-1 top-1" @click.stop>
									<button
										class="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-green-600 text-xs font-bold text-white"
										@click.stop="modalOpened = true"
									>
										<SvgIcon name="link" class="h-2 w-2 fill-current text-white"></SvgIcon>
									</button>
								</OnClickOutside>
							</div>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">Drive</p>
						</button>
						<!-- Google Photos button -->
						<button
							v-if="!isWebview && !isEmbeddedContext"
							class="group"
							@click="selectElement(Panels.googlePhotos, true)"
						>
							<div
								class="group relative mb-2 flex w-full cursor-pointer flex-col items-center justify-center rounded bg-white pb-full text-center text-white focus:outline-none"
							>
								<SvgIcon
									name="integrations-photos"
									class="absolute inset-0 h-full w-full fill-current p-4 text-dropbox-100 group-hover:text-white"
								/>
								<OnClickOutside v-if="authenticatedInGoogle" class="absolute right-1 top-1" @click.stop>
									<button
										class="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-green-600 text-xs font-bold text-white"
										@click.stop="modalOpened = true"
									>
										<SvgIcon name="link" class="h-2 w-2 fill-current text-white"></SvgIcon>
									</button>
								</OnClickOutside>
							</div>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">Photos</p>
						</button>
						<GoogleLogoutModal :open="modalOpened" @close="modalOpened = false" @confirm="logout" />
						<!-- QR Code -->
						<div class="group">
							<label
								data-testid="qr-code"
								class="group relative mb-2 flex w-full cursor-pointer justify-center rounded bg-gray-700 pb-full text-center font-bold text-white focus:outline-none"
								@click="selectElement(Panels.qrCode, true)"
							>
								<SvgIcon name="qr" class="absolute inset-0 h-full w-full p-5 opacity-75 group-hover:opacity-100" />
							</label>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">{{ trans('QR Code') }}</p>
						</div>
						<!-- Brand Kits -->
						<div v-if="!isPhotoMode && !isWebview && !isSlidesgoMode && !isEmbeddedContext" class="group">
							<label
								data-testid="brand-kit"
								class="group relative mb-2 flex w-full cursor-pointer justify-center rounded bg-gray-700 pb-full text-center font-bold text-white focus:outline-none"
								@click="selectElement(Panels.brandKits, true)"
							>
								<SvgIcon
									name="brand-kit"
									class="absolute inset-0 h-full w-full p-5 opacity-75 group-hover:opacity-100"
								/>
							</label>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">{{ trans('My brand') }}</p>
						</div>
						<!-- Creations -->
						<div v-if="!isMobile && !isWebview && !isPhotoMode && !isSlidesgoMode && !isEmbeddedContext" class="group">
							<label
								data-testid="my-creations"
								class="group relative mb-2 flex w-full cursor-pointer justify-center rounded bg-gray-700 pb-full text-center font-bold text-white focus:outline-none"
								@click="selectElement(Panels.myCreations, true)"
							>
								<SvgIcon name="folder" class="absolute inset-0 h-full w-full p-5 opacity-75 group-hover:opacity-100" />
							</label>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">{{ trans('Projects') }}</p>
						</div>
						<!-- Text to image -->
						<div v-if="isLogged" class="group">
							<label
								data-testid="text-to-image"
								class="group relative mb-2 flex w-full cursor-pointer justify-center rounded bg-gray-700 pb-full text-center font-bold text-white focus:outline-none"
								@click="textToImageClicked"
							>
								<SvgIcon name="text-to-img" class="absolute inset-0 h-full w-full p-4" />
							</label>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">
								{{ trans('Text to img') }}
							</p>
						</div>
						<!-- AI Presentation -->
						<div v-if="isLogged && !isPhotoMode" class="group">
							<label
								data-testid="text-to-image"
								class="group relative mb-2 flex w-full cursor-pointer justify-center rounded bg-gray-700 pb-full text-center font-bold text-white focus:outline-none"
								@click="aiPresentationClicked"
							>
								<SvgIcon name="ai-presentation" class="absolute inset-0 h-full w-full p-4" />
							</label>
							<p class="text-center text-xs leading-none text-gray-100 group-hover:text-white">
								{{ trans('AI Presentation') }}
							</p>
						</div>
						<!-- AI Writer -->
						<div v-if="isLogged" class="group">
							<label
								data-testid="ai-writer"
								class="group relative flex h-16 w-full cursor-pointer items-center justify-center rounded bg-gray-700 text-center font-bold text-white focus:outline-none"
								@click="aiWriterClicked"
							>
								<SvgIcon name="ai-writer" class="absolute inset-0 h-full w-full p-4" />
							</label>
							<p class="mt-2 text-center text-xs text-gray-100 group-hover:text-white">{{ trans('AI writer') }}</p>
						</div>
						<!-- Dev -->
						<div v-if="showAdminPanel" class="group">
							<label
								data-testid="text-to-image"
								class="group relative mb-2 flex w-full cursor-pointer justify-center rounded bg-gray-700 pb-full text-center font-bold text-white focus:outline-none"
								@click="selectElement(Panels.dev, true)"
							>
								<SvgIcon name="dev" class="absolute inset-0 h-full w-full p-4 opacity-75 group-hover:opacity-100" />
							</label>
							<p class="text-center text-xs text-gray-100 group-hover:text-white">Dev</p>
						</div>
					</div>
				</div>
			</div>
		</KeepAlive>
	</div>
</template>
