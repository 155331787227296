<script lang="ts" setup>
import { toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDownloadCustomBox } from '@/elements/box//composables/useDownloadCustomBox';
import { Box } from '@/elements/box/classes/Box';

const props = defineProps<{ element: Box }>();
const element = toRef(props, 'element');

const { t: trans } = useI18n();
const { convertAndDownload } = useDownloadCustomBox(element);

const onDownloadClick = () => {
	convertAndDownload();
};
</script>

<template>
	<button tooltip-position="top" :tooltip="trans('Download Shape as SVG')" class="h-10" @click="onDownloadClick">
		<SvgIcon
			name="download"
			class="flex h-11 w-[15vw] shrink-0 flex-col items-center justify-between text-gray-100 hover:text-white lg:h-4 lg:w-8 lg:justify-center"
		/>
	</button>
</template>
