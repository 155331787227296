<script setup lang="ts">
import { ref, watch } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useAiWriter } from '@/elements/texts/text/composables/useAiWriter';
import DownloadInfo from '@/export/download/components/DownloadInfo.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';

const { trans } = useI18n();
const { downloads, downloading } = useDownloadsProject();
const { isMobile } = useDeviceInfo();
const { textAI } = useAiWriter();

const open = ref(!isMobile.value);
const toggle = () => (open.value = !open.value);
// Cada vez que se añade un download nuevo abrimos el panel de descargas
watch(
	() => downloads.value.size,
	() => (open.value = true)
);

const handleDelete = (uuid: string) => {
	downloads.value.delete(uuid);
};

const deleteAll = () => {
	downloads.value.clear();
};
</script>

<template>
	<div
		id="downloads-container"
		class="pointer-events-auto absolute bottom-20 right-4 z-20 max-w-xs lg:mb-0 lg:w-full lg:max-w-sm"
		:class="{
			' mb-2 w-full rounded bg-white text-gray-600': open,
			'z-0 hidden': textAI && textAI.id,
			'h-12 w-12 rounded-full bg-blue-500 text-white slidesgo:bg-purple-400 lg:h-auto lg:w-full lg:rounded lg:bg-white lg:text-gray-600':
				!open,
			'download-button': isMobile && !open,
		}"
	>
		<div
			class="flex h-full w-full items-center justify-center rounded-full text-lg font-semibold lg:justify-between lg:p-4"
			:class="{
				'justify-between p-4': open,
				'downloading-button': downloading && !open && isMobile,
			}"
		>
			<button
				class="flex flex-1 items-center gap-2"
				:class="{
					'justify-center lg:justify-start': !open,
				}"
				data-testid="download-status-btn"
				@click="toggle"
			>
				<span class="hidden lg:block">
					{{ downloading ? trans('Download in progress') : trans('Download complete') }}</span
				>
				<span v-if="downloading && open" class="lg:hidden">
					{{ trans('Download in progress') }}
				</span>
				<span v-if="!downloading && open" class="lg:hidden">
					{{ trans('Download complete') }}
				</span>

				<SvgIcon v-if="!open" name="download" class="h-5 w-5 lg:hidden" />
				<SvgIcon
					name="arrow"
					class="h-5 w-5"
					:class="{
						'rotate-180': open,
						'hidden lg:block': !open,
					}"
				/>
			</button>
			<button
				:class="{
					'hidden lg:block': !open,
				}"
				@click="deleteAll"
			>
				<SvgIcon name="cross" class="h-5 w-5" />
			</button>
		</div>
		<div v-show="open" class="flex flex-col gap-4 px-4 pb-4">
			<template v-for="[uuid, download] in downloads.entries()" :key="uuid">
				<DownloadInfo :download="download" @delete="handleDelete(uuid)" />
			</template>
		</div>
	</div>
</template>
<style lang="sass" scoped>
@media (max-width: 1023px)
.download-button
	@apply bg-gray-600
	animation: download-button 1s forwards

	@keyframes download-button
		0%
			transform: translateY(-30px)

		100%
			transform: translateY(0px)

.downloading-button
	animation: downloading-button 2s infinite
	animation-delay: 1s

	@keyframes downloading-button
		0%
			@apply bg-gray-600

		50%
			@apply bg-gray-500

		100%
			@apply bg-gray-600
</style>
