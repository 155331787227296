<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { computed, nextTick, ref } from 'vue';

import { GradientColor } from '@/color/classes/GradientColor';
import { SolidColor } from '@/color/classes/SolidColor';
import ColorPicker from '@/color/components/ColorPicker.vue';
import Popper from '@/common/components/Popper.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { useMainStore } from '@/editor/stores/store';
import { Color } from '@/Types/colorsTypes';

const store = useMainStore();

const { isMobile } = useDeviceInfo();

const props = defineProps<{
	colors: Color[];
	max: number;
	hideAlpha?: boolean;
	pickerClass?: string;
	pickerParent?: string;
	curvedTextsColors?: Color[];
	linesStrokeColors?: Color[];
	preventChange?: boolean;
}>();

const emit = defineEmits<{
	(e: 'change', data: { color: Color; newColor: Color }): void;
	(e: 'select', color: SolidColor | GradientColor): void;
}>();
const visibleColors = computed(() => {
	if (props.colors.length > props.max) return [];

	return props.colors;
});
const invisibleColors = computed(() => {
	return props.colors;
});
const allVisible = computed(() => visibleColors.value.length >= props.colors.length);
const showAll = ref(false);
const container = ref();
const selectedIdColor = ref('');

onClickOutside(container, (ev) => {
	if (!showAll.value) return;

	const target = ev.target as HTMLElement | null;

	if (target?.closest('.color-picker')) return;

	showAll.value = false;
	selectedIdColor.value = '';
});

const emitColor = (color: Color, newColor: Color) => {
	emit('change', { color: cloneDeep(color), newColor });
};

const isCurvedTextColor = (color: Color): boolean => {
	if (!props.curvedTextsColors) return false;
	return !!props.curvedTextsColors?.find((c) => c.toCssString() === color.toCssString());
};

const showAllColors = () => {
	if (props.preventChange) {
		emit('select', visibleColors.value[0]);
		return;
	}

	showAll.value = !showAll.value;
};

const colorPickerCustomClass = (c: Color) => {
	let customClass = props.pickerClass || '';

	if (selectedIdColor.value === c.id) {
		customClass += ' preset-active';
	}

	return customClass;
};

const onSelectColor = async (ev: MouseEvent, color: Color) => {
	// Evitamos que se abra el color picker al hacer click con el botón derecho
	if (ev.button !== 0) return;

	// Si se hace click en un color picker que está en un toolbar, cerramos el panel
	if (ev.target instanceof HTMLElement && ev.target.closest('.toolbar') && !isMobile.value) {
		store.editPanel = null;

		await nextTick();
	}

	selectedIdColor.value = color.id;
};
</script>

<template>
	<div
		ref="container"
		data-testid="colorpicker-group"
		class="color-group flex items-center gap-2"
		:class="props.colors.length > 5 ? 'lg:gap-0' : 'lg:gap-2'"
	>
		<ColorPicker
			v-for="color in visibleColors"
			:key="color.id"
			:color="(color as Color)"
			class="h-full transition-all"
			:class="{
				[pickerClass || '']: true,
				'last:mr-0 lg:-mr-2': props.colors.length > 5 && props.max !== Infinity,
			}"
			:parent="pickerParent"
			:hide-gradient="isCurvedTextColor(color)"
			:hide-alpha="hideAlpha"
			:prevent-change="preventChange"
			placement="right-start"
			@click="(ev: MouseEvent) => onSelectColor(ev, color)"
			@change="emitColor(color, $event)"
			@select="($event) => emit('select', $event as SolidColor | GradientColor)"
		/>
		<button
			v-if="!allVisible"
			:class="pickerClass"
			class="all-colors relative h-full shrink-0 overflow-hidden rounded-full text-white transition-all duration-300"
			@click="showAllColors"
		></button>

		<Popper v-if="showAll && !isMobile" data-testid="all-global-colors" placement="top" class="z-10">
			<div class="mb-3.5 flex w-32 flex-wrap items-center gap-2 whitespace-nowrap rounded-lg bg-gray-700 py-2 px-2">
				<ColorPicker
					v-for="color in invisibleColors"
					:key="color.id"
					:hide-gradient="isCurvedTextColor(color)"
					class="relative aspect-square rounded-full"
					style="margin-right: 0px"
					:class="colorPickerCustomClass(color)"
					:color="(color as Color)"
					:hide-alpha="hideAlpha"
					:prevent-change="preventChange"
					:parent="pickerParent"
					placement="right-start"
					@click="(ev: MouseEvent) => onSelectColor(ev, color)"
					@change="(newColor) => emit('change', { color, newColor })"
					@select="($event) => emit('select', $event as SolidColor | GradientColor)"
				/>
			</div>
		</Popper>
		<div
			v-if="showAll && isMobile"
			class="fixed bottom-16 left-0 grid w-full grid-cols-10 gap-2 rounded bg-gray-800 py-2 px-2 backdrop-blur"
		>
			<ColorPicker
				v-for="color in invisibleColors"
				:key="color.id"
				:hide-gradient="isCurvedTextColor(color)"
				class="relative aspect-square rounded-full"
				style="margin-right: 0px"
				:class="colorPickerCustomClass(color)"
				:color="(color as Color)"
				:hide-alpha="hideAlpha"
				:prevent-change="preventChange"
				:parent="pickerParent"
				placement="right-start"
				@click="(ev: MouseEvent) => onSelectColor(ev, color)"
				@change="(newColor) => emit('change', { color, newColor })"
				@select="($event) => emit('select', $event as SolidColor | GradientColor)"
			/>
		</div>
	</div>
</template>
<style lang="scss">
.all-colors {
	background: conic-gradient(#f44336, #ffeb3b, #8bc34a, #00bcd4, #3f51b5, #9c27b0, #f44336);
}

.preset-active::after {
	content: '';
	@apply absolute inset-0 m-[2px] rounded-full border-2 border-gray-800;
}
</style>
