<script lang="ts" setup>
import { computed } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useMainStore } from '@/editor/stores/store';
import { ImageApi, UploadApi, VideoApi } from '@/Types/apiClient';

const props = withDefaults(
	defineProps<{
		image: ImageApi | VideoApi | UploadApi;
		deletable?: boolean;
		useBackgroundRemovedIfAvailable?: boolean;
	}>(),
	{
		deletable: false,
	}
);

const emit = defineEmits<{ (e: 'delete'): void }>();

const src = computed(() => {
	return (
		store.uploads.get(props.image.id)?.preview ||
		(props.useBackgroundRemovedIfAvailable && props.image.backgroundRemoved) ||
		props.image.preview
	);
});

const store = useMainStore();
</script>

<template>
	<div :class="deletable ? 'group relative w-full rounded bg-gray-700 hover:bg-gray-600' : ''">
		<button
			v-if="deletable"
			class="absolute right-0 top-0 z-20 mr-2 mt-2 flex h-5 w-5 items-center justify-center rounded bg-red-500 text-white transition-opacity duration-300 group-hover:opacity-100 lg:opacity-0"
			@click.stop="emit('delete')"
		>
			<SvgIcon name="close-modal" class="h-3 w-3 fill-current" />
		</button>
		<img :src="src" class="h-full w-full cursor-pointer rounded object-contain hover:opacity-90" />
		<slot></slot>
	</div>
</template>
