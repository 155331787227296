<script lang="ts" setup>
import { Ref, ref } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { useDeviceInfo } from '@/common/composables/useDeviceInfo';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import ElementFilter from '@/elements/medias/filter/components/menus/ElementFilter.vue';
import ForegroundImage from '@/elements/medias/images/foreground/classes/ForegroundImage';
import Image from '@/elements/medias/images/image/classes/Image';
import { Video } from '@/elements/medias/video/classes/Video';
import { useI18n } from '@/i18n/useI18n';

const { isMobile } = useDeviceInfo();
const filterMenuContainer = ref() as Ref<HTMLElement>;
const { trans } = useI18n();

defineProps<{ element: Image | Video }>();
const { closePanel } = usePanelManagement();
</script>

<template>
	<div ref="filterMenuContainer" class="mb-3">
		<div
			:class="{
				'flex items-center justify-between px-4 py-3': isMobile,
				'mb-3 flex h-6 items-center justify-between lg:mb-4': !isMobile,
			}"
		>
			<div class="flex items-center gap-4">
				<h2
					class="text-sm font-bold uppercase text-gray-100 mockup:text-fp-gray-700 lg:text-xl lg:font-semibold lg:normal-case"
				>
					{{ trans('Edit filters') }}
				</h2>

				<button
					v-if="isMobile && element instanceof ForegroundImage"
					class="flex items-center text-xs text-gray-100 hover:text-white"
					@click="() => element.setFilter(null)"
				>
					<SvgIcon name="reload" class="mr-2 h-3 w-3" />
					{{ trans('Reset') }}
				</button>
			</div>
			<div
				:class="{
					'flex items-center justify-between': isMobile,
					'mb-3 flex h-6 items-center justify-between lg:mb-4': !isMobile,
				}"
			>
				<div class="flex items-center gap-4">
					<button
						data-testid="close-panel"
						:class="{
							'flex h-6 w-6 items-center justify-center rounded-full bg-gray-800/50 text-xs text-gray-100 hover:text-white':
								isMobile,
							'text-gray-100 focus:outline-none hover:text-white mockup:text-fp-gray-600 mockup:hover:text-fp-gray-800':
								!isMobile,
						}"
						@click="closePanel(element)"
					>
						<SvgIcon name="cross" :class="{ 'h-3 w-3': isMobile, 'h-5 w-5': !isMobile }" />
					</button>
				</div>
			</div>
		</div>
		<ElementFilter :element="element" />
	</div>
</template>
