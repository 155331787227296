import { computed, Ref } from 'vue';

import Image from '@/elements/medias/images/image/classes/Image';

export const useImage = (image: Ref<Image>) => {
	const isBase64 = computed(() => image.value.url.startsWith('data:image'));

	const isLandscape = computed(() => image.value.size.width >= image.value.size.height);

	const isAiImage = computed(() => image.value.metadata.source === 'ai');

	const isPlaceholder = computed(() => image.value.url.includes('svg/mask-placeholder.svg'));

	const ratio = computed(() => image.value.size.width / image.value.size.height);

	return {
		isLandscape,
		isPlaceholder,
		isBase64,
		isAiImage,
		ratio,
	};
};
