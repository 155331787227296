<script lang="ts" setup>
import { toRef } from 'vue';

import SvgIcon from '@/common/components/SvgIcon.vue';
import { usePanelManagement } from '@/editor/composables/usePanelManagement';
import FlipMenu from '@/elements/element/components/menus/FlipMenu.vue';
import OrderMenu from '@/elements/element/components/menus/OrderMenu.vue';
import PositionMenu from '@/elements/element/components/menus/PositionMenu.vue';
import TransformMenu from '@/elements/element/components/menus/TransformMenu.vue';
import { useGroup } from '@/elements/group/composables/useGroup';
import { QRCode } from '@/elements/qr-code/classes/QRCode';
import QRCodeMenu from '@/elements/qr-code/components/menus/QRCodeMenu.vue';
import { useI18n } from '@/i18n/useI18n';

const props = defineProps<{ element: QRCode }>();

const element = toRef(props, 'element');

const { isGrouped } = useGroup(element);
const { closePanel } = usePanelManagement();
const { trans } = useI18n();
</script>

<template>
	<div class="mb-3 flex h-6 items-center justify-between lg:mb-4">
		<h2 class="text-xl font-semibold text-gray-100">{{ trans('Edit QR Code') }}</h2>
		<button class="text-gray-100 focus:outline-none hover:text-white" data-testid="close-panel" @click="closePanel()">
			<SvgIcon name="cross" class="h-5 w-5" />
		</button>
	</div>

	<div data-testid="sidebar-panel" class="flex flex-col">
		<template v-if="!isGrouped">
			<PositionMenu :element="element" />
			<OrderMenu :element="element" />
		</template>

		<h4 class="mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('Edition') }}</h4>
		<div class="mb-6 w-1/2">
			<FlipMenu :inline-label="true" :element="element" />
		</div>

		<TransformMenu :element="element" :allow-height="true" :allow-keep-proportion="false" />

		<h4 class="mt-3 mb-3 text-sm font-bold uppercase text-gray-100 opacity-75">{{ trans('QR Options') }}</h4>

		<QRCodeMenu :element="element" :editing="true" />
	</div>
</template>
