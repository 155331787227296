<script lang="ts" setup>
import Bugsnag from '@bugsnag/js';
import { useTimeoutFn } from '@vueuse/core';
import { computed, onMounted, toRef, watch } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useMainStore } from '@/editor/stores/store';
import DownloadPanel from '@/export/download/components/DownloadPanel.vue';
import { useDownloadsProject } from '@/export/download/composables/useDownloadsProject';
import { useI18n } from '@/i18n/useI18n';
import { useProject } from '@/project/composables/useProject';
import { useProjectStore } from '@/project/stores/project';

// Using composables
const emits = defineEmits(['close', 'error', 'share']);

defineProps<{ templateDistribution?: string }>();

const { inSlidesgoContext, isPhotoMode } = useEditorMode();
const { trans } = useI18n();
const { isLogged } = useAuth();
const store = useMainStore();
const project = useProjectStore();

const allChangesAreSaved = toRef(useProjectStore(), 'allChangesSaved');
const { projectIsPresentation } = useProject();
const { setupForDownload, canDownload } = useDownloadsProject();

// Methods
onMounted(() => {
	setupForDownload();
});

watch(allChangesAreSaved, () => {
	setupForDownload();
});

const activeTemplate = computed(() =>
	store.downloadTemplateCategory?.name ? store.downloadTemplateCategory.name : 'default'
);

const templateDistribution = computed(() => {
	// Slidego Presentation
	if (inSlidesgoContext.value || projectIsPresentation) {
		return 'present';
	}

	// RRSS publication
	if (
		activeTemplate.value.toLowerCase().includes('facebook') ||
		activeTemplate.value.toLowerCase().includes('instagram') ||
		activeTemplate.value.toLowerCase().includes('twitter') ||
		activeTemplate.value.toLowerCase().includes('youtube') ||
		activeTemplate.value.toLowerCase().includes('zoom')
	) {
		return 'publish';
	}

	return 'default';
});
const onError = () => {
	emits('error');
};

useTimeoutFn(() => {
	if (canDownload.value) {
		return;
	}

	Bugsnag.leaveBreadcrumb('STATUS', {
		userVector: store.userVector,
		allChangesSaved: project.allChangesSaved,
		isLogged: isLogged.value,
		finishedLoading: store.finishedLoading,
		isPhotoMode: isPhotoMode.value,
		syncError: project.syncError,
		syncing: project.syncing,
		pendingSync: project.pendingSync,
		isHistoryBlocked: project.isHistoryBlocked,
		isPaused: project.isPaused,
		triedToSaveWhilePaused: project.triedToSaveWhilePaused,
	});

	Bugsnag.notify('Download panel is not showing');
}, 20000);

const retrySync = () => {
	project.triggerSync?.();
	Bugsnag.notify(`User retried sync`);
};
</script>

<template>
	<template v-if="canDownload">
		<div
			class="overflow-overlay fixed right-2 top-16 w-screen max-w-[95%] overflow-auto rounded-md bg-white p-4 text-white shadow-xl scrollbar-thin scrollbar-thumb-gray-100 lg:max-h-[calc(100vh-70px)] lg:max-w-sm"
			data-testid="download-modal"
		>
			<DownloadPanel :template-distribution="templateDistribution" @error="onError" @close="emits('close')" />
		</div>
	</template>
	<template v-else-if="project.syncError">
		<div
			class="pointer-events-auto fixed right-2 top-16 flex w-auto items-center justify-center rounded bg-white px-4 py-3 text-gray-700 shadow-xl"
		>
			Something went wrong <button class="ml-2 text-red-600 hover:text-red-500" @click="retrySync">try again</button>
		</div>
	</template>
	<template v-else-if="project.allVideos.some((video) => video.metadata.temporal)">
		<div
			class="fixed right-2 top-16 flex w-auto items-center justify-center rounded bg-white px-4 py-3 text-gray-700 shadow-xl"
		>
			<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin text-blue-500" />
			<span class="whitespace-nowrap text-sm font-semibold">{{
				trans('Your video is being processed. Please wait for a few minutes before you can continue with the download.')
			}}</span>
		</div>
	</template>
	<template v-else>
		<div
			class="fixed right-2 top-16 flex w-auto items-center justify-center rounded bg-white px-4 py-3 text-gray-700 shadow-xl"
		>
			<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin text-blue-500" />
			<span class="whitespace-nowrap text-sm font-semibold">{{
				trans(isLogged ? 'Saving your changes...' : 'Loading all pages...')
			}}</span>
		</div>
	</template>
</template>
