<script setup lang="ts">
import { OnClickOutside } from '@vueuse/components';
import { until } from '@vueuse/core';
import { computed, ref, toRef } from 'vue';

import { useAuth } from '@/auth/composables/useAuth';
import Popper from '@/common/components/Popper.vue';
import SvgIcon from '@/common/components/SvgIcon.vue';
import { useEditorMode } from '@/editor/composables/useEditorMode';
import { useUserImageProvider } from '@/elements/medias/images/image/composables/useUserImageProvider';
import { useI18n } from '@/i18n/useI18n';
import { useProjectStore } from '@/project/stores/project';
import { Lang } from '@/Types/types';

const props = defineProps({
	isEditor: {
		type: Boolean,
		default: true,
	},
});

const allChangesAreSaved = toRef(useProjectStore(), 'allChangesSaved');
const open = ref(false);
const isDisconnecting = ref(false);

const { user, isLogged, logout } = useAuth();
const { isSlidesgoMode } = useEditorMode();
const { trans, routes, locale } = useI18n();
const { emptyUserUploads } = useUserImageProvider();

const logoutHandler = async () => {
	open.value = false;
	isDisconnecting.value = true;
	await until(allChangesAreSaved).toBeTruthy();
	logout();
	emptyUserUploads();
	isDisconnecting.value = false;
};

const editProfileLink = computed(() => {
	if (isSlidesgoMode.value) return 'https://slidesgo.com/profile/edit';
	return 'https://www.freepik.com/profile/me';
});

const myProjectsLink = computed(() => {
	if (isSlidesgoMode.value) return '/profile/projects';
	return routes.myCreations[locale.value as Lang];
});

const supportLink = computed(() => {
	if (isSlidesgoMode.value) return 'https://slidesgo.com/faqs';
	return 'https://support.wepik.com/s/?language=' + locale.value;
});
</script>

<template>
	<OnClickOutside v-if="isLogged" @trigger="open = false">
		<div class="relative z-20">
			<button
				:class="[
					props.isEditor
						? 'ml-1 flex items-center text-gray-300 hover:text-white'
						: 'flex h-9 w-9 shrink-0 items-center text-sm font-medium text-gray-500 transition duration-150 ease-in-out focus:border-gray-300 focus:text-gray-700 focus:outline-none hover:border-gray-300 hover:text-gray-700 sm:h-10 sm:w-10',
				]"
				@click="open = !open"
			>
				<img
					:src="user?.avatar"
					:class="[
						props.isEditor
							? 'pii-cs h-8 w-8 rounded-full object-contain'
							: 'h-full w-full shrink-0 rounded-full object-contain',
					]"
				/>
				<SvgIcon v-if="props.isEditor" name="caret" class="ml-1 hidden h-3 w-3 lg:block" />
			</button>
			<Popper v-if="open" placement="bottom" :offsets="{ x: 5, y: 20 }">
				<Transition
					appear
					enter-active-class="transition duration-100 ease-out"
					enter-from-class="transform -translate-y-4 opacity-0"
				>
					<div class="z-10 mr-2 w-64 rounded bg-white px-4 py-3 shadow-xl">
						<div class="mb-2 flex border-b border-gray-100 border-opacity-50 pb-4">
							<img :src="user?.avatar" class="pii-cs h-14 w-14 rounded-full object-contain" />
							<div class="min-w-0 flex-1 pl-3">
								<p class="pii-cs truncate text-sm font-bold text-gray-800">{{ user?.name }}</p>
								<p class="pii-cs mb-2 truncate text-xs text-gray-600">{{ user?.email }}</p>
								<a
									:href="editProfileLink"
									target="_blank"
									:class="
										isSlidesgoMode
											? 'mt-2 rounded bg-purple-400 px-4 py-1.5 text-sm text-white hover:bg-purple-500 sm:mt-1 sm:py-1 lg:mt-2 lg:py-1.5'
											: 'text-blue-500'
									"
									class="text-sm font-bold"
								>
									{{ trans('Edit profile') }}
								</a>
							</div>
						</div>
						<a
							:href="myProjectsLink"
							target="_blank"
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500 slidesgo:hover:text-purple-400"
						>
							<SvgIcon name="folder" class="mr-2 h-4 w-4 fill-current p-px" />
							{{ trans('My projects') }}
						</a>
						<a
							v-if="!isSlidesgoMode"
							:href="routes.brandkitRoutes[locale as Lang]"
							target="_blank"
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500"
						>
							<SvgIcon name="brand-kit" class="mr-2 h-4 w-4 fill-current p-px" />
							{{ trans('My brand') }}
						</a>
						<a
							:href="supportLink"
							target="_blank"
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500 slidesgo:hover:text-purple-400"
						>
							<SvgIcon name="support" class="mr-2 h-4 w-4 fill-current p-px" />
							{{ trans(isSlidesgoMode ? 'Faqs' : 'Support') }}
						</a>
						<button
							v-if="!isSlidesgoMode"
							class="flex w-full items-center py-2 text-sm uppercase text-gray-800 hover:text-blue-500 slidesgo:hover:text-purple-400"
							@click="logoutHandler"
						>
							<SvgIcon name="logout" class="mr-2 h-4 w-4 fill-current p-px" />
							{{ trans('Logout') }}
						</button>
					</div>
				</Transition>
			</Popper>
		</div>
	</OnClickOutside>

	<template v-if="!allChangesAreSaved && isDisconnecting">
		<div
			class="absolute right-0 top-10 flex w-auto items-center justify-center rounded bg-white px-4 py-3 text-gray-700 lg:top-14"
		>
			<SvgIcon name="spinner" class="mr-2 h-5 w-5 animate-spin text-blue-500 slidesgo:text-purple-400" />
			<span class="whitespace-nowrap text-sm font-semibold">{{ trans('Saving your changes...') }}</span>
		</div>
	</template>
</template>
