<script lang="ts" setup>
import 'nouislider/dist/nouislider.css';

import { until } from '@vueuse/core';
import * as noUiSlider from 'nouislider';
import { API } from 'nouislider';
import { onBeforeUnmount, onMounted, ref } from 'vue';

import { TimeTools } from '@/common/utils/TimeTools';

const props = withDefaults(
	defineProps<{
		min: number;
		max: number;
		values: number[];
		step: number;
	}>(),
	{
		min: Infinity,
		max: Infinity,
		step: 1,
	}
);

const emit = defineEmits<{
	(e: 'update', value: { values: number[]; positions: number[] }): void;
	(e: 'instance', value: API): void;
}>();

const slider = ref<HTMLDivElement>();
const instance = ref();
onMounted(async () => {
	await until(() => props.max).not.toBe(0);
	if (!slider.value) {
		throw new Error('Error on mount multi range input');
	}
	instance.value = noUiSlider.create(slider.value, {
		start: props.values,
		connect: false,
		range: {
			min: props.min,
			max: props.max,
		},
		tooltips: [
			{ to: (val) => TimeTools.toSeconds(val).toFixed(1) + 's', from: (val) => TimeTools.toSeconds(Number(val)) },
			{ to: (val) => TimeTools.toSeconds(val).toFixed(1) + 's', from: (val) => TimeTools.toSeconds(Number(val)) },
		],
		step: props.step,
	});

	emit('instance', instance.value);

	instance.value.on(
		'update',
		(values: unknown, handle: unknown, unencoded: unknown, tap: unknown, positions: number[], noUiSlider: API) => {
			const data = noUiSlider.get(true);
			// Si los valores son los mismos vamos a actualizar a el mínimo + 500 ms por defecto
			// ya que no vamos a aceptar un vídeo de menor duración
			if (positions[0] === positions[1] && Array.isArray(data)) {
				noUiSlider.set([data[0], Number(data[1]) + 500]);
				return;
			}
			emit('update', { values: noUiSlider.get(true) as number[], positions });
		}
	);
});

onBeforeUnmount(() => {
	instance.value.off('update');
});
</script>

<template>
	<div ref="slider" class="w-full" />
</template>
